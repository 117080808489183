import React, {useState, useCallback, useMemo} from 'react'
import {createPortal} from 'react-dom'
import {APP_LOGO, APP_NAV} from '../../00-Core/Constants'
import {NavLink} from 'react-router-dom'
import HeaderUserInfos from './01-Auth/02-UserInfos/AuthUserInfosModal'
import {Button} from '@mi-gso/cvt'

//PICTURE
import {
    picTelescopeLogoIcon,
} from '../../00-Core/02-Pictures/PicturesCatalogue'
import { envVarManagement } from '../../envVarManagement'

/////////////////////////////////////
// SUMMARY                         
// - HeaderAppTitle         
// - HeaderSearchBar           
// - HeaderLink       
// - HeaderVerticalMenu
// - HeaderLightMenu
// - HeaderMenu
// - HeaderServiceList
// - HeaderProjectList
// - HeaderLogInOut
// - HeaderSeparator
// - Header
/////////////////////////////////////

//LOGO AND APPLICATION NAME ------------------------------------------------------------
function HeaderAppTitle({
    searchBar
}){
    return(
        <div className="flexStartCenter headerLogoSearchBlock" >
            <NavLink to="/" >
                <div className="flexCenterCenter logoBlock">

                    {/* LOGO ABR */}
                    <div className="logoIconAbrContent flexCenterCenter">
                        
                        <img 
                            src={picTelescopeLogoIcon} 
                            className="card-img-top cardImage logoImg" 
                            alt="cardPicture" 
                        />
                    </div>


                    {/* TITRE */}
                    <div className="flexColCenterStart ">
                        <span className="textEllipsis logoContent">
                            {APP_LOGO.logoTxt}
                        </span>
                        <span
                            style={{
                                fontSize:"11px",
                                fontWeight:500,
                                marginTop:"-7px",
                                color:"rgba(255,255,255,0.4)"
                            }}
                        >
                            clayverest.com
                        </span>

                    </div>
                </div>
            </NavLink>

            {/* SEARCH BAR */}
            {searchBar ? <HeaderSearchBar /> : null}
        </div>
    )
}

//SEARCH BAR ---------------------------------------------------------------------------
function HeaderSearchBar({
    lightMenu
}){
    return(
        <div className={lightMenu ? "headerSearchGroupLightMenu" : "headerSearchGroup"}>
            <div className="input-group">
                <span className="input-group-text headerSearchIcon material-icons" >
                    search
                </span>
                <input type="text" className="form-control headerSearch" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
            </div>
        </div>
    )
}

//MENU LINK COMPONENT ----------------------------------------------------------------
function HeaderLink({
    link, 
    handleSettings,
    navTooltip
}){
    return(
        <React.Fragment>

            {/* NAV LINK */}
            <NavLink 
                to={link.url} 
                activeClassName="headerNavSelected" 
                className="flexCenterCenter"
                style={{width:"fit-content"}}
                onClick={handleSettings ? () => handleSettings("lightMenu", false) : null}
            >
                <div className="flexCenterCenter headerMenuItem">
                    <span className="material-icons headerMenuIcon" >{link.icon}</span>
                    <span className="headerMenuTxt">{link.name}</span>
                </div>
            </NavLink>
        </React.Fragment>
    )
}

//VERTICAL MENU ---------------------------------------------------------------------------
function HeaderVerticalMenu({
    handleSettings,
    searchBar,
    lightMenu,
}){
    return createPortal(
        <React.Fragment>
            <div className=" headerLightMenuBlock">

                {/* SEARCH BAR */}
                {searchBar ? 
                    <React.Fragment>
                        <HeaderSearchBar lightMenu={lightMenu} /> 
                        <HeaderSeparator />
                    </React.Fragment>
                : null}

                {/* NAV LINKS */}
                {APP_NAV.map(link => (
                    <HeaderLink 
                        link={link} 
                        key={"nav-" + link.id}  
                        handleSettings={handleSettings}
                    />))}
            </div>
            <div className="headerLightMenuBg" onClick={() => handleSettings("lightMenu", false)}></div>
        </React.Fragment>
    , document.body)
}

//LIGHT MENU BLOCK -------------------------------------------------------------------
function HeaderLightMenu ({
    handleSettings, 
    lightMenu,
    searchBar,
}){

    //CLICK - Need simple to pass inside Button component
    const handleClick = useCallback(()=>{
        handleSettings("lightMenu", !lightMenu)
    },[handleSettings, lightMenu])

    //RETURN
    return(
        <React.Fragment>

            {/* BUTTON LINK LITTLE */}
            <Button 
                type="button"
                css="btn-light headerLink"
                handleSubmit={handleClick}
                icon="link"
                iconCss="headerMenuIconLink"
            />

            {/* Vertical menu */}
            {lightMenu ? 
                <HeaderVerticalMenu 
                    handleSettings={handleSettings} 
                    searchBar={searchBar} 
                    lightMenu={lightMenu}
                /> 
            :null}
        </React.Fragment>
    )
}

//NAV LINK MENU -----------------------------------------------------------------------
function HeaderMenu(){
    return(
        <div className="flexStartCenter headerMenuBlock">
            {APP_NAV.map(link => (
                <HeaderLink 
                    link={link} 
                    key={"nav-" + link.id}  
                    navTooltip={true}
                />))}
        </div>
    );
}

//SERVICE LIST -------------------------------------------------------------------------
function HeaderServiceList({
    currentServiceId
}){

    //ENV SERVICES
    const envServices = useMemo(() => envVarManagement("envServices"),[])

    //RENDER
    return(
        <div className="dropdown">

            {/* BUTTON => can't use dropdown with Button component for the moment*/}
            <button 
                className="btn btn-secondary dropdown-toggle headerServiceDropDown" 
                type="button" 
                data-bs-toggle="dropdown" 
                style={{
                    border:"1px solid rgba(255,255,255,0.3)"
                }}
            >
                Services
            </button>

            {/* LIST */}
            <ul 
                className="dropdown-menu" 
                style={{
                    fontSize:"14px",
                    boxShadow: "0px 1px 4px 0px rgb(0 0 0 / 20%)"
                }}
            >
                {envServices.map(service => {
                    if(currentServiceId === service.id){
                        return null
                    }else{
                        return (
                            <li 
                                key={"service" + service.id} 
                            >
                                <a 
                                    className="dropdown-item flexStartCenter" 
                                    href={service.url}
                                    style={{
                                        fontWeight:600,
                                        paddingLeft:"6px"
                                    }}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span 
                                    className="material-icons"
                                    style={{
                                        fontSize:"14px",
                                        color:"var(--color-migso-blue)",
                                        marginRight:"6px"
                                    }}
                                >
                                    label_important
                                </span>
                                    {service.name}
                                </a>
                            </li>
                        )
                    }
                })}
            </ul>
        </div>
    )
}

//SERVICE LIST -------------------------------------------------------------------------
function HeaderProjectList({
    projectsList,
    currentProjectId
}){

    //ON CHANGE
    const onChangeSelect = (e) => {
        
    }

    //RENDER
    return(

        <select 
            className="form-select headerServiceDropDown" 
            onChange={(e) => onChangeSelect(e)}
            style={{maxWidth:"100px"}}
        >
            <option value="" hidden>Services</option>
            {projectsList.map(project => {
                if(currentProjectId === project.id){
                    return null
                }else{
                    return (
                        <option 
                            key={"service" + project.id} 
                            value={project.id}
                        >
                            {project.name}
                        </option>
                    )
                }
            } )}
        </select>
    )
}

//LOGOUT BUTTON -----------------------------------------------------------------------
function HeaderLogInOut({
    loggedIn,
    signOut,
    users,
    appDispatcher
}){

    //STATE
    const [user, setUser] = useState({
        displayUser: false,
    })

    //HANDLE
    const handleUser = useCallback((key, value) => {
        setUser(prevUser => {
            return({
                ...prevUser,
                [key]: value
            })
        })
    },[])

    //RESET VIEW
    const handleReset = useCallback(() => {
        setUser(prevUser => {
            return({
                ...prevUser,
                displayUser: false
            })
        })
    },[])

    //HANDLE SIGNOUT
    const handleSignOut = useCallback(() => {
        setUser(prevUser => {
            return({
                ...prevUser,
                displayUser: false,
            })
        })
        signOut(
            appDispatcher,
            true
        )
    },[appDispatcher, signOut])

    //HANDLE CLICK WITH BUTTON COMPONENT
    const handleClick = useCallback(()=>{
        handleUser("displayUser", true)
    },[handleUser])
    
    //OK FOR LOGOUT
    if(loggedIn){
        return(
            <React.Fragment>

                <Button 
                    type="button"
                    css="btn-light headerUser" 
                    handleSubmit={handleClick}
                    margin="0px 20px 0px 0px"
                    icon="person"
                    iconFontSize="22px"
                />
                
                {/* display user modal */}
                {user.displayUser ?
                    <HeaderUserInfos 
                        functionResetModal={handleReset}
                        signOut={handleSignOut}
                        users={users}
                        appDispatcher={appDispatcher}
                    />
                :null}
            </React.Fragment>
        )
    }else{
        return null
    }
}

//SEPARATOR -----------------------------------------------------------------------------
function HeaderSeparator({
    isVertical,
    marginLeftOff
}){
    return(
        <React.Fragment>
            {isVertical ? 
                <div 
                    className="headerSeparatorY"
                    style={{marginLeft: marginLeftOff ? "5px" : null}}
                ></div>
            :
                <div className="headerSeparatorX"></div>
            }
        </React.Fragment>
    )
}

//HEADER COMPONENT -------------------------------------------------------------------
export default function Header({
    searchBar,
    navLinks,
    currentServiceId,
    projectList,
    locationPath,
    loggedIn,
    signOut,
    users,
    appDispatcher
}) {

    //HERE HEADER FUNCTIONS 
    const [settings, setSettings] = useState({
        lightMenu:false,
        navTooltip:false,
    })

    //HANDLE SETTINGS
    const handleSettings = (attr, value) =>{
        setSettings(prevSettings => {
            return({
                ...prevSettings, 
                [attr]: value
            })
        })
    }

  
  
    //HERE HEADER RENDER
    return (
        <header 
            id="appHeader" 
            className="flexBetweenCenter"
            style={{
                backgroundColor: locationPath === "/" ?  
                    "var(--color-migso-black)" : "var(--color-migso-darkBlue)" 
            }}
        >

             {/* LOGO + SEARCH BAR */}
             <HeaderAppTitle 
                searchBar={searchBar} 
            />

            {/* NAVBIGATION + AUTHENTIFICATION */}
            <div className="flexEndCenter" >

                {/* Navigation links  */}
                {navLinks ? 
                    <React.Fragment>
                
                        {/* Light Menu */}
                        <HeaderLightMenu 
                            lightMenu={settings.lightMenu} 
                            handleSettings={handleSettings}
                            searchBar={searchBar}
                        />

                        {/* Horizontal menu*/}
                        <HeaderMenu 
                        
                        />

                    </React.Fragment>
                :null}

                {/* SEPARATOR */}
                {loggedIn ?
                    <HeaderSeparator 
                        isVertical={true} 
                    />
                :null}

                {/* SERVICE SELECTION */}
                <HeaderServiceList 
                    currentServiceId={currentServiceId}
                />

                {/* SERVICE SELECTION */}
                {projectList ? 
                    <HeaderProjectList 
                        projectList={projectList}
                    />
                :null}

                {/* SEPARATOR */}
                {loggedIn ?
                    <HeaderSeparator 
                        isVertical={true} 
                        marginLeftOff={true}
                    />
                :null}

                {/* AUTHENTIFICATION */}
                <HeaderLogInOut 
                    loggedIn={loggedIn}
                    users={users}
                    signOut={signOut}
                    appDispatcher={appDispatcher}
                />
            </div>
        </header>
    );
}