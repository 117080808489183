import React, {memo, useEffect, useMemo} from 'react'

//CORE
import {
    APP_WBS_STRUCTURE,
} from '../../../../00-Core/Constants'
import {piclogoDefault} from '../../../../00-Core/02-Pictures/PicturesCatalogue'

//CHARTER
import CharterMini from '../../05-Charter/CharterMini'
import ShortcutMini from '../../06-Shortcut/ShortcutMini'

//////////////////////////
/// WBS ITEM COMPONENT ///
//////////////////////////

//COMPONENT
export default memo(
    function WbsElementFirstColumn({
        item,
        isPortfolioSelected,
        isProjectElement,
        forceProjectNav,
        itemLevel,
        displayedIcon,
        displayedIconColor,
        selectedBranchWbsId,
        selectedWbsId,
        selectedField,
        charterData,
        charterCategory,
        shortCutData,
        bigComponentDisplayed,
        handleDisplay,
        handleDeepDive,
        handleBigComponent,
        isShortcutBigOpen,
        updateTrackers,
        isForceOpen,
    }) {

        // PROJECT ORGANIZATION PORTFOLIO PICTURE
        const itemPicture = useMemo(() => {

            //INIT
            let picture = piclogoDefault

            //TEST PROJECT
            if(isProjectElement){

                //TEST LOGO and picture url
                if(item.logo && item.logo !== ""){
                    picture = item.logo
                }else if(item.pictureUrl && item.pictureUrl !== ""){
                    picture = item.pictureUrl
                } else if(item.logoLitle && item.logoLitle !== "") {
                    picture = item.logoLitle;
                } else if(item.logoTitle && item.logoTitle !== "") {
                    picture = item.logoTitle;
                }
                else if(item.logoLitle && item.logoLitle !== ""){
                    picture = item.logoLitle
                }
            }

            //RETURN
            return picture
        }, [isProjectElement, item.logo, item.logoLitle, item.logoTitle, item.pictureUrl])
        
        // FORCE OPEN
        useEffect(() => {
            if(isForceOpen) {
                handleDisplay();
                document.getElementById(`#container-${item.id}-link`)?.click();
            }
        }, [handleDisplay, isForceOpen, item.id]);
        
        /////////////////////////////////////////////////////////////////////////////
        /// COMPONENT RENDER ///////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////
        
        return (
            <div 
                className="structureRowTitle flexStartCenter"
                style={{
                    maxWidth: (APP_WBS_STRUCTURE.firstColumnWidth - (itemLevel*20)) + "px",
                    minWidth: (APP_WBS_STRUCTURE.firstColumnWidth - (itemLevel*20)) + "px",
                    backgroundColor: isProjectElement || selectedBranchWbsId === item.id ? "var(--border-color-gray-00)" : null,
                    fontWeight: isProjectElement || selectedBranchWbsId === item.id ? 700 : null,
                    position: 'sticky',
                    zIndex:  1000,
                    left: (itemLevel*20) + "px",
                    borderLeft: itemLevel > 0 ? "1px solid var(--border-color-gray-1)" : null,
                }}
            >
                
                {/* ICON COLLAPSE + BRANCH NAME */}
                <div 
                    className="flexStartCenter" 
                    style={{
                        width:"100%",
                    }}
                >

                    {/* ICON */}
                    {item.children 
                    && item.children.length > 0 
                    && (!isProjectElement || (forceProjectNav && isProjectElement)) 
                    && selectedBranchWbsId !== item.id ? 
                        <a 
                            className={"btn btn-sm structureRowTitleIcon " + displayedIconColor} 
                            data-bs-toggle="collapse"
                            href={"#container-" + item.id}
                            id={`#container-${item.id}-link`} 
                            role="button" 
                            onClick={handleDisplay}
                        >
                            <span 
                                className="material-icons" 
                                style={{
                                    fontSize:"15px",
                                    color: "white",
                                }}
                            >
                                {displayedIcon}
                            </span>
                        </a>
                    :
                        isProjectElement && !forceProjectNav ?  

                            <img 
                                id="organizationPic"
                                src={itemPicture} 
                                className='commonOrganizationLitleLogo' 
                                alt=""
                                style={{
                                    margin:"0px 10px 0px 5px",
                                    height:"24px",
                                    width:"24px"
                                }}
                            />
                            
                        :
                            selectedBranchWbsId === item.id ?
                                <div>

                                    {/* ICON */}
                                    <div
                                        className="structureRowTitleIconBack material-icons flexCenterCenter"  
                                        onClick={(e)=>handleDeepDive(e,item.parentId)}
                                        style={{
                                            color: bigComponentDisplayed ? "var(--color-migso-blue)" : null,
                                            backgroundColor: bigComponentDisplayed ? "white" :null,
                                            borderRadius: bigComponentDisplayed ? "20px" :null,

                                        }}
                                    >
                                        keyboard_double_arrow_left
                                        
                                    </div>
                                    
                                    {/* NUMBER OF LEVEL BEFORE */}
                                    <div
                                        className='flexCenterCenter'
                                        onClick={(e)=>handleDeepDive(e,item.parentId)}
                                        style={{
                                            position:"absolute",
                                            top:"6px",
                                            left:"6px",
                                            backgroundColor: "var(--color-migso-blue)",
                                            color:"white",
                                            fontWeight:600,
                                            fontSize:"10px",
                                            height:"14px",
                                            width:"14px",
                                            borderRadius:"10px",
                                            cursor:"pointer",
                                        }}
                                    >
                                        {(item.level+1)}
                                    </div>
                                </div>
                            :

                                <div className="btn btn-sm structureRowTitleIconDisable structureRowTitleIcon" >
                                    <span 
                                        className="material-icons" 
                                        style={{
                                            fontSize:"15px", 
                                            color:"#dcdcdc",
                                        }}
                                    >
                                        keyboard_arrow_right
                                    </span>
                                </div>
                    }
                    
                    {/* NAME */}
                    <div 
                        className='structureRowWbsName'
                        style={{
                            maxWidth: isProjectElement ? (APP_WBS_STRUCTURE.firstColumnWidth - 170) + "px" : (APP_WBS_STRUCTURE.firstColumnWidth - 120) + "px"
                        }}
                    >
                        {item.children 
                        && item.children.length > 0 
                        && selectedBranchWbsId !== item.id 
                        && (!isProjectElement || (forceProjectNav && isProjectElement))?
                            <a 
                                href="/#" 
                                onClick={(e)=>handleDeepDive(e,item.id)}
                                style={{
                                    // color: isProjectElement ? "var(--color-text-3)" : null
                                }}
                            >
                                {item.name}
                            </a>
                        :
                            
                                item.name
                            
                        }
                    </div>
                </div>

                {/* MINI COMPONENT */}
                {!isPortfolioSelected ? 
                    <div className='flexStartCenter'>

                        {/* CHARTER => PASS IMPACT ... TO ALLOW EASY MEMO RENDER  NOT UNDEFINED */}
                        {isProjectElement ?
                            <CharterMini 
                                charterData={charterData}
                                charterUpdate={charterData ? charterData.updatedAt : null}
                                charterCategory={charterCategory}
                                charterDataImpact={charterData ? charterData.impact : null}
                                charterDataTeamSize={charterData ? charterData.teamSize : null}
                                charterDataStakeholderSize={charterData ? charterData.stakeholderSize : null}
                                charterDataBudget={charterData ? charterData.budget : null}
                                propKey={"miniCharter-" + item.id}
                                itemId={item.id}
                                fieldId="charter"
                                handleBigComponent={handleBigComponent}
                                bigComponentDisplayed={bigComponentDisplayed && selectedField === "charter"}
                                updateTracker={updateTrackers.charter}
                            />
                        :null}

                        {/* SHORTCUT */}
                        <ShortcutMini 
                            shortCutData={shortCutData}
                            itemId={item.id}
                            fieldId="shortcut"
                            handleBigComponent={handleBigComponent}
                            bigComponentDisplayed={isShortcutBigOpen && selectedWbsId === item.id}
                        />
                    </div>
                :null}

                {/* ARROW IF NEEDED */}
                {bigComponentDisplayed ? 
                    <div 
                        className="keyboard_arrow_down_container flexCenterCenter"
                        style={{
                            left: (APP_WBS_STRUCTURE.firstColumnWidth - 80) + "px"
                        }}
                    >
                        <div className="arrow_down"></div>
                    </div>
                :null}
            </div>
        )
    }
)