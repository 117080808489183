export const CURRENT_USER = "currentUser";
export const NOT_CURRENT_USER = "notCurrentUser";

// CUSTOM MIN DATE TYPE
export const CUSTOM_MIN_DATE_TYPE = {
    oneWeekAfter: "ONE_WEEK_AFTER",
    oneMonthAfter: 'ONE_MONTH_AFTER',
}

// CUSTOM MAX DATE TYPE
export const CUSTOM_MAX_DATE_TYPE = {
    oneYearAfter: 'ONE_YEAR_AFTER',
}

// CUSTOM DISABLED TYPES
export const CUSTOM_DISABLED_TYPES = {
    dateDiff: "DATE_DIFFERENCE",
}

export const EDIT_SIDE_BAR_IDS = {
    saveBtn: "edit_side_bar_save_btn"
}