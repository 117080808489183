import { CvtSpinnerGrow } from "@mi-gso/cvt";
import { FUNC_CHECK_URL_VALID } from "../../../00-Core/Standards";
import { PICTURE_NOT_FOUND } from "../00-Helpers/BoardConstants";

export default function BoardProjectListPicture({
  pictureUrl,
  alt,
  size,
  loading,
}) {
  return (
    <div
      className="flexCenterCenter"
      style={{
        width: size,
        height: size,
        borderRadius: "8px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* CASE THERE'S A PICTURE */}
      <img
        src={
          pictureUrl && FUNC_CHECK_URL_VALID(pictureUrl)
            ? pictureUrl
            : PICTURE_NOT_FOUND
        }
        alt={"Image " + alt}
        style={{
          width: "100%",
        }}
      />

      {/* LOADER */}
      {loading ? (
        <div className="boardPortfolioListCardLoader flexCenterCenter">
          <CvtSpinnerGrow spinnerColor="white" />
        </div>
      ) : null}
    </div>
  );
}
