import React, { useReducer, useMemo, useCallback, useEffect, memo } from 'react'
import { wbsReducer } from './WbsDispatcher'

//WBS
import WbsElement from './01-Components/WbsElement'
import WbsHeader from './01-Components/WbsHeader'
import WbsElementBig from './01-Components/WbsElementBig'
import WbsElementProject from './01-Components/WbsElementProject'
import FiltersTagSection from '../08-Filters/01-Components/FiltersTagSection/FiltersTagSection'
import {
	WBS_FILTER_PROJECT_DATA_BY_PROJECT,
	WBS_GET_FILTERED_DATA_BY_WBS_BRANCHE,
	WBS_GET_INITIAL_STATE,
	WBS_SEARCH_NESTED_TREE,
	WBS_SEARCH_PATH,
} from './00-Helpers/WbsFunctions'
//
//APP
import { batchMutationGraphql } from '../../00-App/02-Backend/AppBackendCommon'
import { getProjectData } from '../../00-App/02-Backend/AppBackendProjectData'

//CORE
import {
	selectionNestedWbsData,
	selectionNestedWbsDataForPortfolio,
	selectionUpdateLevelNestedWbsData,
} from '../../../00-Core/Selection'
import { APP_TOAST_TXT } from '../../../00-Core/Constants'
import WbsRightComponent from './01-Components/WbsRightComponent'
import {
	FUNCT_FIND_INDEX,
	// FUNC_ARRAY_TO_CHUNKS
} from '../../../00-Core/Standards'
import { FUNC_BOARD_GET_PROJECTS_SINGLE_AND_INSIDE_ORGANIZATION } from '../../01-Board/00-Helpers/BoardFunctions'
import {
	FILTER_PROJECT_TO_REMOVE,
	FILTER_WBS_DATA,
} from '../08-Filters/00-Helpers/FiltersFunctions'
import { CvtSpinnerGrow } from '@mi-gso/cvt'

/////////////////////////////////////////////////////////////////////////////////
/// WBS COMPONENT ///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

export default memo(function Wbs({
	selectedProjectId,
	selectedPortfolio,
	portfolioProjects,
	portfolios,
	projectData,
	wbsSettings,
	coreSelection,
	currentUser,
	usersList,
	projectLeaderList,
	wbsNavigationOptions,
	wbsFilters,
	wbsMinWidth,
	dataConsolidation,
	sideBarOpenWidth,
	appDispatcher,
	moveModalOptionsItemsArray,
	updateTrackers,
	referenceCreateUpdateObject,
	referenceModalItem,
}) {
	////////////////////////////////////////////////////////////////////////////
	/// STATE //////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////

	//CREATE STATE REDUCER BASED ON INITIAL DATA ABOVE
	const [state, wbsDispatcher] = useReducer(
		wbsReducer,
		WBS_GET_INITIAL_STATE(
			coreSelection,
			wbsNavigationOptions,
			selectedPortfolio,
			projectData
		)
	)

	//////////////////////////////////////////////////
	/// OPEN WBS IF PROJECT AVAILABLE FOR THE USER ///
	//////////////////////////////////////////////////

	// REDIRECT TO PORTFOLIO VIEW
	const openPortfolioWBS = useCallback(
		async (portfolio) => {
			//GET PROJECTS AND ORGANIZATON ID
			let selectedProjects = JSON.parse(portfolio.selectedProjects)
			let selectedOrganizations = JSON.parse(portfolio.selectedOrganizations)

			//IF NO PROJECT LINK TO PORTFOLIO
			if (selectedProjects.length === 0 && selectedOrganizations.length === 0) {
				//KILL LOADER NO PROJECT AVAILABLE
				wbsDispatcher({
					type: 'SET_STATE_KEY_VALUE',
					key: 'wbsLoader',
					value: false,
				})

				//OK OPEN
			} else {
				//GET GLOBAL PROJECT LIST FROM THE SELECTED PORTFOLIO
				const portfolioProjects =
					FUNC_BOARD_GET_PROJECTS_SINGLE_AND_INSIDE_ORGANIZATION(
						currentUser.coreProjects,
						selectedProjects,
						selectedOrganizations
					)

				//LOOP ON PORTFOLIO PROJECT LIST
				let k = portfolioProjects.length - 1
				while (k--) {
					//GET INDEX
					let findIndex = FUNCT_FIND_INDEX(
						currentUser.coreProjects,
						'id',
						portfolioProjects[k].id
					)

					//TEST INDEX
					if (findIndex === -1) {
						portfolioProjects.splice(k, 1)
					}
				}

				//PROJECTS LIST WITH ORGANIZATION ID FOR WBS CALL
				const projects = portfolioProjects.map((item) => {
					return {
						projectId: item.id,
						organizationId: item.coreDataOrganizationCoreDataProjectId,
					}
				})

				//ONLY PROJECT IDS
				const projectIds = projects.map((item) => item.projectId)

				//CALL DATA
				await getProjectData(projectIds, portfolio.id, projects)
					.then((res) => {
						//APP STATE
						appDispatcher({
							type: 'SET_LOAD_PORTFOLIO_WBS_DATA',
							portfolio: portfolio,
							projectData: res.projectData,
							wbsList: res.wbsList,
							wbsSettings: res.wbsSettings,
							portfolioProjects: portfolioProjects,
						})

						//STOP LOADING
						wbsDispatcher({
							type: 'SET_STATE_OBJECT',
							object: {
								wbsLoader: false,
								wbsList: res.wbsList,
							},
						})
					})
					.catch((err) => {
						console.log(err)

						// DISPLAY TOAST ERROR
						appDispatcher({
							type: 'SET_GLOBAL_STATE',
							options: {
								source: 'appToast',
								object: {
									display: true,
									color: 'danger',
									message: APP_TOAST_TXT.issueOpenProject,
								},
							},
						})
					})
			}
		},
		[appDispatcher, currentUser.coreProjects]
	)

	//USE EFFECT TO CHECK WHAT WE NEED TO DO
	useEffect(() => {
		//TEST LOCAL STORAGE
		const localPortfolioId = localStorage.getItem(
			'telescope-selectedPortfolioId'
		)
		const localStorageOrga = localStorage.getItem('telescope-selectedOrgaId')
		const localStorageProject = localStorage.getItem(
			'telescope-selectedProjectId'
		)

		//TEST IF SOMETHING TO LOAD
		if (!localPortfolioId && !localStorageOrga && !localStorageProject) {
			//STOP LOADING
			wbsDispatcher({
				type: 'SET_STATE_KEY_VALUE',
				key: 'wbsLoader',
				value: false,
			})
		} else {
			//TEST IF PROJECT ALREADY DISPLAYED
			if (
				selectedProjectId &&
				coreSelection.wbsList.length > 0 &&
				state.wbsLoader
			) {
				//OK EVERYTHING SEEMS GOOD PASS LOADER TO FALSE
				wbsDispatcher({
					type: 'SET_STATE_KEY_VALUE',
					key: 'wbsLoader',
					value: false,
				})

				//NEED TO TRY TO IMPORT WBS
			} else if (
				localStorageOrga &&
				localStorageProject &&
				coreSelection.selectedOrganization.id
			) {
				//OK GET PROJECT DATA
				getProjectData([localStorageProject], null, [
					{
						organizationId: localStorageOrga,
						projectId: localStorageProject,
					},
				])
					.then(async (result) => {
						//LAUNCH APP DISPATCHER
						appDispatcher({
							type: 'SET_INIT_PROJECT_DATA',
							projectData: result.projectData,
							projectId: localStorageProject,
							organizationId: localStorageOrga,
							keepRightComponent: true,
							wbsList: result.wbsList,
							wbsSettings: result.wbsSettings,
						})

						//STOP LOADING
						wbsDispatcher({
							type: 'SET_STATE_OBJECT',
							object: {
								wbsLoader: false,
								organizationId: localStorageOrga,
								projectId: localStorageProject,
								wbsList: result.wbsList,
							},
						})

						//ISSUE
					})
					.catch((err) => {
						console.log(err)

						//STOP LOADING
						wbsDispatcher({
							type: 'SET_STATE_KEY_VALUE',
							key: 'wbsLoader',
							value: false,
						})

						//DISPLAY ISSUE
						appDispatcher({
							type: 'SET_GLOBAL_STATE',
							options: {
								source: 'appToast',
								object: {
									display: true,
									color: 'danger',
									message: APP_TOAST_TXT.issueOpenProject,
								},
							},
						})
					})

				//FOR THE MOMENT TO HAVE THE GLOBAL LOGIC
			} else {
				//TEST IF WE COULD OPEN A PORTFOLIO
				if (selectedPortfolio && coreSelection.wbsList.length > 0) {
					//KILL LOADER NO PROJECT AVAILABLE
					wbsDispatcher({
						type: 'SET_STATE_KEY_VALUE',
						key: 'wbsLoader',
						value: false,
					})

					//TEST IF WE HAVE THE PORTFOLIO
				} else if (localPortfolioId && portfolios.length > 0) {
					//FIND
					let findIndex = FUNCT_FIND_INDEX(portfolios, 'id', localPortfolioId)

					//IF NOT DELETE LOCAL STORAGE AND LOADER FALSE
					if (findIndex > -1) {
						//LOAD PORTFOLIO
						openPortfolioWBS(portfolios[findIndex])
					} else {
						//STOP LOADING
						wbsDispatcher({
							type: 'SET_STATE_KEY_VALUE',
							key: 'wbsLoader',
							value: false,
						})
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coreSelection.selectedOrganization])

	////////////////////////////////////////////////////////////////////////////
	/// CORE DATA OPTIMIZATION /////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////

	//PROJECT TO REMOVE IF CHARTER FILTERED
	const projectToRemove = useMemo(() => {
		//INIT
		let removeList = []
		let noProjectToDisplay = false

		//ONLY IF PORTFOLIO
		if (selectedPortfolio) {
			//INIT
			let charterFilters = wbsFilters.filter(
				(item) => item.domain === 'charter'
			)
			let projectList = [...new Set(portfolioProjects.map((item) => item.id))]

			//TEST IF CHARTER FILTERED
			if (charterFilters.length > 0) {
				removeList = FILTER_PROJECT_TO_REMOVE(
					projectData.charter,
					charterFilters,
					projectList
				)
			}

			//IF ALL REMOVED DISPLAY MESSAGE
			if (removeList.length === projectList.length) {
				noProjectToDisplay = true
			}
		}

		//RETURN
		return {
			removeList: removeList,
			noProjectToDisplay: noProjectToDisplay,
		}
	}, [portfolioProjects, projectData.charter, selectedPortfolio, wbsFilters])

	// NESTED WBS --------------------------------------------------------------
	const nestedWbsData = useMemo(() => {
		//ALLOW TO CONCATENATE ALL PROJECT + WBS IN THE NESTED DATA
		if (selectedPortfolio) {
			return selectionNestedWbsDataForPortfolio(
				state.wbsList,
				portfolioProjects,
				projectToRemove.removeList
			)

			//NORMAL WAY
		} else {
			return selectionNestedWbsData(
				state.wbsList,
				state.projectId,
				state.organizationId
			)
		}
	}, [
		portfolioProjects,
		projectToRemove.removeList,
		selectedPortfolio,
		state.organizationId,
		state.projectId,
		state.wbsList,
	])

	//WBS UPDATE LEVEL ---------------------------------------------------------
	const updateLevelNestedWbsData = useMemo(() => {
		//GET NESTED DATA // MANAGE SANDBOX ... WE WILL SEE
		let nested = selectionUpdateLevelNestedWbsData(nestedWbsData)

		//TEST IF WBS DEEP
		if (state.selectedBranchWbsId) {
			return WBS_SEARCH_NESTED_TREE(nested, state.selectedBranchWbsId)
		}

		//NORMAL
		return nested
	}, [nestedWbsData, state.selectedBranchWbsId])

	//MANAGE PROJECT ITEM OBJECT TO BE USE INSIDE WBS AT THE TOP
	const projectItem = useMemo(() => {
		//PORTFOLIO OR ORGANIZATION
		if (selectedPortfolio) {
			return {
				...selectedPortfolio,
				children: [...updateLevelNestedWbsData],
			}

			//PROJECT ONLY
		} else {
			return {
				...coreSelection.selectedProject,
				children: [...updateLevelNestedWbsData],
			}
		}
	}, [
		coreSelection.selectedProject,
		selectedPortfolio,
		updateLevelNestedWbsData,
	])

	// OPP INDEX IN WBS SETTINGS DOMAIN
	const oppIndex = useMemo(() => {
		if (wbsSettings && wbsSettings.domain) {
			let roIndex = wbsSettings.domain.findIndex(
				(domain) => domain.id === 'riskOpp'
			)

			return wbsSettings.domain[roIndex].displayOpportunity
				? wbsSettings.domain[roIndex].opportunityIndex
				: -1
		}
		return -1
	}, [wbsSettings])

	// CHECK IF IN REVIEW MODE
	const isReviewMode = useMemo(() => {
		if (state.rightComponent?.name) {
			return true
		}

		return false
	}, [state.rightComponent?.name])

	////////////////////////////////////////////////////////////////////////
	/// WBS DYNAMIC ////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////

	//DISPLAY BIG COMPONENT
	const handleBigComponent = useCallback(
		(e, selectedWbsId, selectedField) => {
			e.preventDefault()

			// IF WE CLICK ON THE SAME MINI, CLOSE THE BIG COMPONENT
			if (
				(state.selectedWbsId === selectedWbsId &&
					state.selectedField === selectedField) ||
				(state.isShortcutBigOpen && state.selectedWbsId === selectedWbsId)
			) {
				wbsDispatcher({
					type: 'SET_STATE_OBJECT',
					object: {
						selectedWbsId: null,
						selectedField: null,
						selectedProjectId: null,
						selectedItem: null,
						wbsNavigationOptions: null,
						isShortcutBigOpen: false,
					},
				})
			} else if (
				state.selectedWbsId &&
				state.selectedField &&
				state.isChanged === true
			) {
				wbsDispatcher({
					type: 'SET_STATE_OBJECT',
					object: {
						temporarySelectedField: selectedField,
						temporarySelectedWbsId: selectedWbsId,
					},
				})
			} else if (selectedField === 'shortcut') {
				wbsDispatcher({
					type: 'SET_STATE_OBJECT',
					object: {
						selectedWbsId: selectedWbsId,
						selectedProjectId: selectedProjectId,
						isShortcutBigOpen: true,
					},
				})
			} else {
				wbsDispatcher({
					type: 'SET_STATE_OBJECT',
					object: {
						selectedWbsId: selectedWbsId,
						selectedField: selectedField,
						selectedProjectId: selectedProjectId,
					},
				})
			}
		},
		[
			selectedProjectId,
			state.isChanged,
			state.isShortcutBigOpen,
			state.selectedField,
			state.selectedWbsId,
		]
	)

	//DEEP DIVE WBS
	const handleDeepDive = useCallback((e, wbsId) => {
		e.preventDefault()
		wbsDispatcher({
			type: 'SET_STATE_KEY_VALUE',
			key: 'selectedBranchWbsId',
			value: wbsId,
		})
	}, [])

	//DEEP DIVE WBS
	const handleFullScreen = useCallback(
		(e) => {
			e.preventDefault()
			wbsDispatcher({
				type: 'SET_STATE_KEY_VALUE',
				key: 'isFullScreen',
				value: !state.isFullScreen,
			})
		},
		[state.isFullScreen]
	)

	//GET WBS TOP !! COULD BE MANAGE WITH FILTER SPACE
	const wbsPosition = useMemo(() => {
		//TEST GET WBS BLOCK
		const getWbsBlock = document.getElementById('wbsBlock')

		//IF GOOD
		if (getWbsBlock && state.isFullScreen) {
			//GE OBJECT
			let wbsBlock = getWbsBlock.getBoundingClientRect()
			let wbsTopBlock = document.getElementById('wbsTopBlock')
			let filterBlockHeight = 0
			if (wbsTopBlock) {
				let wbsTopBlockRect = wbsTopBlock.getBoundingClientRect()
				filterBlockHeight = wbsTopBlockRect.height
			}

			//RETURN TOP
			return {
				top: wbsBlock.top,
				left: wbsBlock.left,
				filterBlockHeight: filterBlockHeight,
			}
		} else {
			return {
				top: 0,
				left: 0,
				filterBlockHeight: 0,
			}
		}
	}, [state.isFullScreen])

	// GET SELECTED REVIEW ITEM
	const selectedReviewItem = useMemo(() => {
		if (
			state.rightComponent &&
			state.rightComponent?.name === 'govReview' &&
			state.rightComponent?.data?.govItem
		) {
			return state.rightComponent.data.govItem
		}

		if (wbsFilters && wbsFilters.length > 0) {
			// FIND INDEX
			const reviewFilter = wbsFilters.find(
				(filter) => filter.key === 'governancereviewName'
			)

			// IF FOUND
			if (reviewFilter) {
				// CHECK IF HAS ONLY 1 REVIEW IN THE FILTER
				if (reviewFilter.value.length === 1) {
					// FIND GOV REVIEW ITEM IN DATA
					const govReview = projectData?.govReview.find(
						(review) => review.id === reviewFilter.value[0].value
					)

					return govReview ?? null
				}
			}
		}
		return null
	}, [projectData?.govReview, state.rightComponent, wbsFilters])

	///////////////////////////////////////////////////////////////////////////
	/// DATA //////////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////

	//FILTERED DATA
	const wbsProjectData = useMemo(() => {
		//FILTER DATA
		if (wbsFilters && wbsFilters.length > 0) {
			return FILTER_WBS_DATA(projectData, wbsFilters)
		}

		//RETURN NORMAL
		return projectData
	}, [projectData, wbsFilters])

	// ARRAY OF IDS OF WBS BRANCHES THAT NEED TO "OPEN" WHEN A BRANCH IS SELECTED
	const listOfForceOpenWbs = useMemo(() => {
		if (state.selectedWbsId) {
			return WBS_SEARCH_PATH([projectItem], state.selectedWbsId)
		} else return []
	}, [projectItem, state.selectedWbsId])

	// HANDLE OPEN REFERENCE MODAL
	const handleOpenReferenceModal = useCallback(
		(e, category) => {
			e.stopPropagation()

			if (state.mapDataAccessReferences[category]) {
				//APP STATE
				appDispatcher({
					type: 'SET_GLOBAL_STATE',
					options: {
						source: 'appSettings',
						object: {
							actionModal: 'wbsChooseReference',
							referenceModalItem: state.mapDataAccessReferences[category],
						},
					},
				})
			}
		},
		[appDispatcher, state.mapDataAccessReferences]
	)

	// SAVE REFERENCE
	const handleSaveReference = useCallback(
		async (e, category) => {
			e.stopPropagation()

			try {
				if (referenceCreateUpdateObject) {
					// SET LOADING
					wbsDispatcher({
						type: 'SET_STATE_KEY_VALUE',
						key: 'referenceSavingLoader',
						value: {
							...state.referenceSavingLoader,
							[category]: true,
						},
					})

					// CREATE
					if (referenceCreateUpdateObject.create.length > 0) {
						await batchMutationGraphql(
							'createTelescopeDataSchedule',
							referenceCreateUpdateObject.create,
							appDispatcher
						)
					}

					// UPDATE
					if (referenceCreateUpdateObject.update.length > 0) {
						await batchMutationGraphql(
							'updateTelescopeDataSchedule',
							referenceCreateUpdateObject.update,
							appDispatcher
						)
					}

					// UPDATE APP STATE, TO SEND TO WBS
					appDispatcher({
						type: 'SET_GLOBAL_STATE',
						options: {
							source: 'appSettings',
							object: {
								actionModal: null,
								referenceCreateUpdateObject: null,
								referenceModalItem: null,
							},
						},
						toast: {
							display: true,
							color: 'good',
							message: APP_TOAST_TXT.mutationReferenceOk,
						},
					})

					// SET LOADING FALSE
					wbsDispatcher({
						type: 'SET_STATE_KEY_VALUE',
						key: 'referenceSavingLoader',
						value: {
							...state.referenceSavingLoader,
							[category]: false,
						},
					})
				}
			} catch (error) {
				console.log('Error saving reference...', error)

				// SET LOADING FALSE
				wbsDispatcher({
					type: 'SET_STATE_KEY_VALUE',
					key: 'referenceSavingLoader',
					value: {
						...state.referenceSavingLoader,
						[category]: false,
					},
				})
			}
		},
		[appDispatcher, referenceCreateUpdateObject, state.referenceSavingLoader]
	)

	// HANDLE CLOSE RIGHT COMPONENT WHEN FILTERING ON REVIEWS
	useEffect(() => {
		if (
			wbsFilters &&
			wbsFilters.length > 0 &&
			state.rightComponent &&
			state.rightComponent?.name === 'govReview' &&
			state.rightComponent?.data?.govItem
		) {
			const reviewFilter = wbsFilters.find(
				(filter) => filter.key === 'governancereviewName'
			)
			// IF FOUND REVIEW FILTER
			if (reviewFilter) {
				// TRY TO FIND THE INDEX OF THE GOV REVIEW OPEN IN THE RIGHT COMPONENT IN THE FILTERS
				const rightComponentReviewIndex = reviewFilter.value.findIndex(
					(filterObj) =>
						filterObj.value === state.rightComponent.data.govItem.id
				)
				// IF COULDN'T FIND, THEN RESET RIGHT COMPONENT
				if (rightComponentReviewIndex === -1) {
					// WBS STATE
					wbsDispatcher({
						type: 'SET_STATE_KEY_VALUE',
						key: 'rightComponent',
						value: null,
					})
				}
			}
		}
	}, [state.rightComponent, wbsFilters])

	///////////////////////////////////////////////////////////////////////////
	/// COMPONENT RENDER //////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////

	return (
		<React.Fragment>
			<section
				id='structureContent'
				className='flexColStartStart'
				style={{
					minWidth: isReviewMode ? '363px' : '580px',
				}}>
				{/* FILTER SECTION */}
				<FiltersTagSection
					wbsFilters={wbsFilters}
					usersList={usersList}
					appDispatcher={appDispatcher}
				/>

				{/* WBS */}
				<div
					key='wbsList'
					id='wbsBlock'
					className='structureBlockProject'
					style={{
						backgroundColor:
							coreSelection.selectedProject.id &&
							nestedWbsData.length > 0 &&
							!state.wbsLoader
								? null
								: 'white',
						height: state.isFullScreen
							? 'calc(100vh - ' + (160 + wbsPosition.filterBlockHeight) + 'px)'
							: null,
						overflow: state.isFullScreen ? 'hidden' : null,
						minWidth: isReviewMode ? '320px' : '400px',
					}}>
					{/* HEADER */}
					<WbsHeader
						isSelectedProject={
							(coreSelection.selectedProject.id || selectedPortfolio) &&
							nestedWbsData.length > 0 &&
							!state.wbsLoader
						}
						message={
							state.wbsLoader
								? 'Hey, I am getting all your project data !'
								: projectToRemove.noProjectToDisplay
								? 'Nothing to display'
								: null
						}
						icon={state.wbsLoader ? 'pending' : null}
						selectedProject={coreSelection.selectedProject}
						selectedOrganization={coreSelection.selectedOrganization}
						selectedBranchWbsId={state.selectedBranchWbsId}
						wbsSettings={wbsSettings}
						isReviewMode={isReviewMode}
						selectedPortfolio={selectedPortfolio}
						portfolioProjects={portfolioProjects.length}
						wbsMinWidth={wbsMinWidth}
						isShortcutBigOpen={state.isShortcutBigOpen}
						isFullScreen={state.isFullScreen}
						oppIndex={oppIndex}
						mapDataAccessReferences={state.mapDataAccessReferences}
						handleOpenReferenceModal={handleOpenReferenceModal}
						handleSaveReference={handleSaveReference}
						referenceCreateUpdateObject={referenceCreateUpdateObject}
						referenceModalItem={referenceModalItem}
						wbsDispatcher={wbsDispatcher}
						referenceSavingLoader={state.referenceSavingLoader}
						appDispatcher={appDispatcher}
					/>

					{/* BODY */}
					{(coreSelection.selectedProject.id || selectedPortfolio) &&
					nestedWbsData.length > 0 &&
					!state.wbsLoader &&
					!projectToRemove.noProjectToDisplay ? (
						<div
							className='structureBlockProjectBody'
							style={{
								minWidth: wbsMinWidth + 'px',
							}}>
							{/* PROJECT LINE : ONLY IS WBS NOT DEEP */}
							{!state.selectedBranchWbsId ? (
								<WbsElementProject
									item={projectItem}
									projectData={wbsProjectData}
									globalProjectData={projectData}
									projectToRemove={projectToRemove.removeList}
									selectedWbsId={state.selectedWbsId}
									selectedField={state.selectedField}
									handleBigComponent={handleBigComponent}
									coreSelection={coreSelection}
									usersList={usersList}
									projectLeaderList={projectLeaderList}
									currentUser={currentUser}
									wbsSettings={wbsSettings}
									wbsDispatcher={wbsDispatcher}
									appDispatcher={appDispatcher}
									isShortcutBigOpen={state.isShortcutBigOpen}
									isReadOnlyMode={selectedPortfolio !== null}
									isPortfolioSelected={selectedPortfolio !== null}
									selectedProjectId={selectedProjectId}
									isReviewMode={isReviewMode}
									handleFullScreen={handleFullScreen}
									isFullScreen={state.isFullScreen}
									wbsPosition={wbsPosition}
									dataConsolidation={dataConsolidation}
									sideBarOpenWidth={sideBarOpenWidth}
									oppIndex={oppIndex}
									moveModalOptionsItemsArray={moveModalOptionsItemsArray}
									updateTrackers={updateTrackers}
									referenceCreateUpdateObject={referenceCreateUpdateObject}
									rightComponentData={
										state.rightComponent && state.rightComponent.data
											? state.rightComponent.data
											: null
									}
									listOfForceOpenWbs={listOfForceOpenWbs}
									projectChildIds={projectItem.childId}
									wbsNavigationOptions={state.wbsNavigationOptions}
									selectedItemId={state.selectedItem}
									selectedReviewItem={selectedReviewItem}
								/>
							) : null}

							{/* RECURSIVE FUNCTION TO CREATE WBS NESTED TREE */}
							{(function mapChildren(nestedWbsData, path) {
								//RETURN JSX
								return nestedWbsData.map((item, index) => {
									//MANAGE PATH
									let currentPath = ''
									if (path === '') {
										currentPath = item.name
									} else {
										currentPath = path + ' > ' + item.name
									}

									//SORT CHILDREN
									item.children.sort(function (a, b) {
										if (a.sort < b.sort) {
											return -1
										}
										if (a.sort > b.sort) {
											return 1
										}
										return 0
									})

									//MANAGE LEVEL
									let itemLevel = item.level + 1
									if (item.isProject && selectedPortfolio === null) {
										itemLevel -= 1
									}

									//////////////////////////////////////
									/// FILTER DATA RELATED TO CHILDID ///
									//////////////////////////////////////

									///!!!!!! PRESERVE REF !!!!!!! TO BE UPDATED

									//MANAGE CONSOLIDATION
									let itemIdList = [item.id]
									if (dataConsolidation) {
										itemIdList = [item.id, ...item.childId]
									}

									//FILTER DATA
									//!!! add risk escalation
									let wbsItemData = WBS_GET_FILTERED_DATA_BY_WBS_BRANCHE(
										wbsProjectData,
										itemIdList,
										item.isProject,
										item.id,
										dataConsolidation
									)

									// KEEP ONLY THE CURRENT PROJECT DATA
									let currentProjectGlobalData =
										WBS_FILTER_PROJECT_DATA_BY_PROJECT(
											projectData,
											item.coreDataProjectCoreDataWbsId || item.id
										)

									///!!!!!! PRESERVE REF !!!!!!! TO BE UPDATED

									//////////////////
									/// RETURN JSX ///
									//////////////////

									return (
										<div
											key={item.id}
											id={item.id}
											className='flexStartStrech'
											style={{
												backgroundColor: 'var(--border-color-gray-0)',
												position: 'relative',
												width: '100%',
											}}>
											{/* MARGIN LEFT NEED TO HIDE WITH HORIZONTAL SCROLL */}
											<div
												className='structureRowLeftMargin'
												style={{
													left: (itemLevel - 1) * 20 + 'px',
													zIndex: 200 - itemLevel * 2 + 1,
												}}></div>

											{/* ROW */}
											<div style={{ width: '100%' }}>
												{/* WBS ROW COMPONENT => INCLUDE ALL MINI COMPONENT ----------- */}
												<WbsElement
													item={item}
													itemLevel={itemLevel}
													isProjectElement={item.isProject}
													forceProjectNav={selectedPortfolio !== null}
													selectedWbsId={state.selectedWbsId}
													selectedField={state.selectedField}
													projectData={wbsItemData}
													globalProjectData={currentProjectGlobalData}
													selectedBranchWbsId={state.selectedBranchWbsId}
													handleBigComponent={handleBigComponent}
													handleDeepDive={handleDeepDive}
													wbsProjectData={wbsProjectData}
													currentUser={currentUser}
													wbsSettings={wbsSettings}
													wbsDispatcher={wbsDispatcher}
													isShortcutBigOpen={state.isShortcutBigOpen}
													selectedProjectId={selectedProjectId}
													isReviewMode={isReviewMode}
													handleFullScreen={handleFullScreen}
													isFullScreen={state.isFullScreen}
													wbsPosition={wbsPosition}
													oppIndex={oppIndex}
													updateTrackers={updateTrackers}
													listOfForceOpenWbs={listOfForceOpenWbs}
													wbsNavigationOptions={state.wbsNavigationOptions}
												/>

												{/* BIG COMPONENT ---------------------------------------------- */}
												{item.id === state.selectedWbsId ? (
													<WbsElementBig
														item={item}
														currentPath={currentPath}
														coreSelection={coreSelection}
														selectedWbsId={item.id}
														parentId={
															item.parentId
																? item.parentId
																: coreSelection.selectedProject.id
														}
														selectedWbsName={item.name}
														selectedField={state.selectedField}
														projectData={wbsItemData}
														globalProjectData={currentProjectGlobalData}
														usersList={usersList}
														//TODO NEED CHANGE??
														isReadOnlyMode={false}
														rightComponentData={
															state.rightComponent && state.rightComponent.data
																? state.rightComponent.data
																: null
														}
														projectLeaderList={projectLeaderList}
														isPortfolio={selectedPortfolio !== null}
														currentUser={currentUser}
														wbsProjectData={wbsProjectData}
														wbsSettings={wbsSettings}
														handleBigComponent={handleBigComponent}
														wbsDispatcher={wbsDispatcher}
														appDispatcher={appDispatcher}
														isShortcutBigOpen={state.isShortcutBigOpen}
														selectedProjectId={selectedProjectId}
														isReviewMode={isReviewMode}
														handleFullScreen={handleFullScreen}
														projectChildIds={item.childId}
														isFullScreen={state.isFullScreen}
														wbsPosition={wbsPosition}
														sideBarOpenWidth={sideBarOpenWidth}
														oppIndex={oppIndex}
														moveModalOptionsItemsArray={
															moveModalOptionsItemsArray
														}
														updateTrackers={updateTrackers}
														referenceCreateUpdateObject={
															referenceCreateUpdateObject
														}
														wbsNavigationOptions={state.wbsNavigationOptions}
														selectedItemId={state.selectedItem}
														selectedReviewItem={selectedReviewItem}
													/>
												) : null}

												{/* CALL RECURSIVE FUNCTION IF CHILDREN --------------------------*/}
												{item.children && item.children.length > 0 ? (
													<div
														key={'children-container-' + item.id}
														className={
															state.selectedBranchWbsId === item.id
																? 'collapse show'
																: 'collapse'
														}
														id={'container-' + item.id}>
														{mapChildren(item.children, currentPath)}
													</div>
												) : null}
											</div>
										</div>
									)
								})
							})(updateLevelNestedWbsData, '')}
						</div>
					) : (
						<div className='structureBlockInfo flexStartCenter'>
							{projectToRemove.noProjectToDisplay ? (
								<React.Fragment>
									<span className='material-icons structureBlockInfoIcon'>
										info
									</span>
									<span>
										No Data can be displayed with your current filter(s)
									</span>
								</React.Fragment>
							) : state.wbsLoader ? (
								<React.Fragment>
									<CvtSpinnerGrow
										marginRight='10px'
										text="I'm sure your project will be perfect"
									/>
								</React.Fragment>
							) : (
								<React.Fragment>
									<span className='material-icons structureBlockInfoIcon'>
										info
									</span>
									<span>
										Select a Portfolio / Organization / Project to display his
										detailed structure
									</span>
								</React.Fragment>
							)}
						</div>
					)}
				</div>
			</section>

			{/* WBS RIGHT COMPONENT */}
			{state.rightComponent &&
			state.rightComponent.name &&
			state.rightComponent.data ? (
				<WbsRightComponent
					updateTrackers={updateTrackers}
					componentName={state.rightComponent.name}
					data={state.rightComponent.data}
					currentUser={currentUser}
					usersList={usersList}
					projectData={projectData}
					selectedWbsItem={state.selectedItem}
					appDispatcher={appDispatcher}
					wbsDispatcher={wbsDispatcher}
					dataConsolidation={dataConsolidation}
				/>
			) : null}
		</React.Fragment>
	)
})
