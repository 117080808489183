import { TODAY } from "../../../../00-Core/Constants";
import {
    createTelescopeDataRO,
    deleteTelescopeDataRO,
    updateTelescopeDataRO,
} from "../../../../graphql/mutations";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";

// SUMMARY
// - RISK_OPP_DISPLAY_ID_PREFIX
// - RISK_STRATEGY_LIST
// - OPP_STRATEGY_LIST
// - RISK_OPP_STATUS_LIST
// - RISK_OPP_PROBABILITY_LIST
// - RISK_OPP_INVERTED_IMPACT_LIST
// - RISK_OPP_IMPACT_LIST
// - RISKOPP_CRITICITY_LIST
// - RISKOPP_ESCALATED_OPTIONS
// - RO_HEADER_ESCALATION_TOGGLE_OPTIONS
// - ACTION_OPTIONS
// - RISK_OPP_EXPAND_MODES
// - RISK_OPP_MANDATORY_FIELDS
// - RISK_OPP_GROUP_ITEMS
// - RISK_OPP_ITEM_DATA_STRUCTURE
// - MATRIX_GROUPS
// - RISK_OPPS_MUTATION_QUERIES
// - RISKS_OPPS_MATRIX_SETTINGS
// - RISK_MATRIX_VIEW_MODE
// - RISK_VIEW_MODE_OPTIONS
// - RISK_OPP_TXT_CONVERSION
// - RISK_OPP_CHANGE_ACTIONS_TYPES
// - RISK_OPP_CARD_IMPACT_MAP
// - RISK_OPP_CARD_IMPACT_VALUES
// - RISK_OPP_CRITICITY_TXT_CONVERTION

// DISPLAY ID PREFIX
export const RISK_OPP_DISPLAY_ID_PREFIX = "RO";

// STRATEGY LIST FOR RISKS
export const RISK_STRATEGY_LIST = [
    {
        label: "Avoid",
        value: "Avoid",
        color: "var(--color-text-3)",
    },
    {
        label: "Transfer",
        value: "Transfer",
        color: "var(--color-escalated)",
    },
    {
        label: "Mitigate",
        value: "mitigate",
        color: "var(--color-bad)",
    },
    {
        label: "Accept",
        value: "accept",
        color: "var(--color-good)",
    },
];

// STRATEGY LIST FOR OPP
export const OPP_STRATEGY_LIST = [
    {
        label: "Exploit",
        value: "exploit",
        color: "var(--color-escalated)",
    },
    {
        label: "Share",
        value: "share",
        color: "var(--color-migso-blue)",
    },
    {
        label: "Enhance",
        value: "enhance",
        color: "var(--color-migso-yeallow)",
    },
    {
        label: "Accept",
        value: "accept",
        color: "var(--color-good)",
    },
];

// STATUS
export const RISKOPP_STATUS_LIST = [
    {
        label: "Opened",
        value: "opened",
        backgroundColor: "var(--color-good)",
        color: "white",
    },
    {
        label: "Occurred",
        value: "occurred",
        backgroundColor: "var(--border-color-gray-1)",
        color: "white",
    },
    {
        label: "Closed",
        value: "closed",
        backgroundColor: "var(--color-bad)",
        color: "white",
    },
];

// PROBABILITY LIST
export const RISK_OPP_PROBABILITY_LIST = [
    {
        label: "Low",
        value: "Low",
        criticity: 4,
        riskColor: "#a9ff8f",
        opportunityColor: "#b8e5ff",
    },
    {
        label: "Possible",
        value: "Possible",
        criticity: 3,
        riskColor: "#fae875",
        opportunityColor: "#80d0ff",
    },
    {
        label: "Likely",
        value: "Likely",
        criticity: 2,
        riskColor: "#ffb26b",
        opportunityColor: "#52c0ff",
    },
    {
        label: "Nearly certain",
        value: "Nearly certain",
        criticity: 1,
        riskColor: "#e64959",
        opportunityColor: "#009cf5",
    },
];

// INVERTED LIST FOR RISK BIG MATRIX
export const RISK_OPP_INVERTED_IMPACT_LIST = [
    {
        label: "Very High",
        value: "Very High",
        criticity: 1,
        riskColor: "#e64959",
        opportunityColor: "#b8e5ff",
    },
    {
        label: "High",
        value: "High",
        criticity: 2,
        riskColor: "#ffb26b",
        opportunityColor: "#80d0ff",
    },
    {
        label: "Medium",
        value: "Medium",
        criticity: 3,
        riskColor: "#fae875",
        opportunityColor: "#52c0ff",
    },
    {
        label: "Low",
        value: "Low",
        criticity: 4,
        riskColor: "#a9ff8f",
        opportunityColor: "#009cf5",
    },
];

// IMPACT LIST FOR CARD MATRIX
export const RISK_OPP_IMPACT_LIST = [
    {
        label: "Low",
        value: "Low",
        criticity: 4,
        riskColor: "#a9ff8f",
        opportunityColor: "#b8e5ff",
    },
    {
        label: "Medium",
        value: "Medium",
        criticity: 3,
        riskColor: "#fae875",
        opportunityColor: "#80d0ff",
    },
    {
        label: "High",
        value: "High",
        criticity: 2,
        riskColor: "#ffb26b",
        opportunityColor: "#52c0ff",
    },
    {
        label: "Very High",
        value: "Very High",
        criticity: 1,
        riskColor: "#e64959",
        opportunityColor: "#009cf5",
    },
];

//CRITICITY LIST
export const RISK_OPP_CRITICITY_LIST = [
    {
        max: 2,
        min: 1,
        // value: "Critical",
        value: "Minor",
        riskColor: "#e64959",
        opportunityColor: "#b8e5ff",
    },
    {
        max: 6,
        min: 3,
        // value: "Important",
        value: "Moderate",
        riskColor: "#ffb26b",
        opportunityColor: "#80d0ff",
    },
    {
        max: 12,
        min: 7,
        // value: "Moderate",
        value: "Important",
        riskColor: "#fae875",
        opportunityColor: "#52c0ff",
    },
    {
        max: 16,
        min: 13,
        //value: "Minor",
        value: "Critical",
        riskColor: "#a9ff8f",
        opportunityColor: "#009cf5",
    },
];

// ESCALATION FILTERS CONSTANTS
export const RO_HEADER_ESCALATION_TOGGLE_OPTIONS = ["Not escalated", "All", "Escalated"];

// ACTION OPTIONS
export const ACTION_OPTIONS = ["Delete", "Remove Link", "Link to other"];

// EXPAND MODES
export const RISK_OPP_EXPAND_MODES = {
    matrixOnly: "matrixOnly",
    tableOnly: "tableOnly",
    both: "both",
};

// MANDATORY  FIELDS
export const RISK_OPP_MANDATORY_FIELDS = [
    "name",
    "initialImpact",
    "initialProba",
    "targetProba",
    "targetImpact",
    "status",
    "strategy",
    "raisedDate",
];

// RISK/OPP GROUP ITEMS FOR EDIT SIDE BAR
export const RISK_OPP_GROUP_ITEMS = [
    // INITIAL ASSESSMENT
    { id: 1, name: "Initial Assessment", icon: "calculate" },
    // CURRENT ASSESSMENT
    { id: 2, name: "Current Assessment", icon: "calculate" },
    // TARGET ASSESSMENT
    { id: 3, name: "Target Assessment", icon: "calculate" },
    // GENERAL
    { id: 4, name: "General", icon: "source" },
    // // CONNECTIONS
    // {id:5, name: 'Connections', icon: 'add_link'},
];

// RISK/OPP STRUCTURE OF ITEM DATA
export const RISK_OPP_ITEM_DATA_STRUCTURE = [
    // ITEM IDS
    { groupId: -1, key: "id", value: null, type: "id" },
    { groupId: -1, key: "organizationId", value: "", type: "id" },
    { groupId: -1, key: "projectId", value: "", type: "id" },
    { groupId: -1, key: "wbsId", value: "", type: "id" },
    { groupId: -1, key: "displayId", value: "", type: "id" },

    // NO GROUP (name, responsible, status, strategy,  escalatedTo)

    // NAME
    {
        groupId: 0,
        key: "name",
        value: "",
        label: "Name",
        component: "input",
        type: "text",
        info: "",
        placeholder: "Enter a name",
        mandatory: true,
        rowWithNext: true,
        maxLength: 150,
    },

    // ESCALATED TO
    {
        groupId: 0,
        key: "escalatedTo",
        value: "",
        label: "Escalated",
        component: "rightButton",
        type: "boolean",
        info: "",
        mandatory: false,
        iconTrue: "link",
        iconFalse: "link_off",
        iconCss: "toggleIconRiskOppIcon",
        iconTooltipTrue: "Make it escalated",
        iconTooltipFalse: "Make it not escalated",
    },

    // RESPONSIBLE
    {
        groupId: 0,
        key: "responsible",
        value: "",
        label: "Responsible",
        component: "select",
        maxLength: 150,
        type: "list",
        options: [],
        info: "",
        placeholder: "Who's the responsible",
        mandatory: true,
    },
    // STATUS
    {
        groupId: 0,
        key: "status",
        value: "",
        label: "Status",
        component: "select",
        type: "list",
        options: RISKOPP_STATUS_LIST,
        info: "",
        placeholder: "Choose a status",
        mandatory: true,
    },

    // STRATEGY
    {
        groupId: 0,
        key: "strategy",
        value: "",
        label: "Strategy",
        component: "select",
        type: "list",
        options: [],
        info: "",
        placeholder: "Choose a strategy",
        mandatory: false,
    },

    // -----------------------------------------------------------

    // CALCULATIONS----------------------------------------------

    // INITIAL
    {
        groupId: 1,
        key: "initialImpact",
        value: "",
        label: "Initial Impact",
        component: "select",
        type: "list",
        info: "",
        placeholder: "Select the initial impact",
        mandatory: true,
        options: RISK_OPP_IMPACT_LIST,
    },
    {
        groupId: 1,
        key: "initialProba",
        value: "",
        label: "Initial Probability",
        component: "select",
        type: "list",
        info: "",
        placeholder: "Select the initial probability",
        mandatory: true,
        options: RISK_OPP_PROBABILITY_LIST,
    },

    // CURRENT
    {
        groupId: 2,
        key: "currentImpact",
        value: "",
        label: "Current Impact",
        component: "select",
        type: "list",
        info: "",
        placeholder: "Select the current impact",
        mandatory: false,
        options: RISK_OPP_IMPACT_LIST,
    },
    {
        groupId: 2,
        key: "currentProba",
        value: "",
        label: "Current Probability",
        component: "select",
        type: "list",
        info: "",
        placeholder: "Select the current probability",
        mandatory: false,
        options: RISK_OPP_PROBABILITY_LIST,
    },

    // TARGET
    {
        groupId: 3,
        key: "targetImpact",
        value: "",
        label: "Target Impact",
        component: "select",
        type: "list",
        info: "",
        placeholder: "Select the target impact",
        mandatory: false,
        options: RISK_OPP_IMPACT_LIST,
    },
    {
        groupId: 3,
        key: "targetProba",
        value: "",
        label: "Target Probability",
        component: "select",
        type: "list",
        info: "",
        placeholder: "Select the target probability",
        mandatory: false,
        options: RISK_OPP_PROBABILITY_LIST,
    },

    // -----------------------------------------------------------

    // GENERAL----------------------------------------------------

    // SHORT DESCRIPTION
    {
        groupId: 4,
        key: "longTitle",
        value: "",
        label: "Short Description",
        component: "input",
        type: "text",
        info: "",
        placeholder: "Enter the short description",
        mandatory: false,
    },
    //TODO CATEGORY
    // DESCRIPTION
    {
        groupId: 4,
        key: "description",
        value: "",
        label: "Description",
        component: "textArea",
        type: "richTextArea",
        info: "",
        placeholder: "Enter a description",
        mandatory: false,
    },
    // CAUSE
    {
        groupId: 4,
        key: "cause",
        value: "",
        label: "Cause",
        component: "input",
        type: "text",
        info: "",
        placeholder: "Enter the cause",
        mandatory: false,
    },
    // EFFECT
    {
        groupId: 4,
        key: "effect",
        value: "",
        label: "Effect",
        component: "input",
        type: "text",
        info: "",
        placeholder: "Enter the effect",
        mandatory: false,
    },

    // -----------------------------------------------------------

    // // STRATEGY
    // {groupId: 5, key: "strategy", value: "", label: "Strategy", component: "select", type: "list", options: [], info: "", placeholder: "Choose a strategy",mandatory: false,},
    // CONNECTIONS
    // {groupId: 3, key: 'actionsIds', value: '', label: "Action plan (NEEDS TO BE REVIEWED)", component:'multi_select', type: 'multi_select', info: '', placeholder: 'Connect actions', mandatory:false, options: []},
];

// MATRIX GROUPS
export const MATRIX_GROUPS = [
    {
        id: 3,
        riskColor: "#FEE990",
        opportunityColor: "#BADFFE",
    },
    {
        id: 2,
        riskColor: "#FFB999",
        opportunityColor: "#73C7F1",
    },
    {
        id: 1,
        riskColor: "#e55563",
        opportunityColor: "#00B0F0",
    },
];

// MUTATION QUERIES
export const RISK_OPPS_MUTATION_QUERIES = {
    create: createTelescopeDataRO,
    delete: deleteTelescopeDataRO,
    update: updateTelescopeDataRO,
};

// MATRIX SETTINGS

export const RISKS_OPPS_MATRIX_SETTINGS = [
    {
        impact: "Very High",
        probability: "Low",
        criticity: 5,
        groupCriticity: 3,
        riskColor: "#FEE990",
        opportunityColor: "#BADFFE",
    },
    {
        impact: "Very High",
        probability: "Possible",
        criticity: 8,
        groupCriticity: 2,
        riskColor: "#FEE990",
        opportunityColor: "#BADFFE",
    },
    {
        impact: "Very High",
        probability: "Likely",
        criticity: 12,
        groupCriticity: 1,
        riskColor: "#FFB999",
        opportunityColor: "#73C7F1",
    },
    {
        impact: "Very High",
        probability: "Nearly certain",
        criticity: 16,
        groupCriticity: 1,
        riskColor: "#e55563",
        opportunityColor: "#00B0F0",
    },
    {
        impact: "High",
        probability: "Low",
        criticity: 4,
        groupCriticity: 3,
        riskColor: "#D8FF7D",
        opportunityColor: "#E1F5FF",
    },
    {
        impact: "High",
        probability: "Possible",
        criticity: 7,
        groupCriticity: 2,
        riskColor: "#FEE990",
        opportunityColor: "#BADFFE",
    },
    {
        impact: "High",
        probability: "Likely",
        criticity: 11,
        groupCriticity: 2,
        riskColor: "#FFB999",
        opportunityColor: "#73C7F1",
    },
    {
        impact: "High",
        probability: "Nearly certain",
        criticity: 15,
        groupCriticity: 1,
        riskColor: "#e55563",
        opportunityColor: "#00B0F0",
    },
    {
        impact: "Medium",
        probability: "Low",
        criticity: 2,
        groupCriticity: 3,
        riskColor: "#D8FF7D",
        opportunityColor: "#E1F5FF",
    },
    {
        impact: "Medium",
        probability: "Possible",
        criticity: 6,
        groupCriticity: 3,
        riskColor: "#FEE990",
        opportunityColor: "#BADFFE",
    },
    {
        impact: "Medium",
        probability: "Likely",
        criticity: 10,
        groupCriticity: 2,
        riskColor: "#FFB999",
        opportunityColor: "#73C7F1",
    },
    {
        impact: "Medium",
        probability: "Nearly certain",
        criticity: 14,
        groupCriticity: 1,
        riskColor: "#FFB999",
        opportunityColor: "#73C7F1",
    },
    {
        impact: "Low",
        probability: "Low",
        criticity: 1,
        groupCriticity: 3,
        riskColor: "#D8FF7D",
        opportunityColor: "#E1F5FF",
    },
    {
        impact: "Low",
        probability: "Possible",
        criticity: 3,
        groupCriticity: 3,
        riskColor: "#D8FF7D",
        opportunityColor: "#E1F5FF",
    },
    {
        impact: "Low",
        probability: "Likely",
        criticity: 9,
        groupCriticity: 3,
        riskColor: "#FEE990",
        opportunityColor: "#BADFFE",
    },
    {
        impact: "Low",
        probability: "Nearly certain",
        criticity: 13,
        groupCriticity: 2,
        riskColor: "#FEE990",
        opportunityColor: "#BADFFE",
    },
];

// VIEW MODE OF RISK MATRIX

export const RISK_MATRIX_VIEW_MODE = {
    initial: { label: "Initial", value: "initial", backgroundColor: "var(--color-escalated)" },
    current: { label: "Current", value: "current", backgroundColor: "var(--color-migso-blue)" },
    target: { label: "Target", value: "target", backgroundColor: "var(--color-bad)" },
};

// SELECT MODE OF RISK VIEW MODE

export const RISK_VIEW_MODE_OPTIONS = [
    { key: "description", name: "Description" },
    { key: "comments", name: "Comments" },
    { key: "events", name: "Events" },
];

// TEXT CONVERSTION FOR EVENTS
export const RISK_OPP_TXT_CONVERSION = {
    escalatedTo: "Escalated",
    initialProba: "Initial Probability",
    initialImpact: "Initial Impact",
    initialCriticity: "Initial Criticity",
    currentProba: "Current Probability",
    currentImpact: "Current Impact",
    currentCriticity: "Current Criticity",
    targetProba: "Target Probability",
    targetImpact: "Target Impact",
    targetCriticity: "Target Criticity",
    endProba: "End Probability",
    endImpact: "End Impact",
    endCriticity: "End Criticity",
    schedule: "Schedule",
    cost: "Cost",
    performance: "Performance",
    name: "Name",
    actionsIds: "Related actions",
};

// TYPES OF OPERATIONS ON ACTIONS WHEN IN R&O
export const RISK_OPP_CHANGE_ACTIONS_TYPES = {
    edit: "edit",
    create: "create",
};

// ARRAY OF ITEMS TO MAP IN IMPACT RATING OF RISK CARD
export const RISK_OPP_CARD_IMPACT_MAP = ["Cost", "Schedule", "Scope", "Quality"];

export const RISK_OPP_CARD_IMPACT_VALUES = [
    "> 0% > 0€",
    "> 2% > 25K€",
    "> 5% > 50K€",
    "> 10% > 100K€",
];

// CONVERTION FOR CRITICITY RISK MINI COMPONENT
export const RISK_OPP_CRITICITY_TXT_CONVERTION = {
    veryHigh: "Critical",
    high: "Important",
    medium: "Moderate",
    low: "Minor",
};

// DEFAULT RISK OPP ITEM
export const RO_DEFAULT_ITEM = {
    // IDS
    id: null,
    organizationId: null,
    projectId: null,
    wbsId: null,
    displayId: null,

    // ESCALATION
    escalatedFrom: null,
    escalatedTo: null,

    // CALCULATIONS------

    // INITIAL
    initialProba: RISK_OPP_PROBABILITY_LIST[0].value, // LOW BY DEFAULT
    initialImpact: RISK_OPP_IMPACT_LIST[0].value, // LOW BY DEFAULT
    initialCriticity: null,
    // CURRENT
    currentProba: RISK_OPP_PROBABILITY_LIST[0].value, // LOW BY DEFAULT,
    currentImpact: RISK_OPP_IMPACT_LIST[0].value, // LOW BY DEFAULT,
    currentCriticity: null,
    // TARGET
    targetProba: RISK_OPP_PROBABILITY_LIST[0].value, // LOW BY DEFAULT
    targetImpact: RISK_OPP_IMPACT_LIST[0].value, // LOW BY DEFAULT,
    targetCriticity: null,
    // END
    endProba: null,
    endImpact: null,
    endCriticity: null,

    // INITIAL RATINGS
    initialSchedule: null,
    initialCost: null,
    initialScope: null,
    initialQuality: null,
    // CURRENT RATINGS
    currentSchedule: null,
    currentCost: null,
    currentScope: null,
    currentQuality: null,
    // TARGET RATINGS
    targetSchedule: null,
    targetCost: null,
    targetScope: null,
    targetQuality: null,
    // OTHERS
    performance: null,
    weightedCost: null,

    // ------------------

    // DATES
    createdAt: TODAY,
    // INFO------------
    name: null,
    longTitle: null,
    description: null,
    isRisk: null,
    category: null,
    cause: null,
    effect: null,
    responsible: null,
    // OPEN BY DEFAULT
    status: RISKOPP_STATUS_LIST[0].value,
    strategy: null,
    progressStatus: null,
    path: null,
    // INFO------------

    // SOCIALS---------
    comments: null,
    // ----------------

    // CONNECTIONS-----
    actionsIds: [],
    telescopeDataGovernanceTelescopeDataRoIds: null,
    // ----------------

    // LOGS
    events: null,
};

export const RO_DEFAULT_STATE = {
    // CONTENT THAT GOES INSIDE EDIT SIDE BAR
    editSideBarContent: null,
    // WHETHER THE EDIT SIDE BAR IS OPEN OR NOT
    editSideBar: false,
    // SELECTED RISKS FROM TABLE
    selected: [],
    // VIEW MODE
    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
    // EXPAND MODE
    expandMode: RISK_OPP_EXPAND_MODES.both,
    // RISK/OPP ITEM IN VIEW MODE (TODO LATER)
    riskOppViewItem: null,
    // TEXT SEARCH INPUT ON TABLE SEARCH BAR
    textSearchInput: "",
    // WHETHER SHOWING OPEN OR CLOSED RISK OPP ITEMS IN TABLE
    roStatusSearchFilter: [],

    // RISK SELECTED VIEW MODE
    riskSelectedViewMode: RISK_MATRIX_VIEW_MODE.current,

    // RISK VIEW MODE SELECTION MODE (BY DEFAULT IS DESCRIPTION)
    riskViewModeSelection: RISK_VIEW_MODE_OPTIONS[0].key,

    // IS ADD ACTION
    actionsOperationType: null,

    // detailed ro matrix item
    detailedRoMatrixItem: null,

    // PROP KEY INCREMENT
    propKeyIncrement: 0,

    // STATUS SEARCH INPUT (for actions table)
    statusSearchInput: [],

    // ACTIONS TEXT SEARCH INPUT
    actionsTextSearchInput: "",

    // VIEW OPEN ON THE RISK CARD MATRIX IMPACT CONTAINER
    riskCardImpactProbaOpenView: null,

    // IS CONSOLIDATED (TRUE == SHOWS BELONGING + CHILDRENS)
    isConsolidated: true,
    widthSizeLimit: false,
};
