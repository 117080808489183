import React, { memo, useCallback, useMemo, useState } from "react";

//LIBRARIES
import { v4 as uuid } from "uuid";
import _ from "lodash";

//CORE
import { CONVERT_NULL_TO_EMPTY_TXT } from "../../../00-Core/Standards";
import { CvtSideBarButton } from "@mi-gso/cvt";

//APP
import { mutateGraphql } from "../../00-App/02-Backend/AppBackendCommon";

//GRAPH QL
import {
    createTelescopeDataCharter as CreateTelescopeDataCharter,
    updateTelescopeDataCharter as UpdateTelescopeDataCharter,
} from "../../../graphql/mutations";

//WBS
import WbsElementBigStructure from "../00-Wbs/01-Components/WbsElementBigStructure";
import WbsElementBigSideBar from "../00-Wbs/01-Components/WbsElementBigSideBar";

// CHARTER
import {
    CHARTER_GROUP_ITEMS,
    CHARTER_INIT_STATE,
    CHARTER_ITEM_DATA,
} from "./00-Helpers/CharterConstants";
import CharterBigCard from "./01-Components/CharterBigCard";
import WbsElementStructureBody from "../00-Wbs/01-Components/WbsElementStructureBody";
import CharterBigProjectDetails from "./01-Components/CharterBigProjectDetails";

// EDIT SIDE BAR
import { EDIT_SIDE_BAR_DATA_PREPARATION } from "../07-EditSideBar/00-Helpers/EditSideBarFunctions";
import EditSideBar from "../07-EditSideBar/EditSideBar";
import CharterBigDetailsCat from "./01-Components/CharterBigDetailsCat";
import { CHARTER_FUNC_CATEGORIZATION } from "./00-Helpers/CharterFunctions";
import 'react-tooltip/dist/react-tooltip.css';


/////////////////////////////
/// CHARTER BIG COMPONENT ///
/////////////////////////////

export default memo(function CharterBig({
    data,
    itemId,
    wbsId,
    wbsName,
    appDispatcher,
    bigComponentExpandFunction,
    bigComponentIsExpanded,
    selectedOrganizationId,
    projectId,
    selectedProject,
    usersList,
    megaUsersIds,
    securityGroup,
    handleBigComponent,
    fieldInformation,
    currentUser,
    updateTracker,
}) {
    //////////////////////////////////////////////////
    /// STATES ///////////////////////////////////////
    //////////////////////////////////////////////////

    //State to manage modal mode (display or edit)
    const [state, setState] = useState({
        richContent: null,
        editSideBar: false,
        flipCard: false,
    });

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // CHARTER DATA
    const charterData = useMemo(() => {
        if (updateTracker) {
            // JUST TO TRIGGER MEMO IN CASE DATA IS UPDATED
        }

        //GET DATA FILTERED FROM WBSID
        let wbsData = data.filter((charter) => charter.wbsId === itemId);

        //GET THE FIRST
        if (wbsData.length > 0) {
            return CONVERT_NULL_TO_EMPTY_TXT(wbsData[0]);
        } else {
            return CHARTER_INIT_STATE;
        }
    }, [data, itemId, updateTracker]);

    ////////////////////////////////////////////////////////////////////////////
    /// FUNCTIONS //////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////

    //DISPLAY EDIT SIDE BAR ----------------------------------------------------
    const onEditSideBar = useCallback(() => {
        //INJECT DATA INTO STANDARD ITEM DATA FOR EDITION
        let richContent = EDIT_SIDE_BAR_DATA_PREPARATION(
            charterData ? _.cloneDeep(charterData) : null,
            CHARTER_ITEM_DATA,
            {
                id: charterData.id ? charterData.id : uuid(),
                organizationId: selectedOrganizationId,
                projectId: projectId,
                wbsId: wbsId,
            },
            [{ key: "leader", value: usersList }]
        );

        //PASS RISH CONTENT
        setState((prevState) => {
            return {
                ...prevState,
                richContent: richContent,
                editSideBar: true,
                propKeyIncrement:0,
            };
        });
    }, [charterData, projectId, selectedOrganizationId, usersList, wbsId]);

    //RESET SIDE BAR -----------------------------------------------------------
    const resetEditSideBar = useCallback((e) => {
        e.preventDefault();
        setState((prevState) => {
            return {
                ...prevState,
                editSideBar: false,
            };
        });
    }, []);

    //ON SAVE -------------------------------------------------------------------
    const onSave = useCallback((e, mutateObject) => {
        e.preventDefault();

        ////////////////////////////////
        /// CALCULTAE CATEGORIZATION ///
        ////////////////////////////////

        let categorization = null;

        //INIT
        let newData = _.cloneDeep({ ...charterData, ...mutateObject });

        //TETS DATE
        if (
            newData.startDate !== "" &&
            Object.prototype.toString.call(newData.startDate) !== "[object Date]"
        ) {
            newData.startDate = new Date(newData.startDate);
        }

        //TEST DATE
        if (
            newData.endDate !== "" &&
            Object.prototype.toString.call(newData.endDate) !== "[object Date]"
        ) {
            newData.endDate = new Date(newData.endDate);
        }

        if (
            (newData.startDate !== "" && isNaN(newData.startDate.getTime())) ||
            (newData.endDate !== "" && isNaN(newData.endDate.getTime()))
        ) {
            return null;
        }

        //IF DATA AVAILABLE
        if (
            newData &&
            newData.impact &&
            newData.teamSize &&
            newData.teamSize !== "" &&
            newData.teamSize > 0 &&
            newData.startDate &&
            newData.endDate &&
            newData.stakeholderSize &&
            newData.stakeholderSize !== "" &&
            newData.stakeholderSize > 0 &&
            newData.budget &&
            newData.budget !== "" &&
            newData.budget > 0
        ) {
            //CALCULATE CATEGORY
            categorization = CHARTER_FUNC_CATEGORIZATION({
                dates: {
                    startDate: newData.startDate,
                    endDate: newData.endDate,
                },
                inputs: {
                    budget: newData.budget,
                    stakeholders: newData.stakeholderSize + newData.teamSize,
                    impact: newData.impact,
                },
            });
        }

        //
        let categorizationId = categorization ? categorization.id : null;

        //INIT
        let graphQLQuery;
        let dispatcherQuery;
        let updatedObject = {};

        //TEST IF CREATE OR NOT
        if (charterData.id) {
            graphQLQuery = UpdateTelescopeDataCharter;
            dispatcherQuery = "SET_PROJECT_DATA_UPDATE";
            updatedObject = {
                ...mutateObject,
                updatedAt: new Date(),
                category: categorizationId,
            };

            //CREATE ADD ALL NEEDED INFO
        } else {
            graphQLQuery = CreateTelescopeDataCharter;
            dispatcherQuery = "SET_PROJECT_DATA_CREATE";
            updatedObject = {
                ...charterData,
                ...mutateObject,
                updatedAt: new Date(),
                category: categorizationId,
            };
        }

        // UPDATE BACKEND -------------------------------
        mutateGraphql(
            graphQLQuery,
            {
                ...mutateObject,
                groupEditors: securityGroup.groupEditors,
                groupViewers: securityGroup.groupViewers,
                category: categorizationId,
            },
            appDispatcher
        );

        //UPDATE OR CREATE APP STATE ---------------------
        appDispatcher({
            type: dispatcherQuery,
            source: "charter",
            objects: [updatedObject],
        });

        // //THIS STATE FOR NO RENDER -----------------------
        setState((prevState) => {
            //UPDATE STATE
            return {
                ...prevState,
                richContent: null,
                editSideBar: false,
                propKeyIncrement: state.propKeyIncrement + 1,
            };
        });
    },[charterData, securityGroup.groupEditors, securityGroup.groupViewers, appDispatcher, state.propKeyIncrement]);

    //SWITCH CARD IN BIG MINI VIEW -------------------------------------------
    const switchCard = useCallback(() => {
        setState((prevState) => {
            //UPDATE StATE
            return {
                ...prevState,
                flipCard: !prevState.flipCard,
            };
        });
    }, []);

    //////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////
    //////////////////////////////////////////////////

    return (
        
        <React.Fragment>
            {/* BIG COMPONENT STANDARD STRUCTURE */}
            <WbsElementBigStructure
                fieldInformation={fieldInformation}
                handleBigComponent={handleBigComponent}
                projectTitle={wbsName}
                buttonExpand={true}
                isExpendedEnabled={bigComponentIsExpanded}
                functionExpand={bigComponentExpandFunction}
                tooltipInfo="pass component to display updated At and By"
            >
                {/* SIDE BAR ------------------------------------------ */}
                {securityGroup.isCurrentUserEditor ? (
                    <WbsElementBigSideBar>

                        {/* EDIT CHARTER */}
                        <CvtSideBarButton
                            buttonClass="flexCenterCenter buttonSideBar "
                            id="tooltipEditBig"
                            icon="edit"
                            fontSize="22px"
                            title="Edit Charter"
                            onClickFunction={onEditSideBar}
                        />
                    </WbsElementBigSideBar>
                ) : null}

                {/* BIG COMPONENT BODY ---------------------------------*/}
                <WbsElementStructureBody isExpendedEnabled={bigComponentIsExpanded}>
                    {/* CARD CAN FLIP */}
                    <div
                        className="charterFlipCard"
                        style={{
                            minWidth: "450px",
                            width: "40%",
                        }}
                    >
                        {/* FILP BLOCK */}
                        <div
                            className="charterFlipCardInner"
                            style={{
                                transform: state.flipCard ? "rotateY(180deg)" : null,
                            }}
                        >
                            {/* CHARTER CARD */}

                                
                            <CharterBigCard
                                content={charterData}
                                projectId={projectId}
                                switchCard={switchCard}
                                flipCard={state.flipCard}
                                updateTracker={updateTracker}
                                data-tip="target-tooltip"
                            />

                            {/* CALCULATION DETAILS */}
                            <CharterBigDetailsCat
                                switchCard={switchCard}
                                flipCard={state.flipCard}
                                charterData={charterData}
                                updateTracker={updateTracker}
                            />
                        </div>
                    </div>

                    {/* PROJECT DETAILS */}
                    <CharterBigProjectDetails
                        selectedProject={selectedProject}
                        content={charterData}
                        usersList={usersList}
                        megaUsersIds={megaUsersIds}
                        bigComponentIsExpanded={bigComponentIsExpanded}
                        key={state.propKeyIncrement}
                    />
                </WbsElementStructureBody>
            </WbsElementBigStructure>

            {/* EDIT RIGHT SIDE BAR ----------------------------------- */}
            <EditSideBar
                display={state.editSideBar}
                content={state.richContent}
                title="Edit Charter"
                group={CHARTER_GROUP_ITEMS}
                onSave={onSave}
                resetEditSideBar={resetEditSideBar}
                currentUser={currentUser}
            />
        </React.Fragment>
    );
});
