import _ from 'lodash'
import React, { useMemo } from 'react'

//COMPONENT
import WbsElement from './WbsElement'
import WbsElementBig from './WbsElementBig'

//////////////////////////////
/// MINI WRAPPER COMPONENT ///
//////////////////////////////

//COMPONENT
export default function WbsElementProject({
    item,
    projectData,
    globalProjectData,
    projectToRemove,
    selectedWbsId,
    selectedField,
    handleBigComponent,
    coreSelection,
    usersList,
    projectLeaderList,
    currentUser,
    wbsSettings,
    wbsDispatcher,
    appDispatcher,
    isShortcutBigOpen,
    isReadOnlyMode,
    isPortfolioSelected,
    selectedProjectId,
    isReviewMode,
    handleFullScreen,
    isFullScreen,
    wbsPosition,
    dataConsolidation,
    sideBarOpenWidth,
    oppIndex,
    moveModalOptionsItemsArray,
    updateTrackers,
    referenceCreateUpdateObject,
    rightComponentData,
    wbsNavigationOptions,
    listOfForceOpenWbs,
    projectChildIds,
    selectedItemId,
    selectedReviewItem,
}) {
 
    //MANAGE PROJECT DATA
    const projectDataWithCharterFilters = useMemo(() => {

        //ONLY IF PORTFOLIO AND PROJECT DATA TO REMOVE (FROM FILTERS CHARTER SECTION)
        if((isPortfolioSelected && projectToRemove && projectToRemove.length > 0) ){

            //MANAGE DATA
            let data = _.cloneDeep(projectData)

            //GET ATTRIBUTE
            let projectDataAttributes = Object.keys(data);

            //LOOP
            for (let i = 0; i < projectDataAttributes.length; i++){

                //EXCLUDED IF PROJECT ID NOT INCLUDED INTO ITEM
                let filteredDomainData = data[projectDataAttributes[i]].filter(item => {

                    //NO
                    if(projectToRemove && projectToRemove.length > 0 && projectToRemove?.includes(item.projectId)){
                        return false
                    }

                    //KEEP IT
                    return true
                })

                //UPDATE DATA
                data[projectDataAttributes[i]] = filteredDomainData
            }

            //RETURN UPDATED DATA
            return data

        //RETURN NORMAL
        }else if(isPortfolioSelected && !dataConsolidation){

            //INIT
            let data = {};

            //GET ATTRIBUTE
            let projectDataAttributes = Object.keys(projectData);

            //LOOP
            for (let i = 0; i < projectDataAttributes.length; i++){

                //UPDATE DATA
                data[projectDataAttributes[i]] = []
            }

            //RETURN UPDATED DATA
            return data

        //NORMAL
        }else{
            return projectData
        }
    }, [dataConsolidation, isPortfolioSelected, projectData, projectToRemove])

    //MANAGE PROJECT WITH CONSO
    const projectDataWithConsolidation = useMemo(() => {

        //TEST IF WE NEED TO NOT CONSO THE PROJECT LEVEL
        if(selectedProjectId && !dataConsolidation){
            
            //MANAGE DATA
            let data = _.cloneDeep(projectDataWithCharterFilters)

            //GET ATTRIBUTE
            let projectDataAttributes = Object.keys(data);

            //LOOP
            for (let i = 0; i < projectDataAttributes.length; i++){

                //EXCLUDED IF PROJECT ID NOT INCLUDED INTO ITEM
                let filteredDomainData = data[projectDataAttributes[i]].filter(item => {

                    //SPECIFIC FOR RISK ESCALATED ITEM
                    if(projectDataAttributes[i] === "riskOpp"){

                        //CHECK THAT THE RISK OPP IS ESCALATED
                        if(item.escalatedTo && item.escalatedTo.length > 0){

                            //TRY IF THE WBSIDS IS INSIDE ESCALATED TO
                            if(item.escalatedTo?.includes(selectedProjectId)){
                                return true
                            }
                        }
                    }

                    //NO
                    if(selectedProjectId !== item.wbsId){
                        return false
                    }

                    //KEEP IT
                    return true
                })

                //UPDATE DATA
                data[projectDataAttributes[i]] = filteredDomainData
            }

            //RETURN UPDATED DATA
            return data

        //NORMAL
        }else{
            return projectDataWithCharterFilters
        }

    }, [dataConsolidation, projectDataWithCharterFilters, selectedProjectId])

    /////////////////////////////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>

            {/* WBS ROW COMPONENT => INCLUDE ALL MINI COMPONENT ----------- */}
            <WbsElement
                item={item}
                itemLevel={0}
                isProjectElement
                isPortfolioSelected={isPortfolioSelected}
                dataConsolidation={dataConsolidation}
                selectedWbsId={selectedWbsId}
                selectedField={selectedField}
                projectData={projectDataWithConsolidation}
                handleBigComponent={handleBigComponent}
                currentUser={currentUser}
                wbsProjectData={projectDataWithConsolidation}
                wbsDispatcher={wbsDispatcher}
                wbsSettings={wbsSettings}
                isShortcutBigOpen={isShortcutBigOpen}
                isReviewMode={isReviewMode}
                oppIndex={oppIndex}
                updateTrackers={updateTrackers}
                listOfForceOpenWbs={listOfForceOpenWbs}
                wbsNavigationOptions={wbsNavigationOptions}
            />

            {/* BIG COMPONENT ---------------------------------------------- */}
            {item.id === selectedWbsId ? 
                <WbsElementBig 
                    item={item}
                    isProjectElement
                    coreSelection={coreSelection}
                    selectedWbsId={item.id}
                    selectedWbsName={item.name}
                    selectedField={selectedField}
                    selectedItemId={selectedItemId}
                    projectData={projectDataWithConsolidation}
                    globalProjectData={globalProjectData}
                    usersList={usersList}
                    projectLeaderList={projectLeaderList}
                    currentUser={currentUser}
                    wbsProjectData={projectDataWithConsolidation}
                    wbsSettings={wbsSettings}
                    handleBigComponent={handleBigComponent}
                    wbsDispatcher={wbsDispatcher}
                    appDispatcher={appDispatcher}
                    isShortcutBigOpen={isShortcutBigOpen}
                    isReadOnlyMode={isReadOnlyMode}
                    selectedProjectId={selectedProjectId}
                    isReviewMode={isReviewMode}
                    isPortfolioLine={isPortfolioSelected}
                    handleFullScreen={handleFullScreen}
                    isFullScreen={isFullScreen}
                    wbsPosition={wbsPosition}
                    sideBarOpenWidth={sideBarOpenWidth}
                    oppIndex={oppIndex}
                    moveModalOptionsItemsArray={moveModalOptionsItemsArray}
                    updateTrackers={updateTrackers}
                    referenceCreateUpdateObject={referenceCreateUpdateObject}
                    rightComponentData={rightComponentData}
                    wbsNavigationOptions={wbsNavigationOptions}
                    projectChildIds={projectChildIds}
                    selectedReviewItem={selectedReviewItem}
                />
            :null}
        </React.Fragment>
    )
}
