//IMPORT ALL PICTURES
import whitePaper from '../../00-Core/02-Pictures/whitePaper.jpg'
import selectTemplate from '../../00-Core/02-Pictures/selectTemplate.jpg'
import automaticView from '../../00-Core/02-Pictures/automaticView.jpg'
import digitalTranformation from '../../00-Core/02-Pictures/digitalTranformation.png'
import documentation from '../../00-Core/02-Pictures/documentation.jpg'
import exportData from '../../00-Core/02-Pictures/exportData.png'
import exportPicture from '../../00-Core/02-Pictures/exportPicture.png'
import exportPdf from '../../00-Core/02-Pictures/exportPdf.png'
import customerPain from '../../00-Core/02-Pictures/customerPain.jpg'
import createAutomaticView from '../../00-Core/02-Pictures/createAutomaticView.png'
import createRoadmapFromWhitePaper from '../../00-Core/02-Pictures/createRoadmapFromWhitePaper.png'
import mappingDragAndDrop from '../../00-Core/02-Pictures/mappingDragAndDrop.png'
import mappingMatchTypes from '../../00-Core/02-Pictures/mappingMatchTypes.png'
import mappingDescription from '../../00-Core/02-Pictures/picMappingDescription.png'
import selectWayOfImportData from '../../00-Core/02-Pictures/selectWayOfImportData.png'
import switchToReferenceDatabase from '../../00-Core/02-Pictures/switchToReferenceDatabase.png'
import logoDefault from '../../00-Core/02-Pictures/logoDefault.png'
import ssoLogoDefault from '../../00-Core/02-Pictures/ssoLogoDefault.png'
import telescopeLogo from '../../00-Core/02-Pictures/telescopeLogo.svg'
import telescopeLogoIcon from '../../00-Core/02-Pictures/telescopeLogoIcon.svg'
import triangleStep1 from '../../00-Core/02-Pictures/triangleStep1.svg'
import triangleStep2 from '../../00-Core/02-Pictures/triangleStep2.svg'
import triangleStep3 from '../../00-Core/02-Pictures/triangleStep3.svg'
import leafTriangleIcon from '../../00-Core/02-Pictures/leafTriangleIcon.svg'
import lockKeyIcon from '../../00-Core/02-Pictures/lockKeyIcon.svg'
import treesIcon from '../../00-Core/02-Pictures/treesIcon.svg'
import cvtTriangleIcon from '../../00-Core/02-Pictures/cvtTriangle.svg'
import telescopeNutshell from '../../00-Core/02-Pictures/telescopeNutshell.svg'
import telescopeStep1 from '../../00-Core/02-Pictures/telescopeStep1.svg'
import telescopeStep2 from '../../00-Core/02-Pictures/telescopeStep2.svg'
import telescopeStep3 from '../../00-Core/02-Pictures/telescopeStep3.svg'
import telescopeLogoName from '../../00-Core/02-Pictures/telescopeNameLogoWhiteRegular.svg'
import bigTriangles from '../../00-Core/02-Pictures/bigTriangles.svg'

//DOCUMENTATION PICTURES
//---Pictures for CONTROLS
import TELProjectCharter1 from '../../00-Core/02-Pictures/TELProjectCharter1.png'
import TELProjectCharter2 from '../../00-Core/02-Pictures/TELProjectCharter2.png'
import TELActions1 from '../../00-Core/02-Pictures/TELActions1.png'
import TELActions2 from '../../00-Core/02-Pictures/TELActions2.png'
import TELActions3 from '../../00-Core/02-Pictures/TELActions3.png'
import TELRisknOps1 from '../../00-Core/02-Pictures/TELriskNOps1.png'
import TELRisknOps2 from '../../00-Core/02-Pictures/TELriskNOps2.png'
import TELRisknOps3 from '../../00-Core/02-Pictures/TELriskNOps3.png'
import TELRisknOps4 from '../../00-Core/02-Pictures/TELriskNOps4.png'
import TELRisknOps5 from '../../00-Core/02-Pictures/TELriskNOps5.png'
import TELRisknOps6 from '../../00-Core/02-Pictures/TELriskNOps6.png'
import TELSchedule1 from '../../00-Core/02-Pictures/TELSchedule1.png'
import TELSchedule2 from '../../00-Core/02-Pictures/TELSchedule2.png'
import TELSchedule3 from '../../00-Core/02-Pictures/TELSchedule3.png'
import TELGov1 from '../../00-Core/02-Pictures/TELGov1.png'
import TELGov2 from '../../00-Core/02-Pictures/TELGov2.png'
import TELGov3 from '../../00-Core/02-Pictures/TELGov3.png'
import TELGov4 from '../../00-Core/02-Pictures/TELGov4.png'
import TELShortcuts1 from '../../00-Core/02-Pictures/TELShortcuts1.png'
import TELShortcuts2 from '../../00-Core/02-Pictures/TELShortcuts2.png'
import TELGenSideBar1 from '../../00-Core/02-Pictures/TELGenSideBar1.png'
import TELGenSideBar2 from '../../00-Core/02-Pictures/TELGenSideBar2.png'
import TELGenSideBar3 from '../../00-Core/02-Pictures/TELGenSideBar3.png'
import TELGenSideBarTable from '../../00-Core/02-Pictures/TELGenSideBarTable.png'
import TELFocDay1 from '../../00-Core/02-Pictures/TELFocDay1.png'
import TELFocDay2 from '../../00-Core/02-Pictures/TELFocDay2.png'
import TELBrowsing1 from '../../00-Core/02-Pictures/TELBrowsing1.png'
import TELBrowsing2 from '../../00-Core/02-Pictures/TELBrowsing2.png'
import TELBrowsing3 from '../../00-Core/02-Pictures/TELBrowsing3.png'
import TELBrowsing4 from '../../00-Core/02-Pictures/TELBrowsing4.png'
import TELBrowsing5 from '../../00-Core/02-Pictures/TELBrowsing5.png'


//EXPORT ALL PICTURE TO BE ABLE EASLY TO GET THEM {}
export const picWhitePaper = whitePaper;
export const picSelectTemplate = selectTemplate;
export const picAutomaticView = automaticView;
export const picDigitalTransformation = digitalTranformation;
export const picDocumentation = documentation;
export const picExportData = exportData;
export const picExportPicture = exportPicture;
export const picExportPdf = exportPdf;
export const picCustomerPain = customerPain;
export const picMappingDescription = mappingDescription;
export const picCreateAutomaticView = createAutomaticView;
export const picCreateRoadmapFromWhitePaper = createRoadmapFromWhitePaper;
export const picMappingDragAndDrop = mappingDragAndDrop;
export const picMappingMatchTypes = mappingMatchTypes;
export const picSelectWayOfImportData = selectWayOfImportData;
export const picSwitchToReferenceDatabase = switchToReferenceDatabase;
export const piclogoDefault = logoDefault;
export const picSsoLogoDefault = ssoLogoDefault;
export const picTelescopeLogo = telescopeLogo;
export const picTelescopeLogoIcon = telescopeLogoIcon;
export const pictriangleStep1 = triangleStep1;
export const pictriangleStep2 = triangleStep2;
export const pictriangleStep3 = triangleStep3;
export const picLeafTriangleIcon = leafTriangleIcon;
export const piclockKeyIcon = lockKeyIcon;
export const picTreesIcon = treesIcon;
export const picCvtTriangleIcon = cvtTriangleIcon;
export const picTelescopeNutshell = telescopeNutshell;
export const picTelescopeStep1 = telescopeStep1;
export const picTelescopeStep2 = telescopeStep2;
export const picTelescopeStep3 = telescopeStep3;
export const picTelescopeLogoName = telescopeLogoName;
export const picBigTriangles = bigTriangles;

//DOC export
export const picTELProjectCharter1 = TELProjectCharter1;
export const picTELProjectCharter2 = TELProjectCharter2;
export const picTelActions1 = TELActions1;
export const picTelActions2 = TELActions2;
export const picTelActions3 = TELActions3;
export const picTELRisknOps1 = TELRisknOps1;
export const picTELRisknOps2 = TELRisknOps2;
export const picTELRisknOps3 = TELRisknOps3;
export const picTELRisknOps4 = TELRisknOps4;
export const picTELRisknOps5 = TELRisknOps5;
export const picTELRisknOps6 = TELRisknOps6;
export const picTELSchedule1 = TELSchedule1;
export const picTELSchedule2 = TELSchedule2;
export const picTELSchedule3 = TELSchedule3;
export const picTELGov1 = TELGov1;
export const picTELGov2 = TELGov2;
export const picTELGov3 = TELGov3;
export const picTELGov4 = TELGov4;
export const picTELShortcuts1 = TELShortcuts1;
export const picTELShortcuts2 = TELShortcuts2;

export const picTELGenSideBar1 = TELGenSideBar1;
export const picTELGenSideBar2 = TELGenSideBar2;
export const picTELGenSideBar3 = TELGenSideBar3;
export const picTELGenSideBarTable = TELGenSideBarTable;
export const picTelFocDay1 = TELFocDay1;
export const picTelFocDay2 = TELFocDay2;
export const picTELBrowsing1 = TELBrowsing1;
export const picTELBrowsing2 = TELBrowsing2;
export const picTELBrowsing3 = TELBrowsing3;
export const picTELBrowsing4 = TELBrowsing4;
export const picTELBrowsing5 = TELBrowsing5;

