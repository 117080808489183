import React from 'react'

//CORE
import {APP_WBS_STRUCTURE} from '../../../../00-Core/Constants'

//////////////////////////////
/// MINI WRAPPER COMPONENT ///
//////////////////////////////

//COMPONENT
export default function WbsElementMini({
    propKey,
    itemId,
    fieldId,
    handleBigComponent,
    bigComponentDisplayed,
    children
}) {
    
    return (
        <div
            key={propKey}
            className='structureBlockTitleCell flexCenterCenter'
            style={{
                minWidth:APP_WBS_STRUCTURE.minCellWidth + "px",
                padding:"10px",
            }}
        >
            {/* VALUE */}
            <div
                onClick={(e) => handleBigComponent(e,itemId,fieldId)}
                style={{cursor:"pointer"}}
            >
                {children}
            </div>

            {/* ARROW IF NEEDED */}
            {bigComponentDisplayed ? 
                <div className="keyboard_arrow_down_container flexCenterCenter">
                    <div className="arrow_down"></div>
                </div>
            :null}


        </div>
    )
}
