import { CvtAvatar, CvtTooltip } from '@mi-gso/cvt'
import _ from 'lodash'
import React, { memo, useCallback, useMemo } from 'react'

//STYLES
import './FiltersTagSection.styles.css'

import {
	FUNCT_FIND_INDEX,
	FUNCT_FIND_INDEX_ARRAY,
} from '../../../../../00-Core/Standards'
import { WBS_DOMAIN_DETAILS } from '../../../00-Wbs/00-Helpers/WbsConstants'
import { FILTERS_DOMAIN_INFO } from '../../00-Helpers/FiltersConstants'

//FILTER TAG SECTION COMPONENT
export default memo(function FiltersTagSection({
	wbsFilters,
	usersList,
	appDispatcher,
}) {
	// HANDLE CLEAR ALL FILTERS ----------------------------------------
	const handleClearAllFilters = useCallback(() => {
		appDispatcher({
			type: 'SET_KEY_VALUE',
			key: 'wbsFilters',
			value: [],
		})
	}, [appDispatcher])

	// HANDLE DELETE FILTER -------------------------------------------
	const handleRemoveFilterItem = useCallback(
		(e, domain, subDomain, value, isDate) => {
			e.preventDefault()

			let findIndex = FUNCT_FIND_INDEX(wbsFilters, 'key', domain + subDomain)
			if (findIndex > -1) {
				//GET VALUE
				let wbsFiltersValues = wbsFilters[findIndex].value
				//TEST IF SPECIFIC TO A DATE RANGE
				if (isDate && wbsFiltersValues.length > 1) {
					//FIND CORRECT INDEX
					let findDateIndex = FUNCT_FIND_INDEX_ARRAY(wbsFiltersValues, value)
					if (findDateIndex === 0) {
						wbsFiltersValues[0] = undefined
					}
				}

				// CHECK IF OBJECT
				if (typeof value === 'object') {
					wbsFiltersValues = wbsFiltersValues.filter(
						(filterValue) => filterValue.value !== value.value
					)
				} else {
					wbsFiltersValues = wbsFiltersValues.filter(
						(filterValue) => filterValue !== value
					)
				}

				//APP STATE
				appDispatcher({
					type: 'SET_WBS_FILTER_RESET_ITEM',
					domain: domain,
					subDomain: subDomain,
					value: wbsFiltersValues,
				})
			}
		},
		[appDispatcher, wbsFilters]
	)

	// HANDLE DATE/NUMBER CHANGE -------------------------------------------
	const handleFilterResetSubDomain = useCallback(
		(e, key) => {
			e.preventDefault()

			// SEND ACTION TO APP DISPATCHER
			appDispatcher({
				type: 'SET_WBS_FILTER_RESET_SUBDOMAIN',
				key: key,
			})
		},
		[appDispatcher]
	)

	//IN CASE OF ONLY START DATE FOR A RANGE ACTIVATED
	const displayResetFilters = useMemo(() => {
		//INIT
		let test = true

		//TEST IF ONLY ONE ACTIVE FILTER
		if (wbsFilters && wbsFilters.length === 1) {
			//GET DETAILS
			let currentSubDomainInfoIndex = FUNCT_FIND_INDEX(
				FILTERS_DOMAIN_INFO[wbsFilters[0].domain],
				'subDomain',
				wbsFilters[0].subDomain
			)

			//IF DATE SORT
			let isDate =
				FILTERS_DOMAIN_INFO[wbsFilters[0].domain][currentSubDomainInfoIndex]
					.type === 'date'

			//TEST IF DATE
			if (isDate && wbsFilters[0].value.length > 1 && !wbsFilters[0].value[0]) {
				test = false
			}
		}

		//RETURN
		return test
	}, [wbsFilters])

	//////////////////////////////////////////////////////////////////////////
	/// RENDER ONLY IF TAGS //////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////

	if (wbsFilters && wbsFilters.length > 0) {
		//RETURN
		return (
			<div
				id='wbsTopBlock'
				className='filterSection'
				style={{
					marginBottom: !displayResetFilters ? '0px' : null,
				}}>
				{/* CLEAR ALL */}
				{displayResetFilters ? (
					<div
						className='material-icons filterSectionClearIcon'
						onClick={handleClearAllFilters}>
						filter_alt_off
					</div>
				) : null}
				<div className='filterSectionFilters'>
					{/* FILTER MAP */}
					{Object.keys(FILTERS_DOMAIN_INFO).map((domain) => {
						//GET IF FILTER ON
						let wbsDomainFilters = wbsFilters.filter(
							(item) => item.domain === domain
						)

						//NO FILTER ON THIS DOMAIN
						if (wbsDomainFilters.length === 0) {
							return []

							//OK DISPLAY SUB DOMAIN
						} else {
							//LOOP ON SUB DOMAIN
							return wbsDomainFilters.map((subDomain) => {
								//DETAIL OF GROUP
								let currentGroupIndex = FUNCT_FIND_INDEX(
									WBS_DOMAIN_DETAILS,
									'id',
									subDomain.domain
								)
								let currentSubDomainInfoIndex = FUNCT_FIND_INDEX(
									FILTERS_DOMAIN_INFO[domain],
									'subDomain',
									subDomain.subDomain
								)

								//VALUE CLONE
								let subDomainValue = _.cloneDeep(subDomain.value)

								//IF DATE SORT
								let isDate =
									FILTERS_DOMAIN_INFO[domain][currentSubDomainInfoIndex]
										.type === 'date'
								if (isDate) {
									//END DATE INVALID DO NOT DISPLAY
									if (subDomainValue.length > 1 && !subDomainValue[0]) {
										return []
									}

									//OK SORT AND CONTINUE
									subDomainValue.sort()
								}

								//RETURN
								return (
									<div
										key={'wbsFilterSubgroup-' + subDomain.subDomain}
										className='filterSectionTagBlock flexStartCenter'>
										{/* SUBGROUP TITLE */}
										<div className='filterSectionSubGroup flexStartCenter'>
											{/* GROUP ICON */}
											<span className='material-icons rightSideBarGroupButtonIcon'>
												{WBS_DOMAIN_DETAILS[currentGroupIndex].icon}
											</span>

											{/* SUBGROUP TITLE */}
											{
												FILTERS_DOMAIN_INFO[domain][currentSubDomainInfoIndex]
													.name
											}
										</div>

										{/* TAGS */}
										{subDomainValue.map((value, index) => {
											//SET UNIQUE ID FOR TOOLTIP
											const uniqueId = _.uniqueId('wbsFilterSubGroupTag-')

											//GET CURRENT USER IF OWNER
											let currentUser = null
											if (subDomain.subDomain === 'owner') {
												currentUser = usersList.find(
													(user) => user.value === value
												)
											}

											//MANAGE DATE FORMAT
											let dateLocalTxt = null
											let displayArrow = false
											if (isDate) {
												//NEW DATE
												let newDate = new Date(value)

												//RETURN GOOD FORMAT
												dateLocalTxt = newDate.toLocaleString(undefined, {
													year: 'numeric',
													month: '2-digit',
													day: '2-digit',
												})

												//IF FIRST DATE DISPLAY ARROW
												if (index === 0 && subDomainValue.length > 1) {
													displayArrow = true
												}
											}

											//GET GOOD VALUE FOR CHECKBOX
											let valueLabel =
												typeof value === 'string' ? value : value.label
											if (
												FILTERS_DOMAIN_INFO[domain][currentSubDomainInfoIndex]
													.type === 'checkbox' &&
												subDomain.subDomain !== 'owner'
											) {
												//GET OPTIONS
												let subDomainOptions =
													FILTERS_DOMAIN_INFO[domain][currentSubDomainInfoIndex]
														.options

												//TEST IN CASE THERE IS AN OPTION
												if (subDomainOptions) {
													//FIND INDEX
													let findOptionIndex = FUNCT_FIND_INDEX(
														subDomainOptions,
														'id',
														value
													)

													//CHECK IF IT4S GOOD
													if (findOptionIndex > -1) {
														valueLabel = subDomainOptions[findOptionIndex].name
													}
												}
											}

											//IF NOT RETURN
											return (
												<React.Fragment
													key={
														'wbsFilterSubgrougTag-' +
														subDomain.key +
														'-' +
														value +
														index
													}>
													<div
														id={uniqueId}
														className={
															' filterSectionTag ' +
															(subDomain.subDomain === 'owner'
																? ''
																: 'filterSectionTagLabel')
														}
														onClick={(e) =>
															handleRemoveFilterItem(
																e,
																domain,
																subDomain.subDomain,
																value,
																isDate
															)
														}>
														<CvtTooltip
															anchorId={uniqueId}
															content={isDate ? dateLocalTxt : valueLabel}
														/>
														{/* IF OWNER PASS AVATAR */}
														{subDomain.subDomain === 'owner' ? (
															<CvtAvatar
																key={'filterSection-owner-' + currentUser.label}
																value={currentUser}
																radius={10}
																fontSize='10px'
																css='filterSectionAvatar'
															/>
														) : isDate ? (
															dateLocalTxt
														) : (
															valueLabel
														)}

														{/* CANCEL BUTTON */}
														<div className='material-icons filterTagReset'>
															cancel
														</div>
													</div>

													{/* DISPLAY ARROW FOR DATE RANGE */}
													{displayArrow ? (
														<span
															className='material-icons'
															style={{
																color: 'var(--color-text-5)',
																fontSize: '20px',
																marginRight: '5px',
															}}>
															keyboard_double_arrow_right
														</span>
													) : null}
												</React.Fragment>
											)
										})}

										{/* RESET ALL SUBDOMAIN */}
										{subDomain.value.length > 1 ? (
											<div
												className='material-icons filterSectionTagReset'
												onClick={(e) =>
													handleFilterResetSubDomain(
														e,
														domain + subDomain.subDomain
													)
												}>
												cancel
											</div>
										) : null}
									</div>
								)
							})
						}
					})}
				</div>
			</div>
		)
	}

	//IF NOT RETURN NULL
	return null
})
