import _ from "lodash";
import React, { memo, useCallback } from "react";
import { CvtButton } from "@mi-gso/cvt";
import { ACTION_STATUS_KEY } from "../../00-Helpers/ActionsConstants";

/////////////////////////////////////
/// ACTION TABLE LEGEND COMPONENT ///
/////////////////////////////////////

export default memo(function ActionTableLegend({
    legendAndCounter,
    statusSearchInput,
    actionsDispatcher,
}) {
    //////////////////////////////////////////////////////////////
    /// FUNCTIONS ////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    //ON TAG CLICK -------------------------------------------- OK
    const handleStatusTagClick = useCallback(
        (tagValue) => {
            //INIT
            let newStatusSearchInput = _.cloneDeep(statusSearchInput);

            //TEST IF SELECTED
            let isSelected = statusSearchInput?.includes(tagValue);

            //IF ALREADY SELECTED REMOVE IT
            if (isSelected) {
                newStatusSearchInput = newStatusSearchInput.filter((status) => status !== tagValue);

                //OR ADD IT
            } else {
                //IF NOT ALL SELECTED
                // if (
                //     legendAndCounter.length - 1 === statusSearchInput.length + 2 &&
                //     FUNCT_FIND_INDEX_ARRAY(statusSearchInput, ACTION_STATUS_KEY.done) === -1
                // ) {
                //     newStatusSearchInput = [];
                // } else {
                //     newStatusSearchInput.push(tagValue);
                // }
                newStatusSearchInput.push(tagValue);
            }

            //UPDATE ACTION DISPATCHER
            actionsDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    statusSearchInput: newStatusSearchInput,
                    selected: [],
                },
            });
        },
        [actionsDispatcher, statusSearchInput]
    );

    //RESET ALL FILTERS --------------------------------------- OK
    const onStatusReset = useCallback(() => {
        actionsDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                statusSearchInput: [],
            },
        });
    }, [actionsDispatcher]);

    //////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    return (
        <div className="flexStartCenter" style={{ marginLeft: "5px" }}>
            {/* MAP STATUS */}
            {legendAndCounter.map((item) => {
                //IF SELECTED
                let isSelected =
                    statusSearchInput.length > 0 && statusSearchInput?.includes(item.id);

                //TOT WITH SELECTION

                //RENDER FOR TOTAL
                if (item.id !== "total") {
                    return (
                        <div
                            key={"actionTableLegend-" + item.id}
                            className={
                                "actionsTableLegendTag flexStartCenter " +
                                (isSelected ? "actionsTableLegendTagSelected" : "")
                            }
                            onClick={() => handleStatusTagClick(item.id)}
                            style={{
                                background: item.backgroundColor,
                                opacity:
                                    (!isSelected && item.id === ACTION_STATUS_KEY.done) ||
                                    (statusSearchInput.length > 0 && !isSelected)
                                        ? "0.5"
                                        : null,
                            }}
                        >
                            {/* NAME */}
                            <span style={{ color: item.color }}>{item.name}</span>
                        </div>
                    );
                }

                //IF TOTAL RETURN NULL
                return null;
            })}

            {/* RESET */}
            {statusSearchInput.length > 0 ? (
                <CvtButton
                    color="gray"
                    customHover="red"
                    shape="noShape"
                    icon="filter_alt_off"
                    handleSubmit={onStatusReset}
                />
            ) : null}
        </div>
    );
});
