
import React, { useCallback, useMemo } from 'react'

//CORE
import { FUNC_GET_USER_INFO } from '../../../../../00-Core/Standards';
import TextUpdater from '../../../../../00-Core/TextUpdater';

//ACTION
import ActionCardProgressBar from './ActionCardProgressBar';
// import EnrichTextEditor from '../../../../../00-Core/00-Components/EnrichTextEditor/EnrichTextEditor';
import {CvtEnrichTextEditor} from '@mi-gso/cvt';
import { APP_FORM_STYLE } from '../../../../../00-Core/Constants';
import { CvtAvatar, CvtInputTextArea } from '@mi-gso/cvt';

///////////////////////////////////
/// ACTION CARD INFOS COMPONENT ///
///////////////////////////////////

export default function ActionCardInfos({
    action,
    editSideBar,
    viewModeInputText,
    usersList,
    megaUsersId,
    handleUpdateAction,
    actionsDispatcher,
    isReadOnlyMode,
    displayNone,
    isExpanded,
    canModifySelected,
}) {
  
    /////////////////////////////////////////////////////////////////////////
    /// DATA ////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////

    //GET RESPONSIBLE OBJECT ------------------------------------------------
    const responsibleObject = useMemo(() => {
        if(action){
            return FUNC_GET_USER_INFO(
                usersList, 
                megaUsersId, 
                action.responsible
            );
        }
    }, [action, megaUsersId, usersList]);


    /////////////////////////////////////////////////////////////////////////
    /// FUNCTIONS ///////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////

    // HANDLE INPUT
    const handleInputChange = useCallback((e) => {
        actionsDispatcher({
            type: "SET_STATE_KEY_VALUE",
            key: e.target.name,
            value: e.target.value,
        })
    }, [actionsDispatcher]);

  
    /////////////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    
    return (
        <div 
            className='actionCardInfos flexColStartStart'
            style={{
                display: displayNone ? "none" : null
            }}
        >

            {/* TITLE ----------------------------------------------------------- */}
            <div 
                className="flexStartCenter"
                style={{width:"100%"}}
            >

                {/* USER AVATAR */}
                <CvtAvatar
                    value={responsibleObject}
                    radius={15}
                    regularTooltip
                    tooltipTxt={responsibleObject?.label}
                />

                {/* ACTION NAME */}
                <TextUpdater 
                    item={{
                        id: action ? action.id : null,
                        name: action ? action.action : null
                    }}
                    viewModeCss="actionCardInfosTitle"
                    inputModeCss="actionCardInfosTitleEdition"
                    onSave={handleUpdateAction}
                    onSaveKey="action"
                    doubleClick={true}
                    isReadOnlyMode={isReadOnlyMode}
                />
            </div>

            {/* BLOCK PROGRESS BAR + DESCRIPTION ----------------------------------- */}
            <div className='flexStartStrech cardInfosBody'>

                {/* PROGRESS BAR */}
                <ActionCardProgressBar 
                    action={action}
                    actionDueDate={action ? action.dueDate : null}
                    actionStatus={action ? action.status : null}
                />
   
                {/* DESCRIPTION */}
                {(editSideBar || isReadOnlyMode) ? 
                    <CvtEnrichTextEditor 
                        isOnViewMode={true}
                        placeholder="Describe your action..."
                        title="Description"
                        titleIcon="article"
                        editorCss="enrichTextEditContent"
                        headingStylesSelectDirection="down"
                        editingValue={!action ? null : action.description && action.description !=="" ? action.description : ''}
                    />   
                :
                <div style={{width:"100%"}}>
                    
                    {/* ENRICH EDITION */}
                    <CvtInputTextArea 
                        type="richTextArea" 
                        placeholder={null}
                        inputBlockCss="flexColStartStart modalOptionSubBlock"
                        inputPadding="0px"
                        inputBlockHeight="100%"
                        inputCss={"form-control modalInputLight"}
                        inputHeight="80px"
                        inputFontSize={APP_FORM_STYLE.fontSize}
                        inputBlockPadding="0px"
                        inputBlockMargin="0px"
                        inputWidthFull={true}
                        name="viewModeInputText"
                        editingValue={viewModeInputText}
                        value={!action ? null : action.description && action.description !=="" ? action.description : ''}
                        onChange={handleInputChange}
                        onBlur={(e) => {
                            if (action.description !== viewModeInputText) {

                            handleUpdateAction(e, 'description', viewModeInputText)
                            }
                        }}
                        enrichTitleIcon="article"
                        enrichEditorCss="modalInputEnrichTxt"
                        isOnViewMode={!canModifySelected}
                        enrichTitle="Description"
                        headingStylesSelectDirection={isExpanded ? "down" : "up"}
                    />
                </div>
                }

            </div>
        </div>
    )
}
