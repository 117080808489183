import { CvtButton, FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import { useMemo } from "react";
import { WBS_GET_DOMAIN_ICON } from "../../../01-Action/00-Helpers/ActionsFunctions";

export default function GovScopeLinksListItem({
  link,
  projectData,
  onDeleteLinkItem,
  onLinkItemClick,
  isReadOnlyMode,
}) {
  // GET ITEM FROM PROJECT DATA
  const item = useMemo(() => {
    return FIND_OBJECT_ARRAY_ITEM(
      projectData[link.type],
      "id",
      link.destinationItemId
    );
  }, [link.destinationItemId, link.type, projectData]);

  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////
  return (
    <div
      className="actionCardLinkItem flexBetweenCenter"
      onClick={(e) => {
        onLinkItemClick(e, link);
      }}
      style={{
        margin: "5px 10px",
      }}
    >
      {/* LEFT PART */}
      <div className="flexStartCenter viewModeDescriptionText flexGap10">
        {/* DOMAIN ICON */}
        <span
          className="material-icons structureBlockTitleCellIcon"
          style={{
            marginRight: "0px",
          }}
        >
          {WBS_GET_DOMAIN_ICON(link)}
        </span>

        {/* DISPLAY ID */}
        {item?.displayId ? (
          <span className="actionCardInfosProgressBarDateLegend">
            {item.displayId}
          </span>
        ) : null}

        {/* NAME */}
        {item ? (
          <span>{item.name || item.title || item.action}</span>
        ) : (
          "Item not found"
        )}
      </div>

      {/* DELETE BUTTON */}
      {!isReadOnlyMode ? (
        <div className="actionCardLinkItemDeleteButton">
          <CvtButton
            color="red"
            icon="delete"
            iconType="filled"
            shadow="none"
            shape="noShape"
            txt=""
            confirmType="danger"
            needConfirmation
            txtConfirm={"Sure ?"}
            handleSubmit={onDeleteLinkItem}
            handleSubmitOptions={link}
          />
        </div>
      ) : null}
    </div>
  );
}
