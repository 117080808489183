import React, { useState, memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

//COMPONENT
export default memo(
    function FileElement({ 
        file, 
        fileSelected, 
        fileSelectedParentId, 
        smallView 
    }) {

        //STATE
        const [state, setState] = useState({
            displayedIcon: "keyboard_arrow_right",
            displayedIconColor: "modalButtonSubmit",
        });

        //HANDLE DISPLAY
        const handleDisplay = useCallback(e => {
                e.preventDefault();

                //INIT
                let displayedIcon = "keyboard_arrow_down";
                let displayedIconColor = "btn-secondary";

                //TEST UPDATE
                if (state.displayedIcon === displayedIcon) {
                    displayedIcon = "keyboard_arrow_right";
                    displayedIconColor = "modalButtonSubmit";
                }

                //UPDATE STATE
                setState(prevState => {
                    return {
                        ...prevState,
                        displayedIcon: displayedIcon,
                        displayedIconColor: displayedIconColor,
                    };
                });
        },[state]);

        // Build the row element
        const elementToReturn = useMemo(() => {
            return (
                <div
                    className="row"
                    style={{
                        margin: "0px",
                        borderTop: file.level === 0 && file.id !== "init"? "1px solid var(--border-color-gray-0)": null,
                        cursor: "pointer",
                    }}
                >
                    <div
                        className={
                            "structureRow " +
                            ((file.id === "init" && fileSelected) || file.id === fileSelectedParentId
                                ? "structureRowSummaryTitleSelect"
                                : fileSelected
                                ? "structureRowSummarySelect"
                                : "structureRowSummary")
                        }
                        onClick={file.level === 0 && file.id !== "init" ? handleDisplay : null}
                        data-bs-toggle={file.level === 0 ? "collapse" : null}
                        href={"#container-" + file.id}
                        style={{ 
                            margin: "0px",
                            boxShadow:"none"
                        }}
                    >
                        <div
                            className="structureRowTitle flexBetweenCenter structureRowLink"
                            style={{
                                marginLeft: smallView ? "0px" : file.level * 20 + "px",
                                zIndex: 100 - file.level,
                                padding: "5px 10px",
                                boxShadow: "none",
                                backgroundColor: "rgba(255,255,255,0)",
                                borderBottom: "none",
                            }}
                        >
                            <div
                                className="flexStartCenter"
                                style={{
                                    height: "32px",
                                    fontSize: smallView && file.level !== 0 ? "12px" : "",
                                }}
                            >
                                {/*GESTION DES ICONS*/}
                                {file.children && file.children.length > 0 ? 
                                    <a
                                        className={
                                            "btn btn-sm structureRowTitleIcon " +
                                            state.displayedIconColor
                                        }
                                        href={"#container-" + file.id}
                                        role="button"
                                        style={{
                                            color:"white"
                                        }}
                                    >
                                        <span className="material-icons" style={{ fontSize: "15px" }}>
                                            {state.displayedIcon}
                                        </span>
                                    </a>
                                : 
                                    file.id === "init" ? 
                                        <span
                                            className="material-icons"
                                            style={{
                                                fontSize: "22px",
                                                margin: "0px 14px 0px 8px",
                                            }}
                                        >
                                            home
                                        </span>
                                    : 
                                        <div
                                            className="btn btn-sm structureRowTitleIconDisable structureRowTitleIcon"
                                            style={{ height: "10px", width: "10px" }}
                                        ></div>
                                }

                                {/* WBS NAME + GESTION DU LIEN */}
                                {file.title}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }, [file.level, file.id, file.children, file.title, fileSelected, fileSelectedParentId, handleDisplay, smallView, state.displayedIconColor, state.displayedIcon]);

        // return an expandable element or a link to a documentation file
        if(file.level === 0 && file.id !== "init"){
            return elementToReturn
        }else{
            return(
                <Link to={`/docs/${file.id}`}>
                    {elementToReturn}
                </Link>
            )
        }
    }
);
