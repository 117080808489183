import { CvtAvatar } from "@mi-gso/cvt";
import React, { memo, useMemo } from "react";

// CORE
import { FUNC_GET_USER_INFO } from "../../../../../00-Core/Standards";

//ACTION
import { ACTION_STATUS_KEY, ACTION_STATUS_LIST } from "../../00-Helpers/ActionsConstants";
import { FUNC_ACTION_GET_NUM, FUNC_ACTION_IS_LATE } from "../../00-Helpers/ActionsFunctions";
import { TooltipWrapper } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { renderToString } from "react-dom/server";
import ActionTableLinkIconsList from "./ActionTableLinkIconsList";

///////////////////////////////////
/// ACTION TABLE ITEM COMPONENT ///
///////////////////////////////////

export default memo(function ActionTableItem({
    //DATA
    action,
    isSelected,
    isChild,
    isReadOnlyMode,
    projectData,
    isLinksShown,

    //USER
    usersList,
    megaUsersId,
    securityGroup,

    //FUNCTION
    onItemClicked,
    onSelectFunction,
    onOverTooltip,
    isCurrentUserEditor,
    wbsDispatcher,
    appDispatcher,
    actionsDispatcher,
}) {
    
    //////////////////////////////////////////////////////////////////////////
    /// DATA /////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    //GET RESPONSIBLE OBJECT ---------------------------------------------- OK
    const responsibleObject = useMemo(() => {
        return FUNC_GET_USER_INFO(usersList, megaUsersId, action.responsible);
    }, [action.responsible, megaUsersId, usersList]);

    //GET OBJECT STATUS --------------------------------------------------- OK
    const actionStatusObject = useMemo(() => {
        return ACTION_STATUS_LIST.find((item) => item.id === action.status);
    }, [action.status]);

    //TEST IF ACTION LATE ------------------------------------------------- OK
    const isLate = useMemo(() => {
        return FUNC_ACTION_IS_LATE(action.status, action.dueDate);
    }, [action.dueDate, action.status]);

    //RETURNS TRUE IF ACTION CREATED IN LAST 7 DAYS ------------------------ OK
    const isNew = useMemo(() => {
        //INIT
        let test = false;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() - 7);

        //GET CREATEDON DATE OBJECT
        let createdOn = Date.parse(action.createdOn) || null;

        //MAKE TEST
        if (today.getTime() < createdOn) test = true;

        //RETURN
        return test;
    }, [action.createdOn]);

    //GET WEEKNUM ----------------------------------------------------------- OK
    const actionWeekNum = useMemo(() => {
        return FUNC_ACTION_GET_NUM(action);
    }, [action]);

    //GET DATE INFO --------------------------------------------------------- OK
    const actionDateInfo = useMemo(() => {
        //INIT
        let date;
        let dateName;

        //SWITCH STATUS
        switch (action.status) {
            //DONE
            case ACTION_STATUS_KEY.done:
                date = new Date(action.realisedDate);
                dateName = "Closed Date";
                break;

            //ON HOLD
            case ACTION_STATUS_KEY.onHold:
                date = new Date(action.cancelledDate);
                dateName = "On Hold Date";
                break;

            //DEFAULT
            default:
                date = new Date(action.dueDate);
                dateName = "Due Date";
                break;
        }

        //INIT
        let dayText = date.toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        //RETURN
        return {
            value: dayText,
            name: dateName,
        };
    }, [action]);

    //GET ACTION PATH -------------------------------------------------------- OK
    const actionPath = useMemo(() => {
        //INIT - TO BE DELETED IN THE FUTUR
        let jsxPath = [
            <div key={"path-" + action.id}>
                Child path not available. Action has been created in a previous telescope version
            </div>,
        ];

        //CREATE JSX
        if (isChild && action && action.path) {
            //SPLIT
            let pathSplit = action.path.split(" > ");

            //MAP
            jsxPath = pathSplit.map((item, index) => {
                if (index === 0) {
                    return <div key={"path-" + action.id + "-" + index}>{item}</div>;
                } else {
                    return (
                        <div
                            key={"path-" + action.id + "-" + index}
                            style={{ marginLeft: 5 * index + "px" }}
                        >
                            {" > " + item}
                        </div>
                    );
                }
            });
        }

        //RETURN
        return jsxPath;
    }, [action, isChild]);

    //WEEK TOOLTIP
    const weekTooltip = useMemo(() => {
        return (
            <div className="flexColStartStart">
                <span
                    className="actionMiniTooltipTitle"
                    style={{
                        fontSize: "12px",
                    }}
                >
                    {actionDateInfo.name}
                </span>
                {actionDateInfo.value}

                {action.baseline ? (
                    <React.Fragment>
                        <span
                            className="actionMiniTooltipTitle"
                            style={{
                                fontSize: "12px",
                                marginTop: "0px",
                            }}
                        >
                            Baseline
                        </span>
                        {new Date(action.baseline).toLocaleString(undefined, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        })}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }, [action.baseline, actionDateInfo.name, actionDateInfo.value]);

    //////////////////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    return (
        <div
            data-cy="actions-table-item"
            id={"actionId-" + action.id}
            className={
                "actionTableItemContainer flexStartStart " +
                (isSelected ? "actionTableItemContainerSelected" : "")
            }
        >
            {/* SELECT BOX */}
            {!isReadOnlyMode && isCurrentUserEditor ? (
                <div
                    className={
                        "actionTableSelectBox " + (isSelected ? "actionTableSelectBoxActive" : "")
                    }
                    onClick={(e) => onSelectFunction(e, action)}
                ></div>
            ) : null}

            {/* CLICKABLE */}
            <div
                onClick={() => onItemClicked(action)}
                className="flexBetweenStart actionTableItemContainerClick"
            >
                {/* LEFT BLOCK ---------------------------------- */}
                <div className="flexStartStart">
                    {/* ACTION NAME */}
                    <div
                        className="actionTableItemDisplayId"
                        style={{
                            color: isLate ? "var(--color-bad)" : null,
                        }}
                    >
                        {action.displayId ? action.displayId : "AC-ERR"}
                    </div>

                    {/* NAME? */}
                    <div
                        className="actionTableItemName"
                        style={{
                            color: isLate ? "var(--color-bad)" : null,
                        }}
                    >
                        {action.action}
                    </div>

                    {/* CHILD */}
                    {isChild ? (
                        <TooltipWrapper
                            html={renderToString(actionPath)}
                            tooltipId="action-table-tooltip"
                        >
                            <div className="actionTableItemLockIcon material-icons">bookmarks</div>
                        </TooltipWrapper>
                    ) : null}

                    {/* NEW TEXT */}
                    {isNew ? (
                        <span
                            className="actionTableItemNew"
                            style={{
                                marginRight: "5px",
                            }}
                        >
                            New
                        </span>
                    ) : null}
                </div>

                {/* RIGHT BLOCK ---------------------------------- */}
                <div className="flexEndCenter">
                    {/* LINKS TO OTHER DOMAIN ITEMS */}
                    {isLinksShown ? (
                        <ActionTableLinkIconsList
                            action={action}
                            projectData={projectData}
                            onHoverTooltip={onOverTooltip}
                            securityGroup={securityGroup}
                            onOpenActionItem={onItemClicked}
                            wbsDispatcher={wbsDispatcher}
                            appDispatcher={appDispatcher}
                            actionsDispatcher={actionsDispatcher}
                        />
                    ) : null}

                    {/* EDITABLE STATUS TAG*/}
                    {isLate ? (
                        <div
                            className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
                            style={{
                                background: "var(--color-bad)",
                                marginLeft: "8px",
                            }}
                        >
                            {/* NAME */}
                            <span style={{ color: "white" }}>Late</span>
                        </div>
                    ) : null}

                    {/* EDITABLE STATUS TAG*/}
                    <div
                        className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
                        style={{
                            background: actionStatusObject.backgroundColor,
                            marginLeft: "3px",
                        }}
                    >
                        {/* NAME */}
                        <span style={{ color: actionStatusObject.color }}>
                            {actionStatusObject.name}
                        </span>
                    </div>

                    {/* WEEK */}
                    <TooltipWrapper
                        html={renderToString(weekTooltip)}
                        tooltipId="action-table-tooltip"
                    >
                        <div className="actionTableItemWeek">W{actionWeekNum}</div>
                    </TooltipWrapper>

                    {/* RESPONSIBLE */}
                    {responsibleObject && responsibleObject.label ? (
                        <TooltipWrapper
                            content={responsibleObject.label}
                            tooltipId="action-table-tooltip"
                        >
                            <div className="actionBigTableItemResponsible flexCenterCenter">
                                <CvtAvatar
                                    value={responsibleObject}
                                    radius={12}
                                    fontSize="10px"
                                    margin="0px 0px 0px 8px"
                                />
                            </div>
                        </TooltipWrapper>
                    ) : null}
                </div>
            </div>
        </div>
    );
});
