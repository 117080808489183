import React, {memo, useCallback, useState, useMemo} from 'react'

//CORE
import {
    APP_WBS_STRUCTURE,
} from '../../../../00-Core/Constants'

//WBS
import WbsElementMini from './WbsElementMini'
import WbsElementFirstColumn from './WbsElementFirstColumn'

// COMPONENTS
import ActionMini from '../../01-Action/ActionMini';
import RiskMini from '../../02-Risk/RiskMini';
import GovernanceMini from '../../04-Governance/GovMini';

// WBS
import { CHARTER_CATEGORIZATION_TYPE } from '../../05-Charter/00-Helpers/CharterConstants';
import _ from 'lodash';
import ScheduleMini from '../../09-Schedule/ScheduleMini';

//////////////////////////
/// WBS ITEM COMPONENT ///
//////////////////////////

//COMPONENT
export default memo(
    function WbsElement({
        item,
        itemLevel,
        isProjectElement,
        isPortfolioSelected,
        dataConsolidation,
        forceProjectNav,
        projectData,
        selectedWbsId,
        selectedField,
        selectedBranchWbsId,
        handleBigComponent,
        handleDeepDive,
        currentUser,
        wbsProjectData,
        wbsDispatcher,
        wbsSettings,
        isShortcutBigOpen,
        isReviewMode,
        oppIndex,
        updateTrackers,
        listOfForceOpenWbs,
        wbsNavigationOptions,
    }) {
      
        /////////////
        /// STATE ///
        /////////////

        const [state, setState] = useState({
            displayedIcon: "keyboard_arrow_right",
            displayedIconColor: "modalButtonSubmit",
        });

        /////////////
        /// MEMO ///
        /////////////
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const riskData = useMemo(() => {
            if(updateTrackers.riskOpp) {};
            return projectData.riskOpp.filter((roItem) => roItem.isRisk === true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [projectData.riskOpp, projectData.riskOpp.length, updateTrackers.riskOpp]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const oppData = useMemo(() => { 
            if(updateTrackers.riskOpp) {};
            return projectData.riskOpp.filter((roItem) => roItem.isRisk === false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [projectData.riskOpp, projectData.riskOpp.length, updateTrackers.riskOpp]);
      
  
        //////////////////////////////////////////////////////////////////////////////////////////////////
        /// FRONT TREATMENT //////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////

        //HANDLE DISPLAY
        const handleDisplay = useCallback(() =>{
          
            //INIT
            let displayedIcon = "keyboard_arrow_down";
            let displayedIconColor = "btn-secondary";

            //TEST UPDATE
            if(state.displayedIcon === displayedIcon){
                displayedIcon = "keyboard_arrow_right";
                displayedIconColor = "modalButtonSubmit";
            }

            //UPDATE STATE
            setState(prevState => {
                return({
                    ...prevState, 
                    displayedIcon:displayedIcon,
                    displayedIconColor: displayedIconColor,
                })
            })
        },[state])

        /////////////////////////////////////////
        /// LEVEL RELATED WBS OR PROJECT LINE ///
        /////////////////////////////////////////

        //ShortCut DATA
        const shorcutData = useMemo(()=>
            projectData.shortcut.filter((shortcut) => shortcut.wbsId === item.id)
        ,[item.id, projectData.shortcut])
        
        //Charter DATA
        const charterData = useMemo(() =>{
            if (updateTrackers.charter) {}

            return projectData.charter[0];
        }
        ,[projectData.charter, updateTrackers.charter])
        
        // IF IS FORCE OPEN
        const isForceOpen = useMemo(() => {
            if(selectedWbsId && item.id !== selectedWbsId && state.displayedIconColor !== "btn-secondary" && wbsNavigationOptions?.goToItemId) {
                return listOfForceOpenWbs?.includes(item.id);
            }
            else return false;
        }, [item.id, listOfForceOpenWbs, selectedWbsId, state.displayedIconColor, wbsNavigationOptions?.goToItemId]);
       
        ////////////////////////////////////////////////////////////////////////////
        /// COMPONENT RENDER ///////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////
     
        return (
                <div 
                    key={`small_components_${item.id}`}
                    id={item.id}
                    className="structureRow flexStartCenter"
                    style={{
                        height: APP_WBS_STRUCTURE.rowHeight,
                        marginLeft: (itemLevel*20) + "px",
                        zIndex: 200 - itemLevel*2,
                        backgroundColor: isProjectElement || selectedBranchWbsId === item.id? "var(--border-color-gray-00)" : null,
                    }}
                >   
                    {/* FIRST COLUMN ------------------------------------------- */}
                    <WbsElementFirstColumn 
                        item={item}
                        isPortfolioSelected={isPortfolioSelected}
                        dataConsolidation={dataConsolidation}
                        isProjectElement={isProjectElement}
                        forceProjectNav={forceProjectNav}
                        itemLevel={itemLevel}
                        displayedIcon={state.displayedIcon}
                        displayedIconColor={state.displayedIconColor}
                        selectedBranchWbsId={selectedBranchWbsId}
                        selectedWbsId={selectedWbsId}
                        selectedField={selectedField}
                        shortCutData={shorcutData}
                        charterData={charterData}
                        charterCategory={CHARTER_CATEGORIZATION_TYPE}
                        bigComponentDisplayed={item.id === selectedWbsId && ("charter" === selectedField || "shortcut" === selectedField)}
                        handleDisplay={handleDisplay}
                        handleDeepDive={handleDeepDive}
                        handleBigComponent={handleBigComponent}
                        isShortcutBigOpen={isShortcutBigOpen}
                        isReviewMode={isReviewMode}
                        updateTrackers={updateTrackers}
                        isForceOpen={isForceOpen}
                    />

                    {/* IS PORTFOLIO AND NO CONSOLIDATED */}
                    {isPortfolioSelected && !dataConsolidation ?
                        []
                    :

                        // MAP ALL OTHER COLUMN ----------------------------------- 
                        wbsSettings.domain.map((field, index)=>{
                            
                            //RENDER MINI COMPONENT
                            if(field.enabled && field.displayInColumn){
 

                                //RETURN
                                return(
                                    <React.Fragment 
                                        key={"wbsElement-" + item.id + "-" + field.id}
                                    >

                                        {
                                            oppIndex === index ?

                                            <WbsElementMini
                                                propKey={"wbsElement-" + item.id + "-opportunity"}       
                                                itemId={item.id}
                                                fieldId={"opportunity"}
                                                handleBigComponent={handleBigComponent}
                                                bigComponentDisplayed={item.id === selectedWbsId && "opportunity" === selectedField}
                                                isProjectElement={isProjectElement}
                                                itemLevel={itemLevel}
                                                selectedWbsId={selectedWbsId}
                                            >

                                                {/* RISK & OPP --------------------------------------- */}
                                                
                                                    <RiskMini
                                                        roData={oppData}
                                                        actionData={wbsProjectData.action}
                                                        propKey={"mini-risk-" + item.id}
                                                        projectItemId={item.id}
                                                        wbsDispatcher={wbsDispatcher}
                                                        wbsId={item.id}
                                                        selectedWbsId={selectedWbsId}
                                                        bigComponentDisplayed={item.id === selectedWbsId}
                                                        isRisk={false}
                                                        cyId={`oops-${itemLevel}`}
                                                    />
                                               
                                            </WbsElementMini>
                                        :null}

                                        {/* MINI COMPONENT */}
                                        <WbsElementMini
                                            propKey={"wbsElement-" + item.id + "-" + field.id }       
                                            itemId={item.id}
                                            fieldId={field.id}
                                            handleBigComponent={handleBigComponent}
                                            bigComponentDisplayed={item.id === selectedWbsId && field.id === selectedField}
                                            isProjectElement={isProjectElement}
                                            itemLevel={itemLevel}
                                            selectedWbsId={selectedWbsId}
                                           
                                        >
                                            
                                            

                                            {/* ACTION ------------------------------------------ */}
                                            {field.id === "action" ?
                                                <ActionMini
                                                    data={_.cloneDeep(projectData[field.id])}
                                                    bigComponentDisplayed={item.id === selectedWbsId}
                                                    propKey={"mini-action-" + item.id + projectData[field.id].length}
                                                    cyId={`${field.id}-${itemLevel}`}
                                                />
                                            :null}

                                            {/* RISK & OPP --------------------------------------- */}
                                            {field.id==="riskOpp" ?
                                                <RiskMini
                                                    roData={riskData}
                                                    actionData={wbsProjectData.action}
                                                    propKey={"mini-risk-" + item.id + projectData.riskOpp.length}
                                                    projectItemId={item.id}
                                                    wbsDispatcher={wbsDispatcher}
                                                    wbsId={item.id}
                                                    selectedWbsId={selectedWbsId}
                                                    bigComponentDisplayed={item.id === selectedWbsId}
                                                    isRisk={true}
                                                    cyId={`risk-${itemLevel}`}
                                                />
                                            :null}

                                            {/* SCHEDULE -------------------------------------- */}
                                            {field.id === "schedule" ?
                                                <ScheduleMini 
                                                    data={projectData[field.id]}
                                                    bigComponentDisplayed={item.id === selectedWbsId && field.id === selectedField}
                                                    propKey={"mini-schedule-" + item.id}
                                                    cyId={`${field.id}-${itemLevel}`}
                                                />
                                            :null}

                                            {/* GOVERNANCE --------------------------------------- */}
                                            {field.id === "governance" ?
                                                <GovernanceMini 
                                                    data={_.cloneDeep(projectData.govScopeChange)}
                                                    bigComponentDisplayed={item.id === selectedWbsId}
                                                    propKey={"mini-govScopes-" + item.id + projectData.govScopeChange.length}
                                                    currentUser={currentUser} 
                                                    isProjectElement={isProjectElement}
                                                    cyId={`${field.id}-${itemLevel}`}
                                                />
                                            :null}

                                        </WbsElementMini>

                                        {/* CHECK FOR AFTER */}
                                        {/* !!NEED OPTIMIZATION */}
                                        {
                                            oppIndex === (index + 1) && oppIndex === wbsSettings.domain.length ?
                                            <WbsElementMini
                                                propKey={"wbsElement-" + item.id + "-opportunity"}       
                                                itemId={item.id}
                                                fieldId={"opportunity"}
                                                handleBigComponent={handleBigComponent}
                                                bigComponentDisplayed={item.id === selectedWbsId && "opportunity" === selectedField}
                                                isProjectElement={isProjectElement}
                                                itemLevel={itemLevel}
                                                selectedWbsId={selectedWbsId}
                                            >

                                                {/* RISK & OPP --------------------------------------- */}
                                                
                                                    <RiskMini
                                                        roData={oppData}
                                                        actionData={wbsProjectData.action}
                                                        propKey={"mini-risk-" + item.id}
                                                        projectItemId={item.id}
                                                        wbsDispatcher={wbsDispatcher}
                                                        wbsId={item.id}
                                                        selectedWbsId={selectedWbsId}
                                                        bigComponentDisplayed={item.id === selectedWbsId}
                                                        isRisk={false}
                                                    />
                                               
                                            </WbsElementMini>
                                        :null
                                        }
                                    </React.Fragment>
                                )
                            }
                            
                            //IF NOT RETURN NULL
                            return null
                        })
                    }   
                </div>
           
        )
    }
)