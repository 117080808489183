import React, { memo, useMemo, useCallback } from "react";

// CORE
import { FUNC_GET_USER_INFO } from "../../../../00-Core/Standards";
import { SCHEDULE_TYPE_KEYS } from "../00-Helpers/ScheduleConstants";
import ScheduleMilestoneTimeline from "./ScheduleMilestoneTimeline";
import ScheduleTaskTimeline from "./ScheduleTaskTimeline";
import { CvtAvatar } from "@mi-gso/cvt";
import {  TooltipWrapper } from "react-tooltip";
import { renderToString } from "react-dom/server";

///////////////////////////////////
/// SCHEDULE TABLE ITEM COMPONENT ///
///////////////////////////////////

export default memo(function ScheduleTableItem({
    //DATA
    scheduleItem,
    isSelected,
    selectedItems,
    isChild,
    isCurrentUserEditor,

    // DISPLAY
    isReadOnlyMode,
    isExpanded,
    isReviewMode,

    //USER
    usersList,
    megaUsersIds,

    //FUNCTION
    scheduleDispatcher,
    onItemClick
}) {
    //////////////////////////////////////////////////////////////////////////
    /// DATA /////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    // RETURNS TRUE IF CREATED IN LAST 7 DAYS
    const isNew = useMemo(() => {
        //INIT
        let test = false;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() - 7);

        //GET CREATEDON DATE OBJECT
        let createdOn = new Date(scheduleItem.createdOn);

        //MAKE TEST
        if (today.getTime() < createdOn) test = true;

        //RETURN
        return test;
    }, [scheduleItem.createdOn]);

    //GET RESPONSIBLE OBJECT ---------------------------------------------- OK
    const responsibleObject = useMemo(() => {
        return FUNC_GET_USER_INFO(usersList, megaUsersIds, scheduleItem.responsible);
    }, [megaUsersIds, scheduleItem.responsible, usersList]);

    // DATE TOOLTIP
    const dueDateTooltip = useMemo(() => {
        return (
            <div className="flexColStartStart">
                <span
                    className="actionMiniTooltipTitle"
                    style={{
                        fontSize: "12px",
                    }}
                >
                </span>

                {scheduleItem.dueDate.date ? (
                    <React.Fragment>
                        {new Date(scheduleItem.dueDate.date).toLocaleString(undefined, {
                            year: "2-digit",
                            month: "2-digit",
                        })}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }, [scheduleItem.dueDate.date]);


      //YEAR-MONTH INFO
      const BaselineFinishTooltip = useMemo(() => {
        return (
            <div className="flexColStartStart">
                <span
                    className="actionMiniTooltipTitle"
                    style={{
                        fontSize: "12px",
                    }}
                >
                </span>

                {scheduleItem.baselineFinish ? (
                    <React.Fragment>
                        <span
                            className="actionMiniTooltipTitle"
                            style={{
                                fontSize: "12px",
                                marginTop: "0px",
                            }}
                        >
                            Baseline Finish
                        </span>
                        {new Date(scheduleItem.baselineFinish).toLocaleString(undefined, {
                            year: "2-digit",
                            month: "2-digit",
                            day: "2-digit"
                        })}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }, [scheduleItem.baselineFinish]);

    //GET ACTION PATH -------------------------------------------------------- OK
    const schedulePath = useMemo(() => {
        //INIT - TO BE DELETED IN THE FUTUR
        let jsxPath = [
            <div key={"path-" + scheduleItem.id}>
                Child path not available. This item has been created in a previous telescope
                version.
            </div>,
        ];

        //CREATE JSX
        if (isChild && scheduleItem && scheduleItem.path) {
            //SPLIT
            let pathSplit = scheduleItem.path.split(" > ");

            //MAP
            jsxPath = pathSplit.map((item, index) => {
                if (index === 0) {
                    return <div key={"path-" + scheduleItem.id + "-" + index}>{item}</div>;
                } else {
                    return (
                        <div
                            key={"path-" + scheduleItem.id + "-" + index}
                            style={{ marginLeft: 5 * index + "px" }}
                        >
                            {" > " + item}
                        </div>
                    );
                }
            });
        }
        //RETURN
        return jsxPath;
    }, [scheduleItem, isChild]);

    //////////////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    // HANDLE SELECT CHECKBOX
    const handleSelectCheckbox = useCallback(
        (e) => {
            e.stopPropagation();

            //INIT
            let newSelectedItems = selectedItems;

            //TEST IF ALREADY SELECTED SELECTED => UNSELECT
            if (isSelected) {
                newSelectedItems = newSelectedItems.filter(
                    (scheduleSelected) => scheduleSelected.id !== scheduleItem.id
                );

                //SELECT
            } else {
                newSelectedItems.push(scheduleItem);
            }

            // USE ACTIONS DISPATCHER
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selected: newSelectedItems,
                    isConfirmDelete: false,
                },
            });
        },
        [selectedItems, isSelected, scheduleDispatcher, scheduleItem]
    );


    //////////////////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    return (
        <div
            id={"actionId-" + scheduleItem.id}
            className={
                "actionTableItemContainer flexStartStart " +
                (isSelected ? "actionTableItemContainerSelected" : "")
            }
            data-cy="schedule-table-item"
        >
            {/* SELECT BOX */}
            {!isReadOnlyMode && isCurrentUserEditor ? (
                <div
                    className={
                        "actionTableSelectBox " + (isSelected ? "actionTableSelectBoxActive" : "")
                    }
                    onClick={handleSelectCheckbox}
                ></div>
            ) : null}

            {/* CONTENT */}
            <div
                className="flexBetweenStart actionTableItemContainerClick"
                style={{
                    marginLeft: "10px",
                }}
                onClick={() => {
                    onItemClick(scheduleItem);
                }}
            >
                {/* LEFT BLOCK ---------------------------------- */}
                <div className="flexStartStart">
                    {/* DISPLAY ID */}
                    <div
                        className="actionTableItemDisplayId"
                        style={{
                            marginLeft: "10px",
                        }}
                    >
                        {scheduleItem.displayId ? scheduleItem.displayId : "SC-ERR"}
                    </div>

                    {/* TITLE */}
                    <div className="actionTableItemName">{scheduleItem.name}</div>

                    {/* NEW TEXT */}
                    {isNew ? (
                        <span
                            className="actionTableItemNew flexCenterCenter"
                            style={{
                                margin: "3px 0px 0px 10px",
                            }}
                        >
                            New
                        </span>
                    ) : null}

                    {/* CHILD */}
                    {isChild ? (
                        <TooltipWrapper
                            html={renderToString(schedulePath)}
                            tooltipId="schedule-table-tooltip"
                        >
                            <div className="actionTableItemLockIcon material-icons">
                                bookmarks
                            </div>
                        </TooltipWrapper>
                    ) : null}
                </div>

                {/* RIGHT BLOCK ---------------------------------- */}
                <div className="flexEndCenter">
                    {/* EXPANDED MODE DATES */}
                    {isExpanded && !isReviewMode ? (
                        
                        <div
                            className="flexStartStart"
                            style={{
                                margin: "-20px 10px -10px 0px",
                            }}
                        >
                            {/* TIMELINES */}
                            {!scheduleItem.type || scheduleItem.type === SCHEDULE_TYPE_KEYS.task ? (
                                <ScheduleTaskTimeline item={scheduleItem} />
                            ) : (
                                <ScheduleMilestoneTimeline item={scheduleItem} />
                            )}
                        </div>

                    ) : null}

                    {/* WEEK AND YEAR */}
                    {!isExpanded ? (         
                    <TooltipWrapper
                        html={renderToString(BaselineFinishTooltip)}
                        tooltipId="schedule-table-tooltip"
                    >
                        <div className="actionTableItemWeek">{dueDateTooltip}</div>
                    </TooltipWrapper>
                    ) : null }

                    {/* STATUS TAG*/}
                    <div className="flexEndCenter" style={{ width: "90px" }}>
                        <div
                            className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
                            style={{
                                backgroundColor: scheduleItem.status.backgroundColor,
                                marginLeft: "10px",
                            }}
                        >
                            {/* NAME */}
                            <span>{scheduleItem.status.label}</span>
                        </div>
                    </div>

                    {/* RESPONSIBLE AVATAR */}
                        <TooltipWrapper
                            content={responsibleObject.label}
                            tooltipId="schedule-table-tooltip"
                        >
                            <div className="actionBigTableItemResponsible flexCenterCenter" >
                                <CvtAvatar 
                                    value={responsibleObject}
                                    radius={12}
                                    fontSize="10px"
                                    margin="0px 8px 0px 8px"
                                />
                            </div> 
                        </TooltipWrapper>
                </div>
            </div>
        </div>
    );
});