import React from 'react'

//HOME
import { HomeAnimationsFadeIn } from "./HomeAnimations";
import {HomeStepPicture, HomeStepTable, HomeStepTitle} from './HomeMiniComponents';


//HOME IMPORT DATA COMPONENT
export default function HomeStepSection({
    isVisible, //From the parent
    titleText,
    titlePicture,
    stepNumber,
    stepData,
    picture,
    altPicture,
    pictureWidth,
}) {

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////

    return (
        <React.Fragment>

            {/* LEFT SIDE */}
            <div
                className="homeHalfPageContainer flexColCenterCenter"
            >
                <HomeAnimationsFadeIn
                    isVisible={isVisible}
                    from={stepNumber % 2 === 0 ? "right" : "left"}
                    delay={750}
                >

                    {/* TITLE */}
                    <HomeStepTitle 
                        title={titleText}
                        stepPicture={titlePicture}
                        altPicture={`Triangle with step number ${stepNumber} inside`}
                    />

                    {/* TABLE INFORMATION */}
                    <HomeStepTable 
                        stepData={stepData}
                    />
                </HomeAnimationsFadeIn>
            </div>

            {/* RIGHT SIDE */}
            <div
                className="homeHalfPageContainer flexColCenterCenter"
            >
                <HomeAnimationsFadeIn
                    isVisible={isVisible}
                    from={stepNumber % 2 === 0 ? "left": "right"}
                    delay={1000}
                >

                    {/* PICTURE */}
                    <HomeStepPicture 
                        stepPicture={picture}
                        altPicture={altPicture}
                        style={{width: pictureWidth}}
                    />
                </HomeAnimationsFadeIn>
            </div>
        </React.Fragment>
    )
}
