import { CvtSideBarButton } from "@mi-gso/cvt";
import WbsElementBigSideBar from "../../../00-Wbs/01-Components/WbsElementBigSideBar";
import { GOV_REVIEW_MEETING_STATUS_KEY } from "../../00-Helpers/GovReviewConstants";

/////////////////// COMPONENT /////////////////////
export default function GovReviewLeftSideBar({
    govReviewMeetingStatus,
    handleUpdateGovReview,
    canEdit,
}) {
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <WbsElementBigSideBar>

            {/* NOT STARTED -> IN PROGRESS */}
            {(!govReviewMeetingStatus || govReviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.notStarted ||
            govReviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.done) &&
            canEdit ? 
                <CvtSideBarButton
                    buttonClass="flexCenterCenter buttonSideBar"
                    id="tooltipStartMeeting"
                    icon={"edit"}
                    fontSize="22px"
                    title={
                        govReviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.notStarted
                            ? "Start the Meeting"
                            : "Edit the Meeting"
                    }
                    onClickFunction={(e) => {
                        handleUpdateGovReview(
                            e,
                            "reviewMeetingStatus",
                            GOV_REVIEW_MEETING_STATUS_KEY.inProgress
                        );
                    }}
                />
            : null}

            {/* IN PROGRESS -> DONE */}
            {govReviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.inProgress && canEdit ? 
                <CvtSideBarButton
                    buttonClass="flexCenterCenter buttonSideBar"
                    id="tooltipFinishMeeting"
                    icon={"done"}
                    fontSize="22px"
                    title={"End the meeting"}
                    onClickFunction={(e) => {
                        handleUpdateGovReview(
                            e,
                            "reviewMeetingStatus",
                            GOV_REVIEW_MEETING_STATUS_KEY.done
                        );
                    }}
                />
            : null}

            {/* DONE -> CLOSED */}
            {govReviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.done && canEdit ? 
                <CvtSideBarButton
                    buttonClass="flexCenterCenter buttonSideBar"
                    id="tooltipCloseMeeting"
                    icon={"done_outline"}
                    fontSize="22px"
                    title={"Close and Lock"}
                    onClickFunction={(e) => {
                        handleUpdateGovReview(
                            e,
                            "reviewMeetingStatus",
                            GOV_REVIEW_MEETING_STATUS_KEY.closed
                        );
                    }}
                />
            : null}

            {/* SHARE MEETING */}
            {govReviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.closed ? 
                <CvtSideBarButton
                    buttonClass="flexCenterCenter buttonSideBar"
                    id="tooltipShareMinutes"
                    icon={"share"}
                    fontSize="22px"
                    title={"Share with Attendees"}
                    onClickFunction={() => {}}
                />
            : null}
        </WbsElementBigSideBar>
    );
}
