import { CvtAvatar } from "@mi-gso/cvt";
import _ from "lodash";
import React, { memo, useMemo, useCallback } from "react";
import { renderToString } from "react-dom/server";
import { TooltipWrapper } from "react-tooltip";

// CORE
import { FUNCT_FIND_INDEX, FUNC_GET_USER_INFO } from "../../../../../00-Core/Standards";
import {
    GOV_SCOPE_LEVELS,
    GOV_SCOPE_STATUS,
    GOV_SCOPE_STATUS_KEY,
} from "../../00-Helpers/GovScopeConstants";

//ACTION

///////////////////////////////////
/// ACTION TABLE ITEM COMPONENT ///
///////////////////////////////////

export default memo(function GovScopeTableItem({

    //DATA
    scopeItem,
    isSelected,
    selectedItems,
    isReadOnlyMode,
    isChild,
    isCurrentUserEditor,

    //USER
    usersList,
    megaUsersIds,

    //FUNCTION
    onItemClicked,
    govDispatcher
}) {
    //////////////////////////////////////////////////////////////////////////
    /// DATA /////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    // GET OBJECT STATUS
    const govScopeStatusObject = useMemo(() => {
        return GOV_SCOPE_STATUS.find((item) => item.id === scopeItem.status);
    }, [scopeItem.status]);

    // RETURNS TRUE IF CREATED IN LAST 7 DAYS
    const isNew = useMemo(() => {
        //INIT
        let test = false;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() - 7);

        //GET CREATEDON DATE OBJECT
        let createdOn = Date.parse(scopeItem.createdOn) || null;

        //MAKE TEST
        if (today.getTime() < createdOn) test = true;

        //RETURN
        return test;
    }, [scopeItem.createdOn]);

    // SCOPE LEVEL ICON CONFIG
    const scopeLevelIconConfig = useMemo(() => {
        let findIndex = FUNCT_FIND_INDEX(GOV_SCOPE_LEVELS, "id", scopeItem.level);

        if (findIndex !== -1) {
            return GOV_SCOPE_LEVELS[findIndex];
        }

        return null;
    }, [scopeItem.level]);

    // CALCULATE DISPLAYED DATE
    const displayedDate = useMemo(() => {
        let date;

        if (scopeItem.status === GOV_SCOPE_STATUS_KEY.agreed) {
            // STATUS AGREED -> RETURN CLOSE DATE
            date = scopeItem.closedDate;
        } else {
            date = scopeItem.dueDate;
        }

        // ELSE RETURN DUE DATE
        return new Date(date).toLocaleDateString(undefined, {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        });
    }, [scopeItem.closedDate, scopeItem.dueDate, scopeItem.status]);

    //GET RESPONSIBLE OBJECT ---------------------------------------------- OK
    const responsibleObject = useMemo(() => {
        return FUNC_GET_USER_INFO(usersList, megaUsersIds, scopeItem.responsible);
    }, [megaUsersIds, scopeItem.responsible, usersList]);

    //GET ACTION PATH -------------------------------------------------------- OK
    const scopePath = useMemo(() => {
        //INIT - TO BE DELETED IN THE FUTUR
        let jsxPath = [
            <div key={"path-" + scopeItem.id}>
                Child path not available. This item has been created in a previous telescope version.
            </div>,
        ];

        //CREATE JSX
        if (isChild && scopeItem && scopeItem.path) {
            //SPLIT
            let pathSplit = scopeItem.path.split(" > ");

            //MAP
            jsxPath = pathSplit.map((item, index) => {
                if (index === 0) {
                    return <div key={"path-" + scopeItem.id + "-" + index}>{item}</div>;
                } else {
                    return (
                        <div
                            key={"path-" + scopeItem.id + "-" + index}
                            style={{ marginLeft: 5 * index + "px" }}
                        >
                            {" > " + item}
                        </div>
                    );
                }
            });
        }

        //RETURN
        return jsxPath;
    }, [scopeItem, isChild]);

    //////////////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    // HANDLE SELECT CHECKBOX
    const handleSelectCheckbox = useCallback(
        (e) => {
            e.stopPropagation();

            //INIT
            let newSelectedItems = _.cloneDeep(selectedItems);

            //TEST IF ALREADY SELECTED SELECTED => UNSELECT
            if (isSelected) {
                newSelectedItems = newSelectedItems.filter(
                    (scopeSelected) => scopeSelected.id !== scopeItem.id
                );

                //SELECT
            } else {
                newSelectedItems.push(scopeItem);
            }

            // USE ACTIONS DISPATCHER
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selectedItems: newSelectedItems,
                    isConfirmDelete: false,
                },
            });
        },
        [selectedItems, isSelected, govDispatcher, scopeItem]
    );

    //////////////////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    return (
        <div
            data-cy="governance-table-item"
            id={"actionId-" + scopeItem.id}
            className={
                "actionTableItemContainer flexStartStart " +
                (isSelected ? "actionTableItemContainerSelected" : "")
            }
        >
            {/* SELECT BOX */}
            {!isReadOnlyMode && isCurrentUserEditor ? (
                <div
                    className={
                        "actionTableSelectBox " + (isSelected ? "actionTableSelectBoxActive" : "")
                    }
                    onClick={handleSelectCheckbox}
                ></div>
            ) : null}

            {/* CLICKABLE */}
            <div
                onClick={() => onItemClicked(scopeItem)}
                className="flexBetweenStart actionTableItemContainerClick"
                style={{
                    marginLeft: "10px",
                }}
            >
                {/* LEFT BLOCK ---------------------------------- */}
                <div className="flexStartStart">
                    {/* LEVEL ICON */}
                    {scopeLevelIconConfig ? (
                        <TooltipWrapper
                            content={scopeLevelIconConfig.label}
                            tooltipId="gov-table-tooltip"
                        >
                            <div
                                className="flexCenterCenter"
                                style={{
                                    width: "20px",
                                    minWidth: "20px",
                                    height: "20px",
                                    borderRadius: "5px",
                                    color: "white",
                                    fontWeight: "700",
                                    fontSize: "12px",
                                    backgroundColor: scopeLevelIconConfig.backgroundColor,
                                }}
                            >
                                {scopeLevelIconConfig.small}
                            </div>
                        </TooltipWrapper> 
                    ) : null}

                    {/* DISPLAY ID */}
                    <div
                        className="actionTableItemDisplayId"
                        style={{
                            marginLeft: "10px",
                        }}
                    >
                        {scopeItem.displayId ? scopeItem.displayId : "SC-ERR"}
                    </div>

                    {/* TITLE */}
                    <div className="actionTableItemName">{scopeItem.title}</div>

                    {/* NEW TEXT */}
                    {isNew ? (
                        <span
                            className="actionTableItemNew flexCenterCenter"
                            style={{
                                margin: "3px 10px 0px 10px",
                            }}
                        >
                            New
                        </span>
                    ) : null}

                    {/* CHILD */}
                    {isChild ? 
                        <TooltipWrapper
                            html={renderToString(scopePath)}
                            tooltipId="gov-table-tooltip"
                        >
                            <div className="actionTableItemLockIcon material-icons">
                                bookmarks
                            </div>
                        </TooltipWrapper>
                    : null}
                </div>

                {/* RIGHT BLOCK ---------------------------------- */}
                <div className="flexEndCenter">

                    {/* STATUS TAG*/}
                    <div
                        className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
                        style={{
                            backgroundColor: govScopeStatusObject?.backgroundColor ?? "grey",
                            marginLeft: "10px",
                        }}
                    >
                        {/* NAME */}
                        <span>{govScopeStatusObject?.label.toUpperCase() ?? "Unknown"}</span>
                    </div>

                    <div
                        style={{
                            margin: "0px 10px",
                            fontWeight: "700",
                            fontSize: "12px",
                            color: "var(--color-text-5)",
                        }}
                    >
                        {displayedDate}
                    </div>

                    {/* RESPONSIBLE */}
                    {responsibleObject && responsibleObject.label ? 
                        <TooltipWrapper
                            content={responsibleObject.label}
                            tooltipId="gov-table-tooltip"
                        >
                            <div className="actionBigTableItemResponsible flexCenterCenter">
                                <CvtAvatar
                                    value={responsibleObject}
                                    radius={12}
                                    fontSize="10px"
                                    margin="0px 0px 0px 8px"
                                />
                            </div>
                        </TooltipWrapper>
                    : null} 
                </div>
            </div>
        </div>
    );
});