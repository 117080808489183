import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import {
    FUNC_REMOVE_ELEMENT_FROM_ARRAY,
    FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING,
} from "../../../../../00-Core/Standards";
import {
    updateTelescopeDataAction,
    updateTelescopeDataGovReview,
    updateTelescopeDataGovScopeChange,
} from "../../../../../graphql/mutations";
import { multipleMutateGraphql } from "../../../../00-App/02-Backend/AppBackendCommon";
import { WBS_DEFAULT_NAVIGATION_OPTIONS } from "../../../00-Wbs/00-Helpers/WbsConstants";
import { WBS_OPEN_ITEM } from "../../../00-Wbs/00-Helpers/WbsFunctions";
import { ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST } from "../../../01-Action/00-Helpers/ActionsFunctions";
import GovScopeLinksListItem from "./GovScopeLinksListItem";

export default function GovScopeLinksList({
    govItem,
    govDispatcher,
    appDispatcher,
    links,
    onDeleteListItem,
    projectData,
    securityGroup,
    wbsDispatcher,
    isReadOnlyMode,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // PARSE LINKS DATA
    const parsedLinks = useMemo(() => {
        return FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(links);
    }, [links]);

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // DELETE ONE ACTION LINK
    const onDeleteLinkItem = useCallback(
        (e, link) => {
            let finalMutation = null;
            let updatedScope = null;

            if (link.type === "action") {
                // INIT
                finalMutation = {
                    graphql: {
                        govScopeChange: {
                            query: updateTelescopeDataGovScopeChange,
                            objects: [],
                        },
                        action: {
                            query: updateTelescopeDataAction,
                            objects: [],
                        },
                    },
                    dispatcher: {
                        govScopeChange: {
                            update: [],
                        },
                        action: {
                            update: [],
                        },
                    },
                };

                // GET THE ACTION
                let action = FIND_OBJECT_ARRAY_ITEM(
                    projectData.action,
                    "id",
                    link.destinationItemId
                );

                // UPDATE ACTION LINKS LIST
                action.links = ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(action.links, govItem.id);

                // ADD ACTION TO MUTATION
                finalMutation.dispatcher.action.update.push(action);
                finalMutation.graphql.action.objects.push({
                    id: action.id,
                    links: action.links,
                });

                // UPDATE CURRENT SCOPE CHANGE ACTIONS IDS
                updatedScope = {
                    ...govItem,
                    actionsIds: FUNC_REMOVE_ELEMENT_FROM_ARRAY(
                        govItem.actionsIds,
                        link.destinationItemId
                    ),
                };

                // ADD SCOPE CHANGE TO MUTATION
                finalMutation.dispatcher.govScopeChange.update.push(updatedScope);
                finalMutation.graphql.govScopeChange.objects.push({
                    id: updatedScope.id,
                    actionsIds: updatedScope.actionsIds,
                });
            } else if (link.type === "govReview") {
                // INIT
                finalMutation = {
                    graphql: {
                        govScopeChange: {
                            query: updateTelescopeDataGovScopeChange,
                            objects: [],
                        },
                        govReview: {
                            query: updateTelescopeDataGovReview,
                            objects: [],
                        },
                    },
                    dispatcher: {
                        govScopeChange: {
                            update: [],
                        },
                        govReview: {
                            update: [],
                        },
                    },
                };

                // GET GOV REVIEW
                let govReview = FIND_OBJECT_ARRAY_ITEM(
                    projectData.govReview,
                    "id",
                    link.destinationItemId
                );

                // PARSE AGENDA
                let agenda = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(govReview.agenda);

                // REMOVE SCOPE ITEM FROM AGENDA
                agenda = agenda.filter(
                    (agendaItem) => agendaItem.destinationItemId !== govItem.itemId
                );

                // UPDATE GOV REVIEW WITH NEW AGENDA
                govReview = {
                    ...govReview,
                    agenda: JSON.stringify(agenda),
                };

                // ADD IT TO MUTATION
                finalMutation.dispatcher.govReview.update.push(govReview);
                finalMutation.graphql.govReview.objects.push({
                    id: govReview.id,
                    agenda: govReview.agenda,
                });

                // UPDATE CURRENT SCOPE CHANGE ACTIONS IDS
                updatedScope = {
                    ...govItem,
                    govReviewIds: FUNC_REMOVE_ELEMENT_FROM_ARRAY(
                        govItem.govReviewIds,
                        link.destinationItemId
                    ),
                };

                // ADD IT TO MUTATION
                finalMutation.dispatcher.govScopeChange.update.push(updatedScope);
                finalMutation.graphql.govScopeChange.objects.push({
                    id: updatedScope.id,
                    govReviewIds: updatedScope.govReviewIds,
                });
            }

            // UPDATE GRAPHQL
            for (let queryName of Object.keys(finalMutation.graphql)) {
                multipleMutateGraphql(
                    finalMutation.graphql[queryName].query,
                    finalMutation.graphql[queryName].objects,
                    appDispatcher
                );
            }

            //UPDATE APP STATE
            appDispatcher({
                type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
                object: finalMutation.dispatcher,
            });

            // UPDATE GOV STATE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    govItemView: updatedScope,
                    selected: [],
                },
            });
        },
        [appDispatcher, govDispatcher, govItem, projectData.action, projectData.govReview]
    );

    // OPEN DESTINATION LINK ITEM
    const onLinkItemClick = useCallback(
        (e, link) => {
            e.stopPropagation();

            if (!govItem) {
                return;
            }

            // BUILD WBS NAVIGATION OPTIONS
            let wbsNavigationOptions = _.cloneDeep(WBS_DEFAULT_NAVIGATION_OPTIONS);

            // SET ORIGIN
            wbsNavigationOptions.originDomain = "govScopeChange";
            wbsNavigationOptions.originItemId = govItem.id;

            // SET DESTINATION
            wbsNavigationOptions.goToItemId = link.destinationItemId;
            wbsNavigationOptions.goToDomain = link.type;
            wbsNavigationOptions.overrideWbsId = govItem.projectId;

            // SET OPTION TO OPEN CONNECTION TABS
            wbsNavigationOptions.options = {
                openLinksTab: true,
            };

            // OPEN THE DESTINATION ITEM
            WBS_OPEN_ITEM(
                projectData,
                link.type,
                link.destinationItemId,
                null,
                wbsNavigationOptions,
                wbsDispatcher,
                appDispatcher,
                securityGroup
            );
        },
        [appDispatcher, govItem, projectData, securityGroup, wbsDispatcher]
    );

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexColStartStart"
            style={{
                fontSize: "14px",
                width: "100%",
                // padding: "5px 0px",
            }}
        >
            {/* LINKS LIST */}
            {parsedLinks.map((link) => (
                <GovScopeLinksListItem
                    key={"govScopeCardLinkItem_" + link.destinationItemId}
                    link={link}
                    projectData={projectData}
                    onLinkItemClick={onLinkItemClick}
                    onDeleteLinkItem={onDeleteLinkItem}
                    isReadOnlyMode={isReadOnlyMode}
                />
            ))}

            {/* CASE NO ITEMS */}
            {parsedLinks.length === 0 ? (
                <div
                    className="cardDetailsNoData flexStartCenter"
                    // style={{
                    //     padding: "0px",
                    //     paddingLeft: "15px",
                    // }}
                >
                    <span
                        className="material-icons alertIconInfoLigth"
                        style={{
                            marginRight: "8px",
                            fontSize: "20px",
                        }}
                    >
                        info
                    </span>
                    No items
                </div>
            ) : null}
        </div>
    );
}
