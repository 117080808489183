import { useState } from "react";
import { useCallback } from "react";
import { memo } from "react";
import {
    GOV_REVIEW_MODAL_TYPE,
    GOV_REVIEW_SERIES_MODAL_OPTIONS,
} from "../../00-Helpers/GovReviewConstants";
import { CvtButton, CvtModal } from "@mi-gso/cvt";

///////////////////////////////////////
/// GOV REVIEW DELETE SERIES MODAL ////
///////////////////////////////////////

export default memo(function GovReviewSeriesModal({
    handleConfirmDelete,
    handleCancel,
    modalReview,
    modalType,
    onAddEditClick,
}) {
    ///////////////////////////////////
    /// STATE /////////////////////////
    ///////////////////////////////////

    const [state, setState] = useState({
        selectedOption: GOV_REVIEW_SERIES_MODAL_OPTIONS.onlyThis,
    });

    ///////////////////////////////////
    /// FUNCTIONS /////////////////////
    ///////////////////////////////////

    const handleChange = useCallback((clickedName) => {
        setState((prevState) => {
            return {
                selectedOption:
                    clickedName !== prevState.selectedOption
                        ? clickedName
                        : prevState.selectedOption,
            };
        });
    }, []);

    ///////////////////////////////////
    /// RENDER ////////////////////////
    ///////////////////////////////////

    return (
        <CvtModal
            title={`${modalType === GOV_REVIEW_MODAL_TYPE.delete ? "Delete" : "Edit"} review`}
            icon={`${modalType === GOV_REVIEW_MODAL_TYPE.edit ? "edit" : "delete"}`}
            resetFunction={handleCancel}
        >
            {/* BODY */}
            <div className="modal-body flexColStartStart modalBody">
                {/* ONLY THIS */}

                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={state.selectedOption === GOV_REVIEW_SERIES_MODAL_OPTIONS.onlyThis}
                        onChange={() => handleChange(GOV_REVIEW_SERIES_MODAL_OPTIONS.onlyThis)}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Only this occurrence
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={
                            state.selectedOption === GOV_REVIEW_SERIES_MODAL_OPTIONS.wholeSeries
                        }
                        onChange={() => handleChange(GOV_REVIEW_SERIES_MODAL_OPTIONS.wholeSeries)}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Whole series
                    </label>
                </div>
            </div>

            {/* FOOTER */}
            <div className="modal-footer modalBlockBg flexEndCenter">
                {/* DELETE BTN */}
                <CvtButton
                    txt={`${modalType === GOV_REVIEW_MODAL_TYPE.edit ? "Confirm" : "Delete"}`}
                    color={
                        modalType === GOV_REVIEW_MODAL_TYPE.delete ? "red" : "blue"
                    }
                    needConfirmation={modalType === GOV_REVIEW_MODAL_TYPE.delete}
                    handleSubmit={(e) => {
                        if (modalType === GOV_REVIEW_MODAL_TYPE.edit) {
                            e.preventDefault();
                            onAddEditClick(modalReview, false, state.selectedOption);
                        } else {
                            handleConfirmDelete(e, state.selectedOption);
                        }
                    }}
                />
            </div>
        </CvtModal>
    );
});
