import _ from 'lodash'
import {
	FUNCT_FIND_INDEX,
	FUNCT_FIND_INDEX_ARRAY,
	FUNC_DATE_TO_INPUT_DATETIME,
	FUNC_DATE_TO_TXT_STANDARD,
} from '../../../../00-Core/Standards'

//SIDE BAR DATA PREPARATION
export const EDIT_SIDE_BAR_DATA_PREPARATION = (
	data, //CURRENT ITEM STATE DATA
	standard, // ITEM STANDARD LOGIC
	ids, // ITEM ID, ORGA, PRO, WBS
	options // SPECIFIC OPTIONS FOR ATTRIBUTE
) => {
	//INIT
	let integratedStandard = _.cloneDeep(standard)
	let findIndex
	let dataAttributs

	//GET ALL ATTRIBUT OF DATA
	if (data) {
		dataAttributs = Object.keys(data)
	}
	//LOOP STANDARD
	for (let i = 0; i < integratedStandard.length; i++) {
		//MANAGE CORE ID
		if (integratedStandard[i].type === 'id') {
			switch (integratedStandard[i].key) {
				//ID
				case 'id':
					integratedStandard[i].value = ids.id
					integratedStandard[i].isNew = ids.isNew
					break

				//ORGA
				case 'organizationId':
					integratedStandard[i].value = ids.organizationId
					break

				//PROJECT
				case 'projectId':
					integratedStandard[i].value = ids.projectId
					break

				//WBS
				case 'wbsId':
					integratedStandard[i].value = ids.wbsId
					break

				//DISPLAY ID
				case 'displayId':
					integratedStandard[i].value = ids.displayId
					break

				//ERROR
				default:
					break
			}

			//MANAGE ALL OTHERS ATTRIBUTES
		} else {
			//ONLY IF DATA
			if (data) {
				//FIND KEY
				findIndex = FUNCT_FIND_INDEX_ARRAY(
					dataAttributs,
					integratedStandard[i].key
				)

				//ONLY IF FIND
				if (findIndex > -1) {
					/////////////
					/// DATES ///
					/////////////
					// IF FULL DATE TIME (Date and hours)
					if (
						integratedStandard[i].type === 'datetime-local' &&
						data[dataAttributs[findIndex]]
					) {
						let date = FUNC_DATE_TO_INPUT_DATETIME(
							data[dataAttributs[findIndex]]
						)
						integratedStandard[i].value = date
					}
					//IF DATE CONVERT
					else if (
						Object.prototype.toString.call(data[dataAttributs[findIndex]]) ===
						'[object Date]'
					) {
						integratedStandard[i].value = FUNC_DATE_TO_TXT_STANDARD(
							data[dataAttributs[findIndex]]
						)

						//IF CURRENT DATE CONVERT
					} else if (
						Object.prototype.toString.call(integratedStandard[i].value) ===
						'[object Date]'
					) {
						integratedStandard[i].value = FUNC_DATE_TO_TXT_STANDARD(
							integratedStandard[i].value
						)

						//IF DATE TEXT
					} else if (
						integratedStandard[i].type === 'date' &&
						integratedStandard[i].value !== null
					) {
						//GET DATE TXT
						let dateTxt = data[dataAttributs[findIndex]]
						//TEST IT INCLUDE TIME
						if (dateTxt && dateTxt.includes('T')) {
							//SPLIT DATE
							let splitDateTxt = dateTxt.split('T')
							integratedStandard[i].value = splitDateTxt[0]

							//NO TIME
						} else {
							integratedStandard[i].value = dateTxt
						}

						//////////////
						/// NUMBER ///
						//////////////
					} else if (integratedStandard[i].type === 'number') {
						//VALUE INTEGRATION
						if (
							data[dataAttributs[findIndex]] &&
							data[dataAttributs[findIndex]] !== ''
						) {
							integratedStandard[i].value = parseInt(
								data[dataAttributs[findIndex]]
							)
						} else {
							integratedStandard[i].value = ''
						}

						//////////////
						/// NORMAL ///
						//////////////
					} else {
						//VALUE INTEGRATION
						if (data[dataAttributs[findIndex]]) {
							integratedStandard[i].value = data[dataAttributs[findIndex]]
						} else {
							integratedStandard[i].value = ''
						}
					}
				}
			}

			///////////////
			/// OPTIONS ///
			///////////////

			if (options) {
				//TEST OPTIONS
				findIndex = FUNCT_FIND_INDEX(options, 'key', integratedStandard[i].key)

				//TETS IF FIND
				if (findIndex > -1) {
					//OPTIONS INTEGRATION
					integratedStandard[i].options = options[findIndex].value
				}
			}
		}
	}

	//RETURN INTEGRATED
	return integratedStandard
}
