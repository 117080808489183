import { Button } from "@mi-gso/cvt";
import Kalend, { CalendarView } from "kalend";
import "kalend/dist/styles/index.css"; // import styles
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FUNCT_FIND_INDEX, FUNC_DATE_TO_INPUT_DATETIME } from "../../../../../00-Core/Standards";
import { GOV_COMPONENT_EXPAND_TYPES } from "../../00-Helpers/GovConstants";
import { DEFAULT_REVIEW_ITEM } from "../../00-Helpers/GovDispatcher";
import {
    GOV_REVIEW_MEETING_STATUS,
    GOV_REVIEW_MEETING_STATUS_KEY,
    GOV_REVIEW_MODAL_TYPE,
} from "../../00-Helpers/GovReviewConstants";
import GovReviewContextMenu from "./GovReviewContextMenu";
import { WBS_OPEN_ITEM } from "../../../00-Wbs/00-Helpers/WbsFunctions";
import { AGENDA_ITEM_TYPES_KEYS } from "../../../../../00-Core/Constants";

/////////////////// COMPONENT /////////////////////
export default function GovReviewCalendar({
    // DATA
    events,
    updateTracker,
    wbsId,
    projectData,
    securityGroup,

    // DISPLAY
    expandMode,
    isReadOnlyMode,

    // USERS
    megaUsersIds,
    currentUser,
    isCurrentUserEditor,

    // FUNCTIONS
    onExpandArrowClick,
    onAddEditClick,
    onOpenReviewMode,
    onDeleteGovReview,
    handleMoveItemsClicked,
    handleShowModal,
    handleOpenWbsItem,
    wbsDispatcher,
    appDispatcher,

    isLoading,
}) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////
    const [state, setState] = useState({
        displayContextMenu: false,
        contextMenuX: null,
        contextMenuY: null,
        clickedItemId: null,
        keyProp: 0,
        calendarStart: new Date().toISOString(),
    });

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // MAPPED EVENTS FOR KALEND
    const kalendEvents = useMemo(() => {
        if (updateTracker) {
            // SO ESLINT IS HAPPY
            // JUST TO TRIGGER THE MEMO IF DATA IS UPDATED
        }

        return events.map((event) => {
            // GET EVENT STATUS CONFIG
            let findIndex = FUNCT_FIND_INDEX(
                GOV_REVIEW_MEETING_STATUS,
                "id",
                event.reviewMeetingStatus ?? GOV_REVIEW_MEETING_STATUS_KEY.notStarted
            );
            let status = GOV_REVIEW_MEETING_STATUS[findIndex];

            return {
                ...event,
                startAt: FUNC_DATE_TO_INPUT_DATETIME(event.startDate),
                endAt: FUNC_DATE_TO_INPUT_DATETIME(event.endDate),
                summary: event.name ?? "",
                color:
                    (event.recurrencyParentId || event.isRecurrencyParent) && findIndex === 0
                        ? "var(--color-alert)"
                        : status?.backgroundColor,
            };
        });
    }, [events, updateTracker]);
    
    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // RESET CONTEXT MENU
    const resetContextMenu = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            displayContextMenu: false,
        }));
    }, []);

    // CALLED AFTER CREATING EVENT AND RELEASED MOUSE
    const onNewEventClick = useCallback(
        (event) => {
            if (isReadOnlyMode) {
                return null;
            }

            let newReview = {
                ...DEFAULT_REVIEW_ITEM,
                startDate: new Date(event.startAt),
                endDate: new Date(event.endAt),
            };

            onAddEditClick(newReview, true);
            resetContextMenu();
        },
        [isReadOnlyMode, onAddEditClick, resetContextMenu]
    );

    // CALLED WHEN CLICK ON EXISTING EVENT
    const onEventClick = useCallback(
        (govReview) => {
            // IF CAN EDIT
            const canEdit = isCurrentUserEditor || govReview.createdBy === currentUser.username;
           
            if (
                isReadOnlyMode ||
                govReview.reviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.closed ||
                !canEdit
            ) {
                onOpenReviewMode(govReview, !isReadOnlyMode);
            } else if (canEdit) {
             
                if(wbsId !== govReview.wbsId) {
                    
                    const wbsNavigationOptions = {
                        isOpenSideBar: true,
                        goToItemId: govReview.id,
                        isScopeChange: false,
                    }
                    WBS_OPEN_ITEM(
                        // PROJECT DATA
                        projectData,
                        // TYPE
                        AGENDA_ITEM_TYPES_KEYS.review,
                        // DESTINATION ITEM ID
                        govReview.id,
                        // FILTERS
                        null,
                        // NAVIGATION OPTIONS
                        wbsNavigationOptions,
                        // WBS DISPATCHER,
                        wbsDispatcher,
                        // APP DISPATCHER
                        appDispatcher,
                        // SECURITY GROUP
                        securityGroup,
                    );
                }
                else {
                    if (govReview.recurrencyParentId || govReview.isRecurrencyParent) {
                        handleShowModal(null, govReview, GOV_REVIEW_MODAL_TYPE.edit);
                    }
                    else {
                        onAddEditClick(govReview, false);
                    }
                }
            }

        },
        [appDispatcher, currentUser.username, handleShowModal, isCurrentUserEditor, isReadOnlyMode, onAddEditClick, onOpenReviewMode, projectData, securityGroup, wbsDispatcher, wbsId]
    );

    // KALEND EVENT LISTNER
    const calendarRightClickEventListener = useCallback(
        (e) => {

            const targetPath = e.composedPath();

            // CLEAR CONTEXT
            setState((prevState) => {
                return {
                    ...prevState,
                    displayContextMenu: false,
                    contextMenuX: null,
                    contextMenuY: null,
                    clickedItem: null,
                }
            });

            // SEARCH FOR KALEND EVENT ID
            let foundId = null;
            let i = 0;
           
            while (!foundId && i < 5 && targetPath.length > i) {
                // IF EVENT CLASS FOUND, GET EVENT ID
                if (
                    targetPath[i].className &&
                    (
                        targetPath[i].className?.includes("Kalend__Event-normal") ||
                        targetPath[i].className?.includes("Kalend__Event-header")
                    )
                ) {
                    foundId = targetPath[i].id;
                }

                i++;
            }

            // RIGHT CLICK IS ON A KALEND EVENT
            if (foundId) {
                e.preventDefault();

                // CHECK ID IS A EVENT ID
                const findIndex = FUNCT_FIND_INDEX(events, "id", foundId);
                if (findIndex !== -1) {

                    
                        setState((prevState) => ({
                            ...prevState,
                            displayContextMenu: true,
                            contextMenuX: e.clientX,
                            contextMenuY: e.clientY,
                            clickedItem: events[findIndex],
                        }));
    
                        // WAIT FOR RENDER, AND FOCUS
                        setTimeout(() => {
                            document.querySelector(".contextMenuContent")?.focus();
                        }, 20)
                
                }
            }
        },
        [events]
    );
   
    /////////////////////////////////////////////////////
    // USE EFFECTS //////////////////////////////////////
    /////////////////////////////////////////////////////

    // ATTACH CONTEXT MENU LISTNER ON KALEND EVENTS
    useEffect(() => {
        // DISABLE DEFAULT CONTEXT MENU
        document.addEventListener("contextmenu", calendarRightClickEventListener);

        return () => {
            document.removeEventListener("contextmenu", calendarRightClickEventListener);
        };
    }, [calendarRightClickEventListener]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            id="govReviewCalendar"
            className="actionTableContainer noAnimations"
            style={{
                border: "1px solid var(--border-color-gray-0)",
                borderRadius: "8px",
                width:
                    expandMode === GOV_COMPONENT_EXPAND_TYPES.calendar
                        ? "100%"
                        : expandMode === GOV_COMPONENT_EXPAND_TYPES.table
                        ? "0%"
                        : "50%",
                margin:
                    expandMode === GOV_COMPONENT_EXPAND_TYPES.calendar
                        ? "0px 20px"
                        : "0px 20px 0px 10px",
            }}
        >
            {!isLoading ? (
                <React.Fragment>
                    {/* EXPAND ARROW */}
                    <Button
                        icon={
                            expandMode === GOV_COMPONENT_EXPAND_TYPES.calendar
                                ? "chevron_right"
                                : "chevron_left"
                        }
                        css="btn commonButtonSubmitLight actionTableExpandButton"
                        borderRadius="20px"
                        iconFontSize="16px"
                        handleSubmit={() => onExpandArrowClick(GOV_COMPONENT_EXPAND_TYPES.calendar)}
                    />

                    <Kalend
                        events={kalendEvents}
                        initialDate={state.calendarStart}
                        initialView={CalendarView.WEEK}
                        disabledViews={[CalendarView.DAY, CalendarView.THREE_DAYS]}
                        onNewEventClick={onNewEventClick}
                        onEventClick={onEventClick}
                        timeFormat={"24"}
                        weekDayStart={"Monday"}
                        language={"en"}
                        autoScroll={true}
                        disabledDragging={true}
                    />

                    {state.displayContextMenu ? (
                        <GovReviewContextMenu
                            contextMenuX={state.contextMenuX}
                            contextMenuY={state.contextMenuY}
                            resetContextMenu={resetContextMenu}
                            item={state.clickedItem}
                            megaUsersIds={megaUsersIds}
                            currentUser={currentUser}
                            isCurrentUserEditor={isCurrentUserEditor}
                            isReadOnlyMode={isReadOnlyMode}
                            onOpenReviewMode={onOpenReviewMode}
                            onDeleteGovReview={onDeleteGovReview}
                            handleMoveItemsClicked={handleMoveItemsClicked}
                            handleShowModal={handleShowModal}
                            wbsId={wbsId}
                        />
                    ) : null}
                </React.Fragment>
            ) : (
                <div
                    className="flexCenterCenter"
                    style={{
                        width: "100%",
                        height: "100%",
                        fontWeight: "bold",
                    }}
                >
                    LOADING REVIEWS....
                </div>
            )}
        </div>
    );
}
