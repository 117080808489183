// LIBS
import { useCallback, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";

// COMPONENTS
import { CvtButton,CvtModal, CvtModalBody, CvtModalFooter } from "@mi-gso/cvt";
import PortfolioCreateEditGlobalInfos from "./PortfolioCreateEditGlobalInfos";
import PortfolioCreateEditProjectList from "./PortfolioCreateEditProjectList";
import PortfolioCreateEditSelectedProjects from "./PortfolioCreateEditSelectedProjects";
import {CvtModalConfirmDelete} from '@mi-gso/cvt'
import UserManagementAddModal from "../01-UserManagement/UserManagementAddModal";
import UserManagementList from "../01-UserManagement/UserManagementList";

// FUNCTIONS
import { FUNCT_FIND_INDEX, FUNC_CHECK_URL_VALID } from "../../../../00-Core/Standards";


// GRAPHQL
import {
    createTelescopeDataPortfolio as CreateTelescopeDataPortfolio,
    deleteTelescopeDataPortfolio as DeleteTelescopeDataPortfolio,
    updateTelescopeDataPortfolio as UpdateTelescopeDataPortfolio,
} from "../../../../graphql/mutations";

// CONSTANTS
import { BOARD_CREATE_MANDATORY_FIELDS } from "../../00-Helpers/BoardConstants";
import { authCleanUserDataCookie } from "../../../00-App/01-Auth/00-Functions/AuthSignOut";



// PORTFOLIO CREATE / EDIT ROOT COMPONENT
export default function PortfolioCreateEdit({
    portfolios,
    portfolioEdit,
    portfolioEditUpdateTracker,
    portfolioHasChanged,
    portfolioSavingLoader,
    portfolioDeletingLoader,
    isEdit,
    isEditingRoles,
    isAddingUsers,
    organizationList,
    projectList,
    draggingType,
    dragDropActive,
    currentUserId,
    usersList,
    boardDispatcher,
    appDispatcher,
}) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    const [state, setState] = useState({
        showSaveErrModal: false,
        deleteConfirmationModalDisplayed: false,
    });


    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // IS SAVE POSSIBLE
    const isSavePossible = useMemo(() => {
        // CHECK MANDATORY FIELDS
        for (let mandatoryField of BOARD_CREATE_MANDATORY_FIELDS) {
            if (portfolioEdit[mandatoryField] === "" || !portfolioEdit[mandatoryField]) {
                return false;
            }
        }

        // CHECK PICTURE IS HTTPS
        if (portfolioEdit.pictureUrl !== "" && !FUNC_CHECK_URL_VALID(portfolioEdit.pictureUrl)) {
            return false;
        }

        return true;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioEdit, portfolioEditUpdateTracker]);


    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////
   
    // WHEN SELECTING A DRAGGABLE
    const onDragStart = useCallback((dragging) => {
        // We need to change the Dragging Type because if we choose an organization,
        // we don't want the projects droppable area to appear and vice versa
    
        let newType = "DEFAULT";

        if(dragging.draggableId?.includes("_")) {
            newType = dragging.draggableId.split("_")[0];
        }

        // SET TYPE FOR THE DESTINATION DROPPABLE
        boardDispatcher({
            type: "SET_DRAG_DROP",
            draggingType: newType,
            dndStatus: true,
        });

    }, [boardDispatcher]);

    // WHEN RELEASE A ORG/PROJECT ID
    const onDragEnd = (result) => {
        // DISABLE DND
        boardDispatcher({
            type: "SET_DRAG_DROP",
            draggingType: "DEFAULT",
            dndStatus: false,
        });

        // GET DROP INFOS
        let { source, destination, draggableId } = result;

        // IF OUTSIDE
        if (!destination) {
            // DO NOTHING
            return;
        }

        if(source.droppableId === destination.droppableId) return;

        // ADD SELECTED PROJECT / ORG
        if (
            source.droppableId !== "SelectedProjectsListColumn" &&
            destination.droppableId === "SelectedProjectsListColumn"
        ) {
            // IF ID CONTAINS UNDERSCORE, IT'S A PROJECT
            if (draggableId?.includes("_")) {
                let projectId = draggableId.split("_")[1];
                let newSelectedProjectList = [...portfolioEdit.selectedProjects, projectId];

                // UPDATE STATE
                boardDispatcher({
                    type: "SET_GLOBAL_STATE",
                    options: {
                        source: "portfolioEdit",
                        object: {
                            selectedProjects: newSelectedProjectList,
                        },
                    },
                });
            } else {
                // ELSE IT'S AN ORGANIZATION
                let newSelectedOrganizationList = [
                    ...portfolioEdit.selectedOrganizations,
                    draggableId,
                ];

                // REMOVE SELECTED PROJECTS THAT ARE ALONE IN THE ORGA
                let organizationProjectList = projectList.filter(
                    (project) => project.coreDataOrganizationCoreDataProjectId === draggableId
                );

                // GET THE LIST OF PROJECTS TO REMOVE
                let alreadySelectedProjectsToRemove = organizationProjectList.filter((project) =>
                    portfolioEdit.selectedProjects?.includes(project.id)
                );

                // REMOVE AND UPDATE STATE
                if (alreadySelectedProjectsToRemove.length > 0) {
                    let newSelectedProjectList = [...portfolioEdit.selectedProjects];

                    // REMOVE ALONE PROJECTS FROM SELECTED PROJECTS LIST
                    alreadySelectedProjectsToRemove.forEach((projectToRemove) => {
                        newSelectedProjectList = newSelectedProjectList.filter(
                            (selectedProjectId) => projectToRemove.id !== selectedProjectId
                        );
                    });

                    // UPDATE PROJECT LIST STATE
                    boardDispatcher({
                        type: "SET_GLOBAL_STATE",
                        options: {
                            source: "portfolioEdit",
                            object: {
                                selectedProjects: newSelectedProjectList,
                            },
                        },
                    });
                }

                // UPDATE ORGA LIST STATE
                boardDispatcher({
                    type: "SET_GLOBAL_STATE",
                    options: {
                        source: "portfolioEdit",
                        object: {
                            selectedOrganizations: newSelectedOrganizationList,
                        },
                    },
                });
            }
        }

        // REMOVE SELECTED ORG / PROJECT
        else if (
            source.droppableId === "SelectedProjectsListColumn" 
            // &&
            // destination.droppableId === "OrgProjectsListColumn"
        ) {
            const projectId = draggableId.split("_")[1];

            // CHECK IF ITEM IN PROJECT LIST
            let foundProject = FUNCT_FIND_INDEX(projectList, "id", projectId);
            
            // IF FOUND, IT'S A PROJECT
            if (foundProject !== -1) {
                let newSelectedProjectList = portfolioEdit.selectedProjects.filter(
                    (project) => project !== projectId
                );

                // UPDATE STATE
                boardDispatcher({
                    type: "SET_GLOBAL_STATE",
                    options: {
                        source: "portfolioEdit",
                        object: {
                            selectedProjects: newSelectedProjectList,
                        },
                    },
                });
            } else {
                // ELSE IT'S AN ORGANIZATION
                let newSelectedOrganizationList = portfolioEdit.selectedOrganizations.filter(
                    (org) => org !== draggableId
                );

                // UPDATE STATE
                boardDispatcher({
                    type: "SET_GLOBAL_STATE",
                    options: {
                        source: "portfolioEdit",
                        object: {
                            selectedOrganizations: newSelectedOrganizationList,
                        },
                    },
                });
            }
        }
    };

    // ON SAVE PORTFOLIO
    const onSave = useCallback(async () => {
        // ACTIVATE LOADER
        boardDispatcher({
            type: "SET_LOADER",
            name: "portfolioSaving",
            status: true,
        });

        // PREPARE OBJECT FOR GRAPHQL
        let graphQLPortfolio = { ...portfolioEdit };

        // CREATE ID IN CREATE MODE
        if (!isEdit) {
            graphQLPortfolio.id = uuid();
        }

        // SET CREATED BY
        if (!isEdit) {
            graphQLPortfolio.createdBy = currentUserId;
        }

        // DELETE GRAPHQL FIELDS
        delete graphQLPortfolio.createdAt;
        delete graphQLPortfolio.updatedAt;
        delete graphQLPortfolio.owner;

        // STRINGIFY AWSJSON
        graphQLPortfolio.selectedProjects = JSON.stringify(portfolioEdit.selectedProjects);
        graphQLPortfolio.selectedOrganizations = JSON.stringify(
            portfolioEdit.selectedOrganizations
        );

        // SAVE ON GRAPHQL
        await API.graphql({
            query: isEdit ? UpdateTelescopeDataPortfolio : CreateTelescopeDataPortfolio,
            variables: {
                input: graphQLPortfolio,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
            .then((response) => {
                if (
                    response.data.createTelescopeDataPortfolio ||
                    response.data.updateTelescopeDataPortfolio
                ) {
                    // TOAST SUCCESS
                    appDispatcher({
                        type: "SET_GLOBAL_STATE",
                        options: {
                            source: "appToast",
                            object: {
                                display: true,
                                color: "good",
                                message: "Portfolio saved",
                            },
                        },
                    });

                    // IF IN EDITION MODE
                    if (isEdit) {
                        // EDIT STATE
                        appDispatcher({
                            type: "SET_UPDATE_PORTFOLIO",
                            portfolio: graphQLPortfolio,
                        });
                    } else {
                        // ELSE IN CREATE MODE, ADD TO STATE
                        appDispatcher({
                            type: "SET_ADD_PORTFOLIO",
                            portfolio: graphQLPortfolio,
                        });
                    }

                    // EXIT PORTFOLIO EDITION / CREATION
                    boardDispatcher({
                        type: "SET_EXIT_PORTFOLIO_CREATION",
                    });
                }
            })
            .catch((e) => {
                console.log(e);

                // SHOW TOAST ERROR
                appDispatcher({
                    type: "SET_GLOBAL_STATE",
                    options: {
                        source: "appToast",
                        object: {
                            display: true,
                            color: "danger",
                            message: "Error while saving Portfolio",
                        },
                    },
                });

                // DISABLE LOADER
                boardDispatcher({
                    type: "SET_LOADER",
                    name: "portfolioSaving",
                    status: false,
                });
            });

        //DELETE USER DATAT COOKIE
        authCleanUserDataCookie();
    }, [appDispatcher, boardDispatcher, currentUserId, isEdit, portfolioEdit]);

    // ON DELETE PORTFOLIO
    const onDelete = useCallback(
        async (e) => {
            e.preventDefault();

            // ACTIVATE LOADER
            boardDispatcher({
                type: "SET_LOADER",
                name: "portfolioDeleting",
                status: true,
            });

            // REMOVE FROM GRAPHQL
            await API.graphql({
                query: DeleteTelescopeDataPortfolio,
                variables: {
                    input: { id: portfolioEdit.id },
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
            })
                .then((response) => {
                    if (response.data.deleteTelescopeDataPortfolio) {
                        // TOAST SUCCESS
                        appDispatcher({
                            type: "SET_GLOBAL_STATE",
                            options: {
                                source: "appToast",
                                object: {
                                    display: true,
                                    color: "good",
                                    message: "Portfolio deleted",
                                },
                            },
                        });

                        // EDIT STATE
                        appDispatcher({
                            type: "SET_DELETE_PORTFOLIO",
                            portfolioId: portfolioEdit.id,
                        });

                        // RETURN TO BOARD
                        boardDispatcher({
                            type: "SET_EXIT_PORTFOLIO_CREATION",
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);

                    // SHOW TOAST ERROR
                    appDispatcher({
                        type: "SET_GLOBAL_STATE",
                        options: {
                            source: "appToast",
                            object: {
                                display: true,
                                color: "danger",
                                message: "Error while deleting Portfolio",
                            },
                        },
                    });

                    // DISABLE LOADER
                    boardDispatcher({
                        type: "SET_LOADER",
                        name: "portfolioDeleting",
                        status: false,
                    });
                });

            //DELETE USER DATAT COOKIE
            authCleanUserDataCookie();
        },
        [appDispatcher, boardDispatcher, portfolioEdit.id]
    );


    // HANDLE SHOW/HIDE SAVE ERROR MODAL
    const handleShowHideModal = useCallback((e) => {
        e.stopPropagation();
        setState((prevState) => {
            return {
                ...prevState,
                showSaveErrModal: !prevState.showSaveErrModal,
                triedToSave: true,
            }
        });
    }, []);

    //



    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="structureBlock"
            style={{
                width: "100%",
                marginTop: "20px",
            }}
        >
            {/* HEADER */}
            <div className="structureBlockTitle flexBetweenCenter">
                <div className="flexStartCenter">
                    <span className="material-icons structureBlockTitleIcon boardTitleIcon">
                        category
                    </span>
                    Portfolio
                </div>

                {/* BUTTONS */}
                <div className="flexStartCenter" style={{ height: "30px" }}>
                    {/* DELETE */}
                    {isEdit  && (
                        <>
                            {/* DELETE BUTTON */}
                            <CvtButton
                                color="red"
                                txt="Delete"
                                margin="0px 20px 0px 0px"
                                icon="delete"
                                handleSubmit={() => {
                                    setState({ deleteConfirmationModalDisplayed: true });
                                }}
                            />

                            {/* CONFIRMATION MODAL */}
                            {state.deleteConfirmationModalDisplayed && (
                                <CvtModalConfirmDelete
                                    resetModalFunction={() => {
                                        setState({ deleteConfirmationModalDisplayed: false });
                                    }}
                                    validationText={portfolioEdit.name}
                                    deleteLoader={portfolioDeletingLoader}
                                    deleteFunction={onDelete}
                                />
                            )}
                        </>
                    )}

                    {/* SAVE */}
                    <CvtButton
                        txt="Save"
                        color={"blue"}
                        icon="save"
                        ////disabled={!isSavePossible || portfolioSavingLoader}
                        margin="0px 10px 0px 0px"
                        handleSubmit={

                            !isSavePossible || portfolioSavingLoader ? handleShowHideModal : onSave }
                    />

                    {/* CLOSE BUTTON */}
                    <CvtButton
                        color="white"
                        shape="noShape"
                        icon="close"
                        needConfirmation={portfolioHasChanged ? true : false}
                        txtConfirm="Exit without saving ?"
                        handleSubmit={() => {
                            // EXIT PORTFOLIO CREATION
                            boardDispatcher({
                                type: "SET_EXIT_PORTFOLIO_CREATION",
                            });
                        }}
                    />
                </div>
            </div>

            {/* CONTENT */}
            <div
                className="flexStartStrech"
                style={{
                    height: "calc(100% - 50px)",
                }}
            >
                {/* FIRST COLUMN : INFOS */}
                <PortfolioCreateEditGlobalInfos
                    portfolioEdit={portfolioEdit}
                    portfolios={portfolios}
                    boardDispatcher={boardDispatcher}
                />

                {/* MODAL EDIT ROLES */}
                {isEditingRoles && (
                    <CvtModal
                        icon="group"
                        title="Shared with"
                        widthPercent="80%"
                        resetFunction={() => {
                            // EXIT MODAL
                            boardDispatcher({
                                type: "SET_EXIT_ROLE_EDITION",
                            });
                        }}
                    >
                        {/* BODY */}
                        <CvtModalBody padding="0px">
                            {isAddingUsers ? (
                                // ADD USERS
                                <UserManagementAddModal
                                    usersList={usersList}
                                    portfolioEdit={portfolioEdit}
                                    projectList={projectList}
                                    boardDispatcher={boardDispatcher}
                                />
                            ) : (
                                // LIST USERS AND MANAGE ROLES
                                <UserManagementList
                                    usersList={usersList}
                                    currentUserId={currentUserId}
                                    portfolioEdit={portfolioEdit}
                                    projectList={projectList}
                                    boardDispatcher={boardDispatcher}
                                />
                            )}
                        </CvtModalBody>
                    </CvtModal>
                )}

                {/* COLUMNS ORGA/PROJECT LIST + SELECTED LIST */}
                <div
                    style={{
                        width: "66%",
                        padding: "20px 10px",
                    }}
                >
                    <span className="boardPortfolioCreateFormColumnTitle">Projects</span>

                    {/* COLUMNS CONTAINER */}
                    <div
                        className="flexStartStart"
                        style={{
                            width: "100%",
                            gap: "10px",
                            marginTop: "20px",
                        }}
                    >
                        {/* DRAG / DROP CONTEXT */}
                        <DragDropContext onDragEnd={onDragEnd} 
                        onDragStart={onDragStart}
                        // onBeforeCapture={onDragStart}
                        >
                            {/* COLUMN PROJECTS/ORGA LIST */}
                            <PortfolioCreateEditProjectList
                                organizationList={organizationList}
                                projectList={projectList}
                                selectedProjects={portfolioEdit.selectedProjects}
                                selectedOrganizations={portfolioEdit.selectedOrganizations}
                                dragDropActive={dragDropActive}
                                draggingType={draggingType}
                            />

                            {/* COLUMN SELECTED PROJECTS/ORGA LIST */}
                            <PortfolioCreateEditSelectedProjects
                                organizationList={organizationList}
                                projectList={projectList}
                                selectedProjects={portfolioEdit.selectedProjects}
                                selectedOrganizations={portfolioEdit.selectedOrganizations}
                                usersList={usersList}
                                itemViewers={portfolioEdit.itemViewers}
                                itemEditors={portfolioEdit.itemEditors}
                                draggingType={draggingType}
                                dragDropActive={dragDropActive}
                            />
                        </DragDropContext>

                         {/* SAVE ERROR MODAL */}
                         {

                            state.showSaveErrModal ?
                            <CvtModal
                                icon="save"
                                title="Save Error"
                                resetFunction={handleShowHideModal}
                            >
                                {/* BODY */}
                                <CvtModalBody padding="50px"
                                >
                                    <span  className="rightSideBarSaveErrorPortModalBody flexCenterCenter">
                                            Please fill out all mandatory fields to save
                                    </span>
                                </CvtModalBody>
                                <CvtModalFooter
                                    resetFunction={handleShowHideModal}
                                    resetName="Cancel"
                                >
                                </CvtModalFooter>
                            </CvtModal>
                        :
                        null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
