import { CvtButton, FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { APP_TXT_MEGA_USERS } from "../../../../00-Core/Constants";
import { FUNCT_FIND_INDEX_ARRAY } from "../../../../00-Core/Standards";
import { APP_PORTFOLIO_DISPLAY_CONFIG } from "../../../00-App/00-Helpers/AppInitState";
import GovReviewViewMode from "../../04-Governance/01-Components/01-GovReview/GovReviewViewMode";
import { WBS_DEFAULT_NAVIGATION_OPTIONS, WBS_RIGHT_COMPONENT } from "../00-Helpers/WbsConstants";
// import { WBS_GET_FILTERED_DATA_BY_WBS_BRANCHE } from "../00-Helpers/WbsFunctions";

export default function WbsRightComponent({
    componentName,
    data,
    currentUser,
    usersList,
    projectData,
    selectedWbsItem,
    updateTrackers,
    appDispatcher,
    wbsDispatcher,
    dataConsolidation,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // console.log(data)
    // const filteredProjectData = useMemo(() => {
    //     return WBS_GET_FILTERED_DATA_BY_WBS_BRANCHE(
    //         projectData,
    //         data.projectIds,
    //         data.isProject,
    //         data.projectId,
    //         dataConsolidation
    //     );
    // }, [data.isProject, data.projectId, data.projectIds, dataConsolidation, projectData]);

    // REVIEW MODE DATA
    const reviewData = useMemo(() => {
        // UPDATE IF UPDATE TRACKER CHANGES
        if (updateTrackers.govReview) {
        }

        // GET ITEM FROM DATA
        let item = FIND_OBJECT_ARRAY_ITEM(projectData.govReview, "id", data.govItem.id);

        return item;
    }, [data.govItem.id, projectData.govReview, updateTrackers.govReview]);

    //SECURITY GROUP ----------------------------------------------------------------
    const securityGroup = useMemo(() => {
        //MANAGE GROUPS CAN EDIT
        let groupEditors = [
            APP_TXT_MEGA_USERS,
            data.govItem.organizationMegaUsersGroup,
            data.govItem.projectMegaUsersGroup,
        ];

        //MANAGE GROUP CAN SEE
        let groupViewers = [data.govItem.projectUsersGroup];

        //isCurrentUserEditor
        let isCurrentUserEditor = false;

        //GET CURRENT USER GROUP
        let currentUserGroup = [];
        if (
            currentUser.user &&
            currentUser.user.signInUserSession &&
            currentUser.user.signInUserSession.accessToken.payload["cognito:groups"]
        ) {
            currentUserGroup =
                currentUser.user.signInUserSession.accessToken.payload["cognito:groups"];
        }

        //LOOP ON GROUPEDITORS NEED A LEAST ONE
        for (let i = 0; i < groupEditors.length; i++) {
            //FIND
            if (FUNCT_FIND_INDEX_ARRAY(currentUserGroup, groupEditors[i]) > -1) {
                isCurrentUserEditor = true;
                break;
            }
        }

        //RETURN
        return {
            groupEditors: groupEditors,
            groupViewers: groupViewers,
            isCurrentUserEditor: isCurrentUserEditor,
        };
    }, [
        currentUser.user,
        data.govItem.organizationMegaUsersGroup,
        data.govItem.projectMegaUsersGroup,
        data.govItem.projectUsersGroup,
    ]);

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // EXIT REVIEW MODE, GO BACK TO PREVIOUS WBS DISPLAY CONFIG
    const exitReviewMode = useCallback(() => {
        appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
                source: "portfolioDisplay",
                object: _.cloneDeep(APP_PORTFOLIO_DISPLAY_CONFIG),
            },
        });

        wbsDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                wbsNavigationOptions: { ...WBS_DEFAULT_NAVIGATION_OPTIONS },
                rightComponent: null,
            },
        });
    }, [appDispatcher, wbsDispatcher]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="structureBlock noAnimations wbsRightComponent"
            style={{
                height: "fit-content",
            }}
        >
            {/* TITLE */}
            <div
                className="structureBlockTitle flexBetweenCenter"
                style={{
                    position: "sticky",
                    top: "0px",
                    borderBottomColor: "var(--border-color-gray-1)",
                    borderRadius: "0px",
                    height: "51px",
                }}
            >
                <div className="flexStartCenter">
                    <span className="material-icons structureBlockTitleIcon boardTitleIcon">
                        calendar_month
                    </span>
                    Review Mode
                </div>

                {/* CLOSE BUTTON */}
                <CvtButton
                    color="white"
                    shape="noShape"
                    icon="close"
                    needConfirmation
                    txtConfirm="Sure ?"
                    handleSubmit={exitReviewMode}
                />
            </div>

            {/* BIG COMPONENT */}
            {componentName === WBS_RIGHT_COMPONENT.govReview ? (
                <GovReviewViewMode
                    key={`gov_review_view_mode_${updateTrackers.govReview}`}
                    govItem={reviewData}
                    scopeChanges={projectData.govScopeChange}
                    editMode={data.editMode}
                    handleUpdateGovReview={data.handleUpdateGovReview}
                    usersList={usersList}
                    currentUser={currentUser}
                    securityGroup={securityGroup}
                    projectData={projectData}
                    selectedWbsItem={selectedWbsItem}
                    appDispatcher={appDispatcher}
                    wbsDispatcher={wbsDispatcher}
                    projectIds={data.projectIds}
                    isProject={data.isProject}
                    projectId={data.projectId}
                />
            ) : null}
        </div>
    );
}
