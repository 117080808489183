import { CvtInput } from "@mi-gso/cvt";
import _ from "lodash";
import React from "react";
import { useCallback } from "react";
import { memo } from "react";
import { GOV_REVIEW_WEEK_DAYS } from "../../00-Helpers/GovReviewConstants";
import { CvtTooltip } from "@mi-gso/cvt";

/////////////////////////////////////////////////////
// GOVERNANCE REVIEW PERIODICITY SIDEBAR ////////////
/////////////////////////////////////////////////////

export default memo(function GovReviewPeriodicitySideBar({ periodicity, handleChange, disabled }) {
    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    const handleInnerInputChange = useCallback(
        (e) => {
            let newPeriodicity = _.cloneDeep(periodicity);

            newPeriodicity.weekGap = parseInt(e.target.value);

            // CALL EDIT SIDE BAR HANDLE CHANGE FUNC
            handleChange("periodicity", newPeriodicity, "weekGap");
        },
        [handleChange, periodicity]
    );

    // HANDLE CHANGE IN WEEKDAYS
    const handleWeekDayChange = useCallback(
        (weekDay) => {
            let newWeekDays = _.clone(periodicity.weekDays);

            newWeekDays = {
                ...newWeekDays,
                [weekDay]: !newWeekDays[weekDay],
            };

            handleChange("periodicity", { ...periodicity, weekDays: newWeekDays }, "weekDays");
        },
        [handleChange, periodicity]
    );

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <React.Fragment>
            {/* LEGENDS */}
            <div
                className="flexStartCenter"
                style={{
                    width: "100%",
                    marginTop: "8px",
                }}
            >
                <span
                    className="modalInputLegend"
                    style={{
                        color: "rgba(3, 169, 219, 0.6)",
                        fontSize: "12px",
                        margin: "0px 0px 5px",
                    }}
                >
                    Periodicity
                </span>

                <span
                    style={{
                        color: "var(--color-bad)",
                        marginLeft: "5px",
                    }}
                >
                    *
                </span>

                {/* TOOLTIP */}
                <span
                    className="material-icons"
                    style={{
                    cursor: "help",
                    color: "var(--border-color-gray-1)",
                    fontSize: "14px",
                    marginLeft: "4px",
                    marginBottom: "5px",
                    }}
                    
                    id="gov_review_periodicity_side_bar_periodicity"
                >
                    help_outline
                    <CvtTooltip
                        anchorId="gov_review_periodicity_side_bar_periodicity"
                        place="top"
                        content="Number of weeks apart between two instances"
                        theme="dark"
                    />
                </span>
            </div>

            <div
                className="flexColStartStart"
                style={{
                    marginLeft: "10px",
                    marginTop: "3px",
                    width: "100%",
                }}
            >
                {/* WEEK GAP */}
                <div className="flexStartCenter">
                    <span className="govReviewPeriodicitySideBarWeekTxt">Every</span>

                    <CvtInput
                        name="weekGap"
                        value={periodicity.weekGap}
                        type="number"
                        max={51}
                        min={1}
                        inputPadding="4px 4px 4px 8px"
                        inputFontSize="12px"
                        onChange={handleInnerInputChange}
                        mandatory={true}
                        inputWidthFull
                        inputWidth="50px"
                        inputBlockPadding="0px"
                        inputBlockMargin="0px"
                        inputUnitCss="bigComponentBodyMiniBlockTitleUnit"
                        inputCss="form-control modalInputLight" 
                        inputBlockCss="flexStartCenter modalOptionSubBlock marginBottomZero"
                    />

                    <span className="govReviewPeriodicitySideBarWeekTxt" style={{marginLeft: '3px'}}>Week(s) on:</span>
                </div>

                

                {/* WEEK DAYS */}
                <div
                    className="flexBetweenCenter"
                    style={{
                        width: "100%",
                        padding: "0px 10px 0px 0px",
                        marginTop: "15px",
                    }}
                >
                    {GOV_REVIEW_WEEK_DAYS.map((weekDay) => {
                        let isSelected =
                            periodicity.weekDays[weekDay.value] &&
                            periodicity.weekDays[weekDay.value] === true;

                        return (
                            <div
                                key={`gov_review_periodicity_side_bar_week_day_${weekDay.value}_${isSelected}`}
                                className="govReviewPeriodicityWeekDay flexCenterCenter"
                                style={{
                                    background: isSelected ? "var(--color-migso-blue)" : "",
                                    color: isSelected ? "white" : "",
                                    boxShadow: disabled ? "none" : "",
                                    transform: disabled ? "none" : "",
                                    cursor: disabled
                                        ? isSelected
                                            ? "default"
                                            : "not-allowed"
                                        : "",
                                }}
                                onClick={() =>
                                    !disabled ? handleWeekDayChange(weekDay.value) : {}
                                }
                            >
                                <span>{weekDay.label}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
});
