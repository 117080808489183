import _ from "lodash";
import React, { useCallback } from "react";
import { CvtMultipleCheckboxDropdown } from "@mi-gso/cvt";
/////////////////////////////////////
/// ACTION TABLE LEGEND COMPONENT ///
/////////////////////////////////////

export default function GovScopeTableLegend({
    selectLevelOptions,
    selectStatusOptions,
    isReadOnlyMode,
    govDispatcher,
    levelFilters,
    statusFilters,
}) {
    //////////////////////////////////////////////////////////////
    /// FUNCTIONS ////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    //RESET ALL FILTERS --------------------------------------- OK
    // const onStatusReset = useCallback(() => {
    //     govDispatcher({
    //         type: "SET_STATE_KEY_VALUE",
    //         key: "searchFilters",
    //         value: { ...DEFAULT_SCOPE_TABLE_FILTERS },
    //     });
    // }, [govDispatcher]);

    // // HANDLE CHANGES
    // const handleChanges = useCallback(
    //     (e) => {
    //         govDispatcher({
    //             type: "SET_STATE_KEY_VALUE",
    //             key: "searchFilters",
    //             value: {
    //                 ...searchFilters,
    //                 [e.target.name]: e.target.value,
    //             },
    //         });
    //     },
    //     [govDispatcher, searchFilters]
    // );


        // HANDLES SELECT OF FILTERS
  const handleSelectFilter = useCallback((e, property) => {

    let name = e.target.name;
    let value = e.target.checked;

    let filter = _.cloneDeep(property === "levelFilters" ? levelFilters : statusFilters);

    let newFilters = !value ? filter.filter((option) => option !== name) : [...filter, name];

    govDispatcher({
        type: 'SET_STATE_KEY_VALUE',
        key: property,
        value: newFilters,
    });
  }, [govDispatcher, levelFilters, statusFilters]);

  // HANDLE CLEAR ALL FILTERS FOR A SPECIFIC PROPERTY
  const handleClearAll = useCallback(property => {
    govDispatcher({
        type: 'SET_STATE_KEY_VALUE',
        key: property,
        value: [],
    });
  }, [govDispatcher])

    //////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    return (
        <div
            className="flexStartCenter"
            style={{
                marginLeft: isReadOnlyMode ? "0px" : "5px",
            }}
        >
            {/* LEVEL SELECT FILTER */}
            {/* <CvtSelect
                selectBlockCss="flexStartCenter"
                selectFontSize="12px"
                selectPlaceHolder="Level"
                name="level"
                value={searchFilters.level ?? ""}
                list={selectLevelOptions}
                listName="label"
                listValue="id"
                selectBlockMargin={"0px 10px"}
                selectPadding="3px 5px 3px 10px"
                selectWidth={"90px"}
                onChange={handleChanges}
            /> */}

            <CvtMultipleCheckboxDropdown
                options={selectLevelOptions}
                handleSelect={
                    (e) => handleSelectFilter(e, 'levelFilters')
                }
                selectedList={levelFilters}
                title="Level"
                blockCss="riskTableFilterBlockCss"
                titleBlockCss="riskTableFilterTitleBlockCss"
                keyName="levelFilters"
                handleClearAll={() => handleClearAll("levelFilters")}

            />


            {/* LEVEL STATUS FILTER */}
            {/* <CvtSelect
                selectBlockCss="flexStartCenter"
                selectFontSize="12px"
                selectPlaceHolder="Status"
                name="status"
                value={searchFilters.status ?? ""}
                list={selectStatusOptions}
                listName="label"
                listValue="id"
                selectPadding="3px 5px 3px 10px"
                selectWidth={"85px"}
                onChange={handleChanges}
            /> */}

            <CvtMultipleCheckboxDropdown
                options={selectStatusOptions}
                handleSelect={
                    (e) => handleSelectFilter(e, 'statusFilters')
                }
                selectedList={statusFilters}
                title="Status"
                blockCss="riskTableFilterBlockCss"
                titleBlockCss="riskTableFilterTitleBlockCss"
                keyName="statusFilters"
                customInlineBlockStyles={{
                    marginLeft: '10px',
                }}
                handleClearAll={() => handleClearAll("statusFilters")}
            />

            {/* RESET */}
            {
            // searchFilters.level || searchFilters.status ? (
            //     <Button
            //         css="btn commonButtonSubmitLightClose"
            //         icon="filter_alt_off"
            //         iconFontSize="20px"
            //         padding="0px"
            //         handleSubmit={onStatusReset}
            //         margin="0px 10px"
            //     />
            // ) : null
            }
        </div>
    );
}
