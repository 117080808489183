import React, { memo, useCallback, useMemo, useState } from "react";
// import {Tooltip} from "react-tooltip";

// ACTION
import ActionTableItem from "./ActionTableItem";
import ActionTableLegend from "./ActionTableLegend";
// import ActionTableSearch from "./ActionTableSearch";
import {
  ACTION_COMPONENT_EXPAND_TYPES,
  ACTION_STATUS_KEY,
} from "../../00-Helpers/ActionsConstants";

// CORE
import { Button, CvtButton, CvtSearchBar } from "@mi-gso/cvt";

// TOOLTIP
import { Tooltip, TooltipProvider } from "react-tooltip";

//////////////////////////////
/// ACTION TABLE COMPONENT ///
//////////////////////////////

export default memo(function ActionTable({
  //DATA
  filteredActions,
  // ORIGINAL ACTIONS
  actionData,
  statusSearchInput,
  legendAndCounter,
  textSearchInput,
  selectedList,
  expandMode,
  wbsId,
  editSideBar,
  viewMode,
  projectData,
  securityGroup,

  //USERS
  usersList,
  megaUsersId,

  //FUNCTIONS
  onClickAdd,
  onItemClick,
  onExpandArrowClick,
  isReadOnlyMode,
  displayWidth,
  handleOpenWbsItem,
  isCurrentUserEditor,
  //DISPATCHER
  actionsDispatcher,
  wbsDispatcher,
  appDispatcher,
}) {
  //////////////////////////////////////////////////////////////
  /// TOOLTIP //////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  //STATE
  // eslint-disable-next-line no-unused-vars
  const [tooltip, setTooltip] = useState({
    id: null,
    content: null,
  });

  //ON OVER
  const onOverTooltip = useCallback((e, id, content) => {
    e.preventDefault();
    setTooltip({
      id: id,
      content: content,
    });
  }, []);

  //////////////////////////////////////////////////////////////
  /// DATA /////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  //!!!!!!!!!!!!! sort data directly in app Dispatcher !!!!!!!
  //!!! on edit / dupplicate / delete !!!!!!!!///

  //SORT ACTION
  const sortedActions = useMemo(() => {
    if (!editSideBar || editSideBar || viewMode) {
      // FOR RENDER -- need to optimized from filteredActions

      //MAP TO ADD SORT INDEX
      let actionsWithStatus = filteredActions.map((item) => {
        //GET TIME FOR SORT NUMBER
        let sortNum = 0;

        //SWITCH STATUS
        switch (item.status) {
          //DONE
          case ACTION_STATUS_KEY.done:
            sortNum = Date.parse(item.realisedDate) || 0;
            break;

          //ON HOLD
          case ACTION_STATUS_KEY.onHold:
            sortNum = Date.parse(item.cancelledDate) || 0;
            break;

          //DEFAULT
          default:
            sortNum = Date.parse(item.dueDate) || 0;
            break;
        }

        //ADD SORT
        return {
          ...item,
          sort: sortNum,
        };
      });

      //SORT DATA
      let actionsSorted = actionsWithStatus.sort(function (a, b) {
        if (a.sort < b.sort) {
          return -1;
        }
        if (a.sort > b.sort) {
          return 1;
        }
        return 0;
      });

      //RETURN
      return actionsSorted;
    }
  }, [editSideBar, viewMode, filteredActions]);

  //////////////////////////////////////////////////////////////
  /// FUNCTION /////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  // HANDLE SELECT CHECKBOX ----------------------------------------------- OK
  // => NO RENDER FOR ACTION ITEM
  const handleSelectCheckboxIndividual = useCallback(
    (e, action) => {
      e.preventDefault();

      let selectedAction = { ...action };

      //CONVERT DATE
      selectedAction.createdOn = new Date(action.createdOn);
      selectedAction.dueDate = new Date(action.dueDate);

      // USE ACTIONS DISPATCHER
      actionsDispatcher({
        type: "SET_ACTIONS_SELECTION_INDIVIDUAL",
        action: selectedAction,
      });
    },
    [actionsDispatcher]
  );

  // HANDLE SELECT CHECKBOX ----------------------------------------------- OK
  // RENDER ON FILTERING BUT ONLY AT THE TABLE LEVEL
  const handleSelectCheckboxGlobal = useCallback(
    (e) => {
      e.preventDefault();

      // USE ACTIONS DISPATCHER
      actionsDispatcher({
        type: "SET_ACTIONS_SELECTION_GLOBAL",
        filteredActions: filteredActions,
      });
    },
    [actionsDispatcher, filteredActions]
  );

  // HANDLE CHANGE TEXT SEARCH INPUT
  const onChangeInput = useCallback(
    (e) => {
      //INIT
      let key = e.target.name;
      let value = e.target.value;

      //UPDATE APP DISPATCHER
      actionsDispatcher({
        type: "SET_STATE_KEY_VALUE",
        key,
        value,
      });
    },
    [actionsDispatcher]
  );

  // HANDLE RESET TEXT SEARCH INPUT
  const onChangeReset = useCallback(() => {
    actionsDispatcher({
      type: "SET_STATE_KEY_VALUE",
      key: "textSearchInput",
      value: "",
    });
  }, [actionsDispatcher]);

  // useEffect(() => {

  //   if(tooltip.id) {

  //     const actionItemId= tooltip.id.split("-").splice(1, tooltip.id.split("-").length + 1 ).reduce((accumulator, currentValue) => accumulator === "" ? currentValue : accumulator+"-"+currentValue, "");

  //     // Get actionID
  //     const actionItem = document.getElementById("actionId-" + actionItemId);

    //   if(actionItem) {
    //     // console.log(actionItem)
    //   }
    // }

  // },[tooltip])

  //////////////////////////////////////////////////////////////
  /// RENDER ///////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  return (
    <TooltipProvider>
      <div
        className="actionTableContainer "
        data-cy="actions-table-container"
        style={{
          width: displayWidth,
          display: displayWidth === "0%" ? "none" : null,
          margin:
            displayWidth === "0%"
              ? "0px"
              : expandMode === ACTION_COMPONENT_EXPAND_TYPES.tableOnly
              ? "0px 20px"
              : "0px 20px 0px 0px",
        }}
      >
        {/* STATUS TAGS AND SEARCH BAR */}
        <div
          className="flexBetweenCenter actionTableContainerHeader"
          style={{
            display: displayWidth === "0%" ? "none" : null,
          }}
        > 
          {
            actionData.length === 0 ?
            <div
              style={{
                color: "var(--color-text-2)",
                fontWeight: "600",
                fontSize: "14px",
                marginLeft: "20px",
                // marginBottom: "10px",
              }}
            >
              Actions
            </div>
          :null}
          

          {/* EXPAND ARROW */}
          <Button
            icon={
              expandMode === ACTION_COMPONENT_EXPAND_TYPES.tableOnly
                ? "chevron_right"
                : "chevron_left"
            }
            css="btn commonButtonSubmitLight actionTableExpandButton"
            borderRadius="20px"
            iconFontSize="16px"
            handleSubmit={() =>
              onExpandArrowClick(
                expandMode === ACTION_COMPONENT_EXPAND_TYPES.tableOnly
                  ? false
                  : true,
                false
              )
            }
          />

          {sortedActions.length > 0 ? (
            <>
              {/* SELECT - LEGEND */}
              <div className="flexStartCenter">
                {/* SELECT BOX */}
                {!isReadOnlyMode &&
                sortedActions.length > 0 &&
                isCurrentUserEditor ? (
                  <div
                    className={
                      "flexCenterCenter actionTableSelectBox " +
                      (selectedList.length === filteredActions.length
                        ? "actionTableSelectBoxActive"
                        : selectedList.length > 0
                        ? "actionTableSelectBoxMiActive"
                        : "")
                    }
                    onClick={handleSelectCheckboxGlobal}
                    style={{
                      minWidth: "16px",
                      minHeight: "16px",
                      maxWidth: "16px",
                      maxHeight: "16px",
                      margin: "0px 5px 0px 12px",
                    }}
                  >
                    {selectedList.length > 0 ? selectedList.length : ""}
                  </div>
                ) : null}

                {/* STATUS TAGS */}
                <ActionTableLegend
                  legendAndCounter={legendAndCounter}
                  statusSearchInput={statusSearchInput}
                  actionsDispatcher={actionsDispatcher}
                />
              </div>

              {/* SEARCH BAR */}
              <CvtSearchBar
                textSearchInput={textSearchInput}
                total={filteredActions.length}
                textSearchKey="textSearchInput"
                onChangeInput={onChangeInput}
                onChangeReset={onChangeReset}
              />
            </>
          ) : null}
        </div>

        {/* ACTIONS LIST BLOCK FOR RESPONSIVE*/}
        <div
          style={{
            height: "calc(100% - 13px)",
            width: "100%",
            display: displayWidth === "0%" ? "none" : null,
          }}
        >
          {/* ACTION LIST */}
          <div
            id="actionsTable"
            className="actionTable"
            style={{
              maxHeight: isReadOnlyMode ? "calc(100% - 60px)" : null,
            }}
          >
            {sortedActions.length > 0 ? (
              sortedActions.map((action) => {
                //IF ITEM SELECTED
                let isSelected =
                  selectedList.find(
                    (actionSelected) => actionSelected.id === action.id
                  ) !== undefined;

                //IS A CHILD
                let isChild = action.wbsId !== wbsId;

                //RETURN
                return (
                  <ActionTableItem
                    key={"actionTableItem-" + action.id}
                    action={action}
                    projectData={projectData}
                    isSelected={isSelected}
                    isChild={isChild}
                    isReadOnlyMode={isReadOnlyMode}
                    usersList={usersList}
                    megaUsersId={megaUsersId}
                    schedulesData={projectData.schedule}
                    onItemClicked={onItemClick}
                    onSelectFunction={handleSelectCheckboxIndividual}
                    onOverTooltip={onOverTooltip}
                    isCurrentUserEditor={isCurrentUserEditor}
                    isLinksShown={true}
                    securityGroup={securityGroup}
                    wbsDispatcher={wbsDispatcher}
                    appDispatcher={appDispatcher}
                    actionsDispatcher={actionsDispatcher}
                  />
                );
              })
            ) : null 
            }
          </div>

          {/* CREATE BUTTON - FOOTER */}
          <div className="actionTableCreateButton flexStartCenter">
            {isReadOnlyMode ? (
              <div></div>
            ) : (
              <CvtButton
                color="white"
                shape="noShape"
                size="small"
                icon="add"
                fullWidth
                txt={`Create ${actionData.length === 0 ? "your first" : "an"} action`}
                handleSubmit={onClickAdd}
                margin="5px 0px 0px"
              />
            )}
          </div>
        </div>
      </div>

      {/* TOOLTIP */}
      <Tooltip id="action-table-tooltip" className="tooltipCommon" />
    </TooltipProvider>
  );
});
