import React from 'react'

//CORE
import { 
    picCvtTriangleIcon,
} from '../../../00-Core/02-Pictures/PicturesCatalogue'

// HOME STEP TITLE COMPONENT
export function HomeStepTitle({
    stepPicture,
    altPicture,
    title
}) {
  return (
    <div
        className="homeStepTitleContainer flexStartCenter"
    >
        {/* STEP 1 TRIANGLE */}
        <img
            className='homeStepNumberTriangle'
            src={stepPicture}
            alt={altPicture}
        />
        {/* TITLE */}
        <span className="homeStepTitle">
            {title}
        </span>
    </div>
  )
}

// HOME STEP TABLE
export function HomeStepTable({
    stepData
}) {
  return (
    <div className="flexColBetweenCenter homeBoxShadow homeStepBulletBox">
        <div
            className="homeStepTableContainer flexColCenterCenter"
        >

            {/* MAP THE DATA */}
            {stepData.map((item, index)=>{
                
                //CALCULTAE THE PICTURE ROTATION DEG
                let deg = (index + 1)*30
                
                //RETURN
                return(
                    <div
                        key={item.key}
                        className="homeStepContentContainer flexStartStart"
                    >

                        {/* PICTURE */}
                        <div
                            className="homeStepTriangleContainer flexCenterCenter"
                        >
                            {/* TRIANGLE BULLET POINT */}
                            <img
                                alt=""
                                src={picCvtTriangleIcon}
                                className="homeStepBulletTriangle"
                                style={{
                                    transform: "rotate(" + deg + "deg)",
                                }}
                            />
                        </div>

                        {/* TEXT */}
                        <div
                            className="homeStepTextContainer flexColCenterStart"
                        >
                            <span className="homeStepText">
                                {item.text}
                            </span>
                            <div className="homeStepLine" />
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

//HOME STEP PICTURE
export function HomeStepPicture({
    stepPicture,
    altPicture,
    style
}) {
  return (
    <div
        className='flexColCenterCenter homeImgBox'
    >
        <img
            style={{...style}}
            src={stepPicture}
            alt={altPicture}
        />
    </div>
  )
}


//HOME QUALITIES TOPIC FOR HOME QUALITIES TABLE
function HomeQualitiesTopic({
    icon,
    iconClass,
    text,
    isLast,
    color,
}) {
    return (
        <div
            className="homeQualitiesTopicBigContainer flexColStartCenter"
        >
                <div
                    className="homeQualitiesTopicSmallContainer flexStartCenter"
                >
                    <div
                        className="homeQualitiesTopicIconContainer flexCenterCenter"
                    >
                        {/* ICON */}
                        <img
                            alt=""
                            src={icon}
                            className={iconClass}
                        />
                    </div>
                    <div
                        className="homeQualitiesTextContainer flexColCenterStart"
                    >   
                        {/* TEXT */}
                        <span className="homeQualitiesText">
                            {text}
                        </span>
                    </div>
                </div>
                {/* LINE */}
                {
                    !isLast ?
                    
                        <div
                            className='homeQualitiesLineContainer'
                        >
                            <div
                                className='homeQualitiesLine'
                                style={{
                                    borderLeft: `3px solid ${color}`,
                                }}
                            />
                        </div>
                    : null
                }
        </div>
    )
}

//HOME QUALITIES TABLE
export function HomeQualitiesTable({
    title,
    topics,
    color,
    icon,
    iconClass,

}) {
    return (
        <div
        className='homeQualitiesTableContainer'
        >
            {/* TITLE */}
                <div
                    className="homeQualitiesTitleContainer flexStartCenter"
                >
                    <span
                    className="homeQualitiesTitle"
                    style={{
                        color: color,
                    }}
                    >
                        {title}
                    </span>
                </div>
            {/* TOPICS BIG BLOCK */}
                <div
                    className="homeQualitiesBigBlockContainer flexColCenterCenter"
                >
                {
                    topics.map((e, i, arr) =>
                        {   const isLast = i + 1 === arr.length
                                return(
                                    <HomeQualitiesTopic
                                        key={e.key}
                                        icon={icon}
                                        iconClass={iconClass}
                                        text={e.text}
                                        color={color}
                                        isLast={isLast}
                                    />
                                )
                        }
                    )
                }
                </div>
        </div>
    )
}
