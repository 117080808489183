// GIVEN AN ITEM AND A FILTER, CHECK IF THAT ITEM PASSES THE FILTER
import _ from "lodash";
import { FUNCT_FIND_INDEX, FUNC_IS_STRING_JSON } from "../../../../00-Core/Standards";
import { DOMAIN_ACTION_DEPENDENCY_MAPPER, FILTERS_DOMAIN_INFO } from "./FiltersConstants";

//PROJECT TO REMOVE -------------------------------------------------------
export const FILTER_PROJECT_TO_REMOVE =(
  charterData,
  charterFilters,
  projectList
)=>{


  //PROJECT DATA TO REMOVE
  let charterDataFiltered = _.cloneDeep(charterData);
  let projectToRemove = _.cloneDeep(projectList)
  let charterFiltersWithValue = []
  let currentCharterInfos = FILTERS_DOMAIN_INFO.charter

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /// PREPRARE FILTERS VALUE EN ATTRIBUTES ////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //LOOP SUBDOMAIN
  for (let j = 0; j < charterFilters.length; j++) {

    //FIND IT
    let findIndex = FUNCT_FIND_INDEX(currentCharterInfos,"subDomain", charterFilters[j].subDomain)

    //TEST IF IT4S VALID EIN CASE OF
    if(findIndex > -1){
          charterFiltersWithValue.push({
            attribute : currentCharterInfos[findIndex].tables[0].attribute,
            value : charterFilters[j].value,
            valueType : currentCharterInfos[findIndex].type
          })
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /// FILTERS CHARTER DATA ////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //FILTER
  let newCharterData = charterDataFiltered.filter(item => {

    //LOOP ON FILTERS ATTRIBUTE => AND
    for (let k = 0; k < charterFiltersWithValue.length; k++) {

      //ITEM VALUE
      let filterValue = charterFiltersWithValue[k].value;
      let itemValue = item[charterFiltersWithValue[k].attribute];
    
      //TEST IF VALUE INCLUDED FOR OR THE REST FOR AND
      switch (charterFiltersWithValue[k].valueType) {

        //CHECKBOX
        case "checkbox":
          if(!filterValue?.includes(itemValue)){
            return false;
          }
          break;

        //CHECKBOX
        case "date":

          //FOR RANGE IF ONLY START DATE
          if(filterValue[0] !== "noFilter"){

            //INIT
            let itemDate = _.cloneDeep(itemValue);

            //TEST IF ITEM VALUE IS A DATE OBJECT
            if(Object.prototype.toString.call(itemValue) !== "[object Date]"){
              itemDate = new Date(itemValue)
            }

            //RESET HOURS
            itemDate.setHours(0, 0, 0, 0);

            //TETS IF RANGE
            if(filterValue.length > 1){

              //COMPARE
              let testRange = false
              if(filterValue[1].getTime() <= itemDate.getTime()
              && itemDate.getTime() <= filterValue[0].getTime()){
                testRange = true
              }
              return testRange;
              
            //NORMAL
            }else{

              //COMPARE
              if(itemDate.getTime() !== filterValue[0].getTime()){
                return false;
              }
            }
          }
          break;

        //CHECKBOX
        case "number":
          if(parseInt(filterValue,10) !== itemValue){
            return false;
          }
          break;
      
        //ERRROR TYPE DO NOT TREAT
        default:
          break;
      }
    }

    //RETURN THE RESULT TO KEEP OR NOT THE ITEM
    return true
  })
  
  /////////////////////////////////////
  /// MANAGE PROJECT LIST TO REMOVE ///
  /////////////////////////////////////

  if(newCharterData.length > 0){

    //GET PROJECT ID FROM CHARTER FILTEREDT
    let projectListFromCharterData = [...new Set(newCharterData.map(item => item.projectId))]; 

    //DELETE IF PRESENT IN CHARTER DATA
    let k = projectToRemove.length ;
    while (k--) {

      //GET INDEX
      if(projectListFromCharterData?.includes(projectToRemove[k])) {
        projectToRemove.splice(k, 1);
      }
    }
  }

  //RETURN IT
  return projectToRemove
}

//GET WBS DATA FILTERED BY FILTERS ------------------------------------------------------
export const FILTER_WBS_DATA = (
  projectData,
  wbsFilters
)=>{

  //INIT
  let projectDataFiltered = _.cloneDeep(projectData);
  let domainAttributes = Object.keys(FILTERS_DOMAIN_INFO);
  let tableToFilterWithValue = [];
  let findIndex;

  ///////////////////////////////////////////////////////////////////////
  /// PREPARER WICH TABLE TO FILTER WITH FILTERS ATTRIBUTES AND VALUE ///
  ///////////////////////////////////////////////////////////////////////

  for (let i = 0; i < domainAttributes.length; i++) {
    
    //GET IF CURRENT DOMAIN HAS A FILTER
    let wbsFiltersDomain = wbsFilters.filter(item => item.domain === domainAttributes[i])

    //SOMETHING TO TREAT
    if(wbsFiltersDomain.length > 0){

      //LOOP SUBDOMAIN
      for (let j = 0; j < wbsFiltersDomain.length; j++) {

        let currentDomainInfos = FILTERS_DOMAIN_INFO[domainAttributes[i]]
       
        //FIND IT
        findIndex = FUNCT_FIND_INDEX(currentDomainInfos,"subDomain", wbsFiltersDomain[j].subDomain)
        
        //TEST IF IT4S VALID EIN CASE OF
        if(findIndex > -1){

          //LOOP ON TABLE
          for (let k = 0; k < currentDomainInfos[findIndex].tables.length; k++) {
            
            //TEST IF TABLE ALREADY FILTERED 
            let findTableIndex = FUNCT_FIND_INDEX(tableToFilterWithValue,'table', currentDomainInfos[findIndex].tables[k].table)

            //MANAGE VALUE
            let valueInGoodType;
            switch (currentDomainInfos[findIndex].type) {
              
              // CHECKBOX
              case "checkbox":
                valueInGoodType = wbsFiltersDomain[j].value
                break;

              // DATE
              case "date":

                //END DATE
                let endDate = "noFilter"
                if(wbsFiltersDomain[j].value[0]){
                  endDate = new Date(wbsFiltersDomain[j].value[0])
                  endDate.setHours(0, 0, 0, 0);
                }
                
                //INTEGRATION END DATE
                valueInGoodType = [endDate]

                //TETS RANGE
                if(wbsFiltersDomain[j].value[1] && endDate[0] !== "noFilter"){
                  let startDate = new Date(wbsFiltersDomain[j].value[1])
                  startDate.setHours(0, 0, 0, 0);
                  valueInGoodType.push(startDate)
                }
                break;

              //CHECKBOX
              case "number":
                valueInGoodType = wbsFiltersDomain[j].value[0]
                break;
              // SELECT
              case "select":
                // ADD ARRAY OF IDS (VALUES)
                valueInGoodType = wbsFiltersDomain[j].value.map((option) => option.value);
                break;
              default:
                break;
            }

            //IF EXIST ADD FILTER
            if(findTableIndex > -1){
              tableToFilterWithValue[findTableIndex].filters.push({
                attribute : currentDomainInfos[findIndex].tables[k].attribute,
                altAttribute: currentDomainInfos[findIndex].tables[k].altAttribute,
                value : valueInGoodType,
                valueType : currentDomainInfos[findIndex].isOnlyNotNull ? "notNull" : currentDomainInfos[findIndex].type,
                jsonAttr: currentDomainInfos[findIndex].tables[k].jsonAttr,
              })

            //INTEGRATE NEW
            }else{
              tableToFilterWithValue.push({
                table : currentDomainInfos[findIndex].tables[k].table,
                filters: [
                  {
                    attribute : currentDomainInfos[findIndex].tables[k].attribute,
                    altAttribute: currentDomainInfos[findIndex].tables[k].altAttribute,
                    value : valueInGoodType,
                    valueType : currentDomainInfos[findIndex].isOnlyNotNull ? "notNull" : currentDomainInfos[findIndex].type,
                    jsonAttr: currentDomainInfos[findIndex].tables[k].jsonAttr,
                  },
                ]
              })
            }
          }
        }
      }
    }
  }
 
  ////////////////////////////////////////////////////////////////////////////////////////
  /// FILTER DATA ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  
  //GET ATTRIBUTE
  let projectDataAttributes = Object.keys(projectDataFiltered);

  // ARRAY OF IDS OF DEPENDENCIES FROM ACTIONS THAT WILL ALWAYS SHOW WHEN FILTERING ON REVIEWS
  let forceShowDependencyArray = [];
  let actionsToAdd = [];
  //LOOP
  for (let i = 0; i < projectDataAttributes.length; i++) {

    //TEST IF FILTER ACTIVE ON DATA DOMAIN
    let findFilterIndex = FUNCT_FIND_INDEX(tableToFilterWithValue, "table", projectDataAttributes[i])
 
    //ONLY FILTER IF NEEDED
    if(findFilterIndex > -1){

      //FILTER
      let newDomainData = projectDataFiltered[projectDataAttributes[i]].filter(item => {
        
        //LOOP ON FILTERS ATTRIBUTE => AND
        for (let k = 0; k < tableToFilterWithValue[findFilterIndex].filters.length; k++) {

          //ITEM VALUE
          let filterValue = tableToFilterWithValue[findFilterIndex].filters[k].value;
          let itemValue = item[tableToFilterWithValue[findFilterIndex].filters[k].attribute];

          // CHECK FOR ALTERNATIVE ATTRIBUTE IF FIRST FAILS
          if(!itemValue && tableToFilterWithValue[findFilterIndex].filters[k].altAttribute) {
            // ASSIGN ALTERNATIVE ATTRIBUTE
            itemValue = item[tableToFilterWithValue[findFilterIndex].filters[k].altAttribute];
          }
         
          //TEST IF VALUE INCLUDED FOR OR THE REST FOR AND
          switch (tableToFilterWithValue[findFilterIndex].filters[k].valueType) {

            //NOT NULL
            case "notNull" :
              if(!itemValue || itemValue === "" || itemValue.length === 0){
                return false;
              }  
              break;
            
            //CHECKBOX
            case "checkbox":
              if(!filterValue?.includes(itemValue)){
                return false;
              }
              break;

            //CHECKBOX
            case "date":

              //FOR RANGE IF ONLY START DATE
              if(filterValue[0] !== "noFilter"){

                //INIT
                let itemDate = _.cloneDeep(itemValue);

                //TEST IF ITEM VALUE IS A DATE OBJECT
                if(Object.prototype.toString.call(itemValue) !== "[object Date]"){
                  itemDate = new Date(itemValue)
                }

                //RESET HOURS
                itemDate.setHours(0, 0, 0, 0);

                //TETS IF RANGE
                if(filterValue.length > 1){

                  //COMPARE
                  let testRange = false
                  if(filterValue[1].getTime() <= itemDate.getTime()
                  && itemDate.getTime() <= filterValue[0].getTime()){
                    testRange = true
                  }
                  return testRange;
                  
                //NORMAL
                }else{
                  //COMPARE
                  if(itemDate.getTime() !== filterValue[0].getTime()){
                    return false;
                  }
                }
              }
              break;

            //CHECKBOX
            case "number":
              if(parseInt(filterValue,10) !== itemValue){
                return false;
              }
              break;
            // SELECT
            case "select":
              
              // IF ID IS INCLUDED IN THE FORCE DEPENDENCY ARRAY, THEN BREAK
              if(forceShowDependencyArray?.includes(item.id)) {

                // IF SCHEDULE/SCOPE CHANGES/RO => ADD ALL ACTIONS IDS TO ARRAY
                const actionsIds = item[DOMAIN_ACTION_DEPENDENCY_MAPPER[projectDataAttributes[i]]];
                if(actionsIds && Array.isArray(actionsIds)) {
                  // FOR EACH ACTION TO ADD
                  for(const actionId of actionsIds) {
                    // IF NOT IN THE ACTIONS TO ADD ARRAY ALREADY
                    if(actionId) {
                      const findIndex = actionsToAdd.findIndex((action) => action.id === actionId);
                      if(findIndex === -1) {
                        const action = projectData.action.find((action) => action.id === actionId);
                        if(action) {
                          actionsToAdd.push(action);
                        }
                      }
                    }
                  }
                }
                  

                break;
              }

              // IF NULL, THEN FILTER OUT
              if(filterValue.length > 0  && !itemValue) return false;
              // IF THE DEPENDENCY ONLY TAKES ONE ITEM
              if(typeof itemValue === "string" && !FUNC_IS_STRING_JSON(itemValue)) {
                if(!filterValue?.includes(itemValue)) return false;
                else break;
              }

              let isReturnTrue = false;
              // IF THE DEPENDENCY TAKES MULTIPLE ITEMS
              for(const filterOption of filterValue) {
                const checkArray = FUNC_IS_STRING_JSON(itemValue) ? JSON.parse(itemValue) : itemValue;
                
                const jsonAttr = tableToFilterWithValue[findFilterIndex].filters[k].jsonAttr;
              
                
                if(jsonAttr) {
                  const findIndex = checkArray.findIndex((option) => option[jsonAttr] === filterOption);
                  if(findIndex > -1) {
                    isReturnTrue = true;
                    // ADD ALL DEPENDENCIES TO THE IDS
                    if(projectDataAttributes[i] === "action") {
                      forceShowDependencyArray.push(
                        ...checkArray.filter((dependency) => dependency.type !== "govReview").map((dependency) => dependency.destinationItemId),
                      );
                    }
                    break;
                  }
                }
                else if(checkArray?.includes(filterOption)) {
                  isReturnTrue = true;

                  // NOT ACTION, SO ADD ALL DEPENDENCIES
                  
                  const actionsIds = item[DOMAIN_ACTION_DEPENDENCY_MAPPER[projectDataAttributes[i]]];
                  if(actionsIds && Array.isArray(actionsIds)) {
                    // FOR EACH ACTION TO ADD
                    for(const actionId of actionsIds) {
                      if(actionId) {
                        // IF NOT IN THE ACTIONS TO ADD ARRAY ALREADY
                        const findIndex = actionsToAdd.findIndex((action) => action.id === actionId);
                        if(findIndex === -1) {
                          const action = projectData.action.find((action) => action.id === actionId);
                          if(action) {
                            actionsToAdd.push(action);
                          }
                          
                        }
                      }
                      
                    }
                  }
                  break;
                }
              }

              if(!isReturnTrue) return false;
              
              break;
            //ERRROR TYPE DO NOT TREAT
            default:
              break;
          }
          
        }

        //RETURN THE RESULT TO KEEP OR NOT THE ITEM
        return true
      })

      //UPDATE RESULT TO PROJECT DATA
      projectDataFiltered[projectDataAttributes[i]] = newDomainData
    }
  }

  // SPECIAL FOR ACTIONS DEPEDENCIES
  if(actionsToAdd.length > 0) {
    for(const actionToAdd of actionsToAdd) {
      // CHECK IF ALREADY IN PROJECT DATA FILTERED
      const findIndex = projectDataFiltered.action.findIndex((action) => action.id === actionToAdd.id);
      
      // IF NOT, THEN ADD
      if(findIndex === -1) {
        projectDataFiltered.action.push(actionToAdd);
      }
    }
  }

  //RETURN FINAL
  return projectDataFiltered
}