import React, {useState, useEffect, useCallback} from 'react'
import {updateUserAttributes} from '../00-Functions/AuthAttributes'
import {changePassword} from '../00-Functions/AuthPassword'
import {Auth} from '@aws-amplify/auth'
import {CvtModal,CvtModalBody} from '@mi-gso/cvt'

//COMPONENT
import HeaderUserInfosEditName from './AuthUserInfosModalEditName'
import {
    HeaderUserInfosDisplayUser,
    HeaderUserInfosLoader,
    HeaderUserInfosSignOut,
    HeaderUserInfosForgetDevice
} from './AuthUserInfosModalMiniComponents'
import {HeaderUserInfosEditPassword} from './AuthUserInfosModalEditPassword'
import {HeaderUserInfosMfa} from './AuthUserInfosModalMfa'
import { authCleanUserDataCookie } from '../00-Functions/AuthSignOut'
import { FUNCT_FIND_INDEX } from '../../../../00-Core/Standards'
import HeaderUserInfosEditColors from './AuthUserInfosModalEditColor'
import { updateEnvUserByKeyAndValue, getEnvUserByName } from '../../02-Backend/AppBackendUser'

//USER INFOS MODAL
export default function HeaderUserInfos ({
    functionResetModal,
    signOut,
    users,
    appDispatcher
}) {

    /////////////
    /// STATE ///
    /////////////

    //GET USER
    const [user, setUser] = useState(null)
    const [edit, setEdit] = useState({
        loader: false,
        value:"",
        err:null,
        oldPassword:"",
        newPassword1:"",
        newPassword2:"",
        errName:null
    })

    //SWITCH TO EDIT MODE
    const handleEdit = useCallback(async (e, value) => {
        e.preventDefault()

        //IF VALUE === Name
        if(user && edit.value === "name" && user.name !== user.previousName){

            //GET VALIDATED NAME
            await Auth.currentAuthenticatedUser({bypassCache: true}).then((updatedUser)=>{
            
                //INTEGRATE COGNITO INFO
                setUser(prevUser => {
                    return({
                        ...prevUser,
                        name: updatedUser.attributes.name
                    })
                })
            
                //DEL USER DATA COOKIE
                authCleanUserDataCookie()
            })
        }

        //OK EDIT
        setEdit(prevEdit => {
            return({
                ...prevEdit,
                value: value
            })
        })
    },[edit.value, user])

    //ON CHANGE
    const onChange  = useCallback((e) => {
        setUser(prevUser => {
            return({
                ...prevUser,
                [e.target.name]: e.target.value
            })
        })
    },[])

    //Change PREFERRED MFA
    const onChangeMfa  = useCallback((e) => {

        //TEST PROMISE
        Auth.currentAuthenticatedUser({bypassCache: true}).then(
  
          //OK USER CONNECTED
          (updatedUser)=>{

            //UPDATE USER
            setUser(prevUser => {
                return({
                    ...prevUser,
                    ...updatedUser.attributes,
                    preferredMFA: updatedUser.preferredMFA
                })
            })

            //UPDATE VIEW
            setEdit(prevEdit => {
                return({
                    ...prevEdit,
                    loader: false,
                    value:"",
                    err:null,
                })
            })

            //DELETE USERDATA COOKIE
            authCleanUserDataCookie()
          }
        )
    },[])


    //////////////////
    /// ATTRIBUTES ///
    //////////////////

    //UPDATE ATTRIBUTE
    const handleUpdateAttributeName = useCallback(async (e) => {
        e.preventDefault()

        //LAUNCH LOADER
        setEdit(prevEdit => {
            return({
                ...prevEdit,
                loader: true,
                errName:null
            })
        })

        //TEST
        try{

            //TEST IF USER NOT ALREADY USED -> ANTICIPATION NEXT ARCHI
            let nameCheck = true
            if(user.previousName !== user.name) nameCheck = await getEnvUserByName(user.name)

            //LAUNCH UPDATE
            if(nameCheck){

                //UPDATE
                await updateUserAttributes(
                    "name",
                    user.previousName !== user.name ? user.name : null,
                    appDispatcher,
                    setEdit,
                    user.previousFirstName !== user['custom:firstName'] ? user['custom:firstName'] : null,
                    user.previousLastName !== user['custom:lastName'] ? user['custom:lastName'] : null,
                )

                //FIX
                setEdit(prevEdit => {
                    return({
                        ...prevEdit, 
                        loader: false,
                        value:"",
                        errName:null,
                        err:null
                    })
                })

            //USERNAME ALLREADY USED
            }else{
                setEdit(prevState => {
                    return({
                        ...prevState, 
                        loader:false,
                        errName: "Name already used!"
                    })
                })
                setUser(prevState => {
                    return({
                        ...prevState, 
                        errName: "Name already used!"
                    })
                })
            }
            
        //ERROR
        }catch(err){
            console.log(err)
            setEdit(prevState => {
                return({
                    ...prevState, 
                    loader:false,
                    errName: "Access Denied!"
                })
            })
        }
    },[appDispatcher, user])

    //UPDATE ATTRIBUTE
    const handleUpdateBackgroundColor = useCallback(async (e) => {
        e.preventDefault()

            //LAUNCH LOADER
            setEdit(prevEdit => {
                return({
                    ...prevEdit,
                    loader: true
                })
            })

            //API UPDATE
            await updateEnvUserByKeyAndValue(
                user.username,
                {
                    backgroundColor: user.backgroundColor
                }
            )


            //RESET LOADER
            setEdit(prevState => {
                return({
                    ...prevState, 
                    loader: false,
                    value:""
                })
            })

            //UPDATE APPDISPATCHER
            appDispatcher({
                type:"SET_USER_ATTRIBUTE",
                userId: user.username,
                key: "backgroundColor",
                value: user.backgroundColor
            })
    },[appDispatcher, user])

    ////////////////
    /// PASSWORD ///
    ////////////////

    //UPDATE PASSWORD
    const handleUpdatePasword = useCallback((e) => {
        e.preventDefault()

        //CHECK IF NEW PASSWORD 1 AND 2 ARE IDENTICal
        if(edit.newPassword1 !== edit.newPassword2){

            //LAUNCH LOADER
            setEdit(prevEdit => {
                return({
                    ...prevEdit,
                    err: "New password not the same"
                })
            })

        //OK GO
        }else{

            //LAUNCH LOADER
            setEdit(prevEdit => {
                return({
                    ...prevEdit,
                    loader: true
                })
            })

            //CALL BACKEND
            changePassword(
                edit.oldPassword,
                edit.newPassword1,
                setEdit
            )
        }
    },[edit])

     //ONCHANGE
     const onChangePassword  = useCallback((e) => {
        setEdit(prevEdit => {
            return({
                ...prevEdit,
                [e.target.name]: e.target.value
            })
        })
    },[])

    //////////////
    /// DEVICE ///
    //////////////

    const forgetDevice = useCallback(async (e) => {
        e.preventDefault();

        //TEST TO FORGET
        try{
            await Auth.forgetDevice();

            //UPDATE FRONT
            setEdit(prevState => {
                return({
                    ...prevState, 
                    loader: false,
                    value:"",
                    err:null,
                    oldPassword:"",
                    newPassword1:"",
                    newPassword2:""
                })
            })

            //LAUNCH TOAST
            signOut(
                appDispatcher,
                true
            )
        
        //CATCH ERROR
        }catch (error) {
            console.log('Error forgetting device', error)
        }
    },[appDispatcher, signOut])

    /////////////////////
    /// GET USER INFO ///
    /////////////////////

    //GET TEMPLATE IF USER ALREADY SIGNED
    useEffect(()=>{

        //TEST PROMISE
        Auth.currentAuthenticatedUser({bypassCache: true}).then((updatedUser)=>{

            //GET CURRENT USER INFO
            let findIndex = FUNCT_FIND_INDEX(users, "id", updatedUser.username)
      
            //INTEGRATE COGNITO INFO
            setUser(prevUser => {
                return({
                    ...prevUser,
                    ...updatedUser.attributes,
                    preferredMFA: updatedUser.preferredMFA,
                    backgroundColor: findIndex > -1? users[findIndex].backgroundColor : "#000",
                    username: updatedUser.username,
                    previousName:updatedUser.attributes.name,
                    previousFirstName:updatedUser.attributes['custom:firstName'],
                    previousLastName:updatedUser.attributes['custom:lastName'],
                })
            })
          
            //DEL USER DATA COOKIE
            authCleanUserDataCookie()
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



    //////////////////////////////
    /// MODAL COMPONENT RENDER ///
    //////////////////////////////

    return (
        <CvtModal 
            icon="person"
            title="User profil"
            resetFunction={functionResetModal}
        >

            {/* BODY */}
            <CvtModalBody padding="0px">

                {/* USER AVAILABLE */}
                {!user ?
                    <HeaderUserInfosLoader />
                :
                    <React.Fragment>

                        {/* CONNECTED USER  */}
                        <HeaderUserInfosDisplayUser 
                            email={user.email}
                            ssoConnection={user.identities}
                        />

                        {/* COLOR */}
                        <HeaderUserInfosEditColors
                            isEditMode={edit.value === "backgroundColor"}
                            backgroundColor={user.backgroundColor}
                            name={user.name}
                            onChange={onChange}
                            handleUpdateBackgroundColor={handleUpdateBackgroundColor}
                            errName={edit.errName}
                            loader={edit.loader}
                            handleEdit={handleEdit}
                        />

                        {/* NAME ------------------------------------------------ */}
                        {user.identities ? null :
                            <React.Fragment>
                                <HeaderUserInfosEditName 
                                    isEditMode={edit.value === "name"}
                                    name={user.name}
                                    firstName={user['custom:firstName']}
                                    lastName={user['custom:lastName']}
                                    onChange={onChange}
                                    handleUpdateAttributeName={handleUpdateAttributeName}
                                    errName={edit.errName}
                                    loader={edit.loader}
                                    handleEdit={handleEdit}
                                    updated={user.previousName !== user.name 
                                        ||  user.previousFirstName !== user['custom:firstName']
                                        || user.previousLastName !== user['custom:lastName'] 
                                    }
                                />

                                {/* EDIT PASSWORD --------------------------------------------- */}
                                <HeaderUserInfosEditPassword
                                    isEditMode={edit.value === "password"}
                                    error={edit.err}
                                    onChange={onChange}
                                    oldPassword={edit.oldPassword}
                                    newPassword1={edit.newPassword1}
                                    newPassword2={edit.newPassword2}
                                    onChangePassword={onChangePassword}
                                    handleEdit={handleEdit}
                                    handleUpdatePasword={handleUpdatePasword}
                                    loader={edit.loader}
                                />
                                
                            
                                {/* MFA ------------------------------------------------ */}
                                <HeaderUserInfosMfa 
                                    isEditMode={edit.value === "mfa"}
                                    handleEdit={handleEdit}
                                    onChangeMfa={onChangeMfa}
                                    user={user}
                                />
                            </React.Fragment>
                        }
                        
                        {/* USER LOGOUT ------------------------------------------*/}
                        <HeaderUserInfosSignOut 
                            signOut={signOut}
                            padding="5px 20px 15px 20px"
                        />

                        {/* FORGET DEVICE ------------------------------------------*/}
                        {user.identities ? null :
                            <HeaderUserInfosForgetDevice 
                                forgetDevice={forgetDevice}
                            />
                        }

                    </React.Fragment>
                }
            </CvtModalBody>
        </CvtModal>
    );
  };