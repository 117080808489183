import { useCallback } from "react";

// CREATE PORTFOLIO BUTTON
export default function PortfolioNewCard({ portfolios, boardDispatcher }) {
    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // GO TO CREATION MODE
    const onClick = useCallback(() => {
        boardDispatcher({
            type: "SET_SWITCH_MODE_CREATE_PORTFOLIO",
            portfolios: portfolios,
        });
    }, [boardDispatcher, portfolios]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div className="boardPortfolioListCard" onClick={onClick}>
            <div
                className="boardPortfolioListNewCard flexColCenterCenter"
                style={{
                    gap: "15px",
                }}
            >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                    add
                </span>

                <span>Create portfolio</span>
            </div>
        </div>
    );
}
