///////////////////////////////////////////////////////////////////////////
/// IMPORT ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

//PACKAGES
import React, { memo } from "react";

///////////////////////////////////////////////////////////////////////
/// SHORTCUT MINI COMPONENT  ///////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

export default memo(function ShortcutMini({
  // SHORCUT DATA 
  shortCutData,
  // ITEM ID
  itemId,
  // FIELD ID
  fieldId,
  // HANDLE BIF COMPONENT FUNCTION
  handleBigComponent,
  // IF BIG COMPONENT IS OPEN
  bigComponentDisplayed,
}) {
    
  ///////////////////////////////////////////////////////////////////////////
  /// COMPONENT RENDER ////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  return (
    <div
      onClick={(e) => handleBigComponent(e, itemId, fieldId)}
      className="flexCenterCenter"
      style={{
        cursor: "pointer",

        borderRadius: "15px",
        padding: "0px 5px",

        position: "relative",
      }}
    >
      <div
        className="material-icons shortcutMini"
        style={{
          fontSize: "22px",
          color: bigComponentDisplayed
            ? "var(--color-migso-blue)"
            : shortCutData.length > 0 ? "var(--color-text-3)" : "var(--border-color-gray-1)",
        }}
      >
        link
      </div>

      {/* {
        shortCutData.length > 0 && 
        
        <div className="shortcutMiniCount">
            {shortCutData.length}
        </div>
        } */}
    </div>
  );
});
