import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import React from "react";
import { ACTION_TXT_CONVERSION } from "../../00-Helpers/ActionsConstants";

/////////////////////////////
/// COMPONENT EVENTS LIST ///
/////////////////////////////

export default function ActionCardDetailsEvents({ eventsArray, usersList }) {
    //////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER /////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    // IF NO EVENTS RETURN NULL
    if (eventsArray.length === 0) {
        return null;
    }

    return eventsArray.map((event, index) => {
        // INIT
        let lastValue = null;
        let updatedValue = null;
        let attribute;
        let text;
        let isDate;
        let dateText;

        // GET CURRENT DATE OBJECT
        let currentDate = new Date(event.createdOn);

        // GET CURRENT DATE TEXT
        dateText = currentDate.toLocaleString(undefined, {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
        });

        // SPECIFIC CASE FOR LINKS
        if (event?.attribute === "links") {
            // GET USER NAME
            let username = FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.userId, "label");

            // GET USER NAME FROM LABEL
            if (username) {
                username = username.split(" ")[0];
            } else {
                username = "an unknown user";
            }

            text = "New dependencies added by " + username;

            updatedValue = event.newValue;
        } else if (event?.attribute === "linksDeletion") {
            // GET USER NAME
            let username = FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.userId, "label");

            // GET USER NAME FROM LABEL
            if (username) {
                username = username.split(" ")[0];
            } else {
                username = "an unknown user";
            }

            text = "Dependency deleted by " + username;

            updatedValue = event.newValue;
        }
        // OTHER ATTRIBUTES
        else {
            // GET LAST AND UPDATED VALUE IF NOT DESCRIPTION
            if (!event?.value?.includes("description")) {
                //LAST VALUE
                if (event.lastValue) {
                    //TEST IF DATA
                    isDate = Date.parse(event.lastValue) || null;

                    //LOACAL STRING IF DATE
                    if (isDate && !event.lastValue?.includes(" ")) {
                        lastValue = new Date(event.lastValue).toLocaleString(undefined, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        });

                        //MANAGE TEXT WITH CONVERSION
                    } else {
                        attribute = event.lastValue.split(" ");
                        lastValue = event.lastValue;
                        if (ACTION_TXT_CONVERSION[attribute[0]]) {
                            lastValue = lastValue.replace(
                                attribute[0],
                                ACTION_TXT_CONVERSION[attribute[0]]
                            );
                        }
                    }
                }

                //UPDATED VALUE
                if (event.updatedValue) {
                    //TEST IF DATA
                    isDate = Date.parse(event.updatedValue) || null;

                    //IF DATE GET LOCALE STRING
                    if (isDate) {
                        updatedValue = new Date(event.updatedValue).toLocaleString(undefined, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        });

                        //MANAGE TEXT WITH CONVERSION
                    } else {
                        attribute = event.updatedValue.split(" ");
                        updatedValue = event.updatedValue;
                        if (ACTION_TXT_CONVERSION[attribute[0]]) {
                            updatedValue = updatedValue.replace(
                                attribute[0],
                                ACTION_TXT_CONVERSION[attribute[0]]
                            );
                        }
                    }
                }
            }

            if (event?.attribute !== "links" && event?.attribute !== "linksDeletion") {
                // GET CONVERSION TEXT
                if (event.attribute) {
                    attribute = event.attribute.split(" ");
                } else {
                    attribute = event.value.split(" ");
                }
                text = event.attribute || event.value;
                if (ACTION_TXT_CONVERSION[attribute[0]]) {
                    text = text.replace(attribute[0], ACTION_TXT_CONVERSION[attribute[0]]);
                }
            }

            if(event?.attribute === "createdBy") {
                text = "Created by: " + FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.newValue, "label");
            }
        }

        //////////////
        /// RENDER ///
        //////////////

        return (
            <div key={"actionEvent-" + index} className="actionCardDetailsEvent flexStartStart">
                {/* DATE */}
                <div
                    className="actionCardInfosProgressBarDateLegend"
                    style={{
                        marginRight: "5px",
                    }}
                >
                    {dateText}
                </div>

                {/* RIGHT BLOCK */}
                <div style={{ width: "100%" }}>
                    {/* VALUE */}
                    <div
                        style={{
                            fontWeight: 500,
                        }}
                    >
                        {text}
                    </div>

                    <div className="flexStartCenter" style={{ width: "100%" }}>
                        {/* LAST VALUE IF AVAILABLE */}
                        {event.attribute !== "links" &&
                        event.attribute !== "linksDeletion" &&
                        updatedValue ? (
                            <React.Fragment>
                                <span
                                    style={{
                                        color: "var(--color-text-4)",
                                        maxWidth: "50%",
                                        wordBreak: "break-word",
                                    }}
                                >
                                    {lastValue ? lastValue : "none"}
                                </span>
                                <span
                                    className="material-icons"
                                    style={{
                                        fontSize: "20px",
                                        margin: "0px 5px",
                                    }}
                                >
                                    navigate_next
                                </span>
                            </React.Fragment>
                        ) : null}

                        {/* UPDATED VALUE OR JUST EVENT VALUE */}
                        {event.attribute === "links" || event.attribute === "linksDeletion" ? (
                            <ul
                                style={{
                                    color: "var(--color-migso-blue)",
                                    maxWidth: "50%",
                                    wordBreak: "break-word",
                                    marginBottom: "0px",
                                    paddingInlineStart: "20px",
                                }}
                            >
                                {updatedValue.map((link, index) => (
                                    <li key={`action_details_events_${link.text}_${index}`}>{link.text}</li>
                                ))}
                            </ul>
                        ) : (
                            <span
                                style={{
                                    color: "var(--color-migso-blue)",
                                    maxWidth: "50%",
                                    wordBreak: "break-word",
                                }}
                            >
                                {updatedValue}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    });
}
