import { ADD_ITEM_EVENT, FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import _ from "lodash";
import { AGENDA_ITEM_TYPES_KEYS, TODAY } from "../../../../00-Core/Constants";
import {
    FUNCT_FIND_INDEX,
    FUNC_CHECK_DATE2_IS_AFTER_DATE1,
    FUNC_CHECK_DATES_SAME_DAY,
    FUNC_GET_DAYS_BETWEEN_DATES,
    FUNC_GET_DIFFERENCE_BETWEEN_OBJECTS_ARRAY,
    FUNC_GET_MONDAY_FROM_DATE,
    FUNC_REMOVE_ELEMENT_FROM_ARRAY,
    FUNC_REMOVE_OBJECT_FROM_ARRAY,
    FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING,
    FUNC_SAFE_GET_JSON_FROM_STRING,
} from "../../../../00-Core/Standards";
import {
    createTelescopeDataGovReview,
    createTelescopeDataWbsSettings,
    deleteTelescopeDataAction,
    deleteTelescopeDataGovReview,
    deleteTelescopeDataGovScopeChange,
    deleteTelescopeDataRO,
    deleteTelescopeDataSchedule,
    updateTelescopeDataAction,
    updateTelescopeDataGovReview,
    updateTelescopeDataGovScopeChange,
    updateTelescopeDataRO,
    updateTelescopeDataWbsSettings,
} from "../../../../graphql/mutations";
import { multipleMutateGraphql } from "../../../00-App/02-Backend/AppBackendCommon";
import { WBS_BIG_COMPONENT_VIEW_MODE, WBS_ITEMS_LINKS } from "../../00-Wbs/00-Helpers/WbsConstants";
import { GOV_ITEM_VIEW_TYPE } from "./GovConstants";
import { DEFAULT_REVIEW_ITEM } from "./GovDispatcher";
import {
    FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND,
    GOV_FUNC_GET_NEXT_VIEW_MODE,
    GOV_FUNC_UPDATE_PATH,
    MERGE_GOV_REVIEW_MUTATIONS,
} from "./GovFunctions";
import {
    GOV_REVIEW_AGENDA_DEFAULT_ITEM,
    GOV_REVIEW_MODAL_TYPE,
    GOV_REVIEW_SERIES_MODAL_OPTIONS,
} from "./GovReviewConstants";
import { FUNC_ACTION_CREATE_LINK_OBJECT } from "../../01-Action/00-Helpers/ActionsFunctions";
import { v4 as uuid } from "uuid";

/////////////////////////////////////////////////
////////////////// SUMMARY //////////////////////
/////////////////////////////////////////////////
// ADD_ITEM_TO_AGENDA
// DELETE_GOV_REVIEW_WITH_LINKS
// DELETE_RECURRENT_GOV_REVIEW_AND_LINKS
// GET_AGENDA_LINK_MUTATIONS
// GET_ALL_REVIEWS
// GET_DELETE_GOV_REVIEW_MUTATION
// GET_GOV_LINK_ATTRIBUTE_NAME
// GET_MUTATION_TO_DELETE_ITEM_FROM_REVIEW_AGENDA
// GET_NEW_AGENDA_ITEM_OBJECT
// GET_UPDATED_PERIODICITY
// GET_UPDATED_TAGS_MUTATION
// GOV_FUNC_GET_AGENDA_TYPE_ITEMS
// GOV_FUNC_GET_RECURRENT_REVIEWS
// GOV_FUNC_GET_UPDATED_REVIEWS
// GOV_FUNC_GET_WEEK_DAYS_OBJ
// GOV_FUNC_GET_WEEK_DAY_INCREMENT
// GOV_FUNC_MOVE_PARENT_START
// GOV_FUNC_SAVE_REVIEW
// GOV_REVIEW_MODE_UPDATE_DISPLAY
// MERGE_GOV_REVIEW_MUTATIONS
// REMOVE_ITEM_FROM_AGENDA
// RUN_DELETE_FINAL_MUTATION
// SWITCH_RECURRENCY_ON_OFF
/////////////////////////////////////////////////

//////////////////////////////////////
//////// MUTATIONS OBJECTS ///////////
//////////////////////////////////////

// DELETE FINAL MUTATIONS
const DELETE_FINAL_MUTATION = {
    graphql: {
        action: {
            query: updateTelescopeDataAction,
            objects: [],
        },
        riskOpp: {
            query: updateTelescopeDataRO,
            objects: [],
        },
        govScopeChange: {
            query: updateTelescopeDataGovScopeChange,
            objects: [],
        },
        govReview: {
            query: deleteTelescopeDataGovReview,
            objects: [],
        },
    },
    dispatcher: {
        action: {
            update: [],
        },
        riskOpp: {
            update: [],
        },
        govScopeChange: {
            update: [],
        },
        govReview: {
            create: [],
            update: [],
            delete: [],
        },
    },
};

// DELETE FINAL MUTATIONS
const CREATE_FINAL_MUTATION = {
    graphql: {
        action: {
            query: updateTelescopeDataAction,
            objects: [],
        },
        riskOpp: {
            query: updateTelescopeDataRO,
            objects: [],
        },
        govScopeChange: {
            query: updateTelescopeDataGovScopeChange,
            objects: [],
        },
        govReview: {
            query: createTelescopeDataGovReview,
            objects: [],
        },
    },
    dispatcher: {
        action: {
            update: [],
        },
        riskOpp: {
            update: [],
        },
        govScopeChange: {
            update: [],
        },
        govReview: {
            create: [],
            update: [],
            delete: [],
        },
    },
};

// SAVE GOV REVIEW
export function GOV_FUNC_SAVE_REVIEW(
    currentPath,
    currentUser,
    projectData,
    isNew,
    itemMutation,
    securityGroup,
    modalType,
    selectedModalOption,
    wbsSettings,
    appDispatcher,
    govDispatcher
) {
    // INIT
    let govReviews = projectData.govReview;
    let userId = currentUser.username;
    let finalMutation = _.cloneDeep(CREATE_FINAL_MUTATION);
    let parentUpdated = null;
    let fullParentUpdated = null;
    let eventsUpdated = null;
    let childrenReviews = {
        create: [],
        delete: [],
        update: [],
    };
    let reviewItem;

    // CONVERT REVIEW DATES TO DATE
    if (Object.keys(itemMutation)?.includes("startDate")) {
        itemMutation.startDate = new Date(itemMutation.startDate);
    }
    if (Object.keys(itemMutation)?.includes("endDate")) {
        itemMutation.endDate = new Date(itemMutation.endDate);
    }

    // UPDATE WBS TAGS
    if (Object.keys(itemMutation)?.includes("tags")) {
        let wbsMutation = GET_UPDATED_TAGS_MUTATION(wbsSettings, itemMutation, appDispatcher);
        finalMutation.graphql = { ...finalMutation.graphql, ...wbsMutation };
    }

    // CONVERT AWSJSON AND KEEP ONLY IDS OF ATTENDEES
    if (Object.keys(itemMutation)?.includes("attendees")) {
        // PARSE JSON
        itemMutation.attendees = FUNC_SAFE_GET_JSON_FROM_STRING(itemMutation.attendees);

        // INIT IF NULL
        if (!itemMutation.attendees) {
            itemMutation.attendees = [];
        }

        // IF ATTENDEES MUTATION COME FROM EDIT SIDE BAR,
        // CONVERT SELECT INPUT ITEMS
        itemMutation.attendees = itemMutation.attendees.map((attendee) => {
            // IF IT COME FROM THE INPUT FROM EDIT SIDEBAR
            if (attendee.value) {
                // CONVERT
                return {
                    id: attendee.value,
                    present: false,
                };
            }

            // OTHERWISE, DONT CHANGE THE VALUE
            return attendee;
        });

        // STRINGIFY
        itemMutation.attendees = JSON.stringify(itemMutation.attendees);
    }

    // UPDATE PATH
    itemMutation.path = GOV_FUNC_UPDATE_PATH(isNew, itemMutation, currentPath);

    // IF CREATING NEW ITEM
    if (isNew) {
        // ADD CREATION EVENT
        eventsUpdated = ADD_ITEM_EVENT(itemMutation, itemMutation, userId);

        // BUILD FINAL PARENT REVIEW OBJECT
        parentUpdated = {
            ...itemMutation,
            updatedAt: new Date(),
            createdBy: userId,
            createdOn: TODAY,
            events: JSON.stringify(eventsUpdated),
            groupEditors: securityGroup.groupEditors,
            groupViewers: securityGroup.groupViewers,
        };

        // GET RECURRENT REVIEWS IF ACTIVATED
        if (itemMutation.isRecurrencyParent === true) {
            // ADD
            let childrenObject = {
                ...parentUpdated,
            };

            // GET RECURRENT REVIEWS TO CREATE
            childrenReviews.create = GOV_FUNC_GET_RECURRENT_REVIEWS(
                childrenObject,
                currentUser,
                securityGroup
            );
        }

        // CLEAN OBJECT
        delete parentUpdated.govItemType;

        // PARSE PERIODICITY
        if (parentUpdated.periodicity) {
            parentUpdated.periodicity = JSON.stringify(parentUpdated.periodicity);
        }

        // ADD PARENT TO FINAL MUTATION
        finalMutation.graphql.parentReviewMutation = {
            query: createTelescopeDataGovReview,
            objects: [parentUpdated],
        };

        // GET DEFAULT REVIEW ITEM
        let defaultItem = _.cloneDeep(DEFAULT_REVIEW_ITEM);

        // ADD ALL DEFAULT VALUES TO APP DATA
        fullParentUpdated = {
            ...defaultItem,
            ...parentUpdated,
        };

        // ADD PARENT TO DISPATCHER OBJECT
        finalMutation.dispatcher.govReview.create.push(fullParentUpdated);
    }
    // UPDATE GOV REVIEW ITEM
    else {
        // GET THE ITEM FROM DATA
        reviewItem = FIND_OBJECT_ARRAY_ITEM(govReviews, "id", itemMutation.id);

        // UPDATE EVENTS
        if (!reviewItem.events) {
            // COMPATIBILITY : CHECK THAT EVENTS ARRAY EXISTS
            itemMutation.events = "[]";
        }
        eventsUpdated = ADD_ITEM_EVENT(reviewItem, itemMutation, userId);

        // IF IS RECURRENT PARENT BOOL SWITCHED ON
        if (itemMutation.isRecurrencyParent) {
            childrenReviews = SWITCH_RECURRENCY_ON_OFF(
                itemMutation,
                reviewItem,
                govReviews,
                currentUser,
                securityGroup
            );
        }
        // IF RECURRENCY SETTINGS HAS CHANGED
        else if (
            Object.keys(itemMutation).find((attr) =>
                ["endRecurrency", "weekGap", "weekDays", "periodicity"]?.includes(attr)
            )
        ) {
            // DELETE ALL RECURRENT REVIEWS EXCEPT PARENT
            childrenReviews.delete = govReviews.filter(
                (item) => item.recurrencyParentId === reviewItem.id
            );

            // CREATE NEW CHILDRENS
            childrenReviews.create = GOV_FUNC_GET_RECURRENT_REVIEWS(
                {
                    ...reviewItem,
                    ...itemMutation,
                },
                currentUser,
                securityGroup
            );
        }
        // IF WE EDIT THE WHOLE SERIE WITHOUT CHANGING THE PERIODICITY SETTINGS
        else if (
            modalType === GOV_REVIEW_MODAL_TYPE.edit &&
            selectedModalOption === GOV_REVIEW_SERIES_MODAL_OPTIONS.wholeSeries
        ) {
            delete itemMutation.reviewMeetingStatus;
            childrenReviews.update = GOV_FUNC_GET_UPDATED_REVIEWS(itemMutation, govReviews);
        }

        // UPDATE PARENT PERIODICITY SETTING
        let periodicity = GET_UPDATED_PERIODICITY(itemMutation, reviewItem);

        /////////////////////////////
        /// CREATE FINAL MUTATION ///
        /////////////////////////////

        // DELETE CREATED BY IN CASE OF MUTATION
        delete itemMutation.createdBy;
        delete itemMutation.govItemType;

        // GET FINAL PARENT UP TO DATE
        parentUpdated = {
            ...itemMutation,
            updatedAt: new Date(),
            events: JSON.stringify(eventsUpdated),
            periodicity: periodicity,
            groupEditors: securityGroup.groupEditors,
            groupViewers: securityGroup.groupViewers,
        };

        // GET FULL PARENT OBJECT FOR DISPATCHER
        fullParentUpdated = {
            ...reviewItem,
            ...parentUpdated,
        };

        // ADD PARENT REVIEW TO FINAL MUTATION
        finalMutation.graphql.parentReviewMutation = {
            query: updateTelescopeDataGovReview,
            objects: [parentUpdated],
        };

        // ADD PARENT TO DISPATCHER OBJECT
        finalMutation.dispatcher.govReview.update.push(fullParentUpdated);
    }

    delete parentUpdated.sort;

    // IF DELETE REVIEWS ADD IT TO FINAL MUTATION
    if (childrenReviews.delete?.length > 0) {
        let itemsToDelete = childrenReviews.delete.map((review) => ({ id: review.id }));
        let idsToDelete = childrenReviews.delete.map((review) => review.id);

        // GRAPQHL MUTATION
        finalMutation.graphql.deleteChildrenReviews = {
            query: deleteTelescopeDataGovReview,
            objects: itemsToDelete,
        };

        // DISPATCHER MUTATION
        finalMutation.dispatcher.govReview.delete.push(...idsToDelete);

        ///// NEED TO DELETE AGENDA LINKS !!!!!!!!!!!!!
    }

    // IF CREATE REVIEWS ADD IT TO FINAL MUTATION
    if (childrenReviews.create?.length > 0) {
        // GRAPQHL MUTATION
        finalMutation.graphql.createChildrenReviews = {
            query: createTelescopeDataGovReview,
            objects: childrenReviews.create,
        };

        // DISPATCHER MUTATION
        finalMutation.dispatcher.govReview.create.push(...childrenReviews.create);
    }

    // IF UPDATING REVIEWS ADD IT TO FINAL MUTATION
    if (childrenReviews.update?.length > 0) {
        // GRAPQHL MUTATION
        finalMutation.graphql.updateChildrenReviews = {
            query: updateTelescopeDataGovReview,
            objects: childrenReviews.update,
        };

        // DISPATCHER MUTATION
        finalMutation.dispatcher.govReview.update.push(...childrenReviews.update);
    }

    // UPDATE AGENDA DESTINATION LINKS
    if (Object.keys(itemMutation)?.includes("agenda")) {
        let oldAgenda = [];

        // IF ITEM IS UPDATED, GET OLD AGENDA
        if (!isNew) {
            oldAgenda = reviewItem.agenda;
        }

        // GET AGENDA LINKS UPDATE MUTATIONS
        const agendaMutations = GET_AGENDA_LINK_MUTATIONS(
            itemMutation.id,
            oldAgenda,
            itemMutation.agenda,
            projectData
        );

        // MERGE MUTATIONS
        finalMutation = MERGE_GOV_REVIEW_MUTATIONS(finalMutation, agendaMutations);
    }

    // RUN FINAL MUTATION ON GRAPHQL AND APP STATE
    RUN_FINAL_MUTATION(finalMutation, appDispatcher);

    // CALCULATE VIEW MODE TO GO TO AFTER CLICKING SAVE
    let nextViewMode = GOV_FUNC_GET_NEXT_VIEW_MODE(GOV_ITEM_VIEW_TYPE.review);

    // UPDATE GOV STATE IF NOT IN REVIEW MODE
    if (govDispatcher) {
        govDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                isEditSideBarOpen: false,
                editSideBarContent: null,
                editSideBarGroup: null,
                govItemView:
                    nextViewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ? fullParentUpdated : null,
                viewMode: nextViewMode,
                selectedItems: [],
                scrollToId: parentUpdated.id,
                modalType: null,
                selectedModalOption: null,
            },
        });
    }
}

// UPDATE PERIODICITY SETTING
export function GET_UPDATED_PERIODICITY(itemMutateObject, reviewItem) {
    // INIT
    let periodicity = null;

    // IF PERIODICITY DISABLED
    if (itemMutateObject.isRecurrencyParent === false) {
        itemMutateObject.periodicity = null;
    }
    // IF PERIODICITY ENABLED / UPDATED
    else if (itemMutateObject.periodicity) {
        periodicity = itemMutateObject.periodicity;

        // STRINGIFY IF NEEDED
        if (typeof itemMutateObject.periodicity !== "string") {
            periodicity = JSON.stringify(itemMutateObject.periodicity);
        }
    }
    // IF NO CHANGES, KEEP THE ONE FROM ITEM
    else if (typeof reviewItem.periodicity === "string") {
        periodicity = reviewItem.periodicity;
    }
    // IF THE ONE FROM ITEM IS NOT ALREADY IN STRING
    else {
        periodicity = JSON.stringify(reviewItem.periodicity);
    }

    // RETURN
    return periodicity;
}

// SWITCH RECURRENCY ON/OFF
export function SWITCH_RECURRENCY_ON_OFF(
    itemMutateObject,
    govItem,
    govItems,
    currentUser,
    securityGroup
) {
    let createRecurrentReviews = null;
    let deleteRecurrentReviews = null;
    let mutationObject = _.cloneDeep(itemMutateObject);

    // IF SWITCH ON
    if (mutationObject.isRecurrencyParent === true) {
        // CREATE REVIEWS
        createRecurrentReviews = GOV_FUNC_GET_RECURRENT_REVIEWS(
            {
                ...govItem,
                ...mutationObject,
            },
            currentUser,
            securityGroup
        );
    }
    // DELETE REVIEWS WITH START DATE AFTER TODAY
    else {
        deleteRecurrentReviews = govItems.filter(
            (reviewItem) => reviewItem.recurrencyParentId === govItem.id
        );

        // MAKE PERIODICITY NULL
        mutationObject.periodicity = null;
        // MAKE END RECURRENCY DATE NULL
        mutationObject.endRecurrency = null;
    }

    // RETURN RESULT
    return {
        create: createRecurrentReviews,
        delete: deleteRecurrentReviews,
    };
}

// RETURN THE MUTATION TO UPDATE THE LINKS FOR THE DESTINATION ITEMS OF AN AGENDA
export function GET_AGENDA_LINK_MUTATIONS(
    reviewItemId,
    oldAgendaParam,
    newAgendaParam,
    projectData
) {
    // INIT DATA
    const updatedItems = [];
    const finalMutation = _.cloneDeep(DELETE_FINAL_MUTATION);
    let oldAgenda = FUNC_SAFE_GET_JSON_FROM_STRING(oldAgendaParam);
    let newAgenda = FUNC_SAFE_GET_JSON_FROM_STRING(newAgendaParam);

    if (!oldAgenda) {
        oldAgenda = [];
    }
    if (!newAgenda) {
        newAgenda = [];
    }

    // LIST THE AGENDA ITEM LINKS TO CREATE
    const agendaLinksToCreate = FUNC_GET_DIFFERENCE_BETWEEN_OBJECTS_ARRAY(
        newAgenda,
        oldAgenda,
        "id"
    );

    // LIST THE AGENDA ITEM LINKS TO REMOVE
    const agendaLinksToRemove = FUNC_GET_DIFFERENCE_BETWEEN_OBJECTS_ARRAY(
        oldAgenda,
        newAgenda,
        "id"
    );

    // FIND AGENDA ITEMS WITH A LINK THAT HAVE BEEN UPDATED
    newAgenda.forEach((newAgendaItem) => {
        // FIND THE ITEM IN OLD AGENDA
        let oldAgendaItemFound = FIND_OBJECT_ARRAY_ITEM(oldAgenda, "id", newAgendaItem.id);

        // IF THE DESTINATION ITEM HAS CHANGED
        if (oldAgendaItemFound && oldAgendaItemFound.itemId !== newAgendaItem.itemId) {
            // DELETE THE LINK FROM OLD DESTINATION
            if (oldAgendaItemFound.itemId) {
                agendaLinksToRemove.push({
                    itemId: oldAgendaItemFound.itemId,
                    type: oldAgendaItemFound.type,
                });
            }

            // ADD THE LINK TO NEW DESTINATION
            if (newAgendaItem.itemId) {
                agendaLinksToCreate.push({
                    itemId: newAgendaItem.itemId,
                    type: newAgendaItem.type,
                });
            }
        }
    });

    // FOR EACH LINK TO DELETE, GET THE DESTINATION ITEM AND REMOVE THE LINK TO ITS LIST
    agendaLinksToRemove.forEach((agendaItemToRemove) => {
        // CHECK WE DELETE AN ITEM THAT HAS A LINK SET
        if (agendaItemToRemove.itemId) {
            // GET FULL DESTINATION ITEM
            const destinationItem = FIND_OBJECT_ARRAY_ITEM(
                projectData[agendaItemToRemove.type],
                "id",
                agendaItemToRemove.itemId
            );

            // IF DESTINATION ITEM NOT FOUND, CONTINUE
            if (!destinationItem) {
                return;
            }

            // GET LINKS LIST NAME
            const linkName = GET_GOV_LINK_ATTRIBUTE_NAME(agendaItemToRemove.type);
            let links = FUNC_SAFE_GET_JSON_FROM_STRING(destinationItem[linkName]);

            // INIT ARRAY IF NOT EXISTS
            if (!links) {
                links = [];
            }

            // ADD THE REVIEW TO THE LIST
            if (agendaItemToRemove.type === "action") {
                // IF ITEM IS AN ACTION, REMOVE LINK OBJECT FROM ACTION
                let newActionLink = links.filter((link) => link.destinationItemId !== reviewItemId);

                // ADD ACTION LINK
                links = JSON.stringify(newActionLink);
            } else {
                // REMOVE THE REVIEW FROM THE LIST
                links = FUNC_REMOVE_ELEMENT_FROM_ARRAY(links, reviewItemId);
            }

            // ADD ITEM TO UPDATED ITEMS LIST
            updatedItems.push({
                id: destinationItem.id,
                type: agendaItemToRemove.type,
                fullObject: destinationItem,
                [linkName]: links,
            });
        }
    });

    // FOR EACH LINK TO CREATE, GET THE DESTINATION ITEM AND ADD A LINK TO ITS LIST
    agendaLinksToCreate.forEach((agendaItemToCreate) => {
        // IF DESTINATION ITEM ID SELECTED
        if (agendaItemToCreate.itemId) {
            // CHECK THE DESTINATION HASN'T BEEN UPDATED BY THE DELETION PROCESS
            let foundAlreadyUpdatedItemIndex = FUNCT_FIND_INDEX(
                updatedItems,
                "id",
                agendaItemToCreate.itemId
            );

            let destinationItem;

            // FIND THE DESTINATION ITEM IN THE CORRECT LIST
            if (foundAlreadyUpdatedItemIndex > -1) {
                destinationItem = updatedItems[foundAlreadyUpdatedItemIndex];
            } else {
                destinationItem = FIND_OBJECT_ARRAY_ITEM(
                    projectData[agendaItemToCreate.type],
                    "id",
                    agendaItemToCreate.itemId
                );
            }

            // GET LINKS LIST
            const linkName = GET_GOV_LINK_ATTRIBUTE_NAME(agendaItemToCreate.type);

            // CHECK ATTRIBUTE EXISTS (COMPATIBILITY)
            if (!destinationItem.hasOwnProperty(linkName)) {
                destinationItem[linkName] = [];
            }

            let links = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(destinationItem[linkName]);

            // INIT ARRAY IF NOT EXISTS
            if (!links) {
                links = [];
            }

            // ADD THE REVIEW TO THE LIST
            if (agendaItemToCreate.type === "action") {
                // IF ITEM IS AN ACTION, CREATE THE LINK OBJECT
                let newActionLink = FUNC_ACTION_CREATE_LINK_OBJECT("govReview", reviewItemId);
              
                // ADD ACTION LINK
                links.push(newActionLink);
                links = JSON.stringify(links);
            } else {
                // ELSE JUST ADD AN ID TO THE LIST
                links.push(reviewItemId);
            }

            // IF ALREADY UPDATED ITEM, UPDATE ITS LINKS
            if (foundAlreadyUpdatedItemIndex > -1) {
                updatedItems[foundAlreadyUpdatedItemIndex][linkName] = links;
            } else {
                // SAVE THE UPDATED ITEM
                updatedItems.push({
                    id: destinationItem.id,
                    type: agendaItemToCreate.type,
                    fullObject: destinationItem,
                    [linkName]: links,
                });
            }
        }
    });

    // FOR EACH ITEMS, CREATE THE UPDATE MUTATION
    updatedItems.forEach((updatedItem) => {
        // GET LINK NAME
        const linkName = GET_GOV_LINK_ATTRIBUTE_NAME(updatedItem.type);

        // CREATE GRAPHQL MUTATION
        finalMutation.graphql[updatedItem.type].objects.push({
            id: updatedItem.id,
            [linkName]: updatedItem[linkName],
        });

        // CREATE DISPATCHER MUTATION
        finalMutation.dispatcher[updatedItem.type].update.push({
            ...updatedItem.fullObject,
            [linkName]: updatedItem[linkName],
        });
    });

    return finalMutation;
}

// GET MUTATIONS TO DELETE ONE GOV REVIEW AND ALL LINKS IN THE AGENDA
export function GET_DELETE_GOV_REVIEW_MUTATION(govReviewItem, projectData) {
    // INIT FINAL MUTATION
    let finalMutation = _.cloneDeep(DELETE_FINAL_MUTATION);

    // DELETE PARENT GOVERNANCE REVIEW
    finalMutation.graphql.govReview.objects.push({
        id: govReviewItem.id,
    });
    finalMutation.dispatcher.govReview.delete.push(govReviewItem.id);

    // GET MUTATION TO REMOVE LINKS IN THE AGENDA
    let clearAgendaLinksMutations;
    if (govReviewItem.agenda) {
        clearAgendaLinksMutations = GET_AGENDA_LINK_MUTATIONS(
            govReviewItem.id,
            govReviewItem.agenda,
            [],
            projectData
        );

        // ADD AGENDA MUTATIONS TO FINAL MUTATION
        finalMutation = MERGE_GOV_REVIEW_MUTATIONS(finalMutation, clearAgendaLinksMutations);
    }

    return finalMutation;
}

// RUN THE DELETE MUTATION ON GRAPHQL AND APP STATE
export function RUN_FINAL_MUTATION(finalMutation, appDispatcher) {
    // UPDATE GRAPHQL
    for (let queryName of Object.keys(finalMutation.graphql)) {
        multipleMutateGraphql(
            finalMutation.graphql[queryName].query,
            finalMutation.graphql[queryName].objects,
            appDispatcher
        );
    }

    // UPDATE APP STATE
    appDispatcher({
        type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
        object: finalMutation.dispatcher,
    });

    // IF GOV REVIEW DELETE NOT EMPTY, UPDATE BOARD
    if (finalMutation.dispatcher?.govReview?.delete?.length > 0) {
        // UPDATE BOARD NEXT REVIEWS DATA
        appDispatcher({
            type: "SET_UPDATE_BOARD_DATA",
            table: "nextReviews",
            data: {
                delete: finalMutation.dispatcher.govReview.delete,
            },
        });
    }
}

// DELETE ONE GOV REVIEW AND REMOVE ALL THE LINKS
export function DELETE_GOV_REVIEW_WITH_LINKS(govReviewItem, projectData, appDispatcher) {
    // GET THE MUTATIONS
    let finalMutation = GET_DELETE_GOV_REVIEW_MUTATION(govReviewItem, projectData);

    // SAVE MUTATIONS ON GRAPHQL AND APP STATE
    RUN_FINAL_MUTATION(finalMutation, appDispatcher);
}

// WILL RETURN ALL ITEMS OF A RECURRENCY,
// NO MATTER IF YOU GIVE A PARENT OR CHILD ITEM
export function GET_ALL_REVIEWS(govReview, projectData) {
    let parentReview = null;

    // IF ITEM IS PARENT, JUST INIT
    if (govReview.isRecurrencyParent) {
        parentReview = govReview;
    } else {
        // GET THE PARENT ID
        let parentId = govReview.recurrencyParentId;

        // GET PARENT OBJECT
        parentReview = FIND_OBJECT_ARRAY_ITEM(projectData.govReview, "id", parentId);
    }

    // GET ALL CHILDREN REVIEWS
    let childrenReviews = projectData.govReview.filter(
        (item) => item.recurrencyParentId === (parentReview ? parentReview.id : govReview.recurrencyParentId)
    );
      
    // MERGE ALL REVIEWS
    let allReviews = parentReview ? [parentReview, ...childrenReviews] : childrenReviews;

    return allReviews;
}

// RETURN THE ATTRIBUTE OF A DESTINATION ITEM LINK FOR A GOVERNANCE
export function GET_GOV_LINK_ATTRIBUTE_NAME(linkType) {
    return WBS_ITEMS_LINKS.govReview[linkType];
}

// DELETE ALL REVIEWS OF A RECURRENCY AND THEIR LINKS
export function DELETE_RECURRENT_GOV_REVIEW_AND_LINKS(govReview, projectData, appDispatcher) {
    // INIT
    let linksToRemove = [];
    let updatedItems = [];
    const finalMutation = _.cloneDeep(DELETE_FINAL_MUTATION);

    // GET ALL REVIEWS
    let allReviews = GET_ALL_REVIEWS(govReview, projectData);
   
    // GET ALL LINKS TO REMOVE
    allReviews.forEach((review) => {
        // PARSE AGENDA
        let agenda = FUNC_SAFE_GET_JSON_FROM_STRING(review.agenda);

        // CHECK AGENDA NOT NULL
        if (agenda) {
            // ITTERATE OVER AGENDA CHECK IF THERE'S LINKS TO REMOVE
            agenda.forEach((agendaItem) => {
                if (agendaItem.itemId) {
                    agendaItem = {
                        itemId: agendaItem.itemId,
                        type: agendaItem.type,
                        originItem: review.id,
                    };
                    linksToRemove.push(agendaItem);
                }
            });
        }

        // ADD THE GOV REVIEW TO DELETION
        finalMutation.dispatcher.govReview.delete.push(review.id);
        finalMutation.graphql.govReview.objects.push({
            id: review.id,
        });
    });

    // UPDATE DESTINATION ITEMS
    linksToRemove.forEach((link) => {

        if(link) {

            let destinationItem = null;
    
            // GET DESTINATION ITEM BUT CHECK IT'S NOT ALREADY BEING UPDATED
            let findIndex = FUNCT_FIND_INDEX(updatedItems, "id", link.itemId);
    
            // IF ITEM FOUND, USE IT
            if (findIndex > -1) {
                destinationItem = updatedItems[findIndex];
            } else {
                // ELSE GET IT FROM PROJECT DATA
                destinationItem = FIND_OBJECT_ARRAY_ITEM(projectData[link.type], "id", link.itemId);
            }
            
            if(destinationItem) {

                const linkName = GET_GOV_LINK_ATTRIBUTE_NAME(link.type);
                if(destinationItem[linkName]) {

                    let links = FUNC_SAFE_GET_JSON_FROM_STRING(destinationItem[linkName]) ;
        
                    // INIT ARRAY IF NOT EXISTS
                    if (!links) {
                        links = [];
                    }
            
                    // REMOVE THE REVIEW FROM THE LIST
                    links = FUNC_REMOVE_ELEMENT_FROM_ARRAY(links, link.originItem);
    
                    // SET LINKS BACK TO DESTINATION
                    destinationItem[linkName] = JSON.stringify(links);
            
                    // IF ITEM IS UPDATED FOR THE FIRST TIME
                    if (findIndex === -1) {
                        // ADD DATA TYPE TO PUT THE OBJECT IN THE CORRECT MUTATION
                        destinationItem.dataType = link.type;
            
                        // SAVE ITEM TO LIST
                        updatedItems.push(destinationItem);
                    } else {
                        // ELSE, UPDATE IT IN THE LIST
                        updatedItems[findIndex] = destinationItem;
                    }

                }

            }
            // GET LINKS LIST NAME

        }
    });

    // CREATE THE MUTATION
    updatedItems.forEach((updatedItem) => {
        // GET LINK NAME ON DESTINATION ITEM
        const linkName = GET_GOV_LINK_ATTRIBUTE_NAME(updatedItem.dataType);

        // ADD GRAPHQL MUTATION
        finalMutation.graphql[updatedItem.dataType].objects.push({
            id: updatedItem.id,
            [linkName]: updatedItem[linkName],
        });

        // ADD DISPATCHER MUTATION
        finalMutation.dispatcher[updatedItem.dataType].update.push(updatedItem);
    });

    RUN_FINAL_MUTATION(finalMutation, appDispatcher);
}

// RETURN AN AGENDA WITH THE NEW ITEM
export function ADD_ITEM_TO_AGENDA(item, agendaParam) {
    // PARSE AGENDA
    let agenda = FUNC_SAFE_GET_JSON_FROM_STRING(agendaParam);

    // IF AGENDA NULL, INIT IT
    if (!agenda) {
        agenda = [];
    }

    // CLONE AGENDA
    agenda = _.cloneDeep(agenda);

    // ADD ITEM TO AGENDA
    agenda.push(item);

    return agenda;
}

// RETURN THE AGENDA BUT REMOVE THE ITEM
export function REMOVE_ITEM_FROM_AGENDA(item, agendaParam) {
    // PARSE AGENDA
    let agenda = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(agendaParam);

    // REMOVE ITEM FROM AGENDA LIST
    agenda = FUNC_REMOVE_OBJECT_FROM_ARRAY(agenda, "id", item.id);

    return agenda;
}

// IN REVIEW MODE, UPDATE APP PORTFOLIO DISPLAY STATE TO UPDATE RIGHT SCREEN
export function GOV_REVIEW_MODE_UPDATE_DISPLAY(
    govItem,
    mutation,
    usersList,
    securityGroup,
    appDispatcher,
    projectIds,
    isProject,
    projectId
) {
    // INIT UPDATE REVIEW MODE STATE
    let updatedReview = {
        ...govItem,
        ...mutation,
    };

    let newPortfolioDisplay = {
        rightComponent: {
            name: "govReview",
            data: {
                govItem: updatedReview,
                editMode: true,
                usersList: usersList,
                securityGroup: securityGroup,
                projectIds,
                projectId,
                isProject,
            },
        },
    };

    // UPDATE REVIEW MODE STATE
    appDispatcher({
        type: "SET_GLOBAL_STATE",
        options: {
            source: "portfolioDisplay",
            object: newPortfolioDisplay,
        },
    });
}

// SWITCH CASE FOR GETTING DAY INCREMENT ACCORDING TO WEEK DAY TXT
export function GOV_FUNC_GET_WEEK_DAY_INCREMENT(weekDay) {
    switch (weekDay) {
        case "monday":
            return 0;
        case "tuesday":
            return 1;
        case "wednesday":
            return 2;
        case "thursday":
            return 3;
        case "friday":
            return 4;
        case "saturday":
            return 5;
        case "sunday":
            return 6;
        default:
            return 0;
    }
}

// GET RECURRENT REVIEWS ACCORDING TO THE PERIODICITY OF A REVIEW
export function GOV_FUNC_GET_RECURRENT_REVIEWS(recurrentParentReview, currentUser, securityGroup) {
    let parsedPeriodicity = recurrentParentReview.periodicity;

    // PARSE PERIODICITY IF NEEDED
    if (typeof parsedPeriodicity === "string") {
        parsedPeriodicity = JSON.parse(parsedPeriodicity);
    }

    // INIT RETURN LIST OF REVIEWS
    let resultReviews = [];

    // GET NUM WEEKS BETWEEN START DATE AND END RECURRENCY DATE
    let totalWeeks =
        Math.ceil(
            FUNC_GET_DAYS_BETWEEN_DATES(
                recurrentParentReview.startDate,
                recurrentParentReview.endRecurrency
            ) / 7
        ) + 1;

    // DIVIDE BY WEEK GAP
    let recurrentWeeks = totalWeeks / parsedPeriodicity.weekGap;

    // LOOP THROUGHT THE RECURRENT WEEKS NUMBER
    for (let i = 0, len = recurrentWeeks; i < len; i++) {
        let startDate = _.cloneDeep(recurrentParentReview.startDate);
        let parentEndDate = _.cloneDeep(recurrentParentReview.endDate);

        // IF DIFF DAYS BETWEEN START DATE AND END DATE IS EQUAL OR BIGGER THAN 1, THEN WE'RE DEALING WITH A MULTI-DAY REVIEW
        if (FUNC_GET_DAYS_BETWEEN_DATES(startDate, parentEndDate) >= 1) {
            // CREATE A NEW REVIEW FOR EACH OF THE WEEKS

            let daysToAdd = 7 * i * parsedPeriodicity.weekGap;

            let newStartDate = _.cloneDeep(startDate);

            newStartDate.setDate(startDate.getDate() + daysToAdd);
            newStartDate.setHours(startDate.getHours());
            newStartDate.setMinutes(startDate.getMinutes());

            let newEndDate = _.cloneDeep(parentEndDate);

            newEndDate.setDate(parentEndDate.getDate() + daysToAdd);
            newEndDate.setHours(parentEndDate.getHours());
            newEndDate.setMinutes(parentEndDate.getMinutes());

            // FOR EACH RECURRENT WEEK, CREATE A NEW REVIEW
            let newReview = {
                // ..._.cloneDeep(DEFAULT_REVIEW_ITEM),
                ...recurrentParentReview,
                // reviewMeetingStatus: null,
                id: uuid(),
                name: recurrentParentReview.name,
                startDate: newStartDate,
                endDate: newEndDate,
                events: JSON.stringify([
                    {
                        user: {
                            id: currentUser.username,
                            label: currentUser.name,
                        },
                        createdOn: new Date(),
                        attribute: "createdBy",
                        value: "created by " + currentUser.name,
                    },
                ]),
                comments: null,
                createdAt: new Date(),
                recurrencyParentId: recurrentParentReview.id,
                isRecurrencyParent: false,
                periodicity: null,
                groupEditors: securityGroup.groupEditors,
                groupViewers: securityGroup.groupViewers,
                createdBy: currentUser.username,
            };

            delete newReview.govItemType;

            // CHECK FOR PASSING DATES
            // IF EITHER IS THE SAME AS THE END RECURRENCY OR BEFORE AND IF IS AFTER THE START DATE OF THE PARENT
            let isPassDates =
                (FUNC_CHECK_DATE2_IS_AFTER_DATE1(
                    newStartDate,
                    recurrentParentReview.endRecurrency
                ) ||
                    FUNC_CHECK_DATES_SAME_DAY(newStartDate, recurrentParentReview.endRecurrency)) &&
                FUNC_CHECK_DATE2_IS_AFTER_DATE1(recurrentParentReview.startDate, newStartDate) &&
                !FUNC_CHECK_DATES_SAME_DAY(recurrentParentReview.startDate, newStartDate);

            if (isPassDates) {
                resultReviews.push(newReview);
            }
        } else {
            // LOOP THROUGH THE DAYS CHOSEN IN THE PERIODICITY
            for (let weekDay of Object.keys(parsedPeriodicity.weekDays)) {
                // IS SELECTED
                if (parsedPeriodicity.weekDays[weekDay] === true) {
                    let newStartDate = _.cloneDeep(startDate);

                    // let weekDayIncrement = Object.keys(parsedPeriodicity.weekDays).findIndex((weekDayKey) =>weekDayKey === weekDay);
                    let weekDayIncrement = GOV_FUNC_GET_WEEK_DAY_INCREMENT(weekDay);

                    // DAYS TO ADD (START AT THE SAME WEEK)
                    let daysToAdd = 7 * i * parsedPeriodicity.weekGap;

                    // ADD 7 DAYS TIMES THE NUMBER OF THE RECURRENT WEEK TIMES THE WEEK GAP
                    newStartDate.setDate(startDate.getDate() + daysToAdd);

                    // NEED TO GET START OF WEEK, THEN ADD DAYS ACCORDING TO WEEK DAY
                    let dateMondayOfWeek = FUNC_GET_MONDAY_FROM_DATE(newStartDate);

                    // NOW ADD WEEK DAY INCREMENT
                    dateMondayOfWeek.setDate(dateMondayOfWeek.getDate() + weekDayIncrement);

                    dateMondayOfWeek.setHours(startDate.getHours());

                    let endDate = _.cloneDeep(dateMondayOfWeek);

                    // SET HOURS OF THE END DATE
                    endDate.setHours(parentEndDate.getHours());
                    // SET MINUTES
                    endDate.setMinutes(parentEndDate.getMinutes());

                    // FOR EACH RECURRENT WEEK, CREATE A NEW REVIEW
                    let newReview = {
                        // ..._.cloneDeep(DEFAULT_REVIEW_ITEM),
                        ...recurrentParentReview,
                        id: uuid(),
                        name: recurrentParentReview.name,
                        startDate: dateMondayOfWeek,
                        endDate: endDate,
                        events: JSON.stringify([
                            {
                                user: {
                                    id: currentUser.username,
                                    label: currentUser.name,
                                },
                                createdOn: new Date(),
                                attribute: "createdBy",
                                value: "created by " + currentUser.name,
                            },
                        ]),
                        comments: null,
                        createdAt: new Date(),
                        recurrencyParentId: recurrentParentReview.id,
                        isRecurrencyParent: false,
                        periodicity: null,
                        endRecurrency: null,
                        groupEditors: securityGroup.groupEditors,
                        groupViewers: securityGroup.groupViewers,
                        createdBy: currentUser.username,
                    };

                    delete newReview.govItemType;
                    delete newReview.updatedAt;

                    // CHECK FOR PASSING DATES
                    // IF EITHER IS THE SAME AS THE END RECURRENCY OR BEFORE AND IF IS AFTER THE START DATE OF THE PARENT
                    let isPassDates =
                        (FUNC_CHECK_DATE2_IS_AFTER_DATE1(
                            dateMondayOfWeek,
                            recurrentParentReview.endRecurrency
                        ) ||
                            FUNC_CHECK_DATES_SAME_DAY(
                                dateMondayOfWeek,
                                recurrentParentReview.endRecurrency
                            )) &&
                        FUNC_CHECK_DATE2_IS_AFTER_DATE1(
                            recurrentParentReview.startDate,
                            dateMondayOfWeek
                        ) &&
                        !FUNC_CHECK_DATES_SAME_DAY(
                            recurrentParentReview.startDate,
                            dateMondayOfWeek
                        );

                    if (isPassDates) {
                        resultReviews.push(newReview);
                    }
                }
            }
        }
    }

    return resultReviews;
}

// GET UPDATED REVIEWS ACCORDING TO EDITTED PROPERTIES
export function GOV_FUNC_GET_UPDATED_REVIEWS(mutateObject, govItems) {
    let children = _.cloneDeep(
        govItems.filter(
            (review) => review.recurrencyParentId && review.recurrencyParentId === mutateObject.id
        )
    );

    if (children.length > 0) {
        for (let i = 0, len = children.length; i < len; i++) {
            // LOOP ALL MODIFIED ATTRIBUTES
            for (let attribute of Object.keys(mutateObject)) {
                if (
                    attribute !== "displayId" &&
                    attribute !== "id" &&
                    attribute !== "organizationId" &&
                    attribute !== "projectId" &&
                    attribute !== "wbsId" &&
                    attribute !== "comments" &&
                    attribute !== "createdBy" &&
                    attribute !== "periodicity" &&
                    attribute !== "isRecurrencyParent" &&
                    attribute !== "endRecurrency" &&
                    attribute !== "weekGap" &&
                    attribute !== "weekDays" &&
                    attribute !== "govItemType" &&
                    attribute !== "reviewMeetingStatus"
                ) {
                    // IF CHANGED START DATE OR END DATE, UPDATE *ONLY* THE HOURS AND MINUTES.
                    if (attribute === "startDate" || attribute === "endDate") {
                        let newDate = _.cloneDeep(children[i][attribute]);

                        // SET NEW HOURS + NEW MINUTES
                        newDate.setHours(mutateObject[attribute].getHours());
                        newDate.setMinutes(mutateObject[attribute].getMinutes());

                        children[i][attribute] = newDate;
                    } else {
                        children[i][attribute] = mutateObject[attribute];
                    }
                }
            }
        }
    }

    return children;
}

export function GOV_FUNC_MOVE_PARENT_START(updatedGovReview) {
    let periodicity = updatedGovReview.periodicity;

    // PARSE PERIODICITY IF NEEDED
    if (typeof periodicity === "string") {
        periodicity = JSON.parse(periodicity);
    }

    // IF REVIEW IS NOT MORE THAN ONE DAY
    if (FUNC_GET_DAYS_BETWEEN_DATES(updatedGovReview.startDate, updatedGovReview.endDate) < 1) {
        let startDateWeek = updatedGovReview.startDate.getDay();

        if (startDateWeek <= 6) {
            startDateWeek -= 1;
        } else if (startDateWeek === 0) {
            startDateWeek = 6;
        }

        let smallestIndex = 6;

        for (let weekDay of Object.keys(periodicity.weekDays)) {
            if (periodicity.weekDays[weekDay] === true) {
                let weekDayIncrement = GOV_FUNC_GET_WEEK_DAY_INCREMENT(weekDay);
                if (weekDayIncrement < smallestIndex) {
                    smallestIndex = weekDayIncrement;
                }
            }
        }

        // IF NOT SAME, THEN ADD MAKE THIS THE START DATE
        if (smallestIndex !== startDateWeek) {
            let weekMonday = FUNC_GET_MONDAY_FROM_DATE(updatedGovReview.startDate);

            weekMonday.setDate(weekMonday.getDate() + smallestIndex);

            let newStartDate = _.cloneDeep(weekMonday);
            let newEndDate = _.cloneDeep(weekMonday);

            newEndDate.setHours(updatedGovReview.endDate.getHours());
            newEndDate.setMinutes(updatedGovReview.endDate.getMinutes());

            return {
                startDate: newStartDate,
                endDate: newEndDate,
            };
        }
    }

    return null;
}

// RETURN AGENDA ITEMS BASED ON TYPE (Risk, action, Gov ...)
export function GOV_FUNC_GET_AGENDA_TYPE_ITEMS(type, projectData) {
    switch (type) {
        case AGENDA_ITEM_TYPES_KEYS.risk:
            return projectData.riskOpp.map((item) => ({
                id: item.id,
                label: item.displayId + " : " + item.name,
            }));
        case AGENDA_ITEM_TYPES_KEYS.action:
            return projectData.action.map((item) => ({
                id: item.id,
                label: item.displayId + " : " + item.action,
            }));
        case AGENDA_ITEM_TYPES_KEYS.scopeChange:
            return projectData.govScopeChange.map((item) => ({
                id: item.id,
                label: item.displayId + " : " + item.title,
            }));
        default:
            return [];
    }
}

// GET WEEK DAYS OBJECT AFTER SELECTING START AND END DATE
export function GOV_FUNC_GET_WEEK_DAYS_OBJ(startDate, endDate) {
    let startIndex = new Date(startDate).getDay();
    let endIndex = new Date(endDate).getDay();

    // SUPPOSING THE MAX DAYS BETWEEN END AND START DATE IS 7 (A WEEK)

    let newWeekDays = {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
    };

    let objKeys = Object.keys(newWeekDays);

    let isBiggerThanWeek = FUNC_GET_DAYS_BETWEEN_DATES(startDate, endDate) > 7;

    for (let i = 0, len = objKeys.length; i < len; i++) {
        if (isBiggerThanWeek) newWeekDays[objKeys[i]] = true;
        else if (endIndex > startIndex) {
            if (i >= startIndex && i <= endIndex) {
                newWeekDays[objKeys[i]] = true;
            }
        } else if (startIndex > endIndex) {
            if (i <= startIndex && i >= endIndex) {
                newWeekDays[objKeys[i]] = true;
            }
        }
    }

    // RE-ORDER FOR THE ALGORITHM TO WORK FINE.
    let oldSunday = newWeekDays.sunday;

    delete newWeekDays.sunday;

    newWeekDays = {
        ...newWeekDays,
        sunday: oldSunday,
    };

    return newWeekDays;
}

// IF A TAG IS ADDED
export function GET_UPDATED_TAGS_MUTATION(wbsSettings, itemMutateObject, appDispatcher) {
    // INIT
    let wbsSettingsMutation;
    let updatedTagList = wbsSettings.tags ? JSON.parse(wbsSettings.tags) : [];
    let isListUpdated = false;

    // CHECK EACH TAGS
    itemMutateObject.tags.forEach((tag) => {
        // IF NOT IN LIST, ADD IT
        if (!updatedTagList?.includes(tag.value)) {
            updatedTagList.push(tag.value);
            isListUpdated = true;
        }
    });

    // STRINGIFY TAGS
    itemMutateObject.tags = JSON.stringify(itemMutateObject.tags.map((item) => item.value));

    // IF NEW TAGS, UPDATE SETTINGS
    if (isListUpdated) {
        //MUTATE GRAPH QL
        let object = {
            id: wbsSettings.id ? wbsSettings.id : uuid(),
            projectId: wbsSettings.projectId,
            domain: JSON.stringify(wbsSettings.domain),
            tags: JSON.stringify(updatedTagList),
        };

        //CREATE MUTATION GRAPHQL
        let wbsSettingsQuery = wbsSettings.id
            ? updateTelescopeDataWbsSettings
            : createTelescopeDataWbsSettings;

        wbsSettingsMutation = {
            [wbsSettingsQuery]: {
                query: wbsSettingsQuery,
                objects: [object],
            },
        };

        //UPDATE APP STATE
        appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
                source: "wbsSettings",
                object: {
                    tags: JSON.stringify(updatedTagList),
                },
            },
        });

        // RETURN
        return wbsSettingsMutation;
    }
}

// CREATE A NEW AGENDA ITEM OBJECT
export function GET_NEW_AGENDA_ITEM_OBJECT(title, type, itemId, expectation) {
    let agendaItem = _.cloneDeep(GOV_REVIEW_AGENDA_DEFAULT_ITEM);

    // INIT VALUES
    agendaItem.id = uuid();
    agendaItem.createdOn = new Date();
    agendaItem.title = title;
    agendaItem.type = type;
    agendaItem.itemId = itemId;
    agendaItem.expectation = expectation;

    // RETURN
    return agendaItem;
}

// GIVEN AN ITEM ID, WILL REMOVE ITS LINK FROM THE AGENDA,
// AND RETURN THE MUTATION
export function GET_MUTATION_TO_DELETE_ITEM_FROM_REVIEW_AGENDA(
    itemIdToRemove,
    reviewId,
    projectData
) {
    // GET THE REVIEW
    let reviewItem = FIND_OBJECT_ARRAY_ITEM(projectData.govReview, "id", reviewId);

    if (!reviewItem) {
        return null;
    }

    // GET THE AGENDA
    let agenda = FUNC_SAFE_GET_JSON_FROM_STRING(reviewItem.agenda);

    // IF NULL, INIT EMPTY
    if (!agenda) {
        agenda = [];
    }

    // REMOVE ITEM FROM AGENDA BASED ON ITEMID
    agenda = agenda.filter((agendaItem) => agendaItem.itemId !== itemIdToRemove);

    // STRINGIFY
    agenda = JSON.stringify(agenda);

    // CREATE UPDATED REVIEW OBJECT
    const updatedReview = {
        id: reviewItem.id,
        agenda: agenda,
    };

    const fullUpdatedItem = {
        ...reviewItem,
        ...updatedReview,
    };

    // CREATE MUTATION
    const mutation = {
        graphql: {
            govReview: {
                query: updateTelescopeDataGovReview,
                objects: [updatedReview],
            },
        },
        dispatcher: {
            govReview: {
                update: [fullUpdatedItem],
            },
        },
    };

    // RETURN MUTATION
    return mutation;
}

// GET MUTATION TO DELETE SEVERAL ITEMS,
// AND THE MUTATIONS TO UPDATE GOV REVIEWS AGENDA ITEMS
export function GET_MUTATION_TO_DELETE_SEVERAL_ITEMS(itemsToDelete, type, projectDataParam) {
    // INIT FINAL MUTATION
    let finalMutation = {
        graphql: {
            govReview: {
                query: updateTelescopeDataGovReview,
                objects: [],
            },
            govScopeChange: {
                query: deleteTelescopeDataGovScopeChange,
                objects: [],
            },
            action: {
                query: deleteTelescopeDataAction,
                objects: [],
            },
            riskOpp: {
                query: deleteTelescopeDataRO,
                objects: [],
            },
            schedule: {
                query: deleteTelescopeDataSchedule,
                objects: [],
            },
        },
        dispatcher: {
            govReview: {
                update: [],
            },
            govScopeChange: {
                delete: [],
            },
            action: {
                delete: [],
            },
            riskOpp: {
                delete: [],
            },
            schedule: {
                delete: [],
            },
        },
    };

    // INIT VARS
    let updatedItems = [];
    let findReview;
    let review;
    let linkedReviewIds;
    let linkName;

    //LOOP ON SELETED ITEMS TO DELETE
    itemsToDelete.forEach((itemToDelete) => {
        // GET LINK NAME
        linkName = WBS_ITEMS_LINKS.govReview[type];

        // GET LINKED REVIEW IDS
        linkedReviewIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(itemToDelete[linkName]);

        // FOR EACH REVIEWS, UPDATE AGENDA
        linkedReviewIds.forEach((reviewId) => {
            // GET REVIEW DATA FROM UPDATED ITEM IF ALREADY UPDATED
            // OR FROM PROJECT DATA IF FIRST TIME
            findReview = FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND(
                updatedItems,
                projectDataParam.govReview,
                "id",
                reviewId
            );
            if (findReview) {
                review = findReview.data;

                // IF REVIEW DOES NOT EXISTS, CONTINUE TO NEXT ONE
                if (!review) {
                    return;
                }

                // REMOVE CURRENT ITEM FROM IT'S AGENDA
                review.agenda = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(review.agenda);
                review.agenda = FUNC_REMOVE_OBJECT_FROM_ARRAY(
                    review.agenda,
                    "itemId",
                    itemToDelete.id
                );
                review.agenda = JSON.stringify(review.agenda);

                // IF ITEM COME FROM UPDATED ITEMS, UPDATE IT
                if (findReview.fromFirstArray) {
                    updatedItems[findReview.index] = review;
                }
                // OTHERWISE, ADD ITEM TO UPDATED ITEMS
                else {
                    updatedItems.push(review);
                }
            }
        });

        // ADD ITEM DELETE MUTATION
        finalMutation.dispatcher[type].delete.push(itemToDelete.id);
        finalMutation.graphql[type].objects.push({ id: itemToDelete.id });
    });

    // FOR EACH UPDATED REVIEWS, ADD A MUTATION TO UPDATE AGENDA
    updatedItems.forEach((updatedReview) => {
        // CREATE MUTATION FOR THIS ITEM
        finalMutation.dispatcher.govReview.update.push(updatedReview);
        finalMutation.graphql.govReview.objects.push({
            id: updatedReview.id,
            agenda: updatedReview.agenda,
        });
    });

    return finalMutation;
}
