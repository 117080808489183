import React, {memo, useMemo} from 'react'

//CORE
import {APP_TOAST_TXT, APP_WBS_STRUCTURE} from '../../../../00-Core/Constants'
import {piclogoDefault} from '../../../../00-Core/02-Pictures/PicturesCatalogue'
import { useCallback } from 'react'
import { DEFAULT_MAP_DATA_ACCESS_REFERENCES } from '../00-Helpers/WbsConstants'
import { useState } from 'react'
import { CvtButton } from '@mi-gso/cvt'
import { getReferenceDataAccess } from '../../../00-App/02-Backend/AppBackendMapping'
import { CvtTooltip } from "@mi-gso/cvt";
import 'react-tooltip/dist/react-tooltip.css';


//////////////////////////
/// WBS ITEM COMPONENT ///
//////////////////////////

//COMPONENT
export default memo(
    function WbsHeader({
        isSelectedProject,
        message,
        icon,
        selectedOrganization,
        selectedProject,
        selectedBranchWbsId,
        wbsSettings,
        isReviewMode,
        portfolioProjects,
        selectedPortfolio,
        wbsMinWidth,
        isShortcutBigOpen,
        isFullScreen,
        oppIndex,
        mapDataAccessReferences,
        handleOpenReferenceModal,
        referenceCreateUpdateObject,
        handleSaveReference,
        referenceModalItem,
        wbsDispatcher,
        referenceSavingLoader,
        appDispatcher,
    }) {

        ////////////////
        // STATE ///////
        ////////////////
        const [state, setState] = useState({
            hasFetchedReferences: false,
            fetchingReference: false,
            blockFetching: false,
        });


        const canProjectHaveReference = useMemo(() => {

            let isVersionFollowingAvailable = true;

            // TEST IF THERE'S ANY VERSION FOLLOWINGS (IF DELETED ALL IN THE MODAL)
            if(referenceModalItem && referenceModalItem.versionFollowing.length === 0){
               isVersionFollowingAvailable = false;
            }

            //TEST
            // return ['5df77055-9cfe-46aa-827f-ec389c164b3c', '4266c8e9-f676-4104-abce-1eec231e9d91']?.includes(selectedProject.id) &&

            //DEMO
            // return ['90de6efe-cfab-4404-b43c-2b8434e2733a', '004c235e-722b-46ab-94ce-e2d9146b8ea6']?.includes(selectedProject.id) &&

            //DEV
            return ['23f91fb7-c250-4422-85e6-5fca4f5dac97', '81882760-959c-431a-b5a1-aa898da1e42c', '5d1646e5-7ba4-4122-a0f1-9bccae92d84f', 'ad49221e-8d02-4e0f-885e-0eaf445d09c9']?.includes(selectedProject.id) &&
            isVersionFollowingAvailable;

        }, [referenceModalItem, selectedProject.id]);

        //GET REFERENCE DATA ACCESS
        const handleFetchReferenceDataAccess = useCallback(async (e) => {
            try {

                //UPDATE STATE
                setState((prevState) => ({
                    ...prevState,fetchingReference: true
                }))

                // TRY TO GET SCHEDULE REFERENCE FROM DATA-MAPPING BACK-END API.
                const scheduleDataAccess = await getReferenceDataAccess(
                   selectedProject.coreDataOrganizationCoreDataProjectId,
                   selectedProject.id,
                   "schedule",
                 );

                //!! What means needsBlocking ?
                const needsBlocking = scheduleDataAccess.length === 0 || (scheduleDataAccess.length > 0 && scheduleDataAccess[0].versionFollowing?.length === 0);

                // IF NEEDS BLOCKING, THEN SHOW TOAST
                if(needsBlocking) {
                    appDispatcher({
                        type: "SET_KEY_VALUE",
                        key: 'appToast',
                        value:{
                            display: true,
                            color: 'good',
                            message: APP_TOAST_TXT.fetchReferenceEmpty,
                        },
                    });
                }

                //UPDATE STATE
                setState((prevState) => ({
                    ...prevState,
                    hasFetchedReferences: true,
                    fetchingReference: false,
                    blockFetching: needsBlocking
                }));

                //SOMETHING TO DISPLAY ?
                if(scheduleDataAccess.length > 0 && !needsBlocking) {

                    //APP STATE
                    appDispatcher({
                        type: "SET_GLOBAL_STATE",
                        options: {
                        source: "appSettings",
                        object: {
                            actionModal: "wbsChooseReference",
                            referenceModalItem: scheduleDataAccess[0],
                        },
                        },
                    });

                    //WBS STATE
                    wbsDispatcher({
                        type:"SET_STATE_OBJECT",
                        object:{
                          mapDataAccessReferences:{
                                ...DEFAULT_MAP_DATA_ACCESS_REFERENCES,
                                schedule: scheduleDataAccess.length > 0 ? scheduleDataAccess[0] : null,
                            }
                        }
                    });
                }

            //ERROR
            } catch (error) {

                //CONSOLE
                console.log('Error fetching references...', error);

                //THIS STATE
                setState((prevState) =>
                ({
                    ...prevState,fetchingReference: false
                }))
            }
        } ,[appDispatcher, selectedProject.coreDataOrganizationCoreDataProjectId, selectedProject.id, wbsDispatcher]);

        ////////////////////////////////////////////////////////////////////////////
        /// COMPONENT RENDER ///////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////

        return (

            <div
                className='structureBlockTitle flexStartCenter'
                style={{
                    height:APP_WBS_STRUCTURE.headerHeight,
                    padding:"0px",
                    margin:"0px",
                    position:"sticky",
                    top: 0,
                    zIndex:isFullScreen ? 0 : 201,
                    minWidth: wbsMinWidth + 'px',
                    borderBottomColor: isSelectedProject ? "var(--border-color-gray-1)" : null,
                }}
            >
                {isSelectedProject ?
                    <React.Fragment>

                        {/* FIRST DIV */}
                        <div
                            className='structureBlockTitleFirstCell flexStartCenter'
                            style={{
                                maxWidth: APP_WBS_STRUCTURE.firstColumnWidth + "px",
                                minWidth: APP_WBS_STRUCTURE.firstColumnWidth + "px",
                            }}
                        >
                            {selectedBranchWbsId ?

                                portfolioProjects === 0 ?
                                    <React.Fragment>

                                        {/* PICTURE */}
                                        <img
                                            id="organizationPic"
                                            src={selectedProject.logoLitle && selectedProject.logoLitle !== "" ? selectedProject.logoLitle : piclogoDefault}
                                            className='commonOrganizationLitleLogo'
                                            alt=""
                                            style={{margin:"0px 10px 0px 0px"}}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src=piclogoDefault;
                                            }}
                                        />


                                        {/* NAME */}
                                        <div className="roadmapHeaderTemplateTitleText">
                                            {selectedProject.name}
                                        </div>
                                    </React.Fragment>
                                :
                                    <React.Fragment>

                                        {/* PICTURE */}
                                        <img
                                            id="organizationPic"
                                            src={selectedPortfolio.pictureUrl && selectedPortfolio.pictureUrl !== "" ? selectedPortfolio.pictureUrl : piclogoDefault}
                                            className='commonOrganizationLitleLogo'
                                            alt=""
                                            style={{margin:"0px 10px 0px 0px"}}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src=piclogoDefault;
                                            }}
                                        />

                                        {/* NAME */}
                                        <div className="roadmapHeaderTemplateTitleText">
                                            {selectedPortfolio.name}
                                        </div>
                                    </React.Fragment>


                            :
                                portfolioProjects === 0 ?
                                    <React.Fragment>

                                        {/* PICTURE */}
                                        <img
                                            id="organizationPic"
                                            src={selectedOrganization.logoLitle && selectedOrganization.logoLitle !=="" ? selectedOrganization.logoLitle : piclogoDefault}
                                            className='commonOrganizationLitleLogo'
                                            alt={selectedOrganization.name}
                                            style={{margin:"0px 10px 0px 0px"}}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src=piclogoDefault;
                                            }}
                                        />


                                        {/* NAME */}
                                        <div className="roadmapHeaderTemplateTitleText">
                                            {selectedOrganization.name}
                                        </div>
                                    </React.Fragment>
                                :
                                    <React.Fragment>

                                        {/* ICON */}
                                        <span
                                            className="material-icons structureBlockTitleCellIcon"
                                            style={{
                                                fontSize:"28px",
                                                marginRight:"15px"
                                            }}
                                        >
                                            hub
                                        </span>

                                        {/* TITLE */}
                                        <div>
                                            Portfolio View
                                            <div
                                                style={{
                                                    fontWeight:400
                                                }}
                                            >
                                                Contains {portfolioProjects} projects
                                            </div>
                                        </div>
                                    </React.Fragment>

                            }
                        </div>

                        {/* COLUMN */}
                        {wbsSettings.domain.map((item, index)=>{


                            //ONLY FIELD WE NEED TO DISPLAY
                            if(item.displayInColumn && item.id !== "opportunity"){
                                return(
                                    <React.Fragment  key={"wbsBlockHeader-" + item.id}>

                                        {/* CHECK FOR OPP SORT */}
                                        {
                                           oppIndex === index ?

                                            <div
                                                key={"wbsElement-opportunity"}
                                                className='structureBlockTitleCell flexCenterCenter'
                                                style={{
                                                    minWidth: APP_WBS_STRUCTURE.minCellWidth + "px",
                                                    cursor:"default",
                                                    backgroundColor: 'white',
                                                }}

                                            >
                                                {/* ICON + NAME */}
                                                <div
                                                    className='flexCenterCenter'
                                                    id="tooltipHeaderWbs-opportunity"
                                                    style={{
                                                        cursor:"help"
                                                    }}
                                                >

                                                    {/* ICON */}
                                                    <span className="material-icons structureBlockTitleCellIcon">
                                                        {item.icon}
                                                    </span>

                                                    {/* NAME */}
                                                    <span className="structureBlockTitleCellTitle">
                                                        {wbsSettings.domain.find((domain) => domain.id === "riskOpp").opportunityName}
                                                    </span>

                                                    <CvtTooltip
                                                        anchorId="tooltipHeaderWbs-opportunity"
                                                        content={"Escalated opportunities (sum)"}
                                                    />
                                                </div>
                                            </div>
                                        :null}

                                        <div
                                            key={"wbsElement-" + item.id}
                                            className='structureBlockTitleCell flexCenterCenter'
                                            style={{
                                                minWidth: APP_WBS_STRUCTURE.minCellWidth + "px",
                                                cursor:"default",
                                                backgroundColor: 'white',
                                            }}

                                        >
                                            {/* ICON + NAME */}
                                            <div
                                                className='flexCenterCenter'
                                                style={{
                                                    cursor:"help"
                                                }}
                                                id={"tooltipHeaderWbs-" + item.id}
                                            >

                                                {/* ICON */}
                                                <span className="material-icons structureBlockTitleCellIcon">
                                                    {item.icon}
                                                </span>

                                                {/* NAME */}
                                                <span className="structureBlockTitleCellTitle">
                                                    {item.name}
                                                </span>


                                                <CvtTooltip
                                                    anchorId={"tooltipHeaderWbs-" + item.id}
                                                    content={item.subtitle}
                                                />
                                            </div>

                                            {/* IF THERE'S A REFERENCE */}
                                            {
                                                // mapDataAccessReferences[item.id] &&
                                                canProjectHaveReference && item.id === "schedule" && !state.blockFetching ?

                                                <CvtButton
                                                    color={referenceCreateUpdateObject && referenceModalItem?.category === item.id ? "red" : "white"}
                                                    shape="noShape"
                                                    icon={
                                                        !state.hasFetchedReferences ? "download" : referenceCreateUpdateObject && referenceModalItem?.category === item.id ? 'save' : 'source'
                                                    }
                                                    loader={
                                                        (referenceSavingLoader[item.id] === true && referenceCreateUpdateObject !== null) || state.fetchingReference === true
                                                    }
                                                    tooltip
                                                    tooltipPosition="top"
                                                    tooltipTxt={
                                                        referenceCreateUpdateObject && referenceModalItem?.category === item.id ? 'Save Reference' : `Get reference data for ${item.id}`
                                                    }
                                                    tooltipTheme="dark"
                                                    handleSubmit={(e) => {
                                                        // IF STILL HASN'T FETCHED REFERENCE DATA
                                                        if(!state.hasFetchedReferences && selectedProject && selectedProject.coreDataOrganizationCoreDataProjectId && selectedProject.id) {

                                                            // FETCH MAP DATA ACCESS AND OPEN MODAL
                                                            handleFetchReferenceDataAccess(e);
                                                        }
                                                        // IF ALREADY FETCHED, AND THERE'S DATA LOADED ON THE APP CACHE AND READY TO BE SAVED
                                                        else if(referenceCreateUpdateObject && referenceModalItem?.category === item.id){
                                                            // SAVE REFERENCE
                                                            handleSaveReference(e, item.id);
                                                        }
                                                        // ELSE, IF FETCHED DATA, THERE'S NO DATA LOADED ON THE APP CACHE AND MODAL IS NOT OPEN
                                                        else{
                                                            handleOpenReferenceModal(e, item.id);
                                                        }
                                                    }}

                                                />
                                            :null}
                                        </div>


                                        {/* OPP AT LAST */}
                                        {/* !!NEED OPTIMIZATION  */}
                                        {
                                            oppIndex === (index + 1) && oppIndex === wbsSettings.domain.length ?

                                            <div
                                                key={"wbsElement-opportunity"}
                                                className='structureBlockTitleCell flexCenterCenter'
                                                style={{
                                                    minWidth: APP_WBS_STRUCTURE.minCellWidth + "px",
                                                    cursor:"default",
                                                    backgroundColor: 'white',
                                                }}

                                            >
                                                {/* ICON + NAME */}


                                                <div
                                                    className='flexCenterCenter'
                                                    id="2"
                                                    style={{
                                                        cursor:"help"
                                                    }}
                                                >

                                                    {/* ICON */}
                                                    <span className="material-icons structureBlockTitleCellIcon">
                                                        {item.icon}
                                                    </span>

                                                    {/* NAME */}
                                                    <span className="structureBlockTitleCellTitle">
                                                        {wbsSettings.domain.find((domain) => domain.id === "riskOpp").opportunityName}
                                                    </span>

                                                </div>

                                            </div>
                                        :null}
                                    </React.Fragment>
                                )
                            }

                            // IF NOT RETURN NULL
                            return null
                        })}
                    </React.Fragment>
                :
                    <div
                        className='flexStartCenter'
                        style={{
                            fontSize:"18px",
                            fontWeight:500,
                            color:"var(--color-text-3)"
                        }}
                    >
                        <span
                            className="material-icons importSubTitleIcon "
                            style={{
                                marginRight: "8px",
                                marginLeft: "10px"
                            }}
                        >
                            {icon ? icon : 'folder_off'}
                        </span>
                        {message ? message : "No Portfolio / Organization / Project selected"}
                    </div>
                }


            </div>

        )
    }
)