import { CvtSpinnerGrow } from "@mi-gso/cvt";

// COMMON LOADER WHEN LOADING DATA
export default function BoardLoader({
  text,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
}) {
  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////
  return (
    <div
      style={{
        marginTop: marginTop,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginBottom: marginBottom,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CvtSpinnerGrow
        marginRight={text ? "10px" : "5px"}
        text={text ? text : null}
      />
    </div>
  );
}
