//LIB
import CryptoJS from 'crypto-js'
import {API, graphqlOperation} from 'aws-amplify'
import {Auth} from '@aws-amplify/auth'

//CORE
import { 
    FUNC_GET_BATCH_CHUNKS, 
    FUNC_GET_INPUT_FROM_OBJECT, 
    GET_LATEST_QUERY_TO_RESULT_OBJECT 
} from '../../../00-Core/Standards';

//APP
import {authCleanUserDataCookie} from '../01-Auth/00-Functions/AuthSignOut'

///////////////////////////////////
// SUMMARY
// - mutateGraphql
// - multipleMutateGraphql
// - batchMutationGraphql
// - batchQueryGraphql
// - multipleQueryMultipleMutationGraphql << TO BE DELETED
// - getGraphql
// - CLEAN APP ATTRIBBUTES
///////////////////////////////////

/////////////////////////
/// ENCRYPT - DECRYPT ///
/////////////////////////

export const cvtCrypto = (
    type,
    string,
    key
) =>{

    //SWITCH TYPE
    switch(type){

        //ENCRYPT
        case "encrypt":
            var encryptedString = CryptoJS.AES.encrypt(string, key).toString();
            return encryptedString;

        //DECRYPT
        case "decrypt":
            var bytes  = CryptoJS.AES.decrypt(string, key);
            var decryptedString = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedString;

        //ERROR
        default:
            const err = new Error('Access Denied!');
            err.statusCode = 403;
            throw err
    }
}

/////////////////////////
/// ENCRYPT - DECRYPT ///
/////////////////////////

export const cvtCryptoApi = async (
    type,
    string,
    key
) =>{

    //INIT
    let myInit = { 
        body: {
            "code": string,
            "type": type,
            "key" : key
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //CALL API
    const result = await API.post("mpCoreRestApi", "/core/crypto", myInit)
    return result.body
}


//SIMPLE MUTATION
export async function mutateGraphql(
    query, 
    object, 
    appDispatcher,
    onDeleteItemIfIssue
) {
    try {

        //CALL
        await API.graphql(
            graphqlOperation(
                query, 
                { 
                    input: object,
                    limit: 10000 
                }
            )
        );

        //DELETE USER DATAT COOKIE
        authCleanUserDataCookie()

    //ERRRO
    } catch (err) {
        console.log("error creating item...", err);

        //TEST IF WE NEED TO DELETE ITEM FROM STATE IF ISSUE
        if(onDeleteItemIfIssue){

            //PASS ITEM DELETION IN FRONT WITH MESSAGE
            onDeleteItemIfIssue(object.id)

        //NORMAL
        }else{
            appDispatcher({
                type: "SET_GLOBAL_STATE",
                options:{
                source: "appToast",
                    object: {
                        display:true,
                        color:"danger",
                        message:"Mutation Issue!",
                    },
                }
            })
        }
    }
}

//MULTIPLE MUTATION
export async function multipleMutateGraphql(
    query, 
    objects,
    appDispatcher
) {

    //TRY IT
    try {

        //PROMISE ALL
        await Promise.all(
            objects.map(item => API.graphql(
                graphqlOperation(
                    query, 
                    { 
                        input: item, 
                        limit: 10000 
                    }
                )
            ))
        );

        //DELETE USER DATAT COOKIE
        authCleanUserDataCookie()

    //ERRROR
    }catch(err){
        console.log("error multiple mutation item...", err);
        appDispatcher({
            type: "SET_GLOBAL_STATE",
            options:{
            source: "appToast",
                object: {
                    display:true,
                    color:"danger",
                    message:"Mutation Issue!",
                },
            }
        })
    }
}

// BATCH MUTATION
export async function batchMutationGraphql(
    queryName,
    objects,
    appDispatcher,
) {

    try {

        // INIT TXT MUTATION
        const txtMutation = objects.map((obj, index) => {
            
            //CONVERT OBJECT INTO QUERY FORMAT
            const input = FUNC_GET_INPUT_FROM_OBJECT(obj);
            
            //RETURN MUTATION LIST
            return `mutation${index}: ${queryName}(input: {
                ${input}
            }) { id }`;
        });

        // DIVIDE INTO CHUNKS
        const mutationChunks = FUNC_GET_BATCH_CHUNKS(txtMutation);

        // LOOP THROUGH EACH CHUNK
        for(const chunk of mutationChunks){

            // BATCH MUTATION STRING
            const batchMutation = `mutation batchMutation {
                ${chunk}
            }`;

            // MAKE API CALL
            await API.graphql(
                graphqlOperation(
                    batchMutation
                )
            );
        }

        // CLEAR COOKIES
        authCleanUserDataCookie()

    //ON ERROR
    } catch (error) {

        //CONSOLE
        console.log("error multiple query multiple mutation item...", error);
        
        //APP STATE
        appDispatcher({
            type: "SET_GLOBAL_STATE",
            options:{
            source: "appToast",
                object: {
                    display:true,
                    color:"danger",
                    message:"Mutation Issue!",
                },
            }
        })
    }
}

// BATCH QUERY
export async function batchQueryGraphql(
    // QUERY OBJECTS [{queryName: 'getCharterById', options: {limit: 100000, projectId: '2313123-213-21-321-'} }]
    queryObjects,
) {
    try {

        // GET LIST QUERY RESULT OBJECT
        const queryToResultObj = GET_LATEST_QUERY_TO_RESULT_OBJECT(
            queryObjects.map((queryObj) => queryObj.queryName.charAt(0).toLowerCase() + queryObj.queryName.slice(1))
        );

        // TXT QUERY ARRAY
        const txtQuery = queryObjects.map((obj, index) => {

            return `${obj.queryName}_${index}: ${obj.queryName}(
                ${
                    Object.keys(obj.options).map((optionKey) => {
                        return ` ${optionKey}: ${typeof obj.options[optionKey] === "string" ? `"${obj.options[optionKey]}"` : obj.options[optionKey]}`
                    })
                }
            ) {
                items {
                    ${queryToResultObj[obj.queryName.charAt(0).toLowerCase() + obj.queryName.slice(1)]}  
                }                
                nextToken
            }`;
        });

        // GET CHUNKS
        const queryChunks = FUNC_GET_BATCH_CHUNKS(txtQuery);

        // GET RESULTS
        const results = await Promise.all(
            queryChunks.map((queryChunk) => {
                const batchQuery = `query batchQuery {
                    ${queryChunk}
                }`;
                
                return API.graphql(
                    graphqlOperation(
                        batchQuery
                    )
                );
            })
        );
        
        let toReturn = [];

        for(let queryName of Object.keys(results[0].data)) {

            const correctQueryName = queryName.split("_")[0];
           
            toReturn.push({
                data: {
                    [correctQueryName]: {
                        ...results[0].data[queryName], 
                    }
                }
            })
        }

        return toReturn;

    } catch (error) {
        console.log("Error using batch query...", error);
    }
}

//!!! TO BE DELETED
export async function multipleQueryMultipleMutationGraphql(data, appDispatcher) {
    // KEY WILL BE THE TITLE OF THE QUERY, THE ACTUAL QUERY STRING WILL BE INSIDE THE OBJECT IN THE DATA RRAY.

    // data: { createTeles...: {objects: [item1, item2], query: telescope...}, deleteTelescope....: [{id: 1}, {id: 2}].... }

    try {
        
        await Promise.all(
            Object.keys(data).map(async (queryName) => {
                for(let item of data[queryName].objects) {
                    API.graphql(graphqlOperation(data[queryName].query, {input: item, limit: 10000}))
                }
            })
        );

        // CLEAR COOKIES
        authCleanUserDataCookie();
    } catch (error) {
        console.log("error multiple query multiple mutation item...", error);
        appDispatcher({
            type: "SET_GLOBAL_STATE",
            options:{
            source: "appToast",
                object: {
                    display:true,
                    color:"danger",
                    message:"Mutation Issue!",
                },
            }
        })
    }
}

//GET DATA FROM GRAPHQL 
export async function getGraphql(query) {

    //TRY
    try {

        //CALL GRAPHQL
        const data = await API.graphql(graphqlOperation(query, {limit: 10000}));
        return data;
    
    //ON ERROR
    } catch (err) {
        console.log("error fetching data..", err);
    }
}
