import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING } from "../../../../../00-Core/Standards";
import { WBS_DEFAULT_NAVIGATION_OPTIONS } from "../../../00-Wbs/00-Helpers/WbsConstants";
import { WBS_OPEN_ITEM } from "../../../00-Wbs/00-Helpers/WbsFunctions";
import { FUNC_ACTION_REMOVE_LINK } from "../../00-Helpers/ActionsFunctions";
import ActionCardLinkItem from "./ActionLinksListItem";

export default function ActionLinksList({
    action,
    actionsDispatcher,
    appDispatcher,
    isItemClickEnabled,
    links,
    onDeleteListItem,
    projectData,
    authorizedProjectData,
    currentUserId,
    securityGroup,
    wbsDispatcher,
    canModifySelected,
    isReadOnlyMode,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // PARSE LINKS DATA
    const parsedLinks = useMemo(() => {
        return FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(links);
    }, [links]);

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // DELETE ONE ACTION LINK
    const onDeleteLinkItem = useCallback(
        (e, link) => {
            // DELETE FROM LINK MODAL
            if (onDeleteListItem) {
                onDeleteListItem(e, link.id);
            }
            // DELETE FROM ACTION CARD CONNECTIONS TAB
            else {
                FUNC_ACTION_REMOVE_LINK(
                    link,
                    action,
                    projectData,
                    currentUserId,
                    appDispatcher,
                    actionsDispatcher
                );
            }
        },
        [action, actionsDispatcher, appDispatcher, currentUserId, onDeleteListItem, projectData]
    );

    // OPEN DESTINATION LINK ITEM
    const onLinkItemClick = useCallback(
        (e, link) => {
            e.stopPropagation();

            if (!action) {
                return;
            }

            // IF OPENING ITEM ON CLICK IS ENABLED
            if (isItemClickEnabled) {
                // BUILD WBS NAVIGATION OPTIONS
                let wbsNavigationOptions = _.cloneDeep(WBS_DEFAULT_NAVIGATION_OPTIONS);

                // SET ORIGIN
                wbsNavigationOptions.originDomain = "action";
                wbsNavigationOptions.originItemId = action.id;

                // SET DESTINATION
                wbsNavigationOptions.goToItemId = link.destinationItemId;
                wbsNavigationOptions.goToDomain = link.type;

                // SET OPTION TO OPEN CONNECTION TABS
                wbsNavigationOptions.options = {
                    openLinksTab: true,
                };

                // OPEN THE DESTINATION ITEM
                WBS_OPEN_ITEM(
                    projectData,
                    link.type,
                    link.destinationItemId,
                    null,
                    wbsNavigationOptions,
                    wbsDispatcher,
                    appDispatcher,
                    securityGroup
                );
            }
        },
        [action, appDispatcher, isItemClickEnabled, projectData, securityGroup, wbsDispatcher]
    );
  
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexColStartStart"
            style={{
                fontSize: "14px",
                width: "100%",
                padding: "5px 0px",
            }}
        >
            {/* LINKS LIST */}
            {parsedLinks.map((link) => (
                <ActionCardLinkItem
                    key={"actionCardLinkItem_" + link.id}
                    link={link}
                    projectData={projectData}
                    onLinkItemClick={onLinkItemClick}
                    onDeleteLinkItem={onDeleteLinkItem}
                    canModifySelected={canModifySelected}
                    isReadOnlyMode={isReadOnlyMode}
                />
            ))}

            {/* CASE NO ITEMS */}
            {parsedLinks.length === 0 ? (
                <div
                    className="cardDetailsNoData flexStartCenter"
                    style={{
                        padding: "0px",
                        paddingLeft: "15px",
                    }}
                >
                    <span
                        className="material-icons alertIconInfoLigth"
                        style={{
                            marginRight: "10px",
                            fontSize: "20px",
                        }}
                    >
                        info
                    </span>
                    No items
                </div>
            ) : null}
        </div>
    );
}