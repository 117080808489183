import React from 'react'
import { CvtButton} from '@mi-gso/cvt'

// FILTER HEADER COMPONENT
export default function FiltersHeader({
    handleOpenWbsFilter
}) {
  return (
    <div className="filterHeader flexBetweenCenter">

        {/* TITLE */}
        <div className='filterHeaderTitle flexStartCenter'>
            <span
                className="material-icons"
                style={{
                    marginRight:"10px",
                    color:"var(--color-migso-blue)"
                }}
            >
                filter_center_focus
            </span>
            Filter Center
        </div>

        {/* BUTTONS */}
        <div className='flexStartCenter'>

            {/* CLOSE */}
            <CvtButton
                color="white"
                shape="noShape"
                icon="close"
                iconFontSize="24px"
                iconMarginRight="10px"
                padding="4px 10px"
                handleSubmit={handleOpenWbsFilter}
                fontWeight={500}
            />
        </div>
    </div>
  )
}
