import {memo} from 'react';
import WbsElementBigStructure from './WbsElementBigStructure';
import WbsElementStructureBody from './WbsElementStructureBody';


export default memo(function WbsNoData({
    fieldInformation,
    handleBigComponent,
    wbsName,
    bigComponentIsExpanded,
    bigComponentExpandFunction,
    tooltipInfo,
}){
    return (
        <WbsElementBigStructure 
            fieldInformation={fieldInformation}
            handleBigComponent={handleBigComponent}
            projectTitle={wbsName}
            buttonExpand={true}
            isExpendedEnabled={bigComponentIsExpanded}
            functionExpand={(e) => bigComponentExpandFunction(e)}
            tooltipInfo={tooltipInfo}
        >
                <WbsElementStructureBody
                    isExpendedEnabled={bigComponentIsExpanded}
                    blockFlex="flexCenterCenter"
                >
                    <span
                        style={{
                            fontWeight:'bold',
                            fontSize: '26px',
                        }}
                    >
                        No Data
                    </span>

                </WbsElementStructureBody>
        </WbsElementBigStructure>
    );
});