import React from 'react'
import { CvtButton, CvtInput, CvtColorGroup, CvtInputTextArea, CvtSelect, CvtSelectMultiple, CvtCheckbox,CvtTooltip } from '@mi-gso/cvt'

//CORE
import { APP_FORM_STYLE } from '../../../../00-Core/Constants'
import GovReviewAgendaSideBar from '../../04-Governance/01-Components/02-GovReviewAgendaSideBar/GovReviewAgendaSideBar'
import GovReviewPeriodicitySideBar from '../../04-Governance/01-Components/01-GovReview/GovReviewPeriodicitySideBar'
import ActionLinksSideBar from '../../01-Action/01-Components/ActionEditSideBar/ActionLinksSideBar'

//SIDE BAR EDITION ITEM
export default function EditSideBarItem({
    item,
    handleChange,
    testError,
    errorMessage,
    projectData,
    lastItem,
    entireContent,
    currentUserId,
    isCurrentUserEditor,
}) {
    //////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////
    //////////////////////////////////////////////////////

    return (
        <React.Fragment>

            {/* LIST */}
            {item.component === "select" ?
                <CvtSelect
                    selectBlockCss="flexColStartStart modalGroup"
                    selectFontSize={APP_FORM_STYLE.fontSize}
                    selectCss={!item.optionLeftComponent ? "form-select modalInputLight modalSelectLight" : "modalInputLight modalSelectLight modalReactSelect"}
                    selectPadding={APP_FORM_STYLE.selectPadding}
                    selectBlockMargin={APP_FORM_STYLE.blockMargin}
                    selectPlaceHolder={item.placeholder && item.placeholder !=="" ? item.placeholder : null}
                    legendColor={APP_FORM_STYLE.legendColor}
                    legendFontSize={APP_FORM_STYLE.legendFontSize}
                    legendMargin={APP_FORM_STYLE.legendMargin}
                    name={item.key}
                    legend={item.label}
                    legendWidth="100%"
                    value={item.value}
                    list={item.options}
                    listValue={item.options.length > 0 && item.options[0].id ? "id" : "value"}
                    listName={item.options.length > 0 && item.options[0].name ? "name" : "label"}
                    onChange={(e)=> handleChange(e.target.name, e.target.value, item.type)}
                    info={item.info && item.info !== "" ? item.info : null}
                    infoId={item.info && item.info !== "" ? "info-" + item.key : null}
                    mandatory={item.mandatory}
                    optionLeftComponent={item.optionLeftComponent ? item.optionLeftComponent : null}
                />
            :null}

            {/* MULTI SELECT */}
            {item.component==="multi_select" ?
                <CvtSelectMultiple
                    selectBlockCss="flexColStartStart modalGroup"
                    selectFontSize={APP_FORM_STYLE.fontSize}
                    selectCss="form-select"
                    selectPadding={APP_FORM_STYLE.selectPadding}
                    selectBlockMargin={APP_FORM_STYLE.blockMargin}
                    placeholder={item.placeholder && item.placeholder !=="" ? item.placeholder : null}
                    legendColor={APP_FORM_STYLE.legendColor}
                    legendFontSize={APP_FORM_STYLE.legendFontSize}
                    legendMargin={APP_FORM_STYLE.legendMargin}
                    name={item.key}
                    legendTxt={item.label}
                    legendWidth="100%"
                    value={item.value}
                    options={item.options}
                    listValue={item.options.length > 0 && item.options[0].id ? "id" : "value"}
                    listName={item.options.length > 0 && item.options[0].name ? "name" : "label"}
                    onChange={(newArray) => handleChange(item.key, newArray, item.type)}
                    info={item.info && item.info !== "" ? item.info : null}
                    infoId={item.info && item.info !== "" ? "info-" + item.key : null}
                    mandatory={item.mandatory}
                    closeOnSelect={false}
                    canCreate={item.canCreate === true ? true : false}
                />
            :null}

            {/* INPUT */}
            {item.component === "input"  ?
                <React.Fragment>
                    <CvtInput
                        type={item.type}
                        legend={item.label}
                        legendWidth="100%"
                        placeholder={item.placeholder && item.placeholder !=="" ? item.placeholder : null}
                        legendColor={APP_FORM_STYLE.legendColor}
                        legendMargin={APP_FORM_STYLE.legendMargin}
                        legendFontSize={APP_FORM_STYLE.legendFontSize}
                        inputBlockCss="flexColStartStart modalOptionSubBlock"
                        inputPadding={APP_FORM_STYLE.inputPadding}
                        inputCss={"form-control modalInputLight " + (testError ? "modalInputAlert" : "")}
                        inputFontSize={APP_FORM_STYLE.fontSize}
                        inputBlockPadding="0px"
                        inputBlockMargin={APP_FORM_STYLE.blockMargin}
                        inputWidthFull={true }
                        inputWidth={item.type === "number" ? "100%" : null}
                        name={item.key}
                        maxLength={item.maxLength ? item.maxLength : null}
                        value={item.value && item.value !=="" ? item.value : ""}
                        onChange={(e)=> handleChange(e.target.name, e.target.value, item.type)}
                        tooltipId={testError ? ("err-" + item.key) : null}
                        inputUnitCss="bigComponentBodyMiniBlockTitleUnit"
                        inputUnit={item.unit}
                        info={item.info && item.info !== "" ? item.info : null}
                        infoId={item.info && item.info !== "" ? "info-" + item.key : null}
                        mandatory={item.mandatory}
                        maxDate={item.maxDate}
                        minDate={item.minDate}
                        dateStep={item.dateStep}
                    />

                    {/* TOOLTP ERROR */}
                    {testError ?
                        <CvtTooltip
                            anchorId={"err-" + item.key}
                            content={errorMessage}
                        />
                    :null}
                </React.Fragment>
            :null}

            {/* INPUT TEXTAERA */}
            {item.component === "textArea"  ?
                <CvtInputTextArea
                    type={item.type === "richTextArea" ? "richTextArea" : "textAera"}
                    placeholder={item.placeholder && item.placeholder !=="" ? item.placeholder : null}
                    legend={item.label}
                    legendWidth="100%"
                    legendMargin={APP_FORM_STYLE.legendMargin}
                    legendColor={APP_FORM_STYLE.legendColor}
                    legendFontSize={APP_FORM_STYLE.legendFontSize}
                    inputBlockCss="flexColStartStart modalOptionSubBlock"
                    inputPadding="0px"
                    inputCss={"form-control modalInputLight " + (testError ? "modalInputAlert" : "")}
                    inputHeight="80px"
                    inputBlockHeight="calc(100% - 42px)"
                    inputFontSize={APP_FORM_STYLE.fontSize}
                    inputBlockPadding="0px"
                    inputBlockMargin={APP_FORM_STYLE.blockMargin}
                    inputWidthFull={true}
                    name={item.key}
                    value={item.value && item.value !=="" ? item.value : ""}
                    onChange={(e)=> handleChange(e.target.name, e.target.value, item.type)}
                    info={item.info && item.info !== "" ? item.info : null}
                    infoId={item.info && item.info !== "" ? "info-" + item.key : null}
                    mandatory={item.mandatory}
                    enrichTitleCss="enrichTextTilteSideBar"
                    enrichEditorCss="modalInputLight"
                />
            :null}

            {/* BUTTON LINK TO A FIELD */}
            {item.component === "rightButton" ?
                <CvtButton
                    color={item.iconColor ? item.iconColor : "gray"}
                    iconColor={item.iconCss === "toggleIconRiskOppIcon" ? "var(--color-migso-yeallow)" : null}
                    shape="noShape"
                    margin="0px 0px 0px -15px"
                    icon={item.value && item.value !== "" ? item.iconTrue : item.iconFalse}
                    handleSubmit={(e) => handleChange(item.key,item.value && item.value !== "" ? false : true, item.type)}
                    tooltip={item.iconTooltipTrue ? true : false}
                    tooltipTxt={item.value && item.value !== "" ? item.iconTooltipFalse : item.iconTooltipTrue}
                    tooltipPosition="top"
                    tooltipTheme="dark"
                />
            :null}

            {/* //!!!!!! to be reviewed ICON TOGGLE */}
            {item.component === "icon_toggle" ?
                <CvtCheckbox
                    title={item.label}
                    titleCss="toggleIconModalTitle"
                    titleWidth={item.titleWidth ?? null}
                    marginTop="-3px"
                    value={item.value}
                    name={item.key}
                    titleFirst={true}
                    isFlexStart={true}
                    disabled={item.disabled ? true : false}
                    handleFunction={(e) => handleChange(
                        item.key,
                        e.target.checked,
                        "icon_toggle",
                    )}
                    jsx={
                        <div className={`material-icons ${item.iconCss ? item.iconCss : ""}`} key={`icon_toggle_icon_${item.value}`}
                            onClick={(e) => handleChange(
                                item.key,
                                !e.currentTarget.previousElementSibling.checked,
                                item.type,
                            )}
                        >
                            {item.value && item.value === true ? item.iconTrue : item.iconFalse}
                        </div>
                    }
                    padding={"20px 0px 0px 10px"}
                    info={item.info && item.info !== "" ? item.info : null}
                    infoId={item.info && item.info !== "" ? "info-" + item.key : null}
                    isBlueInfoIcon={item.isBlueInfoIcon ?? false}
                    isInfoIconBeforeToggle={
                        item.isInfoIconBeforeToggle ?? false
                    }
                />
                : null
            }

            {
                item.component==="color_picker" ?
                <React.Fragment>
                     <CvtColorGroup
                        title={item.label}
                        name={item.key}
                        value={item.value}
                        handleFunction={(e) => handleChange(e.target.name, e.target.value, item.type)}
                        handlerPicker={(name, value,) => handleChange(name, value, item.type)}
                        userColors={item.options}
                        resetColor={() => handleChange(item.key, "", item.type)}
                        tooltipId={testError ? ("err-" + item.key) : null}

                        //!! NEWWWW
                        // INPUT CSS
                        inputCss={"form-control modalInputLight " + (testError ? "modalInputAlert" : "")}
                        // BLOCK INPUT CSS
                        blockInputCss='flexColStartStart modalOptionSubBlock'
                        legendColor={APP_FORM_STYLE.legendColor}
                        legendMargin={APP_FORM_STYLE.legendMargin}
                        legendFontSize={APP_FORM_STYLE.legendFontSize}
                        legendWidth="100%"
                        placeholder={item.placeholder && item.placeholder !=="" ? item.placeholder : null}
                        inputFontSize={APP_FORM_STYLE.fontSize}
                        inputBlockPadding="0px"
                        inputBlockMargin={APP_FORM_STYLE.blockMargin}
                    />


                    {/* TOOLTP ERROR */}
                    {testError ?
                        <CvtTooltip
                            anchorId={"err-" + item.key}
                            content={errorMessage}
                        />
                    :null}
                </React.Fragment>

            :null}

            {/* CUSTOM COMPONENTS */}
            {item.component === "custom" ?
                item.type ==="govReviewAgenda" ?
                    <GovReviewAgendaSideBar
                        agendaList={item.value}
                        projectData={projectData}
                        handleChange={handleChange}
                        handleOpenWbsItem={null}
                        editMode={true}
                        inReviewMode={false}
                    />
            :
                item.type === "govReviewPeriodicity" ?
                    <GovReviewPeriodicitySideBar
                        periodicity={item.value}
                        handleChange={handleChange}
                        entireContent={entireContent}
                        disabled={item.disabled}
                    />
                : 
                item.type === "actionLinks" ?
                    <ActionLinksSideBar 
                        links={item.value}
                        handleChange={handleChange}
                        projectData={projectData}
                        currentUserId={currentUserId}
                        isCurrentUserEditor={isCurrentUserEditor}
                    />
                : null
            :null}

            {/* END BLOCK TO PREVENT ANIMATION BUG WITH COLLAPSE AND PADDING BOTTOM */}
            {lastItem ?
                <div style={{height:"20px"}}></div>
            :null}
        </React.Fragment>
    )
}