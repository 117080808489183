// REACT
import { useCallback, useMemo, useState, memo } from 'react';

// PACKAGES
import _ from 'lodash';

// MIGSO 
import { Button } from '@mi-gso/cvt';

// BEATIFUL DND
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// CONSTANTS
import { RISK_OPP_PROBABILITY_LIST, RISKS_OPPS_MATRIX_SETTINGS, RISK_MATRIX_VIEW_MODE, RISK_OPP_EXPAND_MODES, RISK_OPP_INVERTED_IMPACT_LIST } from '../../00-Helpers/RiskConstants';

// COMPONENTS
import RiskMatrixItem from './RiskMatrixItem';
import RiskMatrixToogle from './RiskMatrixToogle';
import { WBS_BIG_COMPONENT_VIEW_MODE } from '../../../00-Wbs/00-Helpers/WbsConstants';

////////////////////////////
/// RISK MATRIX ////////////
////////////////////////////

export default memo(function RiskMatrix({
    riskData,
    viewMode,
    expandMode,
    handleExpandArrowClick,
    isRisk,
    wbsId,
    parentId,
    actionsData,
    riskSelectedViewMode,
    riskOppDispatcher,
    detailedRoMatrixItem,
    isExpanded,
    handleEditRoItem,
    textSearchInput,
    displayWidth,
    isCurrentUserEditor,
    currentUser,
    isReadOnlyMode,
}) {
    
    /////////////////////////////////////////////////////
    /// STATE ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    const [state, setState] = useState({
        isDragging: false,
    });

    /////////////////////////////////////////////////////
    /// MEMO ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // FILTERED RO DATA ACCORDING TO OPTION SELECTED (target, current, ..)
    const filteredRoData = useMemo(() => {
        // HERE FILTER RO DATA ACCORDING TO THE SELECTED MODE OF VIEW IN MATRIX
        let resultData = _.cloneDeep(riskData);
        // ONLY NEED TO FILTER IF SELECTED MODE IS CURRENT
        if(riskSelectedViewMode.value === RISK_MATRIX_VIEW_MODE.current.value) {
            resultData = resultData.filter((ro) => ro.currentCriticity!==null);
        }
        return resultData;
    }, [riskData, riskSelectedViewMode.value]);

    /////////////////////////////////////////////////////
    /// FUNCTIONS ///////////////////////////////////////
    /////////////////////////////////////////////////////

    // FUNCTION THAT GETS CORRECT RO ITEMS FOR SPECIFIC CRITICITY ACCORDING TO THE SELECTED MODE AS WELL.
    const getCorrectRoData = useCallback((criticity) => {
        let resultData = _.cloneDeep(filteredRoData);

        switch(riskSelectedViewMode.value) {

            case RISK_MATRIX_VIEW_MODE.initial.value:
                resultData = resultData.filter((ro) => ro.initialCriticity === criticity);
                break;
            case RISK_MATRIX_VIEW_MODE.current.value:
                resultData = resultData.filter((ro) => ro.currentCriticity === criticity);
                break;
            default:
                resultData = resultData.filter((ro) => ro.targetCriticity === criticity);
        }
        
        return resultData;
    }, [filteredRoData, riskSelectedViewMode.value]);

    // HANDLE DROP OF RO ITEM IN MATRIX CEL
    const handleDrop = useCallback((droppedRoItem) => {
       
        let roItem = filteredRoData.find((ro) => ro.id === droppedRoItem.draggableId);
       
        // GET NEW CRITICITY VALUE
        let newCriticityValue = parseInt(droppedRoItem.destination.droppableId.split("_").at(-1));

        // CHECK IF NEW CRITICITY VALUE IS SAME AS THE R&O CRITICITY, ACCORDING TO THE SELECTED MODE.
        let isSame = false;
        switch(riskSelectedViewMode.value) {
            case RISK_MATRIX_VIEW_MODE.initial.value:
                isSame = roItem.initialCriticity === newCriticityValue;
                break;
            case RISK_MATRIX_VIEW_MODE.current.value:
                isSame = roItem.currentCriticity === newCriticityValue;
                break;
            case RISK_MATRIX_VIEW_MODE.target.value:
                isSame = roItem.targetCriticity === newCriticityValue;
                break;
            default:
                break;
        }

        // IF NOT SAME, THEN SET THE NEW R&O CRITICITY VALUE ACCORDING TO THE SELECTED MODE.
        if(isSame === false) {
            let criticityKeyToUpdate;
            switch(riskSelectedViewMode.value) {
                case RISK_MATRIX_VIEW_MODE.initial.value:
                    criticityKeyToUpdate="initialCriticity";
                    break;
                case RISK_MATRIX_VIEW_MODE.current.value:
                    criticityKeyToUpdate="currentCriticity";
                    break;
                case RISK_MATRIX_VIEW_MODE.target.value:
                    criticityKeyToUpdate="targetCriticity";
                    break;
                default: 
                    break;
            }
            
            // CALL HANDLE EDIT RO ITEM
            handleEditRoItem(roItem, criticityKeyToUpdate, newCriticityValue);
        }
        
        // SET IS DRAGGING TO FALSE
        setState((prevState) => {
            return {
                ...prevState,
                isDragging: false,
            }
        })
    }, [filteredRoData, handleEditRoItem, riskSelectedViewMode.value]);

    /////////////////////////////////////////////////////
    /// COMPONENT RENDER ////////////////////////////////
    /////////////////////////////////////////////////////
    
    return (
        <div className={`actionTableContainer flexColStartCenter ${viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view || expandMode === RISK_OPP_EXPAND_MODES.tableOnly ? 'hideContainer' : ''}`}
            style={{
                width: displayWidth, 
                display: expandMode === RISK_OPP_EXPAND_MODES.tableOnly ? "none" : "",
                height: '100%',
                border: '1px solid var(--border-color-gray-0-5)',
                borderRadius: '8px',
            }}

            key={`matrix_risk_${riskSelectedViewMode.value}`}
        >

            {/* EXPAND BUTTON*/}
            <Button
                icon={expandMode === RISK_OPP_EXPAND_MODES.matrixOnly ? "chevron_left" : "chevron_right"}
                css="btn commonButtonSubmitLight leftComponentExpandButton"
                borderRadius="20px"
                iconFontSize="16px"
                handleSubmit={() => handleExpandArrowClick(
                    expandMode === RISK_OPP_EXPAND_MODES.matrixOnly ?
                    false : true, true
                )}   
            />
          
            

            {/* HEADERS (TITLE AND CURRENT/START SELECT) */}
            <div className="flexBetweenCenter" 
                style={{
                    width: '100%',
                    padding: '3px 12px',
                }}
            >   
                <div className='flexStartCenter'>
                    {/* ICON */}
                    <span className="material-icons actionsChartHeaderIcon">
                        balance
                    </span>
                    {/* TITLE */}
                    <span className='actionsChartHeaderTitle'
                    >
                        Mapping
                    </span>
                </div>
               
                
                {/* TOGGLE */}
                <RiskMatrixToogle 
                    selectedMode={riskSelectedViewMode}
                    riskOppDispatcher={riskOppDispatcher}
                />
            </div>

            {/* INNER MATRIX CONTAINER (IMPACT && LIKELIHOOD LEGENDS + MATRIX) */}

            <div className="flexStartStart flexOne"
                style={{
                    width: '100%',
                    paddingTop: '5px',
                    
                }}
            >
                    
                    {/* LEFT SIDE (IMPACT LEGEND) */}
                    <div className="flexCenterCenter"
                        style={{
                            height: '83%',
                            minHeight: '200px',
                        }}
                    >
                        <span className="riskMatrixLegend" style={{transform: "rotate(-90deg)"}}>Impact</span>
                        {/* MATRIX LEGENDs */}
                        <div className="flexColStartStart" style={{height: '100%', maxWidth: '30px'}}>
                            {
                                RISK_OPP_INVERTED_IMPACT_LIST.map((impact, index) => (
                                    <div key={`risk_matrix_legends_container_${impact.label}_${index}`} className='riskMatrixSmallLegend flexCenterCenter'>
                                        <span style={{transform:"rotate(-90deg)", whiteSpace: 'nowrap'}}>{impact.label}</span>
                                    </div>
                                    )
                                )
                            }
                        </div>
                    </div>

                    {/* RIGHT SIDE (MATRIX + LIKELIHOOD LEGEND) */}
                    <div className="flexColStartCenter flexOne" style={{height: '100%', paddingRight: '10px'}}>
                        
                        {/* MATRIX */}
                        <DragDropContext
                            onDragEnd={handleDrop}
                            onDragStart={() => setState((prevState) => ({...prevState, isDragging: true}))}
                        >
                            <div className="riskMatrix"
                            >
                                {
                                    RISKS_OPPS_MATRIX_SETTINGS.map((matrixSetting) => {

                                        let correctRo = getCorrectRoData(matrixSetting.criticity);
                                        
                                        return (
                                            <Droppable
                                                droppableId={`risk_matrix_item_${matrixSetting.criticity}`}
                                                key={`risk_matrix_item_droppable_${matrixSetting.criticity}`}
                                            >   
                                                {
                                                    (provided, snapshot) => (
                                                        <RiskMatrixItem
                                                            droppableProps={provided.droppableProps}
                                                            droppableRef={provided.innerRef}
                                                            isDragging={state.isDragging} 
                                                            key={`risk_matrix_item_${matrixSetting.criticity}`}
                                                            matrixSetting={matrixSetting}
                                                            roItems={correctRo}
                                                            isRisk={isRisk}
                                                            wbsId={wbsId}
                                                            parentId={parentId}
                                                            actionsData={actionsData}
                                                            providedPlaceholder={provided.placeholder}
                                                            isDragOver={snapshot.isDraggingOver}
                                                            riskOppDispatcher={riskOppDispatcher}
                                                            detailedRoMatrixItem={detailedRoMatrixItem}
                                                            isExpanded={isExpanded}
                                                            textSearchInput={textSearchInput}
                                                            isCurrentUserEditor={isCurrentUserEditor}
                                                            currentUser={currentUser}
                                                            isReadOnlyMode={isReadOnlyMode}
                                                        />
                                                    )
                                                }
                                                
                                            </Droppable>
                                        )
                                    })
                                }
                            </div>
                        </DragDropContext>

                        {/* BOTTOM LEGEND */}
                        <div style={{width: '100%'}}>
                            {/* MATRIX BOTTOM LEGENDS */}
                            <div className="flexStartStart">
                                {
                                    RISK_OPP_PROBABILITY_LIST.map((prob, index) => (
                                        <div key={`risk_matrix_bottom_legend_${prob.label}_${index}`} className='riskMatrixSmallLegend flexCenterCenter'>
                                            <span style={{height: '8px'}}>{prob.label}</span>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            {/* LIKELIHOOD LEGEND */}
                            <div className='flexCenterCenter' style={{width: '100%', paddingBottom: '5px'}}>
                                <span className='riskMatrixLegend'>Likelihood</span>
                            </div>
                           
                        </div>
                        
                    </div>

            </div>
        </div>
    );

});

