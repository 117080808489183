// LIBS
import { useCallback, useMemo, useState } from "react";

// FUNCTIONS
import { FUNC_DATE_IS_TODAY } from "../../../00-Core/Standards";

// COMPONENTS
import BoardReviewListItem from "./BoardReviewListItem";

export default function BoardNextReviews({ reviews, openWbsItem, boardDispatcher }) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////
    
    // LOADING ID
    const [loadingId, setLoadingId] = useState();

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // COUNT TODAY REVIEWS
    const todayReviews = useMemo(() => {
        let todayReviews = reviews.filter((review) =>
            FUNC_DATE_IS_TODAY(new Date(review.startDate))
        );

        return todayReviews.length;
    }, [reviews]);

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // ACTIVATE LOADER
    const activateLoader = useCallback((id) => {
        setLoadingId(id);
    }, []);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="structureBlock boardSectionStructureBlock"
            style={{
                minWidth: "600px",
                flex: 1,
            }}
        >
            {/* HEADER */}
            <div className="structureBlockTitle flexBetweenCenter">
                <div className="flexStartCenter">
                    <span className="material-icons structureBlockTitleIcon boardTitleIcon">
                        event
                    </span>
                    Next reviews
                </div>
            </div>

            {/* COUNT + CONTENT */}
            <div className="flexStartStart">
                {/* COUNT */}
                <div className="boardSectionLeftInfos flexColCenterCenter" style={{ gap: "20px" }}>
                    {/* TOTAL COUNT */}
                    <div className="flexColCenterCenter">
                        <span
                            className="boardSectionLeftInfosNumber boardSectionLeftInfosNumberWithBackground flexCenterCenter"
                            style={{
                                backgroundColor: "var(--color-migso-blue)",
                            }}
                        >
                            {reviews.length}
                        </span>
                        <span className="boardSectionLeftInfosText">Reviews</span>
                    </div>

                    {/* TODAY COUNT */}
                    <div className="flexColCenterCenter">
                        <span
                            className="boardSectionLeftInfosNumber boardSectionLeftInfosNumberWithBackground flexCenterCenter"
                            style={{
                                backgroundColor: "var(--color-migso-blue)",
                            }}
                        >
                            {todayReviews}
                        </span>
                        <span className="boardSectionLeftInfosText">Today</span>
                    </div>
                </div>

                {/* REVIEWS LIST */}
                <div className="structureBlockInfo boardStructureBlockInfo flexColStartStart">
                    {reviews.map((review) => (
                        <BoardReviewListItem
                            review={review}
                            key={review.id}
                            activateLoader={activateLoader}
                            openWbsItem={openWbsItem}
                            loadingId={loadingId}
                            boardDispatcher={boardDispatcher}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
