import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import _ from "lodash";
import { AGENDA_ITEM_TYPES_KEYS, APP_CORE_SANDBOX } from "../../../../00-Core/Constants";
import { FUNCT_FIND_INDEX } from "../../../../00-Core/Standards";
import { WBS_INITIAL_STATE } from "./WbsConstants";

export function WBS_GET_INITIAL_STATE(
    coreSelection,
    wbsNavigationOptions,
    selectedPortfolio,
    projectData
) {
    // GET INITIAL STATE
    let initialState = _.cloneDeep(WBS_INITIAL_STATE);

    // SET ORGANIZATION ID
    if (coreSelection?.selectedOrganization?.id) {
        initialState.organizationId = coreSelection.selectedOrganization.id;
    }

    // SET PROJECT ID
    if (coreSelection?.selectedProject?.id) {
        initialState.projectId = coreSelection.selectedProject.id;
    } else if (coreSelection.selectedOrganization.id === APP_CORE_SANDBOX.id) {
        initialState.projectId = APP_CORE_SANDBOX.id;
    }

    // SET WBS LIST
    if (coreSelection?.wbsList) {
        initialState.wbsList = coreSelection.wbsList;
    }

    // SET NAVIGATION OPTIONS
    if (wbsNavigationOptions.goToItemId || wbsNavigationOptions.goToDomain) {
        // GET DESTINATION ITEM
        let destinationItem = FIND_OBJECT_ARRAY_ITEM(
            projectData[wbsNavigationOptions.goToDomain],
            "id",
            wbsNavigationOptions.goToItemId
        );

        if (destinationItem) {
            // MAKE THE OPTIONS AVAILABLE THROUGH STATE (FOR COMPONENTS IF THEY NEED IT)
            initialState.wbsNavigationOptions = wbsNavigationOptions;

            // INIT WBS
            initialState.selectedWbsId = destinationItem.wbsId;

            // INIT DOMAIN
            if (wbsNavigationOptions.goToDomain) {
                initialState.selectedField = wbsNavigationOptions.goToDomain;
            }

            // INIT ITEM ID
            if (wbsNavigationOptions.goToItemId) {
                initialState.selectedItem = wbsNavigationOptions.goToItemId;
            }

            // SPECIFIC CASE FOR GOV REVIEW
            if (wbsNavigationOptions.goToDomain === "govReview") {
                initialState.selectedItem = null;
                initialState.selectedWbsId = null;

                initialState.rightComponent = {
                    name: "govReview",
                    data: {
                        govItem: destinationItem,
                        editMode: true,
                    },
                };
            }
        }
    }

    // SET WBS LOADER IF WE COME FROM PORTFOLIO
    if (selectedPortfolio) {
        initialState.wbsLoader = false;
    }

    return initialState;
}

//GET FILTERED DATA
export const WBS_GET_FILTERED_DATA_BY_WBS_BRANCHE = (
    projectData,
    wbsIds,
    isProject,
    projectId,
    dataConsolidation
) => {
    //INIT - ONLY SHALLOW CLONE TO PRESERV JAVASCRIPT REF
    let cloneProjectData = projectData;
    let filteredData = {};

    //GET ALL OBJECTS ATTRIBUTS TO UPDATE
    let projectDataAttributs = Object.keys(cloneProjectData);

    //UPDATE REFERNECE OBJECT WITH NEW VALUES
    for (let i = 0; i < projectDataAttributs.length; i++) {
        //GET ONLY DATA WITH WBS ID
        filteredData[projectDataAttributs[i]] = cloneProjectData[projectDataAttributs[i]].filter(
            (item) => {
                //SPECIFIC FOR RISK / OP ESCALATIION
                if (!dataConsolidation && projectDataAttributs[i] === "riskOpp") {
                    //CHECK THAT THE RISK OPP IS ESCALATED
                    if (item.escalatedTo && item.escalatedTo.length > 0) {
                        //TRY IF THE WBSIDS IS INSIDE ESCALATED TO
                        if (item.escalatedTo?.includes(wbsIds[0])) {
                            return true;
                        }
                    }
                }

                //PROJECT OR WBS BRANCHE IN CASE OF PORTFOLIO
                if (isProject && dataConsolidation) {
                    return item.projectId === projectId;
                } else {
                    return wbsIds.indexOf(item.wbsId) > -1;
                }
            }
        );
    }

    //RETURN
    return filteredData;
};

// WBS SEARCH PATH ALGORITHM
export const WBS_SEARCH_PATH_FULL = (items, id) => {
    // INIT PATH
    const path = [];

    // DEFINE RECURSIVE FUNCTION
    function find(items, id) {
        // LOOP ARRAY
        for (const item of items) {
            // CHECK IF CURRENT ITEM HAS THE SPECIFIED ID
            if (item.id === id) {
                path.push(item);
                return path;
            }
            // IF THE CURRENT OBJECT DOESN'T HAVE THE SPECIFIED ID
            else {
                // CHECK IF IT HAS CHILDREN
                if (item.children) {
                    // IF IT HAS CHILDREN, SEARCH FOR THE ID IN THE CHILDREN
                    const found = find(item.children, id);
                    if (found) {
                        path.push(item);
                        return path;
                    }
                }
            }
        }
    }

    // CALL THE RECURSIVE FUNCTION
    find(items, id);

    // RETURN THE PATH
    return path;
};

// GIVEN A SELECTED TREE ID AND A NESTED ARRAY OF TREE OBJECTS, RETURN THE CORRECT NESTED ARRAY OF TREES TO MAP IN PORTFOLIO.JS

// IF THE TREE ITEM SELECTED DOESN'T HAVE CHILDREN THEN RETURN THE PARENT PROJECT ITEM INSTEAD
// TO BE THE SELECTED TREE ID
// export const filterNestedTreeArray = (selectedTreeId, initialProjectItem) => {

export const WBS_SEARCH_NESTED_TREE = (nested, selectedTreeId) => {
    //INIT
    let result = [];
    let found = false;

    //RECURSIVE FUNCTION
    function recurse(nestedData) {
        for (let j = 0; j < nestedData.length; j++) {
            //BREAK IF FOUND
            if (found) {
                break;
            }

            // START BY CHECKING IF CURRENT IN FUNCTION IS THE SELECTED ONE
            if (nestedData[j].id === selectedTreeId) {
                // ELSE RETURN PROJECT ITEM FOUND.
                found = true;
                result.push(nestedData[j]);
                break;

                // IF CURRENT IN FUNCTION HAS CHILDREN THEN CALL THIS FUNCTION RECURSIVELY ON THEM
            } else {
                //CONTINUE
                if (nestedData[j].children.length > 0 && !found) {
                    recurse(nestedData[j].children, selectedTreeId);
                }
            }
        }
    }

    //LAUNCH RECURSIVE
    recurse(nested);

    //RETURN
    return result;
};

// BREADTH-FIRST SEARCH ALGORITHM TO TRY TO FIND A WBS IN THE CHILDREN
export const WBS_SEARCH_IN_CHILDREN = (wbs, idToSearch) => {
    // INIT QUEUE
    let queue = [...wbs.children];
    // LOOP THROUGH THE QUEUE
    while (queue.length > 0) {
        // GET THE ITEM
        const item = queue.shift();
        // CHECK IF ITEM ID IS SAME AS ID TO SEARCH
        if (item.id === idToSearch) {
            return item;
        }

        // IF NOT, THEN PUSH CHILDREN TO QUEUE
        if (item.children) {
            queue.push(...item.children);
        }
    }
    // IF REACH THIS POINT, THEN HASN'T FOUND IT
    return null;
};

// ALGORITHM THAT GIVEN A NESTED ARRAY OF WBS AND A WBS ID TO SEARCH FOR, WILL RETURN THE IDS IN THE PATH TO FIND THAT WBS.
export const WBS_SEARCH_PATH = (items, id) => {
    // INIT PATH
    const path = [];

    // DEFINE RECURSIVE FUNCTION
    function find(items, id) {
        // LOOP ARRAY
        for (const item of items) {
            // CHECK IF CURRENT ITEM HAS THE SPECIFIED ID
            if (item.id === id) {
                path.push(item.id);
                return path;
            }
            // IF THE CURRENT OBJECT DOESN'T HAVE THE SPECIFIED ID
            else {
                // CHECK IF IT HAS CHILDREN
                if (item.children) {
                    // IF IT HAS CHILDREN, SEARCH FOR THE ID IN THE CHILDREN
                    const found = find(item.children, id);
                    if (found) {
                        path.push(item.id);
                        return path;
                    }
                }
            }
        }
    }

    // CALL THE RECURSIVE FUNCTION
    find(items, id);

    // RETURN THE PATH
    return path;
};

///////////////////////////////////////////////////////////////
/// CONCA ORGANIZATION / PROJECT MEGA USER AND PROJECT USER ///
///////////////////////////////////////////////////////////////

//V1
export const TEMPLATE_GET_PROJECT_USERS = (
    orgaMegaUsersDetails,
    projectMegaUsersDetails,
    projectUsersDetails
) => {
    //CONCA AND DUPPLICATE ELIMINATION
    let concaUsersDetails = [];

    //ORGA MEGA
    if (orgaMegaUsersDetails) {
        concaUsersDetails = [...orgaMegaUsersDetails];
    }

    //PROJECT MEGA
    if (projectMegaUsersDetails) {
        concaUsersDetails = [...concaUsersDetails, ...projectMegaUsersDetails];
    }

    //USERS
    if (projectUsersDetails) {
        concaUsersDetails = [...concaUsersDetails, ...projectUsersDetails];
    }

    //FINAL
    const finalUsersList = [
        ...new Map(concaUsersDetails.map((item) => [item.value, item])).values(),
    ];

    //GET UNIQUE
    return finalUsersList;
};

//NEW VERSION V2
export const TEMPLATE_GET_PROJECT_USERS_V2 = (
    orgaMegaUsersId,
    projectMegaUsersId,
    projectUsersId,
    users,
    megaUsersId,
    leaderList
) => {
    ///////////////////////////////////
    /// ID USERS LIST CONCATENATION ///
    ///////////////////////////////////

    //CONCA AND DUPPLICATE ELIMINATION
    let concaUsersDetails = [];

    //ORGA MEGA
    if (orgaMegaUsersId) {
        concaUsersDetails = [...orgaMegaUsersId];
    }

    //PROJECT MEGA
    if (projectMegaUsersId) {
        concaUsersDetails = [...concaUsersDetails, ...projectMegaUsersId];
    }

    //USERS
    if (projectUsersId) {
        concaUsersDetails = [...concaUsersDetails, ...projectUsersId];
    }

    //MEGAUSERS
    if (megaUsersId) {
        concaUsersDetails = [...concaUsersDetails, ...megaUsersId];
    }

    //UNIQUE LIST IN CASE OF ...
    concaUsersDetails = [...new Set(concaUsersDetails)];

    ///////////////////////////
    /// DETAILS INTEGRATION ///
    ///////////////////////////

    //INIT
    let finalUsersList = [];
    let findIndex;

    //LOOP IDS
    for (let i = 0; i < concaUsersDetails.length; i++) {
        //FIND INDEX
        findIndex = FUNCT_FIND_INDEX(users, "id", concaUsersDetails[i]);
        if (findIndex > -1) {
            //LEADER MANAGEMENT
            let leader = false;
            if (leaderList && leaderList.length > 1) {
                leader = leaderList?.includes(concaUsersDetails[i]);
            }

            //INTEGRATION
            finalUsersList.push({
                value: concaUsersDetails[i],
                label: users[findIndex].name + " (" + users[findIndex].email + ")",
                backgroundColor: users[findIndex].backgroundColor,
                projectLeader: leader,
            });
        }
    }

    //GET UNIQUE
    return finalUsersList;
};

// RETURN ONLY THE DATA FOR THE GIVEN PROJECT ID
export function WBS_FILTER_PROJECT_DATA_BY_PROJECT(projectData, projectId) {
    let projectDataCopy = _.cloneDeep(projectData);

    // FOR EACH DATA TYPE
    Object.keys(projectDataCopy).forEach((projectDataType) => {
        projectDataCopy[projectDataType] = projectDataCopy[projectDataType].filter(
            (item) => item.projectId === projectId
        );
    });

    return projectDataCopy;
}

// OPEN AN ITEM CARD DIRECTLY
export function WBS_OPEN_ITEM(
    projectData,
    type,
    itemId,
    wbsFilters,
    wbsNavigationOptions,
    wbsDispatcher,
    appDispatcher,
    securityGroup
) {
    // INIT
    let dispatcherObject = {
        type: "SET_BIG_COMPONENT_DISPLAYER",
    };

    // FIND ITEM IN PROJECT DATA
    let foundItem = FIND_OBJECT_ARRAY_ITEM(projectData[type], "id", itemId);

    // TEST IF PRESENT
    if (foundItem) {
        // INIT
        let domain = type;

        // CASE FOR GOVERNANCE
        if (
            type === AGENDA_ITEM_TYPES_KEYS.scopeChange ||
            type === AGENDA_ITEM_TYPES_KEYS.review
        ) {
            domain = "governance";

            // CASE FOR RISK OPP
        } else if (type === AGENDA_ITEM_TYPES_KEYS.risk) {
            if (foundItem.isRisk === true) {
                domain = AGENDA_ITEM_TYPES_KEYS.risk;
            } else {
                domain = AGENDA_ITEM_TYPES_KEYS.opportunity;
            }
        }

        // IF WE OPEN A REVIEW, OPEN REVIEW MODE IN WBS RIGHT COMPONENT
        if (type === AGENDA_ITEM_TYPES_KEYS.review && !wbsNavigationOptions.isOpenSideBar) {
            // CREATE RIGHT COMPONENT OBJECT WITH DATA
            let rightComponent = {
                name: "govReview",
                data: {
                    govItem: foundItem,
                    editMode: true,
                    securityGroup: securityGroup,
                },
            };

            dispatcherObject.rightComponent = rightComponent;

            // UPDATE WBS DISPATCHER
            wbsDispatcher(dispatcherObject);

            // UPDATE APP STATE SO IF WE GO TO BARD AND WE COME BACK IT STAYS OPEN
            appDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                    source: "portfolioDisplay",
                    object: {
                        // DIRECT ACCESS TO ITEM
                        wbsNavigationOptions: {
                            goToItemId: itemId,
                            goToDomain: "govReview",
                        },
                    },
                },
            });

            return;
        }

        // SET DISPATCHER OPTIONS
        dispatcherObject = {
            ...dispatcherObject,
            selectedWbsId: foundItem.wbsId,
            selectedField: domain,
            selectedProjectId: foundItem.projectId,
            selectedItem: itemId,
        };

        // IF SOME WBS NAVIGATION OPTIONS, ADD IT TO WBS STATE
        if (wbsNavigationOptions) {
            dispatcherObject.wbsNavigationOptions = wbsNavigationOptions;
        }

        // IF WE WANT TO OVERRIDE WBS ID, FOR CASE WE WANT TO OPEN AN ITEM ON THE SAME WBS INSTEAD OF NESTED
        // if (wbsNavigationOptions?.overrideWbsId) {
        //     dispatcherObject.selectedWbsId = wbsNavigationOptions.overrideWbsId;
        //     dispatcherObject.selectedProjectId = wbsNavigationOptions.overrideWbsId;
        // }

        // UPDATE WBS TO DISPLAY ITEM
        wbsDispatcher(dispatcherObject);
    }
    // NOT FOUND
    else {
        // ITEM NOT FOUND -> TOAST
        appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
                source: "appToast",
                object: {
                    display: true,
                    color: "danger",
                    message:
                        "Item not found in the WBS. " +
                        (wbsFilters && wbsFilters.length > 0
                            ? "Filters enabled, maybe this item is currently hidden"
                            : ""),
                },
            },
        });
    }
}

// OPEN SPECIFIC DOMAIN
export function WBS_OPEN_DOMAIN(type, projectId, wbsNavigationOptions, wbsDispatcher) {
    // INIT
    let domain = type;
    let dispatcherObject = null;

    // CASE FOR GOVERNANCE
    if (
        type === AGENDA_ITEM_TYPES_KEYS.scopeChange ||
        type === AGENDA_ITEM_TYPES_KEYS.review
    ) {
        domain = "governance";
    }

    // SET DISPATCHER OPTIONS
    dispatcherObject = {
        type: "SET_BIG_COMPONENT_DISPLAYER",
        selectedWbsId: projectId,
        selectedField: domain,
        selectedProjectId: projectId,
        selectedItem: null,
    };

    // IF SOME WBS NAVIGATION OPTIONS, ADD IT TO WBS STATE
    if (wbsNavigationOptions) {
        dispatcherObject.wbsNavigationOptions = wbsNavigationOptions;
    }

    // UPDATE WBS TO DISPLAY ITEM
    wbsDispatcher(dispatcherObject);
}

// FUNCTION CALLED WHEN CLICKING ON BIG COMPONENTS SIDE BAR BACK BUTTON
export function WBS_ON_BACK_SIDEBAR(
    wbsNavigationOptions,
    projectData,
    projectId,
    wbsDispatcher,
    appDispatcher
) {
    // IF NAVIGATION OPTION SET
    if (wbsNavigationOptions?.originItemId) {
        // SET DESTINATION ITEM
        wbsNavigationOptions.goToItemId = wbsNavigationOptions.originItemId;
        wbsNavigationOptions.goToDomain = wbsNavigationOptions.originDomain;

        // SET OPTION TO OPEN CONNECTION TABS
        wbsNavigationOptions.options = {
            openLinksTab: true,
        };

        // IF ITEM SET, OPEN ITEM
        WBS_OPEN_ITEM(
            projectData,
            wbsNavigationOptions.originDomain,
            wbsNavigationOptions.originItemId,
            null,
            wbsNavigationOptions,
            wbsDispatcher,
            appDispatcher
        );
        return;
    }
    // ELSE IF AT LEAST DOMAIN SET, OPOEN DOMAIN
    else if (wbsNavigationOptions?.originDomain) {
        WBS_OPEN_DOMAIN(
            wbsNavigationOptions.originDomain,
            projectId,
            wbsNavigationOptions,
            wbsDispatcher
        );
        return;
    }
}
