import React, { useCallback, useMemo } from 'react'
import { CvtCheckbox } from '@mi-gso/cvt'
import { WBS_DOMAIN_DETAILS } from '../00-Wbs/00-Helpers/WbsConstants'
import { FilterGroup } from './01-Components/FiltersGroup'
import FiltersHeader from './01-Components/FiltersHeader'
import { FUNC_IS_DATE1_AFTER_OR_EQUAL_DATE2_NO_HOURS } from '../../../00-Core/Standards'
import _ from 'lodash'

/////////////////////////
/// FILTERS COMPONENT ///
/////////////////////////

export default function Filters({
    wbsFilters,
    usersList,
    isPortfolio,
    handleOpenWbsFilter,
    dataConsolidation,
    appDispatcher,
    projectData,
}) {
 
    //////////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////

    const handleDataConsolidation = useCallback(() => {
        appDispatcher({
            type: 'SET_GLOBAL_STATE',
            options: {
            source: 'appSettings',
            object: {
                dataConsolidation: !dataConsolidation
            }
            }
        })
    },[appDispatcher, dataConsolidation])
    

    //////////////////////////////////////////////////////////////////////
    /// DATA /////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////

    const legend = useMemo(()=>[
        <div 
        key="date-filter"
        className="flexStartCenter"
        >
        <div 
            className='filterCheckBoxTxtAvatar'
            style={{
            fontWeight:500,
            marginLeft:"0px"        
            }}
        >
            Data Consolidation
        </div>
        </div>
    ],[])

    // FILTERED PROJECT DATA
    const filteredProjectData = useMemo(() => {

        const dateFilter = wbsFilters.find((filter) => filter.domain === "deadline");
        if(dateFilter) {

            let newReviews = projectData.govReview.filter((review) => {
                const clonedReview = _.cloneDeep(review);
                // IF MORE THAN 1 DATE, THEN GET THE CORRECT ORDER
                if(dateFilter.value.length > 1) {
                    
                    // SORT
                    const sortedDates = _.cloneDeep(dateFilter).value.sort((a, b) => {
                        if(a < b) {
                            return -1;
                        }
                        if(a > b) {
                            return 1;
                        }
                        return 0;
                    });

                    return (
                        FUNC_IS_DATE1_AFTER_OR_EQUAL_DATE2_NO_HOURS(
                            clonedReview.startDate, 
                            new Date(sortedDates[0])
                        )  
                        &&
                        FUNC_IS_DATE1_AFTER_OR_EQUAL_DATE2_NO_HOURS(
                            new Date(sortedDates[1]), 
                            clonedReview.endDate
                        )
                    );
                }
                else {
                    return FUNC_IS_DATE1_AFTER_OR_EQUAL_DATE2_NO_HOURS(
                        new Date(dateFilter.value[0]), 
                        clonedReview.endDate
                    );
                }
            });
            
            return {
                ...projectData,
                govReview: newReviews,
            };
        }
        return projectData;
    }, [projectData, wbsFilters]);

    //////////////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>

            {/* TITLE AND CLOSE BUTTON */}
            <FiltersHeader
                handleOpenWbsFilter={handleOpenWbsFilter}
            />

            {/* GROUP LIST */}
            <div 
                className="accordion accordion-flush filterContainer" 
                id="accordionEditSideBar"
            >

                {/* DATA CONSOLIDATION */}
                <CvtCheckbox 
                    value={dataConsolidation}
                    name="deadlineDateRange"
                    titleSize="12px"
                    padding="0px 0px 18px 2px"
                    width="100%"
                    marginTop="0px"
                    jsx={legend}
                    noFocusBoxShadow
                    checkboxContainerWidth="100%"
                    handleFunction={handleDataConsolidation}
                />

                {/* LOOP ON DOMAIN CONSTANTES */}
                {WBS_DOMAIN_DETAILS.map((wbsDomain) => {

                    //CHARTER ONLY ON PORTFOLIO LEVEL
                    if(!isPortfolio && wbsDomain.id === "charter"){
                        return []
                    //NORMAL
                    } else {

                        //GET ONLY WHAT WE NEED INSIDE EACH GROUP
                        let wbsDomainFilters = wbsFilters.filter(item => item.domain === wbsDomain.id)
                        
                        //ONLY IF FILTER ATTRIBUTE TRUE
                        if(wbsDomain.filter){
                            return(
                                <FilterGroup 
                                    key={"filter_side_bar_" + wbsDomain.id}
                                    wbsDomain={wbsDomain}
                                    wbsDomainFilters={wbsDomainFilters}
                                    usersList={usersList}
                                    appDispatcher={appDispatcher} 
                                    filteredProjectData={filteredProjectData}
                                />
                            )
                        }
                    }

                    //IF NOT RETURN NULL
                    return []
                })}
            </div>
        </React.Fragment>
    )
}