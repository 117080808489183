////////////////////////////////////////////////////////////////////////
// APPLICATION SET /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//MEGA USERS GROUP NAME
export const APP_TXT_MEGA_USERS = "MegaUsers";


//TRANSLATE COLOR TO INFO FOR LIB TOAST COMPONENT
export const APP_TOAST_OPTIONS = {
    fetch: {
      type: "fecth",
      title: "Fecthing data!",
    },
    danger: {
      type: "error",
      title: "Something went wrong!",
    },
    good: {
      type: "success",
      title: "Done!",
    },
  };

//ALL THE TOAST TEST STORE HERE
export const APP_TOAST_TXT={
    issueOpenProject: "I have detected an issue trying getting the project data... If the issue persist, please contact Clayverest Administrator.",
    mutationDeleteOk: "Item Deleted!",
    mutationCreateOk: "Item Created!",
    mutationUpdateOk: "Item Updated!",
    mutationSuccessfully: "Great News, my database are up to date !",
    endDateSmallerThanStartDate: "The end date can't be smaller than the start date!",
    issueCreateSuccess: "Your issue has been created",
    issueCreateError: "An error occured",
    noProjectInportfolio: "I can't open this Portfolio because no project is linked to it...",
    missingProjectsInPortfolio: "I can't open this Portfolio because some project(s) is missing..",
    stateReferenceOk: 'Reference added successfully!',
    mutationReferenceOk: 'Reference saved successfully!',
    referenceNoDataChanges: 'There were no changes in the reference data!',
    errorDeletingReferenceVersion: 'Error deleting reference version!',
    successfullyDeletedReferenceVersion: "Reference version deleted!",
    fetchReferenceEmpty: "Didn't find any reference data!",
    errorDownloadingActionData: 'Error while downloading action data',
    downloadingActionData: "Downloading actions data...",
    successfullyDownloadedActionData: "Actions data downloaded successfully!",
}

//APP FORM STYLE
export const APP_FORM_STYLE={
    legendColor: "rgba(3, 169, 219, 0.6)",
    legendFontSize: "12px",
    legendMargin:"0px 0px 5px 0px",
    selectPadding: "4px 30px 4px 8px",
    inputPadding: "4px 12px",
    fontSize: "14px",
    blockMargin:"15px 0px 0px 0px",
    lastItemBlockMargin: "15px 0px 15px 0px",
}

//LANGUAGE
export const APP_LANGUAGE = [
    { id: "EN", EN: "English", FR: "Anglais", ES: "Inglés", DE: "Englisch" },
    { id: "FR", EN: "French", FR: "Français", ES: "Francés", DE: "Französisch" },
    { id: "ES", EN: "Spanish", FR: "Espagnol", ES: "Español", DE: "Spanisch" },
    { id: "DE", EN: "German", FR: "Allemand", ES: "Alemán", DE: "Deutsch" },
];

//STRUCTURE
export const APP_STRUCTURE = {
    sideBarWidth: 64,
    sideBarWidthBig: 200,
    sibeBarWidthFilter: 300
};

//WBS STRUCTURE
export const APP_WBS_STRUCTURE ={
    firstColumnWidth: 350,
    headerHeight: 60,
    rowHeight: 50,
    minCellWidth: 120,
    bigComponentHeight: 367,
    bigComponentHeaderHeight: 40,
    sideBarWidthBig: 150,
    sideBarWidth: 42,
    shortcutWidth: 200,
}

//LOGO
export const APP_LOGO = {
    icon: "flag",
    iconAbr: "TEL",
    iconColor: "#FFFFFF",
    iconBgColor: "#007BFF",
    logoTxt: "Telescope",
};

//CORE SANDBOX
export const APP_CORE_SANDBOX = {
    id: "sandbox",
    name: "Sandbox",
    organizationMegaUsersGroup:"sandbox",
    logoLitle:"https://ps.w.org/simple-draft-list/assets/icon-256x256.png"
};

//NAVIGATION
export const APP_NAV = [
    {
        id: "board",
        name: "Board",
        icon: "dynamic_feed",
        url: "/Board",
    },
    {
        id: "portfolio",
        name: "Controls",
        icon: "control_camera",
        url: "/controls",
    },
    {
        id: "docs",
        name: "Docs",
        icon: "help",
        url: "/docs",
    },
];

////////////////////
///DATE CONSTANT ///
////////////////////

export const TODAY = new Date();
export const TODAY_MINUS_1M = new Date(TODAY.setMonth(TODAY.getMonth() - 1));
export const TODAY_PLUS_1M = new Date(TODAY.setMonth(TODAY.getMonth() + 1));
export const TODAY_MINUS_1 = new Date(new Date().setDate(new Date().getDate() - 1));
export const TODAY_PLUS_30D = new Date(new Date().setDate(new Date().getDate() + 30));



//!!!! TO BE MOVED
// UNDO AND REDO CONSTANTS
export const USER_OPERATIONS_TYPES = {
    create: "createSingle",
    createMultiple: "createMultiple",
    delete: "deleteSingle",
    deleteMultiple: "deleteMultiple",
    update: "updateSingle",
    // UPDATE MULTIPLE
    updateMultiple:"updateMultiple",
}


// DEFAULT INIT STATE FOR MOVE MODAL OPTIONS
export const DEFAULT_STATE_MOVE_MODAL_OPTIONS = {
    itemsArray: null,
    tableName: null,
    query: null,
    propertiesToDelete: null,
};

// AGENDA CUSTOM COMPONENT ITEM TYPES KEYS
export const AGENDA_ITEM_TYPES_KEYS = {
    risk: "riskOpp",
    action: "action",
    opportunity: "opportunity",
    scopeChange: "govScopeChange",
    review: "govReview",
    schedule: "schedule"
};

// AGENDA CUSTOM COMPONENT ITEM TYPES
export const AGENDA_ITEM_TYPES = [
    {
        id: "riskOpp",
        label: "Risk / Opportunity",
        icon: "fact_check",
    },
    {
        id: "action",
        label: "Action",
        icon: "alarm",
    },
    {
        id: "govScopeChange",
        label: "Scope Change",
        icon: "event",
    },
];
