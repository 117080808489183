//PACKAGES
import { CvtPillKPI, CvtTooltip } from "@mi-gso/cvt";
import React, { useMemo, memo } from "react";

// CONSTANTS
import { GOV_SCOPE_STATUS_KEY } from "./00-Helpers/GovScopeConstants";

/////////////////////////////
/// ACTION MINI COMPONENT ///
/////////////////////////////

export default memo(function GovMini({ data, bigComponentDisplayed, propKey }) {
  /////////////
  /// COUNT ///
  /////////////

  const govScopesDisplayCount = useMemo(() => {
    if (propKey) {
      // INIT
      let returnGovScopesCount = {
        total: data.length,
        onHold: 0,
        pending: 0,
        closed: 0,
        notClosed: 0,
      };

      // Here calculate a new object to return containing all actions display counts
      if (data != null && data.length > 0) {
        // Use the "status" property of the actions to show the overall status of the actions in the tooltip
        data.forEach((scope) => {
          // ON HOLD
          if (scope.status === GOV_SCOPE_STATUS_KEY.onHold) {
            returnGovScopesCount.onHold++;
          } else if (scope.status === GOV_SCOPE_STATUS_KEY.pending) {
            returnGovScopesCount.pending++;
          } else if (scope.status === GOV_SCOPE_STATUS_KEY.closed) {
            returnGovScopesCount.closed++;
          }
        });
      }

      returnGovScopesCount.notClosed =
        returnGovScopesCount.total - returnGovScopesCount.closed;

      //RETURN OBJECT
      return returnGovScopesCount;
    }
    return null;
  }, [data, propKey]);

  ///////////////////////
  /// TOOLTIP CONTENT ///
  ///////////////////////

  const tooltipContent = useMemo(() => {
    return (
      <div className="flexColStartStart">
        {/* OPEN */}
        <span className="actionMiniTooltipTitle">
          Not Closed: <b>{govScopesDisplayCount.notClosed}</b>
        </span>

        {/* ON HOLD */}
        <span className="actionMiniTooltipSubTitle">
          On Hold: <b>{govScopesDisplayCount.onHold}</b>
        </span>

        {/* PENDING */}
        <span className="actionMiniTooltipSubTitle">
          Pending: <b>{govScopesDisplayCount.pending}</b>
        </span>

        {/* CLOSED */}
        <span className="actionMiniTooltipTitle">
          Closed: <b>{govScopesDisplayCount.closed}</b>
        </span>
      </div>
    );
  }, [
    govScopesDisplayCount.closed,
    govScopesDisplayCount.notClosed,
    govScopesDisplayCount.onHold,
    govScopesDisplayCount.pending,
  ]);

  // WHETHER TO DISPLAY SOMETHING OR NOT
  const isTooltip = useMemo(() => {
    return govScopesDisplayCount.notClosed > 0;
  }, [govScopesDisplayCount.notClosed]);

  //////////////
  /// RENDER ///
  //////////////

  return (
    <>
      <CvtPillKPI
        values={[
          {
            value:
              govScopesDisplayCount.notClosed > 0
                ? govScopesDisplayCount.notClosed
                : "",
          },
          {
            value:
              govScopesDisplayCount.onHold > 0
                ? govScopesDisplayCount.onHold
                : govScopesDisplayCount.pending > 0
                ? 0
                : null,
            color: "var(--color-migso-yeallow)",
          },
          {
            value:
              govScopesDisplayCount.pending > 0
                ? govScopesDisplayCount.pending
                : 0,
            color: "var(--color-bad)",
          },
        ]}
        tooltipId={propKey}
      />

      {/* TOOLTIP */}
      {isTooltip ?
        <CvtTooltip
          anchorId={propKey}
          place="right"
        >
          {tooltipContent}
        </CvtTooltip>
      :null }
    </>
  );
});
