import { CvtButton, CvtInput } from '@mi-gso/cvt'
import React from 'react'
import { APP_FORM_STYLE } from '../../../../00-Core/Constants'

//FILTER SEARCH BAR FOR GROUP COMPONENT
export default function FiltersGroupSearchBar({
    setSearch,
    value
}) {
    return (
        <div
            className='flexStartCenter'
            style={{
                width:"100%",
                margin:"5px 0px 10px 0px"
            }}
        >

            {/* SEARCH */}
            <CvtInput
                type="text"
                placeholder="Search"
                inputBlockCss="flexColStartStart modalOptionSubBlock modalOptionsSubBlockNoMargin"
                inputPadding="6px 12px 6px 36px"
                inputCss="form-control modalInputLight2 filterSearch "
                inputFontSize={APP_FORM_STYLE.fontSize}
                inputBlockPadding="0px"
                inputBlockMargin="0px"
                inputWidthFull={true }
                name="ownerSearch"
                value={value}
                onChange={(e)=> setSearch(e.target.value)}
            />

            {/* CLOSE BUTTON */}
            {value !== "" ?
                <CvtButton
                    color="white"
                    shape="noShape"
                    size="small"
                    icon="close"
                    margin="0px -8px 0px 0px"
                    handleSubmit={()=> setSearch("")}
                    fontWeight={500}
                />
            :null}
        </div>
    )
}
