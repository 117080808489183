import { FUNCT_FIND_INDEX } from '../../../../00-Core/Standards';
import {
    CHARTER_CATEGORIZATION_TYPE,
    CHARTER_CATEGORIZATION_DETAIL
} from './CharterConstants'

//MATCHING CONDITION
function GET_VALUE_MATCHING_CONDITION(
    dataArray, 
    value
) {

    for(let dataValue of dataArray) {
        let valueMatchCondition = false;

        switch(dataValue.operator) {
            case "inferior":
                valueMatchCondition = value <= dataValue.valueArray[0];
                break;
            case "between":
                valueMatchCondition = value > dataValue.valueArray[0] && value < dataValue.valueArray[1];
                break;
            case "superior":
                valueMatchCondition = value >= dataValue.valueArray[0];
                break;
            case "equal":
                valueMatchCondition = value === dataValue.valueArray[0];
                break;
            default:
                return null;
        }

        // If there's a match with the currently tested condition, return the weight
        if (valueMatchCondition) {
            return dataValue;
        }
    }

    return null;
}

//GET OBJECT OF INPUT
function GET_CATEGORIZATION_DETAIL_FROM_VALUE(typeId, value) {

    // Find Categorization details data corresponding to type
    let categorizationDataIndex = FUNCT_FIND_INDEX(CHARTER_CATEGORIZATION_DETAIL, "id", typeId);
    let categorizationData = CHARTER_CATEGORIZATION_DETAIL[categorizationDataIndex];

    // Returns the value object that match the value
    return GET_VALUE_MATCHING_CONDITION(categorizationData.values, value);
}

//RETURN THE CATEGORIZATION SETTING OF A PROJECT
export function CHARTER_FUNC_CATEGORIZATION(
    props
){

    //////////////////
    /// MNG INPUTS ///
    //////////////////

    //CALCULATE DURATION
    let durationYear = ((props.dates.endDate.getTime() - props.dates.startDate.getTime()) / (1000 * 3600 * 24)) / 365

    //CALCULATE PERCENTAGE
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let percent = ((today.getTime() - props.dates.startDate.getTime()) / (props.dates.endDate.getTime() - props.dates.startDate.getTime()))*100

    //NEW INPUTS
    let newInputs = {
        ...props.inputs,
        duration: durationYear
    }

    //INIT CUMU
    let totalWeight = 0;
    let detailsInfo = [];

    // Itterate over each categorization props to add each weight
    for (const [key, value] of Object.entries(newInputs)) {
        let categorizationDetails = GET_CATEGORIZATION_DETAIL_FROM_VALUE(
            key, 
            value
        );
        if(categorizationDetails){
            totalWeight += categorizationDetails.weight
            detailsInfo.push({
                ...categorizationDetails,
                key: key,
                userValue: value
            })
        }
    }

    // return id ====> CHARTER_CATEGORIZATION_TYPE
    let categorizationTypeValue = GET_VALUE_MATCHING_CONDITION(
        CHARTER_CATEGORIZATION_TYPE, 
        totalWeight
    );

    //RETUNR
    return {
        ...categorizationTypeValue,
        percent: percent,
        detailsInfo: detailsInfo
    }
}