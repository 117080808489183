// REACT
import { CvtInputTextArea } from "@mi-gso/cvt";
import { memo, useMemo } from "react";

// CORE CONSTANTS
import { APP_FORM_STYLE } from "../../../../../../../00-Core/Constants";

////////////////////////////////////////
/// RISK CARD INFOS DESCRIPTION ////////
////////////////////////////////////////

export default memo(function RiskCardInfosDescription({
    // DATA
    descriptionValue,
    editingValue,
    megaUsersIds,
    currentUser,
    responsible,
    canModifyRo,

    // FUNCTIONS
    handleInputChange,
    handleEditRoItem,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // DESCRIPTION EDITOR PLACEHOLDER
    const editorPlaceholder = useMemo(() => {
        // CASE USER IS MEGAUSER OR RESPONSIBLE
        if (megaUsersIds?.includes(currentUser) || currentUser === responsible) {
            return "Click here to add a description";
        }

        // OTHER USERS
        return "No description";
    }, [currentUser, megaUsersIds, responsible]);

    /////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ///////////////////////////////////////
    ////////////////////////////////////////////////////////////
    return (
        <CvtInputTextArea
            type="richTextArea"
            placeholder={editorPlaceholder}
            inputBlockCss="flexColStartStart"
            inputPadding="0px"
            inputCss="form-control modalInputLight"
            inputFontSize={APP_FORM_STYLE.fontSize}
            inputBlockPadding="0px"
            inputBlockMargin="0px"
            inputWidthFull={true}
            name="input"
            editingValue={editingValue}
            value={descriptionValue ? descriptionValue : ""}
            onChange={handleInputChange}
            onBlur={
                editingValue !== "" && descriptionValue !== editingValue
                    ? (e) => handleEditRoItem(e, "description", editingValue)
                    : null
            }
            enrichTitleCss="enrichTextTilteSideBar"
            enrichEditorCss="modalInputEnrichTxt"
            isOnViewMode={!canModifyRo}
            headingStylesSelectDirection="down"
        />
    );
});
