import React, {useState, useCallback, useMemo} from 'react'
import {updateUserTermsAttributes} from '../00-App/01-Auth/00-Functions/AuthAttributes'
import {
    CvtModal,
    CvtModalBody,
    CvtModalFooter,
} from '@mi-gso/cvt'
import TERMS_FILES from './00-ContentFiles/TermsFilesExport'
import {APP_LANGUAGE} from '../../00-Core/Constants'
import {FUNCT_FIND_INDEX} from '../../00-Core/Standards'
import { envVarManagement } from '../../envVarManagement'

////////////////////////////////
/// ADD ITEM MODAL COMPONENT ///
////////////////////////////////

export default function TermsModalValidation ({
    appDispatcher
}) {

    //ENV VAR
    const envTermsVersion = useMemo(()=> envVarManagement('envTermsVersion'),[])

    //TYPE AND SPECIFIC STATE ----------------------------------------------------------------
    const [validate, setValidate] = useState({
        loader: false,
        err: null
    });

    //ON CHANGE
    const onChange = useCallback((e)=>{
        e.preventDefault();
        setLanguage(e.target.value)
    },[])

    //STATE TO MANAGE LANGUAGE
    const [language, setLanguage] = useState("EN")

    //HANDLE SUBMIT
    const handleSubmit = useCallback((e) => {
        e.preventDefault();

        //LAUNCH LOADER
        setValidate(prevValidate => {
            return({
                ...prevValidate,
                loader: true
            })
        })

        //FIND INDEX
        let indexFile = FUNCT_FIND_INDEX(APP_LANGUAGE, "id",language)
        let languageTxt = language + "-" + APP_LANGUAGE[indexFile][language]

        //LAUNCH UPDATE ATTRIBUTE
        updateUserTermsAttributes(
            appDispatcher,
            setValidate,
            envTermsVersion,
            languageTxt
        )
    },[appDispatcher, envTermsVersion, language])

    /////////////////////////////////
    /// MODAL COMPONENT FUNCTIONS ///
    /////////////////////////////////

    //DATA TEMPLATE MANAGEMENT
    const functionResetModal = useCallback(() => {
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                  displayTermsValidation: false,
                }
            }
          })
    },[appDispatcher])

    //GET SELECTED FILE
    const fileData = useMemo(()=> {

        //FIND INDEX
        let indexFile = FUNCT_FIND_INDEX(TERMS_FILES, "id",language)

        //RETURN GOOD ONE
        return TERMS_FILES[indexFile]
    },[language])

    //GET SELECTED FILE
    const FileJsx = useMemo(()=>
        fileData.file
    ,[fileData.file])

    //////////////////////////////
    /// MODAL COMPONENT RENDER ///
    //////////////////////////////

    return (
        <CvtModal
            icon={fileData.icon}
            title={fileData.title}
            resetFunction={functionResetModal}
            disabledBackgroundReset
            submitFunction={handleSubmit}
            widthPercent="80%"
        >
            {/* BODY */}
            <CvtModalBody>

                {/* TITLE + SELECT LANGUAGE  */}
                <div className='flexBetweenCenter' style={{width:"100%"}}>

                    {/* INFO */}
                    <div
                        style={{
                            padding:"20px",
                            fontWeight:500
                        }}
                    >
                        Before access to Clayverest Platform, you need to accept the terms bellow
                    </div>

                    <div className='flexStartCenter'>

                            <span
                                className="material-icons"
                                style={{color:"var(--color-text-4)"}}
                            >
                                language
                            </span>

                            <select
                                className="form-select"
                                style={{
                                    fontSize: "14px",
                                    padding: "5px 35px 5px 12px",
                                    margin: "0px 20px 0px 8px"
                                }}
                                name="language"
                                value={language}
                                onChange={onChange}
                            >

                                {/* OPTIONS MAP */}
                                {APP_LANGUAGE.map((item, index) =>(
                                        <option
                                            key={"language-" + item.id}
                                            value={item.id}
                                        >
                                            {item[language]}
                                        </option>
                                    )
                                )}
                            </select>

                        </div>


                </div>

                <div
                    className='blockMarkDown'
                    style={{
                        padding:"0px 20px 20px 20px",
                        border: "1px solid var(--border-color-gray-0)",
                        margin:"0px 20px 20px 20px",
                        borderRadius:"8px",

                    }}
                >
                    {FileJsx}
                </div>
            </CvtModalBody>

            {/* BUTTON */}
            <CvtModalFooter
                submitTxt="Accept"
                submitColor="blue"
                resetFunction={functionResetModal}
                resetFunctionTxt="Refuse"
                submitLoader={validate.loader}
            />
        </CvtModal>
    );
};