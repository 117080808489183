// REACT
import _ from 'lodash';
import {memo, useCallback, useMemo} from 'react';

// MIGSO
import { CvtButton } from '@mi-gso/cvt';

/////////////////////////////////////
/// RO TABLE LEGEND COMPONENT ///
/////////////////////////////////////

export default memo(function RiskTableLegend({
    // LEGEND AND COUNTER ARRAY
    roLegendAndCounter,
    // STATUS SEARCH FILTER ARRAY
    roStatusSearchFilter,
    // RO DISPATCHER
    riskOppDispatcher,
}) {

    //////////////////////////////////////////////////////////////
    /// MEMO /////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    const openRoExists = useMemo(() => {
        return roLegendAndCounter.findIndex((item) => item.id === "opened") > -1;
    }, [roLegendAndCounter]);

    //////////////////////////////////////////////////////////////
    /// FUNCTIONS ////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    // ON TAG CLICK
    const handleStatusTagClick = useCallback((tagValue) => {

        // INIT
        let newStatusSearchFilter = _.cloneDeep(roStatusSearchFilter);

        // TEST IF SELECTED
        let isSelected = roStatusSearchFilter?.includes(tagValue);

        // IF ALREADY SELECTED, REMOVE IT
        if(isSelected) {
            newStatusSearchFilter = newStatusSearchFilter.filter((status) => status!==tagValue);
        }
        // ADD IT
        else {

            if(
                (roLegendAndCounter.length - 1) === (roStatusSearchFilter.length + 2) && false
                // &&
                // FUNCT_FIND_INDEX_ARRAY(roStatusSearchFilter, "closed") === -1
            ) {
                newStatusSearchFilter = [];
            }
            else {
                newStatusSearchFilter.push(tagValue);
            }
        }

        riskOppDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                'roStatusSearchFilter': newStatusSearchFilter,
                selected: [],
            }
        });

    }, [riskOppDispatcher, roLegendAndCounter.length, roStatusSearchFilter]);

    // RESET ALL FILTERS
    const onStatusReset = useCallback(() => {
        riskOppDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                'roStatusSearchFilter': [],
                selected: [],
            }
        });
    }, [riskOppDispatcher]);

    //////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////


    return (
        <div
            className="flexStartCenter"
            style={{marginLeft: "5px"}}
        >
            {/* MAP STATUS */}
            {
                roLegendAndCounter.map((item) => {
                    // IF SELECTED
                    let isSelected = roStatusSearchFilter.length > 0 && roStatusSearchFilter?.includes(item.id);

                    // IF IS NOT SELECTED AND,
                    // THE STATUS FILTER IS NOT EMPTY OR THIS FILTER IS "closed" AND THERE ARE SOME OPEN RO ITEMS.
                    let isOpacityLow = !isSelected && (roStatusSearchFilter.length > 0 || (openRoExists && item.id === "closed"));

                    // RENDER FOR NOT TOTAL
                    if(item.id!=="total") {
                        return (
                            <div
                                key={`risk_table_legend_${item.id}`}
                                className={`
                                    actionsTableLegendTag flexStartCenter
                                    ${isSelected ? "actionsTableLegendTagSelected" : ""}
                                `}
                                onClick={() => handleStatusTagClick(item.id)}
                                style={{
                                    background: item.backgroundColor,
                                    opacity: isOpacityLow ? "0.5" : null,
                                }}
                            >
                                <span style={{color: item.color}}>
                                    {item.name}
                                </span>
                            </div>
                        )
                    }

                    // RETURN NULL IF TOTAL
                    return null;
                })
            }

            {/* RESET */}
            {
                roStatusSearchFilter.length > 0 ?
                <CvtButton
                    color="gray"
                    customHover="red"
                    shape="noShape"
                    icon="filter_alt_off"
                    handleSubmit={onStatusReset}
                />
            :null}
        </div>
    );
});
