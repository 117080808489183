import React, { memo, useCallback, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";
import ShortcutListItem from "./ShortcutListItem";

export default memo(function ShortcutListTable({
  isOnlyShortcutOpen,
  shortcutData,
  viewMode,
  onAddEditClick,
  onDelete,
  domain,
  index,
  // DND
  provided,
  snapshot,
  
}) {
  // ///////////////////////////////////////////////////////////////////////////
  /// STATE ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const [state, setState] = useState({
    canScrollUp: false,
    canScrollDown: false,
  });

  // /////  //////////////////////////////////////////////////////////////////////
  /// FUNCTIONS ////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // HANDLE SCROLL OF LIST OF SHORCUTS
  const handleListScroll = useCallback(
    (e) => {
      // GET ATTRIBUTES TO CALCULATE IF CAN OR NOT SCROLL DOWN OR UP
      let { scrollTop, scrollHeight, clientHeight } = e.target;

      // INIT CAN SCROLL TOP
      let canScrollTop = state.canScrollUp;

      // INIT CNA SCROLL DOWN
      let canScrollDown = state.canScrollDown;

      // IF HAS SCROLL EVEN A BIT DOWN
      if (scrollTop > 0) {
        // CAN SCROLL TOP TRUE
        canScrollTop = true;
        // REACHED BOTTOM IF THIS IS TRUE
        if (scrollTop + clientHeight === scrollHeight) {
          // CAN SCROLL BOTTOM FALSE!
          canScrollDown = false;
        }
      }
      // HASN'T SCROLLED AT ALL
      else {
        // MAKE CAN SCROLL TOP FALSE
        canScrollTop = false;
        // CAN SCROLL BOTTOM TRUE!
        canScrollDown = true;
      }

      // IF EITHER OF THESE IS DIFFERENT FROM THE ONES IN THE STATE, THEN UPDATE THE STATE
      if (
        state.canScrollDown !== canScrollDown ||
        state.canScrollUp !== canScrollTop
      ) {
        setState((prevState) => {
          return {
            ...prevState,
            canScrollDown: canScrollDown,
            canScrollUp: canScrollTop,
          };
        });
      }
    },
    [state.canScrollDown, state.canScrollUp]
  );

  // USE EFFECT TO INITIALLY CHECK IF LIST IS OVERFLOWING OR NOT
  useEffect(() => {
    // GET THE SHORTCUT LIST ELEMENT
    let shortcutList = document.getElementById(`shortcut_big_list_container_${isOnlyShortcutOpen}_${domain ? domain.id : ""}_${index}`);

    //IF IS OVERFLOWING THEN MAKE CAN SCROLL DOWN (INITIALLY) TRUE.
    if (shortcutList && shortcutList.clientHeight < shortcutList.scrollHeight) {
      setState((prevState) => ({ ...prevState, canScrollDown: true }));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  //////////////////////////////////////////////////////////////////////////////
  /// COMPONENT RENDER /////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  return (
    <React.Fragment>

        {/* CAN SCROLL UP 3 DOTS  */}
        {state.canScrollUp === true ? (
            <div
            className="flexCenterCenter shortcutListMoreIcon material-icons"
            style={{ width: "100%" }}
            >
            {state.canScrollUp === true ? "more_horiz" : ""}
            </div>
        ) : null}
        
        {/* TABLE */}
        <div
        className={`
            ${isOnlyShortcutOpen === false ? "flexColStartCenter" : "wrap"} 
            shortcutBigListContainer 
            ${snapshot.isDraggingOver ? "shortcutBigListContainerDashedBorder" : ""}
            ${state.canScrollDown ? "maskImageDown" : ""}
            ${state.canScrollUp ? "maskImageUp" : ""}          
        `}
        ref={provided.innerRef}
        onScroll={handleListScroll}
        {...provided.droppableProps}
        id={`shortcut_big_list_container_${isOnlyShortcutOpen}_${domain ? domain.id : ""}_${index}`}

        style={{
          zIndex: snapshot.isDraggingOver ? 1 : '',
          overflow: snapshot.isDraggingOver ? 'visible': '',
          ...provided.droppableProps.style,
        }}
        >
        {/* MAPPING OF SHORTCUT DATA SORTED BY THE LOWEST POSITION TO THE HIGHEST */}
        {shortcutData.map((shortcut, index) => {
            return (
            <Draggable
                key={`shortcut_list_item_${shortcut.id}_${index}`}
                draggableId={shortcut.id.toString()}
                index={index}
                isDragDisabled={viewMode === WBS_BIG_COMPONENT_VIEW_MODE.list}
            >
                {(provided, draggableSnapshot) => {

                  // OVERRIDE STYLES
                  let style = {
                    zIndex: draggableSnapshot.isDragging ? 9999999 : 1,
                    ...provided.draggableProps.style,
                  }

                  // RETURN SHORTCUT ITEM
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className='shortcutListItemDraggableContainer'
                      onClick={() =>
                      viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit
                          ? onAddEditClick(shortcut)
                          : window.open(shortcut.url, "_blank")
                      }
                      style={style}
                    >
                      <ShortcutListItem
                      key={`shortcut_big_list_container_item_${shortcut.id}_${index}`}
                      shortcut={shortcut}
                      viewMode={viewMode}
                      dragHandleProps={provided.dragHandleProps}
                      draggableProps={provided.draggableProps}
                      onDelete={onDelete}
                      isOnlyShortcutOpen={isOnlyShortcutOpen}
                      />
                    </div>
                  )
                }}
            </Draggable>
            );
        })}


        {/* DROPPABLE PLACEHOLDER */}
        {provided.placeholder}
        </div>


        {/* MORE ICON */}

        {state.canScrollDown === true ? (
            <div
            className="flexCenterCenter shortcutListMoreIcon material-icons"
            style={{ marginBottom: "-5px" }}
            >
            {state.canScrollDown === true ? "more_horiz" : ""}
            </div>
        ) : null}
    </React.Fragment>
  );
});
