// GOV BIG COMPONENT BODY EXPAND TYPES
export const GOV_COMPONENT_EXPAND_TYPES = {
    calendar: "CALENDAR",
    table: "TABLE",
    both: "BOTH",
};

// ITEM TYPES
export const GOV_ITEM_VIEW_TYPE = {
    scope: "scope",
    review: "review",
};
