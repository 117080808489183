import _ from "lodash";
import React, { useMemo } from "react";
import { FUNCT_FIND_INDEX } from "../../../../00-Core/Standards";
import {
    CHARTER_CATEGORIZATION_COLUMN,
    CHARTER_CATEGORIZATION_DETAIL,
    CHARTER_CATEGORIZATION_TYPE,
} from "../00-Helpers/CharterConstants";
import { CHARTER_FUNC_CATEGORIZATION } from "../00-Helpers/CharterFunctions";

export default function CharterBigDetailsCat({ switchCard, flipCard, charterData, updateTracker }) {
    ///////////////////////////////////////////////
    /// DATA //////////////////////////////////////
    ///////////////////////////////////////////////

    // GET CHARTER CATEGORIZATION LETTER (S, M, L)
    const charterCategorization = useMemo(() => {
        if (updateTracker) {
        }

        //TEST IF ITS GOOD
        if (charterData) {
            //INIT
            let newData = _.cloneDeep(charterData);

            //TETS DATE
            if (
                newData.startDate !== "" &&
                Object.prototype.toString.call(newData.startDate) !== "[object Date]"
            ) {
                newData.startDate = new Date(newData.startDate);
            }

            //TEST DATE
            if (
                newData.endDate !== "" &&
                Object.prototype.toString.call(newData.endDate) !== "[object Date]"
            ) {
                newData.endDate = new Date(newData.endDate);
            }

            if (
                (newData.startDate !== "" && isNaN(newData.startDate.getTime())) ||
                (newData.endDate !== "" && isNaN(newData.endDate.getTime()))
            ) {
                return null;
            }

            //IF DATA AVAILABLE
            if (
                newData.impact &&
                newData.teamSize &&
                newData.teamSize !== "" &&
                newData.teamSize > 0 &&
                newData.startDate &&
                newData.endDate &&
                newData.stakeholderSize &&
                newData.stakeholderSize !== "" &&
                newData.stakeholderSize > 0 &&
                newData.budget &&
                newData.budget !== "" &&
                newData.budget > 0
            ) {
                //CALCULATE CATEGORY
                return CHARTER_FUNC_CATEGORIZATION({
                    dates: {
                        startDate: newData.startDate,
                        endDate: newData.endDate,
                    },
                    inputs: {
                        budget: newData.budget,
                        stakeholders:
                            parseInt(newData.stakeholderSize) + parseInt(newData.teamSize),
                        impact: newData.impact,
                    },
                });
            }
        }

        //IF EMPTY
        return null;
    }, [charterData, updateTracker]);

    //COUNT
    const totalWeight = useMemo(() => {
        //INIT
        let count = 0;

        if (charterCategorization) {
            //LOOP
            for (let i = 0; i < charterCategorization.detailsInfo.length; i++) {
                count = count + charterCategorization.detailsInfo[i].weight;
            }
        }

        //RETURN
        return count;
    }, [charterCategorization]);

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////

    return (
        <div className="charterFlipCardBack">
            <div
                className="flexColStartStart bigComponentProjectCard"
                style={{ maxHeight: "100%" }}
            >
                {/* BACK CARD */}
                {flipCard ? (
                    <div className="flexStartCenter charterSwitchView" onClick={switchCard}>
                        <span className="material-icons charterSwitchViewIcon">assignment</span>
                        Card View
                    </div>
                ) : null}

                {/* Title */}
                <div
                    className="flexStartCenter"
                    style={{
                        fontWeight: 500,
                        color: "var(--color-text-2)",
                    }}
                >
                    <span
                        className="material-icons charterSwitchViewIcon"
                        style={{
                            fontSize: "20px",
                        }}
                    >
                        functions
                    </span>
                    Categorization's calculation details
                </div>

                {/* TABLE HEADER ----------------------------------------------------------*/}
                <div className="flexStartStrech charterCalculRow charterCalculRowHeader">
                    {/* LEGENDE */}
                    <div
                        className="flexStartCenter charterCalculCellLegend"
                        style={{
                            borderRight: "1px solid white",
                            borderLeft: "none",
                        }}
                    >
                        <div
                            className="charterCalculCellWeight flexCenterCenter"
                            style={{
                                position: "relative",
                                top: 0,
                                right: 0,
                                marginRight: "5px",
                                backgroundColor: "var(--color-migso-green)",
                                color: "white",
                            }}
                        >
                            X
                        </div>
                        <span
                            style={{
                                color: "var(--color-text-3)",
                            }}
                        >
                            Weight
                        </span>
                    </div>

                    {/* MAP CELLS */}
                    {CHARTER_CATEGORIZATION_COLUMN.map((cell) => {
                        return (
                            <div
                                key={"catRow-header-cell-" + cell.id}
                                className="charterCalculCellHeader flexCenterCenter"
                                style={{
                                    width: cell.id === "medium" ? "100%" : "80%",
                                }}
                            >
                                {cell.name}
                            </div>
                        );
                    })}
                </div>

                {/* TABLE ROW --------------------------------------------------------- */}
                {CHARTER_CATEGORIZATION_DETAIL.map((row) => {
                    //GET SELECTED VALUE
                    let currentUserSelection = {};
                    if (charterCategorization) {
                        let findIndex = FUNCT_FIND_INDEX(
                            charterCategorization.detailsInfo,
                            "key",
                            row.id
                        );
                        currentUserSelection = charterCategorization.detailsInfo[findIndex];
                    }

                    //RETURN
                    return (
                        <div
                            key={"catRow-" + row.id}
                            className="flexStartStrech charterCalculRow charterCalculRowBody"
                        >
                            {/* LEGENDE */}
                            <div className="flexStartCenter charterCalculCellLegend">
                                {row.name}
                                {row.unit ? (
                                    <span className="charterCalculCellUnit ">
                                        ({row.unitLight ? row.unitLight : row.unit})
                                    </span>
                                ) : null}
                            </div>

                            {/* MAP CELLS */}
                            {row.values.map((cell) => {
                                //TEST IF SELECTED
                                let selected = false;
                                if (currentUserSelection.id === cell.id) {
                                    selected = true;
                                }

                                //RETURN
                                return (
                                    <div
                                        key={"catRow-" + row.id + "-cell-" + cell.id}
                                        style={{
                                            width: cell.id === "medium" ? "100%" : "80%",
                                        }}
                                        className="flexStartStrech charterCalculCellContent"
                                    >
                                        {/* INFERIOR */}
                                        {cell.operator === "inferior" ? (
                                            <div className="charterCalculCell flexCenterCenter">
                                                <span
                                                    className={
                                                        selected ? "charterCalculCellSelected" : ""
                                                    }
                                                >
                                                    {"X <= " + cell.valueArray[0]}
                                                </span>
                                            </div>
                                        ) : null}

                                        {/* BETWEEN */}
                                        {cell.operator === "between" ? (
                                            <div className="charterCalculCell flexCenterCenter">
                                                <span
                                                    className={
                                                        selected ? "charterCalculCellSelected" : ""
                                                    }
                                                >
                                                    {cell.valueArray[0] +
                                                        " < X < " +
                                                        cell.valueArray[1]}
                                                </span>
                                            </div>
                                        ) : null}

                                        {/* SUPERIOR */}
                                        {cell.operator === "superior" ? (
                                            <div className="charterCalculCell flexCenterCenter">
                                                <span
                                                    className={
                                                        selected ? "charterCalculCellSelected" : ""
                                                    }
                                                >
                                                    {"X >= " + cell.valueArray[0]}
                                                </span>
                                            </div>
                                        ) : null}

                                        {/* SUPERIOR */}
                                        {cell.operator === "equal" ? (
                                            <div className="charterCalculCell flexCenterCenter">
                                                <span
                                                    className={
                                                        selected ? "charterCalculCellSelected" : ""
                                                    }
                                                >
                                                    {cell.valueName[0]}
                                                </span>
                                            </div>
                                        ) : null}

                                        {/* WEIGHT */}
                                        <div
                                            className="charterCalculCellWeight flexCenterCenter"
                                            style={{
                                                backgroundColor: selected
                                                    ? "var(--color-migso-green)"
                                                    : null,
                                                color: selected ? "white" : null,
                                            }}
                                        >
                                            {cell.weight}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}

                {/* RESULT ------------------------------------------------------------- */}
                <div className="flexStartStrech charterCalculRow charterCalculRowBody charterCalculRowResult">
                    {/* LEGENDE */}
                    <div
                        className="flexColCenterStart charterCalculCellLegend"
                        style={{
                            borderLeft: "none",
                            paddingLeft: "10px",
                        }}
                    >
                        Category
                        <div className="flexStartCenter">
                            <span
                                style={{
                                    color: "var(--color-text-3)",
                                    marginRight: "5px",
                                }}
                            >
                                Weight Sum
                            </span>
                            <div
                                className="charterCalculCellWeight flexCenterCenter"
                                style={{
                                    position: "relative",
                                    top: 0,
                                    right: 0,
                                    marginRight: "5px",
                                    backgroundColor: "var(--color-migso-green)",
                                    color: "white",
                                    fontSize: "12px",
                                    height: "16px",
                                    width: "16px",
                                }}
                            >
                                {totalWeight}
                            </div>
                        </div>
                    </div>

                    {/* MAP CELLS */}
                    {CHARTER_CATEGORIZATION_TYPE.map((cell) => {
                        //TEST IF SELECTED
                        let selected = false;
                        if (charterCategorization && charterCategorization.id === cell.id) {
                            selected = true;
                        }

                        //RETURN
                        return (
                            <div
                                key={"categoTot-" + cell.id}
                                style={{ width: "100%", position: "relative" }}
                                className="flexStartStrech charterCalculRowResultCell"
                            >
                                {/* INFERIOR */}
                                {cell.operator === "inferior" ? (
                                    <div className="charterCalculCell flexCenterCenter">
                                        <div
                                            className="flexColCenterCenter"
                                            style={{
                                                color: selected ? "white" : null,
                                                backgroundColor: selected ? cell.color : null,
                                                padding: "0px 10px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <div className="flexCenterCenter">
                                                {selected ? (
                                                    <span
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: "18px",
                                                            marginLeft: "-4px",
                                                        }}
                                                    >
                                                        workspace_premium
                                                    </span>
                                                ) : null}
                                                <span className="charterCalculRowResultCellCategory">
                                                    {cell.name}
                                                </span>
                                            </div>
                                            {"X <= " + cell.valueArray[0]}
                                        </div>
                                    </div>
                                ) : null}

                                {/* BETWEEN */}
                                {cell.operator === "between" ? (
                                    <div className="charterCalculCell flexCenterCenter">
                                        <div
                                            className="flexColCenterCenter"
                                            style={{
                                                color: selected ? "white" : null,
                                                backgroundColor: selected ? cell.color : null,
                                                padding: "0px 10px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <div className="flexCenterCenter">
                                                {selected ? (
                                                    <span
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: "18px",
                                                            marginLeft: "-4px",
                                                        }}
                                                    >
                                                        workspace_premium
                                                    </span>
                                                ) : null}
                                                <span className="charterCalculRowResultCellCategory">
                                                    {cell.name}
                                                </span>
                                            </div>
                                            {cell.valueArray[0] + " < X < " + cell.valueArray[1]}
                                        </div>
                                    </div>
                                ) : null}

                                {/* SUPERIOR */}
                                {cell.operator === "superior" ? (
                                    <div className="charterCalculCell flexCenterCenter">
                                        <div
                                            className="flexColCenterCenter"
                                            style={{
                                                color: selected ? "white" : null,
                                                backgroundColor: selected ? cell.color : null,
                                                padding: "0px 10px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <div className="flexCenterCenter">
                                                {selected ? (
                                                    <span
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: "18px",
                                                            marginLeft: "-4px",
                                                        }}
                                                    >
                                                        workspace_premium
                                                    </span>
                                                ) : null}
                                                <span className="charterCalculRowResultCellCategory">
                                                    {cell.name}
                                                </span>
                                            </div>
                                            {"X >= " + cell.valueArray[0]}
                                        </div>
                                    </div>
                                ) : null}

                                {/* SUPERIOR */}
                                {cell.operator === "equal" ? (
                                    <div className="charterCalculCell flexCenterCenter">
                                        <span
                                            style={{
                                                color: selected ? "red" : null,
                                            }}
                                        >
                                            {cell.valueName[0]}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
