import React, { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import {
    GOV_REVIEW_CALENDAR_CONTEXT_MENU_WIDTH,
    GOV_REVIEW_MEETING_STATUS_KEY,
    GOV_REVIEW_MODAL_TYPE,
} from "../../00-Helpers/GovReviewConstants";

export default function GovReviewContextMenu({
    contextMenuX,
    contextMenuY,
    item,
    resetContextMenu,
    isReadOnlyMode,
    onOpenReviewMode,
    onDeleteGovReview,
    handleShowModal,
    currentUser,
    isCurrentUserEditor,
}) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // GLOBAL COMPONENT STATE
    const [state, setState] = useState({
        deleteConfirm: false,
    });

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    //STOP INHERIT TO PREVENT TO LAUCNH OTHER ROADMAP FUNCTIONS
    const stopInherit = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    //MANAGE X
    const positionX = useMemo(() => {
        //TEST REVERT
        let maxWidth = window.screen.availWidth - (window.outerWidth - window.innerWidth);
        let revert = maxWidth - contextMenuX < GOV_REVIEW_CALENDAR_CONTEXT_MENU_WIDTH;

        //REVERT
        if (revert) {
            return contextMenuX - GOV_REVIEW_CALENDAR_CONTEXT_MENU_WIDTH;
        }

        //NORMAL
        return contextMenuX;
    }, [contextMenuX]);

    // IF CAN MODIFY THE SELECTED REVIEW OR NOT
    const canModify = useMemo(() => {
        return isCurrentUserEditor || item.createdBy === currentUser.username;
    }, [currentUser.username, isCurrentUserEditor, item.createdBy]);
    
    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // HANDLE DELETE CLICK
    const handleDeleteClick = useCallback((e) => {
        if(canModify) {
            // IF PART OF RECURRENCY, SHOW MODAL
            if (item.recurrencyParentId || item.isRecurrencyParent) {
                handleShowModal(e, item, GOV_REVIEW_MODAL_TYPE.delete);
                resetContextMenu();
            } else if (state.deleteConfirm) {
                onDeleteGovReview(e, item);
                resetContextMenu();
            } else {
                setState((prevState) => ({
                    ...prevState,
                    deleteConfirm: true,
                }));
            }
        }
    }, [canModify, handleShowModal, item, onDeleteGovReview, resetContextMenu, state.deleteConfirm]);

    // HANDLE READ MINUTES CLICK
    const handleReadMinutesClick = useCallback(() => {
        if (item.reviewMeetingStatus !== GOV_REVIEW_MEETING_STATUS_KEY.closed) {
            return;
        }
        onOpenReviewMode(item);
        resetContextMenu();
    }, [item, onOpenReviewMode, resetContextMenu]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    return createPortal(
        <React.Fragment>
            {/* MENU */}
            <div
                tabIndex={0}
                onBlur={resetContextMenu}
                className="contextMenuContent"
                style={{
                    top: contextMenuY,
                    left: positionX,
                    width: GOV_REVIEW_CALENDAR_CONTEXT_MENU_WIDTH,
                }}
                onContextMenu={(e) => stopInherit(e)}
            >
                {/* GO TO REVIEW MODE */}
                <div
                    className={"contextMenuAction flexStartCenter"}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => {
                        onOpenReviewMode(item, !isReadOnlyMode);
                        resetContextMenu();
                    }}
                >
                    <span className="material-icons contextMenuActionIcon">library_books</span>
                    Go to review mode
                </div>

                {/* READ MINUTES */}
                <div
                    className={"contextMenuAction flexStartCenter"}
                    style={{
                        color:
                            item.reviewMeetingStatus !== GOV_REVIEW_MEETING_STATUS_KEY.closed
                                ? "var(--border-color-gray-1)"
                                : null,
                        cursor:
                            item.reviewMeetingStatus !== GOV_REVIEW_MEETING_STATUS_KEY.closed
                                ? "default"
                                : null,
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={handleReadMinutesClick}
                >
                    <span
                        className="material-icons contextMenuActionIcon"
                        style={{
                            color:
                                item.reviewMeetingStatus !== GOV_REVIEW_MEETING_STATUS_KEY.closed
                                    ? "var(--border-color-gray-1)"
                                    : null,
                        }}
                    >
                        visibility
                    </span>
                    Read Minutes
                </div>

                {/* DELETE ITEM */}
                {!isReadOnlyMode && canModify ? (
                    <div
                        className={`contextMenuAction contextMenuActionDelete flexStartCenter`}
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={handleDeleteClick}
                    >
                        <span className="material-icons contextMenuActionIcon">
                            delete
                        </span>
                        {state.deleteConfirm ? "Are you sure ?" : "Delete"}
                    </div>
                ) : null}
            </div>

        </React.Fragment>,
        document.body
    );
}
