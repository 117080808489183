// LIBS
import { memo, useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { CvtCheckbox } from "@mi-gso/cvt";

// COMPONENTS
import BoardLoader from "./BoardLoader";
import BoardActionListItem from "./BoardTopActionListItem";

// FUNCTIONS
import { FUNCT_FIND_INDEX } from "../../../00-Core/Standards";

import {
    ACTION_STATUS_KEY,
    ACTION_STATUS_LIST,
} from "../../02-Portfolio/01-Action/00-Helpers/ActionsConstants";
import { FUNC_ACTION_IS_LATE } from "../../02-Portfolio/01-Action/00-Helpers/ActionsFunctions";
import BoardTopActionsFilters from "./BoardTopActionsFilters";
import { TODAY } from "../../../00-Core/Constants";

export default memo(function BoardTopActions({
    loader,
    actions,
    listedByProject,
    projectList,
    openWbsItem,
    boardDispatcher,
}) {
    ////////////////////////////////////////////////////
    // STATE ///////////////////////////////////////////
    ////////////////////////////////////////////////////
    const [state, setState] = useState({
        statusFilters: [],
        loadingId: null,
    });

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // ACTIVATE LOADER
    const activateLoader = useCallback((id) => {
        setState((prevState) => ({ ...prevState, loadingId: id }));
    }, []);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // FILTER ACTIONS BY STATUS
    const filteredActions = useMemo(() => {
        // IF FILTERS ACTIVE
        if (state.statusFilters.length > 0) {
            return actions.filter((action) => {
                // LATE
                if (state.statusFilters?.includes(ACTION_STATUS_KEY.late)) {
                    if (
                        action.status === ACTION_STATUS_KEY.todo ||
                        action.status === ACTION_STATUS_KEY.inProgress
                    ) {
                        //TEST IF DUEDATE NOT A DATE
                        let dueDate = action.dueDate;
                        if (Object.prototype.toString.call(dueDate) !== "[object Date]") {
                            dueDate = new Date(dueDate);
                        }

                        //TEST
                        if (dueDate.getTime() < TODAY.getTime()) return true;
                    }
                }

                // OTHER STATUS
                return state.statusFilters?.includes(action.status);
            });
        }

        // JUST REMOVE DONE
        return actions.filter((action) => action.status !== ACTION_STATUS_KEY.done);
    }, [actions, state.statusFilters]);

    // SORT BY DUE DATE AND GET ONLY TOP 5
    const sortedActionList = useMemo(() => {
        let actionsWithStatus = filteredActions.map((item) => {
            //GET TIME FOR SORT NUMBER
            let sortNum = 0;

            //SWITCH STATUS
            switch (item.status) {
                //DONE
                case ACTION_STATUS_KEY.done:
                    sortNum = Date.parse(item.realisedDate) || 0;
                    break;

                //ON HOLD
                case ACTION_STATUS_KEY.onHold:
                    sortNum = Date.parse(item.cancelledDate) || 0;
                    break;

                //DEFAULT
                default:
                    sortNum = Date.parse(item.dueDate) || 0;
                    break;
            }

            //ADD SORT
            return {
                ...item,
                sort: sortNum,
            };
        });

        //SORT DATA
        let actionsSorted = actionsWithStatus.sort(function (a, b) {
            if (a.sort < b.sort) {
                return -1;
            }
            if (a.sort > b.sort) {
                return 1;
            }
            return 0;
        });

        //RETURN
        return actionsSorted;
    }, [filteredActions]);

    // ACTION COUNT
    const actionCount = useMemo(() => {
        let count = {
            total: null,
            onHold: null,
            late: null,
        };

        // TOTAL
        count.total = filteredActions.length;

        // ON HOLD
        count.onHold = filteredActions.filter(
            (action) => action.status === ACTION_STATUS_KEY.onHold
        ).length;

        // STATUS LATE
        count.late = filteredActions.filter((action) =>
            FUNC_ACTION_IS_LATE(action.status, action.dueDate)
        ).length;

        return count;
    }, [filteredActions]);

    // ACTION LIST COMPONENT
    const actionListComponent = useMemo(() => {
        // GROUP BY PROJECT
        if (listedByProject && projectList.length > 0) {
            let actionList = [];

            // MAP EACH ACTIONS TO FILTER BY PROJECTS
            sortedActionList.forEach((action) => {
                // CREATE ARRAY IF NOT EXISTS
                if (!actionList[action.projectId]) {
                    // GET PROJECT INFOS
                    let projectIndex = FUNCT_FIND_INDEX(projectList, "id", action.projectId);
                    let projectInfos = projectList[projectIndex];

                    actionList[action.projectId] = {
                        projectInfos: projectInfos,
                        projects: [],
                    };
                }

                // ADD ACTION
                actionList[action.projectId].projects.push(action);
            });

            // BUILD COMPONENT
            return (
                <>
                    {/* MAP ON PROJECT ID */}
                    {Object.keys(actionList).map((actionProjectId) =>
                        actionList[actionProjectId].projectInfos?.name ? (
                            <div
                                style={{ marginBottom: "10px", width: "100%" }}
                                key={actionProjectId}
                            >
                                {/* PROJECT NAME */}
                                <div style={{ color: "var(--color-text-2)" }}>
                                    {actionList[actionProjectId].projectInfos.name}
                                </div>

                                {/* ACTION LIST */}
                                {actionList[actionProjectId].projects.map((actionItem) => (
                                    <BoardActionListItem
                                        key={actionItem.id}
                                        actionItem={actionItem}
                                        projectList={projectList}
                                        loadingId={state.loadingId}
                                        activateLoader={activateLoader}
                                        openWbsItem={openWbsItem}
                                        boardDispatcher={boardDispatcher}
                                    />
                                ))}
                            </div>
                        ) : null
                    )}
                </>
            );
        }

        return null;
    }, [
        activateLoader,
        boardDispatcher,
        listedByProject,
        openWbsItem,
        projectList,
        sortedActionList,
        state.loadingId,
    ]);

    const legendAndCounter = useMemo(() => {
        //INIT
        let legendObject = [];
        let cloneActions = [];
        cloneActions = _.cloneDeep(actions);

        //ONLY IF DATA
        if (cloneActions && cloneActions.length > 0) {
            //LOOP ON ARRAY LIST
            let totalCount = 0;
            let totalLateCount = 0;
            for (let i = 0; i < ACTION_STATUS_LIST.length; i++) {
                //COUNT
                let currentActions = cloneActions.filter(
                    (item) => item.status === ACTION_STATUS_LIST[i].id
                );
                let currentCount = currentActions.length;

                //INTEGRATE IF NOT EMPTY
                if (currentCount > 0) {
                    //TEST FOR IN PROGRESS AND TODO
                    let lateCount = 0;
                    if (
                        ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.todo ||
                        ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.inProgress
                    ) {
                        //FIND IF DUE DATE BEFORE TODAY
                        lateCount = currentActions.filter((item) => {
                            return FUNC_ACTION_IS_LATE(item.status, item.dueDate);
                        }).length;
                    }

                    //TOTAL COUNT - RELATED TO CURRENT SELECTION
                    totalCount = totalCount + currentCount;

                    //LATE
                    if (lateCount > 0) {
                        totalLateCount = totalLateCount + lateCount;
                    }

                    //INTEGRATE
                    legendObject.push({
                        id: ACTION_STATUS_LIST[i].id,
                        name: ACTION_STATUS_LIST[i].name,
                        backgroundColor: ACTION_STATUS_LIST[i].backgroundColor,
                        color: ACTION_STATUS_LIST[i].color,
                        value: currentCount,
                        late: lateCount,
                    });
                }
            }

            //UNSHIFT LATE
            if (totalLateCount > 0) {
                legendObject.unshift({
                    id: "late",
                    name: "Late",
                    value: totalLateCount,
                    backgroundColor: "var(--color-bad)",
                    color: "white",
                });
            }

            //TOTAL
            legendObject.push({
                id: "total",
                name: "Total",
                value: totalCount,
            });
        }

        //RETURN OBJECT
        return legendObject;
    }, [actions]);

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // SWITCH TO LIST BY PROJECT
    const onListByProjectToggle = useCallback(() => {
        boardDispatcher({
            type: "SET_SIMPLE_STATE",
            key: "actionsListedByProject",
            value: !listedByProject,
        });
    }, [boardDispatcher, listedByProject]);

    // WHEN CLICK ON STATUS TAG FILTER
    const handleStatusTagClick = useCallback(
        (tagValue) => {
            //INIT
            let newStatusSearchInput = _.cloneDeep(state.statusFilters);

            //TEST IF SELECTED
            let isSelected = state.statusFilters?.includes(tagValue);

            //IF ALREADY SELECTED REMOVE IT
            if (isSelected) {
                newStatusSearchInput = newStatusSearchInput.filter((status) => status !== tagValue);
            } else {
                //OR ADD IT
                newStatusSearchInput.push(tagValue);
            }

            //IF ALL SELECTED
            if (legendAndCounter.length - 1 === newStatusSearchInput.length) {
                newStatusSearchInput = [];
            }

            setState((prevState) => ({
                ...prevState,
                statusFilters: newStatusSearchInput,
            }));
        },
        [legendAndCounter, state.statusFilters]
    );

    // RESET ACTION STATUS FILTERS
    const onStatusReset = useCallback(() => {
        setState((prevState) => ({ ...prevState, statusFilters: [] }));
    }, []);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="structureBlock boardSectionStructureBlock"
            style={{
                minWidth: "600px",
                flex: 1,
            }}
        >
            {/* HEADER */}
            <div className="structureBlockTitle flexBetweenCenter">
                {/* LEFT PART */}
                <div className="flexStartCenter" style={{ gap: "20px" }}>
                    {/* TITLE */}
                    <div className="flexStartCenter">
                        <span className="material-icons structureBlockTitleIcon boardTitleIcon">
                            alarm
                        </span>
                        My Actions
                    </div>
                </div>

                {/* RIGHT PART */}
                <div className="flexStartCenter" style={{ gap: "10px" }}>
                    <BoardTopActionsFilters
                        legendAndCounter={legendAndCounter}
                        statusFilters={state.statusFilters}
                        handleStatusTagClick={handleStatusTagClick}
                        total={sortedActionList.length}
                        onStatusReset={onStatusReset}
                    />

                    {/* TOGGLE TO LIST BY PROJECTS */}
                    <div className="flexStartCenter"  style={{ gap: "10px" }}>
                        <span className="text_Color2_600_12px">Group</span>
                        <CvtCheckbox
                            handleFunction={onListByProjectToggle}
                            value={listedByProject}
                            padding="0px"
                            width="fit-content"
                        />
                    </div>
                </div>
            </div>

            {/* COUNT + CONTENT */}
            <div className="flexStartStart">
                {/* LEFT: COUNT */}
                <div className="boardSectionLeftInfos flexColBetweenCenter">
                    {/* TOTAL ACTIONS */}
                    <div className="flexColCenterCenter">
                        <span
                            className="boardSectionLeftInfosNumber"
                            style={{ color: "var(--color-text-2)" }}
                        >
                            {actionCount.total ?? "..."}
                        </span>
                        <span className="boardSectionLeftInfosText">Total</span>
                    </div>

                    {/* ON HOLD */}
                    <div
                        className="flexColCenterCenter"
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        <span
                            className="boardSectionLeftInfosNumber"
                            style={{ color: "var(--color-text-2)" }}
                        >
                            {actionCount.onHold ?? "..."}
                        </span>
                        <span className="boardSectionLeftInfosText">On Hold</span>
                    </div>

                    {/* LATE ACTIONS */}
                    <div className="flexColCenterCenter">
                        <span
                            className="boardSectionLeftInfosNumber boardSectionLeftInfosNumberWithBackground flexCenterCenter"
                            style={{
                                backgroundColor: "var(--color-bad)",
                            }}
                        >
                            {actionCount.late ?? "..."}
                        </span>
                        <span className="boardSectionLeftInfosText">Late</span>
                    </div>
                </div>

                {/* RIGHT: CONTENT */}
                <div className="structureBlockInfo boardStructureBlockInfo flexColStartStart">
                    {loader ? (
                        <BoardLoader text="Loading Actions ..." />
                    ) : (
                        <>
                            {listedByProject
                                ? actionListComponent
                                : sortedActionList.map((actionItem) => (
                                      <BoardActionListItem
                                          actionItem={actionItem}
                                          projectList={projectList}
                                          key={actionItem.id}
                                          displayProject
                                          activateLoader={activateLoader}
                                          loadingId={state.loadingId}
                                          openWbsItem={openWbsItem}
                                          boardDispatcher={boardDispatcher}
                                      />
                                  ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});
