import React, { useMemo } from "react";
import { FUNCT_FIND_INDEX } from "../../../../../00-Core/Standards";

//USER
import UsersManagementTableItem from "./UserManagementTableItem";

////////////////////////////
/// USER TABLE COMPONENT ///
////////////////////////////

export default function UsersManagementTable({
    currentPageUsers,
    currentUserId,
    isAddingUsers,
    usersList,
    selectedUsers,
    projectList,
    portfolioEdit,
    handleSelectUser,
    handleTypeClick,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // HEADERS
    const tableHeaders = useMemo(() => {
        let headers = ["", "Name", "Email"];
        if (!isAddingUsers) {
            headers = [...headers, "Role"];
        }

        return headers;
    }, [isAddingUsers]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <table className="projectUserManagementTable">
            {/* HEADER------------------------------- */}
            <thead>
                <tr>
                    {tableHeaders.map((header) => (
                        <th
                            className="projectUserManagementTableHeader"
                            key={"userHeader-" + header}
                        >
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>

            {/* BODY ------------------------------------ */}
            <tbody>
                {currentPageUsers.map((user) => {
                    //CHECK IF SELECTED
                    let isSelected =
                        selectedUsers &&
                        selectedUsers.findIndex((selectedUser) => selectedUser.id === user.id) > -1;

                    //CHECK NAME
                    let name = user.name;
                    if (!name) {
                        //FIND USER
                        let findIndex = FUNCT_FIND_INDEX(usersList, "id", user.userName);

                        //TEST IF FOUND
                        if (findIndex > -1) {
                            name = usersList.users[findIndex].name;
                        }
                    }

                    //RETURN
                    return (
                        <UsersManagementTableItem
                            key={"userBody-" + user.userName}
                            userDetail={user}
                            currentUserId={currentUserId}
                            name={name}
                            handleSelectUser={handleSelectUser}
                            isSelected={isSelected}
                            isAddingUsers={isAddingUsers}
                            projectList={projectList}
                            portfolioEdit={portfolioEdit}
                            handleTypeClick={handleTypeClick}
                        />
                    );
                })}
            </tbody>
        </table>
    );
}
