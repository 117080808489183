// REACT 
import { memo } from "react";

// BEATIFUL-DND
import {Draggable} from "react-beautiful-dnd";

////////////////////////////
/// RISK CARD //////////////
////////////////////////////

export default memo(function RiskCardMatrixItem({

  // DATA
  droppableProps,
  droppableRef,
  matrixSetting,
  
  // FROM CONSTANTS (RISK_MATRIX_VIEW_MODE, ex: initial, current, target)
  modes,
  isRisk,
  providedPlaceholder,
  isDragOver,
  handleSwitchingView
}) {

  /////////////////////////////////////////////////////////////
  /// COMPONENT RENDER ////////////////////////////////////////
  /////////////////////////////////////////////////////////////

  return (
    <div
      className="riskMatrixItemContainer"
      ref={droppableRef}
      {...droppableProps}
      style={{
        background:
          isRisk === true
            ? matrixSetting.riskColor
            : matrixSetting.opportunityColor,
        boxShadow:
          isDragOver === true ? "1px 1px 2px 1px rgba(0, 0, 0,0.5)" : "",
        borderColor: isDragOver === true ? "var(--color-migso-blue)" : "",
        ...droppableProps.style,
      }}
    >
      {/* MATRIX ITEM GRID */}
      <div className="riskMatrixItemGrid">
        {/* MAP THE RO ITEMS (2 per row)*/}
        {modes.map((mode, index) => {
          return(
            <Draggable 
                key={`risk_view_matrix_mode_draggable_${mode.value.toString()}`}
                id={mode.value.toString()}
                draggableId={mode.value.toString()}
                index={index}
                isDragDisabled={true}
            >{(provided, snapshot) => (
                <div className="riskMatrixViewItem flexCenterCenter"  
                    // draggable={true}
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    onClick={(e) => handleSwitchingView(e, mode)}
                    style={{
                      zIndex: snapshot.isDragging === true ? 10000 : null,
                      ...provided.draggableProps.style,
                    }}
                    id={`risk_matrix_view_item_${mode.value.toString()}`}
                  >
                    <div 
                      key={`risk_view_matrix_mode_${mode.value.toString()}`} 
                      style={{backgroundColor: mode.backgroundColor,}}
                      draggable={true}
                      className="riskMatrixViewInnerItem"
                    >
                        {mode.label}
                    </div>
                </div>
              )
            }
            </Draggable>
          )
        })
        }

      </div>

      {/* BOTTOM LEFT CRITICITY */}
      <span className="riskMatrixItemCriticity">{matrixSetting.criticity}</span>

      {providedPlaceholder}
    </div>
  );
});
