import * as XLSX from 'xlsx';


// - FUNC_CONVERT_JSON_TO_EXCEL                 // 


// CONVER A JSON FILE TO EXCEL
export const FUNC_CONVERT_JSON_TO_EXCEL = (
    jsonData,
    fileName,
  ) => {
    
    let workSheet = XLSX.utils.aoa_to_sheet([], {skipHeader:true})
    workSheet['!merges'] = [{s: {r: 1, c:1}, e: {r: 1, c: 1}}]; // FOR MERGING COLUMNS
    XLSX.utils.sheet_add_json(workSheet, jsonData, {origin: 'A1'}) // ORIGIN FOR JSON DATA
    // RANGE
    const range = XLSX.utils.decode_range(workSheet['!ref']);
    // LOOP RANGE
    for(let C = range.s.r; C <= range.e.r; ++C) {
      // ADRESS
      let address = XLSX.utils.encode_col(C);
      // IF DOESN'T EXIST, THEN SKIP
      if(!workSheet[address]) continue;
      // ELSE ADD 
      workSheet[address].v = workSheet[address].v.charAt(0).toUpperCase() + workSheet[address].v.substr(1).toLowerCase();
    }
    
    const workbook = {Sheets: { 'Grid': workSheet}, SheetNames: ['Grid']};
    
    // DOWNLOAD FILE DIRECTLY
    XLSX.writeFile(workbook, fileName);
}