import React, { useReducer, useEffect, useCallback, useMemo } from "react";
import './00-Helpers/AppCss.css'

//LIBRARIES
import {Switch, Route, useLocation, useHistory} from 'react-router-dom'



//COMPONENTS
import Header from "./Header"
import Footer from './Footer'
import Home from "../00-Home/Home"
import Board from "../01-Board/Board"
import Wbs from "../02-Portfolio/00-Wbs/Wbs"
import WbsSideBar from '../02-Portfolio/00-Wbs/WbsSideBar'
import Documentation from '../06-Documentation/Documentation'
import Terms from '../07-Terms/Terms'
import WbsSettingsModal from '../02-Portfolio/00-Wbs/01-Components/WbsSettingsModal'
import {TEMPLATE_GET_PROJECT_USERS_V2 } from "../02-Portfolio/00-Wbs/00-Helpers/WbsFunctions";

//AUTHENTICATOR
import {autoSignIn} from './01-Auth/00-Functions/AuthAutoSignIn'
import {signOut} from './01-Auth/00-Functions/AuthSignOut'
import TermsModalValidation from '../07-Terms/TermsModalValidation'
import AuthAuthenticatorWithMessage from './01-Auth/01-Authenticator/AuthAuthenticatorWithMessage'

//FUNCTIONS
import appReducer from "./AppDispatcher";
import {initialState} from "./AppDispatcher";
import { APP_TOAST_OPTIONS, APP_WBS_STRUCTURE, DEFAULT_STATE_MOVE_MODAL_OPTIONS } from "../../00-Core/Constants";
import { WBS_DOMAIN_DETAILS } from "../02-Portfolio/00-Wbs/00-Helpers/WbsConstants";
import WbsMoveItemsModal from "../02-Portfolio/00-Wbs/01-Components/WbsMoveItemsModal";
import WbsReferenceModal from "../02-Portfolio/00-Wbs/01-Components/WbsReferenceModal";
import 'react-tooltip/dist/react-tooltip.css';
import { CvtToast } from "@mi-gso/cvt";
import { envVarManagement } from "../../envVarManagement";

//DELETE MOCK DATA
// $ rm -r amplify/mock-data

//CHECK GRAPHQL
//amplify status api -acm <model>

//COGNITO ACTION
//https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_Operations.html

//CHECK PROPS RE RENDER
// useEffect( () => {
//     console.log('PROPS UPDATED');
// }, [roadmapData])

//DEBUG CONSOLE
//window.LOG_LEVEL = 'DEBUG'

//DEV GRAPHQL ENDPOINT
//"https://ibaiga4sefd2pnsv2cpzbv73ku.appsync-api.eu-central-1.amazonaws.com/graphql"

/////////////////////
/// APP COMPONENT ///
/////////////////////

export default function App(){

  //ENV VAR
  const envMegaUsers = envVarManagement("envMegaUsers")

  ////////////////
  /// INIT APP ///
  ////////////////

  //APP STATE DISPATCHER
  const [state, appDispatcher] = useReducer(appReducer, initialState);

  //CURRENT LOCATION
  const location = useLocation();
  let history = useHistory();

  //CLOSE APP TOAST
  const closeAppToast = useCallback(() => {
    appDispatcher({
      type: "SET_GLOBAL_STATE",
      options:{
        source: "appToast",
        object: {
          display:false,
          color:"",
          message:"",
        },
      }
    })
  }, [])


  ////////////////////////
  /// AUTHENTIFICATION ///
  ////////////////////////

  //SIGN OUT & GOTO HOME PAGE
  const handleSignOut = useCallback(() => {

    //CALL SIGN OUT FUNCTION
    signOut(
      appDispatcher,
      true
    );

    //MOVE HISTORY TO HOME
    history.push("/");
  },[history]);

  //TRY AUTO SIGN IN AND GET USER DATA
  useEffect(()=>{
    //LAUNCH TO TRY AN AUTO SIGN IN
    autoSignIn(
      appDispatcher
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  /////////////////////////////////////////
  /// NEEDED TREATMENTS WITH APP RENDER ///
  /////////////////////////////////////////

  //GET PROJECT LEADER LIST FROM CHARTER LOADED
  const projectLeaderList = useMemo(() => {
    return[...new Set(state.projectData.charter.map(item => item.leader))];
  },[state.projectData.charter])

  // USERS LIST FOR SELECTED PROJECT
  const currentProjectUsersList = useMemo(() => {

    //INIT
    let organizationMegaUsersList = []
    let projectMegaUsersList = []
    let projectUsersList = []

    //PORTFOLIO OR ORGANIZATION
    if(state.portfolioSelection.selectedPortfolio){

      //LOOP TO CONCATENATE ALL PORTFOLIO USERS
      state.portfolioSelection.portfolioProjects.forEach(project => {
        organizationMegaUsersList.push(...project.organizationMegaUsersList)
        projectMegaUsersList.push(...project.projectMegaUsersList)
        projectUsersList.push(...project.projectUsersList)
      });

    //ONLY ONE PROJECT
    }else if(state.coreSelection && state.coreSelection.selectedProject && state.coreSelection.selectedProject.id){
      organizationMegaUsersList = state.coreSelection.selectedProject.organizationMegaUsersList
      projectMegaUsersList = state.coreSelection.selectedProject.projectMegaUsersList
      projectUsersList = state.coreSelection.selectedProject.projectUsersList
    }

    //CONCATENATE USER
    return TEMPLATE_GET_PROJECT_USERS_V2(
      organizationMegaUsersList,
      projectMegaUsersList,
      projectUsersList,
      state.coreSelection.users,
      envMegaUsers,
      projectLeaderList
    )
  }, [envMegaUsers, projectLeaderList, state.coreSelection, state.portfolioSelection.portfolioProjects, state.portfolioSelection.selectedPortfolio]);

  // GET WBS MAX WIDTH RELATED TO FIRST COLUMN AND ALL DISPLAYED DOMAIN
  const wbsMinWidth = useMemo(() =>{

    //INIT
    let width = APP_WBS_STRUCTURE.firstColumnWidth;

    //LOOP TO ADD ALL MIN COLUMN DISPLAYED WIDTH
    for (let i = 0; i < WBS_DOMAIN_DETAILS.length; i++) {
      if(WBS_DOMAIN_DETAILS[i].displayInColumn){
        if(WBS_DOMAIN_DETAILS[i].id === "riskOpp"){
          width += APP_WBS_STRUCTURE.minCellWidth*2;
        }else{
          width += APP_WBS_STRUCTURE.minCellWidth;
        }
      }
    }

    //RETURN
    return width;
  },[])


  ////////////////////////
  /// FUNCTIONS //////////
  ////////////////////////

  const showHideMoveItemsModal = useCallback((e) => {
    e.preventDefault();
    appDispatcher({
      type: 'SET_GLOBAL_STATE',
      options: {
        source: 'appSettings',
        object: {
          actionModal: null,
          moveModalOptions: DEFAULT_STATE_MOVE_MODAL_OPTIONS,
        },
      },
    });
  }, []);



  /////////////////////////////
  /// APP CONSOLE LOG DEBUG ///
  /////////////////////////////

  // console.log("Render: APP")
  //console.log("appToast: ", appToast)
  //console.log("app: ", state.currentUser)
  //console.log("coreOrganizationList: ", state.coreOrganizationList)
  // console.log("coreProjectList: ", state.coreProjectList)
  // console.log("coreSelection", state.coreSelection)
  //console.log("data", state.data)
  // console.log("projectData", state.projectData.schedule)
  //console.log("wbsDOmain", state.wbsDomain)
  //console.log("charterData", state.projectData.charter)
  //console.log("filters", state.wbsFilters)
  //console.log("portfolio", state.portfolioSelection)
  // console.log("app settings", state.appSettings)

  //////////////////
  /// APP RENDER ///
  //////////////////


  return (
    <main id="appMain">

      {/* HEADER ------------------------------------------------------------- */}
      <Header
        searchBar
        navLinks={state.currentUser.loggedIn}
        currentServiceId="tel"
        locationPath={location.pathname}
        loggedIn={state.currentUser.loggedIn}
        signOut={handleSignOut}
        users={state.coreSelection.users}
        appDispatcher={appDispatcher}
      />

        {/* BODY ---------------------------------------------------------------*/}
        <section id="appBody" className="flexStartStrech">

          {/* CONTENT + ROUTE */}
          <Switch>

            {/* BOARD VIEW ----------------------------------------------- */}
            <Route
              exact path="/board"
              render={() => {
                if(state.currentUser.loggedIn){
                  return(
                    <Board
                      portfolios={state.portfolioSelection.portfolios}
                      myActions={state.boardData.myActions}
                      nextReviews={state.boardData.nextReviews}
                      projectData={state.projectData}
                      selectedPortfolioId={state.portfolioSelection.selectedPortfolio ? state.portfolioSelection.selectedPortfolio.id : null }
                      selectedProjectId={state.coreSelection.selectedProject.id ? state.coreSelection.selectedProject.id : null }
                      showProjetListView={state.portfolioSelection.showProjetListView}
                      currentUserId={state.currentUser.username}
                      isMegaUser={envMegaUsers?.includes(state.currentUser.username)}
                      userList={state.coreSelection.users}
                      projectList={state.currentUser.coreProjects}
                      organizationList={state.currentUser.coreOrganization}
                      appDispatcher={appDispatcher}
                      currentUser={state.currentUser}
                    />
                  )
                }else{
                  return(
                    <AuthAuthenticatorWithMessage
                      restrictedMessage="Portfolio Section is a restricted area"
                      currentUser={state.currentUser}
                      appDispatcher={appDispatcher}
                    />
                  )
                }
              }}
            />

            {/* PORTFOLIO ------------------------------------------------------------- */}
            <Route
              path="/controls"
              render={() => {
                if(state.currentUser.loggedIn){
                  return(
                    <React.Fragment>

                      {/* WBS SIDEBAR */}
                      <WbsSideBar
                        projectData={state.projectData}
                        usersList={currentProjectUsersList}
                        wbsFilters={state.wbsFilters}
                        isPortfolio={state.portfolioSelection.selectedPortfolio !== null}
                        portfolioEditors={state.portfolioSelection.selectedPortfolio ? [...state.portfolioSelection.selectedPortfolio.itemEditors, state.portfolioSelection.selectedPortfolio.createdBy] : null}
                        selectedProject={state.coreSelection.selectedProject? state.coreSelection.selectedProject : null }
                        currentUser={state.currentUser}
                        dataConsolidation={state.appSettings.dataConsolidation}
                        appDispatcher={appDispatcher}
                      />

                      {/* WBS */}
                      <Wbs
                        currentUser={state.currentUser}
                        coreSelection={state.coreSelection}
                        selectedOrganizationId={state.coreSelection.selectedOrganization.id}
                        selectedProjectId={state.coreSelection?.selectedProject?.id}
                        projectData={state.projectData}
                        wbsSettings={state.wbsSettings}
                        usersList={currentProjectUsersList}
                        projectLeaderList={projectLeaderList}
                        selectedPortfolio={state.portfolioSelection.selectedPortfolio}
                        portfolioProjects={state.portfolioSelection.portfolioProjects}
                        portfolios={state.portfolioSelection.portfolios}
                        wbsFilters={state.wbsFilters}
                        wbsMinWidth={wbsMinWidth}
                        dataConsolidation={state.appSettings.dataConsolidation}
                        sideBarOpenWidth={state.appSettings.sideBarOpenWidth}
                        appDispatcher={appDispatcher}
                        moveModalOptionsItemsArray={state.appSettings.moveModalOptions.itemsArray}
                        updateTrackers={state.portfolioDisplay.updateTrackers}
                        referenceCreateUpdateObject={state.appSettings.referenceCreateUpdateObject}
                        referenceModalItem={state.appSettings.referenceModalItem}
                        wbsNavigationOptions={state.portfolioDisplay?.wbsNavigationOptions}
                      />
                    </React.Fragment>
                  )
                }else{
                  return(
                    <AuthAuthenticatorWithMessage
                      restrictedMessage="Project Section is a restricted area"
                      currentUser={state.currentUser}
                      appDispatcher={appDispatcher}
                    />
                  )
                }
              }}
            />

            {/* DOCS VIEW --------------------------------------------- */}
            <Route
                exact path="/docs/:fileId?"
                render={() => {
                  if(state.currentUser.loggedIn){
                    return(
                        <Documentation />
                    )
                  }else{
                    return(
                      <AuthAuthenticatorWithMessage
                        restrictedMessage="Docs Section is a restricted area"
                        currentUser={state.currentUser}
                        appDispatcher={appDispatcher}
                      />
                    )
                  }
                }}
              />

            {/* TERMS VIEW --------------------------------------------- */}
            <Route
              exact path="/terms"
              render={() => {
                if(state.currentUser.loggedIn){
                  return(
                      <Terms />
                  )
                }else{
                  return(
                    <AuthAuthenticatorWithMessage
                      restrictedMessage="Terms Section is a restricted area"
                      currentUser={state.currentUser}
                      appDispatcher={appDispatcher}
                    />
                  )
                }
              }}
            />

            {/* HOME VIEW --------------------------------------------- */}
            <Route
              exact path="/"
              render={() => (
                  <Home
                    selectedOrganization={state.coreSelection.selectedOrganization}
                    selectedProjectId={state.coreSelection.selectedProject.id}
                    isWbsLoaded={state.coreSelection.wbsList.length > 0}
                    currentUser={state.currentUser}
                    appDispatcher={appDispatcher}
                  />
              )}
            />
          </Switch>
        </section>

        {/* FOOTER -------------------------------------*/}
        <Footer
          isLoggedIn={state.currentUser.loggedIn}
        />

        {/* WBS SETTINGS MODAL ------------------------------ */}

        {state.appSettings.actionModal === "wbsSettings" ?
            <WbsSettingsModal
              wbsSettings={state.wbsSettings}
              currentId={state.portfolioSelection.selectedPortfolio ? state.portfolioSelection.selectedPortfolio.id : state.coreSelection.selectedProject.id }
              appDispatcher={appDispatcher}
            />
          :null}

        {/* WBS MOVE MODAL ------------------------------ */}
        {
          state.appSettings.actionModal === "wbsMove" ?

          <WbsMoveItemsModal
            itemsArray={state.appSettings.moveModalOptions.itemsArray}
            tableName={state.appSettings.moveModalOptions.tableName}
            projectList={state.portfolioSelection.selectedPortfolio?.id !== undefined ? state.portfolioSelection.portfolioProjects : state.currentUser.coreProjects}
            currentProjectWbsList={state.coreSelection.wbsList}
            projectId={state.coreSelection.selectedProject.id}
            wbsId={state.coreSelection.selectedWbs?.id === state.coreSelection.selectedProject.id ? '' : state.coreSelection.selectedWbs?.id}
            selectedOrganizationId={state.coreSelection.selectedOrganization.id}
            appDispatcher={appDispatcher}
            onCancelClicked={showHideMoveItemsModal}
            // handleMoveItemsConfirm={handleMoveItemsConfirm}
            graphqlUpdateQuery={state.appSettings.moveModalOptions.query}
            propertiesToDelete={state.appSettings.moveModalOptions.propertiesToDelete}
            isInPortfolio={state.portfolioSelection.selectedPortfolio?.id !== undefined}
          />

        :null}

        {/* CHOOSE REFERENCE VERSION MODAL */}
        {
          state.appSettings.actionModal === "wbsChooseReference" ?

          <WbsReferenceModal
            referenceModalItem={state.appSettings.referenceModalItem}
            projectId={state.coreSelection.selectedProject.id}
            organizationId={state.coreSelection.selectedOrganization.id}
            appDispatcher={appDispatcher}
            scheduleData={state.projectData.schedule}
            currentUser={state.currentUser}
            selectedProject={state.coreSelection.selectedProject}
          />

        :null}

        {/* VALIDATE TERMS MODAL ------------------------------ */}
        {state.currentUser.displayTermsValidation ?
          <TermsModalValidation
            appDispatcher={appDispatcher}
          />
        :null}

        {/* TOAST ---------------------------------------------- */}
        {state.appToast.display ?
          <CvtToast
            duration={state.appToast.color === "danger" ? 6000 : 3000}
            loader
            title={APP_TOAST_OPTIONS[state.appToast.color]?.title ?? "Info update!"}
            type={APP_TOAST_OPTIONS[state.appToast.color]?.type ?? "sync"}
            text={state.appToast.message}
            onClose={ closeAppToast }
          />
        :null}
    </main>
  );
}