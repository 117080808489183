// export const SHORTCUT_BIG_MANDATORY_FIELDS = ["id", "name", "url"];

import React from "react"
import { createTelescopeDataShortcut, deleteTelescopeDataShortcut, updateTelescopeDataShortcut } from "../../../../graphql/mutations"


// LOCAL STORAGE ITEM KEY FOR LOCKED SHORTCUTS
export const LOCKED_SHORTCUTS_STORAGE_KEY = "lockedShortcuts";

// BACKGROUND COLORS FOR SHORTCUT
export const SHORTCUT_BACKGROUND_COLORS = [
    // RED
    {   
        label: "Red",
        value: "#e55766",
    },
    // BLACK
    {   
        label: "Black",
        value: "#1e1e1e",
    },
    // MIGSO BLUE
    {
        label: "Blue)",
        value: "#03a9db",
    },
    // YELLOW
    {
        label: "Yellow",
        value: "#fcc708",
    },
    // GREEN
    {
        label: "Green",
        value: "#00b04f",
    },
    // ORANGE   
    {
        label: "Orange",
        value: "#ed7d31",
    },
    // CLASH
    {
        label: "Clash",
        value: "#ED7D31",
    },
    // GREY 
    {
        label: "Grey",
        value: "#5a5a5a",
    },
    // GOLD
    {
        label: "Gold",
        value: "#FFD700",
    },
]


// DEFAULT COLORS FOR A SHORTCUT
export const SHORTCUT_BIG_DEFAULT_COLORS = {
    // textColor: "var(--color-text-2)",
    textColor: '#ffffff',
    backgroundColor: '#FFFFFF',
    borderColor: "var(--border-color-gray-1)",
}

// EDIT SIDE BAR GROUPS
export const SHORTCUT_GROUP_ITEMS = [
    // // COLORS
    // {id: 1, name: "Colors", icon: 'color_lens'},
]

// STRUCTURE OF SHORTCUT ITEM
export const SHORTCUT_ITEM_DATA = [
    //--------------- ITEM IDS-----------------------
    { groupId: -1, key: "id", value: null, type: "id" },
    { groupId: -1, key: "organizationId", value: "", type: "id" },
    { groupId: -1, key: "projectId", value: "", type: "id" },
    { groupId: -1, key: "wbsId", value: "", type: "id" },
    //-----------------------------------------------

    //-----NO GROUP (NAME, URL, ICON, CATEGORY)------

    // NAME
    {
        groupId: 0,
        key: "name",
        value: "",
        label: "Name",
        component: "input",
        type: "text",
        info: "",
        placeholder: "Enter a name...",
        mandatory: true,
    },
    // URL
    {
        groupId:0,
        key: "url",
        label: "Url",
        value: "",
        component: "input",
        type: "url",
        info: "",
        placeholder: "Enter an url (https://...)",
        mandatory: true,
    },
    // ICON
    {
        groupId:0,
        key: "icon",
        label: "Icon",
        value: "",
        component: "select",
        type: "list",
        options: [],
        placeholder: "Choose an icon",
        info: "",
        mandatory: true,
        optionLeftComponent: ({value, label}) => (
        <div className="flexStartCenter">
            <div className='material-icons'>
            {value}
            </div>
            <span style={{marginLeft:'5px'}}>{label}</span>
        </div>
        
        ),
        isNew: true,
    },
    // CATEGORY 
    {
        groupId: 0,
        key: "category",
        label: "Category",
        value: "",
        component: "select",
        type: "list",
        options: [],
        placeholder: "Enter a category...",
        info: "",
        mandatory: true,
    },
    
    //-------------------------------------------------------

    // ---------------------COLORS --------------------------

    // BACKGROUND COLOR
    {
        groupId: 0,
        key: "backgroundColor",
        label: "Background Color",
        value: "",
        component: "color_picker",
        type: "color",
        placeholder: "Choose a background color",
        options: SHORTCUT_BACKGROUND_COLORS,
        info: "",
        mandatory: false,
        // optionLeftComponent: ({value, _}) => (
        //     <div style={{width: '100%', height: '20px', backgroundColor: value}}>
        //     </div>
        // ),
        isNew: true,

    },
    // // TEXT COLOR
    // {
    //     groupId: 1,
    //     key: "textColor",
    //     value: "",
    //     label: "Text Color",
    //     component: "input",
    //     type: "color",
    //     placeholder: "Choose a color for your text",
    //     info: "",
    //     mandatory: true,
    // },
    // BORDER COLOR
    // {
    //     groupId: 1,
    //     key: "borderColor",
    //     value: "",
    //     label: "Border Color",
    //     component: "input",
    //     type: "color",
    //     placeholder: "Choose a border color",
    //     info: "",
    //     mandatory: true,
    // },

    // ------------------------------------------------------
]

// SHORTCUT MUTATION QUERIES
export const SHORTCUT_MUTATION_QUERIES = {
    create: createTelescopeDataShortcut,
    delete: deleteTelescopeDataShortcut,
    update: updateTelescopeDataShortcut,
}