import { useMemo } from "react";
import { TODAY } from "../../../../00-Core/Constants";
import { SCHEDULE_STATUS_KEYS, SCHEDULE_TIMELINE_COLORS } from "../00-Helpers/ScheduleConstants";
import {
    SCHEDULE_FUNC_FIX_OVERLAPS_MILESTONE,
    SCHEDULE_FUNC_GET_TIMELINE_LETTERS,
    SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH,
    SCHEDULE_FUNC_GET_TIMELINE_START_POSITION,
    SCHEDULE_FUNC_SORT_DATES,
    SCHEDULE_FUNC_TIMELINE_DATE_TO_STR,
} from "../00-Helpers/ScheduleFunctions";

export default function ScheduleMilestoneTimeline({ item }) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // TODAY
    const today = useMemo(() => {
        return new Date(TODAY.setHours(0, 0, 0, 0)).getTime();
    }, []);

    // CALCULATE TIMELINE CONFIGURATION
    const timelineConfig = useMemo(() => {
        // --------- INIT -----------

        let config = {
            baseline: {
                start: null,
                letters: null,
                date: null,
            },
            forecast: {
                start: null,
                letters: null,
                date: null,
            },
            actual: {
                start: null,
                letters: null,
                date: null,
            },
            today: {
                start: null,
                letters: null,
                date: null,
            },
        };
        let timelineStart;

        // SET HOURS TO 0 AND CONVERT DATES TO TIMESTAMPS
        let itemBaseline = item.baselineStart.setHours(0, 0, 0, 0);
        let itemForecast = item.forecastStart.setHours(0, 0, 0, 0);
        let itemActual = item.actualStart?.setHours(0, 0, 0, 0);

        // INIT FIND FIRST AND LAST DATE
        let allDates = null;
        let firstDate = null;
        let lastDate = null;
        let duration = null;

        // -------- SORT ALL DATES TO FIND FIRST AND LAST --------
        allDates = [itemBaseline, itemForecast];

        // ADD ACTUAL DATE IF NOT NULL
        if (itemActual) {
            allDates.push(itemActual);
        }

        // FOR STATUS IN THE PAST, ADD TODAY
        if (item.status.value === SCHEDULE_STATUS_KEYS.inThePast) {
            allDates.push(today);
        }

        // SORT DATES
        allDates = SCHEDULE_FUNC_SORT_DATES(allDates);

        // FIND FIRST AND LAST DATE
        firstDate = allDates[0];
        lastDate = allDates[allDates.length - 1];

        // ------ CALCULATE TOTAL DURATION IN MS -------
        duration = lastDate - firstDate;
        config.duration = duration;

        // ----- CALCULATE BASELINE CONFIG -------
        timelineStart = SCHEDULE_FUNC_GET_TIMELINE_START_POSITION(
            firstDate,
            itemBaseline,
            duration
        );

        config.baseline.start = timelineStart;
        config.baseline.date = itemBaseline;

        // ----- CALCULATE FORECAST CONFIG -------
        timelineStart = SCHEDULE_FUNC_GET_TIMELINE_START_POSITION(
            firstDate,
            itemForecast,
            duration
        );

        config.forecast.start = timelineStart;
        config.forecast.date = itemForecast;

        // ----- CALCULATE ACTUAL CONFIG -------

        if (itemActual) {
            timelineStart = SCHEDULE_FUNC_GET_TIMELINE_START_POSITION(
                firstDate,
                itemActual,
                duration
            );

            config.actual.start = timelineStart;
            config.actual.date = itemActual;
        }

        // -------- CALCULATE TODAY ---------

        // GET TODAY START
        let timeline = SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH(
            firstDate,
            itemActual ? itemActual : itemForecast,
            today,
            duration
        );
        config.today.start = timeline.start;
        config.today.width = timeline.width;
        config.today.date = today;

        // IF STATUS DONE AND TODAY IS OUTSIDE TIMELINE, REMOVE TODAY
        if (config.today.start + config.today.width > 100) {
            config.today.start = null;
        }

        // IF B=F=A, REMOVE TODAY
        if (duration === 0) {
            config.today.start = null;
        }

        // ------------- FIND TIMELINE LETTERS (B/F/A) ------------ //

        // GET TIMELINE LETTERS
        let letters = SCHEDULE_FUNC_GET_TIMELINE_LETTERS(itemBaseline, itemForecast, itemActual);

        // PUT THEM TO CONFIG
        config.baseline.letters = letters.baseline;
        config.forecast.letters = letters.forecast;
        config.actual.letters = letters.actual;

        // ---------- CHECK DATES/LETTERS DON'T STEPS ON EACH OTHERS -------------

        // CHECK EACH DATES
        config.baseline.start = SCHEDULE_FUNC_FIX_OVERLAPS_MILESTONE(config.baseline.start);
        config.forecast.start = SCHEDULE_FUNC_FIX_OVERLAPS_MILESTONE(config.forecast.start);
        if (config.actual.start !== null) {
            config.actual.start = SCHEDULE_FUNC_FIX_OVERLAPS_MILESTONE(config.actual.start);
        }

        // RETURN
        return config;
    }, [item.actualStart, item.baselineStart, item.forecastStart, item.status.value, today]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="text_Color2_500_10px"
            style={{
                width: "400px",
                height: "4px",
                borderRadius: "2px",
                backgroundColor: "var(--border-color-gray-0)",
                marginTop: "-4px",
                position: "relative",
            }}
        >
            {/* BASELINE */}
            {timelineConfig.baseline.start !== null && timelineConfig.baseline.letters !== null ? (
                <div
                    className="scheduleTimelineMilestone"
                    style={{
                        marginLeft: timelineConfig.baseline.start + "%",
                        color:
                            timelineConfig.baseline.date === today
                                ? "var(--color-migso-yeallow)"
                                : null,
                    }}
                >
                    {/* SQUARE */}
                    <div
                        className="scheduleTimelineMilestoneSquare flexCenterCenter"
                        style={{
                            backgroundColor:
                                item.status.value === SCHEDULE_STATUS_KEYS.done &&
                                timelineConfig.duration === 0
                                    ? "var(--color-migso-green)"
                                    : SCHEDULE_TIMELINE_COLORS.baseline,
                            // border: timelineConfig.baseline.letters.length > 1 ? "none" : null,\
                            width: timelineConfig.baseline.letters.length > 1 ? "17px" : "",
                            height: timelineConfig.baseline.letters.length > 1 ? "17px" : "",
                        }}
                    >
                        {/* LETTER */}
                        <div className="scheduleTimelineMilestoneLetter">
                            {timelineConfig.baseline.letters}
                        </div>
                    </div>

                    {/* DATE */}
                    {timelineConfig.baseline.letters ? (
                        <div className="scheduleTimelineMilestoneDate">
                            {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(timelineConfig.baseline.date)}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* FORECAST */}
            {timelineConfig.forecast.start !== null && timelineConfig.forecast.letters !== null ? (
                <div
                    className="scheduleTimelineMilestone"
                    style={{
                        marginLeft: timelineConfig.forecast.start + "%",
                        color:
                            timelineConfig.forecast.date === today
                                ? "var(--color-migso-yeallow)"
                                : null,
                    }}
                >
                    {/* SQUARE */}
                    <div
                        className="scheduleTimelineMilestoneSquare flexCenterCenter"
                        style={{
                            backgroundColor: timelineConfig.forecast.letters.length > 1 ?  'var(--color-migso-green)' : 'var(--color-text-4)',
                            width: timelineConfig.forecast.letters.length > 1 ? "17px" : "",
                            height: timelineConfig.forecast.letters.length > 1 ? "17px" : "",
                        }}
                    >
                        {/* LETTER */}
                        <div className="scheduleTimelineMilestoneLetter"
                            style={{
                                color: timelineConfig.forecast.date === today ? 'var(--color-migso-yeallow)' : '',
                            }}
                        >
                            {timelineConfig.forecast.letters}
                        </div>
                    </div>

                    {/* DATE */}
                    {timelineConfig.forecast.letters ? (
                        <div className="scheduleTimelineMilestoneDate">
                            {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(timelineConfig.forecast.date)}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* ACTUAL */}
            {timelineConfig.actual.start !== null && timelineConfig.actual.letters !== null ? (
                <div
                    className="scheduleTimelineMilestone"
                    style={{
                        marginLeft: timelineConfig.actual.start + "%",
                        color:
                            timelineConfig.actual.date === today
                                ? "var(--color-migso-yeallow)"
                                : null,
                    }}
                >
                    {/* SQUARE */}
                    <div
                        className="scheduleTimelineMilestoneSquare flexCenterCenter"
                        style={{
                            backgroundColor:
                                item.status.value === SCHEDULE_STATUS_KEYS.done
                                    ? "var(--color-migso-green)"
                                    : SCHEDULE_TIMELINE_COLORS.actual,
                        }}
                    >
                        {/* LETTER */}
                        <div className="scheduleTimelineMilestoneLetter">
                            {timelineConfig.actual.letters}
                        </div>
                    </div>

                    {/* DATE */}
                    {timelineConfig.actual.letters ? (
                        <div className="scheduleTimelineMilestoneDate">
                            {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(timelineConfig.actual.date)}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* TODAY DOT */}
            {timelineConfig.today.start !== null && 
                timelineConfig.forecast.date !== today &&
                timelineConfig.actual.date !== today &&
                timelineConfig.baseline.date !== today
            
                ? (
                <div className="flexColStartCenter"
                
                    style={{
                        position: "absolute",
                            marginLeft:
                                timelineConfig.today.start + timelineConfig.today.width - 1 + "%",
                    }}
                >
                    {/* DOT */}
                    <div
                        style={{
                            marginTop: "-3px",
                            height: "10px",
                            width: "10px",
                            borderRadius: "5px",
                            zIndex: 1000,
                            // backgroundColor:
                            //     item.status.value === SCHEDULE_STATUS_KEYS.inThePast
                            //         ? "var(--color-bad)"
                            //         : "var(--color-migso-yeallow)",
                            backgroundColor: 'var(--color-migso-yeallow)'
                        }}
                    >
                    </div>

                    {/* TODAY TXT */}
                    <div
                        className="scheduleTimelineTaskDate"
                        style={{
                            marginTop: '13px',
                            color: 'var(--color-migso-yeallow)'
                        }}
                    >   
                        {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(timelineConfig.today.date)}
                    </div>
                </div>
                
            ) : null}

            {/* TODAY LINE */}
            {
            timelineConfig.today.start !== null ? (
                <div
                    style={{
                        position: "absolute",
                        width: timelineConfig.today.width + 1 + "%",
                        height: "4px",
                        borderRadius: "2px",
                        backgroundColor:
                            item.status.value === SCHEDULE_STATUS_KEYS.inThePast
                                ? "var(--color-bad)"
                                : "",
                        marginTop: "0px",
                        marginLeft: timelineConfig.today.start + "%",
                    }}
                ></div>
            ) : null
            }
        </div>
    );
}
