///////////////////
/// RISK OPP REDUCER ///
///////////////////

import { RISK_OPP_EXPAND_MODES } from "./RiskConstants";

export const riskOppReducer = (state, action) => {
    // REUSABLE VARIABLES
    let newState;
    let attributes = [];

    switch (action.type) {
        //UPDATE SEVERAL IN ONE TIME -----------------------------------------------
        case "SET_STATE_OBJECT":
            //ACTION object

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributes = Object.keys(action.object);

            //GET ALL PREVIOUS STATE
            newState = { ...state };

            //UPDATE STATE FROM OBJECT
            for (let i = 0; i < attributes.length; i++) {
                newState[attributes[i]] = action.object[attributes[i]];
            }

            //RETURN
            return {
                ...newState,
            };

        // UPDATE FROM KEY AND VALUE -----------------------------------------------
        case "SET_STATE_KEY_VALUE":
            //action , key value

            //RETURN
            return {
                ...state,
                [action.key]: action.value,
            };

        // SELECT ACTION -----------------------------------------------------------
        case "SET_RISKOPP_EXPAND_MODE":
            //widthSizeLimit

            //INIT
            let expandMode;

            //window <= 1200
            if (action.widthSizeLimit) {
                if (state.expandMode === RISK_OPP_EXPAND_MODES.matrixOnly) {
                    expandMode = RISK_OPP_EXPAND_MODES.matrixOnly;
                } else {
                    expandMode = RISK_OPP_EXPAND_MODES.tableOnly;
                }

                //window > 1200
            } else {
                expandMode = RISK_OPP_EXPAND_MODES.both;
            }

            //RETURN
            return {
                ...state,
                widthSizeLimit: action.widthSizeLimit,
                expandMode: expandMode,
            };

        default:
            console.log(`${action.type} not in the ActionsDispatcher :(`);
            return { ...state };
    }
};

export default riskOppReducer;
