import { useMemo, useState } from "react";
import { FUNCT_FIND_INDEX } from "../../../00-Core/Standards";
import BoardLoader from "./BoardLoader";

export default function BoardLastActivities({ lastActivities, portfolios, projects, openWbsItem }) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // LOADER
    const [loaderId, setLoaderId] = useState(null);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // BUILD LAST ACTIVITIES STRINGS
    const lastActivitiesObj = useMemo(() => {
        if (lastActivities) {
            return lastActivities.map((activity) => {
                let findIndex;
                let data;
                let activityString;

                switch (activity.type) {
                    // ACTIONS
                    case "action":
                        activityString = "Action " + activity.data.displayId;
                        break;

                    // PORTFOLIOS
                    case "portfolio":
                        // GET PORTFOLIO NAME
                        findIndex = FUNCT_FIND_INDEX(portfolios, "id", activity.data.id);
                        if (findIndex === -1) {
                            return null;
                        }

                        data = portfolios[findIndex];

                        activityString = "Portfolio " + data.name;
                        break;

                    // PROJECTS
                    case "project":
                        // GET PORTFOLIO NAME
                        findIndex = FUNCT_FIND_INDEX(projects, "id", activity.data.id);
                        if (findIndex === -1) {
                            return null;
                        }

                        data = projects[findIndex];

                        activityString = "Project " + data.name;
                        break;

                    case "Organization":
                        activityString = "Organization " + activity.data.name;
                        break;

                    // REVIEW
                    case "govReview":
                        activityString = "Review " + activity.data.name;
                        break;

                    default:
                        return null;
                }

                return {
                    string: activityString,
                    item: activity.data,
                    type: activity.type,
                    id: activity.id,
                };
            });
        }

        return null;
    }, [lastActivities, portfolios, projects]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="structureBlock boardSectionStructureBlock"
            style={{
                width: "200px",
            }}
        >
            {/* TITLE */}
            <div className="structureBlockTitle flexBetweenCenter">
                <div className="flexStartCenter">
                    <span className="material-icons structureBlockTitleIcon boardTitleIcon">
                        switch_access_shortcut
                    </span>
                    Last Activities
                </div>
            </div>

            {/* CONTENT */}
            <div
                className="structureBlockInfo flexColStartCenter"
                style={{
                    paddingTop: "10px",
                    gap: "10px",
                }}
            >
                {/* MAP EACH ACTIVITIES */}
                {lastActivitiesObj && lastActivitiesObj.length > 0 ? (
                    lastActivitiesObj.map((activity, index) => {
                        if (activity) {
                            return (
                                <div
                                    key={"last_activity_" + index}
                                    className="flexStartCenter"
                                    style={{
                                        position: "relative",
                                        color: "var(--color-migso-blue)",
                                        border: "1px solid var(--color-migso-blue)",
                                        borderRadius: "5px",
                                        width: "160px",
                                        cursor: "pointer",
                                        paddingLeft: "5px",
                                        flex: 1,
                                    }}
                                    onClick={() => {
                                        // ACTIVATE LOADER
                                        setLoaderId(activity.id);

                                        // OPEN ITEM
                                        openWbsItem(activity.type, activity.item);
                                    }}
                                >
                                    {/* LOADER */}
                                    {loaderId === activity.id ? <BoardLoader /> : null}

                                    {/* ACTIVITY TITLE */}
                                    <span className="textEllipsis">{activity.string}</span>
                                </div>
                            );
                        }

                        return null;
                    })
                ) : (
                    <span>Nothing for now !</span>
                )}
            </div>
        </div>
    );
}
