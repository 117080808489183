import React, { memo, useCallback, useMemo } from "react";

// CORE
import _ from "lodash";
import { GOV_COMPONENT_EXPAND_TYPES } from "../../00-Helpers/GovConstants";
import { Button, CvtButton } from "@mi-gso/cvt";
import GovScopeTableItem from "./GovScopeTableItem";
import GovScopeTableSearch from "./GovScopeTableSearch";
import GovScopeTableLegend from "./GovScopeTableLegend";
import { TooltipProvider, Tooltip } from "react-tooltip";

//////////////////////////////
/// ACTION TABLE COMPONENT ///
//////////////////////////////

export default memo(function GovScopeTable({
  //DATA
  filteredGovScopes,
  govScopeChanges,
  levelFilters,
  statusFilters,
  selectLevelOptions,
  selectStatusOptions,
  textSearchInput,
  selectedItems,
  expandMode,
  isReadOnlyMode,
  wbsId,
  isCurrentUserEditor,

  //USERS
  currentUser,
  usersList,
  megaUsersIds,

  //FUNCTIONS
  onClickAdd,
  onItemClick,
  onExpandArrowClick,

  //DISPATCHER
  govDispatcher,
}) {
  //////////////////////////////////////////////////////////////
  /// DATA /////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  // SORT GOV SCOPES
  const sortedGovScopes = useMemo(() => {
    //SORT DATA
    let actionsSorted = filteredGovScopes.sort(function (a, b) {
      if (a.sort < b.sort) {
        return -1;
      }
      if (a.sort > b.sort) {
        return 1;
      }
      return 0;
    });

    //RETURN
    return actionsSorted;
  }, [filteredGovScopes]);

  //////////////////////////////////////////////////////////////
  /// FUNCTION /////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  // HANDLE SELECT CHECKBOX
  const handleSelectCheckbox = useCallback(
    (e) => {
      e.stopPropagation();

      let newSelectedItems = [];

      //IF NOTHING SELECT ALL
      if (selectedItems.length === 0) {
        newSelectedItems = _.cloneDeep(filteredGovScopes);
      }

      // USE ACTIONS DISPATCHER
      govDispatcher({
        type: "SET_STATE_OBJECT",
        object: {
          selectedItems: newSelectedItems,
          isConfirmDelete: false,
        },
      });
    },
    [filteredGovScopes, govDispatcher, selectedItems.length]
  );

  //////////////////////////////////////////////////////////////
  /// RENDER ///////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  return (
    <TooltipProvider>
      {/* SCOPE TABLE */}
      <div
        className={`noAnimations actionTableContainer`}
        style={{
          height: "100%",
          width:
            expandMode === GOV_COMPONENT_EXPAND_TYPES.table
              ? "100%"
              : expandMode === GOV_COMPONENT_EXPAND_TYPES.calendar
              ? "0%"
              : "50%",
          margin:
            expandMode === GOV_COMPONENT_EXPAND_TYPES.table
              ? "0px 20px"
              : "0px 10px 0px 20px",
        }}
      >
        <div className="actionTableContainerHeader">
          <div
            style={{
              color: "var(--color-text-2)",
              fontWeight: "600",
              fontSize: "14px",
              marginLeft: "20px",
              // marginBottom: "10px",
            }}
          >
            Scope changes
          </div>

          {/* EXPAND ARROW */}
          <Button
            icon={
              expandMode === GOV_COMPONENT_EXPAND_TYPES.table
                ? "chevron_left"
                : "chevron_right"
            }
            css="btn commonButtonSubmitLight leftComponentExpandButton"
            borderRadius="20px"
            iconFontSize="16px"
            handleSubmit={() =>
              onExpandArrowClick(GOV_COMPONENT_EXPAND_TYPES.table)
            }
          />

          {govScopeChanges.length > 0 ? (
            <div className="flexBetweenCenter">
              {/* SELECT - LEGEND */}
              <div className="flexStartCenter">
                {/* SELECT BOX */}
                {!isReadOnlyMode && isCurrentUserEditor ? (
                  <div
                    className={
                      "flexCenterCenter actionTableSelectBox " +
                      (selectedItems.length > 0
                        ? selectedItems.length === filteredGovScopes.length
                          ? "actionTableSelectBoxActive"
                          : "actionTableSelectBoxMiActive"
                        : "")
                    }
                    onClick={handleSelectCheckbox}
                    style={{
                      minWidth: "16px",
                      minHeight: "16px",
                      maxWidth: "16px",
                      maxHeight: "16px",
                      margin: "0px 5px 0px 12px",
                    }}
                  >
                    {selectedItems.length > 0 ? selectedItems.length : ""}
                  </div>
                ) : null}

                {/* STATUS TAGS */}
                <GovScopeTableLegend
                  levelFilters={levelFilters}
                  statusFilters={statusFilters}
                  selectLevelOptions={selectLevelOptions}
                  selectStatusOptions={selectStatusOptions}
                  isReadOnlyMode={isReadOnlyMode}
                  govDispatcher={govDispatcher}
                />
              </div>

              {/* SEARCH BAR */}
              <GovScopeTableSearch
                textSearchInput={textSearchInput}
                total={filteredGovScopes.length}
                govDispatcher={govDispatcher}
              />
            </div>
          ) : null}
        </div>

        {/* LIST AND CREATE BUTTON */}
        <div
          style={{
            height: "calc(100% - 35px)",
            width: "100%",
          }}
        >
          {/* GOV SCOPE LIST */}
          <div
            id="actionsTable"
            className="actionTable"
            style={{
              maxHeight: isReadOnlyMode ? "calc(100% - 57px)" : null,
            }}
          >
            {sortedGovScopes.map((scope) => {
              //IF ITEM SELECTED
              let isSelected =
                selectedItems.find((selected) => selected.id === scope.id) !==
                undefined;

              //IS A CHILD
              let isChild = scope.wbsId !== wbsId;

              //RETURN
              return (
                <GovScopeTableItem
                  key={"govScopeTableItem-" + scope.id}
                  scopeItem={scope}
                  isSelected={isSelected}
                  selectedItems={selectedItems}
                  usersList={usersList}
                  megaUsersIds={megaUsersIds}
                  currentUser={currentUser}
                  isChild={isChild}
                  onItemClicked={onItemClick}
                  isReadOnlyMode={isReadOnlyMode}
                  govDispatcher={govDispatcher}
                  isCurrentUserEditor={isCurrentUserEditor}
                />
              );
            })}
          </div>

          {/* CREATE BUTTON - FOOTER */}
          <div className="actionTableCreateButton flexStartCenter">
            {!isReadOnlyMode ? (
              <CvtButton
                color="white"
                shape="noShape"
                size="small"
                icon="add"
                fullWidth
                margin="5px 0px 0px"
                txt={`Create ${govScopeChanges.length === 0 ? "your first" : "a"} scope change`}
                handleSubmit={onClickAdd}
              />
            ) : null}
          </div>
        </div>
      </div>

      {/* TOOLTIP */}
      <Tooltip id="gov-table-tooltip" className="tooltipCommon" />
    </TooltipProvider>
  );
});
