//GRAPHQL
import {
    listTelescopeDataCharters as ListTelescopeDataCharters,
    listTelescopeDataGovScopeChanges as ListTelescopeDataGovScopeChanges,
    listTelescopeDataShortcuts as ListTelescopeDataShortcuts,
    listTelescopeDataGovReviews as ListTelescopeDataGovReviews,
    listTelescopeDataSchedules as ListTelescopeDataSchedules,
    charterByProjectId as CharterByProjectId,
    scopeChangeByProjectId as ScopeChangeByProjectId,
    govReviewByProjectId as GovReviewByProjectId,
    shortcutByProjectId as ShortcutByProjectId,
    actionByProjectId as ActionByProjectId,
    scheduleByProjectId as ScheduleByProjectId,
    rOByProjectId as ROByProjectId,
} from "../../../../graphql/queries";
import {
    listTelescopeDataActions as ListTelescopeDataActions,
    listTelescopeDataROS as ListTelescopeDataROS,
} from "../../../../00-Core/01-GraphqlCustom/QueriesCustom";

//DOMAIN QUERIES QUERIES
export const WBS_DATA_QUERIES = [
    {
        id: "charter",
        query: ListTelescopeDataCharters,
        queryName: "listTelescopeDataCharters",
        dateFields: ["endDate", "startDate"],
    },
    {
        id: "govScopeChange",
        query: ListTelescopeDataGovScopeChanges,
        queryName: "listTelescopeDataGovScopeChanges",
        dateFields: ["dueDate, closedDate"],
        notLinkedToDomain: true,
    },
    {
        id: "govReview",
        query: ListTelescopeDataGovReviews,
        queryName: "listTelescopeDataGovReviews",
        dateFields: ["endDate", "startDate"],
        notLinkedToDomain: true,
    },
    { id: "shortcut", query: ListTelescopeDataShortcuts, queryName: "listTelescopeDataShortcuts" },
    { id: "action", query: ListTelescopeDataActions, queryName: "listTelescopeDataActions" },
    { id: "riskOpp", query: ListTelescopeDataROS, queryName: "listTelescopeDataROS" },
    {
        id: "schedule",
        query: ListTelescopeDataSchedules,
        queryName: "listTelescopeDataSchedules",
        dateFields: [
            "baselineStart",
            "baselineFinish",
            "forecastStart",
            "forecastFinish",
            "actualStart",
            "actualFinish",
        ],
    },
];

//DOMAIN QUERIES QUERIES
export const WBS_DATA_QUERIES_NEW_WAY = [
    {
        id: "charter",
        query: CharterByProjectId,
        queryName: "charterByProjectId",
        dateFields: ["endDate", "startDate"],
    },
    {
        id: "govScopeChange",
        query: ScopeChangeByProjectId,
        queryName: "scopeChangeByProjectId",
        dateFields: ["dueDate, closedDate"],
        notLinkedToDomain: true,
    },
    {
        id: "govReview",
        query: GovReviewByProjectId,
        queryName: "govReviewByProjectId",
        dateFields: ["endDate", "startDate"],
        notLinkedToDomain: true,
    },
    { id: "shortcut", query: ShortcutByProjectId, queryName: "shortcutByProjectId" },
    { id: "action", query: ActionByProjectId, queryName: "actionByProjectId" },
    { id: "riskOpp", query: ROByProjectId, queryName: "ROByProjectId" },
    {
        id: "schedule",
        query: ScheduleByProjectId,
        queryName: "scheduleByProjectId",
        dateFields: [
            "baselineStart",
            "baselineFinish",
            "forecastStart",
            "forecastFinish",
            "actualStart",
            "actualFinish",
        ],
    },
];

//DOMAIN DETAILS
export const WBS_DOMAIN_DETAILS = [
    {
        id: "charter",
        name: "Project",
        icon: "control_camera",
        enabled: true,
        subtitle: "to be defined",
        filter: true,
    },
    {
        id: "owner",
        name: "People",
        icon: "person",
        enabled: false,
        filter: true,
    },
    {
        id: "deadline",
        name: "Dates",
        icon: "date_range",
        enabled: false,
        filter: true,
    },

    {
        id: "shortcut",
        name: "Shortcuts",
        icon: "link",
        enabled: true,
        subtitle: "to be defined",
        filter: false,
    },
    {
        id: "governance",
        name: "Governance",
        icon: "event",
        enabled: true,
        subtitle: "Number of Reviews (sum) and alerts",
        displayInColumn: true,
        filter: true,
    },
    {
        id: "action",
        name: "Actions",
        icon: "alarm",
        enabled: true,
        subtitle: "In Progress and Late (sum)",
        displayInColumn: true,
        filter: true,
    },
    {
        id: "riskOpp",
        name: "Risks",
        icon: "fact_check",
        enabled: true,
        subtitle: "Escalated risk (sum)",
        displayInColumn: true,
        displayOpportunity: true,
        opportunityIndex: 7,
        opportunityName: "Opps",
        opportunityIcon: "fact_check",
        filter: true,
    },
    {
        id: "schedule",
        name: "Schedules",
        icon: "flag",
        enabled: true,
        subtitle: "S-Curve",
        displayInColumn: true,
        filter: true,
    },
];

export const WBS_BIG_COMPONENT_VIEW_MODE = {
    add: "add",
    edit: "edit",
    view: "view",
    list: "list",
};

export const WBS_RIGHT_COMPONENT = {
    govReview: "govReview",
};

// DEFAULT MAP DATA ACCESS REFERENCES OBJECT
export const DEFAULT_MAP_DATA_ACCESS_REFERENCES = {
    risk: null,
    action: null,
    schedule: null,
    cost: null,
};

// DEFAULT REFERENCE LOADER
export const DEFAULT_REFERENCE_LOADER = {
    risk: false,
    action: false,
    schedule: false,
    cost: false,
};

// ITEMS LINKS NAMES
export const WBS_ITEMS_LINKS = {
    govReview: {
        action: "links",
        govScopeChange: "govReviewIds",
        riskOpp: "telescopeDataGovernanceTelescopeDataRoIds",
    },
};

// ICONS OF EACH DOMAINS BASED ON DATA TABLE NAME
export const WBS_DATA_ICON = {
    govReview: "event",
    govScopeChange: "free_cancellation",
    action: "alarm",
    risk: "fact_check",
    opp: "flag",
    schedule: "flag",
};

// INITIAL WBS NAVIGATION OPTIONS OBJECT
export const WBS_DEFAULT_NAVIGATION_OPTIONS = {
    // WHERE WE WANT TO GO
    goToItemId: null,
    goToDomain: null,
    // overrideWbsId: null,

    // WHERE WE COME FROM
    originItemId: null,
    originDomain: null,

    // SPECIFIC OPTIONS
    options: null,
};

// WBS INITIAL STATE
export const WBS_INITIAL_STATE = {
    //CORE
    organizationId: "",
    projectId: "",
    wbsList: [],

    //SELECTED
    selectedWbsId: null,
    selectedField: null,
    selectedItem: null,
    wbsNavigationOptions: null,
    temporarySelectedWbsId: null,
    temporarySelectedField: null,

    isChanged: false,
    selectedBranchWbsId: null,
    wbsLoader: true,
    isShortcutBigOpen: false,

    //FULL SCREEN
    isFullScreen: false,

    // MAP DATA ACCESS REFERENCES
    mapDataAccessReferences: DEFAULT_MAP_DATA_ACCESS_REFERENCES,

    // REFERENCE SAVE LOADER
    referenceSavingLoader: DEFAULT_REFERENCE_LOADER,
};
