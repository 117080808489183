import { CvtPillKPI, CvtTooltip } from "@mi-gso/cvt";
import React, { useMemo, memo, useCallback } from "react";

// CORE
import { FUNC_ACTION_IS_LATE } from "../01-Action/00-Helpers/ActionsFunctions";
import {
  RISK_OPP_CRITICITY_LIST,
  RISK_OPP_CRITICITY_TXT_CONVERTION,
} from "./00-Helpers/RiskConstants";
import _ from "lodash";

///////////////////////////////////////////
/// RISK MINI COMPONENT ///////////////////
///////////////////////////////////////////

export default memo(function RiskMini({
  roData,
  actionData,
  propKey,
  projectItemId,
  wbsDispatcher,
  wbsId,
  selectedWbsId,
  bigComponentDisplayed,
  isRisk,
  cyId
}) {

  // GETS ACTIONS AND LATE ACTIONS COUNTS FOR A SPECIFIC PROPERTY IN THE DISPLAY COUNT OBJECT
  const getActionsCount = useCallback(
    (property, roItem, visitedActionsIds) => {
      let actionsProperty = `${property}Actions`;
      let lateActionsProperty = `${property}LateActions`;

      // property: 'veryHigh', 'high', 'medium'
      let object = {
        [actionsProperty]: 0,
        [lateActionsProperty]: 0,
        allActions: 0,
        allLateActions: 0,
        newVisitedActionsIds: _.cloneDeep(visitedActionsIds),
      };
      

      // HANDLE ACTIONS
      if (roItem.actionsIds && roItem.actionsIds.length > 0) {
        
       let riskActions = actionData.filter(item => roItem.actionsIds?.includes(item.id));
      // INCREMENT NUM OF ACTIONS RELATED TO THIS RO ITEM
          object[actionsProperty] += riskActions.length ;
          object.allActions += riskActions.length ;

      //  // INCREMENT NUM OF ACTIONS RELATED TO THIS RO ITEM
      //           object[actionsProperty] += riskActions.length;
      //           object.allActions += riskActions.length;
            
        // FOR EACH OF THE RELATED ACTIONS IDS, CHECK IF IT IS A LATE ACTION OR NOT
        for (let id of roItem.actionsIds) {

          // CHECK IF ALREADY COUNTED FOR
          const isAlreadyVisited = object.newVisitedActionsIds?.includes(id);

          if(!isAlreadyVisited) {
            
            // FIND ACTION ITEM IN actionsData ARRAY
            let actionItem = actionData.find((action) => action.id === id);

            if (actionItem && actionItem.status && actionItem.dueDate) {

              // ADD NEW VISITED ACTION ID
              object.newVisitedActionsIds.push(id);

              let isLate = FUNC_ACTION_IS_LATE(
                actionItem.status,
                actionItem.dueDate
              );

              if (isLate === true) {
                object[lateActionsProperty] += 1;
                object.allLateActions += 1;
                object.allActions += 1;
              }
              
            }
          }
        }
      }

      return object;
    },
    [actionData]
  );

  // GENERATE PARAGRAPH IN HTML FOR EACH CRITICITY LEVEL (critical, medium, minor....)
  const getTooltipParagraph = useCallback(
    (displayCountObj, criticity) => {
      return (isRisk === true && displayCountObj[`${criticity}Risk`] > 0) ||
        (isRisk === false && displayCountObj[`${criticity}Opportunity`] > 0) ? (
        <React.Fragment>
          {/* CRITICITY RO */}
          <span
            className="actionMiniTooltipTitle"
            style={{ marginTop: criticity !== "veryHigh" ? "0px" : "" }}
          >
            {RISK_OPP_CRITICITY_TXT_CONVERTION[criticity]}:{" "}
            <b>
              {isRisk === true
                ? displayCountObj[`${criticity}Risk`]
                : displayCountObj[`${criticity}Opportunity`]}
            </b>
          </span>

          {/* ACTIONS */}
          {displayCountObj[`${criticity}Actions`] > 0 ? (
            <span className="actionMiniTooltipSubTitle">
              Actions: {displayCountObj[`${criticity}Actions`]}
            </span>
          ) : null}

          {/* LATE ACTIONS */}
          {displayCountObj[`${criticity}LateActions`] > 0 ? (
            <span className="actionMiniTooltipSubtitle">
              Late Actions: {displayCountObj[`${criticity}LateActions`]}
            </span>
          ) : null}

          {/* ESCALATED */}
          {displayCountObj[
            `${criticity}${isRisk === true ? "Risk" : "Opportunity"}Escalated`
          ] > 0 ? (
            <span className="actionMiniTooltipSubTitle">
              Escalated:{" "}
              {
                displayCountObj[
                  `${criticity}${
                    isRisk === true ? "Risk" : "Opportunity"
                  }Escalated`
                ]
              }
            </span>
          ) : null}
        </React.Fragment>
      ) : null;
    },
    [isRisk]
  );

  /////////////
  /// COUNT ///
  /////////////
 
  //  This memo returns the object that is used to display
  // the calculated ro items in the small component tooltip
  const displayCount = useMemo(() => {
    // Creating the return object
    let returnRoDisplayCount =
      isRisk === true
        ? {
            risk: 0,
            veryHighRisk: 0,
            veryHighRiskEscalated: 0,
            highRisk: 0,
            highRiskEscalated: 0,
            mediumRisk: 0,
            mediumRiskEscalated: 0,
            lowRisk: 0,
            lowRiskEscalated: 0,
          }
        : {
            opportunity: 0,
            veryHighOpportunity: 0,
            veryHighOpportunityEscalated: 0,
            highOpportunity: 0,
            highOpportunityEscalated: 0,
            mediumOpportunity: 0,
            mediumOpportunityEscalated: 0,
            lowOpportunity: 0,
            lowOpportunityEscalated: 0,
          };

    // ADD COMMON ACTIONS ATTRIBUTES
    returnRoDisplayCount = {
      ...returnRoDisplayCount,

      // ALL ACTIONS CONNECTED
      allActions: 0,
      // ALL LATE ACTIONS CONNECTED
      allLateActions: 0,
      // VERY HIGH
      veryHighActions: 0,
      veryHighLateActions: 0,
      // HIGH
      highActions: 0,
      highLateActions: 0,
      // MEDIUM
      mediumActions: 0,
      mediumLateActions: 0,
      // LOW
      lowActions: 0,
      lowLateActions: 0,
    };

    //TEST IF DATA
    if (roData.length > 0) {
      let visitedActionsIds = [];
      roData.forEach((ro) => {
        // Display only Open RO items
        if (ro.status === "opened") {
          //INIT
          let isEscalated =
            ro.escalatedTo &&
            ro.escalatedTo.length > 0 &&
            ro.escalatedTo?.includes(projectItemId);
          let constantValue;

          // TAKE INTO ACCOUNT ALL RO ITEMS IN THE LEFT OF COMPONENT
          if (isRisk === true) {
            returnRoDisplayCount.risk += 1;
          } else {
            returnRoDisplayCount.opportunity += 1;
          }

          //GET CRITICITY
          RISK_OPP_CRITICITY_LIST.forEach((item) => {
            if (
              ro.currentCriticity >= item.min &&
              ro.currentCriticity <= item.max
            ) {
              constantValue = item.value;
            }
          });

          let actions;
        
          //SWITCH CRITICITY
          switch (constantValue) {
            // Very high
            case RISK_OPP_CRITICITY_LIST[3].value:
              if (isRisk === true) {
                returnRoDisplayCount.veryHighRisk++;
                if (isEscalated) returnRoDisplayCount.veryHighRiskEscalated++;
              } else {
                returnRoDisplayCount.veryHighOpportunity++;
                if (isEscalated)
                  returnRoDisplayCount.VeryHighOpportunityEscalated++;
              }

              // GET ACTION COUNT
              actions = getActionsCount("veryHigh", ro, visitedActionsIds);

              visitedActionsIds = actions.newVisitedActionsIds;

              // CHECK FOR ACTIONS RELATED
              returnRoDisplayCount = {
                ...returnRoDisplayCount,
                veryHighActions:
                  returnRoDisplayCount.veryHighActions +
                  actions.veryHighActions,
                veryHighLateActions:
                  returnRoDisplayCount.veryHighLateActions +
                  actions.veryHighLateActions,
                allActions:
                  returnRoDisplayCount.allActions + actions.allActions,
                allLateActions:
                  returnRoDisplayCount.allLateActions + actions.allLateActions,
              };

              break;

            // High
            case RISK_OPP_CRITICITY_LIST[2].value:
              if (isRisk === true) {
                returnRoDisplayCount.highRisk++;
                if (isEscalated) returnRoDisplayCount.highRiskEscalated++;
              } else {
                returnRoDisplayCount.highOpportunity++;
                if (isEscalated)
                  returnRoDisplayCount.highOpportunityEscalated++;
              }

              // ACTIONS
              actions = getActionsCount("high", ro, visitedActionsIds);

              visitedActionsIds = actions.newVisitedActionsIds;

              returnRoDisplayCount = {
                ...returnRoDisplayCount,
                highActions:
                  returnRoDisplayCount.highActions + actions.highActions,
                highLateActions:
                  returnRoDisplayCount.highLateActions +
                  actions.highLateActions,
                allActions:
                  returnRoDisplayCount.allActions + actions.allActions,
                allLateActions:
                  returnRoDisplayCount.allLateActions + actions.allLateActions,
              };

              break;

            // Medium
            case RISK_OPP_CRITICITY_LIST[1].value:
              if (isRisk === true) {
                returnRoDisplayCount.mediumRisk++;
                if (isEscalated) returnRoDisplayCount.mediumRiskEscalated++;
              } else {
                returnRoDisplayCount.mediumOpportunity++;
                if (isEscalated)
                  returnRoDisplayCount.mediumOpportunityEscalated++;
              }

              // ACTIONS
              actions = getActionsCount("medium", ro, visitedActionsIds);

              visitedActionsIds = actions.newVisitedActionsIds;

              returnRoDisplayCount = {
                ...returnRoDisplayCount,
                mediumActions:
                  returnRoDisplayCount.mediumActions + actions.mediumActions,
                mediumLateActions:
                  returnRoDisplayCount.mediumLateActions +
                  actions.mediumLateActions,
                allActions:
                  returnRoDisplayCount.allActions + actions.allActions,
                allLateActions:
                  returnRoDisplayCount.allLateActions + actions.allLateActions,
              };

              break;

            // Low
            case RISK_OPP_CRITICITY_LIST[0].value:
              if (isRisk === true) {
                returnRoDisplayCount.lowRisk++;
                if (isEscalated) returnRoDisplayCount.lowRiskEscalated++;
              } else {
                returnRoDisplayCount.lowOpportunity++;
                if (isEscalated) returnRoDisplayCount.lowOpportunityEscalated++;
              }

              actions = getActionsCount("low", ro, visitedActionsIds);
              
              visitedActionsIds = actions.newVisitedActionsIds;

              returnRoDisplayCount = {
                ...returnRoDisplayCount,
                lowActions:
                  returnRoDisplayCount.lowActions + actions.lowActions,
                lowLateActions:
                  returnRoDisplayCount.lowLateActions + actions.lowLateActions,
                allActions:
                  returnRoDisplayCount.allActions + actions.allActions,
                allLateActions:
                  returnRoDisplayCount.allLateActions + actions.allLateActions,
              };

              break;
            default:
              break;
          }
        }
      });
    }

    //RETURN
    return returnRoDisplayCount;
  }, [isRisk, roData, projectItemId, getActionsCount]);

  // DATA EXISTS
  const dataExists = useMemo(() => {
    return isRisk === true
      ? displayCount.risk !== 0
      : displayCount.opportunity !== 0;
  }, [displayCount.opportunity, displayCount.risk, isRisk]);

  ///////////////
  /// TOOLTIP ///
  ///////////////

  const tooltipContent = useMemo(() => {
    return (
      <div className="flexColStartStart">
        {/* CRITICAL */}
        {getTooltipParagraph(displayCount, "veryHigh")}

        {/* IMPORTANT */}
        {getTooltipParagraph(displayCount, "high")}

        {/* MODERATE */}
        {getTooltipParagraph(displayCount, "medium")}

        {/* MINOR */}
        {getTooltipParagraph(displayCount, "low")}
      </div>
    );
  }, [displayCount, getTooltipParagraph]);

  /////////////////
  /// FUNCTIONS ///
  /////////////////

  //////////////////////////////////////////////////
  //____________________RENDER _____________________
  //////////////////////////////////////////////////
  
  return (
    <React.Fragment key={`${propKey}`}>
      <CvtPillKPI
        values={[
          {
            value: isRisk
              ? displayCount.risk > 0
                ? displayCount.risk
                : ""
              : displayCount.opportunity > 0
              ? displayCount.opportunity
              : "",
          },
          {
            value:
              displayCount.allLateActions > 0
                ? displayCount.allLateActions
                : displayCount.allActions > 0
                ? displayCount.allActions
                : "",
            color:
              displayCount.allLateActions > 0
                ? "var(--color-bad)"
                : displayCount.allActions > 0
                ? "var(--color-good)"
                : "var(--border-color-gray-0)",
          },
        ]}
        tooltipId={`${
          isRisk === true ? "risk" : "opportunity"
        }-small-${propKey}-${projectItemId}`}
        testId={cyId}
      />

      {/* TOOLTIP */}
      {dataExists ?
      <CvtTooltip
        anchorId={`${isRisk ? "risk" : "opportunity"}-small-${propKey}-${projectItemId}`}
        place="left"
      >
        {tooltipContent}
      </CvtTooltip>
      :null}
    </React.Fragment>
  );
});
