// REACT
import { useCallback, memo } from "react";

// PACKAGES
import _ from "lodash";

// CORE COMPONENTS


// COMPONENTS
import ActionTableItem from "../../../../01-Action/01-Components/ActionTable/ActionTableItem";
import WbsNoItem from "../../../../00-Wbs/01-Components/WbsNoItem";

// CONSTANTS
import ActionTableLegend from "../../../../01-Action/01-Components/ActionTable/ActionTableLegend";
import RiskCardActionsTableHeaders from "./RiskCardActionsTableHeaders";
import { CvtButton, CvtSearchBar } from "@mi-gso/cvt";

////////////////////////////////////
/// RISK CARD ACTIONS TABLE ////////
////////////////////////////////////

export default memo(
  function RiskCardActionsTable({
    // FILTERED ACTIONS DATA
    actionsData,
    // RO ITEM
    roItem,
    // USERS LIST
    usersList,
    megaUsersId,
    isFlexOne,
    width,
    isCurrentUserEditor,

    // ON CREATE ACTION CLICKED
    handleCreateAction,
    // HANDLE EDIT OF RO ITEM (FOR STRATEGY)
    handleEditRoItem,
    // CURRENT USER
    currentUser,
    backgroundColor,
    headersBackground,
    border,
    headerBottomBorder,
    maxHeight,
    tableBorderBottom,
    riskOppDispatcher,
    // SELECTED ITEMS ARRAY
    selectedItems,
    legendAndCounter,
    statusSearchInput,
    textSearchInput,
    canModifyRo,

    handleEditAction,
    handleDuplicateClick,
    handleDeleteClick,
    handleRoSelectCheckbox,
    canEditActions,
    handleMoveItemsClicked,
  }) {

    //////////////////////////////////////////////////////////////////////////
    /// FUNCTIONS /////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    // HANDLE SELECT/UNSELECT OF ALL ACTIONS
    const handleSelectAllCheckbox = useCallback((e) => {

      e.stopPropagation();

      // INIT
      let newSelectedList = [];

      // IF NOTHING SELECT ALL
      if(selectedItems.length === 0) {
        newSelectedList = _.cloneDeep(actionsData);
      }

      // UPDATE R&O STATE
      riskOppDispatcher({
        type: "SET_STATE_KEY_VALUE",
        key: 'selected',
        value: newSelectedList,
      });

    }, [actionsData, riskOppDispatcher, selectedItems.length]);

    // HANDLE RESET OF SEARCH TEXT INPUT
    const handleResetTextInput = useCallback((e) => {
      riskOppDispatcher({
        type: "SET_STATE_KEY_VALUE",
        key: "actionsTextSearchInput",
        value: '',
      })
    }, [riskOppDispatcher]);

    // HANDLE CHANGE IN TEXT SEARCH INPUT
    const handleChangeTextInput = useCallback((e) => {
      riskOppDispatcher({
        type: "SET_STATE_KEY_VALUE",
        key: e.target.name,
        value: e.target.value,
      });
    }, [riskOppDispatcher]);



    //////////////////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    return (
      <div
        className={"riskBigViewModeRelatedActionTableContainer flexColStartCenter" + (isFlexOne && isFlexOne === true ? " flexOne" : "")}
        style={{
          backgroundColor: backgroundColor ? backgroundColor : null,
          border: border ? border : "",
          width: width ? width : '30%',
          overflowY: 'clip',
        }}
      >

        {/* HEADERS */}
        <RiskCardActionsTableHeaders
          // DATA
          selectedItems={selectedItems}
          headersBackground={headersBackground}
          headerBottomBorder={headerBottomBorder}
          roItem={roItem}
          filteredActionsLength={actionsData.length}
          currentUserId={currentUser.username}

          // FUNCTIONS
          handleEditAction={handleEditAction}
          handleDuplicateClick={handleDuplicateClick}
          handleDeleteClick={handleDeleteClick}
          handleEditRoItem={handleEditRoItem}
          handleMoveItemsClicked={handleMoveItemsClicked}

          isCurrentUserEditor={isCurrentUserEditor}
          
        />


        {/* SELECT ALL BOX + TAGS FILTERS + SEARCH */}

        {
          actionsData.length > 0 || (textSearchInput.length > 0 || statusSearchInput.length > 0)
          ?

          <div className="flexBetweenCenter" style={{width: '100%', paddingRight: '22px', paddingLeft:'6px', marginTop:'5px'}}>

          {/* SELECT - LEGEND */}
           <div className="flexStartCenter">

                {/* SELECT BOX */}
                {actionsData.length > 0 && (canEditActions) ?
                  <div
                  className={"flexCenterCenter actionTableSelectBox " + (selectedItems.length === actionsData.length ? "actionTableSelectBoxActive" : selectedItems.length > 0 ? "actionTableSelectBoxMiActive" : "")}
                  onClick={handleSelectAllCheckbox}
                  style={{
                    minWidth: "16px",
                    minHeight: "16px",
                    maxWidth: "16px",
                    maxHeight: "16px",
                    margin:"0px 5px 0px 12px"
                  }}
                  >{selectedItems.length > 0 ? selectedItems.length : ""}
                  </div>
                :null}


                {/* LEGEND STATUS TAGS */}
                <ActionTableLegend
                  legendAndCounter={legendAndCounter}
                  statusSearchInput={statusSearchInput}
                  actionsDispatcher={riskOppDispatcher}
                />

           </div>

           {/* SEARCH BAR */}
           <CvtSearchBar
            textSearchInput={textSearchInput}
            total={actionsData.length}
            textSearchKey="actionsTextSearchInput"
            onChangeInput={handleChangeTextInput}
            onChangeReset={handleResetTextInput}
           />

        </div>
        :null}

        {/* ACTION TABLE CONTENT */}
        <div className=" flexColStartCenter flexOne"
          style={{
              //borderBottom: tableBorderBottom ? tableBorderBottom : '',
              width:'100%',
              marginTop: '5px',
          }}
          >

          {actionsData.length > 0 ? (
            <div style={{ width: "100%", flex: 1 }} className="flexColStartStart">
              <div
                className="actionTable"
                style={{
                  maxHeight: maxHeight ? maxHeight : "",
                }}
                key={`risk_big_view_actions_table_${roItem ? roItem.id : ''}`}
              >
                {actionsData.map((action, index) => {
                  let isSelected = selectedItems.find((item) => item.id === action.id);
                  return (
                    <ActionTableItem
                      key={`risk_related_action_${action.id}_${index}_${isSelected}`}
                      action={action}
                      linkedRisk={roItem}
                      usersList={usersList}
                      isSelected={isSelected}
                      displayStatus={action.status}
                      onItemClicked={() => {}}
                      currentUser={currentUser}
                      megaUsersId={megaUsersId}
                      isDisplayInRo={true}
                      onSelectFunction={(e, action) => handleRoSelectCheckbox(e, action, isSelected)}
                      isReadOnlyMode={!canEditActions}
                      isCurrentUserEditor={isCurrentUserEditor || canEditActions}
                    />
                  );
                })}
              </div>

            </div>
          ) :  (
            <div
              className="flexCenterCenter"
              style={{
                flex: 1,
                width: "100%",
                marginBottom: '20px'
              }}
            >
              {
                canModifyRo ?
                <WbsNoItem
                  text="Create an action"
                  onClickAdd={() => handleCreateAction(null)}
                />
                :
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '25px',
                    marginTop: '10px',
                  }}
                >No Actions</span>
              }

            </div>
          )}
        </div>

        {/* CREATE BTN-FOOTER */}
        {
          actionsData.length > 0 && canEditActions ?
          <div className="actionTableCreateButton flexStartCenter" style={{width: '100%', marginBottom: '10px', border: 'none'}}>
                <CvtButton
                  color="white"
                  shape="noShape"
                  size="small"
                  icon="add"
                  fullWidth
                  txt="Create Action"
                  handleSubmit={() => handleCreateAction(null)}
                  margin="5px 0px 0px"
                />
              </div>

        :null}

      </div>
    );
  }
);
