import React, { useMemo } from "react";
import { CvtButton,CvtSpinnerGrow } from '@mi-gso/cvt'
import { envVarManagement } from '../../../../envVarManagement';

//COMPONENT LOADER
export function HeaderUserInfosLoader(){
    return(
        <div
            className='flexStartCenter'
            style={{
                padding:"20px",
                fontWeight:500
            }}
        >
            <CvtSpinnerGrow
                marginRight="10px"
                text="I'm updating your information"
            />
        </div>
    )
}

//COMPONENT DISPLAY USER
export function HeaderUserInfosDisplayUser({
    email,
    ssoConnection,
}){

    //IND LICENSE
    const envInd = useMemo(() => envVarManagement("envInd"), [])

    //TREATMENT
    const providerName = useMemo(()=>{

        //INIT
        let name = ""

        //TEST IF SSO
        if(ssoConnection){
            let identitiesParse = JSON.parse(ssoConnection)
            name = identitiesParse[0].providerName
        }

        //RETURN
        return name
    },[ssoConnection])

    //RENDER
    return(
        <React.Fragment>

            {/* CONNECTED USER  */}
            {ssoConnection ?
                <React.Fragment>
                    <div
                        style={{
                            padding:"20px 20px 10px 20px"
                        }}
                    >
                        SSO Connection
                        <b
                            style={{
                                marginLeft:"10px",
                                textTransform: "capitalize"
                            }}
                        > {providerName}</b>
                    </div>

                    <div className="modalSeparator"></div>
                </React.Fragment>
            :null}


            {/* CONNECTED USER  */}
            <div
                style={{
                    padding:"20px 20px 10px 20px"
                }}
            >
                User
                <span
                    style={{
                        fontWeight:500,
                        marginLeft:"10px"
                    }}
                >{email}</span>
                
            </div>

            {/* SEPERATOR */}
            <div className="modalSeparator"></div>
            
            <div
                className='flexBetweenCenter'
                style={{
                    padding:"5px 20px 5px 0px",
                    width:"100%"
                }}
            >
                <div
                    className='flexStartCenter'
                    style={{
                        padding:"10px 20px 10px 20px"
                    }}
                >
                    License
                    <span
                        style={{
                            fontWeight:500,
                            marginLeft:"10px"
                        }}
                    >
                        {envInd.activated ?
                            "Individual"
                        : "Enterprise"}
                    </span>
                </div>
                
                {/* EDIT BUTTON */}
                <CvtButton
                    color="gray"
                    outline
                    customHover="blue"
                    icon="edit"
                    disabled={true}
                    tooltip
                    tooltipTxt={"Not available, if you need information about license please contact support@clayverest.com"}
                    customCursor="not-allowed"
                />
            </div>

            {/* SEPERATOR */}
            <div className="modalSeparator"></div>
        </React.Fragment>
    )
}

//COMPONENT DISPLAY USER
export function HeaderUserInfosSignOut({
    signOut,
    padding
}){
    return(
        <React.Fragment>

            {/* SEPARATOR */}
            <div className="modalSeparator"></div>

            {/* SIGN OUT */}
            <div
                className='flexBetweenCenter'
                style={{
                    padding:padding? padding : "5px 20px 5px 20px",
                    width:"100%"
                }}
            >
                {/* USER SIGN OUT */}
                <div className='flexStartCenter'>
                    User
                    <span
                        style={{
                            fontWeight:500,
                            margin:"0px 10px"
                        }}
                    >
                        Sign Out
                    </span>
                </div>

                {/* BUTTON */}
                <CvtButton
                    color="red"
                    outline
                    icon="logout"
                    handleSubmit={signOut}
                />
            </div>
        </React.Fragment>
    )
}

//COMPONENT DISPLAY USER
export function HeaderUserInfosForgetDevice({
    forgetDevice
}){
    return(
        <React.Fragment>

            {/* SEPARATOR */}
            <div className="modalSeparator"></div>

            {/* SIGN OUT */}
            <div
                className='flexBetweenCenter'
                style={{
                    padding:"5px 20px 20px 20px",
                    width:"100%"
                }}
            >

                {/* SIGN OUT + DEVICE */}
                <div className='flexStartCenter'>
                    User
                    <span
                        style={{
                            fontWeight:500,
                            margin:"0px 10px"
                        }}
                    >
                        Sign Out + Forget Device
                    </span>
                </div>

                {/* BUTTON */}
                <CvtButton
                    color="red"
                    outline
                    icon="desktop_access_disabled"
                    handleSubmit={forgetDevice}
                />
            </div>
        </React.Fragment>
    )
}
