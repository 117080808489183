import { CvtButton, CvtTag } from "@mi-gso/cvt";
import { useMemo } from "react";

export default function GovReviewAgendaSideBarLegend({
    isAddView,
    agendaItem,
    listLength,
    onOpenNewAgendaView,
    onGoBackToAgendaList,
    onSaveAgendaItem,
    editMode,
    inReviewMode,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // CHECK USER CAN ADD AN ITEM TO THE LIST
    const canAdd = useMemo(() => {
        if (inReviewMode) return !isAddView && editMode === true;
        return !isAddView;
    }, [editMode, inReviewMode, isAddView]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className={isAddView ? "flexEndCenter" : "flexStartCenter"}
            style={{ marginTop: "15px", gap: "10px", marginBottom: isAddView ? "0px" : "20px" }}
        >
            {/* LEFT: NEW ITEM + COUNT */}
            {canAdd && !isAddView ? (
                <>
                    {/* ADD */}
                    <CvtButton
                        color="blue"
                        icon="add"
                        shadow="none"
                        size="tiny"
                        txt="Add"
                        handleSubmit={onOpenNewAgendaView}
                    />

                    {/* COUNT */}
                    <CvtTag
                        color="dark"
                        shape={"round"}
                        txt={listLength + " item" + (listLength > 0 ? "s" : "")}
                    />
                </>
            ) : null}

            {/* SAVE / GO BACK */}
            {isAddView ? (
                <>
                    {/* GO BACK */}
                    <CvtButton
                        color="gray"
                        icon="arrow_back"
                        shadow="none"
                        size="tiny"
                        txt="Cancel"
                        handleSubmit={onGoBackToAgendaList}
                    />

                    {/* SAVE */}
                    <CvtButton
                        color="blue"
                        icon="save"
                        shadow="none"
                        size="tiny"
                        txt="Save"
                        handleSubmit={onSaveAgendaItem}
                        disabled={!agendaItem.title || agendaItem.title === ""}
                    />
                </>
            ) : null}
        </div>
    );
}
