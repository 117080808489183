import React, { useCallback, useState } from 'react'

//AUTH MINI COMPONENT
import {
    AuthAuthenticatorTitle,
    AuthAuthenticatorForm,
    AuthAuthenticatorFormTitle
} from './AuthAuthenticatorMiniComponents'

//CORE
import { CvtInput } from '@mi-gso/cvt'

////////////////////////////////////////////////////////////////////////////////////////////
/// EMAIL VERIFICATION COMPONENT ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

export default function AuthAuthenticatorConfirmSignUp({
    options,
    onChange,
    errorMessage,
    handleSubmit,
    handleSendCodeConfirmSignUp,
    handleBackToSignIn
}){

    //CODE SENT
    const [send, setSend] = useState(false)

    //SEND CODE
    const sendCode = useCallback((e)=>{
        e.preventDefault();
        setSend(true);
        handleSendCodeConfirmSignUp();
    },[handleSendCodeConfirmSignUp])

    //RENDER
    return(
        <React.Fragment>

            {/* TITLE & ERROR */}
            <AuthAuthenticatorTitle 
                title="Confirm Sign Up"
                errorMessage={errorMessage}
                icon="account_circle"
            />

            {/* INPUTS BLOCK */}
            <AuthAuthenticatorForm 
                disabled={options.code === "" }
                loader={options.loader}
                text="Verify Email"
                handleBackToSignIn={handleBackToSignIn}
                onSubmit={handleSubmit}
                errorMessage={errorMessage}
            >

                {/* FORM TITLE */}
                <AuthAuthenticatorFormTitle 
                    icon="mark_email_read"
                    text="Your email need to be verified"
                />

                    {/* USERNAME */}
                    <div style={{padding:"20px 20px 0px 20px",fontWeight:500}}>
                        {options.email}
                    </div>

                    {/* CODE */}
                    <CvtInput 
                        legend="Code *"
                        placeholder="Enter code sent to your email"
                        inputBlockCss="modalOptionSubBlock"
                        inputBlockPadding="15px 20px 10px 20px"
                        inputCss="form-control appAuthenticatorInput"
                        name="code"
                        value={options.code}
                        onChange={onChange}
                        autoComplete="off"
                    />

                    {/* RESEND CODE */}
                    {send ? 
                        <div 
                            className="appAuthenticatorQuestion"
                            style={{
                                fontWeight:500,
                                color:"var(--color-good)"
                            }}
                        >
                            Code sent!
                        </div>
                    :
                        <div className="appAuthenticatorQuestion">
                            Code not received? 
                            <a 
                                href='/#' 
                                onClick={sendCode} 
                                style={{marginLeft:"5px"}}
                            >
                                Resend the code
                            </a>
                        </div>
                    }
            </AuthAuthenticatorForm>
        </React.Fragment>
    )
}