//GET ALL ENV VAR 
export const checkUserFromSsoProvider = (
    user,
    ssoList
) =>{

    //GET USER GROUP
    let userGroups = []
    if(user
    && user.signInUserSession
    && user.signInUserSession.accessToken
    && user.signInUserSession.accessToken.payload
    && user.signInUserSession.accessToken.payload["cognito:groups"]){
        userGroups = user.signInUserSession.accessToken.payload["cognito:groups"]
    }

    //CHECK IF EVERYTHING GOOD TO CONTINUE
    if(userGroups.length > 0 
    && ssoList 
    && ssoList.length > 0){

        //INIT
        for (let i = 0; i < ssoList.length; i++) {
            if(userGroups?.includes(ssoList[i].cognitoGroup)) return true
        }
    }

    //RETURN
    return false
}