import React, { useMemo } from "react";

//CORE
import { 
  CvtButton, 
  CvtInput,
  CvtReminder
} from "@mi-gso/cvt";
import { FUNC_PASSWORD_CHECK } from "../../../../00-Core/Standards";

//COMPONENT LOADER
export function HeaderUserInfosEditPassword({
  isEditMode,
  error,
  onChange,
  oldPassword,
  newPassword1,
  newPassword2,
  onChangePassword,
  handleEdit,
  handleUpdatePasword,
  loader,
}) {
  //PASSWORD CHECK
  const passWordCheck = useMemo(
    () => FUNC_PASSWORD_CHECK(newPassword1),
    [newPassword1]
  );

  //RENDER
  return (
    <React.Fragment>
      {isEditMode ? (
        <div
          className="flexColStartStart"
          style={{
            width: "calc(100% - 40px)",
            margin: "10px 20px",
            border: "1px solid var(--border-color-gray-0-5)",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              padding: "10px 20px",
              width: "100%",
              backgroundColor: "var(--border-color-gray-00-5)",
              borderBottom: "1px solid var(--border-color-gray-0)",
              fontWeight: 500,
            }}
          >
            Change Password form
          </div>

          {/* MESSAGE ERREUR */}
          {error ? (
            <div
              className="structureBlockInfo flexBetweenCenter alertContentDanger alertContent"
              style={{
                boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.2)",
                margin: "20px 20px 0px 20px",
                width: "calc(100% - 40px)",
              }}
            >
              <CvtReminder 
                icon="info" 
                message={error} 
              />
            </div>
          ) : null}

          {/* NEEDED FOR PASSWORD USERNAME */}
          <input
            autoComplete="username"
            name="username"
            onChange={onChange}
            value=""
            hidden
          ></input>

          {/* OLD PASSWORD */}
          <CvtInput
            legend="Old password"
            legendWidth="140px"
            mandatory
            type="password"
            placeholder="Enter your old password"
            inputBlockCss="flexBetweenCenter modalOptionSubBlock"
            inputBlockPadding="20px 20px 5px 20px"
            inputCss="form-control appAuthenticatorInput"
            name="oldPassword"
            value={oldPassword}
            onChange={onChangePassword}
            inputWidthFull={true}
            autoComplete="new-password"
          />

          {/* NEW PASSWORD */}
          <CvtInput
            legend="New password"
            legendWidth="140px"
            mandatory
            type="password"
            placeholder="Enter your new password"
            inputBlockCss="flexBetweenCenter modalOptionSubBlock"
            inputBlockPadding="2px 20px 5px 20px"
            inputCss="form-control appAuthenticatorInput"
            name="newPassword1"
            value={newPassword1}
            onChange={onChangePassword}
            inputWidthFull={true}
            autoComplete="new-password"
          />

          {/* NEW PASSWORD */}
          <CvtInput
            legend="Confirm password"
            legendWidth="140px"
            mandatory
            type="password"
            placeholder="Confirm your new password"
            inputBlockCss="flexBetweenCenter modalOptionSubBlock"
            inputBlockPadding="2px 20px 20px 20px"
            inputCss="form-control appAuthenticatorInput"
            name="newPassword2"
            value={newPassword2}
            onChange={onChangePassword}
            inputWidthFull={true}
            autoComplete="new-password"
          />

          {/* PASSWORD DETAILS */}
          <div
            className="appAuthenticatorPassword flexStartStart"
            style={{ backgroundColor: "white" }}
          >
            {/* ICON */}
            <div
              className="material-icons appAuthenticatorPasswordIcon"
              style={{
                color: passWordCheck.global ? "var(--color-migso-green)" : null,
              }}
            >
              lock
            </div>

            {/* RULES */}
            <div className="flexColStartStart">
              {/* LENGTH */}
              <div
                className="flexColStartStart"
                style={{ fontWeight: 500, marginBottom: "5px" }}
              >
                Password rules
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                  }}
                >
                  <div
                    style={{
                      color: passWordCheck.number
                        ? "var(--color-migso-green)"
                        : null,
                    }}
                  >
                    At least 14 characters
                  </div>
                  <div
                    style={{
                      color: passWordCheck.digit
                        ? "var(--color-migso-green)"
                        : null,
                    }}
                  >
                    Contains at least 1 number
                  </div>
                  <div
                    style={{
                      color: passWordCheck.lower
                        ? "var(--color-migso-green)"
                        : null,
                    }}
                  >
                    Contains at least 1 uppercase letter
                  </div>
                  <div
                    style={{
                      color: passWordCheck.upper
                        ? "var(--color-migso-green)"
                        : null,
                    }}
                  >
                    Contains at least 1 lowercase letter
                  </div>
                  <div
                    style={{
                      color: passWordCheck.special
                        ? "var(--color-migso-green)"
                        : null,
                    }}
                  >
                    Contains at least 1 special character
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <div
            className="flexEndCenter"
            style={{
              padding: "10px 20px",
              width: "100%",
              backgroundColor: "var(--border-color-gray-00-5)",
              borderBottom: "1px solid var(--border-color-gray-0)",
              fontWeight: 500,
            }}
          >
            {/* CANCEL */}
            <CvtButton
              color="gray"
              outline
              customHover="red"
              margin="0px 20px 0px 0px"
              txt="Cancel"
              handleSubmit={handleEdit}
              handleSubmitOptions=""
            />

            {/* VALIDATE */}
            <CvtButton
              color="green"
              txt="Update"
              handleSubmit={handleUpdatePasword}
              loader={loader}
              disabled={
                !passWordCheck.global ||
                newPassword1 !== newPassword2 ||
                newPassword1 === "" ||
                newPassword2 === "" ||
                oldPassword === ""
              }
            />
          </div>
        </div>
      ) : (
        <div
          className="flexBetweenCenter"
          style={{
            padding: "5px 20px 5px 20px",
            width: "100%",
          }}
        >
          {/* PASSWORD */}
          <div className="flexStartCenter">
            Change
            <span
              style={{
                fontWeight: 500,
                margin: "0px 10px",
              }}
            >
              Password
            </span>
          </div>

          {/* EDIT */}
          <CvtButton
            color="gray"
            customHover="blue"
            outline
            icon="edit"
            handleSubmit={handleEdit}
            handleSubmitOptions="password"
          />
        </div>
      )}

      {/* SEPERATOR */}
      <div className="modalSeparator"></div>
    </React.Fragment>
  );
}
