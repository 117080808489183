// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { picTELGenSideBar1, picTELGenSideBar2, picTELGenSideBar3, picTELGenSideBarTable } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `
On the left, you can find a sidebar with some options helping you
navigating in the control page.

![picTELGenSideBar1](${picTELGenSideBar1} "" )

The features available are:

> **1**.  Expand sidebar: By default the sidebar is collapsed, but you can
    expand it     
**2**.  Filters: You can filter on many fields to easily deep dive inside
    your data. Click on filter icon to open all the fields available      
**3**.  Settings

***

# Filters

## Description

Several options are available on filters:

![picTELGenSideBar2](${picTELGenSideBar2} "" )

> **1**.  Data Consolidation: By default, all the items are consolidated on
    the parent WBS branches: on a WBS branch which have child, you see
    the items created on the WBS branch but also the items created on
    the children. It is possible to unable that and hide the children's
    items.        
**2**.  Filter sections: In the sections, you can apply filters on the
    domain with an impact in the global control page, in every branch
    displayed. Find below the fields impacted by the filter:

![picTELGenSideBarTable](${picTELGenSideBarTable} "" )

    WARNING: All users can apply filters.

## Process

**Data Consolidation**

Click on the filter icon in the sidebar to open the filters > click on
the toggle button 'Data Consolidation'

![picTELGenSideBar3](${picTELGenSideBar3} "" )

All items will not be consolidated at parent level in the entire control
view (mini and big category).

**Sections**

**Selection**: Click on the filter icon in the sidebar to open
the filters > click on Project section > Select the values (one or
multiple) of the fields (one or multiple). After the selection, all the
filters are available above the cockpit.

**Deletion**: there is two options to delete filters:

-   Option 1: You can delete your filter directly from the line above
    the cockpit one by one or clear all filters thanks to the first
    button.
-   Option 2: In the sidebar, you can :
    -   2a: delete the filters one by one in each field: unselect the value
    -   2b: by section: click on the red cross button to clear all
        filters of this section

***

# Settings

## Description

The settings allow you to customize the application and the control
page.

Here you can reorganize the domains and rename the columns.

Only following roles can edit the settings:

-   Project Mega User
-   Organization Mega User
-   Environment Mega User

## Process

Click on the settings icon in the sidebar:

-   Reorganize the order of the columns: drag and drop the lines in the
    order you want. Click on the icon on the left of the line > stay
    click and move the line in the position you want > release the
    mouse > click on button Update to save your modifications
-   Rename the column: click on the title of the column > edit the name > click on button Update to save your modifications
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;