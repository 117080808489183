// REACT
import React, { memo, useState, useCallback} from "react";

// CORE COMPONENTS
import { CvtSideBarButton } from "@mi-gso/cvt";

// CONSTANTS
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../../00-Wbs/00-Helpers/WbsConstants";

// COMPONENTS
import WbsElementBigSideBar from "../../../00-Wbs/01-Components/WbsElementBigSideBar";

////////////////////////////
/// RISK BIG SIDE BAR //////
////////////////////////////

export default memo(function RiskBigSideBar({
  viewMode,
  onAddEditClick,
  selectedItems,
  onDeleteSideBar,
  onBackSideBar,
  riskOppViewItem,
  canModifyRo,

  // NEW
  isCurrentUserEditor,
  currentUserId,
  onDuplicateSideBar,
  isInDetailedActionsMode,
  isConsolidated,
  riskOppDispatcher,
  isReadOnlyMode,
  handleMoveItemsClicked,
}) {
  //INIT
  const [confirm, setConfirm] = useState({
    value: false,
    type: "",
  });

  //HANDLE DELETE
  const handleAction = useCallback(
    (e, actionType, reset) => {
      e.preventDefault();

      //RESET
      if (reset) {
        setConfirm({
          value: false,
          type: "",
        });

        //ALREADY CONFIRM CAN DELETE
      } else if (confirm.value) {
        //TYPE
        switch (actionType) {
          //DUPLICATE
          case "duplicate":
            onDuplicateSideBar(e);
            break;

          //DELETE
          case "delete":
            onDeleteSideBar(e);
            break;

          default:
            break;
        }

        //UPDATE STATE
        setConfirm({
          value: false,
          type: "",
        });

        //NEED CONFIRM
      } else {
        setConfirm({
          value: true,
          type: actionType,
        });
      }
    },
    [confirm.value, onDeleteSideBar, onDuplicateSideBar]
  );

  //MANAGE CONSOLIDATE
  const onConsolidatedClick = useCallback(() => {
    riskOppDispatcher({
      type: "SET_STATE_KEY_VALUE",
      key: "isConsolidated",
      value: !isConsolidated,
    });
  }, [isConsolidated, riskOppDispatcher]);


  /////////////////////////////////////////////////////
  /// RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////

    return (
    <WbsElementBigSideBar>
      {viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
      viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.edit ? (
        <React.Fragment>

          {/* ADD BTN */}
          {!isReadOnlyMode ?
              <CvtSideBarButton
                buttonClass="flexCenterCenter buttonSideBar"
                id="tooltipAddBig"
                icon="add"
                fontSize="22px"
                title="Add R&O Item"
                onClickFunction={() => onAddEditClick()}
                testId="buttonAddBig"
        
              />
          :null}
          

          {/* Consolidate */}
          <CvtSideBarButton
            buttonClass={
              "flexCenterCenter buttonSideBar " +
              (isConsolidated ? "" : "buttonSideBarSelected")
            }
            id="tooltipConsolidate"
            icon={isConsolidated ? "bookmarks" : "bookmark"}
            fontSize="22px"
            title={
              isConsolidated
                ? "Only this branche's R&O items"
                : "Included all children's R&O items"
            }
            onClickFunction={onConsolidatedClick}
          />

          {/* EDIT BTN */}
          {selectedItems.length === 1 && canModifyRo ? (
            <CvtSideBarButton
              buttonClass="flexCenterCenter buttonSideBar"
              id="tooltipEditBig"
              icon="edit"
              fontSize="22px"
              title="Edit"
              onClickFunction={() => onAddEditClick(selectedItems[0])}
            />)
          : null}

          {/* DUPLICATE BTN */}
          {
            selectedItems.length > 0 && !isInDetailedActionsMode ? (
            <CvtSideBarButton
              buttonClass={
                "flexCenterCenter buttonSideBar " +
                (confirm.value && confirm.type === "duplicate"
                  ? "buttonSideBarAlert"
                  : "")
              }
              id="tooltipDuplicateBig"
              icon="copy_all"
              fontSize="22px"
              title={
                confirm.value && confirm.type === "duplicate"
                  ? "Are you sure?"
                  : "Duplicate"
              }
              onClickFunction={(e) => handleAction(e, "duplicate")}
              onBlur={(e) => handleAction(e, "duplicate", true)}
              onBlurConfirmation={
                confirm.value && confirm.type === "duplicate"
                  ? confirm.value
                  : false
              }
            />)
          : null}

          {/* DELETE BTN */}
          {canModifyRo && selectedItems.length > 0 ? (
            <CvtSideBarButton
              buttonClass={
                "flexCenterCenter buttonSideBar " +
                (confirm.value && confirm.type === "delete"
                  ? "buttonSideBarConfirmDelete"
                  : "")
              }
              id="tooltipDeleteBig"
              testId="buttonDeleteBig"
              icon="delete"
              fontSize="22px"
              title={
                confirm.value && confirm.type === "delete"
                  ? "Are you sure?"
                  : "Delete"
              }
              onClickFunction={(e) => handleAction(e, "delete")}
              onBlur={(e) => handleAction(e, "delete", true)}
              onBlurConfirmation={
                confirm.value && confirm.type === "delete"
                  ? confirm.value
                  : false
              }
              data-cy='buttonDeleteBig'
            />)
          : null}

          {/* MOVE TO ANOTHER BRANCH */}
          {selectedItems.length > 0 &&
            canModifyRo ? (
            <CvtSideBarButton
              buttonClass="flexCenterCenter buttonSideBar"
              id="tooltipMoveBig"
              icon="move_down"
              fontSize="22px"
              title="Move Items"
              onClickFunction={handleMoveItemsClicked}
            />)
          : null}
        </React.Fragment>
      ) : (
        <React.Fragment>

          {/* GO BACK */}
          <CvtSideBarButton
            buttonClass="flexCenterCenter buttonSideBar buttonSideBarBack"
            id="tooltipBackBig"
            icon="arrow_back"
            fontSize="18px"
            title="Back to list"
            onClickFunction={onBackSideBar}
          />

          {/* EDIT */}
          {
            canModifyRo && !isReadOnlyMode ?

            <CvtSideBarButton
              buttonClass="flexCenterCenter buttonSideBar"
              id="tooltipEditBig"
              icon="edit"
              fontSize="22px"
              title="Edit"
              onClickFunction={() => onAddEditClick(riskOppViewItem)}
            />
          :null}
          
          {/* DUPLICATE */}
          {
            !isReadOnlyMode ?

            <CvtSideBarButton
              buttonClass={
                "flexCenterCenter buttonSideBar " +
                (confirm.value && confirm.type === "duplicate"
                  ? "buttonSideBarAlert"
                  : "")
              }
              id="riskSideBarTooltipDuplicateBig"
              icon="copy_all"
              fontSize="22px"
              title={
                confirm.value && confirm.type === "duplicate"
                  ? "Are you sure?"
                  : "Duplicate"
              }
              onClickFunction={(e) => handleAction(e, "duplicate")}
              onBlur={(e) => handleAction(e, "duplicate", true)}
              onBlurConfirmation={
                confirm.value && confirm.type === "duplicate"
                  ? confirm.value
                  : false
              }
            />
          :null}

          {/* DELETE CURRENT R&O */}
          {
            canModifyRo && !isReadOnlyMode ? (
            
            <CvtSideBarButton
              buttonClass={
                "flexCenterCenter buttonSideBar " +
                (confirm.value && confirm.type === "delete"
                  ? "buttonSideBarConfirmDelete"
                  : "")
              }
              id="tooltipDeleteBig"
              icon="delete"
              fontSize="22px"
              title={
                confirm.value && confirm.type === "delete"
                  ? "Are you sure?"
                  : "Delete"
              }
              onClickFunction={(e) => handleAction(e, "delete")}
              onBlur={(e) => handleAction(e, "delete", true)}
              onBlurConfirmation={
                confirm.value && confirm.type === "delete"
                  ? confirm.value
                  : false
              }
              cyId="buttonDeleteBig"
            />
            )
          : null}

          {/* MOVE */}
          {
            canModifyRo && !isReadOnlyMode ?
              <CvtSideBarButton 
                buttonClass={`flexCenterCenter buttonSideBar`}
                id="tooltipMoveBig"
                icon="move_down"
                fontSize="22px"
                title="Move Items"
                onClickFunction={handleMoveItemsClicked}
              />
          :null}
        </React.Fragment>
      )}
    </WbsElementBigSideBar>
  );
});
