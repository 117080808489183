///////////////////////
/// ACTIONS REDUCER ///
///////////////////////

import _ from "lodash";
import { TODAY, TODAY_PLUS_30D } from "../../../../00-Core/Constants";
import { FUNCT_FIND_INDEX } from "../../../../00-Core/Standards";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";
import {
    ACTION_COMPONENT_EXPAND_TYPES,
    ACTION_DETAILS_KEY_BLOCK,
    ACTION_STATUS_KEY,
} from "./ActionsConstants";

export const ACTION_DEFAULT_ITEM = {
    // IDS
    id: null,
    organizationId: null,
    projectId: null,
    wbsId: null,
    displayId: null,
    createdBy: null,
    contributors: null,

    // INFO
    action: null,
    cancelledDate: null,
    description: null,
    events: null,
    dueDate: TODAY_PLUS_30D,
    baseline: null,
    createdAt: TODAY,
    createdOn: TODAY,
    responsible: null,
    status: ACTION_STATUS_KEY.todo,
    realisedDate: null,
    lastModified: null,
    lastModifiedBy: null,
    progression: null,
    comments: null,
    isPrivate: null,

    // CONNECTIONS
    telescopeDataGovernanceTelescopeDataActionId: null,
    telescopeDataGovernance: null,
    telescopeDataROTelescopeDataActionId: null,
    telescopeDataRO: null,
    telescopeDataScheduleTelescopeDataActionId: null,
    telescopeDataCostTelescopeDataActionId: null,
};

export const ACTION_DEFAULT_STATE = {
    editSideBarContent: null, // CONTENT THAT GOES INSIDE EDIT SIDE BAR
    editSideBar: false, // WHETHER SIDE BAR IS OPEN OR NOT
    previousChanges: [], // WILL STORE PREVIOUS ACTIONS
    nextChanges: [], // WILL STORE NEXT ACTIONS
    selected: [], // SELECTED FROM TABLE
    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
    isConfirmDelete: false,
    textSearchInput: "",
    statusSearchInput: [],
    expandMode: ACTION_COMPONENT_EXPAND_TYPES.both, // EXPAND MODE (initially show both graph and table)
    actionView: null, // ACTION IN VIEW MODE
    selectedViewModeContent: ACTION_DETAILS_KEY_BLOCK[0].key, // SELECTED CONTENT IN VIEW MODE (default to description)
    viewModeInputText: "", // VIEW MODE INPUT TEXT DESCRIPTION
    viewModeInputComment: "", // VIEW MODE INPUT TEXT DESCRIPTION
    scrollToId: null,
    isEnrichTextEditing: false,
    isConsolidate: true,
    widthSizeLimit: false,
    windowWidth: 0,

    // IS SHOWING MOVE MODAL
    isShowMoveModal: false,
    isLinkModalShown: false,

    goBackOriginItem: null, // IF GO DIRECTLY TO ITEM CONFIGURED, SPECIFY THE ITEM TO OPEN WHEN CLICKING ON GO BACK BUTTON
};

export const actionsReducer = (state, action) => {
    // REUSABLE VARIABLES
    let newState;
    let attributes = [];
    let selected = [];
    let findIndex;

    //SWITCH
    switch (action.type) {
        //SET STATE FROM SOURCE ATTRIBUT AND  ----------------------------------------
        case "SET_GLOBAL_STATE":
            //options: source, object

            //INIT
            let currentSource = state[action.options.source];

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributes = Object.keys(action.options.object);

            //UPDATE REFERNECE OBJECT WITH NEW VALUES
            for (let i = 0; i < attributes.length; i++) {
                currentSource[attributes[i]] = action.options.object[attributes[i]];
            }

            //RETURN
            return {
                ...state,
                [action.options.source]: currentSource,
                viewModeInputText: "",
                viewModeInputComment: "",
            };

        //UPDATE SEVERAL IN ONE TIME -----------------------------------------------
        case "SET_STATE_OBJECT":
            //ACTION object

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributes = Object.keys(action.object);

            //GET ALL PREVIOUS STATE
            newState = { ...state };

            //UPDATE STATE FROM OBJECT
            for (let i = 0; i < attributes.length; i++) {
                newState[attributes[i]] = action.object[attributes[i]];
            }

            //RETURN
            return {
                ...newState,
            };

        // UPDATE FROM KEY AND VALUE -----------------------------------------------
        case "SET_STATE_KEY_VALUE":
            //action , key value

            //RETURN
            return {
                ...state,
                [action.key]: action.value,
            };

        // SELECT ACTION -----------------------------------------------------------
        case "SET_ACTIONS_SELECTION_INDIVIDUAL":
            //action: action

            //INIT
            selected = [...state.selected];

            //TEST IF THE ACTION IS ALREADY INSIDE THE SELECTED ARRAY
            findIndex = FUNCT_FIND_INDEX(selected, "id", action.action.id);

            //DELETE IT
            if (findIndex > -1) {
                selected.splice(findIndex, 1);

                //ADD IT
            } else {
                selected.push(action.action);
            }

            //RETURN
            return {
                ...state,
                selected: selected,
            };

        // SELECT ACTION -----------------------------------------------------------
        case "SET_ACTIONS_SELECTION_GLOBAL":
            //filteredActions

            //INIT
            selected = [...state.selected];

            //IF NOTHING SELECT ALL
            if (selected.length === 0) {
                selected = _.cloneDeep(action.filteredActions);

                //RESET ALL
            } else {
                selected = [];
            }

            //RETURN
            return {
                ...state,
                selected: selected,
            };

        // SELECT ACTION -----------------------------------------------------------
        case "SET_ACTIONS_EXPAND_MODE":
            //widthSizeLimit

            //INIT
            let expandMode;

            //window <= 1200
            if (action.widthSizeLimit) {
                if (state.expandMode === ACTION_COMPONENT_EXPAND_TYPES.graphOnly) {
                    expandMode = ACTION_COMPONENT_EXPAND_TYPES.graphOnly;
                } else {
                    expandMode = ACTION_COMPONENT_EXPAND_TYPES.tableOnly;
                }

                //window > 1200
            } else {
                expandMode = ACTION_COMPONENT_EXPAND_TYPES.both;
            }

            //RETURN
            return {
                ...state,
                widthSizeLimit: action.widthSizeLimit,
                expandMode: expandMode,
            };

        //DEFAULT
        default:
            console.log(`${action.type} not in the ActionsDispatcher :(`);
            return { ...state };
    }
};
