import React from 'react'

//AUTH MINI COMPONENT
import {
    AuthAuthenticatorTitle,
    AuthAuthenticatorFormTitle,
    AuthAuthenticatorLink
} from './AuthAuthenticatorMiniComponents'

//Authenticator DEFAULT
export default function AuthAuthenticatorNeedValidateTerms({
    handleBackToSignIn
}){
    return(
        <React.Fragment>

            {/* TITLE  */}
            <AuthAuthenticatorTitle 
                title="Terms validation"
                icon="check_circle"
            />

            {/* INFO */}
            <div className='appAuthenticatorInputBlock' >

                {/* FORM TITLE */}
                <AuthAuthenticatorFormTitle 
                    icon="person"
                    text="Waiting for Terms validation"
                />
            </div>

            {/* LINK */}
            <AuthAuthenticatorLink 
                text="Back to Sign in"
                handleClick={handleBackToSignIn}
            />
        </React.Fragment>
    )
}