import React from "react";

//IF NO ITEM IN THE SELECTED WBS AND DOMAIN 
export default function WbsNoItem({ 
    text, 
    onClickAdd 
}) {
    return (
        <div
            className="flexCenterStart"
            style={{width:"100%"}}
        >
            <div 
                className="wbsNoItemBlock flexCenterCenter" 
                onClick={onClickAdd}
            >
                <span 
                    className="material-icons wbsNoItemBlockIcon"
                    style={{
                        marginRight:"10px"
                    }}
                >
                    note_add
                </span>
                <span >
                    {text}
                </span>
            </div>
        </div>
    );
}
