import React, { useMemo } from 'react';
import {NavLink} from 'react-router-dom'
import { FUNCT_FIND_INDEX } from '../../00-Core/Standards';
import { envVarManagement } from '../../envVarManagement';

////////////////////////
/// FOOTER COMPONENT ///
//////////////////////// 

function Footer ({
    isLoggedIn
}){ 

    //ENV SERVICES
    const env = useMemo(()=> envVarManagement("env"),[])
    const envServices = useMemo(() => envVarManagement("envServices"),[])

    //VERSION
    const appVersion = useMemo(() => {
        let findIndex = FUNCT_FIND_INDEX(envServices,"id", "tel")
        if(findIndex > -1) {
            return envServices[findIndex].version
        }
        return "Error"
    },[envServices])

    // GET CORE URL
    const coreSupportUrl = useMemo(() => {
        let foundCore = FUNCT_FIND_INDEX(envServices, "id", "core");
        return envServices[foundCore].url + "support?id=tel";
    }, [envServices])


    //RENDER
    return(
        <footer id="appFooter" className="flexBetweenCenter appFooterContent">
            <div className="footerLink textEllipsis">
                Copyright © 2022 - MI-GSO | PCUBED - Clayverest - TEL - Telescope - 
                <NavLink to={"/docs/APPVERSION_" + appVersion}>
                    Version {appVersion}
                </NavLink>
            </div>

            {/* MESSAGE FOR FAKE DATA ONLY */}
            {env === "dev" || env === "test" || env === "demo" ?
                <div 
                    style={{
                        color:"red",
                        backgroundColor:"white",
                        borderRadius:"4px",
                        border: "1px solid red",
                        padding: "0px 4px",
                        fontWeight:700
                    }}
                >
                    <span style={{textTransform: "capitalize"}}>{env}</span> environment, please use only fake data
                </div>
            :null}
            
            {/* VERSION & TERMS */}
            <div 
                className="footerLink textEllipsis"
                style={{
                    // marginRight: isLoggedIn ? "130px" : null
                }}
            >
                <NavLink to="/terms">
                    Policy Privacy & Terms
                </NavLink> 
            </div>

            {/* SUPPORT */}
            {isLoggedIn ?
                <a 
                    className="supportTabButton flexCenterCenter"
                    href={coreSupportUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{color:"white"}}
                >
                    <span 
                        className="material-icons material-symbols-outlined"
                        style={{
                            marginRight:"5px",
                            opacity:0.8
                        }}
                    >
                        help
                    </span>
                    Support
                </a>
            : null}
        </footer>
    )
}
export default Footer
