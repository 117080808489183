import {useState, useEffect, } from 'react'

export const useOnScreen = (ref) => {

  // State and setter for storing whether element is visible

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {

    const currentRef = ref.current

    const observer = new IntersectionObserver(

      ([entry]) => {

        // Update our state when observer callback fires
        // If statement makes animation run only once
        if(entry.isIntersecting) {
          setVisible(true);
        }
      },
      {
        rootMargin: "-50%"
      }
    );

    if (ref.current) {
      observer.observe(currentRef);
    }
    // Return a function to end the event listener
    return () => {
      observer.unobserve(currentRef);
    };

  }, [ref]); 
  
  return isVisible;
}