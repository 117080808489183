// REACT
import { memo, useCallback, useMemo, useState } from 'react'

//STYLES
import './RiskCard.styles.css'

// COMPONENTS
import RiskCardMatrixImpact from './RiskCardMatrixImpact/RiskCardMatrixImpact'
import RiskCardInfos from './RiskCardInfos/RiskCardInfos'

// CONSTANTS
import {
	OPP_STRATEGY_LIST,
	RISK_MATRIX_VIEW_MODE,
	RISK_STRATEGY_LIST,
} from '../../00-Helpers/RiskConstants'
import {
	WBS_BIG_COMPONENT_VIEW_MODE,
	WBS_DEFAULT_NAVIGATION_OPTIONS,
} from '../../../00-Wbs/00-Helpers/WbsConstants'
import {
	CvtButton,
	FIND_OBJECT_ARRAY_ITEM,
	CvtAvatar,
	CvtItemTable,
	CvtSelectMultiple,
	CvtSideBarButton,
} from '@mi-gso/cvt'
import ActionTableLegend from '../../../01-Action/01-Components/ActionTable/ActionTableLegend'
import RiskTableItemStrategyTag from '../RiskTable/RiskTableItemStrategyTag'
import RiskCardActionsTableItemTags from './RiskCardActionsTable/RiskCardActionsTableItemTags'
import { multipleMutateGraphql } from '../../../../00-App/02-Backend/AppBackendCommon'
import {
	updateTelescopeDataAction,
	updateTelescopeDataRO,
} from '../../../../../graphql/mutations'
import { FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING } from '../../../../../00-Core/Standards'
import {
	ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST,
	FUNC_ACTION_ADD_NEW_LINKS,
	FUNC_ACTION_CREATE_LINK_OBJECT,
} from '../../../01-Action/00-Helpers/ActionsFunctions'
import { WBS_OPEN_ITEM } from '../../../00-Wbs/00-Helpers/WbsFunctions'
import _ from 'lodash'
import { Tooltip, TooltipProvider, TooltipWrapper } from 'react-tooltip'

////////////////////////////
/// RISK CARD //////////////
////////////////////////////

export default memo(function RiskCard({
	// DATA
	roItem,
	usersList,
	riskViewModeSelection,
	roData,
	projectData,
	currentUser,
	parentId,
	wbsId,
	previousChanges,
	propKeyIncrement,
	actionsData,
	allActionsData,
	selectedItems,
	megaUsersId,
	legendAndCounter,
	statusSearchInput,
	textSearchInput,
	riskCardImpactProbaOpenView,
	viewMode,
	displayWidth,
	isReviewMode,
	isCurrentUserEditor,
	isReadOnlyMode,
	megaUsersIds,
	securityGroup,
	canModifyRo,

	// FUNCTIONS
	appDispatcher,
	riskOppDispatcher,
	handleEditRoItem,
	handleCreateAction,
	onSaveAddEdit,
	handleEditAction,
	handleDeleteClick,
	handleDuplicateClick,
	wbsDispatcher,
}) {
	//////////////////////////////////////////////////////////////////////////
	/// STATE /////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////

	// STATE
	const [state, setState] = useState({
		confirm: {
			value: false,
			type: '',
		},
		actionPlanLink: {
			isDisplayed: false,
			selectedActions: [],
		},
	})

	/////////////////////////////////////////////////////////
	/// FUNCTIONS ///////////////////////////////////////////
	/////////////////////////////////////////////////////////

	// HANDLE DROP IN INDIVIDUAL MATRIX
	const handleDrop = useCallback(
		(dropped) => {
			// NEED TO DEFINE WHICH KEY TO PASS
			let key

			switch (dropped.draggableId) {
				case RISK_MATRIX_VIEW_MODE.initial.value:
					key = 'initialCriticity'
					break
				case RISK_MATRIX_VIEW_MODE.current.value:
					key = 'currentCriticity'
					break
				case RISK_MATRIX_VIEW_MODE.target.value:
					key = 'targetCriticity'
					break
				default:
					break
			}

			if (key) {
				let droppableIdArray = dropped.destination.droppableId.split('_')

				// GET NEW CRITICITY
				let newCriticity = parseInt(
					droppableIdArray[droppableIdArray.length - 1]
				)

				// USE handleEditRoItem
				handleEditRoItem(roItem, key, newCriticity)
			}
		},
		[handleEditRoItem, roItem]
	)

	// WHEN SEARCH BAR TEXT IS UPDATED
	const onSearchTextInputChanged = useCallback(
		(value) => {
			riskOppDispatcher({
				type: 'SET_STATE_KEY_VALUE',
				key: 'actionsTextSearchInput',
				value: value,
			})
		},
		[riskOppDispatcher]
	)

	// WHEN TABLE SELECT LIST IS UPDATED
	const onSelectedItemsChanged = useCallback(
		(newSelectedList) => {
			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: newSelectedList,
				},
			})
		},
		[riskOppDispatcher]
	)

	// HANDLE DELETE
	const handleAction = useCallback(
		(e, actionType, reset) => {
			e.preventDefault()

			//RESET
			if (reset) {
				setState((prevState) => ({
					...prevState,
					confirm: {
						value: false,
						type: '',
					},
				}))

				//ALREADY CONFIRM CAN DELETE
			} else if (state.confirm.value) {
				//TYPE
				switch (actionType) {
					//DUPLICATE
					case 'duplicate':
						handleDuplicateClick(e)
						break

					//DELETE
					case 'delete':
						handleDeleteClick(e)
						break

					default:
						break
				}

				//UPDATE STATE
				setState((prevState) => ({
					...prevState,
					confirm: {
						value: false,
						type: '',
					},
				}))

				//NEED CONFIRM
			} else {
				setState((prevState) => ({
					...prevState,
					confirm: {
						value: true,
						type: actionType,
					},
				}))
			}
		},
		[state.confirm.value, handleDuplicateClick, handleDeleteClick]
	)

	// WHEN ACTION LINK MULTIPLE SELECT INPUT CHANGES
	const onActionLinkSelected = useCallback((newValue) => {
		setState((prevState) => {
			let newState = { ...prevState }

			newState.actionPlanLink.selectedActions = newValue

			return newState
		})
	}, [])

	// LINK NEW ACTIONS TO ACTION PLAN
	const onLinkNewActions = useCallback(async () => {
		// GET FULL SELECTED ACTIONS
		let selectedActions = state.actionPlanLink.selectedActions.map(
			(selectedAction) => {
				// GET FROM PROJECT DATA
				let action = FIND_OBJECT_ARRAY_ITEM(
					allActionsData,
					'id',
					selectedAction.value
				)

				return action
			}
		)

		// CREATE A LINK OBJECT TO THIS RO
		let newLink = FUNC_ACTION_CREATE_LINK_OBJECT('riskOpp', roItem.id)

		// UPDATE APP STATE FOR ACTIONS AND RO
		FUNC_ACTION_ADD_NEW_LINKS(
			[newLink],
			selectedActions,
			projectData,
			currentUser.username,
			appDispatcher
		)

		// UPDATE SCHEDULE DISPATCHER
		let updatedActionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
			roItem.actionsIds
		)
		let newIds = selectedActions.map((action) => action.id)
		updatedActionsIds.push(...newIds)

		riskOppDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				riskOppViewItem: {
					...roItem,
					actionsIds: updatedActionsIds,
				},
			},
		})

		// RESET LOCAL STATE
		setState((prevState) => ({
			...prevState,
			actionPlanLink: {
				isDisplayed: false,
				selectedActions: [],
			},
		}))
	}, [
		allActionsData,
		appDispatcher,
		currentUser.username,
		projectData,
		riskOppDispatcher,
		roItem,
		state.actionPlanLink.selectedActions,
	])

	// UNLINK SELECTED ACTIONS FROM THIS RISK
	const onUnlinkAction = useCallback(() => {
		// INIT
		let finalMutation = {
			graphql: {
				riskOpp: {
					query: updateTelescopeDataRO,
					objects: [],
				},
				action: {
					query: updateTelescopeDataAction,
					objects: [],
				},
			},
			dispatcher: {
				riskOpp: {
					update: [],
				},
				action: {
					update: [],
				},
			},
		}

		// FOR EACH SELECTED ACTION
		selectedItems.forEach((action) => {
			// UPDATE ACTION LINKS LIST
			action.links = ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(
				action.links,
				roItem.id
			)

			// ADD TO MUTATION
			finalMutation.dispatcher.action.update.push(action)
			finalMutation.graphql.action.objects.push({
				id: action.id,
				links: action.links,
			})
		})

		// UPDATE CURRENT RO ACTIONS IDS
		let actionsIdsToRemove = selectedItems.map((item) => item.id)
		let newActionIds = roItem.actionsIds.filter(
			(actionId) => !actionsIdsToRemove?.includes(actionId)
		)
		let updatedRO = {
			...roItem,
			actionsIds: newActionIds,
		}

		// ADD RO MUTATION
		finalMutation.dispatcher.riskOpp.update.push(updatedRO)
		finalMutation.graphql.riskOpp.objects.push({
			id: updatedRO.id,
			actionsIds: updatedRO.actionsIds,
		})

		// UPDATE GRAPHQL
		for (let queryName of Object.keys(finalMutation.graphql)) {
			multipleMutateGraphql(
				finalMutation.graphql[queryName].query,
				finalMutation.graphql[queryName].objects,
				appDispatcher
			)
		}

		//UPDATE APP STATE
		appDispatcher({
			type: 'SET_PROJECT_DATA_MULTIPLE_TABLES',
			object: finalMutation.dispatcher,
		})

		// UPDATE RISKOPP DISPATCHER
		riskOppDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				riskOppViewItem: updatedRO,
				selected: [],
			},
		})
	}, [appDispatcher, riskOppDispatcher, roItem, selectedItems])

	// WHEN CLICK ON AN ITEM FROM THE ACTION PLAN TABLE
	const onActionPlanItemClick = useCallback(
		(action) => {
			if (!roItem) {
				return
			}

			// BUILD WBS NAVIGATION OPTIONS
			let wbsNavigationOptions = _.cloneDeep(WBS_DEFAULT_NAVIGATION_OPTIONS)

			// SET ORIGIN
			wbsNavigationOptions.originDomain = 'riskOpp'
			wbsNavigationOptions.originItemId = roItem.id

			// SET DESTINATION
			wbsNavigationOptions.goToItemId = action.id
			wbsNavigationOptions.goToDomain = 'action'

			// SET OPTION TO OPEN CONNECTION TABS
			wbsNavigationOptions.options = {
				openLinksTab: true,
			}

			// OPEN THE DESTINATION ITEM
			WBS_OPEN_ITEM(
				projectData,
				'action',
				action.id,
				null,
				wbsNavigationOptions,
				wbsDispatcher,
				appDispatcher,
				securityGroup
			)
		},
		[appDispatcher, projectData, roItem, securityGroup, wbsDispatcher]
	)

	/////////////////////////////////////////////////////
	/// MEMOS ///////////////////////////////////////////
	/////////////////////////////////////////////////////

	// CHECK IF RO IS ESCALATED
	const isEscalated = useMemo(
		() =>
			roItem &&
			roItem.escalatedTo &&
			(roItem.escalatedTo?.includes(wbsId) ||
				roItem.escalatedTo?.includes(parentId)),
		[parentId, roItem, wbsId]
	)

	const canModifyActions = useMemo(() => {
		if (actionsData.length === 0 || selectedItems.length === 0 || !roItem)
			return false

		for (const action of selectedItems) {
			if (
				action.responsible !== currentUser.username &&
				action.createdBy !== currentUser.username &&
				!isCurrentUserEditor
			) {
				return false
			}
		}
		return true
	}, [
		actionsData.length,
		currentUser.username,
		isCurrentUserEditor,
		roItem,
		selectedItems,
	])

	// RO ACTIONS TABLE TAG FILTERS COMPONENT
	const customFilterComponent = useMemo(() => {
		if (legendAndCounter) {
			return (
				<ActionTableLegend
					legendAndCounter={legendAndCounter}
					statusSearchInput={statusSearchInput}
					actionsDispatcher={riskOppDispatcher}
				/>
			)
		}

		return null
	}, [legendAndCounter, riskOppDispatcher, statusSearchInput])

	// RO ACTIONS TABLE HEADER CUSTOM COMPONENT (STRATEGY AND ACTIONS BUTTONS)
	const customTitleComponent = useMemo(() => {
		return (
			<>
				<RiskTableItemStrategyTag
					strategyInfo={
						roItem
							? roItem.isRisk === true
								? RISK_STRATEGY_LIST.find(
										(strategy) => strategy.value === roItem.strategy
								  )
								: OPP_STRATEGY_LIST.find(
										(strategy) => strategy.value === roItem.strategy
								  )
							: null
					}
				/>

				{/* BUTTONS */}
				<div
					className='flexStartCenter'
					style={{
						marginTop: '-3px',
					}}>
					{/* EDIT */}
					{canModifyActions && selectedItems.length === 1 ? (
						<CvtSideBarButton
							buttonClass='flexCenterCenter buttonSideBar'
							id='tooltipEditBig'
							icon='edit'
							fontSize='20px'
							iconPadding='0px'
							margin='0px'
							padding='0px'
							backgroundColor='transparent'
							title='Edit Action'
							onClickFunction={() => handleEditAction(selectedItems[0])}
						/>
					) : null}

					{/* DUPLICATE */}
					{selectedItems.length > 0 &&
					actionsData.length > 0 &&
					roItem &&
					(roItem.responsible === currentUser.username ||
						isCurrentUserEditor) ? (
						<CvtSideBarButton
							buttonClass={`
                                flexCenterCenter buttonSideBar roCardActionsTableHeadersBtn
                                ${
																	state.confirm.value &&
																	state.confirm.type === 'duplicate'
																		? 'buttonSideBarAlert'
																		: 'backgroundTransparent'
																}
                            `}
							id='tooltipDuplicateBig'
							icon='copy_all'
							fontSize='20px'
							margin='0px'
							backgroundColor={
								state.confirm.value && state.confirm.type === 'duplicate'
									? null
									: 'transparent'
							}
							title={
								state.confirm.value && state.confirm.type === 'duplicate'
									? 'Are you sure?'
									: `Duplicate action${selectedItems.length > 1 ? 's' : ''}`
							}
							onClickFunction={(e) => handleAction(e, 'duplicate')}
							onBlur={(e) => handleAction(e, 'duplicate', true)}
							onBlurConfirmation={
								state.confirm.value && state.confirm.type === 'duplicate'
									? state.confirm.value
									: false
							}
						/>
					) : null}

					{/* DELETE */}
					{canModifyActions && selectedItems.length > 0 ? (
						<CvtSideBarButton
							buttonClass={`
                                flexCenterCenter buttonSideBar
                                ${
																	state.confirm.value &&
																	state.confirm.type === 'delete'
																		? 'buttonSideBarConfirmDelete'
																		: 'backgroundTransparent'
																}
                            `}
							id='tooltipDeleteBig'
							testId='buttonDeleteHeader'
							icon='delete'
							margin='-2px 0px 0px 0px'
							fontSize='20px'
							backgroundColor={
								state.confirm.value && state.confirm.type === 'delete'
									? null
									: 'transparent'
							}
							title={
								state.confirm.value && state.confirm.type === 'delete'
									? 'Are you sure?'
									: `Delete action${selectedItems.length > 1 ? 's' : ''}`
							}
							onClickFunction={(e) => handleAction(e, 'delete')}
							onBlur={(e) => handleAction(e, 'delete', true)}
							onBlurConfirmation={
								state.confirm.value && state.confirm.type === 'delete'
									? state.confirm.value
									: false
							}
						/>
					) : null}

					{/* UNLINK */}
					{selectedItems.length > 0 &&
					roItem &&
					(roItem.responsible === currentUser.username ||
						isCurrentUserEditor) &&
					canModifyActions ? (
						<CvtSideBarButton
							buttonClass='flexCenterCenter buttonSideBar'
							id='tooltipUnlinkAction'
							icon='link_off'
							margin='-2px 0px 0px 0px'
							backgroundColor='transparent'
							fontSize='20px'
							title='Unlink this Action'
							onClickFunction={onUnlinkAction}
						/>
					) : null}
				</div>
			</>
		)
	}, [
		actionsData.length,
		canModifyActions,
		currentUser.username,
		handleAction,
		handleEditAction,
		isCurrentUserEditor,
		onUnlinkAction,
		roItem,
		selectedItems,
		state.confirm.type,
		state.confirm.value,
	])

	// ACTION PLAN TABLE CUSTOM BOTTOM COMPONENT
	const customBottomComponent = useMemo(() => {
		if (isReadOnlyMode) {
			return null
		}

		// ACTION MULTIPLE SELECT
		if (state.actionPlanLink.isDisplayed && roItem) {
			// GET ACTIONS LIST AND DON'T SHOW THE ONES THAT ARE ALREADY LINKED TO A RISK

			// PARSE EXISTING ACTION LINKS
			let actionsLinks = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(roItem.actionsIds)

			// REMOVE ACTIONS THAT ALREADY HAVE A LINK
			let actionOptions = allActionsData.filter(
				(action) => !actionsLinks?.includes(action.id)
			)

			// CREATE OPTIONS FOR SELECT LIST
			actionOptions = actionOptions.map((action) => ({
				value: action.id,
				label: action.displayId + ' : ' + action.action,
			}))

			// RETURN COMPONENT
			return (
				<div className='riskCardLinkAction'>
					<div className='riskCardLinkActionSelect'>
						<CvtSelectMultiple
							padding='0px 8px 0px 8px'
							margin='2px 10px 0px 0px'
							fullWidth={true}
							options={actionOptions}
							menuPlacement='top'
							onChange={onActionLinkSelected}
							value={state.actionPlanLink.selectedActions}
						/>
					</div>

					{/* CANCEL */}
					<div className='riskCardLinkActionButtons'>
						<CvtButton
							color='gray'
							icon='close'
							handleSubmit={() => {
								setState((prevState) => ({
									...prevState,
									actionPlanLink: {
										isDisplayed: false,
										selectedActions: [],
									},
								}))
							}}
							margin='0px 10px 0px 0px'
							size='tiny'
							tooltip
							tooltipPosition='top'
							tooltipTheme='dark'
							tooltipTxt='Cancel'
						/>

						{/* VALIDATE AND ADD ACTIONS */}
						<CvtButton
							color='green'
							icon='done'
							handleSubmit={onLinkNewActions}
							size='tiny'
							tooltip
							tooltipPosition='top'
							tooltipTheme='dark'
							tooltipTxt='Link these actions'
						/>
					</div>
				</div>
			)
		}

		// DEFAULT BUTTONS
		return (
			<>
				<CvtButton
					color='white'
					shape='noShape'
					size='small'
					icon='add'
					txt='Create Action'
					handleSubmit={() => {
						handleCreateAction(null)
					}}
					margin='-2px 0px 0px 0px'
					iconMarginTop='2px'
					shadow='none'
				/>
				<CvtButton
					color='white'
					shape='noShape'
					size='small'
					icon='link'
					txt='Link existing Action'
					handleSubmit={() => {
						setState((prevState) => ({
							...prevState,
							actionPlanLink: {
								isDisplayed: true,
								selectedActions: [],
							},
						}))
					}}
					margin='-2px 0px 0px 0px'
					iconMarginTop='2px'
					shadow='none'
				/>
			</>
		)
	}, [
		allActionsData,
		handleCreateAction,
		isReadOnlyMode,
		onActionLinkSelected,
		onLinkNewActions,
		roItem,
		state.actionPlanLink.isDisplayed,
		state.actionPlanLink.selectedActions,
	])

	// USER TOOLTIP
	const userTooltipData = useMemo(() => {
		return roItem
			? usersList.find((user) => user.value === roItem.responsible)
			: null
	}, [roItem, usersList])

	/////////////////////////////////////////////////////////////
	/// COMPONENT RENDER ///////////////////////////////////////
	////////////////////////////////////////////////////////////
	return (
		<TooltipProvider>
			{/* RISK CARD */}
			<div
				className={`flexStartStart ${
					viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view ? 'hideContainer' : ''
				}`}
				style={{
					width: displayWidth,
					height: '100%',
					display: viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view ? 'none' : '',
					padding: '0px 20px',
					gap: '20px',
				}}>
				{/* TITLE AND DESCRIPTION + COMMENTS + EVENTS */}
				<div
					className='flexColStartStart'
					style={{
						height: '100%',
						maxWidth: isReviewMode ? '50%' : '30%',
						width: '100%',
					}}>
					{/* USER AVATAR + DISPLAY ID + TITLE */}
					<div
						className='flexStartStart'
						style={{
							width: '100%',
							marginBottom: '10px',
						}}>
						<TooltipWrapper
							content={userTooltipData?.label}
							tooltipId='risk-action-table-tooltip'>
							{/* USER AVATAR */}
							<CvtAvatar value={userTooltipData} radius={15} />
						</TooltipWrapper>

						{/* DISPLAY ID */}
						<span className='riskBigViewModeDisplayId'>
							{roItem ? roItem.displayId : ''}
						</span>

						{/* TITLE */}
						<span
							className='actionCardInfosTitle'
							style={{
								marginTop: '2px',
							}}>
							{roItem ? roItem.name : ''}
						</span>

						{/* ESCALATED ICON */}
						{isEscalated && (
							<div className='detailedRoActionsTableRoEscalatedContainer flexStartCenter'>
								{/* ESCALATED ARROW */}
								<div
									className='material-icons'
									style={{
										// IF ESCALATED TO INCLUDES THIS WBS ID, THEN SHOW BORDER BOTTOM
										borderBottom:
											roItem &&
											roItem.escalatedTo &&
											roItem.escalatedTo?.includes(wbsId)
												? '2px solid var(--color-bad)'
												: '',
										// IF ESCALATED TO INCLUDES PARENT'S ID THEN SHOW BORDER TOP
										borderTop:
											roItem &&
											roItem.escalatedTo &&
											roItem.escalatedTo?.includes(parentId)
												? '2px solid var(--color-bad)'
												: '',
										fontSize: '17px',
									}}>
									arrow_upward
								</div>
							</div>
						)}
					</div>

					{/* DESCRIPTION + COMMENTS + EVENTS CONTAINER */}
					<RiskCardInfos
						riskOppDispatcher={riskOppDispatcher}
						riskViewModeSelection={riskViewModeSelection}
						roItem={roItem}
						roData={roData}
						currentUser={currentUser}
						megaUsersIds={megaUsersIds}
						parentId={parentId}
						wbsId={wbsId}
						appDispatcher={appDispatcher}
						previousChanges={previousChanges}
						propKeyIncrement={propKeyIncrement}
						usersList={usersList}
						onSaveAddEdit={onSaveAddEdit}
						canModifyRo={canModifyRo}
						projectData={projectData}
						isReadOnlyMode={isReadOnlyMode}
					/>
				</div>

				<div className='flexOne' style={{ height: '100%' }}>
					{viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ? (
						// ACTION PLAN ITEM TABLE
						<CvtItemTable
							title={'Action Plan'}
							data={actionsData}
							itemTitleField='action'
							wbsId={wbsId}
							usersList={usersList}
							megaUsersId={megaUsersId}
							selectedItems={selectedItems}
							searchTextInput={textSearchInput}
							isReadOnlyMode={isReadOnlyMode || !canModifyRo}
							isCurrentUserEditor={canModifyRo}
							CustomItemRightComponents={RiskCardActionsTableItemTags}
							customFilterComponent={customFilterComponent}
							customTitleComponent={customTitleComponent}
							bottomCustomComponent={customBottomComponent}
							onSelectedItemsChanged={onSelectedItemsChanged}
							onSearchTextInputChanged={onSearchTextInputChanged}
							onClickAdd={handleCreateAction}
							onItemClick={onActionPlanItemClick}
						/>
					) : null}
				</div>

				{/* MATRIX */}
				{!isReviewMode ? (
					<RiskCardMatrixImpact
						roItem={roItem}
						handleDrop={handleDrop}
						riskCardImpactProbaOpenView={riskCardImpactProbaOpenView}
						riskOppDispatcher={riskOppDispatcher}
						onSaveAddEdit={onSaveAddEdit}
						canModifyRo={canModifyRo}
					/>
				) : null}
			</div>

			{/* TOOLTIP */}
			<Tooltip id='risk-action-table-tooltip' className='tooltipCommon' />
		</TooltipProvider>
	)
})
