import React, { useCallback, useMemo } from "react";
import { ACTION_DETAILS_KEY_BLOCK } from "../../00-Helpers/ActionsConstants";
import ActionCardDetailsComments from "./ActionCardDetailsComments";
import ActionLinksList from "./ActionLinksList";
import ActionCardDetailsEvents from "./ActionCardDetailsEvents";
import { FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING } from "../../../../../00-Core/Standards";

///////////////////////////////////////////
/// ACTION CARD DETAILS RIGHT COMPONENT ///
///////////////////////////////////////////

export default function ActionCardDetails({
    action,
    actionComments,
    actionEvents,
    editingCommentId,
    projectData,
    isReadOnlyMode,
    viewModeInputComment,
    selected,
    handleSelectOption,
    handleEdit,
    handleUpdateAction,
    handleOpenWbsItem,
    onDeleteComment,
    usersList,
    megaUsersId,
    currentUser,
    displayNone,
    securityGroup,
    actionsDispatcher,
    appDispatcher,
    wbsDispatcher,
    canModifySelected,
}) {
    //////////////////////////////////////////////////////////////////
    /// DATA /////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    //COMMENTS ARRAY -------------------------------------------------
    const commentsArray = useMemo(() => {
        //INIT
        let array = [];

        //TEST IF COMMENTS AVAILABLE
        if (actionComments && JSON.parse(actionComments).length > 0) {
            // CONVERT FROM JSON TO ARRAY AND SORT BY DESCENDING DATE
            array = JSON.parse(actionComments).sort(
                (a, b) => Date.parse(b.createdOn) - Date.parse(a.createdOn)
            );
        }

        //RETURN COMMENTS ARRAY
        return array;
    }, [actionComments]);

    //EVENTS ARRAY ---------------------------------------------------
    const eventsArray = useMemo(() => {
        //INIT
        let array = [];

    //TEST IF COMMENTS AVAILABLE
    if(actionEvents && JSON.parse(actionEvents).length > 0) {

        // CONVERT FROM JSON TO ARRAY AND SORT BY DESCENDING DATE
        array = JSON.parse(actionEvents).filter(event => !event.noDisplay).sort((a,b) => 
            Date.parse(b.createdOn) - Date.parse(a.createdOn)
        );
    }

        //RETURN COMMENTS ARRAY
        return array;
    }, [actionEvents]);

    // ACTION LINKS
    const actionLinks = useMemo(() => {
        if (action) {
            return FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(action.links);
        }

        return [];
    }, [action]);

    //////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const countData = useCallback(
        (arrayKey) => {
            //INIT
            let counter = 0;

            //SWITH ARRAYKEY
            switch (arrayKey) {
                //COMMENTS
                case "comments":
                    counter = commentsArray.length;
                    break;

                //COMMENTS
                case "connections":
                    counter = actionLinks.length;
                    break;

                //COMMENTS
                case "events":
                    counter = eventsArray.length;
                    break;

                default:
                    break;
            }

            //RETURN COUNTER
            return counter;
        },
        [actionLinks.length, commentsArray.length, eventsArray.length]
    );

    //////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER /////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    return (
        <div
            className="actionCardDetails flexColStartStart"
            style={{
                display: displayNone ? "none" : null,
            }}
        >
            {/* LIST TO SELECT OPTIONS */}
            <ul
                className="nav nav-tabs"
                style={{
                    position: "sticky",
                    top: 0,
                    width: "100%",
                    borderTop: "none",
                    borderBottom: "none",
                }}
            >
                {/* MAP DETAIL */}
                {ACTION_DETAILS_KEY_BLOCK.map((item, index) => {
                    //COUNT NUMBER OF ITEMS
                    let counter = countData(item.key);

                    //RENDER
                    return (
                        <li key={"detailTab-" + index} className="nav-item ">
                            <a
                                className={
                                    "nav-link bigComponentNavItem flexStartCenter " +
                                    (selected === item.key ? "active" : "")
                                }
                                href="/#"
                                onClick={(e) => handleSelectOption(e, item.key)}
                            >
                                {/* COUNTER */}
                                <div className="cardDetailsTitleCounter flexCenterCenter">
                                    {counter}
                                </div>

                                {/* CHAPTER NAME */}
                                {item.name}
                            </a>
                        </li>
                    );
                })}
            </ul>

            {/* DISPLAY DETAIL */}
            <div
                className="cardDetailsBlockInfo"
                style={{
                    padding:
                        selected === "connections" && actionLinks.length === 0
                            ? "10px 0px 10px 0px"
                            : "",
                }}
            >
                {/* COMMENTS */}
                {selected === "comments" ? (
                    <ActionCardDetailsComments
                        commentsArray={commentsArray}
                        editingCommentId={editingCommentId}
                        viewModeInputComment={viewModeInputComment}
                        handleEdit={handleEdit}
                        handleUpdateAction={handleUpdateAction}
                        onDeleteComment={onDeleteComment}
                        usersList={usersList}
                        megaUsersId={megaUsersId}
                        currentUser={currentUser}
                        isReadOnlyMode={isReadOnlyMode}
                        actionsDispatcher={actionsDispatcher}
                    />
                ) : null}

                {/* CONNECTION */}
                {selected === "connections" ? (
                    <ActionLinksList
                        links={actionLinks}
                        action={action}
                        projectData={projectData}
                        handleOpenWbsItem={handleOpenWbsItem}
                        isItemClickEnabled={true}
                        securityGroup={securityGroup}
                        currentUserId={currentUser.username}
                        actionsDispatcher={actionsDispatcher}
                        appDispatcher={appDispatcher}
                        wbsDispatcher={wbsDispatcher}
                        canModifySelected={canModifySelected}
                        isReadOnlyMode={isReadOnlyMode}
                    />
                ) : null}

                {/* EVENTS */}
                {selected === "events" ? (
                    <ActionCardDetailsEvents eventsArray={eventsArray} usersList={usersList} />
                ) : null}
            </div>
        </div>
    );
}
