/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTelescopeDataWbsSettings = /* GraphQL */ `
  mutation CreateTelescopeDataWbsSettings(
    $input: CreateTelescopeDataWbsSettingsInput!
    $condition: ModelTelescopeDataWbsSettingsConditionInput
  ) {
    createTelescopeDataWbsSettings(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      domain
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateTelescopeDataWbsSettings = /* GraphQL */ `
  mutation UpdateTelescopeDataWbsSettings(
    $input: UpdateTelescopeDataWbsSettingsInput!
    $condition: ModelTelescopeDataWbsSettingsConditionInput
  ) {
    updateTelescopeDataWbsSettings(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      domain
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteTelescopeDataWbsSettings = /* GraphQL */ `
  mutation DeleteTelescopeDataWbsSettings(
    $input: DeleteTelescopeDataWbsSettingsInput!
    $condition: ModelTelescopeDataWbsSettingsConditionInput
  ) {
    deleteTelescopeDataWbsSettings(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      domain
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createTelescopeDataCharter = /* GraphQL */ `
  mutation CreateTelescopeDataCharter(
    $input: CreateTelescopeDataCharterInput!
    $condition: ModelTelescopeDataCharterConditionInput
  ) {
    createTelescopeDataCharter(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      guid
      displayId
      guidUpdate
      manualUpdate
      leader
      department
      customer
      customerDepartment
      type
      phase
      startDate
      endDate
      teamSize
      stakeholderSize
      budget
      impact
      description
      scope
      contextObjectives
      assumptionsConstraints
      target1
      target2
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const updateTelescopeDataCharter = /* GraphQL */ `
  mutation UpdateTelescopeDataCharter(
    $input: UpdateTelescopeDataCharterInput!
    $condition: ModelTelescopeDataCharterConditionInput
  ) {
    updateTelescopeDataCharter(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      guid
      displayId
      guidUpdate
      manualUpdate
      leader
      department
      customer
      customerDepartment
      type
      phase
      startDate
      endDate
      teamSize
      stakeholderSize
      budget
      impact
      description
      scope
      contextObjectives
      assumptionsConstraints
      target1
      target2
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const deleteTelescopeDataCharter = /* GraphQL */ `
  mutation DeleteTelescopeDataCharter(
    $input: DeleteTelescopeDataCharterInput!
    $condition: ModelTelescopeDataCharterConditionInput
  ) {
    deleteTelescopeDataCharter(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      guid
      displayId
      guidUpdate
      manualUpdate
      leader
      department
      customer
      customerDepartment
      type
      phase
      startDate
      endDate
      teamSize
      stakeholderSize
      budget
      impact
      description
      scope
      contextObjectives
      assumptionsConstraints
      target1
      target2
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const createTelescopeDataShortcut = /* GraphQL */ `
  mutation CreateTelescopeDataShortcut(
    $input: CreateTelescopeDataShortcutInput!
    $condition: ModelTelescopeDataShortcutConditionInput
  ) {
    createTelescopeDataShortcut(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      position
      url
      icon
      backgroundColor
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const updateTelescopeDataShortcut = /* GraphQL */ `
  mutation UpdateTelescopeDataShortcut(
    $input: UpdateTelescopeDataShortcutInput!
    $condition: ModelTelescopeDataShortcutConditionInput
  ) {
    updateTelescopeDataShortcut(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      position
      url
      icon
      backgroundColor
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const deleteTelescopeDataShortcut = /* GraphQL */ `
  mutation DeleteTelescopeDataShortcut(
    $input: DeleteTelescopeDataShortcutInput!
    $condition: ModelTelescopeDataShortcutConditionInput
  ) {
    deleteTelescopeDataShortcut(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      position
      url
      icon
      backgroundColor
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const createTelescopeDataAction = /* GraphQL */ `
  mutation CreateTelescopeDataAction(
    $input: CreateTelescopeDataActionInput!
    $condition: ModelTelescopeDataActionConditionInput
  ) {
    createTelescopeDataAction(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      contributors
      guid
      displayId
      guidUpdate
      manualUpdate
      action
      dueDate
      baseline
      createdAt
      updatedAt
      types
      typeInstanceIds
      realisedDate
      cancelledDate
      responsible
      status
      description
      comments
      progression
      events
      createdOn
      lastModified
      lastModifiedBy
      path
      telescopeDataGovernanceTelescopeDataActionId
      telescopeDataROTelescopeDataActionId
      telescopeDataRO {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        escalatedFrom
        escalatedTo
        initialProba
        initialImpact
        initialCriticity
        currentProba
        currentImpact
        currentCriticity
        targetProba
        targetImpact
        targetCriticity
        endProba
        endImpact
        endCriticity
        initialSchedule
        initialCost
        initialQuality
        initialScope
        currentSchedule
        currentCost
        currentQuality
        currentScope
        targetSchedule
        targetCost
        targetQuality
        targetScope
        performance
        weightedCost
        raisedDate
        lastModification
        createdOn
        createdAt
        updatedAt
        modifiedBy
        modifiedDate
        name
        longTitle
        description
        isRisk
        category
        cause
        effect
        responsible
        status
        strategy
        progressStatus
        path
        comments
        actionsIds
        telescopeDataGovernanceTelescopeDataRoIds
        events
        groupEditors
        groupViewers
      }
      telescopeDataScheduleTelescopeDataActionId
      telescopeDataCostTelescopeDataActionId
      links
      groupEditors
      groupViewers
      isPrivate
    }
  }
`;
export const updateTelescopeDataAction = /* GraphQL */ `
  mutation UpdateTelescopeDataAction(
    $input: UpdateTelescopeDataActionInput!
    $condition: ModelTelescopeDataActionConditionInput
  ) {
    updateTelescopeDataAction(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      contributors
      guid
      displayId
      guidUpdate
      manualUpdate
      action
      dueDate
      baseline
      createdAt
      updatedAt
      types
      typeInstanceIds
      realisedDate
      cancelledDate
      responsible
      status
      description
      comments
      progression
      events
      createdOn
      lastModified
      lastModifiedBy
      path
      telescopeDataGovernanceTelescopeDataActionId
      telescopeDataROTelescopeDataActionId
      telescopeDataRO {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        escalatedFrom
        escalatedTo
        initialProba
        initialImpact
        initialCriticity
        currentProba
        currentImpact
        currentCriticity
        targetProba
        targetImpact
        targetCriticity
        endProba
        endImpact
        endCriticity
        initialSchedule
        initialCost
        initialQuality
        initialScope
        currentSchedule
        currentCost
        currentQuality
        currentScope
        targetSchedule
        targetCost
        targetQuality
        targetScope
        performance
        weightedCost
        raisedDate
        lastModification
        createdOn
        createdAt
        updatedAt
        modifiedBy
        modifiedDate
        name
        longTitle
        description
        isRisk
        category
        cause
        effect
        responsible
        status
        strategy
        progressStatus
        path
        comments
        actionsIds
        telescopeDataGovernanceTelescopeDataRoIds
        events
        groupEditors
        groupViewers
      }
      telescopeDataScheduleTelescopeDataActionId
      telescopeDataCostTelescopeDataActionId
      links
      groupEditors
      groupViewers
      isPrivate
    }
  }
`;
export const deleteTelescopeDataAction = /* GraphQL */ `
  mutation DeleteTelescopeDataAction(
    $input: DeleteTelescopeDataActionInput!
    $condition: ModelTelescopeDataActionConditionInput
  ) {
    deleteTelescopeDataAction(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      contributors
      guid
      displayId
      guidUpdate
      manualUpdate
      action
      dueDate
      baseline
      createdAt
      updatedAt
      types
      typeInstanceIds
      realisedDate
      cancelledDate
      responsible
      status
      description
      comments
      progression
      events
      createdOn
      lastModified
      lastModifiedBy
      path
      telescopeDataGovernanceTelescopeDataActionId
      telescopeDataROTelescopeDataActionId
      telescopeDataRO {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        escalatedFrom
        escalatedTo
        initialProba
        initialImpact
        initialCriticity
        currentProba
        currentImpact
        currentCriticity
        targetProba
        targetImpact
        targetCriticity
        endProba
        endImpact
        endCriticity
        initialSchedule
        initialCost
        initialQuality
        initialScope
        currentSchedule
        currentCost
        currentQuality
        currentScope
        targetSchedule
        targetCost
        targetQuality
        targetScope
        performance
        weightedCost
        raisedDate
        lastModification
        createdOn
        createdAt
        updatedAt
        modifiedBy
        modifiedDate
        name
        longTitle
        description
        isRisk
        category
        cause
        effect
        responsible
        status
        strategy
        progressStatus
        path
        comments
        actionsIds
        telescopeDataGovernanceTelescopeDataRoIds
        events
        groupEditors
        groupViewers
      }
      telescopeDataScheduleTelescopeDataActionId
      telescopeDataCostTelescopeDataActionId
      links
      groupEditors
      groupViewers
      isPrivate
    }
  }
`;
export const createTelescopeDataSchedule = /* GraphQL */ `
  mutation CreateTelescopeDataSchedule(
    $input: CreateTelescopeDataScheduleInput!
    $condition: ModelTelescopeDataScheduleConditionInput
  ) {
    createTelescopeDataSchedule(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      responsible
      createdOn
      path
      type
      actionsIds
      description
      comments
      events
      baselineStart
      baselineFinish
      forecastStart
      forecastFinish
      actualStart
      actualFinish
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const updateTelescopeDataSchedule = /* GraphQL */ `
  mutation UpdateTelescopeDataSchedule(
    $input: UpdateTelescopeDataScheduleInput!
    $condition: ModelTelescopeDataScheduleConditionInput
  ) {
    updateTelescopeDataSchedule(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      responsible
      createdOn
      path
      type
      actionsIds
      description
      comments
      events
      baselineStart
      baselineFinish
      forecastStart
      forecastFinish
      actualStart
      actualFinish
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const deleteTelescopeDataSchedule = /* GraphQL */ `
  mutation DeleteTelescopeDataSchedule(
    $input: DeleteTelescopeDataScheduleInput!
    $condition: ModelTelescopeDataScheduleConditionInput
  ) {
    deleteTelescopeDataSchedule(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      responsible
      createdOn
      path
      type
      actionsIds
      description
      comments
      events
      baselineStart
      baselineFinish
      forecastStart
      forecastFinish
      actualStart
      actualFinish
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const createTelescopeDataRO = /* GraphQL */ `
  mutation CreateTelescopeDataRO(
    $input: CreateTelescopeDataROInput!
    $condition: ModelTelescopeDataROConditionInput
  ) {
    createTelescopeDataRO(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      escalatedFrom
      escalatedTo
      initialProba
      initialImpact
      initialCriticity
      currentProba
      currentImpact
      currentCriticity
      targetProba
      targetImpact
      targetCriticity
      endProba
      endImpact
      endCriticity
      initialSchedule
      initialCost
      initialQuality
      initialScope
      currentSchedule
      currentCost
      currentQuality
      currentScope
      targetSchedule
      targetCost
      targetQuality
      targetScope
      performance
      weightedCost
      raisedDate
      lastModification
      createdOn
      createdAt
      updatedAt
      modifiedBy
      modifiedDate
      name
      longTitle
      description
      isRisk
      category
      cause
      effect
      responsible
      status
      strategy
      progressStatus
      path
      comments
      actionsIds
      telescopeDataAction {
        nextToken
      }
      telescopeDataGovernanceTelescopeDataRoIds
      events
      groupEditors
      groupViewers
    }
  }
`;
export const updateTelescopeDataRO = /* GraphQL */ `
  mutation UpdateTelescopeDataRO(
    $input: UpdateTelescopeDataROInput!
    $condition: ModelTelescopeDataROConditionInput
  ) {
    updateTelescopeDataRO(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      escalatedFrom
      escalatedTo
      initialProba
      initialImpact
      initialCriticity
      currentProba
      currentImpact
      currentCriticity
      targetProba
      targetImpact
      targetCriticity
      endProba
      endImpact
      endCriticity
      initialSchedule
      initialCost
      initialQuality
      initialScope
      currentSchedule
      currentCost
      currentQuality
      currentScope
      targetSchedule
      targetCost
      targetQuality
      targetScope
      performance
      weightedCost
      raisedDate
      lastModification
      createdOn
      createdAt
      updatedAt
      modifiedBy
      modifiedDate
      name
      longTitle
      description
      isRisk
      category
      cause
      effect
      responsible
      status
      strategy
      progressStatus
      path
      comments
      actionsIds
      telescopeDataAction {
        nextToken
      }
      telescopeDataGovernanceTelescopeDataRoIds
      events
      groupEditors
      groupViewers
    }
  }
`;
export const deleteTelescopeDataRO = /* GraphQL */ `
  mutation DeleteTelescopeDataRO(
    $input: DeleteTelescopeDataROInput!
    $condition: ModelTelescopeDataROConditionInput
  ) {
    deleteTelescopeDataRO(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      escalatedFrom
      escalatedTo
      initialProba
      initialImpact
      initialCriticity
      currentProba
      currentImpact
      currentCriticity
      targetProba
      targetImpact
      targetCriticity
      endProba
      endImpact
      endCriticity
      initialSchedule
      initialCost
      initialQuality
      initialScope
      currentSchedule
      currentCost
      currentQuality
      currentScope
      targetSchedule
      targetCost
      targetQuality
      targetScope
      performance
      weightedCost
      raisedDate
      lastModification
      createdOn
      createdAt
      updatedAt
      modifiedBy
      modifiedDate
      name
      longTitle
      description
      isRisk
      category
      cause
      effect
      responsible
      status
      strategy
      progressStatus
      path
      comments
      actionsIds
      telescopeDataAction {
        nextToken
      }
      telescopeDataGovernanceTelescopeDataRoIds
      events
      groupEditors
      groupViewers
    }
  }
`;
export const createTelescopeDataPortfolio = /* GraphQL */ `
  mutation CreateTelescopeDataPortfolio(
    $input: CreateTelescopeDataPortfolioInput!
    $condition: ModelTelescopeDataPortfolioConditionInput
  ) {
    createTelescopeDataPortfolio(input: $input, condition: $condition) {
      id
      reference
      name
      pictureUrl
      status
      official
      shortDescription
      longDescription
      itemViewers
      itemEditors
      createdBy
      selectedProjects
      selectedOrganizations
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTelescopeDataPortfolio = /* GraphQL */ `
  mutation UpdateTelescopeDataPortfolio(
    $input: UpdateTelescopeDataPortfolioInput!
    $condition: ModelTelescopeDataPortfolioConditionInput
  ) {
    updateTelescopeDataPortfolio(input: $input, condition: $condition) {
      id
      reference
      name
      pictureUrl
      status
      official
      shortDescription
      longDescription
      itemViewers
      itemEditors
      createdBy
      selectedProjects
      selectedOrganizations
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTelescopeDataPortfolio = /* GraphQL */ `
  mutation DeleteTelescopeDataPortfolio(
    $input: DeleteTelescopeDataPortfolioInput!
    $condition: ModelTelescopeDataPortfolioConditionInput
  ) {
    deleteTelescopeDataPortfolio(input: $input, condition: $condition) {
      id
      reference
      name
      pictureUrl
      status
      official
      shortDescription
      longDescription
      itemViewers
      itemEditors
      createdBy
      selectedProjects
      selectedOrganizations
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTelescopeDataGovScopeChange = /* GraphQL */ `
  mutation CreateTelescopeDataGovScopeChange(
    $input: CreateTelescopeDataGovScopeChangeInput!
    $condition: ModelTelescopeDataGovScopeChangeConditionInput
  ) {
    createTelescopeDataGovScopeChange(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      title
      level
      status
      description
      choices
      decision
      decisionDetails
      events
      comments
      responsible
      path
      actionsIds
      telescopeDataGovReviewTelescopeDataGovScopeChangeId
      govReviewIds
      dueDate
      closedDate
      createdOn
      createdAt
      updatedAt
      groupEditors
      groupViewers
    }
  }
`;
export const updateTelescopeDataGovScopeChange = /* GraphQL */ `
  mutation UpdateTelescopeDataGovScopeChange(
    $input: UpdateTelescopeDataGovScopeChangeInput!
    $condition: ModelTelescopeDataGovScopeChangeConditionInput
  ) {
    updateTelescopeDataGovScopeChange(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      title
      level
      status
      description
      choices
      decision
      decisionDetails
      events
      comments
      responsible
      path
      actionsIds
      telescopeDataGovReviewTelescopeDataGovScopeChangeId
      govReviewIds
      dueDate
      closedDate
      createdOn
      createdAt
      updatedAt
      groupEditors
      groupViewers
    }
  }
`;
export const deleteTelescopeDataGovScopeChange = /* GraphQL */ `
  mutation DeleteTelescopeDataGovScopeChange(
    $input: DeleteTelescopeDataGovScopeChangeInput!
    $condition: ModelTelescopeDataGovScopeChangeConditionInput
  ) {
    deleteTelescopeDataGovScopeChange(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      title
      level
      status
      description
      choices
      decision
      decisionDetails
      events
      comments
      responsible
      path
      actionsIds
      telescopeDataGovReviewTelescopeDataGovScopeChangeId
      govReviewIds
      dueDate
      closedDate
      createdOn
      createdAt
      updatedAt
      groupEditors
      groupViewers
    }
  }
`;
export const createTelescopeDataGovReview = /* GraphQL */ `
  mutation CreateTelescopeDataGovReview(
    $input: CreateTelescopeDataGovReviewInput!
    $condition: ModelTelescopeDataGovReviewConditionInput
  ) {
    createTelescopeDataGovReview(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      description
      tags
      startDate
      endDate
      objectives
      chairman
      agenda
      attendees
      reviewNotes
      path
      reviewMeetingStatus
      events
      comments
      createdAt
      updatedAt
      createdOn
      recurrencyParentId
      isRecurrencyParent
      periodicity
      endRecurrency
      groupEditors
      groupViewers
    }
  }
`;
export const updateTelescopeDataGovReview = /* GraphQL */ `
  mutation UpdateTelescopeDataGovReview(
    $input: UpdateTelescopeDataGovReviewInput!
    $condition: ModelTelescopeDataGovReviewConditionInput
  ) {
    updateTelescopeDataGovReview(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      description
      tags
      startDate
      endDate
      objectives
      chairman
      agenda
      attendees
      reviewNotes
      path
      reviewMeetingStatus
      events
      comments
      createdAt
      updatedAt
      createdOn
      recurrencyParentId
      isRecurrencyParent
      periodicity
      endRecurrency
      groupEditors
      groupViewers
    }
  }
`;
export const deleteTelescopeDataGovReview = /* GraphQL */ `
  mutation DeleteTelescopeDataGovReview(
    $input: DeleteTelescopeDataGovReviewInput!
    $condition: ModelTelescopeDataGovReviewConditionInput
  ) {
    deleteTelescopeDataGovReview(input: $input, condition: $condition) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      description
      tags
      startDate
      endDate
      objectives
      chairman
      agenda
      attendees
      reviewNotes
      path
      reviewMeetingStatus
      events
      comments
      createdAt
      updatedAt
      createdOn
      recurrencyParentId
      isRecurrencyParent
      periodicity
      endRecurrency
      groupEditors
      groupViewers
    }
  }
`;
