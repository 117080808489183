import { CvtPillKPI, CvtTooltip } from "@mi-gso/cvt";
import React, { useMemo } from "react";
import { SCHEDULE_STATUS_KEYS } from "./00-Helpers/ScheduleConstants";
import { SCHEDULE_FUNC_GET_ITEM_STATUS_AND_DUEDATE } from "./00-Helpers/ScheduleFunctions";

export default function ScheduleMini({ data, bigComponentDisplayed, propKey, cyId }) {
  /////////////////////////////////////////////////////
  // MEMO /////////////////////////////////////////////
  /////////////////////////////////////////////////////

  // SCHEDULE WITH CALCULATED STATUS AND DUE DATE
  // !!! MEMO DOESN'T WORK !!!
  const schedulesWithStatusAndDueDate = data.map((scheduleItem) => {
    // CALCULATE STATUS AND DUE DATE
    return SCHEDULE_FUNC_GET_ITEM_STATUS_AND_DUEDATE(scheduleItem);
  });

  // CALCULATE MINI COMPONENT VALUES
  const miniComponentValues = useMemo(() => {
    // INIT
    let values = {
      notDone: 0,
      done: 0,
      inThePast: 0,
    };

    schedulesWithStatusAndDueDate.map((schedule) => {
      const status = schedule.status.value;

      if (status !== SCHEDULE_STATUS_KEYS.done) {
        values.notDone += 1;
      }

      if (status === SCHEDULE_STATUS_KEYS.done) {
        values.done += 1;
      }
      else if (status === SCHEDULE_STATUS_KEYS.inThePast) {
        values.inThePast += 1;
      }
      return schedule;
    });

    return values;
  }, [schedulesWithStatusAndDueDate]);

  ///////////////////////
  /// TOOLTIP CONTENT ///
  ///////////////////////

  const tooltipContent = useMemo(() => {
    return (
      <div className="flexColStartStart">
        {/* NOT DONE */}
        <span className="actionMiniTooltipSubTitle">
          Not Done: <b>{miniComponentValues.notDone}</b>
        </span>

        {/* DONE */}
        <span className="actionMiniTooltipSubTitle">
          Done: <b>{miniComponentValues.done}</b>
        </span>

        {/* IN THE PAST */}
        <span className="actionMiniTooltipSubTitle">
          In the Past (Late): <b>{miniComponentValues.inThePast}</b>
        </span>
      </div>
    );
  }, [
    miniComponentValues.done,
    miniComponentValues.inThePast,
    miniComponentValues.notDone,
  ]);

  //////////////
  /// RENDER ///
  //////////////

  return (
    <>
      <CvtPillKPI
        values={[
          {
            value:
              miniComponentValues.notDone > 0
                ? miniComponentValues.notDone
                : "",
          },
          {
            value:
              miniComponentValues.done > 0
                ? miniComponentValues.done
                : miniComponentValues.inThePast > 0
                ? 0
                : null,
            color: "var(--color-good)",
          },
          {
            value:
              miniComponentValues.inThePast > 0
                ? miniComponentValues.inThePast
                : 0,
            color: "var(--color-bad)",
          },
        ]}
        tooltipId={propKey}
        testId={cyId}
      />

      {/* TOOLTIP */}
      <CvtTooltip
        anchorId={propKey}
        place="left"
      >
        {tooltipContent}
      </CvtTooltip>
    </>
  );
}
