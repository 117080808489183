// import { useMemo } from "react";

import { useMemo } from "react";
import { TODAY } from "../../../../00-Core/Constants";
import { SCHEDULE_STATUS_KEYS, SCHEDULE_TIMELINE_COLORS } from "../00-Helpers/ScheduleConstants";
import {
    SCHEDULE_FUNC_FIX_OVERLAPS_PERCENTAGE,
    SCHEDULE_FUNC_GET_TIMELINE_LETTERS,
    SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH,
    SCHEDULE_FUNC_SORT_DATES,
    SCHEDULE_FUNC_TIMELINE_DATE_TO_STR,
} from "../00-Helpers/ScheduleFunctions";

export default function ScheduleTaskTimeline({ item }) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // TODAY
    const today = useMemo(() => {
        return new Date(TODAY.setHours(0, 0, 0, 0)).getTime();
    }, []);

    // CALCULATE TIMELINE CONFIGURATION
    const timelineConfig = useMemo(() => {
        // --------- INIT -----------

        let config = {
            baseline: {
                start: null,
                width: null,
            },
            forecast: {
                start: null,
                width: null,
            },
            actual: {
                start: null,
                width: null,
            },
            today: {
                start: null,
                width: null,
            },
        };
        let timeline;

        // SET HOURS TO 0 AND CONVERT DATES TO TIMESTAMPS
        let itemBaselineStart = item.baselineStart.setHours(0, 0, 0, 0);
        let itemBaselineFinish = item.baselineFinish.setHours(0, 0, 0, 0);
        let itemForecastStart = item.forecastStart.setHours(0, 0, 0, 0);
        let itemForecastFinish = item.forecastFinish.setHours(0, 0, 0, 0);
        let itemActualStart = item.actualStart?.setHours(0, 0, 0, 0);
        let itemActualFinish = item.actualFinish?.setHours(0, 0, 0, 0);

        // INIT FIND FIRST AND LAST DATE
        let allDates = null;
        let firstDate = null;
        let lastDate = null;
        let duration = null;

        // -------- SORT ALL DATES TO FIND FIRST AND LAST --------
        allDates = [itemBaselineStart, itemBaselineFinish, itemForecastStart, itemForecastFinish];

        // ADD ACTUAL DATES IF NOT NULL
        if (itemActualStart) {
            allDates.push(itemActualStart);
        }
        if (itemActualFinish) {
            allDates.push(itemActualFinish);
        }

        // FOR STATUS IN THE PAST, ADD TODAY
        if (item.status.value === SCHEDULE_STATUS_KEYS.inThePast) {
            allDates.push(today);
        }

        // SORT DATES
        allDates = SCHEDULE_FUNC_SORT_DATES(allDates);

        // FIND FIRST AND LAST DATE
        firstDate = allDates[0];
        lastDate = allDates[allDates.length - 1];

        // ------ CALCULATE TOTAL DURATION IN MS -------
        duration = lastDate - firstDate;

        // ----- CALCULATE BASELINE CONFIG -------
        timeline = SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH(
            firstDate,
            itemBaselineStart,
            itemBaselineFinish,
            duration
        );

        config.baseline.start = timeline.start;
        config.baseline.width = timeline.width;
        config.baseline.startDate = itemBaselineStart;
        config.baseline.finishDate = itemBaselineFinish;

        // ----- CALCULATE FORECAST CONFIG -------
        timeline = SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH(
            firstDate,
            itemForecastStart,
            itemForecastFinish,
            duration
        );

        config.forecast.start = timeline.start;
        config.forecast.width = timeline.width;
        config.forecast.startDate = itemForecastStart;
        config.forecast.finishDate = itemForecastFinish;

        // ----- CALCULATE ACTUAL CONFIG -------
        let actualStart = itemActualStart;
        let actualFinish = itemActualFinish;

        // IF ACTUAL DATE BUT NOT ACTUAL FINISH
        if (itemActualStart && !itemActualFinish) {
            actualFinish = itemForecastFinish;
        }
        // IF ONLY ACTUAL FINISH
        else if (!itemActualStart && itemActualFinish) {
            actualStart = actualFinish;
        }

        if (itemActualStart || itemActualFinish) {
            timeline = SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH(
                firstDate,
                actualStart,
                actualFinish,
                duration
            );

            config.actual.start = timeline.start;
            config.actual.width = timeline.width;
            config.actual.startDate = actualStart;
            config.actual.finishDate = actualFinish;
        }

        // -------- CALCULATE TODAY TIMELINE ---------

        let todayStartDate;
        let todayFinishDate;

        // If today is one of the dates, don't add it
        if (
            itemBaselineStart === today ||
            itemBaselineFinish === today ||
            itemForecastStart === today ||
            itemForecastFinish === today ||
            (itemActualStart && itemActualStart === today) ||
            (itemActualFinish && itemActualFinish === today)
        ) {
            todayStartDate = null;
            todayFinishDate = null;
        } else if (config.actual.start !== null) {
            // If there's an actual date, today's timeline begins at actualStart
            todayStartDate = itemActualStart;
        } else {
            todayStartDate = itemForecastStart;
        }

        // If status in progress or late, timeline ends at today
        // If status done and last date is in the future
        if (
            item.status.value === SCHEDULE_STATUS_KEYS.inProgress ||
            item.status.value === SCHEDULE_STATUS_KEYS.inThePast ||
            (item.status.value === SCHEDULE_STATUS_KEYS.done && lastDate > today)
        ) {
            todayFinishDate = today;
        }

        // CALCULATE PERCENTAGE FOR START AND WIDTH
        if (todayStartDate && todayFinishDate) {
            timeline = SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH(
                firstDate,
                todayStartDate,
                todayFinishDate,
                duration
            );
            config.today.start = timeline.start;
            config.today.width = timeline.width;
            config.today.date = today;
        }

        // ------------- FIND TIMELINE LETTERS (B/F/A) ------------ //

        // GET TIMELINE START LETTERS
        let startLetters = SCHEDULE_FUNC_GET_TIMELINE_LETTERS(
            itemBaselineStart,
            itemForecastStart,
            itemActualStart
        );

        // GET END LETTERS
        let endLetters = SCHEDULE_FUNC_GET_TIMELINE_LETTERS(
            itemBaselineFinish,
            itemForecastFinish,
            itemActualFinish
        );

        // PUT THEM TO CONFIG
        config.baseline.startLetters = startLetters.baseline;
        config.forecast.startLetters = startLetters.forecast;
        config.actual.startLetters = startLetters.actual;

        config.baseline.endLetters = endLetters.baseline;
        config.forecast.endLetters = endLetters.forecast;
        config.actual.endLetters = endLetters.actual;

        // ---------- CHECK DATES/LETTERS DON'T STEPS ON EACH OTHERS -------------
        config = SCHEDULE_FUNC_FIX_OVERLAPS_PERCENTAGE(config);

        // RETURN
        return config;
    }, [
        item.actualFinish,
        item.actualStart,
        item.baselineFinish,
        item.baselineStart,
        item.forecastFinish,
        item.forecastStart,
        item.status.value,
        today,
    ]);
    
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="scheduleTimelineTaskContainer text_Color2_500_10px"
            style={{
                outline: "1px dotted var(--border-color-gray-1)",
                borderRadius: "10px",
                borderTopRightRadius:
                    item.status.value === SCHEDULE_STATUS_KEYS.inThePast &&
                    timelineConfig.today.start + timelineConfig.today.width === 100
                        ? "0px"
                        : "10px",
                borderBottomRightRadius:
                    item.status.value === SCHEDULE_STATUS_KEYS.inThePast &&
                    timelineConfig.today.start + timelineConfig.today.width === 100
                        ? "0px"
                        : "10px",
            }}
        >
            {/* BASELINE */}
            <div
                className="scheduleTimelineTaskDateRangeContainer flexBetweenCenter"
                style={{
                    marginLeft: timelineConfig.baseline.start + "%",
                    width: timelineConfig.baseline.width + "%",
                    backgroundColor: SCHEDULE_TIMELINE_COLORS.baseline,
                    // zIndex:  timelineConfig.baseline.start,
                }}
            >
                {/* START */}
                <div
                    className="scheduleTimelineTaskDateAndLetter"
                    style={{
                        color:
                            timelineConfig.baseline.startDate === today
                                ? "var(--color-migso-yeallow)"
                                : null,
                    }}
                >
                    {/* LETTER */}
                    <div className="scheduleTimelineTaskLetter"
                        // style={{
                        //     zIndex: timelineConfig.baseline.start + 1
                        // }}
                    >
                        {timelineConfig.baseline.startLetters}
                    </div>

                    {/* DATE */}
                    {timelineConfig.baseline.startLetters ? (
                        <div className="scheduleTimelineTaskDate">
                            {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(item.baselineStart)}
                        </div>
                    ) : null}
                </div>

                {/* END */}
                {timelineConfig.baseline.width > 8 ? (
                    <div
                        className="scheduleTimelineTaskDateAndLetter"
                        style={{
                            color:
                                timelineConfig.baseline.finishDate === today
                                    ? "var(--color-migso-yeallow)"
                                    : null,
                        }}
                    >
                        {/* LETTER */}
                        <div className="scheduleTimelineTaskLetter scheduleTimelineTaskLetterEnd"
                            // style={{
                            //     zIndex: timelineConfig.baseline.start + 1
                            // }}
                        >
                            {timelineConfig.baseline.endLetters}
                        </div>

                        {/* DATE */}
                        {timelineConfig.baseline.endLetters ? (
                            <div className="scheduleTimelineTaskDate">
                                {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(item.baselineFinish)}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>

            {/* FORECAST */}
            <div
                className="scheduleTimelineTaskDateRangeContainer flexBetweenCenter"
                style={{
                    marginLeft: timelineConfig.forecast.start + "%",
                    width: timelineConfig.forecast.width + "%",
                    backgroundColor: SCHEDULE_TIMELINE_COLORS.forecast,
                    // zIndex: timelineConfig.forecast.start,
                }}
            >
                {/* START */}
                <div
                    className="scheduleTimelineTaskDateAndLetter"
                    style={{
                        color:
                            timelineConfig.forecast.startDate === today
                                ? "var(--color-migso-yeallow)"
                                : null,
                    }}
                >
                    {/* LETTER */}
                    <div className="scheduleTimelineTaskLetter"
                        // style={{
                        //     zIndex: timelineConfig.forecast.start + 1
                        // }}
                    >
                        {timelineConfig.forecast.startLetters}
                    </div>

                    {/* DATE */}
                    {timelineConfig.forecast.startLetters ? (
                        <div className="scheduleTimelineTaskDate">
                            {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(item.forecastStart)}
                        </div>
                    ) : null}
                </div>

                {/* END */}
                {timelineConfig.forecast.width > 8 ? (
                    <div
                        className="scheduleTimelineTaskDateAndLetter"
                        style={{
                            color:
                                timelineConfig.forecast.finishDate === today
                                    ? "var(--color-migso-yeallow)"
                                    : null,
                        }}
                    >
                        {/* LETTER */}
                        <div className="scheduleTimelineTaskLetter scheduleTimelineTaskLetterEnd"
                            // style={{
                            //     zIndex: timelineConfig.forecast.start + 1
                            // }}
                        >
                            {timelineConfig.forecast.endLetters}
                        </div>

                        {/* DATE */}
                        {timelineConfig.forecast.endLetters ? (
                            <div className="scheduleTimelineTaskDate">
                                {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(item.forecastFinish)}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>

            {/* ACTUAL */}
            {timelineConfig.actual.start !== null ? (
                <div
                    className="scheduleTimelineTaskDateRangeContainer flexBetweenCenter"
                    style={{
                        marginLeft: timelineConfig.actual.start + "%",
                        width: timelineConfig.actual.width + "%",
                        backgroundColor:
                            item.status.value === SCHEDULE_STATUS_KEYS.done
                                // ? "rgba(13, 110, 253, 1)"
                                ? 'rgba(74, 209, 153, 0.6)'
                                : item.status.value === SCHEDULE_STATUS_KEYS.inProgress
                                ? "rgba(3,169,219, 0.4)"
                                : "rgba(127,189,255, 0.3)",
                        // zIndex:timelineConfig.actual.start
                    }}
                >
                    {/* START */}
                    <div
                        className="scheduleTimelineTaskDateAndLetter"
                        style={{
                            color:
                                timelineConfig.actual.startDate === today
                                    ? "var(--color-migso-yeallow)"
                                    : null,
                        }}
                    >
                        {/* LETTER */}
                        <div className="scheduleTimelineTaskLetter"
                            // style={{
                            //     zIndex: timelineConfig.actual.start + 1
                            // }}
                        >
                            {timelineConfig.actual.startLetters}
                        </div>

                        {/* DATE */}
                        {timelineConfig.actual.startLetters ? (
                            <div className="scheduleTimelineTaskDate">
                                {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(
                                    timelineConfig.actual.startDate
                                )}
                            </div>
                        ) : null}
                    </div>

                    {/* END */}
                    {item.actualFinish &&
                    timelineConfig.actual.finishDate !== timelineConfig.actual.startDate ? (
                        <div
                            className="scheduleTimelineTaskDateAndLetter"
                            style={{
                                color:
                                    timelineConfig.actual.finishDate === today
                                        ? "var(--color-migso-yeallow)"
                                        : null,
                            }}
                        >
                            {/* LETTER */}
                            <div className="scheduleTimelineTaskLetter scheduleTimelineTaskLetterEnd"
                                // style={{
                                //     zIndex: timelineConfig.actual.start + 1
                                // }}
                            >
                                {timelineConfig.actual.endLetters}
                            </div>

                            {/* DATE */}
                            {timelineConfig.actual.endLetters ? (
                                <div className="scheduleTimelineTaskDate">
                                    {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(
                                        timelineConfig.actual.finishDate
                                    )}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* TODAY TIMELINE */}
            {timelineConfig.today.start !== null ? (
                <div
                    className="scheduleTimelineTaskTodayLine flexEndCenter"
                    style={{
                        marginLeft: timelineConfig.today.start + "%",
                        width: timelineConfig.today.width + "%",
                        backgroundColor:
                            item.status.value === SCHEDULE_STATUS_KEYS.inThePast
                                ? "rgba(229, 87, 102,0.5)"
                                : null,
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        // zIndex: timelineConfig.today.start
                    }}
                >
                    {/* DATE */}
                    {timelineConfig.today.start !== null ? (
                        <div
                            className="scheduleTimelineTaskDate scheduleTimelineTaskDateAndLetter"
                            style={{
                                top: "18px",
                                marginRight: "-17px",
                                color: "var(--color-migso-yeallow)",
                                backgroundColor: "white",
                                // zIndex: timelineConfig.today.start + 1
                            }}
                        >
                            {SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(timelineConfig.today.date)}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
