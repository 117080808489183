// REACT
import { memo, useCallback } from "react";
// BEATIFUL DND
import {Draggable} from "react-beautiful-dnd";
import { CvtTooltip } from "@mi-gso/cvt";

// FUNCTIONS
import { FUNC_ACTION_IS_LATE } from "../../../01-Action/00-Helpers/ActionsFunctions";
import { canUserEditRoItem } from "../../00-Helpers/RisksFunctions";

////////////////////////////
/// RISK MATRIX ITEM /// ///
////////////////////////////

export default memo(function RiskMatrixItem({
  matrixSetting,
  roItems,
  actionsData,
  isRisk,
  wbsId,
  parentId,
  droppableProps,
  droppableRef,
  providedPlaceholder,
  isDragging,
  isDragOver,
  riskOppDispatcher,
  detailedRoMatrixItem,
  isExpanded,
  textSearchInput,
  isCurrentUserEditor,
  currentUser,
  isReadOnlyMode,
}) {

  /////////////////////////////////////////////////////
  /// FUNCTIONS ///////////////////////////////////////
  /////////////////////////////////////////////////////

  // HANDLE SEE ACTIONS DETAILS TABLE
  const handleSetDetailedRoItem = useCallback((roItem) => {
    riskOppDispatcher({
      type: "SET_STATE_OBJECT",
      object: {
           detailedRoMatrixItem: detailedRoMatrixItem && detailedRoMatrixItem.id === roItem.id ? null : roItem,
           selected: [],
           actionsTextSearchInput: '',
           statusSearchInput: [],
      },
    });
  }, [detailedRoMatrixItem, riskOppDispatcher]);


  /////////////////////////////////////////////////////
  /// COMPONENT RENDER ////////////////////////////////
  /////////////////////////////////////////////////////

  return (
    <div
      className="riskMatrixItemContainer"
      ref={droppableRef}
      {...droppableProps}
      style={{
        background:
          isRisk === true
            ? matrixSetting.riskColor
            : matrixSetting.opportunityColor,
        boxShadow: isDragOver === true ? "1px 1px 2px 1px rgba(0, 0, 0,0.5)" : "",
        borderColor: isDragOver===true ? "var(--color-migso-blue)" : "",
        zIndex: isDragOver===true ? "2": "",
        maxHeight: isExpanded ? null : "48px",
        ...droppableProps.style
      }}
    >
      {/* MATRIX ITEM GRID */}
      <div className="riskMatrixItemGrid">
        {/* MAP THE RO ITEMS (2 per row)*/}
        {roItems.map((roItem, index) => {
          let actionsConnected =
            roItem.actionsIds && roItem.actionsIds.length > 0
              ? actionsData.filter((action) =>
                  roItem.actionsIds?.includes(action.id)
                )
              : [];

          let lateActionsCount =
            actionsConnected.length > 0
              ? actionsConnected.filter((action) => {
                  let isLate = FUNC_ACTION_IS_LATE(
                    action.status,
                    action.dueDate
                  );

                  return isLate;
                }).length
              : 0;

          return (
            <Draggable
              key={`risk_matrix_item_grid_ro_item_${roItem.id.toString()}`}
              draggableId={roItem.id.toString()}
              index={index}
              isDragDisabled={isReadOnlyMode || !canUserEditRoItem(
                isCurrentUserEditor,
                currentUser.username,
                roItem,
              )}
            >
              {(provided, snapshot) => (
                <div
                  draggable
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  className="riskMatrixRoItemContainer flexCenterCenter"
                  onClick={() => handleSetDetailedRoItem(roItem)}
                >
                  <div
                    key={`risk_matrix_item_grid_ro_item_${roItem.id.toString()}`}
                    style={{
                        zIndex: snapshot.isDragging === true ? "999": "",
                        transform: "none",
                        backgroundColor: detailedRoMatrixItem && detailedRoMatrixItem.id === roItem.id ? "var(--color-migso-blue)"
                          : textSearchInput.length > 0 ? "var(--bg-color-selected-2)" :  "",
                        // color: (detailedRoMatrixItem && detailedRoMatrixItem.id === roItem.id) ? 'white' : textSearchInput.length > 0 ? 'var(--color-text-2)' : '',
                        borderColor: lateActionsCount > 0 ? "var(--color-bad)" : "",
                    }}
                    className="riskMatrixRoItem flexStartCenter"

                    id={`risk_matrix_item_grid_ro_item_${roItem.id.toString()}`}
                  >
                    {/* ID */}
                    <span className="riskMatrixRoItemId" style={{color: (detailedRoMatrixItem && detailedRoMatrixItem.id === roItem.id) ? 'white' : textSearchInput.length > 0 ? 'var(--color-text-2)' : '',
                        borderColor: lateActionsCount > 0 ? "var(--color-bad)" : "", }}>
                      {roItem.displayId}
                    </span>

                    {/* ESCALATED THING */}
                    {roItem.escalatedTo && (
                      <span
                        className="material-icons riskMatrixRoItemEscalated"
                        style={{
                          borderBottom:
                            roItem.escalatedTo &&
                            roItem.escalatedTo?.includes(wbsId)
                              ? "2px solid var(--color-clash)"
                              : "",
                          borderTop:
                            roItem.escalatedTo &&
                            roItem.escalatedTo?.includes(parentId)
                              ? "2px solid var(--color-clash)"
                              : "",
                        }}
                      >
                        arrow_upward
                      </span>
                    )}

                    {/* TOOLTIP IF ANY ACTIONS */}
                    {
                      roItem.actionsIds && roItem.actionsIds.length > 0 ?
                      <CvtTooltip
                        anchorId={`risk_matrix_item_grid_ro_item_${roItem.id.toString()}`}
                        tooltipContent={`
                          Actions connected: ${roItem.actionsIds.length}/
                          Late Actions connected: ${lateActionsCount}/
                        `}
                      />
                    :null}
                  </div>
                </div>
              )}
            </Draggable>
          );
        })}
      </div>

      {/* BOTTOM LEFT CRITICITY */}
      <span className="riskMatrixItemCriticity">{matrixSetting.criticity}</span>

      {providedPlaceholder}
    </div>
  );
});