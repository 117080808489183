import React from "react";

//CORE
import { CvtButton, CvtInput } from "@mi-gso/cvt";

//COMPONENT
export default function HeaderUserInfosEditName({
  isEditMode,
  name,
  firstName,
  lastName,
  onChange,
  handleUpdateAttributeName,
  errName,
  loader,
  handleEdit,
  updated,
}) {
  return (
    <React.Fragment>
      {/* SWITCH DISPLAY OR EDIT MODE --------------------------------------------*/}
      {isEditMode ? (
        <React.Fragment>
          <div className="flexBetweenStart" style={{ width: "100%" }}>
            {/* BLOCK INPUT */}
            <div style={{ width: "100%" }}>
              {/* INPUT MODAL */}
              <CvtInput
                legend="User Name"
                legendWidth={80}
                placeholder="Enter your perferred username"
                inputBlockCss="flexBetweenCenter modalOptionSubBlock"
                inputBlockPadding="2px 20px 5px 20px"
                inputCss="form-control appAuthenticatorInput"
                name="name"
                maxLength={30}
                value={name}
                onChange={onChange}
                inputFontColor={errName ? "var(--color-bad)" : null}
                inputWidthFull={true}
              />

              {/* INPUT MODAL */}
              <CvtInput
                legend="First Name"
                legendWidth={80}
                placeholder="What is your First Name ?"
                inputBlockCss="flexBetweenCenter modalOptionSubBlock"
                inputBlockPadding="2px 20px 5px 20px"
                inputCss="form-control appAuthenticatorInput"
                name="custom:firstName"
                maxLength={30}
                value={firstName}
                onChange={onChange}
                inputWidthFull={true}
              />

              {/* INPUT MODAL */}
              <CvtInput
                legend="Last Name"
                legendWidth={80}
                placeholder="What is your Last Name ?"
                inputBlockCss="flexBetweenCenter modalOptionSubBlock"
                inputBlockPadding="2px 20px 5px 20px"
                inputCss="form-control appAuthenticatorInput"
                name="custom:lastName"
                maxLength={30}
                value={lastName}
                onChange={onChange}
                inputWidthFull={true}
              />
            </div>

            {/* BUTTON GROUP */}
            <div className="flexBetweenCenter" style={{ marginTop: "5px" }}>
              {/* SUBMIT */}
              <CvtButton
                color={errName ? "red" : "green"}
                loader={loader}
                icon="check"
                disabled={
                  !updated || name === "" || firstName === "" || lastName === ""
                }
                handleSubmit={handleUpdateAttributeName}
              />

              {/* CANCEL */}
              <CvtButton
                color="gray"
                outline
                customHover="red"
                icon="cancel"
                handleSubmit={handleEdit}
                handleSubmitOptions=""
                margin="0px 20px 0px 10px"
              />
            </div>
          </div>

          {/* NAME ALREADY USED */}
          {errName ? (
            <div
              style={{
                fontSize: "12px",
                fontWeight: 500,
                marginLeft: "20px",
                color: "var(--color-bad)",
              }}
            >
              {errName}
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <div
          className="flexBetweenStart"
          style={{
            padding: "5px 20px 5px 20px",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            {/* NAME */}
            <div className="flexStartCenter">
              User Name
              <span
                style={{
                  fontWeight: 500,
                  margin: "0px 10px",
                }}
              >
                {name}
              </span>
            </div>

            <div className="flexStartCenter">
              First Name
              <span
                style={{
                  fontWeight: 500,
                  margin: "15px 10px",
                }}
              >
                {firstName}
              </span>
            </div>

            <div className="flexStartCenter">
              Last Name
              <span
                style={{
                  fontWeight: 500,
                  margin: "0px 10px",
                }}
              >
                {lastName}
              </span>
            </div>
          </div>

          {/* EDIT BUTTON */}
          <CvtButton
            color="gray"
            customHover="blue"
            outline
            icon="edit"
            handleSubmit={handleEdit}
            handleSubmitOptions="name"
          />
        </div>
      )}

      {/* SEPERATOR */}
      <div className="modalSeparator"></div>
    </React.Fragment>
  );
}
