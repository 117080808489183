import _ from "lodash";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../00-Wbs/00-Helpers/WbsConstants";
import WbsElementBigStructure from "../00-Wbs/01-Components/WbsElementBigStructure";
import { EDIT_SIDE_BAR_DATA_PREPARATION } from "../07-EditSideBar/00-Helpers/EditSideBarFunctions";
import EditSideBar from "../07-EditSideBar/EditSideBar";
import govReducer, { DEFAULT_SCOPE_CHANGE_ITEM } from "./00-Helpers/GovDispatcher";
import GovActionSideBar from "./GovActionSideBar";
import { v4 as uuid } from "uuid";
import {
    createTelescopeDataGovScopeChange as CreateTelescopeDataGovScopeChange,
    updateTelescopeDataGovScopeChange as UpdateTelescopeDataGovScopeChange,
    updateTelescopeDataGovReview as UpdateTelescopeDataGovReview,
    deleteTelescopeDataGovScopeChange,
    updateTelescopeDataGovReview,
    updateTelescopeDataAction,
} from "../../../graphql/mutations";
import { DEFAULT_STATE_MOVE_MODAL_OPTIONS, TODAY } from "../../../00-Core/Constants";
import { multipleMutateGraphql } from "../../00-App/02-Backend/AppBackendCommon";
import WbsElementStructureBody from "../00-Wbs/01-Components/WbsElementStructureBody";
import GovScopeTable from "./01-Components/00-GovScope/GovScopeTable";
import GovReviewCalendar from "./01-Components/01-GovReview/GovReviewCalendar";
import GovReviewViewMode from "./01-Components/01-GovReview/GovReviewViewMode";
import GovScopeView from "./01-Components/00-GovScope/GovScopeView";
import {
    FUNCT_FIND_INDEX,
    FUNC_BUILD_DISPLAY_ID_STR,
    FUNC_DATE_TO_TXT_STANDARD,
    FUNC_GET_USER_INFO,
    FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING,
    FUNC_SAFE_GET_JSON_FROM_STRING,
} from "../../../00-Core/Standards";
import GovReviewDeleteSeriesModal from "./01-Components/01-GovReview/GovReviewSeriesModal";
import {
    GOV_FUNC_SAVE_SCOPE_CHANGE,
} from "./00-Helpers/GovScopeFunctions";
import {
    DELETE_GOV_REVIEW_WITH_LINKS,
    DELETE_RECURRENT_GOV_REVIEW_AND_LINKS,
    GET_MUTATION_TO_DELETE_SEVERAL_ITEMS,
    GOV_FUNC_SAVE_REVIEW,
    GET_ALL_REVIEWS,
} from "./00-Helpers/GovReviewFunctions";
import { GOV_COMPONENT_EXPAND_TYPES, GOV_ITEM_VIEW_TYPE } from "./00-Helpers/GovConstants";
import {
    GOV_SCOPE_DISPLAY_ID_START,
    GOV_SCOPE_EDIT_SIDEBAR_DATA,
    GOV_SCOPE_LEVELS,
    GOV_SCOPE_STATUS,
} from "./00-Helpers/GovScopeConstants";
import {
    GOV_REVIEW_DEFAULT_PERIODICITY,
    GOV_REVIEW_EDIT_SIDEBAR_DATA,
    GOV_REVIEW_EDIT_SIDEBAR_GROUPS,
    GOV_REVIEW_MEETING_STATUS_KEY,
    GOV_REVIEW_MODAL_TYPE,
    GOV_REVIEW_SERIES_MODAL_OPTIONS,
} from "./00-Helpers/GovReviewConstants";
import {
    FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND,
    GOV_FUNC_GET_NEXT_DISPLAY_ID,
    GOV_FUNC_GET_REVIEW_LABEL,
    GOV_GET_INITIAL_STATE,
    MERGE_GOV_REVIEW_MUTATIONS,
} from "./00-Helpers/GovFunctions";
import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import { APP_PORTFOLIO_DISPLAY_CONFIG } from "../../00-App/00-Helpers/AppInitState"; 
import { WBS_ON_BACK_SIDEBAR, WBS_OPEN_ITEM } from "../00-Wbs/00-Helpers/WbsFunctions";
import { ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST } from "../01-Action/00-Helpers/ActionsFunctions";

/////////////////// COMPONENT /////////////////////
export default function GovBig({
    // DATA
    govScopeChanges,
    govReviews,
    isReviewMode,
    projectData,
    wbsSettings,
    biggestDisplayIdScopeChange,
    currentPath,
    rightComponentData,
    projectChildIds,
    isProjectElement,

    // UPDATE TRACKERS
    govReviewUpdateTracker,
    govScopeChangeUpdateTracker,

    // STRUCTURE
    wbsName,
    projectId,
    wbsId,
    selectedOrganizationId,

    // BIG COMPONENT
    fieldInformation,
    handleBigComponent,
    bigComponentExpandFunction,
    bigComponentIsExpanded,
    isReadOnlyMode,
    wbsNavigationOptions,

    // USER
    currentUser,
    securityGroup,
    usersList,
    megaUsersIds,

    // DISPATCHER
    appDispatcher,
    wbsDispatcher,

    // IF PASSED SELECTED FROM ABOVE.
    moveModalOptionsItemsArray,
}) {
   
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////
    const [state, govDispatcher] = useReducer(
        govReducer,
        GOV_GET_INITIAL_STATE(
            isReviewMode,
            wbsNavigationOptions,
            projectData.govReview,
            projectData.govScopeChange,
            usersList,
            securityGroup,
            appDispatcher
        )
    );

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // EDIT SIDE BAR SELECT LEVEL OPTIONS DATA
    const editSideBarSelectLevel = useMemo(() => {
        return GOV_SCOPE_LEVELS.map((item) => ({
            // id: item.id,
            value: item.id,
            label: item.label,
        }));
    }, []);

    // EDIT SIDE BAR SELECT STATUS OPTIONS DATA
    const editSideBarSelectStatus = useMemo(() => {
        return GOV_SCOPE_STATUS.map((item) => ({
            // id: item.id,
            value: item.id,
            label: item.label,
        }));
    }, []);

    // EDIT SIDE BAR SCOPE INSTANCES LINKED
    const editSideBarScopeInstances = useMemo(() => {
        return govReviews.map((review) => {
            let label = GOV_FUNC_GET_REVIEW_LABEL(review)

            return {
                value: review.id,
                label: label,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [govReviews, govReviews.length]);

    // GOV REVIEW ATTENDEES LIST OPTIONS
    const attendeesOptions = useMemo(() => {
        return usersList.map((user) => ({
            label: user.label,
            value: user.value,
        }));
    }, [usersList]);

    // FILTERED SCOPE CHANGES
    const filteredGovScopes = useMemo(() => {
        if (govScopeChangeUpdateTracker) {
            // SO ESLINT IS HAPPY
            // JUST TO TRIGGER THE MEMO IF DATA IS UPDATED
        }

        let tmpFilteredScopes = _.cloneDeep(govScopeChanges);

        // FILTER CONSOLIDATED ITEMS
        if (!state.isConsolidate) {
            tmpFilteredScopes = tmpFilteredScopes.filter((scope) => wbsId === scope.wbsId);
        }

        // LEVEL FILTERS
        if (state.levelFilters.length > 0) {
            tmpFilteredScopes = tmpFilteredScopes.filter((scope) =>
                state.levelFilters?.includes(scope.level)
            );
        }

        // STATUS FILTERS
        if (state.statusFilters.length > 0) {
            tmpFilteredScopes = tmpFilteredScopes.filter((scope) =>
                state.statusFilters?.includes(scope.status)
            );
        }

        // FILTER ON TEXT
        if (state.textSearchInput !== "") {
            //CREATE MAP OF SEARCH
            const searchQueryWords = state.textSearchInput
                .split(" ")
                .map((word) => word.toLowerCase())
                .filter((word) => word !== " ");

            //GET FILTERED SCOPES
            tmpFilteredScopes = tmpFilteredScopes.filter((scope) => {
                //INIT
                let title = scope.title?.toLowerCase() ?? "";
                let description = scope.description?.toLowerCase() ?? "";

                //RETURN
                return searchQueryWords.every((searchQueryWord) => {
                    if (
                        title.indexOf(searchQueryWord) !== -1 ||
                        description.indexOf(searchQueryWord) !== -1
                    ) {
                        return true;
                    }
                    return false;
                });
            });
        }

        return tmpFilteredScopes;
    }, [
        govScopeChanges,
        govScopeChangeUpdateTracker,
        state.isConsolidate,
        state.levelFilters,
        state.statusFilters,
        state.textSearchInput,
        wbsId,
    ]);
    
    const canModifySelected = useMemo(() => {
        if(state.govItemView) {
            return (
                state.govItemView.responsible === currentUser.username ||
                state.govItemView.createdBy === currentUser.username ||
                securityGroup.isCurrentUserEditor
            );
        }

        // LOOP SELECTED
        for(const scopeChange of state.selectedItems) {
            if(
                scopeChange.responsible !== currentUser.username  &&
                scopeChange.createdBy !== currentUser.username &&
                !securityGroup.isCurrentUserEditor
            ) {
                return false;
            }
        }
        return true;
    }, [currentUser.username, securityGroup.isCurrentUserEditor, state.govItemView, state.selectedItems]);

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // OPEN ADD EDIT RIGHT SIDE BAR FOR SCOPES
    const onScopeAddEditClick = useCallback(
        (govItem) => {
            //INIT
            let govItemEdit = govItem ? _.cloneDeep(govItem) : null;

            //PREPARER USERLIST FOR DROPDOWN
            let editSideBarUsersList = usersList.map((user) => ({
                id: user.value,
                label: user.label,
            }));

            // CONVERT REVIEW INSTANCES FROM ID TO SELECT VALUE
            if (govItemEdit && govItemEdit.govReviewIds) {
                govItemEdit.govReviewIds = FUNC_SAFE_GET_JSON_FROM_STRING(govItemEdit.govReviewIds);

                // IF NULL INIT TO EMPTY ARRAY
                if (!govItemEdit.govReviewIds) {
                    govItemEdit.govReviewIds = [];
                }

                // REPLACE ID BY VALUE/LABEL OBJECT
                govItemEdit.govReviewIds = govItemEdit.govReviewIds.map((reviewId) =>
                    FIND_OBJECT_ARRAY_ITEM(editSideBarScopeInstances, "value", reviewId)
                );
            }

            // IF CAN'T FIND USER IN THE USERS LIST, PASS THE RANDOM MEGA USER
            if (
                govItemEdit !== null &&
                !usersList.find((user) => user.value === govItemEdit.responsible)
            ) {
                //GET USERS OBJECT
                let userInCore = FUNC_GET_USER_INFO(
                    usersList,
                    megaUsersIds,
                    govItemEdit.responsible
                );

                //UPDATE IT
                govItemEdit.responsible = userInCore.value;
            }

            //KEEP ONLY THE RESPONSIBLE
            if (
                !securityGroup.isCurrentUserEditor &&
                govItemEdit &&
                govItemEdit.responsible &&
                govItemEdit.responsible !== "" &&
                govItemEdit.responsible !== currentUser.username
            ) {
                let findUser = usersList.find((user) => user.value === govItemEdit.responsible);
                editSideBarUsersList = [
                    {
                        id: findUser.value,
                        label: findUser.label,
                    },
                ];
            }

            // PREPARE DATA TO FEED TO EDIT SIDE BAR
            let editSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
                govItemEdit ? govItemEdit : _.cloneDeep(DEFAULT_SCOPE_CHANGE_ITEM), // DATA
                GOV_SCOPE_EDIT_SIDEBAR_DATA,
                {
                    id: govItemEdit ? govItemEdit.id : uuid(),
                    organizationId: selectedOrganizationId,
                    projectId: projectId,
                    wbsId: govItemEdit ? govItemEdit.wbsId : wbsId,
                    displayId: govItemEdit
                        ? govItemEdit.displayId
                        : FUNC_BUILD_DISPLAY_ID_STR(
                              GOV_SCOPE_DISPLAY_ID_START,
                              biggestDisplayIdScopeChange
                          ),
                    isNew: govItemEdit ? false : true, //VERY IMPORTANT
                },
                [
                    {
                        key: "level",
                        value: editSideBarSelectLevel,
                    },
                    {
                        key: "status",
                        value: editSideBarSelectStatus,
                    },
                    {
                        key: "govReviewIds",
                        value: editSideBarScopeInstances,
                    },
                    {
                        key: "responsible",
                        value: editSideBarUsersList,
                    },
                ]
            );

            // CHANGE VIEW MODE
            let viewMode;
            if (govItem) {
                viewMode = WBS_BIG_COMPONENT_VIEW_MODE.edit;
            } else {
                viewMode = WBS_BIG_COMPONENT_VIEW_MODE.add;
            }

            // PASS EDIT SIDE BAR CONTENT TO STATE, AND MAKE editSideBar TRUE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    editSideBarContent: editSideBarContent,
                    isEditSideBarOpen: true,
                    viewMode: viewMode,
                    govItemView: state.govItemView ? govItemEdit : null,
                    selectedItems:
                        viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add ? [] : state.selectedItems,
                    govItemType: "scope",
                },
            });
        },
        [
            biggestDisplayIdScopeChange,
            currentUser.username,
            editSideBarScopeInstances,
            editSideBarSelectLevel,
            editSideBarSelectStatus,
            megaUsersIds,
            projectId,
            securityGroup.isCurrentUserEditor,
            selectedOrganizationId,
            state.govItemView,
            state.selectedItems,
            usersList,
            wbsId,
        ]
    );

    // OPEN GOV REVIEW MODE
    const onOpenReviewMode = useCallback(
        (govReviewItem, enableEdit) => {
            // UPDATE GOV STATE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    expandMode: GOV_COMPONENT_EXPAND_TYPES.calendar,
                },
            });

            // SET DESTINATION
            let options = {};
            options.goToItemId = govReviewItem.id;
            options.goToDomain = "govReview";

            // OPEN THE DESTINATION ITEM
            WBS_OPEN_ITEM(
                projectData,
                "govReview",
                govReviewItem.id,
                null,
                options,
                wbsDispatcher,
                appDispatcher,
                securityGroup
            );
        },
        [appDispatcher, projectData, securityGroup, wbsDispatcher]
    );

    // OPEN ADD EDIT RIGHT SIDE BAR FOR REVIEWS
    const onReviewAddEditClick = useCallback(
        (govItem, isNew, selectedModalOption) => {
            //INIT
            let govItemEdit = _.cloneDeep(govItem);

            // IF RECURRENCY CHILD THEN MAKE IT BE THE PARENT
            // ALSO IF CHOSE WHOLE SERIES ON THE MODAL
            if (
                govItemEdit.recurrencyParentId &&
                state.modalType &&
                state.modalType === GOV_REVIEW_MODAL_TYPE.edit &&
                selectedModalOption &&
                selectedModalOption === GOV_REVIEW_SERIES_MODAL_OPTIONS.wholeSeries
            ) {
                let parentReview = govReviews.find(
                    (review) => review.id === govItemEdit.recurrencyParentId
                );
                if (parentReview) {
                    govItemEdit = _.cloneDeep({
                        ...parentReview,
                        endRecurrency: FUNC_DATE_TO_TXT_STANDARD(
                            new Date(parentReview.endRecurrency)
                        ),
                    });
                }
            }

            // OPEN REVIEW MODE IF REVIEW HAVE BEGUN
            if (
                (govItemEdit.reviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.closed ||
                    govItemEdit.reviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.done) &&
                !govItemEdit.isRecurrencyParent
            ) {
                onOpenReviewMode(govItemEdit, true);

                // CLOSE MODAL
                govDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        isShowModal: false,
                        modalReview: null,
                        selectedModalOption: selectedModalOption,
                    },
                });

                return null;
            }

            // PARSE GOV ITEM TAGS
            if (govItemEdit.tags && govItemEdit.tags.length > 0) {
                govItemEdit.tags = JSON.parse(govItemEdit.tags);
                govItemEdit.tags = govItemEdit.tags.map((item) => ({
                    value: item,
                    label: item,
                }));
            } else {
                govItemEdit.tags = [];
            }

            // PARSE ATTENDEES AND GET USER DATA FOR MULTI SELECT
            if (govItemEdit.attendees && govItemEdit.attendees.length > 0) {
                // PARSE JSON
                govItemEdit.attendees = JSON.parse(govItemEdit.attendees);

                // GET USERS LABELS
                govItemEdit.attendees = govItemEdit.attendees.map((attendee) => {
                    let findIndex = FUNCT_FIND_INDEX(usersList, "value", attendee.id);

                    return {
                        value: attendee.id,
                        label: usersList[findIndex].label,
                    };
                });
            } else {
                govItemEdit.attendees = [];
            }

            // PARSED AND CREATE SETTINGS TAGS OPTIONS
            let parsedSettingTags = [];
            if (wbsSettings.tags) {
                parsedSettingTags = JSON.parse(wbsSettings.tags).map((item) => ({
                    value: item,
                    label: item,
                }));
            }

            if (govItemEdit.endRecurrency) {
                govItemEdit.endRecurrency = FUNC_DATE_TO_TXT_STANDARD(
                    new Date(govItemEdit.endRecurrency)
                );
            }

            // IF NEW, SET DEFAULT PERIODICITY BASED ON START DATE
            let defaultPeriodicity = null;
            if (isNew || govItemEdit.isRecurrencyParent === false) {
                // FIND FIRST DAY
                let startDate = new Date(govItemEdit.startDate);

                // GET WEEK DAY NAME
                let weekDay = startDate
                    .toLocaleDateString("en-GB", { weekday: "long" })
                    .toLowerCase();

                defaultPeriodicity = _.cloneDeep(GOV_REVIEW_DEFAULT_PERIODICITY);
                defaultPeriodicity.weekDays[weekDay] = true;

                govItemEdit.periodicity = defaultPeriodicity;
            } else {
                // PARSE PERIODICITY
                let parsedPeriodicity =
                    typeof govItemEdit.periodicity === "string"
                        ? JSON.parse(govItemEdit.periodicity)
                        : govItemEdit.periodicity;
                govItemEdit.periodicity = parsedPeriodicity;
            }

            // PREPARE DATA TO FEED TO EDIT SIDE BAR
            let editSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
                govItemEdit, // DATA
                GOV_REVIEW_EDIT_SIDEBAR_DATA,
                {
                    id: isNew ? uuid() : govItemEdit.id,
                    organizationId: selectedOrganizationId,
                    projectId: projectId,
                    wbsId: wbsId,
                    isNew: isNew ? true : false, //VERY IMPORTANT
                },
                [
                    {
                        key: "tags",
                        value: parsedSettingTags,
                    },
                    {
                        key: "attendees",
                        value: attendeesOptions,
                    },
                ]
            );

            // CALCULATE VIEW MODE
            let viewMode;
            if (isNew) {
                viewMode = WBS_BIG_COMPONENT_VIEW_MODE.add;
            } else {
                viewMode = WBS_BIG_COMPONENT_VIEW_MODE.list;
            }

            // DISABLED RECURRENCY IF EDITING  SINGLE RECURRENCY CHILD
            if (
                selectedModalOption &&
                selectedModalOption === GOV_REVIEW_SERIES_MODAL_OPTIONS.onlyThis &&
                govItem?.recurrencyParentId !== null
            ) {
                // FIND INDEX
                let recurrencyToggleIndex = editSideBarContent.findIndex(
                    (item) => item.key === "isRecurrencyParent"
                );

                // REMOVE
                editSideBarContent.splice(recurrencyToggleIndex, 1);
            }

            // PASS EDIT SIDE BAR CONTENT TO STATE, AND MAKE editSideBar TRUE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    editSideBarContent: editSideBarContent,
                    editSideBarGroup: GOV_REVIEW_EDIT_SIDEBAR_GROUPS,
                    isEditSideBarOpen: true,
                    govItemView: govItemEdit ? govItemEdit : null,
                    viewMode: viewMode,
                    govItemType: "review",
                    isShowModal: false,
                    modalReview: null,
                    selectedModalOption: selectedModalOption,
                },
            });
        },
        [
            attendeesOptions,
            govReviews,
            onOpenReviewMode,
            projectId,
            selectedOrganizationId,
            state.modalType,
            usersList,
            wbsId,
            wbsSettings.tags,
        ]
    );

    // SAVE SCOPE CHANGE ITEM FROM SIDE BAR
    const onSaveAddEditScopeChange = useCallback(
        (e, itemMutateObject, isNew) => {
            // CALL SAVING FUNCTION
            GOV_FUNC_SAVE_SCOPE_CHANGE(
                currentPath,
                state.govItemView,
                projectData,
                isNew,
                itemMutateObject,
                rightComponentData,
                securityGroup,
                usersList,
                currentUser,
                appDispatcher,
                govDispatcher,
                state.propKeyView
            );
        },
        [appDispatcher, currentPath, currentUser, projectData, rightComponentData, securityGroup, state.govItemView, state.propKeyView, usersList]
    );

    // SAVE REVIEWS ITEM FROM SIDE BAR
    const onSaveAddEditReview = useCallback(
        (e, itemMutateObject, isNew) => {
            GOV_FUNC_SAVE_REVIEW(
                currentPath,
                currentUser,
                projectData,
                isNew,
                itemMutateObject,
                securityGroup,
                state.modalType,
                state.selectedModalOption,
                wbsSettings,
                appDispatcher,
                govDispatcher
            );
        },
        [
            appDispatcher,
            currentPath,
            currentUser,
            projectData,
            securityGroup,
            state.modalType,
            state.selectedModalOption,
            wbsSettings,
        ]
    );

    // RESET EDIT SIDE BAR
    const onResetEditSideBar = useCallback(() => {
        let newViewMode = state.viewMode;

        // FROM REVIEW EDIT MODE
        if (
            state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit &&
            state.govItemView &&
            state.govItemType === "review"
        ) {
            newViewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
        }
        // FROM ADD MODE
        else if (state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add) {
            newViewMode = WBS_BIG_COMPONENT_VIEW_MODE.list;
        }
        // FROM SCOPE EDIT MODE
        else if (
            state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit &&
            state.govItemType === "scope"
        ) {
            if (state.govItemView) {
                newViewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
            } else {
                newViewMode = WBS_BIG_COMPONENT_VIEW_MODE.list;
            }
        }

        govDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                isEditSideBarOpen: false,
                viewMode: newViewMode,
                editSideBarContent: null,
                editSideBarGroup: null,
                selectedItems: [],
                govItemView:
                    newViewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ? state.govItemView : null,
                govItemType:
                    state.govItemView && state.govItemType !== GOV_ITEM_VIEW_TYPE.review
                        ? state.govItemType
                        : null,
            },
        });
    }, [state.govItemType, state.govItemView, state.viewMode]);

    // DELETE BTN CLICKED FOR SCOPE CHANGES
    const onDeleteScopeChangeSideBar = useCallback(
        (e) => {
            e.preventDefault();

            //INIT
            let finalMutation = {
                graphql: {
                    govReview: {
                        query: updateTelescopeDataGovReview,
                        objects: [],
                    },
                    govScopeChange: {
                        query: deleteTelescopeDataGovScopeChange,
                        objects: [],
                    },
                    action: {
                        query: updateTelescopeDataAction,
                        objects: [],
                    },
                },
                dispatcher: {
                    govReview: {
                        update: [],
                    },
                    govScopeChange: {
                        delete: [],
                    },
                    action: {
                        update: [],
                    },
                },
            };
            let actionFound;
            let updatedActions = [];

            // GET SELECTED SCOPE
            let selectedScopesToDelete;

            // IF ITEM VIEW NOT NULL DELETE CURRENT SCOPE CHANGE
            if (state.govItemView) {
                selectedScopesToDelete = [state.govItemView];
            }
            //IF NOT DELETE ALL SELECTED ACTIONS
            else {
                selectedScopesToDelete = state.selectedItems;
            }

            // GET MUTATIONS TO UPDATE AGENDAS AND DELETE SCOPE ITEM
            let scopeChangesDeleteMutations = GET_MUTATION_TO_DELETE_SEVERAL_ITEMS(
                selectedScopesToDelete,
                "govScopeChange",
                projectData
            );

            // MERGE MUTATION TO FINAL
            finalMutation = MERGE_GOV_REVIEW_MUTATIONS(finalMutation, scopeChangesDeleteMutations);

            // GET MUTATION TO UPDATE ACTION LINKS FOR EACH SCOPE CHANGE
            selectedScopesToDelete.forEach((scopeItemToDelete) => {
                // PARSE SCOPE ACTIONS LINKS IDS
                scopeItemToDelete.actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
                    scopeItemToDelete.actionsIds
                );

                // FOR EACH ACTIONS IDS, REMOVE THE CURRENT SCOPE ITEM
                scopeItemToDelete.actionsIds.forEach((actionId) => {
                    // GET THE ACTION OBJECT
                    actionFound = FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND(
                        updatedActions,
                        projectData.action,
                        "id",
                        actionId
                    );

                    // IF NOT FOUND, CONTINUE TO NEXT ONE
                    if (!actionFound) {
                        return;
                    }

                    // UPDATE ACTION LINKS LIST
                    actionFound.data.links = ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(
                        actionFound.data.links,
                        scopeItemToDelete.id
                    );

                    // IF ITEM COME FROM UPDATED ITEMS, UPDATE IT
                    if (actionFound.fromFirstArray) {
                        updatedActions[actionFound.index] = actionFound.data;
                    }
                    // OTHERWISE, ADD ITEM TO UPDATED ITEMS
                    else {
                        updatedActions.push(actionFound.data);
                    }
                });
            });

            // ADD A MUTATION FOR EACH UPDATED ACTIONS
            updatedActions.forEach((updatedAction) => {
                finalMutation.dispatcher.action.update.push(updatedAction);
                finalMutation.graphql.action.objects.push({
                    id: updatedAction.id,
                    links: updatedAction.links,
                });
            });

            // UPDATE BACKEND
            for (let queryName of Object.keys(finalMutation.graphql)) {
                // CALL GRAPHQL
                multipleMutateGraphql(
                    finalMutation.graphql[queryName].query,
                    finalMutation.graphql[queryName].objects,
                    appDispatcher
                );
            }

            //UPDATE APP STATE
            appDispatcher({
                type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
                object: finalMutation.dispatcher,
            });

            //UPDATE THIS STATE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selectedItems: [],
                    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
                    govItemView: null,
                },
            });
        },
        [appDispatcher, projectData, state.govItemView, state.selectedItems]
    );

    // DUPLICATE A SCOPE CHANGE FROM SIDEBAR
    const onDuplicateSideBar = useCallback(() => {
        //INIT
        let mutateObject = [];
        let currentScopeChange = [];

        //INIT EVENTS
        let eventsUpdated = [
            {
                user: {
                    id: currentUser.username,
                    label: currentUser.name,
                },
                createdOn: new Date(),
                attribute: "createdBy",
                value: "created by " + currentUser.name,
            },
        ];

        //IF ACTION VIEW NOT NULL DUPPLICATE CURRENT ACTION
        if (state.govItemView) {
            currentScopeChange = [_.cloneDeep(state.govItemView)];

            //GET SELECTED ACTION
        } else {
            currentScopeChange = _.cloneDeep(state.selectedItems);
        }

        //INIT biggestDisplayIdNum
        let newDisplayId = GOV_FUNC_GET_NEXT_DISPLAY_ID(
            govScopeChanges,
            GOV_SCOPE_DISPLAY_ID_START
        );

        //LOOP ON SELETED
        for (let i = 0; i < currentScopeChange.length; i++) {
            //INIT
            let updatedScope = _.cloneDeep(currentScopeChange[i]);

            delete updatedScope.govItemType;

            //MUTATE OBJECT
            mutateObject.push({
                ...updatedScope,
                id: uuid(),
                displayId: newDisplayId,
                createdBy: currentUser.username,
                createdOn: TODAY,
                events: JSON.stringify(eventsUpdated),
                title: "(Duplicate) - " + updatedScope.title,
                govReviewIds: [],
                groupEditors: securityGroup.groupEditors,
                groupViewers: securityGroup.groupViewers,
            });

            newDisplayId = GOV_FUNC_GET_NEXT_DISPLAY_ID(mutateObject, GOV_SCOPE_DISPLAY_ID_START);
        }

        // SEND GRAPHQL REQUEST
        multipleMutateGraphql(CreateTelescopeDataGovScopeChange, mutateObject, appDispatcher);

        // UPDATE PROJECT DATA IN APP LEVEL
        appDispatcher({
            type: "SET_PROJECT_DATA_CREATE",
            source: "govScopeChange",
            objects: mutateObject,
        });

        //IF ACTION VIEW // UPDATE ACTION FOR ACTION CARD
        govDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                selectedItems: [],
                govItemView: state.govItemView ? mutateObject[0] : null,
                scrollToId: state.govItemView ? null : mutateObject[0].id,
            },
        });
    }, [
        appDispatcher,
        currentUser.name,
        currentUser.username,
        govScopeChanges,
        securityGroup.groupEditors,
        securityGroup.groupViewers,
        state.govItemView,
        state.selectedItems,
    ]);

    //HANDLE EXPAND ARROW CLICK
    const onExpandArrowClick = useCallback(
        (originComponent) => {
            //INIT
            let newExpandMode;

            // FROM TABLE
            if (originComponent === GOV_COMPONENT_EXPAND_TYPES.table) {
                if (isReviewMode || state.widthSizeLimit) {
                    newExpandMode = GOV_COMPONENT_EXPAND_TYPES.calendar;
                } else {
                    newExpandMode =
                        state.expandMode === GOV_COMPONENT_EXPAND_TYPES.table
                            ? GOV_COMPONENT_EXPAND_TYPES.both
                            : GOV_COMPONENT_EXPAND_TYPES.table;
                }
            }
            // FROM CALENDAR
            else if (originComponent === GOV_COMPONENT_EXPAND_TYPES.calendar) {
                if (isReviewMode || state.widthSizeLimit) {
                    newExpandMode = GOV_COMPONENT_EXPAND_TYPES.table;
                } else {
                    newExpandMode =
                        state.expandMode === GOV_COMPONENT_EXPAND_TYPES.calendar
                            ? GOV_COMPONENT_EXPAND_TYPES.both
                            : GOV_COMPONENT_EXPAND_TYPES.calendar;
                }
            }

            //UPDATE ACTION STATE
            govDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: "expandMode",
                value: newExpandMode,
            });
        },
        [isReviewMode, state.expandMode, state.widthSizeLimit]
    );

    //GO BACK TO CHART AND TABLE
    const onBackSideBar = useCallback(() => {
        // CHECK IF BACK BUTTON HAVE A DESTINATION ITEM ASSOCIATED
        let backButtonResult = WBS_ON_BACK_SIDEBAR(
            wbsNavigationOptions,
            projectData,
            projectId,
            wbsDispatcher,
            appDispatcher
        );

        // IF BACK BUTTON LEADS TO AN OTHER ITEM, EXIT FUNCTION
        if (backButtonResult) {
            return;
        }

        govDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
                scrollToId: state.govItemView ? state.govItemView.id : null,
                govItemView: null,
                govItemType: null,
                selectedViewModeContent: null,
            },
        });

        // RESET WBS SELECTED ITEM
        wbsDispatcher({
            type: "SET_STATE_KEY_VALUE",
            key: "selectedItem",
            value: null,
        });
    }, [
        appDispatcher,
        projectData,
        projectId,
        state.govItemView,
        wbsDispatcher,
        wbsNavigationOptions,
    ]);

    // OPEN SCOPE CHANGE VIEW MODES
    const onOpenScopeViewMode = useCallback(
        (govReviewItem) => {
            //  INIT
            let updatedGovItem = _.cloneDeep(govReviewItem);

            // SET NEW STATE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.view,
                    govItemView: updatedGovItem,
                    selectedItems: [],
                    govItemType: GOV_ITEM_VIEW_TYPE.scope,
                },
            });

            // UPDATE WBS SELECTED ITEM
            wbsDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: "selectedItem",
                value: govReviewItem.id,
            });
        },
        [wbsDispatcher]
    );
   
    // ON DELETE GOV REVIEW FROM CONTEXT MENU
    const onDeleteGovReview = useCallback(
        async (e, govReviewItem) => {
            e.preventDefault();

                //CLOSE THE GOVREVIEWMODE STATE WHEN DELETING THE MEETING IF THE SAME MEETING IS ON THE GOVREVIEWMODE
                const isTheSameReview = govReviewItem.id === rightComponentData?.govItem?.id;
                
                // IF IS, THEN CLOSE THE RIGHT COMPONENT
                if(isTheSameReview){
                   
                    appDispatcher({
                        type: "SET_GLOBAL_STATE",
                        options: {
                            source: "portfolioDisplay",
                            object: _.cloneDeep(APP_PORTFOLIO_DISPLAY_CONFIG),
                        },
                    });

                    wbsDispatcher({
                        type: "SET_STATE_KEY_VALUE",
                        key: "rightComponent",
                        value: APP_PORTFOLIO_DISPLAY_CONFIG.rightComponent
                    })
                }
            
            // DELETE THIS REVIEW WITH ADD THE CHILDREN MUTATIONS
            // IF DELETING WHOLE RECURRENCY
            DELETE_GOV_REVIEW_WITH_LINKS(govReviewItem, projectData, appDispatcher);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appDispatcher, projectData, rightComponentData]
    );

    //MANAGE CONSOLIDATE
    const onConsolidate = useCallback(() => {
        govDispatcher({
            type: "SET_STATE_KEY_VALUE",
            key: "isConsolidate",
            value: !state.isConsolidate,
        });
    }, [state.isConsolidate]);

    // SHOW MOVE MODAL
    const handleMoveItemsClicked = useCallback(
        (e, clickedReview) => {
            e.preventDefault();
            appDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                    source: "appSettings",
                    object: {
                        actionModal: "wbsMove",
                        moveModalOptions: {
                            itemsArray: clickedReview
                                ? [clickedReview]
                                : state.govItemView && state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
                                ? [state.govItemView]
                                : state.selectedItems,
                            tableName: clickedReview ? "govReview" : "govScopeChange",
                            query: clickedReview
                                ? UpdateTelescopeDataGovReview
                                : UpdateTelescopeDataGovScopeChange,
                            propertiesToDelete: ["govItemType"],
                        },
                    },
                },
            });
        },
        [appDispatcher, state.govItemView, state.selectedItems, state.viewMode]
    );

    // CONFIRM DELETE SERIES MODAL CALLBACK
    const handleConfirmDeleteSeries = useCallback(
        (e, selectedOption) => {
            e.preventDefault();

            if (selectedOption === GOV_REVIEW_SERIES_MODAL_OPTIONS.onlyThis) {
                // DELETE THE SINGLE REVIEW
                DELETE_GOV_REVIEW_WITH_LINKS(state.modalReview, projectData, appDispatcher);
            } else {
                // ELSE DELETE ALL RECURRENCY
                DELETE_RECURRENT_GOV_REVIEW_AND_LINKS(
                    state.modalReview,
                    projectData,
                    appDispatcher
                );
            }

            // CHECK IF REVIEW OPEN IN RIGHT COMPONENT (IF EXISTS) IS THE ONE BEING DELETED
            let isCloseReviewMode;
            
            if(selectedOption === GOV_REVIEW_SERIES_MODAL_OPTIONS.onlyThis) {
                isCloseReviewMode = state.modalReview.id === rightComponentData?.govItem?.id;
            }
            else {
             
                const allReviews = GET_ALL_REVIEWS(state.modalReview, projectData).map((review) => review.id);

                isCloseReviewMode = allReviews?.includes(rightComponentData?.govItem?.id);
            }

            // IF IS, THEN CLOSE THE RIGHT COMPONENT
            if(isCloseReviewMode) {
                appDispatcher({
                    type: "SET_GLOBAL_STATE",
                    options: {
                        source: "portfolioDisplay",
                        object: _.cloneDeep(APP_PORTFOLIO_DISPLAY_CONFIG),
                    },
                });

                wbsDispatcher({
                    type: "SET_STATE_KEY_VALUE",
                    key: "rightComponent",
                    value: APP_PORTFOLIO_DISPLAY_CONFIG.rightComponent,
                });
            }

            // UPDATE GOV STATE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    isShowModal: false,
                    modalReview: null,
                    modalType: null,
                    isLoading: false,
                },
            });
        },
        [appDispatcher, projectData, rightComponentData?.govItem?.id, state.modalReview, wbsDispatcher]
    );

    // CANCEL DELETION
    const handleCancelModal = useCallback((e) => {
        e.preventDefault();

        govDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                isShowModal: false,
                modalReview: null,
                modalType: null,
            },
        });
    }, []);

    // HANDLE SHOW DELETE SERIES
    const handleShowModal = useCallback((e, modalReview, modalType) => {
        if (e) {
            e.preventDefault();
        }
        govDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                isShowModal: true,
                modalReview: modalReview,
                modalType: modalType,
            },
        });
    }, []);

    /////////////////////////////////////////////////////
    // USE EFFECTS //////////////////////////////////////
    /////////////////////////////////////////////////////

    // MANAGE CONFIRMING MOVE
    useEffect(() => {
        // IF NOT NULL AND EMPTY (CONFIRMED MOVE MODAL)
        if (moveModalOptionsItemsArray && moveModalOptionsItemsArray.length === 0) {
            // UPDATE BIG COMPONENT STATE
            govDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selectedItems: [],
                    govItemView: null,
                    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
                },
            });
            // UPDATE APP STATE
            appDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                    source: "appSettings",
                    object: {
                        moveModalOptions: DEFAULT_STATE_MOVE_MODAL_OPTIONS,
                    },
                },
            });
        }
    }, [appDispatcher, moveModalOptionsItemsArray]);

    // UPDATE ITEM CARD VIEW IF NAVIGATION OPTIONS UPDATED WHEN ALREADY IN CARD VIEW
    useEffect(() => {
        if (
            wbsNavigationOptions?.goToItemId &&
            state.govItemView?.id !== wbsNavigationOptions.goToItemId
        ) {
            
            // IF SCOPE CHANGE
            if(wbsNavigationOptions.isScopeChange) {
                const scopeChange =  FIND_OBJECT_ARRAY_ITEM(
                    govScopeChanges,
                    "id",
                    wbsNavigationOptions.goToItemId
                );
                if(scopeChange) {
                    onOpenScopeViewMode(scopeChange);
                }
                // COULDN'T FIND
                else {
                    // ITEM NOT FOUND -> TOAST
                    appDispatcher({
                        type: "SET_GLOBAL_STATE",
                        options: {
                            source: "appToast",
                            object: {
                                display: true,
                                color: "danger",
                                message:
                                    "Item not found in the WBS. " 
                                    // +
                                    // (wbsFilters && wbsFilters.length > 0
                                    //     ? "Filters enabled, maybe this item is currently hidden"
                                    //     : ""),
                            },
                        },
                    });
                }
            }
            // FIND REVIEW
            else {
                // GET REVIEW
                const reviewItem = FIND_OBJECT_ARRAY_ITEM(govReviews, "id", wbsNavigationOptions.goToItemId);

                // IF FOUND
                if(reviewItem &&
                    wbsId === reviewItem?.wbsId) {
                    // IF NEEDS TO OPEN SIDE BAR
                    if(wbsNavigationOptions.isOpenSideBar) {
                        // IF NEEDS TO SHOW RECURRENT MODAL
                        if(
                            (reviewItem.recurrencyParentId || reviewItem.isRecurrencyParent )
                        ) 
                        {
                            handleShowModal(null, reviewItem, GOV_REVIEW_MODAL_TYPE.edit);
                        } 
                        // ELSE JUST OPEN EDIT SIDE BAR DIRECTLY  
                        else {
                            onReviewAddEditClick(reviewItem ,false, null);
                        }
                    }
                    //TODO TO BE SPECIFIED
                }
                else {
                    // ITEM NOT FOUND -> TOAST
                    appDispatcher({
                        type: "SET_GLOBAL_STATE",
                        options: {
                            source: "appToast",
                            object: {
                                display: true,
                                color: "danger",
                                message:
                                    "Item not found in the WBS. " 
                                    // +
                                    // (wbsFilters && wbsFilters.length > 0
                                    //     ? "Filters enabled, maybe this item is currently hidden"
                                    //     : ""),
                            },
                        },
                    });
                }
            }

            // CLEAN
            wbsDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: "wbsNavigationOptions",
                value: null,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wbsNavigationOptions]);
  
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <>
            {/* BIG COMPONENT STANDARD STRUCTURE */}
            <WbsElementBigStructure
                fieldInformation={fieldInformation}
                handleBigComponent={handleBigComponent}
                projectTitle={wbsName}
                buttonExpand={true}
                isExpendedEnabled={bigComponentIsExpanded}
                functionExpand={(e) => bigComponentExpandFunction(e, state)}
                tooltipInfo="Governance"
            >
                {/* LEFT SIDE BAR  ----------------------------------------------*/}
                <GovActionSideBar
                    canModifySelected={canModifySelected}
                    viewMode={state.viewMode}
                    selectedItems={state.selectedItems}
                    govItemView={state.govItemView}
                    govItemType={state.govItemType}
                    isCurrentUserEditor={securityGroup.isCurrentUserEditor}
                    currentUserName={currentUser.username}
                    isReadOnlyMode={isReadOnlyMode}
                    isConsolidate={state.isConsolidate}
                    currentUser={currentUser}
                    onAddEditClick={onScopeAddEditClick}
                    onDeleteSideBar={onDeleteScopeChangeSideBar}
                    onDuplicateSideBar={onDuplicateSideBar}
                    onBackSideBar={onBackSideBar}
                    onConsolidate={onConsolidate}
                    handleMoveItemsClicked={handleMoveItemsClicked}
                />

                {/* BIG COMPONENT BODY ------------------------------------------*/}
                <WbsElementStructureBody key={"actionsBigComponentBody" + wbsId}>
                    {/* REVIEW MODES */}
                    {state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view &&
                    state.govItemType === GOV_ITEM_VIEW_TYPE.review ? (
                        // REVIEWS VIEW MODE
                        <GovReviewViewMode
                            scopeChanges={govScopeChanges}
                            govItemView={state.govItemView}
                            editMode={state.isReviewEditModeEnabled}
                            usersList={usersList}
                        />
                    ) : state.govItemType === GOV_ITEM_VIEW_TYPE.scope &&
                      (state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view || state.govItemView) ? (
                        // SCOPE CHANGE VIEW MODE
                        <GovScopeView
                            key={`gov_scope_view_${state.propKeyView}`}
                            govItem={state.govItemView}
                            usersList={usersList}
                            currentUser={currentUser}
                            megaUsersId={megaUsersIds}
                            onSaveAddEdit={onSaveAddEditScopeChange}
                            isReadOnlyMode={isReadOnlyMode}
                            canModifyScopeChange={canModifySelected}
                            selectedViewModeContent={state.selectedViewModeContent}
                            selectedViewModeContentLeft={state.selectedViewModeContentLeft}
                            projectData={projectData}
                            securityGroup={securityGroup}
                            appDispatcher={appDispatcher}
                            wbsDispatcher={wbsDispatcher}
                            govDispatcher={govDispatcher}
                        />
                    ) : (
                        <>
                            {state.expandMode === GOV_COMPONENT_EXPAND_TYPES.table ||
                            state.expandMode === GOV_COMPONENT_EXPAND_TYPES.both ? (
                                <GovScopeTable
                                    isReadOnlyMode={isReadOnlyMode}
                                    isExpanded={bigComponentIsExpanded}
                                    filteredGovScopes={filteredGovScopes}
                                    govScopesLength={govScopeChanges.length}
                                    levelFilters={state.levelFilters}
                                    statusFilters={state.statusFilters}
                                    selectLevelOptions={editSideBarSelectLevel}
                                    selectStatusOptions={editSideBarSelectStatus}
                                    textSearchInput={state.textSearchInput}
                                    selectedItems={state.selectedItems}
                                    expandMode={state.expandMode}
                                    currentUser={currentUser}
                                    usersList={usersList}
                                    megaUsersIds={megaUsersIds}
                                    wbsId={wbsId}
                                    onClickAdd={() => onScopeAddEditClick()}
                                    onItemClick={onOpenScopeViewMode}
                                    onExpandArrowClick={onExpandArrowClick}
                                    onAddEditClick={onScopeAddEditClick}
                                    govDispatcher={govDispatcher}
                                    isCurrentUserEditor={securityGroup.isCurrentUserEditor}
                                    govScopeChanges={govScopeChanges}
                                />
                            ) : null}

                            {state.expandMode === GOV_COMPONENT_EXPAND_TYPES.calendar ||
                            state.expandMode === GOV_COMPONENT_EXPAND_TYPES.both ? (
                                <GovReviewCalendar
                                    events={govReviews}
                                    wbsDispatcher={wbsDispatcher}
                                    expandMode={state.expandMode}
                                    megaUsersIds={megaUsersIds}
                                    currentUser={currentUser}
                                    isReadOnlyMode={isReadOnlyMode}
                                    onAddEditClick={onReviewAddEditClick}
                                    onExpandArrowClick={onExpandArrowClick}
                                    onOpenReviewMode={onOpenReviewMode}
                                    onDeleteGovReview={onDeleteGovReview}
                                    handleMoveItemsClicked={handleMoveItemsClicked}
                                    isCurrentUserEditor={securityGroup.isCurrentUserEditor}
                                    handleShowModal={handleShowModal}
                                    isLoading={state.isLoading}
                                    key={`gov_review_calendar_${wbsId}_${state.isLoading}`}
                                    updateTracker={govReviewUpdateTracker}
                                    wbsId={wbsId}
                                    projectData={projectData}
                                    appDispatcher={appDispatcher}
                                    securityGroup={securityGroup}
                                />
                            ) : null}
                        </>
                    )}
                </WbsElementStructureBody>
            </WbsElementBigStructure>

            {/* EDIT SIDE BAR */}
            <EditSideBar
                display={state.isEditSideBarOpen}
                content={state.editSideBarContent}
                title={state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add ? "Create" : "Edit"}
                onSave={
                    state.govItemType === GOV_ITEM_VIEW_TYPE.scope
                        ? onSaveAddEditScopeChange
                        : onSaveAddEditReview
                }
                resetEditSideBar={onResetEditSideBar}
                currentUser={currentUser}
                firstAddUpdatedFields={
                    state.govItemType === GOV_ITEM_VIEW_TYPE.scope
                        ? ["dueDate", "status", "level"]
                        : ["startDate", "endDate", "periodicity"]
                }
                group={state.editSideBarGroup}
                projectData={projectData}
            />

            {state.isShowModal ? (
                <GovReviewDeleteSeriesModal
                    handleConfirmDelete={handleConfirmDeleteSeries}
                    handleCancel={handleCancelModal}
                    modalReview={state.modalReview}
                    govReviews={govReviews}
                    modalType={state.modalType}
                    onAddEditClick={onReviewAddEditClick}
                />
            ) : null}
        </>
    );
}
