import React from 'react'
import {CvtTooltip} from '@mi-gso/cvt'


// TIME LINE LEGEND COMPONENT
export default function ScheduleTimeLineLegend() {

    //TOOLTIP
    const tooltipContent =
            (<div className="flexColStartStart">

                {/* OPEN */}
                <span className="actionMiniTooltipTitle">
                    Task
                </span>

                {/* ON TRACK */}
                <span className="actionMiniTooltipSubTitle">
                    <b>B</b> : Baseline Start - Finish
                </span>

                {/* In PROGRESS */}
                <span className="actionMiniTooltipSubTitle">
                    <b>F </b>: Forecast Start - Finish
                </span>

                {/* LATE */}
                <span className="actionMiniTooltipSubTitle">
                    <b>A</b> : Actual Start - Finish
                </span>

                <span className="actionMiniTooltipTitle">
                    Milestone
                </span>

                {/* ON TRACK */}
                <span className="actionMiniTooltipSubTitle">
                    <b>B</b> : Baseline Finish
                </span>

                {/* In PROGRESS */}
                <span className="actionMiniTooltipSubTitle">
                    <b>F</b> : Forecast Finish
                </span>

                {/* LATE */}
                <span className="actionMiniTooltipSubTitle">
                    <b>A</b> : Actual Finish
                </span>
            </div>)


    // RENDER
    return (
        <div
            className="flexStartCenter"
            id="ScheduleTimeLineLegend"
            style={{
                fontSize:"11px",
                color:"var(--color-text-5)",
                fontWeight:500,
                marginRight:"5px"
            }}
        >
            {/* ICON */}
            <span
                className="material-icons alertIconInfoLigth"
                style={{
                    marginRight:"4px",
                    fontSize:"22px",
                    opacity:0.7
                }}
            >
                info_outline
            </span>

            {/* TITLE */}
            Timeline Legend

            {/* TOOLTIP */}
            <CvtTooltip
                anchorId="ScheduleTimeLineLegend"
            >
                {tooltipContent}
            </CvtTooltip>
        </div>
    )
}
