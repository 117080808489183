//AMPLIFY
import {Auth} from '@aws-amplify/auth'
import _ from 'lodash';

//WBS
import {
    WBS_DATA_QUERIES_NEW_WAY,
    WBS_DOMAIN_DETAILS
} from '../../02-Portfolio/00-Wbs/00-Helpers/WbsConstants'

//CORE
import { FUNCT_FIND_INDEX } from '../../../00-Core/Standards';

//GRAPHQL
import {
    wbsSettingsByProjectId as WbsSettingsByProjectId,
} from "../../../graphql/queries";

//AUTH
import {authCleanUserDataCookie} from '../01-Auth/00-Functions/AuthSignOut'
import { getProjectWbs } from './AppBackendCore';
import { batchQueryGraphql } from './AppBackendCommon';


///////////////////////////////////
// SUMMARY
// - getProjectDatatNextToken
// - getProjectData
// - getProjectWbs
///////////////////////////////////

//GET ALL PROJECT DATA ITEM WITH NEXT TOKEN - RECURSIVE 
const getProjectDataNextToken = async (
    queries,
    results
)=>{

    try {

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        /// GET ONLY QUERIES WITH NEXT TOKEN //////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        //GET ONLY QUERIES WITH NEXT TOKEN
        let nextQueries = []
        for (let i = 0; i < results.length; i++) {

            //IS THER A NEXT TOKEN
            if(!queries[i].other && results[i].data[queries[i].queryName].nextToken){ 
                
                //INTEGRATE DATA
                nextQueries.push({
                    ...queries[i],
                    nextToken: results[i].data[queries[i].queryName].nextToken
                })
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        /// RUN PROMISE FOR NEXT TOKEN ////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        if(nextQueries.length > 0){

            ///////////////////////////////////////////////////////////////////////////////////////////////////
            /// MANAGE QUERIES BATCH //////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////


            const batchQueryObjects = nextQueries.map((nextQuery) => {
                return {
                    queryName: nextQuery.queryName,
                    options: {
                        limit: 100,
                        projectId: nextQuery.projectId,
                        nextToken: nextQuery.nextToken,
                    }
                }
            })


            ///////////////////////////////////////////////////////////////////////////////////////////////////
            /// LAUNCH GRAPHQL ////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////

            // let data = await Promise.all(
            //     nextQueries.map(item =>  
            //         API.graphql(
            //             graphqlOperation(
            //                 item.query, 
            //                 { 
            //                     limit: 100, 
            //                     projectId: item.projectId,
            //                     nextToken: item.nextToken 
            //                 }
            //             )
            //         )
            //     )
            // );

            const data = await batchQueryGraphql(batchQueryObjects);

            ///////////////////////////////////////////////////////////////////////////////////////////////////
            /// RECURSIVITY ///////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////
            
            // Do the request with the token
            const nextData = await getProjectDataNextToken(
                nextQueries, 
                _.cloneDeep(data)
            );

            let dataCloned = _.cloneDeep(data)

            // Add the new items to the data
            if(nextData && nextData.data.length > 0){

                //INTEGRATION
                for (let j = 0; j < nextData.data.length; j++) {
                    
                    //FIND INDEX TO DATA
                    let currentQueryName = nextData.queries[j].queryName
                    let currentProjectId = nextData.data[j].data[currentQueryName].items[0].projectId

                    //FIND WHERE ADD NEW ITEM IN DATA WIT HTHE COUPLE QUERY NAME AND PROJECT ID
                    for (let k = 0; k < dataCloned.length; k++) {

                        //TETS IF CONDITION OK
                        if(dataCloned[k].data[currentQueryName] 
                        && dataCloned[k].data[currentQueryName].items.length > 0
                        && dataCloned[k].data[currentQueryName].items[0].projectId === currentProjectId){

                            //PUSH NEW ITEMS
                            dataCloned[k].data[currentQueryName].items.push(...nextData.data[j].data[currentQueryName].items)
                            break;
                        }                
                    }
                }
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////
            /// RETURN ////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////
            
            // Return the final data
            return {
                data: dataCloned,
                queries: nextQueries
            }

        //NO MORE DATA TO FETCH
        }else{
            return null;
        }

    /////////////
    /// ISSUE ///
    /////////////

    } catch (err) {
      console.log(err);
    }
}

//GET PROJECT DATA (INCLUDING PORTFOLIO & ORGANIZATION)
export const getProjectData = async (
    projectIds,
    portfolioId,
    projects
)=>{
    
    //OK CALL 
    try{

        //INIT
        let wbsActiveQueries = [];
        let findIndex;
        let results = [];

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        /// PROJECT DATA QUERIES //////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        //MANAGE ENABLED QUERIES
        for (let i = 0; i < WBS_DATA_QUERIES_NEW_WAY.length; i++) {
            
            //FIND DOMAIN
            findIndex = FUNCT_FIND_INDEX(WBS_DOMAIN_DETAILS, "id", WBS_DATA_QUERIES_NEW_WAY[i].id)

            //DATA QUERIES
            if((findIndex > -1 && WBS_DOMAIN_DETAILS[findIndex].enabled) 
                || WBS_DATA_QUERIES_NEW_WAY[i].notLinkedToDomain){
                
                //LOOP ON PROJECT ID
                for (let j = 0; j < projectIds.length; j++) {

                    //INTEGRATE
                    wbsActiveQueries.push({
                        ...WBS_DATA_QUERIES_NEW_WAY[i],
                        projectId: projectIds[j]
                    })
                }
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        /// WBS SETTING QUERy /////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        //GET PROJECT ID OR PORTOFOLIO ID
        let settingsId = projectIds[0]
        if(portfolioId) settingsId = portfolioId

        //ADD WBS SETTINGS QUERIES
        wbsActiveQueries.push({
            id: "wbsSettings",
            other: true,
            projectId: settingsId,
            query: WbsSettingsByProjectId,
            queryName: "wbsSettingsByProjectId"
        })

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        /// QUERIES BATCH /////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        const batchQueryObjects = wbsActiveQueries.map((activeQuery) => {
            return {
                queryName: activeQuery.queryName,
                options: {
                    limit: 10000,
                    projectId: activeQuery.projectId
                }
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        /// GRAPHQL CALL //////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        //GET TOKEN FOR projetc WBS AND REFRECH CURRENT SESSION
        const authorizationToken = `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;

        // OLD WAY
        // results = await Promise.all([
        //     ...wbsActiveQueries.map((item) => API.graphql(
        //             graphqlOperation(
        //                 item.query,
        //                 {
        //                     limit: 10000,
        //                     projectId: item.projectId,
        //                 }
        //             )
        //         )
        //     ),
        //     ...projects.map(item => 
        //         getProjectWbs(
        //             [item],
        //             authorizationToken
        //         )
        //     )
        // ]);

        // GET ACTIVE QUERIES RESULT + PROJECT RESULT USING Promise.all FOR TIME OPTIMIZATION
        results = await Promise.all([
            ...await batchQueryGraphql(
                batchQueryObjects,
            ),
            ...projects.map(item => 
                getProjectWbs(
                    [item],
                    authorizationToken
                )
            )
        ]);
    
        //SPLICE THE RESULT OF DATA AND WBS
        const resultsData = results.splice(0, wbsActiveQueries.length)
        const resultsWbs = _.cloneDeep(results)

        //TEST IF NEXT TOKEN AVAILABLE - POP TO DELETE WBS SETTINGS
        const resultsNextToken = await getProjectDataNextToken(
            wbsActiveQueries,
            resultsData
        )

        //PUSH NEXT TOKEN IF NOT NULL
        if(resultsNextToken){

            //UPDATE RESULT
            resultsData.push(...resultsNextToken.data)

            //UPDATE wbsActiveQueries
            wbsActiveQueries.push(...resultsNextToken.queries)
        } 

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        /// RESULT TREATMENT //////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        //OPTIMIZE RESULT 
        let globalData = {
            projectData: {},
            wbsSettings: {}
        }
    
        ///////////////////////////
        /// MANAGE RESULTS DATA ///
        ///////////////////////////

        let needPush = false;
        for (let i = 0; i < wbsActiveQueries.length; i++) {

            ///////////////////////////////////
            /// CONDITION FOR OTHER QUERIES ///
            ///////////////////////////////////

            if(wbsActiveQueries[i].other){

                //SWITCH FOR OTHER QUERIES
                switch (wbsActiveQueries[i].id) {

                    ////////////////////
                    /// WBS SETTINGS ///
                    ////////////////////

                    case "wbsSettings":
                        
                        //TEST IF RESULT ON SPECIFICS SETTINGS ID
                        if(resultsData[i].data[wbsActiveQueries[i].queryName].items > 0){

                            globalData.wbsSettings = resultsData[i].data[wbsActiveQueries[i].queryName].items[0]
                            globalData.wbsSettings.domain = JSON.parse(globalData.wbsSettings.domain)

                        //STANDARD FORMAT
                        }else{

                            //DOMAIN
                            globalData.wbsSettings.domain = WBS_DOMAIN_DETAILS
                            globalData.wbsSettings.projectId = projectIds[0]
                        }
                        break;
                
                    /////////////
                    /// ERROR ///
                    /////////////

                    default:
                        console.log("Error on other query Id! > " + wbsActiveQueries[i].id)
                        break;
                }
                
            ////////////////////
            /// PROJECT DATA ///
            ////////////////////

            }else{

                //MANAGE IF WE NEED TO ADD OR PUSH
                needPush = false;
                if(globalData.projectData[wbsActiveQueries[i].id]) needPush = true;

                //MANAGE DATE FIELDS

                let currentItems = resultsData[i].data[wbsActiveQueries[i].queryName].items;


                //DATE MNGT
                if(wbsActiveQueries[i].dateFields){

                    //LOOP ON DATA TO UPDATE DATE OBJECT
                    for (let j = 0; j < currentItems.length; j++){

                        //LOOP ON DATE ARRAY
                        for (let k = 0; k < wbsActiveQueries[i].dateFields.length; k++) {
                            if(currentItems[j][wbsActiveQueries[i].dateFields[k]]){
                                currentItems[j][wbsActiveQueries[i].dateFields[k]] = new Date(currentItems[j][wbsActiveQueries[i].dateFields[k]])
                            }
                        }
                    }
                }

                //INTEGRATION
                if(needPush){
                    globalData.projectData[wbsActiveQueries[i].id].push(...currentItems)
                }else{
                    globalData.projectData[wbsActiveQueries[i].id] = currentItems
                }
            }
        }

        /////////////////////////
        /// MANAGE RESULT WBS ///
        /////////////////////////

        const wbsList = [];
        for (let l = 0; l < resultsWbs.length; l++) {
            
            //PARSE BODY
            let currentWbsParsed = JSON.parse(resultsWbs[l].body)

            //LOOP AGAIN ON EACH BRANCHE
            for (let m = 0; m < currentWbsParsed.length; m++) {
                wbsList.push(currentWbsParsed[m])
            }
        }

        //INTEGRATION INTO GLOBAL DATA
        globalData.wbsList = wbsList;

        //////////////
        /// RETURN ///
        //////////////

        //DELETE USER DATAT COOKIE
        authCleanUserDataCookie()

        //RETURN
        return globalData

    //ERROR // NOT CRITIC ONLY NO PROJECT DATA
    }catch(err){
        console.log(err)
    }
}

