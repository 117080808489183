import { CvtButton } from "@mi-gso/cvt";
import { FUNCT_FIND_INDEX } from "../../../../../00-Core/Standards";
import { GOV_FUNC_GET_AGENDA_TYPE_ITEMS } from "../../00-Helpers/GovReviewFunctions";
import { AGENDA_ITEM_TYPES } from "../../../../../00-Core/Constants";

export default function GovReviewAgendaSideBarList({
    agendaItems,
    projectData,
    onEditItem,
    onDeleteAgendaItem,
    inReviewMode,
    editingAgenda,
    editMode,
    handleOpenWbsItem,
}) {
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            style={{
                maxHeight: "250px",
                overflow: "auto",
                margin: inReviewMode ? "10px 0px" : "",
            }}
        >
            {agendaItems.length === 0 ? (
                <div className="text_Color4_600_14px">No agendas yet</div>
            ) : null}

            {agendaItems.map((item, index) => {
                // GET ITEM TYPE ICON
                let typeIconIndex = FUNCT_FIND_INDEX(AGENDA_ITEM_TYPES, "id", item.type);
                let icon = "timer";
                if (typeIconIndex !== -1) {
                    icon = AGENDA_ITEM_TYPES[typeIconIndex].icon;
                }

                // GET ITEM TYPE ITEMS
                let typeItems = GOV_FUNC_GET_AGENDA_TYPE_ITEMS(item.type, projectData);
                let findIndex = FUNCT_FIND_INDEX(typeItems, "id", item.itemId);
                let itemData = typeItems[findIndex];

                return editingAgenda && editingAgenda.id && editingAgenda.id === item.id ? null : (
                    <div
                        className="flexBetweenCenter rootElementHoverHide actionTableItemContainer"
                        key={"GovReviewAgendaList_" + item.id + item.itemId + index}
                        style={{
                            borderBottom: "1px solid var(--border-color-gray-0)",
                            padding: "5px 10px",
                            cursor: inReviewMode ? "pointer" : "",
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (inReviewMode && item.itemId) {
                                handleOpenWbsItem(item.itemId, item.type);
                            }
                        }}
                    >
                        {/* LEFT PART */}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (inReviewMode && item.itemId) {
                                    handleOpenWbsItem(item.itemId, item.type);
                                }
                            }}
                        >
                            {/* TITLE */}
                            <div className="text_migsoBlue_600_13px">{item.title}</div>

                            {/* AGENDA ITEM */}
                            <div className="flexStartCenter text_Color3_400_13px">
                                {/* ICON */}
                                <span
                                    className="material-icons"
                                    style={{
                                        fontSize: "16px",
                                        marginRight: "5px",
                                    }}
                                >
                                    {icon}
                                </span>

                                {/* ITEM TITLE */}
                                {itemData ? itemData.label : ""}
                            </div>

                            {/* EXPECTATION */}
                            <div className="flexStartStart text_Color3_400_13px textItalic">
                                <div>Expectation</div>
                                <div
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    {item.expectation && item.expectation.length > 0
                                        ? item.expectation
                                        : " -"}
                                </div>
                            </div>
                        </div>

                        {/* RIGHT PART : ACTIONS */}
                        <div className="childElementHoverHide flexStartCenter">
                            {/* EDIT */}
                            {editMode ? (
                                <CvtButton
                                    color={"gray"}
                                    icon="edit"
                                    shadow="none"
                                    size="small"
                                    txt=""
                                    type="button"
                                    margin="0px 10px 0px"
                                    handleSubmit={onEditItem}
                                    handleSubmitOptions={{
                                        id: item.id,
                                    }}
                                />
                            ) : null}
                            {/* DELETE */}
                            {editMode ? (
                                <CvtButton
                                    confirmType="danger"
                                    icon="delete"
                                    needConfirmation
                                    shadow="none"
                                    size="small"
                                    type="button"
                                    handleSubmit={onDeleteAgendaItem}
                                    handleSubmitOptions={{
                                        id: item.id,
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
