import { 
  CvtButton,
  CvtModal,
  CvtModalBody,
  CvtModalFooter
 } from "@mi-gso/cvt";
import _ from "lodash";
import { useCallback, useState, useMemo, memo} from "react";
import { APP_TOAST_TXT } from "../../../../00-Core/Constants";
import { FUNC_GET_BIGGEST_DISPLAY_ID } from "../../../../00-Core/Standards";
import {
  deleteReferenceVersion,
  deleteVersionFollowingFromReference,
  getReferenceSignedUrl
} from "../../../00-App/02-Backend/AppBackendMapping";
import {
  SCHEDULE_PPM_GUID_PREFIX,
  SCHEDULE_TLG_GUID_PREFIX,
} from "../../09-Schedule/00-Helpers/ScheduleConstants";
import { SCHEDULE_FUNC_CREATE_UPDATE_REFERENCE } from "../../09-Schedule/00-Helpers/ScheduleFunctions";

//////////////////////////
// WBS REFERENCE MODAL ///
//////////////////////////

export default memo(function WbsReferenceModal({
  referenceModalItem,
  appDispatcher,
  projectId,
  organizationId,
  scheduleData,
  currentUser,
  selectedProject,
}) {
  ///////////////
  // MEMO ///////
  ///////////////

  const versionFollowing = useMemo(() => {
    return referenceModalItem.versionFollowing ?? [];
  }, [referenceModalItem.versionFollowing]);

  // GET ONLY THE SCHEDULE DATA THAT DOESN'T HAVE 'TLG' IN THE GUID (MEANS IT WAS IMPORTED)
  const importData = useMemo(() => {
    return scheduleData.filter((schedule) => {
      const isImported =
        schedule?.guid &&
        !schedule?.guid?.includes(SCHEDULE_TLG_GUID_PREFIX) &&
        !schedule?.guid?.includes(SCHEDULE_PPM_GUID_PREFIX);

      const belongsThisProject = schedule.projectId === projectId;

      // return isImported && belongsThisProject;
      return belongsThisProject && isImported;
    });
  }, [projectId, scheduleData]);

  ///////////////
  // STATE //////
  ///////////////

  const [state, setState] = useState({
    selectedVersionId:
      versionFollowing.length > 0 ? versionFollowing[0].versionId : null,
    loading: false,
    deletingVersionId: null,
    // VERSION FOLLOWING
    versionFollowing: versionFollowing,
  });

  ///////////////
  // FUNTIONS ///
  ///////////////

  // BIGGEST DISPLAY ID SCHEDULE
  const biggestDisplayIdSchedule = useMemo(() => {
    return FUNC_GET_BIGGEST_DISPLAY_ID(scheduleData, 2);
  }, [scheduleData]);

  // HANDLE SELECT VERSION
  const handleSelectVersionId = useCallback(
    (versionId) => {
      if (state.selectedVersionId !== versionId) {
        setState((prevState) => {
          return {
            ...prevState,
            selectedVersionId: versionId,
          };
        });
      }
    },
    [state.selectedVersionId]
  );

  const resetFuntion = useCallback(() => {
    appDispatcher({
      type: "SET_GLOBAL_STATE",
      options: {
        source: "appSettings",
        object: {
          actionModal: null,
          // referenceModalItem: null,
        },
      },
    });
  }, [appDispatcher]);

  // HANDLE CONFIRM VERSION
  const handleSubmitFunction = useCallback(async (e) => {
      e.preventDefault();

      try {

        // SET LOADING
        setState((prevState) => ({
          ...prevState,
          loading: true
        }));

        // GET SIGNED URL FROM  DATA-MAPPING LAMBDA
        const signedUrl = await getReferenceSignedUrl(
          organizationId,
          projectId,
          referenceModalItem.category,
          state.versionFollowing.find((versionObj) => versionObj.versionId === state.selectedVersionId).identityId,
          state.selectedVersionId,
        );

        //FETCH FILE
        const parsedData = await (await fetch(signedUrl)).json();

        // IF NOT EMPTY
        if (parsedData.length > 0) {

          // CHECK WHICH DATA NEEDS TO BE ADDED, WHICH NEEDS TO BE UPDATED USING SAME FUNC AS IN TLG
          // RESULT OF THIS VARIABLE IS {create:[...], update: [....]}
          const createUpdateObject = SCHEDULE_FUNC_CREATE_UPDATE_REFERENCE(
            parsedData,
            importData,
            biggestDisplayIdSchedule,
            currentUser.username,
            selectedProject
          );

          // IF ANY DATA
          if (
            createUpdateObject.create.length > 0 ||
            createUpdateObject.update.length > 0
          ) {
            // UPDATE APP STATE
            appDispatcher({
              type: "SAVE_REFERENCE",
              table: "schedule",
              referenceCreateUpdateObject: createUpdateObject,
            });
          // ELSE IF NO DATA
          }else {

            //APP STATE
            appDispatcher({
              type: "SET_GLOBAL_STATE",
              options: {
                source: "appSettings",
                object: {
                  actionModal: null,
                  referenceModalItem: null,
                  referenceCreateUpdateObject: null,
                },
              },
              toast: {
                display: true,
                color: "good",
                message: APP_TOAST_TXT.referenceNoDataChanges,
              },
            });
          }
        }

      //ERROR MANAGMENT
      } catch (error) {

        //CONSOLE
        console.log(
          "Error fetching reference data for specified version...",
          error
        );

        // SET LOADING FALSE
        setState((prevState) => ({
          ...prevState,
          loading: false
        }));
      }
    },[appDispatcher, biggestDisplayIdSchedule, currentUser.username, importData, organizationId, projectId, referenceModalItem.category, selectedProject, state.selectedVersionId, state.versionFollowing]);

  // HANDLE DELETE VERSION
  const handleDeleteVersion = useCallback(async (e, versionId) => {
    try {
      e.preventDefault();

      //THIS SATTE
      setState((prevState) => ({
        ...prevState,
        deletingVersionId: versionId
      }));

      // CALL DATA MAPPING API
      const isDeleteOk = await deleteReferenceVersion(
        referenceModalItem.s3ReferenceFileName,
        versionId,
        projectId,
        organizationId,
        state.versionFollowing.find((versionObj) => versionObj.versionId === versionId).identityId,
      );

      // IF EVERYTHING IS OK ----------- REFERENCE VERSION DELETED
      if(isDeleteOk) {

        // MAKE CALL TO UPDATE DB
        await deleteVersionFollowingFromReference(
          referenceModalItem.id,
          referenceModalItem.s3ReferenceFileName,
          versionId,
          projectId,
          organizationId,
        );

        //DEEP CLONE LAST VERSION FOLLOWING
        let newVersionFollowing = _.cloneDeep(state.versionFollowing);

        //FILTER BY VERSION ID
        newVersionFollowing = newVersionFollowing.filter((versionObj) => versionObj.versionId !== versionId);

        //APP STATE
        appDispatcher({
          type: "SET_GLOBAL_STATE",
          options: {
            source: "appSettings",
            object: {
              referenceModalItem: {
                ...referenceModalItem,
                versionFollowing: newVersionFollowing,
              },
            },
          },
          toast: {
            display: true,
            color: 'good',
            message: APP_TOAST_TXT.successfullyDeletedReferenceVersion,
          },
        });

        // IF VERSION FOLLOWING IS NOW EMPTY, NEED TO RESET THE MODAL AND HIDE THE REFERENCE BTN NEAR THE HEADERS
        if(newVersionFollowing.length === 0) {
          resetFuntion();
        }

        // UPDATE LOCAL STATE
        setState((prevState) => {
          return {
            ...prevState,
            versionFollowing: newVersionFollowing,
          }
        });

      // ELSE IF NOT OK
      }else{

        // SHOW TOAST SAYING ERROR WHILE DELETING REFERENCE VERSION
        appDispatcher({
          type: "SET_KEY_VALUE",
          key: 'appToast',
          value: {
            display: true,
            color: 'danger',
            message: APP_TOAST_TXT.errorDeletingReferenceVersion,
          },
        });

        //THIS STATE
        setState((prevState) => ({
          ...prevState,
          deletingVersionId: null
        }));
      }

    //ERROR
    } catch (error) {

      //CONSOLE
      console.log('Error deleting version id...', error);

      //THIS STATE
      setState((prevState) => ({
        ...prevState,
        deletingVersionId: null
      }));

      // SHOW TOAST SAYING ERROR WHILE DELETING REFERENCE VERSION
      appDispatcher({
        type: "SET_KEY_VALUE",
        key: 'appToast',
        value: {
          display: true,
          color: 'danger',
          message: APP_TOAST_TXT.errorDeletingReferenceVersion,
        },
      });
    }
  }, [appDispatcher, organizationId, projectId, referenceModalItem, resetFuntion, state.versionFollowing]);

  ///////////////
  // RENDER /////
  ///////////////

  return (
    <CvtModal
      title="Choose reference version"
      icon="source"
      resetFunction={resetFuntion}
      // key={`wbs_reference_modal_${versionFollowing.length}`}
    >
      <CvtModalBody padding="10px">
        {state.versionFollowing.map((version, index) => {

          // CHECK IS SELECTED
          const isSelected = version.versionId === state.selectedVersionId;
          // CHECK IF IS BEING DELETED
          const isBeingDeleted = state.deletingVersionId ? version.versionId === state.deletingVersionId : false;

          return (
            <div
              key={`wbs_reference_modal_version_${version.versionId}_${index}`}
              className={`flexBetweenCenter ${
                isSelected
                  ? "wbsReferenceModalSelectedVersionItem"
                  : "wbsReferenceModalVersionItem"
              }`}
              onClick={() => handleSelectVersionId(version.versionId)}
              style={{
                marginTop: index !== 0 ? "10px" : "",
              }}
            >
              <span className="wbsReferenceModalVersionItemName">
                {version.versionName}
              </span>

              <div className="flexStartCenter">
                <span style={{marginRight: '10px'}}>Version Number: {version.versionNum}</span>

                {/* CLOSE BTN */}
                <CvtButton
                  icon="close"
                  color="white"
                  shape="noShape"
                  size="tiny"
                  needConfirmation
                  txtConfirm="Sure?"
                  handleSubmit={(e) => handleDeleteVersion(e, version.versionId)}
                  loader={isBeingDeleted}
                />
              </div>
            </div>
          );
        })}
      </CvtModalBody>

      <CvtModalFooter
        submitTxt="Confirm"
        submitColor="blue"
        resetName="Cancel"
        resetFunction={resetFuntion}
        handleSubmitFunction={handleSubmitFunction}
        submitDisabled={state.loading}
        submitLoader={state.loading}
      />
    </CvtModal>
  );
});
