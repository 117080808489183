import React, {useMemo, memo, useLayoutEffect, useState, useEffect} from 'react'

//CORE
import {
    APP_TXT_MEGA_USERS, APP_WBS_STRUCTURE,
} from '../../../../00-Core/Constants'
import { FUNCT_FIND_INDEX, FUNCT_FIND_INDEX_ARRAY, FUNC_GET_BIGGEST_DISPLAY_ID } from '../../../../00-Core/Standards'

//WBS
import {  TEMPLATE_GET_PROJECT_USERS_V2 } from '../00-Helpers/WbsFunctions';

//COMPONENTS
import ActionBig from '../../01-Action/ActionBig';
import RiskBig from '../../02-Risk/RiskBig';
import GovernanceBig from '../../04-Governance/GovBig';
import CharterBig from '../../05-Charter/CharterBig';
import ShortcutBig from '../../06-Shortcut/ShortcutBig';
import ScheduleBig from '../../09-Schedule/ScheduleBig';

//ACTION
import { FUNC_ACTION_GET_BIGGEST_DISPLAY_ID } from '../../01-Action/00-Helpers/ActionsFunctions';
import WbsNoData from './WbsNoData';
import { envVarManagement } from '../../../../envVarManagement';

//////////////////////////////////////////
/// BIG WBS ELEMENT COMPONENT ////////////
//////////////////////////////////////////

export default memo(
    function WbsElementBig({
        item,
        currentPath,
        coreSelection,
        selectedWbsId,
        parentId,
        selectedWbsName,
        selectedField,
        projectData,
        globalProjectData,
        usersList,
        projectLeaderList,
        currentUser,
        wbsProjectData,
        wbsSettings,
        handleBigComponent,
        wbsDispatcher,
        appDispatcher,
        isShortcutBigOpen,
        isProjectElement,
        isReadOnlyMode,
        isPortfolio,
        isPortfolioLine,
        isReviewMode,
        handleFullScreen,
        isFullScreen,
        wbsPosition,
        sideBarOpenWidth,
        moveModalOptionsItemsArray,
        updateTrackers,
        referenceCreateUpdateObject,
        rightComponentData,
        wbsNavigationOptions,
        projectChildIds,
        selectedReviewItem,
    }) {

        //ENV VAR
        const envMegaUsers = envVarManagement("envMegaUsers")

        ////////////////////////////////////////////////////////////////
        /// NEEDED INFORMATION /////////////////////////////////////////
        ////////////////////////////////////////////////////////////////

        //FIELD
        const fieldInformation = useMemo(()=>{

            let isOpportunity = selectedField === "opportunity";

            //FIND INDEX
            let findIndex = FUNCT_FIND_INDEX(wbsSettings.domain, "id", isOpportunity===true ? "riskOpp" : selectedField)

            //RETURN GOOD OBJECT
            return wbsSettings.domain[findIndex]
        },[selectedField, wbsSettings.domain])

        //MANAGE USER LIST WITH PORTFOLIO -----------------------------------------------
        const currentSelection = useMemo(() => {

            //INIT
            let list = usersList;
            let megaList = coreSelection.selectedProject.projectMegaUsersList;
            let organizationId = coreSelection.selectedOrganization.id
            let projectId = coreSelection.selectedProject.id

            //TEST IF A PORTFOLIO IS NOT NULL
            if(isPortfolio){

                //FIND PROJECT ID RELATED TO CURRENT WBS BRANCH OR PROJECT
                let getProjectId = null
                if(item.isProject){
                    getProjectId = item.id
                }else{
                    getProjectId = item.coreDataProjectCoreDataWbsId
                }

                //FIND PROJECT INDEX INSIDE CURRENT USER
                let findIndex = FUNCT_FIND_INDEX(currentUser.coreProjects, "id", getProjectId)
                if(findIndex > -1){

                    //CONCATENATE USER
                    list = TEMPLATE_GET_PROJECT_USERS_V2(
                        currentUser.coreProjects[findIndex].organizationMegaUsersList,
                        currentUser.coreProjects[findIndex].projectMegaUsersList,
                        currentUser.coreProjects[findIndex].projectUsersList,
                        coreSelection.users,
                        envMegaUsers,
                        projectLeaderList
                    )

                    //PROJECT INFO
                    megaList = currentUser.coreProjects[findIndex].projectMegaUsersList
                    organizationId = currentUser.coreProjects[findIndex].coreDataOrganizationCoreDataProjectId
                    projectId = currentUser.coreProjects[findIndex].id
                }


            }

            //RETURN
            return {
                userList: list,
                megaUserList: megaList,
                organizationId: organizationId,
                projectId: projectId
            }
        }, [coreSelection.selectedOrganization.id, coreSelection.selectedProject.id, coreSelection.selectedProject.projectMegaUsersList, coreSelection.users, currentUser.coreProjects, envMegaUsers, isPortfolio, item.coreDataProjectCoreDataWbsId, item.id, item.isProject, projectLeaderList, usersList]);


        //SECURITY GROUP ----------------------------------------------------------------
        const securityGroup = useMemo(()=>{

            //MANAGE GROUPS CAN EDIT
            let groupEditors = [
                APP_TXT_MEGA_USERS,
                item.organizationMegaUsersGroup,
                item.projectMegaUsersGroup
            ]

            //MANAGE GROUP CAN SEE
            let groupViewers = [item.projectUsersGroup]

            //isCurrentUserEditor
            let isCurrentUserEditor = false

            //GET CURRENT USER GROUP
            let currentUserGroup = []
            if(currentUser.user
            && currentUser.user.signInUserSession
            && currentUser.user.signInUserSession.accessToken.payload["cognito:groups"]){
                currentUserGroup = currentUser.user.signInUserSession.accessToken.payload["cognito:groups"]
            }

            //LOOP ON GROUPEDITORS NEED A LEAST ONE
            for (let i = 0; i < groupEditors.length; i++) {

                //FIND
                if(FUNCT_FIND_INDEX_ARRAY(currentUserGroup, groupEditors[i]) > -1){
                    isCurrentUserEditor = true
                    break;
                }
            }

            //RETURN
            return{
                groupEditors:groupEditors,
                groupViewers:groupViewers,
                isCurrentUserEditor: isCurrentUserEditor
            }
        },[currentUser.user, item.organizationMegaUsersGroup, item.projectMegaUsersGroup, item.projectUsersGroup])

        // ITEM LEVEL ----------------------------------------------------------------------
        const itemLevel = useMemo(()=>{
            let level = item.level + 1;
            if(isProjectElement){
                level -= 1
            }
            return level
        },[isProjectElement, item.level])

        ////////////////////////////////////////////////////////////////
        /// DATA TREATMENT /////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////

        // CHECK IF THERE'S RISK ITEMS TO DISPLAY THE WbsNoData COMPONENT OR NOT
        const isRiskDataExists = useMemo(() =>{
            if (updateTrackers.riskOpp) {
                // JUST CHECK IF RISK DATA HAS BEEN UPDATED
            }

            // RETURN ONLY RISK DATA
            return projectData.riskOpp.find((risk) => risk.isRisk === true);
        },[projectData.riskOpp, updateTrackers.riskOpp])

        // CHECK IF THERE'S OPP ITEMS TO DISPLAY THE WbsNoData COMPONENT OR NOT
        const isOppDataExists = useMemo(() =>{
            if (updateTrackers.riskOpp) {
                // JUST CHECK IF RISK DATA HAS BEEN UPDATED
            }

            // RETURN ONLY RISK DATA
            return projectData.riskOpp.find((risk) => risk.isRisk === false);
        },[projectData.riskOpp, updateTrackers.riskOpp])

        //GET HIGHEST DISPLAY ID NUMBER OF ALL ACTIONS IN THE PROJECT -------
        const biggestDisplayIdNum = useMemo(() =>{

            //ONLY IF ACTION
            if(wbsProjectData &&
                (selectedField === "action"
                || selectedField === "riskOpp"
                || selectedField === "opportunity"
                || selectedField === "schedule")){
                return FUNC_ACTION_GET_BIGGEST_DISPLAY_ID(wbsProjectData.action)
            }

            //IF NOT RETURN NULL
            return null
        },[wbsProjectData, selectedField])

        // GET BIGGEST DISPLAY ID NUMBER OF ALL RISK ITEMS
        const biggestDisplayIdRisks = useMemo(() =>{

            //ONLY IF CHARTER
            if(wbsProjectData && selectedField === "riskOpp"){
                return FUNC_GET_BIGGEST_DISPLAY_ID(wbsProjectData.riskOpp.filter((ro) => ro.isRisk === true), 2);
            }

            //IF NOT RETURN NULL
            return null
        },[wbsProjectData, selectedField])

        // get BIGGEST DISPLAY ID OPPORTUNITIES
        const biggestDisplayIdOpp = useMemo(() =>{

            //ONLY IF CHARTER
            if(wbsProjectData && selectedField === "opportunity"){
                return FUNC_GET_BIGGEST_DISPLAY_ID(wbsProjectData.riskOpp.filter((ro) => ro.isRisk === false), 2);
            }

            //IF NOT RETURN NULL
            return null
        },[wbsProjectData, selectedField])

        // BIGGEST DISPLAY ID SCHEDULE
        const biggestDisplayIdSchedule = useMemo(() =>{

            if(wbsProjectData && selectedField === "schedule"){
                return FUNC_GET_BIGGEST_DISPLAY_ID(wbsProjectData.schedule, 2);
            }

            //IF NOT RETURN NULL
            return null
        },[wbsProjectData, selectedField])

        // BIGGEST DISPLAY ID SCHEDULE
        const biggestDisplayIdScopeChange = useMemo(() =>{

            if(wbsProjectData && selectedField === "governance"){
                return FUNC_GET_BIGGEST_DISPLAY_ID(wbsProjectData.govScopeChange, 2);
            }

            //IF NOT RETURN NULL
            return null
        },[wbsProjectData, selectedField])

        ////////////////////////////////////////////////////////////////
        /// USE EFFECT /////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////

        //GET SUTRUCTURE WIDTH
        const [structureContent, setStructureContent] = useState({
            width: 0,
            currentItemRowTop: 0,
            scrollLeft: 0
        })

        //MANAGE LIMIT SIZE FOR TO COLUMN IN BIG COMPONENT
        useLayoutEffect(() => {

            //UNLOAD LIGHT MENU IF WINDOWS RESIZE > 768
            function handleSizeLimit (){

                //STRUCTURE CONTENT
                let structureContent = document.getElementById("structureContent")

                //STRUCTURE CONTENT
                let wbsBlock = document.getElementById("wbsBlock");

                setStructureContent(prevState => {
                    return({
                        ...prevState,
                        width: structureContent.clientWidth ,
                        scrollLeft: wbsBlock.scrollLeft
                    })
                })
            }

            //PASS LISTENER
            window.addEventListener('resize', handleSizeLimit);
            handleSizeLimit();
            return () => window.removeEventListener('resize', handleSizeLimit);
        }, []);

        //GET TOP TO SUBSTARCT
        useEffect(() => {

            //STRUCTURE CONTENT
            let currentItemRow = document.getElementById(item.id).getBoundingClientRect();

            //RETURN
            setStructureContent(prevState => {
                return({
                    ...prevState,
                    currentItemRowTop: currentItemRow.top
                })
            })


        }, [isFullScreen, item.id])

        //GET TLEFT IF FULLSCREEN AND HORIZONTAL SCROLL
        useEffect(() => {
            if(isFullScreen){

                //STRUCTURE CONTENT
                let wbsBlock = document.getElementById("wbsBlock");
                let structureContent = document.getElementById("structureContent")

                //RETURN
                setStructureContent(prevState => {
                    return({
                        ...prevState,
                        scrollLeft: wbsBlock.scrollLeft,
                        width: structureContent.clientWidth,
                    })
                })
            }
        }, [isFullScreen, isReviewMode])

        //!! PATCH FOR THE MOMENT
        //RESIZE IF SIDEBAR
        useEffect(()=>{
            setTimeout(() => {
                let structureContent = document.getElementById("structureContent")
                setStructureContent(prevState => {
                    return({
                        ...prevState,
                        width: structureContent.clientWidth ,
                    })
                })
            }
            , 400);
        },[sideBarOpenWidth])

        ////////////////////////////////////////////////////////////////
        /// RENDER /////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////
      
        return (
            <div
                className={isShortcutBigOpen === true ? 'flexStartCenter' : ''}
                style={{
                    padding:isShortcutBigOpen ? "25px 0px 25px 25px" : "25px",
                    position:"relative",
                    zIndex:  200,
                }}
            >

                {/* SHORTCUT */}
                {isShortcutBigOpen === true ?
                    <ShortcutBig
                        dimension={fieldInformation}
                        shortcutData={projectData.shortcut.filter((shortcut) => shortcut.wbsId === selectedWbsId)}
                        selectedOrganizationId={currentSelection.organizationId}
                        projectId={currentSelection.projectId}
                        selectedProjectId={currentSelection.projectId} //!!!!! warnign already passed by projectId
                        wbsId={selectedWbsId}
                        wbsName={selectedWbsName}
                        wbsDispatcher={wbsDispatcher}
                        appDispatcher={appDispatcher}
                        securityGroup={securityGroup}
                        handleBigComponent={handleBigComponent}
                        fieldInformation={fieldInformation}
                        currentUser={currentUser}
                        wbsDomains={wbsSettings.domain}
                        isOnlyShortcutOpen={selectedField === null}
                        bigComponentOpen={selectedField}
                        itemLevel={itemLevel}
                    />
                :null}

                {/* ALL OTHER BIG COMPONENT */}
                <div
                    key={"bigComponent-" + item.id}
                    className="bigComponent flexStartStrech"
                    style={{
                        width:  isFullScreen ?  (structureContent.width - 60) + "px" : (isShortcutBigOpen === true && selectedField!==null) ? isShortcutBigOpen ? ("calc(100% - " + APP_WBS_STRUCTURE.shortcutWidth + "px)") : "100%" :  "",
                        height : isFullScreen ? ("calc(100vh - ") + (160 + wbsPosition.filterBlockHeight) + "px)" : null,
                        position: isFullScreen ? 'absolute' : "relative" ,
                        zIndex: isFullScreen ? 1000 : null,
                        top: isFullScreen ? (-structureContent.currentItemRowTop) + "px" : null,
                        left: isFullScreen ? (structureContent.scrollLeft - 30) + "px" : null,
                        margin: isFullScreen ? "30px" :null
                    }}
                >
                    {/* CHARTER ---------------------------------- */}
                    {selectedField==="charter" ?
                        <CharterBig
                            data={projectData.charter}
                            itemId={item.id}
                            wbsId={selectedWbsId}
                            wbsName={selectedWbsName}
                            wbsDispatcher={wbsDispatcher}
                            appDispatcher={appDispatcher}
                            bigComponentExpandFunction={handleFullScreen}
                            bigComponentIsExpanded={isFullScreen}
                            usersList={currentSelection.userList}
                            megaUsersIds={currentSelection.megaUserList}
                            selectedOrganizationId={currentSelection.organizationId}
                            projectId={currentSelection.projectId}
                            selectedProject={isPortfolio ? item : coreSelection.selectedProject}
                            securityGroup={securityGroup}
                            handleBigComponent={handleBigComponent}
                            fieldInformation={fieldInformation}
                            currentUser={currentUser}
                            updateTracker={updateTrackers.charter}
                        />
                    :null}

                    {/* SHORTCUT ---------------------------------- */}
                    {selectedField === "schedule" ?
                        isPortfolioLine && projectData.schedule.length === 0 ?
                            <div>
                                Implement here no data message
                            </div>
                        :
                            <ScheduleBig
                                actionsData={wbsProjectData.action}
                                bigComponentExpandFunction={handleFullScreen}
                                bigComponentIsExpanded={isFullScreen}
                                biggestActionDisplayIdNum={biggestDisplayIdNum}
                                biggestDisplayId={biggestDisplayIdSchedule}
                                currentPath={currentPath}
                                currentUser={currentUser}
                                dimension={fieldInformation}
                                fieldInformation={fieldInformation}
                                governanceData={wbsProjectData.govReview ?? []}
                                isReadOnlyMode={isReadOnlyMode}
                                isReviewMode={isReviewMode}
                                megaUsersIds={currentSelection.megaUserList}
                                moveModalOptionsItemsArray={moveModalOptionsItemsArray}
                                projectData={globalProjectData}
                                projectId={currentSelection.projectId}
                                referenceCreateUpdateObject={referenceCreateUpdateObject}
                                roData={projectData.riskOpp}
                                scheduleData={projectData.schedule}
                                securityGroup={securityGroup}
                                selectedOrganizationId={currentSelection.organizationId}
                                structureContentWidth={structureContent.width}
                                updateTracker={updateTrackers.schedule}
                                usersList={currentSelection.userList}
                                wbsId={selectedWbsId}
                                wbsName={selectedWbsName}
                                wbsSettings={wbsSettings}
                                wbsNavigationOptions={wbsNavigationOptions}

                                // FUNCTIONS
                                handleBigComponent={handleBigComponent}

                                // DISPATCHERS
                                appDispatcher={appDispatcher}
                                wbsDispatcher={wbsDispatcher}

                                selectedReviewItem={selectedReviewItem}
                            />
                    :null}

                    {/* GOVERNANCE ---------------------------------- */}
                    {selectedField==="governance" ?
                        isPortfolioLine && projectData.govScopeChange.length === 0 && projectData.govReview.length === 0?
                            <div>
                                Implement here no data message
                            </div>
                        :
                            <GovernanceBig
                                dimension={fieldInformation}
                                isReviewMode={isReviewMode}
                                usersList={currentSelection.userList}
                                megaUsersIds={currentSelection.megaUserList}
                                selectedOrganizationId={currentSelection.organizationId}
                                projectId={currentSelection.projectId}
                                govScopeChanges={projectData.govScopeChange}
                                govReviews={projectData.govReview ?? []}
                                biggestDisplayIdScopeChange={biggestDisplayIdScopeChange}
                                wbsId={selectedWbsId}
                                wbsName={selectedWbsName}
                                wbsDispatcher={wbsDispatcher}
                                wbsSettings={wbsSettings}
                                currentPath={currentPath}
                                appDispatcher={appDispatcher}
                                bigComponentExpandFunction={handleFullScreen}
                                bigComponentIsExpanded={isFullScreen}
                                currentUser={currentUser}
                                securityGroup={securityGroup}
                                handleBigComponent={handleBigComponent}
                                fieldInformation={fieldInformation}
                                projectChildIds={projectChildIds ?? item.children.map((project) => project.id)}
                                projectData={globalProjectData}
                                isReadOnlyMode={isReadOnlyMode}
                                wbsNavigationOptions={wbsNavigationOptions}
                                isProjectElement={isProjectElement}
                                structureContentWidth={structureContent.width}
                                moveModalOptionsItemsArray={moveModalOptionsItemsArray}
                                rightComponentData={rightComponentData}

                                // UPDATE TRACKERS
                                govReviewUpdateTracker={updateTrackers.govReview}
                                govScopeChangeUpdateTracker={updateTrackers.govScopeChange}
                            />
                    :null}

                    {/* ACTION ------------------------------------ */}
                    {selectedField === "action" ?
                        isPortfolioLine && projectData.action.length === 0 ?
                            <div>
                                Implement here no data message
                            </div>
                        :
                            <ActionBig
                                actionData={projectData.action}
                                biggestDisplayIdNum={biggestDisplayIdNum}
                                wbsName={selectedWbsName}
                                currentPath={currentPath}
                                usersList={currentSelection.userList}
                                megaUsersIds={currentSelection.megaUserList}
                                selectedOrganizationId={currentSelection.organizationId}
                                projectId={currentSelection.projectId}
                                isReadOnlyMode={isReadOnlyMode}
                                wbsId={selectedWbsId}
                                wbsList={coreSelection.wbsList}
                                wbsDispatcher={wbsDispatcher}
                                bigComponentExpandFunction={handleFullScreen}
                                bigComponentIsExpanded={isFullScreen}
                                handleBigComponent={handleBigComponent}
                                fieldInformation={fieldInformation}
                                currentUser={currentUser}
                                organizationName={isPortfolio ? null : coreSelection?.selectedOrganization?.name}
                                securityGroup={securityGroup}
                                appDispatcher={appDispatcher}
                                isReviewMode={isReviewMode}
                                projectData={globalProjectData}
                                structureContentWidth={structureContent.width}
                                moveModalOptionsItemsArray={moveModalOptionsItemsArray}
                                updateTracker={updateTrackers.action}
                                wbsNavigationOptions={wbsNavigationOptions}
                                selectedReviewItem={selectedReviewItem}
                            />
                    : null}

                    {/* RISK & OPP ----------------------------------- */}
                    {selectedField==="riskOpp" ?
                        isPortfolioLine && !isRiskDataExists ?
                            <WbsNoData
                                fieldInformation={fieldInformation}
                                handleBigComponent={handleBigComponent}
                                wbsName={selectedWbsName}
                                bigComponentIsExpanded={isFullScreen}
                                bigComponentExpandFunction={handleFullScreen}
                                tooltipInfo='Risks'
                            />
                        :
                            <RiskBig
                                key="risk_big_container"
                                isRisk={true}
                                dimension={fieldInformation}
                                governanceData={globalProjectData.govReview ?? []}
                                roData={projectData.riskOpp}
                                actionsData={globalProjectData.action}
                                parentId={parentId}
                                wbsId={selectedWbsId}
                                wbsName={selectedWbsName}
                                isReadOnlyMode={isReadOnlyMode}
                                usersList={currentSelection.userList}
                                megaUsersIds={currentSelection.megaUserList}
                                selectedOrganizationId={currentSelection.organizationId}
                                projectId={currentSelection.projectId}
                                totalRiskOppData={wbsProjectData.riskOpp}
                                bigComponentExpandFunction={handleFullScreen}
                                bigComponentIsExpanded={isFullScreen}
                                isReviewMode={isReviewMode}
                                appDispatcher={appDispatcher}
                                wbsDispatcher={wbsDispatcher}
                                securityGroup={securityGroup}
                                handleBigComponent={handleBigComponent}
                                fieldInformation={fieldInformation}
                                currentUser={currentUser}
                                biggestActionDisplayIdNum={biggestDisplayIdNum}
                                biggestDisplayIdNum={biggestDisplayIdRisks}
                                projectData={globalProjectData}
                                currentPath={currentPath}
                                structureContentWidth={structureContent.width}
                                isProjectElement={isProjectElement}
                                moveModalOptionsItemsArray={moveModalOptionsItemsArray}
                                updateTracker={updateTrackers.riskOpp}
                                wbsNavigationOptions={wbsNavigationOptions}
                                selectedReviewItem={selectedReviewItem}
                            />
                    : null}

                    {/* RISK & OPP ----------------------------------- */}
                    {selectedField==="opportunity" ?
                        isPortfolioLine && !isOppDataExists ?
                            <WbsNoData
                                fieldInformation={{...fieldInformation, name: 'Opportunities'}}
                                handleBigComponent={handleBigComponent}
                                wbsName={selectedWbsName}
                                bigComponentIsExpanded={isFullScreen}
                                bigComponentExpandFunction={handleFullScreen}
                                tooltipInfo='Opportunities'
                            />
                        :
                            <RiskBig
                                key="opp_big_container"
                                isRisk={false}
                                dimension={fieldInformation}
                                roData={projectData.riskOpp}
                                governanceData={globalProjectData.govReview ?? []}
                                actionsData={globalProjectData.action}
                                parentId={parentId}
                                wbsId={selectedWbsId}
                                isReadOnlyMode={isReadOnlyMode}
                                wbsName={selectedWbsName}
                                usersList={currentSelection.userList}
                                megaUsersIds={currentSelection.megaUserList}
                                isReviewMode={isReviewMode}
                                selectedOrganizationId={currentSelection.organizationId}
                                projectId={currentSelection.projectId}
                                totalRiskOppData={wbsProjectData.riskOpp}
                                bigComponentExpandFunction={handleFullScreen}
                                bigComponentIsExpanded={isFullScreen}
                                appDispatcher={appDispatcher}
                                wbsDispatcher={wbsDispatcher}
                                securityGroup={securityGroup}
                                handleBigComponent={handleBigComponent}
                                fieldInformation={fieldInformation}
                                currentUser={currentUser}
                                biggestDisplayIdNum={biggestDisplayIdOpp}
                                biggestActionDisplayIdNum={biggestDisplayIdNum}
                                projectData={globalProjectData}
                                currentPath={currentPath}
                                structureContentWidth={structureContent.width}
                                isProjectElement={isProjectElement}
                                moveModalOptionsItemsArray={moveModalOptionsItemsArray}
                                updateTracker={updateTrackers.riskOpp}
                                wbsNavigationOptions={wbsNavigationOptions}
                                selectedReviewItem={selectedReviewItem}
                            />
                    : null}
                </div>
            </div>
        );
    }
)