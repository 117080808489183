
//CORE
import { CvtTooltip } from '@mi-gso/cvt'

//COMPONENT
export default function CharterBigCategory({
    borderColor,
    backgroundColor,
    leftTextColor,
    leftText,
    leftIcon,
    leftIconSize,
    rightJsx,
    rightDotTextColor,
    rightDotBackgroundColor,
    rightDotBorder,
    rightDotWidth,
    rightDotText,
    marginRight,
    width,
    minWidth,
    tooltip,
    tooltipId,
    tooltipContent,
    bigComponentDisplayed,
    onClick,
    cyId,
}) {
 
    return (
        <div
            onClick={onClick}
            className="InfoDotTag flexBetweenCenter"
            style={{
                borderColor: bigComponentDisplayed ? null : borderColor,
                backgroundColor: backgroundColor,
                width: width ? width : null,
                minWidth: minWidth ? minWidth : null,
                marginRight: marginRight ? marginRight : null
            }}
            id={tooltipId}
            key={tooltipId}
            data-cy={cyId ?? tooltipId}
        >
            {/* LEFT */}
            <span style={{
                color: leftTextColor ,
            }}>
                {leftText}
            </span>

            {/* LEFT ICON */}
            {leftIcon ?
                <span
                    className="material-icons"
                    style={{
                        color:"var(--border-color-gray-2)",
                        margin:"0px 5px 0px -4px",
                        fontSize:leftIconSize ? leftIconSize : null
                    }}
                >
                    {leftIcon}
                </span>
            :null}

            {/* RIGHT */}
            {rightJsx ?
                rightJsx
            :
                <div
                    className="InfoDotTag_rightDot flexCenterCenter"
                    style={{
                        color: rightDotTextColor,
                        backgroundColor: rightDotBackgroundColor,
                        border: rightDotBorder,
                        width : rightDotWidth ? rightDotWidth : null,
                        paddingLeft : rightDotWidth ? "4px" : null,
                        paddingRight : rightDotWidth ? "4px" : null,
                        fontWeight: rightDotWidth ? 700 : null,
                        whiteSpace:"nowrap"
                    }}
                >
                    {rightDotText}
                </div>
            }

            {/* TOOLTIP */}
            {tooltip ?
                <CvtTooltip
                    anchorId={tooltipId}
                    content={tooltipContent}
                />
            :null}
        </div>
    );
}
