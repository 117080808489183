import {memo, useMemo} from 'react';
import { FUNCT_FIND_INDEX, FUNCT_FIND_INDEX_ARRAY, FUNC_GET_AGENDA_TYPE_ITEMS } from '../../../../../00-Core/Standards';
import { CvtSelect, CvtSelectMultiple } from '@mi-gso/cvt';
import { APP_FORM_STYLE } from '../../../../../00-Core/Constants';
import { ACTION_LINK_ITEM_TYPES } from '../../00-Helpers/ActionsConstants';
import _ from 'lodash';


export default memo(function ActionLinksSideBarAddEditItem({
    linkItem,
    projectData,
    handleInputChange,
    parsedLinksList,
    editingDestinationItem,
}) {
    
    //////////////////
    // MEMO //////////
    //////////////////
    
    const connectedItemIds = useMemo(() => {
        return parsedLinksList.map((link) => link.destinationItemId);
    }, [parsedLinksList]);
    
    const allLinkTypeItems = useMemo(() => FUNC_GET_AGENDA_TYPE_ITEMS(linkItem.type, projectData), [linkItem.type, projectData]);
    
    const linkTypeItems = useMemo(() => {

        let newConnectedItemIds = _.cloneDeep(connectedItemIds);

        if(editingDestinationItem && !linkItem.destinationItemId) {
            // TAKE OUT THE ITEM BEING EDITED ID
            const findIndex = FUNCT_FIND_INDEX_ARRAY(newConnectedItemIds, editingDestinationItem);

            newConnectedItemIds.splice(findIndex, 1);
        }

        return allLinkTypeItems.filter((item) => !newConnectedItemIds?.includes(item.id));
    }, [allLinkTypeItems, connectedItemIds, editingDestinationItem, linkItem.destinationItemId]);
    
    const itemSelectedData = useMemo(() => {
        const findIndex = FUNCT_FIND_INDEX(allLinkTypeItems, "id", linkItem.destinationItemId);

        if(findIndex !== -1) {
            return [allLinkTypeItems[findIndex]];
        }
        return [];
    }, [allLinkTypeItems, linkItem.destinationItemId]);
   
        //////////////////
    // RENDER ////////
    //////////////////
   
    return (
        <div>
            {/* TYPE */}
            <CvtSelect
                selectBlockCss="flexColStartStart modalGroup"
                selectFontSize={APP_FORM_STYLE.fontSize}
                selectCss="form-select modalInputLight modalSelectLight"
                selectPadding={APP_FORM_STYLE.selectPadding}
                selectBlockMargin={APP_FORM_STYLE.blockMargin}
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                legendMargin={APP_FORM_STYLE.legendMargin}
                name="type"
                legend="Type"
                legendWidth="100%"
                value={linkItem.type}
                list={ACTION_LINK_ITEM_TYPES}
                listValue="id"
                listName="label"
                onChange={handleInputChange}
                info="Type of item related to this link"
                infoId="actionSideBarItemType"
            />

            {/* MULTI SELECT TYPE ITEMS */}
            <CvtSelectMultiple
                selectBlockCss="flexColStartStart modalGroup"
                selectFontSize={APP_FORM_STYLE.fontSize}
                selectCss="form-select modalInputLight modalSelectLight"
                selectPadding={APP_FORM_STYLE.selectPadding}
                selectBlockMargin={APP_FORM_STYLE.blockMargin}
                placeholder="Select the item related to this link"
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                legendMargin="10px 0px 5px 0px"
                name="destinationId"
                legendTxt="Item"
                legendWidth="100%"
                value={itemSelectedData}

                options={linkTypeItems}
               
                listValue="id"
                listName="label"
                onChange={(newArray) => {
                    // GEN EVENT
                    let event = {
                        target: {
                            name: "destinationItemId",
                            value: newArray.length > 0 ? newArray[0].id : null,
                        },
                    };

                    handleInputChange(event);
                }}
                infoId="actionAgendaSideBarItemDestinationId"
                closeOnSelect={true}
                canCreate={false}
            />
        </div>
    )
});