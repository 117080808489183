import React, {memo, useState, useCallback} from 'react'

/////////////////////////////////
/// ROADMAP UPDATER COMPONENT ///
/////////////////////////////////

export default memo(
  function TextUpdater({
    item,
    viewModeCss,
    inputModeCss,
    onSave,
    onSaveKey,
    doubleClick,
    isReadOnlyMode
  }){

    //STATE INPUT ----------------------------------------------------------------------------------
    const [input, seInput] = useState({
      text: item.name,
      display: false,
    })

    //DISPLAY INPUT --------------------------------------------------------------------------------
    const toggleDisplay = useCallback((e) => {

      //INIT
      e.preventDefault();
      e.stopPropagation();

      //UPDATE SOURCE TO DISPLAY INPUT
      seInput(prevInput => {
        return({
          ...prevInput,
          text: item.name, 
          display: !input.display 
        })
      })
    },[input.display, item])

    //HANDLE CHANGE ---------------------------------------------------------------------------------
    const handleChange = useCallback((e) => {

      //INIT
      e.preventDefault(); 
      e.stopPropagation();

      //UPDATE TEXT
      seInput(prevInput => {
        return({
          ...prevInput, 
          text: e.target.value 
        })
      })
    },[])

    //UPDATE SOURCE ------------------------------------------------------------------------------
    const updateSource = useCallback((e) =>{

      //INIT
      e.stopPropagation();

      //CLOSE INPUT
      seInput(prevInput => {
        return({
          ...prevInput, 
          display: !input.display 
        })
      })

      //TEST IF STATE <> PROPS, IF YES UPDATES DETECTED
      if(input.text !== "" && input.text !== item.name){

          //CALL BACKEND (event, Key, Value)
          onSave(
            e, 
            onSaveKey, 
            input.text
          )
      }
    },[input.display, input.text, item.name, onSave, onSaveKey])

    //STOP SELECTION AND PROPAGATION --------------------------------------------------------------------
    const handleStopEvent = useCallback((e) =>{
      e.stopPropagation();
    },[])

    //RETURN UPDATER COMPONENT
    return (
      <React.Fragment>
        {input.display && !isReadOnlyMode? 
          <input 
            key={"updaterInput-" + item.id}
            type="text"
            className={"updaterInput flex-fill " + inputModeCss}
            name="text"
            id={"input-" + item.id} 
            autoFocus 
            value={input.text} 
            onMouseDown={(e) => handleStopEvent(e)}
            onClick={(e) => handleStopEvent(e)}
            onChange={(e) => handleChange(e)}
            onBlur={(e) => updateSource(e)}
          />
        :
          <div 
            className={viewModeCss}
            onClick={doubleClick ? null : (e) => toggleDisplay(e)} 
            onDoubleClick={doubleClick ? (e) => toggleDisplay(e) : null}
          >
            {item.name}
          </div>
        } 
      </React.Fragment> 

    )
  }
)
