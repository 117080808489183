// REACT
import { useMemo, useCallback, useState, memo } from 'react';
// CONSTANTS
import { RISK_MATRIX_VIEW_MODE } from '../../00-Helpers/RiskConstants';

////////////////////////////
/// RISK MATRIX TOGGLE ///
////////////////////////////
export default memo(function RiskMatrixToogle({
    selectedMode,
    riskOppDispatcher,
}) {

    /////////////////////////////////////////////////////
    /// STATE ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    const [state, setState] = useState({
        isSelecting: false,
    });

    /////////////////////////////////////////////////////
    /// MEMOS ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    const dropdownList = useMemo(() => {
        return Object.keys(RISK_MATRIX_VIEW_MODE).map((viewModeKey) => ({...RISK_MATRIX_VIEW_MODE[viewModeKey]})).filter((viewMode) => viewMode.value!==selectedMode.value);
    }, [selectedMode]);

    /////////////////////////////////////////////////////
    /// FUNCTIONS ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    // HANDLES BLUR OF DROPDOWN CONTAINER
    const handleOnBlur = useCallback((e) => {
        if(!e.currentTarget.contains(e.relatedTarget)) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isSelecting: false,
                }
            })
        }
    }, []);

    // HANDLE SELECT OF ITEM IN DROPDOWN
    const handleSelectViewMode = useCallback((e, viewMode) => {
        e.stopPropagation();

        setState((prevState) => {
            return {
                ...prevState,
                isSelecting: false,
            }
        });

        riskOppDispatcher({
            type: "SET_STATE_KEY_VALUE",
            key: 'riskSelectedViewMode',
            value: viewMode,
        })

    }, [riskOppDispatcher]);

    /////////////////////////////////////////////////////
    /// COMPONENT RENDER ////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <div className='riskMatrixToogle flexColStartCenter'
            tabIndex={0}
            onBlur={handleOnBlur}
            onClick={() => setState((prevState) => ({...prevState, isSelecting: !prevState.isSelecting}))}
        >
            {/* SELECTED OPTION CONTAINER*/}
            <div className='riskMatrixToogleSelected flexCenterCenter'
                style={{
                    background: state.isSelecting ? 'var(--border-color-gray-1)' : '',
                }}
            >
                <span className='riskMatrixToogleSelectedText'>{selectedMode.label}</span>
                <span className='material-icons riskMatrixToogleSelectedArrow'>arrow_drop_down</span>
            </div>

            {/* DROPDOWN */}
            {
                state.isSelecting &&
                <div className='riskMatrixToogleDropDown'>
                    {
                        dropdownList.map((viewMode, index) => (
                            <div
                                onClick={(e) => handleSelectViewMode(e, viewMode)}
                                className='riskMatrixToogleDropDownItem'
                                key={`risk_matrix_toggle_dropdown_item_${viewMode.value}_${index}`}
                            >
                                {viewMode.label}
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
});