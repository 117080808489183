import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import _ from "lodash";
import { FUNCT_FIND_INDEX, FUNC_ZERO_FORMAT_TO_NUM } from "../../../../00-Core/Standards";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";
import { GOV_COMPONENT_EXPAND_TYPES, GOV_ITEM_VIEW_TYPE } from "./GovConstants";
import { DEFAULT_STATE } from "./GovDispatcher";

// SET INITIAL STATE VALUES
export function GOV_GET_INITIAL_STATE(
    isReviewMode,
    wbsNavigationOptions,
    govReviews,
    govScopeChanges,
    usersList,
    securityGroup,
    appDispatcher
) {
    let initialState = _.cloneDeep(DEFAULT_STATE);

    // SET EXPAND MODE
    if (isReviewMode) {
        // IF IN REVIEW MODE, SHOW TABLE ONLY
        initialState.expandMode = GOV_COMPONENT_EXPAND_TYPES.table;
    } else {
        // ELSE SHOW TABLE AND SCOPES
        initialState.expandMode = GOV_COMPONENT_EXPAND_TYPES.both;

        // MANAGE LIMIT SIZE FOR TO COLUMN IN BIG COMPONENT
        initialState.widthSizeLimit = window.innerWidth <= 1200;
    }

    // IF NAVIGATION OPTIONS
    if (wbsNavigationOptions) {
        // IF OPTION TO OPEN AN ITEM
        if (wbsNavigationOptions.goToItemId) {
            // FIND THE ITEM
            let foundScope = FIND_OBJECT_ARRAY_ITEM(
                govScopeChanges,
                "id",
                wbsNavigationOptions.goToItemId
            );

            // IF SCOPE CHANGE, SET SCOPE DATA
            if (foundScope) {
                initialState.viewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
                initialState.govItemView = foundScope;
                initialState.govItemType = GOV_ITEM_VIEW_TYPE.scope;

                // IF WE WANT TO SHOW DIRECTLY THE LINKS TAB
                if (wbsNavigationOptions?.options?.openLinksTab) {
                    initialState.selectedViewModeContent = "connections";
                }
            }
        }
    }

    // RETURN STATE
    return initialState;
}

// GET NEXT DISPLAY ID FOR SCOPE CHANGES AND REVIEWS
export function GOV_FUNC_GET_NEXT_DISPLAY_ID(items, startStr) {
    // GET ALL DISPLAY IDS NUMBERS
    let allDisplayIds = items.map((item) => {
        let strId = item.displayId.split(startStr)[1];
        return parseInt(strId);
    });

    let lastDisplayId = 0;

    // SELECT THE HIGHER ID
    if (allDisplayIds.length > 0) {
        lastDisplayId = Math.max(...allDisplayIds);
    }

    // RETURN HIGHER ID + 1
    return startStr + FUNC_ZERO_FORMAT_TO_NUM(lastDisplayId + 1, 4);
}

// GET NEW ITEM PATH ON CLICK SAVE
export function GOV_FUNC_UPDATE_PATH(isNew, itemMutateObject, currentPath) {
    if (isNew) {
        return currentPath;
    }

    return itemMutateObject.path;
}

// CALCULATE VIEW MODE AFTER CLICKING SAVE
export function GOV_FUNC_GET_NEXT_VIEW_MODE(govItemType, govItemView) {
    let nextViewMode;
    if (govItemType === GOV_ITEM_VIEW_TYPE.scope && govItemView) {
        nextViewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
    } else {
        nextViewMode = WBS_BIG_COMPONENT_VIEW_MODE.list;
    }

    return nextViewMode;
}

// MERGE TWO GOV REVIEWS LINKS MUTATIONS
export function MERGE_GOV_REVIEW_MUTATIONS(final, newMutation) {
    let finalMutation = _.cloneDeep(final);

    // ADD AGENDA MUTATIONS TO FINAL MUTATION FOR GRAPHQL
    Object.keys(newMutation.graphql).forEach((mutationName) => {
        // IF DESTINATION DOES NOT HAVE THE MUTATION, DON'T ADD
        if (finalMutation.graphql[mutationName]?.objects) {
            finalMutation.graphql[mutationName].objects.push(
                ...newMutation.graphql[mutationName].objects
            );
        }
    });

    // SAME FOR DISPATCHER
    Object.keys(newMutation.dispatcher).forEach((tableName) => {
        // IF DESTINATION DOES NOT HAVE THE MUTATION, INIT
        if (!finalMutation.dispatcher[tableName]) {
            finalMutation.dispatcher[tableName] = {};
        }

        // FOR EACH ACTION
        Object.keys(finalMutation.dispatcher[tableName]).forEach((actionName) => {
            // IF NOT IN DESTINATION, CREATE
            if (!finalMutation.dispatcher[tableName][actionName]) {
                finalMutation.dispatcher[tableName][actionName] = [];
            }

            if (newMutation.dispatcher[tableName][actionName]) {
                finalMutation.dispatcher[tableName][actionName].push(
                    ...newMutation.dispatcher[tableName][actionName]
                );
            }
        });
    });

    return finalMutation;
}

// WILL TRY TO FIND AN OBJECT IN THE MAIN ARRAY. IF NOT, WILL FIND IT IN THE SECOND
export function FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND(mainArray, secondArray, field, value) {
    let foundIndex = FUNCT_FIND_INDEX(mainArray, field, value);

    // IF FOUND IN FIRST ARRAY, RETURN IT
    if (foundIndex > -1) {
        return {
            data: _.cloneDeep(mainArray[foundIndex]),
            fromFirstArray: true,
            index: foundIndex,
        };
    }

    // OTHERWISE, FIND IT IN SECOND ARRAY
    foundIndex = FUNCT_FIND_INDEX(secondArray, field, value);

    // NTO FOUND RETURN NULL
    if (foundIndex === -1) {
        return null;
    }

    // RETURN FROM SECONDARY ARRAY
    return {
        data: _.cloneDeep(secondArray[foundIndex]),
        fromFirstArray: false,
        index: foundIndex,
    };
}

// RETURN THE REVIEW LABEL TO BE USED IN SELECT: (01/02/2023 10h00: My Gov Review)
export function GOV_FUNC_GET_REVIEW_LABEL(govReview) {
    let startDateStr = govReview.startDate?.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });
    
    return startDateStr + " : " + govReview.name;
}
