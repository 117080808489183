import { FUNC_SAFE_GET_JSON_FROM_STRING } from "../../../../00-Core/Standards";
import {
    GOV_FUNC_GET_NEXT_VIEW_MODE,
    GOV_FUNC_UPDATE_PATH,
    MERGE_GOV_REVIEW_MUTATIONS,
} from "./GovFunctions";
import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import {
    createTelescopeDataGovScopeChange,
    updateTelescopeDataGovReview,
    updateTelescopeDataGovScopeChange,
} from "../../../../graphql/mutations";
import { multipleMutateGraphql } from "../../../00-App/02-Backend/AppBackendCommon";
import { DEFAULT_SCOPE_CHANGE_ITEM } from "./GovDispatcher";
import _ from "lodash";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";
import { GOV_SCOPE_DECISIONS_KEYS, GOV_SCOPE_STATUS_KEY } from "./GovScopeConstants";
import { GOV_ITEM_VIEW_TYPE } from "./GovConstants";
import {
    ADD_ITEM_TO_AGENDA,
    GET_MUTATION_TO_DELETE_ITEM_FROM_REVIEW_AGENDA,
    GET_NEW_AGENDA_ITEM_OBJECT,
    REMOVE_ITEM_FROM_AGENDA,
} from "./GovReviewFunctions";
import { AGENDA_ITEM_TYPES_KEYS } from "../../../../00-Core/Constants";
import { FUNC_ACTION_NEED_TO_ADD_EVENT } from "../../01-Action/00-Helpers/ActionsFunctions";

// SAVE SCOPE CHANGE
export function GOV_FUNC_SAVE_SCOPE_CHANGE(
    currentPath,
    govItemView,
    projectData,
    isNew,
    itemMutation,
    rightComponentData,
    securityGroup,
    usersList,
    currentUser,
    appDispatcher,
    govDispatcher,
    propKeyView,

) {
    // INIT
    let finalMutation = {
        graphql: {
            govReview: {
                query: updateTelescopeDataGovReview,
                objects: [],
            },
            govScopeChange: {
                query: null,
                objects: [],
            },
        },
        dispatcher: {
            govReview: {
                update: [],
            },
            govScopeChange: {
                create: [],
                update: [],
                delete: [],
            },
        },
    };
    
    let graphQLQuery;
    let govScopeChanges = projectData.govScopeChange;

    // GET THE ITEM FROM DATA
    let scopeItem = FIND_OBJECT_ARRAY_ITEM(govScopeChanges, "id", itemMutation.id);

    let eventsUpdated = isNew ? [] : JSON.parse(scopeItem.events ?? "[]");

    // UPDATE CLOSED DATE IF STATUS CHANGES
    if (
        Object.keys(itemMutation)?.includes("status") &&
        itemMutation.status === GOV_SCOPE_STATUS_KEY.closed
    ) {
        itemMutation.closedDate = new Date();
    }

    // UPDATE STATUS IF DECISION IS TAKEN
    if (
        Object.keys(itemMutation)?.includes("decision") &&
        itemMutation.decision !== GOV_SCOPE_DECISIONS_KEYS.pending
    ) {
        // UPDATE CLOSED DATE
        itemMutation.closedDate = new Date();

        // SET STATUS TO CLOSED
        itemMutation.status = GOV_SCOPE_STATUS_KEY.closed;
    }

    // UPDATE GOV REVIEW AGENDA IF LINKS ARE CREATED OR HAS CHANGED
    if (Object.keys(itemMutation)?.includes("govReviewIds")) {
        // GET ONLY ID FROM SELECT DATA
        itemMutation.govReviewIds = itemMutation.govReviewIds.map(
            (selectValue) => selectValue.value
        );

        // GET MUTATION TO UPDATE GOV REVIEWS AGENDAS
        let agendaMutations = GOV_SCOPE_GET_REVIEW_AGENDA_MUTATIONS(itemMutation, projectData);

        // ADD TO FINAL MUTATION
        finalMutation = MERGE_GOV_REVIEW_MUTATIONS(finalMutation, agendaMutations);
    }

    // UPDATE ITEM PATH
    itemMutation.path = GOV_FUNC_UPDATE_PATH(isNew, itemMutation, currentPath);

    // CALCULATE VIEW MODE TO GO TO AFTER CLICKING SAVE
    let nextViewMode = GOV_FUNC_GET_NEXT_VIEW_MODE(GOV_ITEM_VIEW_TYPE.scope, govItemView);

    // IF NEW
    if (isNew) {
        // CREATE CREATION EVENT
        eventsUpdated = [{
            user: {
                id: currentUser.username,
                label: currentUser.name,
            },
            createdOn: new Date(),
            value: "Created by " + currentUser.name,
            attribute: "createdBy"
        }]
        graphQLQuery = createTelescopeDataGovScopeChange;
    }
    // UPDATE SCOPE ITEM
    else {
        // UPDATE EVENTS
        if (!scopeItem.events) {
            // COMPATIBILITY : CHECK THAT EVENTS ARRAY EXISTS
            itemMutation.events = "[]";
        }
        
        for(const attribute of Object.keys(itemMutation)) {
            if(
                attribute !== "displayId" &&
                attribute !== "id" &&
                attribute !== "organizationId" &&
                attribute !== "projectId" &&
                attribute !== "wbsId" &&
                attribute !== "comments" &&
                attribute !== "createdBy" &&
                attribute !== "govItemType" && 
                attribute !== "path" && 
                attribute !== "comments"
            ) {
                //TEST IF WE HAVE ALREADY ADDED THE EVENT
                const eventIndex = FUNC_ACTION_NEED_TO_ADD_EVENT(
                    eventsUpdated,
                    attribute,
                    currentUser.username
                );
                
                const updatedValue = itemMutation[attribute];
                const lastValue = scopeItem[attribute];
                
                //PUSH NEW EVENTS
                if (eventIndex === -1) {
                    eventsUpdated.push({
                        user: {
                            id: currentUser.username,
                            label: currentUser.name,
                        },
                        createdOn: new Date(),
                        value: attribute + " updated by " + currentUser.name,
                        newValue: updatedValue,
                        oldValue: lastValue,
                        attribute: attribute,
                    });

                    //IF SAME DAY UPDATE VALUE
                } else {
                    eventsUpdated[eventIndex].newValue = updatedValue;
                }

            }
        }
        // eventsUpdated = ADD_ITEM_EVENT(scopeItem, itemMutation, userId);

        graphQLQuery = updateTelescopeDataGovScopeChange;
    }

    // STRINGIFY EVENTS
    itemMutation.events = JSON.stringify(eventsUpdated);

    let defaultItem = _.cloneDeep(DEFAULT_SCOPE_CHANGE_ITEM);

    // BUILD FINAL MUTATION OBJECT
    let updatedObjectMutation = {
        ...defaultItem,
        ...scopeItem,
        ...itemMutation,
        groupEditors: securityGroup.groupEditors,
        groupViewers: securityGroup.groupViewers,
        createdBy: isNew ? currentUser.username : scopeItem.createdBy,
    };

    itemMutation = {
        ...itemMutation,
        groupEditors: securityGroup.groupEditors,
        groupViewers: securityGroup.groupViewers,
    }

    // DELETE FRONT ATTRIBUTES
    delete updatedObjectMutation.updatedAt;
    delete updatedObjectMutation.govItemType;
    delete updatedObjectMutation.sort;
    delete itemMutation.govItemType;
    delete itemMutation.path;
  
    // ADD SCOPE CHANGE FOR GRAPHQL
    finalMutation.graphql.govScopeChange.query = graphQLQuery;
    finalMutation.graphql.govScopeChange.objects.push(isNew ? updatedObjectMutation : itemMutation);

    // ADD SCOPE CHANGE FOR DISPATCHER
    let dispatcherAction = isNew ? "create" : "update";
    finalMutation.dispatcher.govScopeChange[dispatcherAction].push(updatedObjectMutation);

    // SAVE ON GRAPHQL
    for (let queryName of Object.keys(finalMutation.graphql)) {
        multipleMutateGraphql(
            finalMutation.graphql[queryName].query,
            finalMutation.graphql[queryName].objects,
            appDispatcher
        );
    }

    // UPDATE APP STATE
    appDispatcher({
        type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
        object: finalMutation.dispatcher,
    });
    let dispatchPropKeyView = propKeyView ?? 0;
    // UPDATE GOVERNANCE STATE
    govDispatcher({
        type: "SET_STATE_OBJECT",
        object: {
            isEditSideBarOpen: false,
            editSideBarContent: null,
            editSideBarGroup: null,
            govItemView:
                nextViewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ? updatedObjectMutation : null,
            viewMode: nextViewMode,
            selectedItems: [],
            scrollToId: updatedObjectMutation.id,
            modalType: null,
            selectedModalOption: null,
            // IF NEXT MODE IS VIEW, THEN UPDATE PROP
            propKeyView: nextViewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ? dispatchPropKeyView + 1 : dispatchPropKeyView,
        },
    });
}

// GET THE MUTATIONS TO UPDATED GOV REVIEWS AGENDAS WHEN
// ADDING OR REMOVING AN ID IN THE govReviewIds LIST
export function GOV_SCOPE_GET_REVIEW_AGENDA_MUTATIONS(mutation, projectData) {
    // INIT FINAL MUTATION
    let finalMutation = {
        graphql: {
            govReview: {
                query: updateTelescopeDataGovReview,
                objects: [],
            },
        },
        dispatcher: {
            govReview: {
                update: [],
            },
        },
    };

    // GET OLD IDS
    let oldIds = FIND_OBJECT_ARRAY_ITEM(
        projectData.govScopeChange,
        "id",
        mutation.id,
        "govReviewIds"
    );
    oldIds = FUNC_SAFE_GET_JSON_FROM_STRING(oldIds);

    // IF NULL, INIT TO EMPLTY ARRAY
    if (!oldIds) {
        oldIds = [];
    }

    // GET NEW IDS
    let newIds = mutation.govReviewIds;

    // GET IDS THAT NEED LINK CREATION
    let linkIdsToCreate = newIds.filter((newId) => oldIds.indexOf(newId) === -1);

    // GET IDS THAT NEED LINK DELETION
    let linkIdsToDelete = oldIds.filter((oldId) => newIds.indexOf(oldId) === -1);

    // FOR EACH IDS TO CREATE, GET CORREPONDING AGENDA
    // AND CREATE THE UPDATE MUTATION
    linkIdsToCreate.forEach((idToCreate) => {
        // INIT
        let oldAgenda = null;
        let newAgenda = [];

        // GET REVIEW ITEM
        let reviewItem = FIND_OBJECT_ARRAY_ITEM(projectData.govReview, "id", idToCreate);

        // GET OLD AGENDA
        oldAgenda = FUNC_SAFE_GET_JSON_FROM_STRING(reviewItem?.agenda);

        // IF NULL, INIT TO EMPTY ARRAY
        if (!oldAgenda) {
            oldAgenda = [];
        }

        // CREATE NEW AGENDA ITEM
        let newAgendaItem = GET_NEW_AGENDA_ITEM_OBJECT(
            "Added from Scope Change",
            AGENDA_ITEM_TYPES_KEYS.scopeChange,
            mutation.id,
            ""
        );

        // ADD NEW ITEM TO REVIEW AGENDA
        newAgenda = ADD_ITEM_TO_AGENDA(newAgendaItem, oldAgenda);

        // CREATE REVIEW UPDATE ITEM MUTATION
        let reviewMutation = {
            id: idToCreate,
            agenda: JSON.stringify(newAgenda),
        };

        // ADD THE UPDATED ITEM TO FINAL MUTATION FOR DISPATCHER
        finalMutation.dispatcher.govReview.update.push({
            ...reviewItem,
            ...reviewMutation,
        });

        // SAME FOR GRAPHQL
        finalMutation.graphql.govReview.objects.push(reviewMutation);
    });

    // FOR EACH IDS TO DELETE, GET CORRESPONDING AGENDA
    // AND CREATE THE UPDATE MUTATION
    linkIdsToDelete.forEach((idToDelete) => {
        // INIT
        let newAgenda = [];

        // GET REVIEW ITEM
        let reviewItem = FIND_OBJECT_ARRAY_ITEM(projectData.govReview, "id", idToDelete);
        let oldAgenda = FUNC_SAFE_GET_JSON_FROM_STRING(reviewItem.agenda);

        // IF NULL, INIT TO EMPTY ARRAY
        if (!oldAgenda) {
            oldAgenda = [];
        }

        // REMOVE ITEM FROM AGENDA
        newAgenda = REMOVE_ITEM_FROM_AGENDA(idToDelete, oldAgenda);

        // CREATE REVIEW UPDATE ITEM MUTATION
        let reviewMutation = {
            id: idToDelete,
            agenda: JSON.stringify(newAgenda),
        };

        // ADD THE UPDATED ITEM TO FINAL MUTATION FOR DISPATCHER
        finalMutation.dispatcher.govReview.update.push({
            ...reviewItem,
            ...reviewMutation,
        });

        // SAME FOR GRAPHQL
        finalMutation.graphql.govReview.objects.push(reviewMutation);
    });

    // RETURN FINAL MUTATION
    return finalMutation;
}

// DELETE LINKS BETWEEN A SCOPE CHANGE AND REVIEWS
// WILL REMOVE THE ITEMS FROM AN AGENDA
export function GOV_SCOPE_DELETE_REVIEWS_LINKS(scopeItem, projectData) {
    // INIT FINAL MUTATION
    let finalMutation = {
        graphql: {
            govReview: {
                query: updateTelescopeDataGovReview,
                objects: [],
            },
        },
        dispatcher: {
            govReview: {
                update: [],
            },
        },
    };
    let mutation = null;

    // GET REVIEW LINKS
    let reviewLinkIds = FUNC_SAFE_GET_JSON_FROM_STRING(scopeItem.govReviewIds);

    // INIT IF EMPTY
    if (!reviewLinkIds) {
        reviewLinkIds = [];
    }

    // FOR EACH LINKS TO A REVIEW, REMOVE ITS AGENDA ITEM
    reviewLinkIds.forEach((reviewLinkId) => {
        mutation = GET_MUTATION_TO_DELETE_ITEM_FROM_REVIEW_AGENDA(
            scopeItem.id,
            reviewLinkId,
            projectData
        );

        // MERGE MUTATION TO FINAL
        finalMutation = MERGE_GOV_REVIEW_MUTATIONS(finalMutation, mutation);
    });

    return finalMutation;
}
