// REACT
import React, { memo, useCallback, useMemo } from "react";

// PACKAGES
import _ from "lodash";
import { v4 as uuid } from "uuid";

// CORE FUNCTIONS
import { FUNCT_FIND_INDEX, FUNC_GET_USER_INFO } from "../../../../../../../00-Core/Standards";

// MIGSO COMPONENTS
import { CvtButton, CvtAvatar, CvtInput } from "@mi-gso/cvt";

////////////////////////////
/// RISK CARD INFOS COMMENTS ////////
////////////////////////////

export default memo(function RiskCardInfosComments({
    commentsArray,
    editingValue,
    editingCommentId,
    usersList,
    megaUsersIds,
    currentUser,

    // FUNCTIONS
    handleEditComment,
    handleEditRoItem,
    handleInputChange,

    // READ ONLY
    isReadOnlyMode,
}) {
    //////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    //GET USER INFO --------------------------------------------------
    const getCorrectUserInfo = useCallback(
        (userId) => {
            return FUNC_GET_USER_INFO(usersList, megaUsersIds, userId);
        },
        [megaUsersIds, usersList]
    );

    //UPDATE COMMENTS ------------------------------------------------
    const updateComment = useCallback(
        (e) => {
            //UPDATE COMMENT ARRAY
            let commentsArrayUpdated = _.cloneDeep(commentsArray);
            let findIndex = FUNCT_FIND_INDEX(commentsArrayUpdated, "id", editingCommentId);

            //UPDATE
            if (findIndex > -1) {
                commentsArrayUpdated[findIndex].value = editingValue;
                //CREATE
            } else {
                //NEW COMMENT OBJECT
                let newComment = {
                    user: {
                        id: currentUser.username,
                        label: currentUser.name,
                    },
                    createdOn: new Date(),
                    id: uuid(),
                    value: editingValue,
                };

                //PUSH NEW VALUE
                commentsArrayUpdated.push(newComment);
            }

            //SEND TO BACKEND
            handleEditRoItem(e, "comments", JSON.stringify(commentsArrayUpdated));
        },
        [
            commentsArray,
            currentUser.name,
            currentUser.username,
            editingCommentId,
            editingValue,
            handleEditRoItem,
        ]
    );

    const onDeleteComment = useCallback(
        (e, commentId) => {
            // MANAGE NEW COMMENTS ARRAY
            let newComments = _.cloneDeep(commentsArray);

            newComments = newComments.filter((comment) => comment.id !== commentId);

            //SEND TO BACKEND
            handleEditRoItem(e, "comments", JSON.stringify(newComments));
        },
        [commentsArray, handleEditRoItem]
    );

    //////////////////////////////////////////////////////////////////
    /// MEMO /////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    //AVATAR OB LEFT FOR COMMENT -------------------------------------
    const avatarInputleftJsx = useMemo(
        () => (
            <CvtAvatar
                value={getCorrectUserInfo(currentUser.username)}
                radius={11}
                fontSize="10px"
                margin="0px 0px 0px 4px"
                regularTooltip={true}
                tooltipTxt={getCorrectUserInfo(currentUser.username).label}
            />
        ),
        [currentUser.username, getCorrectUserInfo]
    );

    //BUTTON TO VALIDATE ---------------------------------------------
    const buttonInputRightJsx = useMemo(
        () => (
            <div className="material-icons cardDetailsCommentInputValidate" onClick={updateComment}>
                check
            </div>
        ),
        [updateComment]
    );

    //////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER /////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>
            {/* ADD COMMENT MODAL */}
            {!isReadOnlyMode ? (
                <div className="cardDetailsComment">
                    <CvtInput
                        placeholder="New comment..."
                        type="text"
                        name="input"
                        value={editingValue}
                        onChange={handleInputChange}
                        inputBlockCss={
                            "cardDetailsCommentInputBlock " +
                            (editingCommentId ? "cardDetailsCommentInputBlockUpdating" : "")
                        }
                        inputCss={
                            "cardDetailsCommentInput " +
                            (editingCommentId ? "cardDetailsCommentInputBlockUpdating" : "")
                        }
                        leftJsx={avatarInputleftJsx}
                        rightJsx={editingValue !== "" ? buttonInputRightJsx : null}
                    />
                </div>
            ) : null}

            {/* CONTENT */}
            {commentsArray.length > 0 ? (
                commentsArray.map((commentEntry, index) => {
                    //GET SURRENT DATE OBJECT
                    let currentDate = new Date(commentEntry.createdOn);

                    //GET CURRENT DATE TEXT
                    let date = currentDate.toLocaleString(undefined, {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                    });

                    //COMMENT IS ON EDITION
                    let isBeingEdited = editingCommentId === commentEntry.id;

                    // RENDER
                    return (
                        <div
                            key={"actionComment-" + commentEntry.id}
                            className="cardDetailsCommentItem flexStartStart"
                            style={{
                                backgroundColor: isBeingEdited
                                    ? "var(--bg-color-selected-2)"
                                    : null,
                            }}
                        >
                            {/* AVATAR */}
                            <CvtAvatar
                                value={getCorrectUserInfo(commentEntry.user.id)}
                                radius={11}
                                fontSize="10px"
                                margin="6px 5px 0px 4px"
                                regularTooltip={true}
                                tooltipTxt={getCorrectUserInfo(commentEntry.user.id).label}
                            />

                            {/* DATE AND TEXT */}
                            <div className="viewModeDescriptionText">
                                <span
                                    className="actionCardInfosProgressBarDateLegend"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                >
                                    {date}
                                </span>
                                {commentEntry.value}
                            </div>

                            {/* EDIT BTN */}
                            {commentEntry.user.id === currentUser.username ? (
                                <React.Fragment>
                                    {/* EDIT */}
                                    {isBeingEdited ? (
                                         <CvtButton
                                            color="white"
                                            shape="noShape"
                                            icon={isBeingEdited ? "close" : "edit"}
                                            handleSubmit={handleEditComment}
                                            handleSubmitOptions={{
                                                commentId: commentEntry.id,
                                                text: commentEntry.value,
                                            }}
                                        />
                                    ) : null}

                                    {/* EDIT */}
                                    {!editingCommentId ? (
                                        <CvtButton
                                            color="white"
                                            shape="noShape"
                                            margin="0px -5px 0px 0px"
                                            icon={isBeingEdited ? "close" : "edit"}
                                            handleSubmit={handleEditComment}
                                            handleSubmitOptions={{
                                                commentId: commentEntry.id,
                                                text: commentEntry.value,
                                            }}
                                        />
                                    ) : null}

                                    {/* DELETE */}
                                    {!editingCommentId ? (
                                        <CvtButton
                                            color="red"
                                            shape="noShape"
                                            margin="0px 0px 0px 5px"
                                            icon="delete"
                                            handleSubmit={onDeleteComment}
                                            handleSubmitOptions={commentEntry.id}
                                            needConfirmation
                                        />
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </div>
                    );
                })
            ) : (
                <div className="cardDetailsNoData flexStartCenter">
                    <span
                        className="material-icons alertIconInfoLigth"
                        style={{
                            marginRight: "8px",
                            fontSize: "20px",
                        }}
                    >
                        info
                    </span>
                    No Comments
                </div>
            )}
        </React.Fragment>
    );
});
