// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//THE FILE TEXT
const markdown = `

# PRÄAMBEL

Die vorliegenden Nutzungsbedingungen (im Folgenden "**Nutzungsbedingungen**" genannt) sollen die vertragliche Beziehung zwischen dem Unternehmen und dem Nutzer für die Nutzung des Tools CLAYVEREST ("**CLAYVEREST**") definieren.

Der Arbeitgeber oder die Arbeit gebende Behörde/Auftraggeber des Nutzers (der "**Kunde**") hat mit der Firma MI-GSO-PCUBED einen Dienstleistungsvertrag über die Erbringung der Dienstleistungen abgeschlossen. Durch das Lesen und Akzeptieren dieser Nutzungsbedingungen stimmt der Nutzer der Aktivierung seines CLAYVEREST-Kontos zu.

Die Dienstleistungen werden von der MI-GSO-PCUBED, einer ordnungsgemäß nach französischem Recht gegründeten Gesellschaft mit Sitz in Rue Alain Fournier, Toulouse, Frankreich (die "**Gesellschaft**" oder "**MI-GSO-PCUBED**"), erbracht.

Jede Nutzung von CLAYVEREST setzt die vorherige und vorbehaltlose Annahme aller Bestimmungen dieser Nutzungsbedingungen durch den Nutzer voraus. 

# DEFINITIONEN

- "**CLAYVEREST**" bezeichnet das Werkzeug, das es dem Nutzer ermöglicht, Daten im Rahmen der Verwaltung seiner Projekte zu speichern, zu übermitteln, zu organisieren und zu verwalten. CLAYVEREST ist unter www.clayverest.com verfügbar.
- "**Daten**" bezeichnet alle Informationen, die sich auf ein Projekt des Kunden beziehen.
- "**Personenbezogene Daten**" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Eine "identifizierbare natürliche Person" ist eine natürliche Person, die direkt oder indirekt identifiziert werden kann, insbesondere durch Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, Standortdaten, einer Online-Kennung oder zu einem oder mehreren spezifischen Elementen, die für diese natürliche Person typisch sind.
- "**Dienste**" bezeichnet die Dienste, die das Unternehmen dem Kunden und dem Nutzer über den Zugang zu CLAYVEREST zur Verfügung stellt.
- "**Nutzer**" bezeichnet die natürliche Person, der Zugang zu CLAYVEREST gewährt wurde, damit sie dort ihre Aufgaben erfüllen kann.

## BESCHREIBUNG DER LEISTUNGEN

Je nach Vereinbarung zwischen dem Unternehmen und dem Kunden hat der Nutzer Zugang zu einem oder mehreren der folgenden Dienste: 

- TimeLine Generator: Erstellung von individuell anpassbaren, qualitativ hochwertigen Roadmaps, die auf Planungsdaten basieren oder auch nicht. 
- Telescope: Verwaltung von Projektportfolios und deren Hauptkomponenten (Kosten, Fristen, Projektcharta etc.)
- Core: Erstellen von Organisationen und deren Work Breakdown Structure (WBS). Diese WBS werden später in den beiden oben genannten Anwendungen verwendet.

## ZUGANG ZUR WEBSEITE UND ZU DEN LEISTUNGEN 

Der Zugang zu CLAYVEREST ist ausschließlich den Nutzern vorbehalten, die über eine vom Kunden erworbene Lizenz verfügen.

Der Kunde und der Nutzer sind allein für das ordnungsgemäße Funktionieren der Computerhardware und der Internetverbindung verantwortlich, die ihnen den Zugang zu CLAYVEREST und den Dienstleistungen ermöglichen. 

Im Falle einer geplanten oder unvorhergesehenen Unterbrechung von CLAYVEREST wird der Nutzer durch eine Nachricht, die bei der Anmeldung zu seinem Konto erscheint, über die Situation informiert.

Die Gesellschaft wird alle möglichen Anstrengungen unternehmen, um die maximale Verfügbarkeit des Dienstes zu gewährleisten. Das Unternehmen wird alle angemessenen geschäftlichen Mittel einsetzen, um die Verfügbarkeit des Dienstes zu gewährleisten.

## DAUER

Der Nutzer stimmt diesen Nutzungsbedingungen auf unbestimmte Zeit zu.

## PFLICHTEN DES NUTZERS

Der Nutzer verpflichtet sich, auf CLAYVEREST und die Dienste gemäß den geltenden Gesetzen und diesen Nutzungsbedingungen zuzugreifen und diese zu nutzen.

Es ist verboten, Daten auf CLAYVEREST hochzuladen, deren Inhalt illegal, böswillig, geeignet ist, die Rechte Dritter zu verletzen, oder gegen die guten Sitten oder die öffentliche Ordnung verstößt. Die Firma MI-GSO kann unter keinen Umständen für illegale Inhalte, die auf CLAYVEREST gespeichert sind, haftbar gemacht werden und behält sich das Recht vor, jedes Konto, das gegen diese Bestimmungen verstößt, und folglich auch alle damit verbundenen Zugänge zu sperren.

Im Rahmen der Nutzung des Tools zur Benutzerunterstützung und Überwachung neuer Funktionalitäten, dem so genannten „Ticketing“,  verpflichtet sich der Nutzer dazu, weder kommerzielle, noch personenbezogene oder gar sensible Daten (Religion, Politik, Weltanschauung, Gesundheit, sexuelle und geschlechtliche Orientierung) zu übermitteln und sich stets an die gängigen Regeln zur Internet-Nutzung zu halten: die Verwendung von Großbuchstaben begrenzen, eine klare Sprache verwenden und auf Smileys oder Abkürzungen verzichten. 

## SICHERHEIT

Die Gesellschaft wird geeignete technische und organisatorische Maßnahmen ergreifen, um ein dem Risiko angemessenes Sicherheitsniveau zu gewährleisten. 

Der Kunde hat die vom Unternehmen ergriffenen sachdienlichen Sicherheitsmaßnahmen, insbesondere in Bezug auf die Sicherheit der Geräte, validiert, um den Schutz und die Integrität der Daten während der Dauer ihrer Speicherung im Rahmen der Dienstleistungen zu gewährleisten.

## COOKIES, ANALYTIK & JOURNALISIERUNG

Das Unternehmen verwendet nur funktionale Cookies, die das Funktionieren der Plattform ermöglichen, indem sie die Authentifizierung, den Wechsel zwischen den Tools der Plattform usw. verwalten.

Das Unternehmen verwendet auch Analysetools, die es ihm ermöglichen, die Leistung von CLAYVEREST zu messen, sowie die verschiedenen Ereignisse mit Zeitstempeln zu versehen (Protokollierung), um die Sicherheit von CLAYVEREST zu steuern und zur Leistungsanalyse beizutragen.

## HAFTUNG

Der Nutzer und der Kunde haften allein für die korrekte Durchführung der Projekte. 

Das Unternehmen agiert im Rahmen des Projektmanagements als Anbieter eines Tools für die Nutzer. Unter keinen Umständen kann es für die Ergebnisse der Projekte haftbar gemacht werden. Die Haftung des Unternehmens beschränkt sich daher auf die Bereitstellung von CLAYVEREST und es verpflichtet sich, den Zugang zu CLAYVEREST sowie die Wahrung der Integrität und Vertraulichkeit der Daten zu gewährleisten.

## URHEBERRECHT UND LIZENZ

Das Unternehmen behält alle Eigentumsrechte an CLAYVEREST und seinen Diensten bei, einschließlich des gesamten Know-hows, der Methoden, des Codes, der Designs und der Verbesserungen der Leistungen, mit Ausnahme der Daten, die in jeder Projektlieferung enthalten sind. Die dank CLAYVEREST erhaltenen Projektlieferungen und erzielten Ergebnisse sind das wirtschaftliche, literarische oder künstlerische Eigentum des Nutzers oder des Kunden, je nach der zwischen ihnen geltenden Regelung, zu welchem Eigentum das Unternehmen ein Dritter bleibt.

## ÄNDERUNGEN AN CLAYVEREST UND DIESEN NUTZUNGSBEDINGUNGEN

Das Unternehmen behält sich das Recht vor, diese Nutzungsbedingungen zu ändern oder zu aktualisieren. Der Nutzer muss dann die Aktualisierung dieser Nutzungsbedingungen bestätigen, wenn er sich bei CLAYVEREST anmeldet.

## PERSÖNLICHE DATEN

> Dies ist nicht vertraglich bindend, sondern fasst Ihre Rechte als Nutzer in Bezug auf Ihre Daten zusammen. 

MI-GSO und sein Kunde (wir) haben sich im Rahmen der Steuerung ihrer Projekte für das Tool CLAYVEREST entschieden. In dieser Funktion sind wir gemeinsam für die Verarbeitung Ihrer personenbezogenen Daten verantwortlich, wie z. B. Ihre Anmeldeinformationen (E-Mail und Passwort, Telefonnummer) oder die Daten, die Sie in das Tool eingeben. Dies gründet sich auf unser berechtigtes Interesse, Ihnen die für die Durchführung Ihrer Arbeit erforderlichen Tools zur Verfügung zu stellen und die IT-Sicherheit des Tools zu gewährleisten. Wir und unsere Lieferanten werden diese Daten durch unsere Projekt-, Technik- oder Verwaltungsteams nutzen und sie speichern, bis die Projekte, an denen Sie beteiligt waren, abgeschlossen sind oder Ihr CLAYVEREST-Konto deaktiviert ist. 

Sie verfügen über verschiedene Rechte, die Sie geltend machen können, wenn Sie ein Ticket für den technischen Support öffnen. 

| Recht | Bezeichnung |
|:-----|:------|
| **Zugriff** | 	Alle Ihre Daten, mit Ausnahme der Logs, sind im Tool sichtbar und zugänglich. Für eine vollständige Kopie siehe vorheriger Absatz  |
| **Berichtigung** | 	Sie können Ihren Benutzernamen, Ihr Passwort, Ihre E-Mail-Adresse, Ihre Telefonnummer und die Art der doppelten Authentifizierung direkt berichtigen, indem Sie sich bei CLAYVEREST einloggen, auf "User Profile" klicken und dann editieren UND speichern. Sie können auch von sich aus Korrekturen an den Inhalten vornehmen, die Sie im Tool erzeugen  |
| **Löschen** | 	Sie können die Löschung Ihrer Daten verlangen, wodurch eine weitere Nutzung des Tools verhindert wird.  |
| **Einschränkung** | 	Sie können die Einschränkung der Verarbeitung Ihrer Daten verlangen, wodurch zeitweise dessen Nutzung verhindert wird. Sie können sich auch darauf beschränken, CLAYVEREST nicht zu verwenden, bis Ihre Anfrage beantwortet ist.  |
| **Portabilität** | 	Sie können Ihre Daten abrufen, um sie in einer anderen Umgebung neu zu laden.  |
| **Einspruch** | 	Ein Einspruch ist nicht möglich, da Sie dadurch daran gehindert werden, ihre beruflichen Aufgaben zu erfüllen.   |
 
Sie erreichen uns über die Person, die mit dem Schutz der persönlichen Daten Ihres Arbeitgebers betraut ist, oder über ein Support-Ticket. Bei weiter bestehender Unstimmigkeit nach diesem Kontakt können Sie eine Beschwerde bei der Commission Nationale Informatique et Libertés, Place de Fontenoy, 75007 Paris, einlegen. 

Ihre Daten werden nicht nach außerhalb des Europäischen Wirtschaftsraums weitergegeben und wir werden kein Profiling oder eine rein automatisierte Entscheidungsfindung mit ihnen durchführen.

## SPRACHE

Diese Nutzungsbedingungen wurden in mehreren Sprachen verfasst. Für die Zwecke der Auslegung dieser Nutzungsbedingungen ist die französische Version maßgeblich.

## ANWENDBARES RECHT UND ZUSTÄNDIGES GERICHT

Diese Nutzungsbedingungen unterliegen dem französischen Recht. Alle Streitigkeiten im Zusammenhang mit diesen Nutzungsbedingungen, ihrer Erarbeitung, Auslegung, Erfüllung, Beendigung oder ihrem Ablauf unterliegen der ausschließlichen Zuständigkeit der französischen Gerichte.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;