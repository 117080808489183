import { CvtInput } from "@mi-gso/cvt";
import React, { useCallback } from "react";

/////////////////////////////
///SEARCH TABLE COMPONENT ///
/////////////////////////////

export default function ScheduleTableSearch({ textSearchInput, total, scheduleDispatcher }) {
    //////////////////////////////////////////////////////////////
    /// FUNCTIONS ////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    //RESET ALL FILTERS --------------------------------------- OK
    const onChangeReset = useCallback(() => {
        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                textSearchInput: "",
            },
        });
    }, [scheduleDispatcher]);

    //ON CHANGE TEXT SEARCH INPUT ----------------------------- OK
    const onChangeInput = useCallback(
        (e) => {
            //INIT
            let key = e.target.name;
            let value = e.target.value;

            scheduleDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: key,
                value: value,
            });
        },
        [scheduleDispatcher]
    );

    //////////////////////////////////////////////////////////////
    ///RENDER ////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    return (
        <div className="flexStartCenter">
            {/* SEARCH */}
            <CvtInput
                inputBlockCss="flexStartCenter modalOptionSubBlock"
                inputCss="form-control tableSearch"
                inputBlockPadding="0px"
                inputBlockMargin="0px 20px 0px 15px"
                inputPadding="3px 8px"
                inputFontSize="12px"
                inputWidthFull={true}
                name="textSearchInput"
                placeholder="Search"
                value={textSearchInput ? textSearchInput : ""}
                onChange={onChangeInput}
            />

            {/* ICON BUTTON */}
            <div
                className={
                    "material-icons tableSearchIcon " +
                    (textSearchInput && textSearchInput !== "" ? "tableSearchIconClose" : "")
                }
                style={{ cursor: textSearchInput && textSearchInput !== "" ? "pointer" : null }}
                onClick={textSearchInput && textSearchInput !== "" ? onChangeReset : null}
            >
                {textSearchInput && textSearchInput !== "" ? "close" : "search"}
            </div>

            {/* TOTAL RESULTS */}
            <div className="flexStartCenter tableTotal">
                Total
                <span className="flexCenterCenter tableTotalValue">{total}</span>
            </div>
        </div>
    );
}
