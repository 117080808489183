// LIBS
import { useCallback, useMemo } from "react";

// COMPONENTS
import { CvtButton, CvtSpinnerGrow } from "@mi-gso/cvt";
import {
  FUNC_BOARD_GET_ORGANIZATION_PROJECTS,
  FUNC_BOARD_GET_USER_ROLE,
} from "../../00-Helpers/BoardFunctions";
import PortfolioPicture from "./PortfolioPicture";
import { FUNCT_FIND_INDEX } from "../../../../00-Core/Standards";

export default function PortfolioCard({
  portfolio,
  selectedPortfolioId,
  currentUserId,
  isMegaUser,
  projectList,
  portfolioLoadingId,
  openPortfolioWBS,
  boardDispatcher,
}) {
  /////////////////////////////////////////////////////
  // FUNCTIONS ////////////////////////////////////////
  /////////////////////////////////////////////////////

  // WHEN CLICK ON EDIT BUTTON
  const onEditPortfolio = useCallback(
    (e) => {
      e.stopPropagation();

      // SWITCH TO EDITION MODE
      boardDispatcher({
        type: "SET_SWITCH_MODE_EDIT_PORTFOLIO",
        portfolio: portfolio,
      });
    },
    [boardDispatcher, portfolio]
  );

  /////////////////////////////////////////////////////
  // MEMO /////////////////////////////////////////////
  /////////////////////////////////////////////////////

  // const oldProjectCount = useMemo(() => {
  //     let count = JSON.parse(portfolio.selectedProjects).length;
  //     JSON.parse(portfolio.selectedOrganizations).forEach((orgaId) => {
  //         count += FUNC_BOARD_GET_ORGANIZATION_PROJECTS(orgaId, projectList).length;
  //     });
  //     return count;
  // }, [portfolio.selectedOrganizations, portfolio.selectedProjects, projectList]);

  // COUNT NUMBER OF SELECTED PROJECTS
  const projectCount = useMemo(() => {
    // INIT COUNT
    let count = 0;

    // PARSE SELECTED PROJECTS
    let selectedProjects = JSON.parse(portfolio.selectedProjects);

    // CHECK WHICH PROJECTS EXIST
    count = selectedProjects.filter((project) => {
      return FUNCT_FIND_INDEX(projectList, "id", project) > -1;
    }).length;

    // COUNT PROJECTS IN ORGA
    JSON.parse(portfolio.selectedOrganizations).forEach((orgaId) => {
      // GET ORGA PROJECTS LIST
      let orgaProjectList = FUNC_BOARD_GET_ORGANIZATION_PROJECTS(
        orgaId,
        projectList
      );

      // FILTER OUT NON-EXISTENT PROJECTS
      count += orgaProjectList.filter(
        (project) => FUNCT_FIND_INDEX(projectList, "id", project.id) > -1
      ).length;
    });

    return count;
  }, [
    portfolio.selectedOrganizations,
    portfolio.selectedProjects,
    projectList,
  ]);

  // COUNT USERS
  const userCount = useMemo(() => {
    let count = 0;

    // COUNT VIEWERS
    if (portfolio.itemViewers) {
      count += portfolio.itemViewers.length;
    }

    // COUNT EDITORS
    if (portfolio.itemEditors) {
      count += portfolio.itemEditors.length;
    }

    return count;
  }, [portfolio.itemEditors, portfolio.itemViewers]);

  // FIND CURRENT USER ROLE ON PORTFOLIO
  const userRole = useMemo(() => {
    return FUNC_BOARD_GET_USER_ROLE(currentUserId, portfolio);
  }, [currentUserId, portfolio]);

  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////
  return (
    <div
      className={
        "flexColStartStart boardPortfolioListCard " +
        (portfolio.id === selectedPortfolioId
          ? "boardPortfolioListCardSelected"
          : "")
      }
      key={"portfolio_" + portfolio.id}
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (portfolioLoadingId !== portfolio.id) {
          openPortfolioWBS(portfolio);
        }
      }}
    >
      {/* LOADER */}
      {portfolioLoadingId === portfolio.id ? (
        <div className="boardPortfolioListCardLoader flexColCenterCenter">
          <CvtSpinnerGrow spinnerColor="white" />
          <span className="boardPortfolioListCardLoaderTxt">Loading...</span>
        </div>
      ) : null}

      {/* PICTURE */}
      <PortfolioPicture
        pictureUrl={portfolio.pictureUrl}
        name={portfolio.name}
      />

      {/* IS OFFICIAL */}
      {portfolio.official && (
        <div className="material-icons boardPortfolioCertifiedLogo">
          verified
        </div>
      )}

      <div
        style={{
          paddingLeft: "10px",
          marginTop: "3px",
          width: "100%",
        }}
      >
        <div className="flexBetweenCenter">
          {/* PORTFOLIO NAME */}
          <div
            className="textEllipsis"
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: "var(--color-text-1)",
            }}
          >
            {portfolio.name}
          </div>

          {/* EDIT BUTTON */}
          {(userRole === "editor" || userRole === "owner" || isMegaUser) && (
            <CvtButton
              color="gray"
              shape="noShape"
              margin="-5px 3px"
              icon="edit"
              handleSubmit={onEditPortfolio}
            />
          )}
        </div>

        {/* SHORT DESCRIPTION */}
        <div
          className="textEllipsis"
          style={{
            fontSize: "12px",
            color: "var(--color-text-3)",
            height: "20px",
          }}
        >
          {portfolio.shortDescription}
        </div>

        {/* PROJECT COUNT */}
        <div className="flexStartCenter" style={{ marginTop: "10px" }}>
          <span
            className="flexCenterCenter"
            style={{
              whiteSpace: "nowrap",
              width: "20px",
              height: "20px",
              fontSize: "13px",
              backgroundColor: "var(--border-color-gray-0)",
              borderRadius: "10px",
              marginRight: "10px",
            }}
          >
            {projectCount}
          </span>
          <span> Projects</span>
        </div>

        {/* USERS COUNT */}
        <div className="flexStartCenter" style={{ marginTop: "10px" }}>
          <span className="material-icons structureBlockTitleIcon">group</span>

          <span>{userCount} users</span>
        </div>
      </div>
    </div>
  );
}
