import { CvtAvatar,CvtCheckbox } from '@mi-gso/cvt';
import React from 'react'

import { FUNCT_FIND_INDEX_ARRAY } from '../../../../00-Core/Standards';

//FILTER CHECKBOX COMPONENT
export default function FiltersTypeCheckbox({
    wbsDomainId,
    wbsSubDomainFilters,
    item,
    options,
    handleCheckboxFilterChange,
}) {

    //RENDER
    return (
        options.map(option => {

            //TEST IF THE ITEM IS SELECTED OR NOT
            let checkedValue = false
            if(wbsSubDomainFilters && FUNCT_FIND_INDEX_ARRAY(wbsSubDomainFilters.value,option.value) > -1) checkedValue = true
            
            //USER AVATAR
            let legend = [
                <div 
                    key={"legend-" + wbsDomainId + "-" + item.subDomain + "-" + option.value}
                    className="flexStartCenter"
                    style={{maxWidth: "calc(100% - 40px)"}}
                >
                    {/* AVATAR FOR OWNER */}
                    {wbsDomainId === "owner" || (wbsDomainId === "charter" && item.subDomain === "owner") ?
                        <CvtAvatar 
                            value={option}
                            radius={10}
                            fontSize="10px"
                        />
                    :null}

                    {/* STANDARD TEXT */}
                    <div className='filterCheckBoxTxtAvatar'>
                        {option.label}
                    </div>
                </div>
            ]
           
            //RETURN
            return (
                <CvtCheckbox 
                    key={"checkBox-" + wbsDomainId + "-" + item.subDomain + "-" + option.value}
                    value={checkedValue}
                    name={option.value}
                    titleSize="12px"
                    padding="5px 0px"
                    fullWidth={true}
                    width="100%"
                    marginTop="0px"
                    jsx={legend}
                    notSwitch
                    noFocusBoxShadow
                    checkboxContainerWidth="100%"
                    handleFunction={(e, value) => handleCheckboxFilterChange(value, option.value, item.subDomain)}
                />
            );
        })
    )
}
