import { CvtTooltip } from '@mi-gso/cvt'
import React from 'react'
import CharterMini from '../CharterMini'

//CHARTER BIG CARD COMPONENT
export default function CharterBigCard({
    content,
    projectId,
    switchCard,
    flipCard,
    updateTracker,
}) {

//COMPONENT RENDER
  return (
    <div
        className="flexCenterStart charterFlipCardFront"
        style={{height: "100%"}}
    >
        <div
            className="flexColStartStart bigComponentProjectCard"
            style={{maxHeight: "100%"}}
        >

            {/* DETAIL */}
            {!flipCard ?
                <div
                    className='flexStartCenter charterSwitchView'
                    onClick={switchCard}
                >
                    <span className="material-icons charterSwitchViewIcon">
                        functions
                    </span>
                    Calculation View
                </div>
            :null}

            {/* CHARTER MINI IN BIG VIEW */}
            <CharterMini
                charterData={content}
                charterUpdate={1}
                charterDataImpact={content ? content.impact : null}
                charterDataTeamSize={content ? content.teamSize : null}
                charterDataStakeholderSize={content ? content.stakeholderSize : null}
                charterDataBudget={content ? content.budget : null}
                propKey={projectId + "-miniInBig"}
                updateTracker={updateTracker}
                displayInBigComponent
            />

            {/* TARGET */}
            <div
                className="flexStartStart bigComponentBodyMiniBlockInfo commonTextBlock"
                id="category-target"
                style={{
                    marginTop:"15px",
                    borderTop:"1px solid var(--border-color-gray-0-5)",
                    borderRadius: "6px"
                }}
            >
                {/* TARGET */}
                {content.target1 && content.target1 !== "" ?
                    <div className="flexStartStart">
                        <div
                            className="material-icons commonTextBlockIcon"
                            style={{
                                position:"sticky",
                                top:0
                            }}
                        >
                            flag
                        </div>
                        <div style={{textAlign:"start"}}>
                            {content.target1}
                        </div>
                    </div>
                :
                    <React.Fragment>
                        <span className="material-icons commonTextBlockIcon">
                            flag
                        </span>
                        <div style={{width:"100%"}}>

                            <div
                                className='commonEmptyTextLine'
                                style={{width:"90%"}}
                            >
                            </div>
                            <div
                                className='commonEmptyTextLine'
                                style={{width:"55%", marginTop:"8px"}}
                            >
                            </div>

                        </div>
                    </React.Fragment>
                }
            </div>

            {/* TOOLTIP */}
            <CvtTooltip
                anchorId="category-target"
                place="top"
                content={content.target1 && content.target1 !== "" ? "Target" : "Target (missing value)"}
            />
        </div>
    </div>
  )
}
