import React, { useCallback, useMemo, useState } from 'react'
import Cookies from 'universal-cookie'

//AUTH MINI COMPONENT
import {
	AuthAuthenticatorForm,
	AuthAuthenticatorTitle,
	AuthAuthenticatorLink,
} from './AuthAuthenticatorMiniComponents'

//CORE
import { Button, CvtButton, CvtInput } from '@mi-gso/cvt'
import { picSsoLogoDefault } from '../../../../00-Core/02-Pictures/PicturesCatalogue'
import { envVarManagement } from '../../../../envVarManagement'

/////////////////////////////////////////////////////////////////////////////////////
// Authenticator DEFAULT SIGN IN ////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export default function AuthAuthenticatorSignIn({
	handleSubmit,
	handleResetPassword,
	handleRequestAccount,
	options,
	onChange,
	errorMessage,
	handleSignUp,
}) {
	//ENV VAR
	const envSso = useMemo(() => envVarManagement('envSso'), [])

	/////////////
	/// STATE ///
	/////////////

	const [ssoDisplay, setSsoDisplay] = useState(true)

	//OPEN CLAYVEREST ACCOUNT => NEED LIKE THAT FOR BUTTON
	const handleOpenClayverestAccount = useCallback(() => {
		setSsoDisplay(false)
	}, [])

	///////////
	/// SSO ///
	///////////

	const signInWithSSO = useCallback(
		(e, options) => {
			//ONLY IF SSO ACTIVE
			if (envSso.active) {
				//NEED REDIRECT TO APP
				if (options.redirectApp) {
					//NEW COOKIE CLASS
					const cookies = new Cookies()

					//ENV VAR
					const envCookieDomain = envVarManagement('envCookieDomain')

					//ADD COOKIE FOR REDIRECTION
					cookies.set('red', options.redirectApp, {
						path: '/',
						domain: envCookieDomain,
						sameSite: 'lax',
						secure: envCookieDomain !== 'localhost',
					})
				}

				//LAUNCH SIGN IN
				window.location.assign(
					'https://' +
						envSso.params.domain +
						'/oauth2/authorize?identity_provider=' +
						options.identity_provider +
						'&response_type=CODE&client_id=' +
						envSso.params.userPoolClientId +
						'&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=' +
						envSso.params.redirectSignIn
				)
			}
		},
		[envSso]
	)

	//RENDER
	return (
		<React.Fragment>
			{/* TITLE & ERROR */}
			<AuthAuthenticatorTitle
				title='Sign in to your account'
				errorMessage={errorMessage}
				icon='account_circle'
			/>

			{envSso.active ? (
				<div style={{ marginTop: '15px' }}>
					{envSso.list.map((provider) => (
						<div key={'provider-' + provider.id} className='flexStartCenter'>
							{/* CUSTOMER PICTURE */}
							<img
								src={provider.logo}
								className='commonOrganizationLitleLogo'
								alt=''
								style={{
									fontSize: '10px',
								}}
							/>

							{/* BUTTON TO LAUnCH CUSTOMER UI */}
							<Button
								txt={'Connect with ' + provider.name + ' SSO'}
								fontWeight={500}
								css='modalButtonSubmit flexStartCenter'
								padding='8px'
								width='100%'
								fontSize='14px'
								handleSubmit={signInWithSSO}
								handleSubmitOptions={{
									identity_provider: provider.identity_provider,
									redirectApp: provider.redirectApp,
								}}
							/>
						</div>
					))}
				</div>
			) : null}

			{/* SEPARATOR */}
			{envSso.active ? (
				<div className='modalSeparator' style={{ margin: '20px 0px' }}>
					{' '}
				</div>
			) : null}

			{/* INPUTS BLOCK */}
			{envSso.active && ssoDisplay ? (
				<div className='flexStartCenter'>
					{/* PICTURE */}
					<img
						src={picSsoLogoDefault}
						className='commonOrganizationLitleLogo'
						alt=''
						style={{
							fontSize: '10px',
						}}
					/>

					{/* BUTTON */}
					<CvtButton
						color='gray'
						outline
						txt='Connect with Clayverest account'
						fullWidth
						handleSubmit={handleOpenClayverestAccount}
					/>
				</div>
			) : (
				<AuthAuthenticatorForm
					disabled={options.username === '' || options.password === ''}
					loader={options.loader}
					text='Sign In'
					handleRequestAccount={handleRequestAccount}
					handleSignUp={handleSignUp}
					onSubmit={handleSubmit}
					errorMessage={errorMessage}>
					{/* USERNAME */}
					<CvtInput
						legend='Email *'
						placeholder='Enter your email'
						inputBlockCss='modalOptionSubBlock'
						inputBlockPadding='15px 20px 10px 20px'
						inputCss='form-control appAuthenticatorInput'
						name='username'
						value={options.username}
						onChange={onChange}
						autoComplete={true}
					/>

					{/* PASSWORD */}
					<CvtInput
						type='password'
						legend='Password *'
						inputBlockPadding='0px 20px 5px 20px'
						placeholder='Enter your password'
						inputBlockCss='modalOptionSubBlock'
						inputCss='form-control appAuthenticatorInput'
						name='password'
						value={options.password}
						onChange={onChange}
						autoComplete={true}
					/>

					{/* FORGOT PASSWORD */}
					<AuthAuthenticatorLink
						legend='Forgot your password?'
						text='Reset password'
						handleClick={handleResetPassword}
					/>
				</AuthAuthenticatorForm>
			)}
		</React.Fragment>
	)
}
