import {Auth} from 'aws-amplify';
import {signOut} from './AuthSignOut'
import { envVarManagement } from '../../../../envVarManagement';

//OK NEW AUTH

//////////////////////////////////////////////////////////////////////////////
/// SIGNUP FUNCTION //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export async function signUp(
    password, 
    email,
    firstName,
    lastName,
    appDispatcher,
    authenticatorSetState
){
    //TRY TO CONNECT THE USER
    try {

        //SPLIT EMAIL
        const emailSplit = email.split("@")

        //TEST CONNECTION WITH USERNAME AND PASSWORD
        const user = await Auth.signUp({
            username: email,
            password: password,
            attributes: {
                email: email,          
                name: emailSplit[0],
                "custom:firstName":firstName,
                "custom:lastName":lastName
            }
        });

        //LAUNCH EMAIL FLOW VALIDATION
        appDispatcher({
        type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    user: user,
                    signUp: false,
                    confirmSignUp:true,
                    errorMessage: false,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false,
                codeSent: true,
                errName: ""
            })
        })

    //ERROR MANAGEMENT
    } catch (err) {

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false,
                errName: ""
            })
        })

        //UPDATE ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })
    }
}

//////////////////////////////////////////////////////////////////////////////////////
/// CONFIRM SIGNUP FUNCTION //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

export async function confirmSignUp(
    username, 
    code,
    password,
    appDispatcher,
    authenticatorSetState
){

    //ENV VAR
    const envMfaMandatory = envVarManagement("envMfaMandatory")

    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    await Auth.confirmSignUp(username, code).then(() => {

        //TEST IF PASSWORD NOT EMPTY
        if(password !== ""){

            //TRY SIGN IN
            Auth.signIn(username, password).then((user)=>{

                //IF MANDATORY 
                if(envMfaMandatory){

                    //LAUNCH NEW PASSWORD REQUEST
                    appDispatcher({
                        type:"SET_GLOBAL_STATE",
                        options:{
                            source: "currentUser",
                            object: {
                                user: user,
                                needMfaSetUp: true,
                                errorMessage: false,
                                confirmSignUp:false,
                            }
                        }
                    })

                    //RESET LOADER
                    authenticatorSetState(prevState => {
                        return({
                            ...prevState, 
                            loader:false,
                            code:"",
                            codeSent: false,
                            phoneNumber:"",
                            phoneNumberValid:false,
                            qrCode:"",
                            errName: ""
                        })
                    })

                //OK SIGN IN NOW VALIDATED THE TERMS
                }else{

                    appDispatcher({
                        type:"SET_GLOBAL_STATE",
                        options:{
                            source: "currentUser",
                            object: {
                                user:user,
                                displayTermsValidation: true,
                                username: user.username,
                                name: user.attributes.name,
                                errorMessage: false,
                                confirmSignUp:false
                            }
                        }
                    })
                }

            //CAN'T CONNECT THE USER
            }).catch(()=>{

                //BACK TO SIGN IN 
                signOut(
                    appDispatcher,
                    false
                )
            })

        //BACK TO SIGN IN
        }else{

            //BACK TO SIGN IN 
            signOut(
                appDispatcher,
                false
            )
        }

    //ERR
    }).catch((err) => {

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false,
                errName: ""
            })
        })}
    );
}

//////////////////////////////////////////////////////////////////////////////////////
/// RESEND SIGNUP CONFIRMATION CODE //////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

export async function resendConfirmationCode(
    username, 
    appDispatcher,
    authenticatorSetState
){

    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    await Auth.resendSignUp(username).then(() => {

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                codeSent:true
            })
        })
       
    
    //ERR
    }).catch((err) => {

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false,
                codeSent:false
            })
        })}
    );
}