/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTelescopeDataWbsSettings = /* GraphQL */ `
  query GetTelescopeDataWbsSettings($id: ID!) {
    getTelescopeDataWbsSettings(id: $id) {
      id
      organizationId
      projectId
      domain
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listTelescopeDataWbsSettings = /* GraphQL */ `
  query ListTelescopeDataWbsSettings(
    $id: ID
    $filter: ModelTelescopeDataWbsSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataWbsSettings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        domain
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTelescopeDataCharter = /* GraphQL */ `
  query GetTelescopeDataCharter($id: ID!) {
    getTelescopeDataCharter(id: $id) {
      id
      organizationId
      projectId
      wbsId
      guid
      displayId
      guidUpdate
      manualUpdate
      leader
      department
      customer
      customerDepartment
      type
      phase
      startDate
      endDate
      teamSize
      stakeholderSize
      budget
      impact
      description
      scope
      contextObjectives
      assumptionsConstraints
      target1
      target2
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const listTelescopeDataCharters = /* GraphQL */ `
  query ListTelescopeDataCharters(
    $id: ID
    $filter: ModelTelescopeDataCharterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataCharters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        guid
        displayId
        guidUpdate
        manualUpdate
        leader
        department
        customer
        customerDepartment
        type
        phase
        startDate
        endDate
        teamSize
        stakeholderSize
        budget
        impact
        description
        scope
        contextObjectives
        assumptionsConstraints
        target1
        target2
        category
        groupEditors
        groupViewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTelescopeDataShortcut = /* GraphQL */ `
  query GetTelescopeDataShortcut($id: ID!) {
    getTelescopeDataShortcut(id: $id) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      position
      url
      icon
      backgroundColor
      category
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const listTelescopeDataShortcuts = /* GraphQL */ `
  query ListTelescopeDataShortcuts(
    $id: ID
    $filter: ModelTelescopeDataShortcutFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataShortcuts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        name
        position
        url
        icon
        backgroundColor
        category
        groupEditors
        groupViewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTelescopeDataAction = /* GraphQL */ `
  query GetTelescopeDataAction($id: ID!) {
    getTelescopeDataAction(id: $id) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      contributors
      guid
      displayId
      guidUpdate
      manualUpdate
      action
      dueDate
      baseline
      createdAt
      updatedAt
      types
      typeInstanceIds
      realisedDate
      cancelledDate
      responsible
      status
      description
      comments
      progression
      events
      createdOn
      lastModified
      lastModifiedBy
      path
      telescopeDataGovernanceTelescopeDataActionId
      telescopeDataROTelescopeDataActionId
      telescopeDataRO {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        escalatedFrom
        escalatedTo
        initialProba
        initialImpact
        initialCriticity
        currentProba
        currentImpact
        currentCriticity
        targetProba
        targetImpact
        targetCriticity
        endProba
        endImpact
        endCriticity
        initialSchedule
        initialCost
        initialQuality
        initialScope
        currentSchedule
        currentCost
        currentQuality
        currentScope
        targetSchedule
        targetCost
        targetQuality
        targetScope
        performance
        weightedCost
        raisedDate
        lastModification
        createdOn
        createdAt
        updatedAt
        modifiedBy
        modifiedDate
        name
        longTitle
        description
        isRisk
        category
        cause
        effect
        responsible
        status
        strategy
        progressStatus
        path
        comments
        actionsIds
        telescopeDataGovernanceTelescopeDataRoIds
        events
        groupEditors
        groupViewers
      }
      telescopeDataScheduleTelescopeDataActionId
      telescopeDataCostTelescopeDataActionId
      links
      groupEditors
      groupViewers
      isPrivate
    }
  }
`;
export const listTelescopeDataActions = /* GraphQL */ `
  query ListTelescopeDataActions(
    $id: ID
    $filter: ModelTelescopeDataActionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataActions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        contributors
        guid
        displayId
        guidUpdate
        manualUpdate
        action
        dueDate
        baseline
        createdAt
        updatedAt
        types
        typeInstanceIds
        realisedDate
        cancelledDate
        responsible
        status
        description
        comments
        progression
        events
        createdOn
        lastModified
        lastModifiedBy
        path
        telescopeDataGovernanceTelescopeDataActionId
        telescopeDataROTelescopeDataActionId
        telescopeDataScheduleTelescopeDataActionId
        telescopeDataCostTelescopeDataActionId
        links
        groupEditors
        groupViewers
        isPrivate
      }
      nextToken
    }
  }
`;
export const getTelescopeDataSchedule = /* GraphQL */ `
  query GetTelescopeDataSchedule($id: ID!) {
    getTelescopeDataSchedule(id: $id) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      responsible
      createdOn
      path
      type
      actionsIds
      description
      comments
      events
      baselineStart
      baselineFinish
      forecastStart
      forecastFinish
      actualStart
      actualFinish
      groupEditors
      groupViewers
      createdAt
      updatedAt
    }
  }
`;
export const listTelescopeDataSchedules = /* GraphQL */ `
  query ListTelescopeDataSchedules(
    $id: ID
    $filter: ModelTelescopeDataScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataSchedules(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        name
        responsible
        createdOn
        path
        type
        actionsIds
        description
        comments
        events
        baselineStart
        baselineFinish
        forecastStart
        forecastFinish
        actualStart
        actualFinish
        groupEditors
        groupViewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTelescopeDataRO = /* GraphQL */ `
  query GetTelescopeDataRO($id: ID!) {
    getTelescopeDataRO(id: $id) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      escalatedFrom
      escalatedTo
      initialProba
      initialImpact
      initialCriticity
      currentProba
      currentImpact
      currentCriticity
      targetProba
      targetImpact
      targetCriticity
      endProba
      endImpact
      endCriticity
      initialSchedule
      initialCost
      initialQuality
      initialScope
      currentSchedule
      currentCost
      currentQuality
      currentScope
      targetSchedule
      targetCost
      targetQuality
      targetScope
      performance
      weightedCost
      raisedDate
      lastModification
      createdOn
      createdAt
      updatedAt
      modifiedBy
      modifiedDate
      name
      longTitle
      description
      isRisk
      category
      cause
      effect
      responsible
      status
      strategy
      progressStatus
      path
      comments
      actionsIds
      telescopeDataAction {
        nextToken
      }
      telescopeDataGovernanceTelescopeDataRoIds
      events
      groupEditors
      groupViewers
    }
  }
`;
export const listTelescopeDataROS = /* GraphQL */ `
  query ListTelescopeDataROS(
    $id: ID
    $filter: ModelTelescopeDataROFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataROS(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        escalatedFrom
        escalatedTo
        initialProba
        initialImpact
        initialCriticity
        currentProba
        currentImpact
        currentCriticity
        targetProba
        targetImpact
        targetCriticity
        endProba
        endImpact
        endCriticity
        initialSchedule
        initialCost
        initialQuality
        initialScope
        currentSchedule
        currentCost
        currentQuality
        currentScope
        targetSchedule
        targetCost
        targetQuality
        targetScope
        performance
        weightedCost
        raisedDate
        lastModification
        createdOn
        createdAt
        updatedAt
        modifiedBy
        modifiedDate
        name
        longTitle
        description
        isRisk
        category
        cause
        effect
        responsible
        status
        strategy
        progressStatus
        path
        comments
        actionsIds
        telescopeDataGovernanceTelescopeDataRoIds
        events
        groupEditors
        groupViewers
      }
      nextToken
    }
  }
`;
export const getTelescopeDataPortfolio = /* GraphQL */ `
  query GetTelescopeDataPortfolio($id: ID!) {
    getTelescopeDataPortfolio(id: $id) {
      id
      reference
      name
      pictureUrl
      status
      official
      shortDescription
      longDescription
      itemViewers
      itemEditors
      createdBy
      selectedProjects
      selectedOrganizations
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTelescopeDataPortfolios = /* GraphQL */ `
  query ListTelescopeDataPortfolios(
    $id: ID
    $filter: ModelTelescopeDataPortfolioFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataPortfolios(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        reference
        name
        pictureUrl
        status
        official
        shortDescription
        longDescription
        itemViewers
        itemEditors
        createdBy
        selectedProjects
        selectedOrganizations
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTelescopeDataGovScopeChange = /* GraphQL */ `
  query GetTelescopeDataGovScopeChange($id: ID!) {
    getTelescopeDataGovScopeChange(id: $id) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      title
      level
      status
      description
      choices
      decision
      decisionDetails
      events
      comments
      responsible
      path
      actionsIds
      telescopeDataGovReviewTelescopeDataGovScopeChangeId
      govReviewIds
      dueDate
      closedDate
      createdOn
      createdAt
      updatedAt
      groupEditors
      groupViewers
    }
  }
`;
export const listTelescopeDataGovScopeChanges = /* GraphQL */ `
  query ListTelescopeDataGovScopeChanges(
    $id: ID
    $filter: ModelTelescopeDataGovScopeChangeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataGovScopeChanges(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        title
        level
        status
        description
        choices
        decision
        decisionDetails
        events
        comments
        responsible
        path
        actionsIds
        telescopeDataGovReviewTelescopeDataGovScopeChangeId
        govReviewIds
        dueDate
        closedDate
        createdOn
        createdAt
        updatedAt
        groupEditors
        groupViewers
      }
      nextToken
    }
  }
`;
export const getTelescopeDataGovReview = /* GraphQL */ `
  query GetTelescopeDataGovReview($id: ID!) {
    getTelescopeDataGovReview(id: $id) {
      id
      organizationId
      projectId
      wbsId
      createdBy
      guid
      displayId
      guidUpdate
      manualUpdate
      name
      description
      tags
      startDate
      endDate
      objectives
      chairman
      agenda
      attendees
      reviewNotes
      path
      reviewMeetingStatus
      events
      comments
      createdAt
      updatedAt
      createdOn
      recurrencyParentId
      isRecurrencyParent
      periodicity
      endRecurrency
      groupEditors
      groupViewers
    }
  }
`;
export const listTelescopeDataGovReviews = /* GraphQL */ `
  query ListTelescopeDataGovReviews(
    $id: ID
    $filter: ModelTelescopeDataGovReviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTelescopeDataGovReviews(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        name
        description
        tags
        startDate
        endDate
        objectives
        chairman
        agenda
        attendees
        reviewNotes
        path
        reviewMeetingStatus
        events
        comments
        createdAt
        updatedAt
        createdOn
        recurrencyParentId
        isRecurrencyParent
        periodicity
        endRecurrency
        groupEditors
        groupViewers
      }
      nextToken
    }
  }
`;
export const wbsSettingsByProjectId = /* GraphQL */ `
  query WbsSettingsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataWbsSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wbsSettingsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        domain
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const charterByProjectId = /* GraphQL */ `
  query CharterByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataCharterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    charterByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        guid
        displayId
        guidUpdate
        manualUpdate
        leader
        department
        customer
        customerDepartment
        type
        phase
        startDate
        endDate
        teamSize
        stakeholderSize
        budget
        impact
        description
        scope
        contextObjectives
        assumptionsConstraints
        target1
        target2
        category
        groupEditors
        groupViewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const shortcutByProjectId = /* GraphQL */ `
  query ShortcutByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataShortcutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shortcutByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        name
        position
        url
        icon
        backgroundColor
        category
        groupEditors
        groupViewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const actionByProjectId = /* GraphQL */ `
  query ActionByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        contributors
        guid
        displayId
        guidUpdate
        manualUpdate
        action
        dueDate
        baseline
        createdAt
        updatedAt
        types
        typeInstanceIds
        realisedDate
        cancelledDate
        responsible
        status
        description
        comments
        progression
        events
        createdOn
        lastModified
        lastModifiedBy
        path
        telescopeDataGovernanceTelescopeDataActionId
        telescopeDataROTelescopeDataActionId
        telescopeDataScheduleTelescopeDataActionId
        telescopeDataCostTelescopeDataActionId
        links
        groupEditors
        groupViewers
        isPrivate
      }
      nextToken
    }
  }
`;
export const actionByResponsible = /* GraphQL */ `
  query ActionByResponsible(
    $responsible: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionByResponsible(
      responsible: $responsible
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        contributors
        guid
        displayId
        guidUpdate
        manualUpdate
        action
        dueDate
        baseline
        createdAt
        updatedAt
        types
        typeInstanceIds
        realisedDate
        cancelledDate
        responsible
        status
        description
        comments
        progression
        events
        createdOn
        lastModified
        lastModifiedBy
        path
        telescopeDataGovernanceTelescopeDataActionId
        telescopeDataROTelescopeDataActionId
        telescopeDataScheduleTelescopeDataActionId
        telescopeDataCostTelescopeDataActionId
        links
        groupEditors
        groupViewers
        isPrivate
      }
      nextToken
    }
  }
`;
export const scheduleByProjectId = /* GraphQL */ `
  query ScheduleByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduleByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        name
        responsible
        createdOn
        path
        type
        actionsIds
        description
        comments
        events
        baselineStart
        baselineFinish
        forecastStart
        forecastFinish
        actualStart
        actualFinish
        groupEditors
        groupViewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const rOByProjectId = /* GraphQL */ `
  query ROByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataROFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ROByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        escalatedFrom
        escalatedTo
        initialProba
        initialImpact
        initialCriticity
        currentProba
        currentImpact
        currentCriticity
        targetProba
        targetImpact
        targetCriticity
        endProba
        endImpact
        endCriticity
        initialSchedule
        initialCost
        initialQuality
        initialScope
        currentSchedule
        currentCost
        currentQuality
        currentScope
        targetSchedule
        targetCost
        targetQuality
        targetScope
        performance
        weightedCost
        raisedDate
        lastModification
        createdOn
        createdAt
        updatedAt
        modifiedBy
        modifiedDate
        name
        longTitle
        description
        isRisk
        category
        cause
        effect
        responsible
        status
        strategy
        progressStatus
        path
        comments
        actionsIds
        telescopeDataGovernanceTelescopeDataRoIds
        events
        groupEditors
        groupViewers
      }
      nextToken
    }
  }
`;
export const scopeChangeByProjectId = /* GraphQL */ `
  query ScopeChangeByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataGovScopeChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scopeChangeByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        title
        level
        status
        description
        choices
        decision
        decisionDetails
        events
        comments
        responsible
        path
        actionsIds
        telescopeDataGovReviewTelescopeDataGovScopeChangeId
        govReviewIds
        dueDate
        closedDate
        createdOn
        createdAt
        updatedAt
        groupEditors
        groupViewers
      }
      nextToken
    }
  }
`;
export const govReviewByProjectId = /* GraphQL */ `
  query GovReviewByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataGovReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    govReviewByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        name
        description
        tags
        startDate
        endDate
        objectives
        chairman
        agenda
        attendees
        reviewNotes
        path
        reviewMeetingStatus
        events
        comments
        createdAt
        updatedAt
        createdOn
        recurrencyParentId
        isRecurrencyParent
        periodicity
        endRecurrency
        groupEditors
        groupViewers
      }
      nextToken
    }
  }
`;
export const govReviewByRecurrencyParentId = /* GraphQL */ `
  query GovReviewByRecurrencyParentId(
    $recurrencyParentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTelescopeDataGovReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    govReviewByRecurrencyParentId(
      recurrencyParentId: $recurrencyParentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        projectId
        wbsId
        createdBy
        guid
        displayId
        guidUpdate
        manualUpdate
        name
        description
        tags
        startDate
        endDate
        objectives
        chairman
        agenda
        attendees
        reviewNotes
        path
        reviewMeetingStatus
        events
        comments
        createdAt
        updatedAt
        createdOn
        recurrencyParentId
        isRecurrencyParent
        periodicity
        endRecurrency
        groupEditors
        groupViewers
      }
      nextToken
    }
  }
`;
