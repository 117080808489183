///////////////////
/// WBS REDUCER ///
///////////////////

import { LOCKED_SHORTCUTS_STORAGE_KEY } from "../06-Shortcut/00-Helpers/ShortcutConstants";

export const wbsReducer = (state, action) => {
    //INIT
    let newState;
    let attributs = [];

    //SWITCH
    switch (action.type) {
        // BIG COMPONENT DIPLAYER --------------------------------------------------
        case "SET_BIG_COMPONENT_DISPLAYER":
            //action : selectedWbsId, selectedField, selectedItem, wbsNavigationOptions
            // OPTIONAL: goBackOpenSideBar, rightComponent

            //INIT
            let selectedWbsId = action.selectedWbsId;
            let selectedField = action.selectedField;
            let selectedItem = action.selectedItem;
            let wbsNavigationOptions = action.wbsNavigationOptions;

            // IS SHORTCUT BIG OPEN
            let isShortcutBigOpen = state.isShortcutBigOpen;

            // SPECIAL FOR SHORTCUT
            if (selectedField === "shortcut") {
                // IF SHORTCUTS WAS OPEN, AND NOW CLICKED IN THE SAME WBS ID, THEN CLOSE IT.
                if (isShortcutBigOpen === true) {
                    // WAS OPEN AND SAME WBS ROW
                    if (action.selectedWbsId === state.selectedWbsId) {
                        isShortcutBigOpen = false;

                        // IF NO OTHER COMPONENT WAS OPEN, THEN MAKE WBS ID NULL TOO
                        if (state.selectedField === null) {
                            selectedWbsId = null;
                        }
                    }
                    // MAKE SELECTED FIELD THE SAME AS BEFORE (CANT BE SHORTCUT)
                    selectedField = state.selectedField;
                }
                // IF SHORTCUTS WAS CLOSED,
                //      IF NO OTHER BIG COMPONENT WAS OPEN, KEEP CLOSED AND MAKE SELECTEDWBSID NULL AND SELECTEDFIELD NULL.
                //      ELSE IF OTHER BIG COMPONENT WAS OPEN AND SAME WBS ID, THEN OPEN IT, OTHERWISE KEEP IT LIKE IT IS.
                else if (isShortcutBigOpen === false) {
                    // NO OTHER BIG COMPONENT OPEN, SHORTCUT BIG OPEN IS FALSE
                    if (
                        (state.selectedField === null && state.selectedWbsId === null) ||
                        state.selectedWbsId !== selectedWbsId
                    ) {
                        isShortcutBigOpen = true;
                        // MAKE SELECTED FIELD NULL (CANT BE SHORTCUT)
                        selectedField = null;
                        // MAKE SELECTED WBS ID NULL TOO

                        // selectedWbsId = null;
                    }
                    // IF SAME WBS ROW CLICKED AND WAS FALSE, THEN OPEN
                    else if (state.selectedWbsId === selectedWbsId) {
                        isShortcutBigOpen = true;
                        // KEEP THE SAME BIG COMPONENT OPEN
                        selectedField = state.selectedField;
                    }
                }
            }
            //TEST IF IT THE SAME
            else if (
                state.selectedWbsId === action.selectedWbsId &&
                state.selectedField === action.selectedField &&
                !wbsNavigationOptions
            ) {
                selectedField = null;

                selectedWbsId = null;
                isShortcutBigOpen = false;
            }
            // IF OPENING DIFFERENT BRANCH/FIELD
            else {
                // IF OPENING UP NEW ONES, THEN IF SELECTED WBS ID IS IN LOCKED SHORTCUTS LOCAL STORAGE,
                // THEN SET isShortcutBigOpen TO TRUE

                let lockedShortcuts = localStorage.getItem(LOCKED_SHORTCUTS_STORAGE_KEY);

                // IF SELECTED PROJECT HAS AN ENTRY IN THE LOCKED SHORTCUTS OBJECT
                if (lockedShortcuts && JSON.parse(lockedShortcuts)[action.selectedProjectId]) {
                    let isBranchLocked =
                        JSON.parse(lockedShortcuts)[action.selectedProjectId]?.find(
                            (branchId) => branchId === selectedWbsId
                        ) !== undefined;

                    if (isBranchLocked) {
                        isShortcutBigOpen = true;
                    } else {
                        isShortcutBigOpen = false;
                    }
                }
            }

            // INIT NEW STATE
            newState = {
                ...state,
                selectedWbsId: selectedWbsId ? selectedWbsId : state.selectedWbsId,
                selectedField: selectedField ? selectedField : state.selectedField,
                selectedItem: selectedItem ? selectedItem : state.selectedItem,
                temporarySelectedWbsId: null,
                temporarySelectedField: null,
                isChanged: false,
                isShortcutBigOpen: isShortcutBigOpen,
                wbsNavigationOptions: wbsNavigationOptions,
                rightComponent: action.rightComponent
                    ? action.rightComponent
                    : state.rightComponent,
            };

            // RETURN
            return newState;

        // UPDATE FROM KEY AND VALUE -----------------------------------------------
        case "SET_STATE_KEY_VALUE":
            //action , key value

            //RETURN
            return {
                ...state,
                [action.key]: action.value,
            };

        //UPDATE SEVERAL IN ONE TIME -----------------------------------------------
        case "SET_STATE_OBJECT":
            //ACTION object

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributs = Object.keys(action.object);

            //GET ALL PREVIOUS STATE
            newState = { ...state };

            //UPDATE STATE FROM OBJECT
            for (let i = 0; i < attributs.length; i++) {
                newState[attributs[i]] = action.object[attributs[i]];
            }

            //RETURN
            return {
                ...newState,
            };

        //DEFAULT TYPE NOT CREATED ------------------------------------------------
        default:
            console.log(action.type + " > Not in WbsDispatcher!");
            return { ...state };
    }
};
