// REACT
import {memo, useCallback} from 'react';

// CONSTANTS
import { RISK_VIEW_MODE_OPTIONS } from '../../../00-Helpers/RiskConstants';

///////////////////////////////////
/// RISK CARD INFOS HEADER ////////
///////////////////////////////////

export default memo(function RiskCardInfosHeader({
    // CURRENT SELECTED
    riskViewModeSelection,
    // FUNCTIONS
    handleModeSelection,
    commentsArray,
    eventsArray,
}) {

    //////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const countData = useCallback((arrayKey) => {

        //INIT
        let counter = 0;

        //SWITH ARRAYKEY
        switch (arrayKey) {

        //COMMENTS
        case "comments":
            counter = commentsArray.length
            break;

        //COMMENTS
        case "connections":
            
            break;

        //COMMENTS
        case "events":
            counter = eventsArray.length
            break;
        
        default:
            break;
        }

        //RETURN COUNTER
        return counter
    },[commentsArray.length, eventsArray.length])
  

    //////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER /////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    return (
        <ul 
        className="nav nav-tabs"
        style={{
            position: "sticky",
            top:0,
            width:"100%",
            borderTop: "none",
            borderBottom:"none"
        }}
        >   
            {/* MAP OPTIONS */}
            {
                RISK_VIEW_MODE_OPTIONS.map((item, index) => {

                    // COUNT ITEMS
                    let counter = countData(item.key);

                    return (
                        <li
                            key={`detail_tab_${item.key}_${index}`}
                            className="nav-item"
                           
                        >   
                            <a
                                href="/#"
                                className={`
                                    nav-link bigComponentNavItem flexStartCenter
                                    ${riskViewModeSelection === item.key ? "active" : ""}
                                `}
                                onClick={(e) => handleModeSelection(e, item.key)}
                            >
                                {/* COUNTER */}
                                {
                                    item.key !== "description" ?
                                    <div className='cardDetailsTitleCounter flexCenterCenter'>
                                        {counter}
                                    </div>
                                :null}
                                
                                {/* NAME */}
                                {item.name}
                            </a>
                        </li>
                    );
                })
            }
        </ul>
    );
});