// COMPONENTS
import BoardLoader from "./BoardLoader";
import PortfolioCard from "./00-Portfolio/PortfolioCard";
import PortfolioNewCard from "./00-Portfolio/PortfolioNewCard";
import { useCallback, useMemo } from "react";
import { CvtButton, CvtCheckbox } from "@mi-gso/cvt";

export default function BoardPortfoliosList({
    portfolios,
    selectedPortfolioId,
    portfolioLoading,
    portfoliosShowClosed,
    projectList,
    currentUserId,
    isMegaUser,
    portfolioLoadingId,
    openPortfolioWBS,
    boardDispatcher,
    appDispatcher,
    userProjectsList,
}) {
    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // SWITCH TO LIST BY PROJECT
    const onListByProjectToggle = useCallback(() => {
        boardDispatcher({
            type: "SET_SIMPLE_STATE",
            key: "portfoliosShowClosed",
            value: !portfoliosShowClosed,
        });
    }, [boardDispatcher, portfoliosShowClosed]);

    // SWITCH TO PROJECT LIST VIEW
    const onSwitchToProjectListClick = useCallback(() => {
        //INIT USER
        appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
                source: "portfolioSelection",
                object: {
                    showProjetListView: true,
                },
            },
        });
    }, [appDispatcher]);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // CHECK IF THERE'S CLOSED PORTFOLIOS
    const closedPortfolioLength = useMemo(() => {
        let closedPortfolios = portfolios.filter((item) => item.status === "close");
        return closedPortfolios.length;
    }, [portfolios]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div className="structureBlock boardBrowsingStructureBlock">
            {/* TITLE */}
            <div className="structureBlockTitle flexBetweenCenter">
                <div className="flexStartCenter">
                    <span className="material-icons structureBlockTitleIcon boardTitleIcon">
                        wallet
                    </span>
                    Portfolios
                    {/* SWITCH TO PROJECT LIST VIEW */}
                    <CvtButton
                        color="blue"
                        margin="-5px 10px -5px 15px"
                        icon="category"
                        txt="See Projects"
                        size="small"
                        handleSubmit={onSwitchToProjectListClick}
                    />
                </div>

                {/* TOGGLE TO LIST BY PROJECTS */}
                {closedPortfolioLength > 0 ? (
                    <div className="flexStartCenter"  style={{ gap: "10px" }}>
                        <span>Closed</span>

                        <CvtCheckbox
                            padding="0px"
                            handleFunction={onListByProjectToggle}
                            value={portfoliosShowClosed}
                            margin="0px"
                            width="fit-content"
                        />
                        {/* CLOSED PORTFOLIO NUMBER */}
                        <span className="flexCenterCenter tableTotalValue">
                            {closedPortfolioLength}
                        </span>
                    </div>
                ) : null}
            </div>

            {/* CONTENT */}
            <div className="flexStartStart boardBrowsingBlockContent">
                {portfolioLoading ? (
                    <BoardLoader text={"Loading Portfolios ..."} marginTop="20px" />
                ) : (
                    <>
                        {/* NEW PORTFOLIO CARD */}
                        {!portfoliosShowClosed ? (
                            <PortfolioNewCard
                                portfolios={portfolios}
                                boardDispatcher={boardDispatcher}
                            />
                        ) : null}

                        {/* PORTFOLIO LIST */}
                        {portfolios.map((portfolio) => {
                            // SHOW PORTFOLIOS ONLY WITH DESIRED STATUS
                            if (
                                (portfoliosShowClosed && portfolio.status === "open") ||
                                (!portfoliosShowClosed && portfolio.status === "close")
                            ) {
                                return null;
                            };

                            return (
                                <PortfolioCard
                                    key={"portfolioCard_" + portfolio.id}
                                    portfolio={portfolio}
                                    selectedPortfolioId={selectedPortfolioId}
                                    projectList={projectList}
                                    currentUserId={currentUserId}
                                    isMegaUser={isMegaUser}
                                    portfolioLoadingId={portfolioLoadingId}
                                    openPortfolioWBS={openPortfolioWBS}
                                    boardDispatcher={boardDispatcher}
                                    userProjectsList={userProjectsList}
                                />
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}
