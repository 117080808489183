import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";
import { GOV_COMPONENT_EXPAND_TYPES } from "./GovConstants";
import { GOV_REVIEW_MEETING_STATUS_KEY } from "./GovReviewConstants";
import { GOV_SCOPE_LEVEL_KEYS, GOV_SCOPE_STATUS_KEY } from "./GovScopeConstants";

///////////////////////
///  DEAFULT STATE  ///
///////////////////////

// DEFAULT SCOPE CHANGE ITEM
export const DEFAULT_SCOPE_CHANGE_ITEM = {
    id: null,
    organizationId: null,
    projectId: null,
    wbsId: null,
    displayId: null,
    createdBy: null,
    createdOn: null,
    govItemType: "scope",

    // DATA
    title: null,
    level: GOV_SCOPE_LEVEL_KEYS.branch,
    status: GOV_SCOPE_STATUS_KEY.pending,
    responsible: null,
    govReviewIds: null,
    actionsIds: [],
    description: null,
    choices: null,
    decision: null,
    decisionDetails: null,
    dueDate: null,
    closedDate: null,
};

// DEFAULT REVIEW ITEM
export const DEFAULT_REVIEW_ITEM = {
    id: null,
    organizationId: null,
    projectId: null,
    wbsId: null,
    displayId: null,
    createdBy: null,
    createdOn: null,
    govItemType: "review",

    // DATA
    name: null,
    description: null,
    tags: "[]",
    startDate: null,
    endDate: null,
    objectives: null,
    chairman: null,
    agenda: "[]",
    reviewMeetingStatus: GOV_REVIEW_MEETING_STATUS_KEY.notStarted,

    // RECURRENCY
    recurrencyParentId: null,
    isRecurrencyParent: false,
    periodicity: null,
    endRecurrency: null,
};

// DEFAULT SCOPE TABLE FILTERS
export const DEFAULT_SCOPE_TABLE_FILTERS = {
    level: "",
    status: "",
};

export const DEFAULT_STATE = {
    editSideBarContent: null,
    editSideBarGroup: null,
    isEditSideBarOpen: false,
    selectedItems: [],
    selectedViewModeContent: null,

    // FILTERS
    levelFilters: [],
    statusFilters: [],
    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
    isReviewEditModeEnabled: false,
    isConsolidate: true,
    govItemView: null,
    govItemType: null,
    scrollToId: null,
    expandMode: GOV_COMPONENT_EXPAND_TYPES.both,
    widthSizeLimit: false,

    // SERIES MODAL
    isShowModal: false,
    modalType: null,
    modalReview: null,
    selectedModalOption: null,

    // FILTER
    searchFilters: DEFAULT_SCOPE_TABLE_FILTERS,
    textSearchInput: "",

    // VIEW MODE PROP KEY
    propKeyView: 0,
    // HAS FETCHED
    hasFetchedReviews: false,
    // IS LOADING
    isLoading: false,
};

///////////////////////
///  GOV REDUCER    ///
///////////////////////

export default function govReducer(state, action) {
    // REUSABLE VARIABLES
    let newState;
    let attributes = [];

    switch (action.type) {
        //SET STATE FROM SOURCE ATTRIBUT AND  ----------------------------------------
        case "SET_GLOBAL_STATE":
            //options: source, object

            //INIT
            let currentSource = state[action.options.source];

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributes = Object.keys(action.options.object);

            //UPDATE REFERNECE OBJECT WITH NEW VALUES
            for (let i = 0; i < attributes.length; i++) {
                currentSource[attributes[i]] = action.options.object[attributes[i]];
            }

            //RETURN
            return {
                ...state,
                [action.options.source]: currentSource,
            };

        //UPDATE SEVERAL IN ONE TIME -----------------------------------------------
        case "SET_STATE_OBJECT":
            //ACTION object

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributes = Object.keys(action.object);

            //GET ALL PREVIOUS STATE
            newState = { ...state };

            //UPDATE STATE FROM OBJECT
            for (let i = 0; i < attributes.length; i++) {
                newState[attributes[i]] = action.object[attributes[i]];
            }

            //RETURN
            return {
                ...newState,
            };

        // UPDATE FROM KEY AND VALUE -----------------------------------------------
        case "SET_STATE_KEY_VALUE":
            //action , key value

            //RETURN
            return {
                ...state,
                [action.key]: action.value,
            };

        // SELECT ACTION -----------------------------------------------------------
        case "SET_GOV_EXPAND_MODE":
            //widthSizeLimit

            //INIT
            let expandMode;

            //window <= 1200
            if (action.widthSizeLimit) {
                if (state.expandMode === GOV_COMPONENT_EXPAND_TYPES.calendar) {
                    expandMode = GOV_COMPONENT_EXPAND_TYPES.calendar;
                } else {
                    expandMode = GOV_COMPONENT_EXPAND_TYPES.table;
                }

                //window > 1200
            } else {
                expandMode = GOV_COMPONENT_EXPAND_TYPES.both;
            }

            //RETURN
            return {
                ...state,
                widthSizeLimit: action.widthSizeLimit,
                expandMode: expandMode,
            };

        default:
            console.log(`${action.type} not in the GovDispatcher :(`);
            return { ...state };
    }
}
