import React, { useCallback, useMemo } from "react";

// CORE
import _ from "lodash";
import { Button, CvtButton } from "@mi-gso/cvt";
import ScheduleTableLegend from "./ScheduleTableLegend";
import ScheduleTableSearch from "./ScheduleTableSearch";
import ScheduleTableItem from "./ScheduleTableItem";
import { SCHEDULE_COMPONENT_EXPAND_TYPES } from "../00-Helpers/ScheduleConstants";
import ScheduleTimeLineLegend from "./ScheduleTimeLineLegend";
import { TooltipProvider, Tooltip } from "react-tooltip";

////////////////////////////////
/// SCHEDULE TABLE COMPONENT ///
////////////////////////////////

export default function ScheduleTable({
  //DATA
  filteredSchedules,
  scheduleData,
  textSearchInput,
  selectedItems,
  selectedFilters,
  wbsId,
  isCurrentUserEditor,

  // DISPLAY
  expandMode,
  isReadOnlyMode,
  displayWidth,
  isReviewMode,

  //USERS
  currentUser,
  usersList,
  megaUsersIds,

  //FUNCTIONS
  onClickAdd,
  onExpandArrowClick,
  onItemClick,

  //DISPATCHER
  scheduleDispatcher,
}) {
  //////////////////////////////////////////////////////////////
  /// DATA /////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  // SORT SCHEDULE ITEMS
  const sortedSchedules = useMemo(() => {
    //MAP TO ADD SORT INDEX
    let scheduleWithStatus = filteredSchedules.map((item) => {
      //GET TIME FOR SORT NUMBER
      let sortNum = item.dueDate?.date?.getTime() || 0;

      //ADD SORT
      return {
        ...item,
        sort: sortNum,
      };
    });

    //SORT DATA
    let sorted = scheduleWithStatus.sort(function (a, b) {
      if (a.sort < b.sort) {
        return -1;
      }
      if (a.sort > b.sort) {
        return 1;
      }
      return 0;
    });

    //RETURN
    return sorted;
  }, [filteredSchedules]);

  //////////////////////////////////////////////////////////////
  /// FUNCTION /////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  // HANDLE SELECT CHECKBOX
  const handleSelectCheckbox = useCallback(
    (e) => {
      e.stopPropagation();

      let newSelectedItems = [];

      //IF NOTHING SELECT ALL
      if (selectedItems.length === 0) {
        newSelectedItems = _.cloneDeep(filteredSchedules);
      }

      // USE ACTIONS DISPATCHER
      scheduleDispatcher({
        type: "SET_STATE_OBJECT",
        object: {
          selected: newSelectedItems,
          isConfirmDelete: false,
        },
      });
    },
    [filteredSchedules, scheduleDispatcher, selectedItems.length]
  );

  //////////////////////////////////////////////////////////////
  /// RENDER ///////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  return (
    <TooltipProvider>
      {/* SCHEDULE TABLE */}
      <div
        className={`noAnimations actionTableContainer`}
        data-cy="schedule-table-container"
        style={{
          width: displayWidth,
          display: displayWidth === "0%" ? "none" : null,
          height: "100%",
          margin:
            displayWidth === "0%"
              ? "0px"
              : expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.table
              ? "0px 20px"
              : "0px 20px 0px 0px",
        }}
      >
        <div className="actionTableContainerHeader">
          {scheduleData.length === 0 ? (
            <div
              style={{
                color: "var(--color-text-2)",
                fontWeight: "600",
                fontSize: "14px",
                marginLeft: "20px",
              }}
            >
              Schedules
            </div>
          ) : null}

          {/* EXPAND ARROW */}
          <Button
            icon={
              expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.table
                ? "chevron_right"
                : "chevron_left"
            }
            css="btn commonButtonSubmitLight actionTableExpandButton"
            borderRadius="20px"
            iconFontSize="16px"
            handleSubmit={() =>
              onExpandArrowClick(
                expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.table
                  ? false
                  : true,
                false
              )
            }
          />

          {sortedSchedules.length > 0 ? (
            <div className="flexBetweenCenter">
              {/* SELECT - LEGEND */}
              <div className="flexStartCenter">
                {/* SELECT BOX */}
                {!isReadOnlyMode && isCurrentUserEditor ? (
                  <div
                    className={
                      "flexCenterCenter actionTableSelectBox " +
                      (selectedItems.length > 0
                        ? selectedItems.length === filteredSchedules.length
                          ? "actionTableSelectBoxActive"
                          : "actionTableSelectBoxMiActive"
                        : "")
                    }
                    onClick={(e) => {
                      handleSelectCheckbox(e);
                    }}
                    style={{
                      minWidth: "16px",
                      minHeight: "16px",
                      maxWidth: "16px",
                      maxHeight: "16px",
                      margin: "0px 5px 0px 12px",
                    }}
                  >
                    {selectedItems.length > 0 ? selectedItems.length : ""}
                  </div>
                ) : null}

                {/* STATUS TAGS */}
                <ScheduleTableLegend
                  filteredScheduleItems={filteredSchedules}
                  scheduleData={scheduleData}
                  textSearchInput={textSearchInput}
                  selectedFilters={selectedFilters}
                  scheduleDispatcher={scheduleDispatcher}
                />
              </div>

              {/* SEARCG + LEGEND TIME LINE */}
              <div className="flexStartCenter">
                {/* LEGEND */}
                {expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.table &&
                !isReviewMode ? (
                  <ScheduleTimeLineLegend />
                ) : null}

                {/* SEARCH BAR */}
                <ScheduleTableSearch
                  textSearchInput={textSearchInput}
                  total={filteredSchedules.length}
                  scheduleDispatcher={scheduleDispatcher}
                />
              </div>
            </div>
          ) : null}
        </div>

        {/* LIST AND CREATE BUTTON */}
        <div style={{ height: "calc(100% - 17px)" }}>
          {/* SCHEDULE LIST */}
          <div
            id="actionsTable"
            className="actionTable"
            style={{
              maxHeight: isReadOnlyMode ? "calc(100% - 60px)" : null,
            }}
          >
            {sortedSchedules.map((schedule, index) => {
              //IF ITEM SELECTED
              let isSelected =
                selectedItems.find(
                  (selected) => selected.id === schedule.id
                ) !== undefined;

              //IS A CHILD
              let isChild = schedule.wbsId !== wbsId;

              //RETURN
              return (
                <ScheduleTableItem
                  key={"scheduleTableItem-" + schedule.id}
                  scheduleItem={schedule}
                  isSelected={isSelected}
                  isExpanded={
                    expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.table
                  }
                  isReviewMode={isReviewMode}
                  selectedItems={selectedItems}
                  usersList={usersList}
                  megaUsersIds={megaUsersIds}
                  currentUser={currentUser}
                  isReadOnlyMode={isReadOnlyMode}
                  isChild={isChild}
                  scheduleDispatcher={scheduleDispatcher}
                  isCurrentUserEditor={isCurrentUserEditor}
                  onItemClick={onItemClick}
                />
              );
            })}
          </div>

          {/* CREATE BUTTON - FOOTER */}
          <div className="actionTableCreateButton flexStartCenter">
            {!isReadOnlyMode ? (
              <CvtButton
                color="white"
                shape="noShape"
                size="small"
                icon="add"
                margin="5px 0px 0px"
                fullWidth
                txt={`Create ${scheduleData.length === 0 ? "your first" : "a"} schedule item`}
                handleSubmit={onClickAdd}
              />
            ) : null}
          </div>
        </div>
      </div>

      {/* COMMON TOOLTIP */}
      <Tooltip id="schedule-table-tooltip" className="tooltipCommon" />
    </TooltipProvider>
  );
}
