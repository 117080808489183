import { memo } from "react";

export default memo(function RiskTableItemStrategyTag({
    strategyInfo, // {value: ..., color: #wdwd|var(--color-.....), name: ex_name}
}) {
    return strategyInfo ? (
        <div
            className="riskTableItemStrategyTag"
            style={{
                border: `1px solid ${strategyInfo.color}`,
                color: strategyInfo.color,
                marginTop: "-px",
            }}
        >
            {strategyInfo.label}
        </div>
    ) : null;
});
