
//AMPLIFY
import {API, graphqlOperation} from 'aws-amplify'
import {Auth} from '@aws-amplify/auth'

//GRAPHQL
import { 
    listTelescopeDataGovReviews as ListTelescopeDataGovReviews,
    actionByResponsible as ActionByResponsible,
  } from "../../../graphql/queries";

///////////////////////////////////
// SUMMARY
// - getEnvUsers
// - updateEnvUserByKeyAndValue
// - getActionsByResponsible
// - getUserGovReviews
///////////////////////////////////
  
//GET USER LIST 
export const getEnvUsers = async (authorizationToken) =>{

    //INIT
    let myInit = { 
        queryStringParameters: {
            "limit":  10000,
            "status": "enabled",
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: authorizationToken ? authorizationToken : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //CALL API
    const res = await API.get("mpCoreRestApi", "/core/user/list", myInit)
    return JSON.parse(res.body)
}

//UPDATE USER BY ID WITH KEY AND VALUE 
export const updateEnvUserByKeyAndValue = async (
    id,
    bodyObject
) =>{

    //INIT
    let myInit = { 
        body: {
            "id":  id,
            "object": bodyObject,
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //CALL API
    await API.post("mpCoreRestApi", "/core/user/update", myInit)
}

//GET USER LIST
export const getEnvUserByName = async (name) => {
    //INIT
    let myInit = {
      queryStringParameters: {
        name: name,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      }
    };
  
    //CALL API
    try {
      const result = await API.get("mpCoreRestApi", "/core/user/name", myInit);
      const resultBody = JSON.parse(result.body);
  
      //TEST
      if (resultBody.Count > 0) return false;
      return true;
  
      //ERRROR
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

    //GET USER LIST
export const getEnvUserById = async (
    id,
    token
  ) => {
  
    //INIT
    let myInit = {
      queryStringParameters: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? token : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      }
    };
  
    //CALL API
    try {
      const result = await API.get("mpCoreRestApi", "/core/user/get", myInit);
      return JSON.parse(result.body);
  
      //ERRROR
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
    

//LOAD PORTFOLIO DATA
export async function getActionsByResponsible(currentUserId) {

    // GET ACTIONS DATA
    let res = await API.graphql(
        graphqlOperation(
            ActionByResponsible, 
            { 
                responsible: currentUserId 
            }
        )
    );

    //RETURN
    return res;
}

// GET USER GOV REVIEWS THE USER IS ATTENDEES OF
export async function getUserGovReviews(currentUserId) {
    
    //GET DATA
    const res = await API.graphql(
        graphqlOperation(
            ListTelescopeDataGovReviews, 
            {
                filter: {
                    or: [
                        {attendees: {contains: currentUserId}},
                        {createdBy: {eq: currentUserId}}
                    ],
                },
                limit: 1000,
            }
        )
    );

    // CLEAR COOKIES
    return res;
}