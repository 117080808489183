//IMPORT
import {Auth} from 'aws-amplify';
import {nextSignIn} from './AuthNextSignIn'
import {signOut} from './AuthSignOut'
import { envVarManagement } from '../../../../envVarManagement';
import { checkUserFromSsoProvider } from './AuthCommon';

//////////////////////////////////////////////////////////////////////////////
/// AUTP SIGNIN FUNCTION /////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

//OK NEW AUTH

export async function autoSignIn(
    appDispatcher
){

    //ENV VAR
    const envTermsVersion = envVarManagement("envTermsVersion")
    const envMfaMandatory = envVarManagement("envMfaMandatory")
    const envSso = envVarManagement("envSso")

    //TEST PROMISE
    Auth.currentAuthenticatedUser({bypassCache: true}).then((user)=>{

        //IF USER FROM SSO PROVIDER
        let isUserFromSsoProvider = false
        if(envSso.active){
            isUserFromSsoProvider = checkUserFromSsoProvider(
            user,
            envSso.list
            )
        }

        //VALID EMAIL BEFORE
        if(!user.attributes.email_verified && !user.attributes.identities){

            //INIT USER
            appDispatcher({
                type:"SET_GLOBAL_STATE",
                options:{
                    source: "currentUser",
                    object: {
                        user: user,
                        needVerifyEmail:true,
                        refreshLoading: false,
                    }
                }
            })

        //FORCE MFA IN CASE IND
        }else if(!isUserFromSsoProvider && envMfaMandatory && user.preferredMFA === "NOMFA"){

            //LAUNCH NEW PASSWORD REQUEST
            appDispatcher({
                type:"SET_GLOBAL_STATE",
                options:{
                    source: "currentUser",
                    object: {
                        user: user,
                        needMfaSetUp: true,
                        errorMessage: false,
                        refreshLoading: false,
                    }
                }
            })

        //OK EVERYTHING GOOD
        }else if(!user.attributes['custom:terms'] || user.attributes['custom:terms'] !== envTermsVersion){

            //INIT USER
            appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    user: user,
                    displayTermsValidation: true,
                    username: user.username,
                    name: user.attributes.name,
                    refreshLoading: false,
                }
            }
            })

        //OK EVERYTHING GOOD
        }else{

            //LAUNCH NEXT SIGN IN => PREPARE THE APP
            nextSignIn(
                user,
                appDispatcher
            );
        }
        
    //ERR
    }).catch((err)=>{

        console.log(err)

        //SIGN OUT
        signOut(
            appDispatcher,
            true
        )
    })
}