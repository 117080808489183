import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'

//CORE
import {
	FUNCT_FIND_INDEX,
	FUNCT_FIND_INDEX_ARRAY,
	FUNC_CHECK_ANY_URL_VALID,
	FUNC_CHECK_DATE2_IS_AFTER_DATE1,
	FUNC_GET_DAYS_BETWEEN_DATES,
	FUNC_GET_NUM_DAYS_BETWEEN_2_DATES,
	FUNC_IS_HEX_COLOR_VALID,
} from '../../../00-Core/Standards'

//EDIT SIDE BAR
import EditSideBarConfirm from './01-Components/EditSideBarConfirm'
import EditSideBarItem from './01-Components/EditSideBarItem'
import EditSideBarHeader from './01-Components/EditSideBarHeader'
import EditSideBarGroup from './01-Components/EditSideBarGroup'
import {
	CURRENT_USER,
	CUSTOM_DISABLED_TYPES,
	CUSTOM_MAX_DATE_TYPE,
	CUSTOM_MIN_DATE_TYPE,
} from './00-Helpers/EditSideBarConstants'
import { createPortal } from 'react-dom'
import EditSideBarSaveErrorModal from './01-Components/EditSideBarSaveErrorModal'
import { GOV_REVIEW_DEFAULT_PERIODICITY } from '../04-Governance/00-Helpers/GovReviewConstants'
import { GOV_FUNC_GET_WEEK_DAYS_OBJ } from '../04-Governance/00-Helpers/GovReviewFunctions'

//COMPONEENT SIDE BAR
export default memo(function EditSideBar({
	display,
	content,
	firstAddUpdatedFields,
	title,
	group,
	onSave,
	resetEditSideBar,
	currentUser,
	isCurrentUserEditor,
	onDelete,
	viewMode,
	projectData,
}) {
	////////////////////////////////////////////
	/// STATE //////////////////////////////////
	////////////////////////////////////////////

	//STATE --------------------------------- OK
	const [state, setState] = useState({
		content: null,
		updatedFields: [],
		errorMngt: [],
		displayedGroupId: 0,
		confirmType: 0,
		confirmX: 0,
		confirmY: 0,
		showSaveErrModal: false,
		triedToSave: false,
	})

	// GET MAX DATE FROM CUSTOM MAX DATE
	const getMaxDate = useCallback(
		(item) => {
			let contentToSearchOn = state.content ?? content

			let dateToBaseFrom = contentToSearchOn.find(
				(itemInContent) => itemInContent.key === item.customMaxDate.property
			)

			let inDateType = new Date(dateToBaseFrom.value)

			switch (item.customMaxDate.type) {
				case CUSTOM_MAX_DATE_TYPE.oneYearAfter:
					return new Date(
						inDateType.getFullYear() + 1,
						inDateType.getMonth(),
						inDateType.getDate()
					)
				default:
					return inDateType
			}
		},
		[content, state.content]
	)

	// GET MIN DATE FROM CUSTOM MIN DATE
	const getMinDate = useCallback(
		(item) => {
			let contentToSearchOn = state.content ?? content
			// FIND VALUE OF OTHER DATE
			let dateToBaseFrom = contentToSearchOn.find(
				(itemInContent) => itemInContent.key === item.customMinDate.property
			)

			let inDateType = new Date(dateToBaseFrom.value)

			// RETURN MIN DATE BASED ON THE TYPE
			switch (item.customMinDate.type) {
				case CUSTOM_MIN_DATE_TYPE.oneWeekAfter:
					return new Date(
						inDateType.getFullYear(),
						inDateType.getMonth(),
						inDateType.getDate() + 7
					)
				case CUSTOM_MIN_DATE_TYPE.oneMonthAfter:
					return new Date(
						inDateType.getFullYear(),
						inDateType.getMonth() + 1,
						inDateType.getDate()
					)
				default:
					return inDateType
			}
		},
		[content, state.content]
	)

	// CHECK CUSTOM DISABLED
	const checkCustomDisable = useCallback(
		(item) => {
			let isDisabled = false
			for (let condition of item.customDisabled) {
				switch (condition.type) {
					case CUSTOM_DISABLED_TYPES.dateDiff:
						let firstDate = state.content.find(
							(itemContent) => itemContent.key === condition.firstDate
						).value
						let secondDate = state.content.find(
							(itemContent) => itemContent.key === condition.secondDate
						).value

						let dayDiff = FUNC_GET_DAYS_BETWEEN_DATES(firstDate, secondDate)

						if (dayDiff >= condition.disabledAfter) return true
						break
					default:
						isDisabled = true
				}
			}
			return isDisabled
		},
		[state.content]
	)

	// GET THE VALUE FOR THE WEEK DAYS
	const getWeekDaysObj = useCallback(() => {
		let startDate = state.content.find((item) => item.key === 'startDate').value
		let endDate = state.content.find((item) => item.key === 'endDate').value

		return GOV_FUNC_GET_WEEK_DAYS_OBJ(startDate, endDate)
	}, [state.content])

	/////////////////////////////////////////////
	/// STANDARD HANDLE CHANGE //////////////////
	/////////////////////////////////////////////

	//CHANGE --------------------------------- OK
	const handleChange = useCallback(
		(name, value, type) => {
			//INIT
			let updateValue = value
			let error = []

			/////////////////
			/// DATA MNGT ///
			/////////////////

			// CHECK IF DATE VALID
			let isError = false
			let cleanError = null

			if (value && (type === 'date' || type === 'datetime-local')) {
				//CONVERT
				let testDate = new Date(value)
				//TEST DATE
				if (
					updateValue === '' ||
					Object.prototype.toString.call(testDate) !== '[object Date]' ||
					isNaN(testDate.getTime())
				) {
					//MANAGE ERROR
					isError = true
					error.push({
						key: name,
						message: 'Date not valid',
					})
				}

				// IF DOESN'T HAVE ERROR YET
				if (!isError) {
					// CHECK IF YEAR TOO BIG OR TOO SMALL
					const hasYearError =
						testDate.getFullYear() > 9999 || testDate.getFullYear() < 1900

					// IF HAS ERROR ON YEAR, THEN PASS ERROR SAYING NOT VALID YEAR
					if (hasYearError) {
						isError = true
						error.push({
							key: name,
							message: 'Year not valid!',
						})
					}
				}

				// GET THE ITEM CONFIG TO CHECK IF DATE ORDER VERIFICATION IS NEEDED
				let itemConfigIndex = FUNCT_FIND_INDEX(state.content, 'key', name)
				let itemConfig = state.content[itemConfigIndex]

				if (itemConfig !== -1 && !isError) {
					let isDateError = false

					// CHECK TESTED DATE IS BEFORE THE LINKED DATE
					if (itemConfig?.dateIsBefore) {
						// GET LINKED DATE
						let linkedDateIndex = FUNCT_FIND_INDEX(
							state.content,
							'key',
							itemConfig.dateIsBefore
						)
						let linkedDate = state.content[linkedDateIndex].value

						// IF LINKED DATE HAS BEEN FILLED (CAN BE NULL)
						if (linkedDate) {
							// CHECK THE UPDATED DATE IS BEFORE THE LINKED DATE
							let date2afterDate1 = FUNC_CHECK_DATE2_IS_AFTER_DATE1(
								new Date(value),
								linkedDate
							)

							// IF ERROR
							if (!date2afterDate1) {
								isDateError = true
							}
						}
					} else if (itemConfig?.dateIsAfter) {
						// GET LINKED DATE
						let linkedDateIndex = FUNCT_FIND_INDEX(
							state.content,
							'key',
							itemConfig.dateIsAfter
						)
						let linkedDate = state.content[linkedDateIndex].value

						// IF LINKED DATE HAS BEEN FILLED (CAN BE NULL)
						if (linkedDate) {
							// CHECK THE UPDATED DATE IS AFTER THE LINKED DATE
							let date2afterDate1 = FUNC_CHECK_DATE2_IS_AFTER_DATE1(
								linkedDate,
								new Date(value)
							)

							// IF ERROR
							if (!date2afterDate1) {
								isDateError = true
							}
						}
					}

					if (isDateError) {
						isError = true

						// ADD ERROR FOR CURRENT ITEM
						error.push({
							key: name,
							message: 'Dates in wrong order',
							linkedTo: itemConfig?.dateIsBefore
								? itemConfig.dateIsBefore
								: itemConfig.dateIsAfter,
						})

						// ADD ERROR FOR LINKED ITEM
						error.push({
							key: itemConfig?.dateIsBefore
								? itemConfig.dateIsBefore
								: itemConfig.dateIsAfter,
							message: 'Dates in wrong order',
							linkedTo: name,
						})
					} else {
						// ELSE, NO ERROR : CHECK AND REMOVE ERRORS IF ANY
						cleanError = name

						// REMOVE LINKED ERROR
						let errorIndex = FUNCT_FIND_INDEX(
							error,
							'key',
							itemConfig?.dateIsBefore
								? itemConfig.dateIsBefore
								: itemConfig.dateIsAfter
						)
						error.splice(errorIndex, 1)
					}
				}
				// CLEAN ERROR IF CORRECTED
				if (!isError) {
					cleanError = name
				}
			}

			//CONVERT NUMBER TO INT
			else if (type === 'number') {
				if (updateValue) {
					updateValue = parseInt(updateValue)
				} else {
					updateValue = ''
				}
			}

			// IF URL NEEDS TO PASS URL CHECK
			else if (type === 'url') {
				if (FUNC_CHECK_ANY_URL_VALID(value) === false) {
					isError = true
					error.push({
						key: name,
						message: 'Url is not valid',
					})
				} else {
					cleanError = name
				}
			}

			// IF COLOR, CHECK IF HAS CORRECT HEXADECIMAL VALUE
			else if (type === 'color') {
				// IF HAS ERROR
				if (FUNC_IS_HEX_COLOR_VALID(value) === null && value.length !== 0) {
					isError = true

					error.push({
						key: name,
						message: 'Color not in correct hexadecimal form',
					})
				}
				// ELSE
				else {
					cleanError = name
				}
			}

			// GOV REVIEW PERIODICITY WEEK GAP
			else if (type === 'weekGap') {
				let startDate = state.content.find(
					(itemObj) => itemObj.key === 'startDate'
				).value

				let endRecurrency = state.content.find(
					(itemObj) => itemObj.key === 'endRecurrency'
				).value

				// IF HAS ERROR (WEEK GAP IS BIGGER THAN THE DIFFERENCE BETWEEN END RECURRENCY DATE AND START DATE)
				if (
					endRecurrency === '' ||
					Math.floor(
						FUNC_GET_NUM_DAYS_BETWEEN_2_DATES(startDate, endRecurrency)
					) /
						7 <
						value.weekGap
				) {
					isError = true
					error.push({
						key: 'endRecurrency',
						message: 'Week gap too big for the selected end recurrency date',
					})
				} else {
					cleanError = 'endRecurrency'
				}
			} else if (name === 'endRecurrency') {
				let startDate = state.content.find(
					(itemObj) => itemObj.key === 'startDate'
				).value

				let periodicityWeekGap = state.content.find(
					(itemObj) => itemObj.key === 'periodicity'
				).value.weekGap

				// IF HAS ERROR (WEEK GAP IS BIGGER THAN THE DIFFERENCE BETWEEN END RECURRENCY DATE AND START DATE)
				if (
					value === '' ||
					Math.floor(
						FUNC_GET_NUM_DAYS_BETWEEN_2_DATES(startDate, new Date(value))
					) /
						7 <
						periodicityWeekGap
				) {
					isError = true
					error.push({
						key: 'endRecurrency',
						message: 'Week gap too big for the selected end recurrency date',
					})
				} else {
					cleanError = name
				}
			}

			//////////////////
			/// THIS STATE ///
			//////////////////

			setState((prevState) => {
				//MNGT ERROR
				let content = [...prevState.content]
				let errorMngt = [...prevState.errorMngt]
				let findIndex

				//FIND ITEM IN THE STANDARD STRUCTURE
				findIndex = FUNCT_FIND_INDEX(content, 'key', name)

				//CHECK IF FOUND
				if (findIndex === -1) {
					//TEST IF WE ALREADY HAVE AN ERROR ON THE CURRENT KEY
					findIndex = FUNCT_FIND_INDEX(errorMngt, 'key', name)

					//ADD IF NOT EXIST
					if (findIndex === -1) {
						errorMngt.push({
							key: name,
							message: "Attribut doesn't exist, call administrator!",
						})
					}

					// RETURN
					return {
						...prevState,
					}

					//OK LAUNCH
				} else {
					//UPDATE CONTENT
					content[findIndex].value = updateValue

					//DATE ERROR MNAGAE
					if (isError || cleanError) {
						//TEST IF WE ALREADY HAVE AN ERROR ON THE CURRENT KEY
						let findIndexError = FUNCT_FIND_INDEX(
							errorMngt,
							'key',
							type === 'weekGap' ? 'endRecurrency' : name
						)

						//ADD IF NOT EXIST
						if (isError && findIndexError === -1) {
							errorMngt.push(...error)

							// CLEAN
						} else if (cleanError && findIndexError > -1) {
							// REMOVE ERROR
							let errorIsLinkedTo = errorMngt[findIndexError]?.linkedTo
							errorMngt.splice(findIndexError, 1)

							// IF LINKED ERROR (FOR DATES), REMOVE IT
							if (errorIsLinkedTo) {
								// FIND LINKED ERROR INDEX
								let findIndexLinkedError = FUNCT_FIND_INDEX(
									errorMngt,
									'key',
									errorIsLinkedTo
								)

								// REMOVE LINKED ERROR
								errorMngt.splice(findIndexLinkedError, 1)
							}
						}
					}

					//FOLLOW UPDATED ATTRIBUTS
					let updatedFields =
						prevState.updatedFields.length === 0 ||
						prevState.updatedFields.indexOf(name) === -1
							? [...prevState.updatedFields, name]
							: prevState.updatedFields

					//LINK DISPLAY / MANAGE UPDATE FIELDS
					if (content[findIndex].displayLink) {
						//LOOP TO CHEK IF THE CURRENT VALUE IS NOT EGAL TO THE LINKEDTO , IF YES ADD TO UPDATED OR DELETE IF EXIST
						for (let j = 0; j < content[findIndex].displayLink.length; j++) {
							//FIND OBJECT
							let findObject = content.find(
								(item) => item.key === content[findIndex].displayLink[j]
							)

							//PREVENT CHECK
							if (findObject) {
								//FIND UPDATE INDEX
								let findUpdateIndex = FUNCT_FIND_INDEX_ARRAY(
									updatedFields,
									findObject.key
								)

								//IF CURRENT VALUE === DISPLAY OPTIONS STATUS => CHECK THAT IS PRESENT TO UPDATE
								if (
									findObject.displayOptions[name] === value &&
									findUpdateIndex === -1
								) {
									updatedFields.push(findObject.key)

									// CUSTOM MIN DATE LINKS
									if (content[findIndex].customMinDateLinks) {
										// FOR EACH OF THE LINKS, UPDATE THE MIN DATE
										for (let link of content[findIndex].customMinDateLinks) {
											// GET LINK INDEX
											let linkIndex = content.findIndex(
												(item) => item.key === link.property
											)
											// GET MIN DATE
											let minDate = getMinDate(content[linkIndex])
											// SET MIN DATE
											content[linkIndex].minDate = minDate
										}
									}

									// CUSTOM MAX DATE LINKS
									if (content[findIndex].customMaxDateLinks) {
										for (let link of content[findIndex].customMaxDateLinks) {
											let linkIndex = content.findIndex(
												(item) => item.key === link.property
											)

											let maxDate = getMaxDate(content[linkIndex])

											content[linkIndex].maxDate = maxDate
										}
									}

									//IF NOT => CHECK THAT IS NOT PRESENT TO UPDATE
								} else if (
									findObject.displayOptions[name] !== value &&
									findUpdateIndex > -1
								) {
									updatedFields.splice(findUpdateIndex, 1)
								}
							}
						}
					}

					// IF IS LINKED TO OTHER KEYS
					if (content[findIndex].customDisabledLink) {
						for (let itemKey of content[findIndex].customDisabledLink) {
							let indexInContent = content.findIndex(
								(item) => item.key === itemKey
							)

							content[indexInContent].disabled = checkCustomDisable(
								content[indexInContent]
							)

							// IF DISABLED THEN CALCULATE NEW WEEK DAYS
							if (
								content[indexInContent].key === 'periodicity' &&
								content[indexInContent].disabled === true
							) {
								content[indexInContent].value = {
									...GOV_REVIEW_DEFAULT_PERIODICITY,
									weekDays: getWeekDaysObj(),
								}
							}
						}
					}

					//RETURN
					return {
						...prevState,
						content: content,
						errorMngt: errorMngt,
						updatedFields: updatedFields,
					}
				}
			})
		},
		[checkCustomDisable, getMaxDate, getMinDate, getWeekDaysObj, state.content]
	)

	//SAVE ----------------------------------- OK
	const handleSave = useCallback(
		(e) => {
			//FLATDATA WITH IDS AND ONLY UPDATED FIELDS
			let flatData = {}
			let findIndex

			//LOOP
			for (let i = 0; i < state.content.length; i++) {
				//IF ID OK
				if (state.content[i].type === 'id') {
					//INTEGRATE
					flatData[state.content[i].key] = state.content[i].value

					//CHECK IF UPDATED
				} else {
					//FIND IF UPDATED
					findIndex = FUNCT_FIND_INDEX_ARRAY(
						state.updatedFields,
						state.content[i].key
					)

					//IF YES PASS IT
					if (findIndex > -1) {
						//IF DATE CONVERT IT
						if (state.content[i].type === 'date') {
							if (state.content[i].value) {
								flatData[state.content[i].key] = new Date(
									state.content[i].value
								)
							} else {
								flatData[state.content[i].key] = null
							}

							//NORMAL
						} else if (state.content[i].type === 'number') {
							//CONVERT TO INT
							if (state.content[i].value) {
								flatData[state.content[i].key] = parseInt(
									state.content[i].value
								)
							} else {
								flatData[state.content[i].key] = null
							}

							//OK FOR ALL
						} else {
							//INTEGRATE
							flatData[state.content[i].key] = state.content[i].value
						}
					}
				}
			}

			//PASS IF NEW
			let idObject = state.content.find((item) => item.key === 'id')

			//UPDATE DATA BACK FRONT
			onSave(e, flatData, idObject.isNew)

			//MANAGE GROUP OPEN TO DISSMISS RENDER FOR COLLAPSE
			let groupId = 0
			let bodyElement = document.getElementById('rightSideBarBody')
			let groupElements = bodyElement.querySelectorAll(
				'.rightSideBarGroupButton'
			)

			//LOOP ON GROUP
			groupElements.forEach(function (item) {
				if (!item.classList.contains('collapsed')) {
					groupId = parseInt(item.id)
				}
			})

			//THIS STATE
			setState((prevState) => {
				return {
					...prevState,
					displayedGroupId: groupId,
					confirmType: 0,
					confirmX: 0,
					confirmY: 0,
				}
			})
		},
		[onSave, state.content, state.updatedFields]
	)

	// HANDLE SHOW/HIDE SAVE ERROR MODAL
	const handleShowHideSaveModal = useCallback((e) => {
		e.stopPropagation()
		setState((prevState) => {
			return {
				...prevState,
				showSaveErrModal: !prevState.showSaveErrModal,
				triedToSave: true,
			}
		})
	}, [])

	//CORFIRMATION --------------------------- OK
	const handleConfirm = useCallback(
		(e, reset) => {
			//RESET
			if (reset) {
				//ONLY IF NEEDED
				if (state.confirmType > 0) {
					setState((prevState) => {
						return {
							...prevState,
							confirmType: 0,
							confirmX: 0,
							confirmY: 0,
						}
					})
				}

				//DISPLAY MESSAGE
			} else {
				setState((prevState) => {
					//INIT
					let confirmType

					//TEST CONFIRM MESSAGE
					if (
						prevState.updatedFields.length > 0 &&
						prevState.errorMngt.length === 0
					) {
						confirmType = 1

						//SI ERROR
					} else if (prevState.errorMngt.length > 0) {
						confirmType = 2
					}

					//RETURN
					return {
						...prevState,
						confirmType: confirmType,
						confirmX: e.clientX,
						confirmY: e.clientY,
					}
				})
			}
		},
		[state.confirmType]
	)

	/////////////////////////////////////////////
	/// USE EFFECT //////////////////////////////
	/////////////////////////////////////////////

	//INIT UPDATED FIELDS FOR NEW ITEM ------- OK
	const initUpdatedFields = useMemo(() => {
		//TEST IF WE NEED TO INIT UPDATED FIELDS DATA (LIKE INIT RESPONSIBLE / DUE DATE ...)
		if (content && firstAddUpdatedFields) {
			//FIND IF NEW ITEM
			let findIndex = FUNCT_FIND_INDEX(content, 'key', 'id')

			//ID IS MANDATORY BUT CHECK IF NOT OK (DEV PREVENTION)
			if (findIndex > -1 && content[findIndex].isNew)
				return firstAddUpdatedFields
		}

		//RETURN EMPTY IS NOT
		return []
	}, [content, firstAddUpdatedFields])

	//UPDATE STATE --------------------------- OK
	useEffect(() => {
		setState((prevState) => {
			return {
				...prevState,
				content: display ? content : null,
				updatedFields: initUpdatedFields,
				errorMngt: [],
				confirmType: 0,
				confirmX: 0,
				confirmY: 0,
				triedToSave: false,
			}
		})
	}, [content, display, initUpdatedFields])

	//////////////////////////////////////////////
	/// DISPLAY //////////////////////////////////
	//////////////////////////////////////////////

	//GET ITEM LIST WITH NO GROUP, ON TOP ----- OK
	const itemsWithNoGroup = useMemo(() => {
		if (state.content) {
			return state.content.filter((item) => item.groupId === 0)
		}
		return null
	}, [state.content])

	//CAN SAVE RELATED ALL POSSIBILITIES ------ OK
	const canSave = useMemo(() => {
		if (state.content) {
			//LOOP ON STATE CONTENT
			for (let item of state.content) {
				// SPECIAL CHECK FOR REVIEW PERIODICITY
				if (item.key === 'periodicity') {
					let isRecurrencyParent = state.content.find(
						(itemInContent) => itemInContent.key === 'isRecurrencyParent'
					)

					let parsed = item.value

					if (parsed && typeof parsed === 'string') {
						parsed = JSON.parse(parsed)
					}

					// IF ONE OF THE PROPERTIES IS NOT FILLED, THEN CAN'T SAVE :/
					if (
						isRecurrencyParent &&
						isRecurrencyParent.value === true &&
						(!parsed?.weekGap ||
							(parsed?.weekDays &&
								!Object.values(parsed?.weekDays).includes(true)))
					) {
						return false
					} else continue
				} else if (item.key === 'endRecurrency') {
					if (
						state.content?.find(
							(itemInContent) => itemInContent.key === 'isRecurrencyParent'
						)?.value === true &&
						(!item.value || item.value === '')
					) {
						return false
					} else continue
				}

				//FIRST TEST ON MANDATORY FIELDS
				if (
					item.mandatory &&
					item.mandatory === true &&
					(!item.value || item.value === '')
				) {
					//TEST IF WE HAVE A DISPLAY LOGIC
					if (item.displayOptions) {
						//GET ATTRIBUT LIST
						let attributs = Object.keys(item.displayOptions)

						//LOOP TO CHECK KEY / VALUE
						for (let i = 0; i < attributs.length; i++) {
							//FIND INDEX
							let findIndex = FUNCT_FIND_INDEX(
								state.content,
								'key',
								attributs[i]
							)
							if (findIndex > -1) {
								//TEST IF THE VALUE CORRESPOND
								if (
									state.content[findIndex].value ===
									item.displayOptions[attributs[i]]
								) {
									return false
								}
							}
						}

						//IF NO DISPLAY OPTION CAN'T SAVE
					} else {
						return false
					}
				}
			}
		}
		return true
	}, [state.content])

	//DISPLAY FIELD RELATED TO DISPLAY OPTION - OK
	const doNotDisplayFields = useCallback(
		(item) => {
			//INIT
			let toSearchOn = state.content ? state.content : content

			//LOOP ON DISPLAY ATTRIBUTE
			for (let displayOption of Object.keys(item.displayOptions)) {
				//FOUND EDIT SIDEBAR OBJECT
				let foundPropertyItem = toSearchOn.find(
					(propertyItem) => propertyItem.key === displayOption
				)

				// IF FOUND
				if (foundPropertyItem) {
					//SPECIFICITY FOR CURRENT USER
					let value =
						item.displayOptions[displayOption] === CURRENT_USER
							? currentUser.username
							: item.displayOptions[displayOption]

					//MAKE THE TEST
					if (foundPropertyItem?.value !== value) {
						return true
					}

					//IF NOT RETURN FALSE
				} else {
					return true
				}
			}

			//TRUE IN DEFAULT
			return false
		},
		[content, currentUser.username, state.content]
	)

	/////////////////////////
	/// RETURN COMPONEENT ///
	/////////////////////////

	return createPortal(
		<React.Fragment>
			<div
				className={
					'offcanvas offcanvas-end rightSideBar ' + (display ? 'show' : '')
				}
				tabIndex='-1'
				id='offcanvasRight'
				style={{ visibility: display ? 'visible' : 'hidden' }}
				onClick={(e) => handleConfirm(e, true)}
				data-cy='rightEditSideBar'>
				{/* ONLY IF SIDE BAR DISPLAYED */}
				{display ? (
					<React.Fragment>
						{/* HEADER */}
						<EditSideBarHeader
							title={title}
							updatedFields={state.updatedFields}
							errorMngt={state.errorMngt}
							handleSave={handleSave}
							handleShowHideSaveModal={handleShowHideSaveModal}
							resetEditSideBar={resetEditSideBar}
							canSave={canSave}
							itemId={
								state.content
									? state.content.find((item) => item.key === 'id').value
									: null
							}
							onDelete={onDelete}
							viewMode={viewMode}
						/>

						{/* BODY */}
						<div id='rightSideBarBody' className='offcanvas-body'>
							{/* ACCORDION  */}
							{!display && !state.content ? null : (
								<div
									className='accordion accordion-flush'
									id='accordionEditSideBar'>
									{/* ON THE TOP MAP ITEM WIHTOUT GROUP */}
									<div
										className='rightSideBarNoGroup'
										style={{
											borderBottom:
												group && group.length > 1
													? '1px dashed var(--border-color-gray-0)'
													: null,
										}}>
										{!itemsWithNoGroup
											? null
											: itemsWithNoGroup.map((item, index) => {
													//TEST IF ITEM IN ERROR
													let testError = false
													let findError = FUNCT_FIND_INDEX(
														state.errorMngt,
														'key',
														item.key
													)
													if (findError > -1) {
														testError = true
													}

													//TWO IN A ROW
													if (item.rowWithNext) {
														// CHECK IF NEED TO DISPLAY NEXT (THE INPUT AFTER THIS)
														const isDisplayNext =
															itemsWithNoGroup[index + 1] &&
															itemsWithNoGroup[index + 1].displayOptions &&
															doNotDisplayFields(itemsWithNoGroup[index + 1]) &&
															itemsWithNoGroup[index + 1].showNextIfHidden
														// INIT THE CORRECT INDEX
														const nextIndex = isDisplayNext
															? index + 2
															: index + 1

														const isDisplayNextEditItem =
															itemsWithNoGroup[nextIndex] &&
															(!itemsWithNoGroup[nextIndex].displayOptions ||
																(itemsWithNoGroup[nextIndex].displayOptions &&
																	!doNotDisplayFields(
																		itemsWithNoGroup[nextIndex]
																	)))

														return (
															<div
																key={'editItem-' + item.key}
																className={
																	itemsWithNoGroup[nextIndex].component ===
																	'rightButton'
																		? 'flexStartEnd'
																		: 'flexStartStart'
																}
																style={{
																	width:
																		item.halfWidth && !isDisplayNextEditItem
																			? 'calc(50% - 15px)'
																			: null,
																}}>
																{/* CURRENT ITEM */}
																{!item.displayOptions ||
																(item.displayOptions &&
																	!doNotDisplayFields(item)) ? (
																	<EditSideBarItem
																		key={'editItem-' + item.key}
																		item={item}
																		handleChange={handleChange}
																		testError={testError}
																		currentUserId={currentUser.username}
																		isCurrentUserEditor={isCurrentUserEditor}
																		errorMessage={
																			testError
																				? state.errorMngt[findError].message
																				: null
																		}
																		projectData={projectData}
																		entireContent={state.content}
																	/>
																) : null}

																{/* TEST DISPLAY OPTION */}
																{isDisplayNextEditItem ? (
																	<React.Fragment>
																		{/* SPACE BETWEEN */}
																		<div
																			style={{
																				minWidth: '30px',
																				maxWidth: '30px',
																			}}></div>

																		{/* ITEM */}
																		<EditSideBarItem
																			key={
																				'editItem-' +
																				itemsWithNoGroup[nextIndex].key
																			}
																			item={itemsWithNoGroup[nextIndex]}
																			handleChange={handleChange}
																			testError={testError}
																			currentUserId={currentUser.username}
																			isCurrentUserEditor={isCurrentUserEditor}
																			errorMessage={
																				testError
																					? state.errorMngt[findError].message
																					: null
																			}
																			projectData={projectData}
																			entireContent={state.content}
																		/>
																	</React.Fragment>
																) : null}
															</div>
														)
													}

													//TEST DISPLAY OPTION
													if (item.displayOptions && doNotDisplayFields(item))
														return null

													// IF IS DATE AND HAS customMinDate PROPERTY SET THE MIN DATE.
													if (
														(item.type === 'date' ||
															item.type === 'datetime-local') &&
														item.customMinDate
													) {
														item.minDate = getMinDate(item)
														item.maxDate = getMaxDate(item)
													}

													//NOT DISPLAY IF ALREADY DISPLAY IN A ROW
													if (
														index > 0 &&
														itemsWithNoGroup[index - 1].rowWithNext
													) {
														return null
													}

													// IF THE PREVIOUS WAS SET TO SHOW THIS IF IT WAS NOT BEING DISPLAYED, THEN RETURN NULL (BECAUSE IT'S ALREADY SHOWING)
													if (
														index > 0 &&
														itemsWithNoGroup[index - 1].showNextIfHidden &&
														itemsWithNoGroup[index - 1].displayOptions &&
														doNotDisplayFields(itemsWithNoGroup[index - 1])
													) {
														return null
													}

													//RETURN NORMAL
													return (
														<div
															key={'editItem-' + item.key}
															style={{
																width: item.halfWidth
																	? 'calc(50% - 15px)'
																	: null,
															}}>
															<EditSideBarItem
																item={item}
																handleChange={handleChange}
																testError={testError}
																errorMessage={
																	testError
																		? state.errorMngt[findError].message
																		: null
																}
																currentUserId={currentUser.username}
																isCurrentUserEditor={isCurrentUserEditor}
																projectData={projectData}
																entireContent={state.content}
															/>
														</div>
													)
											  })}
									</div>

									{/* MAP GROUP */}
									{state.content &&
										group &&
										group.map((group) => {
											//////////////////////////////////
											/// GET ITEMS RELATED TO GROUP ///
											//////////////////////////////////

											//GET FILTERED ITEM WITH DISPLAY OPTION SINFORMATION
											let itemFiltered = state.content.filter((item) => {
												let isShow = true
												if (item.displayOptions)
													isShow = !doNotDisplayFields(item)
												return item.groupId === group.id && isShow === true
											})

											// RETURN NULL IF EMPTY
											if (itemFiltered.length === 0) return null

											//COUNT
											let numberOfElement = itemFiltered.length
											let numberOfElementNotEmpty = itemFiltered.filter(
												(item) =>
													item.value &&
													item.value !== '' &&
													item.value !== '<p><br></p>'
											).length
											let mandatoryItems = itemFiltered.filter(
												(item) => item.mandatory === true
											)
											let numMandatoryTotal = mandatoryItems.length
											let numMandatoryFilled = mandatoryItems.filter((item) => {
												// HAS ERROR
												const hasError =
													state.errorMngt.findIndex(
														(error) => error.key === item.key
													) > -1
												if (hasError) return true
												return item.value !== null && item.value !== ''
											}).length

											/////////////////////////////
											/// COUNT NUMBER OF ISSUE ///
											/////////////////////////////

											let numberOfIssue = 0
											if (state.errorMngt.length > 0) {
												//INIT
												let itemKey = [
													...new Set(itemFiltered.map((item) => item.key)),
												]
												let errorKey = [
													...new Set(state.errorMngt.map((item) => item.key)),
												]

												//COUNT
												for (let i = 0; i < errorKey.length; i++) {
													if (itemKey.indexOf(errorKey[i]) > -1) {
														numberOfIssue += 1
													}
												}
											}

											////////////////////
											/// RENDER GROUP ///
											////////////////////

											return (
												<EditSideBarGroup
													key={'editGroup-' + group.id}
													group={group}
													displayedGroupId={state.displayedGroupId}
													numberOfElementNotEmpty={numberOfElementNotEmpty}
													numberOfElement={numberOfElement}
													numberOfIssue={numberOfIssue}
													numMandatoryTotal={numMandatoryTotal}
													numMandatoryFilled={numMandatoryFilled}
													noCountTag={group.noCountTag ? true : false}
													triedToSave={state.triedToSave}>
													{/* MAP ATTRIBUT */}
													{itemFiltered.map((item, index) => {
														//TEST IF ITEM IN ERROR
														let testError = false
														let findError = FUNCT_FIND_INDEX(
															state.errorMngt,
															'key',
															item.key
														)
														if (findError > -1) {
															testError = true
														}

														//TWO IN A ROW
														if (item.rowWithNext) {
															return (
																<div
																	key={'editItem-' + item.key}
																	className='flexStartStart'>
																	{/* CURRENT ITEM */}
																	{!item.displayOptions ||
																	(item.displayOptions &&
																		!doNotDisplayFields(item)) ? (
																		<EditSideBarItem
																			key={'editItem-' + item.key}
																			item={item}
																			handleChange={handleChange}
																			testError={testError}
																			currentUserId={currentUser.username}
																			isCurrentUserEditor={isCurrentUserEditor}
																			errorMessage={
																				testError
																					? state.errorMngt[findError].message
																					: null
																			}
																			lastItem={numberOfElement === index + 1}
																			projectData={projectData}
																			entireContent={state.content}
																		/>
																	) : null}

																	{/* NEXT ONE */}
																	{itemFiltered[index + 1] &&
																	(!itemFiltered[index + 1].displayOptions ||
																		(itemFiltered[index + 1].displayOptions &&
																			!doNotDisplayFields(
																				itemFiltered[index + 1]
																			))) ? (
																		<React.Fragment>
																			{/* SPACE BETWEEN */}
																			<div
																				style={{
																					minWidth: '30px',
																					maxWidth: '30px',
																				}}></div>

																			{/* NEXT ITEM */}
																			<EditSideBarItem
																				key={
																					'editItem-' +
																					itemFiltered[index + 1].key
																				}
																				currentUserId={currentUser.username}
																				isCurrentUserEditor={
																					isCurrentUserEditor
																				}
																				item={itemFiltered[index + 1]}
																				handleChange={handleChange}
																				testError={testError}
																				errorMessage={
																					testError
																						? state.errorMngt[findError].message
																						: null
																				}
																				lastItem={numberOfElement === index + 2}
																				projectData={projectData}
																				entireContent={state.content}
																			/>
																		</React.Fragment>
																	) : null}
																</div>
															)
														}

														//TEST DISPLAY OPTION
														if (item.displayOptions && doNotDisplayFields(item))
															return null

														// IF IS DATE AND HAS customMinDate PROPERTY SET THE MIN DATE.
														if (
															(item.type === 'date' ||
																item.type === 'datetime-local') &&
															item.customMinDate
														) {
															item.minDate = getMinDate(item)
															item.maxDate = getMaxDate(item)
														}

														// IF ITEM HAS CUSTOM DISABLED
														if (item.customDisabled) {
															// GET DISABLED
															item.disabled = checkCustomDisable(item)

															// FOR THE CASE OF PERIODICITY
															if (
																item.key === 'periodicity' &&
																item.disabled === true
															) {
																item.value = {
																	weekGap:
																		item.value?.weekGap ??
																		GOV_REVIEW_DEFAULT_PERIODICITY.weekGap,
																	weekDays: getWeekDaysObj(),
																}
															}
														}

														//NOT DISPLAY IF ALREADY DISPLAY IN A ROW
														if (
															index > 1 &&
															itemFiltered[index - 1].rowWithNext
														)
															return null

														//RENDER NORMAL
														return (
															<div
																key={'editItem-' + item.key}
																style={{
																	width: item.halfWidth
																		? 'calc(50% - 15px)'
																		: null,
																}}>
																<EditSideBarItem
																	item={item}
																	handleChange={handleChange}
																	testError={testError}
																	currentUserId={currentUser.username}
																	isCurrentUserEditor={isCurrentUserEditor}
																	errorMessage={
																		testError
																			? state.errorMngt[findError].message
																			: null
																	}
																	lastItem={numberOfElement === index + 1}
																	projectData={projectData}
																	entireContent={state.content}
																/>
															</div>
														)
													})}
												</EditSideBarGroup>
											)
										})}
								</div>
							)}
						</div>
					</React.Fragment>
				) : null}
			</div>

			{/* MESSAGE IF CLICK ON BACKGROUND */}
			{state.confirmType > 0 ? (
				<EditSideBarConfirm
					confirmY={state.confirmY}
					confirmX={state.confirmX}
					confirmType={state.confirmType}
					canSave={canSave}
					handleSave={handleSave}
					resetEditSideBar={resetEditSideBar}
				/>
			) : null}

			{/* BACKGROUND */}
			{display ? (
				<div
					className={'offcanvas-backdrop fade ' + (display ? 'show' : '')}
					onClick={
						state.updatedFields.length > 0
							? (e) => handleConfirm(e, false)
							: resetEditSideBar
					}></div>
			) : null}

			{/* SAVE ERROR MODAL */}
			{state.showSaveErrModal ? (
				<EditSideBarSaveErrorModal
					handleShowHideSaveModal={handleShowHideSaveModal}
				/>
			) : null}
		</React.Fragment>,
		document.body
	)
})
