
//LIBRARIES
import React, {useSpring, animated, easings, config} from 'react-spring'


export const HomeAnimationsFadeIn = ({ children, delay, from, isVisible }) => {
  let value
  from === "left" ? value = -20 : value = 20
  const [styles, api] = useSpring(() => ({
    to: {
      opacity: 1,
      x: 0,
    },
    from: {
      opacity: 0,
      x: value,
    },
    delay,
    config: {
      duration: 500,
      easing: easings.easeInOutCubic,
      clamp: true,
      ...config.gentle,
    },
  }));  

  api.start({
    opacity: isVisible ? 1 : 0,
    x: isVisible ? 0 : value
  })


  return (
          <animated.div className="flexColCenterCenter" style={styles}>
            {children}
          </animated.div>
        )
}
