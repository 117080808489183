// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';


//THE FILE TEXT
const markdown = `
# Context

When we are managing portfolio, we have lots of data dispatched through
many different applications (scheduling, cost, risk & opportunities,
...). It is hard to have a clear and synthetic view of the status of
every projects.

We spend a lot of time to consolidate every data doing some
presentations, registering it, loading the decisions in other tools,
etc.

Telescope is here to help you in this task!

***

# What is Telescope?

Telescope is an application helping you to easily manage your portfolios
of projects. Have an up-to-date overview of your projects helping you to
make better decisions.  

You can choose to link your existing data from external applications or
manage it directly in Telescope. Then, group your projects into
portfolio or program at your convenience and share it to your
colleagues.

The control view will provide an overview of every project domain at a
glance helping you to take better and faster decisions.

***

# How does it work?

# Board

## Concept

Thanks to the board, you have an overview of your coming activities and
you can browse through all your portfolio, organizations and projects.

## Focus for the day

Retrieve on this part:
-   Your last activities on Telescope: list of all items (actions,
    reviews, portfolios, projects or organizations) that you have
    previously opened.
-   Actions of which you are responsible. Open directly the action card
    in the control page by clicking on the item.
-   Next reviews you have created or where you are invited. You can
    directly open the review mode (minutes) by clicking on it.
## Browsing

Find here all portfolios, organizations and projects to which you have
access. Select one item and manage all projects into the controls page.

***

# Controls

## Concept

In the 'controls' page, find the major status of the projects selected
in the board (projects, organization, portfolio). All project management
key indicators are displayed in one page in order to directly address
the major issues.

The control page is organized as a matrix with, in lines, the project
list and all the branches of their WBS defined in Core, and, in columns,
the project domains that you want to follow.

***

## WBS branches

The WBS is directly coming from Core. You can't edit it in Telescope,
you need do this action in Core.

All the branches of your project are displayed in Telescope on the left
side. All parent level consolidates all items from the branches below.

If you open an organization or a portfolio from the board view, the
first line consolidates all items from projects and branches below in
display mode only, you can't create or edit any items at this level.

Then, you can easily navigate and drill down through the WBS of your
projects:

-   Open a branch thanks to the arrow keeping an eye on the other lines
-   Jump into the branch by clicking on the title to only have a look on
    levels below

***

## Project domains

In columns, you find all strategic project domains that you can follow.

2 domains are not displayed in dedicated columns but accessible next to
the title of the branch:

-   Project charter: All users have a quick access to the project
    charter. The indicators are only available at project level and
    according to your rights, you can edit and see the classic elements
    of the project charter.
-   Shortcuts: retrieve all external shortcuts from Clayverest like
    operational dashboards, planification tools, or websites.

Then, the other domains are split into dedicated columns:

-   Governance: the governance column compiles the scope changes and the
    reviews of your project in one place. The scope changes can be
    raised by any users and the decisions are stored to easily retrieve
    it. The agenda allows you to follow all project meetings with their
    minutes.
-   Actions: follow all the actions of your project and work packages
    with alerts on late items.
-   Risk & Opportunities: the two linked activities are split in two
    separated columns but contains the features. All risks and
    opportunities are listed with their action plan, and the actions are
    also displayed in the action columns.
-   Schedule: follow all project tasks and milestones split in the WBS
    branches.

***

## Indicators

Manage all domain thanks to indicators, divided in two main parts:

-   Pill KPI: global figures of the domain, displayed by default. You
    can see the global health of your project briefly thanks to the
    alert displayed in red if items are late.
-   Big component: if you want to see detailed indicators, click on the
    pill KPI to open the big component with a visual chart and a table
    listing all the items. In the big component, you can also edit the
    items: create new one or edit, delete, duplicate and move existing
    ones (see all the features in chapter 2).

`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;