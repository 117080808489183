import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Auth } from "aws-amplify";
import QRCode from "qrcode.react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { 
  CvtButton, 
  CvtSpinnerGrow, 
  CvtInput, 
  CvtCheckbox,
  CvtReminder 
} from "@mi-gso/cvt";

//CORE
import { authCleanUserDataCookie } from "../00-Functions/AuthSignOut";
import { envVarManagement } from "../../../../envVarManagement";

//COMPONENT DISPLAY USER
export function HeaderUserInfosMfa({
  isEditMode,
  handleEdit,
  onChangeMfa,
  user,
}) {
  /////////////
  /// STATE ///
  /////////////

  //STATE
  const [state, setState] = useState({
    preferredMFA: "",
    phoneNumber: "",
    phoneNumberValid: false,
    qrCodeLoading: false,
    qrCode: "",
    error: false,
    loader: false,
    codeSent: false,
    codeLoader: false,
    code: "",
    updateMfa: false,
    email: "",
    username: "",
  });

  //INPUT CHANGE
  const onChange = useCallback((e) => {
    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  //PHONE NUMBER CHANGE ------------------------------------------------------------------
  const onChangePhoneNumber = useCallback((e) => {
    setState((prevState) => {
      return {
        ...prevState,
        phoneNumber: e,
        phoneNumberValid: e && isValidPhoneNumber(e) ? true : false,
      };
    });
  }, []);

  //EFFECT
  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        preferredMFA:
          user.preferredMFA === "NOMFA"
            ? ""
            : user.preferredMFA === "SMS_MFA"
            ? "SMS"
            : "TOTP",
      };
    });
  }, [user.preferredMFA]);

  ///////////
  /// MFA ///
  ///////////

  //GENERATE QR CODE
  const onUpdateTotpSetUp = useCallback(async () => {
    //SET MFA
    setState((prevState) => {
      return {
        ...prevState,
        qrCodeLoading: true,
        updateMfa: true,
        preferredMFA: "TOTP",
      };
    });

    //GET UPDATED USER
    await Auth.currentAuthenticatedUser({ bypassCache: true }).then(
      async (updatedUser) => {
        //GET QRCODE
        await Auth.setupTOTP(updatedUser).then((code) => {
          //GET THE QR CODE
          setState((prevState) => {
            return {
              ...prevState,
              qrCodeLoading: false,
              qrCode: code,
              email: updatedUser.attributes.email,
              username: updatedUser.username,
              updateMfa: true,
            };
          });
        });

        //DELETE USERDATA COOKIE
        authCleanUserDataCookie();
      }
    );
  }, []);

  //CHANGE PREFERRED MFA METHOD
  const onChangePreferredMFA = useCallback(
    async (e, value) => {
      //IF TOTP GENERATE CODE
      if (value === "TOTP" && state.preferredMFA !== "TOTP") {
        //DO NOT GENERATE QR IF IT'S ALREADY THE CASE
        if (user.preferredMFA === "SOFTWARE_TOKEN_MFA" || state.qrCode !== "") {
          setState((prevState) => {
            return {
              ...prevState,
              preferredMFA: "TOTP",
              updateMfa: false,
            };
          });
        } else {
          //GENERATE QR CODE
          onUpdateTotpSetUp();
        }
      }

      //IF TOTP GENERATE CODE
      if (value === "SMS" && state.preferredMFA !== "SMS") {
        setState((prevState) => {
          return {
            ...prevState,
            preferredMFA: "SMS",
            updateMfa: false,
          };
        });
      }

      //IF TOTP GENERATE CODE
      if (
        value === "NOMFA" &&
        state.preferredMFA !== "" &&
        state.preferredMFA !== "NOMFA"
      ) {
        //SET MFA
        setState((prevState) => {
          return {
            ...prevState,
            preferredMFA: "",
          };
        });
      }
    },
    [onUpdateTotpSetUp, state, user.preferredMFA]
  );

  //////////////
  /// SUBMIT ///
  //////////////

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      //LAUCNH LOADER
      setState((prevState) => {
        return {
          ...prevState,
          loader: true,
        };
      });

      //VERIFY TOKEN IF TOTP & ACTIVATE PREFERRED MFA INSIDE AUTH
      if (state.preferredMFA === "TOTP") {
        //GET UPDATED USER
        await Auth.currentAuthenticatedUser({ bypassCache: true }).then(
          async (updatedUser) => {
            //TEST CONNECTION WITH USERNAME AND PASSWORD
            await Auth.verifyTotpToken(updatedUser, state.code.trim())
              .then(() => {
                //SET TOTP AS PREFERED MFA METHOD
                Auth.setPreferredMFA(updatedUser, "TOTP");

                //RESET VIEW AND USER
                onChangeMfa();

                //LAUCNH LOADER
                setState((prevState) => {
                  return {
                    ...prevState,
                    preferredMFA: "TOTP",
                    qrCodeLoading: false,
                    qrCode: "",
                    error: false,
                    loader: false,
                    code: "",
                    updateMfa: false,
                    email: "",
                    username: "",
                  };
                });

                //ERROR MANAGEMENT
              })
              .catch((err) => {
                //LAUCNH LOADER
                setState((prevState) => {
                  return {
                    ...prevState,
                    error: err.message,
                    loader: false,
                  };
                });
              });

            //DELETE USERDATA COOKIE
            authCleanUserDataCookie();
          }
        );

        //VERIFY PHONE NUMBER AND UPDATE PREFERRED MFA
      } else if (state.preferredMFA === "SMS") {
        //GET UPDATED USER
        await Auth.currentAuthenticatedUser({ bypassCache: true }).then(
          async (updatedUser) => {
            //TEST CONNECTION WITH USERNAME AND PASSWORD
            await Auth.verifyCurrentUserAttributeSubmit(
              "phone_number",
              state.code.trim()
            )
              .then(() => {
                //SET TOTP AS PREFERED MFA METHOD
                Auth.setPreferredMFA(updatedUser, "SMS");

                //RESET VIEW AND USER
                onChangeMfa();

                //LAUCNH LOADER
                setState((prevState) => {
                  return {
                    ...prevState,
                    preferredMFA: "SMS",
                    phoneNumber: "",
                    phoneNumberValid: false,
                    error: false,
                    loader: false,
                    codeSent: false,
                    codeLoader: false,
                    code: "",
                    updateMfa: false,
                    email: "",
                    username: "",
                  };
                });

                //CATCH ERROR
              })
              .catch((err) => {
                //ERROR
                setState((prevState) => {
                  return {
                    ...prevState,
                    error: err.message,
                    loader: false,
                  };
                });
              });

            //DELETE USERDATA COOKIE
            authCleanUserDataCookie();
          }
        );

        // NO MFA
      } else {
        //GET UPDATED USER
        await Auth.currentAuthenticatedUser({ bypassCache: true }).then(
          async (updatedUser) => {
            //SET TOTP AS PREFERED MFA METHOD
            await Auth.setPreferredMFA(updatedUser, "NOMFA")
              .then(() => {
                //RESET VIEW AND USER
                onChangeMfa();

                //LAUCNH LOADER
                setState((prevState) => {
                  return {
                    ...prevState,
                    preferredMFA: "",
                    phoneNumber: "",
                    phoneNumberValid: false,
                    error: false,
                    loader: false,
                    codeSent: false,
                    codeLoader: false,
                    code: "",
                    updateMfa: false,
                    email: "",
                    username: "",
                  };
                });

                //CATCH ERROR
              })
              .catch((err) => {
                //ERROR
                setState((prevState) => {
                  return {
                    ...prevState,
                    error: err.message,
                    loader: false,
                  };
                });
              });

            //DELETE USERDATA COOKIE
            authCleanUserDataCookie();
          }
        );
      }
    },
    [onChangeMfa, state]
  );

  ///////////
  /// SMS ///
  ///////////

  //LAUNCH SENDING CODE TO VERIFY ATTRIBUT EMAIL AND PHONE
  const handleSendCodeForPhoneVerification = useCallback(
    async (e) => {
      e.preventDefault();

      //TEST IF TH EPHON ENUMBER IS VALID
      if (!state.phoneNumberValid) {
        //RESET TO SIGN IN
        setState((prevState) => {
          return {
            ...prevState,
            error: "Phone Number not valid!",
          };
        });

        //OK
      } else {
        //RESET TO SIGN IN
        setState((prevState) => {
          return {
            ...prevState,
            codeLoader: true,
            error: false,
          };
        });

        //SEND CODE
        await Auth.currentAuthenticatedUser({ bypassCache: true }).then(
          async (updatedUser) => {
            //UPDATE
            await Auth.updateUserAttributes(updatedUser, {
              phone_number: state.phoneNumber,
            }).then(() => {
              //SEND CODE
              Auth.verifyCurrentUserAttribute("phone_number")
                .then(() => {
                  //RESET LOADER
                  setState((prevState) => {
                    return {
                      ...prevState,
                      codeLoader: false,
                      codeSent: true,
                    };
                  });

                  //GET ERROR
                })
                .catch((error) => {
                  //RESET LOADER
                  setState((prevState) => {
                    return {
                      ...prevState,
                      codeLoader: false,
                      codeSent: false,
                      error: error.message,
                    };
                  });
                });
            });

            //DEL USER DATA COOKIE
            authCleanUserDataCookie();
          }
        );
      }
    },
    [state]
  );

  //BACK TO UPDATE PHONE NUMBER
  const handleBackToUpdatePhoneNumber = useCallback((e) => {
    e.preventDefault();

    //RESET TO SIGN IN
    setState((prevState) => {
      return {
        ...prevState,
        codeLoader: false,
        codeSent: false,
        updateMfa: true,
      };
    });
  }, []);

  ////////////////////////
  /// NEEDED TREATMENT ///
  ////////////////////////

  //ENVIRONEMENT
  const env = useMemo(() => envVarManagement("env"), []);
  const envMfaMandatory = useMemo(
    () => envVarManagement("envMfaMandatory"),
    []
  );

  //CREATE QRCODE
  const qrCode = useMemo(() => {
    let code = "";
    if (state.qrCode && state.qrCode !== "") {
      let issuer = "Clayverest-" + env + " (" + state.email + ")";
      code =
        "otpauth://totp/AWSCognito:" +
        state.username +
        "?secret=" +
        state.qrCode +
        "&issuer=" +
        issuer;
    }
    return code;
  }, [env, state.email, state.qrCode, state.username]);

  //COMPONENT RENDER
  return (
    <React.Fragment>
      {isEditMode ? (
        <div
          className="flexColStartStart"
          style={{
            width: "calc(100% - 40px)",
            margin: "10px 20px",
            border: "1px solid var(--border-color-gray-0-5)",
            borderRadius: "8px",
          }}
        >
          {/* TITLE */}
          <div
            style={{
              padding: "10px 20px",
              width: "100%",
              backgroundColor: "var(--border-color-gray-00-5)",
              borderBottom: "1px solid var(--border-color-gray-0)",
              fontWeight: 500,
            }}
          >
            Change MFA Method
          </div>

          {/* MESSAGE ERREUR */}
          {state.error ? (
            <div
              className="structureBlockInfo flexBetweenCenter alertContentDanger alertContent"
              style={{
                boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.2)",
                margin: "20px 20px 0px 20px",
                width: "calc(100% - 40px)",
              }}
            >
              <CvtReminder 
                icon="info" 
                message={state.error}
              />
            </div>
          ) : null}

          {/* NO MFA */}
          {envMfaMandatory ? null : (
            <CvtCheckbox
              title="NO MFA"
              value={state.preferredMFA === "" ? true : false}
              name="preferredMFA"
              width="100%"
              padding="15px 20px 10px 20px"
              handleFunction={onChangePreferredMFA}
              handleFunctionOption="NOMFA"
            />
          )}

          {/* SMS */}
          <CvtCheckbox
            title="SMS"
            value={state.preferredMFA === "SMS" ? true : false}
            name="preferredMFA"
            width="100%"
            padding="10px 20px 10px 20px"
            handleFunction={onChangePreferredMFA}
            handleFunctionOption="SMS"
          />

          {/* CURRENT METHOD */}
          {state.preferredMFA === "SMS" &&
          user.preferredMFA === "SMS_MFA" &&
          !state.updateMfa ? (
            <div
              style={{
                padding: "0px 20px 20px 20px",
              }}
            >
              <span style={{ fontWeight: 500 }}>
                SMS is your current method
              </span>
              <div
                style={{ fontWeight: 500, color: "var(--color-migso-green)" }}
              >
                {formatPhoneNumberIntl(user.phone_number)}
              </div>
              <div
                className="flexStartCenter"
                style={{
                  marginTop: "4px",
                }}
              >
                Update your phone number ?
                <CvtButton
                  color="green"
                  size="small"
                  margin="0px 0px 0px 10px"
                  txt="Yes"
                  handleSubmit={handleBackToUpdatePhoneNumber}
                />
              </div>
            </div>
          ) : state.preferredMFA === "SMS" ? (
            <React.Fragment>
              {!state.codeSent ? (
                <React.Fragment>
                  <div
                    style={{
                      fontWeight: 500,
                      margin: "5px 20px",
                    }}
                  >
                    Enter a valide phone number
                  </div>

                  {/* PHONE AND BUTTON */}
                  <div className="flexStartCenter">
                    {/* PHONE NUMBER INPUT */}
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={state.phoneNumber}
                      onChange={onChangePhoneNumber}
                    />

                    {/* SEND CODE */}
                    <CvtButton
                      color="green"
                      margin="0px 0px 20px 0px"
                      txt="Send the code"
                      loader={state.codeLoader}
                      handleSubmit={handleSendCodeForPhoneVerification}
                      handleSubmitOptions="phone_number"
                      disabled={!state.phoneNumber || state.phoneNumber === ""}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* CODE */}
                  <CvtInput
                    legend="SMS Code"
                    placeholder={"Enter code sent to " + state.phoneNumber}
                    inputBlockCss="modalOptionSubBlock"
                    inputBlockPadding="0px 20px 5px 20px"
                    inputCss="form-control appAuthenticatorInput"
                    name="code"
                    value={state.code}
                    onChange={onChange}
                    autoComplete="off"
                  />

                  {/* RESEND CODE */}
                  <div className="appAuthenticatorQuestion">
                    <a
                      href="/#"
                      onClick={handleBackToUpdatePhoneNumber}
                      style={{ marginLeft: "5px" }}
                    >
                      Change Phone Number
                    </a>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : null}

          {/* TOTP */}
          <CvtCheckbox
            title="From Authenticator App"
            value={state.preferredMFA === "TOTP" ? true : false}
            name="preferredMFA"
            width="100%"
            padding="10px 20px"
            handleFunction={onChangePreferredMFA}
            handleFunctionOption="TOTP"
          />

          {/* CURRENT METHOD */}
          {state.preferredMFA === "TOTP" &&
          user.preferredMFA === "SOFTWARE_TOKEN_MFA" &&
          !state.updateMfa ? (
            <div
              style={{
                padding: "0px 20px 20px 20px",
              }}
            >
              <span style={{ fontWeight: 500 }}>
                Time Based One Time Password is your current method
              </span>
              <div
                className="flexStartCenter"
                style={{
                  marginTop: "4px",
                }}
              >
                Create a new set-up ?
                <CvtButton
                  color="green"
                  size="small"
                  margin="0px 0px 0px 10px"
                  txt="Yes"
                  handleSubmit={onUpdateTotpSetUp}
                />
              </div>
            </div>
          ) : state.preferredMFA === "TOTP" ? (
            state.qrCodeLoading ? (
              <div
                className="flexStartCenter"
                style={{
                  fontWeight: 500,
                  padding: "10px 20px 20px 20px",
                }}
              >
                 <CvtSpinnerGrow
                      marginRight="10px"
                      text="Qr Code Generation..."
                  />
              </div>
            ) : (
              <div style={{ padding: "0px 20px 20px 20px" }}>
                {/* TITLE */}
                <div
                  style={{
                    fontWeight: 500,
                    margin: "5px 0px",
                  }}
                >
                  Here your generated Qr Code
                </div>

                {/* QR CODE */}
                <QRCode value={qrCode} />

                {/* CODE */}
                <CvtInput
                  legend="Code from your authenticator app"
                  placeholder="Enter TOTP Code  "
                  inputBlockCss="modalOptionSubBlock"
                  inputBlockPadding="5px 0px "
                  inputCss="form-control appAuthenticatorInput"
                  name="code"
                  value={state.code}
                  onChange={onChange}
                  autoComplete="off"
                />
              </div>
            )
          ) : null}

          {/* FOOTER */}
          <div
            className="flexEndCenter"
            style={{
              padding: "10px 20px",
              width: "100%",
              backgroundColor: "var(--border-color-gray-00-5)",
              borderBottom: "1px solid var(--border-color-gray-0)",
              fontWeight: 500,
            }}
          >
            {/* CANCEL */}
            <CvtButton
              color="gray"
              outline
              customHover="red"
              margin="0px 20px 0px 0px"
              txt="Cancel"
              handleSubmit={handleEdit}
              handleSubmitOptions=""
            />

            {/* VALIDATE */}
            <CvtButton
              color="green"
              txt="Update"
              handleSubmit={onSubmit}
              loader={state.loader}
              disabled={
                state.code === "" &&
                (state.preferredMFA === "TOTP" || state.preferredMFA === "SMS")
              }
            />
          </div>
        </div>
      ) : (
        <div
          className="flexBetweenCenter"
          style={{
            padding: "5px 20px 5px 20px",
            width: "100%",
          }}
        >
          <div className="flexStartCenter">
            MFA Method
            <span
              style={{
                fontWeight: 500,
                margin: "0px 10px",
              }}
            >
              {user.preferredMFA === "NOMFA"
                ? "NO MFA"
                : user.preferredMFA === "SMS_MFA"
                ? "SMS"
                : "TOTP"}
            </span>
          </div>

          {/* EDIT */}
          <CvtButton
            color="gray"
            outline
            customHover="blue"
            icon="edit"
            handleSubmit={handleEdit}
            handleSubmitOptions="mfa"
          />
        </div>
      )}
    </React.Fragment>
  );
}
