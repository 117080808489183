import React from "react";
import { HomeAnimationsFadeIn } from "./HomeAnimations";
import { HomeQualitiesTable, HomeStepPicture } from "./HomeMiniComponents";


export default function HomeQualities({
    isVisible, //From the parent
    pageNumber,
    picture,
    altPicture,
    title,
    topics,
    color,
    icon,
    iconClass,
}) {
    //////////////
	/// RETURN ///
	//////////////
    return  (
        <React.Fragment>
            <HomeAnimationsFadeIn
                isVisible={isVisible}
                from={pageNumber % 2 === 0 ? "right" : "left"}
                delay={750}
            >
                <HomeStepPicture
                    stepPicture={picture}
                    altPicture={altPicture}
                    style={{
                        width: "50%",
                        maxHeight: "900px",
                    }}
                />
            </HomeAnimationsFadeIn>
            <HomeAnimationsFadeIn
                isVisible={isVisible}
                from={pageNumber % 2 === 0 ? "left" : "right"}
                delay={750}
            >
                <HomeQualitiesTable
                    title={title}
                    topics={topics}
                    color={color}
                    icon={icon}
                    iconClass={iconClass}
                />
            </HomeAnimationsFadeIn>
        </React.Fragment>
    )
}