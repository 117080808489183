/////////////////////////////////////
/// ACTION TABLE LEGEND COMPONENT ///
/////////////////////////////////////

import { CvtButton } from "@mi-gso/cvt";
import { ACTION_STATUS_KEY } from "../../02-Portfolio/01-Action/00-Helpers/ActionsConstants";

export default function BoardTopActionsFilters({
    legendAndCounter,
    statusFilters,
    handleStatusTagClick,
    onStatusReset,
}) {
    //////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    return (
        <div className="flexStartCenter" style={{ marginLeft: "5px" }}>
            {/* MAP STATUS */}
            {legendAndCounter.map((item) => {
                //IF SELECTED
                let isSelected = statusFilters.length > 0 && statusFilters?.includes(item.id);

                //TOT WITH SELECTION

                //RENDER FOR TOTAL
                if (item.id !== "total") {
                    return (
                        <div
                            key={"actionTableLegend-" + item.id}
                            className={
                                "actionsTableLegendTag flexStartCenter " +
                                (isSelected ? "actionsTableLegendTagSelected" : "")
                            }
                            onClick={() => handleStatusTagClick(item.id)}
                            style={{
                                background: item.backgroundColor,
                                opacity:
                                    (!isSelected && item.id === ACTION_STATUS_KEY.done) ||
                                    (statusFilters.length > 0 && !isSelected)
                                        ? "0.5"
                                        : null,
                            }}
                        >
                            {/* NAME */}
                            <span style={{ color: item.color }}>{item.name}</span>
                        </div>
                    );
                }

                //IF TOTAL RETURN NULL
                return null;
            })}

            <CvtButton
                color="gray"
                shape="noShape"
                icon="filter_alt_off"
                iconColor={"var(--color-text-4)"}
                handleSubmit={onStatusReset}
                margin="0px 5px 0px 5px"
                disabled={statusFilters.length === 0}
            />
        </div>
    );
}
