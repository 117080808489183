// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { picTELBrowsing1, picTELBrowsing2, picTELBrowsing3, picTELBrowsing4, picTELBrowsing5 } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `

# Presentation

This part allows you to navigate inside your portfolio, organization and
projects. Open an item from here to jump in the control page and manage
your projects.

You have access to all portfolios that you have created or shared to
you.

Browse easily through your portfolio, project and organization:
          
![picTELBrowsing1](${picTELBrowsing1} "" )


>**List of portfolios**    
>**1**.  Switch the view between portfolio list and organization and projects    
**2**.  Button to create a new portfolio    
**3**.  Portfolio card              
**4**.  Button to display the closed portfolio 
>
>

>**Portfolio card**    
**5**.  Title of the portfolio    
**6**.  Short description of the portfolio    
**7**.  Number of projects inside the portfolio    
**8**.  Number of users the portfolio is shared to    
**9**.  Edit your portfolio        
**10**. Portfolio certified   
>         

>**Project list**        
**11**. Organization name        
**12**. Organization Status        
**13**. Number of projects inside the organization         
**14**. Organization Mega Users            
**15**. Projects  

***

# Create a new portfolio

## Description

You can create your project portfolio in order to open all projects you
want in the control page and share it to your colleagues.

Every user can create a portfolio.

## Process

Click on the card 'Create portfolio' > Fill the fields (red star means
that is a mandatory) and select the projects you want:

![picTELBrowsing2](${picTELBrowsing2} "" )       
> **1**.  Name: name of your portfolio       
**2**.  Picture URL: you can add a picture to illustrate your portfolio. Add
    in this field a url link xof your picture       
**3**.  Short description: a short description of your portfolio will be
    displayed on the portfolio card (visible in the browsing).       
**4**.  Description: a complete description of your portfolio and its
    objectives       
**5**.  Status of your portfolio (by default, it is open)       
**6**.  Official: if your portfolio is an official one, active the toggle.
    The Portfolio card will be certified.       
**7**.  Shared with: click on the edit button to share your portfolio to
    your colleague or see the person who have access to this portfolio.      
a.  To add new users: click on "Add more users" > select the users
        > click on "Add Selected Users" > Close the window to come
        back on portfolio edition > Save your modification in the
        portfolio card            
b.  To delete users: select the users > click on "Remove users" >
        Close the window > Save your modification in the portfolio card           
        **WARNING**: if a red triangle appears: the user doesn't have access to one
    or several projects selected. The user will only see the projects
    where he has rights.       
> **8**.  Select the projects: Drag and drop the project you want. You can
    also pick an entire organization: if a new project is created, it
    will be automatically embedded in the portfolio.       
**9**.  Save your modifications

Your portfolio is edited and appears in the list, ready to be used.

***

#  Edit an existing portfolio

## Description

According to your rights, you can edit and update an existing portfolio.

## Process

In the browsing box, you can edit all portfolio with the pencil button.
Click on it > Do your modifications  > Save.

![picTELBrowsing3](${picTELBrowsing3} "" )

***

# Delete a portfolio

## Description

According to your rights, you can delete a portfolio.

## Process

Edit the portfolio you want to delete  > Click on
Delete button > Enter the confirmation word requested in bold >
Delete.

![picTELBrowsing4](${picTELBrowsing4} "" )

***

# Open a project or an organization

## Description

You can also open a simple project or an entire organization. Opening an
organization has the same behavior as portfolio, but containing all
projects of this organization automatically (only projects of which you
have access will be opened).

## Process

To open a project or an organization in the control page:

![picTELBrowsing5](${picTELBrowsing5} "" )

> **1**.  In Browsing box, click on "See Portfolios". It is opening the entire
    list of projects you have access, split by organization.       
**2**.  Click on the Project or the Organization you want to open
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;