import React from 'react'

// FILTER GROUP TITLE
export default function FiltersGroupTitle({
    wbsDomain,
    display,
    filtersCount,
    handleFilterReset
}) {
    return (
        <button 
            id={wbsDomain.id}
            className={"accordion-button rightSideBarGroupButton " + (display > 0 ? "" : "collapsed")} 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target={"#flushGroup-" + wbsDomain.id}
        >
            {/* BETWEEN BECAUSE THERE IS AN AFTER IN THE BUTTON  */}
            <div className="flexBetweenCenter" style={{width:"100%"}}>

                {/* LEFT */}
                <div className='flexStartCenter' style={{width:"100%"}}>

                    {/* ICON */}
                    <span className="material-icons rightSideBarGroupButtonIcon">
                        {wbsDomain.icon}
                    </span>

                    {/* NAME */}
                    {wbsDomain.name}
                </div>

                {/* NUMBER OF COMPLETED FIELD */}
                {filtersCount > 0 ? 
                    <div className='filterGroupButtonInfo flexCenterCenter'>
                        {filtersCount}
                        <div 
                            className="material-icons filterReset"
                            onClick={handleFilterReset}
                        >
                            cancel
                        </div>
                    </div>
                :null}
            </div>
        </button>
    )
}
