import { CvtAvatar, CvtSelect } from "@mi-gso/cvt";
import { useMemo } from "react";
import { APP_FORM_STYLE } from "../../../../../00-Core/Constants";
import { FUNCT_FIND_INDEX } from "../../../../../00-Core/Standards";

export default function GovReviewAttendees({
    attendees,
    userList,
    editMode,
    handleAttendeesCheckbox,
    handleUpdateGovReview,
    govItem,
}) {
    // USERS LIST FOR MODAL
    const usersListOptions = useMemo(
        () =>
            userList
                .filter((user) => !attendees.find((attendee) => attendee.id === user.value))
                .map((user) => ({
                    id: user.value,
                    label: user.label,
                })),
        [attendees, userList]
    );

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div className="governanceReviewModeBlock">
            <div className="text_Color2_600_12px">
                Attendees
                {editMode ? (
                    <span className="textItalic text_Color4_500_11px"> (Select present)</span>
                ) : (
                    ""
                )}
            </div>

            {/* ATTENDEES SELECT DROPDOWN */}
            {editMode ? (
                <CvtSelect
                    selectBlockCss="flexColStartStart modalGroup"
                    selectFontSize={APP_FORM_STYLE.fontSize}
                    selectCss="form-select modalInputLight modalSelectLight"
                    selectPadding={APP_FORM_STYLE.selectPadding}
                    selectBlockMargin={APP_FORM_STYLE.blockMargin}
                    selectPlaceholder="Add a new attendee"
                    legendColor={APP_FORM_STYLE.legendColor}
                    legendFontSize={APP_FORM_STYLE.legendFontSize}
                    legendMargin={APP_FORM_STYLE.legendMargin}
                    name="attendees"
                    value=""
                    list={usersListOptions}
                    listValue="id"
                    listName="label"
                    onChange={(e) =>
                        handleUpdateGovReview(
                            e,
                            "attendees",
                            JSON.stringify([{ id: e.target.value, present: false }, ...attendees]),
                            govItem.id
                        )
                    }
                    mandatory={false}
                />
            ) : null}

            {/* USER LIST */}
            <div
                className="flexColStartStart"
                style={{
                    gap: "10px",
                    padding: "10px 0px 10px 10px",
                    maxHeight: "300px",
                    overflow: "auto",
                }}
            >
                {attendees.map((attendee, index) => {
                    let user;
                    let selected = attendee.present ? true : false;

                    // GET USER DATA
                    let findIndex = FUNCT_FIND_INDEX(userList, "value", attendee.id);

                    if (findIndex !== -1) {
                        // USER FOUND
                        user = userList[findIndex];
                    }
                    return (
                        <div className="flexStartCenter" key={index}>
                            {/* CHECKBOX */}

                            <div
                                className={
                                    "actionTableSelectBox " +
                                    (selected ? "actionTableSelectBoxActive" : "")
                                }
                                style={{
                                    margin: "0px 10px 0px 0px",
                                    cursor: !editMode ? "default" : null,
                                }}
                                onClick={(e) => {
                                    if (editMode) handleAttendeesCheckbox(e, user.value);
                                }}
                            ></div>

                            {/* AVATAR */}
                            <CvtAvatar
                                value={user}
                                radius={11}
                                fontSize="10px"
                                margin={"0px 10px 0px 0px"}
                            />

                            {/* USER NAME */}
                            <span
                                className="text_Color2_700_11px"
                                style={{
                                    color: !editMode && !selected ? "var(--color-text-4)" : null,
                                }}
                            >
                                {user.label}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
