import React, {useMemo, useCallback,useState} from 'react';
import TERMS_FILES from './00-ContentFiles/TermsFilesExport'
import {FUNCT_FIND_INDEX} from '../../00-Core/Standards'
import {APP_LANGUAGE} from '../../00-Core/Constants'

//////////////////////
/// GRID COMPONENT ///
////////////////////// 
export default function Terms(){

    //STATE TO MANAGE LANGUAGE
    const [language, setLanguage] = useState("EN")

    //ON CHANGE
    const onChange = useCallback((e)=>{
        e.preventDefault();
        setLanguage(e.target.value)
    },[])

    //HANDLE TOP
    const handleTop = useCallback((e) =>{
        e.preventDefault()

        //SCROLL TOP
        const documentFileBlock = document.getElementById("documentFileBlock");
        documentFileBlock.scrollTop = 0;
    },[])

    ////////////////////////////////////////////////////////////////////////////
    /// GET DOCUMENTATION TREE /////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////

    //GET SELECTED FILE
    const fileData = useMemo(()=> {

        //FIND INDEX
        const indexFile = FUNCT_FIND_INDEX(TERMS_FILES, "id",language)

        //RETURN GOOD ONE
        return TERMS_FILES[indexFile]
    },[language])

    //GET SELECTED FILE
    const FileJsx = useMemo(()=> 
        fileData.file
    ,[fileData.file])

    //RETURN
    return(
        <section 
            id="structureContent" 
            className='flexStartStart'
        >

            {/* DETAILS */}
            <div 
                className="structureBlock "
                id="documentFileBlock"
                style={{
                    width:"100%",
                    marginRight:"20px",
                    marginLeft:"20px",
                }}
            >

                {/* TITLE */}
                <div 
                    className='structureBlockTitle flexBetweenCenter'
                    style={{
                        position: "sticky",
                        top:"0px",
                        borderBottomColor: "var(--border-color-gray-1)",
                        // boxShadow:"0px 1px 4px 0px rgb(0 0 0 / 50%)",
                        borderRadius:"0px"
                    }}
                >

                    {/* ICON TXT */}
                    <div className='flexStartCenter'>
                        <span className="material-icons importSubTitleIcon ">
                        {fileData.icon ? fileData.icon : "list"}
                        </span>
                        {fileData.title}
                    </div>

                    <div className='flexStartCenter'>
                        <div className='flexStartCenter'>

                            {/* LANCGUAGE */}
                            <span 
                                className="material-icons"
                                style={{color:"var(--color-text-4)"}}
                            >
                                language
                            </span>

                            {/* SELECT */}
                            <select 
                                className="form-select" 
                                style={{
                                    fontSize: "14px",
                                    padding: "5px 35px 5px 12px",
                                    margin: "0px 20px 0px 8px"
                                }}
                                name="language" 
                                value={language} 
                                onChange={onChange}
                            >

                                {/* OPTIONS MAP */}
                                {APP_LANGUAGE.map((item, index) =>(
                                        <option 
                                            key={"language-" + item.id} 
                                            value={item.id}
                                        >
                                            {item[language]}
                                        </option>
                                    )
                                )}
                            </select>

                        </div>

                        {/* BACK TO TOP */}
                        <div 
                            className="material-icons structureTopButton" 
                            style={{transform: "rotate(270deg)"}}
                            onClick={handleTop}
                        >
                            double_arrow
                        </div>
                    </div>
                </div>

                {/* FILE */}
                <div className='blockMarkDown'>
                    {FileJsx}
                </div>
            </div>
        </section>
    )
}