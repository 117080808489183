// REACT
import { memo, useCallback, useMemo} from "react";

// MIGSO
import { CvtButton } from "@mi-gso/cvt";

// CONSTANTS
import { RISK_OPP_IMPACT_LIST, RISK_OPP_CARD_IMPACT_MAP, RISK_OPP_CARD_IMPACT_VALUES, RISK_OPP_PROBABILITY_LIST } from "../../../00-Helpers/RiskConstants";

/////////////////////////////////////////////////////////////
/// RISK CARD IMPACT ////////////////////////////////////////
/////////////////////////////////////////////////////////////
export default memo(function RiskCardImpactProba({
  // DATA
  roItem,
  riskCardImpactProbaOpenView,
  canModifyRo,
  // FUNCTIONS
  handleSwitchingView,
  onSaveAddEdit,
}) {

   ////////////////////////////////////////////////////////////
  /// MEMO ///////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  // INIT KEYS
  let ratingKeys = useMemo(() => {
    return {
      cost: riskCardImpactProbaOpenView ? `${riskCardImpactProbaOpenView.value}Cost` : '',
      schedule: riskCardImpactProbaOpenView ? `${riskCardImpactProbaOpenView.value}Schedule` : '',
      scope: riskCardImpactProbaOpenView ? `${riskCardImpactProbaOpenView.value}Scope` : '',
      quality: riskCardImpactProbaOpenView ? `${riskCardImpactProbaOpenView.value}Quality` : '',
    };
  }, [riskCardImpactProbaOpenView]);

  ////////////////////////////////////////////////////////////
  /// FUNCTIONS //////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  // CALCULATE NEW IMPACT AFTER SELECTING ALL 4 COUNT RATINGS
  const calculateImpactProbaIndex = useCallback((keyChanged, newValue) => {
      if(roItem) {
        let highestValue = newValue;


        for(let ratingKey of Object.keys(ratingKeys)) {
          if(ratingKey !== keyChanged && roItem[ratingKeys[ratingKey]] !== null && highestValue < roItem[ratingKeys[ratingKey]]) {
            highestValue = roItem[ratingKeys[ratingKey]];
          }
        }
        return highestValue - 1;
      }

  }, [ratingKeys, roItem]);

 // HANDLE CLICK IN COST/SCHEDULE/SCOPE/QUALITY
  const handleChangeRatings = useCallback((key, value) => {
    if(roItem[ratingKeys[key]] !== value && riskCardImpactProbaOpenView!==null) {
      let mutateObj = {
        id: roItem.id,
        [ratingKeys[key]]: value,
      };


      // UPDATE BOTH IMPACT AND PROBABILITY TO THE HIGHEST INDEX CHOSEN IN THE RATINGS

      let impactProbaIndex = calculateImpactProbaIndex(key, value);

      // CALCULATE NEW IMPACT
      let newImpact = RISK_OPP_IMPACT_LIST[impactProbaIndex].value;

      // UPDATE BOTH IMPACT AND THE KEY SELECTED
      mutateObj[`${riskCardImpactProbaOpenView.value}Impact`] = newImpact;

      // let newProba = RISK_OPP_PROBABILITY_LIST[impactProbaIndex].value;

      // mutateObj[`${riskCardImpactProbaOpenView.value}Proba`] = newProba;


      onSaveAddEdit(null, mutateObj, false);
    }

  }, [calculateImpactProbaIndex, onSaveAddEdit, ratingKeys, riskCardImpactProbaOpenView, roItem]);

  // GIVEN A IMPACT, RETURN TRUE IF IS SELECTED, FALSE IF NOT
  const isImpactSelected = useCallback((impact) => {
    if(roItem && riskCardImpactProbaOpenView!==null ) {
      let impactKey = `${riskCardImpactProbaOpenView.value}Impact`;
      // if(roItem.currentImpact) {
      //   return roItem.currentImpact === impact;
      // }
      // else if(roItem.initialImpact) {
      //   return roItem.initialImpact === impact;
      // }

      if(roItem[impactKey]) {
        return roItem[impactKey] === impact;
      }

    }else {
      return false;
    }
  }, [riskCardImpactProbaOpenView, roItem]);

  // GIVEN A PROBABILITY, RETURN TRUE IF IS SELECTED, FALSE IF NOT
  const isProbaSelected = useCallback((proba) => {
    if(roItem && riskCardImpactProbaOpenView!==null) {

      let probaKey = `${riskCardImpactProbaOpenView.value}Proba`;

      if(roItem[probaKey]) {
        return roItem[probaKey] === proba;
      }

    }
    return false;
  }, [riskCardImpactProbaOpenView, roItem]);

  // HANDLE CLICK PROBA HEADER
  const handleClickProbaHeader = useCallback((probaValue) => {
      let probaKey = `${riskCardImpactProbaOpenView.value}Proba`;

      // IF NOT SAME, THEN UPDATE
      if(roItem && riskCardImpactProbaOpenView && roItem[probaKey] !== probaValue) {

        let mutateObj = {
          id: roItem.id,
          [probaKey]: probaValue,
        }

        onSaveAddEdit(null, mutateObj, false);
      }
  }, [onSaveAddEdit, riskCardImpactProbaOpenView, roItem]);

  /////////////////////////////////////////////////////////////
  /// COMPONENT RENDER ///////////////////////////////////////
  ////////////////////////////////////////////////////////////

  return (
    <div className="riskBigViewModeSingleMatrixContainer flexColStartCenter flipContainerBack">
      {/* HEADERS */}
      <div className="flexBetweenCenter" style={{
        width: '100%',
        borderBottom: '1px solid var(--border-color-gray-1)',
        padding: '4px',
      }}>
        {/* TITLE */}

        <div className='flexStartCenter'>
                    {/* ICON */}
                    <span className="material-icons actionsChartHeaderIcon">
                        calculate
                    </span>
                    {/* TITLE */}
                    <div className="actionsChartHeaderTitle" style={{
                        fontSize: '13px',
                     }}>
                      {`${riskCardImpactProbaOpenView ? riskCardImpactProbaOpenView.label : ''} Rating`}
                    </div>
        </div>




        {/* MODAL CHECKBOX */}
        <CvtButton
          color="gray"
          outline
          customHover="blue"
          shape="rounded"
          icon="balance"
          size="tiny"
          shadow="black"
          handleSubmit={(e) => handleSwitchingView(e, null)}
          txt="Mapping View"
          fontSize="12px"
        />
      </div>

      <div className="flexColStartStart riskCardImpactContentContainer">

        {/* HEADERS */}
        <div className="flexStartCenter" style={{width: '100%'}}>
          { [null, ...RISK_OPP_IMPACT_LIST].map((impactItem, index) => {

            let isSelected = impactItem ? isImpactSelected(impactItem.value) : false;

            return impactItem ? (
              <div
                key={`risk_card_impact_header_${impactItem.value}_${index}`}
                className="riskCardImpactTableHeader ellipsisOneLine flexOne"
                style={{
                    backgroundColor: roItem ? roItem.isRisk ? impactItem.riskColor : impactItem.opportunityColor : impactItem.riskColor,
                    boxShadow: isSelected ? '2px 3px 5px 0px rgba(0, 0, 0,0.3)' : '',
                    // border: isSelected ? '2px solid var(--border-color-gray-3)' : '',
                    opacity: !isSelected ? "0.5" : "1",
                }}
                // onClick={() => handleClickImpactHeader(impactItem.value)}
              >
                 {impactItem.label}
              </div>
            ) : (
              <div key={`risk_card_impact_empty_${index}`} className="riskCardImpactTableHeader riskCardImpactTableHeaderTitle flexOne" style={{padding: 0}}>Impact</div>
            );
          })}
        </div>

        {/* CONTENT */}
        <div style={{width: '100%', marginTop: '5px'}} className="flexColStartStart flexOne">
            {
                RISK_OPP_CARD_IMPACT_MAP.map((impactRow, index) => {
                    return (
                        <div key={`risk_card_impact_map_${impactRow}_${index}`} className="flexStartCenter flexOne" style={{width: '100%', margin: '1px 0px'}}>

                            {/* TITLE ROW */}
                            <div className="flexColStartStart flexOne" style={{marginRight: '20px', minWidth: '64px'}}>
                                {/* TITLE */}
                                <span className="riskCardImpactMapTitle">{impactRow}</span>
                                {/* INCREASE */}
                                <span className="riskCardIMpactMapSubtitle">{riskCardImpactProbaOpenView ? riskCardImpactProbaOpenView.label : ''}</span>
                            </div>

                            {/* OTHER ITEMS */}
                            {
                                RISK_OPP_CARD_IMPACT_VALUES.map((impactRowValue, index) => {

                                    let stringToMap = impactRowValue.split(" >");

                                    // IF RO ITEM.[cost|schedule|scope|quality] has same value as index

                                    let isSelected = roItem ? roItem[ratingKeys[impactRow.toLowerCase()]] ? roItem[ratingKeys[impactRow.toLowerCase()]] === index+1 : false : false;

                                    return (
                                        <div
                                            key={`risk_card_impact_value_${impactRowValue}_${index}`}
                                            className={`flexColCenterCenter flexOne 
                                            
                                               ${canModifyRo ? "riskCardImpactValueContainer" : "riskCardsImpactValueUneditableContainer"}
                                            `}
                                            style={{
                                                backgroundColor: isSelected ? 'var(--color-migso-blue)' : '',
                                                color: isSelected ? 'white' : ''
                                            }}
                                            onClick={() => canModifyRo ? handleChangeRatings(impactRow.toLowerCase(), index + 1) : {}}
                                        >
                                            <span className="riskCardImpactValue">
                                                    {stringToMap[0]}
                                            </span>

                                            {/* <span className="riskCardImpactValue">or</span> */}
                                            <span style={{height:'1px', width: '50%', backgroundColor: 'white'}}></span>

                                            <span className="riskCardImpactValue">{stringToMap[1]}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>

        {/* ROW FOR PROBABILITY TOO */}
        <div className="flexStartCenter" style={{width: '100%', marginTop: '5px'}}>
          { [null, ...RISK_OPP_PROBABILITY_LIST].map((probaItem, index) => {

            let isSelected = probaItem ? isProbaSelected(probaItem.value) : false;

            return probaItem ? (
              <div
                key={`risk_card_proba_header_${probaItem.value}_${index}`}
                className="riskCardImpactTableHeader ellipsisOneLine flexOne"
                style={{
                    backgroundColor: roItem ? roItem.isRisk ? probaItem.riskColor : probaItem.opportunityColor : probaItem.riskColor,
                    boxShadow: isSelected ? '2px 3px 5px 0px rgba(0, 0, 0,0.3)' : '',
                    // border: isSelected ? '2px solid var(--border-color-gray-3)' : '',
                    opacity: !isSelected ? "0.5" : "1",
                    cursor: canModifyRo ? 'pointer' : 'default',
                }}
                onClick={() => canModifyRo ? handleClickProbaHeader(probaItem.value) : {}}
              >
                 {probaItem.label}
              </div>
            ) : (
              <div key={`risk_card_impact_empty_${index}`} className="riskCardImpactTableHeader riskCardImpactTableHeaderTitle" style={{padding: 0}}>Probability</div>
            );
          })}
        </div>


      </div>
    </div>
  );
});
