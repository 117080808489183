import { TODAY, TODAY_PLUS_30D } from "../../../../00-Core/Constants";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";

// SCHEDULE BIG COMPONENT BODY EXPAND TYPES
export const SCHEDULE_COMPONENT_EXPAND_TYPES = {
    graph: "GRAPH",
    table: "TABLE",
    both: "BOTH",
};

// TLG GUID
export const SCHEDULE_TLG_GUID_PREFIX = "TLG";

// PPM GUID
export const SCHEDULE_PPM_GUID_PREFIX = "PPM";

//START DISPLAY ID
export const SCHEDULE_DISPLAY_ID_STR = "SC";

export const SCHEDULE_TYPES = [
    {
        value: "task",
        label: "Task",
    },
    {
        value: "milestone",
        label: "Milestone",
    },
];

export const SCHEDULE_TYPE_KEYS = {
    task: "task",
    milestone: "milestone",
};



// STRUCTURE OF ACTION ITEM
export const SCHEDULE_EDIT_SIDEBAR_CONTENT = [
    // ITEM IDS
    { groupId: -1, key: "id", value: null, type: "id" },
    { groupId: -1, key: "organizationId", value: "", type: "id" },
    { groupId: -1, key: "projectId", value: "", type: "id" },
    { groupId: -1, key: "wbsId", value: "", type: "id" },
    { groupId: -1, key: "displayId", value: "", type: "id" },

    {
        groupId: 0,
        key: "name",
        value: "",
        label: "Name",
        component: "input",
        maxLength: 150,
        type: "text",
        info: "",
        placeholder: "Enter a name",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "responsible",
        value: "",
        label: "Responsible",
        component: "select",
        type: "list",
        options: [],
        info: "",
        placeholder: "Who's the responsible",
        mandatory: true,
    },

    // SCHEDULE TYPE
    {
        groupId: 0,
        key: "type",
        value: "task",
        label: "Type",
        component: "select",
        type: "list",
        options: SCHEDULE_TYPES,
        info: "",
        placeholder: "Choose a schedule type",
        mandatory: true,
        displayLink: ["baseline", "forecast", "actual"],
    },

    // DATES FOR TASK
    {
        groupId: 0,
        key: "baselineStart",
        rowWithNext: true,
        value: TODAY,
        label: "Baseline Start",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        dateIsBefore: "baselineFinish",
        displayOptions: { type: "task" },
    },
    {
        groupId: 0,
        key: "baselineFinish",
        value: TODAY_PLUS_30D,
        label: "Baseline Finish",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        dateIsAfter: "baselineStart",
        displayOptions: { type: "task" },
    },

    {
        groupId: 0,
        key: "forecastStart",
        rowWithNext: true,
        value: TODAY,
        label: "Forecast Start",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        dateIsBefore: "forecastFinish",
        displayOptions: { type: "task" },
    },
    {
        groupId: 0,
        key: "forecastFinish",
        value: TODAY_PLUS_30D,
        label: "Forecast Finish",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        dateIsAfter: "forecastStart",
        displayOptions: { type: "task" },
    },

    {
        groupId: 0,
        key: "actualStart",
        rowWithNext: true,
        value: null,
        label: "Actual Start",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: false,
        dateIsBefore: "actualFinish",
        displayOptions: { type: "task" },
    },
    {
        groupId: 0,
        key: "actualFinish",
        value: null,
        label: "Actual Finish",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: false,
        dateIsAfter: "actualStart",
        displayOptions: { type: "task" },
    },

    // DATES FOR MILESTONE
    {
        groupId: 0,
        key: "baseline",
        value: null,
        label: "Baseline",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        displayOptions: { type: "milestone" },
    },
    {
        groupId: 0,
        key: "forecast",
        value: null,
        label: "Forecast",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        displayOptions: { type: "milestone" },
    },
    {
        groupId: 0,
        key: "actual",
        value: null,
        label: "Actual",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: false,
        displayOptions: { type: "milestone" },
    },
    // DESCRIPTION
    {
        groupId: 0,
        key: 'description',
        value: null,
        label: "Description",
        component: "textArea",
        type: "richTextArea",
        info: "",
        placeholder: "Schedule's description",
        mandatory: false,
    }
];

export const SCHEDULE_STATUS_KEYS = {
    toDo: "toDo",
    inProgress: "inProgress",
    inThePast: "inThePast",
    done: "done",
};

export const SCHEDULE_STATUS = [
    {
        label: "To Do",
        value: "toDo",
        backgroundColor: "var(--color-text-5)",
        color: "white",
    },
    {
        label: "In Progress",
        value: "inProgress",
        backgroundColor: "var(--color-migso-blue)",
        color: "white",
    },
    {
        label: "In the Past",
        value: "inThePast",
        backgroundColor: "var(--color-bad)",
        color: "white",
    },

    {
        label: "Done",
        value: "done",
        backgroundColor: "var(--color-migso-green)",
        color: "white",
    },
];

export const SCHEDULE_CHART_TXT_CONVERSION = {
    inThePast: "In the Past",
    toDo: "To Do",
    inProgress: "In Progress",
    done: "Done",
    baselineCumu: "Baseline",
    forecastCumu: "Forecasted cumu",
    completedCumu: "Completed cumu",
};

export const SCHEDULE_TIMELINE_COLORS = {
    baseline: "var(--border-color-gray-1)",
    // forecast: "var(--color-text-4)",
    forecast: "rgba(170, 170, 170, 0.6)",
    actual: "var(--color-migso-blue-light)",
};

export const SCHEDULE_REQUIRED_ATTRIBUTES = [
    "id",
    "organizationId",
    "projectId",
    "wbsId",
    "createdBy",
    "guid",
    "displayId",
    "guidUpdate",
    "manualUpdate",
    "name",
    "responsible",
    "createdOn",
    "path",
    "type",
    "baselineStart",
    "baselineFinish",
    "forecastStart",
    "forecastFinish",
    "actualStart",
    "actualFinish",
    "groupEditors",
    "groupViewers",
];

// DISPLAY INFO
export const FIELDS_ANALYSIS = [
    { name: "Type", attribut: "type", type: "txt", icon: "notes" },
    { name: "Name", attribut: "name", type: "txt", icon: "notes" },
    { name: "Label", attribut: "label", type: "txt", icon: "notes" },
    {
        name: "Baseline Start Date",
        attribut: "baselineStart",
        type: "date",
        icon: "calendar_today",
        textAlign: "center",
    },
    {
        name: "Baseline Finish Date",
        attribut: "baselineFinish",
        type: "date",
        icon: "calendar_today",
        textAlign: "center",
    },
    {
        name: "Planned Start Date",
        attribut: "plannedStart",
        scheduleAttribut: "forecastStart",
        type: "date",
        icon: "calendar_today",
        textAlign: "center",
    },
    {
        name: "Planned Finish Date",
        attribut: "plannedFinish",
        scheduleAttribut: "forecastFinish",
        type: "date",
        icon: "calendar_today",
        textAlign: "center",
    },
    {
        name: "Actual Start Date",
        attribut: "actualStart",
        type: "date",
        icon: "calendar_today",
        textAlign: "center",
    },
    {
        name: "Actual Finish Date",
        attribut: "actualFinish",
        type: "date",
        icon: "calendar_today",
        textAlign: "center",
    },
];

// DATES
export const SCHEDULE_DATE_ATTRIBUTES = [
    "baselineStart",
    "baselineFinish",
    "forecastStart",
    "forecastFinish",
    "actualStart",
    "actualFinish",
];

export const SCHEDULE_DEFAULT_ITEM = {
    // DATA
    id: null,
    organizationId: null,
    projectId: null,
    wbsId: null,
    displayId: null,
    createdBy: null,
    createdOn: TODAY,
    actionsIds: "[]",

    // INFO
    name: null,
    responsible: null,
    type: "task",
    events: "[]",
    comments: "[]",
    description: "<p><br></p>",

    // DATES FOR TASK
    baselineStart: null,
    baselineFinish: null,
    forecastStart: null,
    forecastFinish: null,
    actualStart: null,
    actualFinish: null,

    // DATES FOR MILESTONE
    baseline: null,
    forecast: null,
    actual: null,
};

export const SCHEDULE_DEFAULT_STATE = {
    actionsOperationType: null,
    editSideBarContent: null, // CONTENT THAT GOES INSIDE EDIT SIDE BAR
    editSideBar: false, // WHETHER SIDE BAR IS OPEN OR NOT
    selected: [], // SELECTED FROM TABLE
    selectedItemView: null,
    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
    isConfirmDelete: false,
    textSearchInput: "",
    statusSearchInput: [],
    expandMode: SCHEDULE_COMPONENT_EXPAND_TYPES.both, // EXPAND MODE (initially show both graph and table)
    scrollToId: null,
    isConsolidate: true,
    widthSizeLimit: false,

    // GRAPH SETTINGS
    atCompletionMode: false,
    atCompletionSelectedFirstYear: null,
    atCompletionSelectedLastYear: null,
    yearSelected: new Date().getFullYear(),
    secondYAxis: false,

    // LOADING SIDE BAR BUTTONS
    isLoadingDelete: false,

    scheduleCardPropKey: 0,
};
