import React from "react";
import {  CvtButton } from "@mi-gso/cvt";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";
import { EDIT_SIDE_BAR_IDS } from "../00-Helpers/EditSideBarConstants";

//EDIT SIDE BAR HEADER
export default function EditSideBarHeader({
    title,
    updatedFields,
    errorMngt,
    handleSave,
    handleShowHideSaveModal,
    resetEditSideBar,
    canSave,
    itemId,
    onDelete,
    viewMode,
}) {
    return (
        <div className="offcanvas-header rightSideBarHeader flexBetweenCenter">
            {/* TITLE */}
            <div id="offcanvasRightLabel" className="rightSideBarHeaderTitle flexStartCenter">
                <span
                    className="material-icons"
                    style={{
                        marginRight: "10px",
                        color: "var(--color-migso-blue)",
                    }}
                >
                    edit
                </span>
                {title}
            </div>

            {/* BUTTONS */}

            <div className="flexStartCenter">
                {/* SUBMIT */}
                <CvtButton
                    txt="Save"
                    color={updatedFields.length > 0 ? "orange" : "blue"}
                    icon="save"
                    disabled={updatedFields.length === 0 || !canSave}
                    margin="0px 10px 0px 0px"
                    handleSubmit={
                        errorMngt.length > 0 || !canSave ? handleShowHideSaveModal : handleSave
                    }
                    buttonId={EDIT_SIDE_BAR_IDS.saveBtn}
                    tooltip={updatedFields.length === 0 || !canSave}
                    tooltipTxt="Please fill out all the mandatorey fields to save"
                />

                {/* DELETE BTN */}
                {itemId && onDelete && viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit && (
                    <CvtButton
                        color="red"
                        icon="delete"
                        disabled={viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.edit}
                        margin="0px 10px 0px 0px"
                        fontWeight={500}
                        handleSubmit={() => onDelete(itemId)}
                        needConfirmation
                    />
                )}

                {/* CLOSE */}
                <CvtButton
                    color="white"
                    shape="noShape"
                    customHover="red"
					outline={true}
                    needConfirmation={updatedFields.length > 0}
                    icon="close"
                    handleSubmit={resetEditSideBar}
                    txtConfirm="Don't Save ?"
                />
            </div>

            
        </div>
    );
}
