// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';


//THE FILE TEXT
const markdown = `

## PRÉAMBULE

Les présentes Conditions d’utilisation (ci-après désignées les « **Conditions d’utilisation** ») visent à définir la relation contractuelle entre la Société et l’Utilisateur pour l’utilisation de l’outil CLAYVEREST (« **CLAYVEREST** »,).

L’employeur ou l’autorité d’emploi/le donneur d’ordre de l’Utilisateur (le « **Client** ») a conclu un contrat de services avec la société MI-GSO - PCUBED pour la réalisation des Services. En lisant et acceptant les présentes Conditions d’utilisation, l’Utilisateur consent à activer son compte CLAYVEREST.

Les Services sont fournis par MI-GSO - PCUBED, une société dûment constituée de droit français, sise rue Alain Fournier, Toulouse, France (la « **Société** » ou « **MI-GSO – PCUBED** »).

Toute utilisation de CLAYVEREST suppose l’acceptation préalable et sans réserve de l’ensemble des termes des présentes Conditions d’Utilisation par l’Utilisateur. 

## DEFINITIONS

- « **CLAYVEREST**» désigne l’outil qui permet à l’Utilisateur de stocker, partager, organiser, gérer des Données dans le cadre de la gestion de ses projets. CLAYVEREST est disponible à l’adresse www.clayverest.com.
- « **Données** » désigne toute information concernant un projet du Client.
- « **Données à caractère personnel** » désigne toute information concernant une personne physique identifiée ou identifiable. Une « personne physique identifiable » est une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu’un nom, un numéro d’identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à ladite personne physique.
- « **Services** » désigne les services fournis par la Société au Client et à l’Utilisateur via l’accès à CLAYVEREST.
- « **Utilisateur** » désigne la personne physique à laquelle a été donné accès à CLAYVEREST afin qu’elle puisse y réaliser ses tâches.

## DESCRIPTION DES SERVICES

Suivant l’accord entre la Société et le Client, l’Utilisateur a accès à un ou plusieurs des services suivants : 

- Timeline : Création de roadmaps customisables de haut niveau basé ou non sur des données planning. 
- Telescope : Gestion de portefeuille de projets et de leurs principales composantes (coûts, délais, charte projet etc.)
- Core : Création d’organisations et de leur Work Breakdown Structure (WBS). Ces WBS sont par la suite utilisée dans les deux applications ci-dessus.

## ACCES AU SITE ET AUX SERVICES

L’accès à CLAYVEREST est exclusivement réservé aux Utilisateurs disposant d’une licence souscrite par le Client.

Le Client et l’Utilisateur sont seuls responsables du bon fonctionnement du matériel informatique et de la connexion internet qui leur permettent l’accès à CLAYVEREST et aux Services. 

En cas d’interruption planifiée ou impromptue de CLAYVEREST, l’Utilisateur sera informé de la situation par un message qui apparaîtra au moment de sa connexion à son compte.

La Société déploiera tous les efforts possibles pour garantir la disponibilité maximale du Service. La Société mettra en œuvre les moyens commerciales raisonnables pour assurer la disponibilité du Service.

## DUREE

L’Utilisateur accepte les présentes Conditions d’Utilisation pour une durée indéterminée.

## OBLIGATIONS DE L’UTILISATEUR

L'Utilisateur s'engage à accéder et utiliser CLAYVEREST et les Services conformément aux lois en vigueur et aux présentes Conditions d’Utilisation.

Il est interdit de charger sur CLAYVEREST des Données dont le contenu est illégal, malveillant, susceptible de porter atteinte aux droits de tiers, ou contraire aux bonnes mœurs ou à l’ordre public. La société MI-GSO ne saurait en aucun cas être tenue pour responsable de tout contenu illégal stocké sur CLAYVEREST et se réserve le droit de suspendre tout compte enfreignant ces dispositions et, par conséquent, de suspendre tout accès lié.

Dans le cadre de l’utilisation de l’outil d’assistance utilisateurs et de suivi des nouvelles fonctionnalités, dit « ticketing », l’Utilisateur s’engage à ne pas saisir de données commerciales ou personnelles, encore moins de données sensibles (religion, politique, philosophique, santé, orientation de sexe et de genre), et à veiller à toujours respecter les règles coutumières sur internet : limiter les majuscules, utiliser un langage clair, limiter les smileys ou les abréviations.

## SECURITE

La Société mettra en place des mesures techniques et organisationnelles adaptées pour assurer un niveau de sécurité adapté au risque. 

Le Client a validé les mesures utiles prises par la Société en matière de sécurité, notamment en ce qui concerne la sécurité des équipements, afin d’assurer la protection et l’intégrité des Données pendant leur durée de conservation dans le cadre des Services.

## COOKIES, ANALYTICS & JOURNALISATION

La Société n’utilise que des cookies fonctionnels, qui permettent le fonctionnement de la plateforme en gérant l’authentification, les changements entre les outils de la plateforme, etc.

La Société utilise également des outils d’analytics, qui lui permettent de mesurer la performance de CLAYVEREST, ainsi que d’horodater les différents évènements (journalisation) pour piloter la sécurité de CLAYVEREST et contribuer à l’analyse de performance.

## RESPONSABILITE

L’Utilisateur et le Client sont les seuls responsables de la bonne gestion des projets. 

La Société intervient en tant que fournisseur d’un outil aux Utilisateurs dans le cadre de la gestion de projets. Elle ne peut être en aucun cas considérée comme responsable des résultats des projets. La responsabilité de la Société se limite donc à la mise à la disposition de CLAYVEREST et s’engage à garantir l’accès à CLAYVEREST ainsi que la conservation de l’intégrité et la confidentialité des Données.

## PROPRIÉTÉ INTELLECTUELLE ET LICENCE

La Société conserve tous les droits de propriété relatifs à CLAYVEREST et à ses Services y compris l’ensemble du savoir-faire, les méthodologies, le code, les designs et améliorations des Services, à l'exclusion des Données intégrées à tout livrable. Les livrables ou résultats obtenus grâce à CLAYVEREST sont la propriété industrielle, littéraire ou artistique de l’Utilisateur ou du Client, suivant le régime qui s’applique entre eux et auquel la Société demeure tierce.

## MODIFICATIONS APPORTÉES A CLAYVEREST ET AUX PRÉSENTES CONDITIONS D’UTILISATION

La Société se réserve le droit de modifier ou de mettre à jour les présentes Conditions d’Utilisation. L’Utilisateur devra alors valider la mise à jour de ces Conditions d’Utilisation lors de sa connexion à CLAYVEREST.

## DONNÉES À CARACTÈRE PERSONNEL

> Ceci n’a pas valeur contractuelle, mais résume vos droits en tant qu’utilisateur, à l’égard de vos données. 

MI-GSO et son Client (nous) ont choisi l’outil CLAYVEREST dans le cadre du pilotage de leurs projets. A ce titre, nous sommes conjointement responsables du traitement de vos données à caractère personnel, comme vos identifiants (courriel et mot-de-passe, numéro de téléphone) ou les données que vous entrez dans l’outil. Cela repose sur notre intérêt légitime à vous fournir les outils nécessaires à la réalisation de votre travail, ainsi qu’à assurer la sécurité informatique de l’outil. Nous et nos fournisseurs utiliseront ces données, au travers de nos équipes projets, techniques, ou administratives, et les conserverons jusqu’à la fin des projets auxquels vous avez participé ou l’inactivation de votre compte CLAYVEREST. 

Vous disposez de plusieurs droits que vous pouvez exercer en ouvrant un ticket de support technique. 

| Droit | Description |
|:-----|:------|
| **Accès** | Toutes vos données, à l’exception des logs, sont visibles et accessibles dans l’outil. Pour une copie intégrale, voir paragraphe précédent |
| **Rectification** | Vous pouvez rectifier directement vos noms d’utilisateur, mot-de-passe, e-mail, numéro de téléphone et le type de double authentification en vous connectant à CLAYVEREST, cliquant sur « User Profile », puis en éditant ET sauvegardant. Vous pouvez également corriger spontanément les objets que vous créez dans l’outil |
| **Effacement** | Vous pouvez demander l’effacement de vos données, ce qui empêchera toute nouvelle utilisation de l’outil. |
| **Limitation** | Vous pouvez demander la limitation du traitement de vos données, ce qui empêchera temporairement son utilisation. |
| **Portabilité** | Ce droit ne s’applique pas à un outil comme CLAYVEREST, car son utilisation ne repose pas sur un consentement ou un contrat. |
| **Opposition** | L’opposition n’est pas possible car cela vous empêcherait de réaliser vos tâches professionnelles.  |

Vous pouvez nous joindre par la personne en charge des données personnelles de votre employeur ou par un ticket de support. En cas de désaccord après ce contact, vous pouvez adresser une réclamation à la Commission Nationale Informatique et Libertés, Place de Fontenoy, 75007 Paris. 

Vos données ne sont pas transférées hors de l’espace économique européen, et nous ne ferons pas de profilage ou de prise de décision purement automatisée sur celles-ci.

## LANGUE

Les présentes Conditions d’utilisation ont été rédigées dans plusieurs langues. Aux fins de l’interprétation des présentes Conditions d’utilisation, la version française prévaudra.

## DROIT APPLICABLE ET JURIDICTION COMPETENTE

Les présentes Conditions d’Utilisation sont soumises au droit français. Tout litige relatif aux présentes Conditions d’Utilisation, à leur formation, interprétation, exécution, résiliation ou expiration relève de la compétence exclusive des juridictions françaises.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;