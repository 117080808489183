import React, { useCallback, useState } from "react";
import { CvtSideBarButton } from "@mi-gso/cvt";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../00-Wbs/00-Helpers/WbsConstants";
import WbsElementBigSideBar from "../00-Wbs/01-Components/WbsElementBigSideBar";

/////////////////////////////////////////////////////////////////
/// LIST LEFT SIDE BAR COMPONENT ////////////////////////////////
/////////////////////////////////////////////////////////////////

export default function ScheduleLeftSideBar({
    actionView,
    canModifySchedules,
    currentUserName,
    isCurrentUserEditor,
    isReadOnlyMode,
    isConsolidate,
    selected,
    selectedItemView,
    viewMode,
    onAddEditClick,
    onDeleteSideBar,
    onDuplicateSideBar,
    onBackSideBar,
    onConsolidate,
    handleMoveItemsClicked,
    isLoadingDelete,
}) {
    /////////////
    /// STATE ///
    /////////////

    //INIT
    const [confirm, setConfirm] = useState({
        value: false,
        type: "",
    });

    //HANDLE DELETE
    const handleAction = useCallback(
        (e, actionType, reset) => {
            e.preventDefault();

            //RESET
            if (reset) {
                setConfirm({
                    value: false,
                    type: "",
                });

                //ALREADY CONFIRM CAN DELETE
            } else if (confirm.value) {
                //TYPE
                switch (actionType) {
                    //DUPLICATE
                    case "duplicate":
                        onDuplicateSideBar(e);
                        break;

                    //DELETE
                    case "delete":
                        onDeleteSideBar(e);
                        break;

                    default:
                        break;
                }

                //UPDATE STATE
                setConfirm({
                    value: false,
                    type: "",
                });

                //NEED CONFIRM
            } else {
                setConfirm({
                    value: true,
                    type: actionType,
                });
            }
        },
        [confirm, onDeleteSideBar, onDuplicateSideBar]
    );  

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////

    // CASE VIEW MODE (ITEM CARD)
    if (
        viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ||
        (viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit && selectedItemView)
    ) {
        return (
            <WbsElementBigSideBar>
                {/* GO BACK */}
                <CvtSideBarButton
                    buttonClass="flexCenterCenter buttonSideBar buttonSideBarBack"
                    id="tooltipBackBig"
                    icon="arrow_back"
                    fontSize="18px"
                    title="Back to list"
                    onClickFunction={onBackSideBar}
                    cyId="buttonBackBig"
                />

                {!isReadOnlyMode ? (
                    <React.Fragment>
                        {/* EDIT */}
                        {canModifySchedules ? (
                            <CvtSideBarButton
                                buttonClass="flexCenterCenter buttonSideBar"
                                id="tooltipEditBig"
                                icon="edit"
                                fontSize="22px"
                                title="Edit"
                                onClickFunction={() => onAddEditClick(selectedItemView)}
                                cyId="buttonEditBig"
                            />
                        ) : null}

                        {/* DUPPLICATE */}
                        <CvtSideBarButton
                            buttonClass={
                                "flexCenterCenter buttonSideBar " +
                                (confirm.value && confirm.type === "duplicate"
                                    ? "buttonSideBarAlert"
                                    : "")
                            }
                            id="tooltipDuplicateBig"
                            icon="copy_all"
                            fontSize="22px"
                            title={
                                confirm.value && confirm.type === "duplicate"
                                    ? "Are you sure?"
                                    : "Duplicate"
                            }
                            onClickFunction={(e) => handleAction(e, "duplicate")}
                            onBlur={(e) => handleAction(e, "duplicate", true)}
                            onBlurConfirmation={
                                confirm.value && confirm.type === "duplicate"
                                    ? confirm.value
                                    : false
                            }
                            cyId="buttonDuplicateBig"
                        />

                        {/* DELETE CURRENT ACTION */}
                        {canModifySchedules ? (
                            <CvtSideBarButton
                                buttonClass={
                                    "flexCenterCenter buttonSideBar " +
                                    (confirm.value && confirm.type === "delete"
                                        ? "buttonSideBarConfirmDelete"
                                        : "")
                                }
                                id="tooltipDeleteBig"
                                icon="delete"
                                fontSize="22px"
                                title={
                                    confirm.value && confirm.type === "delete"
                                        ? "Are you sure?"
                                        : "Delete"
                                }
                                onClickFunction={(e) => handleAction(e, "delete")}
                                onBlur={(e) => handleAction(e, "delete", true)}
                                onBlurConfirmation={
                                    confirm.value && confirm.type === "delete"
                                        ? confirm.value
                                        : false
                                }
                                loading={isLoadingDelete}
                                cyId="buttonDeleteBig"
                            />
                        ) : null}

                        {/* MOVE */}
                        {
                            canModifySchedules ?
                            <CvtSideBarButton
                                buttonClass={`flexCenterCenter buttonSideBar`}
                                id="tooltipMoveBig"
                                icon="move_down"
                                fontSize="22px"
                                title="Move Items"
                                onClickFunction={handleMoveItemsClicked}
                            />
                        :null}
                        
                    </React.Fragment>
                ) : null}
            </WbsElementBigSideBar>
        );
    }

    // OTHER CASES
    return (
        <WbsElementBigSideBar>
            {/* DISPLAY BUTTON RELATED VIEW */}
            {viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
            viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.edit ? 
                <React.Fragment>

                    {/* ADD BUTTON */}
                    {!isReadOnlyMode ? 
                        <CvtSideBarButton
                            buttonClass="flexCenterCenter buttonSideBar"
                            id="tooltipAddBig"
                            icon="add"
                            fontSize="22px"
                            title="Add Schedule"
                            onClickFunction={() => onAddEditClick()}
                            testId="buttonAddBig"
                        />
                    : null}

                    {/* Consolidate */}
                    <CvtSideBarButton
                        buttonClass={
                            "flexCenterCenter buttonSideBar " +
                            (isConsolidate ? "" : "buttonSideBarSelected")
                        }
                        id="tooltipConsolidate"
                        icon={isConsolidate ? "bookmarks" : "bookmark"}
                        fontSize="22px"
                        title={
                            isConsolidate
                                ? "Only this branche's Schedules"
                                : "Include all children's Schedules"
                        }
                        onClickFunction={onConsolidate}
                        testId="buttonConsolidateBig"
                    />

                    {/* EDIT MODE */}
                    {!isReadOnlyMode ? 
                        <React.Fragment>

                            {/* EDIT */}
                            {selected.length === 1 && canModifySchedules ? (
                                <CvtSideBarButton
                                    buttonClass="flexCenterCenter buttonSideBar"
                                    id="tooltipEditBig"
                                    icon="edit"
                                    fontSize="22px"
                                    title="Edit"
                                    onClickFunction={() => onAddEditClick(selected[0])}
                                />)
                            : null}

                            {/* DUPLICATE */}
                            {selected.length > 0 ? 
                                <CvtSideBarButton
                                    buttonClass={
                                        "flexCenterCenter buttonSideBar " +
                                        (confirm.value && confirm.type === "duplicate"
                                            ? "buttonSideBarAlert"
                                            : "")
                                    }
                                    id="tooltipDuplicateBig"
                                    icon="copy_all"
                                    fontSize="22px"
                                    title={
                                        confirm.value && confirm.type === "duplicate"
                                            ? "Are you sure?"
                                            : "Duplicate"
                                    }
                                    onClickFunction={(e) => handleAction(e, "duplicate")}
                                    onBlur={(e) => handleAction(e, "duplicate", true)}
                                    onBlurConfirmation={
                                        confirm.value && confirm.type === "duplicate"
                                            ? confirm.value
                                            : false
                                    }
                                    testId="buttonDuplicateBig"
                                />
                            : null}

                            {/* DELETE */}
                            {selected.length > 0 && canModifySchedules ? (
                                <CvtSideBarButton
                                    buttonClass={
                                        "flexCenterCenter buttonSideBar " +
                                        (confirm.value && confirm.type === "delete"
                                            ? "buttonSideBarConfirmDelete"
                                            : "")
                                    }
                                    id="tooltipDeleteBig"
                                    icon="delete"
                                    fontSize="22px"
                                    title={
                                        confirm.value && confirm.type === "delete"
                                            ? "Are you sure?"
                                            : "Delete"
                                    }
                                    onClickFunction={(e) => handleAction(e, "delete")}
                                    onBlur={(e) => handleAction(e, "delete", true)}
                                    onBlurConfirmation={
                                        confirm.value && confirm.type === "delete"
                                            ? confirm.value
                                            : false
                                    }
                                    loading={isLoadingDelete}
                                />)
                            : null}

                            {/* MOVE */}
                            {selected.length > 0 && canModifySchedules ? (
                                <CvtSideBarButton
                                    buttonClass={`flexCenterCenter buttonSideBar`}
                                    id="tooltipMoveBig"
                                    icon="move_down"
                                    fontSize="22px"
                                    title="Move Items"
                                    onClickFunction={handleMoveItemsClicked}
                                />)
                            : null}
                        </React.Fragment>
                    : null}
                </React.Fragment>
            : 
                <React.Fragment>

                    {/* GO BACK */}
                    <CvtSideBarButton
                        buttonClass="flexCenterCenter buttonSideBar buttonSideBarBack"
                        id="tooltipBackBig"
                        icon="arrow_back"
                        fontSize="18px"
                        title="Back to list"
                        onClickFunction={onBackSideBar}
                        testId="buttonBackBig"
                    />

                    {/* NOT IF READ ONLY */}
                    {!isReadOnlyMode ? 
                        <React.Fragment>

                            {/* EDIT */}
                            {canModifySchedules ?
                                <CvtSideBarButton
                                    buttonClass="flexCenterCenter buttonSideBar"
                                    id="tooltipEditBig"
                                    icon="edit"
                                    fontSize="22px"
                                    title="Edit"
                                    onClickFunction={() => onAddEditClick(actionView)}
                                />
                            : null}

                            {/* DUPPLICATE */}
                            <CvtSideBarButton
                                buttonClass={
                                    "flexCenterCenter buttonSideBar " +
                                    (confirm.value && confirm.type === "duplicate"
                                        ? "buttonSideBarAlert"
                                        : "")
                                }
                                id="tooltipDuplicateBig"
                                icon="copy_all"
                                fontSize="22px"
                                title={
                                    confirm.value && confirm.type === "duplicate"
                                        ? "Are you sure?"
                                        : "Duplicate"
                                }
                                onClickFunction={(e) => handleAction(e, "duplicate")}
                                onBlur={(e) => handleAction(e, "duplicate", true)}
                                onBlurConfirmation={
                                    confirm.value && confirm.type === "duplicate"
                                        ? confirm.value
                                        : false
                                }
                                testId="buttonDuplicateBig"
                            />

                            {/* DELETE CURRENT ACTION */}
                            {canModifySchedules ? 
                                <CvtSideBarButton
                                    buttonClass={
                                        "flexCenterCenter buttonSideBar " +
                                        (confirm.value && confirm.type === "delete"
                                            ? "buttonSideBarConfirmDelete"
                                            : "")
                                    }
                                    id="tooltipDeleteBig"
                                    icon="delete"
                                    fontSize="22px"
                                    title={
                                        confirm.value && confirm.type === "delete"
                                            ? "Are you sure?"
                                            : "Delete"
                                    }
                                    onClickFunction={(e) => handleAction(e, "delete")}
                                    onBlur={(e) => handleAction(e, "delete", true)}
                                    onBlurConfirmation={
                                        confirm.value && confirm.type === "delete"
                                            ? confirm.value
                                            : false
                                    }
                                    testId="buttonDeleteBig"
                                />
                            : null}
                        </React.Fragment>
                    : null}
                </React.Fragment>
            }
        </WbsElementBigSideBar>
    );
}