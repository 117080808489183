//AMPLIFY
import {API} from 'aws-amplify'
import {Auth} from '@aws-amplify/auth'

///////////////////////////////////
// SUMMARY
// - getOrganizations
// - getUserProjects
// - getProjectWbs
///////////////////////////////////

///////////////////////////////
/// GET ORGANIZATION BY IDS ///
///////////////////////////////

export const getOrganizations = async (
    organizationIds
) =>{

    //CREATE ID STRING LIST
    let idStringList = ""
    for (let i = 0; i < organizationIds.length; i++) {
        if(i === 0){
            idStringList = organizationIds[i];
        }else{
            idStringList = idStringList + "," + organizationIds[i];
        }
    }

    //CALL INIT
    let myInit = { 
        queryStringParameters: {
            "organizationIds": idStringList,
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //GET ITEM
    return API.get("mpCoreRestApi", "/core/items", myInit);
}

//NEW VERSION WITH BATCH
export const getOrganizationsByBatch = async (
    organizationIds,
    tempToken
) =>{

    //CREATE ID STRING LIST
    const idStringList = organizationIds.toString()

    //CALL INIT
    let myInit = { 
        queryStringParameters: {
            "organizationIds": idStringList,
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: tempToken ? 
                    tempToken 
                : 
                    `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //GET ITEM
    return await API.get("mpCoreRestApi", "/core/organizations/batchget", myInit);
}

/////////////////////////////////
/// GET RELATED USER PROJECTS ///
/////////////////////////////////

export const getUserProjects = async (authorizationToken) =>{

    //INIT
    let myInit = { 
        headers: {
            'Content-Type' : 'application/json',
            Authorization: authorizationToken ? authorizationToken : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //CALL API
    return API.get("mpCoreRestApi", "/core/projects", myInit);

}

//NEW VERSION WITH BATCH
export const getProjectsByBatch = async (
    projectIds,
    tempToken
) =>{

    //CREATE ID STRING LIST
    const idStringList = projectIds.toString()

    //CALL INIT
    let myInit = { 
        queryStringParameters: {
            "projectIds": idStringList,
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: tempToken ? 
                    tempToken 
                : 
                    `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //GET ITEM
    return await API.get("mpCoreRestApi", "/core/projects/batchget", myInit);
}

/////////////////////////////////////////////////////////////////////
/// GET WBS PER PROJECT - ! FOR THE MOMENT PASS ONLY ON PROJECT ! ///
/////////////////////////////////////////////////////////////////////

export const getProjectWbs = async (
    projects,
    authorizationToken
) =>{

    //INIT
    let myInit = { 
        queryStringParameters: {
            "projects": JSON.stringify(projects)
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: authorizationToken ? authorizationToken : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }

    //CALL API
    return API.get("mpCoreRestApi", "/core/project/wbs", myInit)
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
/// USERS /////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////

//NEW VERSION WITH BATCH
export const getUsersByBatch = async (
    userIds,
    tempToken
) =>{

    //CREATE ID STRING LIST
    const idStringList = userIds.toString()

    //CALL INIT
    let myInit = { 
        queryStringParameters: {
            "userIds": idStringList,
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: tempToken ? 
                    tempToken 
                : 
                    `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        }
    }

    //GET ITEM
    return await API.get("mpCoreRestApi", "/core/user/batchget", myInit);
}