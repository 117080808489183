// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { picTELProjectCharter1, picTELProjectCharter2 } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `
## Description

Every user has an easy and quick access to the project charter. Only
accessible at project level, the pill KPI next to the project title
displays the category of your project.

![picTELProjectCharter1](${picTELProjectCharter1} "" )

![picTELProjectCharter2](${picTELProjectCharter2} "" )

The big component displays all the important information about the
project:

> **1**. The list of organizations you have access to start and end dates of the project with the number of days spent
    (advancement in the circle) and remaining (in the bubble)   
**2**.  Category: calculated thanks to the fields Strategic impact, Duration
    of the project (start and end dates), team size, Stakeholder size
    and Budget. The different categories are: Bronze, Silver and Gold   
**3**. Team size: number of persons in the project team         
**4**.  Stakeholder size: number of external stakeholders involved
    (excluding team project)   
**5**. Budget: total estimated cost of the project     
**6**. Strategic impact of the project on the company   
**7**. Targets and objectives of the project          
**8**. Button to switch to the calculation details of the project category
    see point 15            
**9**. Project title      
**10**. Project leader          
**11**. Department where the project belongs         
**12**. Type of project          
**13**. Project phase          
**14**. Details of the project: Description, Scope, Context & Objectives,
    Assumptions & Constraints. Click on the sheet to display the text.      
**15**. The detailed Card for the calculation of the project Category      

Only Organization and Project mega users can edit the charter.

## Process

Open the big component > click on edit button > fill the fields of the
form. There are no mandatory fields.

    WARNING: The project title is managed in Core application.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;