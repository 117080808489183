import { CvtButton, CvtModal, CvtSelect } from "@mi-gso/cvt";
import _ from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { multipleMutateGraphql } from "../../../00-App/02-Backend/AppBackendCommon";
import { getProjectWbs } from "../../../00-App/02-Backend/AppBackendCore";
import {selectionNestedWbsData} from "../../../../00-Core/Selection";
import { WBS_SEARCH_PATH_FULL } from "../00-Helpers/WbsFunctions";

////////////////////////////
/// WBS MOVE ITEMS MODAL ///
////////////////////////////


export default memo(function WbsMoveItemsModal({
    itemsArray,
    tableName,
    appDispatcher,
    onCancelClicked,
    projectList,
    currentProjectWbsList,
    projectId,
    wbsId,
    selectedOrganizationId,
    graphqlUpdateQuery,
    // ARRAY OF STRINGS OF PROPERTIES TO DELETE BEFORE UPDATING BACK-END
    propertiesToDelete,
    isInPortfolio,
}) {

    //////////////////////////////
    /// STATE ////////////////////
    //////////////////////////////

    const initialState = useMemo(() => {

        let selectProjectId = isInPortfolio ? itemsArray[0].projectId : projectId;
        // let selectWbsId = isInPortfolio ? '' : wbsId ?? itemsArray[0].wbsId;
        let selectWbsId = isInPortfolio ? '' : wbsId ?? '';

        let returnObj = {
            selectedProject: selectProjectId,
            selectedWbs: selectWbsId,
            loadingWbs: false,
            loadingMove: false,
            wbsLists: {}
        };

        if(currentProjectWbsList?.length > 0 && projectId) {
            returnObj.wbsLists = {
                [projectId]: currentProjectWbsList,
            }
        };

        return returnObj;
    }, [currentProjectWbsList, isInPortfolio, itemsArray, projectId, wbsId]);

    const [state, setState] = useState(initialState);
    //////////////////////////////
    /// MEMO /////////////////////
    //////////////////////////////

    // GET PROJECTS THAT BELONG TO THE CURRENT ORG ONLY
    const currentOrgProjects = useMemo(() => {
        return isInPortfolio ? projectList : projectList.filter((project) => project.coreDataOrganizationCoreDataProjectId === selectedOrganizationId);
    }, [isInPortfolio, projectList, selectedOrganizationId]);

    const nestedWbsData = useMemo(() => {
        if(state.selectedWbs!=="") {
            return selectionNestedWbsData(
                state.wbsLists[state.selectedProject],
                state.selectedProject,
                selectedOrganizationId
              );
        }
        return [];
    }, [selectedOrganizationId, state.selectedProject, state.selectedWbs, state.wbsLists]);

    ///////////////////////////////////
    /// FUNCTIONS /////////////////////
    ///////////////////////////////////

    // FETCH WBS LIST FOR A SPECIFIC PROJECT
    const fetchWbsList = useCallback(async (projectIdToFetch) => {

        // SET LOADING
        setState((prevState) => ({...prevState, loadingWbs: true}));

        try {

            // FETCH
            const wbsListFetched = await getProjectWbs([
                        {
                            organizationId: isInPortfolio ? projectList.find((project) => project.id === projectIdToFetch).coreDataOrganizationCoreDataProjectId : selectedOrganizationId,
                            projectId: projectIdToFetch,
                        }
                    ]);


            // UPDATE STATE
            setState((prevState) => {
                return {
                    ...prevState,
                    wbsLists: {
                        ...prevState.wbsLists,
                        [projectIdToFetch]: JSON.parse(wbsListFetched.body),
                    },
                    loadingWbs: false,
                };
            }, []);

            // RETURN TRUE IF EVERYTHING WENT OK.
            return true;


        } catch (error) {

            console.log("Error while fetching wbsList: ", error);

            // SET LOADING
            setState((prevState) => ({...prevState, loadingWbs: false}));

            // RETURN FALSE
            return false;
        }


    }, [isInPortfolio, projectList, selectedOrganizationId]);

    // HANDLE SELECT CHANGE (PROJECT/WBS)
    const handleChange = useCallback((e) => {

        // GET KEY AND VALUE
        let {name, value} = e.target;

        // IF SELECTING A PROJECT
        if(name === "selectedProject") {
            // TRY TO FIND THE PROJECT IN THE STATES WBS LIST
            let stateWbsList = state.wbsLists[value] ?? null;

            // IF DIDN'T FIND, THEN CALL FETCH WBS LIST FUNCTION
            if(!stateWbsList) {

                // FETCH AND GET BOOL AS RETURN VALUE
                let success = fetchWbsList(value);

                // IF EVERYTHING OK, SET NEW STATE
                if(success) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            selectedProject: value,
                            selectedWbs: '',
                        }
                    });
                }

            }
            else {
                // ELSE, JUST SET THE STATE
                setState((prevState) => {
                    return {
                        ...prevState,
                        selectedProject: value,
                        selectedWbs: '',
                    }
                });
            }
        }
        // ELSE SELECT WBS, JUST NORMAL BEHAVIOR
        else {
            setState((prevState) => {
                return {
                    ...prevState,
                    [name]: value,
                }
            })
        }

    }, [fetchWbsList, state.wbsLists]);

    // HANDLE CONFIRM MOVE ITEMS
    const handleMoveSubmit = useCallback((e) => {
        e.preventDefault();

        // SET LOADING MOVE
        setState((prevState) => ({...prevState, loadingMove: true}));

        try {

            let itemsToUpdate = _.cloneDeep(itemsArray);

            // INIT NEW PATH
            let newPath = projectList.find((project) => project.id === state.selectedProject)?.name + " > " ?? "";

            // DEFINE NEW PATH

            // NEED TO USE SEARCH ALGORITHM
            const pathArray = WBS_SEARCH_PATH_FULL(nestedWbsData, state.selectedWbs).reverse();

            if(pathArray.length > 0) {
                 for(let i = 0, len = pathArray.length; i < len; i++) {
                     newPath += pathArray[i].name + (i === len - 1 ? "" : " > ");
                 }
            }

            // LOOP ITEMS
            for(let item of itemsToUpdate) {
                // UPDATE PROJECT ID
                item.projectId = state.selectedProject;
                // UPDATE WBS ID
                item.wbsId = state.selectedWbs;

                // UPDATE PATH IF HAD ONE PREVIOUSLY
                if(item.path) {
                    item.path = newPath;
                }

                // LOOP PROPERTIES TO DELETE
                for(let property of propertiesToDelete) {
                    delete item[property];
                }
            }
      
            // UPDATE APP DATA
            appDispatcher({
                type: 'CONFIRM_MOVE_MODAL',
                source: tableName,
                objects: itemsToUpdate,
            });

            // UPDATE BACK END
            multipleMutateGraphql(
                graphqlUpdateQuery,
                itemsToUpdate,
                appDispatcher,
            );

        } catch (error) {
            console.log("Something bad happened while moving the items: ", error);

            setState((prevState) => {
                return {
                    ...prevState,
                    loadingMove: false,
                }
            });
        }


    }, [appDispatcher, graphqlUpdateQuery, itemsArray, nestedWbsData, projectList, propertiesToDelete, state.selectedProject, state.selectedWbs, tableName]);


    // IF IN PORTFOLIO, FETCH INITIAL PROJECT WBS LIST
    useEffect(() => {
        if(state.selectedProject && !state.wbsLists[state.selectedProject]) {
            fetchWbsList(state.selectedProject);
        }
    }, [fetchWbsList, state.selectedProject, state.wbsLists]);
    ///////////////////////////////////
    /// RENDER ////////////////////////
    ///////////////////////////////////

    return (
        <CvtModal
            title="Move to"
            icon="low_priority"
            resetFunction={onCancelClicked}
        >
            {/* BODY */}
            <div className="modal-body flexColStartStart modalBody">

                {/* PROJECT SELECT */}
                <CvtSelect
                    selectBlockCss="flexStartCenter modalGroup"
                    selectFontSize="14px"
                    icon="widgets"
                    legend="Project"
                    legendWidth="150px"
                    name="selectedProject"
                    value={state.selectedProject}
                    listValue="id"
                    listName="name"
                    list={currentOrgProjects}
                    disabled={state.organizationId === ""}
                    onChange={handleChange}
                />


                {/* WBS SELECT */}
                <CvtSelect
                    selectBlockCss="flexStartCenter modalGroup"
                    selectFontSize="14px"
                    icon="dns"
                    legend="WBS"
                    legendWidth="150px"
                    name="selectedWbs"
                    selectPlaceHolder="Select a wbs"
                    value={state.selectedWbs}
                    listValue="id"
                    listName="name"
                    disabled={state.loadingWbs}
                    list={state.wbsLists[state.selectedProject] ?? []}
                    onChange={handleChange}
                    loading={state.loadingWbs}
                />

            </div>

            {/* FOOTER */}
            <div className="modal-footer modalBlockBg flexBetweenCenter">

                {/* MOVE BTN */}
                <CvtButton
                    txt={`Move item${itemsArray.length > 1 ? 's' : ''}`}
                    color="blue"
                    // DISABLED IF LOADING/HAVENT SELECTED A WBS ID/ SAME WBSID (NO CHANGE)
                    disabled={state.loadingWbs || state.loadingMove || !state.selectedWbs || state.selectedWbs === wbsId}
                    icon="move_down"
                    iconFontSize="20px"
                    fontWeight="500"
                    fontSize="14px"
                    handleSubmit={handleMoveSubmit}
                    loader={state.loadingMove}
                    needConfirmation={true}
                    confirmCss="modalButtonSubmitAlert"
                />

                {/* CANCEL BTN */}
                <CvtButton
                    color="gray"
                    txt="Cancel"
                    handleSubmit={onCancelClicked}
                    disabled={state.loadingMove}
                />


            </div>

        </CvtModal>
    )
});