// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { picTELRisknOps1, picTELRisknOps2, picTELRisknOps3, picTELRisknOps4, picTELRisknOps5, picTELRisknOps6 } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `

# Presentation

In this column, you are able to follow all risks and opportunities
identified with their action plan.

The pill KPI displays:

> **1**.  Number of risks in the branch  
**2**.  Number of actions related to risk (displayed only if there are no
    late action and at least one risk action created)  
**3**.  Number of late actions related to risk (displayed only if there are
    late action)

![picTELRisknOps1](${picTELRisknOps1} "" )


  The Risks or Opportunities views are divided in two parts, on the left
the risk or opportunities matrix, on the right the list of items (with
the ID, the name, the strategy, the responsible, etc.).

Below, the view of the risks (the opportunity one is exactly the same)

![picTELRisknOps2](${picTELRisknOps2} "" )

> **1**.  Add a risk  
**2**.  Show only the items of the branch  
**3**.  Risk's matrix  
**4**.  The assessment of the risk (you can select the current, the initial
    or the Target)   
**5**.  Button to expend the matrix  
**6**.  The list of risks (by default, the occurred ones are not displayed
    unless there are only occurred risks in the list)     
**7**.  Filters on the status    
**8**.  Search bar        
**9**.  Number of items displayed (dynamic with filters)      
**10**. Button to select all items      
**11**. Item ID          
**12**. Item name           
**13**. Icon to indicate the item is coming from a lower branch in the WBS.
    By overing it, the branch is displayed          
**14**. "New" icon if the action has been created in the past 7 days    
**15**. The strategy on the risk: Avoid, Transfer, Mitigate, Accept       
**16**. Current assessment (calculated according to the current impact and
    probability selected)       
**17**. Target Assessment (calculated according to the target impact and
    probability selected)         
**18**. Item responsible          

If you click on a risk, a specific view with more details opens:   

![picTELRisknOps3](${picTELRisknOps3} "" )

> **1**.  Return to the risks or opportunities list  
**2**.  Edit item  
**3**.  Duplicate item   
**4**.  Delete item    
**5**.  Move item in an other branch or project      
**6**.  Responsible of the item        
**7**.  Item's ID         
**8**.  Item's name        
**9**.  Description: the description of the risk/opportunity        
**10**. The comments that have been made on this risk/opportunity (any user
    can add one)         
**11**. List of all the events on this item (creation date, created by, all
    modifications of the fields of the item, etc.)        
**12**. The action plan of the risk/opportunity        
**13**. Risk matrix with a vision on the initial, current and target
    assessment          
**14**. Expend the big component to see it in big screen        
**15**. Close the big component

***

# Add an item 
## Description

Any user can add Rik & Opps (R&O) item. As soon as an item is added, it
will be visible in the table and on the map.

## Process

Click on the pill KPI of the WBS where you want to add your item: the
big component opens. You have two options to create a new R&O item:

> **1**.  In the component's sidebar, click on the "add" button (+)     
**2**.  Or click on "Create a risk/opportunity" button at the bottom of the
    list

Once you have clicked on one of these buttons, fill at least the
mandatory fields and click on "Save" at the top right corner.

Here are the different fields:

-   **Name** (mandatory): the name of your item
-   **Responsible** (mandatory): the person in charge of this item
-   **Status** (mandatory): status of the R&O (see upper)
-   **Strategy**
    -   Avoid
    -   Transfer
    -   Mitigate
    -   Accept
-   **Initial assessment** (mandatory): assess impact and probability
-   **Current assessment**: assess impact and probability
-   **Target assessment**: assess impact and probability
-   **Description**
    -   Short description
    -   Complete description
    -   Cause
    -   Effect

***

# Edit an item

## Description

To modify one or several fields of an item, you can edit it.

    WARNING: OMU and PMU can edit all R&O items. A PU can only edit the R&O he
has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the edit button (pencil) of the sidebar >
Edit form opens > Modify the fields you want and click on "save".

If you want to discard the changes, click on the close button or outside
the edit form and click a second time to confirm.

***

# Duplicate an item

## Description

You can duplicate an item if you want. It will be duplicated in the same
branch (if you want to move it to another branch/project, see the "move
an item" section).

    WARNING: OMU and PMU can duplicate all actions items. A PU can only duplicate the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the duplicate button of the sidebar > Click a
second time to confirm > item is created with "Duplicate" at the
beginning of the title

***

# Delete an item

## Description

Items can be deleted if necessary

    WARNING: OMU and PMU can delete all R&O items. A PU can only delete the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the delete button (bin) of the sidebar >
Click a second time to confirm

***

# Move an item

## Description

If you have created an item in the wrong WBS branch, don't worry! You
can move it to the right one. You are even able to move it in a
different project that belongs to the same organization. In brief, you
can move an item anywhere within an organization (as long as you have
access to the new project).

    WARNING: OMU and PMU can move all R&O items. A PU can only move the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the move button of the sidebar > Click a
second time to confirm

***

# Display item of the current branch

## Description

By default, all the items are consolidated on the parent WBS branches:
on a WBS branch which have child, you see the items created on the WBS
branch but also the items created on the children. It is possible to
unable that and hide the children's items.

## Process

In the big component side bar, click on "only this branch's items"
button (second one of the sidebar). This will apply only on this branch.

If you want to apply this feature on all your WBS branches, see the
"General Sidebar" section.

***

# Filter items according to the status

## Description

It is possible to filter the items visible in the list by status. By
default, the visible items have the status "Opened".

## Process

To filter your items according to the status, click on the status you
want to filter (you can click on several). The status not selected are
grayed out.

To remove your filter(s), click on the unfilter icon. If there is not
this icon, it means you are not filtered and are on the default view
(only "Opened" status).

***

# Search for items

## Description

If you want to find a precise item, you can use the search bar. The
search is done on the item name only.

## Process

In the search bar, type a key words and the items are filtered
automatically.

***

# Change the displayed assessment in the risk matrix features

## Description

All risks and opportunities are displayed in the matrix, according to
their current criticity by default. But you can display another type:
the initial of target criticity.

    WARNING: Any user can use this feature.

## Process

In the risk matrix, click on the arrow next to "Current" > select the
type of criticity you want.

![picTELRisknOps4](${picTELRisknOps4} "" )

***

# Update the criticity of a risk in the risk matrix

## Description

You can update the criticity of a risk directly in the matrix.

    WARNING: OMU and PMU can update all R&O items. A PU can only update the items he has created or he is owner.

## Process

To update the criticity:

> **1**.  Select the type of criticity you want to update        
**2**.  Drag and drop the card of the risk to the new assessment

![picTELRisknOps5](${picTELRisknOps5} "" )

***

# Display the actions list of a risk

## Description

You can have a direct access to the action plan of a risk from the risk
matrix.

    WARNING: Any user can use this feature.

## Process

To open easily the action plan of a risk:

> **1**.  Click on the risk in the risk matrix         
**2**.  On the right, the list of risks switched to the action plan with the
    list of all actions linked to this risk. All action features are
    also available in the list (delete, duplicate, move), but you can't
    open the action here. All the actions are available in the column
    Action, you open and update it in the Action column.

![picTELRisknOps6](${picTELRisknOps6} "" )

***

# Open the detailed view

## Description

If you click on an item of the list, the Card view opens and you will
have more details (cf pictures in intro).

    WARNING : Any user can open the detailed view. 
>   

    WARNING: From this view, if you have the rights, you can edit the description, add comments, create an action plan 
    (actions will be visible also in the action column and identified by an icon) and change the assessments of the risk.

## Process

In the list of the big component, click on the item for which you want
to see more details > the detailed view opens

***

# Edit the description from detailed view

## Description

In the detailed view, you can directly edit the description (without
entering the edit mode).

    WARNING: OMU and PMU can edit the description of all items. A PU can only edit the item he has created.

## Process

In the list of the big component, click on the item for which you want
to change the description > the detailed view opens > Click in the
description box > Update the description > click outside of the box to
save the changes.

    WARNING: It is not possible to discard the changes

***

# Add a comment

## Description

In the detailed view, you can add comment(s) on the item.

    WARNING: Any user can add a comment

## Process

In the list of the big component, click on the item for which you want
to add a comment > the detailed view opens > Click on "Comments" >
Type your comment in the "New comment" zone > click on "Validate" to
publish your comment.

***

# Edit a comment

## Description

In the detailed view, you can edit comment(s) you have created.

## Process

In the list of the big component, click on the item for which you want
to modify your comment > the detailed view opens > Click on "Comments" > Click on the pencil button to edit your comment > make the changes
you want > Click on validate to save the changes.

***

# Delete a comment

## Description

In the detailed view, you can delete comment(s) you have created.

## Process

In the list of the big component, click on the item for which you want
to delete your comment > the detailed view opens > Click on "Comments" > Click on the bin button to delete your comment > click a second time
to validate.

***

# View all the events that occurred on the item

## Description

In the detailed view, you can see all the events that occurred on the
item: creation date, created by, all modifications of the fields of the
item (responsible, description, etc.)

## Process

In the list of the big component, click on the item for which you want
to see the events > the detailed view opens > Click on "Events"

***

# Create an action plan

## Description

You can add action in the action plan of the risk. These actions created
here will also be visible in the action column and identified by an
icon.

From the action plan, you are able to edit/duplicate/delete actions (see
action section for more details).

    WARNING: Theses features are available only if I am the creator of the responsible of the risk, or if I am a OMU or PMU.

## Process

In the list of the big component, click on the item for which you want
to add an action plan > the detailed view opens > Click on "Create
action" > the edit form action opens > fill at least the mandatory
fields (see action section)
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;