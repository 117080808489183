import React from 'react'
import { CvtButton} from '@mi-gso/cvt'

//////////////////////////////////////////
/// ACTION CHART COMMEND BAR COMPONENT ///
//////////////////////////////////////////

export default function ActionGraphCommand({
    forecastedWeek,
    onChangeForecastedWeekCount
}) {


    return (
        <div
            className="bigComponentCommandBarBlock flexBetweenCenter"
            key="action_header_forcasted_week_filter"
        >
            {/* TITLE + NUMBER */}
            <div className="bigComponentCommandBarBlockLegend">
                Forecasted week:
                <span className="bigComponentCommandBarBlockLegendHighlight">
                {forecastedWeek}
                </span>
            </div>

            <CvtButton
                color="white"
                shape="noShape"
                size="tiny"
                margin="0px -7px 0px 0px"
                icon="remove"
                handleSubmit={onChangeForecastedWeekCount}
                handleSubmitOptions={false}
            />

            <CvtButton
                color="white"
                shape="noShape"
                size="tiny"
                icon="add"
                handleSubmit={onChangeForecastedWeekCount}
                handleSubmitOptions={true}
            />
        </div>
  )
}
