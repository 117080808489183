import React, { useEffect, useMemo, useState } from 'react'
import { 
    CvtButton, 
    CvtReminder 
} from '@mi-gso/cvt'
import { envVarManagement } from '../../../../envVarManagement'

// SUMMARY
// 01 - AuthAuthenticatorForm
// 02 - AuthAuthenticatorFormTitle
// 03 - AuthAuthenticatorTitle
// 04 - AuthAuthenticatorLink

///////////////////////////////////////////////////////////////////////////////////////
/// Authenticator FORMS ///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export function AuthAuthenticatorForm({
    disabled,
    loader,
    text,
    hiddeButton,
    handleRequestAccount,
    handleBackToSignIn,
    onSubmit,
    onSubmitOptions,
    handleSignUp,
    errorMessage,
    children
}){
    //GET ERROR HEIGHT
    const [errorHeight, setErrorHeight] = useState(0)
    useEffect(() => {

        //INIT
        let height = 0;

        //isError
        if(errorMessage && errorMessage !== ""){
            const errorElement = document.querySelector(".alertContentDanger");
            height = errorElement.offsetHeight
        }

        //RETURN
        setErrorHeight(height)
    }, [errorMessage])

    //RENDER
    return(
        <form onSubmit={(e) => onSubmit(e, onSubmitOptions)}>

            {/* BLOCK */}
            <div
                className='appAuthenticatorInputBlock'
                style={{
                    maxHeight: "calc(100vh - " + (300 + errorHeight) + "px)"
                }}
            >
                {children}
            </div>

            {/* BUTTON */}
            <AuthAuthenticatorButtons
                disabled={disabled}
                loader={loader}
                text={text}
                hiddeButton={hiddeButton}
                handleSignUp={handleSignUp}
                handleRequestAccount={handleRequestAccount}
                handleBackToSignIn={handleBackToSignIn}
            />
        </form>
    )
}

///////////////////////////////////////////////////////////////////////////////////////
/// Authenticator FORMS TITLE /////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export function AuthAuthenticatorFormTitle({
    icon,
    text
}){
    return(
        <div
            className='flexStartCenter'
            style={{
                padding:"10px 20px",
                fontWeight:500,
                backgroundColor: "var(--border-color-gray-00-5)",
                borderBottom: "1px solid var(--border-color-gray-0)"
            }}
        >
            <span
                className="material-icons"
                style={{
                    color:"var(--color-migso-blue)",
                    marginRight:"10px",

                }}
            >
                {icon}
            </span>
            {text}
        </div>
    )
}

///////////////////////////////////////////////////////////////////////////////////////
/// Authenticator FORMS SUB TITLE /////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export function AuthAuthenticatorFormSubTitle({
    text
}){
    return(
        <div
            className='flexStartStart'
            style={{
                margin:"20px 20px 0px 20px",
                padding:"10px ",
                fontWeight:400,
                border: "1px solid var(--border-color-gray-0)",
                borderRadius:"6px",
                fontStyle:"italic",
                color:"var(--color-text-3)",
                backgroundColor:"var(--border-color-gray-00-5)"
            }}
        >
            <span
                className="material-icons"
                style={{
                    color:"var(--border-color-gray-2)",
                    marginRight:"10px",

                }}
            >
                info
            </span>
            {text}
        </div>
    )
}

//////////////////////////////////////////////////////////////////////////////
/// AUTHENTICATOR TITLE + ERROR MESSAGE //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export function AuthAuthenticatorTitle({
    title,
    errorMessage,
    icon
}){
    return(
        <React.Fragment>

            {/* TITLE */}
            <div className='appAuthenticatorTitle flexStartCenter'>

                {/* ICON */}
                {icon ?
                    <span
                        className="material-icons"
                        style={{
                            marginRight:"8px",
                            color:"var(--color-migso-blue)",
                            fontSize:"28px",
                            opacity:"0.8"
                        }}
                    >
                        {icon}
                    </span>
                :null}

                {/* TITLE */}
                {title}
            </div>

            {/* ERROR MESSAGE */}
            {errorMessage ?
                <div
                    className='structureBlockInfo flexBetweenCenter alertContentDanger alertContent'
                    style={{
                        boxShadow:"1px 1px 3px 0px rgba(0,0,0,0.2)",
                        cursor:"pointer",
                        marginLeft:"0px",
                        marginRight:"0px",
                        marginTop:"12px"
                    }}
                >
                    <CvtReminder
                        icon="info"
                        message={errorMessage}
                    />
                </div>
            :null}
        </React.Fragment>
    )
}

///////////////////////////////////////////////////////////////////////////////////////
/// Authenticator BUTTONS /////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export function AuthAuthenticatorButtons({
    disabled,
    loader,
    text,
    hiddeButton,
    handleBackToSignIn,
    handleRequestAccount,
    handleSignUp
}){

    //ENV VAR
    const envSignUp = useMemo(() => envVarManagement("envSignUp"), [])

    //RENDER
    return(
        <div 
            className='flexStartCenter' 
            style={{minHeight:"36px"}}
        >

            {/* SUBMIT BUTTON */}
            {!hiddeButton || true?
                <CvtButton
                    color="blue"
                    type="submit"
                    txt={text}
                    disabled={disabled}
                    loader={loader}
                />
            :null}

            {/* BACK TO SIGN IN */}
            {handleBackToSignIn ?
                <CvtButton
                    color="gray"
                    customHover="blue"
                    css="modalButtonSubmit"
                    txt="Back to sign in"
                    margin="0px 0px 0px 15px"
                    outline
                    handleSubmit={handleBackToSignIn}
                />
            :null}

            {/* SIGN UP */}
            {handleRequestAccount ?
                <CvtButton
                    color="gray"
                    customHover="blue"
                    css="modalButtonSubmit"
                    txt={
                        <>
                            <span style={{fontWeight:400}}>No account? </span>
                            {envSignUp ? " Sign Up" : " Request account"}
                        </>
                    }
                    margin="0px 0px 0px 15px"
                    outline
                    handleSubmit={envSignUp ? handleSignUp : handleRequestAccount}
                />
            :null}
        </div>
    )
}

///////////////////////////////////////////////////////////////////////////////////////
/// Authenticator FORMS ///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export function AuthAuthenticatorLink({
    legend,
    text,
    handleClick,
}){
    return(
        <div className="appAuthenticatorQuestion">
            {legend}
            <a
                href='/#'
                onClick={handleClick}
                style={{marginLeft:"5px"}}
            >
                {text}
            </a>
        </div>
    )
}
