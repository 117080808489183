import { CvtInput } from "@mi-gso/cvt";
import React, { memo, useCallback } from "react";

//USER
import { USERS_MANAGEMENT_PAGE_COUNT } from "../00-Helpers/UsersConstants";
import { CvtPagination } from "@mi-gso/cvt";
/////////////////////////////
/// USER FOOTER COMPONENT ///
/////////////////////////////

export default memo(function UsersManagementCommandBar({
    currentPage,
    listLength,
    totalPageCount,
    setParentState,
    searchInput,
    handleSearch,
    customSearchBar,
}) {
    //////////////////////////////////////////////////////////
    /// PAGINATION ///////////////////////////////////////////
    //////////////////////////////////////////////////////////

    // HANDLE CHANGE OF PAGE
    const handleChangePage = useCallback(
        (newPage) => {
            
                setParentState((prevState) => {
                    return {
                        ...prevState,
                        currentPage: newPage,
                    };
                });
            
        },
        [setParentState]
    );

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <div className="flexBetweenCenter ProjectUserManagementCommandBar">
            {/* SEARCH BAR */}
            {customSearchBar ? (
                customSearchBar
            ) : (
                <CvtInput
                    placeholder="Search"
                    inputWidthFull
                    inputBlockCss="flexBetweenCenter modalOptionSubBlock"
                    inputPadding="4px 8px"
                    inputFontSize="14px"
                    inputBlockPadding="0px"
                    type="text"
                    name="name"
                    value={searchInput}
                    onChange={handleSearch}
                />
            )}

            {/* PAGE AND TOT */}
            <div className="flexStartCenter" style={{ marginLeft: "20px" }}>
                {/* PREVIOUS AND NEXT BUTTONS */}
                {totalPageCount > 1 ? (
                    (
                        <CvtPagination 
                            currentPage={currentPage}
                            itemsCount={listLength}
                            itemsPerPage={USERS_MANAGEMENT_PAGE_COUNT}
                            onUpdateCurrentPage={handleChangePage}
                            displayTotalPage={false}
                        />
                    )
                ) : null}

                {/* PER PAGE AND TOTAL COUNT */}
                <span className="ProjectUserManagementCommandBarLeft">
                    {listLength} user{listLength > 1 ? "s" : ""}
                </span>
            </div>
        </div>
    );
});
