import React, {useMemo} from 'react'
import QRCode from 'qrcode.react';
import PhoneInput from 'react-phone-number-input'

//AUTH MINI COMPONENT
import {
    AuthAuthenticatorTitle,
    AuthAuthenticatorForm,
    AuthAuthenticatorFormTitle,
    AuthAuthenticatorFormSubTitle
} from './AuthAuthenticatorMiniComponents'

//CORE
import { CvtButton, CvtSpinnerGrow, CvtInput, CvtCheckbox } from '@mi-gso/cvt'
import { envVarManagement } from '../../../../envVarManagement';

////////////////////////////////////////////////////////////////////////////////////////////
/// EMAIL VERIFICATION COMPONENT ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

export default function AuthAuthenticatorMfaSetUp({
    options,
    username,
    email,
    onChangePreferredMFA,
    errorMessage,
    handleBackToSignIn,
    handleVerifyTotpToken,
    onChangePhoneNumber,
    handleSendCodeForPhoneVerification,
    handleVerifyPhoneNumber,
    onChange,
    handleBackToUpdatePhoneNumber,
    handleConfirmSignInWithoutMfa
}){

    //ENVIRONEMENT
    const env = useMemo(()=> envVarManagement("env"),[])
    const envMfaMandatory = useMemo(()=> envVarManagement("envMfaMandatory"),[])

    //CREATE QRCODE
    const qrCode = useMemo(()=>{
        let code = ""
        if(options.qrCode && options.qrCode !== ""){
            let issuer = "Clayverest-" + env + " (" + email + ")"
            code = "otpauth://totp/AWSCognito:"+ username + "?secret=" + options.qrCode + "&issuer=" + issuer;
        }
        return code
    },[email, env, options.qrCode, username])

    //RETURN
    return(
        <React.Fragment>

            {/* TITLE & ERROR */}
            <AuthAuthenticatorTitle
                title="Security Check"
                errorMessage={errorMessage}
                icon="admin_panel_settings"
            />

            {/* INPUTS BLOCK */}
            <AuthAuthenticatorForm
                disabled={(options.code === "" && (options.preferredMFA === "TOTP" || options.preferredMFA === "SMS")) || (envMfaMandatory && options.code === "")}
                loader={options.loader}
                text="Verify MFA"
                handleBackToSignIn={handleBackToSignIn}
                onSubmit={options.preferredMFA === "TOTP" ? handleVerifyTotpToken : options.preferredMFA === "SMS" ? handleVerifyPhoneNumber : handleConfirmSignInWithoutMfa}
                hiddeButton={options.code === "" && (options.preferredMFA === "TOTP" || options.preferredMFA === "SMS")}
                errorMessage={errorMessage}
            >

                {/* FORM TITLE */}
                <AuthAuthenticatorFormTitle
                    icon="verified_user"
                    text="Set up your perferred MFA method"
                />

                {/* SUB EXPLANATION */}
                <AuthAuthenticatorFormSubTitle
                    text="To continue using Clayverest we require a second authentication check, please choose which one you want (MFA: Multi Factor Authentication)"
                />

                {/* USERNAME */}
                <div style={{padding:"15px 20px 0px 20px",fontWeight:400}}>
                    <span style={{fontWeight:500, marginRight:"10px"}}>User:</span> {email}
                </div>

                {/* No MFA */}
                {envMfaMandatory ? null :
                    <CvtCheckbox
                        title="NO MFA"
                        value={options.preferredMFA === "" ? true : false}
                        name="preferredMFA"
                        width="100%"
                        padding="15px 20px 10px 20px"
                        handleFunction={onChangePreferredMFA}
                        handleFunctionOption="NOMFA"
                    />
                }

                {/* SMS */}
                <CvtCheckbox
                    title="SMS"
                    value={options.preferredMFA === "SMS" ? true : false}
                    name="preferredMFA"
                    width="100%"
                    padding="10px 20px 10px 20px"
                    handleFunction={onChangePreferredMFA}
                    handleFunctionOption="SMS"
                />

                {options.preferredMFA === "SMS" ?
                    <div
                        style={{
                            margin:"0px 20px 10px 20px",
                            backgroundColor: "white",
                            border: "1px solid var(--border-color-gray-0)",
                            borderRadius: "6px",
                            padding:"10px 0px 0px 0px"
                        }}
                    >

                        {/* PHONE NUMBER */}
                        {!options.codeSent ?
                            <React.Fragment>
                                <div style={{margin:"0px 20px 8px 20px", fontWeight:500}}>
                                    Phone Number
                                </div>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={options.phoneNumber}
                                    onChange={onChangePhoneNumber}
                                />
                            </React.Fragment>
                        :null}

                        {/* IF CODE SENT */}
                        {!options.codeSent ?
                            <CvtButton
                                color="green"
                                txt="Send the code"
                                margin="0px 20px 20px 20px"
                                handleSubmit={handleSendCodeForPhoneVerification}
                                handleSubmitOptions="phone_number"
                                loader={options.codeLoader}
                            />
                        :
                            <React.Fragment>

                                {/* CODE */}
                                <CvtInput
                                    legend="SMS Code"
                                    placeholder={"Enter code sent to " + options.phoneNumber}
                                    inputBlockCss="modalOptionSubBlock"
                                    inputBlockPadding="0px 20px 5px 20px"
                                    inputCss="form-control appAuthenticatorInput"
                                    name="code"
                                    value={options.code}
                                    onChange={onChange}
                                    autoComplete="off"
                                />

                                {/* RESEND CODE */}
                                <div className="appAuthenticatorQuestion">
                                    <a
                                        href='/#'
                                        onClick={handleBackToUpdatePhoneNumber}
                                        style={{marginLeft:"5px"}}
                                    >
                                        Change Phone Number
                                    </a>
                                </div>
                            </React.Fragment>
                        }
                    </div>

                :null}

                {/* TOTP */}
                <CvtCheckbox
                    title="From App Authenticator"
                    value={options.preferredMFA === "TOTP" ? true : false}
                    name="preferredMFA"
                    width="100%"
                    padding="10px 20px 15px 20px"
                    handleFunction={onChangePreferredMFA}
                    handleFunctionOption="TOTP"
                />

                {/* DISPLAY TOTP SETUP */}
                {options.preferredMFA === "TOTP" ?

                    <div
                        style={{
                            margin:"0px 20px 20px 20px",
                            backgroundColor: "white",
                            border: "1px solid var(--border-color-gray-0)",
                            borderRadius: "6px",
                            padding:"10px 15px"
                        }}
                    >

                        {/* INFO */}
                        <div
                            style={{
                                color:"var(--color-text-3)",
                                fontSize:"13px",
                                fontStyle:"italic"
                            }}
                        >
                            Authenticate with a Time-based One-time Password from an authenticator app such as Authy or Google Authenticator.
                        </div>

                        {/* CODE */}
                        {options.qrCodeLoading ?
                            <div
                                className='flexStartCenter'
                                style={{
                                    fontWeight:500,
                                    marginTop:"10px"
                                }}
                            >
                                <CvtSpinnerGrow
                                    marginRight="10px"
                                    text="Qr Code Generation..."
                                />
                            </div>
                        :
                            <React.Fragment>

                                {/* TITLE */}
                                <div
                                    style={{
                                        fontWeight:500,
                                        margin:"5px 0px"
                                    }}
                                >
                                    Here your generated Qr Code
                                </div>

                                {/* QR CODE */}
                                <QRCode value={qrCode}/>

                                {/* CODE */}
                                <CvtInput
                                    legend="Code from your authenticator app"
                                    placeholder="Enter TOTP Code  "
                                    inputBlockCss="modalOptionSubBlock"
                                    inputBlockPadding="5px 0px "
                                    inputCss="form-control appAuthenticatorInput"
                                    name="code"
                                    value={options.code}
                                    onChange={onChange}
                                    autoComplete="off"
                                />
                            </React.Fragment>
                        }
                    </div>
                :null}

            </AuthAuthenticatorForm>
        </React.Fragment>
    )
}