//AMPLIFY
import {API} from 'aws-amplify'
import {Auth} from '@aws-amplify/auth'

import {authCleanUserDataCookie} from '../01-Auth/00-Functions/AuthSignOut'



///////////////////////////////////
// SUMMARY
// - getIdentityId
// - getReferenceDataAccess
// - getReferenceSignedUrl
// - deleteReferenceVersion
// - deleteVersionFollowingFromReference
///////////////////////////////////

// GET IDENTITY ID OF LOGGED USER
export const getIdentityId = async () => {
    return (await Auth.currentUserCredentials()).identityId;
}

//GET REFERENCE BY PROJECT ID + CATEGORY ///
export const getReferenceDataAccess = async (
    organizationId,
    projectId,
    category,
) => {

    //QUERY INIT PARAMETERS
    const myInit = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        },
        queryStringParameters: {
            projectId,
            organizationId,
            category,
            isGetSpecificReference: true,
        },
    };

    //GET RESULT
    const result = await API.get("mpApiDataMapping", "/item/access", myInit);

    //CLEAN COOKIE
    authCleanUserDataCookie();
    
    //RETURN
    return JSON.parse(result.body);
};

// GET REFERENCE SIGNED URL
export const getReferenceSignedUrl = async (
    organizationId,
    projectId,
    category,
    identityId,
    versionId,
) => {

    // INIT PARAMS
    const myInit = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        },
        queryStringParameters: {
            projectId,
            category,
            fileName: `${projectId}_${category}.json`,
            organizationId,
            versionId: versionId ?? null,
            identityId: identityId ?? await getIdentityId(),
            isGettingRef: true,
        },
    };

    //LAUNCH CALL
    const result = await API.get('mpApiDataMapping', '/item/signedUrl', myInit);

    // CLEAR COOKIES
    authCleanUserDataCookie();

    //RETURN
    return result.body;
}

// DELETE SPECIFIC VERSION OF TEMPORARY FILE
export const deleteReferenceVersion = async (
    fileName, 
    versionId, 
    projectId,
    organizationId,
    identityId,
) => {

    //INT PARAMS
    const myDeleteInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      queryStringParameters: {
        fileName: fileName,
        versionId: versionId,
        identityId: identityId ?? await getIdentityId(),
        projectId: projectId,
        organizationId: organizationId,
        isDeletingRef: true,
      },
    };   
  
    // API CALL
    const deleteResult = await API.get("mpApiDataMapping", "/item/deleteFileVersion", myDeleteInit);

    // CLEAR COOKIES
    authCleanUserDataCookie();

    // RETURN TRUE IF EVERYTHING WENT WELL, FALSE OTHERWISE.
    return deleteResult.isOk;
}

// REMOVE VERSION FOLLOWING FROM MAP DATA ACCESS ITEM
export const deleteVersionFollowingFromReference = async (
    mapDataAccessId,
    s3ReferenceFileName,
    versionId,
    projectId,
    organizationId,
) => {

    // INIT PARAMS
    const myInit = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
        },
        queryStringParameters: {
            mapDataAccessId: mapDataAccessId,
            isReference: true,
            updatedAt: new Date().toISOString(),
            deleteVersionId: versionId,
            projectId: projectId,
            organizationId: organizationId,
            s3ReferenceFileName: s3ReferenceFileName,
        }
    };

    // MAKE CALL TO REST API
    await API.get('mpApiDataMapping', '/item/updateDataAccess', myInit);

    // CLEAN USER COOKIES
    authCleanUserDataCookie();
}