import React from "react";

//CORE
import { CvtButton, CvtAvatar, CvtInput } from "@mi-gso/cvt";

//COMPONENT
export default function HeaderUserInfosEditColors({
  isEditMode,
  backgroundColor,
  name,
  onChange,
  handleUpdateBackgroundColor,
  loader,
  handleEdit,
}) {
  return (
    <React.Fragment>
      {/* SWITCH DISPLAY OR EDIT MODE --------------------------------------------*/}
      {isEditMode ? (
        <div className="flexBetweenCenter" style={{ width: "100%" }}>
          {/* INPUT MODAL */}
          <CvtInput
            type="color"
            legend="Background Color"
            inputBlockCss="flexStartCenter modalOptionSubBlock"
            inputBlockPadding="10px 20px 11px 20px"
            inputCss="form-control modalInputColor"
            name="backgroundColor"
            value={backgroundColor}
            onChange={onChange}
          />

          {/* BUTTON GROUP */}
          <div className="flexBetweenCenter" style={{ marginTop: "2px" }}>
            {/* SUBMIT */}
            <CvtButton
              color="green"
              loader={loader}
              icon="check"
              handleSubmit={handleUpdateBackgroundColor}
            />

            {/* CANCEL */}
            <CvtButton
              color="gray"
              outline
              customHover="red"
              icon="cancel"
              handleSubmit={handleEdit}
              handleSubmitOptions=""
              margin="0px 20px 0px 10px"
            />
          </div>
        </div>
      ) : (
        <div
          className="flexBetweenCenter"
          style={{
            padding: "5px 20px 5px 20px",
            width: "100%",
          }}
        >
          {/* NAME */}
          <div className="flexStartCenter">
            Background Color
            <CvtAvatar
              value={{
                label: name,
                backgroundColor: backgroundColor,
              }}
              margin="0px 10px 0px 10px"
              radius={15}
            />
          </div>

          {/* EDIT BUTTON */}
          <CvtButton
            color="gray"
            outline
            customHover="blue"
            icon="edit"
            handleSubmit={handleEdit}
            handleSubmitOptions="backgroundColor"
          />
        </div>
      )}

      {/* SEPERATOR */}
      <div className="modalSeparator"></div>
    </React.Fragment>
  );
}
