import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import { useMemo } from "react";
import { TooltipWrapper } from "react-tooltip";
import { GOV_FUNC_GET_REVIEW_LABEL } from "../../../04-Governance/00-Helpers/GovFunctions";
import { WBS_GET_DOMAIN_ICON } from "../../00-Helpers/ActionsFunctions";

export default function ActionTableLinkIcon({ link, projectData, onHoverTooltip, onOpenLinkItem }) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // GET DESTINATION ITEM
    const destinationItem = useMemo(() => {
        if (!link) {
            return null;
        }

        // GET ITEM FROM PROJECT DATA
        let item = FIND_OBJECT_ARRAY_ITEM(projectData[link.type], "id", link.destinationItemId);

        return item;
    }, [link, projectData]);

    // TOOLTIP TEXT
    const tooltipText = useMemo(() => {
        // CASE THREE DOTS ICON
        if (!link) {
            return "Open dependencies list";
        }

        // GET ITEM DISPLAY ID
        if (destinationItem && destinationItem.displayId) {
            return (
                destinationItem.displayId + " : " + (destinationItem.title ||
                destinationItem.action ||
                destinationItem.name)
            );
        }

        // IF GOVERNANCE, RETURN TEXT
        if (link.type === "govReview") {
            return GOV_FUNC_GET_REVIEW_LABEL(destinationItem);
        }
    }, [destinationItem, link]);

    //ACTION NAME FOR DATA-CY
    const dataCyActionName = useMemo(() => {
        // CASE THREE DOTS ICON
        if (!link) {
            return "Open dependencies list";
        }

        // GET ITEM DISPLAY ID
        if (destinationItem && destinationItem.displayId) {
            return (
                destinationItem.title ||
                destinationItem.action ||
                destinationItem.name
            );
        }

        // IF GOVERNANCE, RETURN TEXT
        if (link.type === "govReview") {
            return GOV_FUNC_GET_REVIEW_LABEL(destinationItem);
        }
    }, [destinationItem, link]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    // IF ITEM NOT FOUND, DON'T SHOW ICON
    if (link && !destinationItem) {
        return null;
    }

    // ITEM FOUND, SHOW ICON
    return (        
        <div
            className="actionTableItemLinkIcon actionTableItemLinkListItem material-icons"
            data-cy={"link-action-icon-" + dataCyActionName}
            id="linkTooltip"
            onClick={(e) => {
                onOpenLinkItem(e, link);
            }}
            style={{
                backgroundColor: "white",
                opacity: "1",
            }}
        > 
        <TooltipWrapper
            content={tooltipText}
            tooltipId="action-table-tooltip"    
        >
            
            {link
                ? WBS_GET_DOMAIN_ICON(link, destinationItem?.isRisk ? true : false)
                : "more_horiz"}
            
            </TooltipWrapper>
        </div>
    );
}
