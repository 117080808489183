import {Auth} from 'aws-amplify';
import Cookies from 'universal-cookie';
import { envVarManagement } from '../../../../envVarManagement';

//OK NEW AUTH

//SIGN OUT USER
export async function signOut(
    appDispatcher,
    cognito
){

    //COGNITO DECONNECTION
    if(cognito){

        //SIGNOUT CURRENT USER
        await Auth.signOut({ global: true }).then().catch((err)=>{
            console.log('signing out: ', err);
        })

        //CLEAN COOKIE
        const cookies = new Cookies();

        //GET ALL COOKIES
        const allCookies = cookies.getAll()

        //ALL COOKIES ATTRIBUTES
        const cookiesAttributs = Object.keys(allCookies);

        //ENV VAR
        const envCookieDomain = envVarManagement("envCookieDomain")

        // //DELETE ALL
        cookiesAttributs.forEach(attribut => {

            //DELETE COOKIE
            cookies.remove(attribut,
                { 
                    path: '/', 
                    domain: envCookieDomain,
                    sameSite: 'lax',
                    secure: envCookieDomain !== "localhost"
                }
            );
        });
    }

    //UPDATE FRONT
    appDispatcher({
        type:"SET_USER_SIGN_OUT",
    })
};

//CLEAN USERDATA COOKIE
export function authCleanUserDataCookie(){

    //CLEAN COOKIE
    const cookies = new Cookies();

    //GET ALL COOKIES
    const allCookies = cookies.getAll()

    //ALL COOKIES ATTRIBUTES
    const cookiesAttributs = Object.keys(allCookies);

    //FOR LOOP
    for (let i = 0; i < cookiesAttributs.length; i++) {

        //TEST IF USER DATA
        if(cookiesAttributs[i]?.includes("userData") || cookiesAttributs[i]?.includes("accessToken")){

            //ENV VAR
            const envCookieDomain = envVarManagement("envCookieDomain")

            //DELETE COOKIE
            cookies.remove(cookiesAttributs[i],
                { 
                    path: '/', 
                    domain: envCookieDomain,
                    sameSite: 'lax',
                    secure: envCookieDomain !== "localhost"
                }
            );
        }
    }
}