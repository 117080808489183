//IMPORT
import {Auth} from 'aws-amplify';
import {nextSignIn} from './AuthNextSignIn'
import { authCleanUserDataCookie } from './AuthSignOut';
import { envVarManagement } from '../../../../envVarManagement';
import { checkUserFromSsoProvider } from './AuthCommon';

///////////////////////////////////////////////
// UPDATE CURRENT USER OBJECT                //
// 1 - AppInitState : APP_CURRENT_USER       //
// 2 - AppDispatcher : SET_USER_SIGN_OUT     //
// 3 - AppDispatcher : SET_AWS_SIGNIN_INIT   //
// 4 - nextSignIn                            //
///////////////////////////////////////////////

///////////////////////////////////////////////
// SUMMARY                                   //
// 01 - signIn                               //
// 02 - confirmSignIn                        //
// 03 - verifyTotpToken                      //
// 04 - verifyPhoneNumberMfa                 //
///////////////////////////////////////////////

//OK NEW AUTH

//////////////////////////////////////////////////////////////////////////////
/// SIGNIN FUNCTION //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export async function signIn(
    username, 
    password, 
    appDispatcher,
    authenticatorSetState,
    envTermsVersion
){
    //TRY TO CONNECT THE USER
    try {

      //TEST CONNECTION WITH USERNAME AND PASSWORD
      const user = await Auth.signIn(username, password);

      //DELETE USERDATA COOKIE
      authCleanUserDataCookie()

      //ENV VAR
      const envMfaMandatory = envVarManagement("envMfaMandatory")
      const envSso = envVarManagement("envSso")

      //IF USER FROM SSO PROVIDER
      let isUserFromSsoProvider = false
      if(envSso.active){
        isUserFromSsoProvider = checkUserFromSsoProvider(
          user,
          envSso.list
        )
      }

      //TEST IF MFA REQUIRED
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          
        //LAUNCH MFA REQUEST
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    needMfaCode: true,
                    user: user,
                    errorMessage: false,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
          return({
            ...prevState, 
            loader:false,
            password:""
          })
        })

      //TEST IF NEW USER AND NEED TO PASS A NEW PASSWORD
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

        //LAUNCH NEW PASSWORD REQUEST
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                  user: user,
                  needNewPassword: true,
                  errorMessage: false,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
          return({
            ...prevState, 
            loader:false,
            password:""
          })
        })

      //IF USER NEED MFA SETUP
      }else if(!isUserFromSsoProvider && envMfaMandatory && user.preferredMFA === "NOMFA"){

        //LAUNCH NEW PASSWORD REQUEST
        appDispatcher({
          type:"SET_GLOBAL_STATE",
          options:{
              source: "currentUser",
              object: {
                user: user,
                needMfaSetUp: true,
                errorMessage: false,
              }
          }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
          return({
            ...prevState, 
            loader:false,
            password:""
          })
        })

      //USER CONNECTED
      }else{

        //email verification
        if(!user.attributes.email_verified){

          //INIT USER
          appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                  user: user,
                  needVerifyEmail:true,
                  errorMessage: false,
                }
            }
          })

          //RESET LOADER
          authenticatorSetState(prevState => {
            return({
              ...prevState, 
              loader:false
            })
          })

        //TERMS EN CONDITION VERIFICATION
        }else if(!user.attributes['custom:terms'] || user.attributes['custom:terms'] !== envTermsVersion){

            //INIT USER
            appDispatcher({
              type:"SET_GLOBAL_STATE",
              options:{
                  source: "currentUser",
                  object: {
                    user: user,
                    displayTermsValidation: true,
                    username: user.username,
                    name: user.attributes.name,
                    errorMessage: false,
                  }
              }
            })

        //OK CHECK IF NO MFA
        }else{

          //LAUNCH NEXT SIGN IN => PREPARE THE APP
          nextSignIn(
            user,
            appDispatcher
          );
        }
      }

    //ERROR MANAGEMENT
    } catch (err) {

      //TEST IF USER NEED CONFIRM SIGNUP
      if (err.code === 'UserNotConfirmedException') {
        
        //RESET LOADER
        authenticatorSetState(prevState => {
          return({
            ...prevState, 
            loader:false,
            email: username,
            password:""
          })
        })

        //LAUCNH VIEW
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    confirmSignUp: true,
                }
            }
        })

      //OTHER ISSUE
      }else{

        //RESET LOADER
        authenticatorSetState(prevState => {
          return({
            ...prevState, 
            loader:false,
            password:""
          })
        })

        //PASSWORD NOT GOOD
        if (err.code === 'NotAuthorizedException') {
          appDispatcher({
              type:"SET_GLOBAL_STATE",
              options:{
                  source: "currentUser",
                  object: {
                      errorMessage: "Password not correct!",
                  }
              }
          })

        // EMAIL NOT GOOD
        } else if (err.code === 'UserNotFoundException') {
          appDispatcher({
              type:"SET_GLOBAL_STATE",
              options:{
                  source: "currentUser",
                  object: {
                      errorMessage: "Email not correct!",
                  }
              }
          })

        //OTHER ERROR
        } else {
          appDispatcher({
              type:"SET_GLOBAL_STATE",
              options:{
                  source: "currentUser",
                  object: {
                      errorMessage: err.message,
                  }
              }
          })
        }
      }
    }
}

//////////////////////////////////////////////////////////////////////////////
/// CONFIRM SIGNIN WITH MFA CODE FUNCTION ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export async function confirmSignIn(
  user, 
  code, 
  mfaType,
  appDispatcher,
  authenticatorSetState,
  envTermsVersion
){

    //TEST CONNECTION WITH USERNAME AND PASSWORD
    await Auth.confirmSignIn(user, code, mfaType).then(async ()=>{

      //GET UPDATED USER
      Auth.currentAuthenticatedUser({bypassCache: true}).then((loggedUser) =>{

        //ENV VAR
        const envMfaMandatory = envVarManagement("envMfaMandatory")
        const envSso = envVarManagement("envSso")

        //IF USER FROM SSO PROVIDER
        let isUserFromSsoProvider = false
        if(envSso.active){
          isUserFromSsoProvider = checkUserFromSsoProvider(
            user,
            envSso.list
          )
        }

        //FORCE MFA
        if(!isUserFromSsoProvider && envMfaMandatory && user.preferredMFA === "NOMFA"){

          //LAUNCH NEW PASSWORD REQUEST
          appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                  user: user,
                  needMfaSetUp: true,
                  errorMessage: false,
                }
            }
          })

          //RESET LOADER
          authenticatorSetState(prevState => {
            return({
              ...prevState, 
              loader:false,
              password:""
            })
          })

        //LAUNCH NEXT SIGN IN => PREPARE THE APP
        }else if(!loggedUser.attributes['custom:terms'] || loggedUser.attributes['custom:terms'] !== envTermsVersion){

          //INIT USER
          appDispatcher({
              type:"SET_GLOBAL_STATE",
              options:{
                  source: "currentUser",
                  object: {
                      displayTermsValidation: true,
                      needMfaCode: false,
                      needMfaSetUp: false,
                      username: loggedUser.username,
                      name: loggedUser.attributes.name,
                      errorMessage: false,
                  }
              }
          })

        //OK TERMS VERIFIED
        }else{

            //LAUNCH NEXT SIGN IN => PREPARE THE APP
            nextSignIn(
                loggedUser,
                appDispatcher
            );
        }
      })

    //ERROR
    }).catch((err)=>{

        //ERROR
        appDispatcher({
          type:"SET_GLOBAL_STATE",
          options:{
              source: "currentUser",
              object: {
                  errorMessage: err.message,
              }
          }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false
            })
        })
    })
  }
    
//////////////////////////////////////////////////////////////////////////////
/// VERIFYING TOTP TOKEN /////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export async function verifyTotpToken(
  user, 
  code, 
  appDispatcher,
  authenticatorSetState,
  envTermsVersion
){

    //TEST CONNECTION WITH USERNAME AND PASSWORD
    await Auth.verifyTotpToken(user, code).then(()=>{

      //SET TOTP AS PREFERED MFA METHOD
      Auth.setPreferredMFA(user, 'TOTP');

      //LAUNCH NEXT SIGN IN => PREPARE THE APP
      if(!user.attributes['custom:terms'] || user.attributes['custom:terms'] !== envTermsVersion){

        //INIT USER
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    displayTermsValidation: true,
                    needMfaCode: false,
                    needMfaSetUp: false,
                    username: user.username,
                    name: user.attributes.name,
                    errorMessage: false,
                }
            }
        })

      //OK TERMS VERIFIED
      }else{

          //LAUNCH NEXT SIGN IN => PREPARE THE APP
          nextSignIn(
            user,
            appDispatcher
          );
      }

    }).catch((err)=>{

        //ERROR
        appDispatcher({
          type:"SET_GLOBAL_STATE",
          options:{
              source: "currentUser",
              object: {
                  errorMessage: err.message,
              }
          }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false
            })
        })
    })
  }

//////////////////////////////////////////////////////////////////////////////
/// VERIFYING PHONE CODE /////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export async function verifyPhoneNumberMfa(
  user, 
  code, 
  appDispatcher,
  authenticatorSetState,
  envTermsVersion
){

    //TEST CONNECTION WITH USERNAME AND PASSWORD
    await Auth.verifyCurrentUserAttributeSubmit("phone_number", code).then(()=>{

      //SET TOTP AS PREFERED MFA METHOD
      Auth.setPreferredMFA(user, 'SMS');

      //LAUNCH NEXT SIGN IN => PREPARE THE APP
      if(!user.attributes['custom:terms'] || user.attributes['custom:terms'] !== envTermsVersion){

        //INIT USER
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    displayTermsValidation: true,
                    needMfaCode: false,
                    needMfaSetUp: false,
                    username: user.username,
                    name: user.attributes.name,
                    errorMessage: false,
                }
            }
        })

      //OK TERMS VERIFIED
      }else{

          //LAUNCH NEXT SIGN IN => PREPARE THE APP
          nextSignIn(
            user,
            appDispatcher
          );
      }

    }).catch((err)=>{

        //ERROR
        appDispatcher({
          type:"SET_GLOBAL_STATE",
          options:{
              source: "currentUser",
              object: {
                  errorMessage: err.message,
              }
          }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false
            })
        })
    })
}

//////////////////////////////////////////////////////////////////////////////
/// CONFIRM NO MFA ///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export async function confirmSignInWithoutMfa(
  user, 
  appDispatcher,
  authenticatorSetState,
  envTermsVersion
){
  
    //SET TOTP AS PREFERED MFA METHOD
    await Auth.setPreferredMFA(user, 'NOMFA').then(()=>{

      //LAUNCH NEXT SIGN IN => PREPARE THE APP
      if(!user.attributes['custom:terms'] || user.attributes['custom:terms'] !== envTermsVersion){

        //INIT USER
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    displayTermsValidation: true,
                    needMfaCode: false,
                    needMfaSetUp: false,
                    username: user.username,
                    name: user.attributes.name,
                    errorMessage: false,
                }
            }
        })

      //OK TERMS VERIFIED
      }else{

          //LAUNCH NEXT SIGN IN => PREPARE THE APP
          nextSignIn(
            user,
            appDispatcher
          );
      }

    }).catch((err)=>{

        //ERROR
        appDispatcher({
          type:"SET_GLOBAL_STATE",
          options:{
              source: "currentUser",
              object: {
                  errorMessage: err.message,
              }
          }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false
            })
        })
    })
}