import { CvtButton, CvtTag } from '@mi-gso/cvt';
import {memo} from 'react';

export default memo(function ActionLinksSideBarLegend({
    isAddView,
    linkItem,
    listLength,
    onOpenNewLinkView,
    onBackToLinkList,
    onSaveLinkItem,
}) {

    return (
        <div className={!isAddView ? "flexStartCenter" : "flexEndCenter"}
            style={{ marginTop: "15px", gap: "10px", marginBottom: isAddView ? "0px" : "20px" }}
        >
            {/* NEW ITEM + COUNT */}
            {
                !isAddView &&
                <>
                    {/* ADD */}
                    <CvtButton
                        color="blue"
                        icon="add"
                        shadow="none"
                        size="tiny"
                        txt="Add"
                        handleSubmit={onOpenNewLinkView}
                    />

                    {/* COUNT */}
                    <CvtTag
                        color="dark"
                        shape={"round"}
                        txt={listLength + " item" + (listLength > 0 ? "s" : "")}
                    />
                </>
            }

            {/* SAVE / GO BACK */}
            {isAddView && (
                <>
                    {/* GO BACK */}
                    <CvtButton
                        color="gray"
                        icon="arrow_back"
                        shadow="none"
                        size="tiny"
                        txt="Cancel"
                        handleSubmit={onBackToLinkList}
                    />

                    {/* SAVE */}
                    <CvtButton
                        color="blue"
                        icon="save"
                        shadow="none"
                        size="tiny"
                        txt="Save"
                        handleSubmit={onSaveLinkItem}
                        disabled={!linkItem.destinationItemId}
                    />
                </>
            )}
        </div>
    )
});