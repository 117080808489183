// LIBS
import { memo, useCallback, useMemo } from "react";

// FUNCTIONS
import { FUNC_DATE_IS_TODAY, FUNC_ZERO_FORMAT_TO_NUM } from "../../../00-Core/Standards";
import { FUNC_BOARD_UPDATE_LAST_ACTIVITIES } from "../00-Helpers/BoardFunctions";
import BoardLoader from "./BoardLoader";

export default memo(function BoardReviewListItem({
    review,
    openWbsItem,
    activateLoader,
    loadingId,
    boardDispatcher,
}) {
    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // OPEN REVIEW
    const openReviewMode = useCallback(() => {
        // ACTIVATE LOADER
        activateLoader(review.id);

        let lastActivityData = {
            id: review.id,
            name: review.name,
            wbsId: review.wbsId,
            organizationId: review.organizationId,
            projectId: review.projectId,
        };

        // ADD ACTION TO LAST ACTIVITIES
        FUNC_BOARD_UPDATE_LAST_ACTIVITIES("govReview", lastActivityData, boardDispatcher);

        // OPEN ITEM IN WBS
        openWbsItem("govReview", review);
    }, [activateLoader, boardDispatcher, openWbsItem, review]);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // TYPE COLOR DOT
    const reviewTypeColor = useMemo(() => {
        // Need a constant after defining all the types ...
        switch (review.type) {
            case "daily":
                return "var(--color-good)";
            case "steering":
                return "var(--color-migso-yeallow)";
            case "strategic":
                return "var(--color-migso-blue)";
            case "projectMeeting":
                return "var(--color-alert)";

            default:
                return "var(--color-good)";
        }
    }, [review.type]);

    // GET DATE STRINGS
    const dayDateText = useMemo(() => {
        let dayText = null;
        let hoursText = null;

        // COMMON VARS
        let startDate = new Date(review.startDate);
        let today = new Date();

        // DAY DATE TEXT
        let tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (FUNC_DATE_IS_TODAY(startDate)) {
            // TODAY
            dayText = "Today";
        } else if (startDate.toDateString() === tomorrow.toDateString()) {
            // TOMORROW
            dayText = "Tomorrow";
        } else {
            // IN THE FUTUR
            dayText = startDate.toLocaleString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        }

        // HOURS TEXT
        let endDate = new Date(review.endDate);
        hoursText =
            FUNC_ZERO_FORMAT_TO_NUM(startDate.getHours(), 2) +
            ":" +
            FUNC_ZERO_FORMAT_TO_NUM(startDate.getMinutes(), 2) +
            "-" +
            FUNC_ZERO_FORMAT_TO_NUM(endDate.getHours(), 2) +
            ":" +
            FUNC_ZERO_FORMAT_TO_NUM(endDate.getMinutes(), 2);

        // RETURN
        return {
            dayText: dayText,
            hoursText: hoursText,
        };
    }, [review.endDate, review.startDate]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexBetweenCenter boardListItem text_Color3_500_12px"
            style={{
                cursor: "pointer",
            }}
            onClick={openReviewMode}
        >
            {/* LEFT PART */}
            <div className="flexStartCenter">
                {/* TYPE COLOR DOT */}
                <span
                    className="flexStartStart"
                    style={{
                        backgroundColor: reviewTypeColor,
                        width: "10px",
                        minWidth: "10px",
                        height: "10px",
                        borderRadius: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        flexShrink: 0,
                    }}
                ></span>

                {/* START DAY */}
                <span className="text_Color4_500_12px" style={{ minWidth: "65px" }}>
                    {dayDateText.dayText}
                </span>

                {/* HOURS */}
                <span
                    className="text_Color4_500_12px"
                    style={{ marginLeft: "10px", minWidth: "65px" }}
                >
                    {dayDateText.hoursText}
                </span>

                {/* TITLE */}
                {loadingId === review.id ? (
                    <BoardLoader />
                ) : (
                    <span style={{ marginLeft: "10px" }}>{review.name}</span>
                )}
            </div>
        </div>
    );
});
