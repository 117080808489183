import { ACTION_ESCALATED_FROM_LIST, ACTION_ESCALATED_TO_LIST, ACTION_LINK_TYPE_LIST, ACTION_STATUS_LIST } from "../../01-Action/00-Helpers/ActionsConstants";
import { RISKOPP_STATUS_LIST } from "../../02-Risk/00-Helpers/RiskConstants";
import { GOV_SCOPE_DECISIONS, GOV_SCOPE_STATUS } from "../../04-Governance/00-Helpers/GovScopeConstants";
import { CHARTER_CATEGORIZATION_TYPE, CHARTER_LIST_PHASE, CHARTER_LIST_TYPE } from "../../05-Charter/00-Helpers/CharterConstants";

// DEFAULT PORTFOLIO FILTERS
export const FILTERS_DOMAIN_INFO = {
    charter: [
        {
            subDomain: "owner", 
            name:"Leader", 
            type: "checkbox", 
            tables:[
                {table: "charter", attribute: "leader"}
            ]
        },
        {
            subDomain: "category", 
            name:"Category", 
            options: CHARTER_CATEGORIZATION_TYPE, 
            type: "checkbox", 
            tables : [
                {table: "charter", attribute: "category"}
            ]
        },
        {
            subDomain: "phase", 
            name:"Phase", 
            options: CHARTER_LIST_PHASE, 
            type: "checkbox", 
            tables : [
                {table: "charter", attribute: "phase"}
            ]
        },
        {
            subDomain: "type", 
            name:"Type", 
            options: CHARTER_LIST_TYPE, 
            type: "checkbox", 
            tables : [
                {table: "charter", attribute: "type"}
            ]
        }
    ],
    owner: [ 
        {
            subDomain: "owner", 
            name:"People", 
            type: "checkbox", 
            tables:[
                {table:"action", attribute: "responsible"},
                {table:"riskOpp", attribute: "responsible"},
                {table:"govScopeChange", attribute: "responsible"},
                {table:"schedule", attribute: "responsible"},
            ]
        }
    ],
    deadline : [
        {
            subDomain: "deadline", 
            name:"Deadline", 
            type: "date", 
            dateRange: true,
            tables : [
                {table: "action", attribute: "dueDate"},
                {table: "govScopeChange", attribute: "dueDate"},
                {table: "govReview", attribute: "endDate"},
                {table: 'schedule', attribute: 'actualFinish', altAttribute: 'forecastFinish'}
            ]
        }
    ],
    governance: [ 
        {
            subDomain: "scopeStatus", 
            name:"Scope status", 
            options:GOV_SCOPE_STATUS, 
            type: "checkbox", 
            tables:[
                {table:"govScopeChange", attribute: "status"},
            ]
        },
        {
            subDomain: "scopeDecision", 
            name:"Scope Decision", 
            options:GOV_SCOPE_DECISIONS, 
            type: "checkbox", 
            tables:[
                {table:"govScopeChange", attribute: "decision"},
            ]
        },
        {
            subDomain: "reviewName",
            name: "Reviews",
            options: [],
            type: "select",
            isMultiSelect: true,
            placeholder: "Reviews",
            tables: [
                {
                    table: "govReview", 
                    attribute: "id",
                },
                // ACTION
                {
                    table: "action",
                    attribute: "links",
                    jsonAttr: 'destinationItemId'
                },
                // RISK OPP
                {
                    table: "riskOpp",
                    attribute: "telescopeDataGovernanceTelescopeDataRoIds",
                },
                // SCHEDULES
                {
                    table: 'schedule',
                    attribute: null,
                },
                // SCOPE CHANGE
                {
                    table: "govScopeChange",
                    attribute: 'govReviewIds',
                }
            ],
        },
    ],
    action: [
        {
            subDomain: "status", 
            name:"Status", 
            options:ACTION_STATUS_LIST, 
            type: "checkbox", 
            tables : [
                {table: "action", attribute: "status"}
            ]
        },
        {
            subDomain: "type", 
            name:"Type", 
            options:[ACTION_LINK_TYPE_LIST[0]], 
            type: "checkbox", 
            isOnlyNotNull: true,
            tables : [
                {table: "action", attribute: "telescopeDataROTelescopeDataActionId"}
            ]
        }
    ],
    riskOpp: [
        {
            subDomain: "status", 
            name:"Status", 
            options: RISKOPP_STATUS_LIST, 
            type: "checkbox", 
            tables : [
                {table: "riskOpp", attribute: "status"}
            ]
        },
        {
            subDomain: "criticity", 
            name:"Criticity", 
            type: "number", 
            min: 1, 
            max: 16, 
            tables : [
                {table: "riskOpp", attribute: "currentCriticity"}
            ]
        },
        {
            subDomain: "escalated", 
            name:"Escalated", 
            options:ACTION_ESCALATED_TO_LIST, 
            type: "checkbox", 
            isOnlyNotNull: true,
            tables : [
                {table: "riskOpp", attribute: "escalatedTo"}
            ]
        },
        {
            subDomain: "escalatedfrom", 
            name:"Escalated", 
            options:ACTION_ESCALATED_FROM_LIST, 
            type: "checkbox", 
            isOnlyNotNull: true,
            noSubtitle: true,
            tables : [
                {table: "riskOpp", attribute: "escalatedFrom"}
            ]
        }
    ],
  }



// MAPPER OBJECT FROM DOMAIN => ACTION DEPENDENCY PROPERTY NAME
export const DOMAIN_ACTION_DEPENDENCY_MAPPER = {
    riskOpp: "actionsIds",
    govScopeChange: "actionsIds",
    schedule: "actionsIds",
}