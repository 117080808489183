import React, { memo, useMemo, useState, useCallback } from "react";
import _ from "lodash";

//CORE
import { CvtButton, CvtInput } from "@mi-gso/cvt";
import { FUNCT_FIND_INDEX, FUNCT_FIND_INDEX_ARRAY } from "../../../../00-Core/Standards";

//USER
import { USERS_MANAGEMENT_PAGE_COUNT } from "./00-Helpers/UsersConstants";
import UsersManagementCommandBar from "./01-Components/UserManagementCommandBar";
import UsersManagementTable from "./01-Components/UserManagementTable";

//////////////////////////////////
/// USERS MANAGEMENT COMPONENT ///
//////////////////////////////////

export default memo(function UserManagementList({
    currentUserId,
    usersList,
    portfolioEdit,
    projectList,
    boardDispatcher,
}) {
    ////////////////////////////////////////////////
    /// STATE //////////////////////////////////////
    ////////////////////////////////////////////////

    const [state, setState] = useState({
        currentPage: 1,
        searchInput: "",
        selected: [],
    });

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    const detailedAndSortedList = useMemo(() => {
        let allUsers = [...portfolioEdit.itemViewers, ...portfolioEdit.itemEditors];

        // GET USER DETAILED INFOS
        let detailedUsers = allUsers.map((userId) => {
            let userIndex = FUNCT_FIND_INDEX(usersList, "id", userId);
            return usersList[userIndex];
        });

        // SORT BY NAME
        detailedUsers.sort((a, b) => a.name.localeCompare(b.name));

        return detailedUsers;
    }, [portfolioEdit.itemEditors, portfolioEdit.itemViewers, usersList]);

    // ORDERED USER LIST
    const orderedList = useMemo(() => {
        let list = _.cloneDeep(detailedAndSortedList);

        //GET FILTERED USERS
        if (state.searchInput.length > 0) {
            //FILTERED LIST
            list = list.filter((user) => {
                //SEARCH NAME
                if (user.name && user.name.toUpperCase()?.includes(state.searchInput.toUpperCase()))
                    return true;

                //SEARCH EMAIL
                if (
                    user.email &&
                    user.email.toUpperCase()?.includes(state.searchInput.toUpperCase())
                )
                    return true;

                //IF NOT RETURN FALSE
                return false;
            });
        }

        //RETURN
        return list;
    }, [detailedAndSortedList, state.searchInput]);

    // TOTAL PAGES -----------------------------------
    const totalPages = useMemo(() => {
        //TOTAL USER
        let totalCount = orderedList ? orderedList.length : 0;

        //RETURN TOTAL PAGE
        return totalCount < USERS_MANAGEMENT_PAGE_COUNT
            ? 1
            : Math.ceil(totalCount / USERS_MANAGEMENT_PAGE_COUNT);
    }, [orderedList]);

    // CALCULATE CURRENT PAGES USERS AND RETURN GOOD LIST
    const currentPageUsers = useMemo(() => {
        //INIT
        let users = _.cloneDeep(orderedList);

        // USER AVAILABLE
        if (users.length > 0) {
            //IF ONLY ONE PAGE
            if (totalPages === 1) {
                return orderedList;
            }

            // IF TOTAL PAGES IS NOT 1, THEN CALCULATE USE CURRENT PAGE AS WELL.
            else {
                //GET END AND START INDEX FOR USERLIST
                let start =
                    state.currentPage * USERS_MANAGEMENT_PAGE_COUNT - USERS_MANAGEMENT_PAGE_COUNT;
                let end = start + USERS_MANAGEMENT_PAGE_COUNT;

                //RETURN UPDATED USER LIST
                return users.slice(start, end);
            }
        }

        //RETURN TOT LIST
        return users;
    }, [orderedList, state.currentPage, totalPages]);

    ////////////////////////////////////////////////
    /// FUNCTIONS //////////////////////////////////
    ////////////////////////////////////////////////

    // HANDLE REMOVE USERS BUTTON CLICK -------------------
    const handleRemoveUsers = useCallback(() => {
        // CLONE STATE
        let newViewers = [...portfolioEdit.itemViewers];
        let newEditors = [...portfolioEdit.itemEditors];

        let selectedUserIds = state.selected.map((user) => user.id);

        newViewers = newViewers.filter((userId) => !selectedUserIds?.includes(userId));
        newEditors = newEditors.filter((userId) => !selectedUserIds?.includes(userId));

        // UPDATE PORTFOLIO STATE
        boardDispatcher({
            type: "SET_UPDATE_PORTFOLIO_PERMISSIONS",
            itemViewers: newViewers,
            itemEditors: newEditors,
        });

        // RESET SELECTED ARRAY
        setState((prevState) => ({
            ...prevState,
            selected: [],
        }));
    }, [boardDispatcher, portfolioEdit.itemEditors, portfolioEdit.itemViewers, state.selected]);

    // HANDLE USER TYPE CLICK (CHANGES USER TYPE) ---------
    const handleTypeClick = useCallback(
        (user) => {
            let newViewers = [...portfolioEdit.itemViewers];
            let newEditors = [...portfolioEdit.itemEditors];

            // CHECK IN EACH ARRAYS TO SEE IF USER IS VIEWER OR EDITOR
            let userIndexViewerFound = FUNCT_FIND_INDEX_ARRAY(newViewers, user.id);
            let userIndexEditorFound = FUNCT_FIND_INDEX_ARRAY(newEditors, user.id);

            // CHECK IF USER IN VIEWERS -> MAKE IT EDITOR
            if (userIndexViewerFound !== -1) {
                newViewers.splice(userIndexViewerFound, 1);
                newEditors.push(user.id);
            }
            // CHECK IF USER IN EDITOR -> MAKE IT VIEWERS
            else if (userIndexEditorFound !== -1) {
                newEditors.splice(userIndexEditorFound, 1);
                newViewers.push(user.id);
            }

            // UPDATE STATE
            boardDispatcher({
                type: "SET_UPDATE_PORTFOLIO_PERMISSIONS",
                itemViewers: newViewers,
                itemEditors: newEditors,
            });
        },
        [boardDispatcher, portfolioEdit.itemEditors, portfolioEdit.itemViewers]
    );

    // HANDLE ADD USERS -----------------------------------
    const handleAddUsers = useCallback(() => {
        boardDispatcher({
            type: "SET_SIMPLE_STATE",
            key: "isAddingUsers",
            value: true,
        });
    }, [boardDispatcher]);

    // HANDLE SELECT USER IN MANAGE USERS MODAL ------------
    const handleSelectUser = useCallback((userItem) => {
        setState((prevState) => {
            //INIT
            let newSelected = [...prevState.selected];

            //TEST IF WE NEED TO REMOVE IT
            let toRemoveIndex = FUNCT_FIND_INDEX(newSelected, "id", userItem.id);

            //DEL IF REMOVE
            if (toRemoveIndex !== -1) {
                //GET INDEX OF
                newSelected.splice(toRemoveIndex, 1);

                //IF NOT ADD
            } else {
                newSelected.push(userItem);
            }

            //RETURN STATE
            return {
                ...prevState,
                selected: newSelected,
            };
        });
    }, []);

    //HANDLE RESET SEARCH ---------------------------
    const handleResetSearch = useCallback(() => {
        setState((prevState) => {
            return {
                ...prevState,
                searchInput: "",
            };
        });
    }, []);

    // HANDLE SEARCH FOR USER ------------------------------
    const handleSearch = useCallback((e) => {
        setState((prevState) => {
            return {
                ...prevState,
                searchInput: e.target.value,
            };
        });
    }, []);

    ////////////////////////////////////////////////////
    /// MINI COMPONENT /////////////////////////////////
    ////////////////////////////////////////////////////

    //RIGHT JSX SEARCH BAR -----------------------------
    const searchBarRightIcon = useMemo(() => {
        //ONLY IF SEARCH VALUE
        if (state.searchInput !== "") {
            return (
                <div
                    className="material-icons addUsersBlockSearchButton"
                    onClick={handleResetSearch}
                >
                    clear
                </div>
            );
        }

        //NULL
        return null;
    }, [handleResetSearch, state.searchInput]);

    //SEARCH BAR ---------------------------------------
    const searchBar = useMemo(() => {
        return (
            <CvtInput
                placeholder="Search"
                inputWidthFull
                icon="search"
                inputBlockCss="flexBetweenCenter modalOptionSubBlock"
                inputPadding="4px 8px"
                inputFontSize="14px"
                inputBlockPadding="0px"
                inputBlockMargin="0px"
                type="text"
                name="searchInput"
                value={state.searchInput}
                onChange={handleSearch}
                rightJsx={searchBarRightIcon}
            />
        );
    }, [handleSearch, searchBarRightIcon, state.searchInput]);

    //////////////////////////////////////////////////////////
    /// COMPONENT RENDER /////////////////////////////////////
    //////////////////////////////////////////////////////////

    return (
        <div
            className="structureBlockInfo"
            style={{
                width: "100%",
                paddingTop: "10px",
            }}
        >
            {/* HEADER */}
            <div className="flexEndCenter">
                {/* BUTTON ADD AND REMOVE */}
                <div className="flexStartCenter">
                    {/* REMOVE SELECTED USERS*/}
                    <CvtButton
                        color="gray"
                        shape="noShape"
                        needConfirmation
                        txt="Remove users"
                        outline
                        margin="0px 15px 0px 0px"
                        icon="delete"
                        iconColor="var(--color-bad)"
                        handleSubmit={handleRemoveUsers}
                        disabled={!(state.selected && state.selected.length > 0)}
                    />

                    {/* ADD USERS */}
                    <CvtButton
                        color="gray"
                        shape="noShape"
                        txt="Add more users"
                        outline
                        margin="0px 10px 0px 0px"
                        icon="person_add"
                        iconColor="var(--color-migso-green)"
                        handleSubmit={handleAddUsers}
                    />
                </div>
            </div>

            {/* SEARCH BAR */}
            <UsersManagementCommandBar
                currentPage={state.currentPage}
                listLength={orderedList.length}
                totalPageCount={totalPages}
                setParentState={setState}
                customSearchBar={searchBar}
            />

            {/* BODY */}
            <div className="flexColBetweenStart projectUserManagementBody">
                {orderedList.length > 0 ? (
                    <UsersManagementTable
                        currentPageUsers={currentPageUsers}
                        currentUserId={currentUserId}
                        usersList={usersList}
                        projectList={projectList}
                        selectedUsers={state.selected}
                        portfolioEdit={portfolioEdit}
                        handleSelectUser={handleSelectUser}
                        handleTypeClick={handleTypeClick}
                    />
                ) : (
                    <div className="structureBlockInfo flexStartCenter">
                        <span className="material-icons structureBlockInfoIcon">info</span>
                        <span>No user linked to this project</span>
                    </div>
                )}
            </div>
        </div>
    );
});
