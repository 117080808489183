import React, { useMemo, useCallback, useReducer, useEffect, memo } from 'react'

//LIBRARIES
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

// ACTION
import { actionsReducer } from './00-Helpers/ActionsDispatcher'
import {
	ACTION_FUNC_DELETE_ACTIONS_AND_LINKS,
	ACTION_GET_DEFAULT_ITEM,
	ACTION_GET_INITIAL_STATE,
	FUNC_ACTION_ADD_NEW_LINKS,
	FUNC_ACTION_DUPLICATE,
	FUNC_ACTION_IS_LATE,
	FUNC_ACTION_NEED_TO_ADD_EVENT,
	FUNC_ACTION_PREPARE_DATA_EXPORT,
	FUNC_ACTION_REMOVE_MULTIPLE_LINKS,
	FUNC_COMPARE_LINKS,
} from './00-Helpers/ActionsFunctions'
import ActionsChart from './01-Components/ActionChart/ActionChart'
import ActionTable from './01-Components/ActionTable/ActionTable'
import ActionCard from './01-Components/ActionCard/ActionCard'
import {
	ACTION_COMPONENT_EXPAND_TYPES,
	ACTION_DETAILS_KEY_BLOCK,
	ACTION_GROUP_ITEMS,
	ACTION_ITEM_DATA,
	ACTION_START_DISPLAY_ID,
	ACTION_STATUS_KEY,
	ACTION_STATUS_LIST,
} from './00-Helpers/ActionsConstants'

//CORE
import {
	APP_TOAST_TXT,
	DEFAULT_STATE_MOVE_MODAL_OPTIONS,
	TODAY,
} from '../../../00-Core/Constants'
import {
	FUNCT_FIND_INDEX,
	FUNCT_FIND_INDEX_ARRAY,
	FUNC_GET_USER_INFO,
	FUNC_ZERO_FORMAT_TO_NUM,
} from '../../../00-Core/Standards'
//EDIT SIDE BAR
import { EDIT_SIDE_BAR_DATA_PREPARATION } from '../07-EditSideBar/00-Helpers/EditSideBarFunctions'
import EditSideBar from '../07-EditSideBar/EditSideBar'

//WBS
import WbsElementBigStructure from '../00-Wbs/01-Components/WbsElementBigStructure'
import WbsElementStructureBody from '../00-Wbs/01-Components/WbsElementStructureBody'
import { WBS_BIG_COMPONENT_VIEW_MODE } from '../00-Wbs/00-Helpers/WbsConstants'
import ActionSideBar from './ActionSideBar'
import {
	createTelescopeDataAction as CreateTelescopeDataAction,
	updateTelescopeDataAction as UpdateTelescopeDataAction,
} from '../../../graphql/mutations'
import { mutateGraphql } from '../../00-App/02-Backend/AppBackendCommon'
import ActionLinkModal from './01-Components/ActionLinkModal/ActionLinkModal'
import { WBS_ON_BACK_SIDEBAR } from '../00-Wbs/00-Helpers/WbsFunctions'
import { FIND_OBJECT_ARRAY_ITEM } from '@mi-gso/cvt'

////////////////////////////
/// ACTION BIG COMPONENT ///
////////////////////////////

export default memo(function ActionBig({
	//DATA
	actionData,
	biggestDisplayIdNum,
	isReadOnlyMode,
	currentPath,
	projectData,
	organizationName,
	selectedReviewItem,

	//STRUCTURE
	wbsName,
	projectId,
	wbsId,
	wbsList,
	selectedOrganizationId,
	updateTracker,
	wbsNavigationOptions,

	//BIG COMPONENT
	bigComponentExpandFunction,
	bigComponentIsExpanded,
	handleBigComponent,
	fieldInformation,
	isReviewMode,

	//USER
	usersList,
	megaUsersIds,
	currentUser,
	securityGroup,

	//DISPATCHER
	appDispatcher,
	wbsDispatcher,

	// IF PASSED SELECTED FROM ABOVE.
	moveModalOptionsItemsArray,
}) {
	/////////////////////////////////////////////////////
	/// STATE ///////////////////////////////////////////
	/////////////////////////////////////////////////////

	const [state, actionsDispatcher] = useReducer(
		actionsReducer,
		ACTION_GET_INITIAL_STATE(
			isReviewMode,
			wbsNavigationOptions,
			projectData.action
		)
	)

	/////////////////////////////////////////////////////
	// MEMO /////////////////////////////////////////////
	/////////////////////////////////////////////////////

	//FILTERED ACTION
	const filteredActions = useMemo(() => {
		if (updateTracker) {
			// JUST TO TRIGGER THE MEMO IF DATA IS UPDATED
		}

		//ONLY DONE ?
		let statusList = [...new Set(actionData.map((item) => item.status))]
		let onlyDone = false
		if (statusList.length === 1 && statusList[0] === ACTION_STATUS_KEY.done)
			onlyDone = true

		//RETURN
		return actionData.filter((item) => {
			//IF NOT CONSOLIDATED SELECT GOOD WBS
			if (!state.isConsolidate && wbsId !== item.wbsId) return false

			//DONE - DO NOT DISPLAY DONE IF IS NOT ACTIVATED
			if (
				!onlyDone &&
				item.status === ACTION_STATUS_KEY.done &&
				((state.statusSearchInput &&
					state.statusSearchInput.length > 0 &&
					FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, item.status) ===
						-1) ||
					!state.statusSearchInput ||
					state.statusSearchInput.length === 0)
			)
				return false

			// GET CORRECT ITEM STATUS
			let itemStatus = item.status
			let dueDate = item.dueDate
			if (Object.prototype.toString.call(dueDate) !== '[object Date]') {
				dueDate = new Date(dueDate)
			}
			// IF IS LATE, THEN SET itemStatus = "late"
			if (FUNC_ACTION_IS_LATE(itemStatus, dueDate)) {
				itemStatus = 'late'
			}

			//STATUS
			if (
				state.statusSearchInput &&
				state.statusSearchInput.length > 0 &&
				FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, itemStatus) === -1 &&
				// IF IS LATE, THEN CHECK IF THE ORIGINAL STATUS ALSO IS NOT CONTAINED IN THE STATUS SEARCH
				(itemStatus === 'late'
					? FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, item.status) === -1
					: true)
			) {
				// DOESN'T PASS STATUS FILTER
				return false
			}

			//SEARCH
			if (
				state.textSearchInput &&
				state.textSearchInput !== '' &&
				item.action &&
				!item.action.toUpperCase().includes(state.textSearchInput.toUpperCase())
			)
				return false

			//RETURN TRUE
			return true
		})
	}, [
		actionData,
		state.isConsolidate,
		state.statusSearchInput,
		state.textSearchInput,
		wbsId,
		updateTracker,
	])

	////////////////////////////////////////////////////////////////////////////
	/// DATA ///////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////

	//NEXT DISPLAY ID ------------------------------------------------------- OK
	const nextDisplayId = useMemo(() => {
		return (
			ACTION_START_DISPLAY_ID +
			FUNC_ZERO_FORMAT_TO_NUM(biggestDisplayIdNum + 1, 4)
		)
	}, [biggestDisplayIdNum])

	// CAN MODIFY SELECTED ACTIONS
	const canModifySelected = useMemo(() => {
		// IF IS IN CARD VIEW, RETURN EITHER IS RESPONSIBLE OR MEGA USER.
		if (state.actionView)
			return (
				state.actionView.responsible === currentUser.username ||
				state.actionView.createdBy === currentUser.username ||
				securityGroup.isCurrentUserEditor
			)
		for (const action of state.selected) {
			// IF IS NOT RESPONSIBLE AND IS NOT MEGA USER, THEN RETURN FALSE
			if (
				action.responsible !== currentUser.username &&
				action.createdBy !== currentUser.username &&
				!securityGroup.isCurrentUserEditor
			) {
				return false
			}
		}
		return true
	}, [
		currentUser.username,
		securityGroup.isCurrentUserEditor,
		state.actionView,
		state.selected,
	])

	////////////////////////////////////////////////////////////////////////////
	/// EDITION ////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////

	// SHOW MOVE MODAL
	const handleMoveItemsClicked = useCallback(
		(e) => {
			e.preventDefault()

			appDispatcher({
				type: 'SET_GLOBAL_STATE',
				options: {
					source: 'appSettings',
					object: {
						actionModal: 'wbsMove',
						moveModalOptions: {
							itemsArray:
								state.actionView &&
								state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
									? [state.actionView]
									: state.selected,
							tableName: 'action',
							query: UpdateTelescopeDataAction,
							propertiesToDelete: [
								'sort',
								'telescopeDataGovernance',
								'telescopeDataRO',
							],
						},
					},
				},
			})
		},
		[appDispatcher, state.actionView, state.selected, state.viewMode]
	)

	//DELETE SELECTED COMMENT ----------------------------------------------- OK
	const onDeleteComment = useCallback(
		(e, commentId) => {
			e.preventDefault()

			//MANAGE NEW COMMENTS ARRAY
			let actionComments = JSON.parse(state.actionView.comments)
			let commentIndex = actionComments.findIndex(
				(comment) => comment.id === commentId
			)
			actionComments.splice(commentIndex, 1)

			//PREPARE MUTATION OBJECT
			let mutationObject = {
				id: state.actionView.id,
				comments: JSON.stringify(actionComments),
			}

			//UPDATE ACTION STATE
			actionsDispatcher({
				type: 'SET_GLOBAL_STATE',
				options: {
					source: 'actionView',
					object: {
						comments: mutationObject.comments,
					},
				},
			})

			//UPDATE APP DISPATCHER
			appDispatcher({
				type: 'SET_PROJECT_DATA_UPDATE',
				source: 'action',
				objects: [mutationObject],
			})

			//MUTATE OBJECT
			mutateGraphql(UpdateTelescopeDataAction, mutationObject, appDispatcher)
		},
		[appDispatcher, state.actionView]
	)

	//ADD BTN CLICKED ------------------------------------------------------ OK
	const onAddEditClick = useCallback(
		(action) => {
			//INIT
			let currentAction = action ? _.cloneDeep(action) : null

			//PREPARER USERLIST FOR DROPDOWN
			let editSideBarUsersList = usersList.map((user) => ({
				label: user.label,
				value: user.value,
			}))

			if (currentAction && currentAction.baseline) {
				currentAction.baseline = new Date(currentAction.baseline)
			}

			// IF CAN'T FIND USER IN THE USERS LIST, PASS THE RANDOM MEGA USER
			if (
				currentAction !== null &&
				!usersList.find((user) => user.value === currentAction.responsible)
			) {
				//GET USERS OBJECT
				let userInCore = FUNC_GET_USER_INFO(
					usersList,
					megaUsersIds,
					currentAction.responsible
				)

				//UPDATE IT
				currentAction.responsible = userInCore?.value
			}

			//KEEP ONLY THE RESPONSIBLE
			if (
				(!securityGroup.isCurrentUserEditor &&
					currentAction &&
					currentAction.responsible &&
					currentAction.responsible !== '' &&
					currentAction.responsible !== currentUser.username &&
					currentAction.createdBy &&
					currentAction.createdBy !== '' &&
					currentAction.createdBy !== currentUser.username) ||
				(currentAction && currentAction.isPrivate)
			) {
				//IS PRIVATE
				if (currentAction.isPrivate) {
					editSideBarUsersList = [
						{
							value: currentUser.username,
							label: currentUser.name,
						},
					]

					//NOT PRIVATE
				} else {
					let findUser = usersList.find(
						(user) => user.value === currentAction.responsible
					)
					editSideBarUsersList = [
						{
							value: findUser.value,
							label: findUser.label,
						},
					]
				}
			}

			// SET NEW CONTRIBUTORS
			if (
				currentAction?.contributors &&
				typeof currentAction.contributors[0] !== 'object'
			) {
				currentAction.contributors = editSideBarUsersList.filter((user) =>
					currentAction.contributors.includes(user.value)
				)
			}

			// PREPARE DATA TO FEED TO EDIT SIDE BAR
			let newEditSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
				currentAction
					? currentAction
					: ACTION_GET_DEFAULT_ITEM(
							selectedOrganizationId,
							wbsId,
							currentUser.username,
							selectedReviewItem,
							projectId
					  ), // DATA
				ACTION_ITEM_DATA,
				{
					id: currentAction ? currentAction.id : uuid(),
					organizationId: selectedOrganizationId,
					projectId: projectId,
					wbsId: currentAction ? currentAction.wbsId : wbsId,
					displayId: currentAction ? currentAction.displayId : nextDisplayId,
					isNew: currentAction ? false : true, //VERY IMPORTANT
				},
				// SPECIFIC OPTIONS FOR SOME ATTRIBUTES
				[
					// RESPONSIBLE OPTIONS
					{
						key: 'responsible',
						value: editSideBarUsersList,
					},
					// CONTRIBUTORS OPTIONS
					{
						key: 'contributors',
						value: editSideBarUsersList,
					},
				]
			)

			// PASS EDIT SIDE BAR CONTENT TO STATE, AND MAKE editSideBar TRUE
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					editSideBarContent: newEditSideBarContent,
					editSideBarAction: currentAction
						? currentAction
						: ACTION_GET_DEFAULT_ITEM(
								selectedOrganizationId,
								wbsId,
								currentUser.username
						  ),
					editSideBar: true,
					actionView: action ? action : null,
					viewMode: currentAction
						? state.selected.length === 1
							? WBS_BIG_COMPONENT_VIEW_MODE.add
							: WBS_BIG_COMPONENT_VIEW_MODE.edit
						: WBS_BIG_COMPONENT_VIEW_MODE.add,
					textSearchInput: '',
					statusSearchInput: [],
				},
			})
		},
		[
			currentUser.name,
			currentUser.username,
			megaUsersIds,
			nextDisplayId,
			projectId,
			securityGroup.isCurrentUserEditor,
			selectedOrganizationId,
			selectedReviewItem,
			state.selected.length,
			usersList,
			wbsId,
		]
	)

	//IF ACTION DELETED OR PASS INTO PRIVATE AND ANOTHER USER IDSPLAY IT => ISSUE ON SAVE => PASS MESSAGE + UPDATE STATE
	const onDeleteItemIfIssue = useCallback(
		(actionId) => {
			//BACK LIST
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
					scrollToId: state.actionView ? state.actionView.id : null,
					actionView: null,
					selectedViewModeContent: ACTION_DETAILS_KEY_BLOCK[0].key,
					viewModeInputText: '',
					viewModeInputComment: '',
				},
			})

			//UPDATE APP STATE
			appDispatcher({
				type: 'SET_PROJECT_DATA_DELETE',
				source: 'action',
				ids: [actionId],
				toast: {
					display: true,
					color: 'danger',
					message:
						'You have no more access to this action. Or it has been deleted.',
					nbOfmilli: 10000,
				},
			})
		},
		[appDispatcher, state.actionView]
	)

	//ENRICHTEXT HANDLE EDIT
	const handleEditFromComponent = useCallback(() => {
		actionsDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				isEnrichTextEditing: !state.isEnrichTextEditing,
			},
		})
	}, [state.isEnrichTextEditing])

	// SAVE ADD EDIT ON EDIT SIDE BAR
	const onSaveAddEdit = useCallback(
		(e, mutateObject, isNew) => {
			e.preventDefault()

			//INIT
			let graphQLQuery
			let dispatcherQuery
			let updatedObject = {}
			let eventsUpdated = []

			//UPDATE PATH
			if (isNew) {
				mutateObject.path = currentPath
			}

			//TEST IF isPrivate IN MUTATE OBJECT PASS RESPONSIBLE TO
			if (mutateObject.isPrivate) {
				mutateObject.responsible = currentUser.username

				// SET CONTRIBUTORS TO NULL
				mutateObject.contributors = null
			}

			//TEST IF CREATE OR NOT
			if (!isNew) {
				//INIT EVENT
				eventsUpdated = JSON.parse(state.actionView.events)

				//LOOP ON UPDATED FIELDS
				for (let attribute of Object.keys(mutateObject)) {
					//FLAG IS NOT THESE ATTRIBUTES
					if (
						attribute !== 'isPrivate' &&
						attribute !== 'displayId' &&
						attribute !== 'id' &&
						attribute !== 'organizationId' &&
						attribute !== 'projectId' &&
						attribute !== 'wbsId' &&
						attribute !== 'comments' &&
						attribute !== 'contributors' &&
						attribute !== 'createdBy'
					) {
						//TEST IF WE HAVE ALREADY ADDED THE EVENT
						let eventIndex = FUNC_ACTION_NEED_TO_ADD_EVENT(
							eventsUpdated,
							attribute,
							currentUser.username
						)

						//MANAGE UPDATED VALUE / LAST UPDATED
						let updatedValue = mutateObject[attribute]
						let lastValue = state.actionView[attribute]
						let userIndex

						//MANAGE IT FOR RESPONSIBLE
						if (attribute === 'responsible') {
							//UPDATE DVALUE
							if (updatedValue) {
								userIndex = FUNCT_FIND_INDEX(usersList, 'value', updatedValue)
								if (userIndex > -1) {
									updatedValue = usersList[userIndex].label
								}
							}

							//LAST VALUE
							if (lastValue) {
								userIndex = FUNCT_FIND_INDEX(usersList, 'value', lastValue)
								if (userIndex > -1) {
									lastValue = usersList[userIndex].label
								}
							}
						}

						//PUSH NEW EVENTS
						if (eventIndex === -1) {
							eventsUpdated.push({
								user: {
									id: currentUser.username,
									label: currentUser.name,
								},
								createdOn: new Date(),
								value: attribute + ' updated by ' + currentUser.name,
								updatedValue: updatedValue,
								lastValue: lastValue,
							})

							//IF SAME DAY UPDATE VALUE
						} else {
							eventsUpdated[eventIndex].updatedValue = updatedValue
						}
					}
				}

				//DELETE CREATED BY IN CASE OF MUTATION
				delete mutateObject.createdBy

				//INIT GRAPH QL + DISPATCHER
				graphQLQuery = UpdateTelescopeDataAction
				dispatcherQuery = 'SET_PROJECT_DATA_UPDATE'

				updatedObject = {
					...mutateObject,
					updatedAt: new Date(),
					events: JSON.stringify(eventsUpdated),
				}

				//CREATE ADD ALL NEEDED INFO
			} else {
				//MANAGE EVENTS
				eventsUpdated.push({
					user: {
						id: currentUser.username,
						label: currentUser.name,
					},
					createdOn: new Date(),
					value: 'created by ' + currentUser.name,
				})

				// PUSH AN EVENT SPECIFIC FOR STATUS
				eventsUpdated.push({
					user: {
						id: currentUser.username,
						label: currentUser.name,
					},
					createdOn: new Date(),
					value: 'status initialized by ' + currentUser.name,
					updatedValue: mutateObject.status,
					lastValue: null,
					noDisplay: true,
				})

				//GRAPHQL / DISPATCHER INIT
				graphQLQuery = CreateTelescopeDataAction
				dispatcherQuery = 'SET_PROJECT_DATA_CREATE'
				updatedObject = {
					...ACTION_GET_DEFAULT_ITEM(
						selectedOrganizationId,
						wbsId,
						currentUser.username
					),
					...mutateObject,
					updatedAt: new Date(),
					events: JSON.stringify(eventsUpdated),
				}
				mutateObject = {
					...mutateObject,
					createdBy: currentUser.username,
					createdOn: TODAY,
				}
			}

			//DELETE SORT FOR TABLE
			delete mutateObject.sort

			// MANAGE CONTRIBUTORS
			if (mutateObject.contributors) {
				mutateObject.contributors = mutateObject.contributors.map(
					(user) => user.value
				)
				updatedObject.contributors = mutateObject.contributors
			}

			// MANAGE LINKS
			if (mutateObject.links) {
				// UPDATE ALL THE DEPENDENCIES!

				// IF IS NEW, ONLY NEED TO ADD LINKS
				if (isNew) {
					FUNC_ACTION_ADD_NEW_LINKS(
						JSON.parse(mutateObject.links),
						[mutateObject],
						projectData,
						currentUser.username,
						appDispatcher,
						true
					)
				}
				// UPDATING -> MIGHT NEED TO ADD SOME AND REMOVE SOME.
				else {
					const { newLinks, removedLinks } = FUNC_COMPARE_LINKS(
						state.actionView,
						mutateObject
					)

					// CREATE NEW LINKS
					FUNC_ACTION_ADD_NEW_LINKS(
						newLinks,
						[mutateObject],
						projectData,
						currentUser.username,
						appDispatcher,
						true
					)

					// REMOVE LINKS
					FUNC_ACTION_REMOVE_MULTIPLE_LINKS(
						removedLinks,
						mutateObject,
						projectData,
						currentUser.username,
						appDispatcher,
						null
					)
				}
			}

			// UPDATE BACKEND
			mutateGraphql(
				graphQLQuery,
				{
					...mutateObject,
					events: JSON.stringify(eventsUpdated),
					groupEditors: mutateObject.isPrivate
						? []
						: securityGroup.groupEditors,
					groupViewers: mutateObject.isPrivate
						? []
						: securityGroup.groupViewers,
					createdBy:
						state.actionView && state.actionView.createdBy !== ''
							? state.actionView.createdBy
							: currentUser.username,
				},
				appDispatcher,
				onDeleteItemIfIssue
			)

			//UPDATE OR CREATE APP STATE
			appDispatcher({
				type: dispatcherQuery,
				source: 'action',
				objects: [updatedObject],
			})

			// UPDATE BOARD DATA MY ACTIONS
			let boardUpdateObject = {}

			// CREATE BOARD UPDATE OBJECT
			if (isNew) {
				boardUpdateObject = {
					create: [updatedObject],
				}
			} else {
				boardUpdateObject = {
					update: [updatedObject],
				}
			}

			// UPDATE BOARD DATA (MY ACTIONS)
			appDispatcher({
				type: 'SET_UPDATE_BOARD_DATA',
				table: 'myActions',
				data: boardUpdateObject,
			})
			//ACTION DISPATCHER
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					editSideBar: false,
					editSideBarContent: null,
					actionView: { ...state.actionView, ...updatedObject },
					scrollToId: updatedObject.id,
					viewModeInputText: '',
					viewModeInputComment: '',
					isEnrichTextEditing: false,
					selected: [],
				},
			})
		},
		[
			appDispatcher,
			currentPath,
			currentUser.name,
			currentUser.username,
			onDeleteItemIfIssue,
			projectData,
			securityGroup.groupEditors,
			securityGroup.groupViewers,
			selectedOrganizationId,
			state.actionView,
			usersList,
			wbsId,
		]
	)

	//DELETE BTN CLICKED
	const onDeleteSideBar = useCallback(
		(e) => {
			e.preventDefault()

			// RUN ACTION DELETION
			ACTION_FUNC_DELETE_ACTIONS_AND_LINKS(
				state.actionView,
				state.selected,
				projectData,
				appDispatcher
			)

			//UPDATE THIS STATE
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: [],
					viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
					actionView: null,
					selectedViewModeContent: ACTION_DETAILS_KEY_BLOCK[0].key,
					viewModeInputText: '',
					viewModeInputComment: '',
				},
			})
		},
		[appDispatcher, projectData, state.actionView, state.selected]
	)

	// DUPLICATE CLICKED SIDE BAR ------------------------------------------ OK
	const onDuplicateSideBar = useCallback(() => {
		let actionsToDuplicate = null

		//IF ACTION VIEW NOT NULL DUPPLICATE CURRENT ACTION
		if (
			state.actionView &&
			state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.list
		) {
			actionsToDuplicate = [_.cloneDeep(state.actionView)]

			//GET SELECTED ACTION
		} else {
			actionsToDuplicate = _.cloneDeep(state.selected)
		}

		if (actionsToDuplicate.length > 0) {
			// RUN MUTATION AND GET RESULT
			let mutation = FUNC_ACTION_DUPLICATE(
				actionsToDuplicate,
				currentUser,
				biggestDisplayIdNum,
				securityGroup,
				projectData,
				appDispatcher
			)

			// UPDATE LOCAL STATE IF IN VIEW MODE
			if (state.actionView) {
				// GET NEWLY CREATED ACTION
				let newAction = mutation.dispatcher.action.create[0]

				// PUT IT IN THE VIEW MODE
				actionsDispatcher({
					type: 'SET_STATE_OBJECT',
					object: {
						selected: [],
						actionView: newAction,
					},
				})
			}
		}
	}, [
		appDispatcher,
		biggestDisplayIdNum,
		currentUser,
		projectData,
		securityGroup,
		state.actionView,
		state.selected,
		state.viewMode,
	])

	// HANDLE DOWNLOAD
	const onDownloadSideBar = useCallback(() => {
		try {
			// SHOW FETCH TOAST
			appDispatcher({
				type: 'SET_KEY_VALUE',
				key: 'appToast',
				value: {
					display: true,
					color: 'fetch',
					message: APP_TOAST_TXT.downloadingActionData,
				},
			})

			// SET THE SELECTED TO EMPTY
			FUNC_ACTION_PREPARE_DATA_EXPORT(
				state.selected,
				projectData,
				wbsName,
				organizationName,
				usersList
			)

			// UNSELECT ITEMS
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: [],
				},
			})

			// SHOW GOOD TOAST
			appDispatcher({
				type: 'SET_KEY_VALUE',
				key: 'appToast',
				value: {
					display: true,
					color: 'good',
					message: APP_TOAST_TXT.successfullyDownloadedActionData,
				},
			})
		} catch (error) {
			console.log('Error downloading actions data...', error)
			appDispatcher({
				type: 'SET_KEY_VALUE',
				key: 'appToast',
				value: {
					display: true,
					color: 'danger',
					message: APP_TOAST_TXT.errorDownloadingActionData,
				},
			})
		}
	}, [
		appDispatcher,
		organizationName,
		projectData,
		state.selected,
		usersList,
		wbsName,
	])

	////////////////////////////////////////////////////////////
	/// DISPLAY ////////////////////////////////////////////////
	////////////////////////////////////////////////////////////

	//HANDLE EXPAND ARROW CLICK ---------------------------- OK
	const onExpandArrowClick = useCallback(
		(isExpanding, isGraph) => {
			// INIT
			let newExpandMode

			//SWITCH
			if (isReviewMode || state.widthSizeLimit) {
				newExpandMode =
					state.expandMode === ACTION_COMPONENT_EXPAND_TYPES.graphOnly
						? ACTION_COMPONENT_EXPAND_TYPES.tableOnly
						: ACTION_COMPONENT_EXPAND_TYPES.graphOnly
			} else {
				//INIT
				newExpandMode =
					isExpanding === true
						? isGraph
							? ACTION_COMPONENT_EXPAND_TYPES.graphOnly
							: ACTION_COMPONENT_EXPAND_TYPES.tableOnly
						: ACTION_COMPONENT_EXPAND_TYPES.both
			}

			//UPDATE ACTION STATE
			actionsDispatcher({
				type: 'SET_STATE_KEY_VALUE',
				key: 'expandMode',
				value: newExpandMode,
			})
		},
		[isReviewMode, state.expandMode, state.widthSizeLimit]
	)

	// WHEN ACTION ITEM IS CLICKED IN THE TABLE
	const onActionItemClicked = useCallback(
		(action, openLinksTab) => {
			//INIT
			let actionViewItem = action
			let cardInitialState = {}

			//CONVERT DATE
			actionViewItem.createdOn = new Date(action.createdOn)
			actionViewItem.dueDate = new Date(action.dueDate)

			cardInitialState = {
				viewMode: WBS_BIG_COMPONENT_VIEW_MODE.view,
				actionView: actionViewItem,
				selected: [],
			}

			// IF ACCESS FROM THREE DOTS LINK ICON, OPEN CONNECTION TAB
			if (openLinksTab) {
				cardInitialState.selectedViewModeContent = 'connections'
			}

			// SET NEW STATE FOR ACTIONS
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: cardInitialState,
			})

			// UPDATE WBS SELECTED ITEM
			wbsDispatcher({
				type: 'SET_STATE_KEY_VALUE',
				key: 'selectedItem',
				value: actionViewItem.id,
			})
		},
		[wbsDispatcher]
	)

	//GO BACK TO CHART AND TABLE --------------------------- OK
	const onBackSideBar = useCallback(() => {
		// CHECK IF BACK BUTTON HAVE A DESTINATION ITEM ASSOCIATED
		let backButtonResult = WBS_ON_BACK_SIDEBAR(
			wbsNavigationOptions,
			projectData,
			projectId,
			wbsDispatcher,
			appDispatcher
		)

		// IF BACK BUTTON LEADS TO AN OTHER ITEM, EXIT FUNCTION
		if (backButtonResult) {
			return
		}

		actionsDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
				scrollToId: state.actionView ? state.actionView.id : null,
				actionView: null,
				selectedViewModeContent: ACTION_DETAILS_KEY_BLOCK[0].key,
				viewModeInputText: '',
				viewModeInputComment: '',
				goBackOriginItem: null,
			},
		})

		// UPDATE WBS SELECTED ITEM
		wbsDispatcher({
			type: 'SET_STATE_KEY_VALUE',
			key: 'selectedItem',
			value: null,
		})
	}, [
		appDispatcher,
		projectData,
		projectId,
		state.actionView,
		wbsDispatcher,
		wbsNavigationOptions,
	])

	// RESET EDIT SIDE BAR --------------------------------- OK
	const onResetEditSideBar = useCallback(() => {
		actionsDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				editSideBar: false,
				viewMode:
					state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit
						? WBS_BIG_COMPONENT_VIEW_MODE.view
						: WBS_BIG_COMPONENT_VIEW_MODE.list,
				editSideBarContent: null,
			},
		})
	}, [state.viewMode])

	// WHEN CLICKING ON LINK BUTTON IN LEFT SIDE BAR
	const onLinkButtonClick = useCallback(() => {
		actionsDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				isLinkModalShown: true,
			},
		})
	}, [])

	// EXIT LINK MODAL
	const onResetLinkModal = useCallback(() => {
		actionsDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				isLinkModalShown: false,
			},
		})
	}, [])

	// SAVE LINKS CREATED IN THE MODAL
	const onSaveLinks = useCallback(
		(e, newLinks) => {
			let selectedItems

			// USE THE SELECTED ITEMS WITH CHECKBOXES
			if (state.selected?.length > 0) {
				selectedItems = state.selected
			}
			// OTHERWISE, USE ACTION VIEW, USE IT AS SELECTED ITEM
			else {
				selectedItems = [state.actionView]
			}

			// SAVE NEW LINKS
			let updatedActions = FUNC_ACTION_ADD_NEW_LINKS(
				newLinks,
				selectedItems,
				projectData,
				currentUser.username,
				appDispatcher,
				actionsDispatcher
			)

			let dispatchObject = {
				isLinkModalShown: false,
				selected: [],
			}

			// IN ACTION VIEW, UPDATE STATE
			if (state.actionView) {
				dispatchObject.actionView = updatedActions[0]
			}

			// CLOSE MODAL
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: dispatchObject,
			})
		},
		[
			appDispatcher,
			currentUser.username,
			projectData,
			state.actionView,
			state.selected,
		]
	)

	//LEGEND OBJECT WITH COUNTER
	const legendAndCounter = useMemo(() => {
		//INIT
		let legendObject = []
		let cloneActions = []

		//CHOOSE WICH DATA USED
		if (
			state.textSearchInput &&
			state.textSearchInput !== '' &&
			state.statusSearchInput.length === 0
		) {
			cloneActions = _.cloneDeep(filteredActions)
		} else {
			cloneActions = _.cloneDeep(actionData)
		}

		//ONLY IF DATA
		if (cloneActions && cloneActions.length > 0) {
			//LOOP ON ARRAY LIST
			let totalCount = 0
			let totalLateCount = 0
			for (let i = 0; i < ACTION_STATUS_LIST.length; i++) {
				//COUNT
				let currentActions = cloneActions.filter(
					(item) => item.status === ACTION_STATUS_LIST[i].id
				)
				let currentCount = currentActions.length

				//INTEGRATE IF NOT EMPTY
				if (currentCount > 0) {
					//TEST FOR IN PROGRESS AND TODO
					let lateCount = 0
					if (
						ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.todo ||
						ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.inProgress
					) {
						//FIND IF DUE DATE BEFORE TODAY
						lateCount = currentActions.filter((item) => {
							return FUNC_ACTION_IS_LATE(item.status, item.dueDate)
						}).length
					}

					//TOTAL COUNT - RELATED TO CURRENT SELECTION
					totalCount = totalCount + currentCount

					//LATE
					if (lateCount > 0) {
						totalLateCount = totalLateCount + lateCount
					}

					//INTEGRATE
					legendObject.push({
						id: ACTION_STATUS_LIST[i].id,
						name: ACTION_STATUS_LIST[i].name,
						backgroundColor: ACTION_STATUS_LIST[i].backgroundColor,
						color: ACTION_STATUS_LIST[i].color,
						value: currentCount,
						late: lateCount,
					})
				}
			}

			//UNSHIFT LATE
			if (totalLateCount > 0) {
				legendObject.unshift({
					id: 'late',
					name: 'Late',
					value: totalLateCount,
					backgroundColor: 'var(--color-bad)',
					color: 'white',
				})
			}

			//TOTAL
			legendObject.push({
				id: 'total',
				name: 'Total',
				value: totalCount,
			})
		}

		//RETURN OBJECT
		return legendObject
	}, [
		actionData,
		filteredActions,
		state.statusSearchInput.length,
		state.textSearchInput,
	])

	////////////////////////////////////////////////////////////
	/// USE EFFECT /////////////////////////////////////////////
	////////////////////////////////////////////////////////////

	//SCROLL TO LAST ACTION -------------------------------------
	useEffect(() => {
		//CHECK IF scrollToId NOT NULL
		if (state.scrollToId) {
			//GET ACTION ROW ELEMENT
			const getAction = document.getElementById('actionId-' + state.scrollToId)

			//FOUND
			if (getAction) {
				//SCROLL ACTION TABLE
				document.getElementById('actionsTable').scrollTop = getAction.offsetTop

				//SET TO NULL
				actionsDispatcher({
					type: 'SET_STATE_OBJECT',
					object: {
						scrollToId: null,
					},
				})
			}
		}
	}, [state.scrollToId])

	// MANAGE CONFIRMING MOVE
	useEffect(() => {
		// IF NOT NULL AND EMPTY (CONFIRMED MOVE MODAL)
		if (moveModalOptionsItemsArray && moveModalOptionsItemsArray.length === 0) {
			// UPDATE BIG COMPONENT STATE
			actionsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: [],
					actionView: null,
					viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
				},
			})
			// UPDATE APP STATE
			appDispatcher({
				type: 'SET_GLOBAL_STATE',
				options: {
					source: 'appSettings',
					object: {
						moveModalOptions: DEFAULT_STATE_MOVE_MODAL_OPTIONS,
					},
				},
			})
		}
	}, [appDispatcher, moveModalOptionsItemsArray])

	// UPDATE ITEM CARD VIEW IF NAVIGATION OPTIONS UPDATED WHEN ALREADY IN CARD VIEW
	useEffect(() => {
		if (
			wbsNavigationOptions?.goToItemId &&
			state.actionView?.id !== wbsNavigationOptions.goToItemId
		) {
			let newActionView = FIND_OBJECT_ARRAY_ITEM(
				actionData,
				'id',
				wbsNavigationOptions.goToItemId
			)

			if (newActionView) {
				onActionItemClicked(newActionView)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wbsNavigationOptions])

	////////////////////////////////////////////////////////////
	/// COMPONENT RENDER ///////////////////////////////////////
	////////////////////////////////////////////////////////////
	return (
		<React.Fragment>
			{/* BIG COMPONENT STANDARD STRUCTURE */}
			<WbsElementBigStructure
				fieldInformation={fieldInformation}
				handleBigComponent={handleBigComponent}
				projectTitle={wbsName}
				buttonExpand={true}
				isExpendedEnabled={bigComponentIsExpanded}
				functionExpand={bigComponentExpandFunction}
				tooltipInfo='Actions'>
				{/* SIDE BAR  ----------------------------------------------------*/}
				<ActionSideBar
					actionView={state.actionView}
					actionsDispatcher={actionsDispatcher}
					currentUserName={currentUser.username}
					handleMoveItemsClicked={handleMoveItemsClicked}
					isConsolidate={state.isConsolidate}
					isCurrentUserEditor={securityGroup.isCurrentUserEditor}
					isReadOnlyMode={isReadOnlyMode}
					onAddEditClick={onAddEditClick}
					onBackSideBar={onBackSideBar}
					onDeleteSideBar={onDeleteSideBar}
					onDuplicateSideBar={onDuplicateSideBar}
					onLinkButtonClick={onLinkButtonClick}
					onSaveLinks={onSaveLinks}
					onDownloadSideBar={onDownloadSideBar}
					canModifySelected={canModifySelected}
					selected={state.selected}
					responsible={state.actionView ? state.actionView.responsible : null}
					viewMode={state.viewMode}
				/>

				{/* BIG COMPONENT BODY ------------------------------------------*/}
				<WbsElementStructureBody
					key={'actionsBigComponentBody' + wbsId}
					blockFlex='flexBetweenStrech'>
					{/* {actionData.length > 0 ? ( */}
					<React.Fragment>
						{/* ACTION CARD */}
						<ActionCard
							key={
								'actionCard_' +
								(state.actionView && state.actionView.id
									? state.actionView.id
									: 'null')
							}
							action={state.actionView}
							projectData={projectData}
							isExpanded={bigComponentIsExpanded}
							actionId={state.actionView ? state.actionView.id : null}
							wbsList={wbsList}
							editSideBar={state.editSideBar}
							viewModeInputText={state.viewModeInputText}
							viewModeInputComment={state.viewModeInputComment}
							selectedViewModeContent={state.selectedViewModeContent}
							isEnrichTextEditing={state.isEnrichTextEditing}
							handleEditFromComponent={handleEditFromComponent}
							currentUser={currentUser}
							usersList={usersList}
							megaUsersId={megaUsersIds}
							onDeleteComment={onDeleteComment}
							onSaveAddEdit={onSaveAddEdit}
							actionsDispatcher={actionsDispatcher}
							isReadOnlyMode={isReadOnlyMode}
							displayWidth={
								state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ||
								state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit
									? '100%'
									: '0%'
							}
							canModifySelected={canModifySelected}
							wbsDispatcher={wbsDispatcher}
						/>

						{/* CHART */}
						<ActionsChart
							filteredActions={filteredActions}
							onExpandArrowClick={onExpandArrowClick}
							expandMode={state.expandMode}
							displayWidth={
								state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
								state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.edit
									? state.expandMode === ACTION_COMPONENT_EXPAND_TYPES.graphOnly
										? '100%'
										: state.expandMode ===
										  ACTION_COMPONENT_EXPAND_TYPES.tableOnly
										? '0%'
										: '40%'
									: '0%'
							}
						/>

						{/* Actions Table */}
						<ActionTable
							currentUser={currentUser}
							actionData={actionData}
							displayWidth={
								state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
								state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.edit
									? state.expandMode === ACTION_COMPONENT_EXPAND_TYPES.tableOnly
										? '100%'
										: state.expandMode ===
										  ACTION_COMPONENT_EXPAND_TYPES.graphOnly
										? '0%'
										: '60%'
									: '0%'
							}
							editSideBar={state.editSideBar}
							expandMode={state.expandMode}
							filteredActions={filteredActions}
							isCurrentUserEditor={securityGroup.isCurrentUserEditor}
							isReadOnlyMode={isReadOnlyMode}
							legendAndCounter={legendAndCounter}
							megaUsersId={megaUsersIds}
							projectData={projectData}
							selectedList={state.selected}
							statusSearchInput={state.statusSearchInput}
							textSearchInput={state.textSearchInput}
							usersList={usersList}
							viewMode={state.viewMode}
							wbsId={wbsId}
							onClickAdd={() => onAddEditClick()}
							onItemClick={onActionItemClicked}
							onExpandArrowClick={onExpandArrowClick}
							securityGroup={securityGroup}
							actionsDispatcher={actionsDispatcher}
							wbsDispatcher={wbsDispatcher}
							appDispatcher={appDispatcher}
						/>
					</React.Fragment>
					{/* ) : (
                        <WbsNoItem
                            onClickAdd={() => onAddEditClick()}
                            text="Click to Add your first Action"
                        /> */}
					{/* )} */}
				</WbsElementStructureBody>
			</WbsElementBigStructure>

			{/* EDIT SIDE BAR ---------------------------------------------------------------------- */}
			<EditSideBar
				display={state.editSideBar}
				content={state.editSideBarContent}
				title={
					state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add
						? 'Create Action'
						: 'Edit Action'
				}
				group={ACTION_GROUP_ITEMS}
				onSave={onSaveAddEdit}
				firstAddUpdatedFields={['responsible', 'dueDate', 'status', 'links']}
				resetEditSideBar={onResetEditSideBar}
				currentUser={currentUser}
				projectData={projectData}
				isCurrentUserEditor={securityGroup.isCurrentUserEditor}
			/>

			{/* LINK TO OTHER DOMAINS MODAL */}
			{state.isLinkModalShown ? (
				<ActionLinkModal
					projectData={projectData}
					onResetModal={onResetLinkModal}
					onSaveLinks={onSaveLinks}
				/>
			) : null}
		</React.Fragment>
	)
})
