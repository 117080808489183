import { CvtInput, CvtCheckbox} from '@mi-gso/cvt'
import React, { useCallback, useMemo, useState } from 'react'
import { APP_FORM_STYLE } from '../../../../00-Core/Constants'

//FILTER TYPE DATE COMPONENT
export default function FiltersTypeDate({
    item,
    value,
    handleDateFilterChange
}) {

  ///////////////////////////////////////////////////////////////////
  /// STATE /////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  //STATE DATE RANGE
  const [state, setState] = useState({
    dateRange : value[1] && item.dateRange ? true : false,
    startErr : "",
    endErr: ""
  })

  ///////////////////////////////////////////////////////////////////
  /// MEMO //////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  //SWITCH LEGEND
  const legend = useMemo(()=>[
    <div 
      key="date-filter"
      className="flexStartCenter"
    >
      <div 
        className='filterCheckBoxTxtAvatar'
        style={{
          fontWeight:500,
          marginLeft:"0px"        
        }}
      >
          Date Range
      </div>
    </div>
  ],[])

  /////////////////////////////////////////////////////////////////
  /// FUNCTIONS ///////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////

  //SWITCH DATE RANGE ---------------------------------------------
  const handleSwitchRange = useCallback(() => {

    //RESET START FILTER VALUE 
    if(state.dateRange){
      handleDateFilterChange(
        "reset",
        null,
        item.subDomain
      )
    }

    //UPDATE THIS STATE
    setState(prevState => {
      return({
        ...prevState,
        dateRange: !prevState.dateRange,
        startErr : "",
        endErr: ""
      })
    })

  },[handleDateFilterChange, item.subDomain, state.dateRange])

  //RETURN
  return (
    <div 
      style={{
        position:"relative"
      }}
    >

      {/* SWITCH DATE TO DATE RANGE */}
      {item.dateRange ?
        <div
          style={{
            position:"absolute",
            top: "-28px",
            right: "0px"
          }}
        >
          <CvtCheckbox 
              value={state.dateRange}
              name="deadlineDateRange"
              titleSize="12px"
              padding="5px 0px"
              width="100%"
              marginTop="0px"
              jsx={legend}
              noFocusBoxShadow
              checkboxContainerWidth="100%"
              handleFunction={handleSwitchRange}
          />
        </div>
      :null}

      {/* START DATE */}
      {state.dateRange ? 
        <CvtInput 
            legend="Start"
            legendWidth="25px"
            legendFontSize="12px"
            inputWidthFull
            type="date"
            placeholder="Pick a date"
            value={value[1] ? value[1] : ""}
            max={value[0] ? value[0] : null}
            mandatory={false}    
            isOnViewMode={false}
            inputBlockCss="flexStartCenter  "
            inputBlockPadding="10px 0px"
            inputCss="form-control modalInputLight " 
            inputFontSize="12px"
            inputPadding={APP_FORM_STYLE.inputPadding}
            onChange={(e) => handleDateFilterChange("start",e.target.value, item.subDomain)}
        />
      :null}

      {/* END DATE */}
      <CvtInput
          legend={state.dateRange ? "End" : null}
          legendWidth={state.dateRange  ? "25px" : null}
          legendFontSize={state.dateRange  ? "12px" : null}
          inputWidthFull
          type="date"
          placeholder="Pick a date"
          value={value[0] ? value[0] : ""}
          min={value[1] ? value[1] : null}
          mandatory={false}    
          isOnViewMode={false}
          inputBlockCss="flexStartCenter  "
          inputBlockPadding="10px 0px"
          inputCss={"form-control modalInputLight " + (value[1] && !value[0] ? "modalInputAlert" : "")}
          inputFontSize="12px"
          inputPadding={APP_FORM_STYLE.inputPadding}
          onChange={(e) => handleDateFilterChange("end",e.target.value, item.subDomain)}
      />
    </div>
  )
}
