import {Auth} from 'aws-amplify';
import { authCleanUserDataCookie } from './AuthSignOut';

////////////////////////////////
// SUMMARY                    //   
// 01 - forgotPassword        //
// 02 - forgotPasswordSubmit  //
// 03 - completeNewPassword   //
// 04 - changePassword        //
////////////////////////////////

//OK NEW AUTH

/////////////////////////////////////////////////////////////////////////////////////
/// FORGOT PASSWORD => NEED USERNAME ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export async function forgotPassword(
    username,
    appDispatcher
){

    // Send confirmation code to user's email
    await Auth.forgotPassword(username).then(() => {

        //UPDATE FRONT
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    resetPassword: true,
                    errorMessage: false,
                }
            }
        })}
    
    //ERR
    ).catch((err) => {

        //DISPLAY ERR MESSAGE
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })}
    )
}

/////////////////////////////////////////////////////////////////////////////////////
/// SUBMIT NEW PASSWORD WITH CODE ///////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export async function forgotPasswordSubmit(
    username,
    code,
    newPassword,
    appDispatcher,
    authenticatorSetState
){

    // Send confirmation code to user's email
    await Auth.forgotPasswordSubmit(username, code, newPassword).then(() => {

        //RESET TO SIGN IN
        appDispatcher({
            type:"SET_USER_SIGN_OUT"
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false
            })
        })}

    //ERR
    ).catch((err) => {

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false
            })
        })}
    )
}

/////////////////////////////////////////////////////////////////////////////////////
/// SUBMIT COMPLETE NEW PASSWORD PASSWORD WITH CODE /////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export async function completeNewPassword(
    user,
    userDomain,
    newPassword,
    name,
    appDispatcher,
    authenticatorSetState
){

    // Send confirmation code to user's email
    await Auth.completeNewPassword(
        user,               
        newPassword,      
        {
          name: name,
          "custom:domain": userDomain,
          "custom:terms": "to be validate",
          "custom:validateTermsAt": "to be validate",
        }
    ).then(() => {

        //GET UPDATED USER
        Auth.currentAuthenticatedUser({bypassCache: true}).then((updatedUser) =>{

            //IF NEED TO VALIDATE EMAIL
            if(!updatedUser.attributes.email_verified){

                //NED VERIFYING EMAIL
                appDispatcher({
                    type:"SET_GLOBAL_STATE",
                    options:{
                        source: "currentUser",
                        object: {
                            needVerifyEmail:true,
                            needNewPassword: false,
                            errorMessage: false,
                        }
                    }
                })

                //RESET LOADER
                authenticatorSetState(prevState => {
                    return({
                        ...prevState, 
                        loader:false,
                        errName: ""
                    })
                })

            //NEED SET UP MFA
            }else{

                //LAUCNH CHOOSE OF MULTIFACTOR
                appDispatcher({
                    type:"SET_GLOBAL_STATE",
                    options:{
                        source: "currentUser",
                        object: {
                            user: updatedUser,
                            needMfaSetUp: true,
                            needNewPassword: false,
                            username: updatedUser.username,
                            name: updatedUser.attributes.name,
                        }
                    }
                })

                //RESET LOADER
                authenticatorSetState(prevState => {
                    return({
                        ...prevState, 
                        loader:false
                    })
                })
            }

            //DELETE USERDATA COOKIE
            authCleanUserDataCookie()
        })

    //ERR
    }).catch((err) => {

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false,
                errName: ""
            })
        })}
    )
}

/////////////////////////////////////////////////////////////////////////////////////
/// CHANGE PASS WORD => ONLY WITH CONNECTED USER ////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export async function changePassword(
    oldPassword, 
    newPassword, 
    userInfosSet
){

    //TRY TO CHANGE THE USER PASSWORD
    await Auth.currentAuthenticatedUser().then((user) => {

        //RETURN
        return Auth.changePassword(user, oldPassword, newPassword)}
    
    //IF PASSWORD CHANGED 
    ).then(() => { 

        //UPDATE FRONT
        userInfosSet(prevState => {
            return({
                ...prevState, 
                loader: false,
                value:"",
                err:null,
                oldPassword:"",
                newPassword1:"",
                newPassword2:""
            })
        })

        //DELETE USERDATA COOKIE
        authCleanUserDataCookie()

    //ERR
    }).catch((err) => {

        //DISPLAY ERR MESSAGE
        userInfosSet(prevState => {
            return({
                ...prevState, 
                loader:false,
                err:err.message
            })
        })

        //DELETE USERDATA COOKIE
        authCleanUserDataCookie()
    });
}