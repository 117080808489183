import { arrayToTree } from 'performant-array-to-tree'
import {APP_CORE_SANDBOX} from './Constants'

///////////////////////////////////////////
// SUMMARY
// - selectionCoreOrganizations
// - selectionCoreProjects
// - selectionNestedWbsData
// - selectionUpdateLevelNestedWbsData
// - selectionWbsIdList
///////////////////////////////////////////


/////////////////////////////////////////////////////////////////////
/// ORGA ADD SANDBOX ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const selectionCoreOrganizations = (
    coreOrganization
)=>{

    //ADD SANDBOX TO ORGANIZATION LIST
    return [...coreOrganization, APP_CORE_SANDBOX]
}

/////////////////////////////////////////////////////////////////////
/// PROJECT ADD SANDBOX /////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const selectionCoreProjects = (
    organizationId,
    coreProjects
)=>{

    //IF ORGANIZATION DIFF SANDBOX 
    if(organizationId !== APP_CORE_SANDBOX.id){

        //GET PROJECT RELATED TO ORGANIZATION ID
        const newLocal = coreProjects;
        return newLocal.filter(item => item.coreDataOrganizationCoreDataProjectId === organizationId);

    //IF NOT RETURN SANDBOX
    }else{
        return [{
            id: APP_CORE_SANDBOX.id,
            name: APP_CORE_SANDBOX.name
        }];
    }
}

/////////////////////////////////////////////////////////////////////
/// NESTED WBS //////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const selectionNestedWbsDataForPortfolio = (
    wbsList,
    projects,
    projectToRemove
) => {

    //FOR PROJECT AND PORTFOLIO WE ABSOLUTELY NEED
    // id 
    // parentId 
    // childId
    // projectUsersGroup 
    // projectMegaUsersGroup 
    // organizationMegaUsersGroup 
    // sort
    // name
    // level

    //TEST IF WBS and projects AVAILABLE AN
    if(wbsList.length > 0 && projects.length > 0){  

        //INIT
        let rebuildWbsList = [];

        //LOOP ON PROJECT
        for (let i = 0; i < projects.length; i++) {

            //DO NOT INCLUDED IF PROJECT INSIDE PROJECT TO REMOVE LIST
            if(!projectToRemove?.includes(projects[i].id)){

                //RECUP WBS LIST
                let currentWbsChildren = wbsList.filter(item => item.coreDataProjectCoreDataWbsId === projects[i].id )
                
                //CHILD ID
                let currentWbsChildId = currentWbsChildren.map(item => item.id)

                //CREATION OF THE CURRENT PROJECT BRANCHE
                rebuildWbsList.push({
                    ...projects[i],
                    parentId: null,
                    sortProject: i,
                    level:1,
                    childId: currentWbsChildId,
                    isProject:true
                })

                //UPDATE AND PUSH ALL CHILD
                currentWbsChildren.forEach(child => {

                    //INIT
                    let currentChild = child;

                    //IF CURRENT PARENT ID === NULL PASS THE PROJECT ID
                    if(!child.parentId) child.parentId = projects[i].id

                    //PUSH rebuildWbsList
                    rebuildWbsList.push({
                        ...currentChild,
                        sortProject: i,
                        isProject:false,
                        level:currentChild.level + 1,
                    })
                });
            }
        }

        //SORT AND FILTER WITH PROJECT FILTER
        let wbsDataSorted = rebuildWbsList.sort(function(a, b){
            if(a.sortProject < b.sortProject) { return -1; }
            if(a.sortProject > b.sortProject) { return 1; }
            if(a.sort < b.sort) { return -1; }
            if(a.sort > b.sort) { return 1; }
            return 0;
        })
        
        //RETURN
        return arrayToTree(wbsDataSorted,{ dataField: null })

    }
    return [];
}

// NORMAL NESTED DATA FOR ONE PROJECT
export const selectionNestedWbsData = (
    wbsList,
    projectId,
    organizationId,
) => {

    //TEST IF WBS AVAILABLE AND PROJECT SELECTED AND ORGA DIFF SANDBOX
    if(wbsList.length > 0 
    && projectId !== "" 
    && organizationId !== APP_CORE_SANDBOX.id
    ){  

        //SORT AND FILTER WITH PROJECT FILTER
        let wbsDataSorted = wbsList.sort(function(a, b){
            if(a.sort < b.sort) { return -1; }
            if(a.sort > b.sort) { return 1; }
            return 0;
        })
        
        //RETURN
        return arrayToTree(wbsDataSorted,{ dataField: null })
    }
    return [];
}

/////////////////////////////////////////////////////////////////////
/// WBS UPDATE LEVEL ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const selectionUpdateLevelNestedWbsData = (
    nestedWbsData,
) => {

    //TEST IF NESTEDWBSDATA
    if(nestedWbsData){


        return ((function updateLevel(nestedWbsDataWithLevel, depth) {

            //LEVEL MANAGEMENT
            if (typeof depth == 'number'){
                depth++;
            }else{
                depth = 0;
            }
            
            //MAP CHILDREN
            return nestedWbsDataWithLevel.map(item => {

                //LAUNCH AGAIN IF CHILDREN
                if(item.children && item.children.length > 0){
                    updateLevel(item.children, depth)
                }

                //UPDATE LEVEL
                item.level = depth

                //RETURN
                return item
            }
        )})(nestedWbsData,""))

    //RETURN EMPTY ARRAY
    }else{
        return null
    }
}

/////////////////////////////////////////////////////////////////////
/// WBS ID LIST CREATION + HYPHEN ///////////////////////////////////
/////////////////////////////////////////////////////////////////////



//WBS ID LIST WITH HYPHEN AND NUMBER OF TEMPLATE
export const selectionWbsIdList = (
    updateLevelNestedWbsData,
    wbsList,
    organizationId,
    awsDataPlans
) => {

    // INIT -----------------------------------------------------
    let idList = [];
    let name;
    let count = 0;

    //MANAGE HYPHEN RELATED TO LEVEL ----------------------------
    const levelHyphen = (level) =>{
                    
        let hyphen = "";

        //LOOP
        if(level !== 0){
            for (let j = 0; j < level; j++) {
                hyphen = hyphen + "--";
            }
        }

        //RETURN
        return hyphen;
    }

    //COUNT NUMBER OF TEMPLATE ----------------------------------
    const nbOfTemplate = (wbsId) => {
        return awsDataPlans.filter(item => item.wbsId === wbsId).length
    }

    //TEST IF NOT NULL -------------------------------------------
    if(updateLevelNestedWbsData 
    && wbsList 
    && wbsList.length > 0 
    && organizationId !== APP_CORE_SANDBOX.id
    ){

        //RECURSIVE FUNCTION TO SORT ID
        (function createSortedList(nestedWbsDataWithLevel) {

            //LOOP
            for (let i = 0; i < nestedWbsDataWithLevel.length; i++) {

                //COUNT TEMPLATE IF NEEDED
                if(awsDataPlans){
                    count = nbOfTemplate(nestedWbsDataWithLevel[i].id)
                }

                //MANAGE NAME
                name = levelHyphen(nestedWbsDataWithLevel[i].level) + " " + nestedWbsDataWithLevel[i].name + (count > 0 ? " (" + count + ")" : "")

                //OPEN DISPLAY ONLY BRANCH WITH TEMPLATES
                if(awsDataPlans){
                    if(count > 0){
                        idList.push({
                            id: nestedWbsDataWithLevel[i].id,
                            name: name,
                        })
                    }
                
                //SAVE DISPLAY ALL BRANCHES
                }else{
                    idList.push({
                        id: nestedWbsDataWithLevel[i].id,
                        name: name,
                    })
                }

                //IF CHILD AGAIN
                if(nestedWbsDataWithLevel[i].children && nestedWbsDataWithLevel[i].children.length > 0){
                    createSortedList(nestedWbsDataWithLevel[i].children)
                }
            }
        })(updateLevelNestedWbsData)
    }

    //IF SANDBOX
    if(organizationId === APP_CORE_SANDBOX.id){
        idList = [{
            id: APP_CORE_SANDBOX.id,
            name: APP_CORE_SANDBOX.name
        }]
    }

    //RETURN
    return idList;
}