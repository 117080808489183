// REACT
import { memo, useCallback } from "react";

// COMPONENTS
import RiskCardImpactProba from "./RiskCardImpactProba";
import RiskCardMatrix from "./RiskCardMatrix";

////////////////////////////
///  RISK CARD MATRIX    ///
////////////////////////////

export default memo(function RiskCardMatrixImpact({
    // DATA
    roItem,
    riskCardImpactProbaOpenView,
    // FUNCTIONS
    handleDrop,
    // handleEditRoItem,
    onSaveAddEdit,
    riskOppDispatcher,
    canModifyRo,
}) {
    /////////////////////////////////////////////////////////
    /// FUNCTIONS ///////////////////////////////////////////
    /////////////////////////////////////////////////////////

    // HANDLE SWITCHING BETWEEN MATRIX AND IMPACT VIEWS
    const handleSwitchingViews = useCallback(
        (e, newView) => {
            e.preventDefault();

            // UPDATE IN RISK BIG STATE
            riskOppDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: "riskCardImpactProbaOpenView",
                value: newView,
            });
        },
        [riskOppDispatcher]
    );

    /////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ///////////////////////////////////////
    ////////////////////////////////////////////////////////////

    return (
        <div
            className="flexCenterCenter flexOne flipContainer riskCardMatrixImpactFlipContainer"
            style={{
                transform: riskCardImpactProbaOpenView !== null ? "rotateY(180deg)" : "",
            }}
        >
            {/* MATRIX */}
            <RiskCardMatrix
                flipCard={riskCardImpactProbaOpenView !== null}
                roItem={roItem}
                handleDrop={handleDrop}
                handleSwitchingView={handleSwitchingViews}
            />

            {/* IMPACT */}
            <RiskCardImpactProba
                roItem={roItem}
                handleSwitchingView={handleSwitchingViews}
                onSaveAddEdit={onSaveAddEdit}
                riskCardImpactProbaOpenView={riskCardImpactProbaOpenView}
                canModifyRo={canModifyRo}
            />
        </div>
    );
});
