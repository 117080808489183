// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { picTELGov1, picTELGov2, picTELGov3, picTELGov4 } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `

# Presentation

This column is where you are going to link and gather everything around
the governance that you put in place.

The governance column is different from the other columns because it
gathers two features: the possibility to create scope changes (on the
left part) and the agenda of your project on the right part.

The agenda is where you can manage your governance by creating the
meetings you need and link it with all kinds of items created in the
other columns. You can create your meetings and invite the right persons
directly in Telescope. You are able to define the agenda by adding the
different items (risks&opps, actions, scope changes) that must be
reviewed during the meeting. The review mode of the meeting allows you
to generate the Minutes of Meeting while navigating through the
different items that need to be reviewed in the controls view. You are
able at any time to read the MoM of your past meetings.

In the governance column, the pill KPI enables you to see:  
> **1**.  The number of open items  
**2**.  The number of "pending" items (in red)  
**3**.  The number of "on hold" items (in yellow)


![picTELGov1](${picTELGov1} "" )

As explained above, the big component is divided in two parts: on the left
the scope changes list, on the right the agenda with all the meetings. 

![picTELGov2](${picTELGov2} "" )

   
> **1**.  Add a scope change    
**2**.  Show only the items of the branch    
**3**.  The list of scope changes    
**4**.  Filter on the level of the scope change (branch, project or
    portfolio)    
**5**.  Filter on the status of the scope changes (on hold, pending or
    close)    
**6**.  The search bar which enable you to filter according to the name of
    the scope change    
**7**.  The number total of scope changes    
**8**.  The level     
    -   Br = Branch    
    -   Pr = Project       
    -   Po = Portfolio    
**9**          The Id of the scope change  
**10**. The name of the scope change        
**11**. The status of the scope change        
**12**. The due date        
**13**. The responsible of the scope change        
**14**. The agenda        
**15**. A meeting that has been closed        
**16**. A recurring meeting that has been started (but not yet closed)        
**17**. A recurring meeting that hasn't started        
**18**. A meeting that hasn't started        
**19**. Show the today's date        
**20**. Change the timescale of the agenda: Daily, weekly, monthly

When you click on a scope change, a new
view is displayed with more details. Every user has access to this view
(in view mode or edit mode according to his rights). 

![picTELGov3](${picTELGov3} "" )

> **1**.  Return to the scope change list     
**2**.  Edit the scope change     
**3**.  Duplicate the scope change     
**4**.  Delete the scope change     
**5**.  Move the scope change in an other branch or project     
**6**.  The responsible of the item     
**7**.  Item's name     
**8**.  Status: the status of the scope change (on hold, pending or closed)     
**9**.  Decision: the decision status of the scope change (approved,
    pending, refused)     
**10**. Description: the reason of this scope change     
**11**. Decision details: Information on the decision made     
**12**. The comments that have been made on this scope change (any user can
    add one)     
**13**. The connections between the scope changes and other items     
**14**. List of all the events on this item (creation date, created by, all
    modifications of the fields of the item, etc.)     
**15**. Expend to have this view in big screen     
**16**. Close the big component

The meetings in the agenda have different colors:

-   Blue: a none recurrent meeting
-   Orange: a recurrent meeting
-   Yellow: a meeting that is started (feature available in review mode)
-   Green: a meeting that is closed (feature available in review mode)

The review mode enables you to create the Minutes of Meeting of your
review: when the meeting starts, open the review mode. It will open on
the left side:

![picTELGov4](${picTELGov4} "" )     
> **1**.  Left side panel with the controls view and all the columns     
**2**.  Right side panel with the review mode     
**3**.  Close the meeting (edit button if the meeting is not started)     
**4**.  Name of the review     
**5**.  Date of the review     
**6**.  Status of the review (Not started, In progress, Closed)     
**7**.  The Timeline of all the modifications     
**8**.  Add a new item in the agenda to be reviewed during this meeting     
**9**.  Items to be reviewed during the meeting. If you click on the item,
    it opens in the left side panel     
**10**. The notes taken during the meeting    
**11**. The attendees of the meeting    
**12**. The details of the item in the agenda

***

# Add an item

## Description

Any user is able to add a scope change. As soon as an item is added, it
will be visible in the table.

## Process

Click on the pill KPI of the WBS where you want to add your item: the
big component opens. You have two options to create a new scope change:

> **1**. In the component's sidebar, click on the "add" button (+)        
**2**. Or click on "Create Scope Change" button at the bottom of the list

Once you have clicked on one of these buttons, an edit forms opens at
the right. Fill at least the mandatory fields and click on "Save" at the
top right corner.

Here are the different fields:


-   **Title** (mandatory): the name of your scope change
-   **Level** (mandatory): the level of the item (no impact for now)
-   **Responsible** (mandatory): the person in charge of this scope change
-   **Status** (mandatory): the status of the item. By default, "Pending" si
    selected, but you can also choose "On hold" or "Closed"
-   **Due date** (mandatory): the deadline before which a decision needs to
    be 
-   **Instance to review this item**: Select the meeting during which this
    item needs to be reviewed. Ne marche pas, à suppr ?
-   **Description**: description of the scope change
-   **Choices**: the choices made according to the scope change
-   **Decision**: the decision that was taken by the team (Approved, pending
    or refused)
-   **Decision details**: the details of the decision that was taken

***

# Edit an item

## Description

To modify one or several fields of an item, you can edit it.

    WARNING: OMU and PMU can edit all schedule items. A PU can only edit the scope change he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens >
Click on the edit button (pencil) of the sidebar > Edit form opens >
Modify the fields you want and click on "save".

If you want to discard the changes, click on the close button or outside
the edit form and click a second time to confirm.

***
# Duplicate an item

## Description

You can duplicate an item if you want. It will be duplicated in the same
branch (if you want to move it to another branch/project, see the "move
an item" section).

    WARNING: OMU and PMU can duplicate all schedule items. A PU can only duplicate the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens >
Click on the duplicate button of the sidebar > Click a second time to
confirm > a new item is created with exactly the same information and
with "(duplicate)" at the biggening of the name.

***

# Delete an item

## Description

Items can be deleted if necessary

    WARNING: OMU and PMU can delete all schedule items. A PU can only delete the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens >
Click on the delete button (bin) of the sidebar > Click a second time
to confirm

***

# Move an item

## Description

If you have created an item in the wrong WBS branch, don't worry! You
can move it to the right one. You are even able to move it in a
different project that belongs to the same organization. In brief, you
can move an item anywhere within an organization (as long as you have
access to the new project).

    WARNING: OMU and PMU can move all schedule items. A PU can only move the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens >
Click on the move button of the sidebar > Click a second time to
confirm

***

# Show only the items of the branch

## Description

By default, all the items are consolidated on the parent WBS branches:
on a WBS branch which have child, you see the items created on the WBS
branch but also the items created on the children. It is possible to
unable that and hide the children's items.

## Process

In the big component side bar, click on "only this branch's items"
button (second one of the sidebar). This will apply only on this branch.

If you want to apply this feature on all your WBS branches, see the
"General Sidebar" section

***

#  Filter items according to the status

## Description

It is possible to filter the items visible in the list by status.

## Process

To filter your items according to the status, click on the drop-down
list status and select the status you want to filter on.

To remove your filter(s), click on the unfilter icon (the cross).

***

#  Filter items according to the level

## Description

It is possible to filter the items visible in the list by level.

## Process

To filter your items according to the status, click on the drop-down
list level and select the level(s) you want to filter on.

To remove your filter(s), click on the unfilter icon (the cross).

***

#  Search for items

## Description

If you want to find a precise item, you can use the search bar. The
search is done on the item name only.

## Process

In the search bar, type a key word and the items are filtered
automatically.

***

#  Open the detailed view

## Description

If you click on an item of the list, the Card view opens and you will
have more details (cf pictures in intro).

    WARNING: Any user can open the detailed view. From this view, if you have the rights, you can edit the description, add comments, create an action plan
    (actions will be visible also in the action column and identified by an icon) and change the assessments of the risk.

## Process

In the list of the big component, click on the item of the list for
which you want to see more details > the detailed view opens

***

# Edit the description form detailed view

## Description

In the detailed view, you can directly edit the description (without
entering the edit mode).

    WARNING: OMU and PMU can edit the description of all items. A PU can only edit the items he has created.

## Process

In the list of the big component, click on the item for which you want
to change the description > the detailed view opens > Click in the
description box > Update the description > click outside of the box to
save the changes.

    WARNING: It is not possible to discard the changes

***

# Edit the decision details form detailed view

## Description

In the detailed view, you can directly edit the decision details
(without entering the edit mode).

    WARNING: OMU and PMU can edit the description of all items. A PU can only edit the items he has created.

## Process

In the list of the big component, click on the item for which you want
to change the decision details > the detailed view opens > Click in
the decision box > Update the text > click outside of the box to save
the changes.

    WARNING: It is not possible to discard the changes

***
# Add a comment

## Description

In the detailed view, you can add comment(s) on the item.

    WARNING: Any user can add a comment

## Process

In the list of the big component, click on the item for which you want
to add a comment > the detailed view opens > Click on "Comments" >
Type your comment in the "New comment" zone > click on "Validate" to
publish your comment.

***

# Edit a comment

## Description

In the detailed view, you can edit comment(s) you have created.

## Process

In the list of the big component, click on the item for which you want
to modify your comment > the detailed view opens > Click on "Comments" > Click on the pencil button to edit your comment > make the changes
you want > Click on validate to save the changes.

***

# Delete a comment

## Description

In the detailed view, you can delete comment(s) you have created.

## Process

In the list of the big component, click on the item for which you want
to delete your comment > the detailed view opens > Click on "Comments" > Click on the bin button to delete your comment > click a second time
to validate.

***

# View all the events that occurred on the item

## Description

In the detailed view, you can see all the events that occurred on the
item: creation date, created by, all modifications of the fields of the
item (responsible, description, etc.)

## Process

In the list of the big component, click on the item for which you want
to see the events > the detailed view opens > Click on "Events"

***

# Create a meeting

## Description

You can create meetings around which your governance is organized.

## Process

Click on the pill KPI of the WBS where you want to add your item: the
big component opens. Click on the agenda in the right side of the
component (anywhere or directly at the time you want to create your
meeting).

An edit forms opens at the right. Fill at least the mandatory fields and
click on "Save" at the top right corner.

Here are the different fields:

-   **Name** (mandatory): name of the review/meeting
-   **Tags**: you can write anything you want
-   **Review Start** (mandatory): The start date and hour of the meeting
-   **Review End** (mandatory): The end date and hour of the meeting
-   **Attendees**: Select the persons that must assist this meeting (only
    the persons who have access to the project are visible)
-   **Objectives**: the objectives of the meeting
-   **Chairman**: the chairman of the review
-   **Recurrency**: Activate it if you want to create a recurring meeting.
    If activated, you have to fill the followings:
-   **Periodicity** (mandatory): by week
-   **The day for the recurrency** (mandatory). If it is a daily meeting,
    select all the days
-   **The date at which the recurrency ends** (mandatory)
-   **The agenda**: Select all the items (scope changes, actions or
    risks&Opps) that have to be reviewed during this meeting. Click on "New item" and fill:
    -   The title for this item (mandatory)
    -   The type of item (mandatory): actions, risks&opps or scope change
    -   Select the item you want to review during this meeting (mandatory)
    -   Add the expected decision

***

# Edit a meeting

## Description

To modify one or several fields of a meeting, you must edit it.

    WARNING: OMU and PMU can edit all meetings. A PU can only edit the meeting he has created.

## Process

In the big component, click on the meeting you want to edit > Edit form
opens on the right > Modify the fields you want and click on "save".

If you want to discard the changes, click on the close button or outside
the edit form and click a second time to confirm.

If it is a recurring meeting, you will be asked if you want to edit the
whole series or only the occurrence.

***

# Delete a meeting

## Description

Items can be deleted if necessary

    WARNING: OMU and PMU can delete all schedule items. A PU can only delete the items he has created.

## Process

In the big component, do a right click on the meeting you want to delete > Click on "Delete" > click a second time to confirm.

If it is a recurring meeting, you will be asked if you want to delete
the whole series or only the occurrence.

***

# Open Review mode

## Description

When the meeting begins, you can open the review mode, it will display
on the right side and you will still have a vision of the control view
on the left side.

This review mode enables you to select the attendees, write notes and
also go through your agenda : open the different items of the agenda,
and see the details on the left side panel

## Process

In the big component, do a right click on the meeting for which you want
to open the review mode > Click on "Open review mode" > The review
mode opens on the right side panel.

***

# Edit Review mode

## Description

Once the review mode is open, you can edit it (if you have the rights).

    WARNING: OMU and PMU can edit all the review modes of the project. A PU can only edit the review mode of a meeting he has created.

## Process

On the review mode, click on Edit button in the side bar ("Start the
meeting"). You can now edit it: write notes and select the attendees

***

# Open the items of the agenda

## Description

During the meeting, you are able to open the items that have been added
in the agenda and needs to be reviewed during this meeting.

## Process

On the review mode, click on the items of the agenda. The details are
opened in the left side panel

***

# Add a new item in the agenda

## Description

During the meeting, you can add an item that needs to be reviewed and
hasn't been added yet.

## Process

On the review mode, click "New item" and fill:

-  The title for this item (mandatory)
- The type of item (mandatory): actions, risks&opps or scope change
- Select the item you want to review during this meeting (mandatory)
- Add the expected decision

***

# End a meeting

## Description

Once the meeting is finished and you have taken your notes, selected the
attendees and went through the agenda, you can close the meeting. You
will still be able to reopen it to modify your notes

## Process

On the review mode, click on "End the meeting" in the side bar.

***

# Close and lock a meeting

## Description

Once the Minutes of Meeting is finished and ready to share, you can
Close and lock it. After that, no one will be able anymore to modify the
notes. The MoM is only accessible in view mode for every user

## Process

On the review mode, click on "Close and lock" in the side bar.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;