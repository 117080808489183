// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { 
    picTELSchedule1, 
    picTELSchedule2, 
    picTELSchedule3, 
    // picTELShortcuts3 
} from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `

# Presentation

In the schedule column, you are able to follow your planning items
(tasks, milestones, input, output, etc.) and the advancement of your
projects.

The pill KPI enables you to see:

> **1**.  The number of open items  
**2**.  The number of closed items (in green)  
**3**.  The number of late items (in red)


![picTELSchedule1](${picTELSchedule1} "" )

The big component is divided in two parts: on the left the S-Curve of
all your schedule items, on the right the detailed list of all your
items (with the ID, the name, the status, the responsible, the
forecasted end date, etc.).

![picTELSchedule2](${picTELSchedule2} "" )

> **1**.  Add an item  
**2**.  Show only the items of the branch  
**3**.  S-Curve   
**4**.  Slicer to modify the time scale of the graph   
**5**.  Button to show the vision at completion  
**6**.  Add a second Y-axis     
**7**.  Button to expend the graph     
**8**.  The list of schedule items (by default, the done items are not
    displayed unless there are only done items in the list)     
**9**.  Filters on the status     
**10**. Search bar     
**1.** Number of items displayed (dynamic with filters)     
**12**. Button to select all items     
**13**. Item ID     
**14**. Item name     
**15**. Icon to indicate the item is coming from a lower branch in the WBS.
    By overing it, the branch is displayed     
**16**. "New" icon if the action has been created in the past 7 days     
**17**. Item date depending on its status:        
         - If the status is different from "Done": Forecast Finish date           
         - If the status is Done: Actual Finish date     
**18**. Item status                
    - **To do**: the forecast start is in the future             
    -   **In progress**: today's date is between baseline start and baseline
        finish              
    -   **In the past**: the forecast end date is in the past and there is
        no actual end date        
    -   **Done**: the actual finish date is in the past     
**19**. Item responsible

By default, the done items are not displayed in the list. On the graph,
we see by default the items of the current year.

    WARNING: If you extend the list, you will have more details on the items: a visual on the different dates (baseline, forecast and actuals).  
    The visual differs whether the item is a task or a milestone.
<br>

If you click on an item of the list, a detailed view of this item is displayed:

![picTELSchedule3](${picTELSchedule3} "" )

> **1**.	Return to the schedule's list      
**2**.	Edit item      
**3**.	Duplicate item      
**4**.	Delete item      
**5**.	Move item in an other branch or project      
**6**.	Responsible of the item      
**7**.	Item’s ID      
**8**.	Item’s name      
**9**.	Description: the description of the task or milestone      
**10**.	The comments that have been made on this item (any user can add one)      
**11**.	List of all the events on this item (creation date, created by, all modifications of the fields of the item, etc.)   
**12**.	The action plan of the item      

    
***

# Add an item

## Description

Any user is able to add a schedule item. As soon as an item is added, it
will be visible in the table and on the S-Curve.

## Process

Click on the pill KPI of the WBS where you want to add your item: the
big component opens. You have two options to create a new schedule item:

> **1**. In the component's sidebar, click on the "add" button (+)  
**2**. Or click on "Create Schedule" button at the bottom of the list

Once you have clicked on one of these buttons, fill at least the
mandatory fields and click on "Save" at the top right corner.

Here are the different fields:

-   **Name** (mandatory): the name of your item
-   **Responsible** (mandatory): the person in charge of this item
-   **Type** (mandatory): the type of the item, it can be a milestone or a
    task
-   **Baseline** (mandatory): the baseline start and finish date if your
    item is a task, or just one date if it is a milestone
-   **Forecast** (mandatory): the forecast start and finish date if your
    item is a task, or just one date if it is a milestone
-   **Actual**: the actual start and finish date if your item is a task, or
    just one date if it is a milestone

***

# Edit an item

## Description

To modify one or several fields of an item, you can edit it.

    WARNING: OMU and PMU can edit all schedule items. A PU can only edit the schedule he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item > Click on the edit button (pencil)
of the sidebar > Edit form opens > Modify the fields you want and
click on "save".

If you want to discard the changes, click on the close button or outside
the edit form and click a second time to confirm.

***

# Duplicate an item

## Description

You can duplicate an item if you want. It will be duplicated in the same
branch (if you want to move it to another branch/project, see the "move
an item" section).

    WARNING: OMU and PMU can duplicate all schedule items. A PU can only duplicate the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item > Click on the duplicate button of
the sidebar > Click a second time to confirm > a new item is created
with exactly the same information and with "(duplicate)" at the
biggening of the name.

***

# Delete an item

## Description

Items can be deleted if necessary

    WARNING: OMU and PMU can delete all schedule items. A PU can only delete the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item > Click on the delete button (bin) of
the sidebar > Click a second time to confirm

***

# Move an item

## Description

If you have created an item in the wrong WBS branch, don't worry! You
can move it to the right one. You are even able to move it in a
different project that belongs to the same organization. In brief, you
can move an item anywhere within an organization (as long as you have
access to the new project).

    WARNING: OMU and PMU can move all schedule items. A PU can only move the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item > Click on the move button of the
sidebar > Click a second time to confirm

***

# Show only the items of the branch

## Description

By default, all the items are consolidated on the parent WBS branches:
on a WBS branch which have child, you see the items created on the WBS
branch but also the items created on the children. It is possible to
unable that and hide the children's items.

## Process

In the big component side bar, click on "only this branch's items"
button (second one of the sidebar). This will apply only on this branch.

If you want to apply this feature on all your WBS branches, see the
"General Sidebar" section/

***

# Filter items according to the status

## Description

It is possible to filter the items visible in the list by status. By
default, the visible items have the status "To do", "In progress" or "In
the past".

## Process

To filter your items according to the status, click on the status you
want to filter (you can click on several). The status not selected are
grayed out.

To remove your filter(s), click on the unfilter icon. If there is not
this icon, it means you are not filtered and are on the default view
(all status except "Done").

***

# Search for items

## Description

If you want to find a precise item, you can use the search bar. The
search is done on the item name only.

## Process

In the search bar, type a key words and the items are filtered
automatically.



***

# Expand the list to have more details

## Description

If you expand the list, you will have more details on each item: the
timeline of the different dates (Baseline Start/Finish compared the the
Forecast Start finish and the actual start/finish)

## Process

In the big component, click on the arrow at left corner of the list >
the S-Curve disappears and you have the timeline of the different dates
for each item

***

# Open the detailed view

## Description
If you click on an item of the list, the Card view opens and you will have more details (cf pictures in intro). 
    
    WARNING: Any user can open the detailed view.
>

    WARNING From this view, if you have the rights, you can edit the description, add comments, create an action plan
    (actions will be visible also in the action column and identified by an icon) and change the assessments of the risk.

## Process
In the list of the big component, click on the item for which you want to see more details > the detailed view opens.        
In order to go back to the list, click on the return button.
***

# Expand the graph

## Description

You can expend the graph to have a better visualization of your S-Curve.

## Process

In the big component, click on the arrow at right corner of the graph >
the list disappears.

***

# Change the year of the graph

## Description

By default, the S-Curve only show the current year. It is possible to
change the year, in the future or in the past.

## Process

In the big component, next to the S-Cruve title:

- Click on the "+" to switch to the next year
- Click on the "-" to switch to the past year

***

# Vision at completion

## Description

By default, the S-Curve only show the current year. It is possible to
have a vision at completion or on several years that you can choose.

## Process

In the big component, click on the "At completion" button > graph
extend automatically > you can select the first and last year you want
to see.

***

# Change the y-axis

## Description

By default, the S-Curve have only one y-axis. You can add a second axes
if you want. By doing so, you will have the curves on the first axe
(Baseline, forecasted cumu, completed cumu) and the bars on the second
axe (In the past, To do, In progress, Done)

## Process

In the big component, on the graph, click on the "+" or "-" button next
to "Y axis" > the second y-axis is added or removed.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;