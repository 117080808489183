import { CvtInput } from '@mi-gso/cvt'
import React from 'react'

//FILTER TYPE NUMBER COMPONENT
export default function FiltersTypeNumber({
    item,
    value,
    handleFilterChange,
}) {
  return (
    <CvtInput
      type="number"
      inputBlockCss="flexStartCenter modalOptionSubBlock"
      inputPadding="4px 4px 4px 8px"
      inputCss="form-control modalInputLight " 
      inputFontSize="12px"
      inputWidth="50px"
      inputBlockPadding="5px 0px 8px 0px"
      inputBlockMargin="0px"
      name={item.subDomain}
      value={value}
      min={item.min}
      max={item.max}
      onChange={(e) => handleFilterChange(e.target.value, item.subDomain)}
      inputUnitCss="bigComponentBodyMiniBlockTitleUnit"
      inputUnit={item.min + " <= x <= " + item.max}
  />
  )
}
