export default function PortfolioPicture({ pictureUrl, name }) {

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexCenterCenter"
            style={{
                width: "100%",
                height: "90px",
                backgroundColor: "var(--border-color-gray-0)",
                borderRadius: "8px",
                overflow: "hidden",
            }}
        >
            {pictureUrl ? (
                // CASE THERE'S A PICTURE
                <img
                    src={pictureUrl}
                    alt={"Image " + name}
                    style={{
                        width: "100%",
                    }}
                />
            ) : (
                // NO PICTURE, SHOW LOGO
                <span className="material-symbols-outlined flexCenterCenter boardRoundedImage">
                    image
                </span>
            )}
        </div>
    );
}
