import React, { useMemo, memo, useState, useCallback } from "react";

// FUNCTIONS
import { FUNC_ACTIONS_CHART_HISTO, FUNC_ACTIONS_CHART_DATA } from "./ActionChartData";

// COMPONENTS
import {
    ComposedChart,
    LabelList,
    Bar,
    XAxis,
    Line,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import {
    CvtCommonXAxisTick,
    CvtCommonYAxisTick,
    CvtCommonLabelBar,
    CvtCommonLegend,
    CvtCommonToolTip,
} from "@mi-gso/cvt";

// CORE
import {
    ACTION_COMPONENT_EXPAND_TYPES,
    ACTION_STATUS_KEY,
    ACTION_TXT_CONVERSION,
} from "../../00-Helpers/ActionsConstants";
import ActionGraphCommand from "./ActionChartCommandBar";
import { Button } from "@mi-gso/cvt";

////////////////////////
/// CHARTS COMPONENT ///
////////////////////////

export default memo(function ActionsChart({
    filteredActions,
    onExpandArrowClick,
    expandMode,
    displayWidth,
}) {
    /////////////////////////////////////////////////////////////
    /// STATE MANAGEMENT ////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    //STATE
    const [forecastedWeek, setForecastedWeek] = useState(12);

    /////////////////////////////////////////////////////////////
    /// TREATMENT ///////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    //CHANGE FORECASTED WEEK NUMBER OF CHART ---------------- OK
    const onChangeForecastedWeekCount = useCallback(
        (e, isAdd) => {
            e.preventDefault();

            // Minimum forecastedWeek is 4, so decrease button becomes disabled when forecastedWeek is 4.
            if ((isAdd && forecastedWeek < 25) || (!isAdd && forecastedWeek > 4)) {
                //INIT
                let newForecastedWeek = isAdd ? forecastedWeek + 1 : forecastedWeek - 1;

                //SET NEW VALUE
                setForecastedWeek(newForecastedWeek);
            }
        },
        [forecastedWeek]
    );

    /////////////////////////////////////////////////////////////
    /// TREATMENT ///////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    //GET HISTO -------------------------------------------- NOK
    const newActionsHisto = useMemo(
        () => FUNC_ACTIONS_CHART_HISTO(forecastedWeek, filteredActions),
        [filteredActions, forecastedWeek]
    );

    //GET CHART DATA --------------------------------------- NOK
    const newGetChartData = useMemo(
        () => FUNC_ACTIONS_CHART_DATA(newActionsHisto),
        [newActionsHisto]
    );

    /////////////////////////////////////////////////////////////
    /// RETURN COMPONENT ////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    //!!!!! optimize chart in a map ...

    return (
        <div
            className="flexColStartStart actionsChartContainer"
            style={{
                width: displayWidth,
                display: displayWidth === "0%" ? "none" : null,
            }}
        >
            {/* CHART HEADER -------------------------------------- */}
            <div className="actionsChartHeader flexBetweenCenter">
                {/* ICON + TITLE + COMMAND BAR */}
                <div className="flexStartCenter">
                    {/* ICON + TITLE */}
                    <div className="flexStartCenter">
                        {/* ICON */}
                        <span className="material-icons actionsChartHeaderIcon">insights</span>

                        {/* TITLE */}
                        <span className="actionsChartHeaderTitle">Burning Curve</span>
                    </div>

                    {/* COMMAND BAR */}
                    <ActionGraphCommand
                        forecastedWeek={forecastedWeek}
                        onChangeForecastedWeekCount={onChangeForecastedWeekCount}
                    />
                </div>

                {/* EXPAND ARROW */}
                <Button
                    icon={
                        expandMode === ACTION_COMPONENT_EXPAND_TYPES.graphOnly
                            ? "chevron_left"
                            : "chevron_right"
                    }
                    css="btn commonButtonSubmitLight leftComponentExpandButton"
                    borderRadius="20px"
                    iconFontSize="16px"
                    handleSubmit={() =>
                        onExpandArrowClick(
                            expandMode === ACTION_COMPONENT_EXPAND_TYPES.graphOnly ? false : true,
                            true
                        )
                    }
                />
            </div>

            {/* CHART ---------------------------------------------------- */}
            {displayWidth !== "0%" ? (
                <div className="actionsChartBody">
                    <AutoSizer>
                        {({ width, height }) => (
                            <ComposedChart
                                data={newGetChartData}
                                margin={{ top: 10, right: 0, left: -30, bottom: 1 }}
                                width={width}
                                height={height}
                            >
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis
                                    tickMargin={2}
                                    dataKey="week"
                                    height={40}
                                    tick={<CvtCommonXAxisTick />}
                                    interval="preserveStartEnd"
                                />
                                <YAxis
                                    type="number"
                                    tickCount={3}
                                    interval={"preserveStartEnd"}
                                    tickMargin={8}
                                    tick={<CvtCommonYAxisTick />}
                                />
                                <Tooltip
                                    content={<CvtCommonToolTip txtConverter={ACTION_TXT_CONVERSION} />}
                                />
                                <Legend
                                    wrapperStyle={{ bottom: 0, left: -8 }}
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="bottom"
                                    content={<CvtCommonLegend txtConverter={ACTION_TXT_CONVERSION} />}
                                />

                                {/* LATE */}
                                <Bar
                                    type="monotone"
                                    dataKey={ACTION_STATUS_KEY.late}
                                    stackId="a"
                                    fill="var(--color-bad)"
                                >
                                    <LabelList
                                        dataKey={ACTION_STATUS_KEY.late}
                                        content={<CvtCommonLabelBar color="var(--color-bad)" />}
                                    />
                                </Bar>

                                {/* TO DO */}
                                <Bar
                                    type="monotone"
                                    dataKey={ACTION_STATUS_KEY.todo}
                                    stackId="a"
                                    fill="var(--color-text-5)"
                                >
                                    <LabelList
                                        dataKey={ACTION_STATUS_KEY.todo}
                                        content={<CvtCommonLabelBar color="var(--color-text-5)" />}
                                    />
                                </Bar>

                                {/* IN PROGRESS */}
                                <Bar
                                    type="monotone"
                                    dataKey={ACTION_STATUS_KEY.inProgress}
                                    stackId="a"
                                    fill="var(--color-migso-blue)"
                                >
                                    <LabelList
                                        dataKey={ACTION_STATUS_KEY.inProgress}
                                        content={<CvtCommonLabelBar color="var(--color-migso-blue)" />}
                                    />
                                </Bar>

                                {/* ON HOLD */}
                                <Bar
                                    type="monotone"
                                    dataKey={ACTION_STATUS_KEY.onHold}
                                    stackId="a"
                                    fill="var(--color-migso-yeallow)"
                                >
                                    <LabelList
                                        dataKey={ACTION_STATUS_KEY.onHold}
                                        content={
                                            <CvtCommonLabelBar color="var(--color-migso-yeallow)" />
                                        }
                                    />
                                </Bar>

                                {/* DONE */}
                                <Bar
                                    type="monotone"
                                    dataKey={ACTION_STATUS_KEY.done}
                                    stackId="a"
                                    fill="var(--color-migso-green)"
                                >
                                    <LabelList
                                        dataKey={ACTION_STATUS_KEY.done}
                                        content={
                                            <CvtCommonLabelBar color="var(--color-migso-green)" />
                                        }
                                    />
                                </Bar>

                                {/* LINE */}
                                <Line
                                    type="monotone"
                                    dataKey="planned"
                                    stroke="var(--border-color-gray-4)"
                                    strokeWidth={3}
                                    fill="var(--border-color-gray-4)"
                                    dot={{ stroke: "var(--border-color-gray-4)", strokeWidth: 4 }}
                                />
                            </ComposedChart>
                        )}
                    </AutoSizer>
                </div>
            ) : null}
        </div>
    );
});
