//STEP TABLE DATA
export const HOME_CREATE_PORTFOLIO_STEP_TABLE_DATA = [
    {key:"CreatePortfolioText1", text:"Define your organizations and projects you want to see in your Portfolio"},
    {key:"CreatePortfolioText2", text:"Select your coworker and invite them to access your Portfolio"},
    {key:"CreatePortfolioText3", text:"Work together and share information in your Portfolio view"}
]

export const HOME_TAKE_A_LOOK_STEP_TABLE_DATA = [
    {key:"takeALookText1", text:"Have a look on your last activities in Telescope"},
    {key:"takeALookText2", text:"Have a synthetic view for all your actions"},
    {key:"takeALookText3", text:"Check at anytime your next reviews"}
]

export const HOME_CONTROL_STEP_TABLE_DATA = [
    {key:"controlText1", text:"Monitore your different Project Management axes: Governance, Risk&Ops, Schedule, etc."},
    {key:"controlText2", text:"Update and implement data directly in the application to control your projects"},
    {key:"controlText3", text:"Have a consolidated view of all your projects at the portfolio level"}
]

export const HOME_SECURITY_TABLE_DATA = [
    {key:"securityTableText1", text:"Data in transit encrypted with minimum protocol TLS1.2"},
    {key:"securityTableText2", text:"Data stored closed to users and client"},
    {key:"securityTableText3", text:"Client environment partitioned to ensure high level of confidentiality management"},
    {key:"securityTableText4", text:"Recurring audits and tests performed from external certified partner to ensure best level of security management"},
]

export const HOME_GREEN_TABLE_DATA = [
    {key:"greenTableText1", text:"API call to server are optimized"},
    {key:"greenTableText2", text:"Notifications to users is limited to essential information"},
    {key:"greenTableText3", text:"DevOps process and methods respectful to environment"},
    {key:"greenTableText4", text:"Use of better technologies to improve performance from client side"},
]


// CHAPTER IDS
export const HOME_CHAPTER_IDS = {
    telescopeNutShell: "TELESCOPE_NUTSHELL",
    contactUs: 'CONTACT_US',
}