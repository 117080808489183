import { CvtEnrichTextEditor, FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import React from "react";
import { ACTION_TXT_CONVERSION } from "../../../01-Action/00-Helpers/ActionsConstants";
import { FUNC_CHECK_STRING_IS_DATE } from "../../../../../00-Core/Standards";

/////////////////////////////
/// COMPONENT EVENTS LIST ///
/////////////////////////////

export default function GovScopeViewDetailsEvents({ eventsArray, usersList, projectData }) {
    //////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER /////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    // IF NO DATA
    if (eventsArray.length === 0) {
        return null;
    }
    
    return eventsArray.map((event, index) => {
        //GET SURRENT DATE OBJECT
        let currentDate = new Date(event.createdOn);

        //GET CURRENT DATE TEXT
        let dateText = currentDate.toLocaleString(undefined, {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
        });

        //UPDATED VALUE
        let oldValue = null;
        let newValue = null;
        let attribute;
        let text;
        let isDate;

        // CASE ACTIONS ID ADDED
        if (event?.attribute === "actionsIds") {
            // GET USER NAME
            let username = FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.user.id, "label");
       
            // GET USER NAME FROM LABEL
            if (username) {
                username = username.split(" ")[0];
            } else {
                username = "an unknown user";
            }

            text = "Action dependencies added by " + username;

            newValue = event.newValue;
        }
        // ACTIONS ID REMOVED
        else if (event?.attribute === "actionsIdsRemoval") {
            // GET USER NAME
            let username = FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.user.id, "label");

            // GET USER NAME FROM LABEL
            if (username) {
                username = username.split(" ")[0];
            } else {
                username = "an unknown user";
            }

            text = "Action dependencies removed by " + username;

            newValue = event.newValue;
        }
        // GOV REVIEW IDS UPDATED
        else if (event?.attribute === "govReviewIds") {
            // GET USER NAME
            let username = FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.user.id, "label");

            // GET USER NAME FROM LABEL
            if (username) {
                username = username.split(" ")[0];
            } else {
                username = "an unknown user";
            }

            text = "Associated Governance Reviews updated by " + username;
        }

        // OTHER CASES : GET LAST AND UPDATED VALUE IF NOT DESCRIPTION
        // else if (!event?.attribute?.includes("description")) 
        else {
            //LAST VALUE
            if (event.oldValue) {
                //TEST IF DATA
                isDate = Date.parse(event.oldValue) || null;

                //LOCAL STRING IF DATE
                if (isDate && !event.oldValue?.includes(" ")) {
                    oldValue = new Date(event.oldValue).toLocaleString(undefined, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    });

                    //MANAGE TEXT WITH CONVERSION
                } else {
                    attribute = event.oldValue.split(" ");
                    oldValue = event.oldValue;
                    if (ACTION_TXT_CONVERSION[attribute[0]]) {
                        oldValue = oldValue.replace(
                            attribute[0],
                            ACTION_TXT_CONVERSION[attribute[0]]
                        );
                    }
                }
            }

            //UPDATED VALUE
            if (event.newValue) {
                //TEST IF DATA
                isDate = (Date.parse(event.newValue) || null) && FUNC_CHECK_STRING_IS_DATE(event.newValue);

                //IF DATE GET LOCALE STRING
                if (isDate) {
                    
                    newValue = new Date(event.newValue).toLocaleString(undefined, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    });
                   
                    //MANAGE TEXT WITH CONVERSION
                } else {
                    attribute = event.newValue.split(" ");
                    newValue = event.newValue;
                    if (ACTION_TXT_CONVERSION[attribute[0]]) {
                        newValue = newValue.replace(
                            attribute[0],
                            ACTION_TXT_CONVERSION[attribute[0]]
                        );
                    }
                 
                }
            }
        }
        
        if (
            event.attribute !== "actionsIds" &&
            event.attribute !== "actionsIdsRemoval" &&
            event.attribute !== "govReviewIds"
        ) {
            // GET CONVERSION TEXT
            if (event.attribute) {
                attribute = event.attribute.split(" ");
            } else {
                attribute = event.value.split(" ");
            }
            text = event.attribute || event.value;
            if (ACTION_TXT_CONVERSION[attribute[0]]) {
                text = text.replace(attribute[0], ACTION_TXT_CONVERSION[attribute[0]]);
            }
        }
        
        // IF RESPONSIBLE
        if(event.attribute === "responsible" || event?.value?.includes("responsible")) {
            // GET USER NAME
            let newUserLabel = FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.newValue, "label");
            let oldUserLabel =  FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.oldValue, "label");     
            if(oldUserLabel) {
                oldValue = oldUserLabel; 
            }
            if(newUserLabel) {
                newValue = newUserLabel;
            }
        }

        if(event.attribute === "createdBy") {
            
            text = event.value ?? "Created by: " + FIND_OBJECT_ARRAY_ITEM(usersList, "value", event.user.id, "label");
        }
        //////////////
        /// RENDER ///
        //////////////
 
        return (
            <div 
                key={`gov_scope_eventEvent_${event.attribute ?? ""}_${index}_${text}`} 
                className="actionCardDetailsEvent flexStartStart"
            >
                {/* DATE */}
                <div
                    className="actionCardInfosProgressBarDateLegend"
                    style={{
                        marginRight: "5px",
                    }}
                >
                    {dateText}
                </div>

                {/* RIGHT BLOCK */}
                <div style={{ width: "100%" }}>
                    {/* VALUE */}
                    <div
                        style={{
                            fontWeight: 500,
                            textTransform: 'capitalize'
                        }}
                    >
                        {text}
                    </div>

                    <div className="flexStartCenter" style={{ width: "100%" }}>
                        {/* LAST VALUE IF AVAILABLE */}
                        {
                            (event.attribute === "decision" || 
                            event.attribute === "decisionDetails" ||
                            event.attribute === "choices") && oldValue
                        ?
                            (
                                <CvtEnrichTextEditor 
                                    isOnViewMode={true}
                                    value={oldValue}
                                    editorCss="govScopeViewDetailsDescriptionNewValue"
                                />
                            )
                        :
                        event.attribute !== "actionsIds" &&
                        event.attribute !== "actionsIdsRemoval" &&
                        event.attribute !== "govReviewIds" &&
                        newValue ? (
                            <React.Fragment>
                                <span
                                    style={{
                                        color: "var(--color-text-4)",
                                        maxWidth: "50%",
                                        wordBreak: "break-word",
                                    }}
                                >
                                    {oldValue ? oldValue : "none"}
                                </span>
                                <span
                                    className="material-icons"
                                    style={{
                                        fontSize: "20px",
                                        margin: "0px 5px",
                                    }}
                                >
                                    navigate_next
                                </span>
                            </React.Fragment>
                        ) : null}

                        {/* UPDATED VALUE OR JUST EVENT VALUE */}
                        {event.attribute === "actionsIds" ||
                        event.attribute === "actionsIdsRemoval" ? (
                            <ul
                                style={{
                                    color: "var(--color-migso-blue)",
                                    maxWidth: "50%",
                                    wordBreak: "break-word",
                                    marginBottom: "0px",
                                    paddingInlineStart: "20px",
                                }}
                            >
                                {newValue.map((actionId, index) => {
                                    // GET ACTION
                                    let action = FIND_OBJECT_ARRAY_ITEM(
                                        projectData.action,
                                        "id",
                                        actionId
                                    );

                                    if (!action) {
                                        return <li key={`gov_scope_action_deleted_${actionId}_${index}`}>Action deleted</li>;
                                    }

                                    return <li key={`gov_scope_change_new_value_${actionId}_${index}`}>{action.displayId + " : " + action.action}</li>;
                                })}
                            </ul>
                        ) : (
                                event.attribute === "description" || 
                                event.attribute === "decisionDetails" ||
                                event.attribute === "choices"
                            ) ? (
                            <CvtEnrichTextEditor 
                                isOnViewMode={true}
                                value={newValue}
                                editorCss="govScopeViewDetailsDescriptionNewValue"
                            />
                        ) : (
                            <span
                                style={{
                                    color: "var(--color-migso-blue)",
                                    maxWidth: "50%",
                                    wordBreak: "break-word",
                                }}
                            >
                                {newValue}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    });
}
