import { CvtInputTextArea } from "@mi-gso/cvt";
import React, { useCallback, useMemo, useState } from "react";

//CORE
import { piclogoDefault } from "../../../../00-Core/02-Pictures/PicturesCatalogue";
import { APP_WBS_STRUCTURE } from "../../../../00-Core/Constants";
import { FUNCT_FIND_INDEX, FUNC_GET_USER_INFO } from "../../../../00-Core/Standards";


//CHARTER
import {
    CHARTER_DETAILS_KEY_BLOCK,
    CHARTER_LIST_PHASE,
    CHARTER_LIST_TYPE,
} from "../00-Helpers/CharterConstants";

//PROJECT DETAIL COMPOENT
export default function CharterBigProjectDetails({
    selectedProject,
    content,
    bigComponentIsExpanded,
    usersList,
    megaUsersIds,
}) {
    /////////////
    /// STATE ///
    /////////////

    //STATE MANAGE DETAILS
    const [state, setState] = useState({
        selectedDetails: "description",
    });


    //CHANGE DETAIL
    const handleChangeDetails = useCallback(
        (e, detail) => {
            e.preventDefault();

            //ONLY IF IT4S DIFFERENT
            if (detail !== content[state.selectedDetails]) {
                //SET STATE
                setState((prevState) => {
                    return {
                        ...prevState,
                        selectedDetails: detail,
                    };
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [content, state ]
    );

    //////////////////////////////////////////////////
    /// DATA /////////////////////////////////////////
    //////////////////////////////////////////////////

    //GET RESPONSIBLE OBJECT ------------------------------------------------
    const leaderObject = useMemo(() => {
        if (content.leader) {
            return FUNC_GET_USER_INFO(usersList, megaUsersIds, content.leader);
        }
        return null;
    }, [content.leader, megaUsersIds, usersList]);

    //TYPE
    const type = useMemo(() => {
        if (content.type) {
            let findIndex = FUNCT_FIND_INDEX(CHARTER_LIST_TYPE, "id", content.type);
            if (findIndex > -1) {
                return CHARTER_LIST_TYPE[findIndex];
            }
        }
        return null;
    }, [content.type]);

    //PHASE
    const phase = useMemo(() => {
        if (content.phase) {
            let findIndex = FUNCT_FIND_INDEX(CHARTER_LIST_PHASE, "id", content.phase);
            if (findIndex > -1) {
                return CHARTER_LIST_PHASE[findIndex];
            }
        }
        return null;
    }, [content.phase]);

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////
    return (
        <div
            className=""
            style={{
                padding: "0px 25px",
                width: "65%",
            }}
        >
            {/* TITLE */}
            <div className="flexStartStart">
                {/* LOGO */}
                <img
                    src={selectedProject.logo !== "" ? selectedProject.logo : piclogoDefault}
                    className="commonOrganizationLitleLogo"
                    style={{
                        width: "62px",
                        height: "62px",
                        fontSize: "10px",
                    }}
                    alt=""
                />

                {/* BLOCK NAME +DOM */}
                <div className="flexColStartStart">
                    {/* NAME */}
                    <span className="commonOrganizationName commonListItemLight">
                        {selectedProject.name}
                    </span>

                    {/* LEADER */}
                    <div className="flexStartCenter">
                        {/* ICON */}
                        <span className="material-icons commonlitleIconLeftText">person</span>

                        {/* MAIL */}
                        <div className="commonListItemSubTitle">
                            {leaderObject ? leaderObject.label : "No defined Leader"}
                        </div>
                    </div>

                    {/* DEPARTMENT */}
                    {content.department ? (
                        <div className="flexStartCenter">
                            {/* ICON */}
                            <span className="material-icons commonlitleIconLeftText">
                                maps_home_work
                            </span>

                            {/* TEXT */}
                            <div className="commonListItemSubTitle">{content.department}</div>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* TAGS */}
            <div
                className="flexStartCenter"
                style={{
                    margin: "15px 0px 15px 0px",
                }}
            >
                {/* TYPE */}
                {type ? (
                    <div
                        className="commonTagBlock flexCenterCenter"
                        style={{
                            backgroundColor: "rgba(3, 169, 219, 0.5)",
                            color: "white",
                            padding: "0px 10px",
                            borderRadius: "20px",
                            marginRight: "10px",
                            height: "27.5px",
                        }}
                    >
                        {type.name}
                    </div>
                ) : null}

                {/* Phase */}
                {phase ? (
                    <div
                        className="commonTagBlock flexCenterCenter"
                        style={{
                            backgroundColor: "rgba(3, 169, 219, 0.5)",
                            color: "white",
                            padding: "0px 10px",
                            borderRadius: "20px",
                            marginRight: "10px",
                            height: "27.5px",
                        }}
                    >
                        {phase.name}
                    </div>
                ) : null}
            </div>

            {/* DETAILS */}
            <div
                className="biComponentNavBarCard"
                style={{
                    // minHeight:"163px",
                    maxHeight: bigComponentIsExpanded
                        ? "calc(100% - " +
                          (40 + APP_WBS_STRUCTURE.bigComponentHeaderHeight * 2) +
                          "px)"
                        : "calc(100% - " +
                          (80 + APP_WBS_STRUCTURE.bigComponentHeaderHeight) +
                          "px)",
                }}
                
            >
                <ul
                    className="nav nav-tabs"
                    style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#FFFFFF",
                    }}
                    overflow="auto"
                >
                    {/* MAP DETAIL */}
                    {CHARTER_DETAILS_KEY_BLOCK.map((item, index) => (
                        <li key={"detailTab-" + index} className="nav-item ">
                            <a
                                className={
                                    "nav-link bigComponentNavItem " +
                                    (state.selectedDetails === item.key ? "active" : "")
                                }
                                href="/#"
                                onClick={(e) => handleChangeDetails(e, item.key)}
                            >
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ul>

                {/* DISPLAY DETAIL */}
                <div
                    className="bigComponentBodyMiniBlockInfoProject"
                    style={{
                        backgroundColor: "#FFFFFF",
                        borderBottom: "none",
                    }}
                    
                >
                    {content[state.selectedDetails] !== "" ? (
                        <CvtInputTextArea
                                key={`charter_big_project_details_${state.selectedDetails}and${state.propKeyIncrement}}`}
                                type="richTextArea"
                                inputBlockCss="flexColStartStart modalOptionSubBlock"
                                inputPadding="0px"
                                inputBlockHeight="65%"
                                inputCss="form-control modalInputLight"
                                inputBlockPadding="0px"
                                inputBlockMargin="0px"
                                inputWidthFull={true}
                                name="input"
                                value={content[state.selectedDetails] ? content[state.selectedDetails] : "null"}
                                enrichTitleCss="enrichTextTilteSideBar"
                                enrichEditorCss="modalInputEnrichTxt"
                                isOnViewMode={true}
                                headingStylesSelectDirection="down"
                        />
                    ) : (
                        <React.Fragment>
                            <div className="flexStartCenter">
                                <span className="material-icons commonlitleIconLeftText">info</span>
                                No Information
                            </div>
                            <div
                                className="commonEmptyTextLine"
                                style={{ width: "75%", marginTop: "8px" }}
                            ></div>
                            <div
                                className="commonEmptyTextLine"
                                style={{ width: "45%", marginTop: "8px" }}
                            ></div>
                        </React.Fragment>
                    )}

                </div>
            </div>
        </div>
    );
}
