import React, { memo, useMemo, useState } from 'react'

//EDIT SIDE BAR GROUP COMPONENT
export default memo(
    function EditSideBarGroup({
        group,
        displayedGroupId,
        numberOfElementNotEmpty,
        numberOfElement,
        numberOfIssue,
        children,
        numMandatoryFilled,
        numMandatoryTotal,
        noCountTag,
        triedToSave,
    }) {
      
        const [state, setState] = useState({
            clickedGroup: false,
        });

        //NUMBER OF ISSUE WITH MANDATORY
        const alerts = numberOfIssue + (numMandatoryTotal - numMandatoryFilled);
        
        const isShowAlerts = useMemo(() => {
            if(group.isDefaultOpen && group.isDefaultOpen === true) {
                return alerts && (state.clickedGroup === true || triedToSave);
            }
            else return alerts;
        }, [alerts, group.isDefaultOpen, state.clickedGroup, triedToSave]);

        //RENDER
        return (
            <div className="rightSideBarGroup">

                {/* GROUP BUTTON */}
                <button 
                    id={group.id}
                    className={"accordion-button rightSideBarGroupButton " + (displayedGroupId === group.id || group.isDefaultOpen ? "" : "collapsed")} 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={"#flushGroup-" + group.id}
                    onClick={() => setState((prevState) => ({...prevState, clickedGroup: true}))}
                >
                    {/* BETWEEN BECAUSE THERE IS AN AFTER IN THE BUTTON  */}
                    <div className={`flexBetweenCenter ${numMandatoryTotal>0 && numMandatoryTotal !== numMandatoryFilled ? "editSideBarGroupMandatoryAlert" : ""}`} style={{width:"100%"}}>

                        {/* LEFT */}
                        <div className='flexStartCenter'>

                            {/* ICON */}
                            <span 
                                className="material-icons rightSideBarGroupButtonIcon"
                                style={{
                                    color: isShowAlerts  ? "var(--color-bad)" : null
                                }}
                            >
                                {group.icon}
                            </span>

                            {/* NAME */}
                            <span
                                style={{
                                    color: isShowAlerts ? "var(--color-bad)" : null
                                }}
                            >
                                {group.name}
                            </span>

                            {/* ISSUES */}
                            {isShowAlerts ? 
                                <div className='flexCenterCenter rightSideBarGroupButtonAlert'>
                                    <span className="material-icons" style={{fontSize:"20px"}}>
                                        warning
                                    </span>
                                    <span className='rightSideBarGroupButtonAlertTxt'>
                                        {alerts}
                                    </span>
                                </div>
                            :null}
                        </div>

                        {/* NUMBER OF COMPLETED FIELD */}
                        {noCountTag === false ?
                            <div className={'rightSideBarGroupButtonInfo ' + (numberOfElementNotEmpty === numberOfElement ? "rightSideBarGroupButtonInfoGreen" : "")}>
                                {numberOfElementNotEmpty}/{numberOfElement}
                            </div> 
                        : null}
                   
                        
                    </div>
                </button>
                
                {/* GROUP CONTENT */}
                <div 
                    id={"flushGroup-" + group.id} 
                    className={"accordion-collapse collapse rightSideBarGroupContent " + (displayedGroupId === group.id || group.isDefaultOpen ? "show" : "")}
                    data-bs-parent="#accordionEditSideBar"
                >
                    {children}
                </div>
            </div>
        )
    }
)