import { memo, useState, useCallback, useMemo } from "react";
import {
  CvtInput,
  CvtModal,
  CvtModalBody,
  CvtModalFooter
} from "@mi-gso/cvt";

//LIBRARIES
import _ from "lodash";
import { Draggable } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { v4 as uuid } from 'uuid';

//GRAPHQL
import{
  createTelescopeDataWbsSettings as CreateTelescopeDataWbsSettings,
  updateTelescopeDataWbsSettings as UpdateTelescopeDataWbsSettings
} from '../../../../graphql/mutations'

//APP
import {mutateGraphql} from '../../../00-App/02-Backend/AppBackendCommon'

//////////////////
/// GROUP ITEM ///
//////////////////

function OrganizeDomainItem({
  domain,
  selectedDomain,
  onGroupSelected,
  ref,
  updateTxt,
  index
}){

  //IS ITEM SELECTED
  const isItemSelected = selectedDomain && selectedDomain.id === domain.id;

  //RENDER
  return (
    <div ref={ref}>
      <div
        className="organizeGroupItem flexBetweenCenter"
        style={{
          backgroundColor: isItemSelected ? "var(--color-migso-blue)": null,
          color: isItemSelected ? "white": null,
        }}
      >

      {/* BLOCK DARG ICON + NAME */}
      <div
        className="flexStartCenter"
        style={{
          width: isItemSelected ? "calc(100% - 30px)" : "calc(100% - 55px)",
          transition:"0.3s"
        }}
      >

        {/* DRAG INDICATOR */}
        <div
          className="material-icons organizeGroupItemDragIndicator"
          style={{
            color:isItemSelected ? "white": null,
          }}
        >
          drag_indicator
        </div>

        {/* GROUP NAME */}
        <div
          className="organizeGroupItemName"
          style={{
            color:isItemSelected ? "white": null,
          }}
        >
          {/* Select COLOR */}
          <CvtInput
            inputBlockCss="flexBetweenCenter modalOptionSubBlockGroup"
            inputCss={"modalInputGroupName " + (isItemSelected ? "modalInputGroupNameWhite" : "")}
            inputWidthFull
            type="text"
            name="name"
            value={domain.name}
            onChange={(e)=> updateTxt(e, index)}
          />
        </div>
      </div>

      {/* CLOSE/OPEN SUBGROUP ICON */}
      <div className="flexStartCenter" >

        {/* BUTTON OPEN CLOSE */}
        <div
          className={"material-icons organizeGroupItemSwitch " + (selectedDomain && selectedDomain.id === domain.id ? "organizeGroupItemSwitchClose" : "")}
          style={{
            color:selectedDomain && selectedDomain.id === domain.id ? "white": null,
            cursor: "pointer"
          }}
          onClick={() => onGroupSelected(domain)}
        >
          {selectedDomain && selectedDomain.id === domain.id
            ? "close"
            : "keyboard_arrow_right"}
        </div>
      </div>
      </div>
    </div>
  );
};

/////////////
/// MODAL ///
/////////////

export default memo(
  function WbsSettingsModal({
    wbsSettings,
    currentId,
    appDispatcher,
  }) {

    //////////////////
    /// STATE MNGT ///
    //////////////////

    // ADD OPPORTUNITY TO WBS DOMAINS
    const wbsSettingsMemo = useMemo(() => {
      if(wbsSettings?.domain) {
        let roSetting = wbsSettings.domain.find((setting) => setting.id === "riskOpp")
        // IF SHOWING OPPORTUNITIES
        if(roSetting?.displayOpportunity === true) {

          // SHALLOW COPY DOMAINS
          let newDomains = _.cloneDeep(wbsSettings.domain);

          // ADD OPPORTUNITY AT CORRECT INDEX
          newDomains.splice(roSetting.opportunityIndex, 0, {
            id: 'opportunity',
            icon: 'fact_check',
            name: roSetting.opportunityName,
            displayInColumn: true,
            sort: roSetting.opportunityIndex,
          });

          // RETURN
          return newDomains;
        }
        return wbsSettings;
      }
      return [];
    }, [wbsSettings]);


    //INIT
    const initialState = useMemo(() => {
      return {
        wbsDomain: wbsSettingsMemo,
        selectedDomain: null,
        color: null
      };
    }, [wbsSettingsMemo]);


    //USESTATE
    const [state, setState] = useState(initialState);

    ///////////////////////
    /// DIRECT FUNCTION ///
    ///////////////////////

    //SELECT GROUP
    const onGroupSelected = useCallback((group) => {

       // IF SELECTED IS SAME AS OPEN ONE THEN CLOSE EVERYTHING, OTHERWISE OPEN NEW SELECTED DIRECTLY
      setState((prevState) => {

        //COLOR MNGT
        let color = null;
        if(prevState.color === ("group" + group.id)){
          color = prevState.color
        }

        //INIT
        let newSelectedDomain =
          prevState.selectedDomain && prevState.selectedDomain.id === group.id
            ? null
            : group;

        //RETURN
        return {
          ...prevState,
          selectedDomain: newSelectedDomain,
          color: color
        };
      });
    }, []);

    ///////////////////
    /// TEXT UPDATE ///
    ///////////////////

    const updateTxt = useCallback((e, index)=>{
      setState((prevState) => {

        //INIT
        let updatedList = [...prevState.wbsDomain];
        let selectedDomain = null
        if(prevState.selectedDomain){
          selectedDomain = {...prevState.selectedDomain}
        }

        ///////////////////////
        /// MANAGE POSITION ///
        ///////////////////////

        //MANAGE IF ITS GROUP
        updatedList[index].name = e.target.value;
        updatedList[index].updaterTracking += 1;

        //UPDATE STATE
        return {
            ...prevState,
            wbsDomain: updatedList,
            selectedDomain : selectedDomain
        }
      });
    },[])

    /////////////////////
    /// DRAG AND DROP ///
    /////////////////////

    //ON DROP
    const handleDrop = useCallback((droppedItem) => {

      document.activeElement.blur()

      // IGNORE DROP IF DROPPED OUT OF CONTAINER
      if (!droppedItem.destination) return;

      // UPDATE STATE
      setState((prevState) => {

        //INIT
        let updatedList = [];

        ///////////////////////
        /// MANAGE POSITION ///
        ///////////////////////

        //MANAGE IF ITS GROUP
        updatedList = [...prevState.wbsDomain];

        //SAVE DRAGGED OBJECT
        let saveCurrentOject = _.cloneDeep(updatedList[droppedItem.source.index])

        //DELETE DRAGGED ITEM
        updatedList.splice(droppedItem.source.index, 1);

        // ADD DROPPED ITEM
        updatedList.splice(droppedItem.destination.index, 0, saveCurrentOject);

        /////////////////////////////
        /// MANAGE GROUP SETTINGS ///
        /////////////////////////////

        //GET wbsDomain
        let newWbsDomain = updatedList

        //UPDATE SORT
        newWbsDomain.map((item, index) => {
          item.sort = index;
          item.updaterTracking += 1;
          return item
        })

        //UPDATE STATE
        return {
            ...prevState,
            wbsDomain: newWbsDomain,
        }
      });
    },[])

    //////////////
    /// SUBMIT ///
    //////////////

    // WHEN USER CLICKS ON "UPDATE" BUTTON.
    const functionHandleSubmit = useCallback((e) => {
      e.preventDefault();

      //CLONE DEEP WBS DOMAIN
      let domainsToReturn = _.cloneDeep(state.wbsDomain);

      // REMOVE OPPORTUNITIES FROM STATE WBS DOMAIN
      let oppIndex = state.wbsDomain.findIndex((domain) => domain.id === "opportunity");

      if(oppIndex > -1) {

        // FIND INDEX OF riskOpp DOMAIN
        let indexRo = domainsToReturn.findIndex((domain) => domain.id === "riskOpp");

        // UPDATE INDEX OF OPP
        domainsToReturn[indexRo].opportunityIndex = domainsToReturn[oppIndex].sort;

        // SET NEW NAME
        domainsToReturn[indexRo].opportunityName = domainsToReturn[oppIndex].name;

        // DELETE OPP FROM DOMAINS TO RETURN
        domainsToReturn.splice(oppIndex, 1);
      }

      //MUTATE GRAPH QL
      let object = {
        id: wbsSettings.id ? wbsSettings.id : uuid(),
        projectId: currentId,
        domain: JSON.stringify(domainsToReturn)
      }

      //LAUNCH GRAPHQL
      mutateGraphql(
        wbsSettings.id ? UpdateTelescopeDataWbsSettings : CreateTelescopeDataWbsSettings,
        object,
        appDispatcher
      );

      //UPDATE APP STATE
      appDispatcher({
        type: "SET_WBS_DOMAIN_UPDATE",
        wbsDomain: domainsToReturn,
      })
    }, [appDispatcher, currentId, state.wbsDomain, wbsSettings.id]);

    //////////////
    /// OTHERS ///
    //////////////

    //CLOSE MODAL
    const functionResetModal = useCallback((e) => {
      e.preventDefault();
      appDispatcher({
        type: "SET_GLOBAL_STATE",
        options: {
          source: "appSettings",
          object: {
            actionModal: null,
          },
        },
      });
    },[appDispatcher]);

    /////////////////////////
    /// COMPOENENT RENDER ///
    /////////////////////////

    return (
      <CvtModal
        width={700}
        icon="auto_awesome_motion"
        title="Wbs Domain Settings"
        resetFunction={functionResetModal}
        submitFunction={functionHandleSubmit}
      >
        {/* BODY */}
        <CvtModalBody hideOverflowY>

          {/* GROUP DARG DROP CONTEXT */}
          <div className="organizeGroupsBody">

            {/* GROUPS CONTAINER */}
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="groupsContainer">
                {(provided) => (
                  <div
                    className="organizeGroupGroupContainer"
                    style={{ width: state.selectedDomain ? "50%" : "100%" }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                      {/* GROUP TITLE */}
                      <span className="organizeGroupTitle">
                        Domain
                      </span>

                      {/* MAP GROUP */}
                      {state.wbsDomain.map((domain, index) => {

                        //IF NOT ENABLED
                        if(!domain.displayInColumn) return null;

                        //LINE
                        return(
                          <Draggable
                            key={"Group" + domain.id}
                            draggableId={"Group" + domain.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                draggable
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <OrganizeDomainItem
                                    key={`modal-organize-group-item-${domain.id}`}
                                    domain={domain}
                                    onGroupSelected={onGroupSelected}
                                    selectedDomain={state.selectedDomain}
                                    updateTxt={updateTxt}
                                    index={index}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* SUBGROUPS */}
            {state.selectedDomain ?
              <div
                className="organizeGroupSubGroupContainer"
                style={{ width: state.selectedDomain ? "50%" : "100%" }}

              >
                  {/* GROUP TITLE */}
                  <span className="organizeGroupTitle">
                    {state.selectedDomain.name} Settings
                  </span>

                  <div>
                    To be designed
                  </div>
              </div>
            :null}
          </div>
        </CvtModalBody>

        {/* FOOTER */}
        <CvtModalFooter
          submitTxt="Update"
          submitColor="blue"
        />
      </CvtModal>
    );
  }
);
