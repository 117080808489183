// LIBS
import { useCallback, useMemo } from "react";

// FUNCTIONS
import {
  FUNCT_FIND_INDEX,
  FUNC_ZERO_FORMAT_TO_NUM,
} from "../../../00-Core/Standards";
import { ACTION_STATUS_LIST } from "../../02-Portfolio/01-Action/00-Helpers/ActionsConstants";
import {
  FUNC_ACTION_GET_NUM,
  FUNC_ACTION_IS_LATE,
} from "../../02-Portfolio/01-Action/00-Helpers/ActionsFunctions";
import { FUNC_BOARD_UPDATE_LAST_ACTIVITIES } from "../00-Helpers/BoardFunctions";
import BoardLoader from "./BoardLoader";
import BoardTopActionsStatusTag from "./BoardTopActionsStatusTag";

export default function BoardActionListItem({
  actionItem,
  projectList,
  displayProject,
  loadingId,
  activateLoader,
  openWbsItem,
  boardDispatcher,
}) {
  /////////////////////////////////////////////////////
  // CALLBACKS ////////////////////////////////////////
  /////////////////////////////////////////////////////

  // OPEN ACTION IN PORTFOLIO VIEW
  const openAction = useCallback(() => {
    // ACTIVATE LOADER
    activateLoader(actionItem.id);

    let lastActivityData = {
      id: actionItem.id,
      displayId: actionItem.displayId,
      wbsId: actionItem.wbsId,
      organizationId: actionItem.organizationId,
      projectId: actionItem.projectId,
    };

    // ADD ACTION TO LAST ACTIVITIES
    FUNC_BOARD_UPDATE_LAST_ACTIVITIES(
      "action",
      lastActivityData,
      boardDispatcher
    );

    // OPEN ITEM
    openWbsItem("action", actionItem);
  }, [actionItem, activateLoader, boardDispatcher, openWbsItem]);

  /////////////////////////////////////////////////////
  // MEMO /////////////////////////////////////////////
  /////////////////////////////////////////////////////

  // // GET ACTION STATUS
  const status = useMemo(() => {
    return ACTION_STATUS_LIST.find(
      (actionStatus) => actionStatus.value === actionItem.status
    );
  }, [actionItem.status]);

  // GET PROJECT NAME
  const projectName = useMemo(() => {
    // GET PROJECT INFOS
    let projectIndex = FUNCT_FIND_INDEX(
      projectList,
      "id",
      actionItem.projectId
    );
    return projectList[projectIndex]?.name;
  }, [actionItem.projectId, projectList]);

  // CALCULATE DUE DATE WEEK
  const dueDateWeek = useMemo(() => {
    let weekNum = FUNC_ACTION_GET_NUM(actionItem);

    return "W" + FUNC_ZERO_FORMAT_TO_NUM(weekNum, 2);
  }, [actionItem]);

  // CHECK IF ACTION IS LATE
  const isLate = useMemo(() => {
    return FUNC_ACTION_IS_LATE(actionItem.status, actionItem.dueDate);
  }, [actionItem.dueDate, actionItem.status]);

  // // CHECK IF ACTION IS NEW
  // const isNew = useMemo(() => {
  //     return FUNC_CHECK_DATE2_IS_AFTER_DATE1(
  //         new Date().setDate(new Date().getDate() - 7),
  //         actionItem.createdOn
  //     );
  // }, [actionItem.createdOn]);

  //RETURNS TRUE IF ACTION CREATED IN LAST 7 DAYS ------------------------ OK
  const isNew = useMemo(() => {
    //INIT
    let test = false;
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setDate(today.getDate() - 7);

    //GET CREATEDON DATE OBJECT
    let createdOn = Date.parse(actionItem.createdOn) || null;

    //MAKE TEST
    if (today.getTime() < createdOn) test = true;

    //RETURN
    return test;
  }, [actionItem.createdOn]);

  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////
  return (
    <div
      className="flexBetweenStart boardListItem text_Color3_500_12px"
      style={{
        cursor: "pointer",
      }}
      onClick={openAction}
    >
      {/* LOADER */}
      {loadingId === actionItem.id ? (
        <BoardLoader text={"Loading Action"} />
      ) : (
        <>
          <div className="flexStartStart">
            {/* STATUS TAG */}
            <div style={{ width: "80px", minWidth: "80px" }}>
              <BoardTopActionsStatusTag item={status} />
            </div>

            {/* ACTION ID */}
            <div
              className="text_Color4_500_12px"
              style={{
                marginLeft: "15px",
                width: "70px",
                minWidth: "70px",
                color: isLate ? "var(--color-bad)" : null,
              }}
            >
              {actionItem.displayId !== "" ? actionItem.displayId : "N/A"}
            </div>

            {/* TITLE */}
            <span
              style={{
                color: isLate ? "var(--color-bad)" : null,
              }}
            >
              {actionItem.action}
            </span>

            {/* NEW TEXT */}
            {isNew ? <span className="actionTableItemNew">New</span> : null}
          </div>

          <div className="flexStartCenter">
            {/* PROJECT NAME */}
            {displayProject && (
              <div
                className="textEllipsis"
                style={{
                  color: "var(--color-migso-blue)",
                  border: "1px solid var(--color-migso-blue)",
                  borderRadius: "5px",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                {projectName ?? "..."}
              </div>
            )}

            {/* DUE DATE WEEK */}
            <div
              className="actionTableItemWeek"
              style={{
                marginLeft: "10px",
                color: isLate ? "white" : null,
                backgroundColor: isLate ? "var(--color-bad)" : null,
              }}
            >
              {dueDateWeek}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
