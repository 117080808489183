///////////////////////////////////////////////////////////////////////////
/// IMPORT ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

// REACT
import React, {memo, useRef, useMemo} from 'react';

// CUSTOM HOOKS
import {useIsOverflow} from '../../../../00-Core/CustomHooks';
import { WBS_BIG_COMPONENT_VIEW_MODE } from '../../00-Wbs/00-Helpers/WbsConstants';
//  import Button from '../../../../00-Core/00-Components/Button';
import { getCorrectTextColor } from '../00-Helpers/ShortcutFunctions';
import { CvtButton, CvtTooltip } from '@mi-gso/cvt';

/////////////////////////////////////////////////////////////////////////////
/// SHORTCUT LIST ITEM COMPONENT  ///////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

export default memo(function ShortcutListItem({
    // SHORTCUT ITEM DATA
    shortcut,
    // VIEW MODE
    viewMode,
    dragHandleProps,
    // ON DELETE
    onDelete,
    isOnlyShortcutOpen,
}) {

    /////////////////////////////////////////////////////
    /// STATE ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    const textRef = useRef();

    const isTextOverflow = useIsOverflow(textRef);

    /////////////////////////////////////////////////////
    /// MEMO ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    // GET CONTRAST COLOR BASED ON THE BACKGROUND COLOR
    const contrastColor = useMemo(() => {
        return getCorrectTextColor(shortcut.backgroundColor ?? "#ffffff");
    }, [shortcut.backgroundColor]);

    /////////////////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    return (
        <div className='flexStartCenter shortcutListItemContainer'
            style={{
                backgroundColor: shortcut.backgroundColor,
                cursor: "pointer",
                opacity: 1,
            }}
            id={`shortcut_list_item_${shortcut.id}`}
        >
            {
                viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit &&
                <div className='material-icons shortcutListItemDragIcon'
                    // {...dragHandleProps}
                    style={{
                        color: contrastColor
                    }}
                >
                    drag_indicator
                </div>
            }

            {/* ICON */}
            <div className="material-icons" style={{
                color: contrastColor,
                fontSize: '20px',
                marginRight: '3px',
            }}>
                {shortcut.icon}
            </div>

            {/* NAME */}
            <span ref={textRef} className='shortcutListItemName flexOne ellipsisOneLine'
                style={{
                    color: contrastColor,
                }}
                id={`shortcut_list_item_name_${shortcut.id}`}
            >
                {shortcut.name}

                {isTextOverflow && isTextOverflow === true &&
                    <CvtTooltip
                        anchorId={`shortcut_list_item_name_${shortcut.id}`}
                        content={shortcut.name}
                    />
                }

            </span>

            {/* IF IN EDIT MODE */}
            {
                viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ?
                    <CvtButton
                            color="red"
                            shape="noShape"
                            size="small"
                            customHover="red"
                            icon="delete"
                            handleSubmit={(e) => {
                                e.stopPropagation();
                                onDelete(shortcut.id);
                            }}
                            iconColor={contrastColor}
                            needConfirmation
                            handleConfirmCallBack={(e, _) => e.stopPropagation()}
                            // tooltip
                            tooltipTxt="Delete"
                            tooltipPosition="right"
                            tooltipTheme="dark"
                    />
            :null}

        </div>
    );
});