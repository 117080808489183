// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { picTelFocDay1, picTelFocDay2 } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `

# Presentation

This section displays an overview of your coming activities

***

#  Access my last activities

## Description

Find here the 6 last items you have opened (project, portfolio, actions,
review). When you open an item from the board view, it appears in this
box. Then, you can easily retrieve it and reopened it without searching.

All users have access to this feature

## Process

Click on the line you are interested on > the control page opens
directly on the item selected

***

#  Open one of my Actions

## Description

All open action of which you are responsible are listed in this box with
some indicators:   

![picTelFocDay1](${picTelFocDay1} "" )

> **1**.  Total number of open actions    
**2**.  Number of open actions with status On Hold    
**3**.  Number of open and late actions (Due date =< Today)

By default:

-   Only open actions are display (with status different from Done)
-   Actions are grouped by projects
>

    WARNING: All users can use this features.

## Process

Click on the action you want: the action card is opened in the Control
page, in the branch of the project the action belongs.


***

# Filter my actions


## Description
You can filter on the status if the actions thanks to the tag on the top of the box.. The filter is applied on the list of actions and on the indicators.              
All users have access to this feature

## Process
To filter: click on one or several statuses                
To clear filters: unselect the tags or come back to the default view by clicking on the icon next to the tags.

***

# Group my actions

## Description
You can ungroup the actions in order to display all actions sorted by status

## Process
Click on the button Group. Click again to ungroup.

***

#  Access next reviews

## Description

All coming reviews from projects you have access and of which you are
invited or you have created are listed with some indicators:  

![picTelFocDay2](${picTelFocDay2} "" )

> **1**.  Total number of coming reviews  
**2**.  Number of reviews planned today


    WARNING:   This feature is available to all users.

## Process

Click on the review > it is opening the review mode of the meeting in
the control page.

`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;
