import React, { useMemo } from 'react'
import { CvtTooltip } from '@mi-gso/cvt'
import { FUNCT_FIND_INDEX } from '../../../../../00-Core/Standards'

//CORE
import {
	ACTION_DATE_LIST,
	ACTION_STATUS_KEY,
	ACTION_STATUS_LIST,
} from '../../00-Helpers/ActionsConstants'

/////////////////////////////////////
/// ACTION PROGRESS BAR COMPONENT ///
/////////////////////////////////////

export default function ActionCardProgressBar({
	action,
	actionDueDate,
	actionStatus,
}) {
	/////////////////////////////////////////////////////////////////////////
	/// DATA ////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////

	//TEST IF LATE ----------------------------------------------------------
	const isLate = useMemo(() => {
		//INIT
		const today = new Date()

		//TEST IF DATE
		let dueDate = actionDueDate
		if (Object.prototype.toString.call(dueDate) !== '[object Date]') {
			dueDate = new Date(dueDate)
		}

		//TEST
		if (dueDate.getTime() < today.getTime()) return true
		return false
	}, [actionDueDate])

	//FIND STATUS OBJECT ----------------------------------------------------
	const actionStatusObject = useMemo(() => {
		//IF LATE
		if (
			isLate &&
			actionStatus !== ACTION_STATUS_KEY.onHold &&
			actionStatus !== ACTION_STATUS_KEY.done
		) {
			return {
				id: 'late',
				value: 'late',
				name: 'Late',
				label: 'Late',
				backgroundColor: 'var(--color-bad)',
				color: 'white',
			}
		} else {
			return ACTION_STATUS_LIST.find((item) => item.id === actionStatus)
		}
	}, [actionStatus, isLate])

	//GET DATE ARRAY --------------------------------------------------------
	const dateArray = useMemo(() => {
		//IINIT
		let array = []
		let currentDate

		//ONLY IF ACTION NOT NULL
		if (action) {
			//IF NOT CLOSED ADD TODAY
			if (actionStatus !== ACTION_STATUS_KEY.done) {
				//GET TODAY
				const today = new Date()
				currentDate = today.toLocaleString(undefined, {
					month: '2-digit',
					day: '2-digit',
					year: '2-digit',
				})

				//PUSH TODAY TO THE TIMELINE
				array.push({
					id: 'today',
					name: 'Today',
					icon: 'play_arrow',
					getTime: today.getTime(),
					value: currentDate,
				})
			}

			//LOOP ON DATE ATTRIBUTES
			for (let i = 0; i < ACTION_DATE_LIST.length; i++) {
				//TEST IF DATE NOT NULL
				if (action[ACTION_DATE_LIST[i].id]) {
					//TEST IF DATE
					let convertDate = action[ACTION_DATE_LIST[i].id]
					if (Object.prototype.toString.call(convertDate) !== '[object Date]') {
						convertDate = new Date(convertDate)
					}

					//MANAGE DATE TXT
					currentDate = convertDate.toLocaleString(undefined, {
						month: '2-digit',
						day: '2-digit',
						year: '2-digit',
					})

					//GET STRING
					array.push({
						id: ACTION_DATE_LIST[i].id,
						name: ACTION_DATE_LIST[i].name,
						icon: ACTION_DATE_LIST[i].icon,
						getTime: convertDate.getTime(),
						value: currentDate,
					})
				}
			}

			//SORT BY GET TIME
			array.sort(function (a, b) {
				return a.getTime - b.getTime
			})

			//INIT PERCENT RATIO
			let firstGetTime = array[0].getTime
			let lastGetTime = array[array.length - 1].getTime
			let subFirstLast = lastGetTime - firstGetTime
			array[0].percent = '0%'
			array[array.length - 1].percent = '100%'

			//ADD PERCENTAGE
			for (let j = 1; j < array.length - 1; j++) {
				//CALCULATE PERCENT
				let currentPercent = Math.floor(
					((array[j].getTime - firstGetTime) * 100) / subFirstLast
				)

				//INTEGRATE
				array[j].percent = currentPercent + '%'
			}
		}

		//RETURN
		return array
	}, [action, actionStatus])

	//GET VALUE FOR SECOND BAR ----------------------------------------------
	const secondBar = useMemo(() => {
		//ONLY IF DATE ARRAY
		if (dateArray.length > 0) {
			//INIT
			let findIndex

			//INIT PERCENT RATIO
			let firstGetTime = dateArray[0].getTime
			let lastGetTime = dateArray[dateArray.length - 1].getTime
			let subFirstLast = lastGetTime - firstGetTime

			///////////////
			/// ON HOLD ///
			///////////////

			if (actionStatus === ACTION_STATUS_KEY.onHold) {
				//FIND ONHOLD
				findIndex = FUNCT_FIND_INDEX(dateArray, 'id', 'cancelledDate')

				//RETURN
				return {
					height:
						Math.floor(
							((dateArray[findIndex].getTime - firstGetTime) * 100) /
								subFirstLast
						) + '%',
					color: actionStatusObject.backgroundColor,
					value: ACTION_STATUS_KEY.onHold,
				}
			}

			////////////
			/// DONE ///
			////////////

			if (actionStatus === ACTION_STATUS_KEY.done) {
				//FIND DONE
				findIndex = FUNCT_FIND_INDEX(dateArray, 'id', 'realisedDate')

				//RETURN
				return {
					height:
						Math.floor(
							((dateArray[findIndex].getTime - firstGetTime) * 100) /
								subFirstLast
						) + '%',
					color: actionStatusObject.backgroundColor,
					value: ACTION_STATUS_KEY.done,
				}
			}

			////////////
			/// LATE ///
			////////////

			//TEST VS TODAY
			if (isLate) {
				//FIND DONE
				findIndex = FUNCT_FIND_INDEX(dateArray, 'id', 'dueDate')

				//RETURN DEFAULT
				return {
					height:
						Math.floor(
							((dateArray[findIndex].getTime - firstGetTime) * 100) /
								subFirstLast
						) + '%',
					color: 'var(--color-bad)',
					value: ACTION_STATUS_KEY.late,
				}
			}

			////////////////
			/// ON TRACK ///
			////////////////

			//FIND DONE
			findIndex = FUNCT_FIND_INDEX(dateArray, 'id', 'today')

			//RETURN DEFAULT
			return {
				height:
					Math.floor(
						((dateArray[findIndex].getTime - firstGetTime) * 100) / subFirstLast
					) + '%',
				color: 'var(--color-migso-blue)',
				value: ACTION_STATUS_KEY.onTrack,
			}
		}

		return {
			height: '0%',
			color: 'var(--color-migso-blue)',
			value: ACTION_STATUS_KEY.onTrack,
		}
	}, [actionStatus, actionStatusObject.backgroundColor, dateArray, isLate])

	////////////////////////
	/// COMPONENT RENDER ///
	////////////////////////

	return (
		<div className='actionCardInfosBodyLeft flexColStartStart'>
			{/* STATUS */}
			<div
				className='actionCardInfosBodyLeftStatus'
				style={{
					background: actionStatusObject.backgroundColor,
				}}>
				{actionStatusObject.name}
			</div>

			{/* PROGRESS BAR */}
			<div className='actionCardInfosProgressBar'>
				{/* SECOND COLOR BAR */}
				<div
					style={{
						width: '10px',
						height: secondBar.height,
						backgroundColor: secondBar.color,
					}}></div>

				{/* MAP ARRAY DATE */}
				{dateArray.map((date) => {
					//DOT COLOR
					let dotColor = null

					//FORCE FIRST
					if (date.id === 'createdOn') {
						dotColor = secondBar.color

						//ON HOLD
					} else if (
						actionStatusObject.id === ACTION_STATUS_KEY.onHold &&
						(date.id === 'createdOn' || date.id === 'cancelledDate')
					) {
						dotColor = secondBar.color

						//DUE DATE
					} else if (date.id === 'dueDate') {
						//LATE
						if (secondBar.value === ACTION_STATUS_KEY.late) {
							dotColor = secondBar.color

							//ON HOLD
						} else if (secondBar.value === ACTION_STATUS_KEY.onHold) {
							dotColor = null

							//DONE
						} else if (secondBar.value === ACTION_STATUS_KEY.done) {
							dotColor = secondBar.color

							//OTHER
						} else {
							dotColor = 'var(--color-migso-blue)'
						}

						//TODAY
					} else if (date.id === 'today') {
						//TODAY IN LATE
						if (secondBar.value === ACTION_STATUS_KEY.late) {
							dotColor = secondBar.color

							//TODAY ON HOLD
						} else if (secondBar.value === ACTION_STATUS_KEY.onHold) {
							dotColor = null

							//OTHER
						} else {
							dotColor = 'var(--color-migso-blue)'
						}

						//DONE
					} else if (date.id === 'realisedDate') {
						dotColor = secondBar.color
					}

					//RETURN
					return (
						<div
							key={'progressDot-' + date.id}
							className='actionCardInfosProgressBarDate flexStartCenter'
							style={{
								top: 'calc(' + date.percent + ' - 7px)',
							}}>
							{/* DOT */}
							<div
								className='actionCardInfosProgressBarDateDot material-icons flexCenterCenter'
								id={date.id + -'dot'}
								style={{
									backgroundColor: dotColor,
								}}>
								{date.icon}

								{/* NAME ON TOOLTIP */}
								<CvtTooltip anchorId={date.id + -'dot'} content={date.name} />
							</div>

							{/* DATE */}
							<div className='actionCardInfosProgressBarDateLegend'>
								{date.value}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
