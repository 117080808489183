import React, { useCallback, useState } from "react";
import { CvtSideBarButton } from "@mi-gso/cvt";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../00-Wbs/00-Helpers/WbsConstants";
import WbsElementBigSideBar from "../00-Wbs/01-Components/WbsElementBigSideBar";
import { GOV_ITEM_VIEW_TYPE } from "./00-Helpers/GovConstants";

/////////////////// COMPONENT /////////////////////
export default function GovItemEditSideBar({
    viewMode,
    onAddEditClick,
    onDeleteSideBar,
    onDuplicateSideBar,
    onBackSideBar,
    selectedItems,
    currentUser,
    govItemView,
    govItemType,
    isCurrentUserEditor,
    isReadOnlyMode,
    isConsolidate,
    onConsolidate,
    handleMoveItemsClicked,
    canModifySelected,
}) {
    /////////////
    /// STATE ///
    /////////////

    //INIT
    const [confirm, setConfirm] = useState({
        value: false,
        type: "",
    });

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    //HANDLE DELETE
    const handleAction = useCallback(
        (e, actionType, reset) => {
            e.preventDefault();

            //RESET
            if (reset) {
                setConfirm({
                    value: false,
                    type: "",
                });

                //ALREADY CONFIRM CAN DELETE
            } else if (confirm.value) {
                //TYPE
                switch (actionType) {
                    //DUPLICATE
                    case "duplicate":
                        onDuplicateSideBar(e);
                        break;

                    //DELETE
                    case "delete":
                        onDeleteSideBar(e);
                        break;

                    default:
                        break;
                }

                //UPDATE STATE
                setConfirm({
                    value: false,
                    type: "",
                });

                //NEED CONFIRM
            } else {
                setConfirm({
                    value: true,
                    type: actionType,
                });
            }
        },
        [confirm, onDeleteSideBar, onDuplicateSideBar]
    );

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////
 
    return (
        <WbsElementBigSideBar>

            {/* DISPLAY BUTTON RELATED VIEW */}
            {!isReadOnlyMode && !govItemView &&
            (viewMode === WBS_BIG_COMPONENT_VIEW_MODE.list ||
                viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add ||
                viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit) ? 
                <React.Fragment>

                    {/* ADD BUTTON */}
                    <CvtSideBarButton
                        buttonClass="flexCenterCenter buttonSideBar"
                        id="tooltipAddBig"
                        icon="add"
                        fontSize="22px"
                        title="Add Scope Change"
                        onClickFunction={() => onAddEditClick()}
                        cyId="buttonAddBig"
                    />

                    {/* Consolidate */}
                    <CvtSideBarButton
                        buttonClass={
                            "flexCenterCenter buttonSideBar " +
                            (isConsolidate ? "" : "buttonSideBarSelected")
                        }
                        id="tooltipConsolidate"
                        icon={isConsolidate ? "bookmarks" : "bookmark"}
                        fontSize="22px"
                        title={
                            isConsolidate
                                ? "Only this branche's Scope Changes"
                                : "Include all children's Scope Changes"
                        }
                        onClickFunction={onConsolidate}
                        cyId="buttonConsolidateBig"
                    />

                    {/* EDIT */}
                    {selectedItems.length === 1 &&
                    canModifySelected ? 
                        <CvtSideBarButton
                            buttonClass="flexCenterCenter buttonSideBar"
                            id="tooltipEditBig"
                            icon="edit"
                            fontSize="22px"
                            title="Edit"
                            onClickFunction={() => onAddEditClick(selectedItems[0])}
                            cyId="buttonEditBig"
                        />
                    : null}

                    {/* DUPLICATE */}
                    {selectedItems.length > 0 ? 
                        <CvtSideBarButton
                            buttonClass={
                                "flexCenterCenter buttonSideBar " +
                                (confirm.value && confirm.type === "duplicate"
                                    ? "buttonSideBarAlert"
                                    : "")
                            }
                            id="tooltipDuplicateBig"
                            icon="copy_all"
                            fontSize="22px"
                            title={
                                confirm.value && confirm.type === "duplicate"
                                    ? "Are you sure?"
                                    : "Duplicate"
                            }
                            onClickFunction={(e) => handleAction(e, "duplicate")}
                            onBlur={(e) => handleAction(e, "duplicate", true)}
                            onBlurConfirmation={
                                confirm.value && confirm.type === "duplicate"
                                    ? confirm.value
                                    : false
                            }
                            cyId="buttonDuplicateBig"
                        />
                    : null}

                    {/* DELETE */}
                    {canModifySelected && selectedItems.length > 0 ? 
                        <CvtSideBarButton
                            buttonClass={
                                "flexCenterCenter buttonSideBar " +
                                (confirm.value && confirm.type === "delete"
                                    ? "buttonSideBarConfirmDelete"
                                    : "")
                            }
                            id="tooltipDeleteBig"
                            icon="delete"
                            fontSize="22px"
                            title={
                                confirm.value && confirm.type === "delete"
                                    ? "Are you sure?"
                                    : "Delete"
                            }
                            onClickFunction={(e) => handleAction(e, "delete")}
                            onBlur={(e) => handleAction(e, "delete", true)}
                            onBlurConfirmation={
                                confirm.value && confirm.type === "delete" ? confirm.value : false
                            }
                            cyId="buttonDeleteBig"
                        />
                    : null}

                    {/* MOVE */}
                    {selectedItems.length > 0 && canModifySelected ? 
                        <CvtSideBarButton 
                            buttonClass={`flexCenterCenter buttonSideBar`}
                            id="tooltipMoveBig"
                            icon="move_down"
                            fontSize="22px"
                            title="Move Items"
                            onClickFunction={handleMoveItemsClicked}
                            cyId="buttonMoveBig"
                        />
                    :null}
                </React.Fragment>
             : govItemType === GOV_ITEM_VIEW_TYPE.scope ? 
                <React.Fragment>

                    {/* GO BACK */}
                    <CvtSideBarButton
                        buttonClass="flexCenterCenter buttonSideBar buttonSideBarBack"
                        id="tooltipBackBig"
                        icon="arrow_back"
                        fontSize="18px"
                        title="Back to list"
                        onClickFunction={onBackSideBar}
                        cyId="buttonBackBig"
                    />

                    {/* EDIT MODE */}
                    {!isReadOnlyMode ? 
                        <React.Fragment>

                            {/* EDIT */}
                            {canModifySelected ? 
                                <CvtSideBarButton
                                    buttonClass="flexCenterCenter buttonSideBar"
                                    id="tooltipEditBig"
                                    icon="edit"
                                    fontSize="22px"
                                    title="Edit"
                                    onClickFunction={() => onAddEditClick(govItemView)}
                                    cyId="buttonEditBig"
                                />
                            : null}

                            {/* DUPLICATE */}
                            <CvtSideBarButton
                                buttonClass={
                                    "flexCenterCenter buttonSideBar " +
                                    (confirm.value && confirm.type === "duplicate"
                                        ? "buttonSideBarAlert"
                                        : "")
                                }
                                id="tooltipDuplicateBig"
                                icon="copy_all"
                                fontSize="22px"
                                title={
                                    confirm.value && confirm.type === "duplicate"
                                        ? "Are you sure?"
                                        : "Duplicate"
                                }
                                onClickFunction={(e) => handleAction(e, "duplicate")}
                                onBlur={(e) => handleAction(e, "duplicate", true)}
                                onBlurConfirmation={
                                    confirm.value && confirm.type === "duplicate"
                                        ? confirm.value
                                        : false
                                }
                                cyId="buttonDuplicateBig"
                            />

                            {/* DELETE CURRENT ACTION */}
                            {canModifySelected ? 
                                <CvtSideBarButton
                                    buttonClass={
                                        "flexCenterCenter buttonSideBar " +
                                        (confirm.value && confirm.type === "delete"
                                            ? "buttonSideBarConfirmDelete"
                                            : "")
                                    }
                                    id="tooltipDeleteBig"
                                    icon="delete"
                                    fontSize="22px"
                                    title={
                                        confirm.value && confirm.type === "delete"
                                            ? "Are you sure?"
                                            : "Delete"
                                    }
                                    onClickFunction={(e) => handleAction(e, "delete")}
                                    onBlur={(e) => handleAction(e, "delete", true)}
                                    onBlurConfirmation={
                                        confirm.value && confirm.type === "delete"
                                            ? confirm.value
                                            : false
                                    }
                                    cyId="buttonDeleteBig"
                                />
                            :null}

                            {/* MOVE BTN */}
                            {canModifySelected ? 
                                <CvtSideBarButton 
                                    buttonClass={`flexCenterCenter buttonSideBar`}
                                    id="tooltipMoveBig"
                                    icon="move_down"
                                    fontSize="22px"
                                    title="Move Items"
                                    onClickFunction={handleMoveItemsClicked}
                                />
                            :null}
                        </React.Fragment>
                     : null}
                </React.Fragment>
             : null}
        </WbsElementBigSideBar>
    );
}