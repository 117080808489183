// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures
import { picTELShortcuts1, picTELShortcuts2 } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `

# Presentation

Shortcuts is a feature that allows you to create links for each section
of a WBS branch.

Shortcuts is displayed in two views:

A global view that is unique for each branch of the WBS, to display it,
you must close every category (schedule, action, risk...) and click on
the "Shortcut icon".

![picTELShortcuts1](${picTELShortcuts1} "" )

The global view is composed of:        
> **1**.  Shortcut icon        
**2**.  Number of shortcuts in the branch        
**3**.  Padlock icon: lock and unlock shortcut (explained below)        
**4**.  Add shortcut (explained below)        
**5**.  Edition button: edit shortcut (explained below)        
**6**.  Existing shortcut (click to go to)        
**7**.  Other way to add shortcut

A category view that shows only shortcut of the category, to display it,
you must open a category for example "Action" and then click on the
"Shortcut icon". The Shortcut appears on the left of the chart. The
category view is composed of:

![picTELShortcuts2](${picTELShortcuts2} "" )      
> **1**.  Number of shortcuts in the category branch      
**2**.  Padlock icon: lock and unlock shortcut (explained below)      
**3**.  Add shortcut (explained below)      
**4**.  Edition button: edit shortcut (explained below)      
**5**.  Shortcut icon

***

# Add a shortcut

## Description
## Process

You have two ways to create a new shortcut

-   Click on the "+" icon in the shortcut global view, or in the
    shortcut category view
-   Click on "Create a shortcut" in the global view. This icon is
    displayed only if there isn't any short cut in a category.

After clicking to create a new short cut, a pop up appears on the right
of your screen, and you must fill it with fields mandatory or not:

-   Name of the shortcut (Mandatory)
-   URL of the short cut, URL will be red not valid (Mandatory)
-   Icon, chose within a drop-down list (Mandatory)
-   Category to affect your shortcut (Mandatory). The category
    (Governance, Risks, Schedule...) will define where the shortcut is
    attached and where it will be displayed
-   Background color (white by default)

When every field is filed, you can decide to save the shortcut by
clicking on "Save" button, or you can decide to delete your short cut by
clicking on "X", a confirmation will appear, click on it to confirm
deletion.

***

# Lock and unlock shortcuts

## Description

This feature is available only if a shortcut has already been created on
the branch.

## Process

You can able or disable this feature by clicking on the padlock icon,
the grey icon means the feature is disabled, the yellow one means the
features is on. By default, the feature is off.

Locking shortcut, is effective in each category and force display of
shortcut category view when opening a category. It means when you open
your schedule for example you will have directly the shortcut schedule
view displayed without clicking on the shortcut icon.

***

# Edit shortcut

## Description

This feature is available only if a shortcut has already been created on
the branch.

## Process

To edit shortcut, you must click on the edition button , the grey icon
means edition is off, the blue one means it's on.

Shortcut edition allows you to edit a shortcut by clicking on it, it
displays the same pop-up as creation, and you can modify it as long as
each mandatory field is filed.

In the global shortcut view you can also drag and drop shortcut to move
it through categories. You also have the possibility to delete it by
clicking on the trash icon a confirmation will be asked.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;