import React, { useCallback } from 'react'

//AUTH MINI COMPONENT
import {
    AuthAuthenticatorTitle,
    AuthAuthenticatorForm,
    AuthAuthenticatorFormTitle,
    AuthAuthenticatorFormSubTitle,
    AuthAuthenticatorLink
} from './AuthAuthenticatorMiniComponents'

//CORE
import { CvtInput } from '@mi-gso/cvt';

////////////////////////////////////////////////////////////////////////////////////////////
/// EMAIL VERIFICATION COMPONENT ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

export default function AuthAuthenticatorMfaVerification({
    options,
    onChange,
    errorMessage,
    handleConfirmSignIn,
    handleBackToSignIn,
    challengeName
}){
    //CONTACT SUPPORT
    const contatSupport = useCallback(() => {
        let mail = document.createElement("a");
        mail.href = "mailto:support@clayverest.com";
        mail.click();
    },[])
    
    //RENDER
    return(
        <React.Fragment>

            {/* TITLE & ERROR */}
            <AuthAuthenticatorTitle 
                title="Confirm Sign In"
                errorMessage={errorMessage}
                icon="check_circle"
            />

            {/* INPUTS BLOCK */}
            <AuthAuthenticatorForm 
                disabled={options.code === ""}
                loader={options.loader}
                text="Confirm Sign In"
                handleBackToSignIn={handleBackToSignIn}
                onSubmit={handleConfirmSignIn}
                errorMessage={errorMessage}
            >

                {/* FORM TITLE */}
                <AuthAuthenticatorFormTitle 
                    icon={challengeName === "SMS_MFA" ? "phone" : "phonelink_lock"}
                    text="Confirm Sign In with MFA code"
                />

                {/* SUB EXPLANATION */}
                <AuthAuthenticatorFormSubTitle 
                    text={challengeName === "SMS_MFA" ?
                        "Hey, i think you have received a Clayverest Code on your phone!"
                    :
                        "Hey, i need the code from your authenticator application to continue!"
                    }
                />

                {/* CODE */}
                <CvtInput 
                    legend={challengeName === "SMS_MFA" ? "SMS Code" : "Authenticator App Code"}
                    placeholder="Enter your MFA code"
                    inputBlockCss="modalOptionSubBlock"
                    inputBlockPadding="15px 20px 10px 20px"
                    inputCss="form-control appAuthenticatorInput"
                    name="code"
                    value={options.code}
                    onChange={onChange}
                    autoComplete="off"
                />

                {/* RESEND CODE */}
                <AuthAuthenticatorLink
                    legend="Having trouble with the code?"
                    text="Contact Support"
                    handleClick={contatSupport}
                />

            </AuthAuthenticatorForm>
        </React.Fragment>
    )
}