////////////////////////
/// SCHEDULE REDUCER ///
////////////////////////

import _ from "lodash";
import { FUNCT_FIND_INDEX } from "../../../../00-Core/Standards";
import { SCHEDULE_COMPONENT_EXPAND_TYPES } from "./ScheduleConstants";

export const scheduleReducer = (state, action) => {
    // REUSABLE VARIABLES
    let newState;
    let attributes = [];
    let selected = [];
    let findIndex;

    //SWITCH
    switch (action.type) {
        //SET STATE FROM SOURCE ATTRIBUT AND  ----------------------------------------
        case "SET_GLOBAL_STATE":
            //options: source, object

            //INIT
            let currentSource = state[action.options.source];

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributes = Object.keys(action.options.object);

            //UPDATE REFERNECE OBJECT WITH NEW VALUES
            for (let i = 0; i < attributes.length; i++) {
                currentSource[attributes[i]] = action.options.object[attributes[i]];
            }

            //RETURN
            return {
                ...state,
                [action.options.source]: currentSource,
                viewModeInputText: "",
                viewModeInputComment: "",
            };

        //UPDATE SEVERAL IN ONE TIME -----------------------------------------------
        case "SET_STATE_OBJECT":
            //ACTION object

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            attributes = Object.keys(action.object);

            //GET ALL PREVIOUS STATE
            newState = { ...state };

            //UPDATE STATE FROM OBJECT
            for (let i = 0; i < attributes.length; i++) {
                newState[attributes[i]] = action.object[attributes[i]];
            }

            //RETURN
            return {
                ...newState,
            };

        // UPDATE FROM KEY AND VALUE -----------------------------------------------
        case "SET_STATE_KEY_VALUE":
            //action , key value

            //RETURN
            return {
                ...state,
                [action.key]: action.value,
            };

        // SELECT ACTION -----------------------------------------------------------
        case "SET_ACTIONS_SELECTION_INDIVIDUAL":
            //action: action

            //INIT
            selected = [...state.selected];

            //TEST IF THE ACTION IS ALREADY INSIDE THE SELECTED ARRAY
            findIndex = FUNCT_FIND_INDEX(selected, "id", action.action.id);

            //DELETE IT
            if (findIndex > -1) {
                selected.splice(findIndex, 1);

                //ADD IT
            } else {
                selected.push(action.action);
            }

            //RETURN
            return {
                ...state,
                selected: selected,
            };

        // SELECT ACTION -----------------------------------------------------------
        case "SET_ACTIONS_SELECTION_GLOBAL":
            //filteredActions

            //INIT
            selected = [...state.selected];

            //IF NOTHING SELECT ALL
            if (selected.length === 0) {
                selected = _.cloneDeep(action.filteredActions);

                //RESET ALL
            } else {
                selected = [];
            }

            //RETURN
            return {
                ...state,
                selected: selected,
            };

        // CHANGE EXPAND MODE
        case "SET_EXPAND_MODE":
            //widthSizeLimit

            //INIT
            let expandMode;

            //window <= 1200
            if (action.widthSizeLimit) {
                if (state.expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.graph) {
                    expandMode = SCHEDULE_COMPONENT_EXPAND_TYPES.graph;
                } else {
                    expandMode = SCHEDULE_COMPONENT_EXPAND_TYPES.table;
                }

                //window > 1200
            } else {
                expandMode = SCHEDULE_COMPONENT_EXPAND_TYPES.both;
            }

            //RETURN
            return {
                ...state,
                widthSizeLimit: action.widthSizeLimit,
                expandMode: expandMode,
            };

        //DEFAULT
        default:
            console.log(`${action.type} not in the ActionsDispatcher :(`);
            return { ...state };
    }
};
