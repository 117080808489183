import { CvtButton } from "@mi-gso/cvt";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { FUNCT_FIND_INDEX_ARRAY } from "../../../../00-Core/Standards";
import { SCHEDULE_STATUS, SCHEDULE_STATUS_KEYS } from "../00-Helpers/ScheduleConstants";

///////////////////////////////////////
/// SCHEDULE TABLE LEGEND COMPONENT ///
///////////////////////////////////////

export default function ScheduleTableLegend({
    filteredScheduleItems,
    scheduleData,
    selectedFilters,
    textSearchInput,
    scheduleDispatcher,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // DATA SOURCE
    const scheduleItems = useMemo(() => {
        //CHOOSE WICH DATA USED

        // USE FILTERED DATA IF SEARCH INPUT IS NOT EMPTY BUT NO FILTER HAS BEEN SELECTED
        if (textSearchInput && textSearchInput !== "" && selectedFilters.length === 0) {
            return _.cloneDeep(filteredScheduleItems);
        }

        // USE ALL DATA OTHERWISE
        return _.cloneDeep(scheduleData);
    }, [filteredScheduleItems, scheduleData, selectedFilters.length, textSearchInput]);

    // FILTERS
    const filtersData = useMemo(() => {
        let filters = [];

        SCHEDULE_STATUS.forEach((status) => {
            // CHECK THE STATUS HAS AT LEAST ONE ITEM IN THE DATA
            let statusFound = scheduleItems.find((item) => item.status.value === status.value);
            if (statusFound) {
                // ADD STATUS TO FINAL LIST
                filters.push(status);
            }
        });

        return filters;
    }, [scheduleItems]);

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////
    //
    const handleStatusTagClick = useCallback(
        (status) => {
            let newSelectedFilters = _.cloneDeep(selectedFilters);

            // CHECK IF IT'S SELECTED
            let selectedFindIndex = FUNCT_FIND_INDEX_ARRAY(newSelectedFilters, status.value);
            if (selectedFindIndex !== -1) {
                // IT IS SELECTED, REMOVE IT FROM LIST
                newSelectedFilters = newSelectedFilters.filter((item) => item !== status.value);
            } else {
                // ADD IT TO LIST
                newSelectedFilters.push(status.value);
            }

            let statusWithData = SCHEDULE_STATUS.filter((status) => {
                // TRY TO FIND THE STATUS IN SCHEDULE DATA
                let statusFound = scheduleItems.find(
                    (scheduleItem) => scheduleItem.status.value === status.value
                );
                if (statusFound) {
                    return true;
                }
                return false;
            });

            // IF ALL STATUS ARE SELECTED, RESET STATUS
            if (
                statusWithData.length > 1 &&
                statusWithData.length === newSelectedFilters.length + 1 &&
                FUNCT_FIND_INDEX_ARRAY(newSelectedFilters, SCHEDULE_STATUS_KEYS.done) === -1
            ) {
                newSelectedFilters = [];
            }

            // UPDPATE SCHEDULE STATE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    statusSearchInput: newSelectedFilters,
                    selected: [],
                },
            });
        },
        [scheduleDispatcher, scheduleItems, selectedFilters]
    );

    // RESET STATUS FILTER
    const onResetFilters = useCallback(() => {
        // RESET SCHEDULE STATE
        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                statusSearchInput: [],
                selected: [],
            },
        });
    }, [scheduleDispatcher]);

    //////////////////////////////////////////////////////////////
    /// RENDER ///////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    return (
        <div className="flexStartCenter" style={{ marginLeft: "5px" }}>
            {filtersData.map((filter) => {
                // CHECK IF IT'S SELECTED
                let isSelected = false;

                let selectedFindIndex = FUNCT_FIND_INDEX_ARRAY(selectedFilters, filter.value);
                if (selectedFindIndex !== -1) {
                    isSelected = true;
                }

                // RETURN STATUS TAG
                return (
                    <div
                        key={"scheduleTableLegend-" + filter.value}
                        className={
                            "actionsTableLegendTag flexStartCenter " +
                            (isSelected ? "actionsTableLegendTagSelected" : "")
                        }
                        onClick={() => handleStatusTagClick(filter)}
                        style={{
                            background: filter.backgroundColor,
                            opacity: !isSelected && selectedFilters.length > 0 ? "0.5" : null,
                        }}
                    >
                        {/* NAME */}
                        <span style={{ color: filter.color }}>{filter.label}</span>
                    </div>
                );
            })}

            {/* RESET */}
            {selectedFilters.length > 0 ? (
                <CvtButton
                    color="gray"
                    shape="noShape"
                    customHover="red"
                    icon="filter_alt_off"
                    iconColor={"var(--color-text-4)"}
                    size={"small"}
                    handleSubmit={onResetFilters}
                    margin="0px 5px 0px 5px"
                />
            ) : null}
        </div>
    );
}
