import _ from "lodash";
import { FUNC_IS_COLOR_LIGHT } from "../../../../00-Core/Standards";

// GET ARRAY OF SHORTCUTS TO UPDATE
export const handleDropGetUpdateShortcuts = (
  shortcuts,
  sourcePosition,
  destinationPosition,
  newCategory,
) => {

  // COPY THE SHORTCUTS RECURSIVELY
  let shortcutCopy = _.cloneDeep(shortcuts);

  // REMOVE SHORTCUT FROM SOURCE INDEX
  let deletedShortcut = shortcutCopy.splice(sourcePosition, 1)[0];

  // ADD IN DROPPED INDEX
  shortcutCopy.splice(destinationPosition, 0, {...deletedShortcut, category: newCategory ? newCategory : deletedShortcut.category});

  // INIT TO UPDATE SHORTCUTS ARRAY
  let toUpdateShortcuts = [];

  // FOR EACH SHORTCUT IN THE SHORTCUTS PASSED
  for (let i = 0, len = shortcuts.length; i < len; i++) {
    // IF THE POSITION OF THE SHORTCUT IS NOT THE SAME AS THE INDEX,
    // ADD IT TO THE SHORTCUTS TO UPDATE ARRAY AND MAKE THE POSITION BE THE SAME AS THE INDEX.
    if (shortcutCopy[i].position !== i) {
      toUpdateShortcuts.push({
        ...shortcutCopy[i],
        position: i,
      });
    }
  }

  // RETURN SHORTCUTS TO UPDATE ARRAY
  return toUpdateShortcuts;
};



// GET TEXT COLOR GIVEN A BACKGROUND COLOR IN FORMAT #2FA2D2
export const getCorrectTextColor = (backgroundColor) => {
  // TRUE IF IS LIGHT, FALSE IF ITS DARK
  let isBackgroundLight = FUNC_IS_COLOR_LIGHT(backgroundColor);

  return isBackgroundLight === true ? "var(--color-text-2)" : "white";
};
