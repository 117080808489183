// REACT
import React, { memo, useMemo, useCallback } from "react";

// CORE CONSTANTS
import {
    RISKS_OPPS_MATRIX_SETTINGS,
    RISK_OPP_EXPAND_MODES,
    RISK_MATRIX_VIEW_MODE,
    RISK_STRATEGY_LIST,
    OPP_STRATEGY_LIST,
} from "../../00-Helpers/RiskConstants";

// CORE FUNCTIONS
import {
    FUNC_CHECK_DATE2_IS_AFTER_DATE1,
    FUNC_GET_TXT_COLOR,
    FUNC_GET_USER_INFO,
    FUNC_TODAY_MINUS_N_DAYS,
} from "../../../../../00-Core/Standards";

// COMPONENTS
import RiskTableItemStrategyTag from "./RiskTableItemStrategyTag";
import { CvtAvatar } from "@mi-gso/cvt";
import {TooltipWrapper } from 'react-tooltip';
import { renderToString } from 'react-dom/server';

////////////////////////////
/// RISK TABLE ITEM //// ///
////////////////////////////

export default memo(function RiskTableItem({
    riskOppItem,
    usersList,
    megaUsersId,
    onRiskOppItemClick,

    // wbs ID
    wbsId,
    // parent id
    parentId,

    expandMode,
    // TEXT SEARCH INPUT
    textSearchInput,

    // IF IS SELECTED
    isSelected,

    handleRoSelectCheckbox,

    isReviewMode,

    isChild,

    viewMode,

    isCurrentUserEditor,
}) {
    
    /////////////////////////////////////////////////////
    /// MEMOS ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    // WHETHER IS NEW OR NOT
    const isNew = useMemo(() => {
        let oneWeekAgoDate = FUNC_TODAY_MINUS_N_DAYS(7);
        let riskIsNew = FUNC_CHECK_DATE2_IS_AFTER_DATE1(oneWeekAgoDate, riskOppItem?.createdAt);

        return riskIsNew;
    }, [riskOppItem]);

    // RESPONSIBLE INFO OF RO ITEM
    const responsibleInfo = useMemo(() => {
        return FUNC_GET_USER_INFO(usersList, megaUsersId, riskOppItem.responsible);
    }, [megaUsersId, riskOppItem.responsible, usersList]);

    // STRATEGY DETAILS
    const strategy = useMemo(
        () =>
            riskOppItem.isRisk === true
                ? RISK_STRATEGY_LIST.find((strategy) => strategy.value === riskOppItem.strategy)
                : OPP_STRATEGY_LIST.find((strategy) => strategy.value === riskOppItem.strategy),
        [riskOppItem.isRisk, riskOppItem.strategy]
    );

    //GET ACTION PATH -------------------------------------------------------- OK
    const roPath = useMemo(() => {
        //INIT - TO BE DELETED IN THE FUTUR
        let jsxPath = [
            <div key={"path-" + riskOppItem.id}>
                Child path not available. {riskOppItem.isRisk ? "Risk" : "Opportunity"} has been
                created in a previous telescope version
            </div>,
        ];

        //CREATE JSX
        if (isChild && riskOppItem && riskOppItem.path) {
            //SPLIT
            let pathSplit = riskOppItem.path.split(" > ");

            //MAP
            jsxPath = pathSplit.map((item, index) => {
                if (index === 0) {
                    return <div key={"path-" + riskOppItem.id + "-" + index}>{item}</div>;
                } else {
                    return (
                        <div
                            key={"path-" + riskOppItem.id + "-" + index}
                            style={{ marginLeft: 5 * index + "px" }}
                        >
                            {" > " + item}
                        </div>
                    );
                }
            });
        }

        //RETURN
        return jsxPath;
    }, [isChild, riskOppItem]);

    /////////////////////////////////////////////////////
    /// FUNCTIONS ///////////////////////////////////////
    /////////////////////////////////////////////////////

    // GET THE DATE STRING
    const getDateString = useCallback((value) => {
        return new Date(value).toLocaleString(undefined, {
            year: "2-digit",
            month: "short",
            day: "2-digit",
        });
    }, []);

    // GET CRITICITY COLORS
    const criticityInlineStyles = useCallback(
        (
            // RISK_MATRIX_VIEW_MODE
            type
        ) => {
            // CRITICTY
            let criticity;

            switch (type) {
                case RISK_MATRIX_VIEW_MODE.initial.value:
                    criticity = riskOppItem.initialCriticity;
                    break;
                case RISK_MATRIX_VIEW_MODE.current.value:
                    criticity = riskOppItem.currentCriticity;
                    break;
                case RISK_MATRIX_VIEW_MODE.target.value:
                    criticity = riskOppItem.targetCriticity;
                    break;
                default:
                    criticity = riskOppItem.currentCriticity;
            }

            let matrixSetting = RISKS_OPPS_MATRIX_SETTINGS.find(
                (matrixSetting) => matrixSetting.criticity === parseInt(criticity)
            );

            let backgroundColor =
                riskOppItem.isRisk === true
                    ? matrixSetting.riskColor
                    : matrixSetting.opportunityColor;

            return {
                background: backgroundColor,
                color: FUNC_GET_TXT_COLOR(backgroundColor),
            };
        },
        [
            riskOppItem.currentCriticity,
            riskOppItem.initialCriticity,
            riskOppItem.isRisk,
            riskOppItem.targetCriticity,
        ]
    );

    /////////////////////////////////////////////////////
    /// COMPONENT RENDER ////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <div
            className={
                "actionTableItemContainer flexStartCenter " +
                (isSelected === true ? "actionTableItemContainerSelected" : "")
            }
            data-cy="risk-table-item"
            onClick={(e) => onRiskOppItemClick(riskOppItem)}
            style={{
                minHeight: '43px',
                cursor: 'pointer',
                position: 'relative',
                width: '100%',
            }}
        >
            {/* SELECT BOX */}
            {!viewMode && isCurrentUserEditor ? (
                <div
                    className={
                        "actionTableSelectBox " + (isSelected ? "actionTableSelectBoxActive" : "")
                    }
                    onClick={(e) => handleRoSelectCheckbox(e, riskOppItem, isSelected)}
                    style={{ marginTop: "3px" }}
                ></div>
            ) : null}

            {/* DISPLAY ID */}
            <div className="actionTableItemDisplayId" style={{ marginLeft: "8px" }}>
                {riskOppItem.displayId}
            </div>

            <span className="actionTableItemName ">{riskOppItem.name}</span>

            {/* CHILD */}
            {isChild ?
                <TooltipWrapper
                    html={renderToString(roPath)}
                    tooltipId="risk-table-tooltip"
                >
                    <div className="actionTableItemLockIcon material-icons">                    
                        bookmarks
                    </div>
                </TooltipWrapper>
            :null}

            {/* NEW */}
            {isNew === true ? (
                <span
                    className="actionTableItemNew"
                    style={{
                        marginRight: "5px",
                    }}
                >
                    New
                </span>
            ) : null}

            {/* SPACE BETWEEN */}
            <div className="flexOne" style={{ height: "100%" }}></div>

            {/* ESCALATED STATUS  */}
            {riskOppItem.escalatedTo &&
            (riskOppItem.escalatedTo?.includes(wbsId) ||
                riskOppItem.escalatedTo?.includes(parentId)) ? (
                <div
                    className="riskTableItemEscalatedStatus flexCenterCenter material-icons"
                    style={{
                        // IF ESCALATED TO INCLUDES THIS WBS ID, THEN SHOW BORDER BOTTOM
                        borderBottom:
                            riskOppItem.escalatedTo && riskOppItem.escalatedTo?.includes(wbsId)
                                ? "2px solid var(--color-bad)"
                                : "",
                        // IF ESCALATED TO INCLUDES PARENT'S ID THEN SHOW BORDER TOP
                        borderTop:
                            riskOppItem.escalatedTo && riskOppItem.escalatedTo?.includes(parentId)
                                ? "2px solid var(--color-bad)"
                                : "",
                    }}
                >
                    arrow_upward
                    arrow_upward
                </div>
            ) : null}

            {/* STATEGY TAG*/}
            <RiskTableItemStrategyTag strategyInfo={strategy} />

            {expandMode === RISK_OPP_EXPAND_MODES.tableOnly && !isReviewMode && (
                <React.Fragment>
                    <span
                        className="riskTableSmallCriticityTag"
                        style={{
                            marginLeft: "3px",
                            ...criticityInlineStyles(RISK_MATRIX_VIEW_MODE.initial.value),
                        }}
                    >
                        {riskOppItem.initialCriticity}
                    </span>

                    {/* ARROW CONNECTION TWO CRITICITIES */}
                    <span
                        className="material-icons"
                        style={{
                            fontSize: "20px",
                        }}
                    >
                        arrow_right_alt
                    </span>
                </React.Fragment>
            )}

            {/* CURRENT CRITICITY */}
            <span
                className="riskTableSmallCriticityTag"
                style={{
                    marginLeft: expandMode === RISK_OPP_EXPAND_MODES.tableOnly ? "0px" : "3px",
                    ...criticityInlineStyles(RISK_MATRIX_VIEW_MODE.current.value),
                }}
            >
                {riskOppItem.currentCriticity ?? riskOppItem.initialCriticity}
            </span>

            {/* ARROW CONNECTION TWO CRITICITIES */}
            <span
                className="material-icons"
                style={{
                    fontSize: "20px",
                }}
            >
                arrow_right_alt
            </span>

            {/* TARGET CRITICITY */}
            <span
                className="riskTableSmallCriticityTag"
                style={{
                    ...criticityInlineStyles(RISK_MATRIX_VIEW_MODE.target.value),
                }}
            >
                {riskOppItem.targetCriticity}
            </span>

            {/* INCEPTION */}
            {expandMode === RISK_OPP_EXPAND_MODES.tableOnly && !isReviewMode && (
                <React.Fragment>
                    {/* INCEPTION */}
                    <div className="riskTableItemDateContainer flexColStartCenter">
                        <span className="riskTableItemTitle">Inception</span>
                        <span className="riskTableItemDate">
                            {getDateString(riskOppItem.createdAt)}
                        </span>
                    </div>
                    {/* LAST MODIFIED */}
                    {riskOppItem.updatedAt && (
                        <div className="riskTableItemDateContainer flexColStartCenter">
                            <span className="riskTableItemTitle">Last Modified</span>
                            <span className="riskTableItemDate">
                                {getDateString(riskOppItem.updatedAt)}
                            </span>
                        </div>
                    )}
                </React.Fragment>
            )}

            {/* RESPONSIBLE AVATAR */}
            <TooltipWrapper
                content={responsibleInfo.label}
                tooltipId="risk-table-tooltip"
            >
                <div className="actionBigTableItemResponsible flexCenterCenter" >
                    <CvtAvatar 
                        value={responsibleInfo}
                        radius={12}
                        fontSize="10px"
                        margin="0px 8px 0px 8px"
                    />
                </div>
            </TooltipWrapper>
        </div>
    );
});
