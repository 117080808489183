import React from 'react';
import {BrowserRouter} from 'react-router-dom'
import {createRoot} from 'react-dom/client';

//COMPONENT
import App from './01-Components/00-App/App';

//AMPLIFY
import {Amplify} from 'aws-amplify'
import {Auth} from '@aws-amplify/auth'
import config from './aws-exports'
import { envVarManagement } from './envVarManagement';

//////////////////////////////////
/// ENV VAR FOR AMPLIFY CONFIG ///
//////////////////////////////////

const envCookieDomain = envVarManagement("envCookieDomain")
const envCoreApi = envVarManagement("envCoreApi")
const envMappingApi = envVarManagement("envMappingApi")
const envMappingStorage = envVarManagement("envMappingStorage")
const envSso = envVarManagement("envSso")

/////////////////////////////
/// AMPLIFY CONFIGURATION ///
/////////////////////////////

//AMPLIFY CONFIG 
Amplify.configure({
  ...config,
  Auth: {
    ...config,
    cookieStorage: {
      path: '/', 
      domain: envCookieDomain,
      expires: 30,
      sameSite: 'lax',
      secure: envCookieDomain !== "localhost" 
    }
  }
});

//IMPORT CORE REST API TO THE CONFIGURATION
Amplify.configure({
    "aws_cloud_logic_custom": [
      envCoreApi,
      envMappingApi
    ]
});

//CONFIGURE OAUTH IF NEEDED
if(envSso.active){
  Auth.configure({
    "oauth": {
      "domain": envSso.params.domain,
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": envSso.params.redirectSignIn,
      "redirectSignOut": envSso.params.redirectSignOut,
      "responseType": "code"
    }
  });
}

//STORAGE
Auth.configure(envMappingStorage);

//////////////////
/// APP RENDER ///
//////////////////

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);