import { CvtButton } from "@mi-gso/cvt";
import { memo } from "react";

// EDIT SIDE BAR SAVE ERROR MODAL
export default memo(function EditSideBarSaveErrorModal({
    handleShowHideSaveModal,
}) {
  /////////////////////////
  /// RENDER //////////////
  /////////////////////////
  return (
    <div
      className="rightSideBarSaveErrorModalContainer flexCenterCenter"
      onClick={handleShowHideSaveModal}
    >
      <div className="rightSideBarSaveErrorModal flexColStartCenter">
        {/* HEADERS */}
        <div
          className="flexBetweenCenter"
          style={{
            borderBottom: "1px solid var(--border-color-gray-2)",
            padding: "5px",
            width: "100%",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "14px",
              marginLeft: "5px",
            }}
          >
            Save Error
          </span>
          <span
            className="material-icons rightSideBarSaveErrorCloseBtn"
            onClick={handleShowHideSaveModal}
          >
            close
          </span>
        </div>

        {/* BODY */}
        <span className="rightSideBarSaveErrorModalBody flexCenterCenter">
          Please fill out all mandatory fields to save
        </span>

        {/* BOTTOM CLOSE BUTTON */}
        <CvtButton
          color="green"
          txt="OK"
          handleSubmit={handleShowHideSaveModal}
          margin="5px 0px "
        />
      </div>
    </div>
  );
});
