import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FUNCT_FIND_INDEX, FUNC_SAFE_GET_JSON_FROM_STRING } from "../../../../../00-Core/Standards";
import { WBS_DEFAULT_NAVIGATION_OPTIONS } from "../../../00-Wbs/00-Helpers/WbsConstants";
import { WBS_OPEN_ITEM } from "../../../00-Wbs/00-Helpers/WbsFunctions";
import {
    GOV_REVIEW_MEETING_STATUS,
    GOV_REVIEW_MEETING_STATUS_KEY,
} from "../../00-Helpers/GovReviewConstants";
import {
    ADD_ITEM_TO_AGENDA,
    GOV_FUNC_SAVE_REVIEW,
    GOV_REVIEW_MODE_UPDATE_DISPLAY,
    REMOVE_ITEM_FROM_AGENDA,
} from "../../00-Helpers/GovReviewFunctions";
import GovReviewAgendaSideBar from "../02-GovReviewAgendaSideBar/GovReviewAgendaSideBar";
import GovReviewAttendees from "./GovReviewAttendees";
import GovReviewLeftSideBar from "./GovReviewLeftSideBar";
import GovReviewNotes from "./GovReviewNotes";

export default function GovReviewViewMode({
    govItem,
    editMode,
    usersList,
    currentUser,
    securityGroup,
    projectData,
    appDispatcher,
    wbsDispatcher,
    projectIds,
    isProject,
    projectId,
}) {
    
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // LOCAL STATE WITH GOV ITEM
    const [state, setState] = useState({
        govItemUpdated: false,
        govItem: govItem,
    });

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // HANDLE GOV REVIEW DATA UPDATES
    const handleUpdateGovReview = useCallback(
        (e, key, value, itemId) => {
            e.preventDefault();

            //PREPARE MUTATION OBJECT
            let mutationObject = {
                id: itemId ? itemId : state.govItem.id,
                [key]: value,
            };

            // SAVE GOVERNANCE DATA ON APP STATE AND GRAPHQL
            GOV_FUNC_SAVE_REVIEW(
                null,
                currentUser,
                projectData,
                false,
                mutationObject,
                securityGroup,
                null,
                null,
                null,
                appDispatcher,
                null
            );

            // UPDATE PORTFOLIO DISPLAY APP STATE
            GOV_REVIEW_MODE_UPDATE_DISPLAY(
                state.govItem,
                mutationObject,
                usersList,
                securityGroup,
                appDispatcher,
                projectIds,
                isProject,
                projectId,
            );
        },
        [appDispatcher, currentUser, isProject, projectData, projectId, projectIds, securityGroup, state.govItem, usersList]
    );

    // HANDLE UPDATE AGENDA ITEMS
    const handleUpdateReviewAgenda = useCallback(
        (e, agendaItem, isRemoving) => {
            e.preventDefault();

            // INIT
            let newAgenda = null;
            let mutation = null;

            // CASE WE ADD AN ITEM
            if (!isRemoving) {
                newAgenda = ADD_ITEM_TO_AGENDA(agendaItem, state.govItem.agenda);
            } else {
                // REMOVE FROM AGENDA
                newAgenda = REMOVE_ITEM_FROM_AGENDA(agendaItem, state.govItem.agenda);
            }

            // GET UPDATED REVIEW
            mutation = {
                id: state.govItem.id,
                agenda: JSON.stringify(newAgenda),
            };

            // SAVE UPDATED REVIEW
            GOV_FUNC_SAVE_REVIEW(
                null,
                currentUser.username,
                projectData,
                false,
                mutation,
                securityGroup,
                null,
                null,
                null,
                appDispatcher
            );

            // UPDATE PORTFOLIO DISPLAY APP STATE
            GOV_REVIEW_MODE_UPDATE_DISPLAY(
                state.govItem,
                mutation,
                usersList,
                securityGroup,
                appDispatcher,
                projectIds,
                isProject,
                projectId,
            );
        },
        [appDispatcher, currentUser.username, isProject, projectData, projectId, projectIds, securityGroup, state.govItem, usersList]
    );

    // HANDLE ATTENDEES CHECKBOX CLICK
    const handleAttendeesCheckbox = useCallback(
        (e, userId) => {
            // INIT / PARSE
            let attendees = FUNC_SAFE_GET_JSON_FROM_STRING(state.govItem.attendees);

            // IF NOT EXIST, INIT
            if (!attendees) {
                attendees = [];
            }

            // FIND THE USER IN THE ATTENDEES LIST
            let userIndex = FUNCT_FIND_INDEX(attendees, "id", userId);
            const oldStatus = attendees[userIndex].present;
            attendees[userIndex].present = !oldStatus;

            handleUpdateGovReview(e, "attendees", attendees, state.govItem.id);
        },
        [handleUpdateGovReview, state?.govItem?.attendees, state?.govItem?.id]
    );

    // HANDLE DELETE GOV REVIEW
    const handleOpenWbsItem = useCallback(
        (itemId, type) => {
            // BUILD WBS NAVIGATION OPTIONS
            let wbsNavigationOptions = _.cloneDeep(WBS_DEFAULT_NAVIGATION_OPTIONS);

            // SET DESTINATION
            wbsNavigationOptions.goToItemId = itemId;
            wbsNavigationOptions.goToDomain = type;
            wbsNavigationOptions.overrideWbsId = state.govItem.projectId;

            WBS_OPEN_ITEM(
                projectData,
                type,
                itemId,
                null,
                wbsNavigationOptions,
                wbsDispatcher,
                appDispatcher,
                securityGroup
            );
        },
        [appDispatcher, projectData, securityGroup, state.govItem.projectId, wbsDispatcher]
    );

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // DISPLAYED START DATE
    const displayedStartDate = useMemo(() => {
        return state.govItem.startDate.toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
            day: "numeric",
        });
    }, [state.govItem.startDate]);

    // GET REVIEW MEETING STATUS
    const reviewMeetingStatus = useMemo(() => {
        let findIndex = FUNCT_FIND_INDEX(
            GOV_REVIEW_MEETING_STATUS,
            "id",
            state.govItem.reviewMeetingStatus ?? "inProgress"
        );

        // IF FOUND, RETURN THE CORRESPONDING STATUS
        if (findIndex !== -1) {
            return GOV_REVIEW_MEETING_STATUS[findIndex];
        }
    }, [state.govItem.reviewMeetingStatus]);

    // CHECK IF USER CAN EDIT REVIEW CONTENT
    const isEditEnabled = useMemo(() => {
        if (
            editMode &&
            state.govItem.reviewMeetingStatus === GOV_REVIEW_MEETING_STATUS_KEY.inProgress
        ) {
            return true;
        }

        return false;
    }, [editMode, state.govItem.reviewMeetingStatus]);
    
    // CAN EDIT
    const canEdit = useMemo(() => {
        return govItem.createdBy === currentUser.username || securityGroup.isCurrentUserEditor;
    }, [currentUser.username, govItem.createdBy, securityGroup.isCurrentUserEditor]);

    /////////////////////////////////////////////////////
    // USE EFFECTS //////////////////////////////////////
    /////////////////////////////////////////////////////

    // UPDATE STATE IF GOV ITEM CHANGES
    useEffect(() => {
        if (state.govItem !== null && govItem.id !== state.govItem.id) {
            setState({
                govItemUpdated: false,
                govItem: govItem,
            });
        }
    }, [govItem, state.govItem]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexStartStrech noAnimations"
            style={{
                width: "100%",
            }}
        >
            {/* side bar */}
            {editMode ? (
                <GovReviewLeftSideBar
                    handleUpdateGovReview={handleUpdateGovReview}
                    govReviewMeetingStatus={state.govItem.reviewMeetingStatus}
                    canEdit={canEdit}
                />
            ) : null}

            {/* BODY CONTENT */}
            <div
                className="flexColStartStart"
                style={{
                    width: "100%",
                    padding: "10px",
                }}
            >
                {/* TITLE */}
                <div className="flexBetweenCenter" style={{ width: "100%" }}>
                    {/* LEFT: TITLE WITH DATE */}
                    <div className="flexStartCenter">
                        {/* COLOR DOT */}
                        <div
                            style={{
                                backgroundColor: "var(--color-migso-yeallow)",
                                width: "25px",
                                height: "25px",
                                borderRadius: "15px",
                                marginRight: "10px",
                            }}
                        ></div>

                        {/* TITLE */}
                        <span
                            style={{
                                color: "var(--color-text-2)",
                                fontSize: "24px",
                                fontWeight: "600",
                            }}
                        >
                            {state.govItem.name}
                        </span>
                        <span
                            style={{
                                color: "var(--border-color-gray-1)",
                                fontSize: "24px",
                                fontWeight: "600",
                                marginLeft: "10px",
                            }}
                        >
                            |
                        </span>

                        {/* DATE */}
                        <span
                            style={{
                                color: "var(--color-text-4)",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginLeft: "10px",
                            }}
                        >
                            {displayedStartDate}
                        </span>
                    </div>

                    {/* RIGHT : STATUS */}
                    <div
                        style={{
                            color: "white",
                            backgroundColor: reviewMeetingStatus.backgroundColor,
                            fontWeight: "600",
                            fontSize: "12px",
                            padding: "5px 15px",
                            borderRadius: "20px",
                        }}
                    >
                        {reviewMeetingStatus.label}
                    </div>
                </div>

                {/* TIMELINE */}
                {/* <GovReviewTimeline
                    events={JSON.parse(state.govItem.events)}
                    govReviewStatus={state.govItem.reviewMeetingStatus}
                    usersList={usersList}
                /> */}

                {/* AGENDA */}
                <GovReviewAgendaSideBar
                    key={"GovReviewAgendaSideBar_" + state.govItem.reviewMeetingStatus}
                    agendaList={state.govItem.agenda ?? []}
                    editMode={isEditEnabled && canEdit}
                    handleChange={handleUpdateGovReview}
                    handleOpenWbsItem={handleOpenWbsItem}
                    handleUpdateReviewAgenda={handleUpdateReviewAgenda}
                    inReviewMode={true}
                    projectData={projectData}
                    reviewStatus={state.govItem.reviewMeetingStatus}
                />

                {/* NOTES */}
                <GovReviewNotes
                    reviewNotes={state.govItem.reviewNotes}
                    editMode={isEditEnabled && canEdit}
                    handleUpdateGovReview={handleUpdateGovReview}
                    reviewId={state.govItem?.id}
                />

                {/* ATTENDEES */}
                <GovReviewAttendees
                    attendees={state.govItem.attendees ? JSON.parse(state.govItem.attendees) : []}
                    userList={usersList}
                    editMode={isEditEnabled && canEdit}
                    handleAttendeesCheckbox={handleAttendeesCheckbox}
                    handleUpdateGovReview={handleUpdateGovReview}
                    govItem={govItem}
                />
            </div>
        </div>
    );
}
