import { FUNC_BOARD_GET_UPDATED_LAST_ACTIVITIES } from "./BoardFunctions";

// PORTFOLIO STATE
const defaultPortfolioState = {
    id: null,
    reference: null,
    name: "",
    pictureUrl: "",
    shortDescription: "",
    longDescription: "",
    status: "open",
    official: false,
    itemViewers: [],
    itemEditors: [],
    selectedProjects: [],
    selectedOrganizations: [],
};

// GLOBAL BOARD STATE
export const initialState = {
    actions: [],
    reviews: [],
    actionsListedByProject: true,
    isCreatePortfolio: false,
    isEditPortfolio: false,
    isEditingRoles: false,
    isAddingUsers: false,
    portfolioEdit: { ...defaultPortfolioState },
    portfolioEditUpdateTracker: 0,
    portfolioEditDragDropDraggingType: "DEFAULT",
    portfolioEditDragDropActive: false,
    portfolioHasChanged: false,
    portfoliosShowClosed: false,
    showProjetListView: false,
    lastActivities: [],
    loaders: {
        actions: false,
        reviews: false,
        portfolioSaving: false,
        portfolioDeleting: false,
        projectLoadingId: null,
        orgaLoadingId: null,
        portfolioLoadingId: null,
        // [portfolioId1, portfolioId2]
        updatingDeletedProjects: null,
    },
    hasCheckedDeletedProjects: false,
};

/////////////// REDUCER /////////////////
// SET_SIMPLE_STATE
// SET_GLOBAL_STATE
// SET_LOADER
// SET_LOADERS
// SET_SWITCH_MODE_CREATE_PORTFOLIO
// SET_SWITCH_MODE_EDIT_PORTFOLIO
// SET_EXIT_ROLE_EDITION
// SET_EXIT_PORTFOLIO_CREATION
// SET_ADD_LAST_ACTIVITY
// SET_DRAG_DROP
/////////////////////////////////////////

export default function boardReducer(state, action) {
    let newState = null;

    switch (action.type) {
        // SIMPLE KEY VALUE UPDATE
        case "SET_SIMPLE_STATE":
            // key, value

            return {
                ...state,
                [action.key]: action.value,
            };

        // UPDATE STATE OBJECT
        case "SET_GLOBAL_STATE":
            //options: source, object

            //INIT
            let returnObject = {};
            let currentSource = state[action.options.source];

            //GET ALL OBJECTS ATTRIBUTS TO UPDATE
            let attributs = Object.keys(action.options.object);

            //UPDATE REFERNECE OBJECT WITH NEW VALUES
            for (let i = 0; i < attributs.length; i++) {
                currentSource[attributs[i]] = action.options.object[attributs[i]];
            }

            returnObject = {
                ...state,
                [action.options.source]: currentSource,
            };

            if (action.options.source === "portfolioEdit") {
                // INCREMENT UPDATE TRACKER
                returnObject.portfolioEditUpdateTracker += 1;
                returnObject.portfolioHasChanged = true;
            }

            //RETURN
            return returnObject;

        // SET ONE LOADER
        case "SET_LOADER":
            // type, name, status

            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [action.name]: action.status,
                },
            };

        // SET MULTIPLE LOADERS
        case "SET_LOADERS":
            // type, [name], status
            newState = { ...state };

            // CHANGE STATE FOR EACH LOADERS
            action.names.forEach((loader) => {
                newState.loaders[loader] = action.status;
            });

            return newState;

        // GO TO CREATE PORTFOLIO MODE
        case "SET_SWITCH_MODE_CREATE_PORTFOLIO":
            let refNumbers = action.portfolios.map((portfolio) => portfolio.reference);
            let reference;
            if (refNumbers.length > 0) {
                reference = Math.max(...refNumbers) + 1;
            } else {
                reference = 1;
            }

            return {
                ...state,
                isCreatePortfolio: true,
                portfolioHasChanged: false,
                portfolioEdit: {
                    ...state.portfolioEdit,
                    reference: reference,
                },
            };

        // GO TO PORTFOLIO EDITION MODE
        case "SET_SWITCH_MODE_EDIT_PORTFOLIO":
            // portfolio

            return {
                ...state,
                isEditPortfolio: true,
                portfolioHasChanged: false,
                portfolioEdit: {
                    ...action.portfolio,
                    itemEditors: action.portfolio.itemEditors,
                    itemViewers: action.portfolio.itemViewers,
                    selectedProjects: JSON.parse(action.portfolio.selectedProjects),
                    selectedOrganizations: JSON.parse(action.portfolio.selectedOrganizations),
                },
            };

        // UPDATE PORTFOLIO USER LIST
        case "SET_UPDATE_PORTFOLIO_PERMISSIONS":
            // itemViewers, itemEditors, exitSelection

            newState = {
                ...state,
                portfolioHasChanged: true,
                portfolioEdit: {
                    ...state.portfolioEdit,
                },
            };

            // REPLACE VIEWERS
            if (action.itemViewers) {
                newState.portfolioEdit.itemViewers = action.itemViewers;
            }

            // REPLACE EDITORS
            if (action.itemEditors) {
                newState.portfolioEdit.itemEditors = action.itemEditors;
            }

            // EXIT ADDING USERS
            if (action.exitSelection) {
                newState.isAddingUsers = false;
            }

            return newState;

        // EXIT ROLE EDITION, GO BACK TO PORTFOLIO CREATE / EDIT
        case "SET_EXIT_ROLE_EDITION":
            return {
                ...state,
                isEditingRoles: false,
                isAddingUsers: false,
            };

        // EXIT PORTFOLIO CREATE / EDIT MODE, GO BACK TO BOARD
        case "SET_EXIT_PORTFOLIO_CREATION":
            return {
                ...state,
                isCreatePortfolio: false,
                isEditPortfolio: false,
                isEditingRoles: false,
                isAddingUsers: false,
                portfolioEdit: { ...defaultPortfolioState },
                loaders: {
                    ...state.loaders,
                    actions: false,
                    portfolioSaving: false,
                    portfolioDeleting: false,
                },
            };

        case "SET_ADD_LAST_ACTIVITY":
            // newActivity
            let updatedLastActivities = FUNC_BOARD_GET_UPDATED_LAST_ACTIVITIES(
                state.lastActivities,
                action.newActivity
            );

            newState = {
                ...state,
                lastActivities: updatedLastActivities,
            };

            return newState;

        case "SET_DRAG_DROP":
            // draggingType, dndStatus

            return {
                ...state,
                portfolioEditDragDropDraggingType: action.draggingType,
                portfolioEditDragDropActive: action.dndStatus,
            };

        default:
            return state;
    }
}
