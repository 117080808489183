import React, { useMemo } from "react";
import { CvtButton, CvtTooltip } from "@mi-gso/cvt";
import {
    FUNC_BOARD_GET_PROJECTS_USER_HAS_NO_ACCESS_TO,
    FUNC_BOARD_GET_USER_ROLE,
} from "../../../00-Helpers/BoardFunctions";

//USER

/////////////////////////////////////
/// USER ITEM COMPONENT /////////////
/////////////////////////////////////

export default function UserManagementTableItem({
    userDetail,
    name,
    isSelected,
    isAddingUsers,
    projectList,
    portfolioEdit,
    handleSelectUser,
    handleTypeClick,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // CHECK USER HAS ACCES ON SELECTED PROJECTS AND ORGANIZATIONS (CORE PERMISSION)
    const hasNoAccessOnString = useMemo(() => {
        // GET PROJECT LIST THAT USER HAS NO ACCESS TO
        let noAccessProjects = FUNC_BOARD_GET_PROJECTS_USER_HAS_NO_ACCESS_TO(
            userDetail.id,
            portfolioEdit.selectedProjects,
            portfolioEdit.selectedOrganizations,
            projectList
        );

        // BUILD STRING FOR TOOLTIP
        let str;
        if (noAccessProjects.length > 0) {
            str = "User has no access on projects : " + noAccessProjects[0].name;

            noAccessProjects.shift();

            noAccessProjects.forEach((project) => {
                str += ", " + project.name;
            });
            return str;
        }

        return null;
    }, [
        portfolioEdit.selectedOrganizations,
        portfolioEdit.selectedProjects,
        projectList,
        userDetail,
    ]);

    // USER PERMISSION ON PORTFOLIO
    const userPermission = useMemo(() => {
        return FUNC_BOARD_GET_USER_ROLE(userDetail.id, portfolioEdit);
    }, [portfolioEdit, userDetail.id]);

    ///////////////////////////////
    /// RENDER ////////////////////
    ///////////////////////////////

    return (
        <tr className="projectUserManagementTableItem">
            {/* SELECT USER */}
            <td style={{ width: "60px" }}>
                <div
                    key={isSelected}
                    className={
                        "manageUsersTableSelectBox " +
                        (isSelected ? "selectedManageUsersTableSelectBox" : "")
                    }
                    onClick={() => handleSelectUser(userDetail)}
                ></div>
            </td>

            {/* NAME */}
            <td
                style={{
                    width: "40%",
                    textAlign: "left",
                }}
            >
                {name ? (
                    <div className="flexStartCenter">
                        {/* CHECK USERS PERMISSIONS ON SELECTED PROJECTS */}
                        {hasNoAccessOnString && (
                            <div
                                id={"tooltipUserHasNoAccess_" + userDetail.id}
                                className="flexStartCenter"
                            >
                                <span
                                    className="material-icons"
                                    style={{
                                        marginRight: "5px",
                                        color: "var(--color-bad)",
                                    }}
                                >
                                    report_problem
                                </span>
                                <CvtTooltip
                                    anchorId={"tooltipUserHasNoAccess_" + userDetail.id}
                                    content={hasNoAccessOnString}
                                />
                            </div>
                        )}
                        <span>{name}</span>
                    </div>
                ) : (
                    <div className="flexStartCenter projectUserManagementRefreshButton">
                        {/* CASE USER NOT FOUND */}
                        <span
                            className="material-icons"
                            style={{
                                marginRight: "5px",
                                color: "var(--color-bad)",
                            }}
                        >
                            report_problem
                        </span>
                        User unknown
                    </div>
                )}
            </td>

            {/* EMAIL */}
            <td
                style={{
                    width: "40%",
                    textAlign: "left",
                    fontWeight: 500,
                }}
            >
                {userDetail.email}
            </td>

            {/* USER ROLE (EDITOR / VIEWER) */}
            {!isAddingUsers && (
                <td>
                    <CvtButton
                        color={userPermission === "editor" ? "green" : "blue"}
                        txt={userPermission === "editor" ? "Editor" : "Viewer"}
                        outline
                        needConfirmation
                        txtConfirm={userPermission === "editor" ? "To Viewer ?" : "To Editor ?"}
                        handleSubmit={() => handleTypeClick(userDetail)}
                    />
                </td>
            )}
        </tr>
    );
}
