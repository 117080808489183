import { CvtButton, FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import { useMemo } from "react";
import { GOV_FUNC_GET_REVIEW_LABEL } from "../../../04-Governance/00-Helpers/GovFunctions";
import { WBS_GET_DOMAIN_ICON } from "../../00-Helpers/ActionsFunctions";

export default function ActionLinksListItem({
  link,
  projectData,
  onDeleteLinkItem,
  onLinkItemClick,
  canModifySelected,
  isReadOnlyMode,
}) {
  // GET ITEM FROM PROJECT DATA
  const item = useMemo(() => {
    return FIND_OBJECT_ARRAY_ITEM(
      projectData[link.type],
      "id",
      link.destinationItemId
    );
  }, [link.destinationItemId, link.type, projectData]);

  // ITEM LABEL
  const itemLabel = useMemo(() => {
    if (!item) {
      return null;
    }

    if (link.type === "govReview") {
      return GOV_FUNC_GET_REVIEW_LABEL(item);
    } else {
      return item.name || item.title || item.action;
    }
  }, [item, link.type]);

  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////
  return (
    <div
      className="actionCardLinkItem flexBetweenCenter"
      // onClick={(e) => {
      //   onLinkItemClick(e, link);
      // }}
      style={{
        margin: "5px 10px",
      }}
    >
      {/* LEFT PART */}
      <div className="flexStartCenter viewModeDescriptionText flexGap10">
        {/* DOMAIN ICON */}
        <span
          className="material-icons structureBlockTitleCellIcon"
          style={{
            marginRight: "0px",
          }}
        >
          {WBS_GET_DOMAIN_ICON(link, item?.isRisk ? true : false)}
        </span>

        {/* DISPLAY ID */}
        {item?.displayId ? (
          <span className="actionCardInfosProgressBarDateLegend">
            {item.displayId}
          </span>
        ) : null}

        {/* NAME */}
        {item ? <span>{itemLabel}</span> : "Item not found"}
      </div>

      {/* DELETE BUTTON */}
      {canModifySelected && !isReadOnlyMode ? (
        <div className="actionCardLinkItemDeleteButton">
          <CvtButton
            color="red"
            icon="delete"
            iconType="filled"
            shadow="none"
            shape="noShape"
            txt=""
            confirmType="danger"
            needConfirmation
            txtConfirm={"Sure ?"}
            handleSubmit={onDeleteLinkItem}
            handleSubmitOptions={link}
          />
        </div>
      ) : null}
    </div>
  );
}
