import {memo} from 'react';
import { FUNCT_FIND_INDEX, FUNC_GET_AGENDA_TYPE_ITEMS } from '../../../../../00-Core/Standards';
import ActionLinksSideBarListItem from './ActionLinksSideBarListItem';
import { ACTION_LINK_ITEM_TYPES } from '../../00-Helpers/ActionsConstants';
import { FIND_OBJECT_ARRAY_ITEM } from '@mi-gso/cvt';


export default memo(function ActionLinksSideBarList({
    parsedLinksList,
    projectData,
    onEditItem,
    onDeleteLinkItem,
    editingLink,
    authorizedProjectData,
}) {

    ////////////////////////
    // RENDER //////////////
    ////////////////////////
    
    return (
        <div
            style={{
                maxHeight: "250px",
                overflow: 'auto',
            }}
        >
            {
                parsedLinksList.length === 0 ?(
                    <div className="text_Color4_600_14px">No links yet</div>
                ) : null}

            {
                parsedLinksList.map((link, index) => {

                    const typeIconIndex = FUNCT_FIND_INDEX(ACTION_LINK_ITEM_TYPES, "id", link.type);
                    let icon = "timer";
                    if(typeIconIndex !== -1) {
                        icon = ACTION_LINK_ITEM_TYPES[typeIconIndex].icon;
                    }

                    const typeItems = FUNC_GET_AGENDA_TYPE_ITEMS(link.type, projectData);
                    
                    const findIndex = FUNCT_FIND_INDEX(typeItems, "id", link.destinationItemId);

                    if(findIndex === -1) return null;

                    const itemData = typeItems[findIndex];

                    
                    const authorizedItem = FIND_OBJECT_ARRAY_ITEM(authorizedProjectData[link.type], "id", link.destinationItemId);
                    const isAuthorized = authorizedItem !== undefined && authorizedItem !== null;
                   

                    return editingLink?.id && editingLink?.id === link?.id ? null :
                    (
                        <ActionLinksSideBarListItem 
                            key={"action_links_list_inner_" + link?.id + link.destinationId + index}
                            link={link}
                            itemData={itemData}
                            authorizedProjectData={authorizedProjectData}
                            onEditItem={onEditItem}
                            onDeleteLinkItem={onDeleteLinkItem}
                            icon={icon}
                            isAuthorized={isAuthorized}
                        />
                    )
                })
            }
        </div>
    )
});