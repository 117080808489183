import React, { useRef } from "react";
import { useOnScreen } from "../00-Helpers/Hooks";

// HOME CHAPTER COMPONENT
export default function HomeChapter({
	chapterId,
	page,
	chapterFlex, 
	children
}) {

    //PageRefs and boolean for animation
	const pageRef = useRef()

	//SEE IF THE CHAPTER IS VISIBLE OR NOT
	const isVisible = useOnScreen(pageRef, "-200px")

	//INCLUDE INSIDE OUR CHILD THE VISIBLE STATUS
	const childrenWithProps = React.Children.map(children, child => {
		if (React.isValidElement(child)) {
		  return React.cloneElement(child, {  isVisible, });
		}
		return child;
	  });

	//////////////
	/// RETURN ///
	//////////////

	return (
		<div
			className={"homeWelcomePage " + chapterFlex}
			// id={"Page" + page}
			id={chapterId}
			ref={pageRef}
			style={{
				backgroundColor: Math.floor(page) % 2 === 0 ? "#f8f9fb" : "#fff",
			}}
		>

			{/* BACKGROUND DIVS  */}
			<div 
				className={Math.floor(page) % 2 === 0 ? "homePatternBackground" : "homePatternBackground2"}
				style={{
					top:0
				}}
			/>
			<div 
				className={Math.floor(page) % 2 === 0 ? "homePatternBackground2" : "homePatternBackground"}
				style={{
					transform: Math.floor(page) % 3 === 0 ? "rotate(180deg)" : "rotate(0deg)", 
					bottom: 0
				}}
			/>
			{childrenWithProps}
	  </div>
	);
}