import { CvtInputTextArea } from "@mi-gso/cvt";
import { useCallback, useState } from "react";
import { APP_FORM_STYLE } from "../../../../../00-Core/Constants";

export default function GovReviewNotes({ reviewNotes, editMode, handleUpdateGovReview, reviewId }) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // LOCAL STATE
    const [state, setState] = useState({
        reviewNotes: reviewNotes ? reviewNotes : "",
    });
    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // Handle Changes
    const handleChange = useCallback((e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }, []);
    
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="governanceReviewModeBlock"
            style={{
                marginBottom: '10px'
            }}
        >
            <div className="text_Color2_600_12px">Notes</div>

            <CvtInputTextArea
                type="richTextArea"
                inputBlockCss="flexColStartStart modalOptionSubBlock"
                inputPadding="0px"
                inputBlockHeight="fit-content"
                inputCss={"form-control modalInputLight"}
                inputFontSize={APP_FORM_STYLE.fontSize}
                inputBlockPadding="0px"
                inputBlockMargin="10px 0px 0px 0px"
                bottomMargin="0px"
                inputWidthFull={true}
                name="reviewNotes"
                editingValue={state.reviewNotes}
                value={reviewNotes}
                onChange={handleChange}
                onBlur={(e) => {
                    handleUpdateGovReview(e, "reviewNotes", state.reviewNotes);
                }}
                enrichTitleCss="enrichTextTilteSideBar"
                enrichEditorCss="modalInputEnrichTxt"
                isOnViewMode={!editMode}
            />
        </div>
    );
}
