// LIBS
import { CvtInput } from "@mi-gso/cvt";
import { useCallback, useMemo, useState, useEffect } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

// FUNCTIONS
import { FUNC_HANDLE_INPUT_CHANGE } from "../../../../00-Core/Standards";

// COMPONENTS
import {
    FUNC_BOARD_FILTER_PROJECT_LIST,
    FUNC_BOARD_GET_ORGANIZATION_PROJECTS,
} from "../../00-Helpers/BoardFunctions";
import BoardProjectListPicture from "../BoardProjectListPicture";

export default function PortfolioCreateEditProjectList({
    organizationList,
    projectList,
    selectedProjects,
    selectedOrganizations,
    dragDropActive,
    draggingType,
}) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////
    const [state, setState] = useState({
        searchQuery: "",
    });

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // ON SEARCH INPUT CHANGE
    const handleSearchChange = useCallback((e) => {
        FUNC_HANDLE_INPUT_CHANGE(e, setState);
    }, []);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // FILTERED PROJECT LIST
    const filteredProjectList = useMemo(() => {
        return FUNC_BOARD_FILTER_PROJECT_LIST(projectList, organizationList, state.searchQuery);
    }, [organizationList, projectList, state.searchQuery]);

    // ORGANIZATION LIST WITHOUT SELECTED ONES
    const availableOrganizations = useMemo(() => {
        return organizationList.filter((orga) => !selectedOrganizations?.includes(orga.id));
    }, [organizationList, selectedOrganizations]);

    // PROJECT LIST WITHOUT SELECTED ONES
    const availableProjects = useMemo(() => {
        return filteredProjectList.filter((project) => !selectedProjects?.includes(project.id));
    }, [filteredProjectList, selectedProjects]);



    // SCROLL TO ORGANIZATION IF DRAGGING SINGLE PROJECT FROM SELECTED SIDE
    useEffect(() => {
        // IF NOT THE DEFAULT DRAGGING TYPE(IS DRAGGING SOMETHING)
        if(draggingType !== "DEFAULT") {
            // IF THE ORGANIZATION OF THE PROJECT BEING DRAGGED IS NOT THE IN SELECTED ORGANIZATIONS
            if(!selectedOrganizations?.includes(draggingType)) {
                // GET TO HTML ELEMENT
                const orgaDroppable = document.getElementById(draggingType);
                if(orgaDroppable) {
                    orgaDroppable.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "nearest"
                      });
                }
            }
        }
    }, [draggingType, selectedOrganizations]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="structureBlockInfo flexColStartStart"
            style={{
                width: "calc(50% - 5px)",
                backgroundColor: "var(--border-color-gray-00)",
                border: "1px solid var(--border-color-gray-0)",
                borderRadius: "10px",
                padding: "0px",
            }}
        >
            {/* TOP TITLE / SEARCH BAR BLOCK */}
            <div style={{ padding: "20px 20px 0px 20px", width: "100%" }}>
                {/* TITLE */}
                <div
                    style={{
                        fontWeight: "700",
                        color: "var(--color-text-2)",
                        marginBottom: "30px",
                    }}
                >
                    Organization / Project List
                </div>

                {/* SEARCH BAR */}
                <div className="flexStartCenter" style={{ width: "100%" }}>
                    <CvtInput
                        inputBlockCss="flexStartCenter modalOptionSubBlock"
                        inputCss="form-control modalInputLight"
                        inputBlockPadding="0px"
                        inputBlockMargin={"0px"}
                        inputWidthFull={true}
                        name="searchQuery"
                        placeholder={"Search"}
                        value={state.searchQuery}
                        onChange={handleSearchChange}
                    />
                    <div
                        className="material-icons"
                        style={{
                            marginLeft: "-40px",
                            userSelect: "none",
                            color: "var(--border-color-gray-2)",
                            cursor: state.searchQuery ? "pointer" : null,
                        }}
                        onClick={() => {
                            // CLEAR SEARCH BAR
                            setState({ searchQuery: "" });
                        }}
                    >
                        {state.searchQuery !== "" ? "close" : "search"}
                    </div>
                </div>
            </div>

            {/* GLOBAL ORG / PROJECT LIST BLOCK */}
            <div
                style={{
                    height: "calc(100vh - 390px)",
                    width: "100%",
                    marginTop: "15px",
                    padding: "0px 10px",
                    overflow: "auto",
                }}
            >
                {/* DROPPABLE COLUMN AREA */}
                <Droppable droppableId={"OrgProjectsListColumn"} isDropDisabled={draggingType !== "DEFAULT"}>
                    {(provided, snapshot) => (
                        <div
                            key={"OrgProjectsListColumn"}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                                backgroundColor: snapshot.isDraggingOver && draggingType === "DEFAULT" && dragDropActive === true ? "lightblue" : null,
                                borderRadius: "5px",
                                border: dragDropActive ? "2px dotted var(--color-bad-hover)" : null,
                                padding: "10px 20px",
                                minHeight: "100%",
                            }}
                        >
                            {/* MAP ORGANIZATION BOXES */}
                            {availableOrganizations.map((organization, index) => {
                                // GET ORGANIZATION PROJECTS
                                let projects = FUNC_BOARD_GET_ORGANIZATION_PROJECTS(
                                    organization.id,
                                    availableProjects
                                );

                                // IF ORGA CONTAINS PROJECTS
                                    return (
                                        // ORGA DRAGGABLE
                                        <Draggable
                                            key={organization.id}
                                            draggableId={organization.id}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                // ORGA DRAGGABLE CONTENT
                                                <div
                                                    id={organization.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        borderRadius: "5px",
                                                        overflow: "hidden",
                                                        border: snapshot.isDragging
                                                            ? "2px dotted var(--color-bad-hover)"
                                                            : "1px solid var(--border-color-gray-0)",
                                                        boxShadow:
                                                            "1px 1px 2px rgba(0, 0, 0, 0.25)",
                                                        width: "100%",
                                                        marginBottom: "10px",
                                                        backgroundColor: "white",
                                                        ...provided.draggableProps.style,
                                                    }}
                                                >
                                                    {/* ORGANIZATION NAME HEADER */}
                                                    <div
                                                        className="flexBetweenCenter boardPortfolioCreateFormDragableBox"
                                                        style={{
                                                            backgroundColor:
                                                                "var(--border-color-gray-0)",
                                                            color: "var(--color-text-2)",
                                                            fontWeight: "600",
                                                            fontSize: "12px",
                                                        }}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {/* LEFT PART */}
                                                        <div
                                                            className="flexStartCenter"
                                                            style={{ gap: "15px" }}
                                                        >
                                                            <BoardProjectListPicture
                                                                pictureUrl={organization.logoLitle}
                                                                alt={organization.name}
                                                                size="30px"
                                                            />
                                                            <span>
                                                                {organization.name ?? "Unknown"}
                                                            </span>
                                                        </div>

                                                        {/* RIGHT PART */}
                                                        <span className="material-symbols-outlined">
                                                            drag_indicator
                                                        </span>
                                                    </div>

                                                    {/* PROJECT LIST */}
                                                    <div
                                                        style={{
                                                            padding: "15px 15px 15px 35px",
                                                        }}
                                                    >
                                                        <Droppable
                                                            droppableId={organization.id}
                                                            // type={organization.id}
                                                            isDropDisabled={draggingType!==organization.id}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className="flexColStartStart"
                                                                    ref={provided.innerRef}
                                                                    style={{
                                                                        transition: '0.3s all ease',
                                                                        borderRadius: "5px",
                                                                        backgroundColor:
                                                                            snapshot.isDraggingOver
                                                                            && draggingType === organization.id
                                                                                ? "lightblue"
                                                                                : null,
                                                                        gap: "10px",
                                                                        minHeight: projects.length === 0 ? "1px" : "",
                                                                        height: snapshot.isDraggingOver && projects.length === 0 ? '42px' : null,
                                                                    }}
                                                                >
                                                                    {/* LIST ALL PROJECTS INSIDE ORGANIZATION */}
                                                                    {projects.map(
                                                                        (project, index) => {
                                                                            return (
                                                                                <Draggable
                                                                                    key={project.id}
                                                                                    draggableId={
                                                                                        organization.id +
                                                                                        "_" +
                                                                                        project.id
                                                                                    }
                                                                                    index={index}
                                                                                >
                                                                                    {(
                                                                                        provided,
                                                                                        snapshot
                                                                                    ) => (
                                                                                        // PROJECT BOX
                                                                                        <div
                                                                                            ref={
                                                                                                provided.innerRef
                                                                                            }
                                                                                            {...provided.draggableProps}
                                                                                            className="flexBetweenCenter boardPortfolioCreateFormDragableBox"
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    "white",
                                                                                                border: snapshot.isDragging
                                                                                                    ? "2px dotted var(--color-bad-hover)"
                                                                                                    : "1px solid var(--border-color-gray-0)",
                                                                                                color: "var(--color-text-3)",
                                                                                                fontWeight:
                                                                                                    "600",
                                                                                                fontSize:
                                                                                                    "10px",
                                                                                                ...provided
                                                                                                    .draggableProps
                                                                                                    .style,
                                                                                            }}
                                                                                            {...provided.dragHandleProps}
                                                                                        >
                                                                                            {/* LOGO AND NAME */}
                                                                                            <div
                                                                                                className="flexStartCenter"
                                                                                                style={{
                                                                                                    gap: "10px",
                                                                                                }}
                                                                                            >
                                                                                                <BoardProjectListPicture
                                                                                                    pictureUrl={
                                                                                                        project.logo
                                                                                                    }
                                                                                                    alt={
                                                                                                        project.name
                                                                                                    }
                                                                                                    size="30px"
                                                                                                />
                                                                                                {
                                                                                                    project.name
                                                                                                }
                                                                                            </div>

                                                                                            {/* DRAG / DROP BUTTON */}
                                                                                            <span
                                                                                                className="material-symbols-outlined"
                                                                                                style={{
                                                                                                    color: "var(--border-color-gray-0-5)",
                                                                                                }}
                                                                                            >
                                                                                                drag_indicator
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            );
                                                                        }
                                                                    )}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                            })}

                            {/* PLACEHOLDER WHEN AN ELEMENT IS MOVED */}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
}
