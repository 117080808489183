import React, { useState, useCallback, useMemo } from "react";

//LIBRARIES
import { useHistory, NavLink } from "react-router-dom";
import { CvtButton, CvtSpinnerGrow, CvtSelect } from "@mi-gso/cvt";

//ANIMATION´
import { HomeAnimationsFadeIn } from "./01-Components/HomeAnimations";

//COMPONENTS
import HomeChapter from "./01-Components/HomeChapter";
import HomeRegularPage from "./01-Components/HomeRegularPage";
import HomeStepSection from "./01-Components/HomeStepSection";
import HomeQualities from "./01-Components/HomeQualities";

// IMAGES

import {
  picLeafTriangleIcon,
  piclockKeyIcon,
  picTelescopeLogoName,
  picTelescopeNutshell,
  picTelescopeStep1,
  picTelescopeStep2,
  picTelescopeStep3,
  picTreesIcon,
  pictriangleStep1,
  pictriangleStep2,
  pictriangleStep3,
  picBigTriangles,
} from "../../00-Core/02-Pictures/PicturesCatalogue";

//APP
import { AuthAuthenticator } from "../00-App/01-Auth/01-Authenticator/AuthAuthenticator";
import { getProjectData } from "../00-App/02-Backend/AppBackendProjectData";

//CORE
import { FUNCT_FIND_INDEX } from "../../00-Core/Standards";

import {
  selectionCoreOrganizations,
  selectionCoreProjects,
} from "../../00-Core/Selection";
import { piclogoDefault } from "../../00-Core/02-Pictures/PicturesCatalogue";
import { APP_CORE_SANDBOX, APP_TOAST_TXT } from "../../00-Core/Constants";
import {
  HOME_CHAPTER_IDS,
  HOME_CONTROL_STEP_TABLE_DATA,
  HOME_CREATE_PORTFOLIO_STEP_TABLE_DATA,
  HOME_GREEN_TABLE_DATA,
  HOME_SECURITY_TABLE_DATA,
  HOME_TAKE_A_LOOK_STEP_TABLE_DATA,
} from "./00-Helpers/HomeConstants";
import { envVarManagement } from "../../envVarManagement";

//////////////////////////////
/// LANDING PAGE COMPONENT ///
//////////////////////////////

export default function Home({
  selectedOrganization,
  selectedProjectId,
  isWbsLoaded,
  currentUser,
  appDispatcher
}) {

  //ENV SERVICES
  const envServices = useMemo(() => envVarManagement("envServices"),[])

  //NEED TO REDIRECT TO ROADMAP
  let history = useHistory();

  // STATE
  const [loading, setLoading] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  ////////////////////////////////////////////////////////////////////
  /// OPEN PROJECT ///////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  const handleOpen = async (e, projectId) => {
    e.preventDefault();

    // IF A PROJECT IS LOADING, PREVENT AN OTHER ONE TO BE LOADED
    if (loading) {
      return;
    }

    //IF ALREADY OPENED
    if (projectId === selectedProjectId && isWbsLoaded) {
      //MOVE TO PORTFOLIO
      history.push("/controls");
    } else {
      //LAUNCH LOADING
      setLoading(projectId);

      //OK GET PROJECT DATA
      await getProjectData([projectId], null, [
        {
          organizationId: selectedOrganization.id,
          projectId: projectId,
        },
      ])
        .then((result) => {
          //LAUNCH APP DISPATCHER
          appDispatcher({
            type: "SET_INIT_PROJECT_DATA",
            projectData: result.projectData,
            projectId: projectId,
            resetRightComponent: true,
            wbsList: result.wbsList,
            wbsSettings: result.wbsSettings,
          });

          //MOVE TO PORTFOLIO
          history.push("/controls");

          //ISSUE
        })
        .catch((err) => {
          console.log(err);
          setLoading("");

          //DISPLAY ISSUE
          appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
              source: "appToast",
              object: {
                display: true,
                color: "danger",
                message: APP_TOAST_TXT.issueOpenProject,
              },
            },
          });
        });
    }
  };

  ////////////////////////////////
  /// SCROLL FOR UI MANAGEMENT ///
  ////////////////////////////////

  //STATE TO MANAGE SCROLL TOP
  const [scroll, setScroll] = useState(0);

  //UPDATE STATE
  const updateScroll = useCallback(() => {
    const appContentHome = document.getElementById("appContentHome");
    setScroll(appContentHome.scrollTop);
  }, []);

  //SCROLL TOP
  const scrollTop = useCallback(() => {
    const appContentHome = document.getElementById("appContentHome");
    appContentHome.scrollTop = 0;
    setScroll(0);
  }, []);

  ////////////
  /// CORE ///
  ////////////

  // ORGANIZATION ------------------------------------------------------------
  const coreOrganizations = useMemo(() => {
    return selectionCoreOrganizations(currentUser.coreOrganization);
  }, [currentUser.coreOrganization]);

  // PROJECT -----------------------------------------------------------------
  const coreProjects = useMemo(() => {
    return selectionCoreProjects(
      selectedOrganization.id,
      currentUser.coreProjects
    );
  }, [currentUser.coreProjects, selectedOrganization.id]);

  // CORE URL
  const coreSupportUrl = useMemo(() => {
    let foundCore = FUNCT_FIND_INDEX(envServices, "id", "core");
    return envServices[foundCore].url + "project";
  }, [envServices]);

  //HANDLE ORGANIZATION ------------------------------------------------------
  const handleOrganization = useCallback(
    (e) => {
      //FIND ORGA
      let findIndex = FUNCT_FIND_INDEX(coreOrganizations, "id", e.target.value);
      localStorage.setItem(
        "telescope-selectedOrgaId",
        coreOrganizations[findIndex].id
      );

      //UPDATE APP
      appDispatcher({
        type: "SET_GLOBAL_STATE",
        options: {
          source: "coreSelection",
          object: {
            selectedOrganization: coreOrganizations[findIndex],
          },
        },
      });
    },
    [appDispatcher, coreOrganizations]
  );

  //ORGANIZATION LOGO --------------------------------------------------------
  const jsxOrganizationLogo = useMemo(() => {
    if (selectedOrganization.id) {
      return (
        <img
          key={"pic-orga-" + selectedOrganization.id}
          src={
            selectedOrganization.logoLitle !== ""
              ? selectedOrganization.logoLitle
              : piclogoDefault
          }
          className="commonOrganizationLitleLogo"
          alt=""
          style={{ margin: "0px 15px 0px 0px" }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = piclogoDefault;
          }}
        />
      );
    } else {
      return null;
    }
  }, [selectedOrganization.id, selectedOrganization.logoLitle]);

  // SELECTORS
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const contactUsPage = useMemo(() => document.getElementById('contactUs'), [document.getElementById('contactUs')]);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const SecondPage = useMemo(() => document.getElementById("Page2"), [document.getElementById("Page2")]);

  //////////////
  /// RENDER ///
  //////////////

  return (
    <section id="appContentHome" onScroll={updateScroll}>
      {/* WELCOME */}
      <div className="homeWelcome">
        {/* LEFT SIDE */}
        <div
          className="flexColStartCenter"
          style={{
            width: "50%",
            height: "100%",
            padding: "40px 20px 10px 20px",
            zIndex: 1,
          }}
        >
          {/* APP LOGO */}
          <div
            className="flexCenterCenter"
            style={{
              width: "80%",
              margin: "0px 10% 50px 10%",
            }}
          >
            <HomeAnimationsFadeIn isVisible={true} from="right" delay={0}>
              <img
                src={picTelescopeLogoName}
                alt="cardPicture"
                style={{
                  width: "80%",
                  marginTop: "25px",
                }}
              />
            </HomeAnimationsFadeIn>
          </div>

          {/* CATCHPHRASE */}
          <div
            className="flexColCenterCenter"
            style={{
              margin: "0px 0px 0px 5%",
            }}
          >
            <HomeAnimationsFadeIn isVisible={true} from="left" delay={300}>
              <span
                className="landingPage_applicationName"
                style={{
                  fontSize: "clamp(32px, 1.2vw, 30px)",
                  textAlign: "center",
                }}
              >
                Diving into your project
              </span>
              {/* SMALL TEXT */}
              <div
                className="flexCenterCenter"
                style={{
                  fontFamily: "Sarabun, sans-serif",
                  fontSize: "clamp(22px, 1.2vw, 30px)",
                  fontWeight: 500,
                  color: "rgba(255,255,255,0.7)",
                  textAlign: "center",
                }}
              >
                Browse easily your portfolio to steer your projects <br />
                and get clear visibility from top to bottom levels
              </div>
            </HomeAnimationsFadeIn>
          </div>

          {/* HOMEPAGE BUTTONS */}
          <div
            className="flexCenterCenter"
            style={{
              marginTop: "10%",
              width: "80%",
              gap: "5%",
              margin: "60px 0px 0px 0px",
            }}
          >
            <HomeAnimationsFadeIn isVisible={true} from="left" delay={500}>
              <CvtButton
                color="green"
                txt="Get started"
                shadow="white"
                size="huge"
                handleSubmit={() =>
                  document
                    .getElementById(HOME_CHAPTER_IDS.telescopeNutShell)
                    ?.scrollIntoView({ behavior: "smooth" })
                }
                icon="rocket_launch"
              />
            </HomeAnimationsFadeIn>

            <HomeAnimationsFadeIn isVisible={true} from="left" delay={1000}>
              <CvtButton
                color="blue"
                txt="Contact Us"
                handleSubmit={() =>
                  document
                    .getElementById(HOME_CHAPTER_IDS.contactUs)
                    ?.scrollIntoView({ behavior: "smooth" })
                }
                icon="email"
                size="huge"
                shadow="white"
              />
            </HomeAnimationsFadeIn>
          </div>
        </div>
        {/* ARROW ON FIRST PAGE TO COMMUNICATE SCROLL POSIBILITY */}
        <div className="flexCenterCenter homeTopButton">
          <span
            className="material-icons"
            onClick={() =>
              document
                .getElementById(HOME_CHAPTER_IDS.telescopeNutShell)
                .scrollIntoView({ behavior: "smooth" })
            }
            style={{
              cursor: "pointer",
              transform: "rotate(90deg)",
              color: "#fff",
              position: "absolute",
              bottom: "10px",
              fontSize: "60px",
              opacity: scroll < 200 ? 1 : 0,
              transition: "500ms",
            }}
          >
            double_arrow
          </span>
        </div>

        {/* BIG TRIANGLES BG */}
        <div
          style={{
            position: "absolute",
            height: 20,
            bottom: 0,
            left: 0,
            transform: "rotate(80deg)",
            opacity: 0.2,
          }}
        >
          <img src={picBigTriangles} alt="" />
        </div>
        <div
          style={{
            position: "absolute",
            height: 20,
            bottom: 0,
            right: -200,
            transform: "rotate(180deg)",
            opacity: 0.3,
          }}
        >
          <img src={picBigTriangles} alt="" />
        </div>
      </div>

      {/* TELESCOPE IN A NUTSHELL -------------------------------------*/}
      <HomeChapter
        chapterId={HOME_CHAPTER_IDS.telescopeNutShell}
        page={2}
        chapterFlex="flexColCenterCenter"
      >
        <HomeRegularPage title="Telescope in a nutshell">
          <img
            src={picTelescopeNutshell}
            alt="Telescope in a nutshell"
            style={{
              width: "70%",
            }}
          />
        </HomeRegularPage>
      </HomeChapter>

      {/* CREATE YOUR PORTFOLIO  -------------------------------------*/}
      <HomeChapter page={3.1} chapterFlex="flexStartStart">
        <HomeStepSection
          titleText="Create your portfolio and set up your governance"
          titlePicture={pictriangleStep1}
          stepNumber={1}
          stepData={HOME_CREATE_PORTFOLIO_STEP_TABLE_DATA}
          picture={picTelescopeStep1}
          altPicture="Telescope Portfolio"
          pictureWidth={"70%"}
        />
      </HomeChapter>

      {/* TAKE A LOOK AT YOUR BOARD  -------------------------------------*/}
      <HomeChapter page={3.2} chapterFlex="flexStartStartReverse">
        <HomeStepSection
          titleText="Take a look at your Board"
          titlePicture={pictriangleStep2}
          stepNumber={2}
          stepData={HOME_TAKE_A_LOOK_STEP_TABLE_DATA}
          picture={picTelescopeStep2}
          altPicture="Telescope Board"
          pictureWidth={"70%"}
        />
      </HomeChapter>

      {/* CONTROL YOUR PORTFOLIOS  -------------------------------------*/}
      <HomeChapter page={3.3} chapterFlex="flexStartStart">
        <HomeStepSection
          titleText="Control your Portfolios"
          titlePicture={pictriangleStep3}
          stepNumber={3}
          stepData={HOME_CONTROL_STEP_TABLE_DATA}
          picture={picTelescopeStep3}
          altPicture="Telescope Control"
          pictureWidth={"70%"}
        />
      </HomeChapter>

      {/* SECURITY  -------------------------------------*/}

      <HomeChapter page={4} chapterFlex="flexStartStart">
        <HomeQualities
          pageNumber={1}
          picture="https://common-app-content.s3.eu-west-3.amazonaws.com/cvt-graphics/securityTriangleIcon.svg"
          altPicture="security"
          title="Security #1 priority"
          topics={HOME_SECURITY_TABLE_DATA}
          color={"var(--color-migso-blue)"}
          icon={piclockKeyIcon}
          iconClass="homeQualitiesSecurityIcon"
        />
      </HomeChapter>

      {/* GREEN -------------------------------------*/}

      <HomeChapter page={5} chapterFlex="flexStartStartReverse">
        <HomeQualities
          pageNumber={2}
          picture={picLeafTriangleIcon}
          altPicture="Green Leaf"
          title="Green at Heart"
          topics={HOME_GREEN_TABLE_DATA}
          color={"var(--color-migso-green)"}
          icon={picTreesIcon}
          iconClass="homeQualitiesGreenIcon"
        />
      </HomeChapter>

      {/* CONTACT US */}
      <div
        id={HOME_CHAPTER_IDS.contactUs}
        className="flexCenterCenter"
        style={{
          width: "100%",
          height: "20vh",
          padding: "2vh",
          backgroundColor: "#f8f9fb",
        }}
      >
        {/* TITLE */}
        <div
          style={{
            width: "30%",
          }}
        >
          <span className="gradientText">Contact Us</span>
        </div>
        {/* TEXT */}
        <div
          className="flexCenterCenter"
          style={{
            height: "50%",
            width: "50%",
          }}
        >
          <span
            className="homeQualitiesText"
            style={{
              color: "var(--color-text-3)",
              fontFamily: "Sarabun",
              fontWeight: 600,
              fontSize: "clamp(12px, 1.2vw, 30px)",
            }}
          >
            For more information reach us out at
            <a href="mailto:contact@clayverest.com"> contact@clayverest.com</a>
          </span>
        </div>
      </div>

      {/* PROJECT LIST +  Authenticator */}
      <div
        className={
          "homeAuthenticator " +
          (scroll < 200 ? null : "homeAuthenticatorTopLink flexCenterCenter")
        }
      >
        {/* RELATED TO SCROLL DISPLAY OR NOT BLOCK */}
        {scroll < 200 ? (
          currentUser.loggedIn ? (
            <React.Fragment>
              {/* TITLE */}
              <div className="Section__sectionHeader___2djyg">
                Hey{" "}
                <b style={{ textTransform: "capitalize" }}>
                  {" "}
                  {currentUser.name}
                </b>{" "}
                nice to see you here !
              </div>

              {/* ORGANIZATION SELECTION */}
              <CvtSelect
                selectBlockCss="flexStartCenter modalGroup"
                selectFontSize="14px"
                selectPlaceHolder="Select Organization"
                icon="hub"
                iconFontSize="32px"
                name="organizationId"
                value={selectedOrganization.id ? selectedOrganization.id : ""}
                listValue="id"
                listName="name"
                list={coreOrganizations}
                jsxLeft={jsxOrganizationLogo}
                onChange={handleOrganization}
              />

              {/* TEMPLATE BLOCK */}
              <div
                className="Section__sectionBody___ihqqd"
                style={{ padding: "0px" }}
              >
                {/* TEST IF LOADING NEEDED */}
                {currentUser.loading ? (
                  <div
                    className="flexStartCenter"
                    style={{
                      fontWeight: 500,
                      margin: "20px 20px 15px 20px",
                    }}
                  >
                    <CvtSpinnerGrow
                      marginRight="15px"
                      text="I'm looking for your project(s)..."
                    />
                  </div>
                ) : currentUser.err ? (
                  <div
                    className="flexStartCenter"
                    style={{
                      fontWeight: 500,
                      margin: "20px 20px",
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{
                        color: "var(--color-bad)",
                        marginRight: "15px",
                      }}
                    >
                      warning
                    </span>
                    <span
                      style={{
                        color: "var(--color-bad)",
                      }}
                    >
                      Network error...
                    </span>
                  </div>
                ) : coreProjects.length > 0 ? (
                  coreProjects.map((item, index) => {
                    //DO NOT MANAGE SANDBOX FOR THE MOMENT
                    if (item.id === APP_CORE_SANDBOX.id) {
                      return (
                        <div key={"userProjectSandbox"}>
                          Sandox Rules Has to be defined
                        </div>
                      );
                    }

                    //TEST IF SELECTED ITEM
                    let selected = false;
                    if (
                      selectedProjectId &&
                      item.id === selectedProjectId &&
                      isWbsLoaded
                    ) {
                      selected = true;
                    }

                    //RETURN project ITEM
                    return (
                      <div
                        key={"userProject" + index}
                        className={
                          selected
                            ? "homeTemplateItemSelected flexBetweenCenter"
                            : "homeTemplateItem flexBetweenCenter"
                        }
                        onClick={(e) => handleOpen(e, item.id)}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <div
                          style={{ fontWeight: 500 }}
                          className="flexColStartStart"
                        >
                          {/* FIRST ROW */}
                          <div
                            style={{
                              width: "100%",
                              marginBottom: selected ? "3px" : null,
                              fontWeight: selected ? 700 : null,
                            }}
                            className="flexStartCenter"
                          >
                            {loading === item.id ? (
                              <CvtSpinnerGrow marginRight="5px" spinnerColor={isHovered ? "white" : "var(--color-migso-blue)"} />
                            ) : null}
                            {item.name}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="flexStartCenter"
                    style={{
                      margin: "20px 20px 20px 15px",
                      fontWeight: 500,
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{
                        marginRight: "10px",
                        color: "var(--color-migso-blue)",
                      }}
                    >
                      info
                    </span>
                    {selectedOrganization.id
                      ? "No related project!"
                      : "Select an Organization"}
                  </div>
                )}
              </div>

              {/* FOOTER */}
              <div className="Section__sectionFooter___1T54C flexStartCenter">
                <span className="Section__sectionFooterPrimaryContent___2r9ZX">
                  <a
                    href={coreSupportUrl}
                    className="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CvtButton color="blue" txt="Create Project" />
                  </a>
                </span>
                <span className="Section__sectionFooterSecondaryContent___Nj41Q">
                  <NavLink to="/docs">Need Help ?</NavLink>
                </span>
              </div>
            </React.Fragment>
          ) : (
            <AuthAuthenticator
              currentUser={currentUser}
              appDispatcher={appDispatcher}
            />
          )
        ) : (
          <div className="flexCenterCenter homeTopButton" onClick={scrollTop}>
            <span
              className="material-icons"
              style={{ transform: "rotate(270deg)" }}
            >
              double_arrow
            </span>
          </div>
        )}
      </div>
    </section>
  );
}
