import { arrayToTree } from "performant-array-to-tree"
import React, { useMemo } from "react"
import DOCUMENTATION_FILES, { DOCUMENTATION_INIT_FILE } from "./00-ContentFiles/DocumentationFilesExport";
import FileElement from "./DocumentationFileElement";

//////////////////////////////
/// GET DOCUMENTATION TREE ///
//////////////////////////////

export default function DocumentationSummary({ 
    selectedFile, 
    smallView 
}) {
    
    // Get the nested summary structure
    const nestedFileData = useMemo(() =>  
        arrayToTree(DOCUMENTATION_FILES,{ dataField: null })
    , [])

    // COMPONENT RENDER
    return (
        <React.Fragment>
            
            {/* DOCUMENTATION INIT FILE ROW */}
            <FileElement
                file={DOCUMENTATION_INIT_FILE}
                fileSelected={selectedFile && DOCUMENTATION_INIT_FILE.id === selectedFile.id}
                smallView={!!smallView}
            />

            {/* RECURSIVE FUNCTION TO CREATE WBS NESTED TREE */}
            {(function mapChildren(nestedFileData) {
                return nestedFileData.map(file => (
                    <div
                        key={file.id}
                        id={file.id}
                        style={{
                            transition: "0.3s",
                        }}
                    >
                        {/* WBSROW COMPONENT */}
                        <FileElement
                            file={file}
                            fileSelected={selectedFile && file.id === selectedFile.id}
                            fileSelectedParentId={selectedFile && selectedFile.parentId}
                            smallView={!!smallView}
                        />

                        {/* CALL RECURSIVE FUNCTION IF CHILDREN */}
                        {file.children && file.children.length > 0 ? (
                            <div
                                key={"container-" + file.id}
                                className={
                                    "collapse " +
                                    (selectedFile && file.id === selectedFile.parentId ? "show" : null)
                                }
                                id={"container-" + file.id}
                            >
                                {mapChildren(file.children)}
                            </div>
                        ) : null}
                    </div>
                ));
            })(nestedFileData)}
        </React.Fragment>
    );
}
