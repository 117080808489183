import React from "react";

//CORE
import {CvtButton, CvtTooltip} from "@mi-gso/cvt";
import {APP_WBS_STRUCTURE} from '../../../../00-Core/Constants'

////////////////////////////////////////////
/// HEADER BIG COMPONENT ///////////////////
////////////////////////////////////////////

export default function WbsElementBigStructure({
    fieldInformation,
    handleBigComponent,
    projectTitle,
    buttonExpand,
    isExpendedEnabled,
    functionExpand,
    components,
    tooltipInfo,
    children
}) {


    //////////////
    /// RENDER ///
    //////////////

    return (
        <div
            style={{
                height: isExpendedEnabled ? "100%" : APP_WBS_STRUCTURE.bigComponentHeight +  "px",
                width:"100%"
            }}
        >
            {/* HEADER */}
            <React.Fragment>

                {/* PROJECT NAME IN EXPANDED MODE */}
                {isExpendedEnabled && (
                    <div
                        className="flexBetweenCenter"
                        style={{
                            padding:"0px 15px",
                            height: APP_WBS_STRUCTURE.bigComponentHeaderHeight + "px"
                        }}
                    >

                        {/* LEFT SIDE */}
                        <div className="flexCenterCenter">

                            {/* ICON */}
                            <span className="material-icons bigComponentHeaderProjectTitleIcon">
                                gps_fixed
                            </span>

                            {/* PROJECT NAME */}
                            <span
                                style={{
                                    fontWeight:700,
                                    fontSize:"16px"
                                }}
                            >
                                {projectTitle}
                            </span>

                            {/* NAVIGATION */}
                            <div
                                style={{
                                    marginLeft:"20px"
                                }}
                            >
                                {/* //!!!!!! INTEGRATE NAVIGATION */}
                            </div>

                        </div>

                        {/* BUTTONS ON RIGHT */}
                        <div className="flexCenterCenter">

                            {/* EXPAND BUTTON */}
                            <CvtButton
                                icon={!isExpendedEnabled ? "open_in_full" : "close_fullscreen"}
                                color="white"
                                shape="noShape"
                                handleSubmit={functionExpand}
                            />
                        </div>
                    </div>
                )}

                {/* HEADER NOT EXPANDED */}
                <div
                    className="bigComponentHeader flexBetweenCenter"
                    style={{
                        borderTop: isExpendedEnabled ? "1px solid var(--border-color-gray-1)" : null,
                        borderRadius: isExpendedEnabled ? null : "10px 10px 0px 0px",
                        height: APP_WBS_STRUCTURE.bigComponentHeaderHeight + "px",
                    }}
                >
                    {/* COMMAND BAR */}
                    <div className="bigTemplateTitleAndComponents">

                        {/*TITLE */}
                        <div
                            className=" flexStartCenter"
                            id="bigComponentInformation"
                            style={{
                                cursor:"help",
                                position:"sticky",
                                left:"10px"
                            }}
                        >

                            {/* DOMAIN ICON  */}
                            <span className="material-icons bigComponentHeaderIcon">
                                {fieldInformation.icon}
                            </span>

                            {/* NAME */}
                            {fieldInformation.name}

                            {/* INFO */}
                            <CvtTooltip
                                anchorId="bigComponentInformation"
                                content={tooltipInfo}
                            />
                        </div>

                        {/* SPECIFIC COMPONENT */}
                        {components && components.length > 0 ?
                            <div
                                className="flexStartCenter"
                                style={{
                                    width:"100%"
                                }}
                            >
                                {components.map(component => component)}
                            </div>
                        : null}
                    </div>

                    {/* BUTTONS */}
                    {buttonExpand && !isExpendedEnabled ?
                        <div
                            className="flexStartCenter"
                            style={{
                                position:"sticky",
                                right:0
                            }}
                        >

                            {/* EXPAND BUTTON */}
                            <CvtButton
                                icon={!isExpendedEnabled ? "open_in_full" : "close_fullscreen"}
                                color="white"
                                shape="noShape"
                                handleSubmit={functionExpand}
                            />

                            {/* CLOSE BUTTON */}
                            <CvtButton
                                color="white"
                                shape="noShape"
                                customHover="red"
                                icon="close"
                                handleSubmit={(e)=>handleBigComponent(e,null,null)}
                                margin="0px 5px 0px 0px"
                            />
                        </div>
                    :null}
                </div>
            </React.Fragment>

            {/* CONTENT */}
            <div
                className="flexStartStrech"
                style={{
                    width:"100%",
                    height: isExpendedEnabled ?
                        ("calc(100% - " + APP_WBS_STRUCTURE.bigComponentHeaderHeight*2 + "px)")
                    :
                        ("calc(100% - " + (APP_WBS_STRUCTURE.bigComponentHeaderHeight + 2) + "px)")
                }}
            >
                {children}
            </div>
        </div>
    );
}
