import React, { useMemo} from "react";
import {
    ACTION_STATUS_KEY,
    ACTION_STATUS_LIST,
} from "../../../../01-Action/00-Helpers/ActionsConstants";
import {
    FUNC_ACTION_GET_NUM,
    FUNC_ACTION_IS_LATE,
} from "../../../../01-Action/00-Helpers/ActionsFunctions";
import { TooltipWrapper } from "react-tooltip";
import { renderToString } from "react-dom/server";

export default function RiskCardActionsTableItemTags({item, data}) {

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    //GET OBJECT STATUS
    const actionStatusObject = useMemo(() => {
        return ACTION_STATUS_LIST.find((statusItem) => statusItem.id === item.status);
    }, [item]);

    //TEST IF ACTION LATE
    const isLate = useMemo(() => {
        return FUNC_ACTION_IS_LATE(item.status, item.dueDate);
    }, [item.dueDate, item.status]);

    //GET WEEKNUM
    const actionWeekNum = useMemo(() => {
        return FUNC_ACTION_GET_NUM(item);
    }, [item]);

    //GET DATE INFO
    const actionDateInfo = useMemo(() => {
        //INIT
        let date;
        let dateName;

        //SWITCH STATUS
        switch (item.status) {
            //DONE
            case ACTION_STATUS_KEY.done:
                date = new Date(item.realisedDate);
                dateName = "Closed Date";
                break;

            //ON HOLD
            case ACTION_STATUS_KEY.onHold:
                date = new Date(item.cancelledDate);
                dateName = "On Hold Date";
                break;

            //DEFAULT
            default:
                date = new Date(item.dueDate);
                dateName = "Due Date";
                break;
        }

        //INIT
        let dayText = date.toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        //RETURN
        return {
            value: dayText,
            name: dateName,
        };
    }, [item]);

    //WEEK TOOLTIP
    const weekTooltip = useMemo(() => {
        return (
            <div className="flexColStartStart">
                <span
                    className="actionMiniTooltipTitle"
                    style={{
                        fontSize: "12px",
                    }}
                >
                    {actionDateInfo.name}
                </span>
                {actionDateInfo.value}

                {
                    item.baseline ?
                    <React.Fragment>
                        <span
                            className="actionMiniTooltipTitle"
                            style={{
                                fontSize: "12px",
                                marginTop: '0px'
                            }}
                        >
                            Baseline
                        </span>
                        {new Date(item.baseline).toLocaleString(undefined, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        })}
                    </React.Fragment>
                :null}
            </div>
        );
    }, [actionDateInfo.name, actionDateInfo.value, item.baseline]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div className="flexEndCenter">
            {/*  */}
            {
                data ? (
                    <div
                            className="actionTableItemLinkIcon material-icons"
                        >
                            balance
                        </div>
                )
            :null}

            {/* EDITABLE STATUS TAG*/}
            {isLate ? (
                <div
                    className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
                    style={{
                        background: "var(--color-bad)",
                        marginLeft: "8px",
                    }}
                >
                    {/* NAME */}
                    <span style={{ color: "white" }}>Late</span>
                </div>
            ) : null}

            {/* EDITABLE STATUS TAG*/}
            <div
                className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
                style={{
                    background: actionStatusObject.backgroundColor,
                    marginLeft: "3px",
                }}
            >
                {/* NAME */}
                <span
                    style={{
                        color: actionStatusObject.color,
                    }}
                >
                    {actionStatusObject.name}
                </span>
            </div>

            {/* WEEK */}
            <TooltipWrapper
                html={renderToString(weekTooltip)}
                tooltipId="risk-action-table-tooltip"
            >
                <div className="actionTableItemWeek">
                    W{actionWeekNum}
                </div>
            </TooltipWrapper>
        </div>
    );
}
