import React, { useState, useMemo, useCallback, memo } from "react";

//CORE
import { CvtButton, CvtInput, CvtSpinnerGrow } from "@mi-gso/cvt";

//APP

//USERS
import { USERS_MANAGEMENT_PAGE_COUNT } from "./00-Helpers/UsersConstants";
import UsersManagementCommandBar from "./01-Components/UserManagementCommandBar";
import UsersManagementTable from "./01-Components/UserManagementTable";

//GRAPHQL
import _ from "lodash";
import { FUNCT_FIND_INDEX } from "../../../../00-Core/Standards";
import { FUNC_BOARD_GET_ALL_PORTFOLIO_USERS } from "../../00-Helpers/BoardFunctions";

//////////////////////////
/// ADD USER COMPONENT ///
//////////////////////////

export default memo(function UserManagementAddModal({
  usersList,
  currentUserId,
  portfolioEdit,
  projectList,
  boardDispatcher,
}) {
  ////////////////////////////////////////////////
  /// STATE //////////////////////////////////////
  ////////////////////////////////////////////////

  const [state, setState] = useState({
    currentPage: 1,
    searchInput: "",
    selectableUsers: _.cloneDeep(usersList),
    selected: [],
    keyPropIncrement: 0,
    loading: false,
  });

  /////////////////////////////////////////////
  /// DATA ////////////////////////////////////
  /////////////////////////////////////////////

  // GET USERS DATA
  const detailedPortfolioUsers = useMemo(() => {
    let allUsers = FUNC_BOARD_GET_ALL_PORTFOLIO_USERS(
      portfolioEdit.itemViewers,
      portfolioEdit.itemEditors
    );

    let usersData = allUsers.map((userId) => {
      // GET USER DETAILED INFOS
      let userIndex = FUNCT_FIND_INDEX(usersList, "id", userId);
      return usersList[userIndex];
    });

    return usersData;
  }, [portfolioEdit.itemEditors, portfolioEdit.itemViewers, usersList]);

  // ORDERED USER LIST AFTER FETCHING DETAILS -----
  const orderedList = useMemo(() => {
    //CONCATENATE MEGA AND USER
    let list = _.cloneDeep(state.selectableUsers);

    //MANAGE MEMO
    if (state.keyPropIncrement > -1) {
      //KEEP ALREADY SELECTED
      list = list.filter((user) => {
        // REMOVE USERS ALREADY IN PORTFOLIO
        if (FUNCT_FIND_INDEX(detailedPortfolioUsers, "id", user.id) !== -1)
          return false;

        ////////////////////////////
        /// TEST IF SEARCH INPUT ///
        ////////////////////////////

        if (state.searchInput !== "") {
          //ALREADY SELECTED
          if (
            state.selected.length > 0 &&
            FUNCT_FIND_INDEX(state.selected, "userName", user.userName) > -1
          )
            return true;

          //SEARCH NAME
          if (
            user.name &&
            user.name.toUpperCase()?.includes(state.searchInput.toUpperCase())
          )
            return true;

          //SEARCH EMAIL
          if (
            user.email &&
            user.email.toUpperCase()?.includes(state.searchInput.toUpperCase())
          )
            return true;

          //IF NOT RETURN FALSE
          return false;
        }

        //OK
        return true;
      });
    }

    //RETURN
    return list;
  }, [
    detailedPortfolioUsers,
    state.keyPropIncrement,
    state.searchInput,
    state.selectableUsers,
    state.selected,
  ]);

  // TOTAL PAGES -----------------------------------
  const totalPages = useMemo(() => {
    //TOTAL USER
    let totalCount = orderedList ? orderedList.length : 0;

    //RETURN TOTAL PAGE
    return totalCount < USERS_MANAGEMENT_PAGE_COUNT
      ? 1
      : Math.ceil(totalCount / USERS_MANAGEMENT_PAGE_COUNT);
  }, [orderedList]);

  // CALCULATE CURRENT PAGES USERS AND RETURN GOOD LIST
  const currentPageUsers = useMemo(() => {
    //INIT
    let userList = _.cloneDeep(orderedList);

    // USER AVAILABLE
    if (userList.length > 0) {
      //IF ONLY ONE PAGE
      if (totalPages === 1) {
        return orderedList;
      }

      // IF TOTAL PAGES IS NOT 1, THEN CALCULATE USE CURRENT PAGE AS WELL.
      else {
        //GET END AND START INDEX FOR USERLIST
        let start =
          state.currentPage * USERS_MANAGEMENT_PAGE_COUNT -
          USERS_MANAGEMENT_PAGE_COUNT;
        let end = start + USERS_MANAGEMENT_PAGE_COUNT;

        //RETURN UPDATED USER LIST
        return userList.slice(start, end);
      }
    }

    //RETURN TOT LIST
    return userList;
  }, [orderedList, state.currentPage, totalPages]);

  /////////////////////////////////////////////
  /// FUNCTIONS ///////////////////////////////
  /////////////////////////////////////////////

  // HANDLE SEARCH FOR USER --------------------
  const handleSearch = useCallback((e) => {
    setState((prevState) => {
      return {
        ...prevState,
        searchInput: e.target.value,
      };
    });
  }, []);

  //HANDLE RESET SEARCH ---------------------------
  const handleResetSearch = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        searchInput: "",
      };
    });
  }, []);

  // HANDLE CLOSE ADD USERS BLOCK --------------
  const handleClose = useCallback(() => {
    boardDispatcher({
      type: "SET_SIMPLE_STATE",
      key: "isAddingUsers",
      value: false,
    });
  }, [boardDispatcher]);

  // HANDLE SELECT USER ------------------------
  const handleSelectUser = useCallback((userItem) => {
    setState((prevState) => {
      //INIT
      let newSelected = [...prevState.selected];

      //TEST IF WE NEED TO REMOVE IT
      let toRemoveIndex = FUNCT_FIND_INDEX(newSelected, "id", userItem.id);

      //DEL IF REMOVE
      if (toRemoveIndex !== -1) {
        //GET INDEX OF
        newSelected.splice(toRemoveIndex, 1);

        //IF NOT ADD
      } else {
        newSelected.push(userItem);
      }

      //RETURN STATE
      return {
        ...prevState,
        selected: newSelected,
      };
    });
  }, []);

  // HANDLE UPDATE (SAVE CHANGES) -----------------
  const handleAdd = useCallback(() => {
    // GET NEW USERS IDS
    let newUsersIds = state.selected.map((user) => user.id);

    // CLONE STATE
    let newPortfolioViewers = [...portfolioEdit.itemViewers, ...newUsersIds];

    // UPDATE PORTFOLIO STATE
    boardDispatcher({
      type: "SET_UPDATE_PORTFOLIO_PERMISSIONS",
      itemViewers: newPortfolioViewers,
      exitSelection: true,
    });
  }, [boardDispatcher, portfolioEdit, state.selected]);

  ////////////////////////////////////////////////////
  /// MINI COMPONENT /////////////////////////////////
  ////////////////////////////////////////////////////

  //RIGHT JSX SEARCH BAR -----------------------------
  const searchBarRightIcon = useMemo(() => {
    //ONLY IF SEARCH VALUE
    if (state.searchInput !== "") {
      return (
        <div
          className="material-icons addUsersBlockSearchButton"
          onClick={handleResetSearch}
        >
          clear
        </div>
      );
    }

    //NULL
    return null;
  }, [handleResetSearch, state.searchInput]);

  //SEARCH BAR ---------------------------------------
  const searchBar = useMemo(() => {
    return (
      <CvtInput
        placeholder="Search"
        inputWidthFull
        icon="search"
        inputBlockCss="flexBetweenCenter modalOptionSubBlock"
        inputPadding="4px 8px"
        inputFontSize="14px"
        inputBlockPadding="0px"
        inputBlockMargin="0px"
        type="text"
        name="searchInput"
        value={state.searchInput}
        onChange={handleSearch}
        rightJsx={searchBarRightIcon}
      />
    );
  }, [handleSearch, searchBarRightIcon, state.searchInput]);

  ////////////////////////////////////////////////////
  /// COMPONENT RENDER ///////////////////////////////
  ////////////////////////////////////////////////////

  return (
    <div
      className="structureBlockInfo"
      style={{
        width: "100%",
        paddingTop: "10px",
      }}
    >
      {/* HEADER */}
      <div className="flexBetweenCenter">
        {/* CLOSE BTN */}
        <span
          className="material-icons manageUsersCloseButton"
          onClick={handleClose}
          style={{ marginLeft: "20px", marginTop: "10px" }}
        >
          arrow_back
        </span>

        {/* BUTTON */}
        <div className="flexStartCenter">
          {/* ADD USERS */}
          {state.selected.length !== 0 && (
            <CvtButton
              color="gray"
              outline
              customHover="blue"
              margin="0px 20px 0px 0px"
              handleSubmit={handleAdd}
              icon="person_add"
              iconColor="var(--color-migso-green)"
              txt={"Add Selected User" + (state.selected.length > 1 ? "s" : "")}
              disabled={state.selected.length === 0}
            />
          )}
        </div>
      </div>

      {/* COMMAND BAR  */}
      <UsersManagementCommandBar
        currentPage={state.currentPage}
        listLength={orderedList.length}
        setParentState={setState}
        totalPageCount={totalPages}
        customSearchBar={searchBar}
      />

      {/* BODY */}
      <div className="flexColBetweenStart projectUserManagementBody">
        {/* USERS TO ADD TABLE */}
        {state.selectableUsers.length > 0 ? (
          <UsersManagementTable
            currentPageUsers={currentPageUsers}
            selectedUsers={state.selected}
            currentUserId={currentUserId}
            usersList={usersList}
            isAddingUsers={true}
            projectList={projectList}
            portfolioEdit={portfolioEdit}
            handleSelectUser={handleSelectUser}
          />
        ) : (
          <div className="structureBlockInfo flexStartCenter">
            {state.loading ? (
              <React.Fragment>
                <CvtSpinnerGrow
                  marginRight="10px"
                  text="I'm searching, please wait..."
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="material-icons structureBlockInfoIcon">
                  info
                </span>
                No user has been found
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
