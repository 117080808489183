import { AGENDA_ITEM_TYPES_KEYS, TODAY, TODAY_MINUS_1 } from "../../../../00-Core/Constants";
import {
    createTelescopeDataAction,
    deleteTelescopeDataAction,
    updateTelescopeDataAction,
} from "../../../../graphql/mutations";

// SUMMARY
// - ACTION_START_DISPLAY_ID
// - ACTION_DATE_LIST
// - ACTION_STATUS_LIST
// - ACTION_STATUS_KEY
// - ACTION_TXT_CONVERSION
// - ACTION_COMPONENT_EXPAND_TYPES
// - ACTION_GROUP_ITEMS
// - ACTION_ITEM_DATA
// - ACTION_DETAILS_KEY_BLOCK
// - ACTION_CONNECTIONS_TYPE_COLORS
// - ACTION_SAVING_TYPES
// - ACTION_MUTATION_QUERIES
// - ACTION_TYPE_LIST
// - ACTION_FILTER_MODAL_INPUT_SIZES

//START DISPLAY ID
export const ACTION_START_DISPLAY_ID = "AC";

//OBJECT DATE LIST
export const ACTION_DATE_LIST = [
    { id: "createdOn", name: "Created", icon: "zoom_in" },
    { id: "dueDate", name: "Due", icon: "flag" },
    { id: "cancelledDate", name: "On Hold", icon: "block" },
    { id: "realisedDate", name: "Done", icon: "done" },
];

//ACTIONS STATUS LIST DETAILS
export const ACTION_STATUS_LIST = [
    {
        id: "todo",
        value: "todo",
        name: "To Do",
        label: "To Do",
        backgroundColor: "var(--color-text-5)",
        color: "white",
    },
    {
        id: "inProgress",
        value: "inProgress",
        name: "In Progress",
        label: "In Progress",
        backgroundColor: "var(--color-migso-blue)",
        color: "white",
    },
    {
        id: "onHold",
        value: "onHold",
        name: "On Hold",
        label: "On Hold",
        backgroundColor: "var(--color-migso-yeallow)",
        color: "white",
    },
    {
        id: "done",
        value: "done",
        name: "Done",
        label: "Done",
        backgroundColor: "var(--color-migso-green)",
        color: "white",
    },
];

//ACTIONS TYPE
export const ACTION_LINK_TYPE_LIST = [
    {
        id: "riskOpp",
        value: "riskOpp",
        label: "Risk / Opp",
        name: "Risk / Opp",
    },
];

//ACTIONS TYPE
export const ACTION_ESCALATED_TO_LIST = [
    {
        id: "escalatedTo",
        value: "escalatedTo",
        label: "Escalated To",
        name: "Escalated To",
    },
];

//ACTIONS TYPE
export const ACTION_ESCALATED_FROM_LIST = [
    {
        id: "escalatedFrom",
        value: "escalatedFrom",
        label: "Escalated From",
        name: "Escalated From",
    },
];

//STATUS KEY
export const ACTION_STATUS_KEY = {
    late: "late",
    done: "done",
    onHold: "onHold",
    todo: "todo",
    inProgress: "inProgress",
};

//TEST CONVERSION FOR CHART
export const ACTION_TXT_CONVERSION = {
    todo: "To Do",
    inProgress: "In Progress",
    late: "Late",
    done: "Done",
    onHold: "On Hold",
    planned: "Forecast",
    cancelledDate: "On Hold Date",
    createdOn: "Creation Date",
    action: "Title",
    dueDate: "Due Date",
    created: "Created",
    description: "Description",
    decisionDetails: "Decision Details",
};

// ACTIONS BIG COMPONENT BODY EXPAND TYPES
export const ACTION_COMPONENT_EXPAND_TYPES = {
    graphOnly: "GRAPH_ONLY",
    tableOnly: "TABLE_ONLY",
    both: "BOTH",
};

// ACTIONS GROUP ITEMS FOR EDIT SIDE BAR
export const ACTION_GROUP_ITEMS = [
    { id: 1, name: "General", icon: "source" },
    { id: 2, name: "Dependencies", icon: "link", noCountTag: true,},
];

// STRUCTURE OF ACTION ITEM
export const ACTION_ITEM_DATA = [
    // ITEM IDS
    { groupId: -1, key: "id", value: null, type: "id" },
    { groupId: -1, key: "organizationId", value: "", type: "id" },
    { groupId: -1, key: "projectId", value: "", type: "id" },
    { groupId: -1, key: "wbsId", value: "", type: "id" },
    { groupId: -1, key: "displayId", value: "", type: "id" },

    // NO GROUP (name , responsible, status, isPrivate)
    {
        groupId: 0,
        key: "action",
        value: "",
        label: "Name",
        component: "input",
        maxLength: 150,
        type: "text",
        info: "",
        placeholder: "Enter a name",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "responsible",
        value: "",
        label: "Responsible",
        component: "select",
        type: "list",
        options: [],
        info: "",
        placeholder: "Who's the responsible",
        mandatory: true,
    },
    // CONTRIBUTORS
    {
        groupId: 0,
        key: "contributors",
        value: "",
        label: "Contributors",
        component: "multi_select",
        type: "multi_select",
        options: [],
        info: "",
        placeholder: "Add people to this action",
        mandatory: false,
        canCreate: "false",
    },

    {
        groupId: 0,
        key: "baseline",
        rowWithNext: true,
        value: "",
        label: "Baseline",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: false,
    },
    {
        groupId: 0,
        key: "dueDate",
        value: "",
        label: "Due date",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "status",
        value: "",
        halfWidth: true,
        rowWithNext: true,
        label: "Status",
        component: "select",
        type: "list",
        options: ACTION_STATUS_LIST,
        info: "",
        placeholder: "Choose a status",
        mandatory: true,
        displayLink: ["realisedDate", "cancelledDate"],
    },

    {
        groupId: 0,
        key: "realisedDate",
        value: TODAY,
        label: "Closed date",
        halfWidth: true,
        showNextIfHidden: true,
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        maxDate: TODAY_MINUS_1,
        toastErrMsg: "Realised date can't be after today",
        displayOptions: { status: "done" },
    },
    {
        groupId: 0,
        key: "cancelledDate",
        value: TODAY,
        halfWidth: true,
        maxDate: TODAY_MINUS_1,
        label: "On Hold date",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        mandatory: true,
        displayOptions: { status: "onHold" },
    },
    {
        groupId: 1,
        key: "createdOn",
        halfWidth: true,
        value: TODAY,
        label: "Created date",
        component: "input",
        type: "date",
        info: "",
        placeholder: "",
        maxDate: new Date(Date.parse("1 January, 9999, 00:00:00 UTC")),
        mandatory: true,
    },
    {
        groupId: 1,
        key: "description",
        value: "",
        label: "Description",
        component: "textArea",
        type: "richTextArea",
        info: "",
        placeholder: "Click to edit your description",
        mandatory: false,
    },
    {
        groupId: 2,
        key: "links",
        value: "[]",
        label: "Dependencies",
        component: "custom",
        type: "actionLinks",
        mandatory: false,
    }
];

//SWITCH VIEW IN ACTION CARD
export const ACTION_DETAILS_KEY_BLOCK = [
    { key: "comments", name: "Comments" },
    { key: "connections", name: "Connections" },
    { key: "events", name: "Events" },
];

// CONNECTIONS TABLE TYPE COLORS
export const ACTION_CONNECTIONS_TYPE_COLORS = {
    riskOpp: "var(--color-bad)",
    schedule: "var(--color-migso-blue)",
    cost: "var(--color-escalated)",
    governance: "var(--color-good)",
};

// TYPES OF OPERATIONS WHEN SAVING
export const ACTION_SAVING_TYPES = {
    create: "create",
    edit: "edit",
    delete: "delete",
};

// ACTIONS MUTATION QUERIES
export const ACTION_MUTATION_QUERIES = {
    create: createTelescopeDataAction,
    delete: deleteTelescopeDataAction,
    update: updateTelescopeDataAction,
};

//TYPE LIST
export const ACTION_TYPE_LIST = [
    {
        label: "R&O",
        value: "R&O",
    },
    {
        label: "Schedule",
        value: "Schedule",
    },
    {
        label: "Costs",
        value: "Costs",
    },
    {
        label: "Governance",
        value: "Governance",
    },
];

// Contains all the sizes for the Inputs in ActionFilterModal
export const ACTION_FILTER_MODAL_INPUT_SIZES = {
    labelWidth: "150px",
    inputBoxWidth: "100%",
    inputWidth: "90%",
};

//!!!! TO BE REVIEWED

//!!! TO BE DELETED
export const ACTION_CALCULATED_STATUS_LIST = {
    all: {
        label: "All",
        value: "all",
        name: "All",
        id: "All",
    },
    onTrack: {
        name: "On Track",
        label: "On Track",
        value: "onTrack",
        textConvertion: "inProgress",
        color: "white",
        backgroundColor: "#02A8DB",
        tagColor: "dark-blue",
        sort: 2,
    },
    late: {
        name: "Late",
        label: "Late",
        value: "late",
        textConvertion: "late",
        color: "white",
        backgroundColor: "#e55766",
        tagColor: "red-late",
        sort: 1,
    },

    done: {
        label: "Done",
        value: "done",
        name: "Closed",
        color: "white",
        textConvertion: "done",
        backgroundColor: "#dee2e6",
        tagColor: "light-grey",
        sort: 4,
    },
    onHold: {
        label: "On Hold",
        value: "onHold",
        name: "On Hold",
        textConvertion: "onHold",
        color: "white",
        backgroundColor: "#FFAA00",
        tagColor: "amber",
        sort: 5,
    },
};

// DOMAINS AN ACTION CAN BE LINKED TO
export const ACTION_LINKABLE_DOMAINS = [
    {
        id: "review",
        domainId: "governance",
        name: "Governance Review",
        dataTable: "govReview",
    },
    {
        id: "scopeChange",
        domainId: "governance",
        name: "Scope change",
        dataTable: "govScopeChange",
    },
    {
        id: "risk",
        domainId: "riskOpp",
        name: "Risk",
        dataTable: "riskOpp",
    },
    {
        id: "opp",
        domainId: "riskOpp",
        name: "Opportunities",
        dataTable: "riskOpp",
    },
    {
        id: "schedule",
        domainId: "schedule",
        name: "Schedule",
        dataTable: "schedule",
    },
];

export const ACTION_LINKABLE_DOMAINS_KEYS = {
    review: "review",
    scopeChange: "scopeChange",
    risk: "risk",
    opp: "opp",
    schedule: "schedule",
};

export const ACTION_DEFAULT_LINKABLE_DOMAINS_ID = "review";

export const ACTION_LINKS_SIDE_BAR_DEFAULT_ITEM = { 
    id: null,
    destinationItemId: null,
    type: AGENDA_ITEM_TYPES_KEYS.risk,
}

export const ACTION_LINK_ITEM_TYPES = [
    {
        id: "riskOpp",
        label: "Risk / Opportunity",
        icon: "fact_check",
    },
    {
        id: "govScopeChange",
        label: "Scope Change",
        icon: "event",
    },
    {
        id: "govReview",
        label: "Review",
        icon: "event",
    },
    {
        id: "schedule",
        label: "Schedule",
        icon: "flag"
    }
]
