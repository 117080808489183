import React, { useMemo, useCallback } from "react";
import DOCUMENTATION_FILES, {
    DOCUMENTATION_INIT_FILE,
} from "./00-ContentFiles/DocumentationFilesExport";
import { FUNCT_FIND_INDEX } from "../../00-Core/Standards";
import { CvtButton} from '@mi-gso/cvt';
import DocumentationSummary from "./DocumentationSummary";
import { useParams, Link } from "react-router-dom";

///////////////////////////////
/// DOCUMENTATION COMPONENT ///
///////////////////////////////

export default function Documentation() {

    // Get the requested Documentation File ID
    let { fileId } = useParams();

    // Get the requested Documentation content ------------------------------
    const selectedFile = useMemo(() => {

        //SELECT INIT OR WITH PARAMS
        if (fileId === "init" || !fileId) {
            return DOCUMENTATION_INIT_FILE;
        }

        // If not init file is select, look for the data in the constant array
        let findIndex = FUNCT_FIND_INDEX(DOCUMENTATION_FILES, "id", fileId);

        //IF ID EXIST RETURN GOOD FILE
        if (findIndex !== -1) {
            return DOCUMENTATION_FILES[findIndex];
        }

        // If index not found, return init doc
        return DOCUMENTATION_INIT_FILE;

    }, [fileId]);

    //HANDLE TOP ------------------------------------------------------------
    const handleTop = useCallback(e => {
        e.preventDefault();

        //SCROLL TOP
        const documentFileBlock = document.getElementById("documentFileBlock");
        documentFileBlock.scrollTop = 0;
    }, []);

    //PREVIOUS FILE ---------------------------------------------------------
    const previousFile = useMemo(() => {

        //FIND INDEX
        let findIndex = FUNCT_FIND_INDEX(DOCUMENTATION_FILES, "id", selectedFile.id);

        //IF NOT DO NOT DISPLAY BUTTON
        if (findIndex === -1) {
            return null;

        //NEED TO FIND THE PREVIOUS FILE
        }else{

            //IF FIRST CHAPTER TITLE MOVE TO INIT
            if (findIndex === 1) {
                return {
                    id: DOCUMENTATION_INIT_FILE.id,
                    title: DOCUMENTATION_INIT_FILE.title,
                };
            }

            //IF NOT MOVE UP
            let k = findIndex;
            while (k--) {

                //AGAIN FIRST CHAPTER
                if (k === 0) {
                    return {
                        id: DOCUMENTATION_INIT_FILE.id,
                        title: DOCUMENTATION_INIT_FILE.title,
                    };
                }

                //DO NOT CONSIDER LEVEL 0
                if (DOCUMENTATION_FILES[k].level !== 0) {
                    return {
                        id: DOCUMENTATION_FILES[k].id,
                        title: DOCUMENTATION_FILES[k].title,
                    };
                }
            }
        }
    }, [selectedFile.id]);

    //PREVIOUS FILE ---------------------------------------------------------
    const nextFile = useMemo(() => {

        //IF INIT
        if (selectedFile.id === "init") {
            return {
                id: DOCUMENTATION_FILES[1].id,
                title: DOCUMENTATION_FILES[1].title,
            };
        }

        //FIND INDEX
        let findIndex = FUNCT_FIND_INDEX(DOCUMENTATION_FILES, "id", selectedFile.id);

        //IF END
        if (findIndex === DOCUMENTATION_FILES.length - 1) {
            return null;
        }

        //IF NOT DO NOT DISPLAY BUTTON
        for (let k = findIndex + 1; k < DOCUMENTATION_FILES.length; k++) {
            if (DOCUMENTATION_FILES[k].level !== 0) {
                return {
                    id: DOCUMENTATION_FILES[k].id,
                    title: DOCUMENTATION_FILES[k].title,
                };
            }
        }
    }, [selectedFile.id]);

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////

    return (
        <section
            id="structureContent"
            className="flexStartStart"
            style={{

                height:"100%"
              }}
        >

            {/* SUMMARY */}
            <div
                className="structureBlock"
                style={{
                    width: "25%",
                    minWidth: "350px",
                    // boxShadow:"rgb(0 0 0 / 30%) 2px 2px 10px 3px"
                }}
            >
                <div
                    className="structureBlockProjectBody"
                    style={{
                        padding: "0px",
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                >
                    <DocumentationSummary
                        selectedFile={selectedFile}
                    />
                </div>
            </div>

            {/* DETAILS */}
            <div
                className="structureBlock docsDetails"
                id="documentFileBlock"
                style={{
                    width: "75%",
                    marginRight: "20px",
                    marginLeft: "20px",
                    // boxShadow:"rgb(0 0 0 / 30%) 2px 2px 10px 3px"
                    maxHeight: "calc(100vh - 140px)"
                }}
                key={"documentation_content_" + selectedFile.id}
            >

                {/* TITLE */}
                <div
                    className="structureBlockTitle flexBetweenCenter"
                    style={{
                        position: "sticky",
                        top: "0px",
                        borderBottomColor: "var(--border-color-gray-1)",
                        // boxShadow:"0px 1px 4px 0px rgb(0 0 0 / 50%)",
                        borderRadius: "0px",
                    }}
                >

                    {/* ICON TXT */}
                    <div className="flexStartCenter">
                        <span className="material-icons importSubTitleIcon ">
                            {selectedFile.icon ? selectedFile.icon : "list"}
                        </span>
                        {selectedFile.title}
                    </div>

                    {/* BACK TO TOP */}
                    <div
                        className="material-icons structureTopButton"
                        style={{ transform: "rotate(270deg)" }}
                        onClick={handleTop}
                    >
                        double_arrow
                    </div>
                </div>

                {/* FILE */}
                <div className="blockMarkDown">
                    {selectedFile.file}
                </div>

                {/* FOOTER */}
                <div
                    className={
                        "structureRowFooter " +
                        (previousFile ? "flexBetweenCenter" : "flexEndCenter")
                    }
                >

                    {/* PREVIOUS */}
                    {previousFile ? (
                        <Link to={`/docs/${previousFile.id}`}>
                            <CvtButton
                                color="blue"
                                icon="arrow_back_ios"
                                iconFontSize="22px"
                                iconMarginRight="2px"
                                txt={previousFile.title}
                            />
                        </Link>
                    ) : null}

                    {/* NEXT */}
                    {nextFile ? (
                        <Link to={`/docs/${nextFile.id}`}>
                            <CvtButton
                                color="blue"
                                icon="arrow_forward_ios"
                                iconOnRight
                                iconFontSize="22px"
                                txt={nextFile.title}
                            />
                        </Link>
                    ) : null}
                </div>
            </div>
        </section>
    );
}