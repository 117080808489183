// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//THE FILE TEXT
const markdown = `

# PREÁMBULO

Las presentes Condiciones de uso (en adelante «**Condiciones de uso**») tienen por objeto definir la relación contractual entre la Empresa y el Usuario para el uso de la herramienta CLAYVEREST («**CLAYVEREST**»).

El empleador o la autoridad empleadora/contratista del Usuario (el «**Cliente**») ha firmado un contrato de servicios con MI-GSO-PCUBED para la prestación de los Servicios. Al leer y aceptar estas Condiciones de uso, el Usuario se compromete a activar su cuenta CLAYVEREST.

MI-GSO-PCUBED, responsable de suministrar los Servicios, es una empresa debidamente constituida bajo la legislación francesa, con sede en la calle Alain Fournier, Toulouse, Francia (la «**Empresa**» o «**MI-GSO-PCUBED**»).

Cualquier uso de CLAYVEREST implica la aceptación previa y sin reservas por parte del Usuario de todos los términos de estas Condiciones de uso. 

# DEFINICIONES

- «**CLAYVEREST**» designa la herramienta que permite al Usuario almacenar, compartir, organizar y gestionar Datos en el contexto de la gestión de sus proyectos. CLAYVEREST está disponible en www.clayverest.com.
- «**Datos**» designa a cualquier información relativa al proyecto de un Cliente.
- «**Datos personales**» designa a cualquier información relativa a una persona física identificada o identificable. Una «persona física identificable» es una persona física que puede ser identificada, directa o indirectamente, en particular por referencia a un identificador, como un nombre, un número de identificación, datos de localización, un identificador en línea, o a uno o más factores específicos de esa persona física.
- «**Servicios**» significa los servicios prestados por la Empresa al Cliente y al Usuario a través del acceso a CLAYVEREST.
- «**Usuario**» designa a la persona física a la que se ha concedido acceso a CLAYVEREST para que pueda realizar sus tareas.


## DESCRIPCIÓN DE LOS SERVICIOS

En función del acuerdo entre la Empresa y el Cliente, el Usuario tiene acceso a uno o varios de los siguientes servicios: 

- TimeLine Generator (Generador de líneas temporales): Creación de hojas de ruta de alto nivel personalizables basadas, o no, en datos de planificación. 
- Telescope (Telescopio): Gestión de carteras de proyectos y sus principales componentes (costes, plazos, carta de proyectos, etc.)
- Core (Núcleo): Creación de organizaciones y de su estructura de desglose del trabajo (WBS, del inglés Work Breakdown Structure). Estas WBS se utilizarán posteriormente en las dos aplicaciones anteriores.

## ACCESO AL SITIO Y A LOS SERVICIOS

El acceso a CLAYVEREST está reservado exclusivamente a los Usuarios que tengan una licencia suscrita por el Cliente.

El Cliente y el Usuario son los únicos responsables del buen funcionamiento del equipo informático y de la conexión a Internet que les permite acceder a CLAYVEREST y a los Servicios. 

En el caso de una interrupción planificada o imprevista de CLAYVEREST, el Usuario será informado de la situación mediante un mensaje que aparecerá cuando acceda a su cuenta.

La Empresa dedicará todos los esfuerzos posibles para garantizar la máxima disponibilidad del Servicio. La Empresa empleará todos los esfuerzos comerciales razonables para garantizar la disponibilidad del Servicio.

## DURACIÓN

El Usuario acepta las presentes Condiciones de uso por tiempo indefinido.

## OBLIGACIONES DEL USUARIO

El Usuario se compromete a acceder y utilizar CLAYVEREST y los Servicios de acuerdo con la legislación en vigor y estas Condiciones de uso.

Queda prohibido subir a CLAYVEREST Datos cuyo contenido sea ilegal, malicioso, susceptible de vulnerar derechos de terceros, o contrario a la moral o el orden público. La empresa MI-GSO no se responsabiliza de ningún contenido ilegal almacenado en CLAYVEREST y se reserva el derecho de suspender cualquier cuenta que infrinja estas disposiciones y, en consecuencia, de suspender cualquier acceso relacionado.

En el uso de la herramienta de asistencia al usuario y de seguimiento de las nuevas funciones, dicho “ticketing”, el Usuario se compromete à nunca introducir datos comerciales o personales, lo menos datos personales sensibles (religión, políticas, filosóficas, salud, orientation sexual o de genero) y a siempre respectar las normas habituales en Internet: limitar las mayúsculas, utilizar un lenguaje claro, limitar los emoticonos o las abreviaturas

## SEGURIDAD

La Empresa implementará las medidas técnicas y organizativas adecuadas para garantizar un nivel de seguridad apropiado al riesgo. 

El Cliente ha validado las medidas útiles adoptadas por la Empresa en materia de seguridad, en particular en lo que respecta a la seguridad de los equipos, con el fin de garantizar la protección y la integridad de los Datos durante el tiempo que se almacenan en el marco de los Servicios.

## COOKIES, ANÁLISIS Y REGISTRO

La Empresa solo utiliza cookies funcionales, que permiten el funcionamiento de la plataforma al gestionar la autenticación, los cambios entre las herramientas de la plataforma, etc.

La Empresa también usa herramientas de análisis que le permiten medir el rendimiento de CLAYVEREST, así como anotar la fecha y hora de los distintos eventos (registro) para controlar la seguridad de CLAYVEREST y contribuir al análisis del rendimiento.

## RESPONSABILIDAD

El Usuario y el Cliente son los únicos responsables de la correcta gestión de los proyectos. 

La Empresa actúa como proveedor de una herramienta para los Usuarios en el contexto de la gestión de proyectos. No puede responsabilizarse en modo alguno de los resultados de los proyectos. Por lo tanto, la responsabilidad de la Empresa se limita a poner CLAYVEREST a disposición de los clientes y usuarios, y se compromete a garantizar el acceso a CLAYVEREST, así como la preservación de la integridad y confidencialidad de los Datos.

## PROPIEDAD INTELECTUAL Y LICENCIAS

La Empresa retiene todos los derechos de propiedad de CLAYVEREST y sus Servicios, incluyendo todos los conocimientos técnicos, las metodologías, el código, los diseños y las mejoras de los Servicios, excluyendo los Datos incorporados en cualquier entregable. Los entregables o resultados obtenidos gracias a CLAYVEREST son propiedad industrial, literaria o artística del Usuario o del Cliente, según el régimen que se aplique entre ellos y del que la Compañía queda como tercero.

## MODIFICACIONES EN CLAYVEREST Y EN LAS PRESENTES CONDICIONES DE USO

La Empresa se reserva el derecho de modificar o actualizar estas Condiciones de uso. El Usuario deberá entonces validar la actualización de estas Condiciones de uso al conectarse a CLAYVEREST.

## DATOS PERSONALES

> Esta información no tiene valor contractual, pero resume sus derechos como usuario en relación con sus datos. 

MI-GSO y su Cliente (nosotros) han elegido la herramienta CLAYVEREST para la gestión de sus proyectos. En este sentido, somos responsables conjuntamente del tratamiento de sus datos personales, como pueden ser sus datos de acceso (correo electrónico y contraseña, número de teléfono) o los datos que introduce en la herramienta. Esto se basa en nuestro interés legítimo de proporcionarle las herramientas que necesita para hacer su trabajo, así como garantizar la seguridad informática de la herramienta. Nosotros y nuestros proveedores utilizaremos estos datos, a través de nuestros equipos de proyectos, técnicos o administrativos, y los conservaremos hasta la finalización de los proyectos en los que haya participado o hasta la inactivación de su cuenta de CLAYVEREST. 

Tiene varios derechos que puede ejercer abriendo una solicitud de asistencia técnica. 

| Derecho | Descripción |
|:-----|:------|
| **Acceso** | 	Todos sus datos, a excepción de los registros, son visibles y accesibles en la herramienta.  Para obtener una copia completa, vea el párrafo anterior.  |
| **Rectificación** | 	Si lo desea, puede rectificar directamente su nombre de usuario, contraseña, correo electrónico, número de teléfono y tipo de doble autenticación. Para ello, inicie sesión en CLAYVEREST, haga clic en «User Profile» (Perfil de usuario), edite la información que desee rectificar Y guarde los cambios. También puede corregir de forma espontánea los objetos que crea en la herramienta.  |
| **Supresión** | 	Puede solicitar la supresión de sus datos, lo que impedirá que se siga utilizando la herramienta.  |
| **Restricción** | 	Puede solicitar la restricción del tratamiento de sus datos, lo que impedirá temporalmente su uso. Otra posibilidad es que simplemente deje de utilizar CLAYVEREST hasta que se responda a su solicitud.  |
| **Portabilidad** | 	Puede llevar a cabo una extracción de sus datos para cargarlos en otro entorno.  |
| **Oposición** | 	La oposición no es posible ya que esto le impediría realizar sus obligaciones profesionales.   |
 
Puede ponerse en contacto con nosotros a través de la persona encargada de los datos personales en su empresa o presentando una solicitud de asistencia. En caso de desacuerdo después de este contacto, puede enviar una reclamación a la Comisión Nacional de Informática y Libertades, Place de Fontenoy, 75007 París. 

Sus datos no se transfieren fuera del Espacio Económico Europeo, y no elaboramos perfiles ni tomamos decisiones puramente automatizadas sobre ellos.

## IDIOMA

Estas Condiciones de uso se han redactado en varios idiomas. A efectos de interpretación de las presentes Condiciones de uso, prevalecerá la versión francesa.

## DERECHO APLICABLE Y JURISDICCIÓN COMPETENTE

Las presentes Condiciones de uso están sujetas a la legislación francesa. Todo litigio relativo a las presentes Condiciones de uso, su formación, interpretación, ejecución, rescisión o expiración se someterá a la jurisdicción competente exclusiva de los tribunales franceses.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;