import { CvtInput } from "@mi-gso/cvt";
import { useCallback, useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FUNCT_FIND_INDEX, FUNC_HANDLE_INPUT_CHANGE } from "../../../../00-Core/Standards";
import {
    FUNC_BOARD_FILTER_PROJECT_LIST,
    FUNC_BOARD_GET_PROJECTS_USER_HAS_NO_ACCESS_TO,
} from "../../00-Helpers/BoardFunctions";
import UsersManagementMissingUserAccessWarning from "../01-UserManagement/01-Components/UserManagementMissingUserAccessWarning";
import BoardProjectListPicture from "../BoardProjectListPicture";

export default function PortfolioCreateEditSelectedProjects({
    organizationList,
    projectList,
    itemViewers,
    itemEditors,
    usersList,
    selectedProjects,
    selectedOrganizations,
    draggingType,
    dragDropActive,
}) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////
    const [state, setState] = useState({
        searchQuery: "",
    });

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // ON SEARCH INPUT CHANGE
    const handleSearchChange = useCallback((e) => {
        FUNC_HANDLE_INPUT_CHANGE(e, setState);
    }, []);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // FILTERED PROJECT LIST BASED ON SEARCH BAR TEXT
    const filteredProjectList = useMemo(() => {
        return FUNC_BOARD_FILTER_PROJECT_LIST(projectList, organizationList, state.searchQuery);
    }, [organizationList, projectList, state.searchQuery]);

    // CHECK EACH PROJECTS FOR USER PERMISSIONS
    const projectsWithMissingUserPermissions = useMemo(() => {
        let foundMissingPermissions = {};
        let missingPermissionsStrings = {};
        let allUsers = [...itemEditors, ...itemViewers];

        // CHECK EACH USER FOR PROJECT WITH NO ACCESS
        allUsers.forEach((userId) => {
            // GET PROJECTS USER HAS NO ACCESS
            let projectUserHasNoAccess = FUNC_BOARD_GET_PROJECTS_USER_HAS_NO_ACCESS_TO(
                userId,
                selectedProjects,
                selectedOrganizations,
                projectList
            );

            // ADD EACH PROJECTS TO THE LIST
            projectUserHasNoAccess.forEach((project) => {
                // IF PROJECT KEY EXISTS IN THE FINAL OBJECT
                if (foundMissingPermissions[project.id]) {
                    // PUSH IT TO THE ARRAY
                    foundMissingPermissions[project.id].push(userId);
                } else {
                    // ADD THE PROJECT AND ADD THE CURRENTLY TESTED USER TO THE LIST
                    foundMissingPermissions[project.id] = [userId];
                }
            });
        });

        // BUILD STRINGS FOR EACH PROJECT ID
        Object.keys(foundMissingPermissions).forEach((projectId) => {
            let string = "";

            // CREATE A STRING FOR EACH PROJECTS
            foundMissingPermissions[projectId].forEach((missingUserId) => {
                // GET USER INFOS
                let userIndex = FUNCT_FIND_INDEX(usersList, "id", missingUserId);

                // IF USER FOUND
                if (userIndex !== -1) {
                    let userName = usersList[userIndex].name;
                    string += userName + ", ";
                }
            });

            // REMOVE LAST ','
            string = string.slice(0, -2);
            string += " ";

            string += " have no access on the project";

            missingPermissionsStrings[projectId] = string;
        });

        return missingPermissionsStrings;
    }, [itemEditors, itemViewers, projectList, selectedOrganizations, selectedProjects, usersList]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        // GLOBAL COLUMN
        <div
            className="structureBlockInfo flexColStartStart"
            style={{
                width: "calc(50% - 5px)",
                border: "1px solid var(--border-color-gray-0)",
                borderRadius: "10px",
                padding: "0px",
            }}
        >
            {/* TOP TITLE / SEARCH BAR BLOCK */}
            <div style={{ padding: "20px 20px 0px 20px", width: "100%" }}>
                {/* TITLE */}
                <div
                    style={{
                        fontWeight: "700",
                        color: "var(--color-text-2)",
                        marginBottom: "30px",
                    }}
                >
                    Selected Organizations / Projects
                </div>

                {/* SEARCH BAR */}
                <div className="flexStartCenter" style={{ width: "100%" }}>
                    <CvtInput
                        inputBlockCss="flexStartCenter modalOptionSubBlock"
                        inputCss="form-control modalInputLight"
                        inputBlockPadding="0px"
                        inputBlockMargin={"0px"}
                        inputWidthFull={true}
                        name="searchQuery"
                        placeholder={"Search"}
                        value={state.searchQuery}
                        onChange={handleSearchChange}
                    />
                    <div
                        className="material-icons"
                        style={{
                            marginLeft: "-40px",
                            userSelect: "none",
                            color: "var(--border-color-gray-2)",
                            cursor: state.searchQuery ? "pointer" : null,
                        }}
                        onClick={() => {
                            setState({ searchQuery: "" });
                        }}
                    >
                        {state.searchQuery !== "" ? "close" : "search"}
                    </div>
                </div>
            </div>

            {/* GLOBAL ORG / PROJECT LIST BLOCK */}
            <div
                style={{
                    height: "calc(100vh - 390px)",
                    width: "100%",
                    overflow: "auto",
                    marginTop: "15px",
                    padding: "0px 10px",
                }}
            >
                {/* DROPPABLE COLUMN AREA */}
                <Droppable droppableId={"SelectedProjectsListColumn"}>
                    {(provided, snapshot) => (
                        <div
                            key={"SelectedProjectsListColumn"}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                                backgroundColor: snapshot.isDraggingOver ? "lightblue" : null,
                                borderRadius: "5px",
                                position: "relative",
                                border: dragDropActive ? "2px dotted var(--color-bad-hover)" : null,
                                padding: "10px 20px",
                                minHeight: "100%",
                            }}
                        >
                            {/* HELP TEXT */}
                            {selectedOrganizations.length === 0 && selectedProjects.length === 0 ? (
                                <div
                                    className="flexCenterCenter"
                                    style={{
                                        color: "var(--color-text-4)",
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        width: "95%",
                                    }}
                                >
                                    <span className="material-symbols-outlined">flip_to_back</span>
                                    Move your Organization and Projects here
                                </div>
                            ) : null}

                            {/* MAP ORGANIZATION BOXES */}
                            {selectedOrganizations.map((organizationId, index) => {
                                // GET ORGANIZATION NAME
                                let organizationIndex = FUNCT_FIND_INDEX(
                                    organizationList,
                                    "id",
                                    organizationId
                                );
                                let organizationData = organizationList[organizationIndex];
                                let organizationName = organizationData?.name;

                                // GET ORGANIZATION PROJECTS
                                let projects = filteredProjectList.filter(
                                    (project) =>
                                        project.coreDataOrganizationCoreDataProjectId ===
                                        organizationId
                                );

                                // IF ORGA CONTAINS PROJECTS
                                if (projects.length > 0) {
                                    return (
                                        // ORGA DRAGGABLE
                                        <Draggable
                                            key={organizationId}
                                            draggableId={organizationId}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                // ORGA DRAGGABLE CONTENT
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        borderRadius: "5px",
                                                        overflow: "hidden",
                                                        border: snapshot.isDragging
                                                            ? "2px dotted var(--color-bad-hover)"
                                                            : "1px solid var(--border-color-gray-0)",
                                                        boxShadow:
                                                            "1px 1px 2px rgba(0, 0, 0, 0.25)",
                                                        width: "100%",
                                                        marginBottom: "10px",
                                                        backgroundColor: "white",
                                                        ...provided.draggableProps.style,
                                                    }}
                                                >
                                                    {/* ORGANIZATION NAME HEADER */}
                                                    <div
                                                        className="flexBetweenCenter boardPortfolioCreateFormDragableBox"
                                                        style={{
                                                            backgroundColor:
                                                                "var(--border-color-gray-0)",
                                                            color: "var(--color-text-2)",
                                                            fontWeight: "600",
                                                            fontSize: "12px",
                                                        }}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {/* LEFT PART */}
                                                        <div
                                                            className="flexStartCenter"
                                                            style={{ gap: "15px" }}
                                                        >
                                                            <BoardProjectListPicture
                                                                pictureUrl={
                                                                    organizationData.logoLitle
                                                                }
                                                                alt={organizationData.name}
                                                                size="30px"
                                                            />
                                                            <span>
                                                                {organizationName ?? "Unknown"}
                                                            </span>
                                                        </div>

                                                        {/* RIGHT PART */}
                                                        <span className="material-symbols-outlined">
                                                            drag_indicator
                                                        </span>
                                                    </div>

                                                    {/* PROJECT LIST */}
                                                    <div
                                                        style={{
                                                            padding: "15px 15px 15px 35px",
                                                        }}
                                                    >
                                                        <div
                                                            className="flexColStartStart"
                                                            ref={provided.innerRef}
                                                            style={{
                                                                borderRadius: "5px",
                                                                backgroundColor:
                                                                    snapshot.isDraggingOver
                                                                        ? "lightblue"
                                                                        : null,
                                                                gap: "10px",
                                                            }}
                                                        >
                                                            {projects.map((project) => {
                                                                return (
                                                                    // PROJECT BOX
                                                                    <div
                                                                        key={
                                                                            "selectedProjects_" +
                                                                            project.id
                                                                        }
                                                                        className="flexBetweenCenter boardPortfolioCreateFormDragableBox"
                                                                        style={{
                                                                            backgroundColor:
                                                                                "white",
                                                                            border: "1px solid var(--border-color-gray-0)",
                                                                            color: "var(--color-text-3)",
                                                                            fontWeight: "600",
                                                                            fontSize: "10px",
                                                                        }}
                                                                    >
                                                                        {/* LOGO AND NAME */}
                                                                        <div
                                                                            className="flexStartCenter"
                                                                            style={{
                                                                                gap: "10px",
                                                                            }}
                                                                        >
                                                                            <BoardProjectListPicture
                                                                                pictureUrl={
                                                                                    project.logo
                                                                                }
                                                                                alt={project.name}
                                                                                size="30px"
                                                                            />
                                                                            {project.name}
                                                                        </div>

                                                                        {/* SHOW USERS THAT DOES NOT HAVE ACCES TO THE PROJECT */}
                                                                        <UsersManagementMissingUserAccessWarning
                                                                            projectId={project.id}
                                                                            projectsWithMissingUserPermissions={
                                                                                projectsWithMissingUserPermissions
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                }
                                return null;
                            })}

                            {/* MAP PROJECTS */}
                            {selectedProjects.map((projectId, index) => {
                                // GET PROJECT DATA
                                let projectIndex = FUNCT_FIND_INDEX(
                                    filteredProjectList,
                                    "id",
                                    projectId
                                );
                                let projectData = filteredProjectList[projectIndex];

                                // IF PROJECTS DATA FOUND
                                if (projectData) {

                                    return (
                                        // PROJECT DRAGGABLE
                                        <Draggable
                                            key={projectId}
                                            draggableId={projectData.coreDataOrganizationCoreDataProjectId + "_" + projectId}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                // PROJECT BOX
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className="flexBetweenCenter boardPortfolioCreateFormDragableBox"
                                                    style={{
                                                        backgroundColor: "white",
                                                        border: snapshot.isDragging
                                                            ? "2px dotted var(--color-bad-hover)"
                                                            : "1px solid var(--border-color-gray-0)",
                                                        color: "var(--color-text-3)",
                                                        fontWeight: "600",
                                                        fontSize: "10px",
                                                        marginBottom: "10px",
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {/* LOGO AND NAME */}
                                                    <div
                                                        className="flexStartCenter"
                                                        style={{
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <BoardProjectListPicture
                                                            pictureUrl={projectData.logo}
                                                            alt={projectData.name}
                                                            size="30px"
                                                        />
                                                        {projectData.name}
                                                    </div>

                                                    {/* RIGHT PART */}
                                                    <div className="flexStartCenter">
                                                        {/* SHOW USERS THAT DOES NOT HAVE ACCES TO THE PROJECT */}
                                                        <UsersManagementMissingUserAccessWarning
                                                            projectId={projectId}
                                                            projectsWithMissingUserPermissions={
                                                                projectsWithMissingUserPermissions
                                                            }
                                                        />

                                                        {/* DRAG / DROP BUTTON */}
                                                        <span
                                                            className="material-symbols-outlined"
                                                            style={{
                                                                color: "var(--border-color-gray-0-5)",
                                                            }}
                                                        >
                                                            drag_indicator
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                }
                                return null;
                            })}

                            {/* PLACEHOLDER WHEN AN ELEMENT IS MOVED */}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
}
