// REACT
import { useCallback, useMemo, memo } from 'react';

// CORE FUNCTIONS
import { FUNC_GET_USER_INFO } from '../../../../../00-Core/Standards';

// RISK CONSTANTS
import { RISK_OPP_EXPAND_MODES } from '../../00-Helpers/RiskConstants';

// RISK COMPONENTS
import RiskCardActionsTable from '../RiskCard/RiskCardActionsTable/RiskCardActionsTable';

// MIGSO
import { Button, CvtButton, CvtAvatar } from '@mi-gso/cvt';

/////////////////////////////////
/// DETAILED RO ACTIONS TABLE ///
/////////////////////////////////

export default memo(function DetailedRoActionsTable({
    roItem,
    actionsData,
    expandMode,
    handleExpandArrowClick,
    usersList,
    megaUsersId,
    riskOppDispatcher,
    wbsId,
    parentId,
    handleCreateAction,
    handleEditRoItem,
    currentUser,
    selectedItems,
    isCurrentUserEditor,

    textSearchInput,
    statusSearchInput,
    isExpanded,
    displayWidth,
    canModifyRo,

    handleEditAction,
    handleDuplicateClick,
    handleDeleteClick,
    legendAndCounter,
    allActionsData,
    handleRoSelectCheckbox,
    handleMoveItemsClicked,
}) {

    /////////////////////////////////////////////////////
    /// MEMOS ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    const isEscalated = useMemo(() =>roItem && roItem.escalatedTo && (
        roItem.escalatedTo?.includes(wbsId) ||
        roItem.escalatedTo?.includes(parentId)
    ), [parentId, roItem, wbsId]);

    /////////////////////////////////////////////////////
    /// FUNCTIONS ///////////////////////////////////////
    /////////////////////////////////////////////////////

    // HANDLE CLOSE
    const handleClose = useCallback(() => {
        riskOppDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                detailedRoMatrixItem: null,
            }
        })
    }, [riskOppDispatcher]);

    /////////////////////////////////////////////////////
    /// COMPONENT RENDER ////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <div className={`actionTableContainer flexColStartCenter ${!roItem || displayWidth === "0%" ? "hideContainer" : ""}`}
            style={{
                width: displayWidth,
                height: '100%',
                borderRadius: '8px',
                display: displayWidth === '0%' ? 'none' : '',
                // position: 'relative'
                margin: displayWidth === "0%" ? "0px" : expandMode === RISK_OPP_EXPAND_MODES.tableOnly ? "0px 20px" : "0px 20px 0px 0px",
                border: '1px solid var(--border-color-gray-0-5)',
                // overflowY: 'clip'
            }}
            key={`detailed_matrix_risk_table_${roItem && roItem.id}`}
        >
           {/* EXPAND BUTTON*/}
           <Button
                icon={expandMode === RISK_OPP_EXPAND_MODES.tableOnly ? "chevron_right" : "chevron_left"}
                css="btn flexCenterCenter commonButtonSubmitLight actionTableExpandButton"
                borderRadius="20px"
                iconFontSize="16px"
                handleSubmit={() => handleExpandArrowClick(expandMode === RISK_OPP_EXPAND_MODES.tableOnly ? false : true, false)}
            />

            {/* ACTUAL DETAILED TABLE */}

            {/* RO TITLE AND STUFF */}
            {
                roItem ?
                <div className="flexStartCenter"
                    style={{
                        padding: '5px',
                        width: '100%',
                    }}
                    >

                    {/* RESPONSIBLE AVATAR */}
                    <CvtAvatar
                        value={FUNC_GET_USER_INFO(usersList, megaUsersId, roItem.responsible)}
                        radius={12}
                        fontSize="12px"
                        regularTooltip={true}
                        tooltipTxt={FUNC_GET_USER_INFO(usersList, megaUsersId, roItem.responsible).label}
                        margin="5px 8px"
                    />


                    {/* DISPLAY ID */}
                    <span className='riskBigViewModeDisplayId'>{roItem.displayId}</span>

                    {/* TITLE */}
                    <span className='actionCardInfosTitle ellipsisOneLine' style={{maxWidth: '500px'}}>
                        {roItem.name}
                    </span>

                    {
                        isEscalated &&
                        <div className='detailedRoActionsTableRoEscalatedContainer flexStartCenter'>
                            {/* ESCALATED ARROW */}
                            <div className="material-icons detailedRoActionsEscalatedArrow"
                                style={{
                                    // IF ESCALATED TO INCLUDES THIS WBS ID, THEN SHOW BORDER BOTTOM
                                    borderBottom: roItem && roItem.escalatedTo && roItem.escalatedTo?.includes(wbsId) ? "2px solid var(--color-bad)" : '',
                                    // IF ESCALATED TO INCLUDES PARENT'S ID THEN SHOW BORDER TOP
                                    borderTop: roItem && roItem.escalatedTo && roItem.escalatedTo?.includes(parentId) ? "2px solid var(--color-bad)" : '',
                                }}
                            >
                                arrow_upward
                            </div>
                            {/* TEXT */}
                            {/* <span className='detailedRoActionsTableEscalatedText'>
                                {
                                    `Escalated ${roItem && roItem.escalatedTo?.includes(wbsId) ? "down" : "up"}`
                                }
                            </span> */}
                        </div>
                    }

                    <div className='flexOne'></div>

                    {/* CLOSE BTN */}
                    <CvtButton
                        color="gray"
                        customHover="red"
                        shape="noShape"
                        icon="close"
                        handleSubmit={handleClose}
                    />
                </div>
            :null}




            {/* ACTION TABLE */}
             <RiskCardActionsTable
                 roItem={roItem}
                 actionsData={actionsData}
                 megaUsersId={megaUsersId}
                 usersList={usersList}
                 isFlexOne={false}
                 width="100%"
                 isCurrentUserEditor={isCurrentUserEditor}
                 canModifyRo={canModifyRo}
                 
                 currentUser={currentUser}
                 key={`ro_matrix_detailed_actions_table_${roItem && roItem.actionsIds ? roItem.actionsIds.length : 0}`}
                 backgroundColor='var(--border-color-gray-00-5)'
                 //backgroundColor="transparent"
                 headersBackground="var(--border-color-gray-00-5)"
                 border="none"
                 headerBottomBorder="none"
                 maxHeight={isExpanded ? '800px' :  "120px"}
                 tableBorderBottom="1px solid var(--border-color-gray-1)"
                 riskOppDispatcher={riskOppDispatcher}
                 selectedItems={selectedItems}

                 legendAndCounter={legendAndCounter}
                 statusSearchInput={statusSearchInput}
                 textSearchInput={textSearchInput}

                 // FUNCTIONS
                 handleEditAction={handleEditAction}
                 handleDuplicateClick={handleDuplicateClick}
                 handleDeleteClick={handleDeleteClick}
                 allActionsData={allActionsData}
                 handleRoSelectCheckbox={handleRoSelectCheckbox}
                 handleCreateAction={handleCreateAction}
                 handleEditRoItem={handleEditRoItem}
                 handleMoveItemsClicked={handleMoveItemsClicked}
             />
        </div>
    )
});