// LIBS
import {memo, useCallback, useMemo, useState} from 'react';
import { v4 as uuid } from "uuid";
import _ from 'lodash';

// FUNCTIONS
import { FUNCT_FIND_INDEX, FUNC_GET_AUTHORIZED_DATA } from '../../../../../00-Core/Standards';
// COMPONENTS
import ActionLinksSideBarLegend from './ActionLinksSideBarLegend';
import ActionLinksSideBarAddEditItem from './ActionLinksSideBarAddEditItem';
import ActionLinksSideBarList from './ActionLinksSideBarList';
import { ACTION_LINKS_SIDE_BAR_DEFAULT_ITEM } from '../../00-Helpers/ActionsConstants';


export default memo(function ActionLinksSideBar({
    // DATA
    links,
    projectData,
    // FUNCTIONS
    handleChange,
    currentUserId,
    isCurrentUserEditor,
}) {
    
    ///////////////////
    // STATE //////////
    ///////////////////

    const [state, setState] = useState({
        isAddView: false,
        editingDestinationItem: null,
        linkItem: ACTION_LINKS_SIDE_BAR_DEFAULT_ITEM,
    });

    ///////////////////
    // MEMO ///////////
    ///////////////////

    const parsedLinks = useMemo(() => {
        try {
            return JSON.parse(links);
        } catch {
            return [];
        }
    }, [links])

    // GET AUTHORIZED PROJECT DATA
    const authorizedProjectData = useMemo(() => FUNC_GET_AUTHORIZED_DATA(projectData, currentUserId, isCurrentUserEditor), [currentUserId, isCurrentUserEditor, projectData]);
    
    ///////////////////
    // FUNCTIONS //////
    ///////////////////

    const onOpenNewLinkView = useCallback((e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isAddView: true,
            editingDestinationItem: null,
        }));
    }, []);

    const onBackToLinkList = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            isAddView: false,
            linkItem: ACTION_LINKS_SIDE_BAR_DEFAULT_ITEM,
            editingDestinationItem: null,
        }));
    }, []);

    const onEditItem = useCallback((e, options) => {
            e.stopPropagation();
            const findIndex = FUNCT_FIND_INDEX(parsedLinks, "id", options.id);
            const linkItem = parsedLinks[findIndex];

            setState((prevState) => ({
                ...prevState,
                linkItem: linkItem,
                isAddView: true,
                editingDestinationItem: linkItem.destinationItemId,
            }));
    }, [parsedLinks]);

    const onSaveLinkItem = useCallback(() => {
            let newLinksList = [];
            if (parsedLinks.length > 0) {
                newLinksList = [...parsedLinks];
            }

            // INIT AGENDA ITEM
            let linkItem;

            // IF ITEM HAS AN ID, EDIT IT
            if (state.linkItem?.id) {
                let findIndex = FUNCT_FIND_INDEX(newLinksList, "id", state.linkItem.id);
                newLinksList[findIndex] = state.linkItem;
            } else {
                // CREATE NEW ITEM
                linkItem = {
                    ...state.linkItem,
                    id: uuid(),
                };

                // ADD ITEM TO LIST IF TITLE NOT EMPTY
                newLinksList.push(linkItem);
            }
         
            // UPDATE DATA
            handleChange("links", JSON.stringify(newLinksList), "actionAgenda");
            
            // GO BACK TO LIST
            onBackToLinkList();
            
    }, [handleChange, onBackToLinkList, parsedLinks, state.linkItem]);

    const onDeleteLinkItem = useCallback(
        (e, options) => {
            e.stopPropagation();
            e.preventDefault();
            
            let newLinksList = [..._.cloneDeep(parsedLinks)];

            // FIND INDEX
            const findIndex = FUNCT_FIND_INDEX(newLinksList, "id", options.id);
            // IF FOUND
            if (findIndex !== -1) {
                newLinksList.splice(findIndex, 1);
            }

            // UPDATE DATA
            handleChange("links", JSON.stringify(newLinksList), "actionAgenda");
    }, [handleChange, parsedLinks]);

    const handleInputChange = useCallback(
        (e) => {
            const key = e.target.name;
            const value = e.target.value;

            let linkItemState = {...state.linkItem};

            linkItemState[key] = value;

            // CHANGED DOMAIN, SO MAKE DESTINATION ID BACK TO NULL
            if(key === "type") {
                linkItemState.destinationItemId = null;
            }
           
            setState((prevState) => {
                return {
                    ...prevState,
                    linkItem: linkItemState
                };
            }, []);

    }, [state.linkItem]);

    ///////////////////
    // RENDER /////////
    ///////////////////
        
    return (
        <div>
            {/* LEGEND */}
            <ActionLinksSideBarLegend 
                isAddView={state.isAddView}
                linkItem={state.linkItem}
                listLength={parsedLinks.length}
                onOpenNewLinkView={onOpenNewLinkView}
                onBackToLinkList={onBackToLinkList}
                onSaveLinkItem={onSaveLinkItem}
            />

            {/* ADD NEW ITEM FORM */}
            {
                state.isAddView && (
                    <ActionLinksSideBarAddEditItem 
                        linkItem={state.linkItem}
                        projectData={authorizedProjectData}
                        handleInputChange={handleInputChange}
                        parsedLinksList={parsedLinks}
                        editingDestinationItem={state.editingDestinationItem}
                    />
                )
            }

            {/* LIST */}
            {
                !state.isAddView &&
                (
                    <ActionLinksSideBarList 
                        key={`action_links_side_bar_list_${parsedLinks.length}`}
                        parsedLinksList={parsedLinks}
                        projectData={projectData}
                        onEditItem={onEditItem}
                        onDeleteLinkItem={onDeleteLinkItem}
                        editingLink={state.linkItem}
                        authorizedProjectData={authorizedProjectData}
                    />
                )
            }
        </div>
    );
});