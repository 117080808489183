import React from 'react'

// STRUCTURE BODY COMPONENT
export default function WbsElementStructureBody({
    blockFlex,
    children
}) {
  return (
    <div className={"bigComponentBody " + (blockFlex ? blockFlex : "flexStartStrech")}>
        {children}
    </div>
  )
}
