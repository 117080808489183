import React from 'react'
import { FUNCT_FIND_INDEX } from '../../../../00-Core/Standards';

//FILTER
import FiltersTypeCheckbox from './FiltersTypeCheckbox';
import FiltersTypeDate from './FiltersTypeDate';
import FiltersTypeNumber from './FiltersTypeNumber';
import FiltersTypeSelect from './FiltersTypeSelect';

//FILTERS CHILDREN COMPONENT
export default function FiltersChildren({
    wbsDomainId,
    wbsSubDomains,
    wbsDomainFilters,
    ownerList,
    leaderList,
    handleFilterChange,
    handleCheckboxFilterChange,
    handleDateFilterChange,
    handleSelectChange,
    filteredProjectData,
}) {

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////

    return (
        <React.Fragment>

            {/* LOOP ON SUBGROUPS */}
            {wbsSubDomains.map((item) => {

                //IS OWNER FIELD
                let isOwner = wbsDomainId === "owner" 
                let isLeader = wbsDomainId === "charter" && item.subDomain === "owner"

                //OPTIONS LIST MANAGEMENT
                let options = null;
                if(isOwner) options = ownerList;
                if(item.options) options = item.options;
                if(isLeader) options = leaderList;
                // IF IS REVIEWS -> USE FILTERED REVIEWS
                if(item.subDomain === "reviewName") options = filteredProjectData.govReview.map((review) => {

                    const day = review.startDate.toLocaleString("en-US", {day: '2-digit'});
                    const month = review.startDate.toLocaleString("en-US", {month: '2-digit'});
                    const year = review.startDate.toLocaleString("en-US", {year: "2-digit"});

                    return {
                        value: review.id,
                        label: `${day}/${month}/${year} - ${review.name}`
                    }
                });

                //CHECKED VALUE
                let wbsSubDomainFilters = null
                let findIndex = FUNCT_FIND_INDEX(wbsDomainFilters,"subDomain", item.subDomain)
                if(findIndex > -1){
                    wbsSubDomainFilters = wbsDomainFilters[findIndex]
                }

                //RETURN
                return (
                    <div 
                        key={"filterSubDomain-" + wbsDomainId + "-" + item.subDomain}
                        style={{
                            margin: isOwner ? "-5px 0px 5px 5px"  : "-5px 10px 5px 10px" 
                        }}
                    >

                        {/*  TITLE */}
                        {!isOwner && !item.noSubtitle?
                            <div className='filterChildrenSubGroupTitle'>
                                {item.name}
                            </div>
                        :null}
                     
                        {/* OPTIONS */}
                        <div 
                            className={isOwner ? "filterSubGroupOverflow" : ""}
                            style={{
                                width:"100%"
                            }}
                        >
                            
                            {/* CHECKBOX */}
                            {item.type === "checkbox" ?
                                <FiltersTypeCheckbox 
                                    wbsDomainId={wbsDomainId}
                                    wbsSubDomainFilters={wbsSubDomainFilters}
                                    item={item}
                                    options={options}
                                    handleCheckboxFilterChange={handleCheckboxFilterChange}  
                                />
                            :null}

                            {/* DATE */}
                            {item.type === "date" ?
                                <FiltersTypeDate 
                                    item={item}
                                    value={wbsSubDomainFilters ? wbsSubDomainFilters.value : ""}
                                    handleDateFilterChange={handleDateFilterChange}
                                />
                            :null}

                            {/* NUMBER */}
                            {item.type === "number" ?
                                <FiltersTypeNumber 
                                    item={item}
                                    value={wbsSubDomainFilters ? wbsSubDomainFilters.value : ""}
                                    handleFilterChange={handleFilterChange}
                                />
                            :null}

                            {/* SELECT */}
                            {item.type === "select" ? 
                                <FiltersTypeSelect 
                                    item={item}
                                    value={wbsSubDomainFilters ? wbsSubDomainFilters.value : []}
                                    options={options}
                                    handleSelectChange={handleSelectChange}
                                />
                            :null}
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    )
}