import { FUNC_GET_WEEK_INFO_FROM_DATE, FUNC_GET_WEEK_INFO_FROM_WEEK, FUNC_GET_WEEK_NUMBER, FUNC_ZERO_FORMAT_TO_NUM } from "../../../../../00-Core/Standards";
import { ACTION_STATUS_KEY } from "../../00-Helpers/ActionsConstants";

//TRANSFORMATION DES DONNEES 
export const FUNC_ACTIONS_CHART_HISTO = (
    forecastedWeek, 
    actions
) =>{

    //INIT
    var transformedData = [];
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    const pastWeek = 4;
    var todayWeekInfos = {};
    todayWeekInfos = FUNC_GET_WEEK_INFO_FROM_DATE(today);
    // const dateForTimeZone = new Date("1988-05-30T00:00:00");
    // const userTimezoneOffset = dateForTimeZone.getTimezoneOffset() * 60000;

    //////////////////////////////////////////////
    /// TROUVER START WEEK MM SI PREVIOUS YEAR ///
    //////////////////////////////////////////////

    let diffWeeks = todayWeekInfos.number - pastWeek;
    var startWeekInfos = {};
    if(diffWeeks < 0){

        //FIND NUMBER OF WEEKS PREVIOUS YEAR
        startWeekInfos = FUNC_GET_WEEK_INFO_FROM_WEEK(1, todayWeekInfos.firstDay.getFullYear())

    }else{

        //OK PAS AUTRE YEAR
        startWeekInfos = FUNC_GET_WEEK_INFO_FROM_WEEK((todayWeekInfos.number - pastWeek), todayWeekInfos.firstDay.getFullYear())
    }

    /////////////////////////////////////////
    /// TROUVER LAST WEEK MM SI NEXT YEAR ///
    /////////////////////////////////////////
   
    var lastWeekInfos = {};
    let lastDayCurrentYear = new Date(todayWeekInfos.firstDay.getFullYear(),11,31)
    let numberWeeksCurrentYear = FUNC_GET_WEEK_NUMBER(lastDayCurrentYear)
    diffWeeks = (todayWeekInfos.number + forecastedWeek) - numberWeeksCurrentYear ;

    if(diffWeeks > 0){

        //NEXT YEAR
        lastWeekInfos = FUNC_GET_WEEK_INFO_FROM_WEEK(diffWeeks, todayWeekInfos.firstDay.getFullYear()+1)

    }else{

        //OK PAS AUTRE YEAR
        lastWeekInfos = FUNC_GET_WEEK_INFO_FROM_WEEK((todayWeekInfos.number + forecastedWeek), todayWeekInfos.firstDay.getFullYear())
    }

    //SECU
    if(actions){

        //INIT
        var currentWeekInfos = {};
        var currentLine = {};
        let weekTxt;
        let firstWeekLoop;
        let lastWeekLoop;

        //LOOP YEAR
        for (let k = startWeekInfos.year; k < lastWeekInfos.year + 1; k++) {
         
            
            
            if(startWeekInfos.year === lastWeekInfos.year){
                firstWeekLoop = startWeekInfos.number
                lastWeekLoop = lastWeekInfos.number
            }else{

                //IF WE ARE IN THE FIRST LOOP
                if(k === startWeekInfos.year){
                    firstWeekLoop = startWeekInfos.number
                    lastWeekLoop = numberWeeksCurrentYear
                }else{
                    firstWeekLoop = 1
                    lastWeekLoop = lastWeekInfos.number
                }
            }

            //LOOP WEEK FIRST TO TODAY 
            for (let i = firstWeekLoop ; i < lastWeekLoop + 1; i++){

                //INIT
                weekTxt = "W" + FUNC_ZERO_FORMAT_TO_NUM(i,2) + "/" + k.toString().slice(-2);
                
                //GET START AND END DAY FROM CURRENT WEEK
                currentWeekInfos = FUNC_GET_WEEK_INFO_FROM_WEEK(i,k);
           
                //LOOP DATA
                for (let j = 0 ; j < actions.length ; j++){

                    //INIT LINE
                    currentLine = {};

                    //CURRENT DATE ACTIONS
                    let createdOn = Date.parse(actions[j].createdOn) || null
                    let realisedDate = Date.parse(actions[j].realisedDate) || null
                    let onHold = Date.parse(actions[j].cancelledDate) || null
                    let dueDate = Date.parse(actions[j].dueDate) || null

                    
                    //DELETE TIMEZONE BECAUSE WE ARE NOT COMPARING TIME BUT DAY
                    // if(createdOn) createdOn = createdOn + userTimezoneOffset;
                    // if(realisedDate) realisedDate = realisedDate + userTimezoneOffset;
                    // if(onHold) onHold = onHold + userTimezoneOffset;
                    // if(dueDate) dueDate = dueDate + userTimezoneOffset;

                    //TEST CREATED ON
                    if(realisedDate && createdOn > realisedDate) createdOn = realisedDate
                    if(dueDate && createdOn > dueDate) createdOn = dueDate
                    // if(onHold && createdOn > onHold) createdOn = onHold
                    
                    //SEULEMENT PAST
                    if(i <= todayWeekInfos.number && k === startWeekInfos.year){
                        
                        //PAST & CURRENT WEEK
                        if(createdOn <= currentWeekInfos.lastDay.getTime() || onHold){

                            /////////////
                            /// CLOSE  //
                            /////////////
                           
                            if(actions[j].status === ACTION_STATUS_KEY.done
                            && realisedDate
                            && realisedDate >= currentWeekInfos.firstDay.getTime()  
                            && realisedDate <= currentWeekInfos.lastDay.getTime()){

                                
                                //CLOSE
                                currentLine.done = true;
                            

                                ////////////////
                                /// ON HOLD  ///
                                ////////////////
                            }
                            else if(
                                actions[j].status === ACTION_STATUS_KEY.onHold 
                                && onHold
                            ){
                             
                                const canAddHoldToLine = (
                                    onHold <= currentWeekInfos.firstDay.getTime() ||
                                    (todayWeekInfos.firstDay.getTime() >= currentWeekInfos.firstDay.getTime() && onHold <= currentWeekInfos.lastDay.getTime())
                                );
                                
                                if(canAddHoldToLine) {
                                    currentLine.onHold = true;
                                }
                                // CAN'T ADD HOLD, SO TRY TO ADD PREVIOUS STATUS
                                else {

                                    // PARSE EVENTS
                                    const eventsParsed = JSON.parse(actions[j].events);

                                    // SORT THE FILTERED STATUS EVENTS
                                    const sortedStatusEvents = eventsParsed.filter((event) => event.value?.includes("status")).sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

                                    // IF STATUS WAS CHANGED
                                    if(sortedStatusEvents.length > 1) {

                                        // GET PREVIOUS STATUS
                                        const previousStatus = sortedStatusEvents[0].lastValue;

                                        // IF WAS TODO OR IN PROGRESS AND DUE DATE IS AFTER THE CURRENT WEEK FIRST DAY
                                        if(
                                            (previousStatus === ACTION_STATUS_KEY.todo || previousStatus === ACTION_STATUS_KEY.inProgress) && 
                                            (
                                                dueDate >= currentWeekInfos.firstDay.getTime() ||  
                                                (i !== todayWeekInfos.number && dueDate <= today.getTime())
                                            )
                                        ) {
                                            //CHECK IF NOT LATE 
                                            if(dueDate <= currentWeekInfos.lastDay.getTime() && dueDate < today.getTime()){
                                                currentLine.late = true;
                                            }
                                            // NOT LATE
                                            else{
                                                currentLine[previousStatus === ACTION_STATUS_KEY.todo ? "todo" : "inProgress"] = true;
                                            }
                                        }
                                    }
                                }
                               

                                ////////////////////////////////////
                                /// IN PROGRESS / TODO / ON HOLD ///
                                ////////////////////////////////////
                            }
                            else if(i === todayWeekInfos.number && k === startWeekInfos.year
                            && dueDate <= today.getTime() 
                            && (actions[j].status === ACTION_STATUS_KEY.todo
                            || actions[j].status === ACTION_STATUS_KEY.inProgress)
                            ){
                            
                               
                                //LATE AND PLANNED
                                currentLine.late = true;
                                currentLine.planned = true;
                                
                            ////////////////
                            /// DUE DATE ///
                            ////////////////

                            }else if(dueDate >= currentWeekInfos.firstDay.getTime() 
                            ||  (i!== todayWeekInfos.number 
                            && dueDate <= today.getTime())
                            ){
                               
                                ////////////
                                /// TODO ///
                                ////////////

                                if(actions[j].status === ACTION_STATUS_KEY.todo){

                                    //CHECK IF NOT LATE 
                                    if(dueDate <= currentWeekInfos.lastDay.getTime() && dueDate < today.getTime()){
                                        currentLine.late = true;
                                    }else{
                                        currentLine.todo = true;
                                    }
                                    
                                }

                                ///////////////////
                                /// IN PROGRESS ///
                                ///////////////////

                                if(actions[j].status === ACTION_STATUS_KEY.inProgress){

                                    //CHECK IF NOT LATE 
                                    if(dueDate <= currentWeekInfos.lastDay.getTime() && dueDate < today.getTime()){
                                        currentLine.late = true;

                                    }else{
                                        currentLine.inProgress = true;
                                    }
                                   
                                }


                                /// DONE NEXT WEEK IF SAME DUE WEEK THAT THE CURRENT LOOP
                                //CHECK IF DUE WEEK IS NOT THE SAME AND NOT LATE PASS DONE TO THE NEXT WEEK
                                // if((actions[j].status === ACTION_STATUS_KEY.inProgress
                                // || actions[j].status === ACTION_STATUS_KEY.todo)
                                // && dueDate <= currentWeekInfos.lastDay.getTime()){
                                
                                //     //SEULES INFOS NECESSAIRE WEEK NUMBER & action id
                                //     currentLine.week = weekTxt;
                                //     currentLine.year = k;
                                //     currentLine.id = actions[j].id
                                    
                                    
                                //     //PUSH
                                //     transformedData.push({
                                //         week: "W" + FUNC_ZERO_FORMAT_TO_NUM(i+1,2) + "/" + k.toString().slice(-2),
                                //         year: k,
                                //         id: actions[j].id,
                                //         done: true
                                //     })
                                
                                // }

                                ///////////////
                                /// PLANNED ///
                                ///////////////

                                if(actions[j].status !== ACTION_STATUS_KEY.done
                                && actions[j].status !== ACTION_STATUS_KEY.onHold
                                && (i === todayWeekInfos.number && k === startWeekInfos.year)){
                                    currentLine.planned = true
                                }
                            }
                        }

                    }else{
                        
                        //CLOSE OK
                        if(actions[j].status !== ACTION_STATUS_KEY.done
                        && actions[j].status !== ACTION_STATUS_KEY.onHold
                        && dueDate >= currentWeekInfos.firstDay.getTime()  
                        && dueDate <= currentWeekInfos.lastDay.getTime()
                        ){
                            /////////////
                            /// CLOSE ///
                            /////////////
                           
                            currentLine.done = true
                        }
                    }

                    //RESTITUTION SI AU MOINS UNE VALEUR
                    if(currentLine.done 
                    || currentLine.late
                    || currentLine.onHold
                    || currentLine.inProgress
                    || currentLine.todo
                    || currentLine.planned
                    ){

                        //SEULES INFOS NECESSAIRE WEEK NUMBER & action id
                        currentLine.week = weekTxt;
                        currentLine.year = k;
                        currentLine.id = actions[j].id
                       
                        //PUSH
                        transformedData.push(currentLine)
                    }
                }
            }
        }
    }
    
    //RETURN
    let returnObject = {
        actionHisto: transformedData,
        firstWeek: startWeekInfos.number,
        firstWeekYear: startWeekInfos.year,
        lastWeek: lastWeekInfos.number,
        lastWeekYear: lastWeekInfos.year,
        todayWeek: todayWeekInfos.number,
        numberWeeksCurrentYear:numberWeeksCurrentYear,
    }
    return returnObject;
  }

//CREATE CHART DATA
export const FUNC_ACTIONS_CHART_DATA = (
    newActionsHisto,
) =>{
    
    //INIT
    var transformedData = [];
    var currentLine = {};
    var currentCounter = {};
    
    //FILTER OUTSIDE THE LOOP
     const filterCount = (week, property) => {
        return newActionsHisto.actionHisto.filter(action => action.week === week && action[property] === true).length
    }

    //COUNT FUNCTION
    const countFunction = (week) => {

        //INIT
        let counter ={
            late:0,
            inProgress:0,
            onHold:0,
            todo:0,
            done:0,
            planned:0
        }

        //LOOP COUNT
        for (var property in counter){
            counter[property] = filterCount(week, property)
        }

        //RETURN
        return counter;
    }

    //LOOP WEEK
    var forecast = 0;
    var diff;
    let firstWeekLoop;
    let lastWeekLoop;

    //LOOP IF TWO YEAR
    for (let k = newActionsHisto.firstWeekYear; k < newActionsHisto.lastWeekYear + 1; k++) {
      
        if(newActionsHisto.firstWeekYear === newActionsHisto.lastWeekYear){
            firstWeekLoop = newActionsHisto.firstWeek
            lastWeekLoop = newActionsHisto.lastWeek
        }else{

            //IF WE ARE IN THE FIRST LOOP
            if(k === newActionsHisto.firstWeekYear){
                firstWeekLoop = newActionsHisto.firstWeek
                lastWeekLoop = newActionsHisto.numberWeeksCurrentYear
            }else{
                firstWeekLoop = 1
                lastWeekLoop = newActionsHisto.lastWeek
            }
        }

        //WEEK
        for(let i = firstWeekLoop ; i < lastWeekLoop + 1 ; i++){

            //RESET
            currentLine = {};
            currentCounter = {};

            //WEEK
            currentLine.week =  "W" + FUNC_ZERO_FORMAT_TO_NUM(i,2) + "/" + k.toString().slice(-2);

            //TEST IN WEEK
            if(i <= newActionsHisto.todayWeek && k === newActionsHisto.firstWeekYear ){

                

                //COUNTER
                currentCounter = countFunction(currentLine.week)

                //INTERGRATION
                for (var property in currentCounter){
                  
                    if(currentCounter[property] > 0){currentLine[property] = currentCounter[property]}
                    // delete currentCounter.done
                }
                
                //FORECAST
                if(i === newActionsHisto.todayWeek){
                    forecast = currentCounter.planned;
                }

            }else{

                    //CLOSED DIFF
                    diff = filterCount(currentLine.week, ACTION_STATUS_KEY.done);

                    //ON COMPTE QUE LES CLOSED
                    forecast = forecast - diff;
                   
                    currentLine.planned = forecast;

                    if(diff > 0){currentLine.futurClosed = diff;}
            }

            //PUSH
            transformedData.push(currentLine)
        }
    }
    return transformedData
  }