import {Auth} from 'aws-amplify';
import {nextSignIn} from './AuthNextSignIn'
import {FUNC_DATE_TO_TXT_STANDARD} from '../../../../00-Core/Standards';
import { updateEnvUserByKeyAndValue } from '../../02-Backend/AppBackendUser';
import { authCleanUserDataCookie } from './AuthSignOut';

////////////////////////////////////////////
// SUMMARY                                //   
// 01 - verifyCurrentUserAttribute        //
// 03 - verifyCurrentUserEmailSubmit      //
// 04 - updateUserPhoneNumberAndSendCode  //
// 05 - updateUserAttributes              //
// 06 - updateUserTermsAttributes         //
////////////////////////////////////////////

//OK NEW AUTH

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// SEND CODE VERIFICATION FOR ATTRIBUTE EMAIL - SMS //////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

export async function verifyCurrentUserAttribute(
    attr,
    appDispatcher,
    authenticatorSetState
  ){

    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    await Auth.verifyCurrentUserAttribute(attr).then(() => {

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: false,
                }
            }
        })

        //RESET Authenticator LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                codeLoader: false,
                codeSent: true,
            })
        })}
    
    //ERR - NO CODE SENT
    ).catch((err) => {

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                codeLoader:false,
                codeSent: false,
            })
        })}
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// UPDATE EMAIL //////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

export async function verifyCurrentUserEmailSubmit(
    attr,
    code,
    appDispatcher,
    authenticatorSetState
  ){

    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    await Auth.verifyCurrentUserAttributeSubmit(attr, code).then(async () => {

        //GET UPDATED USER
        await Auth.currentAuthenticatedUser({bypassCache: true}).then((updatedUser) =>{

            //LAUCNH CHOOSE OF MULTIFACTOR
            appDispatcher({
                type:"SET_GLOBAL_STATE",
                options:{
                    source: "currentUser",
                    object: {
                        user: updatedUser,
                        needMfaSetUp: true,
                        needVerifyEmail: false,
                        username: updatedUser.username,
                        name: updatedUser.attributes.name,
                        errorMessage: false,
                    }
                }
            })

            //RESET LOADER
            authenticatorSetState(prevState => {
                return({
                    ...prevState, 
                    loader:false,
                    code:"",
                    codeSent: false,
                })
            })

            //DELETE USERDATA COOKIE
            authCleanUserDataCookie()
        })
    
    //ERR
    }).catch((err) => {

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })

        //RESET LOADER
        authenticatorSetState(prevState => {
            return({
                ...prevState, 
                loader:false
            })
        })}
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// UPDATE PHONE NUMBER ///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

export async function updateUserPhoneNumberAndSendCode(
    attrValue,
    appDispatcher,
    currentComponentSetState
  ){

    //GET UPDATED USER
    await Auth.currentAuthenticatedUser({bypassCache: true}).then(async (updatedUser) =>{

        //UPDATE
        await Auth.updateUserAttributes(
            updatedUser, 
            {
                "phone_number": attrValue
            }
        ).then(async ()=>{

            //SEND CODE
            await Auth.verifyCurrentUserAttribute("phone_number").then(() => {

                 //ERROR
                appDispatcher({
                    type:"SET_GLOBAL_STATE",
                    options:{
                        source: "currentUser",
                        object: {
                            errorMessage: false,
                        }
                    }
                })

                //RESET LOADER
                currentComponentSetState(prevState => {
                    return({
                        ...prevState, 
                        codeLoader: false,
                        codeSent: true,
                    })
                })
            
            //GET ERROR
            }).catch((error) =>{

                //UPDATE ERROR
                appDispatcher({
                    type:"SET_GLOBAL_STATE",
                    options:{
                        source: "currentUser",
                        object: {
                            errorMessage: error.message,
                        }
                    }
                })

                //RESET LOADER
                currentComponentSetState(prevState => {
                    return({
                        ...prevState, 
                        codeLoader: false,
                        codeSent: false,
                    })
                })
            })
        });

        //DELETE USERDATA COOKIE
        authCleanUserDataCookie()

    //ERR
    }).catch((err) => {

        //UPDATE ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    errorMessage: err.message,
                }
            }
        })

        //RESET LOADER
        currentComponentSetState(prevState => {
            return({
                ...prevState, 
                codeLoader: false,
                codeSent: false,
            })
        })}
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// UPDATE ATTRIBUTES /////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

export async function updateUserAttributes(
    attrKey,
    attrValue,
    appDispatcher,
    currentComponentSetState,
    firstName,
    lastName
  ){

    //INIT
    let attributesObject = {}
    let bodyObject = {}

    //ATTRIBUTES
    if(attrValue){
        attributesObject[attrKey] = attrValue;
        bodyObject[attrKey] = attrValue;
    }
    if(firstName){
        attributesObject['custom:firstName'] = firstName
        bodyObject.firstName = firstName
    }
    if(lastName){
        attributesObject['custom:lastName'] = lastName
        bodyObject.lastName = lastName
    }

    //GET UPDATED USER
    await Auth.currentAuthenticatedUser({bypassCache: true}).then(async (updatedUser) =>{

        //PROMISE ALL
        await Promise.all([

            //UPDATE COGNITO
            Auth.updateUserAttributes(
                updatedUser, 
                attributesObject
            ),

            //UPDATE DYNAMODB
            updateEnvUserByKeyAndValue(
                updatedUser.username,
                bodyObject
            )
        ])

        //RESET LOADER
        currentComponentSetState(prevState => {
            return({
                ...prevState, 
                loader: false,
                value:"",
                errName:null,
                err:null
            })
        })

        //ERROR
        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    ...attributesObject,
                    errorMessage: false,
                }
            }
        })
    
        //DELETE USERDATA COOKIE
        authCleanUserDataCookie()

    //ERR
    }).catch((err) => {

        //RESET LOADER
        currentComponentSetState(prevState => {
            return({
                ...prevState, 
                loader:false,
                err: err.message
            })
        })}
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// UPDATE TERMS ATTRIBUTES ///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

export async function updateUserTermsAttributes(
    appDispatcher,
    currentComponentSetState,
    envTermsVersion,
    languageTxt
  ){

    //GET DATE VALIDATION
    let today = new Date()
    let todayText = FUNC_DATE_TO_TXT_STANDARD(today)

    //GET UPDATED USER
    await Auth.currentAuthenticatedUser({bypassCache: true}).then(async (updatedUser) =>{

        //UPDATE
        await Auth.updateUserAttributes(
            updatedUser, 
            {
                "custom:terms": envTermsVersion,
                "custom:validateTermsAt": todayText,
                "custom:termsLanguage": languageTxt
            }
        );

        //GET AGAIN
        await Auth.currentAuthenticatedUser({bypassCache: true}).then((userWithGoodAttribute) =>{

            //OK CONNECTION UPDATE FRONT
            nextSignIn(
                userWithGoodAttribute,
                appDispatcher
            )
        })}

    //ERR
    ).catch((err) => {

        //RESET LOADER
        currentComponentSetState(prevState => {
            return({
                ...prevState, 
                loader:false,
                err: err.message
            })
        })}
    );
}