// REACT
import React, { useCallback, useMemo, useState, memo } from "react";

// COMPONENTS
import RiskCardInfosHeader from "./RiskCardInfosHeader";
import RiskCardInfosBody from "./RiskCardInfosBody";

////////////////////////////
/// RISK CARD INFOS ////////
////////////////////////////

export default memo(function RiskCardInfos({
    // RISK BIG DISPATCHER
    riskOppDispatcher,

    // CURRENT SELECTED MODE (DESCRIPTION/COMMENTS/EVENTS)
    riskViewModeSelection,

    // RO ITEM DATA
    roItem,

    projectData,

    // CURRENT USER
    currentUser,

    // USERS LIST
    usersList,

    // MEGA USERS IDS
    megaUsersIds,

    // ON SAVE ADD EDIT
    onSaveAddEdit,

    canModifyRo,

    isReadOnlyMode,
   
}) {
    /////////////////////////////////////////////////////
    /// STATE ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    const [state, setState] = useState({
        input: "",
        editingCommentId: null,
        propKeyIncrement: 0,
    });

    //////////////////////////////////////////////////////////////////
    /// DATA /////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    //COMMENTS ARRAY -------------------------------------------------
    const commentsArray = useMemo(() => {
        //INIT
        let array = [];

        //TEST IF COMMENTS AVAILABLE
        if (roItem && roItem.comments && JSON.parse(roItem.comments).length > 0) {
            // CONVERT FROM JSON TO ARRAY AND SORT BY DESCENDING DATE
            array = JSON.parse(roItem.comments).sort(
                (a, b) => Date.parse(b.createdOn) - Date.parse(a.createdOn)
            );
        }

        //RETURN COMMENTS ARRAY
        return array;
    }, [roItem]);

    //EVENTS ARRAY ---------------------------------------------------
    const eventsArray = useMemo(() => {
        //INIT
        let array = [];

        //TEST IF COMMENTS AVAILABLE
        if (roItem && roItem.events && JSON.parse(roItem.events).length > 0) {
            // CONVERT FROM JSON TO ARRAY AND SORT BY DESCENDING DATE
            array = JSON.parse(roItem.events).sort(
                (a, b) => Date.parse(b.createdOn) - Date.parse(a.createdOn)
            );
        }

        //RETURN COMMENTS ARRAY
        return array;
    }, [roItem]);

    //////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    // HANDLE INPUT CHANGE
    const handleInputChange = useCallback((e) => {
        setState((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    }, []);

    // HANDLE AUTOMATIC UPDATE OF RISK
    const handleEditRoItem = useCallback(
        (e, key, value) => {
            e.stopPropagation();

            // PREPATE MUTATION OBJECT
            let mutationObject = {
                id: roItem ? roItem.id : "",
                [key]: value,
            };

            // CALL ON SAVE FROM RiskBig.js
            onSaveAddEdit(e, mutationObject, false);

            // THIS STATE
            setState((prevState) => {
                return {
                    ...prevState,
                    input: "",
                    editingCommentId: null,
                };
            });
        },
        [onSaveAddEdit, roItem]
    );

    // HANDLE SELECTION OF A DIFFERENT MODE
    const handleModeSelection = useCallback(
        (e, optionKey) => {
            e.preventDefault();

            if (optionKey !== riskViewModeSelection && roItem) {
                riskOppDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        riskViewModeSelection: optionKey,
                    },
                });
                setState((prevState) => {
                    return {
                        ...prevState,
                        editingCommentId: null,
                        input: optionKey === "description" ? roItem.description : "",
                    };
                });
            }
        },
        [riskOppDispatcher, riskViewModeSelection, roItem]
    );

    // HANDLES EDITING COMMENTS
    const handleEditComment = useCallback((e, options) => {
        //UPDATE ACTION CARD STATE
        setState((prevState) => {
            //INIT VALUE
            let editingCommentId =
                prevState.editingCommentId === options.commentId ? null : options.commentId;

            //FOCUS ON INPUT
            if (editingCommentId) {
                const inputEdition = document.getElementsByClassName("cardDetailsCommentInput");
                inputEdition[0].focus();
            }

            //RETURN
            return {
                ...prevState,
                editingCommentId: editingCommentId,
                input: editingCommentId ? options.text : "",
            };
        });
    }, []);

    /////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ///////////////////////////////////////
    ////////////////////////////////////////////////////////////

    return (
        <div
            className="flexColStartStart"
            style={{
                width: "100%",
                overflow: "auto",
            }}
        >
            {/* HEADERS */}
            <RiskCardInfosHeader
                // DATA
                riskViewModeSelection={riskViewModeSelection}
                commentsArray={commentsArray}
                eventsArray={eventsArray}
                // FUNCTIONS
                handleModeSelection={handleModeSelection}
            />

            {/* BODY */}
            <RiskCardInfosBody
                // FUNCTIONS
                handleInputChange={handleInputChange}
                handleEditRoItem={handleEditRoItem}
                handleEditComment={handleEditComment}
                // DATA
                commentsArray={commentsArray}
                eventsArray={eventsArray}
                selectedOption={riskViewModeSelection}
                canModifyRo={canModifyRo}
                roItem={roItem}
                editingValue={state.input}
                editingCommentId={state.editingCommentId}
                usersList={usersList}
                megaUsersIds={megaUsersIds}
                currentUser={currentUser}
                projectData={projectData}
                isReadOnlyMode={isReadOnlyMode}
            />
        </div>
    );
});
