import React from 'react';

//CORE
import {
    CvtSideBar, 
    CvtSideBarGroup
} from '@mi-gso/cvt'
import {
    APP_WBS_STRUCTURE
} from '../../../../00-Core/Constants'

////////////////////////
/// IMPORT COMPONENT ///
//////////////////////// 

export default function WbsElementBigSideBar ({
    children
}){

    /////////////////////////////
    /// GRID COMPONENT RETURN ///
    /////////////////////////////

    return(
        <CvtSideBar 
            sideBar={false}
            boxShadow="0px 0px 4px 0px rgb(0 0 0 / 0%)"
            zIndex={50}
            sideBarWidth={APP_WBS_STRUCTURE.sideBarWidth}
            sideBarWidthBig={APP_WBS_STRUCTURE.sideBarWidthBig}
            borderRadius="0px 0px 0px 10px"
            rightBorder="1px solid var(--border-color-gray-0-5)"
            stickyLeft
        >

            {/* DATA GROUP ------------------------------------------------------- */}
            <CvtSideBarGroup 
                marginLeft="6px"
            >

                {/* BIG COMPONENT BUTTONS  */}
                {React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { sideBar: false });
                    }
                    return child;
                })}
            </CvtSideBarGroup>
        </CvtSideBar>
    )
}