// PROJECT PHASE
export const CHARTER_LIST_PHASE = [
    {id: "opportunity",value: "opportunity", name: "Opportunity", label: "Opportunity", icon: "alarm"},
    {id: "launch",value: "launch", name: "Launch" , label: "Launch",icon: "alarm"},
    {id: "execution",value: "execution", name: "Execution", label: "Execution",icon: "alarm"},
    {id: "closing", value: "closing",name: "Closing" , label: "Closing",icon: "alarm"},
    {id: "closed", value: "closed",name: "Closed", label: "Closed",icon:"alarm"},
];

//PROJECT TYPE
export const CHARTER_LIST_TYPE = [
    {id: "operational",value:"operational", name: "Operational", label:"Operational", icon: "alarm"},
    {id: "strategic",value:"strategic", name: "Strategic" , label:"Strategic",icon: "alarm"},
    {id: "transformation",value:"transformation", name: "Transformation", label:"Transformation",icon: "alarm"},
];

//IMPACT
export const CHARTER_LIST_IMPACT = [
    {id: "low", name: "Low", icon: "alarm",bgColor:"#00b04f", color:"white"},
    {id: "medium", name: "Medium" , icon: "alarm",bgColor:"#ed7d31", color:"white"},
    {id: "high", name: "High", icon: "alarm",bgColor:"#e55766", color:"white"},
];

//GROUP ITEMS
export const CHARTER_GROUP_ITEMS = [
    {id:1, name:"General", icon:"source"},
    {id:2, name:"Category", icon:"category"},
    {id:3, name:"Details", icon:"manage_search"},
]

//ITEM DATA 
export const CHARTER_ITEM_DATA = [

    // ITEM IDS
    {groupId: -1, key: "id", value: null, type: "id"},
    {groupId: -1, key: "organizationId", value: "", type: "id"},
    {groupId: -1, key: "projectId", value: "", type: "id"},
    {groupId: -1, key: "wbsId", value: "", type: "id"},

    //NO GROUP
    {groupId: 0, key: "leader", value : "", label: "Leader", component:"select", type:"list", options:[], info:"", placeholder:"Who is the leader ?"},
    {groupId: 0, key: "target1", value: "", label: "Target", component:"textArea", type:"text", info:"to be defined", placeholder:""},

    //GENERAL
    {groupId: 1, key: "department", value : "", label: "Department", component:"input", type:"text", info:"", placeholder:"What is the project's department ?"},
    {groupId: 1, key: "type", rowWithNext: true, value: "", label: "Type", component:"select", type:"list", options: CHARTER_LIST_TYPE, info:"to be defined", placeholder:""},
    {groupId: 1, key: "phase", value: "",  label: "Phase", component:"select", type:"list", options: CHARTER_LIST_PHASE, info:"to be defined", placeholder:""},
    {groupId: 1, key: "customer", value: "", label: "Customer", component:"input", type:"text", info:"", placeholder:"Test place holder"},
    {groupId: 1, key: "customerDepartment", value: "", label: "Customer Department",  component:"input", type:"text", info:"", placeholder:""},
    
    // CATEGORY
    {groupId: 2, key: "impact", value: "", label: "Strategic Impact", component:"select", type:"list", options: CHARTER_LIST_IMPACT, info:"to be defined", placeholder:""},
    {groupId: 2, key: "startDate", rowWithNext: true, value: "", label: "Start Date", component:"input", type:"date", info:"", placeholder:""},
    {groupId: 2, key: "endDate", value: "", label: "Finish Date", component:"input", type:"date", info:"", placeholder:""},
    {groupId: 2, key: "teamSize", rowWithNext: true, value: "", label: "Team Size", component:"input", type:"number", unit:"Head Count", info:"to be defined", placeholder:""},
    {groupId: 2, key: "stakeholderSize", value: "", label: "Stakeholder Size", component:"input", type:"number", unit:"Head Count", info:"to be defined", placeholder:""},
    {groupId: 2, key: "budget", value: "", label: "Budget", component:"input", type:"number", unit:"k€", info:"to be defined", placeholder:""},

    // DETAILS
    {groupId: 3, key: "description", value: "", label: "Description", component:"textArea", type:"richTextArea", info:"", placeholder:""},
    {groupId: 3, key: "scope", value: "", label: "Scope", component:"textArea", type:"richTextArea", info:"", placeholder:""},
    {groupId: 3, key: "contextObjectives", value: "", label: "Context / Objectives", component:"textArea", type:"richTextArea", info:"", placeholder:""},
    {groupId: 3, key: "assumptionsConstraints", value: "", label: "Assumptions / Constraints", component:"textArea", type:"richTextArea", info:"", placeholder:""}
];

//INIT STATE
export const CHARTER_INIT_STATE = {
    id: null,
    organizationId: "",
    projectId: "",
    wbsId: "",
    leader: "",
    department: "",
    customer: "",
    customerDepartment: "",
    type: "",
    phase: "",
    startDate: "",
    endDate: "",
    teamSize: "",
    stakeholderSize: "",
    budget: "",
    impact: "",
    description: "",
    scope: "",
    contextObjectives: "",
    assumptionsConstraints: "",
    target1: "",
    target2: ""
};

export const CHARTER_DETAILS_KEY_BLOCK = [
    {key:"description", name:"Description"},
    {key:"scope", name:"Scope"},
    {key:"contextObjectives", name:"Context"},
    {key:"assumptionsConstraints", name:"Assumptions"}
]

//TYPE
export const CHARTER_CATEGORIZATION_TYPE =[
    {
        id: "low", 
        value: "low",
        name:"Bronze", 
        label:"Bronze", 
        little:"B",
        valueArray: [6],
        operator: "inferior",
        color:"var(--color-bronze)"
        
    },
    {
        id: "medium", 
        value: "medium", 
        name:"Silver", 
        label:"Silver",
        little:"S",
        valueArray: [6,12],
        operator: "between",
        color:"var(--color-silver)"
    },
    {
        id: "high", 
        value: "high",
        name:"Gold",
        label:"Gold",  
        little:"G",
        valueArray: [12],
        operator: "superior",
        color:"var(--color-gold)"
    },
]

//COLUMN
export const CHARTER_CATEGORIZATION_COLUMN = [
    {
        id: "low", 
        name:"Low", 
    },
    {
        id: "medium", 
        name:"Medium", 
    },
    {
        id: "high", 
        name:"High", 
    },
]

//PROJECT IMPACT
export const CHARTER_CATEGORIZATION_DETAIL = [
    {
        id: "budget", 
        name: "Budget",
        unit: "k€",
        values:[
            {
                id:"low",
                valueArray: [300],
                operator: "inferior",
                weight: 1
            },
            {
                id:"medium",
                valueArray: [300,1000],
                operator: "between",
                weight: 2
            },
            {
                id:"high",
                valueArray: [1000],
                operator: "superior",
                weight: 3
            },
        ]
    },
    {
        id: "duration", 
        name: "Duration",
        unit: "Year",
        values:[
            {
                id:"low",
                valueArray: [1],
                operator: "inferior",
                weight: 1
            },
            {
                id:"medium",
                valueArray: [1,3],
                operator: "between",
                weight: 2
            },
            {
                id:"high",
                valueArray: [3],
                operator: "superior",
                weight: 3
            },
        ]
    },
    {
        id: "stakeholders", 
        name: "People",
        unit: "Head Count",
        unitLight: "#",
        values:[
            {
                id:"low",
                valueArray: [25],
                operator: "inferior",
                weight: 1
            },
            {
                id:"medium",
                valueArray: [25,75],
                operator: "between",
                weight: 2
            },
            {
                id:"high",
                valueArray: [75],
                operator: "superior",
                weight: 3
            },
        ]
    },
    {
        id: "impact", 
        name:"Strategic Impact",
        unit: null,
        values:[
            {
                id:"low",
                valueArray: ["low"],
                valueName: ["Low"],
                operator: "equal",
                weight: 1
            },
            {
                id:"medium",
                valueArray: ["medium"],
                valueName: ["Medium"],
                operator: "equal",
                weight: 4
            },
            {
                id:"high",
                valueArray: ["high"],
                valueName: ["High"],
                operator: "equal",
                weight: 8
            },
        ]
    },
];
