import { CvtButton, CvtModal, CvtSelect, CvtSelectMultiple, FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import { useCallback, useMemo, useState } from "react";
import { APP_FORM_STYLE } from "../../../../../00-Core/Constants";
import {
    ACTION_DEFAULT_LINKABLE_DOMAINS_ID,
    ACTION_LINKABLE_DOMAINS,
    ACTION_LINKABLE_DOMAINS_KEYS,
} from "../../00-Helpers/ActionsConstants";
import ActionLinksList from "../ActionCard/ActionLinksList";
import {
    FUNC_REMOVE_OBJECT_FROM_ARRAY,
    FUNC_REMOVE_SEVERAL_OBJECTS_FROM_OBJECTS_ARRAY,
} from "../../../../../00-Core/Standards";
import { GOV_FUNC_GET_REVIEW_LABEL } from "../../../04-Governance/00-Helpers/GovFunctions";

export default function ActionLinkModal({ onResetModal, onSaveLinks, projectData }) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // MODAL STATE
    const [state, setState] = useState({
        selectedDomainId: ACTION_DEFAULT_LINKABLE_DOMAINS_ID,
        selectedItems: [],
        addedLinks: [],
        canSave: false,
    });

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // LIST OF AVAILABLE DOMAINS
    const domains = useMemo(() => {
        // GET DOMAINS AND CONVERT FOR SELECT
        return ACTION_LINKABLE_DOMAINS.map((domain) => ({
            id: domain.id,
            name: domain.name,
        }));
    }, []);

    // LIST OF ITEMS BASED ON SELECTED DOMAIN
    const domainItems = useMemo(() => {
        if (!state.selectedDomainId) {
            return [];
        }

        // GET LINK TYPE INFOS
        let linkType = FIND_OBJECT_ARRAY_ITEM(
            ACTION_LINKABLE_DOMAINS,
            "id",
            state.selectedDomainId
        );

        // GET DATA FROM PROJECT DATA
        let data = projectData[linkType.dataTable];

        // KEEP ONLY RISK IF SELECTED (REMOVE OPP)
        if (state.selectedDomainId === ACTION_LINKABLE_DOMAINS_KEYS.risk) {
            data = data.filter((item) => item.isRisk);
        }
        // KEEP ONLY OPPORTUNITIES IF SELECTED (REMOVE RISK)
        else if (state.selectedDomainId === ACTION_LINKABLE_DOMAINS_KEYS.opp) {
            data = data.filter((item) => !item.isRisk);
        }

        // REMOVE ALREADY SELECTED ITEMS
        let filteredData = FUNC_REMOVE_SEVERAL_OBJECTS_FROM_OBJECTS_ARRAY(
            data,
            state.addedLinks,
            "id"
        );

        // CONVERT DATA TO DISPLAY IN SELECT AND KEEP SOME INFOS FOR LATER
        data = filteredData.map((item) => {
            let label = null;
            if (linkType.dataTable === "govReview") {
                label = GOV_FUNC_GET_REVIEW_LABEL(item);
            } else {
                label = item.name || item.title || item.action;
            }

            return {
                value: item.id,
                label: label,
                id: item.id,
                type: linkType.dataTable,
                destinationItemId: item.id,
            };
        });

        return data;
    }, [projectData, state.addedLinks, state.selectedDomainId]);

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // ON CHANGE DOMAIN VALUES
    const onDomainChange = useCallback((e) => {
        // GET KEY AND VALUE
        let { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
            selectedItems: [],
        }));
    }, []);

    // ON ITEM SELECT CHANGE
    const onItemSelectChange = useCallback((newValue) => {
        setState((prevState) => ({ ...prevState, selectedItems: newValue }));
    }, []);

    // ADD SELECTED ITEMS TO GLOBAL LIST
    const onAddSelectedItems = useCallback(() => {
        // ADD NEW LINKS TO LINKS
        setState((prevState) => ({
            ...prevState,
            addedLinks: [...prevState.addedLinks, ...state.selectedItems],
            selectedItems: [],
        }));
    }, [state.selectedItems]);

    // DELETE ITEM FROM SELECTED LINKS
    const onDeleteListItem = useCallback(
        (e, itemId) => {
            // REMOVE ITEM FROM LIST
            let newList = FUNC_REMOVE_OBJECT_FROM_ARRAY(
                state.addedLinks,
                "destinationItemId",
                itemId
            );

            // UPDATE STATE
            setState((prevState) => ({
                ...prevState,
                addedLinks: newList,
            }));
        },
        [state.addedLinks]
    );

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <CvtModal width={700} icon="link" title="Add new dependencies" resetFunction={onResetModal}>
            {/* BODY */}
            <div
                className="modal-body flexColStartStart modalBody"
                style={{ padding: "0px", width: "100%" }}
            >
                {/* SELECTION */}
                <div style={{ width: "100%", padding: "15px 15px 0px 15px" }}>
                    {/* DOMAIN SELECTION */}
                    <CvtSelect
                        selectBlockCss="flexStartCenter modalGroup"
                        selectFontSize={APP_FORM_STYLE.fontSize}
                        selectBlockMargin="0px 0px 15px 0px"
                        icon="category"
                        legend="Domain"
                        legendWidth="150px"
                        name="selectedDomainId"
                        value={state.selectedDomain}
                        listValue="id"
                        listName="name"
                        list={domains}
                        onChange={onDomainChange}
                    />

                    {/* ITEM SELECTION */}
                    <div
                        className="flexStartStart"
                        style={{
                            width: "100%",
                        }}
                    >
                        {/* ITEMS SELECT */}
                        <CvtSelectMultiple
                            selectFontSize={APP_FORM_STYLE.fontSize}
                            selectBlockPadding="0px 0px 15px 0px"
                            placeholder={"Select items to link"}
                            name={"selectedItems"}
                            legendTxt={"Items"}
                            legendWidth="150px"
                            value={state.selectedItems}
                            options={domainItems}
                            icon="list"
                            onChange={onItemSelectChange}
                            closeOnSelect={state.selectedItems.length === domainItems.length - 1}
                        />

                        {/* BUTTON TO ADD SELECTED ITEMS TO LIST */}
                        <CvtButton
                            color="gray"
                            icon="add_link"
                            shadow="none"
                            margin={"2px 0px 0px 15px"}
                            tooltip
                            tooltipTxt={"Add these items"}
                            tooltipTheme="dark"
                            type="button"
                            txt="Add to list"
                            disabled={state.selectedItems.length === 0}
                            handleSubmit={onAddSelectedItems}
                        />
                    </div>
                </div>

                {/* ADDED LINKS LIST */}
                <span
                    className="modalInputLegend"
                    style={{
                        paddingLeft: "15px",
                    }}
                >
                    Selected dependencies{" "}
                    {state.addedLinks.length > 0 ? `(${state.addedLinks.length})` : ""}
                </span>
                <div
                    style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        width: "100%",
                        marginTop: "5px",
                        marginBottom: "5px",
                    }}
                >
                    <ActionLinksList
                        links={state.addedLinks}
                        projectData={projectData}
                        onDeleteListItem={onDeleteListItem}
                    />
                </div>
            </div>

            {/* FOOTER */}
            <div className="modal-footer modalBlockBg flexEndCenter">
                {/* CANCEL BUTTON */}
                <CvtButton
                    color="gray"
                    txt="Cancel"
                    type="button"
                    handleSubmit={onResetModal}
                    needConfirmation={state.addedLinks.length !== 0}
                />

                {/* SAVE LINKS BUTTON */}
                <CvtButton
                    color="blue"
                    txt="Save"
                    type="button"
                    handleSubmit={onSaveLinks}
                    handleSubmitOptions={state.addedLinks}
                    disabled={state.addedLinks.length === 0 || state.selectedItems.length !== 0}
                />
            </div>
        </CvtModal>
    );
}
