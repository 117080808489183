////////////////////////////////////////////////////
//__________IMPORT___________//////////////////////
///////////////////////////////////////////////////
//PACKAGES
import React, { memo, useMemo } from "react";
import _ from 'lodash'

//COMPONENT
import { CHARTER_FUNC_CATEGORIZATION } from "./00-Helpers/CharterFunctions";
import CharterBigCategory from "./01-Components/CharterBigCategory";
import { FUNCT_FIND_INDEX, FUNC_CHECK_DATE2_IS_AFTER_DATE1 } from "../../../00-Core/Standards";
import { CHARTER_LIST_IMPACT } from "./00-Helpers/CharterConstants";
import { CvtTooltip } from "@mi-gso/cvt";

import 'react-tooltip/dist/react-tooltip.css';


//////////////////////////////
/// CHARTER MINI COMPONENT ///
//////////////////////////////

export default memo(
    function CharterMini({
        charterData,
        charterUpdate,
        charterDataImpact,
        charterDataTeamSize,
        charterDataStakeholderSize,
        charterDataBudget,
        itemId,
        fieldId,
        propKey,
        handleBigComponent,
        displayInBigComponent,
        updateTracker,
    }){

    ////////////////////////////////////////////////////////
    /// DATA ///////////////////////////////////////////////
    ////////////////////////////////////////////////////////

    // GET CHARTER CATEGORIZATION LETTER (S, M, L)
    const charterCategorization = useMemo(() => {

        if (updateTracker) {
            // JUST TO TRIGGER THE MEMO
        }

        //TEST IF ITS GOOD
        if(charterData){

            //INIT
            let newData = _.cloneDeep(charterData);

            //TETS DATE
            if(newData.startDate !== "" && Object.prototype.toString.call(newData.startDate) !== "[object Date]"){
                newData.startDate = new Date(newData.startDate)
            }

            //TEST DATE
            if(newData.endDate !== "" && Object.prototype.toString.call(newData.endDate) !== "[object Date]"){
                newData.endDate = new Date(newData.endDate)
            }

            if ((newData.startDate !== "" && isNaN(newData.startDate.getTime()))
            || (newData.endDate !== "" && isNaN(newData.endDate.getTime()))) {
                return null
            }

            //IF DATA AVAILABLE
            if(newData
            && newData.impact
            && newData.teamSize
            && newData.teamSize !==""
            && newData.teamSize > 0
            && newData.startDate
            && newData.endDate
            && newData.stakeholderSize
            && newData.stakeholderSize !==""
            && newData.stakeholderSize > 0
            && newData.budget
            && newData.budget !==""
            && newData.budget > 0
            && charterUpdate
            ){

                //CALCULATE CATEGORY
                return CHARTER_FUNC_CATEGORIZATION({
                    dates:{
                        startDate: newData.startDate,
                        endDate: newData.endDate,
                    },
                    inputs:{
                        budget: newData.budget,
                        stakeholders: newData.stakeholderSize + newData.teamSize,
                        impact: newData.impact
                    }
                });
            }
        }

        //IF EMPTY
        return null
    }, [charterData, charterUpdate, updateTracker])

    //COUNT NUMBER OF DAYS
    const numberOfDaysBetweenDates = useMemo(()=>{

        if (updateTracker) {
            // JUST TO TRIGGER THE MEMO
        }

        if(charterData && charterUpdate && charterData.endDate !== ""){

            //INIT
            let newData = _.cloneDeep(charterData);
            let today = new Date()
            today.setHours(0, 0, 0, 0);

            //TEST DATE
            if(Object.prototype.toString.call(newData.endDate) !== "[object Date]"){
                newData.endDate = new Date(newData.endDate)
            }

            if(isNaN(newData.endDate.getTime())) {
                return null
            }

            //CALCULATE
            let dateTimeDiff = newData.endDate.getTime() - today.getTime();
            let dateTimeDiffInDays = dateTimeDiff / (1000 * 3600 * 24);
            return Math.round(dateTimeDiffInDays);
        }
        return null
    },[charterData, charterUpdate, updateTracker])

    //IMPACT
    const categoryImpactDetails = useMemo(()=>{
        if(charterDataImpact){
            let findIndex = FUNCT_FIND_INDEX(CHARTER_LIST_IMPACT, 'id', charterDataImpact)
            if(findIndex > -1){
                return CHARTER_LIST_IMPACT[findIndex]
            }
        }
        return null
    },[charterDataImpact])

    //TEAM SIZE
    const teamSize = useMemo(()=>{
       if(charterDataTeamSize && charterDataTeamSize !== "" && charterDataTeamSize > 0 ){
         return charterDataTeamSize
       }
       return null
    },[charterDataTeamSize])

    //TEAM SIZE
    const stakeholderSize = useMemo(()=>{
        if(charterDataStakeholderSize && charterDataStakeholderSize !== "" && charterDataStakeholderSize > 0 ){
            return charterDataStakeholderSize
        }
        return null
    },[charterDataStakeholderSize])

    //TEAM SIZE
    const budget = useMemo(()=>{
        if(charterDataBudget && charterDataBudget !== "" && charterDataBudget > 0 ){
            return new Intl.NumberFormat().format(charterDataBudget)
        }
        return null
    },[charterDataBudget])

    ///////////////////////
    /// TOOLTIP CONTENT ///
    ///////////////////////

    const tooltipContent = useMemo(()=>{
        if (updateTracker) {
            // JUST TO TRIGGER THE MEMO
        }

        return(
            <div className="flexColStartStart">

                {/* OPEN */}
                <span className="actionMiniTooltipTitle">
                    Category:
                    {charterCategorization ?
                        <b style={{marginLeft: "5px"}}>
                            {charterCategorization.name}
                        </b>
                        :
                        <b style={{marginLeft: "5px"}}>
                            Not defined
                        </b>
                    }
                </span>

                {/* DURATION */}
                <span className="actionMiniTooltipSubTitle">
                    Duration: <b></b>
                    {numberOfDaysBetweenDates ?
                        <b style={{marginLeft: "5px"}}>
                            {numberOfDaysBetweenDates} days
                        </b>
                        :
                        <b style={{marginLeft: "5px"}}>
                            Not defined
                        </b>
                    }
                </span>

                {/* STRATEGIC IMPACT */}
                <span className="actionMiniTooltipSubTitle">
                    Strategic Impact:
                    {categoryImpactDetails ?
                        <b style={{marginLeft: "5px"}}>
                            {categoryImpactDetails.name}
                        </b>
                        :
                        <b style={{marginLeft: "5px"}}>
                            Not defined
                        </b>
                    }
                </span>

                {/* TEAM */}
                <span className="actionMiniTooltipSubTitle">
                    Team:
                    {teamSize ?
                        <b style={{marginLeft: "5px"}}>
                            {teamSize} people
                        </b>
                        :
                        <b style={{marginLeft: "5px"}}>
                            Not defined
                        </b>
                    }
                </span>

                {/* STAKEHOLDER */}
                <span className="actionMiniTooltipSubTitle">
                    Stakeholder:
                    {stakeholderSize ?
                        <b style={{marginLeft: "5px"}}>
                            {stakeholderSize} people
                        </b>
                        :
                        <b style={{marginLeft: "5px"}}>
                            Not defined
                        </b>
                    }
                </span>

                {/* BUDGET */}
                <span className="actionMiniTooltipSubTitle">
                    Budget:
                    {budget ?
                        <b style={{marginLeft: "5px"}}>
                            {budget} k€
                        </b>
                        :
                        <b style={{marginLeft: "5px"}}>
                            Not defined
                        </b>
                    }
                </span>
            </div>
        )
    },[budget, categoryImpactDetails, charterCategorization, numberOfDaysBetweenDates, stakeholderSize, teamSize, updateTracker])

    ////////////////////////////////////////////////////////
    /// RENDER COMPONENT ///////////////////////////////////
    ////////////////////////////////////////////////////////

    return (
        <div
            className="flexStartStart"
            onClick={displayInBigComponent ? null : (e) => handleBigComponent(e,itemId,fieldId)}
            id={propKey}
            style={{
                cursor:displayInBigComponent ? "default" : "pointer",
                marginRight:displayInBigComponent ? null : "10px",
            }}

        >


            {/* WE HAVE CATEGORY ------------------------------------------ */}
            {charterCategorization ?
                <div
                    className="charterProgressBar"
                    style={{
                        background: `radial-gradient(closest-side, white ${displayInBigComponent ? '85%' : '70%'}, ${FUNC_CHECK_DATE2_IS_AFTER_DATE1(charterData.endDate, new Date()) ? "var(--color-bad)" : "transparent"} 0 99.9%, white 0), conic-gradient(${charterCategorization.color} calc(${charterCategorization.percent} * 1%), #e6e6e6 0)`,
                        height: displayInBigComponent ? "110px" : null,
                        width: displayInBigComponent ? "110px" : null,
                        cursor: displayInBigComponent ? "default" : null,
                        color:charterCategorization.color,
                        position:"relative"
                    }}
                >


                    {/* NUMBER OF DAYS */}
                    {displayInBigComponent ?
                        <div
                            className="flexColCenterCenter"
                            style={{
                                position: "absolute",
                                top:"-5px",
                                left:"-8px",
                                backgroundColor:"rgba(3, 169, 219, 0.5)",
                                color:"white",
                                height:"35px",
                                width:"35px",
                                borderRadius:"20px",
                                border:"1px solid white",
                                boxShadow:"0px 1px 4px 0px rgba(0, 0, 0, 0.3)",
                                fontWeight:600
                            }}
                            id="category-nb-days"
                        >

                            {numberOfDaysBetweenDates}

                            <span
                                style={{
                                    opacity:0.7,
                                    marginTop:"-5px",
                                    fontWeight:400,
                                    fontSize:"10px"
                                }}
                            >
                                days
                            </span>
                            <CvtTooltip
                                anchorId="category-nb-days"
                                content="Number of days remaining"
                            />
                        </div>
                    :null}


                    <span
                        style={{
                            fontSize:displayInBigComponent ? "11px" : "11px",
                            fontWeight:displayInBigComponent ? 700 : null,
                        }}
                    >
                        {displayInBigComponent ?
                            <div
                                className="flexColStartCenter"
                                style={{
                                    position:"relative"
                                }}
                            >

                                {/* DATES */}
                                <div
                                    style={{
                                        color:"var(--color-text-3)",
                                        backgroundColor:"var(--border-color-gray-00)",
                                        border:"1px solid var(--border-color-gray-00-5)",
                                        opacity:0.9,
                                        padding:"2px 5px",
                                        borderRadius:"10px",
                                        marginBottom:"14px"
                                    }}
                                >
                                    {charterData.startDate}
                                </div>
                                <div
                                    className="material-icons"
                                    style={{
                                        color:"var(--color-text-3)",
                                        position:"absolute",
                                        top:"calc(50% - 9px)",
                                        fontSize:"18px",
                                        borderRadius:"10px"
                                    }}
                                >
                                    expand_more
                                </div>
                                <div
                                    style={{
                                        color:"var(--color-text-3)",
                                        backgroundColor:"var(--border-color-gray-00)",
                                        border:"1px solid var(--border-color-gray-00-5)",
                                        opacity:0.9,
                                        padding:"2px 5px",
                                        borderRadius:"10px"
                                    }}
                                >
                                    {charterData.endDate}
                                </div>
                            </div>
                        :
                            charterCategorization.little
                        }
                    </span>
                </div>
            :
                <div
                    className="charterProgressBar"
                    style={{
                        height: displayInBigComponent ? "90px" : null,
                        width: displayInBigComponent ? "90px" : null,
                        cursor: displayInBigComponent ? "default" : null,
                        background:  "radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0), conic-gradient(white calc(0%), var(--border-color-gray-0) 0)",
                    }}
                >
                    <span
                        className="material-icons"
                        style={{
                            fontSize:displayInBigComponent ? "28px" : "16px",
                        }}
                    >
                        question_mark
                    </span>
                </div>
            }

            {/* SPECIFIC IN BIG COMPONENT ---------------------------------- */}
            {displayInBigComponent ?
                <div style={{marginLeft:"10px"}}>

                    {/* TITLE */}
                    <div className="flexColStartStart">

                        {/* LEGEND */}
                        <div className="charterCardTitleLegend">
                            Category
                        </div>

                        {/* CATEGORY */}
                        {charterCategorization ?
                            <div
                                className="flexStartCenter charterCardCalcul"
                                style={{
                                    color: charterCategorization ? charterCategorization.color : null,
                                }}
                            >
                                <span className="material-icons">
                                    workspace_premium
                                </span>
                                {charterCategorization.name}
                            </div>
                        :
                            <div
                                className="flexStartCenter commonEmptyTextLine"
                                style={{
                                    margin:"10px 0px 2px 0px"
                                }}
                            >
                                Calculation not possible, field(s) missing
                            </div>
                        }

                        {/* SIZE & BUDGET*/}
                        <div
                            className="flexStartCenter"
                            style={{
                                marginTop:"5px"
                            }}
                        >

                            {/* TEAM SIZE */}
                            <CharterBigCategory
                                borderColor={teamSize ? "var(--border-color-gray-0-5)" : "var(--border-color-gray-0)"}
                                backgroundColor="white"
                                leftTextColor="var(--color-text-2)"
                                leftIcon={teamSize ? "people" :null}
                                rightDotTextColor="white"
                                rightDotBackgroundColor={teamSize ? "rgba(3, 169, 219, 0.5)" : "var(--border-color-gray-0)"}
                                rightDotBorder="1px solid var(--border-color-gray-0)"
                                rightDotText={teamSize ? teamSize : "?"}
                                width={teamSize ? null : "60px"}
                                rightDotWidth={teamSize ? "auto" : null}
                                marginRight="8px"
                                tooltip
                                tooltipId="teamsize-tooltip"
                                tooltipContent={teamSize? "Team Size" : "Team Size (missing value)"}
                            />

                            <CharterBigCategory
                                borderColor={stakeholderSize ? "var(--border-color-gray-0-5)" : "var(--border-color-gray-0)"}
                                backgroundColor="white"
                                leftTextColor="var(--color-text-2)"
                                leftIcon={stakeholderSize ? "hub" :null}
                                leftIconSize="18px"
                                rightDotTextColor="white"
                                rightDotBackgroundColor={stakeholderSize ? "rgba(3, 169, 219, 0.5)" : "var(--border-color-gray-0)"}
                                rightDotBorder="1px solid var(--border-color-gray-0)"
                                rightDotText={stakeholderSize ? stakeholderSize : "?"}
                                width={stakeholderSize ? null : "60px"}
                                rightDotWidth={stakeholderSize ? "auto" : null}
                                marginRight="8px"
                                tooltip
                                tooltipId="stakeholder-tooltip"
                                tooltipContent={stakeholderSize ? "Stakeholders Size" : "Stakeholders Size (missing value)"}
                            />

                            <CharterBigCategory
                                borderColor={budget ? "var(--border-color-gray-0-5)" : "var(--border-color-gray-0)"}
                                backgroundColor="white"
                                leftTextColor="var(--color-text-2)"
                                leftIcon={budget ? "euro_symbol" :null}
                                leftIconSize="20px"
                                rightDotTextColor="white"
                                rightDotBackgroundColor={budget ? "rgba(3, 169, 219, 0.5)" : "var(--border-color-gray-0)"}
                                rightDotBorder="1px solid var(--border-color-gray-0)"
                                rightDotText={budget ? budget + " k€" : "?"}
                                width={budget ? null : "60px"}
                                rightDotWidth={budget ? "auto" : null}
                                tooltip
                                tooltipId="budget-tooltip"
                                tooltipContent={budget ? "Budget" : "Budget (missing value)"}
                            />

                        </div>

                        {/* STRATEGIC IMPACT */}
                        {categoryImpactDetails ?
                            <div
                                className="flexStartCenter"
                                style={{
                                    marginTop:"8px",
                                    fontWeight:500
                                }}
                            >

                                <span
                                    className="commonTagBlock"
                                    style={{
                                        marginRight:"5px",
                                        backgroundColor:categoryImpactDetails.bgColor,
                                        color:categoryImpactDetails.color
                                    }}
                                >
                                    {categoryImpactDetails.name}
                                </span>
                                Strategic Impact
                            </div>
                        :
                            <div
                                className="commonEmptyTextLine flexStartCenter"
                                style={{
                                    width:"75%",
                                    marginTop:"8px"
                                }}
                            >
                                Strategic impact missing
                            </div>
                        }
                    </div>
                </div>
            :null}

            {/*MINI COMPONENT TOOLTIP */}
            {!displayInBigComponent ?
                <CvtTooltip
                    anchorId={propKey}
                >
                    {tooltipContent}
                </CvtTooltip>
            : null}
        </div>
    )}
);
