import React from 'react'
import { CvtButton } from '@mi-gso/cvt'

//CONFIRM BEFORE SAVE
export default function EditSideBarConfirm({
    confirmY,
    confirmX,
    confirmType,
    canSave,
    handleSave,
    resetEditSideBar,
}) {
  return (
    <div
        className='rightSideBarConfirmBlock'
        style={{
            top: confirmY,
            left: confirmX,
        }}
    >
        {confirmType === 1 ?
            <React.Fragment>
                <div className='flexStartCenter rightSideBarConfirmBlockTitle'>
                    <span
                        className="material-icons"
                        style={{
                            marginRight:"5px",
                            color:"var(--color-text-1)"
                        }}
                    >
                        warning
                    </span>
                    Updates Detected
                </div>
                <div className='flexStartCenter'>

                    {/* SUBMIT */}
                    <CvtButton
                        color="orange"
                        icon="save"
                        txt="Save"
                        padding="4px 10px"
                        margin="0px 10px 0px 0px"
                        handleSubmit={handleSave}
                        disabled={!canSave}
                    />

                    {/* DONT SAVE */}
                    <CvtButton
                        color="gray"
                        icon="layers_clear"
                        txt="Don't Save ?"
                        handleSubmit={resetEditSideBar}
                    />
                </div>

            </React.Fragment>
        :
            <React.Fragment>
                <div className='flexStartCenter rightSideBarConfirmBlockTitle'>
                    <span
                        className="material-icons"
                        style={{
                            marginRight:"5px",
                            color:"var(--color-text-1)"
                        }}
                    >
                        warning
                    </span>
                    Updates Detected with Issue(s)
                </div>
                <div className='flexStartCenter'>

                    {/* SUBMIT */}
                    <CvtButton
                        color="orange"
                        icon="save"
                        disabled
                        txt="Fix before save"
                        margin="0px 10px 0px 0px"
                        handleSubmit={handleSave}
                    />

                    {/* DONT SAVE */}
                    <CvtButton
                        color="gray"
                        icon="layers_clear"
                        txt="Don't Save ?"
                        handleSubmit={resetEditSideBar}
                    />
                </div>
            </React.Fragment>
        }
    </div>
  )
}
