import _ from "lodash";
import { FUNC_DATE_TO_TXT_STANDARD, FUNC_GET_USER_INFO } from "../../../../00-Core/Standards";
import {
    RISKS_OPPS_MATRIX_SETTINGS,
    RISK_OPP_EXPAND_MODES,
    RO_DEFAULT_ITEM,
    RO_DEFAULT_STATE,
} from "./RiskConstants";
import { v4 as uuid } from "uuid";
import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";

// GET DEFAULT RO ITEM
export function RO_GET_DEFAULT_ITEM(selectedOrganizationId, wbsId, isRisk, userId) {
    let item = _.cloneDeep(RO_DEFAULT_ITEM);

    item.organizationId = selectedOrganizationId;
    item.projectId = wbsId;
    item.wbsId = wbsId;
    item.isRisk = isRisk;
    item.responsible = userId;

    return item;
}

// GE DEFAULT RO STATE
export function RO_GET_DEFAULT_STATE(
        isReviewMode, 
        wbsNavigationOptions, 
        riskOpp, 
        isRisk,
        appDispatcher,
        wbsDispatcher,
    ) {

    let initialState = _.cloneDeep(RO_DEFAULT_STATE);

    // IF REVIEW MODE OPEN, DISPLAY ONLY TABLE
    if (isReviewMode) {
        initialState.expandMode = RISK_OPP_EXPAND_MODES.tableOnly;
    } else {
        // MANAGE LIMIT SIZE FOR TO COLUMN IN BIG COMPONENT
        initialState.widthSizeLimit = window.innerWidth <= 1200;
    }

    if (wbsNavigationOptions?.goToItemId) {

        const filteredRo = riskOpp.filter((ro) => ro.isRisk === isRisk);

        // FIND THE ITEM
        let foundRo = FIND_OBJECT_ARRAY_ITEM(filteredRo, "id", wbsNavigationOptions.goToItemId);
   
        if(foundRo) {
            initialState.viewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
            initialState.riskOppViewItem = foundRo;
        }
    }

    return initialState;
}

// GIVEN IMPACT AND PROBABILITY, GET THE CRITICITY
export const getCriticityFromImpProba = (impact, probability) => {
    return RISKS_OPPS_MATRIX_SETTINGS.find(
        (matrixSetting) =>
            matrixSetting.impact === impact && matrixSetting.probability === probability
    ).criticity;
};

// GIVEN CRITICITY, RETURN OBJECT WITH IMPACT AND PROBABILITYU (like this : {impact: blablalal, probability: blalbnla})
export const getImpactProbability = (criticity) => {
    let matrixSetting = RISKS_OPPS_MATRIX_SETTINGS.find(
        (matrixSetting) => matrixSetting.criticity === criticity
    );

    return {
        probability: matrixSetting.probability,
        impact: matrixSetting.impact,
    };
};

// PROCESS RISK ITEM AFTER CREATING/EDITING
export const processRiskItem = (
    stateItems,
    riskItem,
    isCreating,
    currentUser,
    parentId,
    wbsId,
    updatedFields,
    // COMMENT ID (IF UPDATING ONE)
    commentId
) => {
    let processedRiskItem = _.cloneDeep(riskItem);

    // HANDLE THE EVENTS, CRITICITY CALCULATION AND ESCALATED.
    if (!!isCreating) {
        if (processedRiskItem.actionsIds && processedRiskItem.actionsIds.length > 0) {
            processedRiskItem.telescopeDataAction = processedRiskItem.actionsIds;
            processedRiskItem.actionsIds = processedRiskItem.actionsIds.map((action) => action.id);
        }

        // EVENTS
        processedRiskItem.events = JSON.stringify([
            {
                user: {
                    id: currentUser.username,
                    label: currentUser.name,
                },
                createdOn: new Date(),
                value: `Created by ${currentUser.name}`,
            },
        ]);

        // CRITICITY CALCULATION---------------

        // INITIAL
        processedRiskItem.initialCriticity = getCriticityFromImpProba(
            processedRiskItem.initialImpact,
            processedRiskItem.initialProba
        );
        // CURRENT
        processedRiskItem.currentCriticity = getCriticityFromImpProba(
            processedRiskItem.initialImpact,
            processedRiskItem.initialProba
        );
        // TARGET
        processedRiskItem.targetCriticity = getCriticityFromImpProba(
            processedRiskItem.targetImpact,
            processedRiskItem.targetProba
        );
        // IF END
        if (processedRiskItem.endImpact && processedRiskItem.endProba) {
            processedRiskItem.endCriticity = getCriticityFromImpProba(
                processedRiskItem.endImpact,
                processedRiskItem.endProba
            );
        }
        //------------------------------

        // ESCALATED--------------------
        if (processedRiskItem.escalatedTo === true) {
            processedRiskItem.escalatedTo = [parentId];
            processedRiskItem.escalatedFrom = wbsId;
        } else {
            // FALSE TO NULL FOR GRAPHQL SAVING
            processedRiskItem.escalatedTo = null;
        }

        // COMMENTS
        if (processRiskItem.comments) {
            processRiskItem.comments = JSON.stringify([
                {
                    id: uuid(),
                    user: {
                        value: currentUser.username,
                        label: currentUser.name,
                    },
                    createdOn: new Date(),
                    value: processRiskItem.comments,
                },
            ]);
        }
        //------------------------------
    }
    // CREATE NEW EVENTS FOR EACH UPDATED FIELD
    else {
        let newEvents = processedRiskItem.events ? JSON.parse(processedRiskItem.events) : [];

        for (let updatedField of updatedFields) {
            // EVENT
            newEvents.push({
                user: {
                    id: currentUser.username,
                    label: currentUser.name,
                },
                createdOn: new Date(),
                value: `${updatedField} updated by ${currentUser.name}`,
            });
            // --------------

            // CRITICITY
            if (updatedField === "initialProba" || updatedField === "initialImpact") {
                processedRiskItem.initialCriticity = getCriticityFromImpProba(
                    processedRiskItem.initialImpact,
                    processedRiskItem.initialProba
                );
            }
            if (updatedField === "targetProba" || updatedField === "targetImpact") {
                processedRiskItem.targetCriticity = getCriticityFromImpProba(
                    processedRiskItem.targetImpact,
                    processedRiskItem.targetProba
                );
            }
            if (
                (updatedField === "endProba" || updatedField === "endImpact") &&
                processedRiskItem.endImpact &&
                processedRiskItem.endProba
            ) {
                processedRiskItem.endCriticity = getCriticityFromImpProba(
                    processedRiskItem.endImpact,
                    processedRiskItem.endProba
                );
            }

            if (updatedField === "escalatedTo") {
                // ESCALATED
                if (processedRiskItem.escalatedTo === true) {
                    let oldEscalatedTo = stateItems.find(
                        (roItem) => roItem.id === riskItem.id
                    ).escalatedTo;
                    // ADD TO ESCALATED TO
                    if (oldEscalatedTo) {
                        oldEscalatedTo.push(parentId);
                    } else {
                        oldEscalatedTo = [parentId];
                        // IF ESCALATED FROM WAS NULL, THEN MAKE IT BE THE WBS ID.
                        processedRiskItem.escalatedFrom = wbsId;
                    }

                    processedRiskItem.escalatedTo = oldEscalatedTo;
                } else {
                    let oldEscalatedTo = stateItems.find(
                        (roItem) => roItem.id === riskItem.id
                    ).escalatedTo;

                    // REMOVE FROM ESCALATED TO
                    oldEscalatedTo = oldEscalatedTo
                        ? oldEscalatedTo.filter((projectItemId) => projectItemId !== parentId)
                        : [];

                    // IF ESCALATED TO IS EMPTY AFTER REMOVING, THEN MAKE IT NULL.
                    if (oldEscalatedTo.length === 0) {
                        oldEscalatedTo = null;

                        // IF WBS ID === processedRiskItem.escalatedFrom then make it null again.
                        if (processedRiskItem.escalatedFrom === wbsId) {
                            processedRiskItem.escalatedFrom = null;
                        }
                    }

                    processedRiskItem.escalatedTo = oldEscalatedTo;
                }
            }

            // IF UPDATED FIELD IS COMMENTS
            if (updatedField === "comments") {
                let existingRo = stateItems.find((ro) => ro.id === riskItem.id);

                let newComments = existingRo.comments ? JSON.parse(existingRo.comments) : [];

                // IF EXISTING ENTRY, THEN REPLACE
                if (
                    newComments.length > 0 &&
                    newComments.find((commentEntry) =>
                        commentId ? commentEntry.id === commentId : false
                    )
                ) {
                    let index = newComments.findIndex(
                        (commentEntry) => commentEntry.id === commentId
                    );

                    newComments[index] = {
                        ...newComments[index],
                        value: processedRiskItem.comments,
                    };
                }
                // ELSE JUST ADD TO COMMENTS
                else {
                    newComments.push({
                        id: uuid(),
                        user: {
                            value: currentUser.username,
                            label: currentUser.name,
                        },
                        createdOn: new Date(),
                        value: riskItem.comments,
                    });
                }

                processedRiskItem.comments = JSON.stringify(newComments);
            }

            // UPDATE ACTIONS IDS
            if (
                updatedField === "actionsIds" &&
                processedRiskItem.actionsIds &&
                processedRiskItem.actionsIds.length > 0
            ) {
                processedRiskItem.actionsIds = processedRiskItem.actionsIds.map((action) =>
                    typeof action !== "string" ? action.id : action
                );
            }
        }

        // SET NEW EVENTS
        processedRiskItem.events = JSON.stringify(newEvents);

        // SET NEW MODIFIED DATe
        processedRiskItem.modifiedDate = FUNC_DATE_TO_TXT_STANDARD(new Date());
    }

    // LAST FIXES FOR GRAPHQL SAVING
    delete processedRiskItem.createdAt;
    delete processedRiskItem.updatedAt;
    delete processedRiskItem.telescopeDataAction;

    processedRiskItem.raisedDate = FUNC_DATE_TO_TXT_STANDARD(
        new Date(processedRiskItem.raisedDate)
    );
    processedRiskItem.createdDate = FUNC_DATE_TO_TXT_STANDARD(
        new Date(processedRiskItem.createdDate)
    );

    return processedRiskItem;
};

// FILTER OUT RO ITEMS ACCORDING TO EXISTING(OR NOT) FILTERS
export const filterRoItems = (
    usersList,
    megaUsersId,
    roItems,
    textSearchInput,
    roStatusSearchFilter,
    isConsolidated
) => {
    let returnRoItems = _.cloneDeep(roItems);

    // GET COUNT OF OPEN RO ITEMS
    let openRo = roItems.reduce((previousCount, currentRoItem) => {
        if (currentRoItem.status === "opened") {
            return previousCount + 1;
        }
        return previousCount;
    }, 0);

    returnRoItems = returnRoItems.filter((roItem) => {
        let isPassTextInput = true;

        if (textSearchInput && textSearchInput.length > 0) {
            let userInfo = FUNC_GET_USER_INFO(usersList, megaUsersId, roItem.responsible);

            isPassTextInput =
                roItem.name.toLowerCase()?.includes(textSearchInput.toLowerCase()) ||
                userInfo.label.toLowerCase()?.includes(textSearchInput.toLowerCase()) ||
                (roItem.description &&
                    roItem.description.length > 0 &&
                    roItem.description.toLowerCase()?.includes(textSearchInput.toLowerCase()));
        }

        // IF NO STATUS FILTERS, ONLY DISPLAY OPEN RO ITEMS, OTHERWISE DISPLAY ACCORDING TO THE STATUS FILTERS CHOSEN
        let isPassStatusFilter;

        if (roStatusSearchFilter.length > 0) {
            isPassStatusFilter = roStatusSearchFilter?.includes(roItem.status);
        } else if (openRo === 0) {
            // LET ANY RO ITEM PASS
            isPassStatusFilter = true;
        } else {
            // IF OPEN RO COUNT IS BIGGER THAN 0, THEN SHOW ONLY OPENED RO ITEMS
            isPassStatusFilter = roItem.status === "opened";
        }

        return isPassStatusFilter === true && isPassTextInput === true;
    });

    return returnRoItems;
};

// GIVEN CURRENT USER ID AND RO ITEM, RETURN TRUE IF HE CAN MAKE CHANGES TO THAT RO ITEM
export const canUserEditRoItem = (
    // PROPERTY FROM SECURITY GROUP
    isCurrentUserEditor,
    // USER ID TO TEST
    userId,
    // RO ITEM
    roItem
) => {
    // IF  CURRENT USER IS EDITOR AND EITHER THIS RO ITEM WAS CREATED BY HIM OR
    // HE IS THE RESPONSIBLE ASSIGNED FOR THIS RO ITEM.
    return isCurrentUserEditor || roItem.createdBy === userId || roItem.responsible === userId;
};

// // RE-ORGANIZE AN ARRAY GIVEN THE MATRIX SIZE
// export const REORGANIZE_MATRIX_ARRAY = (
//     matrixSize,
// ) => {

//     let arrayToConcat = [];

//     let currentArray = [];
//     for(let num of [...Array(matrixSize * matrixSize).keys()].map((i) => i+1)) {

//         currentArray.push(num);

//         if(num % matrixSize === 0) {
//             arrayToConcat.push(currentArray);
//             currentArray = [];
//         }
//     }
//     console.log("Array: ", arrayToConcat);
//     let returnArray = [];

//     for(let i=0, len = arrayToConcat.length; i<len;i++) {

//     }
// }
