import _ from "lodash";
import { APP_TXT_MEGA_USERS, TODAY } from "../../../../00-Core/Constants";
import { FUNCT_FIND_INDEX, FUNC_BUILD_DISPLAY_ID_STR, FUNC_DATE_TO_TXT_STANDARD, FUNC_IS_DATE1_ONE_MONTH_BEFORE_DATE2, FUNC_ZERO_FORMAT_TO_NUM } from "../../../../00-Core/Standards";
import {
    FIELDS_ANALYSIS,
    SCHEDULE_COMPONENT_EXPAND_TYPES,
    SCHEDULE_DATE_ATTRIBUTES,
    SCHEDULE_DEFAULT_ITEM,
    SCHEDULE_DEFAULT_STATE,
    SCHEDULE_DISPLAY_ID_STR,
    SCHEDULE_REQUIRED_ATTRIBUTES,
    SCHEDULE_STATUS,
    SCHEDULE_STATUS_KEYS,
    SCHEDULE_TYPE_KEYS,
} from "./ScheduleConstants";
import { v4 as uuid } from "uuid";
import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";

// GET NEXT DISPLAY ID FOR SCOPE CHANGES AND REVIEWS
export function SCHEDULE_FUNC_GET_NEXT_DISPLAY_ID(items, startStr) {
    // GET ALL DISPLAY IDS NUMBERS
    let allDisplayIds = items.map((item) => {
        let strId = item.displayId.split(startStr)[1];
        return parseInt(strId);
    });

    let lastDisplayId = 0;

    // SELECT THE HIGHER ID
    if (allDisplayIds.length > 0) {
        lastDisplayId = Math.max(...allDisplayIds);
    }

    // RETURN HIGHER ID + 1
    return startStr + FUNC_ZERO_FORMAT_TO_NUM(lastDisplayId + 1, 4);
}

// CALCULATE SCHEDULE ITEM STATUS AND DUE DATE
export function SCHEDULE_FUNC_GET_ITEM_STATUS_AND_DUEDATE(scheduleItem) {
    /////////////////// CALCULATE STATUS ////////////////////
    let status = null;
    let today = TODAY;

    // DONE
    if (scheduleItem.actualFinish && scheduleItem.actualFinish <= today) {
        status = SCHEDULE_STATUS_KEYS.done;
    }
    // IN PROGRESS
    else if (
        scheduleItem.type !== SCHEDULE_TYPE_KEYS.milestone &&
        ((!scheduleItem.actualFinish &&
            scheduleItem.forecastStart <= today &&
            today <= scheduleItem.forecastFinish) ||
            (scheduleItem.actualFinish && scheduleItem.actualFinish > today))
    ) {
        status = SCHEDULE_STATUS_KEYS.inProgress;
    }
    // IN THE PAST
    else if (!scheduleItem.actualFinish && today > scheduleItem.forecastFinish) {
        status = SCHEDULE_STATUS_KEYS.inThePast;
    }
    // TO DO
    else {
        status = SCHEDULE_STATUS_KEYS.toDo;
    }

    /////////////////// CALCULATE DUE DATE ///////////////////
    let date = null;
    let color = null;

    // STATUS DONE -> ACTUAL FINISHED DATE
    if (status === SCHEDULE_STATUS_KEYS.done) {
        date = scheduleItem.actualFinish;
    }
    // STATUS TODO OR IN PROGRESS -> FORECAST FINISH
    else {
        date = scheduleItem.forecastFinish;
    }

    // CALCULATE DATE COLOR
    if (status === SCHEDULE_STATUS_KEYS.inProgress || status === SCHEDULE_STATUS_KEYS.inThePast) {
        if (scheduleItem.forecastFinish < TODAY) {
            color = "var(--color-bad)";
        } else {
            color = "var(--color-clash)";
        }
    } else {
        color = "var(--color-text-4)";
    }

    /////////////////// RETURN ///////////////////

    // GET STATUS INFOS
    let findIndex = FUNCT_FIND_INDEX(SCHEDULE_STATUS, "value", status);

    return {
        ...scheduleItem,
        status: SCHEDULE_STATUS[findIndex],
        dueDate: {
            date: date,
            color: color,
        },
    };
}


// RETURNS RECHART DATA FOR SCHEDULE FOR THE SPECIFIED YEAR
export function SCHEDULE_FUNC_GET_CHART_DATA(scheduleData, year, monthLabelWithYear) {
    // INIT
    let scheduleCopy = _.cloneDeep(scheduleData);
    let chartData = [];

    // LOOP ON EACH MONTH OF THE YEAR TO CREATE THE EMPTY CHART DATA ARRAY
    for (let month = 0; month < 12; month++) {
        // INIT DATA FOR THIS MONTH
        let monthData = {
            month: `${FUNC_ZERO_FORMAT_TO_NUM(month + 1, 2)}${
                monthLabelWithYear ? "/" + year.toString().slice(2, 4) : ""
            }`,
            date: new Date(year, month, 1),
            baseline: 0,
            forecast: 0,
            completed: 0,
        };

        chartData.push(monthData);
    }
    // const today = new Date();
 
    //  LOOP ON EACH SCHEDULE ITEM AND PLACE IT IN THE CORRECT CHART MONTH
    for (let i = 0; i < scheduleCopy.length; i++) {
        let scheduleItem = scheduleCopy[i];
      
        // IF ACTUAL FINISH SET -> DONE
        // AND CHECK ACTUAL FINISH IS IN SELECTE YEAR
        if (
            scheduleItem.status.value === SCHEDULE_STATUS_KEYS.done &&
            scheduleItem.actualFinish &&
            scheduleItem.actualFinish.getFullYear() === year
        ) {
            // GET ITEM ACTUAL FINISH MONTH
            let month = scheduleItem.actualFinish.getMonth();
            
            // ADD ONE TO DONE
            if (chartData[month].done) {
                chartData[month].done += 1;
            } else {
                chartData[month].done = 1;
            }
        }
        // IN PROGRESS
        else if (
            scheduleItem.status.value === SCHEDULE_STATUS_KEYS.inProgress &&
            scheduleItem.forecastFinish &&
            scheduleItem.forecastFinish.getFullYear() === year
        ) {
            // GET ITEM ACTUAL FINISH MONTH
            let month = scheduleItem.forecastFinish.getMonth();

            // ADD ONE TO DONE
            if (chartData[month].inProgress) {
                chartData[month].inProgress += 1;
            } else {
                chartData[month].inProgress = 1;
            }
        }
        // IN THE PAST
        else if (
            scheduleItem.status.value === SCHEDULE_STATUS_KEYS.inThePast &&
            scheduleItem.forecastFinish &&
            scheduleItem.forecastFinish.getFullYear() === year
        ) {
            // GET ITEM ACTUAL FINISH MONTH
            let month = scheduleItem.forecastFinish.getMonth();

            // ADD ONE TO DONE
            if (chartData[month].inThePast) {
                chartData[month].inThePast += 1;
            } else {
                chartData[month].inThePast = 1;
            }
        }
        // TO DO
        else if (
            scheduleItem.status.value === SCHEDULE_STATUS_KEYS.toDo &&
            scheduleItem.forecastFinish &&
            scheduleItem.forecastFinish.getFullYear() === year
        ) {
            // GET ITEM ACTUAL FINISH MONTH
            let month = scheduleItem.forecastFinish.getMonth();

            // ADD ONE TO DONE
            if (chartData[month].toDo) {
                chartData[month].toDo += 1;
            } else {
                chartData[month].toDo = 1;
            }
        }

        // COUNT BASELINE
        if (scheduleItem.baselineFinish && scheduleItem.baselineFinish.getFullYear() === year) {
            // GET ITEM BASELINE FINISH MONTH
            let month = scheduleItem.baselineFinish.getMonth();
            chartData[month].baseline += 1;
        }

        // COUNT ACTUAL FINISH
        if (scheduleItem.actualFinish && scheduleItem.actualFinish.getFullYear() === year) {
            // GET ITEM FORECAST FINISH MONTH
            let month = scheduleItem.actualFinish.getMonth();
            chartData[month].completed += 1;
            chartData[month].forecast += 1;
        }
        // COUNT FORECAST FINISH
        else if (scheduleItem.forecastFinish && scheduleItem.forecastFinish.getFullYear() === year) {
            // GET ITEM FORECAST FINISH MONTH
            let finishMonth = scheduleItem.forecastFinish.getMonth();
            chartData[finishMonth].forecast += 1;
        }
    }
    
    return chartData;
}

// COUNT CUMULATED VALUE FOR BUILDING CUMULATED CURVES
export function SCHEDULE_FUNC_COUNT_CUMULATED_VALUES(chartData) {
    // INIT COUNT CUMULATED VALUES
    let baselineCumu = 0;
    let forecastCumu = 0;
    let completedCumu = 0;

    return chartData.map((monthData, index) => {
        let updatedMonthData = { ...monthData };

        // ADD BASELINE CUMU
        baselineCumu += chartData[index].baseline;
        updatedMonthData.baselineCumu = baselineCumu;
        

        // ADD COMPLETED FINISH FOR MONTH < TODAY
        if (
            (monthData.date.getFullYear() < TODAY.getFullYear() &&
                monthData.date.getMonth() < TODAY.getMonth()) ||
            monthData.date.getFullYear() < TODAY.getFullYear()
        ) {
            completedCumu += chartData[index].completed;
            updatedMonthData.completedCumu = completedCumu;
        }

        // ADD COMPLETED CUMU TO FORECASTE CUMU IF THE CURRENT MONTH IS ONE MONTH BEFORE TODAY'S MONTH.
        if(FUNC_IS_DATE1_ONE_MONTH_BEFORE_DATE2(new Date(monthData.date), TODAY)) {
            forecastCumu = completedCumu;
            updatedMonthData.forecastCumu = completedCumu;
        }
        // ADD FORECAST CUMU FOR MONTH > TODAY
        else if(
            (monthData.date.getFullYear() >= TODAY.getFullYear() &&
                monthData.date.getMonth() >= TODAY.getMonth()) ||
            monthData.date.getFullYear() > TODAY.getFullYear()
        ) {
            forecastCumu += chartData[index].forecast;
            updatedMonthData.forecastCumu = forecastCumu;
        }
      
        return updatedMonthData;
    });
}

// SORT DATE ARRAY FOR TIMELINE
export function SCHEDULE_FUNC_SORT_DATES(dateArray) {
    return _.cloneDeep(dateArray).sort(function (a, b) {
        return a - b;
    });
}

export function SCHEDULE_FUNC_TIMELINE_DATE_TO_STR(date) {
    return new Date(date).toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
    });
}

// GET DATE PERCENTAGE POSITION
export function SCHEDULE_FUNC_GET_TIMELINE_START_POSITION(timelineStartDate, date, duration) {
    if (duration === 0) {
        return 49;
    }

    let position = ((date - timelineStartDate) / duration) * 100;

    return position;
}

// RETURNS TIMELINE START AND WIDTH
export function SCHEDULE_FUNC_GET_TIMELINE_START_AND_WIDTH(
    firstDate,
    dateStart,
    dateFinish,
    duration
) {
    // INIT
    let timeline = {
        start: null,
        width: null,
    };

    // CALCULATE START AND END POSITION
    let start = SCHEDULE_FUNC_GET_TIMELINE_START_POSITION(firstDate, dateStart, duration);
    let end = SCHEDULE_FUNC_GET_TIMELINE_START_POSITION(firstDate, dateFinish, duration);

    timeline.start = start;

    // DEDUCE WIDTH
    timeline.width = end - start;

    // MINIMAL LENGTH FOR SMALL DURATION
    if (timeline.width < 5) {
        timeline.width = 5;
    }

    return timeline;
}

// FOR A TIMELINE, RETURN THE POSITION OF START AND END IN PERCENT
export function SCHEDULE_FUNC_GET_PERCENTAGE_POSITION(start, width) {
    return {
        start: start,
        end: start + width,
    };
}

export function SCHEDULE_FUNC_FIX_OVERLAPS_PERCENTAGE(timelineConfigParam) {
    // INIT
    let timelineConfig = _.cloneDeep(timelineConfigParam);
    let allPercentagesPosition = [];
    let percentages;

    // GET BASELINE START AND END PERCENTAGES
    percentages = SCHEDULE_FUNC_GET_PERCENTAGE_POSITION(
        timelineConfig.baseline.start,
        timelineConfig.baseline.width
    );
    allPercentagesPosition.push(
        {
            name: "baselineStart",
            position: percentages.start,
        },
        {
            name: "baselineFinish",
            position: percentages.end,
        }
    );

    // GET FORECAST START AND END PERCENTAGES
    percentages = SCHEDULE_FUNC_GET_PERCENTAGE_POSITION(
        timelineConfig.forecast.start,
        timelineConfig.forecast.width
    );
    allPercentagesPosition.push(
        {
            name: "forecastStart",
            position: percentages.start,
        },
        {
            name: "forecastFinish",
            position: percentages.end,
        }
    );

    // GET ACTUAL START AND END PERCENTAGES
    if (timelineConfig.actual.start !== null) {
        percentages = SCHEDULE_FUNC_GET_PERCENTAGE_POSITION(
            timelineConfig.actual.start,
            timelineConfig.actual.width
        );
        allPercentagesPosition.push(
            {
                name: "actualStart",
                position: percentages.start,
            },
            {
                name: "actualFinish",
                position: percentages.end,
            }
        );
    }
    // GET TODAY TIMELINE START AND END PERCENTAGES
    if (timelineConfig.today.start !== null) {
        percentages = SCHEDULE_FUNC_GET_PERCENTAGE_POSITION(
            timelineConfig.today.start,
            timelineConfig.today.width
        );
        allPercentagesPosition.push(
            {
                name: "todayStart",
                position: percentages.start,
            },
            {
                name: "todayFinish",
                position: percentages.end,
            }
        );
    }

    // SORT ALL DATES BY PERCENTAGE POSITIONS
    allPercentagesPosition = allPercentagesPosition.sort(function (a, b) {
        return a.position - b.position;
    });

    // MOVE POSITIONS UNTIL THERE'S NO MORE OVERLAPS
    let minimalDistance = 5;
    let newPosition;

    // CHECK ALL POSITIONS WITH THE PREVIOUS ONE EXCEPT FIRST AND LAST
    for (let i = 1; i < allPercentagesPosition.length - 1; i++) {
        // DISTANCE BETWEEN CURRENT DATE AND PREVIOUS ONE
        let diff = allPercentagesPosition[i].position - allPercentagesPosition[i - 1].position;

        // IF PREVIOUS ONE WAS THE SAME VALUE, MOVE IT THE SAME AMOUNT
        if (
            allPercentagesPosition[i - 1].oldPosition &&
            allPercentagesPosition[i - 1].oldPosition === allPercentagesPosition[i].position
        ) {
            allPercentagesPosition[i].position = allPercentagesPosition[i - 1].position;
        }
        // ELSE, ADD MINIMAL DISTANCE
        else if (diff < minimalDistance && diff !== 0) {
            // ADD NECESSARY PERCENTAGE TO HAVE THE MINIMAL DISTANCE
            newPosition = allPercentagesPosition[i].position + minimalDistance - diff;

            if (newPosition <= 100) {
                // STORE OLD POSITION
                allPercentagesPosition[i].oldPosition = allPercentagesPosition[i].position;

                allPercentagesPosition[i].position = newPosition;
            }
        }
    }

    // REMOVE OLD POSITIONS
    allPercentagesPosition = allPercentagesPosition.map((position) => {
        delete position.oldPosition;
        return position;
    });

    // CHECK ALL POSITIONS WITH THE NEXT ONE EXCEPT FIRST AND LAST (FROM LAST TO FIRST)
    for (let i = allPercentagesPosition.length - 2; i > 0; i--) {
        // DISTANCE BETWEEN CURRENT DATE AND PREVIOUS ONE
        let diff = allPercentagesPosition[i + 1].position - allPercentagesPosition[i].position;

        // IF PREVIOUS ONE WAS THE SAME VALUE, MOVE IT THE SAME AMOUNT
        if (
            allPercentagesPosition[i + 1].oldPosition &&
            allPercentagesPosition[i + 1].oldPosition === allPercentagesPosition[i].position
        ) {
            allPercentagesPosition[i].position = allPercentagesPosition[i + 1].position;
        }
        // ELSE, ADD MINIMAL DISTANCE
        else if (diff < minimalDistance && diff !== 0) {
            // STORE OLD POSITION
            allPercentagesPosition[i].oldPosition = allPercentagesPosition[i].position;

            // ADD NECESSARY PERCENTAGE TO HAVE THE MINIMAL DISTANCE
            allPercentagesPosition[i].position -= minimalDistance - diff;
        }
    }

    // --------- PUT THE NEW DATA BACK ---------
    let index;

    // BASELINE
    index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "baselineStart");
    timelineConfig.baseline.start = allPercentagesPosition[index].position;

    index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "baselineFinish");
    timelineConfig.baseline.width =
        allPercentagesPosition[index].position - timelineConfig.baseline.start;

    // FORECAST
    index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "forecastStart");
    timelineConfig.forecast.start = allPercentagesPosition[index].position;

    index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "forecastFinish");
    timelineConfig.forecast.width =
        allPercentagesPosition[index].position - timelineConfig.forecast.start;

    // ACTUAL
    if (timelineConfigParam.actual.start !== null) {
        index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "actualStart");
        timelineConfig.actual.start = allPercentagesPosition[index].position;

        index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "actualFinish");
        timelineConfig.actual.width =
            allPercentagesPosition[index].position - timelineConfig.actual.start;
    }

    // TODAY
    if (timelineConfigParam.today.start !== null) {
        index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "todayStart");
        timelineConfig.today.start = allPercentagesPosition[index].position;

        index = FUNCT_FIND_INDEX(allPercentagesPosition, "name", "todayFinish");
        timelineConfig.today.width =
            allPercentagesPosition[index].position - timelineConfig.today.start;
    }

    // RETURN
    return timelineConfig;
}

// GET TIMELINE LETTERS
export function SCHEDULE_FUNC_GET_TIMELINE_LETTERS(baseline, forecast, actual) {
    // INIT
    let baselineLetter;
    let forecastLetter;
    let actualLetter;

    // CASE START B=F=A
    if (baseline === forecast && actual && actual === forecast) {
        baselineLetter = "B/F/A";
        forecastLetter = null;
        actualLetter = null;
    }
    // CASE B=F
    else if (baseline === forecast) {
        baselineLetter = "B/F";
        forecastLetter = null;
        actualLetter = "A";
    }
    // CASE B=A
    else if (actual && baseline === actual) {
        baselineLetter = "B/A";
        forecastLetter = "F";
        actualLetter = null;
    }
    // CASE F=A
    else if (actual && forecast === actual) {
        baselineLetter = "B";
        forecastLetter = "F/A";
        actualLetter = null;
    }
    // OTHER
    else {
        baselineLetter = "B";
        forecastLetter = "F";
        actualLetter = "A";
    }

    return {
        baseline: baselineLetter,
        forecast: forecastLetter,
        actual: actualLetter,
    };
}

// CHECK THAT THE MIDDLE DATE IS NOT TOO CLOSE OF START AND END
export function SCHEDULE_FUNC_FIX_OVERLAPS_MILESTONE(percentage) {
    let minPercentage = 6;
    if (percentage !== 0 && percentage !== 100) {
        if (percentage < minPercentage) {
            return minPercentage;
        } else if (percentage > 100 - minPercentage) {
            return 100 - minPercentage;
        }
    }

    // TOO CLOSE TO RIGHT, WILL GO OUTSIDE TIMELINE, MOVE IT BACK A BIT
    if (percentage === 100) {
        return 98;
    }

    return percentage;
}

// FUNCTION TO PREPARE REFERENCE SCHEDULE FOR SAVING IN GRAPHQL
export const SCHEDULE_PREPARE_REFERENCE_SAVE = (
    referenceSchedule,
    biggestDisplayIdSchedule,
    currentUserId,
    selectedProject
) => {
    // COPY
    let returnSchedule = _.cloneDeep(referenceSchedule);

    // NEW DISPLAY ID
    returnSchedule.displayId = FUNC_BUILD_DISPLAY_ID_STR(
        SCHEDULE_DISPLAY_ID_STR,
        biggestDisplayIdSchedule
    );

    // TURN TO JSON
    returnSchedule.guidUpdate =
        typeof returnSchedule.guidUpdate !== "string"
            ? JSON.stringify(returnSchedule.guidUpdate)
            : returnSchedule.guidUpdate;

    // CORRECT FORECAST DATES
    returnSchedule.forecastStart = returnSchedule.plannedStart;
    returnSchedule.forecastFinish = returnSchedule.plannedFinish;

    // PROJECT ID
    returnSchedule.projectId = selectedProject.id;

    // WBS ID
    returnSchedule.wbsId = selectedProject.id;

    // ORGANIZATION ID
    returnSchedule.organizationId = selectedProject.coreDataOrganizationCoreDataProjectId;

    // CREATED BY
    returnSchedule.createdBy = currentUserId;

    // RESPONSIBLE
    returnSchedule.responsible = currentUserId;

    // LOOP DATES
    for (let dateAttribute of SCHEDULE_DATE_ATTRIBUTES) {
        // TRY TO GET ATTRIBUTE FROM REFERENCE
        const referenceAttribute = FIELDS_ANALYSIS.find(
            (field) => field.scheduleAttribut === dateAttribute
        )?.attribut;

        const attribut = referenceAttribute ?? dateAttribute;

        // if(!referenceSchedule[attribut]) referenceSchedule[attribut] = new Date();

        returnSchedule[dateAttribute] = referenceSchedule[attribut]
            ? new Date(referenceSchedule[attribut])
            : null;
    }

    // SECURITY

    //MANAGE GROUPS CAN EDIT
    const groupEditors = [
        APP_TXT_MEGA_USERS,
        selectedProject.organizationMegaUsersGroup,
        selectedProject.projectMegaUsersGroup,
    ];

    //MANAGE GROUP CAN SEE
    const groupViewers = [selectedProject.projectUsersGroup];

    // GROUP EDITORS
    returnSchedule.groupEditors = groupEditors;

    // GROUP VIEWERS
    returnSchedule.groupViewers = groupViewers;

    // CLEAN FOR SAVING
    for (let attribute of Object.keys(returnSchedule)) {
        // IF NOT INCLUDED, THEN DELETE
        if (!SCHEDULE_REQUIRED_ATTRIBUTES?.includes(attribute)) {
            delete returnSchedule[attribute];
        }
    }

    return { ...returnSchedule, id: uuid() };
};

export const SCHEDULE_FUNC_UPDATE_FROM_REFERENCE = (oldItem, newItem, attributesToUpdate) => {
    let returnSchedule = {
        id: oldItem.id,
        guid: oldItem.guid,
        guidUpdate: JSON.stringify(newItem.guidUpdate),
        // PROJECT ID
        projectId: oldItem.projectId,
        wbsId: oldItem.wbsId,
        // ORGANIZATION ID
        organizationId: oldItem.organizationId,
    };

    for (let attribute of attributesToUpdate) {
        // TRY TO FIND SCHEDULE ATTRIBUTE
        const scheduleAttribut = FIELDS_ANALYSIS.find(
            (field) => field.attribut === attribute && field.scheduleAttribut !== undefined
        )?.scheduleAttribut;
        // UPDATE IN THE RETURN SCHEDULE
        returnSchedule[scheduleAttribut ?? attribute] = newItem[attribute];
    }

    for (let dateAttribut of SCHEDULE_DATE_ATTRIBUTES) {
        if (returnSchedule[dateAttribut] && typeof returnSchedule[dateAttribut] === "string") {
            returnSchedule[dateAttribut] = new Date(returnSchedule[dateAttribut]);
        }
    }

    // CLEAN FOR SAVING
    for (let attribute of Object.keys(returnSchedule)) {
        // IF NOT INCLUDED, THEN DELETE
        if (!SCHEDULE_REQUIRED_ATTRIBUTES?.includes(attribute)) {
            delete returnSchedule[attribute];
        }
    }

    return { ...returnSchedule, id: uuid() };
};

// FUNCTION TO COMPARE SCHEDULE ITEMS FROM REFERENCE
export const SCHEDULE_FUNC_IDENTIFY_UPDATES = (newItem, oldItem) => {
    let isUpdated = false;
    let attributesToUpdate = [];

    for (let i = 0; i < FIELDS_ANALYSIS.length; i++) {
        const attribut = FIELDS_ANALYSIS[i].attribut;
        const scheduleAttribut = FIELDS_ANALYSIS[i].scheduleAttribut ?? null;

        //TO DISPLAY
        let toDisplayOld =
            oldItem[scheduleAttribut ?? attribut] &&
            oldItem[scheduleAttribut ?? attribut] !== "null"
                ? oldItem[scheduleAttribut ?? attribut]
                : "";
        let toDisplayNew =
            newItem[attribut] && newItem[attribut] !== "null" ? newItem[attribut] : "";

        //SWITCH
        switch (FIELDS_ANALYSIS[i].type) {
            case "date":
                if (toDisplayOld !== "") {
                    toDisplayOld = FUNC_DATE_TO_TXT_STANDARD(toDisplayOld);
                }
                if (toDisplayNew !== "") {
                    toDisplayNew = FUNC_DATE_TO_TXT_STANDARD(new Date(toDisplayNew));
                }
                break;

            case "boolean":
                if (toDisplayOld === true) {
                    toDisplayOld = "Yes";
                }
                if (toDisplayNew === true) {
                    toDisplayNew = "Yes";
                }
                break;

            case "array":
                toDisplayOld = toDisplayOld.length;
                toDisplayNew = toDisplayNew.length;
                break;

            default:
                break;
        }

        //TEST IF IDENTIC
        if (toDisplayOld !== toDisplayNew) {
            isUpdated = true;
            attributesToUpdate.push(FIELDS_ANALYSIS[i].attribut);
            // break;
        }
    }
    return {
        isUpdated: isUpdated,
        attributesToUpdate: attributesToUpdate,
    };
};

// GIVEN AN ARRAY OF ITEMS FROM REFERENCE AND AN ARRAY OF EXISTING SCHEDULES,
// RETURN OBJECT {create: [...], update: [...]} OF THE SCHEDULE FROM REFERENCE

export const SCHEDULE_FUNC_CREATE_UPDATE_REFERENCE = (
    referenceData,
    existingData,
    biggestDisplayIdSchedule,
    currentUserId,
    selectedProject
) => {
    let biggestDisplayId = _.cloneDeep(biggestDisplayIdSchedule);

    // INIT RETURN VALUE
    let result = {
        create: [],
        update: [],
    };

    // LOOP THROUGH ALL REFERENCE DATA
    for (let referenceSchedule of referenceData) {
        // TRY TO FIND ITEM IN EXISTING DATA WITH SAME GUID
        const foundSchedule = existingData.find(
            (existingSchedule) => existingSchedule.guid === referenceSchedule.guid
        );

        // IF DIDN'T FIND, NEED TO CREATE NEW ONE
        if (!foundSchedule) {
            result.create.push(
                SCHEDULE_PREPARE_REFERENCE_SAVE(
                    referenceSchedule,
                    biggestDisplayId,
                    currentUserId,
                    selectedProject
                )
            );
        } else {
            // CHECK IF ANY UPDATES
            const updateResult = SCHEDULE_FUNC_IDENTIFY_UPDATES(referenceSchedule, foundSchedule);

            // IF UPDATED, THEN ADD TO UPDATE ARRAY
            if (updateResult.isUpdated) {
                // GET UPDATED ITEM
                const updatedItem = SCHEDULE_FUNC_UPDATE_FROM_REFERENCE(
                    foundSchedule,
                    referenceSchedule,
                    updateResult.attributesToUpdate
                );

                // PUSH TO UPDATE ARRAY
                result.update.push(updatedItem);
            }
        }

        biggestDisplayId += 1;
    }

    return result;
};

// GET DEFAULT SCHEDULE ITEM
export function SCHEDULE_GET_DEFAULT_ITEM(selectedOrganizationId, wbsId, userId) {
    let item = _.cloneDeep(SCHEDULE_DEFAULT_ITEM);

    item.organizationId = selectedOrganizationId;
    item.projectId = wbsId;
    item.wbsId = wbsId;
    item.createdBy = userId;
    item.responsible = userId;

    return item;
}

// GET INITIAL SCHEDULE STATE
export function SCHEDULE_GET_INITIAL_STATE(isReviewMode, wbsNavigationOptions, schedules) {
    let initialState = _.cloneDeep(SCHEDULE_DEFAULT_STATE);

    // IF REVIEW MODE OPEN, DISPLAY ONLY TABLE
    if (isReviewMode) {
        initialState.expandMode = SCHEDULE_COMPONENT_EXPAND_TYPES.table;
    } else {
        // MANAGE LIMIT SIZE FOR TO COLUMN IN BIG COMPONENT
        initialState.widthSizeLimit = window.innerWidth <= 1450;
    }

    // IF OPTION TO OPEN AN ITEM
    if (wbsNavigationOptions?.goToItemId) {
        // FIND THE ITEM
        let foundSchedule = FIND_OBJECT_ARRAY_ITEM(
            schedules,
            "id",
            wbsNavigationOptions.goToItemId
        );

        // IF ACTION FOUND, SET DATA
        if (foundSchedule) {
            initialState.viewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
            initialState.selectedItemView = foundSchedule;
        }
    }

    // OPEN CONNECTION TAB IF OPTION SET
    if (wbsNavigationOptions?.options?.openLinksTab) {
        initialState.selectedViewModeContent = "connections";
    }

    return initialState;
}
