import { AGENDA_ITEM_TYPES_KEYS } from "../../../../00-Core/Constants";
import {
    CUSTOM_DISABLED_TYPES,
    CUSTOM_MAX_DATE_TYPE,
    CUSTOM_MIN_DATE_TYPE,
} from "../../07-EditSideBar/00-Helpers/EditSideBarConstants";


export const GOV_REVIEW_AGENDA_DEFAULT_ITEM = {
    title: "",
    type: AGENDA_ITEM_TYPES_KEYS.risk,
    itemId: null,
    expectation: "",
    status: "toBeDecided",
};

// SIZE OF CALENDAR CONTEXT MENU
export const GOV_REVIEW_CALENDAR_CONTEXT_MENU_WIDTH = 180;
export const GOV_REVIEW_CALENDAR_CONTEXT_MENU_HEIGHT = 340;

// REVIEW AGENDA ITEM STATUS
export const GOV_REVIEW_AGENDA_STATUS = [
    {
        id: "toBeDecided",
        label: "To be decided",
        backgroundColor: "var(--border-color-gray-2)",
    },
    {
        id: "postponed",
        label: "Postponed",
        backgroundColor: "var(--color-escalated)",
    },
    {
        id: "reviewed",
        label: "Reviewed",
        backgroundColor: "var(--color-migso-green-hover)",
    },
];

// MODAL TYPE
export const GOV_REVIEW_MODAL_TYPE = {
    edit: "EDIT",
    delete: "DELETE",
};

// OPTIONS FOR THE DELETE SERIES MODAL
export const GOV_REVIEW_SERIES_MODAL_OPTIONS = {
    onlyThis: "ONLY_THIS",
    wholeSeries: "WHOLE_SERIES",
};

export const GOV_REVIEW_WEEK_DAYS = [
    {
        label: "Mon",
        value: "monday",
    },
    {
        label: "Tue",
        value: "tuesday",
    },
    {
        label: "Wed",
        value: "wednesday",
    },
    {
        label: "Thu",
        value: "thursday",
    },
    {
        label: "Fri",
        value: "friday",
    },
    {
        label: "Sat",
        value: "saturday",
    },
    {
        label: "Sun",
        value: "sunday",
    },
];

// DEFAULT PERIODICITY OBJECT
export const GOV_REVIEW_DEFAULT_PERIODICITY = {
    weekGap: 1,
    weekDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    },
};

export const GOV_REVIEW_MEETING_STATUS_KEY = {
    notStarted: "notStarted",
    inProgress: "inProgress",
    done: "done",
    closed: "closed",
};

export const GOV_REVIEW_MEETING_STATUS = [
    {
        id: GOV_REVIEW_MEETING_STATUS_KEY.notStarted,
        label: "Not started",
        backgroundColor: "var(--color-migso-blue)",
    },
    {
        id: GOV_REVIEW_MEETING_STATUS_KEY.inProgress,
        label: "In Progress",
        backgroundColor: "var(--color-migso-yeallow)",
    },
    {
        id: GOV_REVIEW_MEETING_STATUS_KEY.done,
        label: "Done",
        backgroundColor: "var(--color-escalated)",
    },
    {
        id: GOV_REVIEW_MEETING_STATUS_KEY.closed,
        label: "Minute Closed",
        backgroundColor: "var(--color-migso-green)",
    },
];

// ACTIONS GROUP ITEMS FOR EDIT SIDE BAR
export const GOV_REVIEW_EDIT_SIDEBAR_GROUPS = [
    {
        id: 1,
        name: "Recurrency",
        icon: "loop",
        noCountTag: true,
        isDefaultOpen: true,
    },
    {
        id: 2,
        name: "Agenda",
        icon: "list",
        noCountTag: true,
    },
];

// STRUCTURE OF GOV REVIEW CHANGE EDIT SIDE BAR
export const GOV_REVIEW_EDIT_SIDEBAR_DATA = [
    // ITEM IDS
    { groupId: -1, key: "id", value: null, type: "id" },
    { groupId: -1, key: "organizationId", value: "", type: "id" },
    { groupId: -1, key: "projectId", value: "", type: "id" },
    { groupId: -1, key: "wbsId", value: "", type: "id" },
    { groupId: -1, key: "displayId", value: "", type: "id" },
    { groupId: -1, key: "govItemType", value: "review", type: "id" },
    {
        groupId: -1,
        key: "reviewMeetingStatus",
        value: GOV_REVIEW_MEETING_STATUS_KEY.notStarted,
        type: "id",
    },

    {
        groupId: 0,
        key: "name",
        value: "",
        label: "Name",
        component: "input",
        maxLength: 80,
        type: "text",
        info: "Review title",
        placeholder: "Review title",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "tags",
        value: "",
        info: "To be defined",
        label: "Tags",
        component: "multi_select",
        type: "list",
        options: [],
        placeholder: "",
        mandatory: false,
        canCreate: true,
    },
    {
        groupId: 0,
        key: "startDate",
        value: "",
        label: "Review Start",
        component: "input",
        type: "datetime-local",
        info: "Starting date of the review",
        dateIsBefore: "endDate",
        placeholder: "",
        rowWithNext: true,
        mandatory: true,
        customMinDateLinks: [
            {
                property: "endRecurrency",
                type: CUSTOM_MIN_DATE_TYPE.oneWeekAfter,
            },
        ],
        customMaxDateLinks: [
            {
                property: "endRecurrency",
                type: CUSTOM_MAX_DATE_TYPE.oneYearAfter,
            },
        ],
        customDisabledLinks: ["periodicity"],
    },
    {
        groupId: 0,
        key: "endDate",
        value: "",
        label: "Review End",
        component: "input",
        type: "datetime-local",
        info: "End date of the review",
        dateIsAfter: "startDate",
        placeholder: "",
        mandatory: true,
        customDisabledLinks: ["periodicity"],
    },
    // PERIODICITY
    {
        groupId: 1,
        key: "periodicity",
        value: "[]",
        label: "Periodicity",
        component: "custom",
        type: "govReviewPeriodicity",
        mandatory: true,
        displayOptions: {
            isRecurrencyParent: true,
        },
        customDisabled: [
            // FIRST CONDITION
            {
                // DATE DIFFERENCE TYPE
                type: CUSTOM_DISABLED_TYPES.dateDiff,
                firstDate: "startDate",
                secondDate: "endDate",
                // DISABLED AFTER 1 DAY (DIFF NEEDS TO BE SMALLER THAN 1!)
                disabledAfter: 1,
            },
        ],
        disabled: false,
    },

    // END
    {
        groupId: 1,
        key: "endRecurrency",
        value: "",
        label: "End Recurrency",
        component: "input",
        type: "date",
        info: "Date when the series ends",
        // minDate:
        customMinDate: {
            property: "startDate",
            type: CUSTOM_MIN_DATE_TYPE.oneWeekAfter,
        },
        // maxDate: ONE_YEAR_FROM_TODAY,
        customMaxDate: {
            property: "startDate",
            type: CUSTOM_MAX_DATE_TYPE.oneYearAfter,
        },
        mandatory: true,
        displayOptions: {
            isRecurrencyParent: true,
        },
    },

    // ATTENDEES
    {
        groupId: 0,
        key: "attendees",
        value: "",
        label: "Attendees",
        component: "multi_select",
        type: "list",
        options: [],
        info: "People who participate to the review",
        placeholder: "",
        mandatory: false,
    },
    {
        groupId: 0,
        key: "objectives",
        value: "",
        label: "Objectives",
        component: "textArea",
        type: "richTextArea",
        info: "Objectives to reach at the end of the review",
        placeholder: "Objectives to reach at the end of the review",
        mandatory: false,
    },
    {
        groupId: 0,
        key: "chairman",
        value: "",
        label: "Chairman",
        component: "input",
        type: "text",
        info: "Who's the chairman of the review",
        placeholder: "Who's the chairman of the review",
        mandatory: false,
    },
    {
        groupId: 0,
        key: "isRecurrencyParent",
        value: "",
        label: "Recurrency",
        component: "icon_toggle",
        info: "Make this review periodic",
        isBlueInfoIcon: false,
        isInfoIconBeforeToggle: false,
        placeholder: "",
        mandatory: false,
        displayLink: ["periodicity", "endRecurrency"],
        addToUpdatedFields: ["periodicity"],
    },
    {
        groupId: 2,
        key: "agenda",
        value: "[]",
        label: "Agenda",
        component: "custom",
        type: "govReviewAgenda",
        mandatory: false,
    },
];


export const GOV_REVIEW_AGENDA_ITEM_TYPES = [
    {
        id: "riskOpp",
        label: "Risk / Opportunity",
        icon: "fact_check",
    },
    {
        id: "action",
        label: "Action",
        icon: "alarm",
    },
    {
        id: "govScopeChange",
        label: "Scope Change",
        icon: "event",
    },
];