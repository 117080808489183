import React from 'react'

//AUTH MINI COMPONENT
import {
    AuthAuthenticatorTitle,
    AuthAuthenticatorForm,
    AuthAuthenticatorFormTitle
} from './AuthAuthenticatorMiniComponents'

//CORE
import { CvtButton, CvtInput } from '@mi-gso/cvt'

////////////////////////////////////////////////////////////////////////////////////////////
/// EMAIL VERIFICATION COMPONENT ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

export default function AuthAuthenticatorEmailVerification({
    options,
    onChange,
    errorMessage,
    handleSendCodeForAttributeVerification,
    handleSubmitAttributeVerification,
    handleBackToSignIn,
    userEmail
}){
    return(
        <React.Fragment>

            {/* TITLE & ERROR */}
            <AuthAuthenticatorTitle
                title="Welcome to Clayverest"
                errorMessage={errorMessage}
                icon="check_circle"
            />

            {/* INPUTS BLOCK */}
            <AuthAuthenticatorForm
                disabled={options.code === "" }
                loader={options.loader}
                text="Verify Email"
                handleBackToSignIn={handleBackToSignIn}
                onSubmit={handleSubmitAttributeVerification}
                onSubmitOptions="email"
                hiddeButton={!options.codeSent}
                errorMessage={errorMessage}
            >

                {/* FORM TITLE */}
                <AuthAuthenticatorFormTitle
                    icon="mark_email_read"
                    text="Your email need to be verified"
                />

                    {/* USERNAME */}
                    <div style={{padding:"20px 20px 0px 20px",fontWeight:500}}>
                        {options.username !== "" ? options.username : userEmail}
                    </div>

                    {/* IF CODE SENT */}
                    {!options.codeSent ?
                        <CvtButton
                            color="green"
                            txt="Send the code"
                            margin="10px 20px 20px 20px"
                            handleSubmit={handleSendCodeForAttributeVerification}
                            handleSubmitOptions="email"
                            loader={options.codeLoader}
                        />
                    :
                        <React.Fragment>

                            {/* CODE */}
                            <CvtInput
                                legend="Code *"
                                placeholder="Enter code sent to your email"
                                inputBlockCss="modalOptionSubBlock"
                                inputBlockPadding="15px 20px 10px 20px"
                                inputCss="form-control appAuthenticatorInput"
                                name="code"
                                value={options.code}
                                onChange={onChange}
                                autoComplete="off"
                            />

                            {/* RESEND CODE */}
                            <div className="appAuthenticatorQuestion">
                                Code not received?
                                <a href='/#' onClick={(e) => handleSendCodeForAttributeVerification(e,"email")} style={{marginLeft:"5px"}}>
                                    Resend the code
                                </a>
                            </div>
                        </React.Fragment>
                    }
            </AuthAuthenticatorForm>
        </React.Fragment>
    )
}