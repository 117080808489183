import { Button } from "@mi-gso/cvt";
import { useMemo } from "react";
import {
    ComposedChart,
    LabelList,
    Bar,
    XAxis,
    Line,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import {
    CvtCommonXAxisTick,
    CvtCommonYAxisTick,
    CvtCommonLabelBar,
    CvtCommonLegend,
    CvtCommonToolTip,
} from "@mi-gso/cvt";
import {
    SCHEDULE_CHART_TXT_CONVERSION,
    SCHEDULE_COMPONENT_EXPAND_TYPES,
    SCHEDULE_STATUS_KEYS,
} from "../00-Helpers/ScheduleConstants";
import {
    SCHEDULE_FUNC_COUNT_CUMULATED_VALUES,
    SCHEDULE_FUNC_GET_CHART_DATA,
} from "../00-Helpers/ScheduleFunctions";
import ScheduleChartCommandBar from "./ScheduleChartCommandBar";
import AutoSizer from "react-virtualized-auto-sizer";

export default function ScheduleChart({
    scheduleData,
    displayWidth,
    expandMode,
    atCompletionMode,
    yearSelected,
    atCompletionModeYearsSelect,
    atCompletionSelectedFirstYear,
    atCompletionSelectedLastYear,
    secondYAxis,
    onExpandArrowClick,
    onChangeGraphYear,
    onChangeAtCompletionMode,
    onChangeYAxis,
    handleCompletionSelectChange,
}) {
    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // BUILD RECHART DATA
    const graphData = useMemo(() => {
        let data = [];

        if (atCompletionMode) {
            let firstYear = atCompletionSelectedFirstYear
                ? atCompletionSelectedFirstYear
                : atCompletionModeYearsSelect[0].value;
            firstYear = parseInt(firstYear);

            let lastYear = atCompletionSelectedLastYear
                ? atCompletionSelectedLastYear
                : atCompletionModeYearsSelect[atCompletionModeYearsSelect.length - 1].value;
            lastYear = parseInt(lastYear);

            // BUILD FULL GRAPH, BETWEEN MIN AND MAX YEAR
            for (let y = firstYear; y <= lastYear; y++) {
                data.push(...SCHEDULE_FUNC_GET_CHART_DATA(scheduleData, y, true));
            }

            // COUNT CUMULATED VALUES
            data = SCHEDULE_FUNC_COUNT_CUMULATED_VALUES(data);
        } else {
            // SELECTED YEAR GRAPH
            data = SCHEDULE_FUNC_GET_CHART_DATA(scheduleData, yearSelected);

            // COUNT CUMULATED VALUES
            data = SCHEDULE_FUNC_COUNT_CUMULATED_VALUES(data, yearSelected);
        }

        return data;
    }, [
        atCompletionMode,
        atCompletionModeYearsSelect,
        atCompletionSelectedFirstYear,
        atCompletionSelectedLastYear,
        scheduleData,
        yearSelected,
    ]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexColStartStart actionsChartContainer"
            style={{
                width: displayWidth,
                display: displayWidth === "0%" ? "none" : null,
            }}
        >
            {/* CHART HEADER -------------------------------------- */}
            <div className="actionsChartHeader flexBetweenCenter">
                {/* ICON + TITLE + COMMAND BAR */}
                <div className="flexStartCenter">
                    {/* ICON + TITLE */}
                    <div className="flexStartCenter">
                        {/* ICON */}
                        <span className="material-icons actionsChartHeaderIcon">insights</span>

                        {/* TITLE */}
                        <span className="actionsChartHeaderTitle">S-Curve</span>
                    </div>

                    {/* COMMAND BAR */}
                    <ScheduleChartCommandBar
                        yearSelected={yearSelected}
                        atCompletionMode={atCompletionMode}
                        atCompletionModeYearsSelect={atCompletionModeYearsSelect}
                        atCompletionSelectedFirstYear={atCompletionSelectedFirstYear}
                        atCompletionSelectedLastYear={atCompletionSelectedLastYear}
                        secondYAxis={secondYAxis}
                        onChangeYAxis={onChangeYAxis}
                        onChangeYear={onChangeGraphYear}
                        onChangeAtCompletionMode={onChangeAtCompletionMode}
                        handleChange={handleCompletionSelectChange}
                    />
                </div>

                {/* EXPAND ARROW */}
                {!atCompletionMode ? (
                    <Button
                        icon={
                            expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.graph
                                ? "chevron_left"
                                : "chevron_right"
                        }
                        css="btn commonButtonSubmitLight leftComponentExpandButton"
                        borderRadius="20px"
                        iconFontSize="16px"
                        handleSubmit={() =>
                            onExpandArrowClick(
                                expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.graph ? false : true,
                                true
                            )
                        }
                    />
                ) : null}
            </div>

            {/* CHART ---------------------------------------------------- */}
            {displayWidth !== "0%" ? (
                <div className="actionsChartBody">
                    <AutoSizer>
                        {({ width, height }) => (
                            <ComposedChart
                                data={graphData}
                                margin={{ top: 10, right: 0, left: -30, bottom: 1 }}
                                width={width}
                                height={height}
                            >
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis
                                    tickMargin={2}
                                    dataKey="month"
                                    height={40}
                                    tick={<CvtCommonXAxisTick />}
                                    interval="preserveStartEnd"
                                />
                                <YAxis
                                    type="number"
                                    tickCount={3}
                                    interval={"preserveStartEnd"}
                                    tickMargin={8}
                                    tick={<CvtCommonYAxisTick />}
                                />

                                {secondYAxis ? (
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        type="number"
                                        tickCount={3}
                                        interval={"preserveStartEnd"}
                                        tickMargin={8}
                                        tick={<CvtCommonYAxisTick />}
                                    />
                                ) : null}
                                
                                <Tooltip
                                    content={
                                        <CvtCommonToolTip
                                            txtConverter={SCHEDULE_CHART_TXT_CONVERSION}
                                        />
                                    }
                                />
                                <Legend
                                    wrapperStyle={{ bottom: 0, left: -8 }}
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="bottom"
                                    content={
                                        <CvtCommonLegend
                                            txtConverter={SCHEDULE_CHART_TXT_CONVERSION}
                                        />
                                    }
                                />

                                {/* IN THE PAST */}
                                <Bar
                                    type="monotone"
                                    dataKey={SCHEDULE_STATUS_KEYS.inThePast}
                                    stackId="a"
                                    fill="var(--color-bad)"
                                    yAxisId={secondYAxis ? "right" : 0}
                                >
                                    <LabelList
                                        dataKey={SCHEDULE_STATUS_KEYS.inThePast}
                                        content={<CvtCommonLabelBar color="var(--color-bad)" />}
                                    />
                                </Bar>

                                {/* TO DO */}
                                <Bar
                                    type="monotone"
                                    dataKey={SCHEDULE_STATUS_KEYS.toDo}
                                    stackId="a"
                                    fill="var(--color-text-5)"
                                    yAxisId={secondYAxis ? "right" : 0}
                                >
                                    <LabelList
                                        dataKey={SCHEDULE_STATUS_KEYS.toDo}
                                        content={<CvtCommonLabelBar color="var(--color-text-5)" />}
                                    />
                                </Bar>

                                {/* IN PROGRESS */}
                                <Bar
                                    type="monotone"
                                    dataKey={SCHEDULE_STATUS_KEYS.inProgress}
                                    stackId="a"
                                    fill="var(--color-migso-blue)"
                                    yAxisId={secondYAxis ? "right" : 0}
                                >
                                    <LabelList
                                        dataKey={SCHEDULE_STATUS_KEYS.inProgress}
                                        content={<CvtCommonLabelBar color="var(--color-migso-blue)" />}
                                    />
                                </Bar>

                                {/* DONE */}
                                <Bar
                                    type="monotone"
                                    dataKey={SCHEDULE_STATUS_KEYS.done}
                                    stackId="a"
                                    fill="var(--color-migso-green)"
                                    yAxisId={secondYAxis ? "right" : 0}
                                >
                                    <LabelList
                                        dataKey={SCHEDULE_STATUS_KEYS.done}
                                        content={
                                            <CvtCommonLabelBar color="var(--color-migso-green)" />
                                        }
                                    />
                                </Bar>

                                {/* LINE BASELINE CUMU */}
                                <Line
                                    type="monotone"
                                    dataKey="baselineCumu"
                                    stroke="var(--color-text-5)"
                                    strokeWidth={2}
                                    strokeDasharray="5"
                                    fill="var(--color-text-5)"
                                    dot={
                                        !atCompletionMode
                                            ? {
                                                  stroke: "var(--color-text-5)",
                                                  strokeWidth: 4,
                                                  r: 2,
                                                  strokeDasharray: "",
                                              }
                                            : null
                                    }
                                />

                                {/* LINE FORECAST CUMU */}
                                <Line
                                    type="monotone"
                                    dataKey="forecastCumu"
                                    stroke="var(--color-migso-yeallow)"
                                    strokeWidth={3}
                                    fill="var(--color-migso-yeallow)"
                                    dot={
                                        !atCompletionMode
                                            ? {
                                                  stroke: "var(--color-migso-yeallow)",
                                                  strokeWidth: 4,
                                              }
                                            : null
                                    }
                                />

                                {/* LINE COMPLETED CUMU */}
                                <Line
                                    type="monotone"
                                    dataKey="completedCumu"
                                    stroke="var(--color-text-2)"
                                    strokeWidth={3}
                                    fill="var(--color-text-2)"
                                    dot={
                                        !atCompletionMode
                                            ? {
                                                  stroke: "var(--color-text-2)",
                                                  strokeWidth: 4,
                                              }
                                            : null
                                    }
                                />
                            </ComposedChart>
                        )}
                    </AutoSizer>
                </div>
            ) : null}
        </div>
    );
}
