///////////////////////////////////
// SUMMARY
// 01 : APP_SETTINGS
// 02 : APP_USER_SETTINGS
// 03 : APP_CORE_SELECTION
// 03 : APP_DATA_HOME
// 04 : APP_TOAST
// 05 : APP_CURRENT_USER
// 06 : APP_CONFIG
// 07 : APP_BOARD_DATA
///////////////////////////////////

import { DEFAULT_STATE_MOVE_MODAL_OPTIONS } from "../../../00-Core/Constants"
import { WBS_DEFAULT_NAVIGATION_OPTIONS } from "../../02-Portfolio/00-Wbs/00-Helpers/WbsConstants"

//APP SETTINGS
export const APP_INIT_SETTINGS={
  actionModal: null,
  moveModalOptions: DEFAULT_STATE_MOVE_MODAL_OPTIONS,
  optionsModal:null,
  rigthSideBarOptions: null,
  dataConsolidation: true,
  sideBarOpenWidth: 0,
  referenceCreateUpdateObject: null,
  
}

//CORE SELECTION
export const APP_CORE_SELECTION={
  selectedOrganization:{},
  selectedProject:{},
  selectedWbs:{},
  wbsList:[],
  selectedProjectList:[],
  users:[]
}

//PROJECT DATA
export const APP_PROJECT_DATA={
  charter: [],
  govScopeChange: [],
  govReview: [],
  shortcut:[],
  action: [],
  riskOpp: []
}

//TO DISPLAY TOAST WITH SPECIFIC COLOR AND MESSAGE
export const APP_TOAST = {
    display:false,
    color:"",
    message:"",
    nbOfmilli:null
}

//CURRENT USER
export const APP_CURRENT_USER={
  user:null,
  refreshLoading: true,
  loggedIn: false,
  signUp: false,
  confirmSignUp:false,
  needVerifyEmail:false,
  needNewPassword: false,
  resetPassword : false,
  needMfaCode: false,
  needMfaSetUp: false,
  errorMessage: null,
  username: "",
  name:"",
  loading: false,
  err: false,
  displayTermsValidation: false,
  coreProjects:[],
  coreOrganization:[],
}

// DEFAULT PORTFOLIOS
export const APP_PORTFOLIO_SELECTION = {
  portfolios: [],
  selectedPortfolio: null,
  portfolioProjects: [],
  showProjetListView: false
}

// DEFAULT PORTFOLIO SPECIFIC CONFIG
export const APP_PORTFOLIO_DISPLAY_CONFIG = {
  rightComponent: {
    name: null,
    data: null,
  },
  wbsNavigationOptions: {...WBS_DEFAULT_NAVIGATION_OPTIONS},
  updateTrackers: {
    action: 0,
    govReview: 0,
    govScopeChange: 0,
    riskOpp: 0,
    schedule: 0,
    charter: 0,
  }
}

export const APP_BOARD_DATA = {
  myActions: [],
  nextReviews: [],
}