import { useCallback, useMemo, useState, useEffect } from "react";
import {
    GOV_REVIEW_AGENDA_DEFAULT_ITEM,
    GOV_REVIEW_MEETING_STATUS_KEY,
} from "../../00-Helpers/GovReviewConstants";
import GovReviewAgendaSideBarAddEditItem from "./GovReviewAgendaSideBarAddEditItem";
import GovReviewAgendaSideBarLegend from "./GovReviewAgendaSideBarLegend";
import GovReviewAgendaSideBarList from "./GovReviewAgendaSideBarList";
import { v4 as uuid } from "uuid";
import { FUNCT_FIND_INDEX } from "../../../../../00-Core/Standards";
import { CvtTag } from "@mi-gso/cvt";

export default function GovReviewAgendaSideBar({
    agendaList,
    editMode,
    handleChange,
    handleOpenWbsItem,
    handleUpdateReviewAgenda,
    inReviewMode,
    projectData,
    reviewStatus,
}) {
    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // LOCAL STATE
    const [state, setState] = useState({
        isAddView: false,
        agendaItem: { ...GOV_REVIEW_AGENDA_DEFAULT_ITEM },
    });

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // PARSED AGENDA LIST
    const parsedAgendaList = useMemo(() => {
        try {
            return JSON.parse(agendaList);
        } catch {
            return [];
        }
    }, [agendaList]);

    // WETHER TO DISPLAY OR NOT LEGEND
    const isLegendDispayed = useMemo(() => {
        if (editMode && inReviewMode && reviewStatus === GOV_REVIEW_MEETING_STATUS_KEY.inProgress) {
            return true;
        } else if (editMode) {
            return true;
        }
        return false;
    }, [editMode, inReviewMode, reviewStatus]);

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // OPEN ADD NEW AGENDA VIEW
    const onOpenNewAgendaView = useCallback((e) => {
        e.preventDefault();

        setState((prevState) => ({
            ...prevState,
            isAddView: true,
        }));
    }, []);

    // GO BACK TO AGENDA LIST VIEW
    const onGoBackToAgendaList = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            isAddView: false,
            agendaItem: { ...GOV_REVIEW_AGENDA_DEFAULT_ITEM },
        }));
    }, []);

    // EDIT AN EXISTING ITEM
    const onEditItem = useCallback(
        (e, options) => {
            e.stopPropagation();
            let findIndex = FUNCT_FIND_INDEX(parsedAgendaList, "id", options.id);
            let agendaItem = parsedAgendaList[findIndex];

            setState((prevState) => ({
                ...prevState,
                agendaItem: agendaItem,
                isAddView: true,
            }));
        },
        [parsedAgendaList]
    );

    // SAVE NEW AGENDA ITEM
    const onSaveAgendaItem = useCallback(
        (e) => {
            let oldAgendaList = [];
            if (agendaList.length > 0) {
                oldAgendaList = [...parsedAgendaList];
            }

            let error = false;
            let isCreating = true;
            let agendaItem;

            // IF ITEM HAS AN ID, EDIT IT
            if (state.agendaItem.id) {
                let findIndex = FUNCT_FIND_INDEX(oldAgendaList, "id", state.agendaItem.id);
                oldAgendaList[findIndex] = state.agendaItem;
                isCreating = false;
            } else {
                // CREATE NEW ITEM
                agendaItem = {
                    createdOn: new Date(),
                    id: uuid(),
                    ...state.agendaItem,
                };

                // ADD ITEM TO LIST IF TITLE NOT EMPTY
                if (agendaItem.title.length > 0) {
                    oldAgendaList.push(agendaItem);
                } else {
                    error = true;
                }
            }

            if (!error) {
                if (inReviewMode) {
                    // IF IS CREATING USE SPECIAL UPDATE AGENDA FUNCTION
                    if (isCreating && agendaItem.itemId) {
                        handleUpdateReviewAgenda(e, agendaItem, false);
                    }
                    // IF IS EDITING JUST USE NORMAL WAY
                    else {
                        handleChange(e, "agenda", JSON.stringify(oldAgendaList));
                    }
                } else {
                    // UPDATE DATA
                    handleChange("agenda", JSON.stringify(oldAgendaList), "govReviewAgenda");
                }

                // GO BACK TO LIST
                onGoBackToAgendaList();
            }
        },
        [
            agendaList.length,
            handleChange,
            handleUpdateReviewAgenda,
            inReviewMode,
            onGoBackToAgendaList,
            parsedAgendaList,
            state.agendaItem,
        ]
    );

    // DELETE AGENDA ITEM
    const onDeleteAgendaItem = useCallback(
        (e, options) => {
            e.stopPropagation();
            e.preventDefault();

            const agendaItemIndex = FUNCT_FIND_INDEX(parsedAgendaList, "id", options.id);

            let oldAgendaList = [...parsedAgendaList];

            let findIndex = FUNCT_FIND_INDEX(oldAgendaList, "id", options.id);
            if (findIndex !== -1) {
                oldAgendaList.splice(findIndex, 1);
            }

            if (inReviewMode) {
                if (parsedAgendaList[agendaItemIndex].itemId) {
                    handleUpdateReviewAgenda(e, parsedAgendaList[agendaItemIndex], true);
                } else {
                    handleChange(e, "agenda", JSON.stringify(oldAgendaList));
                }
            } else {
                // UPDATE DATA
                handleChange("agenda", JSON.stringify(oldAgendaList), "govReviewAgenda");
            }
        },
        [handleChange, handleUpdateReviewAgenda, inReviewMode, parsedAgendaList]
    );

    // HANDLE NEW ITEM FIELDS CHANGE
    const handleInputChange = useCallback(
        (e) => {
            let key = e.target.name;
            let value = e.target.value;

            let agendaItemState = { ...state.agendaItem };

            agendaItemState[key] = value;

            if (key === "type") {
                agendaItemState.itemId = null;
            }

            setState((prevState) => ({
                ...prevState,
                agendaItem: agendaItemState,
            }));
        },
        [state.agendaItem]
    );

    const handleGlobalSaveBtnClick = useCallback((e) => {
        if(state.isAddView && state.agendaItem.title && state.agendaItem.title !== "") {
            onSaveAgendaItem(e);
        }
    }, [onSaveAgendaItem, state.agendaItem.title, state.isAddView]);

    useEffect(() => {
        const globalSaveBtn = document.querySelector(".cvtBtn.cvtBtnOrange");
        if(globalSaveBtn && !inReviewMode) {
            globalSaveBtn.addEventListener("click", handleGlobalSaveBtnClick);
            return () => {
                globalSaveBtn.removeEventListener("click", handleGlobalSaveBtnClick);
            }
        }
    }, [handleGlobalSaveBtnClick, inReviewMode]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <div
            style={{
                width: inReviewMode ? "100%" : "",
                backgroundColor: inReviewMode ? "var(--border-color-gray-00)" : null,
                margin: inReviewMode ? "10px 0px" : null,
                padding: inReviewMode ? "15px 15px 0px" : null,
                borderRadius: inReviewMode ? "10px" : null,
            }}
        >
            {/* TITLE */}
            {inReviewMode ? (
                <div className="flexStartCenter flexGap10">
                    <div className="text_Color2_600_12px">Agenda</div>

                    {reviewStatus !== GOV_REVIEW_MEETING_STATUS_KEY.inProgress ? (
                        <CvtTag
                            color="dark"
                            shape={"round"}
                            txt={
                                parsedAgendaList.length +
                                " item" +
                                (parsedAgendaList.length > 0 ? "s" : "")
                            }
                        />
                    ) : null}
                </div>
            ) : null}

            {/* LEGEND */}
            {isLegendDispayed ? (
                <GovReviewAgendaSideBarLegend
                    isAddView={state.isAddView}
                    agendaItem={state.agendaItem}
                    listLength={parsedAgendaList.length}
                    onOpenNewAgendaView={onOpenNewAgendaView}
                    onGoBackToAgendaList={onGoBackToAgendaList}
                    onSaveAgendaItem={onSaveAgendaItem}
                    editMode={editMode}
                    inReviewMode={inReviewMode}
                />
            ) : null}

            {/* ADD NEW ITEM FORM */}
            {isLegendDispayed && state.isAddView ? (
                <GovReviewAgendaSideBarAddEditItem
                    agendaItem={state.agendaItem}
                    projectData={projectData}
                    handleInputChange={handleInputChange}
                    parsedAgendaList={parsedAgendaList}
                />
            ) : null}

            {/* LIST */}
            {!state.isAddView ||
            (inReviewMode && reviewStatus !== GOV_REVIEW_MEETING_STATUS_KEY.inProgress) ? (
                <GovReviewAgendaSideBarList
                    key={`gov_review_agenda_side_bar_list_${parsedAgendaList.length}`}
                    agendaItems={parsedAgendaList}
                    projectData={projectData}
                    onEditItem={onEditItem}
                    onDeleteAgendaItem={onDeleteAgendaItem}
                    inReviewMode={inReviewMode}
                    editingAgenda={state.agendaItem}
                    editMode={editMode}
                    handleOpenWbsItem={handleOpenWbsItem}
                />
            ) : null}
        </div>
    );
}
