import React, { useCallback, useMemo, useState } from 'react'

//AUTH MINI COMPONENT
import {
    AuthAuthenticatorTitle,
    AuthAuthenticatorForm,
    AuthAuthenticatorFormTitle
} from './AuthAuthenticatorMiniComponents'

//CORE
import { FUNC_PASSWORD_CHECK } from '../../../../00-Core/Standards'
import { CvtInput } from '@mi-gso/cvt'

///////////////////////////////////////////////////////////////////////////////////////
/// Authenticator FIRST CONNECTION ////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export default function AuthAuthenticatorFirstConnection({
    options,
    onChange,
    errorMessage,
    handleFirstConnection,
    handleBackToSignIn
}){
    //STATE
    const [confirmPassword, setConfirmPassword] = useState("")

    //CHANGE CONFIRM PASSWORD
    const onConfirmPassword = useCallback((e) => {
        setConfirmPassword(e.target.value)
    },[])

    //PASSWORD CHECK
    const passWordCheck = useMemo(() => FUNC_PASSWORD_CHECK(options.newPassword), [options.newPassword])

    //RETUNR
    return(
        <React.Fragment>

            {/* TITLE & ERROR */}
            <AuthAuthenticatorTitle 
                title="Welcome to Clayverest"
                errorMessage={errorMessage}
                icon="lock"
            />

            {/* INPUTS BLOCK */}
            <AuthAuthenticatorForm 
                disabled={options.newPassword === "" || !passWordCheck.global || (options.newPassword !== confirmPassword)}
                loader={options.loader}
                text="Update"
                handleBackToSignIn={handleBackToSignIn}
                onSubmit={handleFirstConnection}
                errorMessage={errorMessage}
            >

                {/* FORM TITLE */}
                <AuthAuthenticatorFormTitle 
                    icon="person"
                    text="Please update your information"
                />

                {/* NEEDED FOR PASSWORD USERNAME */}
                <input  autoComplete="username" name="username" onChange={onChange} value="" hidden></input>

                {/* PASSWORD */}
                <CvtInput 
                    type="password"
                    legend="New Password *"
                    inputBlockPadding="20px 20px 15px 20px"
                    placeholder="Enter your new password"
                    inputBlockCss="modalOptionSubBlock"
                    inputCss="form-control appAuthenticatorInput"
                    name="newPassword"
                    value={options.newPassword}
                    onChange={onChange}
                    autoComplete="off"
                />

                {/* CONFIRM PASSWORD */}
                <CvtInput 
                    type="password"
                    legend="Confirm Password *"
                    inputBlockPadding="0px 20px 20px 20px"
                    placeholder="Confirm your password"
                    inputBlockCss="modalOptionSubBlock"
                    inputCss={"form-control appAuthenticatorInput " + (confirmPassword !== "" && confirmPassword !== options.newPassword ? "appAuthenticatorWrongConfirmPassword" : null)}
                    name="password"
                    value={confirmPassword}
                    onChange={onConfirmPassword}
                    autoComplete="new-password"
                    isCheckOk={confirmPassword !== "" && options.newPassword === confirmPassword}
                />

                {/* PASSWORD DETAILS */}
                <div 
                    className="appAuthenticatorPassword flexStartStart"
                    style={{backgroundColor:"white"}}
                >
                        
                    {/* ICON */}
                    <div 
                        className="material-icons appAuthenticatorPasswordIcon"
                        style={{color: passWordCheck.global ? "var(--color-migso-green)" : null}}
                    >
                        lock
                    </div>

                    {/* RULES */}
                    <div className="flexColStartStart">

                        {/* LENGTH */}
                        <div 
                            className="flexColStartStart"
                            style={{fontWeight:500, marginBottom:"5px"}}
                        >
                            Password rules
                            <div 
                                style={{
                                    fontWeight:400, 
                                    fontSize:"13px",
                                }}
                            >
                                <div style={{color: passWordCheck.number ? "var(--color-migso-green)" : null}}>At least 14 characters</div>
                                <div style={{color: passWordCheck.digit ? "var(--color-migso-green)" : null}}>Contains at least 1 number</div>
                                <div style={{color: passWordCheck.lower ? "var(--color-migso-green)" : null}}>Contains at least 1 uppercase letter</div>
                                <div style={{color: passWordCheck.upper ? "var(--color-migso-green)" : null}}>Contains at least 1 lowercase letter</div>
                                <div style={{color: passWordCheck.special ? "var(--color-migso-green)" : null}}>Contains at least 1 special character</div>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthAuthenticatorForm>
        </React.Fragment>
    )
}
