import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import _ from "lodash";
import { GOV_FUNC_GET_REVIEW_LABEL } from "../01-Components/02-Portfolio/04-Governance/00-Helpers/GovFunctions";
import * as queries from "../graphql/queries";
import { AGENDA_ITEM_TYPES_KEYS } from "./Constants";

//////////////////////////////////////////
// SUMMARY                              //
// - FUNC_CHECK_DATE_ISO_FORMAT         //
// - FUNC_PREPARE_AWS_BULK              //
// - FUNC_CONVERT_BLOCK_TO_DATE         //
// - FUNC_IS_NUMBER                     //
// - FUNC_ARRAY_MIN_MAX                 //
// - FUNC_COL_ARRAY_2D                  //
// - FUNC_ADD_ONE_TO_ARRAY_MAX_NUMBER   //
// - FUNC_ARRAY_SUM_ATTRIBUT            //
// - FUNC_PARSE_DATE_FROM_INPUT         //
// - FUNC_GET_WEEK_NUMBER               //
// - FUNC_NB_OF_DAYS                    //
// - FUNC_ZERO_FORMAT_TO_NUM            //
// - FUNC_DATE_TO_TXT_STANDARD          //
// - FUNC_CHECK_DATE_IS_VALID           //
// - FUNC_CHECK_DATE2_IS_AFTER_DATE1    //
// - FUNC_GET_DAYS_BETWEEN_DATES        //
// - FUNC_DATE_IS_TODAY                 //
// - FUNCT_FIND_INDEX                   //
// - FUNCT_FIND_INDEX_ARRAY             //
// - FUNC_FIND_RELATION_ATTRIBUT        //
// - FUNC_FETCH_JIRA_API                //
// - FUNC_UNDO_CHANGES                  //
// - FUNC_REDO_CHANGES                  //
// - FUNC_SAVE__CHANGES_SIDE_BAR        //
// - FUNC_DELETE_SELECTED_SIDE_BAR      //
// - FUNC_DUPLICATE_SELECTED_SIDE_BAR   //
// - FUNC_HANDLE_INPUT_CHANGE           //
// - FUNC_CHECK_URL_VALID               //
// - FUNC_CHECK_ANY_URL_VALID           //
// - FUNC_GET_USER_INFO                 //
// - FUNC_IS_COLOR_LIGHT                //
// - FUNC_IS_HEX_COLOR_VALID            //
// - FUNC_NEED_TO_ADD_EVENT             //
// - FUNC_GET_BIGGEST_DISPLAY_ID        //
// - FUNC_BUILD_DISPLAY_ID_STR
// - FUNC_IS_DATE                       //
// - FUNC_TODAY_MINUS_N_DAYS            //
// - FUNC_GET_TXT_COLOR                 //
// - FUNC_DATE_TO_LOCALE_DATE           //
// - FUNC_GET_NUM_DAYS_BETWEEN_2_DATES  //
// - FUNC_GET_MONDAY_FROM_DATE          //
// - FUNC_ARRAY_TO_CHUNKS               //
// - FUNC_GET_BYTES_FROM_STRING         //
// - FUNC_GET_BATCH_CHUNKS       //
// - FUNC_GET_INPUT_FROM_OBJECT         //
// - FUNC_SAFE_GET_JSON_FROM_STRING     //
// - FUNC_REMOVE_OBJECT_FROM_ARRAY      //
// - FUNC_REMOVE_ELEMENT_FROM_ARRAY     //
// - FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING
// - FUNC_REMOVE_SEVERAL_OBJECTS_FROM_OBJECTS_ARRAY
// - FUNC_IS_DATE1_ONE_MONTH_BEFORE_DATE2
// - FUNC_IS_DATE1_AFTER_DATE2_NO_HOURS // 
// - FUNC_GET_AGENDA_TYPE_ITEMS         //
// - FUNC_CHECK_STRING_IS_DATE          //
//////////////////////////////////////////

export const FUNC_CONVERT_STDR_DATE_TO_DIPLAYED_TXT = (dateTxt, addGmt) => {
  //REPLACE "" to nothing
  let newTxt = dateTxt.replaceAll('"', "");

  //NEW DATE
  let newDate = new Date(newTxt);
  newDate.setTime(newDate.getTime() + addGmt * 60 * 60 * 1000);

  //RETURN GOOD FORMAT
  return newDate.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

//CHECK IF THE DATE TXT IS IN THE STANDARD ISO FORMAT
export const FUNC_CHECK_DATE_ISO_FORMAT = (dateTxt) => {
  //INIT
  let isIsoStandard = false;

  //FORCE SPLIT TO "-"
  let splitDateTxt = dateTxt.toString().split("-");

  //TEST IF 3 FIRST SPLIT ARE
  if (
    splitDateTxt[0].length === 4 &&
    Number(splitDateTxt[0]) &&
    splitDateTxt[1].length === 2 &&
    Number(splitDateTxt[1]) &&
    splitDateTxt[2].length === 2 &&
    Number(splitDateTxt[2])
  ) {
    isIsoStandard = true;
  }

  //RETURN
  return isIsoStandard;
};

// Split the `items` array into multiple, smaller arrays of the given `size`.
export const FUNC_PREPARE_AWS_BULK = (items, size) => {
  //INIT AND CONCAT
  const chunks = [];
  items = [].concat(...items);

  //PUSH
  while (items.length) {
    chunks.push(items.splice(0, size));
  }

  //RETURN
  return chunks;
};

//CONVERT ALL DATE OF AN ARRAY RELATED TO ARRAY ATTRIBUT
export const FUNC_CONVERT_BLOCK_TO_DATE = (data, attrs) => {
  //LOOP ITEMS ARRAY
  return data.map((item) => {
    //LOOP ATTRIBUTES
    for (let j = 0; j < attrs.length; j++) {
      if (item[attrs[j]] && item[attrs[j]] !== "") {
        item[attrs[j]] = new Date(item[attrs[j]]);
      }
    }

    //RETURN CURRENT ITEM
    return item;
  });
};

//IS NUMBER
export const FUNC_IS_NUMBER = (value) => {
  //RETURN TRUE OR FALSE
  return typeof value === "number" && isFinite(value);
};

//RETURN MIN MAX DATE OF AN ARRAY
export const FUNC_ARRAY_MIN_MAX = (array, minAttribut, maxAttribut) => {
  //NEED AT LEAST DATA IN MIN OR MAX ATTRIBUTES
  let arrayWithoutNull = array.filter(
    (item) => item[minAttribut] || item[maxAttribut]
  );
  let minMax = {};

  //GET MIN MAX
  minMax.min = new Date(
    Math.min.apply(
      Math,
      arrayWithoutNull.map(function (o) {
        return o[minAttribut];
      })
    )
  );
  minMax.max = new Date(
    Math.max.apply(
      Math,
      arrayWithoutNull.map(function (o) {
        return o[maxAttribut];
      })
    )
  );

  //RETURN OBJECT
  return minMax;
};

//RETURN MIN DATE OF AN ARRAY
export const FUNC_ARRAY_MIN_VALUE = (array, minAttribut) => {
  //FIND MIN DATE
  let minValue = Math.min.apply(
    Math,
    array.map(function (o) {
      return o[minAttribut];
    })
  );

  //RETURN
  return minValue;
};

//RETURN MIN DATE OF AN ARRAY
export const FUNC_ARRAY_MIN_DATE = (array, minAttribut) => {
  //FIND MIN DATE
  let minValue = new Date(
    Math.min.apply(
      Math,
      array.map(function (o) {
        return o[minAttribut];
      })
    )
  );

  //RETURN
  return minValue;
};

//RETURN MIN DATE OF AN ARRAY
export const FUNC_ARRAY_MAX_DATE = (array, maxAttribut) => {
  //FIND MIN DATE
  let maxValue = new Date(
    Math.max.apply(
      Math,
      array.map(function (o) {
        return o[maxAttribut];
      })
    )
  );

  //RETURN
  return maxValue;
};

//RETURN COLUMN OF AN ARRAY OF ARRAY
export const FUNC_COL_ARRAY_2D = (matrix, col) => {
  //INIT
  let column = [];

  //LOOP ON MATRIX
  for (let i = 0; i < matrix.length; i++) {
    column.push(matrix[i][col]);
  }

  //RETURN ARRAY 2D
  return column;
};

//ADD 1 to max number of an array
export const FUNC_ADD_ONE_TO_ARRAY_MAX_NUMBER = (array, maxAttribut) => {
  //GET MAX NUMBER AND ADD ONE
  return (
    Number(
      Math.max.apply(
        Math,
        array.map(function (o) {
          return o[maxAttribut];
        })
      )
    ) + 1
  );
};

//SUM OF AN ARRAY ATTRIBUT
export const FUNC_ARRAY_SUM_ATTRIBUT = (array, attribut) => {
  //SUM ATTRIBUT
  let total = 0;
  for (let i = 0; i < array.length; i++) {
    total += array[i][attribut];
  }

  //RETURN
  return total;
};

//PARSE DATE FROM INPUT DATE
export const FUNC_PARSE_DATE_FROM_INPUT = (date) => {
  //SPLIT STANDARD
  let b = date.split(/\D/);

  //RETURN DATE
  return new Date(b[0], --b[1], b[2]);
};

//ADD DAYS TO DATE
export const FUNC_ADD_DAYS_TO_DATE = (date, nbDays) => {
  let newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() + nbDays);
  return newDate;
};

//GET WEEK NUMBER FROM DATE
export const FUNC_GET_WEEK_NUMBER = (date) => {
  //GET DATE
  let newDate = new Date(date.getTime());
  newDate.setHours(0, 0, 0, 0);
  newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

  //GET WEEK
  let week1 = new Date(newDate.getFullYear(), 0, 4);

  //RETURN
  return (
    1 +
    Math.round(
      ((newDate.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

//FIND NUMBER OF DAYS OF MONTH WITH A SPECIFIC DATE
export const FUNC_NB_OF_DAYS = (date) => {
  //GET DATE SET TO DAY 1 AND RETURN NUMBER OF DAYS
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

//Transform number to "XXXX" text format WITH 0 IF NEEDED
export const FUNC_ZERO_FORMAT_TO_NUM = (num, size) => {
  //CONVERT NUMBER TO STRING
  num = num.toString();

  //ADD 0 BEFORE IF NEEDED
  while (num.length < size) num = "0" + num;

  //RETURN NUMBER
  return num;
};

export const FUNC_DATE_TO_TXT_CUSTOM_DELIMITATOR = (date, delimitator) => {
   //RETURN FOLLOWING SPECIFIC ISO STANDARD
   return (
    date.getFullYear() +
    delimitator +
    FUNC_ZERO_FORMAT_TO_NUM(date.getMonth() + 1, 2) +
    delimitator +
    FUNC_ZERO_FORMAT_TO_NUM(date.getDate(), 2)
  );
}

//RETURN STANDARD TXT DATE FROM DATE
export const FUNC_DATE_TO_TXT_STANDARD = (date) => {
  //RETURN FOLLOWING SPECIFIC ISO STANDARD
  return (
    date.getFullYear() +
    "-" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getMonth() + 1, 2) +
    "-" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getDate(), 2)
  );
};

// RETURN TXT DATE FROM DATE WITH CUSTOM DELIMITATOR AND ONLY THE VALUES WANTED (YEAR, MONTH, DAY)
export const FUNC_DATE_TO_TXT_CUSTOM = (
  date,
  wantedProperties, // WILL BE ARRAY LIKE ['month', 'year', 'day']. The result will be outputted
  // according to the order of the array
  delimitator,
  sizeOfEachProperty // will be object like {month: 2, year: 3}
) => {
  let dateProperties = [];

  for (let property of wantedProperties) {
    if (property === "year") {
      dateProperties.push(
        date
          .getFullYear()
          .toString()
          .slice(0, sizeOfEachProperty.year + 1)
      );
    } else if (property === "month") {
      let monthTxt;

      if (date.getMonth().toString().length < sizeOfEachProperty.month) {
        monthTxt = FUNC_ZERO_FORMAT_TO_NUM(
          date.getMonth() + 1,
          sizeOfEachProperty.month - date.getMonth().toString().length
        );
      } else {
        monthTxt = date
          .getMonth()
          .toString()
          .slice(0, sizeOfEachProperty.month + 1);
      }
      dateProperties.push(parseInt(monthTxt));
    } else if (property === "day") {
      let dayTxt;

      if (date.getDate().toString().length < sizeOfEachProperty.day) {
        dayTxt = FUNC_ZERO_FORMAT_TO_NUM(
          date.getDate() + 1,
          sizeOfEachProperty.day - date.getDate().toString().length
        );
      } else {
        dayTxt = date
          .getDate()
          .toString()
          .slice(0, sizeOfEachProperty.day + 1);
      }
      dateProperties.push(dayTxt);
    }
  }

  let returnTxt = "";

  for (let property of dateProperties) {
    if (returnTxt === "") {
      returnTxt += property;
    } else {
      returnTxt += `${delimitator}${property}`;
    }
  }

  return returnTxt;
};

//RETURN STANDARD TXT DATE FROM DATE
export const FUNC_DATE_TO_TXT_STANDARD_WITH_TIME = (date) => {
  //RETURN FOLLOWING SPECIFIC ISO STANDARD
  return (
    date.getFullYear() +
    "-" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getMonth() + 1, 2) +
    "-" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getDate(), 2) +
    " / " +
    FUNC_ZERO_FORMAT_TO_NUM(date.getHours(), 2) +
    ":" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getMinutes(), 2)
  );
};

export function FUNC_DATE_TO_INPUT_DATETIME(date) {
  return (
    date.getFullYear() +
    "-" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getMonth() + 1, 2) +
    "-" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getDate(), 2) +
    "T" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getHours(), 2) +
    ":" +
    FUNC_ZERO_FORMAT_TO_NUM(date.getMinutes(), 2)
  );
}

// If date is valid, returns the date with hours to 0, otherwise, returns null
export function FUNC_CHECK_DATE_IS_VALID(dateToTest) {
  //NEW DATE
  let newDate = null;

  //TEST DATE
  if (dateToTest !== "") {
    //INTEGRATE
    newDate = new Date(dateToTest);

    //TEST PLANNED FINISH DATE
    if (
      Object.prototype.toString.call(newDate) === "[object Date]" &&
      !isNaN(newDate.getTime())
    ) {
      newDate.setHours(0, 0, 0, 0);
    } else {
      newDate = null;
    }
  }

  //RETURN
  return newDate;
}

// Returns true if dateAfter > dateBefore
export function FUNC_CHECK_DATE2_IS_AFTER_DATE1(dateBefore, dateAfter) {
  //DATE EXIST
  if (dateBefore && dateAfter) {
    //INIT DATA
    let newDateAfter = Date.parse(dateAfter);
    let newDateBefore = Date.parse(dateBefore);

    //CHECK IF IT4S COHERENT
    if (newDateAfter < newDateBefore) {
      return false;
    }
    return true;
  }

  //RETIURN
  return false;
}

export function FUNC_CHECK_DATES_SAME_DAY(date1, date2) {
  if (date1 && date2) {
    let newDate1 = new Date(date1);
    let newDate2 = new Date(date2);

    return (
      newDate1.getFullYear() === newDate2.getFullYear() &&
      newDate1.getMonth() === newDate2.getMonth() &&
      newDate1.getDate() === newDate2.getDate()
    );
  }
  return false;
}

export function CONVERT_NULL_TO_EMPTY_TXT(object) {
  //GET ALL OBJECTS ATTRIBUTS TO UPDATE
  let attributs = Object.keys(object);
  let newObject = object;

  //IF VALUE OF AN ATTRIBUTE IS NULL CONVERT TO EMPTY TXT
  for (let i = 0; i < attributs.length; i++) {
    if (!newObject[attributs[i]]) {
      newObject[attributs[i]] = "";
    } else if (
      Object.prototype.toString.call(newObject[attributs[i]]) ===
      "[object Date]"
    ) {
      newObject[attributs[i]] = FUNC_DATE_TO_TXT_STANDARD(
        newObject[attributs[i]]
      );
    }
  }

  //RETURN

  return newObject;
}

// Get the number of days between two dates
export function FUNC_GET_DAYS_BETWEEN_DATES(startDate, endDate) {
  if (!startDate || !endDate) {
    return undefined;
  }

  let startDateObj = new Date(startDate);
  let endDateObj = new Date(endDate);

  let dateTimeDiff = endDateObj.getTime() - startDateObj.getTime();

  let dateTimeDiffInDays = dateTimeDiff / (1000 * 3600 * 24);
  return dateTimeDiffInDays;
}

// Returns true if date is today, false if not
export function FUNC_DATE_IS_TODAY(date) {
  let today = new Date();

  if (today.toDateString() === date.toDateString()) {
    return true;
  }
  return false;
}

//findIndex of an item in an array object, specify search column and what are you looking for
export const FUNCT_FIND_INDEX = (data, searchColumn, searchName) => {
  //RETURN INDEX (-1 IF NOT FOUND)
  return data.findIndex((item) => searchName === item[searchColumn]);
};

//findIndex of an item in an array object, specify search column and what are you looking for
export const FUNCT_FIND_INDEX_ARRAY = (data, searchItem) => {
  //RETURN INDEX (-1 IF NOT FOUND)
  return data.findIndex((item) => item === searchItem);
};

//FINC RELATIONShIP ATTRIBUT IN AN ARRAY
export const FUNC_FIND_RELATION_ATTRIBUT = (
  data,
  lookAttr,
  value,
  returnAttr
) => {
  //FIND DATA INDEX RELATED TO ATTRIBUTE
  let findIndex = data.findIndex((item) => item[lookAttr] === value);

  //RETURN USER NEEDS
  return data[findIndex][returnAttr];
};

//FULL SCREEN MODE
export const FUNC_UPDATE_FULLSCREEN = () => {
  //TEST IF ALREADY IN FULLSCREEN
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};

//NUMBER OF DAYS BETWEEN TWO DATES
export const FUNC_NB_OF_DAYS_BETWEEN_TWO_DATES = (date1, date2) => {
  //DIFF
  let diff = date2.getTime() - date1.getTime();

  //CONVERSION
  let days = Math.ceil(diff / (1000 * 3600 * 24));

  //RETURNS
  return days;
};

// SORT DATA
//!!! TO BE REVIEWED !!!!!!!!!!!!!!!!!!
export function FUNC_SORT_DATA(data, attr, order, dataType) {
  let result;

  if (dataType === "date") {
    if (order === "asc") {
      result = data.sort(function (a, b) {
        return Date.parse(a[attr]) - Date.parse(b[attr]);
      });
    } else {
      result = data.sort(function (a, b) {
        return Date.parse(b[attr]) - Date.parse(a[attr]);
      });
    }
  }

  //if type is text
  else if (dataType === "text") {
    if (order === "asc") {
      result = data.sort(function (a, b) {
        if (a[attr] > b[attr]) return -1;
        else if (a < b) return 1;
        else return 0;
      });
    } else {
      result = data.reverse(function (a, b) {
        if (a[attr] > b[attr]) return -1;
        else if (a < b) return 1;
        else return 0;
      });
    }
  }
  //if type is integer
  else {
    if (order === "asc") {
      result = data.sort(function (a, b) {
        return a[attr] - b[attr];
      });
    } else {
      result = data.sort(function (a, b) {
        return b[attr] - a[attr];
      });
    }
  }

  return result;
}

//TXT TO DATE
export const FUNC_TXT_GLOBAL_TO_DATE = (format, split, dateTxt) => {
  //INIT - DATA STRING IN CASE WE PASSE A NUMBER TO TEST THE CONVERSION
  let dateString = dateTxt.toString();
  let txtSplit = dateString.split(split);
  let day;
  let month;
  let year;

  //TEST SI LENGTH !== 2
  if (txtSplit.length !== 3) {
    return null;
  }

  //EN FONCTION FORMATA
  switch (format) {
    //DD-MM-YYYY || DD/MM/YYYY
    case "DDMMYYYY":
      day = parseInt(txtSplit[0], 10);
      month = parseInt(txtSplit[1], 10) - 1;
      year = parseInt(txtSplit[2], 10);

      break;

    //DD-MM-YY || DD/MM/YY
    case "DDMMYY":
      day = parseInt(txtSplit[0], 10);
      month = parseInt(txtSplit[1], 10) - 1;
      year = parseInt("20" + txtSplit[2], 10);

      break;

    //MM-DD-YYYY || MM/DD/YYYY
    case "MMDDYYYY":
      day = parseInt(txtSplit[1], 10);
      month = parseInt(txtSplit[0], 10) - 1;
      year = parseInt(txtSplit[2], 10);

      break;

    //MM-DD-YY || MM/DD/YY
    case "MMDDYY":
      day = parseInt(txtSplit[1], 10);
      month = parseInt(txtSplit[0], 10) - 1;
      year = parseInt("20" + txtSplit[2], 10);

      break;

    //YYYY-MM-DD || YYYY/MM/DD
    case "YYYYMMDD":
      day = parseInt(txtSplit[2], 10);
      month = parseInt(txtSplit[1], 10) - 1;
      year = parseInt(txtSplit[0], 10);

      break;

    //YY-MM-DD || YY/MM/DD
    case "YYMMDD":
      day = parseInt(txtSplit[2], 10);
      month = parseInt(txtSplit[1], 10) - 1;
      year = parseInt("20" + txtSplit[0], 10);

      break;

    //YYYY-DD-MM || YYYY/DD/MM
    case "YYYYDDMM":
      day = parseInt(txtSplit[1], 10);
      month = parseInt(txtSplit[2], 10) - 1;
      year = parseInt(txtSplit[0], 10);

      break;

    //YY-DD-MM || YY/DD/MM
    case "YYDDMM":
      day = parseInt(txtSplit[1], 10);
      month = parseInt(txtSplit[2], 10) - 1;
      year = parseInt("20" + txtSplit[0], 10);

      break;

    default:
      day = 31;
      month = 12;
      year = 2999;
  }

  //RETURN DATE
  return new Date(year, month, day);
};

//GET RANDOM INDEX OF AN ARRAY
export const RANDOM_ARRAY_INDEX = (array) => {
  let randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

//GET FIRST DAY OF A WEEK NUMBER
export const FUNC_GET_WEEK_INFO_FROM_WEEK2 = (week, year) => {
  let weekInfos = {};
  weekInfos.number = week;
  const firstDay = new Date(year, 0, 1).getDay();
  const d = new Date("Jan 01, " + year + " 01:00:00");
  const w = d.getTime() - 3600000 * 24 * (firstDay - 1) + 604800000 * week;
  weekInfos.firstDay = new Date(w);
  weekInfos.lastDay = new Date(w + 518400000);
  return weekInfos;
};

//GET FIRST DAY OF A WEEK NUMBER
export const FUNC_GET_WEEK_INFO_FROM_WEEK = (week, year) => {
  let weekInfos = {};
  weekInfos.number = week;
  weekInfos.year = year;
  // const firstDay = new Date(year, 0, 1).getDay();
  // const d = new Date("Jan 01, " + year + " 00:00:00");
  // const w = d.getTime() - 3600000 * 24 * (firstDay - 1) + 604800000 * week;
  // weekInfos.firstDay = new Date(w);
  // weekInfos.lastDay = new Date(w + 518400000);

  const d = new Date("Jan 01, " + year + " 01:00:00");
  const w = d.getTime() + 604800000 * (week - 1);
  weekInfos.firstDay = new Date(w);
  weekInfos.lastDay = new Date(w + 518400000);

  return weekInfos;
};

//GET WEEK FIRST DAY
export const FUNC_GET_WEEK_FIRST_DAY = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDay(),
    diff = newDate.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(newDate.setDate(diff));
};

//GET LAST DAY WEEK FROM FIRST DAY WEEK
export const FUNC_GET_LAST_DAY_FROM_FIRST_DAY = (date) => {
  let DiffDay = date.getDate() - date.getDay() + 7;
  let lastDay = new Date(date);
  return new Date(lastDay.setDate(DiffDay));
};

//WEEK INFORMATION
export const FUNC_GET_WEEK_INFO_FROM_DATE = (date) => {
  let weekInfos = {};
  weekInfos.number = FUNC_GET_WEEK_NUMBER(date);
  weekInfos.firstDay = FUNC_GET_WEEK_FIRST_DAY(date);
  weekInfos.lastDay = FUNC_GET_LAST_DAY_FROM_FIRST_DAY(weekInfos.firstDay);
  return weekInfos;
};

//!!!!!!!
// Given two action object, check if they are equal (have same value for the same keys)
export const FUNC_ARE_ITEM_SAME = (obj1, obj2, isAdd) => {
  // return false in the condition, so that we don't continue the tests if
  // a difference is found
  for (let obj1Key of Object.keys(obj1)) {
    // Transform value to null if its empty for easier check later

    let obj1Value =
      obj1[obj1Key] !== undefined &&
      obj1[obj1Key] !== null &&
      obj1[obj1Key].length === 0
        ? null
        : obj1[obj1Key];

    let obj2Value =
      obj2[obj1Key] !== undefined &&
      obj2[obj1Key] !== null &&
      obj2[obj1Key].length === 0
        ? null
        : obj2[obj1Key];

    if (
      // If neither of the values for this key are undefined
      !(obj1[obj1Key] === undefined && obj2[obj1Key] === undefined) &&
      // and if the value is not equal for both objects
      obj1Value !== obj2Value &&
      // If isAdd, then ignore the id property
      !(isAdd && obj1Key === "id") &&
      // IGNORE is changed something
      !(obj1Key !== "isChangedSomething")
    ) {
      return false;
    }
  }

  return true;
};

//!!!!!!!
// This function takes in 2 objects and 1 function to compare the objects, and isEdit and isAdd (from the components state).
// It is used for checking whether the user changed something or not when closing the create/edit component.
export const isChanged = (
  defaultObj,
  originalObj,
  objToCompare,
  compareFunc,
  isAdd,
  isEdit
) => {
  // If user is either editing or creating check for object equality
  if (isAdd || isEdit) {
    // If isAdd then compare state.actionItem to defaultActionItem,
    // otherwise find the original action in the data passed to ActionBig and compare that to state.actionItem

    // If they're not the same, then return true
    return !compareFunc(objToCompare, isAdd ? defaultObj : originalObj, isAdd);
  }
  // Return false by default.
  return false;
};

//Conversion number to MILIONS
export const numberFormatMillions = (number) => {
  const options = {
    style: "decimal",
    currency: "EUR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };
  const numberFormat = new Intl.NumberFormat("fr-EU", options);
  return numberFormat.format(number / 1000);
};

//FORMAT number millier
export const numberFormatMilliers = (number) => {
  const numberFormat = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
  return numberFormat;
};

//!!!!
export const numberFormatSwitch = (number, numberFormat) => {
  //INTIT
  let newNumber = number;

  //WHICH CONVERSION
  switch (numberFormat) {
    //MILIONS EUROS
    case "M€":
      newNumber = numberFormatMillions(newNumber) + " M€";
      break;

    //MILIER EUROS
    case "k€":
      newNumber = numberFormatMilliers(newNumber) + " k€";
      break;

    default:
      break;
  }

  //RETURN
  return newNumber;
};

///////////////////////////////////////////////////////////////
/// CONCA ORGANIZATION / PROJECT MEGA USER AND PROJECT USER ///
///////////////////////////////////////////////////////////////

export const CORE_GET_PROJECT_USERS = (
  orgaMegaUsersDetails,
  projectMegaUsersDetails,
  projectUsersDetails
) => {
  //CONCA AND DUPPLICATE ELIMINATION
  let concaUsersDetails = orgaMegaUsersDetails
    .concat(projectMegaUsersDetails)
    .concat(projectUsersDetails);

  const finalUsersList = [
    ...new Map(
      concaUsersDetails.map((item) => (item ? [item.value, item] : []))
    ).values(),
  ];

  //GET UNIQUE
  return finalUsersList;
};

// COMMON FILTER FUNCTION

// Fetch / Post data to Jira
export async function FUNC_FETCH_JIRA_API(baseUrl, verb, method, body, token) {
  let url = `${baseUrl}/${verb}`;
  let headers = new Headers();

  headers.set("Authorization", "Bearer " + token);
  headers.set("Accept", "application/json");

  if (method === "POST") {
    headers.set("Content-Type", "application/json");
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: body ? JSON.stringify(body) : null,
  })
    .then((response) => (response.status !== 204 ? response.json() : response))
    .then((data) => {
      return data;
    });
}

// Common handleChange function
export function FUNC_HANDLE_INPUT_CHANGE(e, setState, level2Key) {
  let key = e.target.name;
  let value = e.target.value;
 
  if (level2Key) {
    setState((prevState) => ({
      ...prevState,
      [level2Key]: {
        ...prevState[level2Key],
        [key]: value,
      },
    }));
  } else {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }
}

// GETS USER INFO FROM USERS LIST (IF EXISTING THERE) OR FROM CORE USERS LIST
export const FUNC_GET_USER_INFO = (usersList, megaUsersId, userId) => {
  //TEST IF USER AVAILABLE
  let user = usersList.find((user) => user.value === userId);

  //OK
  if (user) {
    return user;

    //NOT OK
  } else {
    //TEST IF WE CAN PASS MEGA USER
    if (megaUsersId && megaUsersId.length > 0) {
      return usersList.find((user) => user.value === megaUsersId[0].id);

      //RETURN FIRST USER
    } else {
      return usersList[0];
    }
  }
};

// GETS USER INFO FROM USERS LIST (IF EXISTING THERE) OR FROM CORE USERS LIST
export const FUNC_GET_USER_HOME = (usersList, megaUsersId, userId) => {
  //TEST IF USER AVAILABLE
  let user = usersList.find((user) => user.value === userId);

  //OK
  if (user) {
    return user;

    //NOT OK
  } else {
    //TEST IF WE CAN PASS MEGA USER
    if (megaUsersId && megaUsersId.length > 0) {
      let megaUser = usersList.find((user) => user.value === megaUsersId[0]);
      if (megaUser) return megaUser;
    }
  }

  //RETURN FIRST USER PER DEFAULT
  return usersList[0];
};

//-----------------------------------------------

// CHECK A URL IS VALID AND IN HTTPS
export function FUNC_CHECK_URL_VALID(urlToCheck) {
  let url;

  try {
    url = new URL(urlToCheck);
  } catch (e) {
    return false;
  }

  return url.protocol === "https:";
}

export function FUNC_CHECK_ANY_URL_VALID(urlToCheck) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[#@!()&%/:-a-zA-Z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?$",
    "i"
  ); // fragment locator
  return (
    pattern.test(urlToCheck) === true &&
    (urlToCheck.indexOf("http://") === 0 ||
      urlToCheck.indexOf("https://") === 0)
  );
}

// GIVEN A COLOR (#123fab) RETURN TRUE IF ITS A LIGHT COLOR, OTHERWISE ITS A DARK COLOR
export function FUNC_IS_COLOR_LIGHT(color) {
  let hex = color.replace("#", "");
  let c_r = parseInt(hex.substr(0, 2), 16);
  let c_g = parseInt(hex.substr(2, 2), 16);
  let c_b = parseInt(hex.substr(4, 2), 16);
  let brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
}

// CHECKS IF HEXADECIMAL IS A REPRESENTATION OF A COLOR
export function FUNC_IS_HEX_COLOR_VALID(hex) {
  return hex.match(/^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/i);
}

// TEST IF NEED TO ADD AN EVENT
export const FUNC_NEED_TO_ADD_EVENT = (eventsArray, key, userId) => {
  //TODAY DATE
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  //TRY TO FIND ONE
  let event = eventsArray.find((item) => {
    //CURRENT DATE
    let currentDate = new Date(item.createdOn);
    currentDate.setHours(0, 0, 0, 0);

    //TEST KEY SAME / DATE SAME / USER SAME
    if (
      item?.value?.toUpperCase()?.includes(key?.toUpperCase()) &&
      currentDate.getTime() === today.getTime() &&
      item?.user?.id === userId
    )
      return true;
    return false;
  });

  //RETURN FINAL / IF ALREADY EVENT ON SAME DATE WE DO NOT NEED TO AD NEW ONE
  if (event) return false;
  return true;
};

// GIVEN AN ARRAY OF ITEMS THAT HAVE A DISPLAY ID, GET THE BIGGEST ID NUM
export const FUNC_GET_BIGGEST_DISPLAY_ID = (
  // ARRAY OF ITEMS
  data,
  // NUM OF CHARS UNTIL NUM (example: 'AC0120', numCharsPrefix = 2)
  numCharsPrefix
) => {
  // TEST IF NOT EMPTY
  if (data.length > 0) {
    // INIT
    let cloneData = _.cloneDeep(data);

    // SORT ALL DATA BY DISPLAT ID
    cloneData = cloneData.sort((a, b) => {
      // GET NUMBER WITHOUT TEXT PREFIX
      let aDisplayIdNum =
        a.displayId !== "" ? parseInt(a.displayId.slice(numCharsPrefix)) : 0;
      let bDisplayIdNum =
        b.displayId !== "" ? parseInt(b.displayId.slice(numCharsPrefix)) : 0;

      // CALCULATE
      if (bDisplayIdNum - aDisplayIdNum > 0) {
        return 1;
      } else {
        return -1;
      }
    });

    // RETURN
    return parseInt(cloneData[0].displayId.slice(numCharsPrefix)) + 1;
  }

  // RETURN 1 IF NO DATA
  return 1;
};

// RETURN A DISPLAY ID LIKE AC0012
export function FUNC_BUILD_DISPLAY_ID_STR(startStr, idInt) {
  return startStr + FUNC_ZERO_FORMAT_TO_NUM(idInt, 4);
}

// GIVEN ANY KIND OF DATA ARGUMENT, CHECK IF IT IS A DATE
export const FUNC_IS_DATE = (value) =>
  new Date(value) !== "Invalid Date" && !isNaN(new Date(value));

// RETURNS A DATE MINUS N DAYS
export const FUNC_TODAY_MINUS_N_DAYS = (n) => {
  let date = new Date().setDate(new Date().getDate() - n);
  return new Date(date);
};

// GET CORRECT TEXT COLOR ACCORDING TO BACKGROUND COLOR CONTRAST
export const FUNC_GET_TXT_COLOR = (backgroundColor) => {
  let isBackgroundLight = FUNC_IS_COLOR_LIGHT(backgroundColor);

  return isBackgroundLight === true ? "const(--color-text-3)" : "white";
};

// RETURN STANDARD DISPLAYABLE DATE
export function FUNC_DATE_TO_LOCALE_DATE(date) {
  return new Date(date).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}

// FUNC_GET_NUM_DAYS_BETWEEN_2_DATES
export const FUNC_GET_NUM_DAYS_BETWEEN_2_DATES = (date1, date2) => {
  let date1Correct = typeof date1 === "string" ? new Date(date1) : date1;
  let date2Correct = typeof date2 === "string" ? new Date(date2) : date2;

  let differenceMilliseconds = date2Correct.getTime() - date1Correct.getTime();

  let totalDays = Math.ceil(differenceMilliseconds / (1000 * 3600 * 24));

  return totalDays;
};

// GIVEN X DATE, GET DATE OF THE MONDAY OF THAT WEEK
export const FUNC_GET_MONDAY_FROM_DATE = (date) => {
  let dateCorrect = typeof date === "string" ? new Date(date) : date;

  let first = dateCorrect.getDate() - dateCorrect.getDay() + 1;

  return new Date(dateCorrect.setDate(first));
};

// GIVEN ARRAY AND NUM OF ITEMS PER CHUNKS, DIVIDE IT INTO AN ARRAY OF CHUNKS OF THAT SIZE OR SMALLER (IF LAST ONE DOESN'T HAVE ENOUGH)
export const FUNC_ARRAY_TO_CHUNKS = (originalArray, perChunk) => {
  // INIT
  let i = 0;
  const n = originalArray.length;
  let chunks = [];

  while (i < n) {
    chunks.push(originalArray.slice(i, (i += perChunk)));
  }

  return chunks;
};

// GET NUM OF BYTES OF ANY STRING
export const FUNC_GET_BYTES_FROM_STRING = (s) => {
  return encodeURI(s).split(/%..|./).length - 1;
};

// GIVEN QUERY NAME + MUTATION ITEMS, DIVIDE THE ARRAY OF MUTATION ITEMS INTO CHUNKS
// SUCH THAT IT DOESN'T NOT EXCEED 1MB PER API CALL
export const FUNC_GET_BATCH_CHUNKS = (mutationArray) => {
  // 1MB = 1,000,000 BYTES

  // THIS WILL BE IN EVERY CALL, SO NEED TO SUBTRACT THIS WHEN DIVING THE ARRAY
  const bytesToSubtract = FUNC_GET_BYTES_FROM_STRING(
    `
      mutation batchMutation {}
    `
  );

  // INIT RESULT CHUNKS ARRAY
  let resultChunks = [[]];

  // INIT CURRENT BYTE COUNT
  let currentByteCount = bytesToSubtract;

  // INIT CURRENT CHUNK INDEX
  let currentIndex = 0;

  // LOOP THROUGH EACH MUTATION STRING
  for (let mutation of mutationArray) {
    // GET BYTES FROM CURRENT MUTATION STRING
    const bytes = FUNC_GET_BYTES_FROM_STRING(mutation);

    // IF OVERFLOWS FROM CURRENT COUNT
    if (currentByteCount + bytes > 100000) {
      // PUSH NEW CHUNK TO RESULT CHUNKS ARRAY
      resultChunks.push([mutation]);

      // UPDATE THE CURRENT BYTE COUNT TO BE THE BYTES OF THE CURRENT MUTATION STRING + THE ONES FROM THE WRAPPER
      currentByteCount = bytes + bytesToSubtract;

      // UPDATE THE CURRENT CHUNK INDEX
      currentIndex += 1;
    }
    // ELSE PUSH TO SAME CHUNK
    else {
      // PUSH TO INDEX
      resultChunks[currentIndex].push(mutation);

      // INCREMENT BYTE COUNT
      currentByteCount += bytes;
    }
  }

  // RETURN THE RESULT CHUNKS ARRAY
  return resultChunks;
};

// CHECK IF STRING IS JSON
export const FUNC_IS_STRING_JSON = (s) => {
  try {
    const json = JSON.parse(s);
    if (typeof json === "number") return false;
    return true;
  } catch (error) {
    return false;
  }
};

// GIVEN A STRING, RETURN STRING WITH ESCAPED QUOTES AND BACKSLASHES
export const FUNC_GET_ESCAPED_STRING = (s) => {
  return s.replaceAll("\\", "\\\\").replaceAll('"', '\\"');
};

// GET THE INPUT STRING FROM THE OBJECT
export const FUNC_GET_INPUT_FROM_OBJECT = (mutateObj) => {
  // INIT RETURN STRING
  let resultString = "";

  const mutateObjKeys = Object.keys(mutateObj);

  // LOOP THROUGH EACH PROPERTY
  for (let i = 0, len = mutateObjKeys.length; i < len; i++) {
    // GET VALUE
    const value = mutateObj[mutateObjKeys[i]];
    // CHECK IF IS LAST
    const isLast = i === len - 1;
    // IF ARRAY
    if (Array.isArray(value)) {
      resultString += `${mutateObjKeys[i]}: [${value.map(
        (innerValue) => `"${innerValue}"`
      )}]${!isLast ? "," : ""} \n`;
    }
    // CHECK IF IS JSON
    else if (typeof value === "string" && FUNC_IS_STRING_JSON(value)) {
      resultString += `${mutateObjKeys[i]}: ${JSON.stringify(value)}${
        !isLast ? "," : ""
      } \n`;
    }
    // IF STRING
    else if (typeof value === "string") {
      resultString += `${mutateObjKeys[i]}: "${FUNC_GET_ESCAPED_STRING(
        value
      )}"${!isLast ? "," : ""} \n`;
    }
    // IF DATE
    else if (Object.prototype.toString.call(value) === "[object Date]") {
      const newDate = new Date(value).toISOString();
      resultString += `${mutateObjKeys[i]}: "${newDate}"${
        !isLast ? "," : ""
      } \n`;
    }
    // IF ANY OTHER
    else {
      resultString += `${mutateObjKeys[i]}: ${value}${!isLast ? "," : ""} \n`;
    }
  }

  // RETURN RESULT
  return resultString;
};

// GET LATEST QUERY RESULT OBJECT
export const GET_LATEST_QUERY_TO_RESULT_OBJECT = (queryNames) => {
  let returnObject = {};

  for (let queryName of queryNames) {
    const queryTxt = queries[queryName];

    if (queryTxt) {
      const splitByCurly = queryTxt.split("{");

      if (splitByCurly[3]) {
        const itemsReturnTxt = splitByCurly[3].split("}");

        if (itemsReturnTxt[0]) {
          const processedReturnTxt = itemsReturnTxt[0].replaceAll(/\n/g, "");

          returnObject[queryName] = processedReturnTxt;
        }
      }
    }
  }

  return returnObject;
};

// RETURNS JSON OBJECT IF NOT ALREADY
export function FUNC_SAFE_GET_JSON_FROM_STRING(data) {
  // IF NOT JSON, PARSE AND RETURN
  if (typeof data === "string") {
    try {
      return JSON.parse(data);
    } catch {
      return null;
    }
  }

  return _.cloneDeep(data);
}

// RETURNS A NEW ARRAY WITHOUT THE ITEM
export function FUNC_REMOVE_OBJECT_FROM_ARRAY(array, field, value) {
  let newArray = array.filter((item) => item[field] !== value);

  return newArray;
}

// RETURNS A NEW ARRAY WITHOUT THE ELEMENT (String or value)
export function FUNC_REMOVE_ELEMENT_FROM_ARRAY(array, value) {
  let newArray = array.filter((element) => element !== value);

  return newArray;
}

// RETURN THE OBJECTS THAT ARE IN array1, BUT NOT IN array2
export function FUNC_GET_DIFFERENCE_BETWEEN_OBJECTS_ARRAY(
  array1,
  array2,
  field
) {
  let difference = [];
  array1.forEach((array1Item) => {
    // TRY TO FIND THE ITEM IN THE OTHER ARRAY
    let foundItem = FIND_OBJECT_ARRAY_ITEM(array2, field, array1Item[field]);

    // IF ITEM DOES NOT EXISTS, NEED TO DELETE THE LINK
    if (!foundItem) {
      difference.push(array1Item);
    }
  });

  return difference;
}

// RETURN A PARSED ARRAY FROM A SUPPOSED JSON STRING
export function FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(data) {
  // PARSE TO JSON IF NOT ALREADY
  let parsed = FUNC_SAFE_GET_JSON_FROM_STRING(data);

  // IF NO DATA, INIT TO EMPTY
  if (!parsed) {
    return [];
  }

  // RETURN PARSED JSON
  return parsed;
}

// REMOVE SEVERAL ITEMS FROM A LIST BASED ON FIELD
export function FUNC_REMOVE_SEVERAL_OBJECTS_FROM_OBJECTS_ARRAY(
  data,
  objectsToRemove,
  field
) {
  // GET IDS LIST TO REMOVE
  let idsToRemove = objectsToRemove.map((obj) => obj[field]);

  // REMOVE THESE IDS FROM DATA ARRAY
  let filteredData = data.filter((item) => !idsToRemove?.includes(item[field]));

  return filteredData;
}
export const FUNC_IS_DATE1_ONE_MONTH_BEFORE_DATE2 = (date1, date2) => {
  if (date1.getFullYear() <= date2.getFullYear()) {
    if (date1.getFullYear() === date2.getFullYear()) {
      return date1.getMonth() === date2.getMonth() - 1;
    } else if (date1.getFullYear() === date2.getFullYear() - 1) {
      return date1.getMonth() === 11 && date2.getMonth() === 0;
    } else return false;
  } else return false;
};

export const FUNC_GET_AGENDA_TYPE_ITEMS = (type, projectData) => {
  let nameKey;
  switch (type) {
    case AGENDA_ITEM_TYPES_KEYS.risk:
      nameKey = "name";
      break;
    case AGENDA_ITEM_TYPES_KEYS.action:
      nameKey = "action";
      break;
    case AGENDA_ITEM_TYPES_KEYS.scopeChange:
      nameKey = "title";
      break;
    case AGENDA_ITEM_TYPES_KEYS.review:
      nameKey = "name";
      break;
    case AGENDA_ITEM_TYPES_KEYS.schedule:
      nameKey = "name";
      break;
    default:
      nameKey = null;
  }
  if (!nameKey) return [];
  
  return projectData[type].map((item) => ({
    id: item.id,
    label: type === "govReview" ? GOV_FUNC_GET_REVIEW_LABEL(item) :  item.displayId + " : " + item[nameKey],
  }));
};


export const FUNC_GET_AUTHORIZED_DATA = (projectData, currentUserId, isMegaUser) => {
  if(isMegaUser) return projectData;
  let authorizedProjectData = _.cloneDeep(projectData);

  for(const domain of Object.keys(projectData)) {
    let authorizedItems = [];

    for(const item of projectData[domain]) {
      const isPassCreatedBy = item.createdBy && item.createdBy !== "" && item.createdBy === currentUserId;
      const isPassResponsible = item.responsible && item.responsible !== '' && item.responsible === currentUserId;
     
      if(isPassCreatedBy || isPassResponsible) {
        authorizedItems.push(item);
      } 
      
    }

    // ASSIGN TO AUTHENTORIZED PROJECT DATA
    authorizedProjectData[domain] = authorizedItems;
  }
  
  return authorizedProjectData;
}


export const FUNC_CHECK_STRING_IS_DATE = (dateString) => {
  const dateFormat = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  if(!dateString.match(dateFormat))
    return false;

  const parts = dateString.split("/");
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  if(year < 1000 || year > 3000 || month === 0 || month > 12)
    return false;

  const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  return day > 0 && day <= monthLength[month - 1];
}

export const FUNC_IS_DATE1_AFTER_OR_EQUAL_DATE2_NO_HOURS = (date1, date2) => {
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return date1 >= date2;
}

// INCREMENT AN UPDATE TRACKER OF SOURCE, IF EXISTS
export function APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, source) {
  let toReturn = updateTrackers;

  // IF UPDATE TRACKER EXISTS FOR THIS SOURCE, INCREMENT IT
  if (toReturn[source] !== undefined) {
      toReturn[source] += 1;
  }

  return toReturn;
}

//PASSWORD CHECK
export const FUNC_PASSWORD_CHECK = (
  password
) =>{

  //INIT
  let test = {
    global:false,
    number:false,
    digit:false,
    lower:false,
    upper:false,
    special:false
  }

  //LENGTH
  if(password.length > 13){
    test.number = true
  }

  //DIGIT
  if(/\d/.test(password)) test.digit = true

  //LOWER CASE
  if(/[a-z]/.test(password)) test.lower = true

  //LOWER CASE
  if(/[A-Z]/.test(password)) test.upper = true

  //SPECIAL
  if(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) test.special = true

  //GLOBAL
  if(test.number 
  && test.digit 
  && test.lower 
  && test.upper 
  && test.special){
    test.global = true
  }

  //RETURN 
  return test
}
