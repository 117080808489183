import { ACTION_STATUS_KEY } from "../../02-Portfolio/01-Action/00-Helpers/ActionsConstants";

export default function BoardTopActionsStatusTag({ isSelected, handleStatusTagClick, item }) {
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className={
                "actionsTableLegendTag flexCenterCenter " +
                (isSelected ? "actionsTableLegendTagSelected" : "")
            }
            onClick={() => {
                if (handleStatusTagClick) {
                    handleStatusTagClick(item.id);
                }
            }}
            style={{
                background: item.backgroundColor,
                opacity: !isSelected && item.id === ACTION_STATUS_KEY.done ? "0.5" : null,
                boxShadow: !handleStatusTagClick ? "none" : null,
            }}
        >
            {/* NAME */}
            <span style={{ color: item.color }}>{item.name}</span>
        </div>
    );
}
