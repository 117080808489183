import React, { memo, useCallback, useState } from "react";

//ACTION
import ActionCardInfos from "./ActionCardInfos";
import ActionCardDetails from "./ActionCardDetails";

/////////////////////////////
/// ACTION CARD COMPONENT ///
/////////////////////////////

export default memo(function ActionCard({
    //DATA
    action,
    actionId,
    projectData,
    wbsList,
    editSideBar,
    viewModeInputText,
    viewModeInputComment,
    selectedViewModeContent,
    isEnrichTextEditing,
    isReadOnlyMode,
    displayWidth,
    isExpanded,
    canModifySelected,

    //USERS
    currentUser,
    usersList,
    megaUsersId,
    securityGroup,

    //FUNCTIONS
    onDeleteComment,
    onSaveAddEdit,
    handleEditFromComponent,

    //DISPATCHER
    actionsDispatcher,
    appDispatcher,
    wbsDispatcher,
}) {
   
    /////////////////////////////////////////////////////////////////////////////////////
    /// STATE MANAGEMENT ////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////

    const [state, setState] = useState({
        selected: selectedViewModeContent,
        keyIncrement: 0,
        editing: null,
    });

    //////////////////////////////////////////////////////////////////////////////////////
    /// FUNCTIONS ////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////

    //EDIT A COMMENT ------------------------------------------------------------------ OK
    const handleEdit = useCallback(
        (e, options) => {
            //UPDATE ACTION CARD STATE
            setState((prevState) => {
                //INIT VALUE
                let editing = prevState.editing === options.commentId ? null : options.commentId;

                //FOCUS ON INPUT
                if (editing) {
                    const inputEdition = document.getElementsByClassName("cardDetailsCommentInput");
                    inputEdition[0].focus();
                }

                //RETURN
                return {
                    ...prevState,
                    editing: editing,
                };
            });

            //UPDATE ACTIONS DIPATCHER
            actionsDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: "viewModeInputComment",
                value: state.editing === options.commentId ? "" : options.text,
            });
        },
        [actionsDispatcher, state.editing]
    );

    //UPDATE ACTION ------------------------------------------------------------------- OK
    const handleUpdateAction = useCallback(
        (e, key, value) => {
            e.preventDefault();

            //PREPARE MUTATION OBJECT
            let mutationObject = {
                id: actionId,
                [key]: value,
            };

            //CALL ON SAVE
            onSaveAddEdit(e, mutationObject, false);

            //THIS STATE
            setState((prevState) => {
                return {
                    ...prevState,
                    editing: null,
                };
            });
        },
        [actionId, onSaveAddEdit]
    );

    //SELECT CHAPTER ON DETAILS BLOCK -------------------------------------------------
    const handleSelectOption = useCallback(
        (e, newSelected) => {
            e.preventDefault();

            //ONLY IF DIFFERENT
            if (newSelected !== state.selected) {
                //THIS STATE
                setState((prevState) => {
                    return {
                        ...prevState,
                        selected: newSelected,
                    };
                });

                // UPDATE ACTIONS SELECTED VIEW MODE CONTENT
                actionsDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        selectedViewModeContent: newSelected,
                    },
                });
            }
        },
        [actionsDispatcher, state.selected]
    );

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////
     
    return (
        <div
            className="flexStartStart"
            style={{
                height: "100%",
                width: displayWidth,
            }}
        >
            {/* ACTION INFO WITH PROGRESSION + DESCRIPTION */}
            <ActionCardInfos
                action={action}
                editSideBar={editSideBar}
                viewModeInputText={viewModeInputText}
                isEnrichTextEditing={isEnrichTextEditing}
                handleEditFromComponent={handleEditFromComponent}
                usersList={usersList}
                megaUsersId={megaUsersId}
                handleUpdateAction={handleUpdateAction}
                actionsDispatcher={actionsDispatcher}
                isReadOnlyMode={isReadOnlyMode}
                displayNone={displayWidth === "0%"}
                isExpanded={isExpanded}
                canModifySelected={canModifySelected}
            />

            {/* DETAILS RIGHT SIDE / COMMENTS - CONNECTION - EVENT */}
            <ActionCardDetails
                action={action}
                actionComments={action ? action.comments : null}
                actionEvents={action ? action.events : null}
                editingCommentId={state.editing}
                wbsList={wbsList}
                viewModeInputComment={viewModeInputComment}
                selected={state.selected}
                handleSelectOption={handleSelectOption}
                handleEdit={handleEdit}
                handleUpdateAction={handleUpdateAction}
                onDeleteComment={onDeleteComment}
                usersList={usersList}
                megaUsersId={megaUsersId}
                currentUser={currentUser}
                actionsDispatcher={actionsDispatcher}
                isReadOnlyMode={isReadOnlyMode}
                displayNone={displayWidth === "0%"}
                securityGroup={securityGroup}
                projectData={projectData}
                appDispatcher={appDispatcher}
                wbsDispatcher={wbsDispatcher}
                canModifySelected={canModifySelected}
            />
        </div>
    );
});


