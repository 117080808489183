//APP
import {
  APP_INIT_SETTINGS,
  APP_TOAST, 
  APP_CURRENT_USER, 
  APP_CORE_SELECTION,
  APP_PROJECT_DATA,
  APP_PORTFOLIO_SELECTION,
  APP_PORTFOLIO_DISPLAY_CONFIG,
  APP_BOARD_DATA
} from "./00-Helpers/AppInitState"

//CORE
import {
  APP_CORE_SANDBOX,
  APP_TOAST_TXT
} from '../../00-Core/Constants'
import {FUNCT_FIND_INDEX} from "../../00-Core/Standards";
import _ from "lodash";

// HELPERS FUNCTIONS
import { APP_FUNC_INCREMENT_UPDATE_TRACKER } from "../../00-Core/Standards";

/////////////////////
/// INITIAL STATE ///
/////////////////////

export const initialState = {
  appSettings: APP_INIT_SETTINGS,
  currentUser: APP_CURRENT_USER,
  coreSelection: APP_CORE_SELECTION,
  projectData: APP_PROJECT_DATA,
  wbsSettings: {},
  appToast:APP_TOAST,
  appLoader: false,
  wbsFilters: [],
  portfolioSelection: APP_PORTFOLIO_SELECTION,
  portfolioDisplay: {...APP_PORTFOLIO_DISPLAY_CONFIG},
  boardData: APP_BOARD_DATA
};

////////////////////////////////////
/// REDUCER FOR GLOBAL APP STATE ///
////////////////////////////////////

////////////// SUMMARY /////////////
// SET_KEY_VALUE
// SET_GLOBAL_STATE
// SET_USER_ATTRIBUTE
// SET_EXTERNAL_CONNECTOR
// SET_AWS_SIGNIN_INIT
// SET_AWS_SIGNIN_INIT_WITHOUT_PROJECT
// SET_USER_SIGN_OUT
// SET_INIT_PROJECT_DATA
// SET_PROJECT_DATA_MULTIPLE_TABLES
// SET_PROJECT_DATA_MULTIPLE
// SET_PROJECT_DATA_DELETE
// SET_PROJECT_DATA_CREATE
// SET_PROJECT_DATA_UPDATE
// CONFIRM_MOVE_MODAL
// SET_WBS_DOMAIN_UPDATE
// SET_WBS_FILTER_VALUE
// SET_WBS_FILTER_RESET_DOMAIN
// SET_WBS_FILTER_RESET_SUBDOMAIN
// SET_WBS_FILTER_RESET_ITEM
// SET_ADD_PORTFOLIO
// SET_UPDATE_PORTFOLIO
// SET_DELETE_PORTFOLIO
// SET_LOAD_PORTFOLIO_WBS_DATA
// SET_UPDATE_BOARD_DATA
////////////////////////////////////

export default function appReducer(state, action) {

  //INIT
  let currentUser = {};
  let coreSelection = {};
  let attributs = [];
  let findIndex;
  let projectData = {};
  let appToast ={};
  let appSettings={};
  let wbsFilters={};
  let portfolioSelection={};
  let portfolioDisplay = {};
  let updateTrackers = {...state.portfolioDisplay.updateTrackers};
  let boardData = {};
  let k;
  let toReturn;
  let projectDataSource;

  // SWITH 
  switch (action.type) {

    ///////////////////////////////////////////////////////////////////////////////
    /// GLOBAL ////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // SET STATE BASIC KEY VALUE
    case 'SET_KEY_VALUE':
      return {
        ...state,
        [action.key]: action.value,
      };
    // SET MULTIPLE SOURCES 
    case 'SET_MULTIPLE_SOURCES':

      // options: [{source: 'toast', object: {}, isSingleValue: boolean }]

      // INIT
      let newState = _.cloneDeep(state);

      for(let i = 0, len = action.options.length; i < len; i++) {
        let currentSource = _.cloneDeep(newState[action.options[i].source]);
        if(action.options[i].isSingleValue) {
          currentSource = action.options[i].object;
        }
        else {
          // GET ATTRIBUTES
          attributs = Object.keys(action.options[i].object);

          // UPDATE REFERENCE
          for(let j = 0, len = attributs.length; i < len; i++) {
            currentSource[attributs[j]] = action.options[i].object[attributs[j]];
          }
        }
        // ASSIGN IN STATE
        newState[action.options[i].source] = currentSource;
      }

      return newState;
    //SET STATE FROM SOURCE ATTRIBUT ----------------------------------------
    case 'SET_GLOBAL_STATE':

      //options: source, object, toast
     
      //INIT
      let currentSource = state[action.options.source];

      //GET ALL OBJECTS ATTRIBUTS TO UPDATE
      attributs = Object.keys(action.options.object);

      //UPDATE REFERNECE OBJECT WITH NEW VALUES
      for(let i = 0 ; i < attributs.length ; i++){
          currentSource[attributs[i]] = action.options.object[attributs[i]];
      }

      //RETURN
      return {
          ...state,
          [action.options.source]: currentSource,
          appToast: action.toast ?? state.appToast,
      };

    //USER SIGNOUT -----------------------------------------------------
    case 'SET_USER_ATTRIBUTE':

      //RESET SELECTION
      coreSelection = state.coreSelection
      
      //FIND
      findIndex = FUNCT_FIND_INDEX(coreSelection.users, "id", action.userId)
      
      //UPDATE ATTRIBUTE
      coreSelection.users[findIndex][action.key] = action.value

      //RETURN STATE
      return{
          ...state,
          coreSelection:coreSelection
      };

    // SET EXTERNAL CONNECTOR ---------------------------------------------------
    case 'SET_EXTERNAL_CONNECTOR':
        // key: connector name (jira ...)
        // value: Request access token

        // RETURN
        return {
            ...state,
            externalConnectors: {
                [action.key]: action.value,
            }
        };

    //SET AWS SIGNIN INITIALIZATION ----------------------------------------------
    case 'SET_AWS_SIGNIN_INIT':

      //action : loading, template, coreProjects, coreOrganization, portfolios, myActions, nextReviews
     
      //INIT
      currentUser = {
          ...state.currentUser,
          loading: false,
          refreshLoading: false,
          needVerifyEmail:false,
          signUp: false,
          confirmSignUp:false,
          needMfaCode: false,
          needMfaSetUp: false,
          needNewPassword: false,
          resetPassword : false,
          displayTermsValidation: false,
          errorMessage: false,
          coreProjects: action.coreProjects,
          coreOrganization: action.coreOrganization
      };

      //CORE SELECTION
      coreSelection = {...state.coreSelection};

      /////////////////////////////
      /// ORGA + MANAGE SANDBOX ///
      /////////////////////////////

      //SEULEMENT SI PAS DEJA
      if(!coreSelection.selectedOrganization.id || coreSelection.selectedOrganization.id === ""){

          //ONLY IF ONE ORGA
          if(action.coreOrganization.length <= 1){

              //IF ONE ORGA
              if(action.coreOrganization.length === 1){

                  //CORE SELECT
                  coreSelection = {
                      selectedOrganization: action.coreOrganization[0],
                      selectedProject: {},
                      selectedWbs: {},
                      wbsList: []
                  }
              }

              //IF NOTHING
              if(action.coreOrganization.length === 0){

                  //CORE SELECT
                  coreSelection = {
                      selectedOrganization: APP_CORE_SANDBOX,
                      selectedProject: {},
                      selectedWbs: {},
                      wbsList: []
                  }
              }
             
              //UPDATE LOCAL STORAGE
              localStorage.setItem("telescope-selectedOrgaId", coreSelection.selectedOrganization.id);

          //SEVERAL
          }else{

              //TEST IF LOCAL STORAGE
              const localStorageOrga = localStorage.getItem('telescope-selectedOrgaId');

              //CONVERSION + CHECK IF EXPECTED FIELDS FROM LOCAL STORAGE
              if(localStorageOrga){
                  if(localStorageOrga === APP_CORE_SANDBOX.id){
                      coreSelection = {
                          selectedOrganization: APP_CORE_SANDBOX,
                          selectedProject: {},
                          selectedWbs: {},
                          wbsList: []
                      }
                  }else{
                      //TEST IF ORGA FOUND
                      findIndex = FUNCT_FIND_INDEX(action.coreOrganization, "id", localStorageOrga);

                      //SI TROUVE
                      if(findIndex !== -1){
                          coreSelection = {
                              selectedOrganization: action.coreOrganization[findIndex],
                              selectedProject: {},
                              selectedWbs: {},
                              wbsList: []
                          }
                      }
                  }
              }else{
                  coreSelection = {
                      selectedOrganization: APP_CORE_SANDBOX,
                      selectedProject: {},
                      selectedWbs: {},
                      wbsList: []
                  }
              }
          }
      }

      //PROJECT
      if(coreSelection.selectedOrganization.id !== APP_CORE_SANDBOX.id){
          
          //INIT
          let relatedProjects = [];
          
          //GET PROJECT RELATED TO SELECTED ORGA
          relatedProjects = action.coreProjects.filter(item => item.coreDataOrganizationCoreDataProjectId === coreSelection.selectedOrganization.id);
          
          //LENGTH 0 LET EMPTY
          if(relatedProjects.length > 0){

              //LENGTH 1 AUTO SELECT
              if(relatedProjects.length === 1){

                  //INTEGRATE
                  coreSelection.selectedProject = relatedProjects[0]
                  localStorage.setItem("telescope-selectedProjectId", coreSelection.selectedProject.id);

              //LENGTH > 1 CHECK LOCAL STORAGE
              }else{

                  //TEST IF LOCAL STORAGE
                  const localStorageProject = localStorage.getItem('telescope-selectedProjectId');

                  //IF NO LOCAL STORAGE DO NOT SELECT ANY PROJECT
                  if(localStorageProject){

                      //TEST IF ORGA FOUND
                      findIndex = FUNCT_FIND_INDEX(relatedProjects, "id", localStorageProject);

                      //IF FOUND INTEGRATE
                      if(findIndex !== -1){coreSelection.selectedProject = relatedProjects[findIndex]}
                  }
              }
          }
      }

      /////////////////
      /// PORTFOLIO ///
      /////////////////

      portfolioSelection = {...state.portfolioSelection};

      // ADD PORTFOLIOS TO STATE
      if(action.portfolios) {
        
        //INTEGRATE PORTFOLIO
        portfolioSelection.portfolios = action.portfolios;

      }

      /////////////////
      ///   BOARD   ///
      /////////////////
      boardData = {
        ...state.boardData,
        myActions: action.myActions,
        nextReviews: action.nextReviews,
      };

      //USERS
      coreSelection.users = action.users;

      
      //RETURN
      return {
          ...state,
          currentUser: currentUser,
          coreSelection: coreSelection,
          portfolioSelection: portfolioSelection,
          boardData: boardData
      };

    //SET AWS SIGNIN INITIALIZATION ----------------------------------------------
    case 'SET_AWS_SIGNIN_INIT_WITHOUT_PROJECT':

      //INIT
      currentUser = {
        ...state.currentUser,
        loading: false,
        refreshLoading: false,
        needVerifyEmail:false,
        signUp: false,
        confirmSignUp:false,
        needMfaCode: false,
        needMfaSetUp: false,
        needNewPassword: false,
        resetPassword : false,
        displayTermsValidation: false,
        errorMessage: false,
        coreProjects: [],
        coreOrganization: []
      };  

      //CORE SELECTION
      coreSelection = {...state.coreSelection};
      coreSelection.users = action.users

      //RETURN
      return{
        ...state,
        currentUser: currentUser,
        coreSelection: coreSelection
      };
    
    //SET USER SIGN OUT ---------------------------------------------------------
    case 'SET_USER_SIGN_OUT':

      //CURRENT USER
      currentUser= {
        user:null,
        refreshLoading: false,
        loggedIn: false,
        needVerifyEmail:false,
        needMfaCode: false,
        needMfaSetUp: false,
        needNewPassword: false,
        resetPassword : false,
        displayTermsValidation: false,
        errorMessage: false,
        username: "",
        name:"",
        loading: false,
        err: false,
        coreProjects:[],
        coreOrganization:[],
      }

      //PROJECT DATA
      projectData={
        charter: [],
        govScopeChange: [],
        govReview: [],
        shortcut:[],
        action: [],
        riskOpp: []
      }

      //PORTFOLIO
      portfolioSelection={
        portfolios: [],
        selectedPortfolio: null,
        portfolioProjects: [],
        showProjetListView: false
      }

      //CLEAN
      return {
        ...state,
        currentUser: currentUser,
        appToast:APP_TOAST,
        coreSelection: APP_CORE_SELECTION,
        projectData: projectData,
        portfolioSelection: portfolioSelection,
        wbsFilters: [],
        appLoader: false,
      };

    ///////////////////////////////////////////////////////////////////////////////
    /// PROJECT DATA MANAGEMENT ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    
    // MULTIPLE OPERATIONS ON MULTIPLE ITEMS FOR MULTIPLE TABLES
    case 'SET_PROJECT_DATA_MULTIPLE_TABLES':
      // OBJECT: {
      //   action: {
      //     create: [iterm1,item2,...],
      //     update: [......],
      //     delete: [....]
      //   },
      //   riskOpp: {
      //    create:....
      //  }
      // }
      
      // You can just pass the operations you want to do.

      // INIT
      projectData = {...state.projectData};

      // FOR EACH TABLE
      for(let table of Object.keys(action.object)) {

        // LOOP CREATE
        if(action.object[table].create) {
          for(let itemToAdd of action.object[table].create) {
            projectData[table].push(itemToAdd);
          }
        }

        // LOOP UPDATE
        if(action.object[table].update) {
          for(let itemToUpdate of action.object[table].update) {
            let index = projectData[table].findIndex((item) => item.id === itemToUpdate.id);
            projectData[table][index] = itemToUpdate;
          }
        }

        // LOOP DELETE
        if(action.object[table].delete) {
          projectData[table] = projectData[table].filter((item) => !action.object[table].delete?.includes(item.id));
        }

        // INCREMENT UPDATE TRACKER IF EXISTS
        updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, table);
      }

      //TOAST
      appToast = {
        display: true,
        color: "good",
        message: APP_TOAST_TXT.mutationUpdateOk
      }

      // INIT RETURN
      toReturn = {
        ...state,
        projectData: projectData,
        appToast: appToast,
      };

      // UPDATE TRACKERS
      toReturn.portfolioDisplay.updateTrackers = updateTrackers;

      // RETURN NEW STATE
      return toReturn;
    
    case 'SAVE_REFERENCE':
        appSettings = {...state.appSettings};
        // INIT
        projectData = {...state.projectData};
      
        // CREATE
        if(action.referenceCreateUpdateObject.create.length > 0) {
          for(let itemToAdd of action.referenceCreateUpdateObject.create) {
            projectData[action.table].push(itemToAdd);
          }
        }
        // UPDATE
        if(action.referenceCreateUpdateObject.update.length > 0) {
          for(let itemToUpdate of action.referenceCreateUpdateObject.update) {
            let index = projectData[action.table].findIndex((item) => item.guid === itemToUpdate.guid);
            
            if(index > -1){
              for(let attribut of Object.keys(itemToUpdate)) {
                if(attribut!=="id" && attribut!=="guid") {
                  projectData[action.table][index][attribut] = itemToUpdate[attribut];
                }else if(attribut==="id"){
                  itemToUpdate.id = projectData[action.table][index][attribut];
                }
              }
            }
          }
        }
      
        appSettings.actionModal = null;
        appSettings.referenceCreateUpdateObject = action.referenceCreateUpdateObject;

        // INIT TO RETURN
        toReturn = {
          ...state,
          appSettings: appSettings,
          projectData: projectData,
          //TOAST
          appToast: {
            display: true,
            color: "good",
            message: APP_TOAST_TXT.stateReferenceOk,
          },
        }

         // INCREMENT UPDATE TRACKER FOR SOURCE IF EXISTS
        updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, action.table);

        // UPDATE TRACKERS
        toReturn.portfolioDisplay.updateTrackers = updateTrackers;

        return toReturn;

    // MULTIPLE OPERATIONS ON MULTIPLE ITEMS
    case 'SET_PROJECT_DATA_MULTIPLE':

      // table: "action"
      // object: {
      //    create: [item3,],
      //    update: [item1,item2],
      //    delete: [id1, id2,]
      // You can just pass the operations you want to do.

      // INIT
      projectData = {...state.projectData};
      
      // CREATE
      if(action.object.create) {
        for(let itemToAdd of action.object.create) {
          projectData[action.table].push(itemToAdd);
        }
      }
      // UPDATE
      if(action.object.update) {
        for(let itemToUpdate of action.object.update) {
          let index = projectData[action.table].findIndex((item) => item.id === itemToUpdate.id);

          projectData[action.table][index] = itemToUpdate;
        }
      }

      // DELETE
      if(action.object.delete) {
        projectData[action.table] = projectData[action.table].filter((item) => !action.object.delete?.includes(item.id));
      }

     //TOAST
      appToast = {
        display: true,
        color: "good",
        message: APP_TOAST_TXT.mutationUpdateOk
      }

      //RETURN INIT
      toReturn = {
        ...state,
        projectData: projectData,
        appToast: appToast,
      };

      // INCREMENT UPDATE TRACKER FOR SOURCE IF EXISTS
      updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, action.table);

      // UPDATE TRACKERS
      toReturn.portfolioDisplay.updateTrackers = updateTrackers;
      
      // RETURN NEW STATE
      return toReturn;

    // DELETE MULTIPLE ITEMS -------------------------------------
    case 'SET_PROJECT_DATA_DELETE':

      // ACTION WILL HAVE SOURCE OF WHERE TO DELETE AND AN ARRAY OF IDS OF ITEMS TO BE DELETED
      // action : source: ,ids: ["id1", "id2"]

      ////////////////////
      /// PROJECT DATA ///
      ////////////////////

      //INIT
      projectDataSource = [...state.projectData[action.source]];

      // FOR EACH OF THE IDS OF THE ITEMS TO REMOVE, FIND THE INDEX AND REMOVE THE ITEM.
      for(let i = 0;  i < action.ids.length; i++) {
         
        //FIND GOOD ITEM
        let indexToRemove = FUNCT_FIND_INDEX(projectDataSource, "id", action.ids[i]);

        //DELETE
        if(indexToRemove>-1) {
          projectDataSource.splice(indexToRemove, 1);
        }
      }

      // INCREMENT UPDATE TRACKER IF EXISTS
      updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, action.source);

      /////////////
      /// TOAST ///
      /////////////

      //TOAST DEFINE BY THE COMPONENT
      if(action.toast){
        appToast = action.toast

      //NORMAL TOAST
      }else{

        //TOAST
        appToast = {
          display: true,
          color: "good",
          message: APP_TOAST_TXT.mutationDeleteOk
        }
      }

  
      //////////////
      /// RETURN ///
      //////////////
      toReturn = {
        ...state,
        projectData: {
          ...state.projectData,
          [action.source] : projectDataSource
        },
        appToast: appToast,
      };

      // UPDATE TRACKERS
      toReturn.portfolioDisplay.updateTrackers = updateTrackers;

      // RETURN NEW STATE
      return toReturn

    // CREATE MULTIPLE ITEMS --------------------------------------------
    case 'SET_PROJECT_DATA_CREATE':
      
      // action: source (string, ex: "riskOpp"), objects : [{item1}, {item2}];

      ////////////////////
      /// PROJECT DATA ///
      ////////////////////

      // INIT
      projectData = {...state.projectData};
      
      // IF TABLE NOT IN PROJECT DATA, INIT
      if(!projectData[action.source]) projectData[action.source] = [];

      // FOR EACH ITEM TO ADD INTO PROJECT DATA
      for(let i = 0, len = action.objects.length; i<len; i++) {

        // IF IT HAS AN ID
        if(action.objects[i].id) {

          // ADD TO projectData[action.source]
          projectData[action.source].push(action.objects[i]);
        }
      }

      /////////////
      /// TOAST ///
      /////////////

      //TOAST
      appToast = {
        display: true,
        color: "good",
        message: APP_TOAST_TXT.mutationCreateOk
      }

      //////////////
      /// RETURN ///
      //////////////
      toReturn = {
        ...state,
        projectData: projectData,
      };

      // INCREMENT UPDATE TRACKER FOR SOURCE IF EXISTS
      updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, action.source);

      // UPDATE TRACKERS
      toReturn.portfolioDisplay.updateTrackers = updateTrackers;

      // IF TOAST REQUESTED, ADD IT
      if(!action.noToast) {
        toReturn.appToast = appToast;
      }
      
      // RETURN NEW STATE
      return toReturn;

    // DELETE ITEMS FROM BOTH PROJECT DATA AND REFERENCE
    case 'SET_PROJECT_REFERENCE_DELETE':

       // ACTION WILL HAVE SOURCE OF WHERE TO DELETE AND AN ARRAY OF IDS OF ITEMS TO BE DELETED
      // action : source: ,ids: ["id1", "id2"], referenceCreateUpdateObject

      ////////////////////
      /// PROJECT DATA ///
      ////////////////////

      //INIT
      projectDataSource = [...state.projectData[action.source]];

      // FOR EACH OF THE IDS OF THE ITEMS TO REMOVE, FIND THE INDEX AND REMOVE THE ITEM.
      for(let i = 0;  i < action.ids.length; i++) {
         
        //FIND GOOD ITEM
        let indexToRemove = FUNCT_FIND_INDEX(projectDataSource, "id", action.ids[i]);

        //DELETE
        if(indexToRemove>-1) {
          projectDataSource.splice(indexToRemove, 1);
        }
      }

      // INCREMENT UPDATE TRACKER IF EXISTS
      updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, action.source);

      /////////////
      /// TOAST ///
      /////////////

      //TOAST DEFINE BY THE COMPONENT
      if(action.toast){
        appToast = action.toast

      //NORMAL TOAST
      }else{

        //TOAST
        appToast = {
          display: true,
          color: "good",
          message: APP_TOAST_TXT.mutationDeleteOk
        }
      }

      
      appSettings = {...state.appSettings};

      appSettings.referenceCreateUpdateObject = action.referenceCreateUpdateObject;

      //////////////
      /// RETURN ///
      //////////////
      toReturn = {
        ...state,
        projectData: {
          ...state.projectData,
          [action.source] : projectDataSource
        },
        appSettings: appSettings,
        appToast: appToast,
      };

      // UPDATE TRACKERS
      toReturn.portfolioDisplay.updateTrackers = updateTrackers;

      // RETURN NEW STATE
      return toReturn

    // CONFIRM MOVE MODAL
    case 'CONFIRM_MOVE_MODAL': 

      //action : source (action / riskOpp ...), [objects] => DO NOT FORGET TO PASS THE ID INSIDE EACH OBJECT
      // IF OBJECT HAS PROPERTY idToUpdate, THEN USE THAT VALUE TO FIND THE INDEX INSTEAD. 

      ////////////////////
      /// PROJECT DATA ///
      ////////////////////

      //INIT
      projectData = {...state.projectData}

      //MANAGE PROJECT DATA
      for (let i = 0, len = action.objects.length; i < len; i++) {

        //MANAGE ONLY IF WE HAVE AN ID
        if(action.objects[i].id){

          //GET ALL OBJECTS ATTRIBUTS TO UPDATE
          attributs = Object.keys(action.objects[i]);
        
          //GET THE ITEM 
          findIndex = FUNCT_FIND_INDEX(projectData[action.source],"id", action.objects[i].id);

          //LOOP
          for (let j = 0; j < attributs.length; j++) {

            if(attributs[j] !== "id"){
              projectData[action.source][findIndex][attributs[j]] = action.objects[i][attributs[j]]
            }
          }
        }
      }

      /////////////
      /// TOAST ///
      /////////////

      //TOAST
      appToast = {
        display: true,
        color: "good",
        message: APP_TOAST_TXT.mutationUpdateOk
      }

      //RETURN WITH  NO TOAST
      if(action.noToast){
        return {
          ...state,
          projectData: projectData,
        };
      }

      // INCREMENT UPDATE TRACKER IF EXISTS
      updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, action.source);

      // INIT RETURN
      toReturn = {
        ...state,
        projectData: projectData,
        appToast: appToast,
        appSettings: {
          ...state.appSettings,
          actionModal: null,
          moveModalOptions: {
            itemsArray: [],
            tableName: null,
            query: null,
            propertiesToDelete: null,
          },
        },
      };

      // UPDATE TRACKERS
      toReturn.portfolioDisplay.updateTrackers = updateTrackers;

      // RETURN NEW STATE
      return toReturn;

    // UPDATE MULTIPLE ITEMS --------------------------------------------
    case 'SET_PROJECT_DATA_UPDATE':

      //action : source (action / riskOpp ...), [objects] => DO NOT FORGET TO PASS THE ID INSIDE EACH OBJECT
      // IF OBJECT HAS PROPERTY idToUpdate, THEN USE THAT VALUE TO FIND THE INDEX INSTEAD. 
      
      ////////////////////
      /// PROJECT DATA ///
      ////////////////////

      //INIT
      projectData = {...state.projectData};

      //MANAGE PROJECT DATA
      for (let i = 0, len = action.objects.length; i < len; i++) {

        //MANAGE ONLY IF WE HAVE AN ID
        if(action.objects[i].id){

          //GET ALL OBJECTS ATTRIBUTS TO UPDATE
          attributs = Object.keys(action.objects[i]);

          //GET THE ITEM 
          findIndex = FUNCT_FIND_INDEX(projectData[action.source],"id", action.objects[i].id);

          //LOOP
          for (let j = 0; j < attributs.length; j++) {

            if(attributs[j] !== "id"){
              projectData[action.source][findIndex][attributs[j]] = action.objects[i][attributs[j]]
            }
          }
        }
      }

      // INCREMENT UPDATE TRACKER IF EXISTS
      updateTrackers = APP_FUNC_INCREMENT_UPDATE_TRACKER(updateTrackers, action.source);

      // INIT RETURN
      toReturn = {
        ...state,
        projectData: projectData,
      };

      // ADD TOAST BY DEFAULT
      if(!action.noToast){
        toReturn.appToast = {
          display: true,
          color: "good",
          message: APP_TOAST_TXT.mutationUpdateOk
        }
      }
      
      // UPDATE TRACKERS
      toReturn.portfolioDisplay.updateTrackers = updateTrackers;
      
      // RETURN NEW STATE
      return toReturn;

    //INIT PROJECT DATA ------------------------------------------------------
    case 'SET_INIT_PROJECT_DATA':

      //Action projectData, projectId

      //INIT
      let selectedOrganizationId = state.coreSelection.selectedOrganization.id
      if(action.organizationId) selectedOrganizationId = action.organizationId

      //UPDATE LOCAL STORAGE
      localStorage.setItem("telescope-selectedProjectId", action.projectId)
      localStorage.setItem("telescope-selectedOrgaId", selectedOrganizationId)

      //REMOVE PORTFOLIO
      localStorage.removeItem("telescope-selectedPortfolioId")

      //////////////////////
      /// CORE SELECTION ///
      //////////////////////

      //INIT
      coreSelection = {...state.coreSelection}

      //IF ORGANIZATION ID
      if(action.organizationId){
        findIndex = FUNCT_FIND_INDEX(state.currentUser.coreOrganization,"id",action.organizationId)

        coreSelection.selectedOrganization = state.currentUser.coreOrganization[findIndex]
      }

      //IF PROJECT ID
      if(action.projectId){
        findIndex = FUNCT_FIND_INDEX(state.currentUser.coreProjects,"id",action.projectId)
        coreSelection.selectedProject = state.currentUser.coreProjects[findIndex]
      }
      
      //WBS LIST
      coreSelection.wbsList = action.wbsList

      ////////////////
      /// PORTOLIO ///
      ///////////////

      portfolioSelection = {...state.portfolioSelection}
      portfolioSelection.selectedPortfolio = null
      portfolioSelection.portfolioProjects = []

      /////////////////////////
      /// PORTFOLIO DISPLAY ///
      /////////////////////////

      portfolioDisplay = {
          ...APP_PORTFOLIO_DISPLAY_CONFIG
      };

      // SET DIRECT ITEM ACCESS
      if (action.wbsNavigationOptions) {
        portfolioDisplay.wbsNavigationOptions = action.wbsNavigationOptions;
      }

      // KEEP RIGHT COMPONENT
      if (action.keepRightComponent) {
        portfolioDisplay.rightComponent = {
          ...state.portfolioDisplay.rightComponent,
        }
      }
      
      //////////////
      /// RETURN ///
      //////////////

      return{
        ...state,
        projectData: {
          ...state.projectData,
          ...action.projectData
        },
        coreSelection: coreSelection,
        wbsSettings: action.wbsSettings,
        portfolioSelection: portfolioSelection,
        portfolioDisplay: portfolioDisplay,
      };

    ///////////////////////////////////////////////////////////////////////////////
    /// WBS SETTINGS MANAGEMENT ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    //UPDATE WBS DOMAIN
    case 'SET_WBS_DOMAIN_UPDATE':

      //ACTION: wbsDomain

      //WAITING HERE OTHER SETTINGS
      
      //RESET MODAL
      appSettings = {...state.appSettings}
      appSettings.actionModal = null

      //TOAST
      appToast = {
        display: true,
        color: "good",
        message: "Settings saved to your Project!"
      }
      

      //RETURN
      return{
        ...state,
        wbsSettings: {
          ...state.wbsSettings,
          domain: action.wbsDomain
        },
        appSettings: appSettings,
        appToast:appToast
      }

    ///////////////////////////////////////////////////////////////////////////////
    /// WBS FILTERS ///////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    //UPDATE VALUE ----------------------------------------------------------------
    case 'SET_WBS_FILTER_VALUE':

      // action: domain, subDomain, value
      wbsFilters = [..._.cloneDeep(state.wbsFilters)];

      //TEST IF ALREADY EXIST => UPDATE VALUE
      findIndex = FUNCT_FIND_INDEX(wbsFilters,"key", (action.domain + action.subDomain))
      
      //UPDATE VALUE
      if(findIndex > -1){
        
        //IF LENGHT === 0 DELETE THE LINE
        if(action.value.length > 0){
          wbsFilters[findIndex].value = action.value
        }else{
          wbsFilters.splice(findIndex,1)
        }
        
      //ADD NEW LINE
      }else if(action.value && action.value.length > 0){
        wbsFilters.push({
          key: (action.domain + action.subDomain),
          domain: action.domain,
          subDomain: action.subDomain,
          value: action.value
        })

      }
    
      //UPDATE STATE
      return {
        ...state,
        wbsFilters: wbsFilters
      };

    //RESET DOMAIN FILTERS --------------------------------------------------------
    case 'SET_WBS_FILTER_RESET_DOMAIN':

      // action: domain
      wbsFilters = [..._.cloneDeep(state.wbsFilters)];

      //LOOP ON PORTFOLIO PROJECT LIST
      k = wbsFilters.length ;
      while (k--) {

          //GET INDEX
          if(wbsFilters[k].domain === action.domain) {
            
            wbsFilters.splice(k, 1);
          }
      }

      //UPDATE STATE
      return {
        ...state,
        wbsFilters: wbsFilters
      };

    //RESET DOMAIN FILTERS --------------------------------------------------------
    case 'SET_WBS_FILTER_RESET_SUBDOMAIN':

      // action: key
      wbsFilters = [..._.cloneDeep(state.wbsFilters)];

      //GET OBJECT KEY
      findIndex = FUNCT_FIND_INDEX(wbsFilters,"key", action.key)

      //IF FOUND
      if(findIndex > -1){
        wbsFilters.splice(findIndex,1)
      }

      //UPDATE STATE
      return {
        ...state,
        wbsFilters: wbsFilters
      };

    //RESET DOMAIN FILTERS --------------------------------------------------------
    case 'SET_WBS_FILTER_RESET_ITEM':

      // action: domain, subDomain, value
      wbsFilters = [..._.cloneDeep(state.wbsFilters)];

      //GET OBJECT KEY
      findIndex = FUNCT_FIND_INDEX(wbsFilters,"key", (action.domain + action.subDomain))
      
      //UPDATE VALUE
      if(findIndex > -1){

        //TEST VALUE
        if(action.value){

          //TEST IF LENGTH 0
          if(action.value.length > 0){
            wbsFilters[findIndex].value = action.value
          }else{
            wbsFilters.splice(findIndex,1)
          }

        //IF VALUE NULL DELETE LINE
        }else{
          wbsFilters.splice(findIndex,1)
        }
      }

      //UPDATE STATE
      return {
        ...state,
        wbsFilters: wbsFilters
      };

    ///////////////////////////////////////////////////////////////////////////////
    /// PORTFOLIO MANAGEMENT //////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // ADD NEW PORTFOLIO TO STATE
    case "SET_ADD_PORTFOLIO":
      // portfolio

      // ADD NEW PORTFOLIO
      portfolioSelection = {...state.portfolioSelection}
      portfolioSelection.portfolios = [...state.portfolioSelection.portfolios, action.portfolio]

      // ADD PORTFOLIO TO STATE
      return {
          ...state,
          portfolioSelection: portfolioSelection
      };

    // UPDATE EXISTING PORTFOLIO
    case "SET_UPDATE_PORTFOLIO":

      // portfolio
      portfolioSelection = {...state.portfolioSelection}
      portfolioSelection.portfolios = [...state.portfolioSelection.portfolios];

      // FIND PORTFOLIO
      findIndex = FUNCT_FIND_INDEX(portfolioSelection.portfolios, "id", action.portfolio.id);

      // REPLACE PORTFOLIO BY NEW ONE
      portfolioSelection.portfolios[findIndex] = action.portfolio;

      // RESET LOADED PORTFOLIO
      portfolioSelection.selectedPortfolio = null;

      return {
        ...state,
        portfolioSelection: portfolioSelection,
      };

    // DELETE PORTFOLIO FROM STATE
    case "SET_DELETE_PORTFOLIO":

      // portfolioId
      portfolioSelection = {...state.portfolioSelection}
      portfolioSelection.portfolios = [...state.portfolioSelection.portfolios];

      // FIND PORTFOLIO
      findIndex = FUNCT_FIND_INDEX(portfolioSelection.portfolios, "id", action.portfolioId);

      // REMOVE PORTFOLIO
      portfolioSelection.portfolios.splice(findIndex, 1);

      return {
        ...state,
        portfolioSelection: portfolioSelection,
      };

    // LOAD PORTFOLIO DATA FOR WBS VIEW
    case "SET_LOAD_PORTFOLIO_WBS_DATA":
      // action : portfolio, projectData, wbsList, wbsSettings, portfolioProjects

      //REMOVE PROJECT AND ORGA
      localStorage.removeItem("telescope-selectedProjectId")
      localStorage.removeItem("telescope-selectedOrgaId")

      //ADD PORTFOLIO ID
      localStorage.setItem("telescope-selectedPortfolioId", action.portfolio.id)

      //////////////////////
      /// CORE SELECTION ///
      //////////////////////

      //RESET CORESELECTION BECAUSE PORTFOLIO
      coreSelection = {...state.coreSelection}
      coreSelection.selectedOrganization = {}
      coreSelection.selectedProject = {}

      //WBS LIST
      coreSelection.wbsList = action.wbsList

      /////////////////
      /// PORTFOLIO ///
      /////////////////

      portfolioSelection = {...state.portfolioSelection};
      portfolioSelection.portfolioProjects = action.portfolioProjects;
      portfolioSelection.selectedPortfolio = action.portfolio;

      //////////////
      /// RETURN ///
      //////////////
     
      return{
        ...state,
        projectData: action.projectData,
        coreSelection: coreSelection,
        wbsSettings: action.wbsSettings,
        wbsFilters: [],
        portfolioSelection: portfolioSelection,
        portfolioDisplay: {
          ...APP_PORTFOLIO_DISPLAY_CONFIG
        }
      };

    // UPDATE BOARD DATA WHEN CREATING NEW ITEMS (Reviews and Actions)
    case "SET_UPDATE_BOARD_DATA":
      // table (nextReviews, myActions), data

      // INIT
      projectData = state.boardData[action.table];

      // CREATE
      if(action.data.create) {
        projectData.push(action.data.create[0]);
      }

      // UPDATE
      else if(action.data.update) {
        let index = projectData.findIndex((item) => item.id === action.data.update[0].id);
        projectData[index] = {
          ...projectData[index], 
          ...action.data.update[0]
        };
      }

      // DELETE
      else if(action.data.delete) {
        // IF DELETE IS AN ARRAY, DELETE SEVERAL ITEMS
        if (Array.isArray(action.data.delete)) {
          projectData = projectData.filter((item) => !action.data.delete?.includes(item.id));
        } else {
          // ELSE DELETE SINGLE ITEM
          projectData = projectData.filter((item) => item.id !== action.data.delete.id);
        }
      }

      // RETURN UPDATED BOARD STATE
      return {
        ...state,
        boardData : {
          ...state.boardData,
          [action.table]: projectData
        },
      };

    //DEFAULT TYPE NOT CREATED ------------------------------------------------
    default:

      /////////////
      /// TOAST ///
      /////////////

      //TOAST
      appToast = {
        display: true,
        color: "danger",
        message: action.type + " > Not in AppDispatcher!"
      }
      
      //////////////
      /// RETURN ///
      //////////////

      return {
        ...state,
        appToast:appToast
      };
  }
}
