///////////////////////////////////////////////////////////////////////////
/// IMPORT ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

// REACT
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

// LIBRARIES
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { CvtButton} from "@mi-gso/cvt";
import { APP_WBS_STRUCTURE } from "../../../../00-Core/Constants";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../00-Wbs/00-Helpers/WbsConstants";

// COMPONENTS
import ShortcutListTable from "./ShortcutListTable";

////////////////////////////////////////////////////////////////////////////////
/// SHORTCUT LIST COMPONENT  ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export default memo(function ShortcutList({
  // SHORTCUT DATA
  shortcutData,
  // ON ADD EDIT CLICK FUNCTION
  onAddEditClick,
  // ON DELETE FUNCTION
  onDelete,
  // HANDLE DROP FUNCTION
  handleDrop,
  // VIEW MODE
  viewMode,
  // WHETHER ONLY SHORTCUT BIG IS OPEN
  isOnlyShortcutOpen,
  // WBS DOMAINS
  wbsDomains,
  // ITEM LEVEL
  itemLevel,
  // SECURITY GROUP
  securityGroup,
}) {
  /////////////////////////////////////////////////////
  // REF //////////////////////////////////////////////
  /////////////////////////////////////////////////////
  const containerRef = useRef(null);

  /////////////////////////////////////////////////////
  // STATE ////////////////////////////////////////////
  /////////////////////////////////////////////////////

  // CONTAINER WIDTH
  const [containerWidth, setContainerWidth] = useState(0);

  /////////////////////////////////////////////////////
  // CALLBACKS ////////////////////////////////////////
  /////////////////////////////////////////////////////

  // UPDATE WINDOW SIZE STATE
  const updateWindowSizeState = useCallback(() => {
    setContainerWidth(containerRef.current?.offsetWidth);
  }, []);

  const maxHeight = useMemo(() => {
    let maxHeight = 50;

    for(let domain of wbsDomains) {
      const shortcutsHeight = shortcutData.filter(
        (shortcut) => shortcut.category === domain.id
      ).length * 40;

      if(shortcutsHeight > maxHeight) {
        maxHeight = shortcutsHeight;
      }
    }

    return maxHeight + 'px';
  }, [shortcutData, wbsDomains]);

  /////////////////////////////////////////////////////
  // USE EFFECTS //////////////////////////////////////
  /////////////////////////////////////////////////////

  // UPDATE CONTAINER WIDTH
  useEffect(() => {
      window.addEventListener("resize", () => {
        updateWindowSizeState();
      });

      // UNSUBSCRIBE
      return () => {
        document.removeEventListener('resize', updateWindowSizeState);
    }
  }, [updateWindowSizeState]);

  //////////////////////////////////////////////////////////////////////////////
  /// COMPONENT RENDER /////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  return (
    <div className="flexStartStart" style={{width: '100%'}}>

      {/* OVERFLOW BACKGROUND */}
      {
              isOnlyShortcutOpen ?
              <div className="shortcutListOverflowContainer"
                style={{
                  height: maxHeight,
                }}
              >
              </div>
      :null}

      {/* DRAG AND DROP CONTEXT */}
      <DragDropContext onDragEnd={handleDrop}>
        {/* DROPPABLE AREA */}

        {isOnlyShortcutOpen === true ? (
          <div className="flexBetweenStart" style={{ width: "100%"}} ref={containerRef}>
            {wbsDomains.map((domain, index) => {
              // FILTER SHORTCUTS FOR THIS SPECIFIC CATEGORY
              let shortcuts = shortcutData.filter(
                (shortcut) => shortcut.category === domain.id
              );

              // return MULTI LIST
              return (
                <div
                  className={`shortcutBigMultiList flexOne flexColStartCenter ${domain.id === "charter" ? "shortcutBigMultiListProject" : ""}`}
                  key={`shortcut_list_multi_container_${domain.id}_${index}`}
                  style={{
                    minWidth:
                      domain.id === "charter" || index === 0
                        ? APP_WBS_STRUCTURE.firstColumnWidth - 25 +
                          "px"
                        : null,
                    minHeight: maxHeight,
                  }}
                >
                  {/* LIST OF SHORTCUTS / CREATE BTN */}
                  <Droppable
                    droppableId={`shortcut_list_container_${domain.id}`}
                    direction="vertical"
                    key={`shortcut_list_container_${domain.id}`}
                  >
                    {(provided, snapshot) => {
                      // IF THERES ANY SHORTCUTS, DISPLAY THE ADD BTN
                      return shortcuts.length > 0 ? (
                        <ShortcutListTable
                          isOnlyShortcutOpen={isOnlyShortcutOpen}
                          shortcutData={shortcuts}
                          viewMode={viewMode}
                          onAddEditClick={onAddEditClick}
                          onDelete={onDelete}
                          domain={domain}
                          index={index}
                          provided={provided}
                          snapshot={snapshot}
                        />
                      ) : (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`flexCenterCenter shortcutMultiListNoItemsContainer
                            ${snapshot.isDraggingOver ? "shortcutBigListContainerDashedBorder" :  viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ? "shortcutBigListContainerDashedBorderGray" : ""}

                          `}
                          style={{
                            padding: viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ? "20px" : "",
                            overflowX: snapshot.draggingFromThisWith === `shortcut_list_container_${domain.id}` ? 'visible' : '',
                            ...provided.droppableProps.style,
                          }}
                        >
                          {/* IF NOT IN EDIT MODE, THEN SHOW CREATE BTN */}
                          {/* ELSE SHOW CONTAINER THAT USER CAN DRAG AND DROP INTO */}

                          {viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ? (
                            <div
                              className="flexCenterCenter shortcutListNoItemsDragContainer"
                              style={{
                                height: "100%",
                                width: "100%",
                                display: snapshot.isDraggingOver ? "none" : "",
                              }}
                            >
                              Drop here
                            </div>
                          ) : (

                            <CvtButton
                              key={`shortcut_list_no_shortcut_${index}_${domain.id}`}
                              color={securityGroup.isCurrentUserEditor ? "green" : "gray"}
                              shape={!securityGroup.isCurrentUserEditor ? "noShape" : null}
                              txt={securityGroup.isCurrentUserEditor && containerWidth > 1360 ? 'Create a shortcut' : ''}
                              icon={securityGroup.isCurrentUserEditor ? "add" : "block"}
                              handleSubmit={() => securityGroup.isCurrentUserEditor ? onAddEditClick(null, domain.id) : null}
                              disabled={!securityGroup.isCurrentUserEditor}
                              tooltip={!securityGroup.isCurrentUserEditor}
                              tooltipTxt={
                                !securityGroup.isCurrentUserEditor ? "You don't have permissions to create a shortcut" : ''
                              }
                              tooltipPosition="top"
                              tooltipTheme="dark"
                            />
                          )}

                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              );
            })}
          </div>
        ) : (
          <Droppable droppableId={`shortcut_list_container`}>
            {(provided, snapshot) => (
              <ShortcutListTable
                isOnlyShortcutOpen={isOnlyShortcutOpen}
                shortcutData={shortcutData}
                viewMode={viewMode}
                onAddEditClick={onAddEditClick}
                onDelete={onDelete}
                domain={null}
                index={0}
                provided={provided}
                snapshot={snapshot}
              />
            )}
          </Droppable>
        )}
      </DragDropContext>
    </div>
  );
});
