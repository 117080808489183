import {CvtTooltip} from "@mi-gso/cvt";

// WARNING ICON AND TOOLTIP TO LIST USERS THAT HAVE NO ACCESS ON A PROJECT
export default function UsersManagementMissingUserAccessWarning({
    projectsWithMissingUserPermissions,
    projectId,
}) {

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return projectsWithMissingUserPermissions[projectId] ? (
        <div
            id={"tooltipUserHasNoAccess_" + projectId}
            className="flexStartCenter"
        >
            <span
                className="material-icons"
                style={{
                    marginRight: "5px",
                    color: "var(--color-bad)",
                }}
            >
                report_problem
            </span>
            <CvtTooltip
                anchorId={"tooltipUserHasNoAccess_" + projectId}
                content={projectsWithMissingUserPermissions[projectId]}
            />
        </div>
    ) : null;
}
