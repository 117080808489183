import {
    CvtButton,
    CvtItemCardTitle,
    CvtItemTable,
    CvtSelectMultiple,
    CvtItemCardInfosTabs,
    CvtSideBarButton,
    FIND_OBJECT_ARRAY_ITEM,
} from "@mi-gso/cvt";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING } from "../../../../00-Core/Standards";
import {
    updateTelescopeDataAction,
    updateTelescopeDataSchedule,
} from "../../../../graphql/mutations";
import { multipleMutateGraphql } from "../../../00-App/02-Backend/AppBackendCommon";
import { WBS_DEFAULT_NAVIGATION_OPTIONS } from "../../00-Wbs/00-Helpers/WbsConstants";
import { WBS_OPEN_ITEM } from "../../00-Wbs/00-Helpers/WbsFunctions";
import {
    ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST,
    FUNC_ACTION_ADD_NEW_LINKS,
    FUNC_ACTION_CREATE_LINK_OBJECT,
} from "../../01-Action/00-Helpers/ActionsFunctions";
import ActionTableLegend from "../../01-Action/01-Components/ActionTable/ActionTableLegend";
import RiskCardActionsTableItemTags from "../../02-Risk/01-Components/RiskCard/RiskCardActionsTable/RiskCardActionsTableItemTags";

export function ScheduleItemCard({
    // DATA
    actionsData,
    projectData,
    allActions,
    currentUser,
    item,
    users,
    isCurrentUserEditor,
    isReadOnlyMode,
    legendAndCounter,
    megaUsersId,
    selectedItems,
    statusSearchInput,
    textSearchInput,
    wbsId,
    canModifySchedules,
    securityGroup,
    scheduleCardPropKey,

    // FUNCTIONS
    handleCreateAction,
    handleDuplicateClick,
    handleEditAction,
    onDeleteAction,
    onItemCardInfoUpdated,

    // DISPATCHERS
    appDispatcher,
    wbsDispatcher,
    scheduleDispatcher,
}) {
 
    //////////////////////////////////////////////////////////////////////////
    /// STATE ////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    const [state, setState] = useState({
        confirm: {
            value: false,
            type: "",
        },
        actionPlanLink: {
            isDisplayed: false,
            selectedActions: [],
        },
        viewModeTabOptionSelected: "description",
    });

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // WHEN ACTION LINK MULTIPLE SELECT INPUT CHANGES
    const onActionLinkSelected = useCallback((newValue) => {
        setState((prevState) => {
            let newState = { ...prevState };

            newState.actionPlanLink.selectedActions = newValue;

            return newState;
        });
    }, []);

    // LINK NEW ACTIONS TO ACTION PLAN
    const onLinkNewActions = useCallback(async () => {
        // GET FULL SELECTED ACTIONS
        let selectedActions = state.actionPlanLink.selectedActions.map((selectedAction) => {
            // GET FROM PROJECT DATA
            let action = FIND_OBJECT_ARRAY_ITEM(projectData.action, "id", selectedAction.value);

            return action;
        });

        // CREATE A LINK OBJECT TO THIS SCHEDULE
        let newLink = FUNC_ACTION_CREATE_LINK_OBJECT("schedule", item.id);

        // UPDATE APP STATE FOR ACTIONS AND SCHEDULE
        FUNC_ACTION_ADD_NEW_LINKS(
            [newLink],
            selectedActions,
            projectData,
            currentUser.username,
            appDispatcher
        );

        // UPDATE SCHEDULE DISPATCHER
        let updatedScheduleActionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(item.actionsIds);
        let newIds = selectedActions.map((action) => action.id);
        updatedScheduleActionsIds.push(...newIds);

        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                selectedItemView: {
                    ...item,
                    actionsIds: updatedScheduleActionsIds,
                },
            },
        });

        // RESET LOCAL STATE
        setState((prevState) => ({
            ...prevState,
            actionPlanLink: {
                isDisplayed: false,
                selectedActions: [],
            },
        }));
    }, [
        appDispatcher,
        currentUser.username,
        item,
        projectData,
        scheduleDispatcher,
        state.actionPlanLink.selectedActions,
    ]);

    // UNLINK SELECTED ACTIONS FROM THIS SCHEDULE
    const onUnlinkAction = useCallback(() => {
        // INIT
        let finalMutation = {
            graphql: {
                schedule: {
                    query: updateTelescopeDataSchedule,
                    objects: [],
                },
                action: {
                    query: updateTelescopeDataAction,
                    objects: [],
                },
            },
            dispatcher: {
                schedule: {
                    update: [],
                },
                action: {
                    update: [],
                },
            },
        };

        // FOR EACH SELECTED ACTION
        selectedItems.forEach((action) => {
            // UPDATE ACTION LINKS LIST
            action.links = ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(action.links, item.id);

            // ADD TO MUTATION
            finalMutation.dispatcher.action.update.push(action);
            finalMutation.graphql.action.objects.push({
                id: action.id,
                links: action.links,
            });
        });

        // UPDATE CURRENT SCHEDULE ACTIONS IDS
        let actionsIdsToRemove = selectedItems.map((item) => item.id);
        let newActionIds = item.actionsIds.filter(
            (actionId) => !actionsIdsToRemove?.includes(actionId)
        );
        let updatedSchedule = {
            ...item,
            actionsIds: newActionIds,
        };

        // ADD SCHEDULE MUTATION
        finalMutation.dispatcher.schedule.update.push(updatedSchedule);
        finalMutation.graphql.schedule.objects.push({
            id: updatedSchedule.id,
            actionsIds: updatedSchedule.actionsIds,
        });

        // UPDATE GRAPHQL
        for (let queryName of Object.keys(finalMutation.graphql)) {
            multipleMutateGraphql(
                finalMutation.graphql[queryName].query,
                finalMutation.graphql[queryName].objects,
                appDispatcher
            );
        }

        //UPDATE APP STATE
        appDispatcher({
            type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
            object: finalMutation.dispatcher,
        });

        // UPDATE SCHEDULE DISPATCHER
        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                selectedItemView: updatedSchedule,
                selected: [],
            },
        });
    }, [appDispatcher, item, scheduleDispatcher, selectedItems]);

    // HANDLE DELETE
    const handleAction = useCallback(
        (e, actionType, reset) => {
            e.preventDefault();

            //RESET
            if (reset) {
                setState((prevState) => ({
                    ...prevState,
                    confirm: {
                        value: false,
                        type: "",
                    },
                }));

                //ALREADY CONFIRM CAN DELETE
            } else if (state.confirm.value) {
                //TYPE
                switch (actionType) {
                    //DUPLICATE
                    case "duplicate":
                        handleDuplicateClick(e);
                        break;

                    //DELETE
                    case "delete":
                        onDeleteAction(e);
                        break;

                    default:
                        break;
                }

                //UPDATE STATE
                setState((prevState) => ({
                    ...prevState,
                    confirm: {
                        value: false,
                        type: "",
                    },
                }));

                //NEED CONFIRM
            } else {
                setState((prevState) => ({
                    ...prevState,
                    confirm: {
                        value: true,
                        type: actionType,
                    },
                }));
            }
        },
        [handleDuplicateClick, onDeleteAction, state.confirm.value]
    );

    // WHEN TABLE SELECT LIST IS UPDATED
    const onSelectedItemsChanged = useCallback(
        (newSelectedList) => {
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selected: newSelectedList,
                },
            });
        },
        [scheduleDispatcher]
    );

    // WHEN SEARCH BAR TEXT IS UPDATED
    const onSearchTextInputChanged = useCallback(
        (value) => {
            scheduleDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: "textSearchInput",
                value: value,
            });
        },
        [scheduleDispatcher]
    );

    // WHEN CLICK ON AN ITEM FROM THE ACTION PLAN TABLE
    const onActionPlanItemClick = useCallback(
        (action) => {
            // BUILD WBS NAVIGATION OPTIONS
            let wbsNavigationOptions = _.cloneDeep(WBS_DEFAULT_NAVIGATION_OPTIONS);

            // SET ORIGIN
            wbsNavigationOptions.originDomain = "schedule";
            wbsNavigationOptions.originItemId = item.id;

            // SET DESTINATION
            wbsNavigationOptions.goToItemId = action.id;
            wbsNavigationOptions.goToDomain = "action";

            // SET OPTION TO OPEN CONNECTION TABS
            wbsNavigationOptions.options = {
                openLinksTab: true,
            };

            // OPEN THE DESTINATION ITEM
            WBS_OPEN_ITEM(
                projectData,
                "action",
                action.id,
                null,
                wbsNavigationOptions,
                wbsDispatcher,
                appDispatcher,
                securityGroup
            );
        },
        [appDispatcher, item.id, projectData, securityGroup, wbsDispatcher]
    );

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    const canModifyActions = useMemo(() => {
        if(actionsData.length === 0 || selectedItems.length === 0 || !item) return false;
        
        for(const action of selectedItems) {
            if(
                action.responsible !== currentUser.username && 
                action.createdBy !== currentUser.username &&
                !isCurrentUserEditor
            ) {
                return false;
            }
        }
        return true;
    }, [actionsData.length, currentUser.username, isCurrentUserEditor, item, selectedItems]);

    // RO ACTIONS TABLE TAG FILTERS COMPONENT
    const customFilterComponent = useMemo(() => {
        if (legendAndCounter) {
            return (
                <ActionTableLegend
                    legendAndCounter={legendAndCounter}
                    statusSearchInput={statusSearchInput}
                    actionsDispatcher={scheduleDispatcher}
                />
            );
        }

        return null;
    }, [legendAndCounter, scheduleDispatcher, statusSearchInput]);

    // RO ACTIONS TABLE HEADER CUSTOM COMPONENT (STRATEGY AND ACTIONS BUTTONS)
    const customTitleComponent = useMemo(() => {
        return (
            <React.Fragment>
                {/* BUTTONS */}
                <div
                    className="flexStartCenter"
                    style={{
                        marginTop: "-3px",
                    }}
                >
                    {/* EDIT */}
                    {canModifyActions && canModifySchedules && selectedItems.length === 1 ? (
                        <CvtSideBarButton
                            buttonClass="flexCenterCenter buttonSideBar"
                            id="tooltipEditBig"
                            icon="edit"
                            fontSize="20px"
                            iconPadding="0px"
                            margin="0px"
                            padding="0px"
                            backgroundColor="transparent"
                            title="Edit Action"
                            onClickFunction={() => handleEditAction(selectedItems[0])}
                        />)
                    : null}

                    {/* DUPLICATE */}
                    {selectedItems.length > 0 && actionsData.length > 0 && canModifySchedules ? (
                        <CvtSideBarButton
                            buttonClass={`
                                    flexCenterCenter buttonSideBar roCardActionsTableHeadersBtn
                                    ${
                                        state.confirm.value && state.confirm.type === "duplicate"
                                            ? "buttonSideBarAlert"
                                            : "backgroundTransparent"
                                    }
                                `}
                            id="tooltipDuplicateBig"
                            icon="copy_all"
                            fontSize="20px"
                            margin="0px"
                            backgroundColor={
                                state.confirm.value && state.confirm.type === "duplicate"
                                    ? null
                                    : "transparent"
                            }
                            title={
                                state.confirm.value && state.confirm.type === "duplicate"
                                    ? "Are you sure?"
                                    : `Duplicate action${selectedItems.length > 1 ? "s" : ""}`
                            }
                            onClickFunction={(e) => handleAction(e, "duplicate")}
                            onBlur={(e) => handleAction(e, "duplicate", true)}
                            onBlurConfirmation={
                                state.confirm.value && state.confirm.type === "duplicate"
                                    ? state.confirm.value
                                    : false
                            }
                        />)
                    : null}

                    {/* DELETE */}
                    {canModifyActions && canModifySchedules && selectedItems.length > 0 ? (
                        <CvtSideBarButton
                            buttonClass={`
                                flexCenterCenter buttonSideBar
                                ${
                                    state.confirm.value && state.confirm.type === "delete"
                                        ? "buttonSideBarConfirmDelete"
                                        : "backgroundTransparent"
                                }
                            `}
                            id="tooltipDeleteBig"
                            testId='buttonDeleteHeader'
                            icon="delete"
                            margin="-2px 0px 0px 0px"
                            fontSize="20px"
                            backgroundColor={
                                state.confirm.value && state.confirm.type === "delete"
                                    ? null
                                    : "transparent"
                            }
                            title={
                                state.confirm.value && state.confirm.type === "delete"
                                    ? "Are you sure?"
                                    : `Delete action${selectedItems.length > 1 ? "s" : ""}`
                            }
                            onClickFunction={(e) => handleAction(e, "delete")}
                            onBlur={(e) => handleAction(e, "delete", true)}
                            onBlurConfirmation={
                                state.confirm.value && state.confirm.type === "delete"
                                    ? state.confirm.value
                                    : false
                            }
                        />)
                    : null}

                    {/* UNLINK */}
                    {selectedItems.length > 0 && canModifyActions && canModifySchedules ? (
                        <CvtSideBarButton
                            buttonClass="flexCenterCenter buttonSideBar"
                            id="tooltipUnlinkAction"
                            icon="link_off"
                            margin="-2px 0px 0px 0px"
                            backgroundColor="transparent"
                            fontSize="20px"
                            title="Unlink this Action"
                            onClickFunction={onUnlinkAction}
                        />)
                    : null}
                </div>
            </React.Fragment>
        );
    }, [canModifyActions, canModifySchedules, selectedItems, actionsData.length, state.confirm.value, state.confirm.type, onUnlinkAction, handleEditAction, handleAction]);

    // ACTION PLAN TABLE CUSTOM BOTTOM COMPONENT
    const customBottomComponent = useMemo(() => {
        if (isReadOnlyMode || !canModifySchedules) {
            return null;
        }

        // ACTION MULTIPLE SELECT
        if (state.actionPlanLink.isDisplayed && item) {
            // GET ACTIONS LIST AND DON'T SHOW THE ONES THAT ARE ALREADY LINKED TO A SCHEDULE

            // PARSE EXISTING ACTION LINKS
            let actionsLinks = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(item.actionsIds);

            // REMOVE ACTIONS THAT ALREADY HAVE A LINK
            let actionOptions = allActions.filter((action) => !actionsLinks?.includes(action.id));

            // REMOVE ACTIONS FROM AN OTHER PROJECT
            actionOptions = actionOptions.filter((action) => action.projectId === item.projectId);

            // CREATE OPTIONS FOR SELECT LIST
            actionOptions = actionOptions.map((action) => ({
                value: action.id,
                label: action.displayId + " : " + action.action,
            }));

            // RETURN COMPONENT
            return (
                <div
                    className="flexStartCenter"
                    style={{
                        width: "100%",
                    }}
                >
                    {/* SEELCT ACTIONS */}
                    <CvtSelectMultiple
                        padding="0px 8px 0px 8px"
                        margin="2px 10px 0px 0px"
                        maxHeight="150px"
                        fullWidth={true}
                        options={actionOptions}
                        menuPlacement="top"
                        onChange={onActionLinkSelected}
                        value={state.actionPlanLink.selectedActions}
                    />

                    {/* CANCEL */}
                    <CvtButton
                        color="gray"
                        icon="close"
                        handleSubmit={() => {
                            setState((prevState) => ({
                                ...prevState,
                                actionPlanLink: {
                                    isDisplayed: false,
                                    selectedActions: [],
                                },
                            }));
                        }}
                        margin="0px 10px 0px 0px"
                        size="tiny"
                        tooltip
                        tooltipPosition="top"
                        tooltipTheme="dark"
                        tooltipTxt="Cancel"
                    />

                    {/* VALIDATE AND ADD ACTIONS */}
                    <CvtButton
                        color="green"
                        icon="done"
                        handleSubmit={onLinkNewActions}
                        size="tiny"
                        tooltip
                        tooltipPosition="top"
                        tooltipTheme="dark"
                        tooltipTxt="Link these actions"
                    />
                </div>
            );
        }

        // DEFAULT BUTTONS
        return (
            <React.Fragment>
                {/* CREATE ACTION */}
                <CvtButton
                    color="white"
                    shape="noShape"
                    size="small"
                    icon="add"
                    txt="Create Action"
                    handleSubmit={() => {
                        handleCreateAction(null);
                    }}
                    margin="5px 0px 0px 0px"
                />

                {/* LINK ACTION */}
                <CvtButton
                    color="white"
                    shape="noShape"
                    size="small"
                    icon="link"
                    txt="Link existing Action"
                    handleSubmit={() => {
                        setState((prevState) => ({
                            ...prevState,
                            actionPlanLink: {
                                isDisplayed: true,
                                selectedActions: [],
                            },
                        }));
                    }}
                    margin="5px 0px 0px 0px"
                />
            </React.Fragment>
        );
    }, [allActions, canModifySchedules, handleCreateAction, isReadOnlyMode, item, onActionLinkSelected, onLinkNewActions, state.actionPlanLink.isDisplayed, state.actionPlanLink.selectedActions]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexStartStart flexOne"
            style={{
                margin: "0px 20px",
                gap: "20px",
            }}
        >
            {/* LEFT BLOCK : TITLE, INFOS */}
            <div
                className="flexColStartStart flexOne"
                style={{
                    height: "100%",
                }}
            >
                {/* AVATAR, DISPLAY ID, TITLE */}
                <CvtItemCardTitle item={item} titleField="name" users={users} />

                {/* CARD INFO */}
                <CvtItemCardInfosTabs
                    key={`schedule_card_infos_tab_${scheduleCardPropKey}`}
                    commentsName="comments"
                    currentUser={currentUser}
                    descriptionName="description"
                    eventsName="events"
                    item={item}
                    margin="20px 0px 0px"
                    megaUserIds={megaUsersId}
                    users={users}
                    readOnly={
                        isReadOnlyMode ||
                        !canModifySchedules
                    }
                    onValueUpdated={onItemCardInfoUpdated}
                    projectData={projectData}
                    forceCanAddComments={!isReadOnlyMode}
                />
            </div>

            {/* RIGHT: LINKED ACTIONS TABLE */}
            <div
                className="flexOne"
                style={{
                    height: "100%",
                }}
            >
                <CvtItemTable
                    title={"Action Plan"}
                    data={actionsData}
                    itemTitleField="action"
                    wbsId={wbsId}
                    usersList={users}
                    megaUsersId={megaUsersId}
                    selectedItems={selectedItems}
                    searchTextInput={textSearchInput}
                    isReadOnlyMode={isReadOnlyMode || !canModifySchedules}
                    isCurrentUserEditor={canModifySchedules}
                    CustomItemRightComponents={RiskCardActionsTableItemTags}
                    customFilterComponent={customFilterComponent}
                    customTitleComponent={customTitleComponent}
                    bottomCustomComponent={customBottomComponent}
                    onSelectedItemsChanged={onSelectedItemsChanged}
                    onSearchTextInputChanged={onSearchTextInputChanged}
                    onClickAdd={handleCreateAction}
                    onItemClick={onActionPlanItemClick}
                />
            </div>
        </div>
    );
}
