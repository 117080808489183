/* eslint-disable import/no-webpack-loader-syntax */
import TermsEN from "./00-Terms-EN"
import TermsFR from "./00-Terms-FR"
import TermsES from "./00-Terms-ES"
import TermsDE from "./00-Terms-DE"

//OBJECT WITH JSX TRANSFORMED FILE + ATTRIBUTES
const TERMS_FILES = [
    {
        id:"EN",
        parentId: null,
        level:0,
        title: "Terms",
        icon: "flag",
        file: TermsEN
    },
    {
        id:"FR",
        parentId: null,
        level:0,
        title: "Terms",
        icon: "flag",
        file: TermsFR
    },
    {
        id:"ES",
        parentId: null,
        level:0,
        title: "Terms",
        icon: "flag",
        file: TermsES
    },
    {
        id:"DE",
        parentId: null,
        level:0,
        title: "Terms",
        icon: "flag",
        file: TermsDE
    },
]

//EXPORT DEFAULT 
export default TERMS_FILES