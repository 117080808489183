import { FIND_OBJECT_ARRAY_ITEM } from "@mi-gso/cvt";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING } from "../../../../../00-Core/Standards";
import {
    WBS_DEFAULT_NAVIGATION_OPTIONS,
    WBS_RIGHT_COMPONENT,
} from "../../../00-Wbs/00-Helpers/WbsConstants";
import { WBS_OPEN_ITEM } from "../../../00-Wbs/00-Helpers/WbsFunctions";
import { ACTION_COMPONENT_EXPAND_TYPES } from "../../00-Helpers/ActionsConstants";
import ActionTableLinkIcon from "./ActionTableLinkIcon";

export default function ActionTableLinkIconsList({
    action,
    projectData,
    onHoverTooltip,
    onOpenActionItem,
    securityGroup,
    wbsDispatcher,
    appDispatcher,
    actionsDispatcher,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // PARSED LINKS
    const parsedLinks = useMemo(() => {
        // PARSE
        let links = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(action.links);
     
        // FOR EACH LINK CHECK DESTINATION ITEM EXISTS
        links = links?.filter((link) => {
            if(link && typeof link === "object") {
                let itemFound = FIND_OBJECT_ARRAY_ITEM(
                    projectData[link.type],
                    "id",
                    link.destinationItemId
                );
    
                if (!itemFound) {
                    return false;
                }
                return true;
            }
            return false;
        });

        return links;
    }, [action.links, projectData]);

    // PARSE LINKS
    const displayedLinks = useMemo(() => {
        // GET ONLY 5 LAST LINKS
        return [...parsedLinks].reverse().slice(0, 5);
    }, [parsedLinks]);

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // OPEN LINKED ITEM
    const onOpenLinkItem = useCallback(
        (e, link) => {
            e.preventDefault();
            e.stopPropagation();

            // IF LINK, OPEN THE DESTINATION ITEM
            if (link) {
                // BUILD WBS NAVIGATION OPTIONS
                let wbsNavigationOptions = _.cloneDeep(WBS_DEFAULT_NAVIGATION_OPTIONS);

                // SET ORIGIN
                wbsNavigationOptions.originDomain = "action";

                // SET DESTINATION
                wbsNavigationOptions.goToItemId = link.destinationItemId;
                wbsNavigationOptions.goToDomain = link.type;
                wbsNavigationOptions.overrideWbsId = action.projectId;

                // OPEN THE DESTINATION ITEM
                WBS_OPEN_ITEM(
                    projectData,
                    link.type,
                    link.destinationItemId,
                    null,
                    wbsNavigationOptions,
                    wbsDispatcher,
                    appDispatcher,
                    securityGroup
                );

                // IF LINK IS REVIEW, CHANGE EXPAND MODE
                if (link.type === WBS_RIGHT_COMPONENT.govReview) {
                    actionsDispatcher({
                        type: "SET_STATE_KEY_VALUE",
                        key: "expandMode",
                        value: ACTION_COMPONENT_EXPAND_TYPES.tableOnly,
                    });
                }
            }
            // IF NO LINK, OPEN LINK LIST
            else {
                onOpenActionItem(action, true);
            }
        },
        [
            action,
            actionsDispatcher,
            appDispatcher,
            onOpenActionItem,
            projectData,
            securityGroup,
            wbsDispatcher,
        ]
    );

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="flexStartStart"
            style={{
                marginLeft: "5px",
            }}
        >
            {/* ICONS LIST */}
            {displayedLinks.map((link) => (
                <ActionTableLinkIcon
                    key={"actionTableLinkIcon_" + link.id}
                    link={link}
                    projectData={projectData}
                    onHoverTooltip={onHoverTooltip}
                    onOpenLinkItem={onOpenLinkItem}
                />
            ))}

            {/* IF MORE THAN 5, DISPLAY THREE DOTS ICON */}
            {parsedLinks.length > 5 ? (
                <ActionTableLinkIcon
                    onHoverTooltip={onHoverTooltip}
                    onOpenLinkItem={onOpenLinkItem}
                />
            ) : null}
        </div>
    );
}
