// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//Pictures**
import { picTelActions1, picTelActions2, picTelActions3 } from '../../../00-Core/02-Pictures/PicturesCatalogue';

//THE FILE TEXT
const markdown = `

# Presentation

The Action column allows you manage your action and see its advancement
through the different branches of your WBS.

The pill KPI enables you to see:

> **1**.  Number of open actions     
**2**.  Number of late actions (red)       
**3**.  Number of on hold actions (yellow)       

![picTELActions1](${picTelActions1} "" )

The Actions view is divided in two parts, first a burning curve for your
opened actions on the left. And on the right, you have the list of
actions with the possibility to filter by status (even closed actions)
and to search through it.

![picTELActions2](${picTelActions2} "" )

> **1**.  Add an action          
**2**.  Show only the items of the branch          
**3**.  Burning curve       
**4**.  Slicer to modify the time scale of the graph       
**5**.  Button to expend the graph       
**6**.  The list of actions (by default, the done actions are not displayed
    unless there are only done action in the list)         
**7**.  Filters on the status         
**8**.  Search bar              
**9**.  Number of items displayed (dynamic with filters)         
**10**. Button to select all items              
**11**. Item ID                   
**12**. Item name                    
**13**. Icon to indicate the item is coming from a lower branch in the WBS.
    By overing it, the branch is displayed       
**14**. "New" icon if the action has been created in the past 7 days   
**15**. Icon to show the action is linked to a risk. When overing the icon,
    the name of the risk is displayed. By clicking on the icon, the risk
    card opens (see Risk section)      
**16**. Item status : To do | In progress | On hold | Done | Late (late is a status in addition of the others: an action can
        be in progress and late)        
> **17**. Item due date   
**18**. Item responsible

When you click on an action in the list, it displays a specific view of
it

![picTELActions3](${picTelActions3} "" )

> **1**.  Back button to return to global action view   
**2**.  Edit button  
**3**.  Duplicate button  
**4**.  Delete button  
**5**.  Move button  
**6**.  Action's owner  
**7**.  Action title  
**8**.  Action description   
**9**.  Action timeline   
**10**. Action comment space    
**11**. Action events

***

# Add an item

## Description

Any user can add an Action item. As soon as an item is added, it will be
visible in the table and on the burning curve.

## Process

Click on the pill KPI of the WBS where you want to add your item: the
big component opens. You have two options to create a new action item:

> **1**.  In the component's sidebar, click on the "add" button (+)        
**2**.  Or click on "Create Action" button at the bottom of the list

Once you have clicked on one of these buttons, fill at least the
mandatory fields and click on "Save" at the top right corner.

Here are the different fields:

-   **Name** (mandatory): the name of your item
-   **Responsible** (mandatory): the person in charge of this item
-   **Contributors**: people that can participate to the action
-   **Due date** (mandatory): Forecast finish date
-   **Status** (mandatory): status of the action (see upper)
-   **General**: click to add further information
    -   Created date: define the date of creation for your action
    -   Description

***

# Edit an item

## Description

To modify one or several fields of an item, you can edit it.

    WARNING: OMU and PMU can edit all action items. A PU can only edit the action he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the edit button (pencil) of the sidebar >
Edit form opens > Modify the fields you want and click on "save".

If you want to discard the changes, click on the close button or outside
the edit form and click a second time to confirm.

***

# Duplicate an item

## Description

You can duplicate an item if you want. It will be duplicated in the same
branch (if you want to move it to another branch/project, see the "move
an item" section).

    WARNING: OMU and PMU can duplicate all actions items. A PU can only
duplicate the items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the duplicate button of the sidebar > Click a
second time to confirm > item is created with "Duplicate" at the
beginning of the title

***

# Delete an item

## Description

Items can be deleted if necessary

    WARNING: OMU and PMU can delete all actions items. A PU can only delete the
items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the delete button (bin) of the sidebar >
Click a second time to confirm

***

# Move an item

## Description

If you have created an item in the wrong WBS branch, don't worry! You
can move it to the right one. You are even able to move it in a
different project that belongs to the same organization. In brief, you
can move an item anywhere within an organization (as long as you have
access to the new project).

    WARNING: OMU and PMU can move all actions items. A PU can only move the
items he has created.

## Process

In the list of the big component, select the item by clicking in the
check box at the left of the item or click on the item so it opens the
detailed view > Click on the move button of the sidebar > Click a
second time to confirm

***

# Display item of the current branch

## Description

By default, all the items are consolidated on the parent WBS branches:
on a WBS branch which have child, you see the items created on the WBS
branch but also the items created on the children. It is possible to
unable that and hide the children's items.

## Process

In the big component side bar, click on "only this branch's items"
button (second one of the sidebar). This will apply only on this branch.

If you want to apply this feature on all your WBS branches, see the
"General Sidebar" section/

***

# Filter items according to the status

## Description

It is possible to filter the items visible in the list by status. By
default, the visible items have the status "To do", "In progress" or "On
hold".

## Process

To filter your items according to the status, click on the status you
want to filter (you can click on several). The status not selected are
grayed out.

To remove your filter(s), click on the unfilter icon. If there is not
this icon, it means you are not filtered and are on the default view
(all status except "Done").

***

# Search for items

## Description

If you want to find a precise item, you can use the search bar. The
search is done on the item name only.

## Process

In the search bar, type a key word and the items are filtered
automatically.

***

# Expand the list to have more details

## Description

If you expand the list, you will have more details on each item

## Process

In the big component, click on the arrow at left corner of the list >
the burning disappears

***

#  Expand the graph

## Description

You can expand the graph to have a better visualization of your burning
curve.

## Process

In the big component, click on the arrow at right corner of the graph >
the list disappears.

***

#  Change the forecasted week

## Description

By default, the burning curve only show two weeks before actual date and
12 after the current week. It is possible to increase or decrease the
number of weeks displayed after the current week

## Process

In the big component, next to the burning curve title:    
Click on the "+" or "-" to add or remove a week

***

#  Open the detailed view

## Description

If you click on an item of the list, the Card view opens and you will
have more details (cf pictures in intro).

    WARNING: Any user can open the detailed view. From this view, if you have the rights, you can edit the description and add comments.
## Process

In the list of the big component, click on the item for which you want
to see more details > the detailed view opens

***

#  Edit the description form detailed view

## Description

In the detailed view, you can directly edit the description (without
entering the edit mode).

    WARNING: OMU and PMU can edit the description of all items. A PU can only edit the item he has created.

## Process

In the list of the big component, click on the item for which you want
to change the description > the detailed view opens > Click in the
description box > Update the description > Click outside of the box to
save the changes.

    WARNING: It is not possible to discard the changes

***

#  Add a comment

## Description

In the detailed view, you can add comment(s) on the item.

    WARNING: Any user can add a comment

## Process

In the list of the big component, click on the item for which you want
to add a comment > the detailed view opens > Click on "Comments" >
Type your comment in the "New comment" zone > click on "Validate" to
publish your comment.

***

# Edit a comment

## Description

In the detailed view, you can edit comment(s) you have created.

## Process

In the list of the big component, click on the item for which you want
to modify your comment > the detailed view opens > Click on "Comments" > Click on the pencil button to edit your comment > make the changes
you want > Click on validate to save the changes.

***

# Delete a comment

## Description

In the detailed view, you can delete comment(s) you have created.

## Process

In the list of the big component, click on the item for which you want
to delete your comment > the detailed view opens > Click on "Comments" > Click on the bin button to delete your comment > click a second time
to validate.

***

# View all the events that occurred on the item

## Description

In the detailed view, you can see all the events that occurred on the
item: creation date, created by, all modifications of the fields of the
item (responsible, description, etc.)

## Process

In the list of the big component, click on the item for which you want
to see the events > the detailed view opens > Click on "Events"

***

# Open a risk to which an action is linked

## Description

Actions can also be created form risk & opportunities columns (see risk
& opportunities section). Actions created in the action plan of a risk
are visible in the action column and identified by an icon (see screen
shot in intro). When overing the icon, the name of the risk is
displayed, by clicking on it, the risk opens.

## Process

In the list of the big component, click on the risk icon (if the item
has one) > the detailed view of the risk opens
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;