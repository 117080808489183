// LIBS
import { useCallback, useMemo, useState } from "react";

// CONSTANTS
import { APP_FORM_STYLE } from "../../../../00-Core/Constants";
import { BOARD_PORTFOLIO_STATUS } from "../../00-Helpers/BoardConstants";

// FUNCTIONS
import { FUNC_CHECK_URL_VALID, FUNC_ZERO_FORMAT_TO_NUM } from "../../../../00-Core/Standards";

// COMPONENTS
import PortfolioPicture from "./PortfolioPicture";
import { CvtButton, CvtInput, CvtInputTextArea, CvtSelect, CvtCheckbox } from "@mi-gso/cvt";
import { FUNC_BOARD_COUNT_USERS } from "../../00-Helpers/BoardFunctions";

export default function PortfolioCreateEditGlobalInfos({ portfolioEdit, boardDispatcher }) {

    /////////////////////////////////////////////////////
    // STATE ////////////////////////////////////////////
    /////////////////////////////////////////////////////

    const [state, setState] = useState({
            portfolioName: false,
            shortDescription: false,
            longDescription: false
        });

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // COUNT USERS IN PORTFOLIO
    const portfolioUsersCount = useMemo(() => {
        return FUNC_BOARD_COUNT_USERS(portfolioEdit.itemViewers, portfolioEdit.itemEditors);
    }, [portfolioEdit.itemEditors, portfolioEdit.itemViewers]);

    /////////////////////////////////////////////////////
    // FUNCTIONS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // GLOBAL INPUT CHANGE
    const handleChange = useCallback(
        (e) => {
            boardDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                    source: "portfolioEdit",
                    object: {
                        [e.target.name]: e.target.value,
                    },
                },
            });
        },
        [boardDispatcher]
    );


    // ON TOGGLE CLICK
    const handleToggleChange = useCallback(
        (e) => {
            boardDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                    source: "portfolioEdit",
                    object: {
                        official: !portfolioEdit.official,
                    },
                },
            });
        },
        [boardDispatcher, portfolioEdit.official]
    );

    // ON BLUR
    const handleBlur = (field) => (evt) => {
        setState((prev) => {
            return {
                ...prev,
                [field]: true
            }
        });
    };
    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div
            className="structureBlockInfo flexColStrechStart"
            style={{
                width: "33%",
                flexGrow: 1,
                borderRight: "1px solid var(--border-color-gray-0)",
                padding: "20px",
                overflow: "auto",
            }}
        >
            <span className="boardPortfolioCreateFormColumnTitle">Global infos</span>

            {/* REFERENCE AND PICTURE */}
            <div className="flexBetweenStart boardPortfolioCreateFormInput">
                {/* REFERENCE */}
                <CvtInput
                    legend="Reference"
                    legendMargin="0px 0px 10px 0px"
                    legendColor={APP_FORM_STYLE.legendColor}
                    legendFontSize={APP_FORM_STYLE.legendFontSize}
                    value={"PORT" + FUNC_ZERO_FORMAT_TO_NUM(portfolioEdit.reference, 4)}
                    isOnViewMode
                    info="ID of this Portfolio"
                />

                {/* PICTURE */}

                <div
                    className="flexCenterCenter"
                    style={{
                        width: "170px",
                    }}
                >
                    <PortfolioPicture
                        pictureUrl={
                            portfolioEdit.pictureUrl &&
                            FUNC_CHECK_URL_VALID(portfolioEdit.pictureUrl)
                                ? portfolioEdit.pictureUrl
                                : null
                        }
                        name={portfolioEdit.name}
                    />
                </div>
            </div>

            {/* PORTFOLIO NAME */}
            <CvtInput
                legend="Name"
                legendMargin="0px 0px 10px 0px"
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                inputBlockCss={"boardPortfolioCreateFormInput"}
                value={portfolioEdit.name}
                name="name"
                placeholder="Portfolio name"
                onChange={handleChange}
                onBlur={handleBlur("portfolioName")}
                mandatory={true}
                info="Global name if the Portfolio"
                autoComplete={"off"}
                inputBorderColor={
                     state.portfolioName && portfolioEdit.name.length === 0
                        ? "var(--color-bad)"
                        : null
                }
            />

            {/* PICTURE URL */}
            <CvtInput
                legend="Picture URL"
                legendMargin="0px 0px 10px 0px"
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                inputBlockCss="boardPortfolioCreateFormInput"
                inputBorderColor={
                    portfolioEdit.pictureUrl !== "" &&
                    !FUNC_CHECK_URL_VALID(portfolioEdit.pictureUrl)
                        ? "var(--color-bad)"
                        : null
                }
                value={portfolioEdit.pictureUrl}
                name="pictureUrl"
                placeholder="https://..."
                onChange={handleChange}
                info="Picture must use HTTPS"
            />

            {/* SHORT DESCRIPTION */}
            <CvtInput
                legend="Short Description"
                legendMargin="0px 0px 10px 0px"
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                inputBlockCss="boardPortfolioCreateFormInput"
                value={portfolioEdit.shortDescription}
                name="shortDescription"
                placeholder="Short Description"
                mandatory={true}
                onChange={handleChange}
                onBlur={handleBlur("shortDescription")}
                info="Small description"
                maxLength={100}
                inputBorderColor={
                    state.shortDescription  && portfolioEdit.shortDescription.length === 0
                        ? "var(--color-bad)"
                        : null
                }
            />

            {/* LONG DESCRIPTION */}
            <CvtInputTextArea
                legend="Description"
                legendMargin="0px 0px 10px 0px"
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                inputBlockCss="boardPortfolioCreateFormInput"
                value={portfolioEdit.longDescription}
                name="longDescription"
                placeholder="Full Description"
                rows={4}
                mandatory={true}
                onChange={handleChange}
                onBlur={handleBlur("longDescription")}
                info="Detailed description"
                inputBorderColor={
                    state.longDescription  && portfolioEdit.longDescription.length === 0
                        ? "var(--color-bad)"
                        : null
                }
            />

            {/* STATUS SELECT */}
            <CvtSelect
                selectBlockCss="flexColStartStart modalGroup"
                selectFontSize={APP_FORM_STYLE.fontSize}
                selectCss="form-select modalInputLight modalSelectLight"
                selectPadding={APP_FORM_STYLE.selectPadding}
                selectBlockMargin={APP_FORM_STYLE.blockMargin}
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                legendMargin={APP_FORM_STYLE.legendMargin}
                legend={"Status"}
                value={portfolioEdit.status}
                name="status"
                list={BOARD_PORTFOLIO_STATUS}
                listValue={"value"}
                listName={"label"}
                onChange={handleChange}
                info={"Enable or Disable this Portfolio"}
            />

            {/* OFFICIAL TOGGLE */}
            <div className="flexBetweenCenter boardPortfolioCreateFormInput">
                <span
                    style={{
                        color: APP_FORM_STYLE.legendColor,
                        fontSize: APP_FORM_STYLE.legendFontSize,
                    }}
                >
                    Official
                </span>
                <CvtCheckbox
                    handleFunction={handleToggleChange}
                    value={portfolioEdit.official}
                    padding="0px"
                    width="fit-content"
                />
            </div>

            {/* SHARED WITH */}
            <div className="flexBetweenCenter boardPortfolioCreateFormInput">
                <span
                    style={{
                        color: APP_FORM_STYLE.legendColor,
                        fontSize: APP_FORM_STYLE.legendFontSize,
                    }}
                >
                    Shared with
                </span>

                {/* USER COUNT */}
                <span>
                    {portfolioUsersCount} Partner
                    {portfolioUsersCount > 1 ? "s" : ""}
                </span>

                {/* OPEN EDITION MODAL */}
                <CvtButton
                    color="white"
                    shape="noShape"
                    icon="edit"
                    handleSubmit={() => {
                        boardDispatcher({
                            type: "SET_SIMPLE_STATE",
                            key: "isEditingRoles",
                            value: true,
                        });
                    }}
                />
            </div>
        </div>
    );
}
