// LIBS
import { memo, useCallback, useMemo } from "react";

// COMPONENTS
import BoardLoader from "./BoardLoader";
import { CvtButton } from "@mi-gso/cvt";
import BoardProjectListPicture from "./BoardProjectListPicture";

export default memo(function BoardProjectsList({
  projectList,
  selectedProjectId,
  organizationList,
  userList,
  projectLoadingId,
  orgaLoadingId,
  handleOpenProjectWbs,
  openOrganizationWBS,
  appDispatcher,
}) {
  /////////////////////////////////////////////////////
  // FUNCTIONS ////////////////////////////////////////
  /////////////////////////////////////////////////////

  // SWITCH TO PORTFOLIO LIST VIEW ---------------------------------------
  const onSwitchToPortfolioListClick = useCallback(() => {
    //INIT USER
    appDispatcher({
      type: "SET_GLOBAL_STATE",
      options: {
        source: "portfolioSelection",
        object: {
          showProjetListView: false,
        },
      },
    });
  }, [appDispatcher]);

  /////////////////////////////////////////////////////
  // MEMO /////////////////////////////////////////////
  /////////////////////////////////////////////////////

  const projectListComponent = useMemo(() => {
    // MAP EACH ORGA
    return organizationList.map((organization) => {
      // GET PROJECTS LIST IN THIS ORGA
      let projects = projectList.filter(
        (project) =>
          project.coreDataOrganizationCoreDataProjectId === organization.id
      );

      // FIND ADMIN NAMES
      let orgAdmins = [];
      let orgAdminsFullString = "";

      // SEARCH NAME FOR EACH MEGA USER IN ORGANIZATION
      organization.organizationMegaUsersList.forEach((userId) => {
        let orgAdmin = userList.find((user) => user.id === userId);
        let orgAdminName = orgAdmin?.name;

        // ADD TO LIST ONLY IF USER FOUND
        if (orgAdminName && orgAdminName !== "") {
          orgAdmins.push(orgAdminName);

          // ADD ',' BETWEEN NAMES
          if (orgAdminsFullString !== "") {
            orgAdminsFullString += ", ";
          }
          orgAdminsFullString += orgAdminName;
        }
      });

      // IF PROJECTS, SHOW IN LIST
      if (projects.length > 0) {
        return (
          <div
            key={"boardOrga-" + organization.id}
            className="boardProjectListOrganizationBlock"
          >
            {/* ORGA INFORMATIONS */}
            <div
              className="flexStartCenter"
              style={{
                marginBottom: "20px",
                color: "var(--color-text-2)",
                gap: "20px",
                fontWeight: 500,
              }}
            >
              {/* ORGA PICTURE AND NAME */}
              <div
                className="flexStartCenter boardProjectListOrganizationBlockTitle"
                onClick={
                  orgaLoadingId === organization.id
                    ? null
                    : () => openOrganizationWBS(organization, projects)
                }
              >
                {/* LOGO */}
                <BoardProjectListPicture
                  pictureUrl={organization.logoLitle}
                  alt={organization.name}
                  size="40px"
                  loading={orgaLoadingId === organization.id}
                />

                {/* ORGA NAME */}
                <div style={{ marginLeft: "10px" }}>{organization.name}</div>
              </div>

              {/* ORG STATUS */}
              <span
                className="flexCenterCenter boardProjectListStatusLabel"
                style={{
                  backgroundColor:
                    organization.status === "enabled"
                      ? "var(--color-migso-blue)"
                      : "var(--color-bad-hover)",
                }}
              >
                <span
                  className="material-icons"
                  style={{
                    fontSize: "16px",
                    marginLeft: "2px",
                    marginRight: "5px",
                  }}
                >
                  {organization.status === "enabled"
                    ? "check_circle"
                    : "unpublished"}
                </span>
                {organization.status === "enabled" ? "Enabled" : "Disabled"}
              </span>

              {/* NB OF PROJECTS */}
              <span
                className="flexStartCenter"
                style={{
                  boxShadow: "1px 1px 3px 0 rgb(0 0 0 / 40%)",
                  outline: "1px solid var(--border-color-gray-1-5)",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <span
                  style={{
                    backgroundColor: "var(--border-color-gray-1-5)",
                    padding: "1px 5px",
                    color: "white",
                  }}
                >
                  {"# Projects "}
                </span>
                <span
                  className="flexCenterCenter"
                  style={{
                    backgroundColor: "white",
                    width: "20px",
                    padding: "1px 5px",
                    color: "var(--color-text-4)",
                    fontWeight: "500",
                  }}
                >
                  {projects.length}
                </span>
              </span>

              {/* ADMINS NAME LIST */}
              <div
                className="flexStartCenter"
                style={{ gap: "10px" }}
                id={"tooltipOrgaProjectList_" + organization.id}
              >
                <span className="material-symbols-outlined flexCenterCenter">
                  admin_panel_settings
                </span>
                <span>
                  {orgAdmins.length !== 0
                    ? orgAdmins.map((admin, index) => (
                        <span key={"megaUser-" + organization.id + "-" + index}>
                          {index > 0 ? ", " + admin : admin}
                        </span>
                      ))
                    : "Unknown"}
                </span>
              </div>
            </div>

            {/* PROJECTS LIST */}
            <div
              className="flexStartStart"
              style={{ gap: "20px", flexWrap: "wrap", paddingLeft: "10px" }}
            >
              {/* MAP EACH PROJECTS */}
              {projects.map((project) => (
                <div
                  className={
                    "boardProjectListItem flexStartCenter " +
                    (selectedProjectId === project.id
                      ? "boardPortfolioListCardSelected"
                      : null)
                  }
                  key={project.id}
                  onClick={
                    projectLoadingId === project.id
                      ? null
                      : () => handleOpenProjectWbs(organization.id, project.id)
                  }
                >
                  {/* LOGO */}
                  <BoardProjectListPicture
                    pictureUrl={project.logo}
                    alt={project.name}
                    size="30px"
                  />

                  {/* PROJECT NAME */}
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      marginLeft: "10px",
                      flex: 1,
                    }}
                  >
                    {project.name}
                  </span>

                  {/* LOADER AFTER CLICK */}
                  {projectLoadingId === project.id ? <BoardLoader /> : null}
                </div>
              ))}
            </div>
          </div>
        );
      }

      return null;
    });
  }, [
    handleOpenProjectWbs,
    openOrganizationWBS,
    orgaLoadingId,
    organizationList,
    projectList,
    projectLoadingId,
    selectedProjectId,
    userList,
  ]);

  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////

  return (
    <div className="structureBlock boardBrowsingStructureBlock">
      {/* TITLE */}
      <div className="structureBlockTitle flexStartCenter">
        <div className="flexStartCenter">
          <span className="material-icons structureBlockTitleIcon boardTitleIcon">
            category
          </span>
          Projects
        </div>

        {/* SWITCH TO PROJECT LIST VIEW */}
        <CvtButton
          color="blue"
          margin="-5px 10px -5px 15px"
          icon="wallet"
          txt="See Portfolios"
          size="small"
          handleSubmit={onSwitchToPortfolioListClick}
        />
      </div>

      {/* CONTENT */}
      <div className="boardBrowsingBlockContent">
        {/* PROJECTS LIST */}
        {projectListComponent}
      </div>
    </div>
  );
});
