import _ from "lodash";
import {
  ACTION_COMPONENT_EXPAND_TYPES,
  ACTION_START_DISPLAY_ID,
  ACTION_STATUS_KEY,
} from "./ActionsConstants";
import { TODAY } from "../../../../00-Core/Constants";
import {
  FUNCT_FIND_INDEX,
  FUNC_DATE_TO_TXT_STANDARD,
  FUNC_GET_WEEK_NUMBER,
  FUNC_REMOVE_ELEMENT_FROM_ARRAY,
  FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING,
  FUNC_ZERO_FORMAT_TO_NUM,
} from "../../../../00-Core/Standards";
import { v4 as uuid } from "uuid";
import {
  createTelescopeDataAction,
  deleteTelescopeDataAction,
  updateTelescopeDataAction,
  updateTelescopeDataGovReview,
  updateTelescopeDataGovScopeChange,
  updateTelescopeDataRO,
  updateTelescopeDataSchedule,
} from "../../../../graphql/mutations";
import { FIND_OBJECT_ARRAY_ITEM, ItemEvent } from "@mi-gso/cvt";
import { multipleMutateGraphql } from "../../../00-App/02-Backend/AppBackendCommon";
import {
  GET_MUTATION_TO_DELETE_ITEM_FROM_REVIEW_AGENDA,
  GET_NEW_AGENDA_ITEM_OBJECT,
} from "../../04-Governance/00-Helpers/GovReviewFunctions";
import { FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND } from "../../04-Governance/00-Helpers/GovFunctions";
import {
  WBS_BIG_COMPONENT_VIEW_MODE,
  WBS_DATA_ICON,
} from "../../00-Wbs/00-Helpers/WbsConstants";
import { FUNC_CONVERT_JSON_TO_EXCEL } from "../../../../00-Core/00-Standards/FileStandards";
import { ACTION_DEFAULT_ITEM, ACTION_DEFAULT_STATE } from "./ActionsDispatcher";

// SUMMARY
// - FUNC_ACTION_NEED_TO_ADD_EVENT
// - FUNC_ACTION_GET_BIGGEST_DISPLAY_ID
// - FUNC_ACTION_IS_LATE
// - FUNC_ACTION_GET_NUM
// - FUNC_ACTION_CREATE_LINK_OBJECT
// - FUNC_ACTION_ADD_NEW_LINKS
// - FUNC_ACTION_PREPARE_DATA_EXPORT

let updateLinksMutation = {
  graphql: {
    action: {
      query: updateTelescopeDataAction,
      objects: [],
    },
    govScopeChange: {
      query: updateTelescopeDataGovScopeChange,
      objects: [],
    },
    govReview: {
      query: updateTelescopeDataGovReview,
      objects: [],
    },
    riskOpp: {
      query: updateTelescopeDataRO,
      objects: [],
    },
    schedule: {
      query: updateTelescopeDataSchedule,
      objects: [],
    },
  },
  dispatcher: {
    action: {
      update: [],
    },
    govScopeChange: {
      update: [],
    },
    govReview: {
      update: [],
    },
    riskOpp: {
      update: [],
    },
    schedule: {
      update: [],
    },
  },
};

// GET THE DEFAULT ACTION ITEM
export function ACTION_GET_DEFAULT_ITEM(
  selectedOrganizationId,
  wbsId,
  userId,
  selectedReviewItem,
  projectId,
) {
  let item = _.cloneDeep(ACTION_DEFAULT_ITEM);

  item.organizationId = selectedOrganizationId;
  item.projectId = wbsId;
  item.wbsId = wbsId;
  item.createdBy = userId;
  item.responsible = userId;

  // IF THERE'S A REVIEW SELECTED AND IS FROM THE SAME PROJECT AS WHERE CREATING THE ACTION FROM
  if (selectedReviewItem && selectedReviewItem.projectId && selectedReviewItem.projectId === projectId) {
    item.links = JSON.stringify([FUNC_ACTION_CREATE_LINK_OBJECT("govReview", selectedReviewItem.id)]);
  }
  return item;
}

// GET INITIAL ACTION STATE
export function ACTION_GET_INITIAL_STATE(
  isReviewMode,
  wbsNavigationOptions,
  actions
) {
  let initialState = _.cloneDeep(ACTION_DEFAULT_STATE);

  // IF REVIEW MODE OPEN, DISPLAY ONLY TABLE
  if (isReviewMode) {
    initialState.expandMode = ACTION_COMPONENT_EXPAND_TYPES.tableOnly;
  } else {
    // MANAGE LIMIT SIZE FOR TO COLUMN IN BIG COMPONENT
    initialState.widthSizeLimit = window.innerWidth <= 1300;
  }

  // IF OPTION TO OPEN AN ITEM
  if (wbsNavigationOptions?.goToItemId) {
    // FIND THE ITEM
    let foundAction = FIND_OBJECT_ARRAY_ITEM(
      actions,
      "id",
      wbsNavigationOptions.goToItemId
    );

    // IF ACTION FOUND, SET DATA
    if (foundAction) {
      initialState.viewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
      initialState.actionView = foundAction;
    }
  }

  // OPEN CONNECTION TAB IF OPTION SET
  if (wbsNavigationOptions?.options?.openLinksTab) {
    initialState.selectedViewModeContent = "connections";
  }

  return initialState;
}

//TEST IF WE NEED TO ADD AN EVENT -------------------------------- OK
export const FUNC_ACTION_NEED_TO_ADD_EVENT = (eventsArray, key, userId) => {
  //TODAY DATE
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  //TRY TO FIND ONE
  let event = eventsArray.findIndex((item) => {
    //CURRENT DATE
    let currentDate = new Date(item.createdOn);
    currentDate.setHours(0, 0, 0, 0);

    //TEST KEY SAME / DATE SAME / USER SAME
    if (
      item.value?.toUpperCase()?.includes(key.toUpperCase()) &&
      currentDate.getTime() === today.getTime() &&
      item.user.id === userId &&
      key !== "status"
    )
      return true;
    return false;
  });

  //RETURN FINAL / IF ALREADY EVENT ON SAME DATE WE DO NOT NEED TO AD NEW ONE
  return event;
};

//GET THE BIGGEST DISPLAY ID NUM OF AN ACTION -------------------- OK
export const FUNC_ACTION_GET_BIGGEST_DISPLAY_ID = (actions) => {
  //TEST IF NOT EMPTY
  if (actions.length > 0) {
    //INIT
    let actionsData = _.cloneDeep(actions);

    // SORT ALL ACTIONS DATA
    actionsData = actionsData.sort((a, b) => {
      //GET NUMBER WITHOUT PREFIX
      let aDisplayIdNum =
        a.displayId !== "" ? parseInt(a.displayId.slice(2)) : 0;
      let bDisplayIdNum =
        b.displayId !== "" ? parseInt(b.displayId.slice(2)) : 0;

      //CALCULATE
      if (bDisplayIdNum - aDisplayIdNum > 0) {
        return 1;
      } else {
        return -1;
      }
    });

    //RETURN
    return parseInt(actionsData[0].displayId.slice(2));
  }

  //RETURN 1
  return 1;
};

//TEST IF ACTION LATE OR NOT
export const FUNC_ACTION_IS_LATE = (actionsStatus, actionsDueDate) => {
  //INIT
  let test = false;

  //TEST STATUS
  if (
    actionsStatus === ACTION_STATUS_KEY.todo ||
    actionsStatus === ACTION_STATUS_KEY.inProgress
  ) {
    //TEST IF DUEDATE NOT A DATE
    let dueDate = actionsDueDate;
    if (Object.prototype.toString.call(dueDate) !== "[object Date]") {
      dueDate = new Date(dueDate);
    }

    //TEST
    if (dueDate.getTime() < TODAY.getTime()) test = true;
  }

  //RETURN
  return test;
};

//GET WEEKEND NUM OF ACTION
export const FUNC_ACTION_GET_NUM = (action) => {
  //INIT
  let currentDate;

  //GET GOOD DATE
  if (action.status === ACTION_STATUS_KEY.onHold) {
    currentDate = new Date(action.cancelledDate);
  } else if (action.status === ACTION_STATUS_KEY.done) {
    currentDate = new Date(action.realisedDate);
  } else {
    currentDate = new Date(action.dueDate);
  }

  //RETURN NUM
  return FUNC_GET_WEEK_NUMBER(currentDate);
};

// CREATE LINK OBJECT TO LINK ACTION TO OTHER DOMAINS ITEMS
export function FUNC_ACTION_CREATE_LINK_OBJECT(type, destinationItemId) {
  return {
    id: uuid(),
    type: type,
    destinationItemId: destinationItemId,
  };
}

// ADD NEW LINKS FROM MULTIPLE ACTIONS TO MULTIPLE ITEMS
// RETURNS THE UPDATED ACTIONS
export function FUNC_ACTION_ADD_NEW_LINKS(
  newLinks,
  sourceActions,
  projectData,
  currentUserId,
  appDispatcher,
  isRemoveActionMutation
) {
  // INIT FINAL MUTATION
  let finalMutation = _.cloneDeep(updateLinksMutation);

  if (isRemoveActionMutation) {
    delete finalMutation.graphql.action;
  }

  // GET ACTIONS TO BE UPDATED
  let actions = _.cloneDeep(sourceActions);
  let actionsIds = actions.map((action) => action.id);

  // INIT GENERATION EVENT CONTENT
  let eventLinksData = [];

  // FOR EACH LINK, UPDATE THE ACTION AND DESTINATION ITEMS
  newLinks.forEach((newLink) => {
    // GET DESTINATION ITEM
    let destinationItem = FIND_OBJECT_ARRAY_ITEM(
      projectData[newLink.type],
      "id",
      newLink.destinationItemId
    );

    // FOR EACH ACTIONS SELECTED, ADD ONE LINK
    for (let index = 0; index < actions.length; index++) {
      // CREATE NEW LINK
      let link = FUNC_ACTION_CREATE_LINK_OBJECT(
        newLink.type,
        newLink.destinationItemId
      );

      // PARSE ACTION LINKS
      actions[index].links = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
        actions[index].links
      );

      // GET ACTION LINK ITEM IDS
      let actionLinkIds = actions[index].links.map(
        (link) => link.destinationItemId
      );

      // ADD THE NEW LINK TO THIS ACTION IF NOT ALREADY EXISTS
      if (!actionLinkIds?.includes(newLink.destinationItemId)) {
        actions[index].links.push(link);

        // NEED TO CREATE AN EVENT FOR THIS ACTION
        let eventLabel = null;
        if (newLink.type === "govReview") {
          // IF GOV REVIEW, SPECIFIC LABEL
          let startDateStr = destinationItem.startDate?.toLocaleString(
            undefined,
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }
          );
          eventLabel = startDateStr + " : " + destinationItem.name;
        } else {
          // OTHERWISE, GENERIC LABEL WITH DISPLAY ID
          eventLabel =
            destinationItem.displayId +
            " : " +
            (destinationItem.action ||
              destinationItem.name ||
              destinationItem.title);
        }

        // ADD EVENT TO THE EVENT LIST
        eventLinksData.push({
          actionId: actions[index].id,
          link: link,
          text: eventLabel,
        });
      }

      // STRINGIFY
      actions[index].links = JSON.stringify(actions[index].links);
    }

    // ADD ACTIONS IDS TO DESTINATION ITEM
    // IF NOT A GOV REVIEW, ADD TO ACTIONS IDS LIST
    if (newLink.type !== "govReview") {
      // PARSE ITS ACTIONS LINKS
      let currentActionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
        destinationItem.actionsIds
      );

      // ADD NEW IDS
      currentActionsIds.push(...actionsIds);

      // INSURE IDS ARE UNIQUES
      currentActionsIds = _.uniq(currentActionsIds);

      // GET ONLY THE ADDED ACTIONS IDS TO GENERATE AN EVENT
      let addedActionsIds = actionsIds.filter(
        (actionId) => !destinationItem.actionsIds?.includes(actionId)
      );

      // GENERATE DESTINATION ITEM EVENT
      let destinationItemEvent = ItemEvent(
        currentUserId,
        "actionsIds",
        addedActionsIds
      );

      // GET CURRENT EVENTS
      let currentEvents = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
        destinationItem.events
      );

      // ADD NEW EVENT
      currentEvents.push(destinationItemEvent);

      // UPDATE ITEM
      destinationItem.actionsIds = currentActionsIds;
      destinationItem.events = JSON.stringify(currentEvents);

      // ADD DESTINATION ITEM TO FINAL MUTATION
      finalMutation.dispatcher[newLink.type].update.push(destinationItem);
      finalMutation.graphql[newLink.type].objects.push({
        id: destinationItem.id,
        actionsIds: currentActionsIds,
        events: destinationItem.events,
      });
    }
    // IF GOV REVIEW, ADD TO AGENDA
    else {
      // PARSE AGENDA
      destinationItem.agenda = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
        destinationItem.agenda
      );

      // GET ONLY ACTIONS IDS NOT ALREADY IN THE AGENDA
      let actionsToAdd = actionsIds.filter((actionId) => {
        // TRY TO FIND THE ACTION ID IN THE AGENDA
        let foundAgendaItem = FUNCT_FIND_INDEX(
          destinationItem.agenda,
          "itemId",
          actionId
        );

        return foundAgendaItem === -1;
      });

      // FOR EACH ACTIONS TO ADD TO AGENDA, CREATE THE AGENDAS ITEMS
      actionsToAdd.forEach((actionToAdd) => {
        // CREATE AGENDA ITEM
        let newAgendaItem = GET_NEW_AGENDA_ITEM_OBJECT(
          "Action added automatically",
          "action",
          actionToAdd
        );

        // ADD ACTION AGENDA ITEM TO AGENDA
        destinationItem.agenda.push(newAgendaItem);
      });

      // STRINGIFY AGENDA
      destinationItem.agenda = JSON.stringify(destinationItem.agenda);

      // ADD UPDATED AGENDA TO FINAL MUTATION
      finalMutation.dispatcher.govReview.update.push(destinationItem);
      finalMutation.graphql.govReview.objects.push({
        id: destinationItem.id,
        agenda: destinationItem.agenda,
      });
    }
  });

  // FOR EACH ACTIONS, ADD THE EVENT
  actions = actions.map((action) => {
    // GET EVENTS FOR THIS ACTION
    let newActionEvents = eventLinksData.filter(
      (event) => event.actionId === action.id
    );

    // IF NO ITEM ADDED TO THIS ACTION, DON'T ADD EVENT
    if (newActionEvents.length === 0) {
      return action;
    }

    // REMOVE ACTION ID
    newActionEvents = newActionEvents.map((newEventData) => ({
      link: newEventData.link,
      text: newEventData.text,
    }));

    // CREATE THE NEW EVENT
    let newEvent = ItemEvent(currentUserId, "links", newActionEvents);

    // GET EXISTING ACTION EVENTS
    let actionEvents = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(action.events);

    // ADD THE GENERATION EVENT
    actionEvents.push(newEvent);

    action.events = JSON.stringify(actionEvents);

    return action;
  });

  if (!isRemoveActionMutation) {
    // ADD EACH ACTIONS TO FINAL MUTATION
    actions.forEach((action) => {
      finalMutation.dispatcher.action.update.push(action);
      finalMutation.graphql.action.objects.push({
        id: action.id,
        links: action.links,
        events: action.events,
      });
    });
  }

  // RUN MUTATION
  // SAVE ON GRAPHQL
  for (let queryName of Object.keys(finalMutation.graphql)) {
    multipleMutateGraphql(
      finalMutation.graphql[queryName].query,
      finalMutation.graphql[queryName].objects,
      appDispatcher
    );
  }

  // UPDATE APP STATE
  appDispatcher({
    type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
    object: finalMutation.dispatcher,
  });

  return actions;
}

// GIVVEN A LINK LIST, REMOVE A LINK FROM THE LIST
export function ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(
  links,
  destinationIdToRemove
) {
  let updatedLinks = _.cloneDeep(links);

  // PARSE ACTION LINKS
  updatedLinks = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(updatedLinks);

  // REMOVE LINK FROM THE ACTION
  updatedLinks = updatedLinks.filter(
    (link) => link.destinationItemId !== destinationIdToRemove
  );

  // STRINGIFY ACTION LINKS
  updatedLinks = JSON.stringify(updatedLinks);

  return updatedLinks;
}

export function ACTION_FUNC_REMOVE_LINKS_FROM_ACTION_LIST(
  links,
  destinationIdsToRemove
) {
  let updatedLinks = _.cloneDeep(links);

  // PARSE ACTION LINKS
  updatedLinks = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(updatedLinks);

  // REMOVE LINK FROM THE ACTION
  updatedLinks = updatedLinks.filter(
    (link) => !destinationIdsToRemove?.includes(link.destinationItemId)
  );

  // STRINGIFY ACTION LINKS
  updatedLinks = JSON.stringify(updatedLinks);

  return updatedLinks;
}

// REMOVE ONE LINK FROM AN ACTION AND THE DESTINATION ITEM
export function FUNC_ACTION_REMOVE_LINK(
  linkToRemove,
  actionParam,
  projectData,
  currentUserId,
  appDispatcher,
  actionsDispatcher
) {
  // INIT
  let finalMutation = _.cloneDeep(updateLinksMutation);
  let action = _.cloneDeep(actionParam);

  /////////////////////////
  // UPDATE ACTION LINKS //
  /////////////////////////

  // UPDATE ACTION LINKS LIST
  let updatedLinks = ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(
    action.links,
    linkToRemove.destinationItemId
  );

  // UPDATE ACTION
  action.links = updatedLinks;

  ///////////////////////////////////
  // UPDATE DESTINATION ITEM LINKS //
  ///////////////////////////////////

  // GET DESTINATION ITEM
  let destinationItem = FIND_OBJECT_ARRAY_ITEM(
    projectData[linkToRemove.type],
    "id",
    linkToRemove.destinationItemId
  );

  if (destinationItem) {
    // IF GOV REVIEW, REMOVE FROM AGENDA
    if (linkToRemove.type === "govReview") {
      // GET THE UPDATED AGENDA MUTATION
      let agendaMutation = GET_MUTATION_TO_DELETE_ITEM_FROM_REVIEW_AGENDA(
        action.id,
        linkToRemove.destinationItemId,
        projectData
      );

      if (agendaMutation) {
        // MERGE MUTATIONS
        finalMutation.dispatcher.govReview.update.push(
          agendaMutation.dispatcher.govReview.update[0]
        );
        finalMutation.graphql.govReview.objects.push(
          agendaMutation.graphql.govReview.objects[0]
        );
      }
    } else {
      // GET DESTINATION ACTIONS IDS
      let actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
        destinationItem.actionsIds
      );

      // UPDATE ARRAY
      actionsIds = FUNC_REMOVE_ELEMENT_FROM_ARRAY(actionsIds, action.id);

      // STRINGIFY
      destinationItem.actionsIds = JSON.stringify(actionsIds);

      ////////////////////////////////////
      // UPDATE DESTINATION ITEM EVENTS //
      ////////////////////////////////////

      // GET EVENTS
      let destinationItemEvents = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
        destinationItem.events
      );

      // CREATE NEW REMOVAL EVENT
      let newEvent = ItemEvent(currentUserId, "actionsIdsRemoval", [action.id]);

      // ADD IT TO DESTINATION ITEM
      destinationItemEvents.push(newEvent);
      destinationItem.events = JSON.stringify(destinationItemEvents);

      // ADD TO FINAL MUTATION
      finalMutation.dispatcher[linkToRemove.type].update.push(destinationItem);
      finalMutation.graphql[linkToRemove.type].objects.push({
        id: destinationItem.id,
        actionsIds: destinationItem.actionsIds,
        events: destinationItem.events,
      });
    }

    //////////////////////////
    // UPDATE ACTION EVENTS //
    //////////////////////////

    // ADD ACTION LINK DELETE EVENT
    let events = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(action.events);

    // ADD TEXT TO LINK
    if (linkToRemove.type === "govReview") {
      // IF GOV REVIEW, SPECIFIC LABEL
      let startDateStr = destinationItem.startDate?.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      linkToRemove.text = startDateStr + " : " + destinationItem.name;
    } else {
      linkToRemove.text =
        destinationItem.displayId +
        " : " +
        (destinationItem.action ||
          destinationItem.name ||
          destinationItem.title);
    }

    let newEvent = ItemEvent(currentUserId, "linksDeletion", [linkToRemove]);
    events.push(newEvent);

    action.events = JSON.stringify(events);
  }

  ////////////////////
  // FINAL MUTATION //
  ////////////////////

  // ADD ACTION TO FINAL MUTATION
  finalMutation.dispatcher.action.update.push(action);
  finalMutation.graphql.action.objects.push({
    id: action.id,
    links: action.links,
    events: action.events,
  });

  // RUN MUTATION
  for (let queryName of Object.keys(finalMutation.graphql)) {
    multipleMutateGraphql(
      finalMutation.graphql[queryName].query,
      finalMutation.graphql[queryName].objects,
      appDispatcher
    );
  }

  // UPDATE APP STATE
  appDispatcher({
    type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
    object: finalMutation.dispatcher,
  });

  // UPDATE ACTION VIEW
  if (actionsDispatcher) {
    actionsDispatcher({
      type: "SET_STATE_OBJECT",
      object: {
        actionView: action,
      },
    });
  }
}

// REMOVE MULTIPLE LINKS FROM AN ACTION AND THE DESTINATION ITEMS
export function FUNC_ACTION_REMOVE_MULTIPLE_LINKS(
  linksToRemove,
  actionItem,
  projectData,
  currentUserId,
  appDispatcher,
  actionsDispatcher
) {
  // INIT
  let finalMutation = _.cloneDeep(updateLinksMutation);
  let action = _.cloneDeep(actionItem);

  /////////////////////////
  // UPDATE ACTION LINKS //
  /////////////////////////

  // UPDATE ACTION LINKS LIST
  let updatedLinks = ACTION_FUNC_REMOVE_LINKS_FROM_ACTION_LIST(
    action.links,
    linksToRemove.map((link) => link.destinationItemId)
  );

  // UPDATE ACTION
  action.links = updatedLinks;

  // ADD ACTION LINK DELETE EVENT
  let events = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(action.events);

  for (const linkToRemove of linksToRemove) {
    ///////////////////////////////////
    // UPDATE DESTINATION ITEM LINKS //
    ///////////////////////////////////

    // GET DESTINATION ITEM
    let destinationItem = FIND_OBJECT_ARRAY_ITEM(
      projectData[linkToRemove.type],
      "id",
      linkToRemove.destinationItemId
    );

    if (destinationItem) {
      // IF GOV REVIEW, REMOVE FROM AGENDA
      if (linkToRemove.type === "govReview") {
        // GET THE UPDATED AGENDA MUTATION
        let agendaMutation = GET_MUTATION_TO_DELETE_ITEM_FROM_REVIEW_AGENDA(
          action.id,
          linkToRemove.destinationItemId,
          projectData
        );

        if (agendaMutation) {
          // MERGE MUTATIONS
          finalMutation.dispatcher.govReview.update.push(
            agendaMutation.dispatcher.govReview.update[0]
          );
          finalMutation.graphql.govReview.objects.push(
            agendaMutation.graphql.govReview.objects[0]
          );
        }
      } else {
        // GET DESTINATION ACTIONS IDS
        let actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
          destinationItem.actionsIds
        );

        // UPDATE ARRAY
        actionsIds = FUNC_REMOVE_ELEMENT_FROM_ARRAY(actionsIds, action.id);

        // STRINGIFY
        destinationItem.actionsIds = actionsIds;
        ////////////////////////////////////
        // UPDATE DESTINATION ITEM EVENTS //
        ////////////////////////////////////

        // GET EVENTS
        let destinationItemEvents = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
          destinationItem.events
        );

        // CREATE NEW REMOVAL EVENT
        let newEvent = ItemEvent(currentUserId, "actionsIdsRemoval", [
          action.id,
        ]);

        // ADD IT TO DESTINATION ITEM
        destinationItemEvents.push(newEvent);
        destinationItem.events = JSON.stringify(destinationItemEvents);

        // ADD TO FINAL MUTATION
        finalMutation.dispatcher[linkToRemove.type].update.push(
          destinationItem
        );
        finalMutation.graphql[linkToRemove.type].objects.push({
          id: destinationItem.id,
          actionsIds: destinationItem.actionsIds,
          events: destinationItem.events,
        });
      }

      //////////////////////////
      // UPDATE ACTION EVENTS //
      //////////////////////////

      // ADD ACTION LINK DELETE EVENT
      let events = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(action.events);

      // ADD TEXT TO LINK
      if (linkToRemove.type === "govReview") {
        // IF GOV REVIEW, SPECIFIC LABEL
        let startDateStr = destinationItem.startDate?.toLocaleString(
          undefined,
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }
        );
        linkToRemove.text = startDateStr + " : " + destinationItem.name;
      } else {
        linkToRemove.text =
          destinationItem.displayId +
          " : " +
          (destinationItem.action ||
            destinationItem.name ||
            destinationItem.title);
      }

      // PUSH NEW EVENT
      events.push(ItemEvent(currentUserId, "linksDeletion", [linkToRemove]));
    }
  }

  // UPDATE EVENTS
  action.events = JSON.stringify(events);

  // RUN MUTATION
  for (let queryName of Object.keys(finalMutation.graphql)) {
    multipleMutateGraphql(
      finalMutation.graphql[queryName].query,
      finalMutation.graphql[queryName].objects,
      appDispatcher
    );
  }

  // UPDATE APP STATE
  appDispatcher({
    type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
    object: finalMutation.dispatcher,
  });

  // UPDATE ACTION VIEW
  if (actionsDispatcher) {
    actionsDispatcher({
      type: "SET_STATE_OBJECT",
      object: {
        actionView: action,
      },
    });
  }
}

// GIVEN A LINK, GET DOMAIN ICON NAME
export function WBS_GET_DOMAIN_ICON(link, isRisk) {
  // CASE RISK OPP, CHECK IF IT'S A RISK OR OPP
  if (link.type === "riskOpp") {
    if (isRisk) {
      return WBS_DATA_ICON.risk;
    }

    return WBS_DATA_ICON.opp;
  }

  let icon = WBS_DATA_ICON[link.type];

  return icon;
}

// GIVEN AN ARRAY OF ACTIONS, ADD THE PROJECT NAME + THE BRANCH NAME THAT THEY BELONG TO
// ALSO ADD THE DISPLAY NAME - NAME OF THE CONNECTED R&O
export const FUNC_ACTION_PREPARE_DATA_EXPORT = (
  actions,
  // wbsList,
  projectData,
  projectName,
  organizationName,
  usersList
) => {
  let returnActions = [];

  // LOOP ACTIONS
  for (let action of actions) {
    let newAction = _.cloneDeep(action);
    // INIT NEW PROPERTIES
    // const wbsName = wbsList[findWbsIndex].name;
    let roName = "";
    let creatorName = "";
    let responsibleName = "";

    // CHECK RO CONNECTION
    if (action.telescopeDataROTelescopeDataActionId && projectData.riskOpp) {
      const findRoIndex = FUNCT_FIND_INDEX(
        projectData?.riskOpp,
        "id",
        action.telescopeDataROTelescopeDataActionId
      );

      if (findRoIndex > -1) {
        const ro = projectData.riskOpp[findRoIndex];
        roName = ro.displayId + " - " + ro.name;
      }
    }

    // FIND USER OF WHO CREATED IT
    const createdByIndex = FUNCT_FIND_INDEX(
      usersList,
      "value",
      action.createdBy
    );

    // ASSIGN CREATOR NAME
    if (createdByIndex > -1) {
      creatorName = usersList[createdByIndex].label;
    } else {
      continue;
    }

    // FIND RESPONSIBLE
    const responsibleIndex = FUNCT_FIND_INDEX(
      usersList,
      "value",
      action.responsible
    );

    if (responsibleIndex > -1) {
      responsibleName = usersList[responsibleIndex].label;
    } else {
      continue;
    }

    // DELETE IDS
    delete newAction.id;
    delete newAction.guid;
    delete newAction.projectId;
    delete newAction.wbsId;
    delete newAction.organizationId;
    delete newAction.contributors;
    delete newAction.createdBy;
    delete newAction.responsible;

    // DELETE UPDATE PROPERTIES
    delete newAction.guidUpdate;
    delete newAction.manualUpdate;

    // DELETE TYPES
    delete newAction.types;
    delete newAction.typeInstanceIds;

    // DELETE JSON
    delete newAction.comments;
    delete newAction.progression;
    delete newAction.events;

    // DELETE CONNECTION IDS
    delete newAction.telescopeDataGovernanceTelescopeDataActionId;
    delete newAction.telescopeDataScheduleTelescopeDataActionId;
    delete newAction.telescopeDataCostTelescopeDataActionId;
    delete newAction.telescopeDataROTelescopeDataActionId;

    // DELETE SECURITY
    delete newAction.groupEditors;
    delete newAction.groupViewers;
    delete newAction.isPrivate;

    // DELETE DEVELOPMENT PROPERTIES
    delete newAction.sort;

    newAction = {
      projectName: projectName,
      creatorName: creatorName,
      responsibleName: responsibleName,
      ...newAction,
      // RO NAME
      riskOppName: roName,
    };

    // DELETE ANY MORE NULL PROPERTIES
    for (let property of Object.keys(newAction)) {
      // MAKE NULL PROPERTIES TO BE EMPTY STRINGS
      if (!newAction[property]) newAction[property] = "";
      // IF DATE
      if (
        Object.prototype.toString.call(newAction[property]) === "[object Date]"
      ) {
        // TRANSFORM TO TXT
        newAction[property] = FUNC_DATE_TO_TXT_STANDARD(newAction[property]);
      }
    }

    // IF ORGANIZATION NAME IS AVAILABLE (NOT IN PORTFOLIO VIEW)
    if (organizationName) {
      newAction = {
        organizationName,
        ...newAction,
      };
    }

    // PUSH NEW ACTION
    returnActions.push(newAction);
  }

  // CALL JSON TO EXCEL FILE FUNCTION
  FUNC_CONVERT_JSON_TO_EXCEL(returnActions, `Actions - ${projectName}.xlsx`);
};

export function GET_LINK_DELETE_MUTATION_OBJECT() {
  // FINAL MUTATION
  return {
    graphql: {
      govReview: {
        query: updateTelescopeDataGovReview,
        objects: [],
      },
      govScopeChange: {
        query: updateTelescopeDataGovScopeChange,
        objects: [],
      },
      action: {
        query: deleteTelescopeDataAction,
        objects: [],
      },
      riskOpp: {
        query: updateTelescopeDataRO,
        objects: [],
      },
      schedule: {
        query: updateTelescopeDataSchedule,
        objects: [],
      },
    },
    dispatcher: {
      govReview: {
        update: [],
      },
      govScopeChange: {
        update: [],
      },
      action: {
        delete: [],
      },
      riskOpp: {
        update: [],
      },
      schedule: {
        update: [],
      },
    },
  };
}

// GET DELETE MUTATION TO REMOVE ONE ACTION LINK ID FROM DESTINATION ITEM
// DOES NOT WORK FOR GOV REVIEWS
export function GET_DELETE_LINK_DESTINATION_ITEM_MUTATION(
  action,
  link,
  projectData
) {
  // INIT FINAL MUTATION
  let finalMutation = GET_LINK_DELETE_MUTATION_OBJECT();

  // FIND ITEM IN PROJECT DATA
  let destinationItem = FIND_OBJECT_ARRAY_ITEM(
    projectData[link.type],
    "id",
    link.destinationItemId
  );

  // GET ACTIONS IDS
  let actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
    destinationItem.actionsIds
  );

  // REMOVE ACTION FROM DESTINATION ITEM
  actionsIds = FUNC_REMOVE_ELEMENT_FROM_ARRAY(actionsIds, action.id);

  // UPDATE DESTINATION ITEM
  destinationItem.actionsIds = actionsIds;

  // FILL MUTATION
  finalMutation.dispatcher[link.type].update.push({
    id: link.destinationItemId,
    actionsIds: actionsIds,
  });
  finalMutation.graphql[link.type].objects.push(destinationItem);

  // RETURN MUTATION
  return finalMutation;
}

// GET THE MUTATION TO DELETE SEVERAL ACTIONS AND THEIR LINKS
export function GET_DELETE_MULTIPLE_ACTIONS_MUTATION(
  actionsToDelete,
  projectData
) {
  // INIT
  let updatedItems = [];
  let linksToDelete = [];
  let finalMutation = GET_LINK_DELETE_MUTATION_OBJECT();
  let updatedAgenda = null;
  let foundItemResult;

  // FOR EACH ACTIONS, GET THEIR LINKS TO DELETE
  actionsToDelete.forEach((actionToDelete) => {
    // GET ITEMS IDS
    let actionLinks = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
      actionToDelete.links
    );

    // ADD SOURCE ACTION INFO
    actionLinks = actionLinks.map((link) => ({
      ...link,
      sourceActionId: actionToDelete.id,
    }));

    // ADD TO TOTAL LIST
    linksToDelete.push(...actionLinks);
  });

  // FOR EACH LINK TO DELETE, CREATE THE DELETE MUTATION
  linksToDelete.forEach((linkToDelete) => {
    if(linkToDelete && typeof linkToDelete === "object" && linkToDelete.destinationItemId) {
    
      // GET DESTINATION ITEM
      foundItemResult = FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND(
        updatedItems,
        projectData[linkToDelete.type],
        "id",
        linkToDelete.destinationItemId
      );

      // IF ITEM DOES NOT EXISTS, CONTINUE TO NEXT ONE
      if (!foundItemResult) {
        return;
      }

      // IF ITEM IS A GOV REVIEW, ...
      if (linkToDelete.type === "govReview") {
        // REMOVE CURRENT LINK FROM AGENDA
        updatedAgenda = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
          foundItemResult.data.agenda
        );
        updatedAgenda = updatedAgenda.filter(
          (agendaItem) => agendaItem.itemId !== linkToDelete.sourceActionId
        );

        // UPDATE AGENDA
        foundItemResult.data.agenda = JSON.stringify(updatedAgenda);
      }
      // ELSE UPDATE ACTIONS IDS LIST
      else {
        // GET ACTIONS IDS
        let actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
          foundItemResult.data.actionsIds
        );

        // REMOVE ACTION FROM DESTINATION ITEM
        actionsIds = FUNC_REMOVE_ELEMENT_FROM_ARRAY(
          actionsIds,
          linkToDelete.sourceActionId
        );

        // UPDATE DESTINATION ITEM
        foundItemResult.data.actionsIds = actionsIds;
      }

      // IF ITEM COME FROM UPDATED ITEMS, UPDATE IT
      if (foundItemResult.fromFirstArray) {
        updatedItems[foundItemResult.index] = foundItemResult.data;
      }
      // OTHERWISE, ADD ITEM TO UPDATED ITEMS
      else {
        updatedItems.push({
          ...foundItemResult.data,
          dataType: linkToDelete.type,
        });
      }
    }
  });

  // FOR EACH UPDATED ITEM, CREATE MUTATION
  updatedItems.forEach((updateditem) => {
    let dataType = updateditem.dataType;

    delete updateditem.dataType;

    // CREATE MUTATION FOR THIS ITEM
    finalMutation.dispatcher[dataType].update.push(updateditem);

    if (dataType === "govReview") {
      // IF GOV REVIEW, UPDATE AGENDA
      finalMutation.graphql[dataType].objects.push({
        id: updateditem.id,
        agenda: updateditem.agenda,
      });
    } else {
      // ELSE UPDATE ACTIONS IDS LIST
      finalMutation.graphql[dataType].objects.push({
        id: updateditem.id,
        actionsIds: updateditem.actionsIds,
      });
    }
  });

  // ADD EACH ACTIONS TO FINAL MUTATION
  let actionsIdsToDelete = actionsToDelete.map((action) => action.id);
  let actionsObjtoDelete = actionsToDelete.map((action) => ({ id: action.id }));

  finalMutation.dispatcher.action.delete.push(...actionsIdsToDelete);
  finalMutation.graphql.action.objects.push(...actionsObjtoDelete);

  return finalMutation;
}

export function ACTION_FUNC_DELETE_ACTIONS_AND_LINKS(
  actionView,
  selectedActions,
  projectData,
  appDispatcher
) {
  // GET SELECTED ACTIONS
  let selectedActionsToDelete;

  // IF ITEM VIEW NOT NULL DELETE CURRENT ACTION
  if (actionView) {
    selectedActionsToDelete = [actionView];
  }
  //IF NOT DELETE ALL SELECTED ACTIONS
  else {
    selectedActionsToDelete = selectedActions;
  }

  // GET FINAL MUTATION TO DELETE SELECTED ACTIONS AND ALL LINKS
  let finalMutation = GET_DELETE_MULTIPLE_ACTIONS_MUTATION(
    selectedActionsToDelete,
    projectData
  );

  // UPDATE GRAPHQL
  for (let queryName of Object.keys(finalMutation.graphql)) {
    multipleMutateGraphql(
      finalMutation.graphql[queryName].query,
      finalMutation.graphql[queryName].objects,
      appDispatcher
    );
  }

  //UPDATE APP STATE
  appDispatcher({
    type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
    object: finalMutation.dispatcher,
  });
}

// DUPLICATE SOME ACTIONS AND CREATE THEIR LINKS
// RETURNS THE UPDATED ACTIONS
export function FUNC_ACTION_DUPLICATE(
  actions,
  currentUser,
  biggestDisplayIdNum,
  securityGroup,
  projectData,
  appDispatcher
) {
  //INIT
  let finalMutation = {
    graphql: {
      action: {
        query: createTelescopeDataAction,
        objects: [],
      },
      govScopeChange: {
        query: updateTelescopeDataGovScopeChange,
        objects: [],
      },
      govReview: {
        query: updateTelescopeDataGovReview,
        objects: [],
      },
      riskOpp: {
        query: updateTelescopeDataRO,
        objects: [],
      },
      schedule: {
        query: updateTelescopeDataSchedule,
        objects: [],
      },
    },
    dispatcher: {
      action: {
        create: [],
      },
      govScopeChange: {
        update: [],
      },
      govReview: {
        update: [],
      },
      riskOpp: {
        update: [],
      },
      schedule: {
        update: [],
      },
    },
  };

  //INIT EVENTS
  let newEvent = [
    {
      user: {
        id: currentUser.username,
        label: currentUser.name,
      },
      createdOn: new Date(),
      value: "created by " + currentUser.name,
    },
  ];

  //INIT biggestDisplayIdNum
  let newDisplayId = biggestDisplayIdNum;

  // INIT LOOP
  let newAction = null;
  let newActions = [];
  let newLinks = null;
  let updatedItems = [];
  let destinationItem = null;

  //LOOP ON EACH ACTIONS TO DUPLICATE
  actions.forEach((actionToDuplicate) => {
    newAction = {
      ...actionToDuplicate,
      id: uuid(),
      displayId:
        ACTION_START_DISPLAY_ID + FUNC_ZERO_FORMAT_TO_NUM(newDisplayId + 1, 4),
      createdBy: currentUser.username,
      responsible: currentUser.username,
      createdOn: TODAY,
      events: JSON.stringify(newEvent),
      action: "(Duplicate) - " + actionToDuplicate.action,
      groupEditors: securityGroup.groupEditors,
      groupViewers: securityGroup.groupViewers,
    };

    delete newAction.sort;
    delete newAction.telescopeDataROTelescopeDataActionId;
    delete newAction.telescopeDataGovernanceTelescopeDataActionId;
    delete newAction.telescopeDataScheduleTelescopeDataActionId;
    delete newAction.telescopeDataCostTelescopeDataActionId;
    delete newAction.telescopeDataGovernance;
    delete newAction.telescopeDataRO;

    // PARSE ACTION LINKS
    actionToDuplicate.links = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
      actionToDuplicate.links
    );

    // INIT NEW LINKS
    newLinks = [];

    // FOR EACH LINKS, ADD A NEW ONE TO THE DESTINATION ITEM
    actionToDuplicate.links.forEach((newLink) => {
      // CREATE NEW LINK
      let link = FUNC_ACTION_CREATE_LINK_OBJECT(
        newLink.type,
        newLink.destinationItemId
      );

      // ADD IT TO NEW ACTION LINK LIST
      newLinks.push(link);

      // GET DESTINATION ITEM FROM UPDATED ITEMS OR PROJECT DATA
      destinationItem = FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND(
        updatedItems,
        projectData[newLink.type],
        "id",
        newLink.destinationItemId
      );

      // IF NOT FOUND GO TO NEXT ONE
      if (!destinationItem) {
        return;
      }

      // IF NOT A GOV REVIEW
      if (newLink.type !== "govReview") {
        // PARSE ITS ACTIONS LINKS
        destinationItem.data.actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
          destinationItem.data.actionsIds
        );

        // ADD NEW ID
        destinationItem.data.actionsIds.push(newAction.id);

        // INSURE IDS ARE UNIQUES
        destinationItem.data.actionsIds = _.uniq(
          destinationItem.data.actionsIds
        );
      }
      // IF GOV REVIEW, ADD TO AGENDA
      else {
        // PARSE AGENDA
        destinationItem.data.agenda = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
          destinationItem.data.agenda
        );

        // CREATE AGENDA ITEM
        let newAgendaItem = GET_NEW_AGENDA_ITEM_OBJECT(
          "Action added automatically",
          "action",
          newAction.id
        );

        // ADD ACTION AGENDA ITEM TO AGENDA
        destinationItem.data.agenda.push(newAgendaItem);

        // STRINGIFY AGENDA
        destinationItem.data.agenda = JSON.stringify(
          destinationItem.data.agenda
        );
      }

      // IF ITEM COME FROM UPDATED ITEMS, UPDATE IT
      if (destinationItem.fromFirstArray) {
        updatedItems[destinationItem.index] = destinationItem.data;
      }
      // OTHERWISE, ADD ITEM TO UPDATED ITEMS
      else {
        updatedItems.push({ ...destinationItem.data, dataType: newLink.type });
      }
    });

    // UPDATE LINKS IDS IN NEW ACTION
    newAction.links = JSON.stringify(newLinks);

    //INCREMENT DISPLAY ID
    newDisplayId += 1;

    // ADD THE DUPLICATED ACTION TO THE LIST
    newActions.push(newAction);
  });

  // PUT ACTIONS TO FINAL MUTATION
  finalMutation.dispatcher.action.create = newActions;
  finalMutation.graphql.action.objects = newActions;

  // PUT EACH UPDATED ITEMS IN IT'S DISPATCHER LIST
  updatedItems.forEach((updatedItem) => {
    let type = updatedItem.dataType;
    delete updatedItem.dataType;

    finalMutation.dispatcher[type].update.push(updatedItem);

    if (type === "govReview") {
      finalMutation.graphql[type].objects.push({
        id: updatedItem.id,
        agenda: updatedItem.agenda,
      });
    } else {
      finalMutation.graphql[type].objects.push({
        id: updatedItem.id,
        actionsIds: updatedItem.actionsIds,
      });
    }
  });

  // RUN MUTATION
  // SAVE ON GRAPHQL
  for (let queryName of Object.keys(finalMutation.graphql)) {
    multipleMutateGraphql(
      finalMutation.graphql[queryName].query,
      finalMutation.graphql[queryName].objects,
      appDispatcher
    );
  }

  // UPDATE APP STATE
  appDispatcher({
    type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
    object: finalMutation.dispatcher,
  });

  return finalMutation;
}

export const FUNC_COMPARE_LINKS = (originalAction, newAction) => {
  let newLinks = [];
  let removedLinks = [];

  let originalLinksMap = {};
  let newLinksMap = {};

  const parsedOldLinks = JSON.parse(originalAction?.links ?? "[]") ?? [];
  const parsedNewLinks = JSON.parse(newAction?.links ?? "[]") ?? [];

  // FILL MAP
  parsedOldLinks.forEach((link) => {
    originalLinksMap[link.destinationItemId] = link;
  });

  // FILL MAP
  parsedNewLinks.forEach((link) => {
    newLinksMap[link.destinationItemId] = link;
  });

  // LOOP THROUGH EACH LINK IN THE NEW ACTION
  // IF DOESN'T EXIST IN MAP -> ADD
  parsedNewLinks.forEach((link1) => {
    // IF IS NOT IN THE OLD ARRAY, BUT IS IN THIS ONE
    if (!originalLinksMap[link1.destinationItemId]) {
      newLinks.push(link1);
    }
  });

  // LOOP THROUGH EACH OLD LINK
  // IF DOESN'T EXIST IN NEW ACTION ->
  parsedOldLinks.forEach((link2) => {
    if (!newLinksMap[link2.destinationItemId]) {
      removedLinks.push(link2);
    }
  });

  return { newLinks, removedLinks };
};
