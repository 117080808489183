import React, {useCallback, useMemo, useState} from 'react'

//AUTH MINI COMPONENT
import {
    AuthAuthenticatorForm,
    AuthAuthenticatorTitle
} from './AuthAuthenticatorMiniComponents'

//CORE
import { FUNC_PASSWORD_CHECK } from '../../../../00-Core/Standards'
import { CvtInput } from '@mi-gso/cvt'

/////////////////////////////////////////////////////////////////////////////////////
// Authenticator DEFAULT SIGN IN ////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

export default function AuthAuthenticatorSignUp({
    options,
    onChange,
    handleSubmit,
    handleBackToSignIn,
    errorMessage
}){

    //STATE
    const [confirmPassword, setConfirmPassword] = useState("")

    //CHANGE CONFIRM PASSWORD
    const onConfirmPassword = useCallback((e) => {
        setConfirmPassword(e.target.value)
    },[])

    //PASSWORD CHECK
    const passWordCheck = useMemo(() => FUNC_PASSWORD_CHECK(options.password), [options.password])

    //RENDER
    return(
        <React.Fragment>

            {/* TITLE & ERROR */}
            <AuthAuthenticatorTitle 
                title="Join Our Clayverest Network"
                errorMessage={errorMessage}
                icon="account_circle"
            />

            <AuthAuthenticatorForm 
                disabled={options.firstName === "" || !passWordCheck.global || options.lastName === "" || options.password === "" || options.email === "" || (options.password !== confirmPassword)}
                loader={options.loader}
                text="Sign Up"
                handleBackToSignIn={handleBackToSignIn}
                onSubmit={handleSubmit}
                errorMessage={errorMessage}
            >

                {/* FIRST NAME */}
                <CvtInput 
                    legend="First Name *"
                    placeholder="Enter you First Name"
                    inputBlockCss="modalOptionSubBlock"
                    inputBlockPadding="15px 20px 0px 20px"
                    inputCss="form-control appAuthenticatorInput"
                    name="firstName"
                    maxLength={30}
                    value={options.firstName}
                    onChange={onChange}
                />

                {/* LAST NAME */}
                <CvtInput 
                    legend="Last Name *"
                    placeholder="Enter you Last Name"
                    inputBlockCss="modalOptionSubBlock"
                    inputBlockPadding="15px 20px 0px 20px"
                    inputCss="form-control appAuthenticatorInput"
                    name="lastName"
                    maxLength={30}
                    value={options.lastName}
                    onChange={onChange}
                />

                {/* Email */}
                <CvtInput 
                    legend="Email *"
                    placeholder="Enter your Email"
                    inputBlockCss="modalOptionSubBlock"
                    inputBlockPadding="10px 20px 10px 20px"
                    inputCss="form-control appAuthenticatorInput"
                    name="email"
                    value={options.email}
                    onChange={onChange}
                    autoComplete="email"
                />

                {/*  PASSWORD */}
                <CvtInput 
                    type="password"
                    legend="Password *"
                    inputBlockPadding="0px 20px 5px 20px"
                    placeholder="Enter your password"
                    inputBlockCss="modalOptionSubBlock"
                    inputCss="form-control appAuthenticatorInput"
                    name="password"
                    value={options.password}
                    onChange={onChange}
                    autoComplete="off"
                />

                {/* CONFIRM PASSWORD */}
                <CvtInput 
                    type="password"
                    legend="Confirm Password *"
                    inputBlockPadding="5px 20px 20px 20px"
                    placeholder="Confirm your password"
                    inputBlockCss="modalOptionSubBlock"
                    inputCss={"form-control appAuthenticatorInput " + (confirmPassword !== "" && confirmPassword !== options.password ? "appAuthenticatorWrongConfirmPassword" : null)}
                    name="password"
                    value={confirmPassword}
                    onChange={onConfirmPassword}
                    autoComplete="off"
                    isCheckOk={confirmPassword !== "" && options.password === confirmPassword}
                />

                {/* PASSWORD DETAILS */}
                <div 
                    className="appAuthenticatorPassword flexStartStart"
                    style={{backgroundColor:"white"}}
                >
                        
                    {/* ICON */}
                    <div 
                        className="material-icons appAuthenticatorPasswordIcon"
                        style={{color: passWordCheck.global ? "var(--color-migso-green)" : null}}
                    >
                        lock
                    </div>

                    {/* RULES */}
                    <div className="flexColStartStart">

                        {/* LENGTH */}
                        <div 
                            className="flexColStartStart"
                            style={{fontWeight:500, marginBottom:"5px"}}
                        >
                            Password rules
                            <div 
                                style={{
                                    fontWeight:400, 
                                    fontSize:"13px",
                                }}
                            >
                                <div style={{color: passWordCheck.number ? "var(--color-migso-green)" : null}}>At least 14 characters</div>
                                <div style={{color: passWordCheck.digit ? "var(--color-migso-green)" : null}}>Contains at least 1 number</div>
                                <div style={{color: passWordCheck.lower ? "var(--color-migso-green)" : null}}>Contains at least 1 uppercase letter</div>
                                <div style={{color: passWordCheck.upper ? "var(--color-migso-green)" : null}}>Contains at least 1 lowercase letter</div>
                                <div style={{color: passWordCheck.special ? "var(--color-migso-green)" : null}}>Contains at least 1 special character</div>
                            </div>
                        </div>
                    </div>
                </div>

            </AuthAuthenticatorForm>
            
        </React.Fragment>
    )
}