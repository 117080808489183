///////////////////////////////////////////////////////////////////////////
/// IMPORT ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

// REACT
import React, { useMemo, useState, memo, useCallback } from "react";

// LIBRARIES
import _ from "lodash";
import { v4 as uuid } from "uuid";

// SHORTCUT CONSTANTS
import {
  LOCKED_SHORTCUTS_STORAGE_KEY,
  SHORTCUT_BIG_DEFAULT_COLORS,
  SHORTCUT_GROUP_ITEMS,
  SHORTCUT_ITEM_DATA,
} from "./00-Helpers/ShortcutConstants";

// SHORTCUT COMPONENTS

// SHORTCUT FUNCTIONS
import { handleDropGetUpdateShortcuts } from "./00-Helpers/ShortcutFunctions";

// CORE FUNCTIONS
import { EDIT_SIDE_BAR_DATA_PREPARATION } from "../07-EditSideBar/00-Helpers/EditSideBarFunctions";

// COMPONENTS
import EditSideBar from "../07-EditSideBar/EditSideBar";
import ShortcutList from "./01-Components/ShortcutList";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../00-Wbs/00-Helpers/WbsConstants";
import { CvtButton, CvtTooltip } from "@mi-gso/cvt";
import {
  mutateGraphql,
  multipleMutateGraphql,
} from "../../00-App/02-Backend/AppBackendCommon";

// GRAPHQL QUERIES
import {
  deleteTelescopeDataShortcut as DeleteTelescopeDataShortcut,
  updateTelescopeDataShortcut as UpdateTelescopeDataShortcut,
  createTelescopeDataShortcut as CreateTelescopeDataShortcut,
} from "../../../graphql/mutations";
import { APP_WBS_STRUCTURE } from "../../../00-Core/Constants";
import materialIconsList from "./00-Helpers/materialIconsList";

///////////////////////////////////////////////////////////////////////
/// SHORTCUT BIG COMPONENT  ///////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

export default memo(function ShortcutBig({
  // SHORTCUTS
  shortcutData,
  // PROJECT ID
  projectId,
  // WBS ID
  wbsId,
  // APP DISPATCHER
  appDispatcher,
  // SELECTED ORGANIZATION ID
  selectedOrganizationId,
  // CURRENT USER
  currentUser,
  // DOMAISN
  wbsDomains,
  // SECURITY GROUP
  securityGroup,
  // WHETHER OR NOT ONLY SHORTCUTS BIG IS OPEN
  isOnlyShortcutOpen,
  // BIG COMPONENT OPEN
  bigComponentOpen,
  // ITEM LEVEL
  itemLevel,
  // SELECTED PROJECT ID
  selectedProjectId,
}) {
  /////////////////////////////////////////////////////
  /// STATE ///////////////////////////////////////////
  /////////////////////////////////////////////////////

  // AUTO-FILL CATEGORY FOR DEFAULT SHORTCUT IF ANOTHER BIG COMPONENT IS OPEN.
  const defaultCategory = useMemo(() => {
    if (bigComponentOpen) {
      let domain = wbsDomains.find((domain) => domain.id === bigComponentOpen);

      return domain ? domain.id : null;
    }
    return null;
  }, [bigComponentOpen, wbsDomains]);

  // DEFAULT SHORTCUT
  const defaultShortcut = useMemo(() => {
    return {
      // IDS
      id: null,
      projectId: projectId,
      wbsId: wbsId,
      organizationId: selectedOrganizationId,
      createdBy: currentUser.username,

      // GENERAL
      name: null,
      url: null,
      icon: "link",
      category: defaultCategory,
      // COLORS
      backgroundColor: SHORTCUT_BIG_DEFAULT_COLORS.backgroundColor,
      position: shortcutData.length,
    };
  }, [
    currentUser.username,
    defaultCategory,
    projectId,
    selectedOrganizationId,
    shortcutData.length,
    wbsId,
  ]);

  // DEFAULT STATE
  const defaultState = useMemo(() => {
    return {
      isShowEditSideBar: false,
      editSideBarContent: null,
      viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
      // SELECTED DOMAIN ID WHEN ADDING AND ONLY SHORTCUT BIG IS OPEN
      selectedDomainId: null,
      propKeyIncrement: 0,
    };
  }, []);

  // STATE
  const [state, setState] = useState(defaultState);

  // ///////////////////////////////////////////////////////////////////////////
  /// MEMO //////////// ////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  let sortedShortcuts = useMemo(
    () => shortcutData.sort((a, b) => a.position - b.position),
    [shortcutData]
  );

  // SORTED AND FILTERED BY CATEGORY (IF ANOTHER BIG COMPONENT IS OPEN)
  const filteredShortcutsData = useMemo(() => {
    // CREAT SORTED ARRAY OF SHORTCUTS BY CATEGORY
    let filtered = _.cloneDeep(sortedShortcuts).sort((a, b) => {
      // GET DOMAIN OF A
      let wbsDomainA = wbsDomains.find((domain) => domain.id === a.category);
      // GET DOMAIN OF B
      let wbsDomainB = wbsDomains.find((domain) => domain.id === b.category);
      // SORT BY SMALLEST TO LARGEST SORT IN DOMAIN AND BY SMALLEST TO LARGEST POSITION.

      if (!wbsDomainA || !wbsDomainB) return 0;
      return wbsDomainA.sort - wbsDomainB.sort;
    });

    // IF THERES ANY OTHER BIG COMPONENT OPEN, THEN FILTER THE SHORTCUTS ONLY BELONGING TO THAT CATEGORY
    if (bigComponentOpen) {
      return filtered.filter(
        (shortcut) => shortcut.category === bigComponentOpen
      );
    }

    // ELSE JUST RETURN ALL SHORTCUTS
    return filtered;
  }, [bigComponentOpen, sortedShortcuts, wbsDomains]);

  // PROCESS WBS DOMAINS TO FEED INTO EDIT SIDE BAR AND SHORTCUT LIST COMPONENT
  const wbsDomainsToMap = useMemo(() => {
    let cloneDomains = _.cloneDeep(wbsDomains);

    let oppDomainToAdd = {
      id: "opp",
      name: "Opportunity",
    };

    let indexRo = cloneDomains.findIndex((domain) => domain.id === "riskOpp");

    if (indexRo > -1) {
      cloneDomains.splice(indexRo + 1, 0, oppDomainToAdd);
    } else {
      cloneDomains.push(oppDomainToAdd);
    }

    return cloneDomains.filter(
      (domain) =>
        domain.id !== "owner" &&
        domain.id !== "deadline" &&
        domain.id !== "shortcut"
    );
  }, [wbsDomains]);

  // RETURN TRUE IF SHORTCUT BIG IS LOCKED, FALSE IF UNLOCKED
  const isShortcutBigLocked = useMemo(() => {
    let lockedShortcuts = localStorage.getItem(LOCKED_SHORTCUTS_STORAGE_KEY);

    // IF ANY BRANCHES IN LOCKED SHORTCUTS FOR THIS PROJECT
    if (lockedShortcuts && JSON.parse(lockedShortcuts)[selectedProjectId]) {
      // CHECK IF IT CONTAINS THIS WBS ID
      return (
        JSON.parse(lockedShortcuts)[selectedProjectId].find(
          (branchId) => branchId === wbsId
        ) !== undefined
      );
    }
    return false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId, wbsId, state.propKeyIncrement]);

  ////////////////////////////////////////////////////////////////////////////
  /// FUNCTIONS //////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  // HANDLE ON CREATE BTN CLICK OR EDIT IN SHORTCUT ITEM
  const onAddEditClick = useCallback(
    (shortcut, category) => {
      // WILL ONLY RUN IF EITHER
      // THE SHORTCUT IS NOT NULL AND ITS IN EDIT MODE
      //  OR IF THE SHORTCUT IS NULL AND VIEW MODE IS IN LIST MODE.

      // PREPARE DATA FOR EDIT SIDE BAR
      let newEditSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
        // DATA
        shortcut
          ? _.cloneDeep(shortcut)
          : {
              ...defaultShortcut,
              category: category ? category : defaultShortcut.category,
            },
        // STRUCTURE OF THE DATA
        SHORTCUT_ITEM_DATA,
        // IDS
        {
          id: shortcut ? shortcut.id : uuid(),
          organizationId: selectedOrganizationId,
          projectId: projectId,
          wbsId: wbsId,
        },
        // SPECIAL OPTIONS
        [
          // ICON
          {
            key: "icon",
            value: materialIconsList.map((iconValue) => ({
              label: iconValue,
              value: iconValue,
            })),
          },
          // DOMAINS
          {
            key: "category",
            value: wbsDomainsToMap,
          },
        ]
      );

      // SET NEW STATE ( UPDATE VIEW MODE, SHOW EDIT SIDE BAR, FEED EDIT SIDE BAR CONTENT )
      setState((prevState) => {
        return {
          ...prevState,
          viewMode: shortcut
            ? WBS_BIG_COMPONENT_VIEW_MODE.edit
            : WBS_BIG_COMPONENT_VIEW_MODE.add,
          isShowEditSideBar: true,
          editSideBarContent: newEditSideBarContent,
          selectedDomainId: category ? category : null,
        };
      });
    },
    [defaultShortcut, projectId, selectedOrganizationId, wbsDomainsToMap, wbsId]
  );

  // HANDLE SAVE OF CREATION/EDITING OF ITEM IN EDIT SIDE BAR
  const onSaveAddEdit = useCallback((e, mutateObject) => {

      // INIT
      let isAdding = state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add;
      let graphQLQuery;
      let dispatcherQuery;
      let updatedObject = {};

      // IF IS ADDING
      if (isAdding === true) {
        graphQLQuery = CreateTelescopeDataShortcut;
        dispatcherQuery = "SET_PROJECT_DATA_CREATE";
        updatedObject = {
          ..._.cloneDeep(defaultShortcut),
          ...mutateObject,
          // BACKGROUND COLOR
          backgroundColor:
            mutateObject.backgroundColor === "" || !mutateObject.backgroundColor
              ? defaultShortcut.backgroundColor
              : mutateObject.backgroundColor,
        };

        if (!updatedObject.category) {
          updatedObject.category = defaultCategory
            ? defaultCategory
            : state.selectedDomainId
            ? state.selectedDomainId
            : null;
        }

      // ELSE IF EDITING, THEN FIND SHORTCUT BEING EDITED IN THE SHORTCUT DATA PASSED FROM PROPS AND
      // REPLACE THE EDITED DATA
      }else {
        graphQLQuery = UpdateTelescopeDataShortcut;
        dispatcherQuery = "SET_PROJECT_DATA_UPDATE";
        updatedObject = mutateObject;
      }

      // DELETE CREATED AT AND UPDATED AT FOR SAVING IN GRAPHQL
      delete updatedObject.createdAt;
      delete updatedObject.updatedAt;

      // UPDATE BACKEND
      mutateGraphql(
        graphQLQuery,
        {
          ...updatedObject,
          groupEditors: securityGroup.groupEditors,
          groupViewers: securityGroup.groupViewers,
        },
        appDispatcher
      );

      // UPDATE OR CREATE APP STATE
      appDispatcher({
        type: dispatcherQuery,
        source: "shortcut",
        objects: [updatedObject],
      });

      // SET NEW STATE ( HIDE EDIT SIDE BAR, MAKE EDIT SIDE BAR CONTENT NULL, CHANGE VIEW MODE  )
      setState((prevState) => {
        return {
          ...prevState,
          editSideBarContent: null,
          isShowEditSideBar: false,
          viewMode:
            state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit
              ? WBS_BIG_COMPONENT_VIEW_MODE.edit
              : WBS_BIG_COMPONENT_VIEW_MODE.list,
          selectedDomainId: null,
          propKeyIncrement: prevState.propKeyIncrement + 1,
        };
      });
    },[appDispatcher,defaultCategory,defaultShortcut,securityGroup.groupEditors,securityGroup.groupViewers,state.selectedDomainId,state.viewMode,]);

  // ON CANCEL OF CREATING/EDITING ON EDIT SIDE BAR
  const onResetEditSideBar = useCallback(() => {
    // SET NEW STATE (CHANGE VIEW MODE, HIDE EDIT SIDE BAR, MAKE EDIT BAR CONTENT NULL)
    setState((prevState) => {
      return {
        ...prevState,
        viewMode:
          state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit
            ? WBS_BIG_COMPONENT_VIEW_MODE.edit
            : WBS_BIG_COMPONENT_VIEW_MODE.list,
        editSideBarContent: null,
        isShowEditSideBar: false,
        propKeyIncrement: prevState.propKeyIncrement + 1,
      };
    });
  }, [state.viewMode]);

  // ON DELETE CLICKED
  const onDeleteClicked = useCallback((id) => {

      // UPDATE BACKEND
      mutateGraphql(
        DeleteTelescopeDataShortcut,
        {
          id: id
        },
        appDispatcher
      );

      // UPDATE OR CREATE APP STATE
      appDispatcher({
        type: "SET_PROJECT_DATA_DELETE",
        source: "shortcut",
        ids: [id],
      });

      // RESET EDIT SIDE BAR IF SHORTCUT WAS DELETED FROM EDIT SIDE BAR
      if (state.isShowEditSideBar === true) {
        onResetEditSideBar();
      }
    },[appDispatcher, onResetEditSideBar, state.isShowEditSideBar]);

  // HANDLE DROP WHEN DRAGGING
  const handleDrop = useCallback(
    (droppedShortcut) => {
      // CHECK IF DID NOT DROP OUTSIDE CONTAINER
      if (droppedShortcut.destination !== null) {
        // INIT SHORTCUTS TO UPDATE IN BACK-END
        let shortcutsToUpdate;

        // IF ONLY SHORTCUT BIG WAS OPEN AND SOURCE CATEGORY CONTAINER IS NOT SAME AS
        // DESTINATION CATEGORY CONTAINER
        if (isOnlyShortcutOpen === true) {
          // SPLIT STRING
          let droppedCategoryArray =
            droppedShortcut.destination.droppableId.split("_");
          // GET CATEGORY
          let droppedCategory =
            droppedCategoryArray[droppedCategoryArray.length - 1];

          // FILTERED FOR THAT CATEGORY
          let categoryDroppedFiltered = shortcutData.filter(
            (shortcut) => shortcut.category === droppedCategory
          );

          // IN DIFF CATEGORY TABLE
          if (
            droppedShortcut.source.droppableId !==
            droppedShortcut.destination.droppableId
          ) {
            // GET DRAGGED ITEM
            let shortcutDragged = sortedShortcuts.find(
              (shortcut) => shortcut.id === droppedShortcut.draggableId
            );

            // GET DROPPED AT ITEM
            let droppedAtItem =
              categoryDroppedFiltered[droppedShortcut.destination.index];

            // IF DRAGGED ITEM POSITION IS JUST DROPPED AT ITEM POSITION - 1, THEN ONLY NEED TO UPDATE THE CATEGORY
            if (
              (droppedAtItem &&
                shortcutDragged.position + 1 === droppedAtItem.position) ||
              categoryDroppedFiltered.length === 0
            ) {
              // ONLY UPDATE THE ONE DRAGGED (CHANGE CATEGORY ONLY)
              shortcutsToUpdate = [
                {
                  ...shortcutDragged,
                  category: droppedCategory,
                },
              ];
            }
            // ELSE TREAT NORMALLY
            else {
              // INIT DESTINATION POSITION
              let destinationPosition;

              // IF DID NOT DROP AT END OF CATEGORY TABLE
              if (droppedAtItem !== null && droppedAtItem !== undefined) {
                // IF THE POSITION FROM WHERE IT WAS DRAGGED IS BIGGER THAN THE POSITION AT WHERE IT WAS DROPPED
                if (shortcutDragged.position > droppedAtItem.position) {
                  destinationPosition = droppedAtItem.position;
                }
                // ELSE NEEDS TO BE POSITION DROPPED - 1
                else {
                  destinationPosition = droppedAtItem.position - 1;
                }
              }
              // IF DROPPED AT END OF CATEGORY TABLE, THEN POSITION IS THE LAST ITEM AT THE TABLE'S POSITION + 1
              else {
                destinationPosition =
                  categoryDroppedFiltered[categoryDroppedFiltered.length - 1]
                    .position + 1;
              }

              // GET ARRAY OF SHORTCUTS THAT NEED TO BE UPDATED
              shortcutsToUpdate = handleDropGetUpdateShortcuts(
                sortedShortcuts,
                shortcutDragged.position,
                destinationPosition,
                droppedCategory
              );
            }
          }

          // IN SAME CATEGORY TABLE
          else {
            // GET DROPPED AT ITEM
            let droppedAtItem =
              categoryDroppedFiltered[droppedShortcut.destination.index];

            // GET ARRAY OF SHORTCUTS THAT NEED TO BE UPDATED
            shortcutsToUpdate = handleDropGetUpdateShortcuts(
              sortedShortcuts,
              categoryDroppedFiltered[droppedShortcut.source.index].position,
              droppedAtItem
                ? droppedAtItem.position
                : categoryDroppedFiltered[categoryDroppedFiltered.length - 1]
                    .position + 1
            );
          }
        } else {
          // GET ARRAY OF SHORTCUTS THAT NEED TO BE UPDATED
          shortcutsToUpdate = handleDropGetUpdateShortcuts(
            sortedShortcuts,
            filteredShortcutsData[droppedShortcut.source.index].position,
            filteredShortcutsData[droppedShortcut.destination.index].position
          );
        }

        // FOR EACH OF THE SHORTCUTS TO UPDATE, DELETE THE CREATED AT AND UPDATED AT PROPERTIES.
        for (let i = 0, until = shortcutsToUpdate.length; i < until; i++) {
          delete shortcutsToUpdate[i].createdAt;
          delete shortcutsToUpdate[i].updatedAt;
        }

        // UPDATE APP STATE
        appDispatcher({
          type: "SET_PROJECT_DATA_UPDATE",
          source: "shortcut",
          objects: shortcutsToUpdate,
          // NO TOAST
          noToast: true,
        });

        // UPDATE BACK-END
        multipleMutateGraphql(
          // QUERY
          UpdateTelescopeDataShortcut,
          // OBJECTS
          shortcutsToUpdate,
          // APP DISPATCHER
          appDispatcher
        );
      }
    },
    [
      isOnlyShortcutOpen,
      appDispatcher,
      sortedShortcuts,
      shortcutData,
      filteredShortcutsData,
    ]
  );

  // HANDLE START/END EDIT MODE
  const handleStartEndEdit = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        viewMode:
          prevState.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit
            ? WBS_BIG_COMPONENT_VIEW_MODE.list
            : WBS_BIG_COMPONENT_VIEW_MODE.edit,
      };
    });
  }, []);

  // HANDLE LOCK/UNLOCK SHORTCUTS BIG FROM BRANCH
  const handleLockUnlockShortcutBig = useCallback(() => {
    let lockedShortcuts = localStorage.getItem(LOCKED_SHORTCUTS_STORAGE_KEY);

    // IF WAS LOCKED, THEN REMOVE FROM LOCALSTORAGE
    if (isShortcutBigLocked === true) {
      // FROM JSON STRING TO OBJECT
      lockedShortcuts = JSON.parse(lockedShortcuts);

      // REMOVE WBS ID FROM THE ARRAY AND THEN TRANSFORM BACK TO JSON STRING TO SAVE IN LOCAL STORAGE.
      lockedShortcuts = {
        ...lockedShortcuts,
        [selectedProjectId]: lockedShortcuts[selectedProjectId].filter(
          (branchId) => branchId !== wbsId
        ),
      };
    }
    // IF WAS UNLOCKED
    else {
      //  IF LOCKED SHORTCUTS EXISTS IN LOCAL STORAGE,
      if (lockedShortcuts) {
        // FROM JSON STRING TO OBJECT
        lockedShortcuts = JSON.parse(lockedShortcuts);

        // IF THIS PROJECT EXISTS IN LOCKED SHORTCUTS, THEN ADD IT
        if (lockedShortcuts[selectedProjectId]) {
          lockedShortcuts = {
            ...lockedShortcuts,
            [selectedProjectId]: [...lockedShortcuts[selectedProjectId], wbsId],
          };
        }
        // ELSE CREATE ENTRY FOR THIS PROJECT AND [wbsId] AS THE VALUE
        else {
          lockedShortcuts = {
            ...lockedShortcuts,
            [selectedProjectId]: [wbsId],
          };
        }
      }
      //  ELSE SET NEW LOCKED SHORTCUTS, WITH NEW ENTRY FOR THIS PROJECT ID AND VALUE AS [wbsId].
      else {
        lockedShortcuts = {
          [selectedProjectId]: [wbsId],
        };
      }
    }

    // SET ITEM IN LOCAL STORAGE.
    localStorage.setItem(
      LOCKED_SHORTCUTS_STORAGE_KEY,
      JSON.stringify(lockedShortcuts)
    );

    // UPDATE COMPONENT
    setState((prevState) => {
      return {
        ...prevState,
        propKeyIncrement: prevState.propKeyIncrement + 1,
      };
    });
  }, [isShortcutBigLocked, selectedProjectId, wbsId]);

  ///////////////////////////////////////////////////////////////////////////
  /// COMPONENT RENDER ////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  return (
    <React.Fragment>

      {/* SHORTCUT BIG */}
      <div
        className={"flexColStartStart" + (!isOnlyShortcutOpen ?  " shortcutBigContainer" : "")}
        style={{
          width: isOnlyShortcutOpen === true ? "100%" : `${APP_WBS_STRUCTURE.shortcutWidth}px`,
          height: isOnlyShortcutOpen === true ? "fit-content" : "",
          marginRight: isOnlyShortcutOpen === false ? "0px" : "",
        }}
      >

        {/* SHORTCUT HEADER */}
        <div
          className="flexBetweenCenter"
          style={{
            width: isOnlyShortcutOpen === true ? `${APP_WBS_STRUCTURE.shortcutWidth}px` : "100%",
            position: 'sticky',
            left: 25,
          }}
        >
          {/* TITLE + COUNT */}
          <div className="flexCenterCenter">
            {/* SHORTCUT ICON */}
            <span className="shortcutBigHeaderTitle">Shortcuts</span>

            {/* TOTAL SHORTCUT COUNT */}
            {shortcutData.length > 0 && !bigComponentOpen ? (
              <div
                className="shortcutBigCount flexCenterCenter"
                id={`shortcut_total_count_${wbsId}`}
              >
                {shortcutData.length}
                <CvtTooltip
                  anchorId={`shortcut_total_count_${wbsId}`}
                  content="Total count"
                />
              </div>
            ) : null}

            {/* FILTERED SHORTCUT COUNT */}
            {filteredShortcutsData.length > 0 && bigComponentOpen ? (
              <div
                className="shortcutBigCount flexCenterCenter"
                style={{
                  backgroundColor: "var(--color-migso-blue)",
                  color: "white",
                }}
                id={`shortcut_sorted_count_${wbsId}`}
              >
                {filteredShortcutsData.length}

                <CvtTooltip
                  anchorId={`shortcut_sorted_count_${wbsId}`}
                  content={`${
                    bigComponentOpen
                  } shortcuts count`}
                />
              </div>
            ) : null}
          </div>

          {/* ADD + EDIT BTN */}
          <div className="flexCenterCenter">
            {/* LOCK BTN */}
            <CvtButton
              icon={isShortcutBigLocked === true ? "lock" : "lock_open"}
              color="gray"
              iconColor={ isShortcutBigLocked ? "var(--color-migso-yeallow)" : null}
              shape="noShape"
              customHover="yellow"
              handleSubmit={handleLockUnlockShortcutBig}
              tooltip
              tooltipTxt={`${
                isShortcutBigLocked ? "Unlock" : "Lock"
              } shortcut big`}
              tooltipPosition="right"
              tooltipTheme="dark"
              key={`shortcut_big_lock_icon_${wbsId}_${isShortcutBigLocked}_${state.propKeyIncrement}`}
            />

            {/* ADD BUTTON */}
            {securityGroup.isCurrentUserEditor &&
            filteredShortcutsData.length > 0 ? (
              <CvtButton
                color="white"
                shape="noShape"
                icon="add"
                handleSubmit={() => onAddEditClick(null)}
                tooltip
                tooltipTxt="Create a shortcut"
                tooltipPosition="right"
                tooltipTheme="dark"
              />
            ) : null}

            {/* EDIT BUTTON */}
            {filteredShortcutsData.length > 0 &&
            securityGroup.isCurrentUserEditor ? (
              <CvtButton
                color={state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ? "blue" : "white"}
                shape={state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ? "square" : "noShape"}
                size={state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ? "small" : "regular"}
                icon="edit"
                handleSubmit={handleStartEndEdit}
                tooltip
                tooltipTxt="Edit a shortcut"
                tooltipPosition="right"
                tooltipTheme="dark"
              />
            ) : null}
          </div>
        </div>

        {/* SHORTCUT LIST */}
        {filteredShortcutsData.length > 0 || isOnlyShortcutOpen ? (
          <ShortcutList
            shortcutData={filteredShortcutsData}
            onAddEditClick={onAddEditClick}
            onDelete={onDeleteClicked}
            handleDrop={handleDrop}
            viewMode={state.viewMode}
            key={`shortcut_big_list_${state.propKeyIncrement}`}
            isOnlyShortcutOpen={isOnlyShortcutOpen}
            bigComponentOpen={bigComponentOpen}
            wbsDomains={wbsDomainsToMap}
            itemLevel={itemLevel}
            securityGroup={securityGroup}
          />
        ) : (
          <CvtButton
            color={securityGroup.isCurrentUserEditor ? "green" : "red"}
            icon={securityGroup.isCurrentUserEditor ? "add" : "block"}
            handleSubmit={() =>
              securityGroup.isCurrentUserEditor ? onAddEditClick() : null
            }
            txt={securityGroup.isCurrentUserEditor ? "Create a shortcut" : ""}
            tooltip={!securityGroup.isCurrentUserEditor}
            tooltipTxt={
              !securityGroup.isCurrentUserEditor
                ? "You don't have permissions to create a shortcut"
                : ""
            }
            tooltipPosition="top"
            tooltipTheme="dark"
          />
        )}
      </div>

      {/* EDIT SIDE BAR */}
      <EditSideBar
        display={state.isShowEditSideBar}
        content={state.editSideBarContent}
        title={`${
          state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add
            ? "Create a"
            : "Edit"
        } Shortcut`}
        group={SHORTCUT_GROUP_ITEMS}
        onSave={onSaveAddEdit}
        resetEditSideBar={onResetEditSideBar}
        currentUser={currentUser}
        onDelete={onDeleteClicked}
        viewMode={state.viewMode}
        firstAddUpdatedFields={["backgroundColor", "icon"]}
      />
    </React.Fragment>
  );
});
