import { TODAY_PLUS_30D } from "../../../../00-Core/Constants";

// SWITCH VIEW IN GOV SCOPE DETAILS
export const GOV_SCOPE_DETAILS_KEY_BLOCK = [
    { key: "comments", name: "Comments" },
    { key: "connections", name: "Connections" },
    { key: "events", name: "Events" },
];

// BEGINNING STR OF GOV SCOPE CHANGE DISPLAY ID
export const GOV_SCOPE_DISPLAY_ID_START = "SC";

export const GOV_SCOPE_LEVEL_KEYS = {
    branch: "branch",
    project: "project",
    portfolio: "portfolio",
};

// GOV SCOPE CHANGES LEVELS
export const GOV_SCOPE_LEVELS = [
    {
        id: "branch",
        label: "Branch",
        backgroundColor: "var(--color-migso-yeallow)",
        small: "B",
    },
    {
        id: "project",
        label: "Project",
        backgroundColor: "var(--color-alert)",
        small: "Pr",
    },
    {
        id: "portfolio",
        label: "Portfolio",
        backgroundColor: "var(--color-bad)",
        small: "Po",
    },
];

// GO SCOPE STATUS KEYS
export const GOV_SCOPE_STATUS_KEY = {
    onHold: "onHold",
    pending: "pending",
    closed: "closed",
};

// GOV SCOPE CHANGES STATUS
export const GOV_SCOPE_STATUS = [
    {
        id: "onHold",
        value: "onHold",
        label: "On Hold",
        name: "On Hold",
        backgroundColor: "var(--color-migso-yeallow)",
    },
    {
        id: "pending",
        value: "pending",
        label: "Pending",
        name: "Pending",
        backgroundColor: "var(--color-migso-blue-hover-border)",
    },
    {
        id: "closed",
        value: "closed",
        label: "Closed",
        name: "Closed",
        backgroundColor: "var(--color-migso-green)",
    },
];

export const GOV_SCOPE_DECISIONS_KEYS = {
    approved: "approved",
    pending: "pending",
    refused: "refused",
};

// GOV SCOPE CHANGES DECISIONS
export const GOV_SCOPE_DECISIONS = [
    {
        id: "approved",
        label: "Approved",
        value: "approved",
        name: "Approved",
        backgroundColor: "var(--color-migso-yeallow)",
    },
    {
        id: "pending",
        label: "Pending",
        value: "pending",
        name: "Pending",
        backgroundColor: "var(--color-migso-yeallow)",
    },
    {
        id: "refused",
        label: "Refused",
        value: "refused",
        name: "Refused",
        backgroundColor: "var(--color-migso-yeallow)",
    },
];

// STRUCTURE OF GOV SCOPE CHANGE EDIT SIDE BAR
export const GOV_SCOPE_EDIT_SIDEBAR_DATA = [
    // ITEM IDS
    { groupId: -1, key: "id", value: null, type: "id" },
    { groupId: -1, key: "organizationId", value: "", type: "id" },
    { groupId: -1, key: "projectId", value: "", type: "id" },
    { groupId: -1, key: "wbsId", value: "", type: "id" },
    { groupId: -1, key: "displayId", value: "", type: "id" },
    { groupId: -1, key: "govItemType", value: "scope", type: "id" },

    {
        groupId: 0,
        key: "title",
        value: "",
        label: "Title",
        component: "input",
        maxLength: 80,
        type: "text",
        info: "Scope change title",
        placeholder: "Scope change title",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "level",
        value: "",
        label: "Level",
        component: "select",
        type: "list",
        options: [],
        info: "To which structure element this Scope change is applied",
        placeholder: "",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "responsible",
        value: "",
        label: "Responsible",
        component: "select",
        type: "list",
        options: [],
        info: "",
        placeholder: "Who's the responsible",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "status",
        value: "",
        label: "Status",
        component: "select",
        type: "list",
        options: [],
        info: "Status of the Scope Change",
        placeholder: "",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "dueDate",
        value: TODAY_PLUS_30D,
        label: "Due date",
        component: "input",
        type: "date",
        info: "Date for the Deadline",
        placeholder: "",
        mandatory: true,
    },
    {
        groupId: 0,
        key: "govReviewIds",
        value: "",
        label: "Instances to review this item",
        component: "multi_select",
        type: "list",
        options: [],
        info: "Choose the instances when the item will be reviewed",
        placeholder: "",
        mandatory: false,
    },

    {
        groupId: 0,
        key: "description",
        value: "",
        label: "Description",
        component: "textArea",
        type: "richTextArea",
        info: "Describe the reason of this Scope Change",
        placeholder: "Enter the Scope change description",
        mandatory: false,
    },
    {
        groupId: 0,
        key: "choices",
        value: "",
        label: "Choices",
        component: "textArea",
        type: "richTextArea",
        info: "Choices made according to the Scope Change",
        placeholder: "Scope change description",
        mandatory: false,
    },
    {
        groupId: 0,
        key: "decision",
        value: "",
        label: "Decision",
        component: "select",
        type: "list",
        options: GOV_SCOPE_DECISIONS,
        info: "Decision taken by the team",
        placeholder: "",
        mandatory: false,
    },
    {
        groupId: 0,
        key: "decisionDetails",
        value: "",
        label: "Decision details",
        component: "textArea",
        type: "richTextArea",
        info: "Details of the decision made",
        placeholder: "Details of the decision made",
        mandatory: false,
    },
];


export const GOV_SCOPE_CARD_KEY_BLOCK = [
    { key: "description", name: "Description" },
    { key: "decision", name: "Decision" },
]