import React, {useCallback, useState, useMemo} from 'react'
import {isValidPhoneNumber} from 'react-phone-number-input'
import {Auth} from 'aws-amplify';
import { CvtSpinnerGrow } from "@mi-gso/cvt"

//AUTH FUNCTION
import {
    signUp,
    confirmSignUp,
    resendConfirmationCode
} from '../00-Functions/AuthSignUp'
import {
    signIn,
    confirmSignIn,
    verifyTotpToken,
    verifyPhoneNumberMfa,
    confirmSignInWithoutMfa
} from '../00-Functions/AuthSignIn'
import {
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
} from '../00-Functions/AuthPassword'
import {
    verifyCurrentUserAttribute,
    verifyCurrentUserEmailSubmit,
    updateUserPhoneNumberAndSendCode
} from '../00-Functions/AuthAttributes'

//AUTH AUTHENTICATOR COMPONENT
import AuthAuthenticatorSignUp from '../01-Authenticator/AuthAuthenticatorSignUp'
import AuthAuthenticatorConfirmSignUp from '../01-Authenticator/AuthAuthenticatorConfirmSignUp'
import AuthAuthenticatorSignIn from '../01-Authenticator/AuthAuthenticatorSignIn'
import AuthAuthenticatorEmailVerification from '../01-Authenticator/AuthAuthenticatorEmailVerification'
import AuthAuthenticatorFirstConnection from '../01-Authenticator/AuthAuthenticatorFirstConnection'
import AuthAuthenticatorNeedValidateTerms from '../01-Authenticator/AuthAuthenticatorNeedValidateTerms'
import AuthAuthenticatorResetPassword from '../01-Authenticator/AuthAuthenticatorResetPassword'
import AuthAuthenticatorMfaSetUp from '../01-Authenticator/AuthAuthenticatorMfaSetUp'
import AuthAuthenticatorMfaVerification from '../01-Authenticator/AuthAuthenticatorMfaVerification'
import { authCleanUserDataCookie } from '../00-Functions/AuthSignOut';
import { envVarManagement } from '../../../../envVarManagement';

//INIT STATE
const initialState = {
    username:"",
    password:"",
    loader:false,
    codeSent: false,
    codeLoader:false,
    code:"",
    newPassword:"",
    email:"",
    phoneNumber:"",
    phoneNumberValid:false,
    preferredMFA:"",
    qrCodeLoading: false,
    qrCode:"",
    firstName:"",
    lastName:""
}

//Authenticator DEFAULT
export function AuthAuthenticator({
    currentUser,
    appDispatcher
}){

    /////////////
    /// STATE ///
    /////////////

    //STATE --------------------------------------------------------------------------------
    const [state, setState] = useState(initialState)

    //ENV VAR ------------------------------------------------------------------------------
    const envTermsVersion = useMemo(() => envVarManagement("envTermsVersion"),[])

    //INPUT CHANGE --------------------------------------------------------------------------
    const onChange = useCallback((e) =>{
        setState(prevState => {
            return({
                ...prevState,
                [e.target.name] : e.target.value
            })
        })
    },[])

    //CHANGE PREFERRED MFA METHOD ----------------------------------------------------------
    const onChangePreferredMFA = useCallback(async (e, value) =>{

        //IF TOTP GENERATE CODE
        if(value === "TOTP" && state.preferredMFA !== "TOTP"){

            if(state.qrCode === ""){

                //SET MFA
                setState(prevState => {
                    return({
                        ...prevState,
                        qrCodeLoading: true,
                        preferredMFA : "TOTP"
                    })
                })

                //GET UPDATED USER
                await Auth.currentAuthenticatedUser({bypassCache: true}).then(async (updatedUser) =>{

                    //GET QRCODE
                    await Auth.setupTOTP(updatedUser).then((code) => {

                        //GET THE QR CODE
                        setState(prevState => {
                            return({
                                ...prevState,
                                qrCodeLoading: false,
                                preferredMFA : "TOTP",
                                qrCode: code
                            })
                        })
                    });

                    //DEL USER DATA COOKIE
                    authCleanUserDataCookie()
                });

            //ONLY SWITCH
            }else{
                setState(prevState => {
                    return({
                        ...prevState,
                        preferredMFA : "TOTP"
                    })
                })
            }
        }

        //IF TOTP GENERATE CODE
        if(value === "SMS" && state.preferredMFA !== "SMS"){

            //SET MFA
            setState(prevState => {
                return({
                    ...prevState,
                    preferredMFA : "SMS"
                })
            })
        }

        //IF TOTP GENERATE CODE
        if(value === "NOMFA" && state.preferredMFA !== "" && state.preferredMFA !== "NOMFA"){

            //SET MFA
            setState(prevState => {
                return({
                    ...prevState,
                    preferredMFA : ""
                })
            })
        }
    },[state])

    //PHONE NUMBER CHANGE ------------------------------------------------------------------
    const onChangePhoneNumber = useCallback((e) =>{
        setState(prevState => {
            return({
                ...prevState,
                phoneNumber:e,
                phoneNumberValid:e  && isValidPhoneNumber(e ) ? true : false
            })
        })
    },[])

    //REQUEST ACCOUNT PASSWORD --------------------------------------------------------------
    const handleRequestAccount = useCallback((e)=>{
        e.preventDefault();
        console.log("to be define")
    },[])

    ///////////////
    /// SIGN UP ///
    ///////////////

    //REQUEST ACCOUNT PASSWORD --------------------------------------------------------------
    const handleSignUp = useCallback((e)=>{
        e.preventDefault();

        appDispatcher({
            type:"SET_GLOBAL_STATE",
            options:{
                source: "currentUser",
                object: {
                    signUp: true,
                    username:"",
                    password:"",
                    code:"",
                    newPassword:"",
                    name:"",
                    email:"",
                    phoneNumber:"",
                    preferredMFA:"",
                    qrCode:""
                }
            }
        })
    },[appDispatcher])

    //REQUEST ACCOUNT PASSWORD --------------------------------------------------------------
    const handleSendSignUp = useCallback(async (e)=>{
        e.preventDefault()

        //LAUCNH LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //LAUNCH SIGN IN
        signUp(
            state.password,
            state.email.trim(),
            state.firstName,
            state.lastName,
            appDispatcher,
            setState
        )
    },[appDispatcher, state.email, state.firstName, state.lastName, state.password])

    //REQUEST ACCOUNT PASSWORD --------------------------------------------------------------
    const handleConfirmSignUp = useCallback((e)=>{
        e.preventDefault()

        //LAUCNH LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //LAUNCH SIGN IN
        confirmSignUp(
            state.email.trim(),
            state.code.trim(),
            state.password,
            appDispatcher,
            setState
        )
    },[appDispatcher, state.code, state.email, state.password])

    //REQUEST ACCOUNT PASSWORD --------------------------------------------------------------
    const handleSendCodeConfirmSignUp = useCallback((e)=>{
        e.preventDefault()

        //LAUNCH SIGN IN
        resendConfirmationCode(
            state.email.trim(),
            appDispatcher,
            setState
        )
    },[appDispatcher, state.email])

    ///////////////
    /// SIGN IN ///
    ///////////////

    //SUBMIT SIGNIN ------------------------------------------------------------------------
    const handleSubmit = useCallback((e)=>{
        e.preventDefault()

        //LAUCNH LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //LAUNCH SIGN IN
        signIn(
            state.username.trim(),
            state.password,
            appDispatcher,
            setState,
            envTermsVersion
        )
    },[appDispatcher, envTermsVersion, state.password, state.username])

    //CONFIRM SIGN IN WITH MFA -------------------------------------------------------------
    const handleConfirmSignIn = useCallback((e)=>{
        e.preventDefault()

        //LAUCNH LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //LAUNCH SIGN IN
        confirmSignIn(
            currentUser.user,
            state.code.trim(),
            currentUser.user.challengeName,
            appDispatcher,
            setState,
            envTermsVersion
        )
    },[appDispatcher, currentUser.user, envTermsVersion, state.code])

    //BACK TO SIGN IN -----------------------------------------------------------------------
    const handleBackToSignIn = useCallback((e)=>{
        e.preventDefault();

        //RESET
        setState(prevState => {
            return({
                ...prevState,
                username:"",
                password:"",
                loader:false,
                codeSent: false,
                codeLoader:false,
                code:"",
                newPassword:"",
                name:"",
                email:"",
                phoneNumber:"",
                phoneNumberValid:false,
                preferredMFA:"",
                qrCodeLoading: false,
                qrCode:""
            })
        })

        //RESET TO SIGN IN
        appDispatcher({
            type:"SET_USER_SIGN_OUT"
        })
    },[appDispatcher])

    //FIRST CONNECTION COMPLETION -----------------------------------------------------------
    const handleFirstConnection = useCallback(async (e)=>{
        e.preventDefault();

        //LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //MANAGE USER DOMAIN
        const emailSplit = currentUser.user.challengeParam.userAttributes.email.split("@")

        //LAUNCH COMPLETION
        completeNewPassword(
            currentUser.user,
            "@" + emailSplit[1],
            state.newPassword,
            emailSplit[0],
            appDispatcher,
            setState
        )
    },[appDispatcher, currentUser.user, state])

    ///////////
    /// MFA ///
    ///////////

    //CONFIRM SIGN IN WITH MFA -------------------------------------------------------------
    const handleVerifyTotpToken = useCallback(async (e)=>{
        e.preventDefault()

        //LAUCNH LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //GET UPDATED USER
        await Auth.currentAuthenticatedUser({bypassCache: true}).then(async (updatedUser) =>{

            //LAUNCH SIGN IN
            await verifyTotpToken(
                updatedUser,
                state.code.trim(),
                appDispatcher,
                setState,
                envTermsVersion
            )

            //DEL USER DATA COOKIE
            authCleanUserDataCookie()
        });
    },[appDispatcher, envTermsVersion, state.code])

    //LAUNCH SENDING CODE TO VERIFY ATTRIBUT EMAIL AND PHONE --------------------------------
    const handleSendCodeForPhoneVerification = useCallback((e)=>{
        e.preventDefault();

        //TEST IF TH EPHON ENUMBER IS VALID
        if(!state.phoneNumberValid){
            appDispatcher({
                type:"SET_GLOBAL_STATE",
                options:{
                    source: "currentUser",
                    object: {
                        errorMessage: "Phone Number not valid!",
                    }
                }
            })

        //OK
        }else{

            //RESET TO SIGN IN
            setState(prevState => {
                return({
                    ...prevState,
                    codeLoader: true
                })
            })

            //SEND CODE
            updateUserPhoneNumberAndSendCode(
                state.phoneNumber,
                appDispatcher,
                setState
            )
        }
    },[appDispatcher, state.phoneNumber, state.phoneNumberValid])

    //BACK TO UPDATE PHONE NUMBER
    const handleBackToUpdatePhoneNumber = useCallback((e)=>{
        e.preventDefault();

        //RESET TO SIGN IN
        setState(prevState => {
            return({
                ...prevState,
                codeLoader: false,
                codeSent: false
            })
        })
    },[])

    //CONFIRM SIGN IN WITH MFA -------------------------------------------------------------
    const handleVerifyPhoneNumber = useCallback(async (e)=>{
        e.preventDefault()

        //LAUCNH LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //GET UPDATED USER
        await Auth.currentAuthenticatedUser({bypassCache: true}).then(async (updatedUser) =>{

            //LAUNCH SIGN IN
            await verifyPhoneNumberMfa(
                updatedUser,
                state.code.trim(),
                appDispatcher,
                setState,
                envTermsVersion
            )

            //DEL USER DATA COOKIE
            authCleanUserDataCookie()
        });
    },[appDispatcher, envTermsVersion, state.code])

    //CONFIRM SIGN IN WITHOUT MFA
    const handleConfirmSignInWithoutMfa = useCallback(async (e)=>{
        e.preventDefault()

        //LAUCNH LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //GET UPDATED USER
        await Auth.currentAuthenticatedUser({bypassCache: true}).then(async (updatedUser) =>{

            //LAUNCH SIGN IN
            await confirmSignInWithoutMfa(
                updatedUser,
                appDispatcher,
                setState,
                envTermsVersion
            )

            //DEL USER DATA COOKIE
            authCleanUserDataCookie()
        });
    },[appDispatcher, envTermsVersion])

    ////////////////
    /// PASSWORD ///
    ////////////////

    //RESET PASSWORD ------------------------------------------------------------------------
    const handleResetPassword = useCallback((e)=>{
        e.preventDefault();

        //ERROR IF NO USERNAME
        if(state.username === ""){
            appDispatcher({
                type:"SET_GLOBAL_STATE",
                options:{
                    source: "currentUser",
                    object: {
                        errorMessage: "Username cannot be empty!",
                    }
                }
            })
        }else{

            //LAUNCH RESET PASSWORD
            forgotPassword(
                state.username,
                appDispatcher
            )

            //RESET PASSWORD
            setState(prevState => {
                return({
                    ...prevState,
                    password:""
                })
            })
        }
    },[appDispatcher, state])

    //RESET PASSWORD SUBMIT -----------------------------------------------------------------
    const handleResetPasswordSubmit = useCallback((e)=>{
        e.preventDefault();

        //LOADER
        setState(prevState => {
            return({
                ...prevState,
                loader : true,
                code:"",
                qrCode:""
            })
        })

        //LAUNCH RESET PASSWORD
        forgotPasswordSubmit(
            state.username,
            state.code.trim(),
            state.newPassword,
            appDispatcher,
            setState
        )

    },[appDispatcher, state])

    //////////////////
    /// ATTRIBUTES ///
    //////////////////

    //LAUNCH SENDING CODE TO VERIFY ATTRIBUT EMAIL AND PHONE --------------------------------
    const handleSendCodeForAttributeVerification = useCallback((e, attr)=>{
        e.preventDefault();

        //RESET TO SIGN IN
        setState(prevState => {
            return({
                ...prevState,
                codeLoader: true
            })
        })

        //SEND CODE
        verifyCurrentUserAttribute(
            attr,
            appDispatcher,
            setState
        )
    },[appDispatcher])

    //LAUNCH SENDING CODE TO VERIFY ATTRIBUT EMAIL AND PHONE ---------------------------------
    const handleSubmitAttributeVerification = useCallback((e, attr)=>{
        e.preventDefault();

        //RESET TO SIGN IN
        setState(prevState => {
            return({
                ...prevState,
                loader : true
            })
        })

        //SEND CODE
        verifyCurrentUserEmailSubmit(
            attr,
            state.code.trim(),
            appDispatcher,
            setState
        )
    },[appDispatcher, state])

    ////////////////////////
    /// NEEDED TREATMENT ///
    ////////////////////////

    //GET EMAIL FROM USER IF EXISTE ----------------------------------------------------------
    let userEmail = useMemo(() => {
        let email = null;
        if(!currentUser.needMfaCode){
            if(currentUser.user && currentUser.user.attributes){
                email = currentUser.user.attributes.email;
            }else if(currentUser.user
            && currentUser.user.challengeParam
            && currentUser.user.challengeParam.requiredAttributes){
                email = currentUser.user.challengeParam.requiredAttributes.email;
            }
        }
        return email
    }, [currentUser.needMfaCode, currentUser.user])

    ///////////////////////////////
    /// RETURN GLOBAL COMPONENT ///
    ///////////////////////////////

    return(
        <React.Fragment>

            {/* PAGE REFRESH IN PROGRESS */}
            {currentUser.refreshLoading ?
                <div
                    className='flexStartCenter'
                    style={{fontWeight:500}}
                >
                    <CvtSpinnerGrow
                        marginRight="10px"
                        text="I'm looking for your profil"
                    />
                </div>
            :
                <React.Fragment>

                    {/* SIGN UP */}
                    {currentUser.signUp ?
                        <AuthAuthenticatorSignUp
                            options={state}
                            onChange={onChange}
                            handleSubmit={handleSendSignUp}
                            handleBackToSignIn={handleBackToSignIn}
                            errorMessage={currentUser.errorMessage}
                        />
                    :null}

                    {/* SIGN UP */}
                    {currentUser.confirmSignUp ?
                        <AuthAuthenticatorConfirmSignUp
                            options={state}
                            onChange={onChange}
                            handleSubmit={handleConfirmSignUp}
                            handleBackToSignIn={handleBackToSignIn}
                            handleSendCodeConfirmSignUp={handleSendCodeConfirmSignUp}
                            errorMessage={currentUser.errorMessage}
                        />
                    :null}

                    {/* MFA SETUP */}
                    {currentUser.needMfaSetUp ?
                        <AuthAuthenticatorMfaSetUp
                            options={state}
                            username={currentUser.user ? currentUser.user.username : null}
                            email={currentUser.user && currentUser.user.attributes? currentUser.user.attributes.email : null}
                            onChangePreferredMFA={onChangePreferredMFA}
                            errorMessage={currentUser.errorMessage}
                            onChange={onChange}
                            handleBackToSignIn={handleBackToSignIn}
                            handleVerifyTotpToken={handleVerifyTotpToken}
                            onChangePhoneNumber={onChangePhoneNumber}
                            handleSendCodeForPhoneVerification={handleSendCodeForPhoneVerification}
                            handleVerifyPhoneNumber={handleVerifyPhoneNumber}
                            handleBackToUpdatePhoneNumber={handleBackToUpdatePhoneNumber}
                            handleConfirmSignInWithoutMfa={handleConfirmSignInWithoutMfa}
                        />
                    :null}

                    {/* TERMS NEED VALIDATION*/}
                    {!currentUser.needNewPassword && !currentUser.needVerifyEmail && currentUser.displayTermsValidation ?
                        <AuthAuthenticatorNeedValidateTerms
                            handleBackToSignIn={handleBackToSignIn}
                        />
                    :null}

                    {/* VERIFYING EMAIL */}
                    {!currentUser.needNewPassword && currentUser.needVerifyEmail ?
                        <AuthAuthenticatorEmailVerification
                            options={state}
                            onChange={onChange}
                            handleSendCodeForAttributeVerification={handleSendCodeForAttributeVerification}
                            handleSubmitAttributeVerification={handleSubmitAttributeVerification}
                            errorMessage={currentUser.errorMessage}
                            handleBackToSignIn={handleBackToSignIn}
                            userEmail={userEmail}
                        />
                    :null}

                    {/* FIRST CONNECTION NEED NEW PASSWORD */}
                    {currentUser.needNewPassword ?
                        <AuthAuthenticatorFirstConnection
                            options={state}
                            onChange={onChange}
                            handleFirstConnection={handleFirstConnection}
                            errorMessage={currentUser.errorMessage}
                            handleBackToSignIn={handleBackToSignIn}
                        />
                    :null}

                    {/* NEED MFA TO CONNECT USER */}
                    {currentUser.needMfaCode ?
                        <AuthAuthenticatorMfaVerification
                            options={state}
                            onChange={onChange}
                            errorMessage={currentUser.errorMessage}
                            handleConfirmSignIn={handleConfirmSignIn}
                            handleBackToSignIn={handleBackToSignIn}
                            challengeName={currentUser.user.challengeName}
                        />
                    :null}

                    {/* USER WANT TO RESET HIS PASSWORD */}
                    {currentUser.resetPassword ?
                        <AuthAuthenticatorResetPassword
                            options={state}
                            onChange={onChange}
                            errorMessage={currentUser.errorMessage}
                            handleResetPasswordSubmit={handleResetPasswordSubmit}
                            handleBackToSignIn={handleBackToSignIn}
                        />
                    :null}

                    {/* INITAL USER SIGN IN FORM */}
                    {!currentUser.needNewPassword
                    && !currentUser.needMfaCode
                    && !currentUser.resetPassword
                    && !currentUser.needVerifyEmail
                    && !currentUser.displayTermsValidation
                    && !currentUser.needMfaSetUp
                    && !currentUser.signUp
                    && !currentUser.confirmSignUp ?
                        <AuthAuthenticatorSignIn
                            handleSubmit={handleSubmit}
                            handleResetPassword={handleResetPassword}
                            handleRequestAccount={handleRequestAccount}
                            handleSignUp={handleSignUp}
                            options={state}
                            onChange={onChange}
                            errorMessage={currentUser.errorMessage}
                        />
                    :null}
                </React.Fragment>
            }
        </React.Fragment>
    )
}
