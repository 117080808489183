import { CvtInput, CvtSelect, CvtSelectMultiple } from "@mi-gso/cvt";
import { useMemo } from "react";
import { AGENDA_ITEM_TYPES, APP_FORM_STYLE } from "../../../../../00-Core/Constants";
import { FUNCT_FIND_INDEX } from "../../../../../00-Core/Standards";
import { GOV_FUNC_GET_AGENDA_TYPE_ITEMS } from "../../00-Helpers/GovReviewFunctions";

export default function GovReviewAgendaSideBarAddEditItem({
    agendaItem,
    projectData,
    handleInputChange,
}) {
    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // AGENDA ITEM RELATED TO TYPE
    const agendaTypeItems = useMemo(() => {
        let returnOptions = GOV_FUNC_GET_AGENDA_TYPE_ITEMS(agendaItem.type, projectData);
        
        return returnOptions
    }, [agendaItem.type, projectData]);

    // ITEM SELECTED DATA
    const itemSelectedData = useMemo(() => {
        let findIndex = FUNCT_FIND_INDEX(agendaTypeItems, "id", agendaItem.itemId);

        if (findIndex !== -1) {
            return [agendaTypeItems[findIndex]];
        }
        return [];
    }, [agendaTypeItems, agendaItem.itemId]);

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////
    return (
        <div>
            {/* TITLE */}
            <CvtInput
                type={"text"}
                legend={"Title"}
                legendWidth="100%"
                placeholder={"Title of the agenda item"}
                legendColor={APP_FORM_STYLE.legendColor}
                legendMargin={APP_FORM_STYLE.legendMargin}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                inputBlockCss="flexColStartStart modalOptionSubBlock"
                inputPadding={APP_FORM_STYLE.inputPadding}
                inputCss={"form-control modalInputLight"}
                inputFontSize={APP_FORM_STYLE.fontSize}
                inputBlockPadding="0px"
                inputBlockMargin={APP_FORM_STYLE.blockMargin}
                inputWidthFull={true}
                name={"title"}
                value={agendaItem.title}
                onChange={handleInputChange}
                inputUnitCss="bigComponentBodyMiniBlockTitleUnit"
                mandatory={true}
            />

            {/* TYPE */}
            <CvtSelect
                selectBlockCss="flexColStartStart modalOptionSubBlock modalGroup"
                selectFontSize={APP_FORM_STYLE.fontSize}
                selectCss={"form-select modalInputLight modalSelectLight"}
                selectPadding={APP_FORM_STYLE.selectPadding}
                selectBlockMargin={APP_FORM_STYLE.blockMargin}
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                legendMargin={APP_FORM_STYLE.legendMargin}
                selectBlockPadding="0px"
                name={"type"}
                legend={"Type"}
                legendWidth="100%"
                value={agendaItem.type}
                list={AGENDA_ITEM_TYPES}
                listValue={"id"}
                listName={"label"}
                onChange={handleInputChange}
                info={"Type of item related to this agenda"}
                infoId={"govAgendaSideBarItemType"}
            />

            {/* MULTI SELECT TYPE ITEMS */}
            <CvtSelectMultiple
                selectBlockCss="flexColStartStart modalOptionSubBlock modalGroup"
                selectFontSize={APP_FORM_STYLE.fontSize}
                selectCss="form-select modalInputLight modalSelectLight"
                selectPadding={APP_FORM_STYLE.selectPadding}
                selectBlockPadding="0px"
                selectBlockMargin={APP_FORM_STYLE.blockMargin}
                placeholder={"Select the item related to this Agenda"}
                legendColor={APP_FORM_STYLE.legendColor}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                legendMargin={APP_FORM_STYLE.legendMargin}
                name={"itemId"}
                legendTxt={"Item"}
                legendWidth="100%"
                value={itemSelectedData}
                options={agendaTypeItems}
                listValue={"id"}
                listName={"label"}
                onChange={(newArray) => {
                    // GEN EVENT
                    let event = {
                        target: {
                            name: "itemId",
                            value: newArray.length > 0 ? newArray[0].id : null,
                        },
                    };

                    handleInputChange(event);
                }}
                infoId={"govAgendaSideBarItemItemId"}
                closeOnSelect={true}
                canCreate={false}
            />

            {/* DECISION EXPECTED */}
            <CvtInput
                type={"text"}
                legend={"Decision expectation"}
                legendWidth="100%"
                placeholder={"What decision do you expect"}
                legendColor={APP_FORM_STYLE.legendColor}
                legendMargin={APP_FORM_STYLE.legendMargin}
                legendFontSize={APP_FORM_STYLE.legendFontSize}
                inputBlockCss="flexColStartStart modalOptionSubBlock"
                inputBlockMargin={APP_FORM_STYLE.blockMargin}
                inputPadding={APP_FORM_STYLE.inputPadding}
                inputCss={"form-control modalInputLight"}
                inputFontSize={APP_FORM_STYLE.fontSize}
                inputBlockPadding="0px"
                inputWidthFull={true}
                name={"expectation"}
                value={agendaItem.expectation}
                onChange={handleInputChange}
                inputUnitCss="bigComponentBodyMiniBlockTitleUnit"
            />
        </div>
    );
}
