// Component that reads the markdown string.
import ReactMarkdown from 'react-markdown';

// Plugins for better reading the markdown text.
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

//THE FILE TEXT
const markdown = `

# PREAMBLE

These Terms and Conditions of Use (hereinafter referred to as the “**Terms and Conditions of Use**”) aim to define the contractual relationship between the Company and the User for the use of the CLAYVEREST tool (“**CLAYVEREST**”).

The employer or the employment authority/principal of the User (the “**Client**”) has entered into a service provision agreement with the company MI-GSO-PCUBED for execution of the Services. In reading and accepting these Terms and Conditions of Use, the User consents to activating his CLAYVEREST account.

The Services are provided by MI-GSO-PCUBED, a company duly incorporated under French law, based at rue Alain Fournier, Toulouse, France (the “**Company**” or “**MI-GSO-PCUBED**”).

Any use of CLAYVEREST assumes the prior and unreserved acceptance of all the terms of these Terms and Conditions of Use by the User. 

# DEFINITIONS

- “**CLAYVEREST**” denotes the tool that enables the User to store, share, organise and manage Data within the framework of the management of his projects. CLAYVEREST is available at the address www.clayverest.com.
- “**Data**” denotes all information concerning a project of the Client.
- “**Personal Data**” denotes all information concerning an identified or identifiable natural person. An “identifiable natural person” is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to said natural person.
- “**Services**” denotes the services provided by the Company to the Client and the User via access to CLAYVEREST.
- “**User**” denotes the natural person to whom access to CLAYVEREST has been given in order that he can execute his tasks.

## DESCRIPTION OF THE SERVICES

According to the agreement between the Company and the Client, the User has access to one or more of the following services: 

- TimeLine Generator: Creation of high-level customisable roadmaps, which may or may not be based on calendar data. 
- Telescope: Management of a portfolio of projects and their main components (costs, deadlines, project charter, etc.)
- Core: Creation of organisations and their Work Breakdown Structure (WBS). These WBS are subsequently used in the above two applications.

## ACCESS TO THE SITE AND SERVICES

Access to CLAYVEREST is reserved exclusively to Users holding a licence subscribed by the Client.

The Client and the User are alone responsible for the proper functioning of the IT hardware and the internet connection that enable them access to CLAYVEREST and to the Services. 

In case of planned or unexpected interruption of CLAYVEREST, the User shall be informed of the situation by a message which will appear when he logs into his account.

The Company will make every effort to guarantee maximum availability of the Service. The Company shall implement reasonable commercial resources to ensure the availability of the Service.

## DURATION

The User accepts these Terms and Conditions of Use for an undefined duration.

## OBLIGATIONS OF THE USER

The User undertakes to access and use CLAYVEREST and the Services in compliance with the laws in force and these Terms and Conditions of Use.

It is prohibited to upload to CLAYVEREST Data with a content that is illegal, malicious, liable to infringe third party rights, or contrary to good morals or public order. MI-GSO cannot be held liable under any circumstances for any illegal content stored on CLAYVEREST, and reserves the right to suspend any account infringing these provisions, and consequently to suspend any related access.

In the user assistance and new features follow-up tool, known as “ticketing”, User undertakes to never submit commercial or personal data, least even sensitive personal data (religion, politics, philosophic, health, sexual&gender orientation) and to always abide to the common laws on the internet: limit caps, using a clear langage, refrain from smileys or abbreviations.   

## SECURITY

The Company shall implement the appropriate technical and organisational measures to ensure a level of security appropriate to the risk. 

The Client ha validated the useful measures taken by the Company in the matter of security, notably regarding the security of the hardware, in order to ensure the protection and integrity of the Data throughout their duration of storage within the framework of the Services.

## COOKIES, ANALYTICS & LOGGING

The Company only uses functional cookies, enabling operation of the platform by managing the authentication, changes between the tools of the platform, etc.

The Company also uses analytics tools, which make it possible to measure the performance of CLAYVEREST and to timestamp the various events (logging) in order to manage the security of CLAYVEREST and contribute to the analysis of performance.

## LIABILITY

The User and the Client are alone responsible for the proper management of the projects. 

The Company intervenes as supplier of a tool to the Users within the framework of the project management. It cannot under any circumstances be held liable for the results of the projects. The liability of the Company is therefore limited to the provision of CLAYVEREST, and it undertakes to guarantee access to CLAYVEREST and to maintain the integrity and confidentiality of the Data.

## INTELLECTUAL PROPERTY AND LICENCE

The Company keeps all property rights relative to CLAYVEREST and its Services, including all know-how, methodologies, code, designs and improvements of the Services, to the exclusion of the Data integrated into any deliverable. The deliverables or results obtained through the use of CLAYVEREST are the industrial, literary or artistic property of the User or the Client, as per the regime that applies between them and to which the Company remains third party.

## MODIFICATIONS MADE TO CLAYVEREST AND TO THESE TERMS AND CONDITIONS OF USE

The Company reserves the right to amend or update these Terms and Conditions of Use. The User must then confirm the update to these Terms and Conditions of Use when logging in to CLAYVEREST.

## PERSONAL DATA

> This has no contractual value, but outlines your rights over your data as user. 

MI-GSO and its Client (we) have chosen the CLAYVEREST tool in the context of the management of our projects. In this way, we are jointly responsible for the processing of your personal data, such as your login details (email and password, telephone number) or the data you enter into the tool. This is based on our legitimate interest in providing you with the tools necessary to execute your work, and to ensure the IT security of the tool. We and our suppliers will use these data, through our project, technical or administrative teams, and will keep them until the end of the projects in which you have participated, or the deactivation of your CLAYVEREST account. 

You have several rights you can exercise by opening a technical support ticket. 

| Right | Description |
|:-----|:------|
| **Access** | 	All your data, with the exception of the logs, are visible and accessible in the tool. For a full copy, see previous paragraph |
| **Rectification** | 	You can directly rectify your user name, password, email address, telephone number, and the type of double authentication by logging in to CLAYVEREST, clicking on “User Profile”, then editing AND saving. You can also spontaneously correct the objects you create in the tool  |
| **Erasure** | 	You can request the erasure of your data, which will prevent any further use of the tool.  |
| **Restriction** | 	You can request restriction of processing of your data, which will temporarily prevent its use. You can also simply not use CLAYVEREST until your request has received a response.  |
| **Portability** | 	You can create an extraction of your data in order to load them into another environment.  |
| **Objection** | 	Objection is not possible because this would prevent you from executing your professional tasks.   |
 
You can contact us via the data protection officer of your employer or through a support ticket. In case of disagreement after this contact, you can send a complaint to the Commission Nationale Informatique et Libertés, Place de Fontenoy, 75007 Paris. 

Your data are not transferred outside of the European Economic Area, and we will not undertake purely automated profiling or decision making on your data.

## LANGUAGE

These Terms and Conditions of Use have been written in several languages. For the purposes of interpretation of these Terms and Conditions of Use, the French version shall prevail.

## APPLICABLE LAW AND JURISDICTION 

These Terms and Conditions of Use are subject to French law. Any dispute relative to these Terms and Conditions of Use, or their formation, interpretation, performance, termination or expiry, falls to the exclusive jurisdiction of the French courts.
`
//EXPORT
export default <ReactMarkdown children={markdown} remarkPlugins={[[remarkGfm]]} rehypePlugins={[rehypeRaw]}/>;