import React, { useCallback, useMemo, useState } from 'react'
import {  FUNCT_FIND_INDEX, FUNCT_FIND_INDEX_ARRAY } from '../../../../00-Core/Standards';

//CORE
import FiltersChildren from './FiltersChildren';
import FiltersGroupTitle from './FiltersGroupTitle';
import FiltersGroupSearchBar from './FiltersGroupSearchBar';
import { FILTERS_DOMAIN_INFO } from '../00-Helpers/FiltersConstants';

//////////////////////////////
/// FILTER GROUP COMPONENT ///
//////////////////////////////

export function FilterGroup({
    wbsDomain,
    wbsDomainFilters,
    usersList,
    appDispatcher,
    filteredProjectData,
}) {    
    
    //////////////////////////////////////////////////////////////////////////////
    /// DISPLAY //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    
    //COUNT HOW MANY FILTERS ARE ACTIVATED ---------------------------------------
    const filtersCount = useMemo(() => {

        //INIT
        let count = 0;

        //TEST IF SOME CURRENT FILTERS
        if(wbsDomainFilters.length >0){

            //COUNT
            for (let i = 0; i < wbsDomainFilters.length; i++) {

                //GET DETAILS 
                let currentSubDomainInfoIndex = FUNCT_FIND_INDEX(FILTERS_DOMAIN_INFO[wbsDomainFilters[i].domain],"subDomain",wbsDomainFilters[i].subDomain)

                //IF DATE SORT
                let isDate = FILTERS_DOMAIN_INFO[wbsDomainFilters[i].domain][currentSubDomainInfoIndex].type === "date"

                //COUNT ONLY IF END DATE SELECT IN CASE OF RANGE
                if(isDate && wbsDomainFilters[i].value.length > 1 && !wbsDomainFilters[i].value[0]){
                    count += 0

                //COUNT NORMAL
                }else{
                    count += wbsDomainFilters[i].value.length;
                }
            }
        }

        //RETURN
        return count;
    }, [wbsDomainFilters]);

    //INIT STATE -----------------------------------------------------------------
    const [display, setDisplay] = useState(filtersCount)

    //////////////////////////////////////////////////////////////////////////////
    /// OWNER SEARCH /////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    //SEARCH STATE ---------------------------------------------------------------
    const [ownerSearch, setOwnerSearch] = useState("")

    //NEW LIST -------------------------------------------------------------------
    const ownerList = useMemo(() => {

        //TEST IF LIST AVAILABLE
        if(wbsDomain.id === "owner"){

            //IF SEARCH AVAILABLE
            if(ownerSearch !== ""){

                //INIT
                let newList = [];

                //FIND INDEX
                let findIndex = FUNCT_FIND_INDEX(wbsDomainFilters,"subDomain","owner")

                //ALREADY OWNER SELECTED
                let selectedOwner = null
                if(findIndex > -1){
                    selectedOwner = wbsDomainFilters[findIndex].value
                }

                //KEEP ALREADY SELECTED
                newList = usersList.filter(user => {

                    //ALREADY SELECTED
                    if(selectedOwner && selectedOwner.length > 0 && FUNCT_FIND_INDEX_ARRAY(selectedOwner, user.value) > -1) return true
                    
                    //SEARCH NAME 
                    if(user.name && user.name.toUpperCase()?.includes(ownerSearch.toUpperCase())) return true

                    //SEARCH EMAIL
                    if(user.label && user.label.toUpperCase()?.includes(ownerSearch.toUpperCase())) return true
                
                    //IF NOT RETURN FALSE
                    return false
                })

                //RETURN NEW LIST
                return newList
            }

            //DEFAULT
            return usersList
        }

        //RETURN NULL IF NOT
        return null
    },[ownerSearch, usersList, wbsDomain.id, wbsDomainFilters])

    //GET LEADER LIST
    const leaderList = useMemo(() => {
        
        //INIT
        let list = [];

        //WAITING
        if(usersList && usersList.length > 0){

            //INTEGRATION LOOP
            for (let i = 0; i < usersList.length; i++) {
                if(usersList[i].projectLeader){
                    list.push(usersList[i])
                }
            }
        }

        //RETURN LIST
        return list
    }, [usersList])

    // GET FILTERED REVIEWS


    //////////////////////////////////////////////////////////////////////////////
    /// FUNCTION /////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    
     // HANDLE DATE/NUMBER CHANGE ------------------------------------------------
    const handleFilterChange = useCallback((
        value, 
        subDomain
    ) => {
        //UPDATE FILTERS
        appDispatcher({
            type: "SET_WBS_FILTER_VALUE",
            domain: wbsDomain.id,
            subDomain: subDomain,
            value: [value],
        })
    }, [appDispatcher, wbsDomain.id]);

    // HANDLE SELECT CHANGE -------------------------------------------------------
    const handleSelectChange = useCallback((
        values,
        subDomain,
    ) => {
        appDispatcher({
            type: "SET_WBS_FILTER_VALUE",
            value: values,
            subDomain,
            domain: wbsDomain.id,
        });
        
    }, [appDispatcher, wbsDomain.id]);

    // HANDLE CHECKBOX CHANGE -----------------------------------------------------
    const handleCheckboxFilterChange = useCallback((
        checkValue, 
        value, 
        subDomain
    ) => {
        
        //FIND INDEX
        const findIndex = FUNCT_FIND_INDEX(wbsDomainFilters,"subDomain",subDomain)

        //INIT
        let newValue = null;
        if(findIndex > -1){
            newValue = wbsDomainFilters[findIndex].value
        }
        
        //NEW VALUE
        if(checkValue) {
            if(newValue) {
                newValue.push(value);
            }else {
                newValue = [value];
            }
        }else {
            newValue = newValue.filter((option) => option !== value);
        }

        //APP STATE
        appDispatcher({
            type: "SET_WBS_FILTER_VALUE",
            domain: wbsDomain.id,
            subDomain: subDomain,
            value: newValue,
        })
    }, [appDispatcher, wbsDomain.id, wbsDomainFilters]);

    // HANDLE DATE CHANGE ---------------------------------------------------------
    const handleDateFilterChange = useCallback((
        type,
        value, 
        subDomain
    ) => {

        //FIND INDEX
        let findIndex = FUNCT_FIND_INDEX(wbsDomainFilters,"subDomain",subDomain)

        //INIT
        let newValue = [];
        if(findIndex > -1){
            newValue = wbsDomainFilters[findIndex].value
        }

        //WICH ONE TO UPDATE
        switch (type) {
            case "start":
                newValue[1] = value
                break;

            case "end":
                newValue[0] = value
                break;

            case "reset":
                if(newValue[0]){
                    newValue.splice(1,1)
                }else{
                    newValue = []
                }
                
                break;
        
            default:
                break;
        }

        //APP STATE
        appDispatcher({
            type: "SET_WBS_FILTER_VALUE",
            domain: wbsDomain.id,
            subDomain: subDomain,
            value: newValue,
        })
    }, [appDispatcher, wbsDomain.id, wbsDomainFilters]);

     // HANDLE DATE/NUMBER CHANGE -------------------------------------------------
     const handleFilterReset = useCallback(() => {
        
        // SEND ACTION TO APP DISPATCHER
        appDispatcher({
            type: "SET_WBS_FILTER_RESET_DOMAIN",
            domain: wbsDomain.id,
        })
    }, [appDispatcher, wbsDomain.id]);

    //////////////////////////////////////////////////////////////////////////////
    /// COMPONENT RENDER /////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    //IF SUBDOMAIN INFO
    if(FILTERS_DOMAIN_INFO[wbsDomain.id]){
        return(
            <div className="rightSideBarGroup">

                {/* GROUP TITLE */}
                <FiltersGroupTitle 
                    wbsDomain={wbsDomain}
                    display={display}
                    filtersCount={filtersCount}
                    handleFilterReset={handleFilterReset}
                />

                {/* EDIT / FULL SCREEN VIEW MODE */}
                <div 
                    id={"flushGroup-" + wbsDomain.id} 
                    className={"accordion-collapse collapse filterGroupContent " + (display > 0 ? "show" : "")}
                    data-bs-parent="#accordionEditSideBar"
                > 

                    {/* SEARCH FOR OWNER */}
                    {wbsDomain.id === "owner" ? 
                        <FiltersGroupSearchBar 
                            setSearch={setOwnerSearch}
                            value={ownerSearch}
                        />
                    :null}

                    {/* TO BE INCLUDED INTO CHILDREN*/}
                    {ownerList && ownerList.length === 0 ?
                        <div className='filterNoResults'>
                            No results found
                        </div>
                    :
                        FILTERS_DOMAIN_INFO[wbsDomain.id] ? 
                            <FiltersChildren 
                                wbsDomainId={wbsDomain.id}
                                wbsSubDomains={FILTERS_DOMAIN_INFO[wbsDomain.id]}
                                wbsDomainFilters={wbsDomainFilters}
                                ownerList={ownerList}
                                leaderList={leaderList}
                                handleFilterChange={handleFilterChange}
                                handleCheckboxFilterChange={handleCheckboxFilterChange}
                                handleDateFilterChange={handleDateFilterChange}
                                handleSelectChange={handleSelectChange}
                                setDisplay={setDisplay}
                                filteredProjectData={filteredProjectData}
                            />
                        :null
                    }
                </div>
            </div>
        );
    }

    //IF NO SUBDOMAIN INFOS:
    return []
}