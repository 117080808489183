// PACKAGES
import React, { useCallback, memo, useMemo, useState } from "react";
import _ from "lodash";

// FROM MIGSO
import { Button, CvtButton, CvtSearchBar } from "@mi-gso/cvt";

// CORE COMPONENTS
import { CvtMultipleCheckboxDropdown } from "@mi-gso/cvt";
// RISK CONSTANTS
import {
  RISK_OPP_IMPACT_LIST,
  RISK_OPP_PROBABILITY_LIST,
  RISK_STRATEGY_LIST,
  RISK_OPP_EXPAND_MODES,
  OPP_STRATEGY_LIST,
  RISKOPP_STATUS_LIST,
} from "../../00-Helpers/RiskConstants";
import RiskTableItem from "./RiskTableItem";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../../../00-Wbs/00-Helpers/WbsConstants";
import RiskTableLegend from "./RiskTableLegend";
import { TooltipProvider, Tooltip } from "react-tooltip";

////////////////////////////
/// RISK TABLE /////////////
////////////////////////////

export default memo(function RiskTable({
  // CURRENT STATE EXPAND MODE
  expandMode,
  // FUNCTION THAT HANDLES EXPAND ARROW CLICK
  handleExpandArrowClick,
  // RISK OPP ITEMS
  displayRiskOppItems,
  // STATE RISK OPP ITEMS
  roData,
  // USERS LIST
  usersList,
  megaUsersId,
  // ON ITEM CLICKED
  onRiskOppItemClick,
  // SELECTED RISK OPP ITEMS LIST
  selectedRiskOppItems,
  // TEXT SEARCH INPUT,
  textSearchInput,
  // ARRAY OF STATUS FILTERS APPLIED
  roStatusSearchFilter,
  // FUNCTION TO CREATE NEW ITEMS.
  onClickAdd,
  // RISK OPP DISPATCHER
  riskOppDispatcher,
  // WBS ID
  wbsId,
  // IS RISK
  isRisk,
  // KEY PROP INCREMENT
  propKeyIncrement,
  // PARENT ID
  parentId,
  // APP DISPATCHER
  appDispatcher,
  // HANDLE EDITING ON RO ITEM
  handleEditRoItem,
  // HANDLE CLICK IN CHECKBOX
  handleRoSelectCheckbox,
  // VIEW MODE
  viewMode,
  // DETAILED RO MATRIX ITEM
  detailedRoMatrixItem,
  displayWidth,

  isReviewMode,
  isProjectElement,
  isReadOnlyMode,
  isCurrentUserEditor,
}) {
  /////////////////////////////////////////////////////
  /// STATE ///////////////////////////////////////////
  /////////////////////////////////////////////////////

  const [state, setState] = useState({
    strategyFilters: [],
    likelihoodFilters: [],
    impactFilters: [],
  });

  /////////////////////////////////////////////////////
  /// MEMOS ///////////////////////////////////////////
  /////////////////////////////////////////////////////

  // FILTER RO BY ADITION
  const filteredRo = useMemo(() => {
    // IF NOT EXPANDED, THEN JUST RETURN DISPLAY RISK OPP ITEMS FOR PERFORMANCE
    return expandMode === RISK_OPP_EXPAND_MODES.tableOnly
      ? displayRiskOppItems.filter((ro) => {
          let isPassStrategy = true;
          if (state.strategyFilters.length > 0 && ro.strategy) {
            isPassStrategy = state.strategyFilters?.includes(
              ro.strategy.toLowerCase()
            );
          }
          let isPassLikelihood = true;
          if (state.likelihoodFilters.length > 0) {
            isPassLikelihood =
              state.likelihoodFilters?.includes(ro.initialPorba) ||
              state.likelihoodFilters?.includes(ro.currentProba) ||
              state.likelihoodFilters?.includes(ro.targetProba);
          }
          let isPassImpact = true;
          if (state.impactFilters.length > 0) {
            isPassImpact =
              state.impactFilters?.includes(ro.initialImpact) ||
              state.impactFilters?.includes(ro.currentImpact) ||
              state.impactFilters?.includes(ro.targetImpact);
          }

          return isPassStrategy && isPassLikelihood && isPassImpact;
        })
      : displayRiskOppItems;
  }, [
    displayRiskOppItems,
    expandMode,
    state.impactFilters,
    state.likelihoodFilters,
    state.strategyFilters,
  ]);

  // LEGEND OBJECT WITH COUNTER FOR RO TABLE
  const roLegendAndCounter = useMemo(() => {
    // INIT
    let legendObject = [];
    let cloneRo = [];

    // CHOOSE WITH DATA TO USE
    if (
      textSearchInput &&
      textSearchInput !== "" &&
      roStatusSearchFilter.length === 0
    ) {
      cloneRo = _.cloneDeep(filteredRo);
    } else {
      cloneRo = _.cloneDeep(roData);
    }

    // ONYL IF DATA
    if (cloneRo && cloneRo.length > 0) {
      // LOOP ON ARRAY LIST
      let totalCount = 0;

      for (let i = 0, len = RISKOPP_STATUS_LIST.length; i < len; i++) {
        // GET NUM OF RO ITEMS WITH THIS STATUS
        let currentRoCount = cloneRo.filter(
          (ro) => ro.status === RISKOPP_STATUS_LIST[i].value
        ).length;

        // INTEGRATE IF NOT EMPTY
        if (currentRoCount > 0) {
          // TOTAL COUNT
          totalCount = totalCount + currentRoCount;

          // INTEGRATE
          legendObject.push({
            id: RISKOPP_STATUS_LIST[i].value,
            name: RISKOPP_STATUS_LIST[i].label,
            value: currentRoCount,
            backgroundColor: RISKOPP_STATUS_LIST[i].backgroundColor,
            color: RISKOPP_STATUS_LIST[i].color,
          });
        }
      }

      // TOTAL
      legendObject.push({
        id: "total",
        name: "Total",
        value: totalCount,
      });
    }

    // RETURN
    return legendObject;
  }, [filteredRo, roData, roStatusSearchFilter.length, textSearchInput]);

  /////////////////////////////////////////////////////
  /// FUNCTIONS ///////////////////////////////////////
  /////////////////////////////////////////////////////

  // ON CHANGE SEARCH INPUT TEXT
  const onChangeInput = useCallback(
    (e) => {
      riskOppDispatcher({
        type: "SET_STATE_KEY_VALUE",
        key: e.target.name,
        value: e.target.value,
      });
    },
    [riskOppDispatcher]
  );

  // ON RESET SEARCH INPUT TEXT
  const onChangeReset = useCallback(() => {
    riskOppDispatcher({
      type: "SET_STATE_KEY_VALUE",
      key: "textSearchInput",
      value: "",
    });
  }, [riskOppDispatcher]);

  // HANDLES SELECT OF FILTERS
  const handleSelectFilter = useCallback((e, property) => {
    setState((prevState) => {
      let name = e.target.name;
      let value = e.target.checked;

      return {
        ...prevState,
        [property]: !value
          ? prevState[property].filter((option) => option !== name)
          : [...prevState[property], name],
      };
    });
  }, []);

  // HANDLE CLEAR ALL FILTERS FOR A SPECIFIC PROPERTY
  const handleClearAll = useCallback((property) => {
    setState((prevState) => {
      return {
        ...prevState,
        [property]: [],
      };
    });
  }, []);

  // SELECT/UNSELECT ALL ITEMS
  const handleSelectCheckbox = useCallback(
    (e) => {
      e.stopPropagation();

      // INIT
      let newSelectedList = [];

      // IF EMPTY, THEN SELECT ALL, OTHERWISE IS EMPTY
      if (selectedRiskOppItems.length === 0) {
        newSelectedList = _.cloneDeep(displayRiskOppItems);
      }

      riskOppDispatcher({
        type: "SET_STATE_OBJECT",
        object: {
          selected: newSelectedList,
        },
      });
    },
    [displayRiskOppItems, riskOppDispatcher, selectedRiskOppItems.length]
  );

  /////////////////////////////////////////////////////
  /// COMPONENT RENDER ////////////////////////////////
  /////////////////////////////////////////////////////

  return (
    <TooltipProvider>
      {/* RISK TABLE */}
      <div
        className={`actionTableContainer ${viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view || detailedRoMatrixItem!==null ? "hideContainer" : ""}`}
        data-cy="risk-table-container"
        style={{
          width: displayWidth,
          display:
            expandMode === RISK_OPP_EXPAND_MODES.matrixOnly ||
            detailedRoMatrixItem
              ? "none"
              : "",
          margin:
            displayWidth === "0%"
              ? "0px"
              : expandMode === RISK_OPP_EXPAND_MODES.tableOnly
              ? "0px 20px"
              : "0px 20px 0px 0px",
          height: "100%",
        }}
      >
        {/* HEADERS (SEARCH INPUT + OPEN/CLOSE TOGGLE) */}
        <div
          className="flexBetweenCenter actionTableContainerHeader"
          style={{ display: displayWidth === "0%" ? "none" : null }}
        >
          {roData.length === 0 ? (
            <div
              style={{
                color: "var(--color-text-2)",
                fontWeight: "600",
                fontSize: "14px",
                marginLeft: "20px",
              }}
            >
              {isRisk ? "Risks" : "Opportunities"}
            </div>
          ) : null}

          {/* EXPAND BUTTON*/}
          <Button
            icon={
              expandMode === RISK_OPP_EXPAND_MODES.tableOnly
                ? "chevron_right"
                : "chevron_left"
            }
            css="btn commonButtonSubmitLight actionTableExpandButton"
            borderRadius="20px"
            iconFontSize="16px"
            handleSubmit={() =>
              handleExpandArrowClick(
                expandMode === RISK_OPP_EXPAND_MODES.tableOnly ? false : true,
                false
              )
            }
          />
          {filteredRo.length > 0 ? (
            <>
              {/* SELECT CHECKBOX + FILTERS (IF EXPANDED) */}
              <div className="flexStartCenter">
                {/* SELECT BOX */}
                {!isReadOnlyMode && isCurrentUserEditor ? (
                  <div
                    className={`flexCenterCenter actionTableSelectBox ${
                      selectedRiskOppItems.length > 0
                        ? "actionTableSelectBoxActive"
                        : ""
                    } `}
                    onClick={handleSelectCheckbox}
                    style={{
                      minWidth: "16px",
                      minHeight: "16px",
                      maxWidth: "16px",
                      maxHeight: "16px",
                      margin: "0px 3px 0px 12px",
                    }}
                  >
                    {selectedRiskOppItems.length > 0
                      ? selectedRiskOppItems.length
                      : ""}
                  </div>
                ) : null}

                {/* LEGEND STATUS TAGS */}
                <RiskTableLegend
                  roLegendAndCounter={roLegendAndCounter}
                  roStatusSearchFilter={roStatusSearchFilter}
                  riskOppDispatcher={riskOppDispatcher}
                />

                {/* IF EXPAND */}
                {expandMode === RISK_OPP_EXPAND_MODES.tableOnly &&
                  !isReviewMode && (
                    <React.Fragment>
                      {/* STRATEGY FILTER DROPDOWN */}
                      <CvtMultipleCheckboxDropdown
                        options={
                          isRisk === true
                            ? RISK_STRATEGY_LIST
                            : OPP_STRATEGY_LIST
                        }
                        handleSelect={(e) =>
                          handleSelectFilter(e, "strategyFilters")
                        }
                        selectedList={state.strategyFilters}
                        title="Strategy"
                        blockCss="riskTableFilterBlockCss"
                        titleBlockCss="riskTableFilterTitleBlockCss"
                        keyName="strategyFilters"
                        handleClearAll={() => handleClearAll("strategyFilters")}
                        customInlineBlockStyles={{ marginLeft: "3px" }}
                      />

                      {/* LIKELIHOOD FILTER DROPDOWN*/}
                      <CvtMultipleCheckboxDropdown
                        options={RISK_OPP_PROBABILITY_LIST}
                        handleSelect={(e) =>
                          handleSelectFilter(e, "likelihoodFilters")
                        }
                        selectedList={state.likelihoodFilters}
                        title="Likelihood"
                        blockCss="riskTableFilterBlockCss"
                        // TITLE BLOCK CSS
                        titleBlockCss="riskTableFilterTitleBlockCss"
                        // KEY NAME
                        keyName="strategyFilters"
                        handleClearAll={() =>
                          handleClearAll("likelihoodFilters")
                        }
                        // SMALLER MARGIN LEFT FOR FIRST DROPDOWN
                        customInlineBlockStyles={{
                          marginLeft: "10px",
                        }}
                      />
                      {/* IMPACT FILTER DROPDOWN */}
                      <CvtMultipleCheckboxDropdown
                        options={RISK_OPP_IMPACT_LIST}
                        handleSelect={(e) =>
                          handleSelectFilter(e, "impactFilters")
                        }
                        selectedList={state.impactFilters}
                        title="Impact"
                        blockCss="riskTableFilterBlockCss"
                        // TITLE BLOCK CSS
                        titleBlockCss="riskTableFilterTitleBlockCss"
                        // KEY NAME
                        keyName="strategyFilters"
                        handleClearAll={() => handleClearAll("impactFilters")}
                        // SMALLER MARGIN LEFT FOR FIRST DROPDOWN
                        customInlineBlockStyles={{
                          marginLeft: "10px",
                        }}
                      />
                    </React.Fragment>
                  )}
              </div>
              {/* SEARCH INPUT */}
              <CvtSearchBar
                textSearchInput={textSearchInput}
                total={filteredRo.length}
                textSearchKey="textSearchInput"
                onChangeInput={onChangeInput}
                onChangeReset={onChangeReset}
              />
            </>
          ) : null}
        </div>

        <div
          style={{
            height: "calc(100% - 13px)",
            width: "100%",
            display: displayWidth === "0%" ? "none" : null,
          }}
        >
          {/* TABLE CONTENT */}
          <div
            id="actionsTable"
            className="actionTable"
            style={{
              maxHeight: isReadOnlyMode ? "calc(100% - 60px)" : null,
            }}
          >
            {filteredRo.map((item) => {
              // IS SELECTED
              let isSelected =
                selectedRiskOppItems.find((ro) => ro.id === item.id) !==
                undefined;

              //IS A CHILD
              let isChild =
                item.wbsId !== wbsId ||
                (item.escalatedTo && item.escalatedTo?.includes(wbsId));

              return (
                <RiskTableItem
                  key={`risk_table_item_${isRisk}_${item.id}_${isSelected}`}
                  riskOppItem={item}
                  usersList={usersList}
                  onRiskOppItemClick={onRiskOppItemClick}
                  riskOppDispatcher={riskOppDispatcher}
                  selectedRiskOppItems={selectedRiskOppItems}
                  isSelected={isSelected}
                  wbsId={wbsId}
                  parentId={parentId}
                  appDispatcher={appDispatcher}
                  coreSelectionUsers={megaUsersId}
                  handleEditRoItem={handleEditRoItem}
                  expandMode={expandMode}
                  textSearchInput={textSearchInput}
                  handleRoSelectCheckbox={handleRoSelectCheckbox}
                  isReviewMode={isReviewMode}
                  isCurrentUserEditor={isCurrentUserEditor}
                  isChild={isChild}
                  viewMode={isReadOnlyMode}
                />
              );
            })}
          </div>

          {/* CREATE BUTTON */}
          <div
            className="actionTableCreateButton flexStartCenter"
            style={{ width: "100%", minHeight: "13px" }}
          >
            {!isReadOnlyMode ? (
              <CvtButton
                color="white"
                shape="noShape"
                icon="add"
                size="small"
                fullWidth
                txt={`Create ${roData.length === 0 ? "your first" : `a${isRisk ? "" : "n"}`} ${isRisk ? "risk" : "opportunity"}`}
                handleSubmit={onClickAdd}
                margin="5px 0px 0px"
              />
            ) : null}
          </div>
        </div>
      </div>

      {/* COMMON TOOLTIP */}
      <Tooltip id="risk-table-tooltip" className="tooltipCommon" />
    </TooltipProvider>
  );
});
