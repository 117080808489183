import React from "react";
import { CvtButton, CvtSelect } from "@mi-gso/cvt";

//////////////////////////////////////////
/// ACTION CHART COMMEND BAR COMPONENT ///
//////////////////////////////////////////

export default function ScheduleChartCommandBar({
    yearSelected,
    atCompletionMode,
    atCompletionModeYearsSelect,
    atCompletionSelectedFirstYear,
    atCompletionSelectedLastYear,
    secondYAxis,
    onChangeYear,
    onChangeAtCompletionMode,
    onChangeYAxis,
    handleChange,
}) {

    /////////////////////////////////////////////////////
    // RENDER ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    return (
        <div className="flexStartCenter">
            {/* SELECT YEAR */}
            {!atCompletionMode ? (
                <div
                    className="bigComponentCommandBarBlock flexBetweenCenter"
                    style={{
                        marginRight: "0px",
                        marginLeft: "10px",
                    }}
                >
                    {/* TITLE + NUMBER */}
                    <div
                        className="bigComponentCommandBarBlockLegend"
                        style={{
                            minWidth: "0px",
                            marginRight: "5px",
                        }}
                    >
                        Year :
                        <span className="bigComponentCommandBarBlockLegendHighlight">
                            {yearSelected}
                        </span>
                    </div>

                    {/* MINUS */}
                    <CvtButton
                        color="white"
                        shape="noShape"
                        size="tiny"
                        margin="-10px -8px -10px 0px"
                        icon="remove"
                        iconFontSize="16px"
                        handleSubmit={onChangeYear}
                        handleSubmitOptions={false}
                    />

                    {/* PLUS */}
                    <CvtButton
                        color="white"
                        shape="noShape"
                        size="tiny"
                        margin="-10px -10px -10px 0px"
                        icon="add"
                        iconFontSize="16px"
                        handleSubmit={onChangeYear}
                        handleSubmitOptions={true}
                    />

                    {/* RESET */}
                    <CvtButton
                        color="white"
                        shape="noShape"
                        size="tiny"
                        margin="-10px 0px -10px 10px"
                        icon="refresh"
                        iconFontSize="16px"
                        handleSubmit={(e) => {
                            onChangeYear(e, null, true);
                        }}
                        handleSubmitOptions={true}
                    />
                </div>
            ) : null}

            {/* AT COMPLETION MODE BUTTON */}
            <CvtButton
                color={atCompletionMode ? "blue" : "gray"}
                outline={!atCompletionMode}
                customHover="blue"
                size="tiny"
                icon={atCompletionMode ? "visibility" : "visibility_off"}
                txt="At Completion"
                handleSubmit={onChangeAtCompletionMode}
                margin="0px 0px 0px 10px"
            />

            {/* AT COMPLETION YEAR RANGE SELECTS */}
            {atCompletionMode ? (
                <div className="flexStartCenter">
                    {/* MIN YEAR SELECT FILTER */}
                    <CvtSelect
                        selectBlockCss="flexStartCenter"
                        selectFontSize="12px"
                        selectPlaceHolder="First year"
                        name="atCompletionSelectedFirstYear"
                        value={atCompletionSelectedFirstYear ? atCompletionSelectedFirstYear : ""}
                        list={[{ value: "", label: "First Year" }, ...atCompletionModeYearsSelect]}
                        listName="label"
                        listValue="value"
                        selectBlockMargin={"0px 10px"}
                        selectPadding="3px 5px 3px 10px"
                        selectWidth={"90px"}
                        onChange={handleChange}
                    />

                    {/* MAX YEAR SELECT FILTER */}
                    <CvtSelect
                        selectBlockCss="flexStartCenter"
                        selectFontSize="12px"
                        selectPlaceHolder="Last year"
                        name="atCompletionSelectedLastYear"
                        value={atCompletionSelectedLastYear ? atCompletionSelectedLastYear : ""}
                        list={[{ value: "", label: "Last Year" }, ...atCompletionModeYearsSelect]}
                        listName="label"
                        listValue="value"
                        selectBlockMargin={"0px"}
                        selectPadding="3px 5px 3px 10px"
                        selectWidth={"90px"}
                        onChange={handleChange}
                    />
                </div>
            ) : null}

            {/* ADD Y AXIS */}
            <div
                className="bigComponentCommandBarBlock flexBetweenCenter"
                style={{
                    marginRight: "0px",
                    marginLeft: "10px",
                }}
            >
                {/* TITLE + NUMBER */}
                <div
                    className="bigComponentCommandBarBlockLegend"
                    style={{
                        minWidth: "0px",
                        marginRight: "5px",
                    }}
                >
                    Y axis :
                </div>

                {/* MINUS */}
                <CvtButton
                    color="white"
                    shape="noShape"
                    size="tiny"
                    margin="-10px -2px"
                    icon={secondYAxis ? "remove" : "add"}
                    iconFontSize="16px"
                    handleSubmit={onChangeYAxis}
                    handleSubmitOptions={false}
                />
            </div>
        </div>
    );
}
