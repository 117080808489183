// REACT
import { memo } from "react";
import GovScopeViewDetailsEvents from "../../../../04-Governance/01-Components/00-GovScope/GovScopeViewDetailsEvents";

// COMPONENTS
import RiskCardInfosComments from "./RiskCardInfosComponents/RiskCardInfosComments";
import RiskCardInfosDescription from "./RiskCardInfosComponents/RiskCardInfosDescription";

/////////////////////////////////
/// RISK CARD INFOS BODY ////////
/////////////////////////////////

export default memo(function RiskCardInfosBody({
    // DATA
    selectedOption,
    roItem,
    editingValue,
    commentsArray,
    eventsArray,
    editingCommentId,
    usersList,
    megaUsersIds,
    currentUser,
    canModifyRo,
    isReadOnlyMode,
    // FUNCTIONS
    handleInputChange,
    handleEditRoItem,
    handleEditComment,
    onDeleteComment,
    projectData,
}) {
    /////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ///////////////////////////////////////
    ////////////////////////////////////////////////////////////

    return (
        <div className={"cardDetailsBlockInfo"}>
            {/* DESCRIPTION */}
            {selectedOption === "description" ? (
                <RiskCardInfosDescription
                    descriptionValue={roItem ? roItem.description : ""}
                    editingValue={editingValue}
                    handleInputChange={handleInputChange}
                    handleEditRoItem={handleEditRoItem}
                    megaUsersIds={megaUsersIds}
                    currentUser={currentUser.username}
                    responsible={roItem?.responsible}
                    canModifyRo={canModifyRo}
                />
            ) : null}

            {/* COMMENTS */}
            {selectedOption === "comments" ? (
                <RiskCardInfosComments
                    commentsArray={commentsArray}
                    editingValue={editingValue}
                    editingCommentId={editingCommentId}
                    usersList={usersList}
                    megaUsersIds={megaUsersIds}
                    currentUser={currentUser}
                    handleEditComment={handleEditComment}
                    handleEditRoItem={handleEditRoItem}
                    onDeleteComment={onDeleteComment}
                    handleInputChange={handleInputChange}
                    //TODO NEED TO UPDATE ACCORDING TO PERMISSIONS LATER ON
                    isReadOnlyMode={isReadOnlyMode}
                />
            ) : null}

            {/* EVENTS */}
            {selectedOption === "events" ? (
                <GovScopeViewDetailsEvents
                    eventsArray={eventsArray}
                    usersList={usersList}
                    projectData={projectData}
                />
            ) : null}
            {/* {selectedOption === "events" ? <RiskCardInfosEvents eventsArray={eventsArray} /> : null} */}
        </div>
    );
});
