import _ from "lodash";
import { FUNCT_FIND_INDEX, FUNCT_FIND_INDEX_ARRAY } from "../../../00-Core/Standards";
import { v4 as uuid } from "uuid";

// GET ALL PROJECTS, ALONE OR INSIDE ORGANIZATION
export function FUNC_BOARD_GET_PROJECTS_SINGLE_AND_INSIDE_ORGANIZATION(
    projectList,
    singleProjects,
    organizations
) {
    let fullProjectList = [];

    // GET SELECTED PROJECTS DATA
    let projectsWithData = [];
    singleProjects.forEach((projectId) => {
        // GET PROJECT DATA
        let projectIndex = FUNCT_FIND_INDEX(projectList, "id", projectId);

        if (projectIndex !== -1) {
            projectsWithData.push(projectList[projectIndex]);
        }

        return null;
    });

    fullProjectList = [...projectsWithData];

    // LIST ALL PROJECT OF SELECTED ORGANIZATIONS
    organizations.forEach((orgId) => {
        // GET ORG PROJECTS
        let projects = FUNC_BOARD_GET_ORGANIZATION_PROJECTS(orgId, projectList);

        // ADD ORG PROJECTS TO FULL LIST
        fullProjectList = [...fullProjectList, ...projects];
    });

    return fullProjectList;
}

// GET PROJECTS USERS HAS NO ACCESS TO
export function FUNC_BOARD_GET_PROJECTS_USER_HAS_NO_ACCESS_TO(
    userId,
    selectedProjects,
    selectedOrganizations,
    projectList
) {
    // GET ALL PROJECTS LIST
    let fullProjectList = FUNC_BOARD_GET_PROJECTS_SINGLE_AND_INSIDE_ORGANIZATION(
        projectList,
        selectedProjects,
        selectedOrganizations
    );

    // CHECK EACH PROJECTS
    let noAccessProjects = fullProjectList.filter((projectToCheck) => {
        let usersWithAccess = [];

        // GET ALL USER IDS WITH ACCESS ON PROEJECT
        if (projectToCheck?.organizationMegaUsersList) {
            usersWithAccess = [...usersWithAccess, ...projectToCheck.organizationMegaUsersList];
        }
        if (projectToCheck?.projectMegaUsersList) {
            usersWithAccess = [...usersWithAccess, ...projectToCheck.projectMegaUsersList];
        }
        if (projectToCheck?.projectUsersList) {
            usersWithAccess = [...usersWithAccess, ...projectToCheck.projectUsersList];
        }

        // CHECK USER HAS ACCES IN THE PROJECT
        let userHasAccess = usersWithAccess?.includes(userId);

        return !userHasAccess;
    });

    return noAccessProjects;
}

// GET ALL PROJECTS DETAILS INSIDE ORGNANIZATION
export function FUNC_BOARD_GET_ORGANIZATION_PROJECTS(organizationId, projectList) {
    return projectList.filter(
        (project) => project.coreDataOrganizationCoreDataProjectId === organizationId
    );
}

// FILTER PROJECT LIST BASED ON SEARCH TEXT
export function FUNC_BOARD_FILTER_PROJECT_LIST(projectList, organizationList, searchQuery) {
    let tmpFilteredProjects = [...projectList];

    // FILTER ON SEARCH BAR WORDS
    if (searchQuery !== "") {
        //CREATE MAP OF SEARCH
        const searchQueryWords = searchQuery
            .split(" ")
            .map((word) => word.toLowerCase())
            .filter((word) => word !== " ");

        //GET FILTERED PROJECTS
        tmpFilteredProjects = tmpFilteredProjects.filter((project) => {
            // GET ORGANIZATION NAME
            let organizationIndex = FUNCT_FIND_INDEX(
                organizationList,
                "id",
                project.coreDataOrganizationCoreDataProjectId
            );
            let organizationName = organizationList[organizationIndex]?.name;

            if (organizationName) {
                organizationName = organizationName.toLowerCase();
            }

            //INIT
            let name = project.name.toLowerCase() ?? "";

            //RETURN
            return searchQueryWords.every((searchQueryWord) => {
                // IF ORANIZATION NAME, RETURN ALL PROJECTS
                if (organizationName && organizationName.indexOf(searchQueryWord) !== -1) {
                    return true;
                }

                // IF PROJECT, RETURN ONLY PROJECT
                if (name.indexOf(searchQueryWord) !== -1) {
                    return true;
                }

                return false;
            });
        });
    }

    return tmpFilteredProjects;
}

// GET PORTFOLIO USERS COUNT
export function FUNC_BOARD_COUNT_USERS(viewers, editors) {
    return FUNC_BOARD_GET_ALL_PORTFOLIO_USERS(viewers, editors).length;
}

// GET ALL PORTFOLIO USERS IDS
export function FUNC_BOARD_GET_ALL_PORTFOLIO_USERS(viewers, editors) {
    return [...viewers, ...editors];
}

// GET USER PERMISSIONS ON PORTFOLIO
export function FUNC_BOARD_GET_USER_ROLE(userId, portfolio) {
    if (userId === portfolio.createdBy) {
        return "owner";
    }

    // CHECK IN EACH ARRAYS TO SEE IF USER IS VIEWER OR EDITOR
    let userIndexEditorFound = FUNCT_FIND_INDEX_ARRAY(portfolio.itemEditors, userId);

    if (userIndexEditorFound !== -1) {
        return "editor";
    }

    return "viewer";
}

// UPDATE LAST ACTIVITIES ARRAY IN STATE AND LOCAL STORAGE
export function FUNC_BOARD_UPDATE_LAST_ACTIVITIES(type, data, dispatcher) {
    let dataToAdd = {
        id: uuid(),
        type: type,
        data: data,
    };

    // UPDATE LOCAL STORAGE
    FUNC_BOARD_ADD_LAST_ACTIVITIES_TO_LOCAL_STORAGE(dataToAdd);

    // UPDATE STATE
    dispatcher({
        type: "SET_ADD_LAST_ACTIVITY",
        newActivity: dataToAdd,
    });
}

// ADD NEW ACTIVITY, AND RETURN AN UPDATED ARRAY OF ACTIVITIES
export function FUNC_BOARD_GET_UPDATED_LAST_ACTIVITIES(currentData, newActivity) {
    // IF NOT NULL, THERE IS ALREADY SOME DATA
    if (currentData) {
        let updatedLastActivities = _.cloneDeep(currentData);

        // CHECK THE LAST ACTIVITY IS NOT ALREADY AT TOP
        if (JSON.stringify(newActivity) !== JSON.stringify(currentData[0])) {
            // ADD NEW ELEMENT AT BEGGINING
            updatedLastActivities = [newActivity, ...updatedLastActivities];

            if (updatedLastActivities.length > 6) {
                // IF MORE THAN 5 ELEMENT, DELETE LAST ONE
                updatedLastActivities.pop();
            }
        }

        return updatedLastActivities;
    }

    // SIMPLY ADD THE DATA
    return [newActivity];
}

// ADD LAST ACTIVITY TO LOCAL STORAGE
export function FUNC_BOARD_ADD_LAST_ACTIVITIES_TO_LOCAL_STORAGE(dataToAdd) {
    // GET LAST ACTIVITIES FROM LOCAL STORAGE
    let lastActivities = localStorage.getItem("telescope-boardLastActivities");

    if (lastActivities) {
        lastActivities = JSON.parse(lastActivities);
    }

    lastActivities = FUNC_BOARD_GET_UPDATED_LAST_ACTIVITIES(lastActivities, dataToAdd);

    // ADD THE DATA TO LOCAL STORAGE
    localStorage.setItem("telescope-boardLastActivities", JSON.stringify(lastActivities));
}
