// LIBS
import { memo, useCallback, useEffect, useMemo, useReducer } from "react";

// COMPONENTS
import BoardLastActivities from "./01-Components/BoardLastActivities";
import BoardNextReviews from "./01-Components/BoardNextReviews";
import PortfolioCreateEdit from "./01-Components/00-Portfolio/PortfolioCreateEdit";
import BoardPortfoliosList from "./01-Components/BoardPortfoliosList";
import BoardProjectsList from "./01-Components/BoardProjectsList";
import BoardTopActions from "./01-Components/BoardTopActions";

// REDUCER
import boardReducer, { initialState } from "./00-Helpers/BoardDispatcher";
import _ from "lodash";
import {
  FUNCT_FIND_INDEX,
  FUNCT_FIND_INDEX_ARRAY,
  FUNC_CHECK_DATE2_IS_AFTER_DATE1,
} from "../../00-Core/Standards";
import { APP_TOAST_TXT, TODAY } from "../../00-Core/Constants";
import { getProjectData } from "../00-App/02-Backend/AppBackendProjectData";
import { useHistory } from "react-router-dom";
import {
  FUNC_BOARD_GET_PROJECTS_SINGLE_AND_INSIDE_ORGANIZATION,
  FUNC_BOARD_UPDATE_LAST_ACTIVITIES,
} from "./00-Helpers/BoardFunctions";
import { API, graphqlOperation } from "aws-amplify";
import { listTelescopeDataGovReviews } from "../../graphql/queries";
import { authCleanUserDataCookie } from "../00-App/01-Auth/00-Functions/AuthSignOut";
import { multipleMutateGraphql } from "../00-App/02-Backend/AppBackendCommon";
import { updateTelescopeDataPortfolio } from "../../graphql/mutations";

// ROOT BOARD COMPONENT
export default memo(function Board({
  portfolios,
  myActions,
  nextReviews,
  selectedPortfolioId,
  selectedProjectId,
  projectData,
  showProjetListView,
  currentUserId,
  projectList,
  organizationList,
  userList,
  isMegaUser,
  appDispatcher,
  currentUser,
}) {
  /////////////////////////////////////////////////////
  // STATE ////////////////////////////////////////////
  /////////////////////////////////////////////////////
  const [state, boardDispatcher] = useReducer(boardReducer, initialState);

  /////////////////////////////////////////////////////
  // HISTORY //////////////////////////////////////////
  /////////////////////////////////////////////////////
  let history = useHistory();

  /////////////////////////////////////////////////////
  // CALLBACKS ////////////////////////////////////////
  /////////////////////////////////////////////////////

  // OPEN PROJECT WBS PAGE ---------------------------
  const handleOpenProjectWbs = useCallback(
    async (organizationId, projectId) => {
      //OPEN PORTFOLIO
      if (selectedProjectId === projectId) {
        //MOVE TO PORTFOLIO
        history.push("/controls");

        //LOAD DATA
      } else {
        //LAUNCH LOADING
        boardDispatcher({
          type: "SET_LOADER",
          name: "projectLoadingId",
          status: projectId,
        });

        // ADD TO LAST ACTIVITIES
        let lastActivityData = {
          id: projectId,
          organizationId: organizationId,
        };

        // ADD ACTION TO LAST ACTIVITIES
        FUNC_BOARD_UPDATE_LAST_ACTIVITIES(
          "project",
          lastActivityData,
          boardDispatcher
        );

        //OK GET PROJECT DATA
        await getProjectData([projectId], null, [
          {
            organizationId: organizationId,
            projectId: projectId,
          },
        ])
          .then((result) => {
            //LAUNCH APP DISPATCHER
            appDispatcher({
              type: "SET_INIT_PROJECT_DATA",
              projectData: result.projectData,
              projectId: projectId,
              organizationId: organizationId,
              wbsList: result.wbsList,
              wbsSettings: result.wbsSettings,
            });

            //MOVE TO PORTFOLIO
            history.push("/controls");

            //ISSUE
          })
          .catch((err) => {
            console.log(err);

            //DISPLAY ISSUE
            appDispatcher({
              type: "SET_GLOBAL_STATE",
              options: {
                source: "appToast",
                object: {
                  display: true,
                  color: "danger",
                  message: APP_TOAST_TXT.issueOpenProject,
                },
              },
            });
          })
          .finally(() => {
            // DISABLE LOADER IN ANY CASES
            boardDispatcher({
              type: "SET_LOADER",
              name: "projectLoadingId",
              status: null,
            });
          });
      }
    },
    [appDispatcher, boardDispatcher, history, selectedProjectId]
  );

  // REDIRECT TO ORGANIZATION WBS VIEW
  const openOrganizationWBS = useCallback(
    async (organization, organizationProjects) => {
      //IF PORTFOLIO ALL READY SELECTED JUST MOVE TO PORTFOLIO VIEW
      if (organization.id === selectedPortfolioId) {
        //SWITCH TO PORTFOLIO
        history.push("/controls");

        //LOAD DATA
      } else {
        //IF NO PROJECT LINK TO PORTFOLIO
        if (organizationProjects.length === 0) {
          //DISPLAY ISSUE
          appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
              source: "appToast",
              object: {
                display: true,
                color: "danger",
                message: APP_TOAST_TXT.noProjectInportfolio,
              },
            },
          });

          //OK OPEN
        } else {
          //PASS LOADER
          boardDispatcher({
            type: "SET_LOADER",
            name: "orgaLoadingId",
            status: organization.id,
          });

          //CLONE PROJECTS LIST
          let portfolioProjects = _.cloneDeep(organizationProjects);

          //PROJECTS LIST WITH ORGANIZATION ID FOR WBS CALL
          const projects = portfolioProjects.map((item) => {
            return {
              projectId: item.id,
              organizationId: item.coreDataOrganizationCoreDataProjectId,
            };
          });

          //ONLY PROJECT IDS
          const projectIds = projects.map((item) => item.projectId);

          // ADD ITEM EDITORS (It's the megausers list)
          organization.itemEditors = organization.organizationMegaUsersList;

          //CALL DATA
          await getProjectData(projectIds, organization.id, projects)
            .then((res) => {
              //APP STATE
              appDispatcher({
                type: "SET_LOAD_PORTFOLIO_WBS_DATA",
                portfolio: organization,
                projectData: res.projectData,
                wbsList: res.wbsList,
                wbsSettings: res.wbsSettings,
                portfolioProjects: portfolioProjects,
              });

              // ADD PORTFOLIO OPENING TO LAST ACTIVITIES
              FUNC_BOARD_UPDATE_LAST_ACTIVITIES(
                "Organization",
                organization,
                boardDispatcher
              );

              //MOVE TO PORTFOLIO
              history.push("/controls");

              //ERROR
            })
            .catch((err) => {
              console.log(err);

              // DISPLAY TOAST ERROR
              appDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                  source: "appToast",
                  object: {
                    display: true,
                    color: "danger",
                    message: APP_TOAST_TXT.issueOpenProject,
                  },
                },
              });
            })
            .finally(() => {
              boardDispatcher({
                type: "SET_LOADER",
                name: "orgaLoadingId",
                status: null,
              });
            });
        }
      }
    },
    [appDispatcher, boardDispatcher, history, selectedPortfolioId]
  );

  // REDIRECT TO PORTFOLIO VIEW
  const openPortfolioWBS = useCallback(
    async (portfolio) => {
      //ALREADY OPENED SWITCH VIEW
      if (portfolio.id === selectedPortfolioId) {
        //MOVE TO PORTFOLIO
        history.push("/controls");

        //NOT OPEN SWITCH VIEW
      } else {
        //GET PROJECTS AND ORGANIZATON ID
        let selectedProjects = JSON.parse(portfolio.selectedProjects);
        let selectedOrganizations = JSON.parse(portfolio.selectedOrganizations);

        //IF NO PROJECT LINK TO PORTFOLIO
        if (
          selectedProjects.length === 0 &&
          selectedOrganizations.length === 0
        ) {
          //DISPLAY ISSUE
          appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
              source: "appToast",
              object: {
                display: true,
                color: "danger",
                message: APP_TOAST_TXT.noProjectInportfolio,
              },
            },
          });

          //OK OPEN
        } else {
          //PASS LOADER
          boardDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
              source: "loaders",
              object: {
                portfolioLoadingId: portfolio.id,
              },
            },
          });

          //GET GLOBAL PROJECT LIST FROM THE SELECTED PORTFOLIO
          const portfolioProjects =
            FUNC_BOARD_GET_PROJECTS_SINGLE_AND_INSIDE_ORGANIZATION(
              projectList,
              selectedProjects,
              selectedOrganizations
            );

          if (portfolioProjects.length > 0) {
            let k = portfolioProjects.length - 1;

            // LOOP TO CHECK IF PROJECT ACTUALLY EXISTS
            while (k--) {
              if (portfolioProjects[k]) {
                //GET INDEX
                let findIndex = FUNCT_FIND_INDEX(
                  projectList,
                  "id",
                  portfolioProjects[k].id
                );
                if (findIndex === -1) {
                  portfolioProjects.splice(k, 1);
                }
              } else {
                portfolioProjects.splice(k, 1);
              }
            }

            //PROJECTS LIST WITH ORGANIZATION ID FOR WBS CALL
            const projects = portfolioProjects.map((item) => {
              return {
                projectId: item.id,
                organizationId: item.coreDataOrganizationCoreDataProjectId,
              };
            });

            //ONLY PROJECT IDS
            const projectIds = projects.map((item) => item.projectId);

            //CALL DATA
            await getProjectData(projectIds, portfolio.id, projects)
              .then((res) => {
                // DISABLE LOADER IN ANY CASES
                boardDispatcher({
                  type: "SET_GLOBAL_STATE",
                  options: {
                    source: "loaders",
                    object: {
                      portfolioLoadingId: null,
                    },
                  },
                });

                //APP STATE
                appDispatcher({
                  type: "SET_LOAD_PORTFOLIO_WBS_DATA",
                  portfolio: portfolio,
                  projectData: res.projectData,
                  wbsList: res.wbsList,
                  wbsSettings: res.wbsSettings,
                  portfolioProjects: portfolioProjects,
                });

                // ADD TO LAST ACTIVITIES
                let lastActivityData = {
                  id: portfolio.id,
                };

                // ADD PORTFOLIO OPENING TO LAST ACTIVITIES
                FUNC_BOARD_UPDATE_LAST_ACTIVITIES(
                  "portfolio",
                  lastActivityData,
                  boardDispatcher
                );

                //MOVE TO PORTFOLIO
                history.push("/controls");

                //ERROR
              })
              .catch((err) => {
                console.log(err);

                // DISABLE LOADER IN ANY CASES
                boardDispatcher({
                  type: "SET_GLOBAL_STATE",
                  options: {
                    source: "loaders",
                    object: {
                      portfolioLoadingId: null,
                    },
                  },
                });

                // DISPLAY TOAST ERROR
                appDispatcher({
                  type: "SET_GLOBAL_STATE",
                  options: {
                    source: "appToast",
                    object: {
                      display: true,
                      color: "danger",
                      message: APP_TOAST_TXT.issueOpenProject,
                    },
                  },
                });
              });
          }
          // NO PROJECTS AVAILABLE
          else {
            //DISPLAY ISSUE
            appDispatcher({
              type: "SET_GLOBAL_STATE",
              options: {
                source: "appToast",
                object: {
                  display: true,
                  color: "danger",
                  message: APP_TOAST_TXT.noProjectInportfolio,
                },
              },
            });
            //PASS LOADER
            boardDispatcher({
              type: "SET_GLOBAL_STATE",
              options: {
                source: "loaders",
                object: {
                  portfolioLoadingId: null,
                },
              },
            });
          }
        }
      }
    },
    [appDispatcher, boardDispatcher, history, projectList, selectedPortfolioId]
  );

  // OPEN WBS ITEM
  const openWbsItem = useCallback(
    async (type, item) => {
      // CASE WE OPEN AN ACTION OR REVIEW
      if (type === "action" || type === "govReview") {
        // GET THE ITEM
        let organizationId = item.organizationId;
        let projectId = item.projectId;
        let wbsId = item.wbsId;

        // CHECK ITEM IS IN PROJECT DATA
        let findIndexItem = FUNCT_FIND_INDEX(projectData[type], "id", item.id);

        // WBS NAVIGATION OPTIONS TO OPEN ITEM DIRECTLY
        let wbsNavigationOptions = {
          goToItemId: item.id,
          goToDomain: type,
          // overrideWbsId: wbsId,
        };

        // IF NOT IN DATA, LOAD PROJECT DATA
        if (findIndexItem === -1) {
          // GET PROJECT DATA
          let projectData = await getProjectData([projectId], null, [
            {
              organizationId: organizationId,
              projectId: projectId,
            },
          ]);

          // IF GOV REVIEW, GET GOVERNANCE REVIEWS
          if (type === "govReview") {
            try {
              // GET FILTER OBJECT
              let filterObject;

              // IF PROJECT ELEMENT, THEN FETCH ALL DATA.
              if (projectId && projectId === wbsId) {
                filterObject = { projectId: { eq: projectId } };
              }
              // ELSE JUST FETCH SPECIFIC WBS BRANCH DATA
              else {
                filterObject = { wbsId: { eq: wbsId } };
              }

              // RESULT
              let result = await API.graphql(
                graphqlOperation(
                  // QUERY
                  listTelescopeDataGovReviews,
                  // FILTER AND LIMIT
                  {
                    limit: 10000,
                    filter: filterObject,
                  }
                )
              );

              // CLEAR COOKIES
              authCleanUserDataCookie();

              let items = result.data.listTelescopeDataGovReviews.items.map(
                (reviewItem) => ({
                  ...reviewItem,
                  startDate: new Date(reviewItem.startDate),
                  endDate: new Date(reviewItem.endDate),
                })
              );

              // ADD GOV REVIEWS TO PROJECT DATA
              projectData.projectData.govReview = _.cloneDeep(items);
            } catch (error) {
              console.log("Error fetching gov reviews...", error);
            }
          }

          //LAUNCH APP DISPATCHER
          appDispatcher({
            type: "SET_INIT_PROJECT_DATA",
            projectId: projectId,
            wbsId: wbsId,
            organizationId: organizationId,

            projectData: projectData.projectData,
            wbsSettings: projectData.wbsSettings,
            wbsList: projectData.wbsList,

            // DIRECT ACCESS TO ITEM
            wbsNavigationOptions: wbsNavigationOptions,

            keepRightComponent: selectedProjectId === projectId ? true : false,
          });
        } else {
          // DATA ALREADY LOADED, OPEN DIRECTLY THE ITEM
          appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
              source: "portfolioDisplay",
              object: {
                // DIRECT ACCESS TO ITEM
                wbsNavigationOptions: wbsNavigationOptions,
              },
            },
          });
        }

        //MOVE TO PORTFOLIO
        history.push("/controls");
      }
      // OPEN ORGANIZATION
      else if (type === "Organization") {
        // GET ORGANIZATION DATA
        let findIndexOrga = FUNCT_FIND_INDEX(organizationList, "id", item.id);
        if (findIndexOrga !== -1) {
          let orga = organizationList[findIndexOrga];

          // GET PROJECTS INSIDE ORGANIZATION
          let orgaProjects = projectList.filter(
            (project) =>
              project.coreDataOrganizationCoreDataProjectId === orga.id
          );

          // OPEN ORGANIZATION
          openOrganizationWBS(orga, orgaProjects);
        }
      }
      // OPEN PROJECT
      else if (type === "project") {
        handleOpenProjectWbs(item.organizationId, item.id);
      }
      // OPEN PORTFOLIO
      else if (type === "portfolio") {
        // GET PORTFOLIO DATA
        let portfolioIndex = FUNCT_FIND_INDEX(portfolios, "id", item.id);
        if (portfolioIndex !== -1) {
          let portfolio = portfolios[portfolioIndex];

          openPortfolioWBS(portfolio);
        }
      }
    },
    [
      appDispatcher,
      handleOpenProjectWbs,
      history,
      openOrganizationWBS,
      openPortfolioWBS,
      organizationList,
      portfolios,
      projectData,
      projectList,
      selectedProjectId,
    ]
  );

  /////////////////////////////////////////////////////
  // MEMO /////////////////////////////////////////////
  /////////////////////////////////////////////////////

  // REVIEWS ORDERED BY DATE, KEEP ONLY THE FUTURE
  const orderedFutureReviews = useMemo(() => {
    let copyReviews = _.cloneDeep(nextReviews);

    // REMOVE PASSED REVIEWS
    copyReviews = copyReviews.filter((review) => {
      if (!FUNC_CHECK_DATE2_IS_AFTER_DATE1(TODAY, new Date(review.startDate))) {
        return false;
      }
      return true;
    });

    // SORT DATES AND RETURN
    return copyReviews.sort(
      (a, b) => new Date(a.startDate) - new Date(b.startDate)
    );
  }, [nextReviews]);

  /////////////////////////////////////////////////////
  // USE EFFECTS //////////////////////////////////////
  /////////////////////////////////////////////////////

  //GET DATA
  useEffect(() => {
    // GET LAST ACTIVITIES FROM LOCAL STORAGE
    let lastActivities = localStorage.getItem("telescope-boardLastActivities");

    if (lastActivities) {
      boardDispatcher({
        type: "SET_SIMPLE_STATE",
        key: "lastActivities",
        value: JSON.parse(lastActivities),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CLEAN PORTFOLIO MISSING PROJECTS
  useEffect(() => {
    if (
      !state.hasCheckedDeletedProjects &&
      projectList &&
      portfolios &&
      portfolios.length > 0 &&
      projectList.length > 0
    ) {
      // INIT NEW PORTFOLIOS FOR APP STATE
      let newPortfolios = _.cloneDeep(portfolios);

      // INIT GRAPHQL ARRAY OF PORTFOLIOS TO UPDATE
      let graphqlUpdatePortfolios = [];

      try {
        const userProjectIds = projectList.map((project) => project.id);
        let needsUpdateState = false;
        // LOOP EACH PORTFOLIO SELECTED PROJECTS TO CHECK IF THAT PROJECT EXISTS, IF NOT, THEN REMOVE AND ADD PORTFOLIO TO THE GRAPHQL UPDATE PORTFOLIOS ARRAY
        for (let i = 0, len = newPortfolios.length; i < len; i++) {
          // INIT NEW SELECTED PROJECTS FOR APP STATE
          let newSelectedProjects = [];
          // INIT NEW SELECTED PROJECT FOR GRAPHQL
          let newSelectedProjectsGraphql = [];

          // PARSED PROJECTS
          const parsedProjects = JSON.parse(newPortfolios[i].selectedProjects);

          // LOOP EACH PROJECT ID
          for (const selectedProjectId of parsedProjects) {
            const findIndex = FUNCT_FIND_INDEX_ARRAY(
              userProjectIds,
              selectedProjectId
            );

            // IF FOUND PROJECT
            if (findIndex > -1) {
              newSelectedProjects.push(selectedProjectId);
              newSelectedProjectsGraphql.push(selectedProjectId);
            }
            // ELSE IF DIDN'T FIND AND ALSO IS NOT EDITOR OF THIS PORTFOLIO OR A MEGA USER, STILL PUSH TO THE GRAPHQL, OTHERWISE WILL CAUSE MUTATION ISSUE
            else if (
              findIndex === -1 &&
              !newPortfolios[i].itemEditors?.includes(currentUser.username) &&
              !isMegaUser
            ) {
              newSelectedProjectsGraphql.push(selectedProjectId);
            }
          }

          // IF NOT SAME LENGTH, THEN NEED TO UPDATE APP STATE
          if (parsedProjects.length !== newSelectedProjects.length) {
            const stringifiedNewProjects = JSON.stringify(newSelectedProjects);
            // UPDATE THE NEW PORTFOLIOS
            newPortfolios[i].selectedProjects = stringifiedNewProjects;

            needsUpdateState = true;
          }

          // IF NOT SAME LENGTH, THEN NEED TO UPDATE BACK-END
          if (parsedProjects.length !== newSelectedProjectsGraphql.length) {
            const stringifiedNewProjects = JSON.stringify(
              newSelectedProjectsGraphql
            );
            graphqlUpdatePortfolios.push({
              id: newPortfolios[i].id,
              selectedProjects: stringifiedNewProjects,
            });
          }
        }

        // IF ANY UPDATE
        if (graphqlUpdatePortfolios.length > 0) {
          // UPDATE BACKEND
          multipleMutateGraphql(
            updateTelescopeDataPortfolio,
            graphqlUpdatePortfolios,
            appDispatcher
          );
        }

        if (needsUpdateState) {
          // UPDATE APP STATE
          appDispatcher({
            type: "SET_GLOBAL_STATE",
            options: {
              source: "portfolioSelection",
              object: {
                portfolios: newPortfolios,
              },
            },
          });
        }

        // MAKE THE hasCheckedDeletedProjects TRUE
        boardDispatcher({
          type: "SET_SIMPLE_STATE",
          key: "hasCheckedDeletedProjects",
          value: true,
        });
      } catch (error) {
        console.log("Error checking for deleted projects...", error);
      }
    }
  }, [
    appDispatcher,
    currentUser.username,
    isMegaUser,
    portfolios,
    projectList,
    state.hasCheckedDeletedProjects,
  ]);

  // REMOVE LOADING
  useEffect(() => {
    if (
      state.loaders.portfolioLoadingId &&
      selectedPortfolioId &&
      state.loaders.portfolioLoadingId === selectedPortfolioId
    ) {
      //PASS LOADER
      boardDispatcher({
        type: "SET_GLOBAL_STATE",
        options: {
          source: "loaders",
          object: {
            portfolioLoadingId: null,
          },
        },
      });
    }
  }, [
    selectedPortfolioId,
    state.loaders.portfolioLoadingId,
    state.portfolioLoadingId,
  ]);

  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////

  // RETURN PORTFOLIO CREATE MODE
  if (state.isCreatePortfolio || state.isEditPortfolio) {
    return (
      <PortfolioCreateEdit
        portfolios={portfolios}
        portfolioEdit={state.portfolioEdit}
        portfolioEditUpdateTracker={state.portfolioEditUpdateTracker}
        portfolioSavingLoader={state.loaders.portfolioSaving}
        portfolioDeletingLoader={state.loaders.portfolioDeleting}
        portfolioHasChanged={state.portfolioHasChanged}
        isEditingRoles={state.isEditingRoles}
        isAddingUsers={state.isAddingUsers}
        projectList={projectList}
        organizationList={organizationList}
        draggingType={state.portfolioEditDragDropDraggingType}
        dragDropActive={state.portfolioEditDragDropActive}
        currentUserId={currentUserId}
        usersList={userList}
        isEdit={state.isEditPortfolio}
        boardDispatcher={boardDispatcher}
        appDispatcher={appDispatcher}
      />
    );
  }

  // RETURN BOARD
  return (
    <section
      id="structureContent"
      className="flexColStartStart"
      style={{ overflowY: "auto", padding: "20px" }}
    >
      {/* FOCUS OF THE DAY */}
      <span className="boardSectionTitle">Focus For The Day</span>

      {/* FIRST ROW : LAST ACTIVITIES, ACTIONS, REVIEWS */}
      <div
        className="flexStartStart"
        style={{
          width: "100%",
          marginBottom: "20px",
          flexWrap: "wrap",
          gap: "40px 20px",
        }}
      >
        {/* LAST ACTIVITIES */}
        <BoardLastActivities
          lastActivities={state.lastActivities}
          portfolios={portfolios}
          projects={projectList}
          openWbsItem={openWbsItem}
        />

        {/* TOP ACTIONS */}
        <BoardTopActions
          loader={state.loaders.actions}
          actions={myActions}
          listedByProject={state.actionsListedByProject}
          projectList={projectList}
          organizationList={organizationList}
          openWbsItem={openWbsItem}
          boardDispatcher={boardDispatcher}
        />

        {/* NEXT REVIEWS */}
        <BoardNextReviews
          reviews={orderedFutureReviews}
          loader={state.loaders.reviews}
          openWbsItem={openWbsItem}
          boardDispatcher={boardDispatcher}
        />
      </div>

      {/* BROWSING */}
      <span className="boardSectionTitle">Browsing</span>

      {/* SECOND ROW : PORTFOLIO */}
      <div
        className="flexStartStart"
        style={{
          width: "100%",
          flexWrap: "wrap",
          gap: "40px 20px",
          marginBottom: "20px",
        }}
      >
        {/* PORTFOLIO / PROJECT LIST */}
        {!showProjetListView ? (
          <BoardPortfoliosList
            portfolios={portfolios}
            selectedPortfolioId={selectedPortfolioId}
            portfolioLoading={state.loaders.portfolioLoading}
            projectList={projectList}
            currentUserId={currentUserId}
            isMegaUser={isMegaUser}
            portfoliosShowClosed={state.portfoliosShowClosed}
            portfolioLoadingId={state.loaders.portfolioLoadingId}
            openPortfolioWBS={openPortfolioWBS}
            boardDispatcher={boardDispatcher}
            appDispatcher={appDispatcher}
          />
        ) : (
          <BoardProjectsList
            projectList={projectList}
            selectedPortfolioId={selectedPortfolioId}
            selectedProjectId={selectedProjectId}
            organizationList={organizationList}
            userList={userList}
            projectLoadingId={state.loaders.projectLoadingId}
            orgaLoadingId={state.loaders.orgaLoadingId}
            handleOpenProjectWbs={handleOpenProjectWbs}
            openOrganizationWBS={openOrganizationWBS}
            boardDispatcher={boardDispatcher}
            appDispatcher={appDispatcher}
          />
        )}
      </div>
    </section>
  );
});
