//IMPORT
import { Auth } from 'aws-amplify';
import { 
  listTelescopeDataPortfolios as ListTelescopeDataPortfolios
} from '../../../../graphql/queries'
import {getEnvUsers, getActionsByResponsible, getUserGovReviews} from '../../02-Backend/AppBackendUser'
import { getGraphql} from '../../02-Backend/AppBackendCommon'
import { getOrganizations, getUserProjects } from '../../02-Backend/AppBackendCore';
import {authCleanUserDataCookie, signOut} from './AuthSignOut'

////////////////////////////////////////////////////////////////////////////
/// IF USER SIGN IN OK UPDATE FRONT ////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export async function nextSignIn(
  user,
  appDispatcher
){

  //UPDATE FRONT
  appDispatcher({
    type:"SET_GLOBAL_STATE",
    options:{
      source: "currentUser",
      object: {
        user: user,
        refreshLoading: false,
        loggedIn: true,
        signUp: false,
        confirmSignUp:false,
        needVerifyEmail:false,
        needNewPassword: false,
        needMfaCode: false,
        needMfaSetUp: false,
        resetPassword : false,
        displayTermsValidation: false,
        errorMessage: false,
        username: user.username,
        name: user.attributes.name,
        loading: true,
      }
    }
  })

  //TRY TO GET CORE USER DATA
  try{

      //TEST IF USER INCLUDED AT LEAST IN A GROUP
      if(user.signInUserSession.accessToken.payload["cognito:groups"]){

        const authorizationToken = `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;
        
        //GET PROJECT, USERS, PORTFOLIOS, MY ACTIONS, MY NEXT REVIEWS
        const results = await Promise.all([
          getUserProjects(authorizationToken), 
          getEnvUsers(authorizationToken),
          getGraphql(ListTelescopeDataPortfolios),
          getActionsByResponsible(user.username),
          getUserGovReviews(user.username),
        ])

        //GET PROJECT LIST
        let projectsList = JSON.parse(results[0].body);

        // let usersList = []
        let usersList = results[1].Items

        // GET PORTFOLIOS LIST
        let portfolios = results[2].data.listTelescopeDataPortfolios.items ?? [];

        // GET MY ACTIONS BY RESPONSIBLE
        let myActions = results[3].data.actionByResponsible.items ?? [];
        
        // GET MY ACTIONS BY RESPONSIBLE
        const nextReviews = results[4].data.listTelescopeDataGovReviews.items ?? [];
        
        // CHECK ROLES ARRAYS ARE NOT NULL
        portfolios = portfolios.map((portfolio) => {
          if (portfolio.itemViewers === null) portfolio.itemViewers = [];
          if (portfolio.itemEditors === null)  portfolio.itemEditors = [];
          return portfolio;
      });

        //IF PROJECT NOT EMPTY GET ORAGNIZATION
        if(projectsList.length > 0 ){

          //IDENTIFY UNIQUE ORGANIZATION ID
          const organizationIds = [...new Set(projectsList.map(item => item.coreDataOrganizationCoreDataProjectId))]; 

          //GET CORE DATA
          await getOrganizations(organizationIds).then((result) => {
            
            //OK UPDATE TEMPLATE PROJECT ORGANIZATION
            appDispatcher({
              type:"SET_AWS_SIGNIN_INIT",
              coreProjects: projectsList,
              coreOrganization: JSON.parse(result.body),
              users: usersList,
              portfolios: portfolios,
              myActions: myActions,
              nextReviews: nextReviews
            })
            
          //ERROR FROM API REST
          }).catch(err => {

            //DISPLAY ERROR
            console.log("core organization error:", err);
            appDispatcher({
                type:"SET_GLOBAL_STATE",
                options:{
                  source: "currentUser",
                  object: {
                    loading: false,
                    err: true
                  }
                }
            })
          });

        //UPDATE FRONT ONLY TEMPLATE
        }else{

          //UPDATE FRONT
          appDispatcher({
            type:"SET_AWS_SIGNIN_INIT_WITHOUT_PROJECT",
            users: usersList
          })
        }

      //PAS DE CHECK PROJECT ORGA
      }else{

        // let usersList = []
        let usersList = await getEnvUsers()

        //LAUNCH DISPATCHER
        appDispatcher({
          type:"SET_AWS_SIGNIN_INIT_WITHOUT_PROJECT",
          coreProjects: [],
          coreOrganization: [],
          users: usersList.Items
        })
      }

      //DELETE USER DATAT COOKIE
      authCleanUserDataCookie()

    //ERROR !
  }catch(err){
    console.log(err)

    //SIGN OUT
    signOut(
      appDispatcher,
      true
    )
  }
}