import { CvtSideBarButton } from "@mi-gso/cvt";
import React, { memo, useCallback, useMemo, useState } from "react";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../00-Wbs/00-Helpers/WbsConstants";
import WbsElementBigSideBar from "../00-Wbs/01-Components/WbsElementBigSideBar";

//////////////////////////////////////////////////////////////
/// ACTION SIDE BAR COMPONENT ////////////////////////////////
//////////////////////////////////////////////////////////////

export default memo(
    function ActionSideBar({
        viewMode,
        onAddEditClick,
        onDeleteSideBar,
        onDuplicateSideBar,
        onBackSideBar,
        onLinkButtonClick,
        selected,
        actionView,
        isReadOnlyMode,
        isConsolidate,
        actionsDispatcher,
        handleMoveItemsClicked,
        onDownloadSideBar,
        canModifySelected,
         // isCurrentUserEditor,
        // currentUserName,
        // responsible,
    }) {

    //INIT
    const [confirm, setConfirm] = useState({
        value: false,
        type: "",
    });

    //HANDLE DELETE
    const handleAction = useCallback(
        (e, actionType, reset) => {
            e.preventDefault();

            //RESET
            if (reset) {
                setConfirm({
                    value: false,
                    type: "",
                });

                //ALREADY CONFIRM CAN DELETE
            } else if (confirm.value) {
                //TYPE
                switch (actionType) {
                    //DUPLICATE
                    case "duplicate":
                        onDuplicateSideBar(e);
                        break;

                    //DELETE
                    case "delete":
                        onDeleteSideBar(e);
                        break;

                    default:
                        break;
                }

                //UPDATE STATE
                setConfirm({
                    value: false,
                    type: "",
                });

                //NEED CONFIRM
            } else {
                setConfirm({
                    value: true,
                    type: actionType,
                });
            }
        },
        [confirm, onDeleteSideBar, onDuplicateSideBar]
    );

    //MANAGE CONSOLIDATE
    const onConsolidate = useCallback(() => {
        actionsDispatcher({
            type: "SET_STATE_KEY_VALUE",
            key: "isConsolidate",
            value: !isConsolidate,
        });
    }, [actionsDispatcher, isConsolidate]);

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // LINK BUTTON
    const linkButtonComponent = useMemo(() => {
        // INIT
        let selectedItems = [];

        // IF ACTION VIEW, USE IT AS SELECTED ITEM
        if (actionView) {
            selectedItems = [actionView];
        }
        // OTHERWISE, USE THE SELECTED ITEMS WITH CHECKBOXES
        else {
            selectedItems = selected;
        }

        if (selectedItems.length > 0) {
            return (
                <CvtSideBarButton
                    buttonClass={"flexCenterCenter buttonSideBar"}
                    id="tooltipLinkToOtherDomain"
                    icon="link"
                    fontSize="22px"
                    title={"Link to other domain"}
                    onClickFunction={onLinkButtonClick}
                />
            );
        }
        return null;
    }, [actionView, onLinkButtonClick, selected]);

    ////////////////////////
    /// COMPONENT RENDER ///
    ////////////////////////

    return (
        <WbsElementBigSideBar>
            {/* DISPLAY BUTTON RELATED VIEW */}
            {viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
            viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.edit ? (
                <React.Fragment>
                    {/* ADD BUTTON */}
                    {!isReadOnlyMode ? (
                        <CvtSideBarButton
                            buttonClass="flexCenterCenter buttonSideBar"
                            id="tooltipAddBig"
                            icon="add"
                            fontSize="22px"
                            title="Add Action"
                            onClickFunction={() => onAddEditClick()}
                        />
                    ) : null}

                    {/* Consolidate */}
                    <CvtSideBarButton
                        buttonClass={
                            "flexCenterCenter buttonSideBar " +
                            (isConsolidate ? "" : "buttonSideBarSelected")
                        }
                        id="tooltipConsolidate"
                        icon={isConsolidate ? "bookmarks" : "bookmark"}
                        fontSize="22px"
                        title={
                            isConsolidate
                                ? "Only this branche's Actions"
                                : "Included all children's Action"
                        }
                        onClickFunction={onConsolidate}
                    />

                    {!isReadOnlyMode ? (
                        <React.Fragment>
                            {/* EDIT */}
                            {selected.length === 1 && canModifySelected ? (
                                <CvtSideBarButton
                                    buttonClass="flexCenterCenter buttonSideBar"
                                    id="tooltipEditBig"
                                    icon="edit"
                                    fontSize="22px"
                                    title="Edit"
                                    onClickFunction={() => onAddEditClick(selected[0])}
                                />
                            ) : null}

                            {/* DUPLICATE */}
                            {selected.length > 0 ? (
                                <CvtSideBarButton
                                    buttonClass={
                                        "flexCenterCenter buttonSideBar " +
                                        (confirm.value && confirm.type === "duplicate"
                                            ? "buttonSideBarAlert"
                                            : "")
                                    }
                                    id="tooltipDuplicateBig"
                                    icon="copy_all"
                                    fontSize="22px"
                                    title={
                                        confirm.value && confirm.type === "duplicate"
                                            ? "Are you sure?"
                                            : "Duplicate"
                                    }
                                    onClickFunction={(e) => handleAction(e, "duplicate")}
                                    onBlur={(e) => handleAction(e, "duplicate", true)}
                                    onBlurConfirmation={
                                        confirm.value && confirm.type === "duplicate"
                                            ? confirm.value
                                            : false
                                    }
                                />
                            ) : null}

                            {/* DELETE */}
                            {selected.length > 0 && canModifySelected ? (
                                <CvtSideBarButton
                                    buttonClass={
                                        "flexCenterCenter buttonSideBar " +
                                        (confirm.value && confirm.type === "delete"
                                            ? "buttonSideBarConfirmDelete"
                                            : "")
                                    }
                                    id="tooltipDeleteBig"
                                    icon="delete"
                                    fontSize="22px"
                                    title={
                                        confirm.value && confirm.type === "delete"
                                            ? "Are you sure?"
                                            : "Delete"
                                    }
                                    onClickFunction={(e) => handleAction(e, "delete")}
                                    onBlur={(e) => handleAction(e, "delete", true)}
                                    onBlurConfirmation={
                                        confirm.value && confirm.type === "delete"
                                            ? confirm.value
                                            : false
                                    }
                                />
                            ) : null}

                            {/* MOVE */}
                            {selected.length > 0 && canModifySelected ? (
                                <CvtSideBarButton
                                    buttonClass={`flexCenterCenter buttonSideBar`}
                                    id="tooltipMoveBig"
                                    icon="move_down"
                                    fontSize="22px"
                                    title="Move Items"
                                    onClickFunction={handleMoveItemsClicked}
                                />
                            ) : null}

                            {selected.length > 0 ? (
                                <CvtSideBarButton
                                    buttonClass={`flexCenterCenter buttonSideBar`}
                                    id="tooltipDownloadBig"
                                    icon="download"
                                    fontSize="22px"
                                    title="Download Actions"
                                    onClickFunction={onDownloadSideBar}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {/* GO BACK */}
                    <CvtSideBarButton
                        buttonClass="flexCenterCenter buttonSideBar buttonSideBarBack"
                        id="tooltipBackBig"
                        icon="arrow_back"
                        fontSize="18px"
                        title="Back to list"
                        onClickFunction={onBackSideBar}
                    />

                    {/* NOT IF READ ONLY */}
                    {!isReadOnlyMode ? (
                        <React.Fragment>
                            {/* EDIT */}
                            {
                                canModifySelected ?
                                <CvtSideBarButton
                                    buttonClass="flexCenterCenter buttonSideBar"
                                    id="tooltipEditBig"
                                    icon="edit"
                                    fontSize="22px"
                                    title="Edit"
                                    onClickFunction={() => onAddEditClick(actionView)}
                                    cyId="buttonEditBig"
                                />
                            :null}
                           

                            {/* DUPPLICATE */}
                            <CvtSideBarButton
                                buttonClass={
                                    "flexCenterCenter buttonSideBar " +
                                    (confirm.value && confirm.type === "duplicate"
                                        ? "buttonSideBarAlert"
                                        : "")
                                }
                                id="tooltipDuplicateBig"
                                icon="copy_all"
                                fontSize="22px"
                                title={
                                    confirm.value && confirm.type === "duplicate"
                                        ? "Are you sure?"
                                        : "Duplicate"
                                }
                                onClickFunction={(e) => handleAction(e, "duplicate")}
                                onBlur={(e) => handleAction(e, "duplicate", true)}
                                onBlurConfirmation={
                                    confirm.value && confirm.type === "duplicate"
                                        ? confirm.value
                                        : false
                                }
                            />

                            {/* DELETE CURRENT ACTION */}
                            {canModifySelected  ? (
                                <CvtSideBarButton
                                    buttonClass={
                                        "flexCenterCenter buttonSideBar " +
                                        (confirm.value && confirm.type === "delete"
                                            ? "buttonSideBarConfirmDelete"
                                            : "")
                                    }
                                    id="tooltipDeleteBig"
                                    icon="delete"
                                    fontSize="22px"
                                    title={
                                        confirm.value && confirm.type === "delete"
                                            ? "Are you sure?"
                                            : "Delete"
                                    }
                                    onClickFunction={(e) => handleAction(e, "delete")}
                                    onBlur={(e) => handleAction(e, "delete", true)}
                                    onBlurConfirmation={
                                        confirm.value && confirm.type === "delete"
                                            ? confirm.value
                                            : false
                                    }
                                />
                            ) : null}

                            {/* MOVE BTN */}
                            {canModifySelected  ? (
                                <CvtSideBarButton
                                    buttonClass={`flexCenterCenter buttonSideBar`}
                                    id="tooltipMoveBig"
                                    icon="low_priority"
                                    fontSize="22px"
                                    title="Move Items"
                                    onClickFunction={handleMoveItemsClicked}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            )}

            {/* LINK TO OTHER DOMAIN BUTTON */}
            {canModifySelected && linkButtonComponent}
        </WbsElementBigSideBar>
    );
});
