import { CvtSelect, CvtSelectMultiple } from "@mi-gso/cvt";
import { memo } from "react";


export default memo(function FiltersTypeSelect({
    item,
    value,
    options,
    handleSelectChange,
}) {
   
    return (
        item.isMultiSelect ?
        (
            <CvtSelectMultiple 
                inputWidth="100%"
                options={options}
                placeholder={item.placeholder ?? null}
                onChange={(newValue) => handleSelectChange(newValue, item.subDomain)}
                canCreate={false}
                closeOnSelect={false}
                value={value}
            />
        )
        :
        (
            <CvtSelect 
                name={item.subDomain}
                value={value}
                listValue="value"
                listName="label"
                onChange={(newValue) => handleSelectChange(newValue, item.subDomain)}
                selectWidth="100%"
            />
        )
    );
});