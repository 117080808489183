import { CvtAvatar, CvtInputTextArea } from "@mi-gso/cvt";
import { useCallback, useMemo, useState } from "react";
import { APP_FORM_STYLE } from "../../../../../00-Core/Constants";
import {
  FUNC_GET_USER_INFO,
  FUNC_HANDLE_INPUT_CHANGE,
} from "../../../../../00-Core/Standards";
import TextUpdater from "../../../../../00-Core/TextUpdater";
import {
  GOV_SCOPE_CARD_KEY_BLOCK,
  GOV_SCOPE_DECISIONS,
  GOV_SCOPE_STATUS,
} from "../../00-Helpers/GovScopeConstants";
import GovScopeViewDetails from "./GovScopeViewDetails";

export default function GovScopeView({
  appDispatcher,
  currentUser,
  govItem,
  isReadOnlyMode,
  selectedViewModeContent,
  megaUsersId,
  onSaveAddEdit,
  projectData,
  securityGroup,
  usersList,
  wbsDispatcher,
  govDispatcher,
  selectedViewModeContentLeft,
  canModifyScopeChange,
}) {
  /////////////////////////////////////////////////////
  // STATE ////////////////////////////////////////////
  /////////////////////////////////////////////////////

  // LOCAL STATE
  const [state, setState] = useState({
    descriptionEdit: govItem.description ?? "",
    decisionEdit: govItem.decisionDetails ?? "",
    selected: selectedViewModeContent ? selectedViewModeContent : "comments",
    selectedLeft: selectedViewModeContentLeft
      ? selectedViewModeContentLeft
      : "description",
    newCommentText: "",
    editing: null,
  });
  

  /////////////////////////////////////////////////////
  // MEMO /////////////////////////////////////////////
  /////////////////////////////////////////////////////

  //GET RESPONSIBLE OBJECT
  const responsibleObject = useMemo(() => {
    if (govItem) {
      return FUNC_GET_USER_INFO(usersList, megaUsersId, govItem.responsible);
    }

    return null;
  }, [govItem, megaUsersId, usersList]);

  // GET OBJECT STATUS
  const govScopeStatusObject = useMemo(() => {
    return GOV_SCOPE_STATUS.find((item) => item.id === govItem.status);
  }, [govItem.status]);

  // GET DECISION
  const govScopeDecisionObject = useMemo(() => {
    return GOV_SCOPE_DECISIONS.find((item) => item.id === govItem.decision);
  }, [govItem.decision]);

  /////////////////////////////////////////////////////
  // FUNCTIONS ////////////////////////////////////////
  /////////////////////////////////////////////////////

  // UPDATE GOV SCOPE ITEM
  const handleUpdate = useCallback(
    (e, key, value) => {

      //PREPARE MUTATION OBJECT
      let mutationObject = {
        govItemType: "scope",
        id: govItem.id,
        [key]: value,
      };

      // SAVE DATA
      onSaveAddEdit(e, mutationObject, false);

      // RESET TEXT INPUT
      setState((prevState) => ({
        ...prevState,
        newCommentText: "",
        editing: null,
        // descriptionEdit: '',
        // decisionEdit: '',
      }));
    },
    [govItem.id, onSaveAddEdit]
  );

  // HANDLE INPUT CHANGE FOR COMMENTS
  const handleInputChange = useCallback((e) => {
    FUNC_HANDLE_INPUT_CHANGE(e, setState);
  }, []);

  //SELECT CHAPTER ON DETAILS BLOCK -------------------------------------------------
  const handleSelectOption = useCallback(
    (e, newSelected, isLeft) => {
      e.preventDefault();
      const correctKey = isLeft ? "selectedLeft" : "selected";
      //ONLY IF DIFFERENT
      if (newSelected !== state[correctKey]) {
        //THIS STATE
        setState((prevState) => {
          return {
            ...prevState,
            [correctKey]: newSelected,
          };
        });

        govDispatcher({
          type: "SET_STATE_KEY_VALUE",
          key: `selectedViewModeContent${isLeft ? "Left" : ""}`,
          value: newSelected,
        });
      }
    },
    [govDispatcher, state]
  );

  //EDIT A COMMENT ------------------------------------------------------------------ OK
  const handleEdit = useCallback(
    (e, options) => {
      // UPDATE ACTION CARD STATE
      setState((prevState) => {
        //INIT VALUE
        let editing =
          prevState.editing === options.commentId ? null : options.commentId;

        //FOCUS ON INPUT
        if (editing) {
          const inputEdition = document.getElementsByClassName(
            "cardDetailsCommentInput"
          );
          inputEdition[0].focus();
        }

        //RETURN
        return {
          ...prevState,
          editing: editing,
          newCommentText:
            state.editing === options.commentId ? "" : options.text,
        };
      });
    },
    [state.editing]
  );

  //DELETE SELECTED COMMENT ----------------------------------------------- OK
  const onDeleteComment = useCallback(
    (e, commentId) => {
      e.preventDefault();

      //MANAGE NEW COMMENTS ARRAY
      let oldComments = JSON.parse(govItem.comments);
      let commentIndex = oldComments.findIndex(
        (comment) => comment.id === commentId
      );
      oldComments.splice(commentIndex, 1);

      //PREPARE MUTATION OBJECT
      let mutationObject = {
        id: govItem.id,
        govItemType: "scope",
        comments: JSON.stringify(oldComments),
      };

      onSaveAddEdit(e, mutationObject, false);
    },
    [govItem.comments, govItem.id, onSaveAddEdit]
  );

  /////////////////////////////////////////////////////
  // RENDER ///////////////////////////////////////////
  /////////////////////////////////////////////////////
  return (
    <div
      className="flexStartStart"
      style={{
        width: "100%",
        height: "100%",
      }}
      key={`gov_scope_view_${state.propKey}`}
    >
      <div className="actionCardInfos flexColStartStart">

        {/* TITLE */}
        <div className="flexStartCenter" style={{width:"100%"}}>
          {/* USER AVATAR */}
          <CvtAvatar
            value={responsibleObject}
            radius={15}
            regularTooltip={true}
            tooltipTxt={responsibleObject?.label}
          />

          {/* GOVERNANCE TITLE */}
          <TextUpdater
            item={{
              id: govItem ? govItem.id : null,
              name: govItem ? govItem.title : null,
            }}
            viewModeCss="actionCardInfosTitle"
            inputModeCss="actionCardInfosTitleEdition"
            onSave={handleUpdate}
            onSaveKey="title"
            doubleClick={true}
            isReadOnlyMode={isReadOnlyMode}
          />

          {/* TAGS */}
          <div className="flexStartStart">
            {/* STATUS TAG */}
            <div
              className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
              style={{
                backgroundColor:
                  govScopeStatusObject?.backgroundColor ?? "grey",
                marginLeft: "10px",
                marginTop: "1px",
                cursor: "default",
                padding: "3px 10px",
              }}
            >
              {/* NAME */}
              <span>
                {govScopeStatusObject?.label.toUpperCase() ?? "Unknown"}
              </span>
            </div>
            {/* DECISION TAG */}
            {govScopeDecisionObject ? (
              <div
                className="actionsTableLegendTag flexStartCenter actionsTableLegendTagNoHover"
                style={{
                  backgroundColor: govScopeDecisionObject.backgroundColor,
                  marginLeft: "10px",
                  padding: "2px 10px",
                  cursor: "default",
                }}
              >
                <div
                  className="material-icons"
                  style={{
                    fontSize: "20px",
                    margin: "0px 8px 0px 0px",
                    color: "white",
                  }}
                >
                  gavel
                </div>

                {/* NAME */}
                <span>{govScopeDecisionObject.label.toUpperCase()}</span>
              </div>
            ) : null}
          </div>
        </div>

        {/* VIEW MODE CONTENT */}
        <div
          className="flexStartStart"
          style={{
            width: "100%",
            gap: "20px",
            height: "calc(100% - 40px)",
          }}
        >
          {/* DESCRIPTION + DECISION */}
          <div className="actionCardDetails flexColStartStart" style={{width: '100%', margin: '10px 0px'}}>
            {/* LIST TO SELECT OPTIONS */}
            <ul
              className="nav nav-tabs"
              style={{
                position: "sticky",
                top: 0,
                width: "100%",
                borderTop: "none",
                borderBottom: "none",
              }}
            >
              {GOV_SCOPE_CARD_KEY_BLOCK.map((item, index) => {
                //RENDER
                return (
                  <li key={"detailTab-" + index} className="nav-item ">
                    <a
                      className={
                        "nav-link bigComponentNavItem flexStartCenter " +
                        (state.selectedLeft === item.key ? "active" : "")
                      }
                      href="/#"
                      onClick={(e) => handleSelectOption(e, item.key, true)}
                    >
                      {/* CHAPTER NAME */}
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>

            {/* DISPLAY CONTENT */}
            <div className="cardDetailsBlockInfo">
              <div style={{ width: "100%", height: "100%" }}>
                {/* ENRICH EDITION */}
                <CvtInputTextArea
                  key={`card_details_block_info_${state.selectedLeft}`}
                  type="richTextArea"
                  placeholder={`Click here to add a ${state.selectedLeft}`}
                  inputBlockCss="flexColStartStart modalOptionSubBlock"
                  inputPadding="0px"
                  inputBlockHeight="100%"
                  inputCss={"form-control modalInputLight "}
                  inputHeight="80px"
                  inputFontSize={APP_FORM_STYLE.fontSize}
                  inputBlockPadding="0px"
                  inputBlockMargin="0px"
                  inputWidthFull={true}
                  name={`${state.selectedLeft}Edit`}
                  editingValue={state[`$${state.selectedLeft}Edit`]}
                  value={
                    govItem[
                      state.selectedLeft === "description"
                        ? "description"
                        : "decisionDetails"
                    ] ?? ""
                  }
                  onChange={(e) => {
                    FUNC_HANDLE_INPUT_CHANGE(e, setState);
                  }}
                  onBlur={(e) =>
                    handleUpdate(
                      e,
                      state.selectedLeft === "decision"
                        ? "decisionDetails"
                        : "description",
                      state[`${state.selectedLeft}Edit`]
                    )
                  }
                  enrichTitleCss="enrichTextTilteSideBar"
                  enrichEditorCss="modalInputEnrichTxt"
                  isOnViewMode={isReadOnlyMode || !canModifyScopeChange}
                />
              </div>
            </div>
          </div>
        </div>

        
      </div>

      {/* COMMENTS / CONNECTION / EVENTS */}

        {/* DETAILS RIGHT SIDE / COMMENTS - CONNECTION - EVENT */}
        <GovScopeViewDetails
          govItem={govItem}
          comments={govItem.comments}
          events={govItem.events}
          editingCommentId={state.editing}
          newCommentText={state.newCommentText}
          selected={state.selected}
          handleSelectOption={handleSelectOption}
          handleUpdate={handleUpdate}
          handleEdit={handleEdit}
          handleInputChange={handleInputChange}
          onDeleteComment={onDeleteComment}
          usersList={usersList}
          securityGroup={securityGroup}
          megaUsersId={megaUsersId}
          currentUser={currentUser}
          projectData={projectData}
          isReadOnlyMode={isReadOnlyMode}
          appDispatcher={appDispatcher}
          wbsDispatcher={wbsDispatcher}
          govDispatcher={govDispatcher}
          canModifyScopeChange={canModifyScopeChange}
        />
    </div>
  );
}
