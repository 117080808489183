import _ from "lodash";
import React, { useMemo, useCallback, useReducer, useEffect } from "react";
import {
    FUNCT_FIND_INDEX,
    FUNCT_FIND_INDEX_ARRAY,
    // FUNC_ARRAY_TO_CHUNKS,
    FUNC_BUILD_DISPLAY_ID_STR,
    FUNC_GET_USER_INFO,
    FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING,
    FUNC_ZERO_FORMAT_TO_NUM,
} from "../../../00-Core/Standards";
import { WBS_BIG_COMPONENT_VIEW_MODE } from "../00-Wbs/00-Helpers/WbsConstants";
import { EDIT_SIDE_BAR_DATA_PREPARATION } from "../07-EditSideBar/00-Helpers/EditSideBarFunctions";
import {
    SCHEDULE_COMPONENT_EXPAND_TYPES,
    SCHEDULE_DISPLAY_ID_STR,
    SCHEDULE_EDIT_SIDEBAR_CONTENT,
    SCHEDULE_STATUS_KEYS,
    SCHEDULE_TYPE_KEYS,
} from "./00-Helpers/ScheduleConstants";
import { scheduleReducer } from "./00-Helpers/ScheduleDispatcher";
import { v4 as uuid } from "uuid";
import {
    createTelescopeDataAction as CreateTelescopeDataAction,
    createTelescopeDataSchedule as CreateTelescopeDataSchedule,
    deleteTelescopeDataSchedule,
    updateTelescopeDataAction,
    updateTelescopeDataAction as UpdateTelescopeDataAction,
    updateTelescopeDataGovReview,
    updateTelescopeDataSchedule as UpdateTelescopeDataSchedule,
} from "../../../graphql/mutations";
import {
    batchMutationGraphql,
    multipleMutateGraphql,
    multipleQueryMultipleMutationGraphql,
    mutateGraphql,
} from "../../00-App/02-Backend/AppBackendCommon";
import WbsElementBigStructure from "../00-Wbs/01-Components/WbsElementBigStructure";
import WbsElementStructureBody from "../00-Wbs/01-Components/WbsElementStructureBody";
import ScheduleLeftSideBar from "./ScheduleLeftSideBar";
import EditSideBar from "../07-EditSideBar/EditSideBar";
import ScheduleTable from "./01-Components/ScheduleTable";
import {
    DEFAULT_STATE_MOVE_MODAL_OPTIONS,
    TODAY,
    TODAY_PLUS_30D,
} from "../../../00-Core/Constants";
import {
    SCHEDULE_FUNC_GET_ITEM_STATUS_AND_DUEDATE,
    SCHEDULE_GET_DEFAULT_ITEM,
    SCHEDULE_GET_INITIAL_STATE,
} from "./00-Helpers/ScheduleFunctions";
import ScheduleChart from "./01-Components/ScheduleChart";
import { ScheduleItemCard } from "./01-Components/ScheduleItemCard";
import {
    ACTION_GROUP_ITEMS,
    ACTION_ITEM_DATA,
    ACTION_START_DISPLAY_ID,
    ACTION_STATUS_KEY,
    ACTION_STATUS_LIST,
} from "../01-Action/00-Helpers/ActionsConstants";
import {
    ACTION_FUNC_DELETE_ACTIONS_AND_LINKS,
    ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST,
    FUNC_ACTION_CREATE_LINK_OBJECT,
    FUNC_ACTION_DUPLICATE,
    FUNC_ACTION_IS_LATE,
    FUNC_ACTION_NEED_TO_ADD_EVENT,
} from "../01-Action/00-Helpers/ActionsFunctions";
import { RISK_OPP_CHANGE_ACTIONS_TYPES } from "../02-Risk/00-Helpers/RiskConstants";
import { ADD_ITEM_EVENT, FIND_OBJECT_ARRAY_ITEM, ItemEvent } from "@mi-gso/cvt";
import { WBS_ON_BACK_SIDEBAR } from "../00-Wbs/00-Helpers/WbsFunctions";
import { GET_MUTATION_TO_DELETE_SEVERAL_ITEMS } from "../04-Governance/00-Helpers/GovReviewFunctions";
import {
    FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND,
    MERGE_GOV_REVIEW_MUTATIONS,
} from "../04-Governance/00-Helpers/GovFunctions";

//////////////////////////////
/// SCHEDULE BIG COMPONENT ///
//////////////////////////////

export default function ScheduleBig({
    //DATA
    scheduleData,
    isReadOnlyMode,
    currentPath,
    biggestDisplayId,
    actionsData,
    biggestActionDisplayIdNum,
    governanceData,
    roData,
    projectData,

    //STRUCTURE
    wbsName,
    projectId,
    wbsId,
    selectedOrganizationId,

    //BIG COMPONENT
    bigComponentExpandFunction,
    bigComponentIsExpanded,
    handleBigComponent,
    fieldInformation,
    isReviewMode,
    wbsNavigationOptions,

    //USER
    usersList,
    megaUsersIds,
    currentUser,
    securityGroup,

    //DISPATCHER
    appDispatcher,
    wbsDispatcher,

    // IF PASSED SELECTED FROM ABOVE.
    moveModalOptionsItemsArray,
    // REFERENCE OBJECT HOLDING DATA TO SAVE IN BACK-END
    referenceCreateUpdateObject,

    updateTracker,

    selectedReviewItem,
}) {
    /////////////////////////////////////////////////////
    /// STATE ///////////////////////////////////////////
    /////////////////////////////////////////////////////

    // DEFAULT ACTION ITEM
    const defaultActionItem = useMemo(() => {
        return {
            // IDS
            id: null,
            organizationId: selectedOrganizationId,
            projectId: projectId,
            wbsId: wbsId,
            displayId: null,
            createdBy: currentUser.username,
            contributors: null,

            // INFO
            action: null,
            cancelledDate: null,
            description: "",
            events: null,
            dueDate: TODAY_PLUS_30D,
            createdAt: TODAY,
            createdOn: TODAY,
            responsible: currentUser.username,
            status: ACTION_STATUS_LIST[0].value,
            realisedDate: null,
            lastModified: null,
            lastModifiedBy: null,
            progression: null,
            comments: null,
            path: null,

            // CONNECTIONS

            // GOVERNANCE
            telescopeDataGovernanceTelescopeDataActionId: null,

            // R&O
            telescopeDataROTelescopeDataActionId: null,

            // SCHEDULE
            telescopeDataScheduleTelescopeDataActionId: null,

            // COST
            telescopeDataCostTelescopeDataActionId: null,
        };
    }, [currentUser.username, projectId, selectedOrganizationId, wbsId]);

    // COMPONENT STATE
    const [state, scheduleDispatcher] = useReducer(
        scheduleReducer,
        SCHEDULE_GET_INITIAL_STATE(isReviewMode, wbsNavigationOptions, scheduleData)
    );

    /////////////////////////////////////////////////////
    // MEMO /////////////////////////////////////////////
    /////////////////////////////////////////////////////

    // SCHEDULE WITH CALCULATED STATUS
    const schedulesWithStatusAndDueDate = useMemo(() => {
        if (updateTracker) {
            // SO ESLINT IS HAPPY
            // JUST TO TRIGGER THE MEMO IF DATA IS UPDATED
        }

        return scheduleData.map((scheduleItem) => {
            // CALCULATE STATUS AND DUE DATE
            return SCHEDULE_FUNC_GET_ITEM_STATUS_AND_DUEDATE(scheduleItem);
        });
    }, [scheduleData, updateTracker]);

    // FILTERED SCHEDULE
    const filteredSchedule = useMemo(
        () =>
            schedulesWithStatusAndDueDate.filter((item) => {
                // IF NOT CONSOLIDATED SELECT GOOD WBS
                if (!state.isConsolidate && wbsId !== item.wbsId) return false;

                // FILTER WITH STATUS TAGS
                if (
                    state.statusSearchInput &&
                    state.statusSearchInput.length > 0 &&
                    FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, item.status.value) === -1
                ) {
                    return false;
                }

                // DO NOT SHOW DONE IF NOT SELECTED
                if (
                    item.status.value === SCHEDULE_STATUS_KEYS.done &&
                    FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, SCHEDULE_STATUS_KEYS.done) ===
                        -1
                ) {
                    return false;
                }

                //SEARCH
                if (
                    state.textSearchInput &&
                    state.textSearchInput !== "" &&
                    item.name &&
                    !item.name.toUpperCase()?.includes(state.textSearchInput.toUpperCase())
                )
                    return false;

                //RETURN TRUE
                return true;
            }),
        [
            schedulesWithStatusAndDueDate,
            state.isConsolidate,
            state.statusSearchInput,
            state.textSearchInput,
            wbsId,
        ]
    );

    // CALCULATE MIN AND MAX YEARS FOR FULL GRAPH SELECTS
    const atCompletionModeYearsSelect = useMemo(() => {
        let minFound;
        let maxFound;
        let selectOptions = [];

        schedulesWithStatusAndDueDate.forEach((item) => {
            let allYears = [];

            // IF MIN AND MAX ARE NOT NULL, ADD THEM TO THE TEST
            if (minFound) {
                allYears.push(minFound);
            }
            if (maxFound) {
                allYears.push(maxFound);
            }
            // ADD ALL ITEM DATES
            allYears.push(
                ...[
                    item.baselineStart?.getFullYear(),
                    item.baselineFinish?.getFullYear(),
                    item.forecastStart?.getFullYear(),
                    item.forecastFinish?.getFullYear(),
                ]
            );

            // ADD ACTUAL DATES IF SET
            if (item.actualStart) {
                allYears.push(item.actualStart.getFullYear());
            }

            if (item.actualFinish) {
                allYears.push(item.actualFinish.getFullYear());
            }

            minFound = Math.min(...allYears);
            maxFound = Math.max(...allYears);
        });

        // BUILD SELECT OPTIONS WITH YEAR RANGE
        for (let y = minFound; y <= maxFound; y++) {
            selectOptions.push({
                label: y,
                value: y,
            });
        }

        return selectOptions;
    }, [schedulesWithStatusAndDueDate]);

    // GET FILTERED ACTIONS FOR VIEW MODE
    const filteredActions = useMemo(() => {
        if (updateTracker) {
            // JUST TO TRIGGER MEMO
        }

        // IF NOT IN VIEW MODE, DON'T DO ANYTHING
        if (state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view) return [];

        let actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(state.selectedItemView.actionsIds);

        // FILTER, SORT THE ACTIONS
        return actionsData
            .filter((actionItem) => {
                // IF ACTION IS NOT IN RO ITEMS RELATED
                if (!actionsIds?.includes(actionItem.id)) return false;

                //DONE - DO NOT DISPLAY DONE IF IS NOT ACTIVATED
                if (
                    actionItem.status === ACTION_STATUS_KEY.done &&
                    ((state.statusSearchInput &&
                        state.statusSearchInput.length > 0 &&
                        FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, actionItem.status) ===
                            -1) ||
                        !state.statusSearchInput ||
                        state.statusSearchInput.length === 0)
                )
                    return false;

                //STATUS
                if (
                    state.statusSearchInput &&
                    state.statusSearchInput.length > 0 &&
                    FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, actionItem.status) === -1
                ) {
                    //TEST WITH LATE
                    if (
                        FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, ACTION_STATUS_KEY.late) > -1
                    ) {
                        //ONLY IF TODO OR INPROGRESS //!!! maybe pass late info at the data loading
                        if (
                            actionItem.status === ACTION_STATUS_KEY.todo ||
                            actionItem.status === ACTION_STATUS_KEY.inProgress
                        ) {
                            //TEST IF DUEDATE NOT A DATE
                            let dueDate = actionItem.dueDate;
                            if (Object.prototype.toString.call(dueDate) !== "[object Date]") {
                                dueDate = new Date(dueDate);
                            }

                            //TEST
                            if (dueDate.getTime() < TODAY.getTime()) return true;
                        }
                    }

                    //DEFAULT
                    return false;
                }

                //SEARCH
                if (
                    state.textSearchInput &&
                    state.textSearchInput !== "" &&
                    !actionItem.action.toUpperCase()?.includes(state.textSearchInput.toUpperCase())
                )
                    return false;

                //RETURN TRUE
                return true;
            })
            .map((item) => {
                //GET TIME FOR SORT NUMBER
                let sortNum = 0;

                //SWITCH STATUS
                switch (item.status) {
                    //DONE
                    case ACTION_STATUS_KEY.done:
                        sortNum = Date.parse(item.realisedDate) || 0;
                        break;

                    //ON HOLD
                    case ACTION_STATUS_KEY.onHold:
                        sortNum = Date.parse(item.cancelledDate) || 0;
                        break;

                    //DEFAULT
                    default:
                        sortNum = Date.parse(item.dueDate) || 0;
                        break;
                }

                //ADD SORT
                return {
                    ...item,
                    sort: sortNum,
                };
            })
            .sort(function (a, b) {
                if (a.sort < b.sort) {
                    return -1;
                }
                if (a.sort > b.sort) {
                    return 1;
                }
                return 0;
            });
    }, [
        actionsData,
        state.selectedItemView,
        state.statusSearchInput,
        state.textSearchInput,
        state.viewMode,
        updateTracker,
    ]);

    // LEGEND OBJECT WITH COUNTER FOR RO ACTIONS  TABLE
    const legendAndCounter = useMemo(() => {
        // IF NOT IN DETAILED VIEW MODE OR IN VIEW MODE, RETURN NULL
        if (
            state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
            state.detailedRoMatrixItem === null
        ) {
            return null;
        }

        // ELSE RESUME

        //INIT
        let legendObject = [];
        let cloneActions = [];

        cloneActions = _.cloneDeep(filteredActions);

        //ONLY IF DATA
        if (cloneActions && cloneActions.length > 0) {
            //LOOP ON ARRAY LIST
            let totalCount = 0;
            let totalLateCount = 0;
            for (let i = 0; i < ACTION_STATUS_LIST.length; i++) {
                //COUNT
                let currentActions = cloneActions.filter(
                    (item) => item.status === ACTION_STATUS_LIST[i].id
                );
                let currentCount = currentActions.length;

                //INTEGRATE IF NOT EMPTY
                if (currentCount > 0) {
                    //TEST FOR IN PROGRESS AND TODO
                    let lateCount = 0;
                    if (
                        ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.todo ||
                        ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.inProgress
                    ) {
                        //FIND IF DUE DATE BEFORE TODAY
                        lateCount = currentActions.filter((item) => {
                            return FUNC_ACTION_IS_LATE(item.status, item.dueDate);
                        }).length;
                    }

                    //TOTAL COUNT - RELATED TO CURRENT SELECTION
                    totalCount = totalCount + currentCount;

                    //LATE
                    if (lateCount > 0) {
                        totalLateCount = totalLateCount + lateCount;
                    }

                    //INTEGRATE
                    legendObject.push({
                        id: ACTION_STATUS_LIST[i].id,
                        name: ACTION_STATUS_LIST[i].name,
                        backgroundColor: ACTION_STATUS_LIST[i].backgroundColor,
                        color: ACTION_STATUS_LIST[i].color,
                        value: currentCount,
                        late: lateCount,
                    });
                }
            }

            //UNSHIFT LATE
            if (totalLateCount > 0) {
                legendObject.unshift({
                    id: "late",
                    name: "Late",
                    value: totalLateCount,
                    backgroundColor: "var(--color-bad)",
                    color: "white",
                });
            }

            //TOTAL
            legendObject.push({
                id: "total",
                name: "Total",
                value: totalCount,
            });
        }

        //RETURN OBJECT
        return legendObject;
    }, [filteredActions, state.detailedRoMatrixItem, state.viewMode]);


    const canModifySchedules = useMemo(() => {
        if(state.selectedItemView) {
            return (
                state.selectedItemView.responsible === currentUser.username ||
                state.selectedItemView.createdBy === currentUser.username ||
                securityGroup.isCurrentUserEditor
            );
        }
        for(const schedule of state.selected) {
            if(
                schedule.responsible !== currentUser.username && 
                schedule.createdBy !== currentUser.username &&
                !securityGroup.isCurrentUserEditor
            ) {
                return false;
            }
        }
        return true;
    }, [currentUser.username, securityGroup.isCurrentUserEditor, state.selected, state.selectedItemView]);

    /////////////////////////////////////////////////////
    // CALLBACKS ////////////////////////////////////////
    /////////////////////////////////////////////////////

    // SHOW MOVE MODAL
    const handleMoveItemsClicked = useCallback(
        (e) => {
            e.preventDefault();
            appDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                    source: "appSettings",
                    object: {
                        actionModal: "wbsMove",
                        moveModalOptions: {
                            itemsArray:
                                state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
                                    ? [state.selectedItemView]
                                    : state.selected,
                            tableName: "schedule",
                            query: UpdateTelescopeDataSchedule,
                            propertiesToDelete: [
                                "createdAt",
                                "updatedAt",
                                "status",
                                "dueDate",
                                "sort",
                                "baseline",
                                "forecast",
                                "actual",
                            ],
                        },
                    },
                },
            });
        },
        [appDispatcher, state.selected, state.selectedItemView, state.viewMode]
    );

    // ADD BTN CLICKED
    const onAddEditClick = useCallback(
        (scheduleItem) => {
            // INIT
            let currentItem = scheduleItem ? _.cloneDeep(scheduleItem) : null;

            // CHECK TYPE IS NOT NULL. IF IT IS, IT'S A TASK
            if (currentItem && !currentItem.type) {
                currentItem.type = SCHEDULE_TYPE_KEYS.task;
            }

            // PREPARER USERLIST FOR DROPDOWN
            let editSideBarUsersList = usersList.map((user) => ({
                id: user.value,
                label: user.label,
            }));

            // IF CAN'T FIND USER IN THE USERS LIST, PASS THE RANDOM MEGA USER
            if (
                currentItem !== null &&
                !usersList.find((user) => user.value === currentItem.responsible)
            ) {
                // GET USERS OBJECT
                let userInCore = FUNC_GET_USER_INFO(
                    usersList,
                    megaUsersIds,
                    currentItem.responsible
                );

                // UPDATE IT
                currentItem.responsible = userInCore.value;
            }

            // KEEP ONLY THE RESPONSIBLE
            if (
                !securityGroup.isCurrentUserEditor &&
                currentItem &&
                currentItem.responsible &&
                currentItem.responsible !== "" &&
                currentItem.responsible !== currentUser.username
            ) {
                let findUser = usersList.find((user) => user.value === currentItem.responsible);
                editSideBarUsersList = [
                    {
                        id: findUser.value,
                        label: findUser.label,
                    },
                ];
            }

            // IF UPDATING MILESTONE, HANDLE DATES
            if (currentItem && currentItem.type === SCHEDULE_TYPE_KEYS.milestone) {
                currentItem.baseline = currentItem.baselineStart;
                currentItem.forecast = currentItem.forecastStart;
                currentItem.actual = currentItem.actualStart;
            }

            // PREPARE DATA TO FEED TO EDIT SIDE BAR
            let newEditSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
                currentItem
                    ? currentItem
                    : SCHEDULE_GET_DEFAULT_ITEM(
                          selectedOrganizationId,
                          wbsId,
                          currentUser.username
                      ), // DATA
                SCHEDULE_EDIT_SIDEBAR_CONTENT,
                {
                    id: currentItem ? currentItem.id : uuid(),
                    organizationId: selectedOrganizationId,
                    projectId: projectId,
                    wbsId: currentItem ? currentItem.wbsId : wbsId,
                    displayId: currentItem
                        ? currentItem.displayId
                        : FUNC_BUILD_DISPLAY_ID_STR(SCHEDULE_DISPLAY_ID_STR, biggestDisplayId),
                    isNew: currentItem ? false : true, //VERY IMPORTANT
                },
                // SPECIFIC OPTIONS FOR SOME ATTRIBUTES
                [
                    {
                        key: "responsible",
                        value: editSideBarUsersList,
                    },
                ]
            );

            // PASS EDIT SIDE BAR CONTENT TO STATE, AND MAKE editSideBar TRUE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    editSideBarContent: newEditSideBarContent,
                    editSideBar: true,
                    viewMode: scheduleItem
                        ? WBS_BIG_COMPONENT_VIEW_MODE.edit
                        : WBS_BIG_COMPONENT_VIEW_MODE.add,
                    statusSearchInput: [],
                },
            });
        },
        [
            biggestDisplayId,
            currentUser.username,
            megaUsersIds,
            projectId,
            securityGroup.isCurrentUserEditor,
            selectedOrganizationId,
            usersList,
            wbsId,
        ]
    );

    // SAVE ADD EDIT ON EDIT SIDE BAR
    const onSaveAddEdit = useCallback(
        (e, mutateObject, isNew) => {
            if (e) {
                e.preventDefault();
            }

            //INIT
            let graphQLQuery;
            let dispatcherQuery;
            let updatedObject = {};

            // ADD NEW EVENT BASED ON MUTATION
            let updatedEvents = null;
            let selectedItemView = _.cloneDeep(state.selectedItemView);
           
            if (isNew) {
                updatedEvents = ADD_ITEM_EVENT(mutateObject, mutateObject, currentUser.username);
                mutateObject.actionsIds = [];
            } else if(selectedItemView){
                // COMPATIBILITY : CHECK THAT EVENTS ARRAY EXISTS
                if (!selectedItemView.events) {
                    selectedItemView.events = "[]";
                }

                updatedEvents = ADD_ITEM_EVENT(
                    selectedItemView,
                    mutateObject,
                    currentUser.username
                );
            }
            mutateObject.events = JSON.stringify(updatedEvents);

            //UPDATE PATH
            mutateObject.path = currentPath;

            // IF MILESTONE DATES, HANDLE DATES
            // We don't have the baseline, forecast, actual fields in GraphQL.
            // We use the baselineStart ... to store these fields.
            if (
                (isNew &&
                    mutateObject.type &&
                    mutateObject.type === SCHEDULE_TYPE_KEYS.milestone) ||
                (!isNew &&
                    (mutateObject.baseline ||
                        mutateObject.forecast ||
                        mutateObject.actual ||
                        mutateObject.actual === null))
            ) {
                // BASELINE
                if (mutateObject.baseline) {
                    mutateObject.baselineStart = mutateObject.baseline;
                    mutateObject.baselineFinish = mutateObject.baseline;
                } else {
                    delete mutateObject.baselineStart;
                    delete mutateObject.baselineFinish;
                }

                // FORECAST
                if (mutateObject.forecast) {
                    mutateObject.forecastStart = mutateObject.forecast;
                    mutateObject.forecastFinish = mutateObject.forecast;
                } else {
                    delete mutateObject.forecastStart;
                    delete mutateObject.forecastFinish;
                }

                // ACTUAL
                if (mutateObject.actual || mutateObject.actual === null) {
                    mutateObject.actualStart = mutateObject.actual;
                    mutateObject.actualFinish = mutateObject.actual;
                } else {
                    delete mutateObject.actualStart;
                    delete mutateObject.actualFinish;
                }
            }

            // DELETE MILESTONES DATES ANYWAYS
            delete mutateObject.baseline;
            delete mutateObject.forecast;
            delete mutateObject.actual;

            // DELETE OTHER FIELDS
            delete mutateObject.createdAt;
            delete mutateObject.updatedAt;
            delete mutateObject.status;
            delete mutateObject.dueDate;
            delete mutateObject.sort;

            // DELETE MILESTONES DATES ANYWAYS
            delete mutateObject.baseline;
            delete mutateObject.forecast;
            delete mutateObject.actual;

            //TEST IF CREATE OR NOT
            if (!isNew) {
                //INIT GRAPH QL + DISPATCHER
                graphQLQuery = UpdateTelescopeDataSchedule;
                dispatcherQuery = "SET_PROJECT_DATA_UPDATE";
                updatedObject = {
                    ...mutateObject,
                };

                //CREATE ADD ALL NEEDED INFO
            } else {
                //GRAPHQL / DISPATCHER INIT
                graphQLQuery = CreateTelescopeDataSchedule;
                dispatcherQuery = "SET_PROJECT_DATA_CREATE";
                updatedObject = {
                    ...SCHEDULE_GET_DEFAULT_ITEM(
                        selectedOrganizationId,
                        wbsId,
                        currentUser.username
                    ),
                    ...mutateObject,
                };
                mutateObject = {
                    ...mutateObject,
                    createdBy: currentUser.username,
                    createdOn: TODAY,
                };
            }

            // UPDATE BACKEND
            mutateGraphql(
                graphQLQuery,
                {
                    ...mutateObject,
                    groupEditors: securityGroup.groupEditors,
                    groupViewers: securityGroup.groupViewers,
                },
                appDispatcher
            );

            // UPDATE OR CREATE APP STATE
            appDispatcher({
                type: dispatcherQuery,
                source: "schedule",
                objects: [updatedObject],
            });
          
            // UPDATE SCHEDULE STATE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    editSideBar: false,
                    editSideBarContent: null,
                    viewMode: selectedItemView
                        ? WBS_BIG_COMPONENT_VIEW_MODE.view
                        : WBS_BIG_COMPONENT_VIEW_MODE.list,
                    selectedItemView: selectedItemView
                        ? { ...selectedItemView, ...updatedObject }
                        : null,
                    selected: [],
                    scheduleCardPropKey: selectedItemView && state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ? state.scheduleCardPropKey + 1 : state.scheduleCardPropKey,
                },
            });
        },
        [appDispatcher, currentPath, currentUser.username, securityGroup.groupEditors, securityGroup.groupViewers, selectedOrganizationId, state.scheduleCardPropKey, state.selectedItemView, state.viewMode, wbsId]
    );

    // ON SAVE FROM ITEM CARD INFO COMPONENT
    const onItemCardInfoUpdated = useCallback(
        (e) => {
            let mutateObject = {
                id: state.selectedItemView.id,
                [e.target.name]: e.target.value
            };
           
            onSaveAddEdit(null, mutateObject);
        },
        [onSaveAddEdit, state.selectedItemView]
    );

    const onDeleteSideBar = useCallback(
        async (e) => {
            e.preventDefault();

            // INIT
            let scheduleMutateObject = [];
            let actionMutateObject = [];
            let selectedItems = [];
            // INIT
            let finalMutation = {
                graphql: {
                    schedule: {
                        query: deleteTelescopeDataSchedule,
                        objects: [],
                    },
                    govReview: {
                        query: updateTelescopeDataGovReview,
                        objects: [],
                    },
                    action: {
                        query: updateTelescopeDataAction,
                        objects: [],
                    },
                },
                dispatcher: {
                    schedule: {
                        delete: [],
                    },
                    govReview: {
                        update: [],
                    },
                    action: {
                        update: [],
                    },
                },
            };
            let actionFound;
            let updatedActions = [];

            // IN VIEW MODE, DELETE CURRENT SCHEDULE
            if (state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view) {
                selectedItems = [state.selectedItemView];
            } else {
                selectedItems = state.selected;
            }

            // APP STATE OBJECT
            let appStateObj = [];

            // GET THE ONES THAT WERE SELECTED AND BELONG TO REFERENCE
            let hasDeleteFromReference = false;

            let newReferenceCreateUpdateObject = referenceCreateUpdateObject
                ? _.cloneDeep(referenceCreateUpdateObject)
                : null;

            // IF DELETING ITEMS THAT HAVEN'T BEEN SAVED YET (FROM REFERENCE)
            if (newReferenceCreateUpdateObject) {
                // FOR EACH SELECTED SCHEDULE
                for (let selected of selectedItems) {
                    const findIndexCreate = FUNCT_FIND_INDEX(
                        newReferenceCreateUpdateObject.create,
                        "id",
                        selected.id
                    );
                    const findIndexUpdate = FUNCT_FIND_INDEX(
                        newReferenceCreateUpdateObject.update,
                        "id",
                        selected.id
                    );

                    // IF WAS FOUND IN CREATE
                    if (findIndexCreate !== -1) {
                        newReferenceCreateUpdateObject.create.splice(findIndexCreate, 1);
                        hasDeleteFromReference = true;
                    }
                    // IF WAS FOUND IN UPDATE
                    else if (findIndexUpdate !== -1) {
                        newReferenceCreateUpdateObject.update.splice(findIndexUpdate, 1);
                        hasDeleteFromReference = true;
                    }
                    // ELSE NOT FOUND ANYMORE (COMES FROM DB ALREADY)
                    else {
                        scheduleMutateObject.push({
                            id: selected.id,
                        });
                    }

                    appStateObj.push(selected.id);

                    // IF ACTIONS LINKED, REMOVE THE LINK FROM ACTION
                    if (selected?.actionsIds?.length > 0) {
                        // FOR EACH ACTIONS, RESET THE LINK
                        selected.actionsIds.forEach((actionId) => {
                            // ADD TO GRAPHQL MUTATE OBJECT
                            actionMutateObject.push({
                                id: actionId,
                                telescopeDataScheduleTelescopeDataActionId: null,
                            });
                        });
                    }
                }
            }
            // IF NO REFERENCE LOCAL DATA
            else {
                // GET MUTATIONS TO UPDATE AGENDAS AND DELETE SCHEDULE
                let scheduleDeleteMutations = GET_MUTATION_TO_DELETE_SEVERAL_ITEMS(
                    selectedItems,
                    "schedule",
                    projectData
                );

                // MERGE MUTATION TO FINAL
                finalMutation = MERGE_GOV_REVIEW_MUTATIONS(finalMutation, scheduleDeleteMutations);

                // GET MUTATION TO UPDATE ACTION LINKS FOR EACH SCHEDULE
                selectedItems.forEach((scheduleItemToDelete) => {
                    // PARSE RO ACTIONS LINKS IDS
                    scheduleItemToDelete.actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
                        scheduleItemToDelete.actionsIds
                    );

                    // FOR EACH ACTIONS IDS, REMOVE THE CURRENT SCHEDULE ITEM
                    scheduleItemToDelete.actionsIds.forEach((actionId) => {
                        // GET THE ACTION OBJECT
                        actionFound = FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND(
                            updatedActions,
                            projectData.action,
                            "id",
                            actionId
                        );

                        if (!actionFound) {
                            return;
                        }

                        // UPDATE ACTION LINKS LIST
                        actionFound.data.links = ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(
                            actionFound.data.links,
                            scheduleItemToDelete.id
                        );

                        // IF ITEM COME FROM UPDATED ITEMS, UPDATE IT
                        if (actionFound.fromFirstArray) {
                            updatedActions[actionFound.index] = actionFound.data;
                        }
                        // OTHERWISE, ADD ITEM TO UPDATED ITEMS
                        else {
                            updatedActions.push(actionFound.data);
                        }
                    });
                });

                // ADD A MUTATION FOR EACH UPDATED ACTIONS
                updatedActions.forEach((updatedAction) => {
                    finalMutation.dispatcher.action.update.push(updatedAction);
                    finalMutation.graphql.action.objects.push({
                        id: updatedAction.id,
                        links: updatedAction.links,
                    });
                });

                // UPDATE GRAPHQL
                for (let queryName of Object.keys(finalMutation.graphql)) {
                    multipleMutateGraphql(
                        finalMutation.graphql[queryName].query,
                        finalMutation.graphql[queryName].objects,
                        appDispatcher
                    );
                }

                //UPDATE APP STATE
                appDispatcher({
                    type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
                    object: finalMutation.dispatcher,
                });
            }

            if (scheduleMutateObject.length > 0) {
                // SET LOADING TO TRUE
                scheduleDispatcher({
                    type: "SET_STATE_KEY_VALUE",
                    key: "isLoadingDelete",
                    value: true,
                });

                let promises = [
                    batchMutationGraphql(
                        "deleteTelescopeDataSchedule",
                        scheduleMutateObject,
                        appDispatcher
                    ),
                ];

                // BATCH UPDATE ACTIONS SCHEDULE LINK
                if (actionMutateObject.length > 0) {
                    promises.push(
                        batchMutationGraphql(
                            "updateTelescopeDataAction",
                            actionMutateObject,
                            appDispatcher
                        )
                    );

                    appDispatcher({
                        type: "SET_PROJECT_DATA_UPDATE",
                        source: "action",
                        objects: actionMutateObject,
                    });
                }

                await Promise.all(promises);

                // SET LOADING TO FALSE
                scheduleDispatcher({
                    type: "SET_STATE_KEY_VALUE",
                    key: "isLoadingDelete",
                    value: false,
                });
            }

            // IF NEEDS TO UPDATE APP SETTINGS
            if (hasDeleteFromReference) {
                if (
                    newReferenceCreateUpdateObject.create.length === 0 &&
                    newReferenceCreateUpdateObject.update.length === 0
                ) {
                    newReferenceCreateUpdateObject = null;
                }
                //UPDATE APP STATE
                appDispatcher({
                    type: "SET_PROJECT_REFERENCE_DELETE",
                    source: "schedule",
                    ids: appStateObj,
                    referenceCreateUpdateObject: newReferenceCreateUpdateObject,
                });
            }

            //UPDATE THIS STATE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selected: [],
                    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
                },
            });
        },
        [
            appDispatcher,
            projectData,
            referenceCreateUpdateObject,
            state.selected,
            state.selectedItemView,
            state.viewMode,
        ]
    );

    // DELETE ACTIONS FROM ACTION PLAN
    const onDeleteAction = useCallback(() => {
        // RUN ACTION DELETION
        ACTION_FUNC_DELETE_ACTIONS_AND_LINKS(null, state.selected, projectData, appDispatcher);

        let scheduleToUpdate = _.cloneDeep(state.selectedItemView);

        // GET ALL IDS
        let actionIdsToRemove = state.selected.map((item) => item.id);

        // REMOVE ACTION IDS SELECTED FROM SCHEDULE TO UPDATE
        scheduleToUpdate.actionsIds = scheduleToUpdate.actionsIds.filter(
            (actionId) => !actionIdsToRemove?.includes(actionId)
        );

        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                selected: [],
                selectedItemView: scheduleToUpdate,
            },
        });
    }, [appDispatcher, projectData, state.selected, state.selectedItemView]);

    // DUPLICATE CLICKED SIDE BAR ------------------------------------------ OK
    const onDuplicateSideBar = useCallback(() => {
        //INIT
        let mutateObject = [];
        let scheduleItemToDuplicate =
            state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
                ? [state.selectedItemView]
                : _.cloneDeep(state.selected);

        //LOOP ON SELETED
        for (let i = 0; i < scheduleItemToDuplicate.length; i++) {
            //INIT
            let updatedSchedule = _.cloneDeep(scheduleItemToDuplicate[i]);
            let newDisplayId = FUNC_BUILD_DISPLAY_ID_STR(
                SCHEDULE_DISPLAY_ID_STR,
                biggestDisplayId + i
            );

            delete updatedSchedule.createdAt;
            delete updatedSchedule.updatedAt;
            delete updatedSchedule.status;
            delete updatedSchedule.dueDate;
            delete updatedSchedule.sort;

            // DELETE MILESTONES DATES ANYWAYS
            delete updatedSchedule.baseline;
            delete updatedSchedule.forecast;
            delete updatedSchedule.actual;

            delete updatedSchedule.actionsIds;

            let initialEvents = JSON.stringify([ItemEvent(currentUser.username, "created")]);

            //MUTATE OBJECT
            mutateObject.push({
                ...updatedSchedule,
                id: uuid(),
                displayId: newDisplayId,
                createdBy: currentUser.username,
                responsible: currentUser.username,
                name: "(Duplicate) - " + updatedSchedule.name,
                groupEditors: securityGroup.groupEditors,
                groupViewers: securityGroup.groupViewers,
                actionsIds: [],
                events: initialEvents,
                comments: "[]",
            });
        }

        // SEND GRAPHQL REQUEST
        multipleMutateGraphql(CreateTelescopeDataSchedule, mutateObject, appDispatcher);

        // UPDATE PROJECT DATA IN APP LEVEL
        appDispatcher({
            type: "SET_PROJECT_DATA_CREATE",
            source: "schedule",
            objects: mutateObject,
        });

        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                selected: [],
                selectedItemView:
                    state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ? mutateObject[0] : null,
            },
        });
    }, [
        appDispatcher,
        biggestDisplayId,
        currentUser.username,
        securityGroup.groupEditors,
        securityGroup.groupViewers,
        state.selected,
        state.selectedItemView,
        state.viewMode,
    ]);

    ////////////////////////////////////////////////////////////
    /// DISPLAY ////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////

    // HANDLE EXPAND ARROW CLICK ---------------------------- OK
    const onExpandArrowClick = useCallback(
        (isExpanding, isGraph) => {
            // INIT
            let newExpandMode;

            if (isReviewMode || state.widthSizeLimit) {
                newExpandMode =
                    state.expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.graph
                        ? SCHEDULE_COMPONENT_EXPAND_TYPES.table
                        : SCHEDULE_COMPONENT_EXPAND_TYPES.graph;
            } else {
                //INIT
                newExpandMode =
                    isExpanding === true
                        ? isGraph
                            ? SCHEDULE_COMPONENT_EXPAND_TYPES.graph
                            : SCHEDULE_COMPONENT_EXPAND_TYPES.table
                        : SCHEDULE_COMPONENT_EXPAND_TYPES.both;
            }

            //UPDATE ACTION STATE
            scheduleDispatcher({
                type: "SET_STATE_KEY_VALUE",
                key: "expandMode",
                value: newExpandMode,
            });
        },
        [isReviewMode, state.expandMode, state.widthSizeLimit]
    );

    // RESET EDIT SIDE BAR --------------------------------- OK
    const onResetEditSideBar = useCallback(() => {
        let newViewMode = WBS_BIG_COMPONENT_VIEW_MODE.list;

        // CASE WE ARE IN VIEW MODE
        if (state.selectedItemView) {
            newViewMode = WBS_BIG_COMPONENT_VIEW_MODE.view;
        }

        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                actionsOperationType: null,
                editSideBar: false,
                editSideBarContent: null,
                viewMode: newViewMode,
            },
        });
    }, [state.selectedItemView]);

    // CHANGE SELECTED YEAR
    const onChangeGraphYear = useCallback(
        (e, isAdd, reset) => {
            e.preventDefault();

            if (reset) {
                // RESET TO CURRENT YEAR
                scheduleDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        yearSelected: new Date().getFullYear(),
                    },
                });
            } else if (
                (isAdd && state.yearSelected > 2000) ||
                (!isAdd && state.yearSelected < 2100)
            ) {
                // INCREMENT / DECREMENT IF BETWEEN 2000 AND 2100
                let newYearSelected = isAdd ? state.yearSelected + 1 : state.yearSelected - 1;

                //SET NEW VALUE
                scheduleDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        yearSelected: newYearSelected,
                    },
                });
            }
        },
        [state.yearSelected]
    );

    // UPDATE INPUT VALUES
    const handleCompletionSelectChange = useCallback(
        (e) => {
            let name = e.target.name;
            let value = e.target.value;

            // CHECK MIN <= MAX
            if (
                state.atCompletionSelectedLastYear &&
                name === "atCompletionSelectedFirstYear" &&
                value > state.atCompletionSelectedLastYear
            ) {
                // FIRST > LAST -> SET MAX = FIRST
                scheduleDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        atCompletionSelectedFirstYear: value,
                        atCompletionSelectedLastYear: value,
                    },
                });
            } else if (
                state.atCompletionSelectedFirstYear &&
                name === "atCompletionSelectedLastYear" &&
                value < state.atCompletionSelectedFirstYear
            ) {
                // FIRST < LAST -> SET FIRST = MAX
                scheduleDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        atCompletionSelectedFirstYear: value,
                        atCompletionSelectedLastYear: value,
                    },
                });
            } else {
                scheduleDispatcher({
                    type: "SET_STATE_OBJECT",
                    object: {
                        [name]: value,
                    },
                });
            }
        },
        [state.atCompletionSelectedFirstYear, state.atCompletionSelectedLastYear]
    );

    // SWITCH GRAPH COMPLETION MODE
    const onChangeAtCompletionMode = useCallback(() => {
        let newState = !state.atCompletionMode;

        // UPDATE COMMAND BAR STATE
        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                atCompletionMode: newState,
                expandMode:
                    newState === true || isReviewMode
                        ? SCHEDULE_COMPONENT_EXPAND_TYPES.graph
                        : SCHEDULE_COMPONENT_EXPAND_TYPES.both,
            },
        });
    }, [isReviewMode, state.atCompletionMode]);

    // ENABLE / DISABLE SECOND Y AXIS
    const onChangeYAxis = useCallback(() => {
        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                secondYAxis: !state.secondYAxis,
            },
        });
    }, [state.secondYAxis]);

    //MANAGE CONSOLIDATE
    const onConsolidate = useCallback(() => {
        scheduleDispatcher({
            type: "SET_STATE_KEY_VALUE",
            key: "isConsolidate",
            value: !state.isConsolidate,
        });
    }, [state.isConsolidate]);

    // WHEN CLICK ON TABLE ITEM
    const onItemClick = useCallback(
        (item) => {
            // LOAD ITEM AND GO TO VIEW MODE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.view,
                    selectedItemView: _.cloneDeep(item),
                    selected: [],
                },
            });

            // UPDATE WBS SELECTED ITEM
            wbsDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selectedItem: item.id,
                },
            });
        },
        [wbsDispatcher]
    );

    // CREATE ACTION IN EDIT SIDE BAR
    const onAddEditActionClick = useCallback(
        (item) => {
            
            // INIT
            let currentAction = _.cloneDeep(item ?? defaultActionItem);

            // PREPARE USERLIST FRO DROPDOWN
            let editSideBarUsersList = usersList.map((user) => ({
                value: user.value,
                label: user.label,
            }));

            // IF CAN'T FIND USER IN THE USERS LIST, PASS THE RANDOM MEGA USER
            if (item !== null && !usersList.find((user) => user.value === item.responsible)) {
                //GET USERS OBJECT
                let userInCore = FUNC_GET_USER_INFO(usersList, megaUsersIds, item.responsible);

                //UPDATE IT
                currentAction.responsible = userInCore.value;
            }

            // PREPARE CONTRIBUTORS
            if (currentAction?.contributors) {
                currentAction.contributors = editSideBarUsersList.filter((user) =>
                    currentAction.contributors?.includes(user.value)
                );
            }

            // PREPARE LINKS
            if(selectedReviewItem && !item && selectedReviewItem.projectId && selectedReviewItem.projectId === projectId) {
                currentAction.links = JSON.stringify([FUNC_ACTION_CREATE_LINK_OBJECT("govReview", selectedReviewItem.id)]);
            }

            // PREPARE DATA TO FEED TO EDIT SIDE BAR
            let newEditSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
                // DATA
                currentAction,
                // STRUCTURE OF THE DATA
                ACTION_ITEM_DATA,
                // IDS
                {
                    id: item ? item.id : uuid(),
                    organizationId: item ? item.selectedOrganizationId : selectedOrganizationId,
                    projectId: item ? item.projectId : projectId,
                    wbsId: item ? item.wbsId : wbsId,
                    displayId: item
                        ? item.displayId
                        : ACTION_START_DISPLAY_ID +
                          FUNC_ZERO_FORMAT_TO_NUM(biggestActionDisplayIdNum + 1, 4),
                    isNew: item === null,
                },
                // SPECIFIC OPTIONS FOR SOME ATTRIBUTES
                [
                    // RESPONSIBLE
                    {
                        key: "responsible",
                        value: editSideBarUsersList,
                    },
                    // CONTRIBUTORS
                    {
                        key: "contributors",
                        value: editSideBarUsersList.filter(
                            (user) => user.value !== currentUser.username
                        ),
                    },
                    // GOVERNANCE ITEMS. FOR NOW LABEL IS THE DESCRIPTION BECAUSE GOVERNANCE HAS NO NAME
                    {
                        key: "telescopeDataGovernanceTelescopeDataActionId",
                        value: governanceData.map((governance) => ({
                            value: governance.id,
                            label: governance.description,
                        })),
                    },
                    // R&O ITEMS
                    {
                        key: "telescopeDataROTelescopeDataActionId",
                        value: roData.map((ro) => ({ value: ro.id, label: ro.displayId })),
                    },
                    //SCHEDULE
                    {
                        key: "telescopeDataScheduleTelescopeDataActionId",
                        value: scheduleData.map((item) => ({
                            value: item.id,
                            label: item.displayId,
                        })),
                    },
                ]
            );

            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    editSideBarContent: newEditSideBarContent,
                    editSideBar: true,
                    viewMode:
                        state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
                            ? state.viewMode
                            : WBS_BIG_COMPONENT_VIEW_MODE.list,
                    actionsOperationType: item
                        ? RISK_OPP_CHANGE_ACTIONS_TYPES.edit
                        : RISK_OPP_CHANGE_ACTIONS_TYPES.create,
                },
            });
        },
        [defaultActionItem, usersList, selectedReviewItem, selectedOrganizationId, projectId, wbsId, biggestActionDisplayIdNum, governanceData, roData, scheduleData, state.viewMode, megaUsersIds, currentUser.username]
    );

    // ON SAVE ADD ACTION ON EDIT SIDE BAR
    const onSaveAddAction = useCallback(
        (e, mutateObject, isNew) => {
            // GET THE SCHEDULE ITEM TO UPDATE
            let scheduleToUpdate = _.cloneDeep(state.selectedItemView);

            delete scheduleToUpdate.baseline;
            delete scheduleToUpdate.forecast;
            delete scheduleToUpdate.actual;
            delete scheduleToUpdate.createdAt;
            delete scheduleToUpdate.updatedAt;
            delete scheduleToUpdate.status;
            delete scheduleToUpdate.dueDate;
            delete scheduleToUpdate.sort;

            // INIT
            let newAction = {};

            let actionBeingEdited;

            // IF IS CREATING ACTION
            if (isNew) {
                // UPDATE PATH
                mutateObject.path = currentPath;

                // ADD NEW ACTION ID TO RO ITEMS ACTIONS IDS
                scheduleToUpdate = {
                    ...scheduleToUpdate,
                    actionsIds: scheduleToUpdate.actionsIds
                        ? [...scheduleToUpdate.actionsIds, mutateObject.id]
                        : [mutateObject.id],
                };

                // CREATE EVENTS FOR NEW ACTION
                let newEvents = [
                    {
                        user: {
                            id: currentUser.username,
                            label: currentUser.name,
                        },
                        createdOn: new Date(),
                        value: "Created by " + currentUser.name,
                    },
                    {
                        user: {
                            id: currentUser.username,
                            label: currentUser.name,
                        },
                        createdOn: new Date(),
                        value: "status initialized by " + currentUser.name,
                        updatedValue: mutateObject.status,
                        lastValue: null,
                        noDisplay: true,
                    },
                ];

                // INIT NEW ACTION OBJECT
                newAction = {
                    ..._.cloneDeep(defaultActionItem),
                    ...mutateObject,
                    createdBy: currentUser.username,
                    createdOn: TODAY,
                    events: JSON.stringify(newEvents),
                    wbsId: scheduleToUpdate.wbsId,
                    displayId:
                        ACTION_START_DISPLAY_ID +
                        FUNC_ZERO_FORMAT_TO_NUM(biggestActionDisplayIdNum, 4),
                    groupEditors: mutateObject.isPrivate ? [] : securityGroup.groupEditors,
                    groupViewers: mutateObject.isPrivate ? [] : securityGroup.groupViewers,
                    // CONNECT TO SCHEDULE
                    telescopeDataScheduleTelescopeDataActionId: scheduleToUpdate.id,
                };

                let newLinks = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(mutateObject.links);
                // ADD LINK TO THIS SCHEDULE
                let newActionLink = FUNC_ACTION_CREATE_LINK_OBJECT(
                    "schedule",
                    state.selectedItemView.id
                );
                newAction.links = newLinks ? [...newLinks, newActionLink] : [newActionLink];
                newAction.links = JSON.stringify(newAction.links);
            }
            // ELSE IF EDITING ACTION
            else {
                // FIND ACTION
                actionBeingEdited = actionsData.find((action) => action.id === mutateObject.id);

                // INIT EVENT
                let eventsUpdated = JSON.parse(actionBeingEdited.events);

                // HANDLE EVENTS FOR EDIT ACTION
                //LOOP ON UPDATED FIELDS
                for (let attribute of Object.keys(mutateObject)) {
                    //FLAG IS NOT THESE ATTRIBUTES
                    if (
                        attribute !== "isPrivate" &&
                        attribute !== "displayId" &&
                        attribute !== "id" &&
                        attribute !== "organizationId" &&
                        attribute !== "projectId" &&
                        attribute !== "wbsId" &&
                        attribute !== "comments" &&
                        attribute !== "contributors" &&
                        attribute !== "createdBy"
                    ) {
                        //TEST IF WE HAVE ALREADY ADDED THE EVENT
                        let eventIndex = FUNC_ACTION_NEED_TO_ADD_EVENT(
                            eventsUpdated,
                            attribute,
                            currentUser.username
                        );

                        //MANAGE UPDATED VALUE / LAST UPDATED
                        let updatedValue = mutateObject[attribute];
                        let lastValue = actionBeingEdited[attribute];
                        let userIndex;

                        //MANAGE IT FOR RESPONSIBLE
                        if (attribute === "responsible") {
                            //UPDATE DVALUE
                            if (updatedValue) {
                                userIndex = FUNCT_FIND_INDEX(usersList, "value", updatedValue);
                                if (userIndex > -1) {
                                    updatedValue = usersList[userIndex].label;
                                }
                            }

                            //LAST VALUE
                            if (lastValue) {
                                userIndex = FUNCT_FIND_INDEX(usersList, "value", lastValue);
                                if (userIndex > -1) {
                                    lastValue = usersList[userIndex].label;
                                }
                            }
                        }

                        //PUSH NEW EVENTS
                        if (eventIndex === -1) {
                            eventsUpdated.push({
                                user: {
                                    id: currentUser.username,
                                    label: currentUser.name,
                                },
                                createdOn: new Date(),
                                value: attribute + " updated by " + currentUser.name,
                                updatedValue: updatedValue,
                                lastValue: lastValue,
                            });

                            //IF SAME DAY UPDATE VALUE
                        } else {
                            eventsUpdated[eventIndex].updatedValue = updatedValue;
                        }
                    }
                }

                // FILL NEW ACTION OBJECT
                newAction = {
                    ...actionBeingEdited,
                    ...mutateObject,
                    updatedAt: new Date(),
                    events: JSON.stringify(eventsUpdated),
                };
            }

            // CLEAN FOR GRAPHQL

            // ACTION
            delete newAction.telescopeDataROTelescopeDataActionId;
            delete newAction.telescopeDataGovernanceTelescopeDataActionId;
            delete newAction.telescopeDataCostTelescopeDataActionId;
            // delete newAction.telescopeDataScheduleTelescopeDataActionId;

            // RO
            delete scheduleToUpdate.createdDate;

            // HANDLE CONTRIBUTORS
            if (mutateObject.contributors) {
                newAction.contributors = mutateObject.contributors.map((user) => user.value);
            }

            let updatedEvents = ADD_ITEM_EVENT(
                state.selectedItemView,
                {
                    id: state.selectedItemView.id,
                    actionsIds: scheduleToUpdate.actionsIds,
                },
                currentUser.username
            );

            scheduleToUpdate.events = JSON.stringify(updatedEvents);

            if (isNew) {
                // UPDATE BACKEND
                multipleQueryMultipleMutationGraphql(
                    // DATA
                    {
                        updateTelescopeDataSchedule: {
                            objects: [scheduleToUpdate],
                            query: UpdateTelescopeDataSchedule,
                        },
                        createTelescopeDataAction: {
                            objects: [newAction],
                            query: CreateTelescopeDataAction,
                        },
                    },
                    // APP DISPATCHER
                    appDispatcher
                );

                // UPDATE APP STATE
                appDispatcher({
                    type: "SET_PROJECT_DATA_MULTIPLE_TABLES",
                    object: {
                        action: {
                            create: [newAction],
                        },
                        schedule: {
                            update: [scheduleToUpdate],
                        },
                    },
                });
            } else {
                // UPDATE IN BACK-END THE ACTION
                mutateGraphql(
                    UpdateTelescopeDataAction,
                    {
                        ...newAction,
                        groupEditors: mutateObject.isPrivate ? [] : securityGroup.groupEditors,
                        groupViewers: mutateObject.isPrivate ? [] : securityGroup.groupViewers,
                        createdBy: mutateObject.isPrivate
                            ? ""
                            : actionBeingEdited && actionBeingEdited.createdBy !== ""
                            ? actionBeingEdited.createdBy
                            : currentUser.username,
                        telescopeDataScheduleTelescopeDataActionId: scheduleToUpdate.id,
                    },
                    appDispatcher,
                    null
                );

                // UPDATE IN APP STATE
                appDispatcher({
                    type: "SET_PROJECT_DATA_UPDATE",
                    source: "action",
                    objects: [newAction],
                });
            }

            // UPDATE RISK OPP STATE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    editSideBar: false,
                    editSideBarContent: null,
                    viewMode:
                        state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
                            ? state.viewMode
                            : WBS_BIG_COMPONENT_VIEW_MODE.list,
                    actionsOperationType: null,
                    selectedItemView:
                        state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
                            ? scheduleToUpdate
                            : null,
                    selected: [],
                },
            });
        },
        [
            actionsData,
            appDispatcher,
            biggestActionDisplayIdNum,
            currentPath,
            currentUser.name,
            currentUser.username,
            defaultActionItem,
            securityGroup.groupEditors,
            securityGroup.groupViewers,
            state.selectedItemView,
            state.viewMode,
            usersList,
        ]
    );

    // WHEN USER IS IN VIEW MODE AND CLICKS IN THE GO BACK BTN
    const onBackSideBar = useCallback(() => {
        // CHECK IF BACK BUTTON HAVE A DESTINATION ITEM ASSOCIATED
        let backButtonResult = WBS_ON_BACK_SIDEBAR(
            wbsNavigationOptions,
            projectData,
            projectId,
            wbsDispatcher,
            appDispatcher
        );

        // IF BACK BUTTON LEADS TO AN OTHER ITEM, EXIT FUNCTION
        if (backButtonResult) {
            return;
        }

        scheduleDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
                selectedItemView: null,
                textSearchInput: "",
                selected: [],
                statusSearchInput: [],
            },
        });

        wbsDispatcher({
            type: "SET_STATE_OBJECT",
            object: {
                selectedItem: null,
            },
        });
    }, [appDispatcher, projectData, projectId, wbsDispatcher, wbsNavigationOptions]);

    // DUPLICATE ACTIONS WHEN IN VIEW MODE
    const onDuplicateActionsViewMode = useCallback(() => {
        let duplicateMutation = FUNC_ACTION_DUPLICATE(
            state.selected,
            currentUser,
            biggestActionDisplayIdNum,
            securityGroup,
            projectData,
            appDispatcher
        );

        if (state.selectedItemView) {
            let updatedSchedule = FIND_OBJECT_ARRAY_ITEM(
                duplicateMutation.dispatcher.schedule.update,
                "id",
                state.selectedItemView.id
            );

            // UPDATE SCHEDULE STATE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selected: [],
                    selectedItemView: updatedSchedule,
                },
            });
        }
    }, [
        appDispatcher,
        biggestActionDisplayIdNum,
        currentUser,
        projectData,
        securityGroup,
        state.selected,
        state.selectedItemView,
    ]);

    /////////////////////////////////////////////////////
    // USE EFFECTS //////////////////////////////////////
    /////////////////////////////////////////////////////

    // MANAGE CONFIRMING MOVE
    useEffect(() => {
        // IF NOT NULL AND EMPTY (CONFIRMED MOVE MODAL)
        if (moveModalOptionsItemsArray && moveModalOptionsItemsArray.length === 0) {
            // UPDATE BIG COMPONENT STATE
            scheduleDispatcher({
                type: "SET_STATE_OBJECT",
                object: {
                    selected: [],
                    viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
                },
            });
            // UPDATE APP STATE
            appDispatcher({
                type: "SET_GLOBAL_STATE",
                options: {
                    source: "appSettings",
                    object: {
                        moveModalOptions: DEFAULT_STATE_MOVE_MODAL_OPTIONS,
                    },
                },
            });
        }
    }, [appDispatcher, moveModalOptionsItemsArray]);

    // UPDATE ITEM CARD VIEW IF NAVIGATION OPTIONS UPDATED WHEN ALREADY IN CARD VIEW
    useEffect(() => {
        if (
            wbsNavigationOptions?.goToItemId &&
            state.selectedItemView?.id !== wbsNavigationOptions.goToItemId
        ) {
            let newItemView = FIND_OBJECT_ARRAY_ITEM(
                scheduleData,
                "id",
                wbsNavigationOptions.goToItemId
            );

            if (newItemView) {
                onItemClick(newItemView);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wbsNavigationOptions]);

    ////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ///////////////////////////////////////
    ////////////////////////////////////////////////////////////

    return (
        <React.Fragment>
            {/* BIG COMPONENT STANDARD STRUCTURE */}
            <WbsElementBigStructure
                fieldInformation={fieldInformation}
                handleBigComponent={handleBigComponent}
                projectTitle={wbsName}
                buttonExpand={true}
                isExpendedEnabled={bigComponentIsExpanded}
                functionExpand={bigComponentExpandFunction}
                tooltipInfo="Actions"
            >
                {/* SIDE BAR  ----------------------------------------------------*/}
                <ScheduleLeftSideBar
                    currentUserName={currentUser.username}
                    handleMoveItemsClicked={handleMoveItemsClicked}
                    isConsolidate={state.isConsolidate}
                    isCurrentUserEditor={securityGroup.isCurrentUserEditor}
                    isLoadingDelete={state.isLoadingDelete}
                    isReadOnlyMode={isReadOnlyMode || (state.selectedItemView && state.selected.length > 0)}
                    canModifySchedules={canModifySchedules}
                    onAddEditClick={onAddEditClick}
                    onBackSideBar={onBackSideBar}
                    onConsolidate={onConsolidate}
                    onDeleteSideBar={onDeleteSideBar}
                    onDuplicateSideBar={onDuplicateSideBar}
                    scheduleDispatcher={scheduleDispatcher}
                    selected={state.selected}
                    selectedItemView={state.selectedItemView}
                    viewMode={state.viewMode}
                />

                {/* BIG COMPONENT BODY ------------------------------------------*/}
                <WbsElementStructureBody
                    key={"actionsBigComponentBody" + wbsId}
                    blockFlex="flexBetweenStrech"
                >
                    {state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ||
                    (state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit &&
                        state.selectedItemView) ? (
                        // VIEW MODE : ITEM CARD
                        <ScheduleItemCard
                            actionsData={filteredActions}
                            allActions={actionsData}
                            projectData={projectData}
                            appDispatcher={appDispatcher}
                            currentUser={currentUser}
                            handleCreateAction={onAddEditActionClick}
                            handleDeleteClick={onDeleteSideBar}
                            handleDuplicateClick={onDuplicateActionsViewMode}
                            handleEditAction={onAddEditActionClick}
                            isCurrentUserEditor={securityGroup.isCurrentUserEditor}
                            isReadOnlyMode={isReadOnlyMode}
                            canModifySchedules={canModifySchedules}
                            item={state.selectedItemView}
                            legendAndCounter={legendAndCounter}
                            megaUsersId={megaUsersIds}
                            onDeleteAction={onDeleteAction}
                            onItemCardInfoUpdated={onItemCardInfoUpdated}
                            scheduleDispatcher={scheduleDispatcher}
                            selectedItems={state.selected}
                            securityGroup={securityGroup}
                            statusSearchInput={state.statusSearchInput}
                            textSearchInput={state.textSearchInput}
                            users={usersList}
                            usersList={usersList}
                            wbsId={wbsId}
                            wbsDispatcher={wbsDispatcher}
                            scheduleCardPropKey={state.scheduleCardPropKey}
                        />
                    ) : (
                        // OTHER VIEW MODES
                        <React.Fragment>
                            {/* CHART */}
                            <ScheduleChart
                                displayWidth={
                                    state.expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.graph
                                        ? "100%"
                                        : state.expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.table
                                        ? "0%"
                                        : "40%"
                                }
                                expandMode={state.expandMode}
                                scheduleData={schedulesWithStatusAndDueDate}
                                yearSelected={state.yearSelected}
                                atCompletionMode={state.atCompletionMode}
                                atCompletionModeYearsSelect={atCompletionModeYearsSelect}
                                atCompletionSelectedFirstYear={state.atCompletionSelectedFirstYear}
                                atCompletionSelectedLastYear={state.atCompletionSelectedLastYear}
                                secondYAxis={state.secondYAxis}
                                onChangeYAxis={onChangeYAxis}
                                onExpandArrowClick={onExpandArrowClick}
                                onChangeGraphYear={onChangeGraphYear}
                                onChangeAtCompletionMode={onChangeAtCompletionMode}
                                handleCompletionSelectChange={handleCompletionSelectChange}
                            />

                            {/* SCHEDULE TABLE */}
                            <ScheduleTable
                                filteredSchedules={filteredSchedule}
                                isCurrentUserEditor={securityGroup.isCurrentUserEditor}
                                scheduleData={schedulesWithStatusAndDueDate}
                                textSearchInput={state.textSearchInput}
                                selectedItems={state.selected}
                                selectedFilters={state.statusSearchInput}
                                expandMode={state.expandMode}
                                isReviewMode={isReviewMode}
                                displayWidth={
                                    state.expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.table
                                        ? "100%"
                                        : state.expandMode === SCHEDULE_COMPONENT_EXPAND_TYPES.graph
                                        ? "0%"
                                        : "60%"
                                }
                                currentUser={currentUser}
                                usersList={usersList}
                                megaUsersId={megaUsersIds}
                                isReadOnlyMode={isReadOnlyMode}
                                wbsId={wbsId}
                                editSideBar={state.editSideBar}
                                viewMode={state.viewMode}
                                onClickAdd={() => onAddEditClick()}
                                onExpandArrowClick={onExpandArrowClick}
                                onItemClick={onItemClick}
                                scheduleDispatcher={scheduleDispatcher}
                            />
                        </React.Fragment>
                    )}
                </WbsElementStructureBody>
            </WbsElementBigStructure>

            {/* EDIT SIDE BAR ---------------------------------------------------------------------- */}
            <EditSideBar
                group={
                    state.actionsOperationType ? ACTION_GROUP_ITEMS : []
                }
                display={state.editSideBar}
                content={state.editSideBarContent}
                title={`
						${
                            state.actionsOperationType
                                ? `${
                                      state.actionsOperationType ===
                                      RISK_OPP_CHANGE_ACTIONS_TYPES.edit
                                          ? "Update"
                                          : "Create"
                                  } Action`
                                : `${
                                      state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add
                                          ? "Create"
                                          : "Update"
                                  } Schedule`
                        }
				`}
                onSave={state.actionsOperationType ? onSaveAddAction : onSaveAddEdit}
                firstAddUpdatedFields={
                    state.actionsOperationType
                        ? ["responsible", "dueDate", "status", "links"]
                        : [
                              "responsible",
                              "baselineStart",
                              "baselineStart",
                              "baselineFinish",
                              "forecastStart",
                              "forecastFinish",
                              "type",
                              "events",
                              "comments",
                              "description",
                          ]
                }
                resetEditSideBar={onResetEditSideBar}
                currentUser={currentUser}
                isCurrentUserEditor={securityGroup.isCurrentUserEditor}
                projectData={projectData}
            />
        </React.Fragment>
    );
}
