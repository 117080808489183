//PACKAGES
import React, { useMemo, memo } from "react";

//CORE
import { FUNC_ACTION_IS_LATE } from "./00-Helpers/ActionsFunctions";

// CONSTANTS
import { ACTION_STATUS_KEY } from "./00-Helpers/ActionsConstants";
import { CvtPillKPI, CvtTooltip } from "@mi-gso/cvt";

/////////////////////////////
/// ACTION MINI COMPONENT ///
/////////////////////////////

export default memo(function ActionMini({
  data,
  bigComponentDisplayed,
  propKey,
  cyId
}) {
  /////////////
  /// COUNT ///
  /////////////

  const actionsDisplayCount = useMemo(() => {
    if (propKey) {
      // INIT
      let returnActionsCount = {
        notClosed: 0,
        todo: 0,
        inProgress: 0,
        late: 0,
        onHold: 0,
        closed: 0,
      };

      // Here calculate a new object to return containing all actions display counts
      if (data != null && data.length > 0) {
        // Use the "status" property of the actions to show the overall status of the actions in the tooltip
        data.forEach((action) => {
          //DONE
          if (action.status === ACTION_STATUS_KEY.done) {
            returnActionsCount.closed++;

            //ON HOLD
          } else if (action.status === ACTION_STATUS_KEY.onHold) {
            returnActionsCount.notClosed++;
            returnActionsCount.onHold++;

            //LATE
          } else if (FUNC_ACTION_IS_LATE(action.status, action.dueDate)) {
            returnActionsCount.notClosed++;
            returnActionsCount.late++;

            //TODO
          } else if (action.status === ACTION_STATUS_KEY.todo) {
            returnActionsCount.notClosed++;
            returnActionsCount.todo++;

            //IN PROGRESS
          } else if (action.status === ACTION_STATUS_KEY.inProgress) {
            returnActionsCount.notClosed++;
            returnActionsCount.inProgress++;
          }
        });
      }

      //RETURN OBJECT
      return returnActionsCount;
    }
    return null;
  }, [data, propKey]);

  ///////////////////////
  /// TOOLTIP CONTENT ///
  ///////////////////////

  const tooltipContent = useMemo(() => {
    return (
      <div className="flexColStartStart">
        {/* OPEN */}
        <span className="actionMiniTooltipTitle">
          Not Closed: <b>{actionsDisplayCount.notClosed}</b>
        </span>

        {/* ON TRACK */}
        <span className="actionMiniTooltipSubTitle">
          To Do: <b>{actionsDisplayCount.todo}</b>
        </span>

        {/* In PROGRESS */}
        <span className="actionMiniTooltipSubTitle">
          In Progress: <b>{actionsDisplayCount.inProgress}</b>
        </span>

        {/* LATE */}
        <span className="actionMiniTooltipSubTitle">
          Late: <b>{actionsDisplayCount.late}</b>
        </span>

        {/* ON HOLD */}
        <span className="actionMiniTooltipSubTitle">
          On Hold: <b>{actionsDisplayCount.onHold}</b>
        </span>

        {/* CLOSED */}
        <span className="actionMiniTooltipTitle">
          Closed: <b>{actionsDisplayCount.closed}</b>
        </span>
      </div>
    );
  }, [
    actionsDisplayCount.closed,
    actionsDisplayCount.inProgress,
    actionsDisplayCount.late,
    actionsDisplayCount.notClosed,
    actionsDisplayCount.onHold,
    actionsDisplayCount.todo,
  ]);

  // WHETHER TO DISPLAY SOMETHING OR NOT
  const isTooltip = useMemo(() => {
    return actionsDisplayCount.notClosed > 0 || actionsDisplayCount.closed > 0;
  }, [actionsDisplayCount.closed, actionsDisplayCount.notClosed]);

  //////////////
  /// RENDER ///
  //////////////

  return (
    <>
      <CvtPillKPI
        values={[
          {
            value:
              actionsDisplayCount.notClosed > 0
                ? actionsDisplayCount.notClosed
                : "",
          },
          {
            value:
              actionsDisplayCount.late > 0
                ? actionsDisplayCount.late
                : actionsDisplayCount.onHold > 0
                ? 0
                : null,
            color: "var(--color-bad)",
          },
          {
            value:
              actionsDisplayCount.onHold > 0 ? actionsDisplayCount.onHold : 0,
            color: "var(--color-migso-yeallow)",
          },
        ]}
        minWidth="60px"
        tooltipId={propKey}
        testId={cyId}
      />

      {/* TOOLTIP */}
      {isTooltip ?
        <CvtTooltip
          anchorId={propKey}
          place="right"
        >
          {tooltipContent}
        </CvtTooltip>
      :null}
    </>
  );
});
