import { CvtButton } from '@mi-gso/cvt'
import { memo } from 'react'

export default memo(function ActionLinksSideBarListItem ({
  link,
  itemData,
  onEditItem,
  onDeleteLinkItem,
  icon,
  isAuthorized
}) {
  return (
    <div
      className='flexBetweenCenter rootElementHoverHide actionTableItemContainer'
      style={{
        borderBottom: '1px solid var(--border-color-gray-0)',
        padding: '5px 10px',
        minHeight: '50px'
      }}
    >
      {/* LEFT */}
      <div>
        {/* ITEM */}
        <div className='flexStartCenter text_Color3_400_13px'>
          {/* ICON */}
          <span
            className='material-icons'
            style={{
              fontSize: '16px',
              marginRight: '5px'
            }}
          >
            {icon}
          </span>

          {/* ITEM TITLE */}
          {itemData ? itemData.label : ''}
        </div>
      </div>

      {/* RIGHT PART : ACTIONS */}
      {isAuthorized ? (
        <div className='childElementHoverHide flexStartCenter'>
          {/* EDIT */}

          <CvtButton
            color={'gray'}
            icon='edit'
            shadow='none'
            size='small'
            txt=''
            type='button'
            margin='0px 10px 0px'
            handleSubmit={onEditItem}
            handleSubmitOptions={{
              id: link.id
            }}
          />

          {/* DELETE */}

          <CvtButton
            confirmType='danger'
            icon='delete'
            needConfirmation
            shadow='none'
            size='small'
            type='button'
            handleSubmit={onDeleteLinkItem}
            handleSubmitOptions={{
              id: link.id
            }}
          />
        </div>
      ) : null}
    </div>
  )
})
