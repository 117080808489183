import React from "react";
import {AuthAuthenticator} from './AuthAuthenticator'

//COMPONENT LOADER
export default function AuthAuthenticatorWithMessage({
    restrictedMessage,
    currentUser,
    appDispatcher
}){

    return(
        <div className="signInBlock">

            {/* WITH RESTRICTED MESSAGE */}
            <div className="signInMessage alertContent alertContentGlobal flexStartCenter">
                <span className="material-icons alertIcon alertIconInfo">
                    warning
                </span>
                {restrictedMessage}
            </div>

            <AuthAuthenticator 
                currentUser={currentUser} 
                appDispatcher={appDispatcher}
            />
        </div>
    )
}

