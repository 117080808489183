import React from "react";
import { HomeAnimationsFadeIn } from "./HomeAnimations";


export default function HomeRegularPage({
    isVisible, //From the parent
    title,
    children,
}) {
    //////////////
	/// RETURN ///
	//////////////
    return  (
        <React.Fragment>
            <HomeAnimationsFadeIn
                isVisible={isVisible}
                from="left"
                delay={500}
			>
                <span className="gradientText">
                    {title}
                </span>
            </HomeAnimationsFadeIn>
            <HomeAnimationsFadeIn
                isVisible={isVisible}
                from="right"
                delay={750}
			>
                {children}
            </HomeAnimationsFadeIn>
        </React.Fragment>
    )
}