import GetStarted from "./00-GetStarted"
import ProjectCharter from "./01-01-ProjectCharter"
import Actions from "./01-02-Actions"
import RiskNOpps from "./01-03-RiskNOpps"
import Schedule from "./01-04-Schedule"
import Governance from "./01-05-Governance"
import Shortcuts from "./01-06-Shortcuts"
import GeneralSidebar from "./01-07-GeneralSidebar"
import FocusForTheDay from "./02-01-FocusForTheDay"
import Browsing from "./02-02-Browsing"


//VERSION FOLLOW UP
import appVersion120 from "./VERSION-1.2.0"
import appVersion130 from "./VERSION-1.3.0"
import appVersion131 from "./VERSION-1.3.1"
import appVersion132 from "./VERSION-1.3.2"
import appVersion140 from "./VERSION-1.4.0"
import appVersion141 from "./VERSION-1.4.1"

//SEE FOR UNDERSTAND MARKDOWN
//https://commonmark.org/help/ 

export const DOCUMENTATION_INIT_FILE = {
    id:"init",
    parentId: null,
    level:0,
    title: "Get Started",
    icon: "flag",
    file: GetStarted
}

//OBJECT WITH JSX TRANSFORMED FILE + ATTRIBUTES
const DOCUMENTATION_FILES = [
    {
        id:"CONTROLS",
        parentId: null,
        level:0,
        title: "Controls",
        icon: "list",
    },
    {
        id:"CONTROLSSUB1",
        parentId: "CONTROLS",
        level:1,
        title: "Project Charter",
        icon: "computer",
        file: ProjectCharter
    },
    {
        id:"CONTROLSSUB2",
        parentId: "CONTROLS",
        level:1,
        title: "Actions",
        icon: "computer",
        file: Actions
    },
    {
        id:"CONTROLSSUB3",
        parentId: "CONTROLS",
        level:1,
        title: "Risks & Opportunities",
        icon: "content_copy",
        file: RiskNOpps
    },
    {
        id:"CONTROLSSUB4",
        parentId: "CONTROLS",
        level:1,
        title: "Schedule",
        icon: "storage",
        file: Schedule
    },
    {
        id:"CONTROLSSUB5",
        parentId: "CONTROLS",
        level:1,
        title: "Governance",
        icon: "layers",
        file: Governance
    },
    {
        id:"CONTROLSSUB6",
        parentId: "CONTROLS",
        level:1,
        title: "Shortcuts",
        icon: "layers",
        file: Shortcuts
    },
    {
        id:"CONTROLSSUB7",
        parentId: "CONTROLS",
        level:1,
        title: "General Sidebar",
        icon: "layers",
        file: GeneralSidebar
    },
    {
        id:"BOARD",
        parentId: null,
        level:0,
        title: "Board",
        icon: "cake",
    },
    {
        id:"BOARDSUB1",
        parentId: "BOARD",
        level:1,
        title: "Focus for the day",
        icon: "keyboard_double_arrow_left",
        file: FocusForTheDay 

    },
    {
        id:"BOARDSUB2",
        parentId: "BOARD",
        level:1,
        title: "Browsing",
        icon: "rule",
        file: Browsing 

    },
    //VERSION MGT
    {
        id:"APPVERSION",
        parentId: null,
        level:0,
        title: "Telescope Version Follow-Up",
        icon: "list",
    },
    {
        id:"APPVERSION_1.4.1",
        parentId: "APPVERSION",
        level:1,
        title: "Version 1.4.1",
        icon: "folder_special",
        file: appVersion141
    },
    {
        id:"APPVERSION_1.4.0",
        parentId: "APPVERSION",
        level:1,
        title: "Version 1.4.0",
        icon: "folder_special",
        file: appVersion140
    },
    {
        id:"APPVERSION_1.3.2",
        parentId: "APPVERSION",
        level:1,
        title: "Version 1.3.2",
        icon: "folder_special",
        file: appVersion132
    },
    {
        id:"APPVERSION_1.3.1",
        parentId: "APPVERSION",
        level:1,
        title: "Version 1.3.1",
        icon: "folder_special",
        file: appVersion131
    },
    {
        id:"APPVERSION_1.3.0",
        parentId: "APPVERSION",
        level:1,
        title: "Version 1.3.0",
        icon: "folder_special",
        file: appVersion130
    },
    {
        id:"APPVERSION_1.2.0",
        parentId: "APPVERSION",
        level:1,
        title: "Version 1.2.0",
        icon: "folder_special",
        file: appVersion120
    }
]

//EXPORT DEFAULT 
export default DOCUMENTATION_FILES
