//LOCAL CONFIG
import envLocalConfig from './envLocalConfig.json'

//GET ALL ENV VAR 
export const envVarManagement = (name) =>{

    //INIT
    let envVar = null;

    //SWITCH
    switch (name) {

        //ENVIRONMENT NAME
        case "env":
            envVar = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : envLocalConfig.ENV
            break;

        //ENVIRONMENT NAME
        case "envSignUp":
            let isSignUpActivated = process.env.REACT_APP_SIGN_UP ? process.env.REACT_APP_SIGN_UP : envLocalConfig.SIGN_UP;
            envVar = isSignUpActivated === "true";
            break;

        //ENVIRONEMENT SERVICES
        case "envServices":
            envVar = process.env.REACT_APP_SERVICES ? JSON.parse(process.env.REACT_APP_SERVICES) : envLocalConfig.SERVICES;
            break;

        //ENVIRONEMENT COOKIE DOMAIN
        case "envCookieDomain":
            envVar = process.env.REACT_APP_COOKIE_DOMAIN ? process.env.REACT_APP_COOKIE_DOMAIN : envLocalConfig.COOKIE_DOMAIN;
            break;

        //ENVIRONEMENT TERMS VERSION
        case "envTermsVersion":
            envVar = process.env.REACT_APP_TERMS_VERSION ? process.env.REACT_APP_TERMS_VERSION : envLocalConfig.TERMS_VERSION;
            break;

        //ENVIRONEMENT IS MFA MANDATORY
        case "envMfaMandatory":
            let isMandatoryMFA = process.env.REACT_APP_MFA_MANDATORY ? process.env.REACT_APP_MFA_MANDATORY : envLocalConfig.MFA_MANDATORY
            envVar = isMandatoryMFA === "true"
            break;

        //ENVIRONMENT IS INDIVIDUAL LICENSE FORCED
        case "envInd":
            let isIndActivated = process.env.REACT_APP_IND_LICENSE ? process.env.REACT_APP_IND_LICENSE : envLocalConfig.IND_LICENSE;
            envVar = {
                activated: isIndActivated === "true",
                personalOrganizationId: process.env.REACT_APP_PERSONAL_ORGANIZATION_ID ? process.env.REACT_APP_PERSONAL_ORGANIZATION_ID : envLocalConfig.PERSONAL_ORGANIZATION_ID
            }
            break;

        //ENVIRONMENT IS INDIVIDUAL LICENSE FORCED
        case "envSso":

            //INIT
            envVar = {};

            //CONNECTION
            let isSsoActivated = process.env.REACT_APP_SSO_ACTIVE ? process.env.REACT_APP_SSO_ACTIVE : envLocalConfig.SSO_ACTIVE;
            envVar.active = isSsoActivated === "true";

            //GET PARAMS AND LIST IF IT'S ACTIVE
            if(envVar.active){
                envVar.params = process.env.REACT_APP_SSO_PARAMS ? JSON.parse(process.env.REACT_APP_SSO_PARAMS) : envLocalConfig.SSO_PARAMS;
                envVar.list = process.env.REACT_APP_SSO_LIST ? JSON.parse(process.env.REACT_APP_SSO_LIST) : envLocalConfig.SSO_LIST;
            }
            break;

        //ENVIRONEMENT CORE API
        case "envCoreApi":
            envVar = process.env.REACT_APP_CORE_API ? JSON.parse(process.env.REACT_APP_CORE_API) : envLocalConfig.CORE_API;
            break;

        //ENVIRONEMENT CORE API
        case "envMappingApi":
            envVar = process.env.REACT_APP_MAPPING_API ? JSON.parse(process.env.REACT_APP_MAPPING_API) : envLocalConfig.MAPPING_API;
            break;

        //ENVIRONEMENT MAPPING STORAGE
        case "envMappingStorage":
            envVar = process.env.REACT_APP_MAPPING_STORAGE ? JSON.parse(process.env.REACT_APP_MAPPING_STORAGE) : envLocalConfig.MAPPING_STORAGE;
            break;

        //ENVIRONEMENT MEGA USER
        case "envMegaUsers":
            envVar = process.env.REACT_APP_MEGA_USERS ? JSON.parse(process.env.REACT_APP_MEGA_USERS) : envLocalConfig.MEGA_USERS;
            break;

        //ERROR
        default:
            break;
    }

    //RETURN
    return envVar
}