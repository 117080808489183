// REACT
import { memo, useCallback, useMemo, useState } from "react";

// CORE COMPONENTS
import {CvtSideBarButton} from "@mi-gso/cvt";

// CONSTANTS
import { OPP_STRATEGY_LIST, RISK_STRATEGY_LIST } from "../../../00-Helpers/RiskConstants";

// RISK COMPONENTS
import RiskTableItemStrategyTag from "../../RiskTable/RiskTableItemStrategyTag";

////////////////////////////////////////////
/// RISK CARD ACTIONS TABLE HEADERS ////////
////////////////////////////////////////////

export default memo(function RiskCardActionsTableHeaders({
  // DATA
  selectedItems,
  headersBackground,
  headerBottomBorder,
  roItem,
  filteredActionsLength,
  isCurrentUserEditor,
  currentUserId,

  // FUNCTIONS
  handleEditAction,
  handleDuplicateClick,
  handleDeleteClick,
  handleEditRoItem,
  handleMoveItemsClicked,
}) {
  //////////////////////////////////////////////////////////////////////////
  /// STATE /////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  //INIT
  const [confirm, setConfirm] = useState({
    value: false,
    type: "",
  });

  //////////////////////////////////////////////////////////////////////////
  /// FUNCTIONS /////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  //HANDLE DELETE
  const handleAction = useCallback(
    (e, actionType, reset) => {
      e.preventDefault();

      //RESET
      if (reset) {
        setConfirm({
          value: false,
          type: "",
        });

        //ALREADY CONFIRM CAN DELETE
      } else if (confirm.value) {
        //TYPE
        switch (actionType) {
          //DUPLICATE
          case "duplicate":
            handleDuplicateClick(e);
            break;

          //DELETE
          case "delete":
            handleDeleteClick(e);
            break;

          default:
            break;
        }

        //UPDATE STATE
        setConfirm({
          value: false,
          type: "",
        });

        //NEED CONFIRM
      } else {
        setConfirm({
          value: true,
          type: actionType,
        });
      }
    },
    [confirm.value, handleDeleteClick, handleDuplicateClick]
  );

  //////////////////////////////////////////////////////////////////////////
  /// MEMO /////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  const canModifyActions = useMemo(() => {
    if(filteredActionsLength.length === 0 || selectedItems.length === 0 || !roItem) return false;
    
    for(const action of selectedItems) {
        if(action.responsible !== currentUserId && !isCurrentUserEditor) {
            return false;
        }
    }
    return true;
}, [currentUserId, filteredActionsLength.length, isCurrentUserEditor, roItem, selectedItems]);

  
  //////////////////////////////////////////////////////////////////////////
  /// RENDER ///////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  return (
    <div
      className="riskBigViewModeRelatedActionTableHeaders flexStartCenter"
      style={{
        background: headersBackground ? headersBackground : null,
        borderBottom: headerBottomBorder ? headerBottomBorder : null,
        paddingLeft: "8px",
      }}
    >
      {/* TITLE + BUTTONS */}
      
        {/* TITLE */}
        <span
          style={{
            fontWeight: '600',
            marginLeft: '5px',
            fontSize: '14px',
          }}
        >
          Action Plan
        </span>

        {/* STRATEGY + TOGGLE */}

        <div style={{width: '10px'}}></div>
        {/* STRATEGY TAG */}
        <RiskTableItemStrategyTag
          strategyInfo={roItem ? roItem.isRisk===true ? RISK_STRATEGY_LIST.find(
            (strategy) => strategy.value === roItem.strategy
          ) : OPP_STRATEGY_LIST.find((strategy) => strategy.value === roItem.strategy) : null}
        />
    

        {/* BUTTONS */}
        <div className="flexStartCenter" style={{ marginLeft: "5px" }}>
          {/* EDIT */}
          {
            canModifyActions && selectedItems.length === 1 ? (

            <CvtSideBarButton
              buttonClass="flexCenterCenter buttonSideBar"
              id="tooltipEditBig"
              icon="edit"
              fontSize="22px"
              iconPadding="0px"
              margin="0px 0px 0px 4px"
              backgroundColor="transparent"
              title="Edit Action"
              onClickFunction={() => handleEditAction(selectedItems[0])}
            />)
          : null}

          {/* DUPLICATE */}
          {selectedItems.length > 0 && filteredActionsLength > 0 &&
            roItem && (roItem.responsible === currentUserId || isCurrentUserEditor)
          ? (
            <CvtSideBarButton
              buttonClass={`
                flexCenterCenter buttonSideBar  roCardActionsTableHeadersBtn
                ${
                  confirm.value && confirm.type === "duplicate"
                    ? "buttonSideBarAlert"
                    : "backgroundTransparent"
                }
              `}
              id="tooltipDuplicateBig"
              icon="copy_all"
              fontSize="22px"
              margin="0px 0px 0px 4px"
              title={
                confirm.value && confirm.type === "duplicate"
                  ? "Are you sure?"
                  : `Duplicate action${selectedItems.length > 1 ? "s" : ""}`
              }
              onClickFunction={(e) => handleAction(e, "duplicate")}
              onBlur={(e) => handleAction(e, "duplicate", true)}
              onBlurConfirmation={
                confirm.value && confirm.type === "duplicate"
                  ? confirm.value
                  : false
              }
            />)
          : null}

          {/* DELETE */}
          {canModifyActions && selectedItems.length > 0 ? (
            <CvtSideBarButton
              buttonClass={`
                flexCenterCenter buttonSideBar 
                ${
                  confirm.value && confirm.type === "delete"
                    ? "buttonSideBarConfirmDelete"
                    : "backgroundTransparent"
                }
              `}
              id="tooltipDeleteBig"
              icon="delete"
              margin="0px 0px 0px 4px"
              
              fontSize="22px"
              title={
                confirm.value && confirm.type === "delete"
                  ? "Are you sure?"
                  : `Delete action${selectedItems.length > 1 ? "s" : ""}`
              }
              onClickFunction={(e) => handleAction(e, "delete")}
              onBlur={(e) => handleAction(e, "delete", true)}
              onBlurConfirmation={
                confirm.value && confirm.type === "delete"
                  ? confirm.value
                  : false
              }
            />)
          : null}

          {/* MOVE */}
          {
            canModifyActions && roItem && (roItem.responsible === currentUserId || isCurrentUserEditor) ?

              <CvtSideBarButton 
                buttonClass='flexCenterCenter buttonSideBar'
                id="tooltipMoveBig"
                icon="move_down"
                fontSize="22px"
                title="Move Items"
                onClickFunction={handleMoveItemsClicked}
              />
          :null}
        </div>
    </div>
  );
});
