// REACT
import { memo, useCallback } from "react";

// RISK CONSTANTS
import {
    RISK_OPP_PROBABILITY_LIST,
    RISKS_OPPS_MATRIX_SETTINGS,
    RISK_MATRIX_VIEW_MODE,
    RISK_OPP_INVERTED_IMPACT_LIST,
} from "../../../00-Helpers/RiskConstants";

// BEATIFUL DND
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// RISK COMPONENTS
import RiskCardMatrixItem from "./RiskCardMatrixItem";

// MIGSO
// import { Button } from "@mi-gso/cvt";

/////////////////////////////////////////////////////////////
/// RISK CARD MATRIX ////////////////////////////////////////
/////////////////////////////////////////////////////////////

export default memo(function RiskCardMatrix({
    flipCard,
    // DATA
    roItem,
    // FUNCTIONS
    handleDrop,
    handleSwitchingView,
}) {
    /////////////////////////////////////////////////////////
    /// FUNCTIONS ///////////////////////////////////////////
    /////////////////////////////////////////////////////////

    // GETS MODES ACCORDING TO GIVEN CRITICITY
    const getModes = useCallback(
        (criticity) => {
            // INIT
            let modesToReturn = [];
            // IF CRITICITY IS SAME AS INITIAL CRITICITY
            if (roItem && criticity === roItem.initialCriticity) {
                modesToReturn.push(RISK_MATRIX_VIEW_MODE.initial);
            }
            // IF CRITICITY IS SAME AS CURRENT CRITICITY
            if (roItem && criticity === roItem.currentCriticity) {
                modesToReturn.push(RISK_MATRIX_VIEW_MODE.current);
            }
            // IF CRITICITY IS SAME AS TARGET CRITICITY
            if (roItem && criticity === roItem.targetCriticity) {
                modesToReturn.push(RISK_MATRIX_VIEW_MODE.target);
            }

            // RETURN
            return modesToReturn;
        },
        [roItem]
    );

    /////////////////////////////////////////////////////////////
    /// COMPONENT RENDER ///////////////////////////////////////
    ////////////////////////////////////////////////////////////

    return (
        <div
            key={`risk_card_matrix_${roItem && roItem.isRisk}`}
            className="riskBigViewModeSingleMatrixContainer flexColStartCenter flipContainerFront"
            style={{ zIndex: flipCard ? -1 : 1 }}
        >
            {/* HEADERS */}
            <div
                className="flexBetweenCenter"
                style={{
                    width: "100%",
                    borderBottom: "1px solid var(--border-color-gray-1)",
                    padding: "4px",
                }}
            >
                <div className="flexStartCenter">
                    {/* ICON */}
                    <span className="material-icons actionsChartHeaderIcon">balance</span>
                    {/* TITLE */}
                    <span
                        className="actionsChartHeaderTitle"
                        style={{
                            fontSize: "13px",
                        }}
                    >
                        Mapping
                    </span>
                </div>

                {/* MODAL CHECKBOX */}
                {/* <Button
          icon='functions'
          iconCss='switchViewBtnIcon'
          css='switchViewBtnContainer'
          handleSubmit={handleSwitchingView}
          txt='Calculation View'
          fontSize='12px'
        /> */}
            </div>

            <div
                className="flexStartStart flexOne"
                style={{
                    width: "100%",
                    backgroundColor: "white",
                    padding: "5px 0px",
                }}
            >
                {/* LEFT SIDE (IMPACT LEGEND) */}
                <div className="flexCenterCenter" style={{ height: "83%", minHeight: "200px" }}>
                    <span className="riskMatrixLegend" style={{ transform: "rotate(-90deg)" }}>
                        Impact
                    </span>
                    {/* MATRIX LEGENDs */}
                    <div className="flexColStartStart" style={{ height: "100%", maxWidth: "30px" }}>
                        {RISK_OPP_INVERTED_IMPACT_LIST.map((impact, index) => (
                            <div
                                key={`risk_matrix_legends_container_${impact.value}_${index}`}
                                className="riskMatrixSmallLegend flexCenterCenter"
                            >
                                <span style={{ transform: "rotate(-90deg)", whiteSpace: "nowrap" }}>
                                    {impact.value}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    className="flexColStartCenter flexOne"
                    style={{ height: "100%", paddingRight: "10px" }}
                >
                    {/* MATRIX */}
                    <DragDropContext onDragEnd={handleDrop}>
                        <div className="riskMatrix">
                            {RISKS_OPPS_MATRIX_SETTINGS.map((matrixSetting, index) => {
                                let modes = getModes(matrixSetting.criticity);

                                return (
                                    <Droppable
                                        droppableId={`risk_view_matrix_cell_${matrixSetting.criticity.toString()}`}
                                        key={`risk_view_matrix_cell_${matrixSetting.criticity.toString()}_${index}`}
                                    >
                                        {(provided, snapshot) => (
                                            <RiskCardMatrixItem
                                                key={`risk_matrix_item_${matrixSetting.criticity}_${index}`}
                                                matrixSetting={matrixSetting}
                                                isRisk={roItem ? roItem.isRisk === true : false}
                                                providedPlaceholder={provided.placeholder}
                                                isDragOver={snapshot.isDraggingOver}
                                                modes={modes}
                                                droppableProps={provided.droppableProps}
                                                droppableRef={provided.innerRef}
                                                handleSwitchingView={handleSwitchingView}
                                            />
                                        )}
                                    </Droppable>
                                );
                            })}
                        </div>
                    </DragDropContext>

                    {/* BOTTOM LEGEND */}
                    <div style={{ width: "100%" }}>
                        {/* MATRIX BOTTOM LEGENDS */}
                        <div className="flexStartStart">
                            {RISK_OPP_PROBABILITY_LIST.map((prob, index) => (
                                <div
                                    key={`risk_matrix_bottom_legend_${prob.value}_${index}`}
                                    className="riskMatrixSmallLegend flexCenterCenter"
                                >
                                    <span style={{ height: "8px" }}>{prob.label}</span>
                                </div>
                            ))}
                        </div>

                        {/* LIKELIHOOD LEGEND */}
                        <div
                            className="flexCenterCenter"
                            style={{
                                paddingBottom: "2px",
                                width: "100%",
                            }}
                        >
                            <span className="riskMatrixLegend">Likelihood</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
