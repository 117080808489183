import React, { useCallback, useMemo, useState } from 'react'

//CORE
import {
  CvtSideBar,
  CvtSideBarButtonOpen,
  CvtSideBarGroup,
  CvtSideBarButton
} from '@mi-gso/cvt'
import Filters from '../08-Filters/Filters';
import {
  APP_STRUCTURE, APP_TXT_MEGA_USERS
} from '../../../00-Core/Constants'
////////////////////////
/// IMPORT COMPONENT ///
////////////////////////

export default function WbsSideBar ({
  usersList,
  wbsFilters,
  isPortfolio,
  portfolioEditors,
  selectedProject,
  currentUser,
  dataConsolidation,
  appDispatcher,
  projectData,
}) {

  /////////////////////////////////////////////////////////////////////
  /// STATE ///////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  //STATE
  const [state, setState] = useState({
    isExtended: false,
    isFiltersOpen: false,
    wasExtendedWithoutFilter: false
  });

  /////////////////////////////////////////////////////////////////////
  /// FUNCTION ////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  //HERE COMPONENT FUNCTION
  const handleDisplaySideBar = useCallback((e) => {
    e.preventDefault()
    
    //APP STATE
    appDispatcher({
      type: "SET_GLOBAL_STATE",
      options:{
      source: "appSettings",
          object: {
              sideBarOpenWidth: state.isExtended ? 0 : (APP_STRUCTURE.sideBarWidthBig - 5)
          },
      }
    })

    //THIS STATE
    setState(prevState => {
      return { 
        ...prevState, 
        isExtended: !prevState.isExtended, 
        isFiltersOpen: false,
        wasExtendedWithoutFilter: !prevState.isExtended === true ? true : false
      }
    })
  }, [appDispatcher, state.isExtended])

  //OPEN WBS SETTINGS
  const handleDisplayWbsSettings = useCallback((e) => {
      e.preventDefault()
      appDispatcher({
        type: 'SET_GLOBAL_STATE',
        options: {
          source: 'appSettings',
          object: {
            actionModal: 'wbsSettings'
          }
        }
      })
  },[appDispatcher])

  // OPEN WBS FILTER
  const handleOpenWbsFilter = useCallback((e) => {
    e.preventDefault()

    //APP STATE
    appDispatcher({
      type: "SET_GLOBAL_STATE",
      options:{
      source: "appSettings",
          object: {
              sideBarOpenWidth: !state.isFiltersOpen ? (APP_STRUCTURE.sibeBarWidthFilter - 5) : state.wasExtendedWithoutFilter ? (APP_STRUCTURE.sideBarWidthBig - 5) : 0 
          },
      }
    })

    //THIS STATE
    setState(prevState => {
      return { 
        ...prevState, 
        isExtended: !prevState.isFiltersOpen === false ? prevState.wasExtendedWithoutFilter : true, 
        isFiltersOpen: !prevState.isFiltersOpen
      };
    })
  },[appDispatcher, state.isFiltersOpen, state.wasExtendedWithoutFilter]);

  /////////////////////////////////////////////////////////////////////
  /// DATA ////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  const displayWbsSettings = useMemo(() => {

    //INIT
    let test = false

    //USER GROUP
    let currentUserGroup = []
    if(currentUser.user
      && currentUser.user.signInUserSession
      && currentUser.user.signInUserSession.accessToken.payload["cognito:groups"]){
          currentUserGroup = currentUser.user.signInUserSession.accessToken.payload["cognito:groups"]
      }
    
    //IF MEGA USER OK
    if(currentUserGroup?.includes(APP_TXT_MEGA_USERS)){
      test = true

    //TES IF PORTFOLIO
    }else if(isPortfolio && portfolioEditors?.includes(currentUser.username)){
      test = true

    //TEST PROJECT
    }else if(selectedProject && currentUserGroup.length > 0){

      //INIT
      let groupEditors = [
        selectedProject.organizationMegaUsersGroup,
        selectedProject.projectMegaUsersGroup
      ]

      //LOOP ON GROUPEDITORS NEED A LEAST ONE
      for (let i = 0; i < groupEditors.length; i++) {
                
        //FIND
        if(currentUserGroup?.includes(groupEditors[i])){
          test = true
          break;
        }
      }
    }

    //RETURN
    return test
  }, [currentUser.user, currentUser.username, isPortfolio, portfolioEditors, selectedProject])

  /////////////////////////////////////////////////////////////////////
  /// GRID COMPONENT RETURN ///////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  return (
    <CvtSideBar 
      sideBar={state.isExtended} 
      sideBarWidthBig={state.isFiltersOpen ? APP_STRUCTURE.sibeBarWidthFilter : null}
    >
      {/* SWITCH TO BIG ---------------------------------------------------- */}
      {!state.isFiltersOpen ? 
        <CvtSideBarButtonOpen
          sideBar={state.isExtended}
          handleDisplaySideBar={handleDisplaySideBar}
        />
      :null}

      {/* IF FILTERS SIDE BAR IS NOT OPEN */}
      {!state.isFiltersOpen ? 
        <React.Fragment>
          <CvtSideBarGroup title='Set'>

            {/* OPEN */}
            {/* <CvtSideBarButton
              sideBar={state.isExtended}
              buttonClass='flexCenterCenter buttonSideBar'
              id='tooltipOpenProject'
              icon='file_open'
              title='Open Project'
              onClickFunction={() => console.log('open')}
            /> */}

            {/* FILTER */}
            <CvtSideBarButton
              sideBar={state.isExtended}
              buttonClass='flexCenterCenter buttonSideBar'
              id='tooltipFilterProject'
              icon='filter_alt'
              title='Filters'
              onClickFunction={handleOpenWbsFilter}
            />

            {/* SETTINGS */}
            {displayWbsSettings ?
              <CvtSideBarButton
                sideBar={state.isExtended}
                buttonClass='flexCenterCenter buttonSideBar'
                id='tooltipPersonalSettings'
                icon='settings'
                title='Settings'
                onClickFunction={handleDisplayWbsSettings}
              />
            :null}

            {/* SHARE */}
            {/* <CvtSideBarButton
              sideBar={state.isExtended}
              buttonClass='flexCenterCenter buttonSideBar'
              id='tooltipShareProject'
              icon='ios_share'
              title='Sharing Options'
              onClickFunction={() => console.log('export')}
            /> */}
            
          </CvtSideBarGroup>

          {/* DATA GROUP ------------------------------------------------------- */}
          {/* <CvtSideBarGroup title='View'> */}
            {/* ACCESS */}
            {/* <CvtSideBarButton
              sideBar={state.isExtended}
              buttonClass='flexCenterCenter buttonSideBar'
              id='tooltipUserProject'
              icon='people_alt'
              title='Access Management'
              onClickFunction={() => console.log('user')}
            /> */}

            {/* SETTINGS */}
            {/* <CvtSideBarButton
              sideBar={state.isExtended}
              buttonClass='flexCenterCenter buttonSideBar'
              id='tooltipPersonalSettings'
              icon='settings'
              title='Settings'
              onClickFunction={handleDisplayWbsSettings}
            /> */}
          {/* </CvtSideBarGroup> */}
        </React.Fragment>
      : 
        <Filters
          wbsFilters={wbsFilters}
          usersList={usersList}
          isPortfolio={isPortfolio}
          handleOpenWbsFilter={handleOpenWbsFilter}
          dataConsolidation={dataConsolidation}
          appDispatcher={appDispatcher}
          projectData={projectData}
        />
    }
    </CvtSideBar>
  )
}
