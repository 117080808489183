// LIBRARIES
import React, { memo, useCallback, useEffect, useMemo, useReducer } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

// CORE CONSTANTS
import {
	DEFAULT_STATE_MOVE_MODAL_OPTIONS,
	TODAY,
	TODAY_PLUS_30D,
} from '../../../00-Core/Constants'

// CORE COMPONENTS
import WbsElementBigStructure from '../00-Wbs/01-Components/WbsElementBigStructure'

import WbsElementStructureBody from '../00-Wbs/01-Components/WbsElementStructureBody'
import EditSideBar from '../07-EditSideBar/EditSideBar'
import { EDIT_SIDE_BAR_DATA_PREPARATION } from '../07-EditSideBar/00-Helpers/EditSideBarFunctions'

// CORE FUNCTIONS
import {
	FUNCT_FIND_INDEX,
	FUNCT_FIND_INDEX_ARRAY,
	FUNC_GET_USER_INFO,
	FUNC_NEED_TO_ADD_EVENT,
	FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING,
	FUNC_ZERO_FORMAT_TO_NUM,
} from '../../../00-Core/Standards'

// R&O FUNCTIONS
import {
	getCriticityFromImpProba,
	getImpactProbability,
	RO_GET_DEFAULT_ITEM,
	RO_GET_DEFAULT_STATE,
} from './00-Helpers/RisksFunctions'

// RISK&OPP CONSTANTS
import {
	OPP_STRATEGY_LIST,
	RISK_OPP_CHANGE_ACTIONS_TYPES,
	RISK_OPP_DISPLAY_ID_PREFIX,
	RISK_OPP_EXPAND_MODES,
	RISK_OPP_GROUP_ITEMS,
	RISK_OPP_ITEM_DATA_STRUCTURE,
	RISK_STRATEGY_LIST,
	RO_DEFAULT_ITEM,
} from './00-Helpers/RiskConstants'

// R&O COMPONENTS
import RiskMatrix from './01-Components/RiskMatrix/RiskMatrix'
import RiskTable from './01-Components//RiskTable/RiskTable'
import RiskCard from './01-Components/RiskCard/RiskCard'
import DetailedRoActionsTable from './01-Components/RiskDetailedActions/DetailedRoActionsTable'
import RiskBigSideBar from './01-Components/RiskSideBar/RiskBigSideBar'

// GRAPHQL
import {
	updateTelescopeDataRO as UpdateTelescopeDataRO,
	createTelescopeDataRO as CreateTelescopeDataRO,
	createTelescopeDataAction as CreateTelescopeDataAction,
	updateTelescopeDataAction as UpdateTelescopeDataAction,
	deleteTelescopeDataRO,
	updateTelescopeDataGovReview,
	updateTelescopeDataAction,
} from '../../../graphql/mutations'

// APP BACK-END
import {
	multipleMutateGraphql,
	multipleQueryMultipleMutationGraphql,
	mutateGraphql,
} from '../../00-App/02-Backend/AppBackendCommon'

// REDUCER
import riskOppReducer from './00-Helpers/RiskDispatcher'

// ACTIONS CONTANTS
import {
	ACTION_GROUP_ITEMS,
	ACTION_ITEM_DATA,
	ACTION_START_DISPLAY_ID,
	ACTION_STATUS_KEY,
	ACTION_STATUS_LIST,
} from '../01-Action/00-Helpers/ActionsConstants'

// ACTIONS FUNCTIONS
import {
	ACTION_FUNC_DELETE_ACTIONS_AND_LINKS,
	ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST,
	FUNC_ACTION_CREATE_LINK_OBJECT,
	FUNC_ACTION_DUPLICATE,
	FUNC_ACTION_IS_LATE,
	FUNC_ACTION_NEED_TO_ADD_EVENT,
} from '../01-Action/00-Helpers/ActionsFunctions'

// WBS CONSTANTS
import {
	WBS_BIG_COMPONENT_VIEW_MODE,
	WBS_DEFAULT_NAVIGATION_OPTIONS,
} from '../00-Wbs/00-Helpers/WbsConstants'
import { GET_MUTATION_TO_DELETE_SEVERAL_ITEMS } from '../04-Governance/00-Helpers/GovReviewFunctions'
import {
	FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND,
	MERGE_GOV_REVIEW_MUTATIONS,
} from '../04-Governance/00-Helpers/GovFunctions'
import { WBS_ON_BACK_SIDEBAR } from '../00-Wbs/00-Helpers/WbsFunctions'
import { FIND_OBJECT_ARRAY_ITEM } from '@mi-gso/cvt'

////////////////////////////
/// R&O BIG COMPONENT ///
////////////////////////////

export default memo(function RiskBig({
	// Boolean ()
	isRisk,
	// ALL ACTIONS DATA
	actionsData,
	// PROJECT ID
	projectId,
	// WBS ID
	wbsId,
	// WBS NAME
	wbsName,
	// USERS LIST
	usersList,
	megaUsersIds,
	// TOTAL R&O DATA
	totalRiskOppData,
	// EXPAND FUNCTION
	bigComponentExpandFunction,
	// BOOLEAN WHETHER IS EXPANDED OR NOT
	bigComponentIsExpanded,
	// APP DISPATCHER (TO UPDATE DATA WHEN SAVING IN SIDE BAR)
	appDispatcher,
	// WBS DISPATCHER (TO UPDATE STATE WHEN EXPANDING)
	wbsDispatcher,
	// SECURITY GROUP
	securityGroup,
	// SELECTED ORGANIZATION ID
	selectedOrganizationId,
	// PARENT ID (TO USE WHEN ESCALATING)
	parentId,
	// FIELD INFORMATION
	fieldInformation,
	// HANDLE BIG COMPONENT FUNCTION
	handleBigComponent,
	// CURRENT USER
	currentUser,

	// GOVERNANCE DATA
	governanceData,

	// BIGGEST DISPLAY ID NUMBER OF ACTIONS
	biggestActionDisplayIdNum,

	// BIGGEST DISPLAY ID NUMBER OF R&O
	biggestDisplayIdNum,

	// IS REVIEW MODE
	isReviewMode,

	// IS IN READ ONLY MODE
	isReadOnlyMode,

	// IS CONSOLIDATED LINE
	isProjectElement,

	wbsNavigationOptions,
	projectData,

	// CURRENT PATH
	currentPath,

	moveModalOptionsItemsArray,
	updateTracker,
	roData,
	selectedReviewItem,
}) {
	/////////////////////////////////////////////////////
	/// STATE ///////////////////////////////////////////
	/////////////////////////////////////////////////////

	// DEFAULT ACTION ITEM
	const defaultActionItem = useMemo(() => {
		return {
			// IDS
			id: null,
			organizationId: selectedOrganizationId,
			projectId: projectId,
			wbsId: wbsId,
			displayId: null,
			createdBy: currentUser.username,
			contributors: null,

			// INFO
			action: null,
			cancelledDate: null,
			description: null,
			events: null,
			dueDate: TODAY_PLUS_30D,
			createdAt: TODAY,
			createdOn: TODAY,
			responsible: currentUser.username,
			status: ACTION_STATUS_LIST[0].value,
			realisedDate: null,
			lastModified: null,
			lastModifiedBy: null,
			progression: null,
			comments: null,
			path: null,
			goBackOriginItem: null, // IF GO DIRECTLY TO ITEM CONFIGURED, SPECIFY THE ITEM TO OPEN WHEN CLICKING ON GO BACK BUTTON

			// CONNECTIONS

			// GOVERNANCE
			telescopeDataGovernanceTelescopeDataActionId: null,

			// R&O
			telescopeDataROTelescopeDataActionId: null,

			// SCHEDULE
			telescopeDataScheduleTelescopeDataActionId: null,
			// COST
			telescopeDataCostTelescopeDataActionId: null,
		}
	}, [currentUser.username, projectId, selectedOrganizationId, wbsId])

	// CREATE THE STATE USING REDUCER
	const [state, riskOppDispatcher] = useReducer(
		riskOppReducer,
		RO_GET_DEFAULT_STATE(
			isReviewMode,
			wbsNavigationOptions,
			roData,
			isRisk,
			appDispatcher,
			wbsDispatcher
		)
	)

	/////////////////////////////////////////////////////
	/// MEMOS ///////////////////////////////////////////
	/////////////////////////////////////////////////////

	// CHOOSE RISK OR OPP DATA BASED ON DIMENSION
	const selectedRoData = useMemo(() => {
		if (updateTracker) {
			// SO ESLINT IS HAPPY
			// JUST TO TRIGGER THE MEMO IF DATA IS UPDATED
		}

		return roData.filter((risk) => risk.isRisk === isRisk)
	}, [isRisk, roData, updateTracker])

	// NEW DISPLAY ID FOR R&O ITEMS
	const newDisplayId = useMemo(() => {
		let count = totalRiskOppData.length
		return 'RO' + FUNC_ZERO_FORMAT_TO_NUM(count + 1, 4)
	}, [totalRiskOppData.length])

	// FILTERED R&O ITEMS ACCORDING TO TEXT SEARCH AND OPEN/CLOSED FILTER
	const displayRiskOppItems = useMemo(() => {
		let returnRoItems = _.cloneDeep(selectedRoData)

		// GET COUNT OF OPEN RO ITEMS
		let openRo = selectedRoData.reduce((previousCount, currentRoItem) => {
			if (currentRoItem.status === 'opened') {
				return previousCount + 1
			}
			return previousCount
		}, 0)

		returnRoItems = returnRoItems.filter((roItem) => {
			// IF USER ONLY WANTS TO SEE RO ITEMS BELONGING TO THIS  BRANCH AND ESCALATED ONES
			if (!state.isConsolidated) {
				let isSameWbs = roItem.wbsId === wbsId

				let isEscalated =
					roItem.escalatedTo && roItem.escalatedTo?.includes(wbsId)

				// IF NOT SAME WBS AND ALSO NOT ESCALATED FILTER OUT
				if (!isSameWbs && !isEscalated) return false
			}

			let isPassTextInput = true

			if (state.textSearchInput && state.textSearchInput.length > 0) {
				let userInfo = FUNC_GET_USER_INFO(
					usersList,
					megaUsersIds,
					roItem.responsible
				)

				isPassTextInput =
					roItem.name
						?.toLowerCase()
						?.includes(state.textSearchInput.toLowerCase()) ||
					(userInfo?.label &&
						userInfo.label
							?.toLowerCase()
							?.includes(state.textSearchInput.toLowerCase())) ||
					(roItem.description &&
						roItem.description.length > 0 &&
						roItem.description
							?.toLowerCase()
							?.includes(state.textSearchInput.toLowerCase()))
			}

			// IF NO STATUS FILTERS, ONLY DISPLAY OPEN RO ITEMS, OTHERWISE DISPLAY ACCORDING TO THE STATUS FILTERS CHOSEN
			// IF NO STATUS FILTERS, ONLY DISPLAY OPEN RO ITEMS, OTHERWISE DISPLAY ACCORDING TO THE STATUS FILTERS CHOSEN
			let isPassStatusFilter

			if (state.roStatusSearchFilter.length > 0) {
				isPassStatusFilter = state.roStatusSearchFilter?.includes(roItem.status)
			} else if (openRo === 0) {
				// LET ANY RO ITEM PASS
				isPassStatusFilter = true
			} else {
				// IF OPEN RO COUNT IS BIGGER THAN 0, THEN SHOW ONLY OPENED RO ITEMS
				isPassStatusFilter = roItem.status === 'opened'
			}

			return isPassStatusFilter === true && isPassTextInput === true
		})

		return returnRoItems
	}, [
		selectedRoData,
		state.isConsolidated,
		state.textSearchInput,
		state.roStatusSearchFilter,
		wbsId,
		usersList,
		megaUsersIds,
	])

	// GET FILTERED ACTIONS FOR EITHER FOCUSED RO ITEM IN MATRIX OR IN RO ITEM IN VIEW MODE
	const filteredActions = useMemo(() => {
		if (updateTracker) {
			// JUST TO TRIGGER MEMO
		}

		let roItem =
			state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
				? state.riskOppViewItem
				: state.detailedRoMatrixItem !== null
				? state.detailedRoMatrixItem
				: null

		if (roItem && roItem.actionsIds && roItem.actionsIds.length > 0) {
			return actionsData
				.filter((item) => {
					// IF ACTION IS NOT IN RO ITEMS RELATED
					if (!roItem.actionsIds?.includes(item.id)) return false

					//DONE - DO NOT DISPLAY DONE IF IS NOT ACTIVATED
					if (
						item.status === ACTION_STATUS_KEY.done &&
						((state.statusSearchInput &&
							state.statusSearchInput.length > 0 &&
							FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, item.status) ===
								-1) ||
							!state.statusSearchInput ||
							state.statusSearchInput.length === 0)
					) {
						return false
					}

					//STATUS
					if (
						state.statusSearchInput &&
						state.statusSearchInput.length > 0 &&
						FUNCT_FIND_INDEX_ARRAY(state.statusSearchInput, item.status) === -1
					) {
						//TEST WITH LATE
						if (
							FUNCT_FIND_INDEX_ARRAY(
								state.statusSearchInput,
								ACTION_STATUS_KEY.late
							) > -1
						) {
							//ONLY IF TODO OR INPROGRESS //!!! maybe pass late info at the data loading
							if (
								item.status === ACTION_STATUS_KEY.todo ||
								item.status === ACTION_STATUS_KEY.inProgress
							) {
								//TEST IF DUEDATE NOT A DATE
								let dueDate = item.dueDate
								if (
									Object.prototype.toString.call(dueDate) !== '[object Date]'
								) {
									dueDate = new Date(dueDate)
								}

								//TEST
								if (dueDate.getTime() < TODAY.getTime()) return true
							}
						}

						//DEFAULT
						return false
					}

					//SEARCH
					if (
						state.actionsTextSearchInput &&
						state.actionsTextSearchInput !== '' &&
						!item.action
							?.toUpperCase()
							?.includes(state.actionsTextSearchInput.toUpperCase())
					)
						return false

					//RETURN TRUE
					return true
				})
				.map((item) => {
					//GET TIME FOR SORT NUMBER
					let sortNum = 0

					//SWITCH STATUS
					switch (item.status) {
						//DONE
						case ACTION_STATUS_KEY.done:
							sortNum = Date.parse(item.realisedDate) || 0
							break

						//ON HOLD
						case ACTION_STATUS_KEY.onHold:
							sortNum = Date.parse(item.cancelledDate) || 0
							break

						//DEFAULT
						default:
							sortNum = Date.parse(item.dueDate) || 0
							break
					}

					//ADD SORT
					return {
						...item,
						sort: sortNum,
					}
				})
				.sort(function (a, b) {
					if (a.sort < b.sort) {
						return -1
					}
					if (a.sort > b.sort) {
						return 1
					}
					return 0
				})
		} else {
			return []
		}
	}, [
		actionsData,
		state.actionsTextSearchInput,
		state.detailedRoMatrixItem,
		state.riskOppViewItem,
		state.statusSearchInput,
		state.viewMode,
		updateTracker,
	])

	// LEGEND OBJECT WITH COUNTER FOR RO ACTIONS  TABLE
	const legendAndCounter = useMemo(() => {
		// IF NOT IN DETAILED VIEW MODE OR IN VIEW MODE, RETURN NULL
		if (
			state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
			state.detailedRoMatrixItem === null
		) {
			return null
		}

		// ELSE RESUME

		//INIT
		let legendObject = []
		let cloneActions = []

		//CHOOSE WICH DATA USED
		// if(state.actionsTextSearchInput && state.actionsTextSearchInput !== "" && state.statusSearchInput.length === 0){
		//   cloneActions = _.cloneDeep(filteredActions)
		// }else{
		//   cloneActions = _.cloneDeep(actionsData)
		// }

		let actions = []

		let roItem =
			state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
				? state.riskOppViewItem
				: state.detailedRoMatrixItem !== null
				? state.detailedRoMatrixItem
				: null

		if (roItem && roItem.actionsIds && roItem.actionsIds.length > 0) {
			actions = actionsData.filter((action) =>
				roItem.actionsIds?.includes(action.id)
			)
		}

		cloneActions = _.cloneDeep(actions)

		//ONLY IF DATA
		if (cloneActions && cloneActions.length > 0) {
			//LOOP ON ARRAY LIST
			let totalCount = 0
			let totalLateCount = 0
			for (let i = 0; i < ACTION_STATUS_LIST.length; i++) {
				//COUNT
				let currentActions = cloneActions.filter(
					(item) => item.status === ACTION_STATUS_LIST[i].id
				)
				let currentCount = currentActions.length

				//INTEGRATE IF NOT EMPTY
				if (currentCount > 0) {
					//TEST FOR IN PROGRESS AND TODO
					let lateCount = 0
					if (
						ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.todo ||
						ACTION_STATUS_LIST[i].id === ACTION_STATUS_KEY.inProgress
					) {
						//FIND IF DUE DATE BEFORE TODAY
						lateCount = currentActions.filter((item) => {
							return FUNC_ACTION_IS_LATE(item.status, item.dueDate)
						}).length
					}

					//TOTAL COUNT - RELATED TO CURRENT SELECTION
					totalCount = totalCount + currentCount

					//LATE
					if (lateCount > 0) {
						totalLateCount = totalLateCount + lateCount
					}

					//INTEGRATE
					legendObject.push({
						id: ACTION_STATUS_LIST[i].id,
						name: ACTION_STATUS_LIST[i].name,
						backgroundColor: ACTION_STATUS_LIST[i].backgroundColor,
						color: ACTION_STATUS_LIST[i].color,
						value: currentCount,
						late: lateCount,
					})
				}
			}

			//UNSHIFT LATE
			if (totalLateCount > 0) {
				legendObject.unshift({
					id: 'late',
					name: 'Late',
					value: totalLateCount,
					backgroundColor: 'var(--color-bad)',
					color: 'white',
				})
			}

			//TOTAL
			legendObject.push({
				id: 'total',
				name: 'Total',
				value: totalCount,
			})
		}

		//RETURN OBJECT
		return legendObject
	}, [
		actionsData,
		state.detailedRoMatrixItem,
		state.riskOppViewItem,
		state.viewMode,
	])

	const canModifyRo = useMemo(() => {
		if (state.riskOppViewItem || state.detailedRoMatrixItem) {
			const roViewItem = state.riskOppViewItem ?? state.detailedRoMatrixItem
			return (
				roViewItem.responsible === currentUser.username ||
				roViewItem.createdBy === currentUser.username ||
				securityGroup.isCurrentUserEditor
			)
		}

		for (const roItem of state.selected) {
			if (
				roItem.responsible !== currentUser.username &&
				roItem.createdBy !== currentUser.username &&
				!securityGroup.isCurrentUserEditor
			) {
				return false
			}
		}

		return true
	}, [
		currentUser.username,
		securityGroup.isCurrentUserEditor,
		state.detailedRoMatrixItem,
		state.riskOppViewItem,
		state.selected,
	])

	/////////////////////////////////////////////////////
	/// FUNCTIONS ///////////////////////////////////////////
	/////////////////////////////////////////////////////

	// ADD SIDE BAR BUTTON CLICKED / ITEM IN TABLE CLICKED
	const onAddEditClick = useCallback(
		(item) => {
			let newEditSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
				// DATA
				item
					? _.cloneDeep({
							...item,
							// HANDLE ESCALATED TO
							escalatedTo:
								item.escalatedTo && item.escalatedTo?.includes(parentId)
									? true
									: false,
					  })
					: {
							...RO_GET_DEFAULT_ITEM(
								selectedOrganizationId,
								wbsId,
								isRisk,
								currentUser.username
							),
					  },
				// STRUCTURE OF THE DATA
				RISK_OPP_ITEM_DATA_STRUCTURE,
				// IDS
				{
					id: item ? item.id : uuid(),
					organizationId: selectedOrganizationId,
					projectId: projectId,
					wbsId: item ? item.wbsId : wbsId,
					displayId: item ? item.displayId : newDisplayId,
					isNew: item ? false : true,
				},
				// SPECIFIC OPTIONS FOR SOME ATTRIBUTES
				[
					{
						key: 'responsible',
						value: usersList.map((user) => ({
							id: user.value,
							label: user.label,
						})),
					},
					{
						key: 'actionsIds',
						value: actionsData
							.filter(
								(action) => action.telescopeDataROTelescopeDataActionId === null
							)
							.map((action) => ({
								...action,
								// ONE OF THE 2
								label: action.displayId ? action.displayId : action.action,
								value: action.id,
								// // ------------
								// name: action.displayId ? action.displayId : action.action,
							})),
					},
					// STRATEGY
					{
						key: 'strategy',
						value: isRisk === true ? RISK_STRATEGY_LIST : OPP_STRATEGY_LIST,
					},
				]
			)

			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					editSideBarContent: newEditSideBarContent,
					editSideBar: true,
					viewMode:
						state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
							? state.viewMode
							: item
							? WBS_BIG_COMPONENT_VIEW_MODE.edit
							: WBS_BIG_COMPONENT_VIEW_MODE.add,
				},
			})
		},
		[
			actionsData,
			currentUser.username,
			isRisk,
			newDisplayId,
			parentId,
			projectId,
			selectedOrganizationId,
			state.viewMode,
			usersList,
			wbsId,
		]
	)

	// CREATE ACTION IN EDIT SIDE BAR ------ OK
	const onAddEditActionClick = useCallback(
		(item) => {
			// INIT
			let currentAction = _.cloneDeep(item ?? defaultActionItem)

			// PREPARE USERLIST FRO DROPDOWN
			let editSideBarUsersList = usersList.map((user) => ({
				value: user.value,
				label: user.label,
			}))

			// IF CAN'T FIND USER IN THE USERS LIST, PASS THE RANDOM MEGA USER
			if (
				item !== null &&
				!usersList.find((user) => user.value === item.responsible)
			) {
				//GET USERS OBJECT
				let userInCore = FUNC_GET_USER_INFO(
					usersList,
					megaUsersIds,
					item.responsible
				)

				//UPDATE IT
				currentAction.responsible = userInCore.value
			}

			// PREPARE CONTRIBUTORS
			if (currentAction?.contributors) {
				currentAction.contributors = editSideBarUsersList.filter((user) =>
					currentAction?.contributors?.includes(user.value)
				)
			}

			if (
				selectedReviewItem &&
				!item &&
				selectedReviewItem.projectId &&
				selectedReviewItem.projectId === projectId
			) {
				currentAction.links = JSON.stringify([
					FUNC_ACTION_CREATE_LINK_OBJECT('govReview', selectedReviewItem.id),
				])
			}

			// PREPARE DATA TO FEED TO EDIT SIDE BAR
			let newEditSideBarContent = EDIT_SIDE_BAR_DATA_PREPARATION(
				// DATA
				currentAction,
				// STRUCTURE OF THE DATA
				ACTION_ITEM_DATA,
				// IDS
				{
					id: item ? item.id : uuid(),
					organizationId: item
						? item.selectedOrganizationId
						: selectedOrganizationId,
					projectId: item ? item.projectId : projectId,
					wbsId: item ? item.wbsId : wbsId,
					displayId: item
						? item.displayId
						: ACTION_START_DISPLAY_ID +
						  FUNC_ZERO_FORMAT_TO_NUM(biggestDisplayIdNum + 1, 4),
					isNew: item === null,
				},
				// SPECIFIC OPTIONS FOR SOME ATTRIBUTES
				[
					// RESPONSIBLE
					{
						key: 'responsible',
						value: editSideBarUsersList,
					},
					// CONTRIBUTORS
					{
						key: 'contributors',
						value: editSideBarUsersList.filter(
							(user) => user.value !== currentUser.username
						),
					},
					// GOVERNANCE ITEMS. FOR NOW LABEL IS THE DESCRIPTION BECAUSE GOVERNANCE HAS NO NAME
					{
						key: 'telescopeDataGovernanceTelescopeDataActionId',
						value: governanceData.map((governance) => ({
							value: governance.id,
							label: governance.description,
						})),
					},
					// R&O ITEMS
					{
						key: 'telescopeDataROTelescopeDataActionId',
						value: selectedRoData.map((ro) => ({
							value: ro.id,
							label: ro.displayId,
						})),
					},
				]
			)

			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					editSideBarContent: newEditSideBarContent,
					editSideBar: true,
					viewMode:
						state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
							? state.viewMode
							: WBS_BIG_COMPONENT_VIEW_MODE.list,
					actionsOperationType: item
						? RISK_OPP_CHANGE_ACTIONS_TYPES.edit
						: RISK_OPP_CHANGE_ACTIONS_TYPES.create,
				},
			})
		},
		[
			defaultActionItem,
			usersList,
			selectedReviewItem,
			selectedOrganizationId,
			projectId,
			wbsId,
			biggestDisplayIdNum,
			governanceData,
			selectedRoData,
			state.viewMode,
			megaUsersIds,
			currentUser.username,
		]
	)

	// ON DELETE SIDE BAR BUTTON CLICKED
	const onDeleteSideBar = useCallback(
		(e) => {
			e.preventDefault()

			// INIT
			let finalMutation = {
				graphql: {
					riskOpp: {
						query: deleteTelescopeDataRO,
						objects: [],
					},
					govReview: {
						query: updateTelescopeDataGovReview,
						objects: [],
					},
					action: {
						query: updateTelescopeDataAction,
						objects: [],
					},
				},
				dispatcher: {
					riskOpp: {
						delete: [],
					},
					govReview: {
						update: [],
					},
					action: {
						update: [],
					},
				},
			}
			let actionFound
			let updatedActions = []

			// GET SELECTED SCOPE
			let selectedROToDelete

			// IF ITEM VIEW NOT NULL DELETE CURRENT SCOPE CHANGE
			if (state.riskOppViewItem) {
				selectedROToDelete = [state.riskOppViewItem]
			}
			//IF NOT DELETE ALL SELECTED ACTIONS
			else {
				selectedROToDelete = state.selected
			}

			// GET MUTATIONS TO UPDATE AGENDAS AND DELETE RO
			let roDeleteMutations = GET_MUTATION_TO_DELETE_SEVERAL_ITEMS(
				selectedROToDelete,
				'riskOpp',
				projectData
			)

			// MERGE MUTATION TO FINAL
			finalMutation = MERGE_GOV_REVIEW_MUTATIONS(
				finalMutation,
				roDeleteMutations
			)

			// GET MUTATION TO UPDATE ACTION LINKS FOR EACH RO
			selectedROToDelete.forEach((roItemToDelete) => {
				// PARSE RO ACTIONS LINKS IDS
				roItemToDelete.actionsIds = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(
					roItemToDelete.actionsIds
				)

				// FOR EACH ACTIONS IDS, REMOVE THE CURRENT RO ITEM
				roItemToDelete.actionsIds.forEach((actionId) => {
					// GET THE ACTION OBJECT
					actionFound = FIND_OBJECT_IN_FIRST_ARRAY_OR_SECOND(
						updatedActions,
						projectData.action,
						'id',
						actionId
					)

					// IF NOT FOUND, CONTINUE TO NEXT ONE
					if (!actionFound) {
						return
					}

					// UPDATE ACTION LINKS LIST
					actionFound.data.links = ACTION_FUNC_REMOVE_LINK_FROM_ACTION_LIST(
						actionFound.data.links,
						roItemToDelete.id
					)

					// IF ITEM COME FROM UPDATED ITEMS, UPDATE IT
					if (actionFound.fromFirstArray) {
						updatedActions[actionFound.index] = actionFound.data
					}
					// OTHERWISE, ADD ITEM TO UPDATED ITEMS
					else {
						updatedActions.push(actionFound.data)
					}
				})
			})

			// ADD A MUTATION FOR EACH UPDATED ACTIONS
			updatedActions.forEach((updatedAction) => {
				finalMutation.dispatcher.action.update.push(updatedAction)
				finalMutation.graphql.action.objects.push({
					id: updatedAction.id,
					links: updatedAction.links,
				})
			})

			// UPDATE GRAPHQL
			for (let queryName of Object.keys(finalMutation.graphql)) {
				multipleMutateGraphql(
					finalMutation.graphql[queryName].query,
					finalMutation.graphql[queryName].objects,
					appDispatcher
				)
			}

			//UPDATE APP STATE
			appDispatcher({
				type: 'SET_PROJECT_DATA_MULTIPLE_TABLES',
				object: finalMutation.dispatcher,
			})

			// UPDATE RISK BIG STATE
			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: [],
					viewMode:
						state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view
							? WBS_BIG_COMPONENT_VIEW_MODE.list
							: state.viewMode,
				},
			})
		},
		[
			appDispatcher,
			projectData,
			state.riskOppViewItem,
			state.selected,
			state.viewMode,
		]
	)

	//TODO EXPORT SIDE BAR BUTTON CLICKED
	const onExternalConnectorSideBar = useCallback(() => {}, [])

	// ON SAVE ADD/EDIT OF EDIT SIDE BAR -------- OK
	const onSaveAddEdit = useCallback(
		(e, mutateObject, isNew) => {
			//ONLY IF USERNAME
			if (currentUser.username && currentUser.username !== '') {
				// INIT RO ITEM TO UPDATE
				let riskToUpdate =
					state.riskOppViewItem ||
					state.selected[0] ||
					selectedRoData.find((ro) => ro.id === mutateObject.id)

				// INIT
				let graphqlQuery
				let dispatcherQuery
				let updatedObject = {}
				let eventsUpdated = []

				// IF EDITING (NOT NEW)
				if (!isNew) {
					// SET UPDATED OBJECT
					updatedObject = {
						...mutateObject,
					}

					// INIT EVENT
					eventsUpdated = JSON.parse(riskToUpdate.events)

					// LOOP ON UPDATED FIELDS
					for (let attribute of Object.keys(mutateObject)) {
						//DO NOT UPDATE THESE IF ATTRIBUTE IS ONE OF THESE
						if (
							attribute !== '' &&
							attribute !== 'displayId' &&
							attribute !== 'id' &&
							attribute !== 'organizationId' &&
							attribute !== 'projectId' &&
							attribute !== 'wbsId' &&
							attribute !== 'comments' &&
							attribute !== 'createdBy'
						) {
							// TEST IF ALREADY ADDED EVENT
							let testAddEvent = FUNC_NEED_TO_ADD_EVENT(
								eventsUpdated,
								attribute,
								currentUser.username
							)

							// PUSH NEW EVENTS IF NEED TO ADD
							if (testAddEvent === true) {
								eventsUpdated.push({
									user: {
										id: currentUser.username,
										label: currentUser.name,
									},
									createdOn: new Date(),
									value: attribute + ' updated by ' + currentUser.name,
									updatedValue: mutateObject[attribute],
									lastValue: riskToUpdate[attribute],
								})
							}
						}

						// SPECIFIC CHECKS FOR CERTAIN ATTRIBUTES

						// IMPACT AND PROBABILITY CHECKS

						// INITIAL
						if (attribute === 'initialProba' || attribute === 'initialImpact') {
							updatedObject.initialCriticity = getCriticityFromImpProba(
								mutateObject.initialImpact ?? riskToUpdate.initialImpact,
								mutateObject.initialProba ?? riskToUpdate.initialProba
							)
						}

						// CURRENT
						if (attribute === 'currentProba' || attribute === 'currentImpact') {
							updatedObject.currentCriticity = getCriticityFromImpProba(
								mutateObject.currentImpact ??
									riskToUpdate.currentImpact ??
									riskToUpdate.initialImpact,
								mutateObject.currentProba ??
									riskToUpdate.currentProba ??
									riskToUpdate.initialProba
							)
						}

						// TARGET
						if (attribute === 'targetProba' || attribute === 'targetImpact') {
							updatedObject.targetCriticity = getCriticityFromImpProba(
								mutateObject.targetImpact ?? riskToUpdate.targetImpact,
								mutateObject.targetProba ?? riskToUpdate.targetProba
							)
						}

						// END
						if (
							(attribute === 'endProba' || attribute === 'endImpact') &&
							(mutateObject.endProba || riskToUpdate.endProba) &&
							(mutateObject.endImpact || riskToUpdate.endImpact)
						) {
							updatedObject.endCriticity = getCriticityFromImpProba(
								mutateObject.endImpact ?? riskToUpdate.endImpact,
								mutateObject.endProba ?? riskToUpdate.endProba
							)
						}

						// CRITICITY CHECKS

						// INITIAL
						if (attribute === 'initialCriticity') {
							let { probability, impact } = getImpactProbability(
								parseInt(mutateObject.initialCriticity)
							)
							updatedObject.initialProba = probability
							updatedObject.initialImpact = impact
						}

						// CURRENT
						if (attribute === 'currentCriticity') {
							let { probability, impact } = getImpactProbability(
								parseInt(mutateObject.currentCriticity)
							)
							updatedObject.currentProba = probability
							updatedObject.currentImpact = impact
						}

						// TARGET
						if (attribute === 'targetCricitity') {
							let { probability, impact } = getImpactProbability(
								parseInt(mutateObject.targetCriticity)
							)
							updatedObject.targetProba = probability
							updatedObject.targetImpact = impact
						}

						// END
						if (attribute === 'endCriticity') {
							let { probability, impact } = getImpactProbability(
								parseInt(mutateObject.endCriticity)
							)
							updatedObject.endProba = probability
							updatedObject.endImpact = impact
						}

						// ESCALATED TO CHECKS
						if (attribute === 'escalatedTo') {
							// GET OLD ESCALATED TO ARRAY
							let oldEscalatedTo = riskToUpdate.escalatedTo

							// IF SET TO TRUE
							if (mutateObject.escalatedTo === true) {
								// ADD TO ESCALATED TO

								// IF WAS NOT NULL, THEN WAS ALREADY ESCALATED FROM BELOW
								if (oldEscalatedTo) {
									oldEscalatedTo.push(parentId)
								}
								// WAS NOT ESCALATED (COMES FROM THIS WBS ITEM)
								else {
									oldEscalatedTo = [parentId]

									updatedObject.escalatedFrom = wbsId
								}

								// SET NEW ESCALATED TO
								updatedObject.escalatedTo = oldEscalatedTo
							}
							// IF SET TO FALSE
							else {
								// REMOVE FROM ESCALATED TO
								oldEscalatedTo = oldEscalatedTo?.filter(
									(projectItemId) => projectItemId !== parentId
								)

								// IF ESCALATED TO IS EMPTY AFTER REMOVING, MAKE IT NULL
								if (oldEscalatedTo?.length === 0) {
									oldEscalatedTo = null

									// IF CURRENT WBS ID WAS SAME AS ESCALATED FROM, THEN MAKE IT NULL
									if (wbsId === riskToUpdate.escalatedFrom) {
										updatedObject.escalatedFrom = null
									}
								}

								// SET NEW ESCALATED TO
								updatedObject.escalatedTo = oldEscalatedTo
							}
						}

						//TODO COMMENTS (LATER)

						//TODO ACTIONS IDS
					}

					// DELETE CREATED BY IN CASE OF MUTATION
					delete mutateObject.createdBy

					// SET GRAPHQL QUERY
					graphqlQuery = UpdateTelescopeDataRO
					// SET QUERY FOR DISPATCHER
					dispatcherQuery = 'SET_PROJECT_DATA_UPDATE'
					// SET EVENTS
					updatedObject = {
						...updatedObject,
						events: JSON.stringify(eventsUpdated),
						updatedAt: new Date(),
					}
				}
				// CREATE ALL NEEDED INFO
				else {
					// UPDATE PATH
					mutateObject.path = currentPath

					// MANAGE EVENTS
					eventsUpdated.push({
						user: {
							id: currentUser.username,
							label: currentUser.name,
						},
						createdOn: new Date(),
						value: 'created by ' + currentUser.name,
					})

					// NEED TO CALCULATE INITIAL, CURRENT AND TARGET CRITICITIES.

					// INITIAL
					let initialCriticity = getCriticityFromImpProba(
						mutateObject.initialImpact ?? RO_DEFAULT_ITEM.initialImpact,
						mutateObject.initialProba ?? RO_DEFAULT_ITEM.initialProba
					)
					// CURRENT²

					let currentCriticity = getCriticityFromImpProba(
						mutateObject.currentImpact ?? RO_DEFAULT_ITEM.currentImpact,
						mutateObject.currentProba ?? RO_DEFAULT_ITEM.currentProba
					)
					// TARGET
					let targetCriticity = getCriticityFromImpProba(
						mutateObject.targetImpact ?? RO_DEFAULT_ITEM.targetImpact,
						mutateObject.targetProba ?? RO_DEFAULT_ITEM.targetProba
					)

					let endCriticity = null
					// IF END
					if (mutateObject.endImpact && mutateObject.endProba) {
						endCriticity = getCriticityFromImpProba(
							mutateObject.endImpact,
							mutateObject.endProba
						)
					}

					// INIT ESCALATED
					let escalatedTo = null
					let escalatedFrom = null
					// CHECK IF ESCALATED TO IS TRUE
					if (mutateObject.escalatedTo && mutateObject.escalatedTo === true) {
						escalatedTo = [parentId]
						escalatedFrom = wbsId
					}

					// GRAPHQL / DISPATCHER INIT
					graphqlQuery = CreateTelescopeDataRO
					dispatcherQuery = 'SET_PROJECT_DATA_CREATE'
					updatedObject = {
						...RO_GET_DEFAULT_ITEM(
							selectedOrganizationId,
							wbsId,
							isRisk,
							currentUser.username
						),
						...mutateObject,
						updatedAt: new Date(),
						events: JSON.stringify(eventsUpdated),
						createdBy: currentUser.username,
						createdOn: TODAY,
						initialCriticity: initialCriticity,
						currentCriticity: currentCriticity,
						targetCriticity: targetCriticity,
						endCriticity: endCriticity,
						escalatedFrom: escalatedFrom,
						escalatedTo: escalatedTo,
					}
				}

				// RETRIEVE CREATED BY
				let createdBy = eventsUpdated[eventsUpdated.length - 1].user.id

				// UPDATE BACK-END
				mutateGraphql(
					graphqlQuery,
					{
						...updatedObject,
						groupEditors: securityGroup.groupEditors,
						groupViewers: securityGroup.groupViewers,
						createdBy: isNew === true ? createdBy : riskToUpdate.createdBy,
					},
					appDispatcher
				)

				// UPDATE APP STATE
				appDispatcher({
					type: dispatcherQuery,
					source: 'riskOpp',
					objects: [updatedObject],
					noToast:
						!isNew && state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view,
				})

				// R&O DISPATCHER
				riskOppDispatcher({
					type: 'SET_STATE_OBJECT',
					object: {
						editSideBar: false,
						editSideBarContent: null,
						viewMode:
							state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit
								? WBS_BIG_COMPONENT_VIEW_MODE.list
								: state.viewMode,
						riskOppViewItem:
							state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit ||
							state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
								? { ...state.riskOppViewItem, ...updatedObject }
								: state.riskOppViewItem,
						propKeyIncrement: state.propKeyIncrement + 1,
						selected: [],
					},
				})

				//!! I THINK WE WILL ALWAYS HAVE USERNAME...
			} else {
				// console.log("R&O", currentUser);
				appDispatcher({
					type: 'SET_GLOBAL_STATE',
					options: {
						source: 'appToast',
						object: {
							display: true,
							color: 'danger',
							message: 'Bug current user when creating ro item in R&O',
						},
					},
				})
			}
		},
		[
			currentUser.username,
			currentUser.name,
			state.riskOppViewItem,
			state.selected,
			state.viewMode,
			state.propKeyIncrement,
			selectedRoData,
			securityGroup.groupEditors,
			securityGroup.groupViewers,
			appDispatcher,
			parentId,
			wbsId,
			currentPath,
			selectedOrganizationId,
			isRisk,
		]
	)

	// ON RESET/CANCEL ADD/EDIT OF EDIT SIDE BAR -------- OK
	const resetEditSideBar = useCallback(() => {
		riskOppDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				editSideBar: false,
				viewMode:
					(state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.edit &&
						state.riskOppViewItem) ||
					state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
						? WBS_BIG_COMPONENT_VIEW_MODE.view
						: WBS_BIG_COMPONENT_VIEW_MODE.list,
				editSideBarContent: null,
				actionsOperationType: null,
			},
		})
	}, [state.riskOppViewItem, state.viewMode])

	// HANDLE EXPAND ARROW CLICK ------------- OK
	const handleExpandArrowClick = useCallback(
		(isExpanding, isMatrix) => {
			// INIT
			let newExpandMode

			// IF IN REVIEW MODE
			if (isReviewMode || state.widthSizeLimit) {
				newExpandMode =
					state.expandMode === RISK_OPP_EXPAND_MODES.matrixOnly
						? RISK_OPP_EXPAND_MODES.tableOnly
						: RISK_OPP_EXPAND_MODES.matrixOnly
			} else {
				newExpandMode =
					isExpanding === true
						? isMatrix
							? RISK_OPP_EXPAND_MODES.matrixOnly
							: RISK_OPP_EXPAND_MODES.tableOnly
						: RISK_OPP_EXPAND_MODES.both
			}

			// UPDATE
			riskOppDispatcher({
				type: 'SET_STATE_KEY_VALUE',
				key: 'expandMode',
				value: newExpandMode,
			})
		},
		[isReviewMode, state.expandMode, state.widthSizeLimit]
	)

	// HANDLE RO ITEM CLICK IN TABLE ---------- OK
	const handleRoItemClick = useCallback(
		(roItem) => {
			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					viewMode: WBS_BIG_COMPONENT_VIEW_MODE.view,
					riskOppViewItem: roItem,
					selected: [],
				},
			})

			// UPDATE WBS SELECTED ITEM
			wbsDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selectedItem: roItem.id,
				},
			})
		},
		[wbsDispatcher]
	)

	// WHEN USER IS IN VIEW MODE AND CLICKS IN THE GO BACK BTN
	const onBackSideBar = useCallback(() => {
		// CHECK IF BACK BUTTON HAVE A DESTINATION ITEM ASSOCIATED
		let backButtonResult = WBS_ON_BACK_SIDEBAR(
			wbsNavigationOptions,
			projectData,
			projectId,
			wbsDispatcher,
			appDispatcher
		)

		// IF BACK BUTTON LEADS TO AN OTHER ITEM, EXIT FUNCTION
		if (backButtonResult) {
			return
		}

		riskOppDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
				riskOppViewItem: null,
				actionsTextSearchInput: '',
				selected: [],
				statusSearchInput: [],
				isRiskCardImpactOpen: false,
			},
		})

		wbsDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				selectedItem: null,
			},
		})
	}, [
		appDispatcher,
		projectData,
		projectId,
		wbsDispatcher,
		wbsNavigationOptions,
	])

	// ON SAVE ADD ACTION ON EDIT SIDE BAR
	const onSaveAddAction = useCallback(
		(e, mutateObject, isNew) => {
			// GET THE RO ITEM TO UPDATE
			let riskOppToUpdate =
				state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
					? _.cloneDeep(state.riskOppViewItem)
					: _.cloneDeep(state.detailedRoMatrixItem)

			// INIT
			let newAction = {}
			let actionBeingEdited

			//TEST IF isPriate INMUTATE OBJECT PASS RESPONSIBLE TO
			if (mutateObject.isPrivate) {
				mutateObject.responsible = currentUser.username
				mutateObject.contributors = null
			}

			// IF IS CREATING ACTION
			if (isNew) {
				// UPDATE PATH
				mutateObject.path = currentPath

				// ADD NEW ACTION ID TO RO ITEMS ACTIONS IDS
				riskOppToUpdate = {
					...riskOppToUpdate,
					actionsIds: riskOppToUpdate.actionsIds
						? [...riskOppToUpdate.actionsIds, mutateObject.id]
						: [mutateObject.id],
				}

				// CREATE EVENTS FOR NEW ACTION
				let newEvents = [
					{
						user: {
							id: currentUser.username,
							label: currentUser.name,
						},
						createdOn: new Date(),
						value: 'Created by ' + currentUser.name,
					},
					{
						user: {
							id: currentUser.username,
							label: currentUser.name,
						},
						createdOn: new Date(),
						value: 'status initialized by ' + currentUser.name,
						updatedValue: mutateObject.status,
						lastValue: null,
						noDisplay: true,
					},
				]

				// INIT NEW ACTION OBJECT
				newAction = {
					..._.cloneDeep(defaultActionItem),
					...mutateObject,
					createdBy: currentUser.username,
					createdOn: TODAY,
					events: JSON.stringify(newEvents),
					wbsId: riskOppToUpdate.wbsId,
					displayId:
						ACTION_START_DISPLAY_ID +
						FUNC_ZERO_FORMAT_TO_NUM(biggestActionDisplayIdNum, 4),
					groupEditors: mutateObject.isPrivate
						? []
						: securityGroup.groupEditors,
					groupViewers: mutateObject.isPrivate
						? []
						: securityGroup.groupViewers,
					// CONNECT TO RO
					telescopeDataROTelescopeDataActionId: riskOppToUpdate.id,
				}

				// ADD LINK TO THIS RO
				let newActionLink = FUNC_ACTION_CREATE_LINK_OBJECT(
					'riskOpp',
					state.riskOppViewItem.id
				)
				let newLinks = FUNC_SAFE_GET_JSON_ARRAY_FROM_STRING(mutateObject?.links)

				newAction.links = newLinks
					? [...newLinks, newActionLink]
					: [newActionLink]

				newAction.links = JSON.stringify(newAction.links)

				// ELSE IF EDITING ACTION
			} else {
				// FIND ACTION
				actionBeingEdited = actionsData.find(
					(action) => action.id === mutateObject.id
				)

				// INIT EVENT
				let eventsUpdated = JSON.parse(actionBeingEdited.events)

				// HANDLE EVENTS FOR EDIT ACTION
				//LOOP ON UPDATED FIELDS
				for (let attribute of Object.keys(mutateObject)) {
					//FLAG IS NOT THESE ATTRIBUTES
					if (
						attribute !== 'isPrivate' &&
						attribute !== 'displayId' &&
						attribute !== 'id' &&
						attribute !== 'organizationId' &&
						attribute !== 'projectId' &&
						attribute !== 'wbsId' &&
						attribute !== 'comments' &&
						attribute !== 'contributors' &&
						attribute !== 'createdBy'
					) {
						//TEST IF WE HAVE ALREADY ADDED THE EVENT
						let eventIndex = FUNC_ACTION_NEED_TO_ADD_EVENT(
							eventsUpdated,
							attribute,
							currentUser.username
						)

						//MANAGE UPDATED VALUE / LAST UPDATED
						let updatedValue = mutateObject[attribute]
						let lastValue = actionBeingEdited[attribute]
						let userIndex

						//MANAGE IT FOR RESPONSIBLE
						if (attribute === 'responsible') {
							//UPDATE DVALUE
							if (updatedValue) {
								userIndex = FUNCT_FIND_INDEX(usersList, 'value', updatedValue)
								if (userIndex > -1) {
									updatedValue = usersList[userIndex].label
								}
							}

							//LAST VALUE
							if (lastValue) {
								userIndex = FUNCT_FIND_INDEX(usersList, 'value', lastValue)
								if (userIndex > -1) {
									lastValue = usersList[userIndex].label
								}
							}
						}

						// MANAGE IT FOR LINKS
						if (attribute === 'links') {
							// CHECK IF REMOVED THE LINK TO THIS RO ITEM
							const parsedLinks = JSON.parse(mutateObject.links)

							const findIndex = parsedLinks.findIndex(
								(link) => link.destinationItemId === riskOppToUpdate.id
							)

							if (findIndex === -1) {
								const findActionIdIndex = riskOppToUpdate.actionsIds.findIndex(
									(actionId) => actionId === mutateObject.id
								)
								riskOppToUpdate.actionsIds.splice(findActionIdIndex, 1)
							}
						}

						//PUSH NEW EVENTS
						if (eventIndex === -1) {
							eventsUpdated.push({
								user: {
									id: currentUser.username,
									label: currentUser.name,
								},
								createdOn: new Date(),
								value: attribute + ' updated by ' + currentUser.name,
								updatedValue: updatedValue,
								lastValue: lastValue,
							})

							//IF SAME DAY UPDATE VALUE
						} else {
							eventsUpdated[eventIndex].updatedValue = updatedValue
						}
					}
				}

				// FILL NEW ACTION OBJECT
				newAction = {
					...actionBeingEdited,
					...mutateObject,
					updatedAt: new Date(),
					events: JSON.stringify(eventsUpdated),
				}
			}

			// CLEAN FOR GRAPHQL
			delete newAction.telescopeDataGovernanceTelescopeDataActionId
			delete newAction.telescopeDataCostTelescopeDataActionId
			delete newAction.telescopeDataScheduleTelescopeDataActionId
			delete newAction.telescopeDataGovernance
			delete newAction.telescopeDataRO
			delete riskOppToUpdate.createdDate
			delete newAction.goBackOriginItem

			// HANDLE CONTRIBUTORS
			if (mutateObject.contributors) {
				newAction.contributors = mutateObject.contributors.map(
					(user) => user.value
				)
			}

			//IF NEW
			if (isNew) {
				// UPDATE BACKEND
				multipleQueryMultipleMutationGraphql(
					// DATA
					{
						updateTelescopeDataRO: {
							objects: [riskOppToUpdate],
							query: UpdateTelescopeDataRO,
						},
						createTelescopeDataAction: {
							objects: [newAction],
							query: CreateTelescopeDataAction,
						},
					},
					// APP DISPATCHER
					appDispatcher
				)

				// UPDATE APP STATE
				appDispatcher({
					type: 'SET_PROJECT_DATA_MULTIPLE_TABLES',
					object: {
						action: {
							create: [newAction],
						},
						riskOpp: {
							update: [riskOppToUpdate],
						},
					},
				})

				//UPDATE
			} else {
				if (mutateObject.links) {
					mutateGraphql(UpdateTelescopeDataRO, riskOppToUpdate)
				}

				// UPDATE IN BACK-END THE ACTION
				mutateGraphql(
					UpdateTelescopeDataAction,
					{
						...newAction,
						groupEditors: mutateObject.isPrivate
							? []
							: securityGroup.groupEditors,
						groupViewers: mutateObject.isPrivate
							? []
							: securityGroup.groupViewers,
						createdBy: mutateObject.isPrivate
							? ''
							: actionBeingEdited && actionBeingEdited.createdBy !== ''
							? actionBeingEdited.createdBy
							: currentUser.username,
						telescopeDataROTelescopeDataActionId: riskOppToUpdate.id,
					},
					appDispatcher,
					null
				)

				if (mutateObject.links) {
					// UPDATE APP STATE
					appDispatcher({
						type: 'SET_PROJECT_DATA_MULTIPLE_TABLES',
						object: {
							action: {
								create: [newAction],
							},
							riskOpp: {
								update: [riskOppToUpdate],
							},
						},
					})
				} else {
					// UPDATE IN APP STATE
					appDispatcher({
						type: 'SET_PROJECT_DATA_UPDATE',
						source: 'action',
						objects: [newAction],
					})
				}
			}

			// UPDATE RISK OPP STATE
			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					editSideBar: false,
					editSideBarContent: null,
					viewMode:
						state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
							? state.viewMode
							: WBS_BIG_COMPONENT_VIEW_MODE.list,
					actionsOperationType: null,
					detailedRoMatrixItem: state.detailedRoMatrixItem
						? riskOppToUpdate
						: null,
					riskOppViewItem:
						state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
							? riskOppToUpdate
							: null,
					selected: [],
				},
			})
		},
		[
			actionsData,
			appDispatcher,
			biggestActionDisplayIdNum,
			currentPath,
			currentUser.name,
			currentUser.username,
			defaultActionItem,
			securityGroup.groupEditors,
			securityGroup.groupViewers,
			state.detailedRoMatrixItem,
			state.riskOppViewItem,
			state.viewMode,
			usersList,
		]
	)

	// DUPLICATE MULTIPLE/ONE R&O ITEM ----------- OK
	const onDuplicateSideBar = useCallback(() => {
		// INIT
		let mutateObject = []
		let currentItems = []

		// INIT EVENTS
		let eventsUpdated = [
			{
				user: {
					id: currentUser.username,
					label: currentUser.name,
				},
				createdOn: new Date(),
				value: 'Created by ' + currentUser.name,
			},
		]

		// IF RISK OPP VIEW ITEM IS NOT NULL, THEN DUPLICA THIS ONE
		if (state.riskOppViewItem) {
			currentItems = [_.cloneDeep(state.riskOppViewItem)]
		} else {
			currentItems = _.cloneDeep(state.selected)
		}

		// INIT BIGGEST DISPLAY ID NUM
		let newDisplayId = biggestDisplayIdNum

		// LOOP ON SELECTED
		for (let i = 0, len = currentItems.length; i < len; i++) {
			// INIT
			let updatedItem = _.cloneDeep(currentItems[i])

			// CLEAN CONNECTIONS WITH ACTIONS
			delete updatedItem.actionsIds
			// SET TO ESCALATED TO AND ESCALATED FROM TO NULL
			updatedItem.escalatedTo = null
			updatedItem.escalatedFrom = null

			// SET WBS ID TO CURRENT PROJECT ITEM ID
			updatedItem.wbsId = wbsId

			// SET PATH
			updatedItem.path = currentPath

			// MUTATE OBJECT
			mutateObject.push({
				...updatedItem,
				id: uuid(),
				displayId:
					RISK_OPP_DISPLAY_ID_PREFIX + FUNC_ZERO_FORMAT_TO_NUM(newDisplayId, 4),
				createdBy:
					currentItems[i].createdBy !== ''
						? currentItems[i].createdBy
						: currentUser.username,
				responsible: currentUser.username,
				createdOn: TODAY,
				events: JSON.stringify(eventsUpdated),
				[state.detailedRoMatrixItem !== null
					? 'action'
					: 'name']: `(Duplicate) - ${
					state.detailedRoMatrixItem !== null
						? updatedItem.action
						: updatedItem.name
				}`,
				groupEditors: securityGroup.groupEditors,
				groupViewers: securityGroup.groupViewers,
			})

			// INCREMENT DISPLAY ID
			newDisplayId += 1
		}

		// INIT R&O TO UPDATE
		let roItemToUpdate = state.detailedRoMatrixItem

		if (state.detailedRoMatrixItem !== null) {
			// UPDATE RO ITEM TO UPDATE
			roItemToUpdate.actionsIds = [
				...roItemToUpdate.actionsIds,
				...mutateObject.map((action) => action.id),
			]

			// UPDATE BACK-END
			multipleQueryMultipleMutationGraphql(
				// DATA
				{
					updateTelescopeDataRO: {
						objects: [roItemToUpdate],
						query: UpdateTelescopeDataRO,
					},
					createTelescopeDataAction: {
						objects: mutateObject,
						query: CreateTelescopeDataAction,
					},
				},
				// APP DISPATCHER
				appDispatcher
			)

			// UPDATE APP STATE
			appDispatcher({
				type: 'SET_PROJECT_DATA_MULTIPLE_TABLES',
				object: {
					action: {
						create: mutateObject,
					},
					riskOpp: {
						update: [roItemToUpdate],
					},
				},
			})
		} else {
			// SEND GRAPHQL REQUEST
			multipleMutateGraphql(CreateTelescopeDataRO, mutateObject, appDispatcher)

			// UPDATE APP STATE
			appDispatcher({
				type: 'SET_PROJECT_DATA_CREATE',
				source: 'riskOpp',
				objects: mutateObject,
			})
		}

		// IF IN VIEW MODE, THEN UPDATE THE RISK OPP VIEW ITEM
		riskOppDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				selected: [],
				riskOppViewItem: state.riskOppViewItem ? mutateObject[0] : null,
				propKeyIncrement: state.propKeyIncrement + 1,
				// DETAILED R&O ITEM
				detailedRoMatrixItem:
					state.detailedRoMatrixItem !== null ? roItemToUpdate : null,
			},
		})
	}, [
		appDispatcher,
		biggestDisplayIdNum,
		currentPath,
		currentUser.name,
		currentUser.username,
		securityGroup.groupEditors,
		securityGroup.groupViewers,
		state.detailedRoMatrixItem,
		state.propKeyIncrement,
		state.riskOppViewItem,
		state.selected,
		wbsId,
	])

	// DUPLICATE ACTIONS WHEN IN VIEW MODE
	const onDuplicateActionsViewMode = useCallback(() => {
		let duplicateMutation = FUNC_ACTION_DUPLICATE(
			state.selected,
			currentUser,
			biggestActionDisplayIdNum,
			securityGroup,
			projectData,
			appDispatcher
		)

		if (state.riskOppViewItem) {
			let updatedRo = FIND_OBJECT_ARRAY_ITEM(
				duplicateMutation.dispatcher.riskOpp.update,
				'id',
				state.riskOppViewItem.id
			)

			// UPDATE R&O STATE
			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: [],
					riskOppViewItem: updatedRo,
					detailedRoMatrixItem: state.detailedRoMatrixItem ? updatedRo : null,
					propKeyIncrement: state.propKeyIncrement + 1,
					viewMode:
						state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
							? WBS_BIG_COMPONENT_VIEW_MODE.view
							: WBS_BIG_COMPONENT_VIEW_MODE.list,
				},
			})
		}
	}, [
		appDispatcher,
		biggestActionDisplayIdNum,
		currentUser,
		projectData,
		securityGroup,
		state.detailedRoMatrixItem,
		state.propKeyIncrement,
		state.riskOppViewItem,
		state.selected,
		state.viewMode,
	])

	// HANDLE EDIT OF SINGLE FIELDS ON AN ACTION
	const handleEditRoItem = useCallback(
		(roItem, key, value) => {
			// CALL ON SAVE ADD EDIT FUNCTION
			onSaveAddEdit(
				null,
				{ id: roItem.id, [key]: value, createdBy: roItem.createdBy },
				false
			)
		},
		[onSaveAddEdit]
	)

	// HANDLE R&O TABLE SELECT CHECKBOX
	const handleRoSelectCheckbox = useCallback(
		(e, item, wasSelected) => {
			e.stopPropagation()

			let newSelectedList = _.cloneDeep(state.selected)

			// IF WAS SELECTED, REMOVE FROM NEW SELECTED ARRAY
			if (wasSelected) {
				newSelectedList = newSelectedList.filter(
					(itemInList) => itemInList.id !== item.id
				)
			}
			// ELSE PUSH
			else {
				newSelectedList.push(item)
			}

			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: newSelectedList,
				},
			})
		},
		[state.selected]
	)

	// SHOW MOVE MODAL
	const handleMoveItemsClicked = useCallback(
		(e) => {
			e.preventDefault()

			let isMoveActions = false
			let itemsArray

			// IF IN CARD VIEW MODE
			if (
				state.riskOppViewItem &&
				state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view
			) {
				// IF SELECTED (MEANS ACTIONS ARE SELECTED)
				if (state.selected.length > 0) {
					itemsArray = state.selected
					isMoveActions = true
				} else itemsArray = [state.riskOppViewItem]
			}
			// IF IN DETAILED RO VIEW MODE
			else if (state.detailedRoMatrixItem) {
				if (state.selected.length > 0) {
					itemsArray = state.selected
					isMoveActions = true
				} else itemsArray = [state.detailedRoMatrixItem]
			}
			// IN NORMAL RO LIST VIEW MODE
			else itemsArray = state.selected

			// SEND ACTION TO DISPATCHER
			appDispatcher({
				type: 'SET_GLOBAL_STATE',
				options: {
					source: 'appSettings',
					object: {
						actionModal: 'wbsMove',
						moveModalOptions: {
							itemsArray: itemsArray,
							tableName: isMoveActions ? 'action' : 'riskOpp',
							query: isMoveActions
								? UpdateTelescopeDataAction
								: UpdateTelescopeDataRO,
							propertiesToDelete: isMoveActions
								? ['sort', 'telescopeDataGovernance']
								: [],
						},
					},
				},
			})
		},
		[
			appDispatcher,
			state.detailedRoMatrixItem,
			state.riskOppViewItem,
			state.selected,
			state.viewMode,
		]
	)

	// WHEN DELETE ACTION FROM TOP ACTION BUTTONS
	const onDeleteLinkedAction = useCallback(() => {
		// RUN ACTION DELETION
		ACTION_FUNC_DELETE_ACTIONS_AND_LINKS(
			null,
			state.selected,
			projectData,
			appDispatcher
		)

		let roToUpdate = _.cloneDeep(state.riskOppViewItem)

		// GET ALL IDS
		let actionIdsToRemove = state.selected.map((item) => item.id)

		// REMOVE ACTION IDS SELECTED FROM SCHEDULE TO UPDATE
		roToUpdate.actionsIds = roToUpdate.actionsIds.filter(
			(actionId) => !actionIdsToRemove?.includes(actionId)
		)

		riskOppDispatcher({
			type: 'SET_STATE_OBJECT',
			object: {
				selected: [],
				riskOppViewItem: roToUpdate,
			},
		})
	}, [appDispatcher, projectData, state.riskOppViewItem, state.selected])

	/////////////////////////////////////////////////////
	// USE EFFECTS //////////////////////////////////////
	/////////////////////////////////////////////////////

	// MANAGE CONFIRMING MOVE
	useEffect(() => {
		// IF NOT NULL AND EMPTY (CONFIRMED MOVE MODAL)
		if (moveModalOptionsItemsArray && moveModalOptionsItemsArray.length === 0) {
			// UPDATE BIG COMPONENT STATE
			riskOppDispatcher({
				type: 'SET_STATE_OBJECT',
				object: {
					selected: [],
					riskOppViewItem: null,
					viewMode: WBS_BIG_COMPONENT_VIEW_MODE.list,
				},
			})
			// UPDATE APP STATE
			appDispatcher({
				type: 'SET_GLOBAL_STATE',
				options: {
					source: 'appSettings',
					object: {
						moveModalOptions: DEFAULT_STATE_MOVE_MODAL_OPTIONS,
					},
				},
			})
		}
	}, [appDispatcher, moveModalOptionsItemsArray])

	// UPDATE ITEM CARD VIEW IF NAVIGATION OPTIONS UPDATED WHEN ALREADY IN CARD VIEW
	useEffect(() => {
		if (
			wbsNavigationOptions?.goToItemId &&
			state.riskOppViewItem?.id !== wbsNavigationOptions.goToItemId
		) {
			const newItemView = FIND_OBJECT_ARRAY_ITEM(
				roData,
				'id',
				wbsNavigationOptions.goToItemId
			)

			if (!newItemView || newItemView.isRisk !== isRisk) {
				// APP STATE
				appDispatcher({
					type: 'SET_GLOBAL_STATE',
					options: {
						source: 'portfolioDisplay',
						object: {
							wbsNavigationOptions: WBS_DEFAULT_NAVIGATION_OPTIONS,
						},
					},
				})

				// WBS STATE
				wbsDispatcher({
					type: 'SET_STATE_KEY_VALUE',
					key: 'wbsNavigationOptions',
					value: WBS_DEFAULT_NAVIGATION_OPTIONS,
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wbsNavigationOptions])

	/////////////////////////////////////////////////////////////
	/// COMPONENT RENDER ///////////////////////////////////////
	////////////////////////////////////////////////////////////

	return (
		<React.Fragment>
			{/* EXTERNAL CONNECTORS MODAL */}

			{/* BIG COMPONENT STANDARD STRUCTURE */}
			<WbsElementBigStructure
				fieldInformation={
					isRisk === false
						? { ...fieldInformation, name: 'Opportunities' }
						: fieldInformation
				}
				handleBigComponent={handleBigComponent}
				projectTitle={wbsName}
				buttonExpand={true}
				isExpendedEnabled={bigComponentIsExpanded}
				functionExpand={(e) => bigComponentExpandFunction(e)}
				tooltipInfo={isRisk ? 'Risks' : 'Opportunities'}>
				{/* SIDE BAR */}
				<RiskBigSideBar
					currentUserId={currentUser.username}
					canModifyRo={canModifyRo}
					handleMoveItemsClicked={handleMoveItemsClicked}
					isConsolidated={state.isConsolidated}
					isCurrentUserEditor={securityGroup.isCurrentUserEditor}
					isInDetailedActionsMode={state.detailedRoMatrixItem !== null}
					isProjectElement={isProjectElement}
					isReadOnlyMode={isReadOnlyMode}
					onAddEditActionClick={onAddEditActionClick}
					onAddEditClick={onAddEditClick}
					onBackSideBar={onBackSideBar}
					onDeleteSideBar={onDeleteSideBar}
					onDuplicateSideBar={onDuplicateSideBar}
					onExternalConnectorSideBar={onExternalConnectorSideBar}
					riskOppDispatcher={riskOppDispatcher}
					riskOppViewItem={state.riskOppViewItem}
					selectedItems={state.selected}
					viewMode={state.viewMode}
				/>

				{/* BIG COMPONENT BODY */}
				<WbsElementStructureBody
					isExpendedEnabled={bigComponentIsExpanded}
					key={`risk_opp_component_body_${wbsId}`}
					blockFlex='flexBetweenStrech'>
					<div
						className='flexStartStart'
						style={{
							justifyContent: isReviewMode
								? 'flex-start'
								: state.expandMode === RISK_OPP_EXPAND_MODES.both
								? ''
								: state.expandMode === RISK_OPP_EXPAND_MODES.matrixOnly
								? 'flex-start'
								: 'flex-end',
							width: '100%',
						}}>
						{/* RISK CARD */}
						<RiskCard
							key={'riskCard_' + state.riskOppViewItem?.id}
							canModifyRo={canModifyRo}
							// DATA
							viewMode={state.viewMode}
							roItem={state.riskOppViewItem}
							usersList={usersList}
							isReviewMode={isReviewMode}
							riskViewModeSelection={state.riskViewModeSelection}
							roData={selectedRoData}
							projectData={projectData}
							currentUser={currentUser}
							parentId={parentId}
							wbsId={wbsId}
							previousChanges={state.previousChanges}
							propKeyIncrement={state.propKeyIncrement}
							deleteCommentsIds={state.deleteCommentsIds}
							actionsData={filteredActions}
							megaUsersId={megaUsersIds}
							selectedItems={state.selected}
							megaUsersIds={megaUsersIds}
							legendAndCounter={legendAndCounter}
							statusSearchInput={state.statusSearchInput}
							textSearchInput={state.actionsTextSearchInput}
							allActionsData={actionsData}
							riskCardImpactProbaOpenView={state.riskCardImpactProbaOpenView}
							isExpanded={bigComponentIsExpanded}
							displayWidth={
								state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.view ||
								state.riskOppViewItem
									? '100%'
									: '0%'
							}
							isCurrentUserEditor={securityGroup.isCurrentUserEditor}
							isReadOnlyMode={isReadOnlyMode}
							updateTracker={updateTracker}
							securityGroup={securityGroup}
							// FUNCTIONS
							riskOppDispatcher={riskOppDispatcher}
							appDispatcher={appDispatcher}
							handleEditRoItem={handleEditRoItem}
							handleCreateAction={onAddEditActionClick}
							onSaveAddEdit={onSaveAddEdit}
							handleEditAction={onAddEditActionClick}
							handleDuplicateClick={onDuplicateActionsViewMode}
							handleDeleteClick={onDeleteLinkedAction}
							handleRoSelectCheckbox={handleRoSelectCheckbox}
							handleMoveItemsClicked={handleMoveItemsClicked}
							wbsDispatcher={wbsDispatcher}
						/>

						{/* MATRIX */}
						<RiskMatrix
							// DATA
							expandMode={state.expandMode}
							viewMode={state.viewMode}
							isRisk={isRisk}
							riskData={displayRiskOppItems}
							wbsId={wbsId}
							isReadOnlyMode={isReadOnlyMode}
							parentId={parentId}
							actionsData={actionsData}
							riskSelectedViewMode={state.riskSelectedViewMode}
							previousChanges={state.previousChanges}
							propKeyIncrement={state.propKeyIncrement}
							currentUser={currentUser}
							detailedRoMatrixItem={state.detailedRoMatrixItem}
							isExpanded={bigComponentIsExpanded}
							textSearchInput={state.textSearchInput}
							displayWidth={
								state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view
									? state.expandMode === RISK_OPP_EXPAND_MODES.matrixOnly
										? '100%'
										: state.expandMode === RISK_OPP_EXPAND_MODES.tableOnly
										? '0%'
										: isReviewMode
										? '50%'
										: '40%'
									: '0%'
							}
							isCurrentUserEditor={securityGroup.isCurrentUserEditor}
							// FUNCTIONS
							handleExpandArrowClick={handleExpandArrowClick}
							riskOppDispatcher={riskOppDispatcher}
							appDispatcher={appDispatcher}
							handleEditRoItem={handleEditRoItem}
						/>

						{/* DETAILED ACTIONS TABLE */}

						<DetailedRoActionsTable
							// DATA
							canModifyRo={canModifyRo}
							roItem={state.detailedRoMatrixItem}
							actionsData={filteredActions}
							legendAndCounter={legendAndCounter}
							expandMode={state.expandMode}
							usersList={usersList}
							megaUsersId={megaUsersIds}
							parentId={parentId}
							wbsId={wbsId}
							isCurrentUserEditor={securityGroup.isCurrentUserEditor}
							currentUser={currentUser}
							selectedItems={state.selected}
							textSearchInput={state.actionsTextSearchInput}
							statusSearchInput={state.statusSearchInput}
							allActionsData={actionsData}
							isExpanded={bigComponentIsExpanded}
							displayWidth={
								state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
								state.detailedRoMatrixItem !== null
									? state.expandMode === RISK_OPP_EXPAND_MODES.tableOnly
										? '100%'
										: state.expandMode === RISK_OPP_EXPAND_MODES.matrixOnly
										? '0%'
										: isReviewMode
										? '100%'
										: '60%'
									: '0%'
							}
							// FUNCTIONS
							handleExpandArrowClick={handleExpandArrowClick}
							riskOppDispatcher={riskOppDispatcher}
							handleCreateAction={onAddEditActionClick}
							handleEditRoItem={handleEditRoItem}
							handleEditAction={onAddEditActionClick}
							handleDuplicateClick={onDuplicateActionsViewMode}
							handleDeleteClick={onDeleteSideBar}
							handleRoSelectCheckbox={handleRoSelectCheckbox}
							handleMoveItemsClicked={handleMoveItemsClicked}
							// KEY
							key={`detailed_ro_actions_table_${wbsId}`}
						/>

						{/* RO TABLE */}
						<RiskTable
							// DATA
							isCurrentUserEditor={securityGroup.isCurrentUserEditor}
							isProjectElement={isProjectElement}
							isReviewMode={isReviewMode}
							expandMode={state.expandMode}
							viewMode={state.viewMode}
							displayRiskOppItems={displayRiskOppItems}
							roData={selectedRoData}
							usersList={usersList}
							currentUser={currentUser}
							isFullScreen={bigComponentIsExpanded}
							selectedRiskOppItems={state.selected}
							textSearchInput={state.textSearchInput}
							roStatusSearchFilter={state.roStatusSearchFilter}
							wbsId={wbsId}
							isRisk={isRisk}
							propKeyIncrement={state.propKeyIncrement}
							isReadOnlyMode={isReadOnlyMode}
							parentId={parentId}
							megaUsersId={megaUsersIds}
							detailedRoMatrixItem={state.detailedRoMatrixItem}
							displayWidth={
								state.viewMode !== WBS_BIG_COMPONENT_VIEW_MODE.view &&
								state.detailedRoMatrixItem === null
									? state.expandMode === RISK_OPP_EXPAND_MODES.tableOnly
										? '100%'
										: state.expandMode === RISK_OPP_EXPAND_MODES.matrixOnly
										? '0%'
										: '60%'
									: '0%'
							}
							//roLegendAndCounter={roLegendAndCounter}
							// FUNCTIONS
							handleExpandArrowClick={handleExpandArrowClick}
							onRiskOppItemClick={handleRoItemClick}
							onClickAdd={() => onAddEditClick()}
							riskOppDispatcher={riskOppDispatcher}
							appDispatcher={appDispatcher}
							handleEditRoItem={handleEditRoItem}
							handleRoSelectCheckbox={handleRoSelectCheckbox}
							// KEY
							key={`risk_table_${wbsId}`}
						/>
					</div>
				</WbsElementStructureBody>
				{/* ------------------ */}
			</WbsElementBigStructure>

			{/* EDIT SIDE BAR */}
			<EditSideBar
				display={state.editSideBar}
				content={state.editSideBarContent}
				title={`
						${
							state.actionsOperationType
								? `${
										state.actionsOperationType ===
										RISK_OPP_CHANGE_ACTIONS_TYPES.edit
											? 'Update'
											: 'Create'
								  } Action`
								: `${
										state.viewMode === WBS_BIG_COMPONENT_VIEW_MODE.add
											? 'Create'
											: 'Update'
								  } ${isRisk ? 'Risk' : 'Opportunity'}`
						}
				`}
				group={
					state.actionsOperationType ? ACTION_GROUP_ITEMS : RISK_OPP_GROUP_ITEMS
				}
				onSave={state.actionsOperationType ? onSaveAddAction : onSaveAddEdit}
				resetEditSideBar={resetEditSideBar}
				currentUser={currentUser}
				isCurrentUserEditor={securityGroup.isCurrentUserEditor}
				firstAddUpdatedFields={
					state.actionsOperationType
						? ['responsible', 'dueDate', 'status', 'links']
						: ['status', 'isRisk']
				}
				projectData={projectData}
			/>
		</React.Fragment>
	)
})
